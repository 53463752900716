import styled from 'styled-components';
import {createGlobalStyle} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`
export const Fondo = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 20px;
    margin:${props => props.margin};
    display: flex;
    flex-direction: column;
    padding:${props => props.padding};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    ${media.tablet}{
       width: ${props => props.ancho};
    height: ${props => props.alto};
   }
`
export const FlexAgen = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
export const FlexActividades = styled.div`
    width: 94%;
`

// BOTONES
export const BotonSeleccionado = styled.button`
    padding:${props => props.padding};
    border-radius: 10px;
    border:0;
    background: ${props => props.backgroundcolor ? "#23AAAF" : "white"};
    color:black;
    font-weight: 500;
    margin:${props => props.margin};
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size:${props => props.font};
    width: ${props => props.width};
    border-radius: 10px;
    text-transform:uppercase;       
`

export const BotonPequeño = styled(BotonSeleccionado)`
       box-shadow:none;
       padding:10px;
       background: ${props => props.backgroundcolor ? "#23AAAF" : "white"};
       cursor:pointer;
`
export const BotonNaranja = styled.button`
    border:none;
    background: #FF9B2A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    width:${props => props.width};
    margin:${props => props.margin};
    padding:${props => props.padding};
    justify-content: center;
    font-size: 12px;
    font-weight:500;
    align-items: center;
    text-decoration: none;
    color: black;
    cursor:pointer;
`
export const Boton = styled.button`
       border:0;
       background: white;
       padding: 0px 10px;
       background-color: ${props => props.color};
       cursor:pointer;
`
 export const DefaultMarcado = styled (BotonSeleccionado)`
    // &:focus-within{
 `
 export const Circulo = styled.button`
       border:none;
 `
 export const Sumar = styled.button`
       border:none;
       border-radius: 20px;
       background: #F2F2F2;
       box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
       margin:${props => props.margin};
       cursor:pointer;
 `
//  TEXTOS
export const Titulos = styled.p`
       text-transform: uppercase;
       font-size:14px;
       margin: ${props => props.margin};
       font-weight: ${props => props.weight};
       text-align:${props => props.align};
`
export const P = styled.p`
       margin:0;
       padding:0;
       margin:${props => props.margin};
       border:${props => props.border};
       padding:${props => props.padding};
       height: ${props => props.height};
       background-color:${props => props.background};
       font-size:${props => props.size};
`

// Tablas

export const TableActAgen = styled.table`
    display: block;
    table-layout: fixed;
    flex-direction: column;
    padding: 5px 5px;
    border-collapse: collapse;
    width: 94%;
    font-size: 15px;
    margin-top: 2.5rem;
    ${media.tablet}{
        font-size: 2.1vw;
    }
`

export const TheadAgen = styled.thead`
    padding-left: ${props => props.padding};
    &:after {
        content:"@";
        display:block;
        line-height:10px;
        text-indent:-99999px;
    }
`

export const TbodyAgen = styled.tbody`
    padding-left: ${props => props.padding};
    text-transform: capitalize;
`

export const TrAgen = styled.tr`
    text-align: ${props => props.textalign};
    border-radius: ${props => props.radius};
    box-shadow: ${props => props.shadow};
    background-color: ${props => props.background};
`

export const ThAgen = styled.th`
    border: 1px solid black;
    font-weight: 400;
    width: ${props => props.width};
`

export const TdAgen = styled.td`
    width: ${props => props.width};
    border: 1px solid;
    text-align: ${props => props.textalign};
    padding: 0 3px 0 3px;
    @media (min-width: 960px) {
        width: ${props => props.ancho};
    }
`

// LISTAS
export const Ul = styled.ul`
       list-style:none;
       padding:${props => props.padding};
       ${media.tablet}{
              padding:${props => props.espacio};
       }
`
export const Li = styled.li`
    display:flex;
    justify-content:space-around;
    align-items:center;
    padding:${props => props.padding};
`
export const ListadoObj = styled.li`
       display:flex;
       align-items:center;
       background-color: #F2F2F2;
       box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
       border-radius: 10px;
       width:30vw;
       margin-top: 10px;
       height: 35px;
       justify-content:space-between;
       ${media.tablet}{
              width: 50vw;
       }
`
// FORM
export const Input = styled.input`
       padding:0px;
       height: 20px;
       margin: ${props => props.margin};
       border:0;
`

// LINEAS Y CONTENEDORES
export const Linea = styled.hr`
       width:75vw;
`
export const Div= styled.div`
       width:80vw;
       background-color:white;
       border-radius:10px;
       margin:${props => props.margin};
`
export const Columna = styled.div`
       width:25vw;
       display:flex;
       flex-direction:column;
       align-items:center;
       padding-top:20px;
       ${media.tablet}{
              width: 40vw;
       }
`
export const Vertical = styled.hr`
       height:45vh;
       margin: 0px;
`
export const Actividad = styled.div`
       background: #F2F2F2;
       box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
       border-radius: 10px;
       width: 20vw;
       display:flex;
       justify-content:space-between;
       align-items:center;
       padding: 0px 10px;
       margin-top: 8px;
`
export const Contenedor = styled.div`
       display:flex;
       justify-content:space-around;
       flex-wrap:wrap;
`
// MODAL
export const ModalStyled = styled.div`
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       padding: 7vw 12vw;
       font-family: 'Montserrat', sans-serif;
`
export const ModalLabel=styled.label`
       text-transform:uppercase;
`
export const ModalInput = styled.input`
       background: #F2F2F2;
       box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
       border-radius: 10px;
       width: 40vw;
       border:0;
       padding: 10px;
       margin-bottom: 20px;
`
export const ModalButton = styled.button`
       padding: 10px;
       background-color: #FF9B2A;
       text-transform: uppercase;
       border: 0;
       box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
       border-radius: 10px;
`

export const Flex = styled.div`
       display: flex;
       justify-content: ${props => props.justify ? props.justify : "center"};
       align-items: center;
       padding: ${props => props.padding};
       width: ${props => props.width};
`

export const Img = styled.img`
    width: ${props => props.width}; 
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    border: ${props => props.border};
    border-radius: ${props => props.bradius};
    box-shadow: ${props => props.bs};
    
`