import React, {Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderEjercicios from '../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearUrlAnteriorAction } from '../../../../actions/estadoEjercicioActions';
import { setearEjercicioAction } from '../../../../actions/estadoEjercicioActions';

import { Mobile, Desktop, Flex, FlexCuz, Container, CantB10M43, ImgB10, TablaB10, CantTabla, CantMob, NumCant, FlexFull } from '../../../Css/StyledMatematica';

const instrucciones = 'Construye el número.';
const miniInstrucciones = 'Construye el número.';

const M43 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);

    useEffect(() => {
        if(contador === 8){
            dispatch( finalizarEjercicioAction() );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contador])

    useEffect(() => {
        dispatch(setearEjercicioAction('M43') );
        dispatch(setearUrlAnteriorAction('matematica/base-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(finalizar){
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                    <Fragment>
                        {contador === 0 &&
                        <FlexCuz justify='space-between' margin='5vh 5vw 5vh 5vw' mm='5vh 5vw 5vh 13vw' mt='5vh 5vw 5vh 15vw' md='5vh 5vw 5vh 15vw' mlg='5vh 5vw 5vh 15vw'> 
                            <Flex direction='column'>
                                <CantB10M43>1 CUBO = 1 UNIDAD</CantB10M43>
                                <Container minh='40vh' maxh='40vh'>
                                    <ImgB10 src={`/img/b10_unidad.png`} w='4vw' m='0.5vw' />
                                </Container>
                            </Flex>
                            <Flex margin='9vh 0vw 0vh 0vw'>
                                <TablaB10 direction='column'>
                                    <CantTabla>M</CantTabla>
                                    {contadorSecundario === 1 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px' bl='0px'>
                                    <CantTabla>C</CantTabla>
                                    {contadorSecundario === 1 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px'>
                                    <CantTabla>D</CantTabla>
                                    {contadorSecundario === 1 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column'>
                                    <CantTabla>U</CantTabla>
                                    {contadorSecundario === 1 && <NumCant>1</NumCant>}
                                </TablaB10>
                                
                            </Flex>
                        </FlexCuz>
                        }
                        {contador === 1 &&
                        <FlexCuz justify='space-between' margin='5vh 5vw 5vh 3vw' mm='5vh 5vw 5vh 13vw' mt='5vh 5vw 5vh 15vw' md='5vh 5vw 5vh 15vw' mlg='5vh 5vw 5vh 15vw'>
                            <Flex direction='column'>
                                <CantB10M43>2 CUBOS = 2 UNIDADES</CantB10M43>
                                <Flex minh='40vh' maxh='40vh'>
                                    {contadorSecundario > 1 && <ImgB10 src={`/img/b10_unidad.png`} w='4vw' m='0.5vw' /> }
                                    {contadorSecundario > 2 && <ImgB10 src={`/img/b10_unidad.png`} w='4vw' m='0.5vw' /> } 
                                </Flex>
                            </Flex>
                            <Flex margin='9vh 0vw 0vh 0vw'>
                                <TablaB10 direction='column'>
                                    <CantTabla>M</CantTabla>
                                    {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px' bl='0px'>
                                    <CantTabla>C</CantTabla>
                                    {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px'>
                                    <CantTabla>D</CantTabla>
                                    {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column'>
                                    <CantTabla>U</CantTabla>
                                    {contadorSecundario > 3 && <NumCant>2</NumCant>}
                                </TablaB10>
                            </Flex>
                        </FlexCuz>
                        }
                        <Desktop>
                            {contador === 2 &&
                            <FlexCuz justify='space-between' margin='5vh 5vw 5vh 15vw'>
                                <Flex direction='column'>
                                    <CantB10M43>10 UNIDADES = 1 DECENA</CantB10M43>
                                    <Flex>
                                        <Flex direction='column' minh='40vh' maxh='40vh'>
                                            {contadorSecundario > 4 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 5 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 6 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 7 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 8 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 9 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 10 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 11 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 12 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 13 && <ImgB10 src={`/img/b10_unidad.png`} w='25px' m='0.5px 0px 0.5px 0px' /> }
                                        </Flex>
                                        <Flex>
                                            {contadorSecundario > 14 && <ImgB10 src={`/img/b10_decena.png`} w='35px' h='262px'  m='0px 0vw 0px 170px' /> } {/* hd='35vh' */}
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex margin='15vh 0vw 0vh 0vw'>
                                    <TablaB10 direction='column'>
                                        <CantTabla>M</CantTabla>
                                        {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px' bl='0px'>
                                        <CantTabla>C</CantTabla>
                                        {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px'>
                                        <CantTabla>D</CantTabla>
                                        {contadorSecundario > 16 && <NumCant>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column'>
                                        <CantTabla>U</CantTabla>
                                        {contadorSecundario > 15 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                </Flex>
                            </FlexCuz>
                            }
                        </Desktop>
                        {/* 10 UNIDADES = 1 DECENA MOBILE */}
                        <Mobile>
                            {contador === 2 &&
                            <FlexCuz direction='column' justify='space-between' margin='5vh 5vw 5vh 15vw'>
                                <Flex margin='0vh 0vw 0vh 0vw'>
                                    <TablaB10 direction='column'>
                                        <CantTabla>M</CantTabla>
                                        {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px' bl='0px'>
                                        <CantTabla>C</CantTabla>
                                        {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px'>
                                        <CantTabla>D</CantTabla>
                                        {contadorSecundario > 16 && <NumCant>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column'>
                                        <CantTabla>U</CantTabla>
                                        {contadorSecundario > 15 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                </Flex>
                                <Flex direction='column' margin='25px 0px 0px 0px'>
                                    <CantB10M43>10 UNIDADES = 1 DECENA</CantB10M43>
                                    <Flex margin='-25px 0px 0px 0px'>
                                        <Flex direction='column' minh='40vh' maxh='40vh'>
                                            {contadorSecundario > 4 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 5 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 6 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 7 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 8 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 9 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 10 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 11 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 12 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                            {contadorSecundario > 13 && <ImgB10 src={`/img/b10_unidad.png`} w='20px' m='0.5px 0px 0.5px 0px' /> }
                                        </Flex>
                                        <Flex>
                                            {contadorSecundario > 14 && <ImgB10 src={`/img/b10_decena.png`} w='30px' h='210px' m='0px 0vw 0px 100px' /> }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </FlexCuz>
                            }
                        </Mobile>
                        {/* 10 UNIDADES = 1 DECENA MOBILE */}
                        {contador === 3 &&
                        <FlexCuz justify='space-between' margin='5vh 5vw 5vh 15vw'>
                            <Flex direction='column'>
                                <CantB10M43>2 DECENAS</CantB10M43>
                                <Container minh='40vh' maxh='40vh'>
                                    {contadorSecundario > 17 && <div><ImgB10 src={`/img/b10_decena.png`} w='35px' h='210px' /> <ImgB10 src={`/img/b10_decena.png`} w='35px' h='210px' m='4vh 0vw 0px 50px' /> </div>}
                                </Container>
                            </Flex>
                            <Flex margin='15vh 0vw 0vh 0vw'>
                                <TablaB10 direction='column'>
                                    <CantTabla>M</CantTabla>
                                    {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px' bl='0px'>
                                    <CantTabla>C</CantTabla>
                                    {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px'>
                                    <CantTabla>D</CantTabla>
                                    {contadorSecundario > 19 && <NumCant>2</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column'>
                                    <CantTabla>U</CantTabla>
                                    {contadorSecundario > 18 && <NumCant>0</NumCant>}
                                </TablaB10>
                            </Flex>
                        </FlexCuz>
                        }
                        <Desktop>
                            {contador === 4 &&
                            <FlexCuz justify='space-between' margin='5vh 5vw 5vh 15vw'>
                                <Flex direction='column'>
                                    <CantB10M43>10 DECENAS = 1 CENTENA</CantB10M43>
                                    <Flex>
                                        <Flex minh='40vh' maxh='40vh'>
                                                {contadorSecundario > 20 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 21 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 22 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 23 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 24 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 25 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 26 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 27 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 28 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 29 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                        </Flex>
                                        <Flex>
                                            {contadorSecundario > 30 && <ImgB10 src={`/img/BASE10centena.png`} w='150px' h='150px' m='0px 0vw 0px 5vw' /> }
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex margin='15vh 0vw 0vh 0vw'>
                                    <TablaB10 direction='column'>
                                        <CantTabla>M</CantTabla>
                                        {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px' bl='0px'>
                                        <CantTabla>C</CantTabla>
                                        {contadorSecundario > 33 && <NumCant>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px'>
                                        <CantTabla>D</CantTabla>
                                        {contadorSecundario > 32 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column'>
                                        <CantTabla>U</CantTabla>
                                        {contadorSecundario > 31 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                </Flex>
                            </FlexCuz>
                            }
                        </Desktop>
                        {/* 10 DECENAS = 1 CENTENA MOBILE */}
                        <Mobile>
                            {contador === 4 &&
                            <FlexCuz justify='space-between' margin='5vh 2vw 5vh 2vw'>
                                <Flex direction='column'>
                                    <CantB10M43>10 DECENAS = 1 CENTENA</CantB10M43>
                                    <FlexFull justify='center' align='center' direction='column'>
                                        <Flex minh='40vh' maxh='40vh'>
                                                {contadorSecundario > 20 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 21 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 22 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 23 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 24 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 25 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 26 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 27 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 28 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                                {contadorSecundario > 29 && <ImgB10 src={`/img/b10_decena.png`} w='20px' h='150px' m='-2.5px' /> }
                                        </Flex>
                                        <Flex>
                                                {contadorSecundario > 30 && <ImgB10 src={`/img/BASE10centena.png`} w='150px' h='150px' m='0px 0vw 0px 0vw' /> }
                                        </Flex>
                                    </FlexFull>
                                </Flex>
                                <Flex margin='15vh 0vw 0vh 0vw'>
                                    <TablaB10 direction='column'>
                                        <CantTabla>M</CantTabla>
                                        {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px' bl='0px'>
                                        <CantTabla>C</CantTabla>
                                        {contadorSecundario > 33 && <NumCant>1</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px'>
                                        <CantTabla>D</CantTabla>
                                        {contadorSecundario > 32 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column'>
                                        <CantTabla>U</CantTabla>
                                        {contadorSecundario > 31 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                </Flex>
                            </FlexCuz>
                            }
                        </Mobile>
                        {/* 10 DECENAS = 1 CENTENA MOBILE */}
                        {contador === 5 &&
                            <FlexCuz justify='space-between' margin='5vh 5vw 5vh 15vw'>
                            <Flex direction='column'>
                                <CantB10M43>2 CENTENAS</CantB10M43>
                                <Container minh='40vh' maxh='40vh' margin='3vh 0px 0px 0px'>
                                    {contadorSecundario > 33 && <div><ImgB10 src={`/img/BASE10centena.png`} h='20vh' m='8vh 0.5vw 0px 0.5vw' /> <ImgB10 src={`/img/BASE10centena.png`} h='20vh' m='8vh 0.5vw 0px 0.5vw' /> </div>}
                                </Container>
                            </Flex>
                            <Flex margin='15vh 0vw 0vh 0vw'>
                                <TablaB10 direction='column'>
                                    <CantTabla>M</CantTabla>
                                    {contadorSecundario > 3 && <NumCant op='0'>1</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px' bl='0px'>
                                    <CantTabla>C</CantTabla>
                                    {contadorSecundario > 36 && <NumCant>2</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px'>
                                    <CantTabla>D</CantTabla>
                                    {contadorSecundario > 35 && <NumCant>0</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column'>
                                    <CantTabla>U</CantTabla>
                                    {contadorSecundario > 34 && <NumCant>0</NumCant>}
                                </TablaB10>
                            </Flex>
                        </FlexCuz>
                        }
                        <Desktop>
                            {contador === 6 &&
                            <FlexCuz justify='space-between' margin='5vh 5vw 5vh 1vw' mm='5vh 5vw 5vh 13vw' mt='5vh 5vw 5vh 15vw' md='5vh 5vw 5vh 15vw' mlg='5vh 5vw 5vh 15vw'>
                                <Flex direction='column'>
                                    <CantB10M43>10 CENTENAS = 1 UNIDAD DE MIL</CantB10M43>
                                    <Flex>
                                        <Flex wrap='wrap' minw='30vw' maxw='30vw' minh='40vh' maxh='40vh'> { /* a este flex hay que darle max-width y wrap */ }
                                            {contadorSecundario > 37 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 38 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 39 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 40 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 41 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 42 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 43 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 44 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 45 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                            {contadorSecundario > 46 && <ImgB10 src={`/img/BASE10centena.png`} h='12vh' m='0.2vw' /> }
                                        </Flex>
                                        <Flex>
                                            {contadorSecundario > 47 && <ImgB10 src={`/img/BASE10mil.png`} h='30vh' m='0px 0vw 0px 5vw' /> }
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex margin='15vh 0vw 0vh 0vw'>
                                    <TablaB10 direction='column'>
                                        <CantTabla>M</CantTabla>
                                        {contadorSecundario > 51 && <NumCant>1.</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px' bl='0px'>
                                        <CantTabla>C</CantTabla>
                                        {contadorSecundario > 50 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px'>
                                        <CantTabla>D</CantTabla>
                                        {contadorSecundario > 49 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column'>
                                        <CantTabla>U</CantTabla>
                                        {contadorSecundario > 48 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                </Flex>
                            </FlexCuz>
                            }
                        </Desktop>
                        <Mobile>
                            {contador === 6 &&
                            <FlexCuz justify='space-between' margin='5vh 1vw 5vh 1vw'>
                                <Flex direction='column'>
                                    <CantMob>10 CENTENAS = 1 UNIDAD DE MIL</CantMob>
                                    <Flex direction='column'>
                                        <Flex wrap='wrap' minw='52vw' maxw='52vw' minh='20vh' maxh='20vh'> { /* a este flex hay que darle max-width y wrap */ }
                                            {contadorSecundario > 37 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 38 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 39 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 40 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 41 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 42 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 43 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 44 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 45 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                            {contadorSecundario > 46 && <ImgB10 src={`/img/BASE10centena.png`} h='8vh' m='0.2vw' /> }
                                        </Flex>
                                        <Flex margin='5vh 0vw 0vh 0vw'>
                                            {contadorSecundario > 47 && <ImgB10 src={`/img/BASE10mil.png`} h='20vh' m='0px 0vw 0px 0vw' /> }
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex margin='15vh 0vw 0vh 0vw'>
                                    <TablaB10 direction='column'>
                                        <CantTabla>M</CantTabla>
                                        {contadorSecundario > 51 && <NumCant>1.</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px' bl='0px'>
                                        <CantTabla>C</CantTabla>
                                        {contadorSecundario > 50 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column' br='0px'>
                                        <CantTabla>D</CantTabla>
                                        {contadorSecundario > 49 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                    <TablaB10 direction='column'>
                                        <CantTabla>U</CantTabla>
                                        {contadorSecundario > 48 && <NumCant>0</NumCant>}
                                    </TablaB10>
                                </Flex>
                            </FlexCuz>
                            }
                        </Mobile>
                        {contador === 7 &&
                        <FlexCuz justify='space-between' margin='5vh 5vw 5vh 1vw' mm='5vh 5vw 5vh 13vw' mt='5vh 5vw 5vh 15vw' md='5vh 5vw 5vh 15vw' mlg='5vh 5vw 5vh 15vw'>
                            <Flex direction='column'>
                                <CantB10M43>2 UNIDADES DE MIL</CantB10M43>
                                <div>
                                    <Container minh='40vh' maxh='40vh'>
                                        {contadorSecundario > 51 && <div><ImgB10 src={`/img/BASE10mil.png`} h='20vh' m='8vh 0.5vw 0px 40px' /> <ImgB10 src={`/img/BASE10mil.png`} h='20vh' m='8vh 0.5vw 0px 40px' /> </div>}
                                    </Container>
                                </div>
                            </Flex>
                            <Flex margin='15vh 0vw 0vh 0vw'>
                                <TablaB10 direction='column'>
                                    <CantTabla>M</CantTabla>
                                    {contadorSecundario > 55 && <NumCant>2.</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px' bl='0px'>
                                    <CantTabla>C</CantTabla>
                                    {contadorSecundario > 54 && <NumCant>0</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column' br='0px'>
                                    <CantTabla>D</CantTabla>
                                    {contadorSecundario > 53 && <NumCant>0</NumCant>}
                                </TablaB10>
                                <TablaB10 direction='column'>
                                    <CantTabla>U</CantTabla>
                                    {contadorSecundario > 52 && <NumCant>0</NumCant>}
                                </TablaB10>
                            </Flex>
                        </FlexCuz>
                        }
                        {contador === 0 && contadorSecundario === 0 && 
                        <FlexCuz direction='row-reverse'  position='fixed' right='0' bottom='0'>
                            <BotoneraBottom aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 1 && contador === 0 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <BotoneraBottom  reducirSecundario={true} aumentar={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 1 && contador === 1 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <BotoneraBottom  reducir={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario > 1 && contadorSecundario < 4 && contador === 1 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <BotoneraBottom  reducirSecundario={true} aumentarSecundario={true} />
                        </FlexCuz>}
                        {contadorSecundario === 4 && contador === 1 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <BotoneraBottom  reducirSecundario={true} aumentar={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 4 && contador === 2 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducir={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario > 4 && contadorSecundario < 17 && contador === 2 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 17 && contador === 2 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentar={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 17 && contador === 3 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducir={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario > 17 && contadorSecundario < 20 && contador === 3 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 20 && contador === 3 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentar={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 20 && contador === 4 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducir={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario > 20 && contadorSecundario < 34 && contador === 4 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 34 && contador === 4 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentar={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 34 && contador === 5 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducir={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario > 34 && contadorSecundario < 37 && contador === 5 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 37 && contador === 5 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentar={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 37 && contador === 6 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducir={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario > 37 && contadorSecundario < 52 && contador === 6 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 52 && contador === 6 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentar={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 52 && contador === 7 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducir={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario > 52 && contadorSecundario < 56 && contador === 7 && 
                        <FlexCuz position='fixed' right='0' bottom='0'> 
                        <BotoneraBottom  reducirSecundario={true} aumentarSecundario={true} />
                        </FlexCuz>
                        }
                        {contadorSecundario === 56 && contador === 7 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom  reducirSecundario={true} aumentar={true} />
                        </FlexCuz> 
                        }
                    </Fragment>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M43;