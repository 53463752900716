import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

import { Flex, LinksEst, ImgEst, DesEst } from '../../../Css/StyledMatematica';
import { HrMetodos } from '../../../Css/StyledHeader';

const EstimacionDeCantidad = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"estimacion-y-nocion-de-cantidad/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"ESTIMACION Y NOCION DE CANTIDAD"} componente={"ESTIMACION DE CANTIDAD"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex wrap='wrap' width='70vw' margin='22vh 15vw 2vh 15vw' mt='24vh 15vw 2vh 15vw' md='24vh 15vw 2vh 15vw' mlg='24vh 15vw 2vh 15vw'>
                <LinksEst to={`/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/estimacion-de-cantidad/M15`}><ImgEst src={'/img/portadas/matematica15.jpg'} /><DesEst></DesEst></LinksEst>
                <LinksEst to={`/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/estimacion-de-cantidad/M16`}><ImgEst src={'/img/portadas/matematica16.jpg'} /><DesEst></DesEst></LinksEst>
            </Flex>
        </Fragment>
    );
}
 
export default EstimacionDeCantidad;