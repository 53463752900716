import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import {
    Link
} from "react-router-dom";
const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`

// lectura

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    direction:  ${(props) => props.direction};
    justify-content:  ${(props) => props.justify};
    align-items:  ${(props) => props.align};
    background-color:  ${(props) => props.bg};

    ${media.tablet}{
    margin-top: 2rem ;
}
`

export const BtnGroup = styled.div`
 display: flex;
 flex-wrap: wrap ;
 width: ${(props) => props.width};
height: ${(props) => props.height};
direction:  ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align};
background-color:  ${(props) => props.bg};


${media.tablet}{
    width: ${(props) => props.widthmob};
    margin:  ${(props) => props.marginMob};
}
`

export const BtnsLink = styled(Link)`
 margin:  ${(props) => props.margin};
 padding:  ${(props) => props.padding};;
 font-size:  ${(props) => props.font};
 text-align: center;
 display: flex ;
 justify-content: center ;
 align-items: center ;
 text-decoration: none;
 font-family: nunito;
 font-weight: bold;
 background-color: #D05F67;
 border-radius: 10px;
 color: white;
 cursor: pointer;
 text-transform: uppercase;
 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

${media.tablet}{
    margin: 20px 10px 20px 10px;
    padding: 20px 20px;
    font-size: 1rem;
}
`
