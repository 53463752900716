import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import { FlexFull, Flex, AvanzarImg, Line, NumM33, BtnImg, Lateral, CirculoMenosM33, AvanzarBtn, ImgB10 } from '../../../../Css/StyledMatematica';

const instrucciones = 'Construye el número con el bloque que más te guste.';
const miniInstrucciones = 'Construye el número con el bloque que más te guste.';

const M33 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [elegirColor, setElegirColor] = useState([]);
    const [colorElegido, setColorElegido] = useState([]);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if(comparar[count-1] !== colorElegido.length) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setColorElegido([]);
            }, 300);
        } else {
            console.log('bien');
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setDisable(false);
                setColorElegido([]);
                setCount(count + 1);
            } else {
                setTimeout(() => {
                    setDisable(false);
                    setColorElegido([]);
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }

    const setearColor = (e, colorBloque) => {
        e.preventDefault();
        if(colorElegido.length > 9) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(colorElegido));
        newList.push(colorBloque);
        setColorElegido(newList);
    }

    const eliminarBloque = (e) => {
        e.preventDefault();
        if(colorElegido.length === 0) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(colorElegido));
        newList.pop();
        setColorElegido(newList);
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('M33') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/ejercitacion-1-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M33",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrden,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        const arr = [1,2,3,4,5,6,7,8,9,10]
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(arr).slice(0,5);
        setComparar(shuffleList);
        const arr2 = ['bloquerosa', 'bloqueazul', 'bloquenaranja', 'bloqueverde', 'bloqueamarillo'];
        setElegirColor(arr2);
        setIndexes(5);
        setIdOrden(shuffleList);
    }, [])
    
    return (
        <Fragment>
        <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
        {iniciar
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex margin="auto" width="100%" justify="space-evenly">
                    <FlexFull align='start'>
                        <NumM33>
                            {comparar[count-1]}
                        </NumM33>
                    </FlexFull>
                    <FlexFull direction='column' width='8vw' height='75vh' justify='end' align='center'>
                        <div>
                            {colorElegido.map((item, i) => 
                            <div key={i} >
                                <ImgB10 src={`/img/${item}.png`} style={{zIndex: "0"}} w='7.5vw' wm='5.8vw' wt='4.2vw' wd='3.5vw' wlg='3.2vw' m='-5px 0px 0px 0px'  /> 
                            </div>
                            )}
                        </div>
                        <Line m='2px 0px 0px 0px' />
                        <CirculoMenosM33
                            disabled={disable}
                            onClick={(e) => eliminarBloque(e)}
                        >
                        -
                        </CirculoMenosM33>
                    </FlexFull>
                    <Lateral>
                        {elegirColor.map((item, i) => 
                            <BtnImg
                                key={i}
                                disabled={disable}
                                onClick={(e) => setearColor(e, item)}
                            >
                                <ImgB10 src={`/img/${item}.png`} style={{zIndex: "0"}} w='6vw' wm='5.2vw' wt='4.8vw' wd='4.5vw' wlg='4.3vw' m='-4px 0px -4px -3px' mm='-4px 0px -4px 0px' mt='-4px 0px -4px 0px' md='-4px 0px -4px 4px' mlg='-4px 0px -4px 5px' /> 
                            </BtnImg>
                        )}
                    </Lateral>
                </Flex>
                {/* INSTRUCCIONES */}
                <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                    <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    <AvanzarBtn
                        disabled={disable}
                        onClick={(e) => verificar(e)}
                    >
                        <AvanzarImg src={'/img/play.png'} /> 
                    </AvanzarBtn>
                </Flex>
                {/* FIN INSTRUCCIONES */}
            </Flex>
            :
            <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default M33;