import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PerfilNavbar from '../AuxiliaresPaginas/PerfilNavbar';
import Alertas from '../Metodos/Compartidos/Alertas';
import Suscripcion from './Suscripcion';
import Suscriptor from './Suscriptor';

import { BotonNaranja, Flex, BotonCeleste} from '../Css/StyledPatient';

const Gestion = () => {
    

    const [cobros, setCobros] = useState(false);
    const [volver, setVolver] = useState(false);
    const [suscripcion, setSuscripcion] = useState(false);
    const [suscriptor, setSuscriptor] = useState(false);

    const alerta = useSelector ( (state) => state.alertas.alerta);

    return (
        <Fragment>
            <PerfilNavbar />
            {alerta ? <Alertas /> : null}
            {volver ? <BotonCeleste onClick={() => (setCobros(false), setSuscripcion(false), setSuscriptor(false), setVolver(false))}>Volver</BotonCeleste> : null}
            {!cobros 
            ?
            <Flex margin="2rem 0 2rem 0" direccion="column">
                <BotonNaranja margin="0 0 1rem 0"
                    onClick={() => (setCobros(true), setVolver(true))}
                >
                    Cobros
                </BotonNaranja>
            </Flex>
            :
            null
            }
            {cobros
            ?
            <Flex margin="2rem 0 2rem 0">
                <BotonNaranja margin="0 1rem 0rem 0"
                    onClick={() => (setSuscripcion(true), setSuscriptor(false))}
                >
                    Suscripciones
                </BotonNaranja>
                <BotonNaranja margin="0rem 0 0rem 1rem"
                    onClick={() => (setSuscriptor(true), setSuscripcion(false))}
                >
                    Suscriptores
                </BotonNaranja>
            </Flex>
            :
            null
            }
            {suscripcion ? <Suscripcion /> : null}
            {suscriptor ? <Suscriptor /> : null}
            
        </Fragment>
    );
}

export default Gestion;