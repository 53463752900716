import React, {Fragment, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { aumentarContadorAction, zeroContadorConCorreccionAction, aumentarRondaAction, aumentarContadorSecundarioAction, aumentarContadorTerciarioAction, aumentarContadorCuaternarioAction, reducirContadorAction, reducirContadorSecundarioAction, reducirContadorTerciarioAction, reducirContadorCuaternarioAction, zeroContadorTerciarioAction, aumentarContadorConCorreccionAction, reducirContadorConCorreccionAction, aumentarContadorErrorAction } from '../../../actions/botoneraEjercicioActions';
import { finalizarEjercicioAction } from '../../../actions/estadoEjercicioActions';
import { resetearAyudaAction } from '../../../actions/configuradorActions';

// css
import {BtnBottom} from '../../Css/MetodosCSS/StyledLetras';
import {Img} from '../../Css/StyledActivities';

const BotoneraBottom = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disable, setDisable] = useState(false);
    const [pausa, setPausa] = useState(false);

    const contadorConCorreccion = useSelector(state => state.ejercicio.contadorConCorreccion);
    const ronda = useSelector(state => state.ejercicio.ronda);
    
    const aumentarContador = (e) => {
        e.preventDefault();
        dispatch(aumentarContadorAction())
    }
    const aumentarContadorSecundario = (e) => {
        e.preventDefault();
        dispatch(aumentarContadorSecundarioAction())
    }
    const aumentarContadorTerciario = (e) => {
        e.preventDefault();
        dispatch(aumentarContadorTerciarioAction())
    }
    const aumentarContadorCuaternario = (e) => {
        e.preventDefault();
        dispatch(aumentarContadorCuaternarioAction())
    }

    const zeroContadorTerciario = (e, data) => {
        e.preventDefault();
        dispatch(zeroContadorTerciarioAction(data))
    }

    const aumentarContadorConCorrecion = (e) => {
        e.preventDefault();
        setDisable(true);
        if(contadorConCorreccion < props.cantidad -1) {
            setDisable(false);
            dispatch(aumentarContadorConCorreccionAction())
        } else {
            if(props.ronda !== true || props.ronda === undefined) {
                setDisable(false);
                dispatch( finalizarEjercicioAction() )
                dispatch(resetearAyudaAction(false))
            } else {
                if(ronda === 2){
                    setDisable(false);
                    dispatch( finalizarEjercicioAction() )
                    dispatch(resetearAyudaAction(false))
                } else{
                    setDisable(false);
                    dispatch( finalizarEjercicioAction() )
                    dispatch(aumentarRondaAction())
                }
            }
        }
    }

    const finalizoEjercicio = (e) => {
        e.preventDefault();
        dispatch( finalizarEjercicioAction() )
    }

    const reducirContador = (e) => {
        e.preventDefault();
        dispatch(reducirContadorAction())
    }
    const reducirContadorSecundario = (e) => {
        e.preventDefault();
        dispatch(reducirContadorSecundarioAction())
    }
    const reducirContadorTerciario = (e) => {
        e.preventDefault();
        dispatch(reducirContadorTerciarioAction())
    }
    const reducirContadorCuaternario = (e) => {
        e.preventDefault();
        dispatch(reducirContadorCuaternarioAction())
    }
    const reducirContadorConCorreccion = (e) => {
        e.preventDefault();
        dispatch(reducirContadorConCorreccionAction())
    }

    const error = (e) => {
        e.preventDefault();
        dispatch (aumentarContadorErrorAction())
    }

    const pausar = (e) => {
        e.preventDefault();
        if(pausa === false) {
            setDisable(true);
            setPausa(true)
        } else {
            setDisable(false);
            setPausa(false);
        }
    }

    const rehacer = (e) => {
        e.preventDefault()
    }

    return (
        <Fragment>
            {props.rehacer && 
            <BtnBottom
                onClick={(e) => rehacer(e)}
            >
                <Img src={`/img/icono_rehacer.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.reducir &&
            <BtnBottom
                onClick={(e) => reducirContador(e)}
            >
                <Img src={`/img/icono_retroceder.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.reducirSecundario &&
            <BtnBottom
                onClick={(e) => {reducirContadorSecundario(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_retroceder.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.reducirTerciario &&
            <BtnBottom
                onClick={(e) => {reducirContadorTerciario(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_retroceder.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.reducirCuaternario &&
            <BtnBottom
                onClick={(e) => {reducirContadorCuaternario(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_retroceder.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.reducirConCorreccion &&
            <BtnBottom
                onClick={(e) => {reducirContadorConCorreccion(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_retroceder.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }

            {props.pausar &&
            // <BtnBottom style={{backgroundColor: pausa ? '#23AAAF' : 'transparent'}} 
            //     onClick={(e) => pausar(e)}
            // >   
            //     <Img src={`/img/icono_stop.png`} alt="" width='3.5rem' height="3rem" />
            // </BtnBottom>
            null
            }

            {props.error &&
            <BtnBottom
                disabled={disable}
                onClick={(e) => error(e)}
            >
                <Img src={`/img/icono_error.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }

            {props.aumentar &&
            <BtnBottom
                onClick={(e) => {aumentarContador(e); zeroContadorTerciario(e)}}
            >
                <Img src={`/img/icono_avanzar.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }

            {props.aumentarConCorreccion &&
            <BtnBottom
                onClick={(e) => {aumentarContadorConCorrecion(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_avanzar.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.aumentarSecundario &&
            <BtnBottom
                onClick={(e) => {aumentarContadorSecundario(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_avanzar.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.aumentarTerciario &&
            <BtnBottom
                onClick={(e) => {aumentarContadorTerciario(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_avanzar.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.aumentarCuaternario &&
            <BtnBottom
                onClick={(e) => {aumentarContadorCuaternario(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_avanzar.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
            {props.miniInstrucciones && 
            <p>{props.miniInstrucciones}</p>
            }
            {props.finalizarEjercicio &&
            <BtnBottom
                onClick={(e) => {finalizoEjercicio(e)}}
                disabled={disable}
            >
                <Img src={`/img/icono_avanzar.png`} alt="" width='3.5rem' height="3rem" />
            </BtnBottom>
            }
        </Fragment>
    );
}
 
export default BotoneraBottom;