import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// css
import {LogIn, Ilustracion, Formulario, Cuerpo, Logo, Input, Label, FlexColumn, Form, Titulo, Button, ContenedorLogo, ContenedorForm, Error, Img, ContenedorLogin, ContenedorHeader, ButtonTipo} from '../Css/StyledForm.js';

// Actions de Redux
import { resetPasswordAction } from '../../actions/resetPasswordActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';


const ResetPassword = () => {
    

    // utilizar use dispatch y te crea una función
    const dispatch = useDispatch();

    // Acceder el state del store
    const cargando = useSelector( (state) => state.estado.loading);
    const error = useSelector ( (state) => state.estado.error);
    const alerta = useSelector ( (state) => state.alertas.alerta);
    const [visibilidadPassword, setVisibilidadPassword] = useState(false);

    // state del componente
    const [email, guardarEmail] = useState('');
    const [password, guardarPassword] = useState('');
    const [verificarpassword, guardarVerificarPassword] = useState('');
    const [token, guardarToken] = useState('');
    const [tipoUsuario, setTipoUsuario] = useState('________');

    const definirProfesional = () => {
        setTipoUsuario("Profesional");
    }
    const definirPaciente = () => {
        setTipoUsuario("Paciente");
    }
    const definirTutor = () => {
        setTipoUsuario("Tutor");
    }

    const mostrarPassword = (e) => {
        e.preventDefault();
        if(visibilidadPassword) {
            setVisibilidadPassword(false);
        } else {
            setVisibilidadPassword(true);
        }
        
    }

    // cuando el usuario haga submit
    const submitResetPassword = (e) => {
        e.preventDefault();

        // validar formulario
        if(email.trim() === '') {
            const alerta = {
                msg: 'Falta completar el email'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        if(password !== verificarpassword) {
            const alerta = {
                msg: 'Las contraseñas deben coincidir'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        if(password.length < 8) {
            const alerta = {
                msg: 'La contraseña debe contener al menos 8 caracteres'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        if(password.length > 15) {
            const alerta = {
                msg: 'La contraseña debe contener como mucho 15 caracteres'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const minuscula = new RegExp("(?=.*[a-z])");
        if(!minuscula.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos una minúscula'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const mayuscula = new RegExp("(?=.*[A-Z])");
        if(!mayuscula.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos una mayúscula'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const numero = new RegExp("(?=.*[0-9])");
        if(!numero.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos un número'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }

        if(tipoUsuario === "________") {
            const alerta = {
                msg: 'Elegir tipo de usuario'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }

        // si no hay errores
        dispatch( ocultarAlertaAction());

        // restablece la password
        const informacion = {
            payload: {
                email,
                password,
                token,
                tipoUsuario
            }
        };
        dispatch( resetPasswordAction(informacion) );
    }

    return (
        <ContenedorLogin>
            <ContenedorHeader>
                <ContenedorLogo>
                    <Link to={"/"}>
                        <Logo src={`/img/metodoYogenLogo.png`} alt="" width="20vh"/>
                    </Link>
                </ContenedorLogo>
                <LogIn margin="0 5rem 0 0" to={"/login"}>Login</LogIn>
            </ContenedorHeader>
            <ContenedorForm>
            <Ilustracion src={`/img/ilustracionResetPassword.png`} alt="" width="35vw" ancho="25vw"/>
            
            <Formulario padding="2.5rem" margin="0 7rem 0 0">
                <Titulo>Escribe tu nueva contraseña de <b>{tipoUsuario === "Paciente" ? "Entrenado" : tipoUsuario}</b></Titulo>
                <ButtonTipo onClick={definirProfesional}>Profesional</ButtonTipo>
                <ButtonTipo onClick={definirPaciente}>Entrenado</ButtonTipo>
                <ButtonTipo onClick={definirTutor}>Tutor</ButtonTipo>

                <Form
                    onSubmit={submitResetPassword}
                >
                    <Label padding="2px 12px 2px 7px">
                        <label htmlFor="email">Tu email</label>
                        <Input width="75%"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Tu email"
                            value={email}
                            onChange={e => guardarEmail(e.target.value)}
                        />
                    </Label>
                    <Label padding="2px 12px 2px 7px" margin="7px 0px 0px 0px">
                        <label htmlFor="password">Contraseña</label>
                        <Input width="65%"
                            type={visibilidadPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="nueva Contraseña"
                            autoComplete='new-password'
                            value={password}
                            onChange={e => guardarPassword(e.target.value)}
                        />
                        <Img onClick={e => mostrarPassword(e)} height="1.5rem" width="1.5rem" alt='' src={visibilidadPassword ? `/img/ojo_invisible.png` : `/img/ojo_visible.png`} />
                    </Label>
                    <Label padding="2px 12px 2px 7px" margin="7px 0px 0px 0px">
                        <label htmlFor="verificacion">Confirma tu Contraseña</label>
                        <Input width="50%"
                            type={visibilidadPassword ? "text" : "password"}
                            id="verificacion"
                            name="verificacion"
                            placeholder="Repeti nueva Contraseña"
                            value={verificarpassword}
                            onChange={e => guardarVerificarPassword(e.target.value)}
                        />
                        <Img onClick={e => mostrarPassword(e)} height="1.5rem" width="1.5rem" alt='' src={visibilidadPassword ? `/img/ojo_invisible.png` : `/img/ojo_visible.png`} />
                    </Label>
                    <Label padding="2px 12px 2px 7px" margin="7px 0px 0px 0px">
                        <label htmlFor="token">Coloca el código recibido en tu email</label>
                        <Input width="40%"
                            type="text"
                            id="token"
                            name="token"
                            placeholder="Código"
                            value={token}
                            onChange={e => guardarToken(e.target.value)}
                        />
                    </Label>
                    <Button margin="10px 0px 10px 0px"
                            type="submit"
                    >Modificar contraseña
                    </Button>
                </Form>
                <FlexColumn>

                {cargando ? <p>Cargando...</p> : null }
                {error ? <Error> {error.msg} </Error>: null }
                {alerta ? <p>{alerta.msg}</p> : null }

                    <Button to={"/restablecer-password"}>Recuperar Contraseña</Button>
                </FlexColumn>
            </Formulario>
            </ContenedorForm>
        </ContenedorLogin>
    );
}
 
export default ResetPassword;