import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CantIguales from '../../../Compartidos/CantIguales';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Indica si son suficientes o no.';
const miniInstrucciones = 'Indica si son suficientes o no.';

const M18 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('M18') );
        dispatch(setearUrlAnteriorAction('matematica/estimacion-y-nocion-de-cantidad/nocion-de-cantidad/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])

    return (
        <Fragment>
            <CantIguales w='37vw' wm='30vw' wt='22vw' wd='19vw' wlg='18vw' data={data} indexes={indexes} ejercicio="M18" minMay={false} estrellas={true} carrito={true} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default M18;