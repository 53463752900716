import styled, {css, keyframes} from 'styled-components';
import {createGlobalStyle} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`
// PALABRAS
export const Palabra = styled.p`
    padding: ${props => props.padding};
    background: ${props => props.background};
    border-radius: 10px; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
    margin: ${props => props.margin};
    font-family: nunito;
    font-size: 24px;
` 
export const Texto = styled.p` 
    width: ${props => props.width};
    background: ${props => props.background};
    border-radius: 10px; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
    margin: ${props => props.margin};
    font-family: nunito;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 10px 0px;
`

// CONTENEDORES
export const Flex = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    flex-wrap: ${props => props.wrap};
    margin: ${props => props.margin};
    width: ${props => props.width};
    height: ${props => props.height};
`
export const ContInst = styled.div`
    position: absolute;
    bottom: 40px; 
    left: 25px; 
    text-transform: uppercase; 
    font-family: nunito;
    font-weight: 400;
    font-size: 16px; 
`

// IMAGENES
export const Icon = styled.img`
    position: absolute;
    right: ${props => props.right};
    bottom: ${props => props.bottom};
`
export const ImgBottom = styled.img`
right: ${props => props.right};
bottom: ${props => props.bottom};
    &:hover{
        transform: scale(1.1);
    }
`
export const Icono = styled.img`
    width: 120px;
    margin: ${props => props.margin};
    cursor: pointer;
    ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
    `}
`
export const Ilustracion = styled.img`
    width: ${props => props.width};
    margin: ${props => props.margin};
    ${media.tablet}{
        width: ${props => props.widthTablet};
}
    ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
    `}
`
// ANIMACIONES
export const ShakeImg = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`
export const ZoomIn = keyframes`
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
`
// BOTONES

export const Btn = styled.button`
    border: 0;
    background: transparent; 
    
`
export const Corto = styled.button`
    border: 0;
    background: #FF9B2A;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width:100px;
    height: 70px;
    margin: 0px 0px 0px 0px;
`
export const Largo = styled.button`
    border: 0;
    background: #23AAAF;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 260px;
    height: 70px;
    margin: 0 0 0 1rem;
`
export const ContBtn = styled.div`
    height: 50px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 1rem 0 0 1rem;
    display: flex; 
    align-items: center;
    justify-content: center;
    color: white; 
    font-family: nunito;
    font-size: 18px;
`
export const BtnSelected = styled.button`
    border-radius: 10px; 
    background: transparent;
    border:${props => props.border ? `solid 2px #23AAAF` : `0`};
`
// FORM
export const Input = styled.input`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background: #F1F0F0;
    height: 60px;
    width: 35vw;
    border:0;
    border-radius: 15px;
    text-align:center;
    font-family: nunito; 
    font-size: 18px;
    z-index: 1; 
    ${media.tablet}{
        width: ${props => props.widthTablet};
}
`
