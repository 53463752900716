import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LN8Todos from '../../../Compartidos/LN8Todos';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Resuelve el memotest seleccionando las palabras que son iguales.';
const miniInstrucciones = 'Resuelve el memotest seleccionando las palabras que son iguales.';

const LN8 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN8') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/instruccion-explicita/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])

    return (
        <Fragment>
            <LN8Todos data={data} indexes={indexes} ejercicio="LN8" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default LN8;