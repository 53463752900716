import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

import { setearListaAction } from '../../../../actions/estadoEjercicioActions';

import { FlexLecEsc, InputLecEsc, ButtonEsc, LinksEsc, Links1Al10, ImgB10 } from '../../../Css/StyledMatematica';
import { HrMetodos } from '../../../Css/StyledHeader';

const listas = [
    { id: 1, lista:'unidades', lista_1: 'Unidades', check: false, imagen: 'matematica88' },
    { id: 2, lista:'decenas', lista_1: 'Decenas', check: false, imagen: 'matematica89' },
    { id: 3, lista:'centenas', lista_1: 'Centenas', check: false, imagen: 'matematica90' },
    { id: 4, lista:'unidad_de_mil', lista_1: 'Unidad de Mil', check: false, imagen: 'matematica91' },
    { id: 5, lista:'decenas_de_mil', lista_1: 'Decenas de Mil', check: false, imagen: 'matematica92' },
    { id: 6, lista:'centenas_de_mil', lista_1: 'Centenas de Mil', check: false, imagen: 'matematica93' },
    { id: 7, lista:'millon', lista_1: 'Millón', check: false, imagen: 'matematica94' },
    { id: 8, lista:'todos', lista_1: 'Todos', check: false, imagen: 'matematica95' }
]

const EscrituraMatematica = () => {

    const dispatch = useDispatch();

    const [checkeable, setCheckeable] = useState([]);

    const setearLista = (e, lista) => {
        e.preventDefault();
        dispatch(setearListaAction(lista));
    }

    const checkear = (e, id) => {
        const newList = JSON.parse(JSON.stringify(checkeable));
        if (e.target.checked) {
            newList[id - 1].check = true;
            setCheckeable(newList);
        } else {
            newList[id - 1].check = false;
            setCheckeable(newList);
        }
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(listas));
        setCheckeable(newList)
    }, [])

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"lectura-y-escritura-de-numeros/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"LECTURA Y ESCRITURA DE NUMEROS"} componente={"ESCRITURA"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <FlexLecEsc wrap='wrap' margin='4vh 0vw 0vh 1.3vw' mm='3.5vh 0vw 0vh 4vw' mt='2.8vh 2vw 0vh 10vw' md='2vh 2vw 0vh 12vw' mlg='2.7vh 2vw 0vh 16vw'> 
            {checkeable.map(item => (
            <ButtonEsc
                key={item.id} 
                onClick={(e) => setearLista(e, item.lista)}
            >
                <Links1Al10 to={`/escritorio/metodos/matematica/lectura-y-escritura-de-numeros/escritura/M69`}><ImgB10 src={`/img/portadas/${item.imagen}.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                <LinksEsc>{item.lista_1}</LinksEsc>
                <InputLecEsc
                    type="checkbox"
                    id={item.id}
                    checked={item.check}
                    onChange={(e) => checkear(e, item.id)}/>
            </ButtonEsc>
            ))}
            </FlexLecEsc>
        </Fragment>    
    );
}
 
export default EscrituraMatematica;