import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { resetearConfigPacienteAction } from '../../actions/resetStoreActions.js';

//css
import {Flex, Ubicacion, Div, Enlace, InfoPaciente} from '../Css/StyledHeader.js';

const Header2 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const nombre = useSelector((state) => state.usuario.nombrePaciente);
    const apellido = useSelector((state) => state.usuario.apellidoPaciente);

    const deseleccionarPaciente = (e) => {
        e.preventDefault();
        navigate("/escritorio/pacientes");
        dispatch( resetearConfigPacienteAction() );
    } 

    return ( 
        <Div
            bgcolor={props.bgcolor} 
            shadow={props.shadow} 
            padding={props.padding1} 
            color={props.color}>
            <Flex align="center">
                {props.l4
                    ? <Flex align="center">
                        <Link to={`/escritorio/${props.l1}`}> <img alt='' src={`/img/icono_logo_blanco.png`} /> </Link>
                        <Enlace to={`/escritorio/${props.l1}${props.l2}`}>{props.n2}</Enlace>
                        <Enlace to={`/escritorio/${props.l1}${props.l2}${props.l3}`}>{props.n3}</Enlace>
                        <Enlace to={`/escritorio/${props.l1}${props.l2}${props.l3}${props.l4}`}>{props.n4}</Enlace>
                        <Ubicacion>{props.componente}</Ubicacion>
                    </Flex>
                    : props.l3
                        ? <Flex align="center">
                            <Enlace to={`/escritorio/${props.l1}`}> <img alt='' src={`/img/icono_logo_blanco.png`} /> </Enlace>
                            <Enlace to={`/escritorio/${props.l1}${props.l2}`}>{props.n2}</Enlace>
                            <Enlace to={`/escritorio/${props.l1}${props.l2}${props.l3}`}>{props.n3}</Enlace>
                            <Ubicacion>{props.componente}</Ubicacion>
                        </Flex>
                        : props.l2
                            ? <Flex align="center">
                                <Enlace to={`/escritorio/${props.l1}`}> <img alt='' src={`/img/icono_logo_blanco.png`} /> </Enlace>
                                <Enlace to={`/escritorio/${props.l1}${props.l2}`}>{props.n2}</Enlace>
                                <Ubicacion>{props.componente}</Ubicacion>
                            </Flex>
                            : props.l1
                                ? <Flex align="center">
                                    <Enlace to={`/escritorio/${props.l1}`}> <img alt='' src={`/img/icono_logo_blanco.png`} /> </Enlace>
                                    <Ubicacion>{props.componente}</Ubicacion>
                                </Flex>
                                : props.componente
                                    ? <Flex align="center">
                                        <Ubicacion>{props.componente}</Ubicacion>
                                    </Flex>
                                    : null
                }
                {props.paciente === false
                    ? null
                    : nombre !== ""
                        ? <Flex $justify="flex-end">
                            <InfoPaciente>Paciente: {apellido}, {nombre}</InfoPaciente>
                            <button
                            onClick={(e) => deseleccionarPaciente(e)}
                            >
                                X
                            </button>
                        </Flex>
                        : <Flex $justify="flex-end"><InfoPaciente>Paciente: sin elegir</InfoPaciente></Flex>
                }
            </Flex>
        </Div>
    );
}
 
export default Header2;