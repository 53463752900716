import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

import { Flex, LinksCalculo } from '../../../Css/StyledMatematica';
import { HrMetodos } from '../../../Css/StyledHeader';

const Suma = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"calculo/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"CALCULO"} componente={"SUMA"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex wrap='wrap' margin='16vh 0vw 10vh 0vw' mt='15vh 10vw 10vh 10vw' md='15vh 15vw 10vh 15vw' mlg='13vh 15vw 8vh 15vw'>
                <LinksCalculo to={`/escritorio/metodos/matematica/calculo/suma/m48`}>Presentación</LinksCalculo>
                <LinksCalculo to={`/escritorio/metodos/matematica/calculo/suma/ejercitacion`}>Ejercitacion</LinksCalculo>
                <LinksCalculo to={`/escritorio/metodos/matematica/calculo/suma/calculo-mental`}>Cálculo<br/>Mental</LinksCalculo>
                <LinksCalculo to={`/escritorio/metodos/matematica/calculo/suma/suma-tradicional`}>Suma<br/>Tradicional</LinksCalculo>
                <LinksCalculo to={`/escritorio/metodos/matematica/calculo/suma/suma-descomposicion`}>Suma<br/>Descomposición</LinksCalculo>
            </Flex>
        </Fragment>
    );
}
 
export default Suma;