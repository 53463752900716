import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import BotoneraBottom from '../../Metodos/Compartidos/BotoneraBottom';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction } from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

//css
import { BtnImgSonido, ImgLetra } from '../../Css/MetodosCSS/StyledLN1Todos';
import { ContainerFlexx, BotoneraFixed, DivFlex, Img, SilabasGrandes, SilabasChicas, CuadradoLetra } from '../../Css/MetodosCSS/StyledLectoEscritura';


const LN1 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, instrucciones } = props;

    const [comparar, setComparar] = useState([]);
    const [comparar2, setComparar2] = useState([]);
    const [silaba, setSilaba] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const letra = useSelector(state => state.ejercicio.letra)
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const play1 = () => new Audio(`/sounds/${comparar[0].sonido}.mp3`).play();

    useEffect(() => {
        if (contador === 0) {
            return;
        }
        if (contador < indexes && contador > 0) {
            const newList = JSON.parse(JSON.stringify(comparar));
            newList.shift();
            newList.length = contador;
            setSilaba(newList);
        } else {
            dispatch(finalizarEjercicioAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contador])

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        setComparar(data);
        setComparar2(data[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar])

    useEffect(() => {
        if(!finalizar) {
            return;
        }
        const informacion = {
            config,
            payload: {
            'ejercicio': 'repaso',
            'id_paciente': id_paciente,
            sesion
            }
        }
        dispatch( almacenarAction(informacion) );
        navigate("/escritorio/metodos/finalizar-ejercicio");
    }, [finalizar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} />
            {iniciar
                ?
                <ContainerFlexx height="85vh">
                    {contador === 0 &&
                        <DivFlex height='70vh' width='90vw' wm='87vw' wt='80vw' wd='75vw' wlg='75vw' justify='space-between'>
                            <DivFlex height='100%' direction='column' justify='space-evenly' align='center'>
                                <CuadradoLetra  $minMay={minuscula}>
                                    {letra.toLowerCase() === 'r_suave' || letra.toLowerCase() === 'c' ? 
                                    <i>{comparar2.silaba}</i>
                                    :
                                    comparar2.silaba
                                    }
                                    <ImgLetra>
                                        {!comparar2.seteo_imagen_letra ? null :
                                        <Img w="70px" h="50px" src={`/img/${comparar2.imagen_letra}.png`} />
                                        }
                                    </ImgLetra>
                                </CuadradoLetra>
                                <BtnImgSonido 
                                onClick={() => play1()}
                                >
                                    <Img w="3.5rem" h="3.5rem"  src={`/img/altavoz.png`} />
                                </BtnImgSonido>
                            </DivFlex>
                            <DivFlex height='100%' justify='center' align='center'>
                                {!comparar2.seteo_imagen_letra 
                                ?
                                    <Img w="15vw" h="30vh" src={`/img/${comparar2.imagen_letra}.png`} />
                                :
                                    <Img w="22vw" wm='21vw' wt='16vw' wd='14vw' wlg='12vw' h="36vh" hm='36vh' ht='41vh' hd='43vh' hlg='40vh' src={`/img/${comparar2.imagen_boca}.png`} />
                                }
                            </DivFlex>
                        </DivFlex>
                    }
                    {contador > 0 &&
                        <DivFlex height="75vh" width='90vw' wm='90vw' wt='77vw' wd='76vw' wlg='75vw' justify='space-between' align='center'>
                            <DivFlex direction='column' justify='center' align='center'>
                                <DivFlex>
                                    <SilabasGrandes color="#646464" m='0px 1vw 0px 0px' $minMay={minuscula}>
                                        {letra.toLowerCase() === 'r_suave' || letra.toLowerCase() === 'c' ? 
                                        <i>{comparar[contador].silaba}</i>
                                        :
                                        comparar[contador].silaba
                                        }
                                    </SilabasGrandes>
                                    <SilabasGrandes color="rgba(69, 178, 182, 1)" $minMay={minuscula}>
                                        <span style={comparar[contador].myStyle}>{comparar[contador].segunda_letra}</span>
                                    </SilabasGrandes>
                                </DivFlex>
                                <DivFlex width='100%' justify='space-around' align='center'>
                                    <Img w="13vw" wm='12vw' wt='9vw' wd='8vw' wlg='7vw'  h="18vh" src={`/img/${comparar[contador].imagen_1}.png`} />
                                    <Img w="13vw" wm='12vw' wt='9vw' wd='8vw' wlg='7vw'  h="9vh" src={`/img/${comparar[contador].imagen_2}.png`} />
                                </DivFlex>
                            </DivFlex>

                            <DivFlex direction='column' width='26vw' wm='23vw' wt='18vw' wd='15vw' wlg='15vw'>
                                {silaba.map(item => (
                                    <SilabasChicas $minMay={minuscula}
                                        key={item.id}
                                    >
                                        {letra.toLowerCase() === 'r_suave' || letra.toLowerCase() === 'c' ? 
                                        <i>{item.primera_letra}</i>
                                        :
                                        item.primera_letra
                                        }<span style={item.myStyle}>{item.segunda_letra}</span>
                                    </SilabasChicas>
                                ))}
                            </DivFlex>
                        </DivFlex>
                    }

                    <BotoneraFixed position='fixed' right='0' bottom='0'>
                        {contador === 0 &&
                            <BotoneraBottom aumentar={true} />
                        }
                        {contador > 0 && contador < indexes - 1 &&
                            <BotoneraBottom aumentar={true} reducir={true} />
                        }
                        {contador > indexes - 2 &&
                            <BotoneraBottom reducir={true} aumentarConCorreccion={true} cantidad={indexes-(indexes-1)} />
                        }
                    </BotoneraFixed>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN1;