import styled from 'styled-components';
const media = {
    tablet: '@media (max-width:768px)'
}

export const Container = styled.div `
display: ${(props) => props.display};
flex-direction: ${(props) => props.direction};
background-color: ${(props) => props.bg};
height: ${(props) => props.height};
margin-top: ${(props) => props.margintop};
font-family: nunito;
font-weight: bold;

 ${media.tablet} {
  margin-top: ${(props) => props.margintopmob};
 }

`

export const ContainerElementos = styled.div `
display: ${(props) => props.display};
justify-content: space-around;
align-items: end;
background-color: ${(props) => props.bg};
font-size: 10rem;
font-family: nunito;
font-weight: bold;
 ${media.tablet}{
  font-size: 10rem;
 }
`

export const CuadradoImgLetra = styled.div `
 background-color: #FAFAFA;
 box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
 color: #646464;
 padding: 35px 75px;
 margin-top: 40px;
 border-radius: 15px;
 position: relative;
 font-size: 10rem;
 text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
  ${media.tablet} {
    padding: 15px 55px;
    font-size: 9rem;
  }

`

export const ImgLetra = styled.div `
    position: absolute;
    top: 90px;
    right: 15px;

    ${media.tablet}{
      top: 65px;
    right: 15px;
   }
`

export const ImgBoca = styled.div `
  margin-top: 50px;

  ${media.tablet}{
     img {
       width: 140px;
     };
   }

`

export const BtnImgSonido = styled.button `
height: ${props => props.height};
width: ${props => props.width};
margin: ${props => props.margin};
cursor: pointer;
background-color: transparent;
border: none;

  ${media.tablet} {
    margin: 40px 0 0 190px;
  }
`

export const Botonera = styled.div `
 display: flex;
 justify-content: end;
 align-items: end;
 margin: 25px 0px 0 0;
 cursor: pointer;
 height: 15vh;
`

export const SilabaImg = styled.div `
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
  top: ${(props) => props.top};
  width: ${(props) => props.width};
  font-size: 120px;
  padding: ${(props) => props.padding};
  font-family: nunito;
  font-weight: bold;
/*   
  div {
    display: flex;
 }
  div p {
    padding: 10px 40px;
    color: #646464;
    background-color: #FAFAFA;
    margin-right: 1rem;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
    
   div p:nth-child(2) {
     color: #45B2B6;
   } */

  ${media.tablet} {
    /* div p {
      padding: 5px 25px;
      font-size: 100px;
      color: #646464;
      background-color: #FAFAFA;
      margin-right: 1rem;
      border-radius: 10px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    } */
  }
`


export const Silabas = styled.div`
display: ${(props) => props.display};
flex-direction: ${(props) => props.direction};
align-items: ${(props) => props.align};
justify-content: ${(props) => props.justify};
margin: ${(props) => props.margin};
width: ${(props) => props.width};
height: ${(props) => props.height};
background-color: ${(props) => props.bg};
box-shadow: ${(props) => props.shadow};
border-radius: ${(props) => props.radius};
font-family: 'Nunito';
font-style: normal;
font-weight: 800;
color: ${(props) => props.color};
text-align: center;
font-size: ${(props) => props.font};

${media.tablet} {
  
}

 /* div {
 text-align: center;
  width: 140px;
  height: 90px;
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;


 
}  */

`
 