import React, {Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import AyudaEstructuraOperaciones from '../AyudaEstructuraOperaciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, InputM83Bis, ImgB10, ImgDivM63, Container, DivM83, AvanzarBtn, AvanzarImg, Lateral, BtnImgM64, InputCuadrado, InputRedondo, NumberDivR, NumberDivL, ImgDiv, NumCalculoM83 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la suma.';
const miniInstrucciones = 'Resuelve la suma.';

const M64 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [complejo, setComplejo] = useState(false);
    const [tipoPasoTres, setTipoPasoTres] = useState('');
    const [count, setCount] = useState(1);
    const [countPaso, setCountPaso] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState(0);
    const [digitoD1, setDigitoD1] = useState(0);
    const [digitoU2, setDigitoU2] = useState(0);
    const [digitoD2, setDigitoD2] = useState(0);
    const [digitoIntermedioUno, setDigitoIntermedioUno] = useState(0);
    const [digitoIntermedioUnoA, setDigitoIntermedioUnoA] = useState(0);
    const [digitoIntermedioUnoB, setDigitoIntermedioUnoB] = useState(0);
    const [digitoIntermedioDos, setDigitoIntermedioDos] = useState(0);
    const [digitoIntermedioDosA, setDigitoIntermedioDosA] = useState(0);
    const [digitoFinalUno, setDigitoFinalUno] = useState(0);
    const [digitoFinalDos, setDigitoFinalDos] = useState(0);
    const [numeroUnidadUno, setNumeroUnidadUno] = useState(0);
    const [numeroUnidadDos, setNumeroUnidadDos] = useState(0);
    const [numeroDecenaUno, setNumeroDecenaUno] = useState(0);
    const [numeroDecenaDos, setNumeroDecenaDos] = useState(0);
    const [numeroIntermedioDos, setNumeroIntermedioDos] = useState(0);
    const [numeroIntermedioDosA, setNumeroIntermedioDosA] = useState(0);
    const [numeroIntermedioUno, setNumeroIntermedioUno] = useState(0);
    const [numeroIntermedioUnoA, setNumeroIntermedioUnoA] = useState(0);
    const [numeroIntermedioUnoB, setNumeroIntermedioUnoB] = useState(0);
    const [numeroFinalUno, setNumeroFinalUno] = useState(0);
    const [numeroFinalDos, setNumeroFinalDos] = useState(0);
    const [numeroFinal, setNumeroFinal] = useState(0);
    const [pasoUno, setPasoUno] = useState(false);
    const [pasoDos, setPasoDos] = useState(false);
    const [pasoTres, setPasoTres] = useState(false);
    const [pasoCuatro, setPasoCuatro] = useState(false);
    const [pasoCinco, setPasoCinco] = useState(false);
    const [pasoSeis, setPasoSeis] = useState(false);
    const [pasoSiete, setPasoSiete] = useState(false);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarErrorEstructura, setContarErrorEstructura] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const asignar = (e, tipo) => {
        e.preventDefault();
        if(complejo === false){
            if(countPaso === 1){
                if(tipo === 'cereza') {
                    setPasoUno(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 2){
                if(tipo === 'cereza') {
                    setPasoDos(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 3){
                if(tipo === 'resultado') {
                    setPasoTres(true);
                    setCountPaso(countPaso + 1);
                    setTipoPasoTres('resultado');
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso > 3){
            }
        }
        if(complejo === true){
            if(countPaso === 1){
                if(tipo === 'cereza') {
                    setPasoUno(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 2){
                if(tipo === 'cereza') {
                    setPasoDos(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 3){
                if(tipo === 'circulo') {
                    setPasoTres(true);
                    setCountPaso(countPaso + 1);
                    setTipoPasoTres('circulo');
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 4){
                if(tipo === 'circulo') {
                    setPasoCuatro(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 5){
                if(tipo === 'cereza') {
                    setPasoCinco(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 6){
                if(tipo === 'circulo') {
                    setPasoSeis(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 7){
                if(tipo === 'resultado') {
                    setPasoSiete(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
        }
    }

    const verificar = (e) => {
        e.preventDefault();
        if(complejo === true) {
            if(tipoPasoTres === 'resultado') {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setNumeroUnidadUno(0);
                    setNumeroDecenaUno(0);
                    setNumeroUnidadDos(0);
                    setNumeroDecenaDos(0);
                    setNumeroFinalUno(0);
                    setNumeroFinalDos(0);
                    setNumeroIntermedioUno(0);
                    setNumeroIntermedioDos(0);
                    setNumeroIntermedioUnoB(0);
                    setNumeroIntermedioUnoA(0);
                    setNumeroIntermedioDosA(0);
                    setNumeroFinal(0);
                }, 300);
            }
    
            if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || digitoIntermedioUno.toString() !== numeroIntermedioUno.toString() || digitoIntermedioDos.toString() !== numeroIntermedioDos.toString() || digitoIntermedioUnoB.toString() !== numeroIntermedioUnoB.toString() || digitoIntermedioUnoA.toString() !== numeroIntermedioUnoA.toString() || digitoIntermedioDosA.toString() !== numeroIntermedioDosA.toString()) {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || digitoIntermedioUno.toString() !== numeroIntermedioUno.toString() || digitoIntermedioDos.toString() !== numeroIntermedioDos.toString() || digitoIntermedioUnoB.toString() !== numeroIntermedioUnoB.toString() || digitoIntermedioUnoA.toString() !== numeroIntermedioUnoA.toString() || digitoIntermedioDosA.toString() !== numeroIntermedioDosA.toString()) {
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                        setNumeroFinalUno(0);
                        setNumeroFinalDos(0);
                        setNumeroIntermedioUno(0);
                        setNumeroIntermedioDos(0);
                        setNumeroIntermedioUnoB(0);
                        setNumeroIntermedioUnoA(0);
                        setNumeroIntermedioDosA(0);
                        setNumeroFinal(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                        setNumeroFinalUno(0);
                        setNumeroFinalDos(0);
                        setNumeroFinal(0);
                        setDigitoU1(0);
                        setDigitoD1(0);
                        setDigitoU2(0);
                        setDigitoD2(0);
                        setDigitoFinalUno(0);
                        setDigitoFinalDos(0);
                        setNumeroIntermedioUno(0);
                        setNumeroIntermedioDos(0);
                        setNumeroIntermedioUnoB(0);
                        setNumeroIntermedioUnoA(0);
                        setNumeroIntermedioDosA(0);
                        setTipoPasoTres('');
                        setCountPaso(1);
                        setPasoUno(false);
                        setPasoDos(false);
                        setPasoTres(false);
                        setPasoCuatro(false);
                        setPasoCinco(false);
                        setPasoSeis(false);
                        setPasoSiete(false);
                        setComplejo(false);
                        setCount(count + 1);
                    }, 300);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch( finalizarEjercicioAction() );
                    }, 1500);
                }
            }
        } else {
            if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numeroFinal.toString()) {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numeroFinal.toString()) {
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                        setNumeroFinalUno(0);
                        setNumeroFinalDos(0);
                        setNumeroFinal(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                        setNumeroFinalUno(0);
                        setNumeroFinalDos(0);
                        setNumeroFinal(0);
                        setDigitoU1(0);
                        setDigitoD1(0);
                        setDigitoU2(0);
                        setDigitoD2(0);
                        setDigitoFinalUno(0);
                        setDigitoFinalDos(0);
                        setTipoPasoTres('');
                        setCountPaso(1);
                        setPasoUno(false);
                        setPasoDos(false);
                        setPasoTres(false);
                        setPasoCuatro(false);
                        setPasoCinco(false);
                        setPasoSeis(false);
                        setPasoSiete(false);
                        setComplejo(false);
                        setCount(count + 1);
                    }, 300);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch( finalizarEjercicioAction() );
                    }, 1500);
                }
            }
        }
    }

    useEffect(() => {
        const min = 10;
        const max = 79;
        const randomNumeroUno = Math.floor(Math.random() * (max - min +1)) + min;
        const randomNumeroDos = (function () {
            const d1 = Number(randomNumeroUno.toString().substring(0,1));
            const minU = 0;
            const maxU = 9;
            const u2 = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 1;
            const maxD = 8-d1;
            const d2 = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d2.toString().concat(u2.toString()));
            return numero;
        })();
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        const pasoUnoU2 = newList.cantidad_1.toString().substring(1,2);
        const pasoUnoU1 = newList.cantidad_2.toString().substring(1,2);
        const pasoUnoD2 = (Number(newList.cantidad_1.toString().substring(0,1))*10).toString();
        const pasoUnoD1 = (Number(newList.cantidad_2.toString().substring(0,1))*10).toString();
        const pasoDosU1 = (Number(pasoUnoU1) + Number(pasoUnoU2)).toString();
        const pasoDosD1 = (Number(pasoUnoD1) + Number(pasoUnoD2)).toString();
        const pasoTresD1 = pasoDosD1;
        const pasoTresD2 = (Number(pasoDosU1.substring(0,1))*10).toString();
        const pasoTresU2 = pasoDosU1.substring(1,2);
        const pasoCuatroD1 = (Number(pasoTresD1) + Number(pasoTresD2)).toString();
        const pasoCuatroU1 = pasoTresU2;
        
        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(pasoUnoU1);
            setDigitoD1(pasoUnoD1);
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(pasoUnoU2);
            setDigitoD2(pasoUnoD2);
        }
        if(pasoDosU1 > 9){
            setComplejo(true);
            setTipoEjercicio('suma_descomposicion_compleja');
            setDigitoIntermedioUno(pasoDosU1);
            setDigitoIntermedioUnoA(pasoTresD2);
            setDigitoIntermedioUnoB(pasoTresU2);
            setDigitoIntermedioDos(pasoDosD1);
            setDigitoIntermedioDosA(pasoTresD1);
            setDigitoFinalUno(pasoCuatroD1);
            setDigitoFinalDos(pasoCuatroU1);
        } else {
            setTipoEjercicio('suma_descomposicion');
            setDigitoFinalUno((Number(pasoUnoD1) + Number(pasoUnoD2)).toString());
            setDigitoFinalDos((Number(pasoUnoU1) + Number(pasoUnoU2)).toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M64') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/suma-descomposicion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M64",
                    'errores': contarErrores,
                    'error_estructura': contarErrorEstructura,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'ayuda_visual': ayuVisualSolicitada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);
    
    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment
                isCorrect={isCorrect}
                isIncorrect={isIncorrect}
                >
                    <div>
                        {/* <div>
                            {comparar.length === 0 ? null :
                            <div>
                                <div>{comparar[count-1].cantidad_1}</div> + <div>{comparar[count-1].cantidad_2}</div> = 
                                {pasoSiete === true || tipoPasoTres === 'resultado' ?
                                <div>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <input type="number" min="0" max="10000" value={numeroFinal} disabled={true} />
                                        Final
                                    </form>
                                </div>
                                : null
                                }
                            </div>
                            }
                        </div> */}

                    <FlexFull justify='space-evenly' align='center' height='78vh'> {/*  border='2px solid blue' */} 
                        <FlexFull direction='column' justify='space-around' align='start' width='48vw' height='60vh' hlg='70vh'> {/*  border='2px solid red' */} 
                            <FlexFull direction='column' justify='center' align='start' width='48vw'>
                            {/* CALCULO */}
                            <FlexFull justify='end' align='center' width='100%'> {/*  border='2px solid blue' */}
                                {comparar.length === 0 ? null : 
                                    <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                        <NumCalculoM83 color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculoM83>
                                        <NumCalculoM83 color='#646464'>+</NumCalculoM83>                                     
                                        <NumCalculoM83 color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculoM83>
                                        <NumCalculoM83 color='#646464'>=</NumCalculoM83>  
                                        <NumCalculoM83 color='#646464'>       
                                        {pasoSiete === true || tipoPasoTres === 'resultado' ?
                                            <form onSubmit={(e) => onSubmit(e)}>
                                                <InputM83Bis type="text" value={numeroFinal} disabled={true} />
                                            </form>
                                            : null
                                        }
                                        </NumCalculoM83>  
                                    </FlexFull>
                                    
                                }
                            </FlexFull>
                            {/* FIN CALCULO */}
                            {/* <div> */}
                            {/* DESCOMPOSICIÓN */}
                        <FlexFull justify='space-around' align='center'  minh='25vh' margin='-11vh 0vw 0vh 0px' mm='-54px 0vw 0vh 0px' mt='-44px 0vw 0vh -5px' md='-39px 0vw 0vh -5px' mlg='-22px 0vw 0vh 15px'> {/* border='3px solid blue' */}
                            {pasoUno === true &&
                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 2vw 0vh 0vw' mt='0px 1.5vw 0vh 0px' md='0px 1.2vw 0vh 0px' mlg='0px 2.3vw 0vh 0px'> { /* border='3px solid violet'     mt='0vh 1.5vw 0vh 0vw' mlg='0px' */}
                                <div>
                                    <ImgDiv>
                                        <ImgB10 src={`/img/lineasDescomposicion.png`}  w='55%' h='12vh' hm='12vh' hlg='15vh' />
                                    </ImgDiv>
                                    <Flex>
                                        <NumberDivL>
                                                <InputRedondo type="text" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disable} />
                                        </NumberDivL>
                                        <NumberDivR>
                                                <InputCuadrado type="text" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disable} />
                                        </NumberDivR>
                                    </Flex>
                                </div>
                            </Flex>
                            }
                            {pasoDos === true &&
                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 0vw 0vh 2vw' mlg='0px'>  { /*   margin='0vh 0vw 0vh 4vw'   border='1px solid green' */}
                                <div>
                                    <ImgDiv>
                                        <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='12vh' hm='12vh' hlg='15vh' /> {/* h='14vh' hm='14vh' hlg='17vh' */}
                                    </ImgDiv>
                                    <Flex>
                                        <NumberDivL>
                                            <InputRedondo type="text" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disable} />
                                        </NumberDivL>
                                        <NumberDivR>
                                            <InputCuadrado type="text" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disable} />
                                        </NumberDivR>
                                    </Flex>
                                </div>
                            </Flex>
                            }
                        </FlexFull>
                            {/* {pasoUno === true &&
                            <div>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disable} />
                                    A
                                </form>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disable} />
                                    B
                                </form>
                            </div>
                            }
                            {pasoDos === true &&
                            <div>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disable} />
                                    C
                                </form>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disable} />
                                    D
                                </form>
                            </div>
                            } */}




                            {/* {pasoTres === false ? null : tipoPasoTres === 'resultado' ?
                            <div>
                                <div>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <input type="number" min="0" max="10000" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                        J
                                    </form>
                                </div>
                                <div>+</div>
                                <div>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <input type="number" min="0" max="10000" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} />
                                        K
                                    </form>
                                </div>
                                <div>=</div>
                                <div>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <input type="number" min="0" max="10000" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                        L
                                    </form>
                                </div>
                            </div>
                            :
                            <div>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroIntermedioUno} onChange={(e) => setNumeroIntermedioUno(e.target.value)} disabled={disable} />
                                    F
                                </form>
                            </div>
                            } */}

                            <FlexFull justify='space-evenly' align='center' width='32.3vw' minw='32.3vw' margin='-27px 0vw 0vh 0px' mm='-25px 0vw 0vh 0px' mt='-23px 0vw 0vh -5px' md='-20px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px'>
                                <Flex>
                                    {pasoCuatro === true && complejo === true &&
                                        <Container minw='50%' dis='flex' justify='center' align='center'>
                                            <InputRedondo type="text" value={numeroIntermedioDos} onChange={(e) => setNumeroIntermedioDos(e.target.value)} disabled={disable} />
                                        </Container>
                                    }
                                </Flex>
                                <Flex>
                                    {pasoTres === false ? null : tipoPasoTres === 'resultado' ?
                                    <FlexFull justify='center' align='center' width='100%'>
                                        <FlexFull justify='space-around' align='center'> 
                                            <DivM83>
                                                <div>
                                                    <InputRedondo type="text" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                                </div>
                                            </DivM83>
                                            <DivM83>
                                                <NumCalculoM83 color='#646464'>+</NumCalculoM83>
                                            </DivM83>
                                            <DivM83>
                                                <div>
                                                    <InputCuadrado type="text" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} />
                                                </div>
                                            </DivM83>
                                            <DivM83>
                                                <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                            </DivM83>
                                            <DivM83>
                                            <InputCuadrado type="text" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                            </DivM83>
                                        </FlexFull>
                                    </FlexFull>
                            
                                    :
                                    // <div>
                                    //     <form onSubmit={(e) => onSubmit(e)}>
                                    //         <input type="number" min="0" max="10000" value={numeroIntermedioUno} onChange={(e) => setNumeroIntermedioUno(e.target.value)} disabled={disable} />
                                    //         F
                                    //     </form>
                                    // </div>

                                    <Container minw='50%' dis='flex' justify='center' align='center'>
                                        <InputCuadrado type='text' value={numeroIntermedioUno} onChange={(e) => setNumeroIntermedioUno(e.target.value)} disabled={disable} m='0px 10px 20px 0px' mm='0px 0px 20px 0px' mt='0px 0px 20px 0px' md='0px 0px 15px 0px' mlg='0px 0px 0px 30px' />
                                    </Container>
                                    }
                                </Flex>
                            </FlexFull>

                            {/* {pasoCuatro === true && complejo === true &&
                            <div>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroIntermedioDos} onChange={(e) => setNumeroIntermedioDos(e.target.value)} disabled={disable} />
                                    E
                                </form>
                            </div>
                            } */}
                            


                            {/* {pasoCinco === true && complejo === true &&
                            <div>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroIntermedioUnoA} onChange={(e) => setNumeroIntermedioUnoA(e.target.value)} disabled={disable} />
                                    H
                                </form>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroIntermedioUnoB} onChange={(e) => setNumeroIntermedioUnoB(e.target.value)} disabled={disable} />
                                    I
                                </form>
                            </div>
                            }
                            {pasoSeis === true && complejo === true &&
                            <div>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input type="number" min="0" max="10000" value={numeroIntermedioDosA} onChange={(e) => setNumeroIntermedioDosA(e.target.value)} disabled={disable} />
                                    G
                                </form>
                            </div>
                            } */}


                            {/* width='32.3vw' */}
                            <FlexFull justify='end' align='center' width='30vw' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' margin='53px 0vw 0vh 0px' mm='38px 0vw 0vh 0px' mt='23px 0vw 0vh -5px' md='3px 0vw 0vh -5px' mlg='-5px 0vw 5px 15px'>  { /* width='32.3vw' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' margin='0vh 0vw 0vh 0px' mm='0px 0vw 0vh 0px' mt='0px 0vw 0vh -5px' md='0px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px' border='2px solid green' */}
                                {/* <FlexFull direction='column' minw='50%' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' justify='end' align='center' padding='0px' margin='0px 15px 40px 0px' mm='0px 0px 45px 0px' mt='0px 0px 45px 0px' md='0px 0px 35px 0px' mlg='0px 0px 0px 0px'> */}
                                <FlexFull direction='column' minw='50%' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' justify='end' align='center' padding='0px' margin='0px 0px 40px 0px' mm='0px -20px 45px 0px' mt='0px -24px 45px 0px' md='0px -26px 35px 0px' mlg='0px -30px 0px 0px'>
                                    {pasoSeis === true && complejo === true &&
                                        <FlexFull>
                                            <InputRedondo type="text" value={numeroIntermedioDosA} onChange={(e) => setNumeroIntermedioDosA(e.target.value)} disabled={disable} />
                                        </FlexFull>
                                    }
                                </FlexFull>
                                <FlexFull justify='end' align='end' minw='50%' minh='100%' maxh='100%'>
                                    <div>
                                        <div>
                                        {pasoCinco === true && complejo === true &&
                                            <FlexFull direction='column' minw='50%' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' justify='end' align='center' padding='0px' margin='0px -20px 0px 5px' mm='0px -16px 0px 0px' mt='0px 0px 0px 5px' md='0px 0px 0px 5px' mlg='0px 0px 0px 5px'>
                                                <ImgDivM63>
                                                    <ImgB10 src={`/img/lineasDescomposicion.png`} w='45%' h='12vh' margin='40px 0px 0px 0px' mm='50px 0px 0px -5px' mt='50px 0px 0px -3px' md='50px 0px 0px -12px' mlg='50px 0px 0px -5px' z='-1' />
                                                </ImgDivM63>
                                                <Flex minw='100%'> 
                                                            <NumberDivL>
                                                                    <InputRedondo type="text" value={numeroIntermedioUnoA} onChange={(e) => setNumeroIntermedioUnoA(e.target.value)} disabled={disable} />
                                                            </NumberDivL>
                                                            <NumberDivR>
                                                                    <InputCuadrado type="text" value={numeroIntermedioUnoB} onChange={(e) => setNumeroIntermedioUnoB(e.target.value)} disabled={disable} />
                                                            </NumberDivR>
                                                </Flex>
                                            </FlexFull>
                                        }
                                        </div>
                                    </div>
                                </FlexFull>
                            </FlexFull>
                            {/* FIN DESCOMPOSICIÓN */}
                            </FlexFull>
                            {/* {pasoSiete === true && complejo === true &&
                            <div>
                                <div>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <input type="number" min="0" max="10000" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                        J
                                    </form>
                                </div>
                                <div>+</div>
                                <div>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <input type="number" min="0" max="10000" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} />
                                        K
                                    </form>
                                </div>
                                <div>=</div>
                                <div>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <input type="number" min="0" max="10000" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                        L
                                    </form>
                                </div>
                            </div>
                            } */}
                            {/* CALCULO ABAJO */}    
                            {pasoSiete === true && complejo === true &&
                            <FlexFull justify='center' align='center' width='100%'>
                                <FlexFull justify='space-around' align='center'> 
                                    <DivM83>
                                        <div>
                                            <InputRedondo type="text" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                        </div>
                                    </DivM83>
                                    <DivM83>
                                        <NumCalculoM83 color='#646464'>+</NumCalculoM83>
                                    </DivM83>
                                    <DivM83>
                                        <div>
                                            <InputCuadrado type="text" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} />
                                        </div>
                                    </DivM83>
                                    <DivM83>
                                        <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                    </DivM83>
                                    <DivM83>
                                    <InputCuadrado type="text" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                    </DivM83>
                                </FlexFull>
                            </FlexFull>
                            }   
                            {/* FIN CALCULO ABAJO */}
                        </FlexFull>
                        <AyudaEstructuraOperaciones tipo={tipoEjercicio} />

                        <Lateral>
                            <Flex direction='column'>
                                <BtnImgM64 w='100px' h='70px' m='2px -1px 2px 5px'
                                    disabled={disable}
                                    onClick={(e) => asignar(e, 'cereza')}
                                >
                                    <ImgB10 src={`/img/cerezaa.png`} w='100px' h='70px' bradius='20px' />
                                </BtnImgM64>
                                <BtnImgM64 w='70px' h='70px' m='2px -1px 2px 5px'
                                    disabled={disable}
                                    onClick={(e) => asignar(e, 'circulo')}
                                >
                                    <ImgB10 src={`/img/circulo.png`} w='70px' h='65px' bradius='20px' />
                                </BtnImgM64>
                                <BtnImgM64 w='70px' h='70px' m='2px -1px 2px 5px'
                                    disabled={disable}
                                    onClick={(e) => asignar(e, 'cuadrado')}
                                >
                                    <ImgB10 src={`/img/rectangulo.png`} w='70px' h='65px' bradius='20px' />
                                </BtnImgM64>
                                <BtnImgM64 w='100px' h='70px' m='2px -1px 2px 5px'
                                    disabled={disable}
                                    onClick={(e) => asignar(e, 'resultado')}
                                >
                                    <ImgB10 src={`/img/resultado.png`} w='100px' h='65px' bradius='20px' />
                                </BtnImgM64>
                            </Flex>
                        </Lateral>
                        </FlexFull>
                        
                    </div>

                    {/* {pasoSiete === true && complejo === true &&
                    <div>
                        <div>{miniInstrucciones}</div>
                        <button
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            boton avanzar
                        </button>
                    </div>
                    } */}
                    {/* INSTRUCCIONES */}
                    {pasoSiete === true && complejo === true &&
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <div>{miniInstrucciones}</div>
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    }
                    {/* {pasoTres === true && tipoPasoTres === 'resultado' &&
                    <div>
                        <div>{miniInstrucciones}</div>
                        <button
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                            >
                            boton avanzar
                        </button>
                    </div>
                    } */}
                    {pasoTres === true && tipoPasoTres === 'resultado' &&
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <div>{miniInstrucciones}</div>
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                            >
                            <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    }
                    {/* FIN INSTRUCCIONES */}
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M64;