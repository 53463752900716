import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clienteAxios from '../../config/axios';

import useConfigAuth from '../../Hooks/useConfigAuth';

import { setearAlerta } from '../../actions/alertaActions';

import { Table2, Th, Tr, Td1, Thead, BotonPacienteDos, Flex, Input } from '../Css/StyledPatient';

const PagoRechazado = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [cobrosRechazados, setCobrosRechazados] = useState([]);
    const [suscripcion, setSuscripcion] = useState('');

    const buscarPagosRechazados = async (e) => {
        e.preventDefault();
        try {
            setDisable(true)
            const payload = {};
            const informacion = await clienteAxios.post('/usuarios/buscarpagosrechazados/mobbex', payload, config);
            setCobrosRechazados(informacion.data);
            setDisable(false);
        } catch (error) {
            setDisable(false);
            console.log(error.response)
        }
    }

    const solicitarPagoMasivo = async (e) => {
        e.preventDefault();
        try {
            setDisable(true);
            const payload = {
                suscripcion
            }
            const {data} = await clienteAxios.post('/usuarios/masivosuscriptores/mobbex', payload, config);
            const alerta = {
                msg: data.msg
            }
            dispatch( setearAlerta (alerta) );
            setDisable(false);
            setSuscripcion('');
        } catch (error) {
            setDisable(false);
            setSuscripcion('');
            console.log(error.response)
        }
        
    }

    return (
        <Flex direccion="column">
            <Flex margin="3rem 0 0rem 0">
                <BotonPacienteDos
                    disabled={disable}
                    onClick={(e) => buscarPagosRechazados(e)}
                >
                    Traer Pagos Rechazados
                </BotonPacienteDos>
            </Flex>
            <Flex direccion="column" margin="3rem 0 3rem 0">
                <Table2>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>UID Suscripcion</Th>
                            <Th>UID Suscriptor</Th>
                            <Th>Intentos</Th>
                            <Th>Último intento</Th>
                            <Th>ID Profesional</Th>
                        </Tr>
                    </Thead>
                    <tbody>
                        {cobrosRechazados?.map( item => 
                        <Tr textalign="center"
                            key={item.id}
                        >
                            <Td1>{item.id}</Td1>
                            <Td1>{item.id_suscripcion}</Td1>
                            <Td1>{item.id_suscriptor}</Td1>
                            <Td1>{item.intentos}</Td1>
                            <Td1>{item.fecha_cobro}</Td1>
                            <Td1>{item.profesional_id}</Td1>
                        </Tr>
                        )}
                    </tbody>
                </Table2>
            </Flex>
            <Flex margin="2rem 0 0 0">
                <form
                    onSubmit={(e) => solicitarPagoMasivo(e)}
                >  
                    <label style={{width: "5rem"}}htmlFor="text">Que suscripcion?</label>
                    <Input width="6rem" radius="10px"
                        type="text"
                        value={suscripcion || ""}
                        onChange={e => setSuscripcion(e.target.value)}
                    />
                    <BotonPacienteDos
                        type="submit"
                    >
                        Pago Masivo
                    </BotonPacienteDos>
                </form>
            </Flex>
        </Flex>
    );
}
 
export default PagoRechazado;