import {
    CONSEGUIR_INFORMACION_PACIENTE,
    CONSEGUIR_INFORMACION_PACIENTE_EXITO,
    CONSEGUIR_INFORMACION_PACIENTE_ERROR
} from '../types';
import clienteAxios from '../config/axios';

/* Solicito Pasarse de un profesional a otro al paciente */
export function conseguirInformacionPacienteAction(email) {
    return async (dispatch) => {
        dispatch (conseguirInformacionPaciente());
        try {
            const respuesta = await clienteAxios.post('/solicitoinformacion', email); /*Vicky ver endpoint*/
            dispatch( conseguirInformacionPacienteExito(respuesta.data.informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( conseguirInformacionPacienteError(error.response.data) );
            }
        }
    }
}

const conseguirInformacionPaciente = () => ({
    type: CONSEGUIR_INFORMACION_PACIENTE,
    payload: true
});

// si logro obtener la informacion de la BD
const conseguirInformacionPacienteExito = (exito) => ({
    type: CONSEGUIR_INFORMACION_PACIENTE_EXITO,
    payload: exito
});

// si hubo un error
const conseguirInformacionPacienteError = (msg) => ({
    type: CONSEGUIR_INFORMACION_PACIENTE_ERROR,
    payload: msg
});