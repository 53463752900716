import React, {Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SumaCompartido from './SumaCompartido';
import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Resuelve la suma.';
const miniInstrucciones = 'Resuelve la suma.';

const M59 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [indexes, setIndexes] = useState(0);

    useEffect(() => {
        const numeros = [100,200,300,400,500,600,700,800,900];

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const randomNumeros = shuffle(numeros);

        const modelo = [
            {id:'1', cantidad_1:randomNumeros[0], cantidad_2:100},
            {id:'2', cantidad_1:randomNumeros[1], cantidad_2:100},
            {id:'3', cantidad_1:randomNumeros[2], cantidad_2:100},
            {id:'4', cantidad_1:randomNumeros[3], cantidad_2:100},
            {id:'5', cantidad_1:randomNumeros[4], cantidad_2:100}
        ]
        setData(modelo);
        setIndexes(modelo.length);
        dispatch(setearEjercicioAction('M59') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <SumaCompartido data={data} indexes={indexes} ejercicio="M59" tipo={"centena"} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} ayuda={true} width={"18vw"} inp={"26vw"} color={"#23AAAF"} />
        </Fragment>
    );
}
 
export default M59;