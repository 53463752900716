import React, { useState } from 'react';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import ObjetivosDisponibles from './ObjetivosDisponibles';
import ObjetivosAsignados from './ObjetivosAsignados';

//css
import {Cuerpo, FlexBox, FlexColumn} from '../Css/StyledHeader';
import {BotonSeleccionado, BotonPequeño, Div, Contenedor} from '../Css/StyledActivities';

const objetivosSeccion = [
    {id:'1', seccion_objetivo: 'LECTURA'},
    {id:'2', seccion_objetivo: 'ESCRITURA'},
    {id:'3', seccion_objetivo: 'CC FONOLÓGICA'},
    {id:'4', seccion_objetivo: 'DENOMINACIÓN RÁPIDA'},
    {id:'5', seccion_objetivo: 'MATEMÁTICA'},
    {id:'6', seccion_objetivo: 'COGNICIÓN'},
    {id:'7', seccion_objetivo: 'EMOCIÓN'},
    {id:'8', seccion_objetivo: 'LENGUAJE'}
];

const Objetivos = () => {

    const [seccionObjetivo, setSeccionObjetivo] = useState({id:objetivosSeccion[0].id, seccion:objetivosSeccion[0].seccion_objetivo});
    const [objetivosEtapa, setObjetivosEtapa] = useState('asignados');

    return (
        <Cuerpo>
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes/paciente`} componente={"Objetivos"} paciente={true} />
            <FlexColumn align="center">
                <Div margin="15px 0px 10px 7px">
                <Contenedor>
                    {objetivosSeccion.map(item => 
                        <BotonPequeño backgroundcolor={seccionObjetivo.seccion === item.seccion_objetivo ? true : false} 
                        key={item.id}
                        onClick={() => setSeccionObjetivo({id:item.id, seccion:item.seccion_objetivo})}>
                            {item.seccion_objetivo}
                        </BotonPequeño>
                    )}
                </Contenedor>
                </Div>
                <FlexBox justify="center">
                <div>
                    <BotonSeleccionado name="disponible" onClick={e => setObjetivosEtapa(e.target.name)} backgroundcolor={objetivosEtapa === "disponible" ? true : false} width="40vw" padding="15px 0px" margin="20px 0px 0px 10px">Objetivos Disponibles</BotonSeleccionado>
                </div>
                <div>
                    <BotonSeleccionado name="asignados" onClick={e => setObjetivosEtapa(e.target.name)} backgroundcolor={objetivosEtapa === "asignados" ? true : false} width="40vw" padding="15px 0px" margin="20px 0px 0px 10px">Objetivos Asignados</BotonSeleccionado>
                </div>
                </FlexBox>
                <div>
                    {objetivosEtapa === "disponible" ? <ObjetivosDisponibles seccion={seccionObjetivo} /> : null }
                    {objetivosEtapa === "asignados" ? <ObjetivosAsignados seccion={seccionObjetivo} /> : null }
                </div>
            </FlexColumn>
        </Cuerpo>
    );
}
 
export default Objetivos;