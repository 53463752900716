import {
    SOLICITAR_HISTORIA_CLINICA,
    SOLICITAR_HISTORIA_CLINICA_EXITO,
    SOLICITAR_HISTORIA_CLINICA_ERROR,
    MODIFICAR_HISTORIA_CLINICA,
    MODIFICAR_HISTORIA_CLINICA_EXITO,
    MODIFICAR_HISTORIA_CLINICA_ERROR,
    NUEVO_HISTORIA_CLINICA,
    NUEVO_HISTORIA_CLINICA_EXITO,
    NUEVO_HISTORIA_CLINICA_ERROR
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function historiaClinicaSolicitarAction(informacion) {
    return async (dispatch) => {
        dispatch( historiaClinicaSolicitar() );
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/buscarhistoriaClinica', payload, config);
            dispatch( historiaClinicaSolicitarExito(data));
        } catch (error) {
            dispatch( historiaClinicaSolicitarError(error.response.data));
        }
    }
};

const historiaClinicaSolicitar = () => ({
    type: SOLICITAR_HISTORIA_CLINICA,
    payload: true
});

const historiaClinicaSolicitarExito = (data) => ({
    type: SOLICITAR_HISTORIA_CLINICA_EXITO,
    payload: data
});

const historiaClinicaSolicitarError = (msg) => ({
    type: SOLICITAR_HISTORIA_CLINICA_ERROR,
    payload: msg
});

export function modificarHistoriaClinicaAction(informacion) {
    return async (dispatch) => {
        dispatch( modificarHistoriaClinica() );
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.put('/paciente/modificarhistoriaclinica', payload, config);
            dispatch( historiaClinicaSolicitarExito(data));
        } catch (error) {
            dispatch( modificarHistoriaClinicaError());
        }
    }
};

const modificarHistoriaClinica = () => ({
    type: MODIFICAR_HISTORIA_CLINICA,
    payload: true
});

const modificarHistoriaClinicaExito = (cambios) => ({
    type: MODIFICAR_HISTORIA_CLINICA_EXITO,
    payload: cambios
});

const modificarHistoriaClinicaError = () => ({
    type: MODIFICAR_HISTORIA_CLINICA_ERROR,
    payload: true
});

export function historiaClinicaNuevosAction(informacion) {
    return async (dispatch) => {
        dispatch( historiaClinicaNuevos() );
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/nuevohistoriaclinica', payload, config);
            dispatch( historiaClinicaNuevosExito() );
            Swal.fire(
                'Correcto',
                'El objetivo se agregó correctamente',
                'success'
            )
            dispatch( historiaClinicaSolicitarExito(data));
        } catch (error) {
            dispatch( historiaClinicaNuevosError(true) );
            Swal.fire(
                'Error',
                'El objetivo no se pudo agregar correctamente',
                'error'
            )
        }
    }
}


const historiaClinicaNuevos = () => ({
    type: NUEVO_HISTORIA_CLINICA,
    payload: true
});

const historiaClinicaNuevosExito = () => ({
    type: NUEVO_HISTORIA_CLINICA_EXITO,
    payload: true
});

const historiaClinicaNuevosError = (estado) => ({
    type: NUEVO_HISTORIA_CLINICA_ERROR,
    payload: estado
});