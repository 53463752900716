import styled from 'styled-components';
import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400&display=swap');
}
`
// QUERYS
const media = {
    min: '@media (min-width:100px) and (max-width:767px)',
    tablet: '@media (min-width:768px) and (max-width:1023px)',
    desktop: '@media(min-width:1024px) and (max-width:1439px)',
    desktopLg: '@media (min-width:1440px) and (max-width:1899px)',
    desktopXlg: '@media (min-width:1900px)'
}

// Contenedores

export const FlexLanding = styled.div`
    display: flex;
    text-align: center;
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    background-color: ${props => props.bgcolor};
    align-self: ${props => props.alignself};
    order: ${props => props.order};
    border: ${props => props.border};
    border-radius: ${props => props.borderradius};
    flex-wrap: ${props => props.wrap};
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin};
    cursor: ${props => props.cursor};

    ${media.min} {
        flex-direction: ${props => props.directionmin};
        margin: ${props => props.min_margin};
        padding: ${props => props.min_padding};
        width: ${props => props.min_width};
        height: ${props => props.min_height};
        flex-wrap: ${props => props.min_wrap};
        align-items: ${props => props.alignmin};
    }

    ${media.tablet} {
        margin: ${props => props.tb_margin};
        padding: ${props => props.tb_padding};
        width: ${props => props.tb_width};
        height: ${props => props.tb_height};
        flex-wrap: ${props => props.tb_wrap};
    }

    ${media.desktop} {
        margin: ${props => props.dkt_margin};
        padding: ${props => props.dkt_padding};
        width: ${props => props.dkt_width};
        height: ${props => props.dkt_height};
        flex-wrap: ${props => props.dkt_wrap};
    }

    ${media.desktopLg} {
        margin: ${props => props.lg_margin};
        padding: ${props => props.lg_padding};
        width: ${props => props.lg_width};
        height: ${props => props.lg_height};
        flex-wrap: ${props => props.lg_wrap};
    }

    ${media.desktopXlg} {
        margin: ${props => props.xlg_margin};
        padding: ${props => props.xlg_padding};
        width: ${props => props.xlg_width};
        height: ${props => props.xlg_height};
        flex-wrap: ${props => props.xlg_wrap};
    }
`

// Botones

export const Cerrar = styled.div`
    cursor: pointer;
    font-size: 1rem;
    margin: 1rem 1rem 0 0;
    text-align: end;
`

export const BotonLanding = styled.div`
    cursor: pointer;
    background-color: ${props => props.bgcolor};
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: ${props => props.fontweight};
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

    ${media.min} {
        margin: ${props => props.min_margin};
        padding: ${props => props.min_padding};
        width: ${props => props.min_width};
    }

    ${media.tablet} {
        margin: ${props => props.tb_margin};
        padding: ${props => props.tb_padding};
        width: ${props => props.tb_width};
    }

    ${media.desktop} {
        margin: ${props => props.dkt_margin};
        padding: ${props => props.dkt_padding};
        width: ${props => props.dkt_width};
    }

    ${media.desktopLg} {
        margin: ${props => props.lg_margin};
        padding: ${props => props.lg_padding};
        width: ${props => props.lg_width};
    }

    ${media.desktopXlg} {
        margin: ${props => props.xlg_margin};
        padding: ${props => props.xlg_padding};
        width: ${props => props.xlg_width};
    }
`

export const BotonCarrusel = styled.div`
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    background-color: ${props => props.bgcolor};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

    ${media.min} {
        margin: ${props => props.min_margin};
    }

    ${media.tablet} {
        margin: ${props => props.tb_margin};
    }

    ${media.desktop} {
        margin: ${props => props.dkt_margin};
    }

    ${media.desktopLg} {
        margin: ${props => props.lg_margin};
    }

    ${media.desktopXlg} {
        margin: ${props => props.xlg_margin};
    }
`

//Imagen

export const Imagen = styled.img`
    aspect-ratio: ${props => props.aspectratio};
    cursor: ${props => props.cursor};
    
    ${media.min} {
        margin: ${props => props.min_margin};
        padding: ${props => props.min_padding};
        width: ${props => props.min_width};
        height: ${props => props.min_height};
    }

    ${media.tablet} {
        margin: ${props => props.tb_margin};
        padding: ${props => props.tb_padding};
        width: ${props => props.tb_width};
        height: ${props => props.tb_height};
    }

    ${media.desktop} {
        margin: ${props => props.dkt_margin};
        padding: ${props => props.dkt_padding};
        width: ${props => props.dkt_width};
        height: ${props => props.dkt_height};
    }

    ${media.desktopLg} {
        margin: ${props => props.lg_margin};
        padding: ${props => props.lg_padding};
        width: ${props => props.lg_width};
        height: ${props => props.lg_height};
    }

    ${media.desktopXlg} {
        margin: ${props => props.xlg_margin};
        padding: ${props => props.xlg_padding};
        width: ${props => props.xlg_width};
        height: ${props => props.xlg_height};
    }
`

export const ImageBackground = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    ${media.min} {
        background-image: ${(props) => `url(${props.min_imgurl})`};
    }

    ${media.tablet} {
        background-image: ${(props) => `url(${props.tb_imgurl})`};
    }

    ${media.desktop} {
        background-image: ${(props) => `url(${props.dkt_imgurl})`};
    }

    ${media.desktopLg} {
        background-image: ${(props) => `url(${props.lg_imgurl})`};
    }

    ${media.desktopXlg} {
        background-image: ${(props) => `url(${props.xlg_imgurl})`};
    }
`

// Texto

export const TextoCorto = styled.div`
    font-size: ${props => props.fontsize};
    font-weight: ${props => props.fontweight};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    text-align: ${props => props.textalign};
    line-height: ${props => props.lineheight};
    border-radius: ${props => props.borderradius};
    text-shadow: ${props => props.textshadow};
    cursor: ${props => props.cursor};
    
    ${media.min} {
        margin: ${props => props.min_margin};
        padding: ${props => props.min_padding};
        width: ${props => props.min_width};
        font-size: ${props => props.min_fontsize};
    }

    ${media.tablet} {
        margin: ${props => props.tb_margin};
        padding: ${props => props.tb_padding};
        width: ${props => props.tb_width};
        font-size: ${props => props.tb_fontsize};
    }

    ${media.desktop} {
        margin: ${props => props.dkt_margin};
        padding: ${props => props.dkt_padding};
        width: ${props => props.dkt_width};
        font-size: ${props => props.dkt_fontsize};
    }

    ${media.desktopLg} {
        margin: ${props => props.lg_margin};
        padding: ${props => props.lg_padding};
        width: ${props => props.lg_width};
        font-size: ${props => props.lg_fontsize};
    }

    ${media.desktopXlg} {
        margin: ${props => props.xlg_margin};
        padding: ${props => props.xlg_padding};
        width: ${props => props.xlg_width};
        font-size: ${props => props.xlg_fontsize};
    }
`