import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { Palabra, Oracion, Img } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { ContainerFlex } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Escribe la oración en tu cuaderno en el tipo de letra que corresponde.';
const miniInstrucciones = 'Escribe la oración en tu cuaderno en el tipo de letra que corresponde.';

const ESC17 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [count, setCount] = useState(1);
    const [indexes, setIndexes] = useState();
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [disable, setDisable] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        console.log('bien');
        setIsCorrect(true);
        setDisable(true);
        setTimeout(() => {
            setIsCorrect(false);
        }, 300);
        GoodWork();
        if (count < indexes) {
            setCount(count + 1);
            setDisable(false);
        } else {
            setTimeout(() => {
                setUltimoTiempo(true);
                dispatch(finalizarEjercicioAction());
            }, 1500);
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC17') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/transicion-de-letras/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC17",
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center" align="center"
                    isCorrect={isCorrect}
                >
                    <ContainerFlex display="flex" direction="column" justify="center" align="center" margin="auto">
                        <ContainerFlex display="flex" justify="center" width="100%">
                            <Palabra width="100%" height="20vh" bg="rgba(35, 170, 175, 1)" shadow= "0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" display="flex" align="center" justify="center">
                                <ContainerFlex width="100%" borderTop="1px solid black" borderBottom="1px solid black" display="flex" justify="center" padding="0.5rem 0 0 0">
                                    <Oracion display="flex" justify="center" align="center" font="2.5rem" padding="0.3rem 0 0 0" width="100%" wrap="wrap">
                                        {comparar.frase}
                                    </Oracion>
                                </ContainerFlex>
                            </Palabra>
                        </ContainerFlex>
                        <ContainerFlex margintop="6rem" display="flex" justify="center">
                            <ContainerFlex radius="20px" shadow="inset 1px 3px 4px 4px rgba(0, 0, 0, 0.25)" padding="2rem">
                                <Img width="750px" height="70px" widthmob="650px" heightmob="70px" src={`/img/abecedario17.png`} />
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlex>

                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC17;