import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import { TablaPacientes } from '../AuxiliaresPaginas/TablaPacientes';

//css
import {Cuerpo} from '../Css/StyledHeader.js';
import { FlexLanding } from '../Css/StyledLanding';

import { cargarPacientesAction } from '../../actions/cargarPacientesActions';
import { solicitoSuscripcionProfesionalAction } from '../../actions/cargarSuscripcionActions.js';

import { BotonPacienteDos}  from '../Css/StyledPatient.js';
import useReturnInitialState from '../../Hooks/useReturnInitialState';
import useConfigAuth from '../../Hooks/useConfigAuth';


const Pacientes = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [returnState] = useReturnInitialState();
    const [config] = useConfigAuth();

    const pacientes = useSelector((state) => state.informacion.lista_pacientes);
    const infoSuscripcion = useSelector((state) => state.usuario.suscripcion);

    const {estado_suscripcion} = infoSuscripcion;


    useEffect(() => {
        if(pacientes.length > 0){
            return
        }
        const informacion = {
            config
        }
        const cargoPacientes = () => dispatch (cargarPacientesAction(informacion));
        dispatch( solicitoSuscripcionProfesionalAction(informacion) );
        cargoPacientes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const irSuscripcio = (e) => {
        e.preventDefault();
        navigate("/escritorio/misuscripcion");
    }

    return ( 
        <Cuerpo>
            <HeaderUno />
            <HeaderDos componente={"Listado pacientes"} paciente={false} />
            <TablaPacientes pacientes={pacientes} />
            {!estado_suscripcion ? null :
            !estado_suscripcion[0].prueba_gratis ?
                <FlexLanding justify="center" align="center" margin="2rem 0 0 0">
                    <BotonPacienteDos
                        onClick={(e) => irSuscripcio(e)}
                    >
                        Ir a Activar Demo Gratis
                    </BotonPacienteDos>
                </FlexLanding>
            : null
            }
        </Cuerpo>
    );
}
 
export default Pacientes;