import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import AyudaCuadricula from '../../Suma/AyudaCuadricula';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, NumSumaM55, AvanzarImg, AvanzarBtn, InputM55 } from '../../../../../Css/StyledMatematica';

const RestaCompartido = (props) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, tipo, color, instrucciones, miniInstrucciones  } = props;
    const { w } = props.width;

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numero.toString() ) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numero.toString()){
                    setNumero(0);
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero(0);
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero(0);              
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(data));
        setComparar(newList);
        setTipoEjercicio(tipo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    'ayuda_visual': ayuVisualSolicitada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <FlexCuz $alignself='start' $order='1'>
                            {props.ayuda === true ? <AyudaCuadricula /> : null}
                        </FlexCuz>
                        {comparar.length === 0 ? null :
                        <Flex>
                            <NumSumaM55 color='#FF9B2A' w={w}>{comparar[count-1].cantidad_1}</NumSumaM55> 
                            <NumSumaM55 color='#646464' w='8vw'>-</NumSumaM55> 
                            <NumSumaM55 color='#FF9B2A' w={w}>{comparar[count-1].cantidad_2}</NumSumaM55> 
                            <NumSumaM55 color='#646464' w='8vw'>=</NumSumaM55> 
                        </Flex>
                        } 
                        <form onSubmit={(e) => onSubmit(e)}>
                            <InputM55 type="numeric" min="1" max="5000" value={numero} onChange={(e) => setNumero(e.target.value)} color={color} w={props.inp} bs='inset 0px 4px 4px rgba(0, 0, 0, 0.25)' bradius='20px' />
                        </form>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default RestaCompartido;