import React, {Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';
import { aumentarContadorAction } from '../../../../../actions/botoneraEjercicioActions';

import { Flex, NumberM74 } from '../../../../Css/StyledMatematica';

const instrucciones = 'Lee los números a continuación.';
const miniInstrucciones = 'Lee los números a continuación.';

const M74 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [indexes, setIndexes] = useState();
    const [contarTiempo, setContarTiempo] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const informacion = useSelector(state => state.informacion.info_DB);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const contador = useSelector(state => state.ejercicio.contador);
    const lista = useSelector(state => state.ejercicio.lista);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const error = useSelector(state => state.ejercicio.error);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setComparar(newList);
        setIndexes(informacion.length);
    }, [informacion]);

    useEffect(() => {
        if(comparar.length === 0){
            return
        }
        if(contador < indexes ){
            return
        } else {
            dispatch( finalizarEjercicioAction() );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contador]);
    
    useEffect(() => {
        if(contador === 0){
            return;
        }
        if(contador === 1){
            setErroresOrden([...erroresOrden, error]);
            return;
        }
        const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
        setErroresOrden([...erroresOrden, err]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contador])

    useEffect(() => {
        if(iniciar === false && contador === indexes ){
            return;
        }else if(iniciar === false && contador === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contador]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M74') );
        dispatch(setearUrlAnteriorAction('matematica/lectura-y-escritura-de-numeros/lectura/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(finalizar === true && contador === indexes -1 ){
            dispatch(aumentarContadorAction());
        }
        if(finalizar && contador === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
            const erroresOrdenFinal = [...erroresOrden, err];

            const informacion = {
                config,
                payload: {
                    'ejercicio': 'M74',
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'erroresOrden': erroresOrdenFinal,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contador]);
    
    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly">
                        {comparar.length === 0 || contador > 5 ? null :
                        <NumberM74 bgcolor='#FF9B2A' color='#FFFFFF'>
                            {comparar[contador].palabra}
                        </NumberM74>
                        }
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <Flex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </Flex>
                        <Flex>
                            <BotoneraBottom error={true} aumentar={true} />
                        </Flex>
                    </Flex>
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M74;