import React, { Fragment, useState } from 'react';

import ModalCostos from '../PaginaPrincipal/ModalCostos';
import useWindowSize from '../../Hooks/useWindowSize';

import {FlexLanding, BotonLanding, Imagen, TextoCorto, ImageBackground, BotonCarrusel} from '../Css/StyledLanding';

const Main = () => {

    const [carrusel, setCarrusel] = useState(0);
    const [carruselDos, setCarruselDos] = useState(0);
    const [carruselMovil, setCarruselMovil] = useState(0);
    const [carruselMovilDos, setCarruselMovilDos] = useState(0);
    const [carruselMovilTres, setCarruselMovilTres] = useState(0);

    const [widthWindowSize] = useWindowSize();

    const irLink = (e, donde) => {
        e.preventDefault();
        if(donde === "tienda") {
            window.open("http://metodoyogen.empretienda.com.ar");
        } else if(donde === "suscribite") {
            window.open("http://metodoyogen.com/nueva-cuenta");
        } else if(donde === "logueate") {
            window.open("http://metodoyogen.com/login");
        } else if(donde === "instagram") {
            window.open("https://www.instagram.com/metodoyogen");
        } else if(donde === "facebook") {
            window.open("https://www.facebook.com/metodoyogen");
        } else if(donde === "twitter") {
            
        } else if(donde === "youtube") {
            
        } else if(donde === "google") {
            
        }
    }

    const aumentarCarruselMovil = (e) => {
        e.preventDefault();
        if(carruselMovil < 4){
            setCarruselMovil(carruselMovil + 1);
        } else {
            setCarruselMovil(0);
        }
    }

    const reducirCarruselMovil = (e) => {
        e.preventDefault();
        if(carruselMovil > 0 ){
            setCarruselMovil(carruselMovil -1);
        } else {
            return;
        }
    }

    const aumentarCarruselMovilDos = (e) => {
        e.preventDefault();
        if(carruselMovilDos < 2){
            setCarruselMovilDos(carruselMovilDos + 1);
        } else {
            setCarruselMovilDos(0);
        }
    }

    const reducirCarruselMovilDos = (e) => {
        e.preventDefault();
        if(carruselMovilDos > 0 ){
            setCarruselMovilDos(carruselMovilDos -1);
        } else {
            return;
        }
    }

    const aumentarCarruselMovilTres = (e) => {
        e.preventDefault();
        if(carruselMovilTres < 5){
            setCarruselMovilTres(carruselMovilTres + 1);
        } else {
            setCarruselMovilTres(0);
        }
    }

    const reducirCarruselMovilTres = (e) => {
        e.preventDefault();
        if(carruselMovilTres > 0 ){
            setCarruselMovilTres(carruselMovilTres -1);
        } else {
            return;
        }
    }

    const aumentarCarrusel = (e) => {
        e.preventDefault();
        if(carrusel < 2){
            setCarrusel(carrusel + 1);
        } else {
            setCarrusel(0);
        }
    }

    const reducirCarrusel = (e) => {
        e.preventDefault();
        if(carrusel > 0 ){
            setCarrusel(carrusel -1);
        } else {
            return;
        }
    }

    const reducirCarruselDos = (e) => {
        e.preventDefault();
        if(carruselDos === 0 ){
            setCarruselDos(5);
        } else {
            setCarruselDos(carruselDos -1);
        }
    }

    const aumentarCarruselDos = (e) => {
        e.preventDefault();
        if(carruselDos === 5){
            setCarruselDos(0);
        } else {
            setCarruselDos(carruselDos + 1);
        }
    }

    return (
        <Fragment>
            <FlexLanding justify="end"
            xlg_margin="2.5rem 1.5rem 6rem 1.5rem"
            lg_margin="2.5rem 1.5rem 6rem 1.5rem"
            dkt_margin="2.5rem 1.5rem 6rem 1.5rem"
            tb_margin="2.5rem 1.5rem 6rem 1.5rem"
            min_margin="2.5rem 1.5rem 6rem 1.5rem"
            directionmin="column"
            >
                <BotonLanding bgcolor="#6261D6" fontweight="bold"
                xlg_margin="0rem 0.5rem 0rem 0rem" xlg_padding="0.3rem 1rem"
                lg_margin="0rem 0.5rem 0rem 0rem" lg_padding="0.3rem 1rem"
                dkt_margin="0rem 0.5rem 0rem 0rem" dkt_padding="0.3rem 1rem"
                tb_margin="0rem 0.5rem 0rem 0rem" tb_padding="0.3rem 1rem"
                min_margin="0rem 0.5rem 0rem 0rem" min_padding="0.3rem 1rem"
                    onClick={(e) => irLink(e,"tienda")}
                >
                    TIENDA
                </BotonLanding>
                
                <BotonLanding bgcolor="#D53A77" fontweight="bold"
                xlg_margin="0rem 0.5rem 0rem 0rem" xlg_padding="0.3rem 1rem"
                lg_margin="0rem 0.5rem 0rem 0rem" lg_padding="0.3rem 1rem"
                dkt_margin="0rem 0.5rem 0rem 0rem" dkt_padding="0.3rem 1rem"
                tb_margin="0rem 0.5rem 0rem 0rem" tb_padding="0.3rem 1rem"
                min_margin="0rem 0.5rem 0rem 0rem" min_padding="0.3rem 1rem"
                    onClick={(e) => irLink(e,"suscribite")}
                >
                    SUSCRIBITE!
                </BotonLanding>
                <BotonLanding bgcolor="#D53A77" fontweight="bold"
                xlg_margin="0rem 0.5rem 0rem 0rem" xlg_padding="0.3rem 1rem"
                lg_margin="0rem 0.5rem 0rem 0rem" lg_padding="0.3rem 1rem"
                dkt_margin="0rem 0.5rem 0rem 0rem" dkt_padding="0.3rem 1rem"
                tb_margin="0rem 0.5rem 0rem 0rem" tb_padding="0.3rem 1rem"
                min_margin="0rem 0.5rem 0rem 0rem" min_padding="0.3rem 1rem"
                    onClick={(e) => irLink(e,"logueate")}
                >
                    LOG-IN
                </BotonLanding>
            </FlexLanding>
            <ImageBackground
            xlg_imgurl="/img/landing_fondo_1_1900.png"
            lg_imgurl="/img/landing_fondo_1_1440.png"
            dkt_imgurl="/img/landing_fondo_1_1024.png"
            tb_imgurl="/img/landing_fondo_1_768.png"
            min_imgurl="/img/landing_fondo_1_505.png"
            >
                <FlexLanding direction="column" align="center"
                xlg_margin="0 0rem 0 0rem" xlg_padding="0rem 0rem 8rem 0rem" xlg_width="100%"
                lg_margin="0 0rem 0 0rem" lg_padding="0rem 0rem 8rem 0rem" lg_width="100%"
                dkt_margin="0 0rem 0 0rem" dkt_padding="0rem 0rem 8rem 0rem" dkt_width="100%"
                tb_margin="0 0rem 0 0rem" tb_padding="0rem 0rem 8rem 0rem" tb_width="100%"
                min_margin="0 0rem 0 0rem" min_padding="0rem 0rem 8rem 0rem" min_width="100%"
                >
                    <Imagen src={`/img/logo_landing.png`} alt="Logo Método Yögen" 
                    xlg_width="35%"
                    lg_width="49%"
                    dkt_width="49%"
                    tb_width="49%"
                    min_width="49%"
                    />
                    <TextoCorto textshadow="#000000 1px 0 3px"
                    xlg_margin="3rem 0 0 0 "
                    lg_margin="3rem 0 0 0 "
                    dkt_margin="3rem 0 0 0 "
                    tb_margin="3rem 0 0 0 "
                    min_margin="3rem 0 0 0 "
                    
                    >Programa de Enseñanza Explícito</TextoCorto>
                    <BotonLanding bgcolor="#6261D6" fontweight="bold"
                    xlg_margin="1.5rem 0 0rem 0" xlg_padding="0.3rem 1rem" xlg_width="auto" 
                    lg_margin="1.5rem 0 0rem 0" lg_padding="0.3rem 1rem" lg_width="auto" 
                    dkt_margin="1.5rem 0 0rem 0" dkt_padding="0.3rem 1rem" dkt_width="auto" 
                    tb_margin="1.5rem 0 0rem 0" tb_padding="0.3rem 1rem" tb_width="auto" 
                    min_margin="1.5rem 0 0rem 0" min_padding="0.3rem 1rem" min_width="auto"
                        onClick={(e) => irLink(e,"suscribite")}
                    >
                        SUSCRIBIRME
                    </BotonLanding>
                </FlexLanding>
            </ImageBackground>
            <ImageBackground 
            xlg_imgurl="/img/landing_fondo_2_1900.png"
            lg_imgurl="/img/landing_fondo_2_1440.png"
            dkt_imgurl="/img/landing_fondo_2_1024.png"
            tb_imgurl="/img/landing_fondo_2_768.png"
            min_imgurl="/img/landing_fondo_2_505.png"
            >
                <FlexLanding direction="column" align="center" 
                xlg_width="100%"
                lg_width="100%"
                dkt_width="100%"
                tb_width="100%"
                min_width="100%"
                >
                    <TextoCorto fontsize="2rem" color="#FFFFFF" fontweight="bold" lineheight="1.5"
                    xlg_margin="2.5rem 1.5rem 0rem 1.5rem" xlg_padding="0 0 3rem 0"
                    lg_margin="2.5rem 1.5rem 0rem 1.5rem" lg_padding="0 0 3rem 0"
                    dkt_margin="2.5rem 1.5rem 0rem 1.5rem" dkt_padding="0 0 3rem 0"
                    tb_margin="2.5rem 1.5rem 0rem 1.5rem" tb_padding="0 0 3rem 0"
                    min_margin="2.5rem 1.5rem 0rem 1.5rem" min_padding="0 0 3rem 0"
                    >TODAS LAS HERRAMIENTAS<br/>PARA TU CONSULTORIO<br/> EN UN SOLO LUGAR</TextoCorto>
                    <FlexLanding align="center"
                    xlg_margin="2rem 0 9rem 0"
                    lg_margin="2rem 0 9rem 0"
                    dkt_margin="2rem 0 9rem 0"
                    tb_margin="2rem 0 9rem 0"
                    min_margin="2rem 0 9rem 0"
                    >
                        <BotonLanding bgcolor="#D53A77" fontweight="bold"
                        xlg_margin="0rem 0.5rem 0rem 0" xlg_padding="0.3rem 1rem" xlg_width="auto"
                        lg_margin="0rem 0.5rem 0rem 0" lg_padding="0.3rem 1rem" lg_width="auto"
                        dkt_margin="0rem 0.5rem 0rem 0" dkt_padding="0.3rem 1rem" dkt_width="auto"
                        tb_margin="0rem 0.5rem 0rem 0" tb_padding="0.3rem 1rem" tb_width="auto"
                        min_margin="0rem 0.5rem 0rem 0" min_padding="0.3rem 1rem" min_width="auto"
                            onClick={(e) => irLink(e,"suscribite")}
                        >
                            DEMO POR 15 DÍAS
                        </BotonLanding>
                        <BotonLanding  bgcolor="#D53A77" fontweight="bold"
                        xlg_margin="0rem 0 0rem 0.5rem" xlg_padding="0.3rem 1rem" xlg_width="auto"
                        lg_margin="0rem 0 0rem 0.5rem" lg_padding="0.3rem 1rem" lg_width="auto"
                        dkt_margin="0rem 0 0rem 0.5rem" dkt_padding="0.3rem 1rem" dkt_width="auto"
                        tb_margin="0rem 0 0rem 0.5rem" tb_padding="0.3rem 1rem" tb_width="auto"
                        min_margin="0rem 0 0rem 0.5rem" min_padding="0.3rem 1rem" min_width="auto"
                        >
                            <ModalCostos />
                        </BotonLanding>
                    </FlexLanding>
                </FlexLanding>
            </ImageBackground>
            <ImageBackground 
            xlg_imgurl="/img/landing_fondo_3_1900.png"
            lg_imgurl="/img/landing_fondo_3_1440.png"
            dkt_imgurl="/img/landing_fondo_3_1024.png"
            tb_imgurl="/img/landing_fondo_3_768.png"
            min_imgurl="/img/landing_fondo_3_505.png"
            >
                <FlexLanding align="center" directionmin="column"
                xlg_margin="5.5rem 0 10rem 0" xlg_padding="0 0 0 0" xlg_width="100%"
                lg_margin="5.5rem 0 10rem 0" lg_padding="0 0 0 0" lg_width="100%"
                dkt_margin="5.5rem 0 10rem 0" dkt_padding="0 0 0 0" dkt_width="100%"
                tb_margin="5.5rem 0 10rem 0" tb_padding="0 0 0 0" tb_width="100%"
                min_margin="5.5rem 0 10rem 0" min_padding="0 0 0 0" min_width="100%"
                >
                    <FlexLanding direction="column" alignself="start"
                    xlg_width="50%" xlg_margin="4rem 0 0 0"
                    lg_width="50%"
                    dkt_width="50%"
                    tb_width="60%"
                    min_width="100%"
                    >
                        <Imagen src={"/img/landing_quemetodo.png"} 
                        xlg_width="90%" xlg_height="90%"
                        lg_width="100%" lg_height="100%"
                        dkt_width="100%" dkt_height="100%"
                        tb_width="100%" tb_height="100%"
                        min_width="100%" min_height="100%"
                        />
                        <TextoCorto fontweight="bold" lineheight="2"
                        xlg_padding="5rem 3rem 0 3rem" xlg_fontsize="1.5rem"
                        lg_padding="3rem 3rem 0 3rem" lg_fontsize="1.2rem"
                        dkt_padding="3rem 3rem 0 3rem" dkt_fontsize="1.2rem"
                        tb_padding="3rem 3rem 0 3rem" tb_fontsize="1.2rem"
                        min_padding="3rem 3rem 0 3rem" min_fontsize="1.2rem"
                        >¿Sos psicopedagoga o fonoaudiológa? Estás buscando una forma de encarar tu tratamiento?</TextoCorto>
                        <TextoCorto textalign="left" lineheight="2"
                        xlg_padding="3rem 3rem 0 3rem" xlg_fontsize="1.2rem"
                        lg_padding="3rem 3rem 0 3rem" lg_fontsize="1rem"
                        dkt_padding="3rem 3rem 0 3rem" dkt_fontsize="1rem"
                        tb_padding="3rem 3rem 0 3rem" tb_fontsize="1rem"
                        min_padding="3rem 3rem 0 3rem" min_fontsize="1rem"
                        >MÉTODO YÖGEN es un programa de aprendizaje <span style={{color: "#655EE8"}}>metódico</span> y <span style={{color: "#655EE8"}}>sistemático</span> basado en <strong>evidencia científica.</strong><br/><br/>Ideado para cumplir como herramienta de uso profesional en la enseñanza y tratamiento de <strong>procesos lectores, escritura, matemática, lenguaje y procesos cognitivos</strong> subyacentes. Se acompaña de estrategias metacognitivas y de regulación de la conducta.</TextoCorto>
                    </FlexLanding>
                    <FlexLanding
                    xlg_width="50%"
                    lg_width="50%"
                    dkt_width="50%"
                    tb_width="40%"
                    min_width="40%"
                    >
                        <Imagen src={"/img/compu_decoracion.png"} 
                        xlg_width="80%" xlg_height="80%"
                        lg_width="100%" lg_height="100%"
                        dkt_width="100%" dkt_height="100%"
                        tb_width="100%" tb_height="100%"
                        min_width="100%" min_height="100%"
                        />
                    </FlexLanding>
                </FlexLanding>
            </ImageBackground>
            <ImageBackground 
            xlg_imgurl="/img/landing_fondo_4_1900.png"
            lg_imgurl="/img/landing_fondo_4_1440.png"
            dkt_imgurl="/img/landing_fondo_4_1024.png"
            tb_imgurl="/img/landing_fondo_4_768.png"
            min_imgurl="/img/landing_fondo_4_505.png"
            >
                <FlexLanding direction="column" align="center"
                xlg_margin="2rem 0 6rem 0" xlg_width="100%"
                lg_margin="0 0 5rem 0" lg_width="100%"
                dkt_margin="0 0 5rem 0" dkt_width="100%"
                tb_margin="0 0 5rem 0" tb_width="100%"
                min_margin="0 0 5rem 0" min_width="100%"
                >
                    <FlexLanding align="center">
                        <TextoCorto fontsize="3.5rem" fontweight="bold" color="#FFFFFF"
                        xlg_padding="0 0 0 0"
                        lg_padding="0 0 0 0"
                        dkt_padding="0 0 0 0"
                        tb_padding="0 0 0 0"
                        min_padding="0 0 0 0"
                        >¿Cómo me ayuda?</TextoCorto>
                    </FlexLanding>
                    <FlexLanding align="center" directionmin="column"
                    xlg_margin="3rem 0 4rem 0"
                    lg_margin="3rem 0 4rem 0"
                    dkt_margin="3rem 0 4rem 0"
                    tb_margin="2rem 0 3rem 0"
                    min_margin="2rem 0 3rem 0"
                    >
                        <FlexLanding align="start" direction="column"
                        xlg_margin="0 6rem 0 0"
                        lg_margin="0 4rem 0 0"
                        dkt_margin="0 4rem 0 0"
                        tb_margin="0 2rem 0 0"
                        min_margin="0 2rem 0 0"
                        >
                            <Imagen src={"/img/texto1.png"} aspectratio="10/3"
                            xlg_margin="0 0 0 0" xlg_width="270px"
                            lg_margin="0 0 0 0" lg_width="254px"
                            dkt_margin="0 0 0 0" dkt_width="254px"
                            tb_margin="0 0 0 0" tb_width="254px"
                            min_margin="0 0 0 0" min_width="254px"
                            />
                            <Imagen src={"/img/texto3.png"} aspectratio="10/3"
                            xlg_margin="3rem 0 0 0" xlg_width="150px" xlg_height="40px"
                            lg_margin="3rem 0 0 0" lg_width="134px" lg_height="35px"
                            dkt_margin="3rem 0 0 0" dkt_width="134px" dkt_height="35px"
                            tb_margin="3rem 0 0 0" tb_width="134px" tb_height="35px"
                            min_margin="3rem 0 0 0" min_width="134px" min_height="35px"
                            />
                            
                        </FlexLanding>
                        <FlexLanding align="start" direction="column"
                        xlg_margin="0 0 0 6rem"
                        lg_margin="0 0 0 4rem"
                        dkt_margin="0 0 0 4rem"
                        tb_margin="0 0 0 4rem"
                        min_margin="0 0 0 4rem"
                        >
                            <Imagen src={"/img/texto2.png"} aspectratio="10/3"
                            xlg_margin="0 0rem 0 0" xlg_width="175px"
                            lg_margin="0 0rem 0 0" lg_width="168px"
                            dkt_margin="0 0rem 0 0" dkt_width="168px"
                            tb_margin="0 0rem 0 0" tb_width="168px"
                            min_margin="0 0rem 0 0" min_width="168px"
                            />
                            <Imagen src={"/img/texto4.png"} aspectratio="10/3"
                            xlg_margin="3rem 0rem 0 0" xlg_width="245px"
                            lg_margin="3rem 0rem 0 0" lg_width="223px"
                            dkt_margin="3rem 0rem 0 0" dkt_width="223px"
                            tb_margin="3rem 0rem 0 0" tb_width="223px"
                            min_margin="3rem 0rem 0 0" min_width="223px"
                            />
                        </FlexLanding>
                    </FlexLanding>
                </FlexLanding>
            </ImageBackground>
            <ImageBackground
            xlg_imgurl="/img/landing_fondo_5_1900.png"
            lg_imgurl="/img/landing_fondo_5_1440.png"
            dkt_imgurl="/img/landing_fondo_5_1024.png"
            tb_imgurl="/img/landing_fondo_5_768.png"
            min_imgurl="/img/landing_fondo_5_505.png"
            >
                <FlexLanding direction="column" align="center"
                xlg_margin="4rem 0 5rem 0" xlg_width="100%"
                lg_margin="4rem 0 5rem 0" lg_width="100%"
                dkt_margin="4rem 0 5rem 0" dkt_width="100%"
                tb_margin="4rem 0 5rem 0" tb_width="100%"
                min_margin="4rem 0 5rem 0" min_width="100%"
                >
                    <FlexLanding align="center"
                    xlg_margin="0 0 2rem 0"
                    lg_margin="0 0 2rem 0"
                    dkt_margin="0 0 2rem 0"
                    tb_margin="0 0 2rem 0"
                    min_margin="0 0 2rem 0"
                    >
                        <TextoCorto fontsize="3.5rem" fontweight="bold"
                        xlg_padding="3rem 0 0 0 "
                        lg_padding="3rem 0 0 0 "
                        dkt_padding="3rem 0 0 0 "
                        tb_padding="0rem 0 0 0 "
                        min_padding="3rem 0 0 0 "
                        >¿Cómo funciona?</TextoCorto>
                    </FlexLanding>
                    <FlexLanding align="center"
                    xlg_height="500px"
                    lg_height="500px"
                    dkt_height="660px"
                    tb_height="700px"
                    min_padding="0rem 0 1rem 0 "
                    >   
                        {widthWindowSize <= 767 ? 
                        <FlexLanding>
                            {carruselMovil === 0 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            min_width="90%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo1.png"} />
                                <TextoCorto fontsize="1em" fontweight="bold" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >TE ACOMPAÑA DESDE EL PRIMER CONTACTO CON TU PACIENTE</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >Cuenta con una amanmesis para recabar información inicial sobre los datos relevantes de la historia evolutiva y los hitos del desarrollo</TextoCorto>
                            </FlexLanding>
                            : null}
                            {carruselMovil === 1 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            min_width="90%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo2.png"} />
                                <TextoCorto fontsize="1rem" fontweight="bold" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >OBJETIVOS DE TRATAMIENTO</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >Permite establecer objetivos de tratamiento a largo plazo y metas mínimas para llevar un seguimiento de cada paciente.</TextoCorto>
                            </FlexLanding>
                            : null}
                            {carruselMovil === 2 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            min_width="90%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo3.png"} />
                                <TextoCorto fontsize="1rem" fontweight="bold" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >TRATAMIENTO HOLÍSTICO</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >Cuenta con un sistema graduado y sistemático para la enseñanza de la lectoescritura, comprensión, matemática, lenguaje, sin dejar de lado habilidades metacognitivas y aspectos emocionales y conductuales.</TextoCorto>
                            </FlexLanding>
                            : null}
                            {carruselMovil === 3 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            min_width="90%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo4.png"} />
                                <TextoCorto fontsize="1rem" fontweight="bold" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >ACOMPAÑAMIENTO ASINCRÓNICO</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >Esta comprobado mediante investigaciones científicas que el proceso de aprendizaje debe ser sistemático, gradual y explícito, por esa razón, resulta importante poder sistematizar lo trabajado en el consultorio y poder llevarlo al domicilio. MÉTODO YÖGEN te permite asignarle actividades para realizar al estudiante en su domicilio.</TextoCorto>
                            </FlexLanding>
                            : null}
                            {carruselMovil === 4 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            min_width="90%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo5.png"} />
                                <TextoCorto fontsize="1rem" fontweight="bold" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >RESULTADOS EN TIEMPO REAL</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                min_padding="2rem 0 0 0 "
                                >Sabemos lo importante que es poder visualizar los avances en tiempo real. Por ese motivo el programa automáticamente valora el rendimiento en la sesión y genera estadísticas.</TextoCorto>
                            </FlexLanding>
                            : null} 
                        </FlexLanding>
                        :
                        <FlexLanding>
                            {carrusel === 0 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            xlg_width="33.33%" xlg_padding="0 0.5rem 0 0.5rem"
                            lg_width="33.33%" lg_padding="0 0.5rem 0 0.5rem"
                            dkt_width="33.33%" dkt_padding="0 0.5rem 0 0.5rem"
                            tb_width="33.33%" tb_padding="0 0.5rem 0 0.5rem"
                            min_width="33.33%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo1.png"} />
                                <TextoCorto fontsize="1em" fontweight="bold" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >TE ACOMPAÑA DESDE EL PRIMER CONTACTO CON TU PACIENTE</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >Cuenta con una amanmesis para recabar información inicial sobre los datos relevantes de la historia evolutiva y los hitos del desarrollo</TextoCorto>
                            </FlexLanding>
                            : null}
                            {carrusel < 2 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            xlg_width="33.33%" xlg_padding="0 0.5rem 0 0.5rem"
                            lg_width="33.33%" lg_padding="0 0.5rem 0 0.5rem"
                            dkt_width="33.33%" dkt_padding="0 0.5rem 0 0.5rem"
                            tb_width="33.33%" tb_padding="0 0.5rem 0 0.5rem"
                            min_width="33.33%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo2.png"} />
                                <TextoCorto fontsize="1rem" fontweight="bold" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >OBJETIVOS DE TRATAMIENTO</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >Permite establecer objetivos de tratamiento a largo plazo y metas mínimas para llevar un seguimiento de cada paciente.</TextoCorto>
                            </FlexLanding>
                            : null} 
                            <FlexLanding direction="column" align="center" alignself="start"
                            xlg_width="33.33%" xlg_padding="0 0.5rem 0 0.5rem"
                            lg_width="33.33%" lg_padding="0 0.5rem 0 0.5rem"
                            dkt_width="33.33%" dkt_padding="0 0.5rem 0 0.5rem"
                            tb_width="33.33%" tb_padding="0 0.5rem 0 0.5rem"
                            min_width="33.33%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo3.png"} />
                                <TextoCorto fontsize="1rem" fontweight="bold" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >TRATAMIENTO HOLÍSTICO</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >Cuenta con un sistema graduado y sistemático para la enseñanza de la lectoescritura, comprensión, matemática, lenguaje, sin dejar de lado habilidades metacognitivas y aspectos emocionales y conductuales.</TextoCorto>
                            </FlexLanding>
                            {carrusel > 0 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            xlg_width="33.33%" xlg_padding="0 0.5rem 0 0.5rem"
                            lg_width="33.33%" lg_padding="0 0.5rem 0 0.5rem"
                            dkt_width="33.33%" dkt_padding="0 0.5rem 0 0.5rem"
                            tb_width="33.33%" tb_padding="0 0.5rem 0 0.5rem"
                            min_width="33.33%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo4.png"} />
                                <TextoCorto fontsize="1rem" fontweight="bold" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >ACOMPAÑAMIENTO ASINCRÓNICO</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >Esta comprobado mediante investigaciones científicas que el proceso de aprendizaje debe ser sistemático, gradual y explícito, por esa razón, resulta importante poder sistematizar lo trabajado en el consultorio y poder llevarlo al domicilio. MÉTODO YÖGEN te permite asignarle actividades para realizar al estudiante en su domicilio.</TextoCorto>
                            </FlexLanding>
                            : null}
                            {carrusel === 2 ? 
                            <FlexLanding direction="column" align="center" alignself="start"
                            xlg_width="33.33%" xlg_padding="0 0.5rem 0 0.5rem"
                            lg_width="33.33%" lg_padding="0 0.5rem 0 0.5rem"
                            dkt_width="33.33%" dkt_padding="0 0.5rem 0 0.5rem"
                            tb_width="33.33%" tb_padding="0 0.5rem 0 0.5rem"
                            min_width="33.33%" min_padding="0 0.5rem 0 0.5rem"
                            >
                                <Imagen src={"/img/circulo5.png"} />
                                <TextoCorto fontsize="1rem" fontweight="bold" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >RESULTADOS EN TIEMPO REAL</TextoCorto>
                                <TextoCorto fontsize="1rem" dkt_width="75%" lineheight="2"
                                xlg_padding="3rem 0 0 0 "
                                lg_padding="3rem 0 0 0 "
                                dkt_padding="3rem 0 0 0 "
                                tb_padding="3rem 0 0 0 "
                                min_padding="3rem 0 0 0 "
                                >Sabemos lo importante que es poder visualizar los avances en tiempo real. Por ese motivo el programa automáticamente valora el rendimiento en la sesión y genera estadísticas.</TextoCorto>
                            </FlexLanding>
                            : null} 
                        </FlexLanding>
                        }
                        
                    </FlexLanding>
                    {widthWindowSize > 767 ? 
                        <FlexLanding>
                            <BotonCarrusel bgcolor="#D53A77"
                            xlg_margin="0 1rem 0 0"
                            lg_margin="0 1rem 0 0"
                            dkt_margin="0 1rem 0 0"
                            tb_margin="0 1rem 0 0"
                            min_margin="0 1rem 0 0"
                                onClick={(e) => reducirCarrusel(e)}
                            >
                                <TextoCorto color="#E8BA35" fontweight="bold">
                                    &lt;
                                </TextoCorto>
                            </BotonCarrusel>
                            <BotonCarrusel bgcolor="#D53A77"
                            xlg_margin="0 0rem 0 1rem"
                            lg_margin="0 0rem 0 1rem"
                            dkt_margin="0 0rem 0 1rem"
                            tb_margin="0 0rem 0 1rem"
                            min_margin="0 0rem 0 1rem"
                                onClick={(e) => aumentarCarrusel(e)}
                            >
                                <TextoCorto color="#E8BA35" fontweight="bold">
                                    &gt;
                                </TextoCorto>
                            </BotonCarrusel>
                        </FlexLanding>
                    : null}
                    {widthWindowSize <= 767 ? 
                        <FlexLanding>
                            <BotonCarrusel bgcolor="#D53A77"
                            xlg_margin="0 1rem 0 0"
                            lg_margin="0 1rem 0 0"
                            dkt_margin="0 1rem 0 0"
                            tb_margin="0 1rem 0 0"
                            min_margin="0 1rem 0 0"
                                onClick={(e) => reducirCarruselMovil(e)}
                            >
                                <TextoCorto color="#E8BA35" fontweight="bold">
                                    &lt;
                                </TextoCorto>
                            </BotonCarrusel>
                            <BotonCarrusel bgcolor="#D53A77"
                            xlg_margin="0 0rem 0 1rem"
                            lg_margin="0 0rem 0 1rem"
                            dkt_margin="0 0rem 0 1rem"
                            tb_margin="0 0rem 0 1rem"
                            min_margin="0 0rem 0 1rem"
                                onClick={(e) => aumentarCarruselMovil(e)}
                            >
                                <TextoCorto color="#E8BA35" fontweight="bold">
                                    &gt;
                                </TextoCorto>
                            </BotonCarrusel>
                        </FlexLanding>
                    : null}
                </FlexLanding>
            </ImageBackground>
            <ImageBackground
            xlg_imgurl="/img/landing_fondo_6_1900.png"
            lg_imgurl="/img/landing_fondo_6_1440.png"
            dkt_imgurl="/img/landing_fondo_6_1024.png"
            tb_imgurl="/img/landing_fondo_6_768.png"
            min_imgurl="/img/landing_fondo_6_505.png"
            >
                {widthWindowSize > 767 ? 
                    <FlexLanding direction="column"
                    xlg_margin="2rem 0 6rem 0" xlg_width="100%"
                    lg_margin="0 0 8rem 0" lg_width="100%"
                    dkt_margin="0 0 8rem 0" dkt_width="100%"
                    tb_margin="0 0 8rem 0" tb_width="100%"
                    min_margin="0 0 8rem 0" min_width="100%"
                    >
                        <FlexLanding direction="column" align="center">
                            <TextoCorto fontsize="3rem" fontweight="bold" lineheight="2"
                            xlg_padding="0rem 0 0 0 "
                            lg_padding="0rem 0 0 0 "
                            dkt_padding="0rem 0 0 0 "
                            tb_padding="0rem 0 0 0 "
                            min_padding="0rem 0 0 0 "
                            >¡Contás con todas estas modalidades!</TextoCorto>
                        </FlexLanding>
                        <FlexLanding justify="center" directionmin="column"
                        xlg_margin="3rem 0rem 0 0"
                        lg_margin="3rem 0rem 0 0"
                        dkt_margin="3rem 0rem 0 0"
                        tb_margin="3rem 0rem 0 0"
                        min_margin="3rem 0rem 0 0"
                        >
                            <FlexLanding direction="column"
                            xlg_margin="0 3rem 0 0" xlg_width="40%"
                            lg_margin="0 3rem 0 0" lg_width="40%"
                            dkt_margin="0 3rem 0 0" dkt_width="40%"
                            tb_margin="0 3rem 0 0" tb_width="40%"
                            min_margin="0 3rem 0 0" min_width="40%"
                            >
                                <TextoCorto fontsize="1.3rem" fontweight="bold" lineheight="2" bgcolor="#D53977" color="#FFFFFF" borderradius="10px"
                                xlg_padding="0 0 0 0"
                                lg_padding="0 0 0 0"
                                dkt_padding="0 0 0 0"
                                tb_padding="0 0 0 0"
                                min_padding="0 0 0 0"
                                >SINCRÓNICO</TextoCorto>
                                <TextoCorto fontsize="1rem" fontweight="bold"lineheight="2"
                                xlg_padding="0 0 0 0"
                                lg_padding="0 0 0 0"
                                dkt_padding="0 0 0 0"
                                tb_padding="0 0 0 0"
                                min_padding="0 0 0 0"
                                >( en el consultorio )</TextoCorto>
                                <TextoCorto fontsize="1em" lineheight="2"
                                xlg_padding="1rem 0 0 0"
                                lg_padding="1rem 0 0 0"
                                dkt_padding="1rem 0 0 0"
                                tb_padding="1rem 0 0 0"
                                min_padding="1rem 0 0 0"
                                >Permite el trabajo en el consultorio con un método de enseñanza explícito, sistemático y progresivo. Acompaña con un seguimiento a partir de los los objetivos y de la histórica clínica.</TextoCorto>
                            </FlexLanding>
                            <FlexLanding direction="column"
                            xlg_margin="0 0rem 0 3rem" xlg_width="40%"
                            lg_margin="0 0rem 0 3rem" lg_width="40%"
                            dkt_margin="0 0rem 0 3rem" dkt_width="40%"
                            tb_margin="0 0rem 0 3rem" tb_width="40%"
                            min_margin="0 0rem 0 3rem" min_width="40%"
                            >
                                <TextoCorto fontsize="1.3rem" fontweight="bold" lineheight="2" bgcolor="#E8BA35" color="#FFFFFF" borderradius="10px"
                                xlg_padding="0 0 0 0"
                                lg_padding="0 0 0 0"
                                dkt_padding="0 0 0 0"
                                tb_padding="0 0 0 0"
                                min_padding="0 0 0 0"
                                >MULTISENSORIAL</TextoCorto>
                                <TextoCorto fontsize="1em" fontweight="bold" lineheight="2"
                                xlg_padding="0 0 0 0"
                                lg_padding="0 0 0 0"
                                dkt_padding="0 0 0 0"
                                tb_padding="0 0 0 0"
                                min_padding="0 0 0 0"
                                >( material complementario )</TextoCorto>
                                <TextoCorto fontsize="1em" lineheight="2"
                                xlg_padding="1rem 0 0 0"
                                lg_padding="1rem 0 0 0"
                                dkt_padding="1rem 0 0 0"
                                tb_padding="1rem 0 0 0"
                                min_padding="1rem 0 0 0"
                                >Consideramos que el aprendizaje debe estar acompañado por material cinestésico por lo que ideamos una serie de materiales multisensoriales ideales para acompañar el proceso.</TextoCorto>
                            </FlexLanding>
                        </FlexLanding>
                        <FlexLanding justify="center"
                        xlg_margin="5rem 0 0 0rem"
                        lg_margin="5rem 0 0 0rem"
                        dkt_margin="5rem 0 0 0rem"
                        tb_margin="5rem 0 0 0rem"
                        min_margin="5rem 0 0 0rem"
                        >
                            <FlexLanding direction="column"
                            xlg_margin="0 3rem 0 0" xlg_width="40%"
                            lg_margin="0 3rem 0 0" lg_width="40%"
                            dkt_margin="0 3rem 0 0" dkt_width="40%"
                            tb_margin="0 3rem 0 0" tb_width="40%"
                            min_margin="0 3rem 0 0" min_width="40%"
                            >
                                <TextoCorto fontsize="1.3rem" fontweight="bold" lineheight="2" bgcolor="#4AA4A5" color="#FFFFFF" borderradius="10px"
                                xlg_padding="0 0 0 0"
                                lg_padding="0 0 0 0"
                                dkt_padding="0 0 0 0"
                                tb_padding="0 0 0 0"
                                min_padding="0 0 0 0"
                                >ASINCRÓNICO</TextoCorto>
                                <TextoCorto fontsize="1em" fontweight="bold" lineheight="2"
                                xlg_padding="0 0 0 0"
                                lg_padding="0 0 0 0"
                                dkt_padding="0 0 0 0"
                                tb_padding="0 0 0 0"
                                min_padding="0 0 0 0"
                                >( en su casa )</TextoCorto>
                                <TextoCorto fontsize="1em" lineheight="2"
                                xlg_padding="1rem 0 0 0"
                                lg_padding="1rem 0 0 0"
                                dkt_padding="1rem 0 0 0"
                                tb_padding="1rem 0 0 0"
                                min_padding="1rem 0 0 0"
                                >Ideado para el uso dentro del espacio de tratamiento en el consultorio y como complemento en el domicilio. 
                                El profesional actúa como agente mediador del aprendizaje y selecciona las actividades pertinentes para cada estudiante en cada etapa.</TextoCorto>
                            </FlexLanding>
                            <FlexLanding
                            xlg_margin="0 0rem 0 3rem" xlg_width="40%"
                            lg_margin="0 0rem 0 3rem" lg_width="40%"
                            dkt_margin="0 0rem 0 3rem" dkt_width="40%"
                            tb_margin="0 0rem 0 3rem" tb_width="40%"
                            min_margin="0 0rem 0 3rem" min_width="40%"
                            >
                                <Imagen src={"/img/imagen_landing_2.png"}
                                xlg_width="75%" xlg_height="auto"
                                lg_width="100%" lg_height="auto"
                                dkt_width="100%" dkt_height="auto"
                                tb_width="100%" tb_height="auto"
                                min_width="100%" min_height="auto"
                                />
                            </FlexLanding>
                        </FlexLanding>
                        <FlexLanding justify="center"
                        xlg_margin="0rem 0 0 0rem"
                        lg_margin="3rem 0 0 0rem"
                        dkt_margin="3rem 0 0 0rem"
                        tb_margin="3rem 0 0 0rem"
                        min_margin="3rem 0 0 0rem"
                        >
                            <BotonLanding bgcolor="#6261D6" fontweight="bold"
                            xlg_margin="1.5rem 0 0rem 0" xlg_padding="0.3rem 1rem" xlg_width="auto"
                            lg_margin="1.5rem 0 0rem 0" lg_padding="0.3rem 1rem" lg_width="auto"
                            dkt_margin="1.5rem 0 0rem 0" dkt_padding="0.3rem 1rem" dkt_width="auto"
                            tb_margin="1.5rem 0 0rem 0" tb_padding="0.3rem 1rem" tb_width="auto"
                            min_margin="1.5rem 0 0rem 0" min_padding="0.3rem 1rem" min_width="auto"
                            onClick={(e) => irLink(e,"suscribite")}
                            >
                                LO QUIERO!
                            </BotonLanding>
                        </FlexLanding>
                    </FlexLanding>
                :
                    <FlexLanding direction="column" align="center"
                    min_margin="0 0 8rem 0" min_width="100%"
                    >
                        <FlexLanding direction="column" align="center">
                            <TextoCorto fontsize="3rem" fontweight="bold" lineheight="2"
                            min_padding="0rem 0 0 0 "
                            >¡Contás con todas estas modalidades!</TextoCorto>
                        </FlexLanding>
                        {carruselMovilDos === 0 ? 
                            <FlexLanding direction="column"
                            min_margin="0 0rem 0 0" min_width="80%"
                            >
                                <TextoCorto fontsize="1.3rem" fontweight="bold" lineheight="2" bgcolor="#D53977" color="#FFFFFF" borderradius="10px"
                                min_padding="0 0 0 0"
                                >SINCRÓNICO</TextoCorto>
                                <TextoCorto fontsize="1rem" fontweight="bold"lineheight="2"
                                min_padding="0 0 0 0"
                                >( en el consultorio )</TextoCorto>
                                <TextoCorto fontsize="1em" lineheight="2"
                                min_padding="1rem 0 0 0"
                                >Permite el trabajo en el consultorio con un método de enseñanza explícito, sistemático y progresivo. Acompaña con un seguimiento a partir de los los objetivos y de la histórica clínica.</TextoCorto>
                            </FlexLanding>
                        : null}
                        {carruselMovilDos === 1 ? 
                            <FlexLanding direction="column"
                            min_margin="0 0rem 0 0rem" min_width="80%"
                            >
                                <TextoCorto fontsize="1.3rem" fontweight="bold" lineheight="2" bgcolor="#E8BA35" color="#FFFFFF" borderradius="10px"
                                min_padding="0 0 0 0"
                                >MULTISENSORIAL</TextoCorto>
                                <TextoCorto fontsize="1em" fontweight="bold" lineheight="2"
                                xlg_padding="0 0 0 0"
                                lg_padding="0 0 0 0"
                                dkt_padding="0 0 0 0"
                                tb_padding="0 0 0 0"
                                min_padding="0 0 0 0"
                                >( material complementario )</TextoCorto>
                                <TextoCorto fontsize="1em" lineheight="2"
                                xlg_padding="1rem 0 0 0"
                                lg_padding="1rem 0 0 0"
                                dkt_padding="1rem 0 0 0"
                                tb_padding="1rem 0 0 0"
                                min_padding="1rem 0 0 0"
                                >Consideramos que el aprendizaje debe estar acompañado por material cinestésico por lo que ideamos una serie de materiales multisensoriales ideales para acompañar el proceso.</TextoCorto>
                            </FlexLanding>
                        : null}
                        {carruselMovilDos === 2 ? 
                            <FlexLanding direction="column"
                            min_margin="0 0rem 0 0" min_width="80%"
                            >
                                <TextoCorto fontsize="1.3rem" fontweight="bold" lineheight="2" bgcolor="#4AA4A5" color="#FFFFFF" borderradius="10px"
                                min_padding="0 0 0 0"
                                >ASINCRÓNICO</TextoCorto>
                                <TextoCorto fontsize="1em" fontweight="bold" lineheight="2"
                                min_padding="0 0 0 0"
                                >( en su casa )</TextoCorto>
                                <TextoCorto fontsize="1em" lineheight="2"
                                min_padding="1rem 0 0 0"
                                >Ideado para el uso dentro del espacio de tratamiento en el consultorio y como complemento en el domicilio. 
                                El profesional actúa como agente mediador del aprendizaje y selecciona las actividades pertinentes para cada estudiante en cada etapa.</TextoCorto>
                            </FlexLanding>
                        : null}
                        {widthWindowSize <= 767 ? 
                            <FlexLanding margin="1rem 0 0 0">
                                <BotonCarrusel bgcolor="#D53A77"
                                xlg_margin="0 1rem 0 0"
                                lg_margin="0 1rem 0 0"
                                dkt_margin="0 1rem 0 0"
                                tb_margin="0 1rem 0 0"
                                min_margin="0 1rem 0 0"
                                    onClick={(e) => reducirCarruselMovilDos(e)}
                                >
                                    <TextoCorto color="#E8BA35" fontweight="bold">
                                        &lt;
                                    </TextoCorto>
                                </BotonCarrusel>
                                <BotonCarrusel bgcolor="#D53A77"
                                xlg_margin="0 0rem 0 1rem"
                                lg_margin="0 0rem 0 1rem"
                                dkt_margin="0 0rem 0 1rem"
                                tb_margin="0 0rem 0 1rem"
                                min_margin="0 0rem 0 1rem"
                                    onClick={(e) => aumentarCarruselMovilDos(e)}
                                >
                                    <TextoCorto color="#E8BA35" fontweight="bold">
                                        &gt;
                                    </TextoCorto>
                                </BotonCarrusel>
                            </FlexLanding>
                        : null}
                        <FlexLanding justify="center"
                        xlg_margin="5rem 0 0 0rem"
                        lg_margin="5rem 0 0 0rem"
                        dkt_margin="5rem 0 0 0rem"
                        tb_margin="5rem 0 0 0rem"
                        min_margin="5rem 0 0 0rem"
                        >
                            
                            <FlexLanding
                            xlg_margin="0 0rem 0 3rem" xlg_width="40%"
                            lg_margin="0 0rem 0 3rem" lg_width="40%"
                            dkt_margin="0 0rem 0 3rem" dkt_width="40%"
                            tb_margin="0 0rem 0 3rem" tb_width="40%"
                            min_margin="0 0rem 0 3rem" min_width="40%"
                            >
                                <Imagen src={"/img/imagen_landing_2.png"}
                                xlg_width="75%" xlg_height="auto"
                                lg_width="100%" lg_height="auto"
                                dkt_width="100%" dkt_height="auto"
                                tb_width="100%" tb_height="auto"
                                min_width="100%" min_height="auto"
                                />
                            </FlexLanding>
                        </FlexLanding>
                        <FlexLanding justify="center"
                        xlg_margin="0rem 0 0 0rem"
                        lg_margin="3rem 0 0 0rem"
                        dkt_margin="3rem 0 0 0rem"
                        tb_margin="3rem 0 0 0rem"
                        min_margin="3rem 0 0 0rem"
                        >
                            <BotonLanding bgcolor="#6261D6" fontweight="bold"
                            xlg_margin="1.5rem 0 0rem 0" xlg_padding="0.3rem 1rem" xlg_width="auto"
                            lg_margin="1.5rem 0 0rem 0" lg_padding="0.3rem 1rem" lg_width="auto"
                            dkt_margin="1.5rem 0 0rem 0" dkt_padding="0.3rem 1rem" dkt_width="auto"
                            tb_margin="1.5rem 0 0rem 0" tb_padding="0.3rem 1rem" tb_width="auto"
                            min_margin="1.5rem 0 0rem 0" min_padding="0.3rem 1rem" min_width="auto"
                            onClick={(e) => irLink(e,"suscribite")}
                            >
                                LO QUIERO!
                            </BotonLanding>
                        </FlexLanding>
                    </FlexLanding>
                }
            </ImageBackground>
            <ImageBackground
            xlg_imgurl="/img/landing_fondo_7_1900.png"
            lg_imgurl="/img/landing_fondo_7_1440.png"
            dkt_imgurl="/img/landing_fondo_7_1024.png"
            tb_imgurl="/img/landing_fondo_7_768.png"
            min_imgurl="/img/landing_fondo_7_505.png"
            >
                <FlexLanding direction="column" align="center"
                xlg_margin="5rem 0 6rem 0" xlg_width="100%"
                lg_margin="4rem 0 5rem 0" lg_width="100%"
                dkt_margin="4rem 0 5rem 0" dkt_width="100%"
                tb_margin="4rem 0 5rem 0" tb_width="100%"
                min_margin="2rem 0 0rem 0" min_width="100%"
                >
                    <FlexLanding align="center">
                        <TextoCorto fontsize="3rem" fontweight="bold" color="#FFFFFF"
                        xlg_padding="0 0 0 0"
                        lg_padding="0 0 0 0"
                        dkt_padding="0 0 0 0"
                        tb_padding="0 0 0 0"
                        min_padding="0 0 0 0"
                        >Accedé a todos los sectores</TextoCorto>
                    </FlexLanding>
                    {widthWindowSize > 767 ?
                        <FlexLanding align="center"
                        xlg_margin="5rem 0 0 0"
                        lg_margin="5rem 0 0 0"
                        dkt_margin="5rem 0 0 0"
                        tb_margin="5rem 0 0 0"
                        min_margin="2.5rem 0 0 0"
                        >
                            {carruselDos === 0 || carruselDos === 4 || carruselDos === 5 ? 
                            <FlexLanding direction="column" align="center" order={carruselDos === 0 ? "0" : carruselDos === 4 ? "2" : "1"}>
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                xlg_padding="0.5rem 1rem 0.5rem 1rem"
                                lg_padding="0.5rem 1rem 0.5rem 1rem"
                                dkt_padding="0.5rem 1rem 0.5rem 1rem"
                                tb_padding="0.5rem 1rem 0.5rem 1rem"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >ADMINISTRATIVO</TextoCorto>
                                <Imagen src={"/img/landing_administracion.png"}
                                xlg_margin="1rem 0 0 0" xlg_width="" xlg_height=""
                                lg_margin="1rem 0 0 0" lg_width="" lg_height=""
                                dkt_margin="1rem 0 0 0" dkt_width="" dkt_height=""
                                tb_margin="1rem 0 0 0" tb_width="100%" tb_height="100%"
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselDos === 0 || carruselDos === 1 || carruselDos === 5 ? 
                            <FlexLanding direction="column" align="center" order={carruselDos === 0 ? "1" : carruselDos === 1 ? "0" : "2"}>
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                xlg_padding="0.5rem 1rem 0.5rem 1rem"
                                lg_padding="0.5rem 1rem 0.5rem 1rem"
                                dkt_padding="0.5rem 1rem 0.5rem 1rem"
                                tb_padding="0.5rem 1rem 0.5rem 1rem"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >LECTURA Y ESCRITURA</TextoCorto>
                                <Imagen src={"/img/landing_lectoescritura.png"} 
                                xlg_margin="1rem 0 0 0" xlg_width="" xlg_height=""
                                lg_margin="1rem 0 0 0" lg_width="" lg_height=""
                                dkt_margin="1rem 0 0 0" dkt_width="" dkt_height=""
                                tb_margin="1rem 0 0 0" tb_width="100%" tb_height="100%"
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselDos === 0 || carruselDos === 1 || carruselDos === 2 ?
                            <FlexLanding direction="column" align="center" order={carruselDos === 0 ? "2" : carruselDos === 1 ? "1" : "0"}>
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                xlg_padding="0.5rem 1rem 0.5rem 1rem"
                                lg_padding="0.5rem 1rem 0.5rem 1rem"
                                dkt_padding="0.5rem 1rem 0.5rem 1rem"
                                tb_padding="0.5rem 1rem 0.5rem 1rem"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >MATEMÁTICA</TextoCorto>
                                <Imagen src={"/img/landing_matematica.png"}
                                xlg_margin="1rem 0 0 0" xlg_width="" xlg_height=""
                                lg_margin="1rem 0 0 0" lg_width="" lg_height=""
                                dkt_margin="1rem 0 0 0" dkt_width="" dkt_height=""
                                tb_margin="1rem 0 0 0" tb_width="100%" tb_height="100%"
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselDos === 1 || carruselDos === 2 || carruselDos === 3 ?
                            <FlexLanding direction="column" align="center" order={carruselDos === 1 ? "2" : carruselDos === 2 ? "1" : "0"}>
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                xlg_padding="0.5rem 1rem 0.5rem 1rem"
                                lg_padding="0.5rem 1rem 0.5rem 1rem"
                                dkt_padding="0.5rem 1rem 0.5rem 1rem"
                                tb_padding="0.5rem 1rem 0.5rem 1rem"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >LENGUAJE</TextoCorto>
                                <Imagen src={"/img/landing_lenguaje.png"}
                                xlg_margin="1rem 0 0 0" xlg_width="" xlg_height=""
                                lg_margin="1rem 0 0 0" lg_width="" lg_height=""
                                dkt_margin="1rem 0 0 0" dkt_width="" dkt_height=""
                                tb_margin="1rem 0 0 0" tb_width="100%" tb_height="100%"
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselDos === 2 || carruselDos === 3 || carruselDos === 4 ?
                            <FlexLanding direction="column" align="center" order={carruselDos === 2 ? "2" : carruselDos === 3 ? "1" : "0"}>
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                xlg_padding="0.5rem 1rem 0.5rem 1rem"
                                lg_padding="0.5rem 1rem 0.5rem 1rem"
                                dkt_padding="0.5rem 1rem 0.5rem 1rem"
                                tb_padding="0.5rem 1rem 0.5rem 1rem"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >COGNICIÓN</TextoCorto>
                                <Imagen src={"/img/landing_cognicion.png"}
                                xlg_margin="1rem 0 0 0" xlg_width="" xlg_height=""
                                lg_margin="1rem 0 0 0" lg_width="" lg_height=""
                                dkt_margin="1rem 0 0 0" dkt_width="" dkt_height=""
                                tb_margin="1rem 0 0 0" tb_width="100%" tb_height="100%"
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselDos === 3 || carruselDos === 4 || carruselDos === 5 ?
                            <FlexLanding direction="column" align="center" order={carruselDos === 3 ? "2" : carruselDos === 4 ? "1" : "0"}>
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                xlg_padding="0.5rem 1rem 0.5rem 1rem"
                                lg_padding="0.5rem 1rem 0.5rem 1rem"
                                dkt_padding="0.5rem 1rem 0.5rem 1rem"
                                tb_padding="0.5rem 1rem 0.5rem 1rem"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >EMOCIÓN</TextoCorto>
                                <Imagen src={"/img/landing_emocion.png"}
                                xlg_margin="1rem 0 0 0" xlg_width="" xlg_height=""
                                lg_margin="1rem 0 0 0" lg_width="" lg_height=""
                                dkt_margin="1rem 0 0 0" dkt_width="" dkt_height=""
                                tb_margin="1rem 0 0 0" tb_width="100%" tb_height="100%"
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                        </FlexLanding>
                    :
                        <FlexLanding align="center"
                        min_margin="5rem 0 0 0"
                        >
                            {carruselMovilTres === 0 ? 
                            <FlexLanding direction="column" align="center">
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >ADMINISTRATIVO</TextoCorto>
                                <Imagen src={"/img/landing_administracion.png"}
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselMovilTres === 1 ? 
                            <FlexLanding direction="column" align="center">
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                xlg_padding="0.5rem 1rem 0.5rem 1rem"
                                lg_padding="0.5rem 1rem 0.5rem 1rem"
                                dkt_padding="0.5rem 1rem 0.5rem 1rem"
                                tb_padding="0.5rem 1rem 0.5rem 1rem"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >LECTURA Y ESCRITURA</TextoCorto>
                                <Imagen src={"/img/landing_lectoescritura.png"} 
                                xlg_margin="1rem 0 0 0" xlg_width="" xlg_height=""
                                lg_margin="1rem 0 0 0" lg_width="" lg_height=""
                                dkt_margin="1rem 0 0 0" dkt_width="" dkt_height=""
                                tb_margin="1rem 0 0 0" tb_width="100%" tb_height="100%"
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselMovilTres === 2 ? 
                            <FlexLanding direction="column" align="center">
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                xlg_padding="0.5rem 1rem 0.5rem 1rem"
                                lg_padding="0.5rem 1rem 0.5rem 1rem"
                                dkt_padding="0.5rem 1rem 0.5rem 1rem"
                                tb_padding="0.5rem 1rem 0.5rem 1rem"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >MATEMÁTICA</TextoCorto>
                                <Imagen src={"/img/landing_matematica.png"}
                                xlg_margin="1rem 0 0 0" xlg_width="" xlg_height=""
                                lg_margin="1rem 0 0 0" lg_width="" lg_height=""
                                dkt_margin="1rem 0 0 0" dkt_width="" dkt_height=""
                                tb_margin="1rem 0 0 0" tb_width="100%" tb_height="100%"
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselMovilTres === 3 ? 
                            <FlexLanding direction="column" align="center">
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >LENGUAJE</TextoCorto>
                                <Imagen src={"/img/landing_lenguaje.png"}
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselMovilTres === 4 ? 
                            <FlexLanding direction="column" align="center">
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >COGNICIÓN</TextoCorto>
                                <Imagen src={"/img/landing_cognicion.png"}
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                            {carruselMovilTres === 5 ? 
                            <FlexLanding direction="column" align="center">
                                <TextoCorto fontsize="1.3rem" fontweight="bold" color="#FFFFFF" bgcolor="#6261D4" borderradius="10px"
                                min_padding="0.5rem 1rem 0.5rem 1rem"
                                >EMOCIÓN</TextoCorto>
                                <Imagen src={"/img/landing_emocion.png"}
                                min_margin="1rem 0 0 0" min_width="" min_height=""
                                />
                            </FlexLanding>
                            : null}
                        </FlexLanding>
                    }
                    {widthWindowSize > 767 ?
                        <FlexLanding
                        xlg_margin="2rem 0 0 0"
                        lg_margin="2rem 0 0 0"
                        dkt_margin="2rem 0 0 0"
                        tb_margin="2rem 0 0 0"
                        min_margin="2rem 0 0 0"
                        >
                            <BotonCarrusel bgcolor="#E8BA35"
                            xlg_margin="0 1rem 0 0"
                            lg_margin="0 1rem 0 0"
                            dkt_margin="0 1rem 0 0"
                            tb_margin="0 1rem 0 0"
                            min_margin="0 1rem 0 0"
                                onClick={(e) => reducirCarruselDos(e)}
                            >
                                <TextoCorto color="#D53A77" fontweight="bold">
                                    &lt;
                                </TextoCorto>
                            </BotonCarrusel>
                            <BotonCarrusel bgcolor="#E8BA35"
                            xlg_margin="0 0rem 0 1rem"
                            lg_margin="0 0rem 0 1rem"
                            dkt_margin="0 0rem 0 1rem"
                            tb_margin="0 0rem 0 1rem"
                            min_margin="0 0rem 0 1rem"
                                onClick={(e) => aumentarCarruselDos(e)}
                            >
                                <TextoCorto color="#D53A77" fontweight="bold">
                                    &gt;
                                </TextoCorto>
                            </BotonCarrusel>
                        </FlexLanding>
                    :
                        <FlexLanding
                        xlg_margin="2rem 0 0 0"
                        lg_margin="2rem 0 0 0"
                        dkt_margin="2rem 0 0 0"
                        tb_margin="2rem 0 0 0"
                        min_margin="2rem 0 1rem 0"
                        >
                            <BotonCarrusel bgcolor="#E8BA35"
                            xlg_margin="0 1rem 0 0"
                            lg_margin="0 1rem 0 0"
                            dkt_margin="0 1rem 0 0"
                            tb_margin="0 1rem 0 0"
                            min_margin="0 1rem 0 0"
                                onClick={(e) => reducirCarruselMovilTres(e)}
                            >
                                <TextoCorto color="#D53A77" fontweight="bold">
                                    &lt;
                                </TextoCorto>
                            </BotonCarrusel>
                            <BotonCarrusel bgcolor="#E8BA35"
                            xlg_margin="0 0rem 0 1rem"
                            lg_margin="0 0rem 0 1rem"
                            dkt_margin="0 0rem 0 1rem"
                            tb_margin="0 0rem 0 1rem"
                            min_margin="0 0rem 0 1rem"
                                onClick={(e) => aumentarCarruselMovilTres(e)}
                            >
                                <TextoCorto color="#D53A77" fontweight="bold">
                                    &gt;
                                </TextoCorto>
                            </BotonCarrusel>
                        </FlexLanding>
                    }
                </FlexLanding>
            </ImageBackground>
            <ImageBackground
            xlg_imgurl="/img/landing_fondo_8_1900.png"
            lg_imgurl="/img/landing_fondo_8_1440.png"
            dkt_imgurl="/img/landing_fondo_8_1024.png"
            tb_imgurl="/img/landing_fondo_8_768.png"
            min_imgurl="/img/landing_fondo_8_505.png"
            >
                <FlexLanding direction="column" align="center"
                xlg_margin="7rem 0 14rem 0" xlg_width="100%"
                lg_margin="7rem 0 10rem 0" lg_width="100%"
                dkt_margin="7rem 0 10rem 0" dkt_width="100%"
                tb_margin="5rem 0 8rem 0" tb_width="100%"
                min_margin="2.5rem 0 8rem 0" min_width="100%"
                >
                    <TextoCorto fontsize="2rem" fontweight="bold"
                    xlg_padding="0 0 0 0"
                    lg_padding="0 0 0 0"
                    dkt_padding="0 0 0 0"
                    tb_padding="0 0 0 0"
                    min_padding="0 0 0 0"
                    >MODIFICÁ TU EXPERIENCIA</TextoCorto>
                    <TextoCorto fontsize="1.2rem" lineheight="2"
                    xlg_margin="3rem 0 0 0"
                    lg_margin="3rem 0 0 0"
                    dkt_margin="3rem 0 0 0"
                    tb_margin="3rem 0 0 0"
                    min_margin="3rem 0 0 0"
                    >Incorporá METODO YÖGEN a tu práctica profesional</TextoCorto>
                    <BotonLanding bgcolor="#D53A77" fontweight="bold"
                    xlg_margin="3rem 0 0rem 0" xlg_padding="0.3rem 1rem" xlg_width="auto"
                    lg_margin="1.5rem 0 0rem 0" lg_padding="0.3rem 1rem" lg_width="auto"
                    dkt_margin="1.5rem 0 0rem 0" dkt_padding="0.3rem 1rem" dkt_width="auto"
                    tb_margin="1.5rem 0 0rem 0" tb_padding="0.3rem 1rem" tb_width="auto"
                    min_margin="1.5rem 0 0rem 0" min_padding="0.3rem 1rem" min_width="auto"
                        onClick={(e) => irLink(e,"suscribite")}
                    >
                        SUSCRIBIRME
                    </BotonLanding>
                    <BotonLanding bgcolor="#23AAAF" fontweight="bold"
                    xlg_margin="3rem 0 0rem 0" xlg_padding="0.3rem 1rem" xlg_width="auto"
                    lg_margin="1.5rem 0 0rem 0" lg_padding="0.3rem 1rem" lg_width="auto"
                    dkt_margin="1.5rem 0 0rem 0" dkt_padding="0.3rem 1rem" dkt_width="auto"
                    tb_margin="1.5rem 0 0rem 0" tb_padding="0.3rem 1rem" tb_width="auto"
                    min_margin="1.5rem 0 0rem 0" min_padding="0.3rem 1rem" min_width="auto"
                        onClick={(e) => irLink(e,"suscribite")}
                    >
                        VERSIÓN DEMO POR 15 DÍAS
                    </BotonLanding>
                </FlexLanding>
                <FlexLanding direction="column" align="center"
                xlg_margin="0rem 0 0rem 0" xlg_width="100%"
                lg_margin="0rem 0 0rem 0" lg_width="100%"
                dkt_margin="0rem 0 0rem 0" dkt_width="100%"
                tb_margin="0rem 0 0rem 0" tb_width="100%"
                min_margin="0rem 0 0rem 0" min_width="100%"
                >
                    <TextoCorto fontsize="1rem" lineheight="2" color="#FFFFFF"
                    xlg_margin="3rem 0 0 0"
                    lg_margin="3rem 0 0 0"
                    dkt_margin="3rem 0 0 0"
                    tb_margin="3rem 0 0 0"
                    min_margin="3rem 0 0 0"
                    >Creado por psicopedagogos para psicopedagogos</TextoCorto>
                </FlexLanding>
            </ImageBackground>
            <FlexLanding direction="column" align="center" bgcolor="#6261D4"
            xlg_padding="2rem 0 0rem 0" xlg_width="100%"
            lg_padding="2rem 0 0rem 0" lg_width="100%"
            dkt_padding="2rem 0 0rem 0" dkt_width="100%"
            tb_padding="2rem 0 0rem 0" tb_width="100%"
            min_padding="2rem 0 0rem 0" min_width="100%"
            >
                <FlexLanding justify="space-around" directionmin="column"
                xlg_width="100%"
                lg_width="100%"
                dkt_width="100%"
                tb_width="100%"
                min_width="100%"
                >
                    <FlexLanding direction="column" align="start" alignmin="center">
                        <TextoCorto fontsize="1rem" fontweight="bold"
                        xlg_padding="0rem 0 0 0"
                        lg_padding="0rem 0 0 0"
                        dkt_padding="0rem 0 0 0"
                        tb_padding="0rem 0 0 0"
                        min_padding="0rem 0 0 0"
                        >CONTACTO</TextoCorto>
                        <TextoCorto fontsize="0.9rem"
                        xlg_margin="1rem 0 0 0"
                        lg_margin="1rem 0 0 0"
                        dkt_margin="1rem 0 0 0"
                        tb_margin="1rem 0 0 0"
                        min_margin="1rem 0 0 0"
                        >Mail: contacto@metodoyogen.com</TextoCorto>
                        <FlexLanding justify="space-between"
                        xlg_margin="1.5rem 0 0 0" xlg_padding="0 4rem 0 0"
                        lg_margin="1.5rem 0 0 0" lg_padding="0 4rem 0 0"
                        dkt_margin="1.5rem 0 0 0" dkt_padding="0 4rem 0 0"
                        tb_margin="1.5rem 0 0 0" tb_padding="0 4rem 0 0"
                        min_margin="1.5rem 0 0 0" min_padding="0 4rem 0 0"
                        >
                            <Imagen src={`/img/icono_instagram.png`} alt="instagram"
                            xlg_width="20px" xlg_height="20px"
                            lg_width="20px" lg_height="20px"
                            dkt_width="20px" dkt_height="20px"
                            tb_width="20px" tb_height="20px"
                            min_width="20px" min_height="20px"
                            min_margin="0 0.5rem 0 0"
                            cursor="pointer"
                                onClick={(e) => irLink(e,"instagram")}
                            />
                            <Imagen src={`/img/icono_facebook.png`} alt="facebook"
                            xlg_width="20px" xlg_height="20px"
                            lg_width="20px" lg_height="20px"
                            dkt_width="20px" dkt_height="20px"
                            tb_width="20px" tb_height="20px"
                            min_width="20px" min_height="20px"
                            min_margin="0 0.5rem 0 0"
                            cursor="pointer"
                                onClick={(e) => irLink(e,"facebook")}
                            />
                            <Imagen src={`/img/icono_twitter.png`} alt="twitter"
                            xlg_width="20px" xlg_height="20px"
                            lg_width="20px" lg_height="20px"
                            dkt_width="20px" dkt_height="20px"
                            tb_width="20px" tb_height="20px"
                            min_width="20px" min_height="20px"
                            min_margin="0 0.5rem 0 0"
                            cursor="pointer"
                                onClick={(e) => irLink(e,"twitter")}
                            />
                            <Imagen src={`/img/icono_youtube.png`} alt="youtube"
                            xlg_width="20px" xlg_height="20px"
                            lg_width="20px" lg_height="20px"
                            dkt_width="20px" dkt_height="20px"
                            tb_width="20px" tb_height="20px"
                            min_width="20px" min_height="20px"
                            min_margin="0 0.5rem 0 0"
                            cursor="pointer"
                                onClick={(e) => irLink(e,"youtube")}
                            />
                            <Imagen src={`/img/icono_google.png`} alt="google"
                            xlg_width="20px" xlg_height="20px"
                            lg_width="20px" lg_height="20px"
                            dkt_width="20px" dkt_height="20px"
                            tb_width="20px" tb_height="20px"
                            min_width="20px" min_height="20px"
                            cursor="pointer"
                                onClick={(e) => irLink(e,"google")}
                            />
                        </FlexLanding>
                        <TextoCorto dkt_margin="1rem 0 0 0" fontsize="0.9rem"
                        
                        >Mail: ventas@metodoyogen.com</TextoCorto>
                    </FlexLanding>
                    <FlexLanding direction="column" align="start" alignmin="center">
                        <TextoCorto fontsize="1rem" fontweight="bold"
                        xlg_padding="0 0 0 0"
                        lg_padding="0 0 0 0"
                        dkt_padding="0 0 0 0"
                        tb_padding="0 0 0 0"
                        min_padding="0.5rem 0 0 0"
                        >ENLACES RÁPIDOS</TextoCorto>
                        <TextoCorto fontsize="0.9rem"
                        xlg_margin="1rem 0 0 0"
                        lg_margin="1rem 0 0 0"
                        dkt_margin="1rem 0 0 0"
                        tb_margin="1rem 0 0 0"
                        min_margin="0.5rem 0 0 0"
                        cursor="pointer"
                            onClick={(e) => irLink(e,"suscribite")}
                        >Suscripción</TextoCorto>
                        <TextoCorto fontsize="0.9rem"
                        xlg_padding="0 0 0 0"
                        lg_padding="0 0 0 0"
                        dkt_padding="0 0 0 0"
                        tb_padding="0 0 0 0"
                        min_margin="0.4rem 0 0 0"
                        cursor="pointer"
                            onClick={(e) => irLink(e,"tienda")}
                        >Tienda online</TextoCorto>
                    </FlexLanding>
                    <FlexLanding direction="column" align="start" alignmin="center">
                        <TextoCorto fontsize="1rem" fontweight="bold"
                        xlg_padding="0 0 0 0"
                        lg_padding="0 0 0 0"
                        dkt_padding="0 0 0 0"
                        tb_padding="0 0 0 0"
                        min_padding="0.5rem 0 0 0"
                        >ATRIBUCIONES</TextoCorto>
                        <TextoCorto fontsize="0.9rem"
                        xlg_margin="1rem 0 0 0"
                        lg_margin="1rem 0 0 0"
                        dkt_margin="1rem 0 0 0"
                        tb_margin="1rem 0 0 0"
                        min_margin="1rem 0 0 0"
                        >Imagenes: Ayelen Chesini, freepick</TextoCorto>
                    </FlexLanding>
                </FlexLanding>
                <FlexLanding align="center" justify="space-around" directionmin="column"
                xlg_margin="4rem 0 0 0" xlg_width="100%"
                lg_margin="4rem 0 0 0" lg_width="100%"
                dkt_margin="4rem 0 0 0" dkt_width="100%"
                tb_margin="4rem 0 0 0" tb_width="100%"
                min_margin="2rem 0 0 0" min_width="100%"
                >
                    <FlexLanding>
                        <Imagen src={`/img/icono_copyright.png`} alt="copyright"
                        xlg_width="20px" xlg_height="20px"
                        lg_width="20px" lg_height="20px"
                        dkt_width="20px" dkt_height="20px"
                        tb_width="20px" tb_height="20px"
                        min_width="20px" min_height="20px"
                        />
                        <TextoCorto fontsize="0.9rem"
                        xlg_padding="0 0 0 0"
                        lg_padding="0 0 0 0"
                        dkt_padding="0 0 0 0"
                        tb_padding="0 0 0 0"
                        min_padding="0 0 0 0"
                        >2023, Método Yögen</TextoCorto>
                    </FlexLanding>
                    <TextoCorto fontsize="0.9rem"
                    xlg_padding="0 0 0 0"
                    lg_padding="0 0 0 0"
                    dkt_padding="0 0 0 0"
                    tb_padding="0 0 0 0"
                    min_margin="0.2rem 0 0 0"
                    >El programa garantiza mejor funcionamiento<br/> con los navegadores Chrome y Firefox</TextoCorto>
                    <TextoCorto fontsize="0.9rem"
                    xlg_padding="0 0 0 0"
                    lg_padding="0 0 0 0"
                    dkt_padding="0 0 0 0"
                    tb_padding="0 0 0 0"
                    min_margin="0.2rem 0 0.2rem 0"
                    >Pantalla y resolución mínima: Tablet</TextoCorto>
                </FlexLanding>
            </FlexLanding>
        </Fragment>
    );
}

export default Main;