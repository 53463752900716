import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, ContainerImg, FlexLink, Descripcion } from '../../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../../Css/StyledHeader';

const CCLexica = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lenguaje/"} l3={"conciencia-fonologica/"} n1={"Métodos"} n2={"LENGUAJE"} n3={"CONCIENCIA FONOLÓGICA"} componente={"CCLEXICA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Container justify="center" direction="column" width="100%" height="100%">
                <Container justify="space-around">
                    <FlexLink  to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN15'}><ContainerImg src={`/img/portadas/LEN15.jpg`} alt=""/> <Descripcion>INTRODUCCION</Descripcion></FlexLink >
                    <FlexLink  to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN16'}><ContainerImg src={`/img/portadas/LEN16.jpg`} alt=""/><Descripcion>CLASIFICACIÓN PALABRAS</Descripcion></FlexLink >
                    <FlexLink  to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN17'}><ContainerImg src={`/img/portadas/LEN17.jpg`} alt=""/><Descripcion>aRMAR ORACIÓN</Descripcion></FlexLink >
                </Container>
                <Container justify="space-around" margin="50px 0px 0px 0px">
                    <FlexLink  to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN18'}><ContainerImg src={`/img/portadas/LEN18.jpg`} alt=""/><Descripcion>ENSAMBLAR PALABRAS</Descripcion></FlexLink >
                    <FlexLink  to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN19'}><ContainerImg src={`/img/portadas/LEN19.jpg`} alt=""/><Descripcion>SEGMENTAR PALABRAS</Descripcion></FlexLink >
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN30'}><ContainerImg src={`/img/portadas/LEN30.jpg`} alt=""/> <Descripcion>Misma palabra</Descripcion></FlexLink>
                </Container>
            </Container>
        </Fragment>    
    );
}
 
export default CCLexica;