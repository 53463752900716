import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../../Metodos/Compartidos/BotoneraBottom';
import M61Bis from './M61Bis';
import M61Practica from './M61Practica';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, PasosSubT, Numeros, PasosT, Pasos, NumCalculo, DivFlexM82, Menos, FlechaP, ResultLeft, ResultRight, Out, DownLeft, DownRight, UpLeft, UpRight, Flecha, Camila, Camila2 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la suma.';
const miniInstrucciones = 'Resuelve la suma.';

const M61 = () => {

    const dispatch = useDispatch();

    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);

    useEffect(() => {
        dispatch(setearEjercicioAction('M61') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/suma-tradicional/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            <Fragment>
                    {iniciar || fuerzoInicio
                        ?
                        <Fragment>
                            {contador=== 0 &&
                            <div>
                                {contadorSecundario >= 0 &&
                                <FlexFull justify='space-evenly' align='center' height='80vh'>
                                    {/* CALCULO Y CUADRICULA */}
                                    <FlexFull direction='column' justify='center' align='center' width='40%' wm='40%' wt='50%' wd='50%' wlg='50%' height='76vh'>
                                        {/* CALCULO */}
                                        <FlexFull justify='start' align='center' width='100%' height='35%'>
                                            <FlexFull justify='start' align='center' height='fit-content'> 
                                                <NumCalculo color='#D76970'>56</NumCalculo>
                                                <NumCalculo color='#646464'>+</NumCalculo>
                                                <NumCalculo color='#23AAAF'>35</NumCalculo>
                                                <NumCalculo color='#646464'>=</NumCalculo>
                                                {contadorSecundario >= 15 && <NumCalculo color='#646464'>91</NumCalculo>}
                                            </FlexFull>
                                        </FlexFull>
                                        {/* FIN CALCULO */}
                                        {/* CUADRICULA */}
                                        <Flex margin='0px 10vw 0px 0px'>
                                            <DivFlexM82>
                                                <Flex>
                                                    <Flecha>
                                                        {contadorSecundario >= 12 &&
                                                            <FlechaP>↓</FlechaP>
                                                        }
                                                    </Flecha>
                                                    <Flecha>
                                                        {contadorSecundario >= 7 &&
                                                            <FlechaP>↓</FlechaP>
                                                        }
                                                    </Flecha>
                                                </Flex>
                                                <Flex>
                                                    <Flecha>
                                                        {contadorSecundario >= 9 &&
                                                        <Numeros>1</Numeros>
                                                        } 
                                                    </Flecha>
                                                    <Flecha>
                                                        
                                                    </Flecha>
                                                </Flex>
                                                <Flex>
                                                    <UpRight>
                                                        {contadorSecundario >= 2 &&    
                                                        <Numeros>5</Numeros>
                                                        } 
                                                    </UpRight>
                                                    <UpLeft>
                                                        {contadorSecundario >= 3 &&
                                                        <Numeros>6</Numeros>
                                                        }
                                                    </UpLeft>
                                                </Flex>
                                                <Flex>
                                                    <Out>
                                                        {contadorSecundario >= 6 &&
                                                        <Menos>+</Menos>
                                                        }
                                                    </Out>
                                                    <DownLeft>
                                                    {contadorSecundario >= 4 &&
                                                    <Numeros>3</Numeros>
                                                    }
                                                    </DownLeft>
                                                    <DownRight>
                                                    {contadorSecundario >= 5 &&
                                                    <Numeros>5</Numeros>
                                                    }
                                                    </DownRight>
                                                </Flex>
                                                <Flex>
                                                    <ResultLeft>
                                                        {contadorSecundario >= 13 &&
                                                        <Numeros>9</Numeros>
                                                        }
                                                    </ResultLeft>
                                                    <ResultRight>
                                                        {contadorSecundario >= 10 &&
                                                        <Numeros>1</Numeros>
                                                        }
                                                    </ResultRight>
                                                </Flex>
                                            </DivFlexM82>
                                        </Flex>
                                        {/* FIN CUADRICULA */}
                                    </FlexFull>
                                    {/* FIN CALCULO Y CUADRICULA */}
                                    {/* PASOS */}
                                    <FlexFull direction='column' justify='start' align='center' width='30%' height='65vh'>
                                        <PasosT>
                                            PASOS
                                        </PasosT>
                                        <PasosSubT>
                                            SUMA TRADICIONAL
                                        </PasosSubT>
                                        {contadorSecundario >= 1 &&
                                        <Pasos bgcolor='#D76970'>
                                            1- COLOCO LOS NÚMEROS EN SU RESPECTIVO LUGAR
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 6 &&
                                        <Pasos bgcolor='#23AAAF'>
                                            2- COMIENZO SUMANDO LAS UNIDADES
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 8 &&
                                        <Pasos bgcolor='#FF9B2A'>
                                            3- ¿CÓMO ESCRIBO EL NÚMERO?
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 11 &&
                                        <Pasos bgcolor='#FFDC73'>
                                            4- AHORA SUMO LAS DECENAS
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 14 &&
                                        <Pasos bgcolor='#655EE8'>
                                            5- ¿CUÁL ES EL RESULTADO?
                                        </Pasos>
                                        }
                                    </FlexFull>
                                    {/* FIN PASOS */}
                                </FlexFull>
                                }
                                <FlexCuz position='fixed' right='0' bottom='0'>
                                {contadorSecundario === 0 && <BotoneraBottom aumentarSecundario={true} />}
                                {contadorSecundario > 0 && contadorSecundario < 15 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />}
                                {contadorSecundario === 15 && <BotoneraBottom reducirSecundario={true} aumentar={true} />}
                                </FlexCuz>
                            </div>
                            }
                            {contador === 1 && <M61Bis mostrar={true} />}
                            {contador === 2 && <M61Practica mostrar={true} />}
                        </Fragment>
                        :
                        <Instrucciones instrucciones={instrucciones} />
                    }
                </Fragment>
        </Fragment>
    );
}
 
export default M61;