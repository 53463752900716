import React, { useState } from 'react';

//css
import {SelectLocalidad, Label, Flex} from '../components/Css/StyledForm';

const useSelectDropwdown = (label, opciones, defaultValue, tengoPadre, idPadre) => {

    const [state, setState] = useState(defaultValue);

    const SelectDropdown = ({disable, incompleto}) => (
        <Flex>
            <Label padding="2px 0px 2px 7px">
                {!tengoPadre
                ?
                <>
                    <SelectLocalidad disabled={disable} value={state === null ? '' : state} onChange={e => setState(e.target.value)}>
                        <option>{label}</option>
                        {opciones.map((item)=> (
                            <option key={item.id} value={item.id}>{item.parametro}</option>
                        ))}
                    </SelectLocalidad>
                    {!incompleto ? null : <div>X</div>}
                </>
                : isNaN(idPadre)
                ?
                <SelectLocalidad disabled={true} value={state === null ? '' : state} onChange={e => setState(e.target.value)}>
                    <option>{label}</option>
                    {opciones.filter(item => item.id_padre == idPadre).map((item)=> (
                        <option key={item.id} value={item.id}>{item.parametro}</option>
                    ))}
                </SelectLocalidad>
                :
                <>
                    <SelectLocalidad disabled={disable} value={state === null ? '' : state} onChange={e => setState(e.target.value)}>
                        <option>{label}</option>
                        {opciones.filter(item => item.id_padre == idPadre).map((item)=> (
                            <option key={item.id} value={item.id}>{item.parametro}</option>
                        ))}
                    </SelectLocalidad>
                    {!incompleto ? null : <div>X</div>}
                </>
                }
            </Label>
        </Flex>
    );
    return [state, SelectDropdown]
};

export default useSelectDropwdown;