export const ESTADO_ERROR_INITIAL = 'ESTADO_ERROR_INITIAL';
export const ESTADO_LOADING_INITIAL = 'ESTADO_LOADING_INITIAL';
export const ESTADO_CORRECTO_INITIAL = 'ESTADO_CORRECTO_INITIAL';

export const CREAR_USUARIO = 'CREAR_USUARIO';
export const CREAR_USUARIO_EXITO = 'CREAR_USUARIO_EXITO';
export const CREAR_USUARIO_ERROR = 'CREAR_USUARIO_ERROR';

export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';
export const MOSTRAR_SUCCESSFUL = 'MOSTRAR_SUCCESSFUL';
export const OCULTAR_SUCCESSFUL = 'OCULTAR_SUCCESSFUL';

export const LOGIN = 'LOGIN';
export const LOGIN_EXITO = 'LOGIN_EXITO';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const AUTENTICADO = 'AUTENTICADO';
export const AUTENTICADO_EXITO = 'AUTENTICADO_EXITO';
export const AUTENTICADO_ERROR = 'AUTENTICADO_ERROR';

export const CERRAR_SESION = 'CERRAR_SESION';

export const RESTABLECER_PASSWORD = 'RESTABLECER_PASSWORD';
export const RESTABLECER_PASSWORD_EXITO = 'RESTABLECER_PASSWORD_EXITO';
export const RESTABLECER_PASSWORD_ERROR = 'RESTABLECER_PASSWORD_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_EXITO = 'RESET_PASSWORD_EXITO';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const CONFIRMAR_CUENTA = 'CONFIRMAR_CUENTA';
export const CONFIRMAR_CUENTA_EXITO = 'CONFIRMAR_CUENTA_EXITO';
export const CONFIRMAR_CUENTA_ERROR = 'CONFIRMAR_CUENTA_ERROR';

export const SOLICITAR_CONFIRMAR = 'SOLICITAR_CONFIRMAR';
export const SOLICITAR_CONFIRMAR_EXITO = 'SOLICITAR_CONFIRMAR_EXITO';
export const SOLICITAR_CONFIRMAR_ERROR = 'SOLICITAR_CONFIRMAR_ERROR';

export const ALMACENAR = 'ALMACENAR';
export const ALMACENAR_EXITO = 'ALMACENAR_EXITO';
export const ALMACENAR_ERROR = 'ALMACENAR_ERROR';

export const ALMACENAR_ANAMNESIS = 'ALMACENAR_ANAMNESIS';
export const ALMACENAR_ANAMNESIS_EXITO = 'ALMACENAR_ANAMNESIS_EXITO';
export const ALMACENAR_ANAMNESIS_ERROR = 'ALMACENAR_ANAMNESIS_ERROR';

export const CONSEGUIR_INFORMACION_PACIENTE = 'CONSEGUIR_INFORMACION_PACIENTE';
export const CONSEGUIR_INFORMACION_PACIENTE_EXITO = 'CONSEGUIR_INFORMACION_PACIENTE_EXITO';
export const CONSEGUIR_INFORMACION_PACIENTE_ERROR = 'CONSEGUIR_INFORMACION_PACIENTE_ERROR';

export const SOLICITO_INFORMACION_EJERCICIO = 'SOLICITO_INFORMACION_EJERCICIO';
export const SOLICITO_INFORMACION_EJERCICIO_EXITO = 'SOLICITO_INFORMACION_EJERCICIO_EXITO';
export const SOLICITO_INFORMACION_EJERCICIO_ERROR = 'SOLICITO_INFORMACION_EJERCICIO_ERROR';

export const SOLICITO_INFORMACION_PACIENTE = 'SOLICITO_INFORMACION_PACIENTE';
export const SOLICITO_INFORMACION_PACIENTE_EXITO = 'SOLICITO_INFORMACION_PACIENTE_EXITO';
export const SOLICITO_INFORMACION_PACIENTE_ERROR = 'SOLICITO_INFORMACION_PACIENTE_ERROR';

export const ACTUALIZAR_INFORMACION_PACIENTE_EXITO = 'ACTUALIZAR_INFORMACION_PACIENTE_EXITO';
export const ACTUALIZAR_INFORMACION_PACIENTE_ERROR = 'ACTUALIZAR_INFORMACION_PACIENTE_ERROR';

export const ALMACENAR_RESIDENCIA = 'ALMACENAR_RESIDENCIA';

export const ALMACENAR_PROFESION = 'ALMACENAR_PROFESION';

export const BUSCO_PACIENTE = 'BUSCO_PACIENTE';
export const BUSCO_PACIENTE_EXITO = 'BUSCO_PACIENTE_EXITO';
export const BUSCO_PACIENTE_ERROR = 'BUSCO_PACIENTE_ERROR';

export const CREAR_PACIENTE = 'CREAR_PACIENTE';
export const CREAR_PACIENTE_EXITO = 'CREAR_PACIENTE_EXITO';
export const CREAR_PACIENTE_ERROR = 'CREAR_PACIENTE_ERROR';

export const EDITAR_PACIENTE = 'EDITAR_PACIENTE';
export const EDITAR_PACIENTE_EXITO = 'EDITAR_PACIENTE_EXITO';
export const EDITAR_PACIENTE_ERROR = 'EDITAR_PACIENTE_ERROR';

export const SETEAR_ID_PACIENTE = 'SETEAR_ID_PACIENTE';
export const SETEAR_ID_PACIENTE_EXITO = 'SETEAR_ID_PACIENTE_EXITO';
export const SETEAR_ID_PACIENTE_ERROR = 'SETEAR_ID_PACIENTE_ERROR';

export const SOLICITAR_PASARSE = 'SOLICITAR_PASARSE';
export const SOLICITAR_PASARSE_EXITO = 'SOLICITAR_PASARSE_EXITO';
export const SOLICITAR_PASARSE_ERROR = 'SOLICITAR_PASARSE_ERROR';

export const NUEVO_OBJETIVO = 'NUEVO_OBJETIVO';
export const NUEVO_OBJETIVO_EXITO = 'NUEVO_OBJETIVO_EXITO';
export const NUEVO_OBJETIVO_ERROR = 'NUEVO_OBJETIVO_ERROR';

export const SOLICITAR_OBJETIVOS = 'SOLICITAR_OBJETIVOS';
export const SOLICITAR_OBJETIVOS_EXITO = 'SOLICITAR_OBJETIVOS_EXITO';
export const SOLICITAR_OBJETIVOS_ERROR = 'SOLICITAR_OBJETIVOS_ERROR';

export const MODIFICAR_OBJETIVO = 'MODIFICAR_OBJETIVO';
export const MODIFICAR_OBJETIVO_EXITO = 'MODIFICAR_OBJETIVO_EXITO';
export const MODIFICAR_OBJETIVO_ERROR = 'MODIFICAR_OBJETIVO_ERROR';

export const SETEAR_MINUSCULA = 'SETEAR_MINUSCULA';
export const SETEAR_AYUDA_VISUAL = 'SETEAR_AYUDA_VISUAL';
export const SETEAR_AYUDA_LECTURA = 'SETEAR_AYUDA_LECTURA';
export const SETEAR_AYUDA_IMAGEN = 'SETEAR_AYUDA_IMAGEN';
export const RESETEAR_AYUDA_LECTURA = 'RESETEAR_AYUDA_LECTURA';
export const RESETEAR_AYUDA_VISUAL = 'RESETEAR_AYUDA_VISUAL';

export const INICIAR_EJERCICIO = 'INICIAR_EJERCICIO';
export const FINALIZAR_EJERCICIO = 'FINALIZAR_EJERCICIO';
export const RESET_EJERCICIO = 'RESET_EJERCICIO';
export const NUEVA_RONDA_EJERCICIO = 'NUEVA_RONDA_EJERCICIO';
export const AUMENTAR_CONTADOR = 'AUMENTAR_CONTADOR';
export const REDUCIR_CONTADOR = 'REDUCIR_CONTADOR';
export const ZERO_CONTADOR = 'ZERO_CONTADOR';
export const ZERO_CONTADOR_SECUNDARIO = 'ZERO_CONTADOR_SECUNDARIO';
export const ZERO_CONTADOR_TERCIARIO = 'ZERO_CONTADOR_TERCIARIO';
export const ZERO_CONTADOR_CUATERNARIO = 'ZERO_CONTADOR_CUATERNARIO';
export const ZERO_CONTADOR_CON_CORRECCION = 'ZERO_CONTADOR_CON_CORRECCION';
export const SETEAR_EJERCICIO = 'SETEAR_EJERCICIO';
export const SETEAR_CONTADOR = 'SETEAR_CONTADOR';
export const SETEAR_PRIMER_TIEMPO = 'SETEAR_PRIMER_TIEMPO';
export const SETEAR_SEGUNDO_TIEMPO = 'SETEAR_SEGUNDO_TIEMPO';
export const SETEAR_LETRA = 'SETEAR_LETRA';
export const SETEAR_LISTA = 'SETEAR_LISTA';
export const SETEAR_SESION = 'SETEAR_SESION';
export const SETEAR_SESION_TERMINADA = 'SETEAR_SESION_TERMINADA';
export const SETEAR_URL = 'SETEAR_URL';
export const SETEAR_URL_ANTERIOR = 'SETEAR_URL_ANTERIOR';
export const BORRAR_EJERCICIO = 'BORRAR_EJERCICIO';
export const BORRAR_PRIMER_TIEMPO = 'BORRAR_PRIMER_TIEMPO';
export const BORRAR_SEGUNDO_TIEMPO = 'BORRAR_SEGUNDO_TIEMPO';
export const BORRAR_LETRA = 'BORRAR_LETRA';
export const BORRAR_LISTA = 'BORRAR_LISTA';
export const BORRAR_URL = 'BORRAR_URL';
export const BORRAR_URL_ANTERIOR = 'BORRAR_URL_ANTERIOR';
export const AUMENTAR_RONDA = 'AUMENTAR_RONDA';
export const AUMENTAR_CONTADOR_SECUNDARIO = 'AUMENTAR_CONTADOR_SECUNDARIO';
export const AUMENTAR_CONTADOR_TERCIARIO = 'AUMENTAR_CONTADOR_TERCIARIO';
export const AUMENTAR_CONTADOR_CUATERNARIO = 'AUMENTAR_CONTADOR_CUATERNARIO';
export const REDUCIR_CONTADOR_SECUNDARIO = 'REDUCIR_CONTADOR_SECUNDARIO';
export const REDUCIR_CONTADOR_TERCIARIO = 'REDUCIR_CONTADOR_TERCIARIO';
export const REDUCIR_CONTADOR_CUATERNARIO = 'REDUCIR_CONTADOR_CUATERNARIO';
export const AUMENTAR_CONTADOR_CON_CORRECCION = 'AUMENTAR_CONTADOR_CON_CORRECCION';
export const REDUCIR_CONTADOR_CON_CORRECCION = 'REDUCIR_CONTADOR_CON_CORRECCION';
export const AUMENTAR_CONTADOR_ERROR = 'AUMENTAR_CONTADOR_ERROR';
export const PROXIMO_EJERCICIO = 'PROXIMO_EJERCICIO';
export const PROXIMA_RONDA = 'PROXIMA_RONDA';
export const SETEAR_GRUPO_EJERCICIO = 'SETEAR_GRUPO_EJERCICIO';
export const SETEAR_ITEM_GRUPO = 'SETEAR_ITEM_GRUPO';
export const BORRAR_INFO_DB = 'BORRAR_INFO_DB';

export const SOLICITO_NUEVO_PROFESIONAL = 'SOLICITO_NUEVO_PROFESIONAL';
export const SOLICITO_NUEVO_PROFESIONAL_EXITO = 'SOLICITO_NUEVO_PROFESIONAL_EXITO';
export const SOLICITO_NUEVO_PROFESIONAL_ERROR = 'SOLICITO_NUEVO_PROFESIONAL_ERROR';

export const DAR_DE_BAJA_METODO = 'DAR_DE_BAJA_METODO';
export const DAR_DE_BAJA_METODO_EXITO = 'DAR_DE_BAJA_METODO_EXITO';
export const DAR_DE_BAJA_METODO_ERROR = 'DAR_DE_BAJA_METODO_ERROR';

export const ACTIVAR_METODO = 'ACTIVAR_METODO';
export const ACTIVAR_METODO_EXITO = 'ACTIVAR_METODO_EXITO';
export const ACTIVAR_METODO_ERROR = 'ACTIVAR_METODO_ERROR';

export const SOLICITO_ANAMNESIS = 'SOLICITO_ANAMNESIS';
export const SOLICITO_ANAMNESIS_EXITO = 'SOLICITO_ANAMNESIS_EXITO';
export const SOLICITO_ANAMNESIS_ERROR = 'SOLICITO_ANAMNESIS_ERROR';

export const SOLICITAR_HISTORIA_CLINICA = 'SOLICITAR_HISTORIA_CLINICA';
export const SOLICITAR_HISTORIA_CLINICA_EXITO = 'SOLICITAR_HISTORIA_CLINICA_EXITO';
export const SOLICITAR_HISTORIA_CLINICA_ERROR = 'SOLICITAR_HISTORIA_CLINICA_ERROR';

export const NUEVO_HISTORIA_CLINICA = 'NUEVO_HISTORIA_CLINICA';
export const NUEVO_HISTORIA_CLINICA_EXITO = 'NUEVO_HISTORIA_CLINICA_EXITO';
export const NUEVO_HISTORIA_CLINICA_ERROR = 'NUEVO_HISTORIA_CLINICA_ERROR';

export const MODIFICAR_HISTORIA_CLINICA = 'MODIFICAR_HISTORIA_CLINICA';
export const MODIFICAR_HISTORIA_CLINICA_EXITO = 'MODIFICAR_HISTORIA_CLINICA_EXITO';
export const MODIFICAR_HISTORIA_CLINICA_ERROR = 'MODIFICAR_HISTORIA_CLINICA_ERROR';

export const SOLICITAR_ACTIVIDADES = 'SOLICITAR_ACTIVIDADES';
export const SOLICITAR_ACTIVIDADES_EXITO = 'SOLICITAR_ACTIVIDADES_EXITO';
export const SOLICITAR_ACTIVIDADES_ERROR = 'SOLICITAR_ACTIVIDADES_ERROR';

export const SOLICITAR_ACTIVIDADES_AGENDADAS = 'SOLICITAR_ACTIVIDADES_AGENDADAS';
export const SOLICITAR_ACTIVIDADES_AGENDADAS_EXITO = 'SOLICITAR_ACTIVIDADES_AGENDADAS_EXITO';
export const SOLICITAR_ACTIVIDADES_AGENDADAS_ERROR = 'SOLICITAR_ACTIVIDADES_AGENDADAS_ERROR';

export const ACTUALIZAR_ACTIVIDADES = 'ACTUALIZAR_ACTIVIDADES';
export const ACTUALIZAR_ACTIVIDADES_EXITO = 'ACTUALIZAR_ACTIVIDADES_EXITO';
export const ACTUALIZAR_ACTIVIDADES_ERROR = 'ACTUALIZAR_ACTIVIDADES_ERROR';

export const ACTUALIZAR_INFO_ACTIVIDADES = 'ACTUALIZAR_INFO_ACTIVIDADES';
export const ACTUALIZAR_INFO_ACTIVIDADES_EXITO = 'ACTUALIZAR_INFO_ACTIVIDADES_EXITO';
export const ACTUALIZAR_INFO_ACTIVIDADES_ERROR = 'ACTUALIZAR_INFO_ACTIVIDADES_ERROR';

export const AGENDAR_ACTIVIDADES = 'AGENDAR_ACTIVIDADES';
export const AGENDAR_ACTIVIDADES_EXITO = 'AGENDAR_ACTIVIDADES_EXITO';
export const AGENDAR_ACTIVIDADES_ERROR = 'AGENDAR_ACTIVIDADES_ERROR';

export const SOLICITO_INFORMACION_PERFIL_PROFESIONAL = 'SOLICITO_INFORMACION_PERFIL_PROFESIONAL';
export const SOLICITO_INFORMACION_PERFIL_PROFESIONAL_EXITO = 'SOLICITO_INFORMACION_PERFIL_PROFESIONAL_EXITO';
export const SOLICITO_INFORMACION_PERFIL_PROFESIONAL_ERROR = 'SOLICITO_INFORMACION_PERFIL_PROFESIONAL_ERROR';

export const SOLICITO_TOKEN_PERFIL_PROFESIONAL = 'SOLICITO_TOKEN_PERFIL_PROFESIONAL';
export const SOLICITO_TOKEN_PERFIL_PROFESIONAL_EXITO = 'SOLICITO_TOKEN_PERFIL_PROFESIONAL_EXITO';
export const SOLICITO_TOKEN_PERFIL_PROFESIONAL_ERROR = 'SOLICITO_TOKEN_PERFIL_PROFESIONAL_ERROR';

export const SOLICITO_SUSCRIPCION_PROFESIONAL = 'SOLICITO_SUSCRIPCION_PROFESIONAL';
export const SOLICITO_SUSCRIPCION_PROFESIONAL_EXITO = 'SOLICITO_SUSCRIPCION_PROFESIONAL_EXITO';
export const SOLICITO_SUSCRIPCION_PROFESIONAL_ERROR = 'SOLICITO_SUSCRIPCION_PROFESIONAL_ERROR';

export const ENVIAR_INVITACION_PACIENTE = 'ENVIAR_INVITACION_PACIENTE';
export const ENVIAR_INVITACION_PACIENTE_EXITO = 'ENVIAR_INVITACION_PACIENTE_EXITO';
export const ENVIAR_INVITACION_PACIENTE_ERROR = 'ENVIAR_INVITACION_PACIENTE_ERROR';

export const CARGAR_DATOS_PACIENTE_NO_REGISTRADO = 'CARGAR_DATOS_PACIENTE_NO_REGISTRADO';
export const CARGAR_DATOS_PACIENTE_NO_REGISTRADO_EXITO = 'CARGAR_DATOS_PACIENTE_NO_REGISTRADO_EXITO';
export const CARGAR_DATOS_PACIENTE_NO_REGISTRADO_ERROR = 'CARGAR_DATOS_PACIENTE_NO_REGISTRADO_ERROR';

export const CREAR_USUARIO_PACIENTE = 'CREAR_USUARIO_PACIENTE';
export const CREAR_USUARIO_PACIENTE_EXITO = 'CREAR_USUARIO_PACIENTE_EXITO';
export const CREAR_USUARIO_PACIENTE_ERROR = 'CREAR_USUARIO_PACIENTE_ERROR';

export const SOLICITO_CANTIDAD_SUSCRIPCION = 'SOLICITO_CANTIDAD_SUSCRIPCION';
export const SOLICITO_CANTIDAD_SUSCRIPCION_EXITO = 'SOLICITO_CANTIDAD_SUSCRIPCION_EXITO';
export const SOLICITO_CANTIDAD_SUSCRIPCION_ERROR = 'SOLICITO_CANTIDAD_SUSCRIPCION_ERROR';

export const ESTADO_PACIENTE_INITIAL = 'ESTADO_PACIENTE_INITIAL';
export const ESTADO_USUARIO_PACIENTE_INITIAL = 'ESTADO_USUARIO_PACIENTE_INITIAL';

export const CONSULTAR_ACTIVIDADES_RESULTADOS_SUCCESS = 'CONSULTAR_ACTIVIDADES_RESULTADOS_SUCCESS';
export const CONSULTAR_ACTIVIDADES_RESULTADOS_ERROR = 'CONSULTAR_ACTIVIDADES_RESULTADOS_ERROR';
export const CONSULTAR_EJERCICIOS_RESULTADOS_SUCCESS = 'CONSULTAR_EJERCICIOS_RESULTADOS_SUCCESS';
export const CONSULTAR_EJERCICIOS_RESULTADOS_ERROR = 'CONSULTAR_EJERCICIOS_RESULTADOS_ERROR';

export const MODIFICAR_PACIENTES = 'MODIFICAR_PACIENTES';

export const SUSPENDO_SUSCRIPTOR = 'SUSPENDO_SUSCRIPTOR';
export const SUSPENDO_SUSCRIPTOR_EXITO = 'SUSPENDO_SUSCRIPTOR_EXITO';
export const SUSPENDO_SUSCRIPTOR_ERROR = 'SUSPENDO_SUSCRIPTOR_ERROR';

export const ACTIVO_SUSCRIPTOR = 'ACTIVO_SUSCRIPTOR';
export const ACTIVO_SUSCRIPTOR_EXITO = 'ACTIVO_SUSCRIPTOR_EXITO';
export const ACTIVO_SUSCRIPTOR_ERROR = 'ACTIVO_SUSCRIPTOR_ERROR';

export const CREAR_SUSCRIPTOR = 'CREAR_SUSCRIPTOR';
export const CREAR_SUSCRIPTOR_EXITO = 'CREAR_SUSCRIPTOR_EXITO';
export const CREAR_SUSCRIPTOR_ERROR = 'CREAR_SUSCRIPTOR_ERROR';

export const CAMBIAR_SUSCRIPCION = 'CAMBIAR_SUSCRIPCION';
export const CAMBIAR_SUSCRIPCION_EXITO = 'CAMBIAR_SUSCRIPCION_EXITO';
export const CAMBIAR_SUSCRIPCION_ERROR = 'CAMBIAR_SUSCRIPCION_ERROR';

export const PROBAR_GRATIS = 'PROBAR_GRATIS';
export const CANCELAR_PRUEBA_GRATIS = 'CANCELAR_PRUEBA_GRATIS';


export const SOLICITO_LISTA_EJERCICIO = 'SOLICITO_LISTA_EJERCICIO';
export const SOLICITO_LISTA_EJERCICIO_EXITO = 'SOLICITO_LISTA_EJERCICIO_EXITO';
export const SOLICITO_LISTA_EJERCICIO_ERROR = 'SOLICITO_LISTA_EJERCICIO_ERROR';

export const ALMACENAR_CHECK_EJERCICIO_EXITO = 'ALMACENAR_CHECK_EJERCICIO_EXITO';