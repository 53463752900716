import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, DivFlex, ImgB10, InputM54, AvanzarImg, AvanzarBtn, TextM42 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la suma usando la clave de animales.';
const miniInstrucciones = 'Resuelve la suma usando la clave de animales.';

const M42 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState('');
    const [imagenUno, setImagenUno] = useState([]);
    const [imagenDos, setImagenDos] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if(numero.toString() !== (imagenUno.valor + imagenDos.valor).toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numero.toString() !== (imagenUno.valor + imagenDos.valor).toString()) {
                    setNumero('');
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setImagenUno([]);
                    setImagenDos([]);
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setImagenUno([]);
                    setImagenDos([]);
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const numeros = shuffle([0,1,2,3,4,5]);
        newList[0].valor = numeros[0];
        newList[1].valor = numeros[1];
        newList[2].valor = numeros[2];
        newList[3].valor = numeros[3];
        newList[4].valor = numeros[4];
        newList[5].valor = numeros[5];

        const shuffleList = Object.values(newList);
        const newShuffleList = shuffle(shuffleList);

        setComparar(newShuffleList);
        setLanzoEffect(true);
        setIndexes(3);
        setTipoEjercicio('unidad');
    }, [informacion])

    useEffect(() => {
        if(comparar.length === 0) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = Object.values(newList);
        const newShuffleList = shuffle(shuffleList);
        setImagenUno(newShuffleList[0]);
        setImagenDos(newShuffleList[1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect])

    useEffect(() => {
        dispatch(setearEjercicioAction('M42') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/ejercitacion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M42",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex direction="column" margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        {/* RECUADRO */}
                        <Flex direction='column' color='#D33B52' bradius='10px'>
                            <Flex direction='column' color='white' margin='1vw' bradius='10px' bs='0px 4px 4px rgba(0, 0, 0, 0.25)'>
                                {/* ANIMALES */}
                                <Flex bordb='2px solid #D33B52' width='65vw' wt='59vw' wd='57vw' wlg='55vw'>
                                    {comparar.map(item => 
                                    <DivFlex key={item.id}>
                                        <ImgB10 src={`/img/${item.imagen_url_1}.png`} w='100%' m='0px 0px -0.3vh 0px' style={{zIndex: 0}} />
                                    </DivFlex>
                                    )}
                                </Flex>
                                {/* FIN ANIMALES */}
                                {/* NÚMEROS */}
                                <Flex width='65vw' wt='59vw' wd='57vw' wlg='55vw'>
                                    {comparar.map(item => 
                                    <DivFlex key={item.id} width='100%' flex='flex' justify='center' align='center'>
                                        <TextM42 m='0px'>{item.valor}</TextM42>
                                    </DivFlex>
                                    )}
                                </Flex>
                                {/* FIN NÚMEROS */}
                            </Flex>
                        </Flex>
                        {/* FIN RECUADRO */}

                        {/* SUMA ANIMALES */}
                        <Flex>
                            <div>
                                <ImgB10 src={`/img/${imagenUno.imagen_url_1}.png`} w='20vw' wm='18vw' wt='15vw' wd='10vw' wlg='10vw' bs='0px 4px 4px rgba(0, 0, 0, 0.25)' p='0.1vw' bradius='10px' />
                            </div>
                            <TextM42 color='#D33B52'>+</TextM42> 
                            <div>
                                <ImgB10 src={`/img/${imagenDos.imagen_url_1}.png`} w='20vw' wm='18vw' wt='15vw' wd='10vw' wlg='10vw' bs='0px 4px 4px rgba(0, 0, 0, 0.25)' p='0.1vw' bradius='10px'/>
                            </div>
                            <TextM42 color='#D33B52' m='0px 3vw 0px 0px' mm='0px 1vw 0px 0px' mt='0px 1vw 0px 0px' md='0px 1vw 0px 0px' mlg='0px 1vw 0px 0px'>=</TextM42>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM54 type="numeric" min="1" max="10" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                            </form>
                        </Flex>
                        {/* FIN SUMA ANIMALES */}
                    </Flex>
                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M42;