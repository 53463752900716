import Header from '../../AuxiliaresPaginas/Header';
import SideBar from '../../AuxiliaresPaginas/Sidebar';

import {FlexHeader} from '../../Css/StyledHeader.js';

const HeaderUno = () => {
    return (
        <FlexHeader>
            <SideBar />
            <Header />
        </FlexHeader>
    );
}
 
export default HeaderUno;