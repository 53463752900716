import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, NumSumaM55, AvanzarImg, AvanzarBtn, InputM55 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve las sumas.';
const miniInstrucciones = 'Resuelve las sumas.';

const M55Bis = (props) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [ejercicio, setEjercicio] = useState([]);
    const [numero, setNumero] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if(ejercicio[count-1].resultado.toString() !== numero.toString()){
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setNumero('');
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setNumero('');
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setDisable(false);
                }, 1500);
            }
        }
    }

    useEffect(() => {

        const data = [
            {id:'1', numero:1, resultado:2},
            {id:'2', numero:2, resultado:4},
            {id:'3', numero:3, resultado:6},
            {id:'4', numero:4, resultado:8},
            {id:'5', numero:5, resultado:10},
            {id:'6', numero:6, resultado:12},
            {id:'7', numero:7, resultado:14},
            {id:'8', numero:8, resultado:16},
            {id:'9', numero:9, resultado:18},
            {id:'10', numero:10, resultado:20}
        ];
        const newList = JSON.parse(JSON.stringify(data));

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList);
        setEjercicio(shuffleList);
        setIndexes(shuffleList.length);
        setTipoEjercicio('unidad');
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M55Bis') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M55Bis",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        {ejercicio.length === 0 ? null :
                            <Flex height='75vh'>
                                <NumSumaM55 color='#FF9B2A' w='12vw'>{ejercicio[count-1].numero}</NumSumaM55>
                                <NumSumaM55 color='#646464' w='8vw'>+</NumSumaM55>
                                <NumSumaM55 color='#FF9B2A' w='12vw'>{ejercicio[count-1].numero}</NumSumaM55>
                                <NumSumaM55 color='#646464' w='8vw'>=</NumSumaM55>
                                <form onSubmit={(e) => verificar(e)}>
                                    <InputM55 type="numeric" min="1" max="20" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} color='#FF9B2A' w='15vw' bs='inset 0px 4px 4px rgba(0, 0, 0, 0.25)' bradius='20px' />
                                </form>
                            </Flex>
                        }
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                 </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M55Bis;