import {
    EDITAR_PACIENTE,
    EDITAR_PACIENTE_EXITO,
    EDITAR_PACIENTE_ERROR   
} from '../types';
import clienteAxios from '../config/axios';
import { cargarPacientesAction } from './cargarPacientesActions';

// Mando al store los datos de residencia
export function editarPacienteAction(informacion) {
    return async (dispatch) => {
        dispatch( editarPaciente() );
        try {
            const {config, payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/editarpacienteadministrativo', payload, config);
            dispatch( editarPacienteExito(respuesta.data.msg) );
            dispatch (cargarPacientesAction(informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( editarPacienteError(error.response.data) );
            }
        }
    }
}

const editarPaciente = () => ({
    type: EDITAR_PACIENTE,
    payload: true
});
const editarPacienteExito = (msg) => ({
    type: EDITAR_PACIENTE_EXITO,
    payload: msg
});
const editarPacienteError = (msg) => ({
    type: EDITAR_PACIENTE_ERROR,
    payload: msg
});