import React, {Fragment} from 'react';

import HeaderUno from '../../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../../AuxiliaresPaginas/HeaderMetodos';

import { HrMetodos } from '../../../../Css/StyledHeader';
import { Flex, FlexFull, Links1Al10, ImgB10, PEj } from '../../../../Css/StyledMatematica';

const RestaDescomposicion = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"calculo/"} l4={"resta/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"CALCULO"} n4={"RESTA"} componente={"RESTA DESCOMPOSICION"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex height='100%' hm='100%' ht='100%' hd='100%' hlg='100%'>
                <FlexFull align='center' justify='center' wrap='wrap' width='98%' wm='90%' wt='100%' wd='90%' wlg='80%'> 
                    <Flex wrap='wrap'>
                        <PEj m='0px' fs='2.5vw' fsm='2.2vw' fst='1.8vw' fsd='1.5vw' fslg='1.4vw'>SIN COMPLEJIDAD</PEj>
                        <Flex>
                            <Flex margin='0.5px 0px 0.5px 0px' direction='column'>
                                <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M83`}><ImgB10 src={`/img/portadas/matematica62.jpg`} w='26vw' wm='22vw' wt='17.5vw' wd='13.5vw' wlg='13vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                                <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>PRESENTACIÓN</PEj>
                            </Flex>
                            <Flex margin='0.5px 0px 0.5px 0px' direction='column'>
                                <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M83Bis`}><ImgB10 src={`/img/portadas/matematica62practicaconguia.jpg`} w='26vw' wm='22vw' wt='17.5vw' wd='13.5vw' wlg='13vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                                <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>PRÁCTICA CON GUÍA</PEj>
                            </Flex>
                            <Flex margin='0.5px 0px 0.5px 0px' direction='column'>
                                <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M83Practica`}><ImgB10 src={`/img/portadas/matematica62practica.jpg`} w='26vw' wm='22vw' wt='17.5vw' wd='13.5vw' wlg='13vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                                <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1.1vw'>PRÁCTICA</PEj>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex wrap='wrap' margin='15px 0px 0px 0px'>
                        <PEj m='0px' fs='2.5vw' fsm='2.2vw' fst='1.8vw' fsd='1.5vw' fslg='1.4vw'>CON COMPLEJIDAD</PEj>
                        <Flex>
                            <Flex margin='0.5px 0px 0.5px 0px' direction='column'>
                                <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M84`}><ImgB10 src={`/img/portadas/matematica63.jpg`} w='26vw' wm='22vw' wt='17.5vw' wd='13.5vw' wlg='13vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                                <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>PRESENTACIÓN</PEj>
                            </Flex>
                            <Flex margin='0.5px 0px 0.5px 0px' direction='column'>
                                <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M84Bis`}><ImgB10 src={`/img/portadas/matematica63practicaconguia.jpg`} w='26vw' wm='22vw' wt='17.5vw' wd='13.5vw' wlg='13vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                                <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>PRÁCTICA CON GUÍA</PEj>
                            </Flex>
                            <Flex margin='0.5px 0px 0.5px 0px' direction='column'>
                                <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M84Practica`}><ImgB10 src={`/img/portadas/matematica63practica.jpg`} w='26vw' wm='22vw' wt='17.5vw' wd='13.5vw' wlg='13vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                                <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>PRÁCTICA</PEj>
                            </Flex>
                        </Flex>
                    </Flex>
                    <FlexFull justify='start' align='center' width='96%' wm='92%' wt='52%' wd='47%' wlg='52%'>
                        <Flex margin='0.5px 0px 0.5px 0px' direction='column'>
                            <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M85`}><ImgB10 src={`/img/portadas/matematica64.jpg`} w='26vw' wm='22vw' wt='17.5vw' wd='13.5vw' wlg='13vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                            <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>PRÁCTICA GENERAL</PEj>
                        </Flex>
                    </FlexFull>
                </FlexFull>
            </Flex>
        </Fragment>    
    );
}
 
export default RestaDescomposicion;