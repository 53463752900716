import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import PracticaMiles from '../PracticaMiles'

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, NumeroM31, RayitaM31, FlechaM31, NumberDiv, NumeroM31R, Bloque, FlechaM31T, NumberDivM31 } from '../../../../Css/StyledMatematica';

const instrucciones = 'A continuación se presentan números diferenciados por su valor posicional. El niño deberá leer en voz alta dichos números. Si necesita ayuda se puede hacer click sobre el recuadro donde presenta dificultad. Asimismo se puede hacer click sobre el ícono de ayuda.';
const miniInstrucciones = 'Lee los números.';

const M31 = () => {

    const dispatch = useDispatch();

    const [miles, setMiles] = useState(0);
    const [resto, setResto] = useState(0);
    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);

    useEffect(() => {
        const min = Math.ceil(1000);
        const max = Math.floor(100000);
        const numero = Math.floor(Math.random() * (max - min + 1)) + min;
        const longitud = numero.toString().length;
        const hasta = longitud - 3;
        setMiles(numero.toString().substring(0,hasta+1));
        setResto(numero.toString().substring(hasta,longitud+1));
    }, [])

    if(contador === 7){
        dispatch(setearEjercicioAction('M31Bis') );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('M31') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar || fuerzoInicio
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex direction="column" margin="auto" width="100%">
                    {contador === 0 &&
                    <Flex direction='column'>
                        {/* <div>1</div>
                        <div>.</div>
                        <div>000</div> */}
                        <NumeroM31>1.000</NumeroM31>
                    </Flex>
                    }
                    {contador === 1 &&
                    <Flex direction='column'>
                        {/* <div>1</div>
                        <div>.</div>
                        <div>000</div> */}
                        <NumeroM31>1.000</NumeroM31>
                        {/* <div>rayita</div> */}
                        <RayitaM31></RayitaM31>
                    </Flex>
                    }
                    {contador === 2 &&
                    <Flex direction='column'>
                        {/* <div>1</div>
                        <div>.</div>
                        <div>000</div>
                        <div>rayita</div>
                        <div>flecha MIL</div> */}
                        <NumeroM31>1.000</NumeroM31>
                        <RayitaM31></RayitaM31>
                        <FlechaM31></FlechaM31>
                        <NumberDiv>MIL</NumberDiv>
                    </Flex>
                    }
                    {contador === 3 &&
                    <div>
                    <Flex>
                        <NumeroM31R>
                            {miles}.
                        </NumeroM31R>
                        <NumeroM31R>
                            {resto}
                        </NumeroM31R>
                    </Flex>
                    </div>
                    }
                    {contador === 4 &&
                    <div>
                    <Flex>
                        <NumeroM31R>
                            {miles}.
                        </NumeroM31R>
                        <Bloque>
                            <NumeroM31R op='0'>
                                {resto}
                            </NumeroM31R>
                        </Bloque>
                    </Flex>
                    </div>
                    }
                    {contador === 5 &&
                    <div>
                        <Flex direction='column'>
                            <Flex>
                                <NumeroM31R>
                                    {miles}.
                                </NumeroM31R>
                                <Bloque>
                                    <NumeroM31R op='0'>
                                        {resto}
                                    </NumeroM31R>
                                </Bloque>
                            </Flex>
                            { 
                                miles.length === 2 ? 
                                <Flex margin='0px 30vw 0px 0px' mm='0px 24vw 0px 0px' mt='0px 21vw 0px 0px' md='0px 20vw 0px 0px' mlg='0px 19vw 0px 0px'>
                                    <FlechaM31T m='-5vh 0px 0px 0px' mm='-5vh 0px 0px 0px' mt='-5vh 0px 0px 0px' md='-5vh 0px 0px 0px' mlg='-5vh 0px 0px 0px'></FlechaM31T> 
                                    <FlechaM31T m='-5vh 0px 0px 17vw' mm='-5vh 0px 0px 13vw' mt='-5vh 0px 0px 12vw' md='-5vh 0px 0px 11vw' mlg='-5vh 0px 0px 10vw'></FlechaM31T>
                                </Flex>
                                : 
                                <Flex margin='0px 23vw 0px 0px' mm='0px 18vw 0px 0px' mt='0px 16vw 0px 0px' md='0px 15vw 0px 0px' mlg='0px 15vw 0px 0px'>
                                    <FlechaM31T m='-5vh 0px 0px 0px' mm='-5vh 0px 0px 0px' mt='-5vh 0px 0px 0px' md='-5vh 0px 0px 0px' mlg='-5vh 0px 0px 0px'></FlechaM31T> 
                                    <FlechaM31T m='-5vh 0px 0px 24vw' mm='-5vh 0px 0px 20vw' mt='-5vh 0px 0px 17vw' md='-5vh 0px 0px 16vw' mlg='-5vh 0px 0px 16vw'></FlechaM31T>
                                </Flex>
                            }
                        </Flex>
                    </div>
                    }
                    {contador === 6 &&
                    <Flex direction='column'>
                        <Flex>
                            <NumeroM31R>
                                {miles}.
                            </NumeroM31R>
                            <Bloque>
                                <NumeroM31R op='0'>
                                    {resto}
                                </NumeroM31R>
                            </Bloque>
                        </Flex>
                        { 
                            miles.length === 2 ? 
                            <Flex margin='0px 30vw 0px 0px' mm='0px 24vw 0px 0px' mt='0px 21vw 0px 0px' md='0px 20vw 0px 0px' mlg='0px 19vw 0px 0px'>
                                <FlechaM31T m='-5vh 0px 0px 0px' mm='-5vh 0px 0px 0px' mt='-5vh 0px 0px 0px' md='-5vh 0px 0px 0px' mlg='-5vh 0px 0px 0px'></FlechaM31T> 
                                <FlechaM31T m='-5vh 0px 0px 17vw' mm='-5vh 0px 0px 13vw' mt='-5vh 0px 0px 12vw' md='-5vh 0px 0px 11vw' mlg='-5vh 0px 0px 10vw'></FlechaM31T>
                            </Flex>
                            : 
                            <Flex margin='0px 23vw 0px 0px' mm='0px 18vw 0px 0px' mt='0px 16vw 0px 0px' md='0px 15vw 0px 0px' mlg='0px 15vw 0px 0px'>
                                <FlechaM31T m='-5vh 0px 0px 0px' mm='-5vh 0px 0px 0px' mt='-5vh 0px 0px 0px' md='-5vh 0px 0px 0px' mlg='-5vh 0px 0px 0px'></FlechaM31T> 
                                <FlechaM31T m='-5vh 0px 0px 24vw' mm='-5vh 0px 0px 20vw' mt='-5vh 0px 0px 17vw' md='-5vh 0px 0px 16vw' mlg='-5vh 0px 0px 16vw'></FlechaM31T>
                            </Flex>
                        }

                        { 
                            miles.length === 2 ? 
                            <Flex margin='0px 28vw 0px 0px' mm='0px 23vw 0px 0px' mt='0px 20vw 0px 0px' md='0px 19vw 0px 0px' mlg='0px 18.5vw 0px 0px'>
                                <NumberDivM31 m='-1vh 0px 0px 0px' mm='-1vh 0px 0px 0px' mt='-1vh 0px 0px 0px' md='-1vh 0px 0px 0px' mlg='-1vh 0px 0px 0px'>
                                    {miles}
                                </NumberDivM31>
                                <NumberDivM31 m='-1vh 0px 0px 7vw' mm='-1vh 0px 0px 5vw' mt='-1vh 0px 0px 6vw' md='-1vh 0px 0px 7vw' mlg='-1vh 0px 0px 6vw'>
                                    MIL
                                </NumberDivM31>
                            </Flex>
                            : 
                            <Flex margin='0px 23vw 0px 0px' mm='0px 18vw 0px 0px' mt='0px 16vw 0px 0px' md='0px 15vw 0px 0px' mlg='0px 15vw 0px 0px'>
                                <NumberDivM31 m='-1vh 0px 0px 0px' mm='-1vh 0px 0px 0px' mt='-1vh 0px 0px 0px' md='-1vh 0px 0px 0px' mlg='-1vh 0px 0px 0px'>
                                    {miles}
                                </NumberDivM31>
                                <NumberDivM31 m='-1vh 0px 0px 9vw' mm='-1vh 0px 0px 9.5vw' mt='-1vh 0px 0px 9vw' md='-1vh 0px 0px 10vw' mlg='-1vh 0px 0px 11vw'>
                                    MIL
                                </NumberDivM31>
                            </Flex>
                        }
                    </Flex>
                    }
                </Flex>
                {contador === 0 &&
                <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                    <Flex>
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    </Flex>
                    <Flex>
                        <BotoneraBottom aumentar={true} />
                    </Flex>
                </Flex>
                }
                {contador !== 0 && contador <=6 ?
                <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                    <Flex>
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    </Flex>
                    <Flex>
                        <BotoneraBottom reducir={true} aumentar={true} />
                    </Flex>
                </Flex>
                : null
                }
                {contador > 6 &&
                    <PracticaMiles ejercicio="M31Bis" />
                }
            </Flex>
            :
            <Instrucciones miniInstrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M31;