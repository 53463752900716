import React from 'react';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import AgendarCitaModal from './AgendarCitaModal';

import FullCalendar from '@fullcalendar/react' ;
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

// css
import {CuerpoCompleto, Flex, Linea, FlexBox} from '../Css/StyledHeader.js';
import {ContenedorCalendario, Fondo} from '../Css/StyledCalendar.js';


// Redux

const Calendario = () => {

    const esLocale = {
        code: "es",
        week: {
          dow: 1, // Sunday is the first day of the week.
          doy: 6, // The week that contains Jan 1st is the first week of the year.
        },
        buttonText: {
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
        },
        weekText: "Sm",
        allDayText: "Todo el día",
        moreLinkText: "más",
        noEventsText: "No hay eventos para mostrar",
    }
    
    const eventos = [
        {title: 'Cumpleaños de Oli',
         start: '2021-07-10',
         allDay: true,
         id: 1
        },
        {title: 'Festejo de Oli',
         start: '2021-07-11T12:30:00',
         end: '2021-07-11T17:45:00',
         color: 'yellow',
         id: 2
        }
    ]


    /*Vicky encontrar una manera de eliminar registros*/
    // const removerEvento = (algo) => dispatch( solicitarPasarseAction(algo) );

    // const handleEventClick = (id) => {
    //     console.log(id)
    //     if (window.confirm(`Are you sure you want to delete the event '${eventos.id.title}'`)) {
    //       id.eventos.remove() // will render immediately. will call handleEventRemove
    //     }
    // }
    // const handleEventRemove = (algo) => {
        
    //     removerEvento(algo)
    // }

    return (
        <CuerpoCompleto height="140vh" width="99vw">
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes/paciente`} componente={"Calendario Paciente"} paciente={true} />
            <Flex>
            <AgendarCitaModal />
            </Flex>
            <Linea/>
            <FlexBox justify="center">
            <Fondo>
                <ContenedorCalendario>
                    <FullCalendar
                        plugins={[ dayGridPlugin, timeGridPlugin ]}
                        initialView="timeGridWeek"
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        locale={esLocale}
                        slotMinTime={'08:00:00'}
                        slotMaxTime={'21:00:00'}
                        nowIndicator={true}
                        height="auto"
                        events={eventos}
                        // eventClick={e => handleEventClick(e.events.eventos.id)}
                        // eventRemove={handleEventRemove}
                    />
                    </ContenedorCalendario>
            </Fondo>
            </FlexBox>
        </CuerpoCompleto>
    );
}
 
export default Calendario;