import React, { Fragment, useRef, useState, useEffect } from 'react';

import useCloseElement from '../../Hooks/useCloseElement.js';

// css
import {  Icon, Item, NavMenu,NavBar, MenuText, NavMenuItems, BtnCerrar } from '../Css/StyledHeader.js';

const Sidebar = () => {

    const wrapperRef = useRef();
    const [estadoClose] = useCloseElement(wrapperRef);

    const [sidebar, setSidebar] = useState(false);
    
    const showSidebar = () => setSidebar(!sidebar);

    useEffect(() => {
        if(estadoClose) {
            setSidebar(false);
        }
    }, [estadoClose])

    return (
        <div ref={wrapperRef}>
            <NavBar>
                <Icon src={`/img/menu.svg`} alt="barra lateral" width="1.5rem" margin="0" padding="0 2rem 0 0.5rem" onClick={showSidebar}/>
            </NavBar>
            <NavMenu sidebar={sidebar}>
                <NavMenuItems>
                    <BtnCerrar src={`/img/cerrar.svg`} alt="x cerrar"  onClick={showSidebar}/>
                        <MenuText><Item to={'/escritorio/pacientes'}> <Icon src={`/img/calendario.svg`} alt="" width="15px" margin="0px 10px"/> Inicio</Item></MenuText>
                        <MenuText><Item to={'/escritorio/pacientes'} > <Icon src={`/img/pacientes.svg`} alt="" width="22px" margin="0px 10px"/>Pacientes</Item></MenuText>
                </NavMenuItems>
            </NavMenu>
        </div>
    )
};

export default Sidebar;
