import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import useConfigAuth from '../../Hooks/useConfigAuth';

// css
import {Cuerpo,Icon, FlexBox } from '../Css/StyledHeader.js'
import {FondoModalClinica, Table2, Th, Tr, Td1, Thead, BotonEdit,  BotonNaranjaButton, ModalStyledClinica, Input, BotonClose, FlexClinica, Flex, FlexButton, BotonRedondo, FlexClinicaPosition, ClinicaModalContainer, TextAreaClinica} from '../Css/StyledPatient.js';
import { historiaClinicaNuevosAction, modificarHistoriaClinicaAction } from '../../actions/historiaClinicaActions';

Modal.setAppElement('#root');

const HistoriaClinica = () => {

    const dispatch = useDispatch();

    const [config] = useConfigAuth();

    const historiaClinica = useSelector((state) => state.informacion.info_historia_clinica);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTwoIsOpen, setModalTwoIsOpen] = useState(false);
    const [idSesion, setIdSesion] = useState('');
    const [comentarioSesion, setComentarioSesion] = useState('');
    const [fechaHistorial, setFechaHistorial] = useState('');
    const [lugarHistorial, setLugarHistorial] = useState('');
    const [asistenciaHistorial, setAsistenciaHistorial] = useState('');
    const [comentarioHistorial, setComentarioHistorial] = useState('');
    const [cambio, setCambio] = useState();

    const crearSesion = (e) => {
        e.preventDefault();
        if(fechaHistorial === '' || lugarHistorial === '' || asistenciaHistorial === ''){
            return;
        }
        const nuevoSesion = Math.max(...historiaClinica.map(item => item.sesion))+1;
        const informacion = {
            config,
            payload: {
                'fecha': fechaHistorial,
                'sesion': nuevoSesion,
                'lugar': lugarHistorial,
                'asistencia': asistenciaHistorial,
                'comentario': comentarioHistorial,
                'id_paciente': id_paciente
            }
        }
        dispatch( historiaClinicaNuevosAction(informacion) );
        setModalTwoIsOpen(false);
        setFechaHistorial('');
        setLugarHistorial('');
        setAsistenciaHistorial('');
        setComentarioHistorial('');
    }

    const modificarSesion = (e) => {
        e.preventDefault();
        const informacion = {
            config,
            payload: {
                'id_sesion':idSesion,
                'comentario': comentarioSesion,
                'id_paciente': id_paciente
            }
        }
        dispatch( modificarHistoriaClinicaAction(informacion) );
        setIdSesion('');
        setFechaHistorial('');
        setLugarHistorial('');
        setAsistenciaHistorial('');
        setComentarioHistorial('');
        setModalIsOpen(false);
    }

    useEffect(() => {
        if(!idSesion){
            return
        }
        const indexObj = historiaClinica.findIndex(obj => obj.id === idSesion);
        const newObj = JSON.parse(JSON.stringify(historiaClinica[indexObj]));
        setComentarioSesion(newObj.comentario)
        setCambio(newObj)
    }, [idSesion])
    
    
    return ( 
        <Cuerpo>
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes/paciente`} componente={"Historia Clínica"} paciente={true} />
            <Flex>
                <FlexClinica align="center">
                    <Table2>
                        <Thead>
                            <Tr bgcolor="#FF9B2A">
                                <Th padding="0.3rem">Fecha Agendada</Th>
                                <Th padding="0.3rem">Sesion</Th>
                                <Th padding="0.3rem">Lugar</Th>
                                <Th padding="0.3rem">Asistencia</Th>
                                <Th padding="0.3rem">Actividades</Th>
                                <Th padding="0.3rem">Estado</Th>
                                <Th padding="0.3rem" width="100%" colSpan={2} >Comentario</Th>
                            </Tr>
                        </Thead>
                        <tbody>
                            {historiaClinica[0] === "vacio" ? null :
                            historiaClinica.map((historiaClinica) => (
                            <Tr textalign="center"
                                key={historiaClinica.id}
                            >
                                <Td1 padding="0.3rem">{historiaClinica.fecha}</Td1>
                                <Td1 padding="0.3rem">{historiaClinica.sesion}</Td1>
                                <Td1 padding="0.3rem">{historiaClinica.lugar}</Td1>
                                <Td1 padding="0.3rem">{historiaClinica.asistencia}</Td1>
                                <Td1 padding="0.3rem" width="40%">{historiaClinica.actividades}</Td1>
                                <Td1 padding="0.3rem">{historiaClinica.estado}</Td1>
                                <Td1 padding="0.3rem" width="60%" ancho="100%" textalign="left" >{historiaClinica.comentario}</Td1>
                                <Td1 padding="0.3rem">
                                    <BotonEdit onClick={() => {setModalIsOpen(true); setIdSesion(historiaClinica.id)}} >
                                        <Icon src={`/img/editar.svg`} alt="" width="15px"/>
                                    </BotonEdit>
                                    {!cambio ? null :
                                    <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                                    <ModalStyledClinica>
                                        <FlexBox justify="center">
                                            <BotonClose onClick={() => setModalIsOpen(false)}><Icon src={`/img/cerrar.svg`} alt="Cerrar" width="15px" /></BotonClose>
                                        </FlexBox>
                                        <form
                                            onSubmit={(e) => modificarSesion(e)}
                                        >
                                            <FondoModalClinica>
                                                <FlexClinicaPosition>
                                                    <ClinicaModalContainer width="50%">
                                                        <label style={{width: "5rem"}}htmlFor="text">Fecha Agendada</label>
                                                        <Input width="6rem" radius="10px"
                                                            type="date"
                                                            disabled={true}
                                                            value={cambio.fecha || ""}
                                                        />
                                                    </ClinicaModalContainer>
                                                    <ClinicaModalContainer width="10%">
                                                        <label htmlFor="text">N°</label>
                                                        <Input width="22px" radius="10px"
                                                            type="text"
                                                            disabled={true}
                                                            value={cambio.sesion || ""}
                                                        />
                                                    </ClinicaModalContainer>
                                                    <ClinicaModalContainer width="40%">
                                                        <label htmlFor="text">Lugar</label>
                                                        <Input width="88px" radius="10px"
                                                            type="text"
                                                            disabled={true}
                                                            value={cambio.lugar || ""}
                                                        />
                                                    </ClinicaModalContainer>
                                                </FlexClinicaPosition>
                                                <FlexClinicaPosition>
                                                    <ClinicaModalContainer width="50%">
                                                        <label htmlFor="text">Asistencia</label>
                                                        <Input width="88px" radius="10px" margin="0 6px 0 6px"
                                                            type="text"
                                                            disabled={true}
                                                            value={cambio.asistencia || ""}
                                                        />
                                                    </ClinicaModalContainer>
                                                    <ClinicaModalContainer width="50%">
                                                        <label htmlFor="text">Estado</label>
                                                        <Input width="88px" radius="10px" margin="0 6px 0 6px"
                                                            type="text"
                                                            disabled={true}
                                                            value={cambio.ejercitacion || ""}
                                                        />
                                                    </ClinicaModalContainer>
                                                </FlexClinicaPosition>
                                                <FlexClinicaPosition>
                                                    <ClinicaModalContainer width="100%">
                                                        <label htmlFor="text">Actividades</label>
                                                        <TextAreaClinica width="100%" radius="10px" margin="0 0 0 6px"
                                                            type="text"
                                                            disabled={true}
                                                            value={cambio.actividades || ""}
                                                        />
                                                    </ClinicaModalContainer>
                                                </FlexClinicaPosition>
                                                <FlexClinicaPosition>
                                                    <ClinicaModalContainer width="100%">
                                                        <label htmlFor="text">Editar el comentario</label>
                                                        <TextAreaClinica width="100%" radius="10px" margin="0 0 0 6px" height="200px"
                                                            type="text"
                                                            name="comentarioSesion"
                                                            value={comentarioSesion || ""}
                                                            onChange={e => setComentarioSesion(e.target.value)}
                                                        />
                                                    </ClinicaModalContainer>
                                                </FlexClinicaPosition>
                                            </FondoModalClinica>
                                            <FlexBox justify="flex-end">
                                                <BotonNaranjaButton type="submit" margin="15px">Grabar</BotonNaranjaButton>
                                            </FlexBox>
                                        </form>
                                    </ModalStyledClinica>
                                    </Modal>
                                    }
                                </Td1>
                            </Tr>
                            ))
                            }
                        </tbody>
                    </Table2>
                    <FlexButton>
                        <BotonRedondo
                            onClick={() => setModalTwoIsOpen(true)}
                        >
                            +
                        </BotonRedondo>
                        <Modal isOpen={modalTwoIsOpen} onRequestClose={() => setModalTwoIsOpen(false)}>
                            <FlexBox justify="center">
                                <BotonClose onClick={() => setModalTwoIsOpen(false)}><Icon src={`/img/cerrar.svg`} alt="Cerrar" width="15px" /></BotonClose>
                            </FlexBox>
                            <form
                                onSubmit={crearSesion}
                            >
                                <FondoModalClinica>
                                    <FlexClinicaPosition>
                                        <ClinicaModalContainer width="50%">
                                            <label style={{width: "5rem"}}htmlFor="text">Fecha</label>
                                            <Input width="6rem" radius="10px"
                                            type="date"
                                            name="fechaHistorial"
                                            value={fechaHistorial}
                                            onChange={e => setFechaHistorial(e.target.value)}
                                        />
                                        </ClinicaModalContainer>
                                    </FlexClinicaPosition>
                                    <FlexClinicaPosition>
                                        <ClinicaModalContainer>
                                        <select value={lugarHistorial} onChange={e => setLugarHistorial(e.target.value)}>
                                            <option>Seleccione Lugar</option>
                                            <option>Consultorio</option>
                                            <option>Domicilio</option>
                                            <option>Virtual</option>
                                        </select>
                                        </ClinicaModalContainer>
                                    </FlexClinicaPosition>
                                    <FlexClinicaPosition>
                                        <ClinicaModalContainer>
                                        <select value={asistenciaHistorial} onChange={e => setAsistenciaHistorial(e.target.value)}>
                                            <option>Seleccione Asistencia</option>
                                            <option>Presente</option>
                                            <option>Ausente</option>
                                        </select>
                                        </ClinicaModalContainer>
                                    </FlexClinicaPosition>
                                    <FlexClinicaPosition>
                                        <ClinicaModalContainer width="100%">
                                            <label htmlFor="text">Comentario</label>
                                            <TextAreaClinica width="100%" radius="10px" margin="0 0 0 6px" height="200px"
                                                type="text"
                                                name="comentarioSesion"
                                                value={comentarioHistorial}
                                                onChange={e => setComentarioHistorial(e.target.value)}
                                            />
                                        </ClinicaModalContainer>
                                    </FlexClinicaPosition>
                                </FondoModalClinica>
                                <FlexBox justify="flex-end">
                                    <BotonNaranjaButton type="submit" margin="15px">Grabar</BotonNaranjaButton>
                                </FlexBox>
                            </form>
                        </Modal>
                    </FlexButton>
                </FlexClinica>
            </Flex>
        </Cuerpo>
    );
}
 
export default HistoriaClinica;