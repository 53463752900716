import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, ImgB10, NumSumaM56, InputM56, LateralM71, AvanzarImg, AvanzarBtn, BotonMano } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Coloca la mano en el número más grande para poder resolver la resta.';
const miniInstrucciones = 'Resuelve la resta.';

const M71 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState('');
    const [asignadoUno, setAsignadoUno] = useState(false);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignameUno = (e) => {
        e.preventDefault();
        if(asignadoUno === false){
            setAsignadoUno(true);
        }
        
    }
    const asignameDos = (e) => {
        e.preventDefault();
        if(asignadoUno === true){
            return;
        }
        setIsIncorrect(true);
        setTimeout(() => {
            setIsIncorrect(false);
        }, 300);
        TryAgain();
    }

    const verificar = (e) => {
        e.preventDefault();
        if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numero.toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setNumero('');
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setAsignadoUno(false);
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setAsignadoUno(false);                 
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const listaUno = [1,2,3,4,5,6,7,8,9,10];
        const randomListaUno = shuffle(listaUno);
        const listaUnoFinal = [randomListaUno[0],randomListaUno[1],randomListaUno[2],randomListaUno[3],randomListaUno[4]];

        const listaDos = [];
        const randomLista = (lista, listaBase) => {
            for(let i = 0; i < 5; i++){
                let min = 1;
                let max = listaBase[i];
                let randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                lista.push(randomNumero);
            }
            return lista
        }
        const randomListaDos = randomLista(listaDos, listaUnoFinal);
        const modelo = [
            {id:'1', cantidad_1:listaUnoFinal[0], cantidad_2:randomListaDos[0]},
            {id:'2', cantidad_1:listaUnoFinal[1], cantidad_2:randomListaDos[1]},
            {id:'3', cantidad_1:listaUnoFinal[2], cantidad_2:randomListaDos[2]},
            {id:'4', cantidad_1:listaUnoFinal[3], cantidad_2:randomListaDos[3]},
            {id:'5', cantidad_1:listaUnoFinal[4], cantidad_2:randomListaDos[4]}
        ]
        setComparar(modelo);
        setIndexes(modelo.length);
        setTipoEjercicio('unidad');
    }, []);

    useEffect(() => {
        dispatch(setearEjercicioAction('M71') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/ejercitacion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M71",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <LateralM71>
                            <ImgB10 src={`/img/dedos_5.png`} w='75px' style={{zIndex:0}} />
                        </LateralM71>
                        <FlexFull direction='column' height='480px' justify='space-evenly' align='start'>
                            <FlexFull width='340px' wm='360px' wt='570px' wd='830px' wlg='900px' height='180px' justify='space-evenly' align='center'>
                                <NumSumaM56 color='#FF9B2A'>{comparar[count-1].cantidad_1}</NumSumaM56> 
                                <NumSumaM56 color='#646464'>-</NumSumaM56>
                                <NumSumaM56 color='#FF9B2A'>{comparar[count-1].cantidad_2}</NumSumaM56> 
                                <NumSumaM56 color='#646464'>=</NumSumaM56>
                                <NumSumaM56>
                                {asignadoUno === false ? null :
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <InputM56 type="numeric" min="1" max="20" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} color='#21A2A7' />
                                </form>
                                }
                                </NumSumaM56>
                            </FlexFull>
                            <FlexFull width='150px' wm='165px' wt='240px' wd='330px' wlg='350px' margin='-40px 0px 0px 10px' mm='-40px 0px 0px 10px' mt='-40px 0px 0px 40px' md='-40px 0px 0px 80px' mlg='-40px 0px 0px 95px' justify='space-between' align='center'>
                                <BotonMano
                                    disabled={disable}
                                    onClick={(e) => asignameUno(e)}
                                >
                                    {!asignadoUno ? <ImgB10 src={`/img/manoBorrosa.png`} w='50px' /> : <ImgB10 src={`/img/dedos_5.png`} w='50px' style={{zIndex:0}} />}
                                </BotonMano>
                                <BotonMano
                                    disabled={disable}
                                    onClick={(e) => asignameDos(e)}
                                >
                                    <ImgB10 src={`/img/manoBorrosa.png`} w='50px' />
                                </BotonMano>
                            </FlexFull>
                        </FlexFull>
                    </Flex>
                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M71;