import React from 'react';
import { useSelector } from 'react-redux';

//css
import { TableActAgen, TheadAgen, TrAgen, ThAgen, TdAgen, TbodyAgen, FlexAgen} from '../Css/StyledActivities';

const SeleccionarActividadesAgendadas = () => {

    const infoActividadesAgendadas = useSelector((state) => state.informacion.info_actividades_agendadas);
    const infoActividades = useSelector((state) => state.informacion.info_actividades);
    const {actividades} = infoActividades;

    return (
        <FlexAgen>
            <TableActAgen width="100%">
                <TheadAgen>
                    <TrAgen radius="10px" shadow="0px 4px 4px rgba(0,0,0,0.25)" background="#E09D52">
                        <ThAgen>Fecha Creación</ThAgen>
                        <ThAgen>Fecha Asignada</ThAgen>
                        <ThAgen>Lección</ThAgen>
                        <ThAgen>Fecha Expiración</ThAgen>
                        <ThAgen>Progreso</ThAgen>
                        <ThAgen>Fecha Ejercitación</ThAgen>
                        <ThAgen width="100%">Ejercitación</ThAgen>
                    </TrAgen>
                </TheadAgen>
                <TbodyAgen>
                    {infoActividadesAgendadas.map(item => (
                    <TrAgen
                        key={item.sesion}
                    >
                        <TdAgen>{item.fecha_registro}</TdAgen>
                        <TdAgen>{item.fecha_agendada}</TdAgen>
                        <TdAgen>{item.sesion}</TdAgen>
                        <TdAgen>{item.fecha_expiracion}</TdAgen>
                        <TdAgen>{item.estado_ejercicio}</TdAgen>
                        <TdAgen>{item.fecha_ejercitacion}</TdAgen>
                        <TdAgen>{item.ejercitacion}</TdAgen>
                    </TrAgen>
                    ))
                    }
                </TbodyAgen>
            </TableActAgen>
        </FlexAgen>
    );
}
 
export default SeleccionarActividadesAgendadas;