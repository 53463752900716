import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, NumM35, BtnImg, BtnM35, ImgB10 } from '../../../../Css/StyledMatematica';

const instrucciones = 'Selecciona el número correspondiente.';
const miniInstrucciones = 'Selecciona el número correspondiente.';

const M35 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [cuadrados, setCuadrados] = useState([]);
    const [barra, setBarra] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();
    
    const verificar = (e, cant) => {
        e.preventDefault();
        if(cant === cuadrados[count-1].cantidad_1) {
            console.log("bien");
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() )                        
                }, 1500);
            }
        } else {
            console.log("mal");
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
            }, 300);
        }
    }
 
    useEffect(() => {
        const newList2 = [1,2,3,4,5,6,7,8,9,10];
        setBarra(newList2);
    }, [])
    
    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(informacion));
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
            
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = 
                [array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList);
        setCuadrados(shuffleList);
        setIndexes(10);
        const newList2 = JSON.parse(JSON.stringify(shuffleList));
        const elOrdenDelId = newList2.map(item => item.cantidad_1)
        setIdOrden(elOrdenDelId);
    }, [informacion])

    useEffect(() => {
        dispatch(setearEjercicioAction('M35') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/ejercitacion-1-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M35",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrden,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <FlexFull direction="column" height="100%" width="100%">
                    <FlexFull direction='column' margin="auto" width="100%" justify='space-evenly' align='center'
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <Flex>
                        {cuadrados[count-1] === undefined ? null :
                        <BtnM35>
                            <ImgB10 src={`/img/${cuadrados[count-1].imagen_url_1}.png`} w='45vw' wm='40vw' wt='45vw' wd='45vw' wlg='45vw' />
                        </BtnM35>
                        }
                        </Flex>
                        <Flex> 
                            {barra.map((item, i) => (
                                <BtnImg
                                    key={i}
                                    disabled={disable}
                                    onClick={(e) => verificar(e, item)}
                                >
                                    <NumM35>{item}</NumM35>
                                </BtnImg>
                            ))}
                        </Flex>
                    </FlexFull>
                    <FlexFull margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <FlexFull>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </FlexFull>
                    </FlexFull>
                </FlexFull>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M35;