import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ImgSubL1 from '../../../Compartidos/ImgSubL1';
//CAMI

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'A continuación observarás el dibujo de una mano. Tendrás que colocar tus dedos como lo indica la imagen y decir en voz alta la cantidad de dedos que tenes levantados. Hazlo lo más rápdio posible.';
const miniInstrucciones = 'Colocar los dedos como lo indica la imagen y decir en voz alta la cantidad de dedos que levantados.';

const M2 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('M2') );
        dispatch(setearUrlAnteriorAction('matematica/subitizacion/nivel-1/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])
    
    return (
        <Fragment>
            <ImgSubL1 width="250px" wm='270px' wt='300px' wd='330px' wlg='360px'  margin='-3vh 0px 0px 0vh' data={data} indexes={indexes} ejercicio="M2" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} estrellas={true} carrito={true} h="20rem" w="20rem" />
        </Fragment>
    );
}
 
export default M2;