import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, BtnLetraLN31, PalabraLN31, InputTextLN31, Img, FormInputLN31 } from '../../../../Css/MetodosCSS/StyledLectoEscritura';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Observa la palabra rápidamente y escribe la primera y última letra. Si no logras leerla puedes volver a hacer click en el recuadro. El objetivo es que logres leerla en el primer intento.';
const miniInstrucciones = 'Observa la palabra rápidamente y escribe la primera y última letra.';

const LN31 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [disable, setDisable] = useState(false);
    const [letra, setLetra] = useState('');
    const [letra2, setLetra2] = useState('');
    const [count, setCount] = useState(1);
    const [contar, setContar] = useState(1);
    const [contarAyuda, setContarAyuda] = useState(0);
    const [mostrarPalabra, setMostrarPalabra] = useState(false);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if (letra === '' || letra2 === '') {
            return;
        }
        const ltr = comparar.palabra_1.slice(0, 1);
        const ltr2 = comparar.palabra_1.slice(comparar.palabra_1.length - 1, comparar.palabra_1.length);

        if (letra.toUpperCase() === ltr.toUpperCase() && letra2.toUpperCase() === ltr2.toUpperCase()) {
            console.log('bien')
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setLetra('');
                    setLetra2('');
                    setContar(1);
                    setMostrarPalabra(false);
                }, 1500);
            } else {
                setDisable(true);
                setTimeout(() => {
                    setDisable(false);
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1500);
            }
        } else {
            console.log('mal')
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setLetra('');
                setLetra2('');
            }, 1500);
        }
    }

    const mostrar = (e) => {
        e.preventDefault();
        if (contar === 1) {
            setMostrarPalabra(true);
            setTimeout(() => {
                setMostrarPalabra(false);
            }, 500);
        } else if (contar === 2) {
            setMostrarPalabra(true);
            setContarAyuda(contarAyuda+1);
            setTimeout(() => {
                setMostrarPalabra(false);
            }, 1000);
        } else if (contar === 3) {
            setMostrarPalabra(true);
            setContarAyuda(contarAyuda+1);
            setTimeout(() => {
                setMostrarPalabra(false);
            }, 1500);
        } else {
            setMostrarPalabra(true);
            setContarAyuda(contarAyuda+1);
        }
        setContar(contar+1);
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LN31') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/ejercicios/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LN31",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda': contarAyuda,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlexx height="100%" width="100%" display="flex" direction="column" justify="center" align="center"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                >
                    <ContainerFlexx display="flex" direction="column" justify="center" align="center" margin="auto">
                        <ContainerFlexx width='23rem' height="6rem">
                            {mostrarPalabra === true
                                ?
                                <PalabraLN31>
                                    {comparar.palabra_1}
                                </PalabraLN31>
                                : 
                                <BtnLetraLN31  width="100%" height="100%"
                                    onClick={(e) => mostrar(e)}
                                >
                                    <Img w='100%' h='100%' br='20px' src={`/img/reves_verde.png`}/>
                                    {/* <Img w='100%' h='100%' br='20px' src={`/img/reves_azul.png`}/> */}
                                </BtnLetraLN31>
                            }
                        </ContainerFlexx>

                        <ContainerFlexx width='100%'>
                            <FormInputLN31
                                onSubmit={(e) => verificar(e)}
                            >
                                <InputTextLN31 m='2rem 2rem 0 2rem' type="text" value={letra} onChange={(e) => setLetra(e.target.value)} disabled={disable} />
                                <InputTextLN31 m='2rem 2rem 0 2rem' type="text" value={letra2} onChange={(e) => setLetra2(e.target.value)} disabled={disable} />
                            </FormInputLN31>
                        </ContainerFlexx>
                    </ContainerFlexx> 

                    <ContainerFlexx margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlexx>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN31;