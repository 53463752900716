import {
    SETEAR_MINUSCULA,
    SETEAR_AYUDA_VISUAL,
    SETEAR_AYUDA_LECTURA,
    SETEAR_AYUDA_IMAGEN,
    RESETEAR_AYUDA_VISUAL,
    RESETEAR_AYUDA_LECTURA,
    MODIFICAR_PACIENTES
} from '../types';
import clienteAxios from '../config/axios';

export function minusculaAction(dato) {
    return (dispatch) => {
        dispatch( minuscula(dato) );
    }
}

export function modificoPacienteMinusculaAction(informacion) {
    return async (dispatch) => {
        try {
            const {config, payload, info_pacientes} = informacion;
            await clienteAxios.post('/paciente/modificopacienteminuscula', payload,config);
            const pacientesFinal = info_pacientes.pacientes;
            const newList = JSON.parse(JSON.stringify(pacientesFinal));
            const index = pacientesFinal.findIndex(item => item.id_paciente === payload.id_paciente);
            newList[index].minuscula = payload.estado;
            dispatch( modificarPacientes(newList));
        } catch (error) {
            console.log(error.response);
        }
    }
}

export function modificoPacienteLecturaAction(informacion) {
    return async (dispatch) => {
        try {
            const {config, payload, info_pacientes} = informacion;
            await clienteAxios.post('/paciente/modificopacientelectura', payload,config);
            const pacientesFinal = info_pacientes.pacientes;
            const newList = JSON.parse(JSON.stringify(pacientesFinal));
            const index = pacientesFinal.findIndex(item => item.id_paciente === payload.id_paciente);
            newList[index].ayuda_lectura = payload.estado;
            dispatch( modificarPacientes(newList));
        } catch (error) {
            console.log(error.response);
        }
    }
}

export function ayudaVisualAction(dato) {
    return (dispatch) => {
        dispatch( ayudaVisual(dato) );
    }
}

export function ayudaLecturaAction(dato) {
    return (dispatch) => {
        dispatch( ayudaLectura(dato) );
    }
}

export function ayudaImagenAction(dato) {
    return (dispatch) => {
        dispatch( ayudaImagen(dato) );
    }
}

export function resetearAyudaAction(dato) {
    return (dispatch) => {
        dispatch(resetearAyudaVisual(dato));
        setTimeout(() => {
            dispatch(resetearAyudaLectura(dato)); 
        }, );
        
    }
}

const minuscula = (dato) => ({
    type: SETEAR_MINUSCULA,
    payload: dato
});

const ayudaVisual = (dato) => ({
    type: SETEAR_AYUDA_VISUAL,
    payload: dato
});

const ayudaLectura = (dato) => ({
    type: SETEAR_AYUDA_LECTURA,
    payload: dato
});

const ayudaImagen= (dato) => ({
    type: SETEAR_AYUDA_IMAGEN,
    payload: dato
});

const resetearAyudaVisual = (dato) => ({
    type: RESETEAR_AYUDA_VISUAL,
    payload: dato
});

const resetearAyudaLectura = (dato) => ({
    type: RESETEAR_AYUDA_LECTURA,
    payload: dato
});


const modificarPacientes = (dato) => ({
    type: MODIFICAR_PACIENTES,
    payload: dato
})
