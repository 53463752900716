import React, {Fragment, useState, useEffect} from 'react';
import { useSelector } from 'react-redux';

const Alertas = (props) => {

    const {alertaOk, errorOk, correctoOk} = props;

    const [mostrarError, setMostrarError] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mostrarCorrecto, setMostrarCorrecto] = useState(false);

    const error = useSelector( (state) => state.estado.error);
    const alerta = useSelector( (state) => state.alertas.alerta);
    const correcto = useSelector( (state) => state.estado.correcto);

    useEffect(() => {
        if(error === null){
            setMostrarError(false);
        } else {
            setMostrarError(true);
            setTimeout(() => {
                setMostrarError(false);
            }, 1500);
        }
    }, [error])

    useEffect(() => {
        if(alerta === null){
            setMostrarAlerta(false);
        } else {
            setMostrarAlerta(true);
            setTimeout(() => {
                setMostrarAlerta(false);
            }, 1500);
        }
    }, [alerta])

    useEffect(() => {
        if(correcto === null){
            setMostrarCorrecto(false);
        } else {
            setMostrarCorrecto(true);
            setTimeout(() => {
                setMostrarCorrecto(false);
            }, 1500);
        }
    }, [correcto])

    return ( 
        <Fragment>
            {errorOk && mostrarError ? <div>{error.msg}</div> : null}
            {alertaOk && mostrarAlerta ? <div>{alerta.msg}</div> : null}
            {correctoOk && mostrarCorrecto ? <div>{correcto.msg}</div> : null}
        </Fragment>
    );
}
 
export default Alertas;