import {
    SUSPENDO_SUSCRIPTOR,
    SUSPENDO_SUSCRIPTOR_EXITO,
    SUSPENDO_SUSCRIPTOR_ERROR,
    ACTIVO_SUSCRIPTOR,
    ACTIVO_SUSCRIPTOR_EXITO,
    ACTIVO_SUSCRIPTOR_ERROR,
    CREAR_SUSCRIPTOR,
    CREAR_SUSCRIPTOR_EXITO,
    CREAR_SUSCRIPTOR_ERROR,
    CAMBIAR_SUSCRIPCION,
    CAMBIAR_SUSCRIPCION_EXITO,
    CAMBIAR_SUSCRIPCION_ERROR,
    PROBAR_GRATIS,
    CANCELAR_PRUEBA_GRATIS
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { solicitoSuscripcionProfesionalAction } from './cargarSuscripcionActions';


export function suspenderSuscriptorAction(informacion) {
    return async (dispatch) => {
        dispatch (suspenderSuscriptor());
        try {
            const {config, payload, infoSuscripcion} = informacion;
            const {data} = await clienteAxios.post('/usuarios/suspendersuscriptor/mobbex', payload, config);
            if(data.msg === 'Perfecto') {
            const newList = JSON.parse(JSON.stringify(infoSuscripcion));
            newList.suscriptor.activo = false;
            dispatch( suspenderSuscriptorExito(newList));
            } else {
                dispatch( suspenderSuscriptorError('Hubo un error') );
            }
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( suspenderSuscriptorError(error.response.data) );
            }
        }
    }
}

const suspenderSuscriptor = () => ({
    type: SUSPENDO_SUSCRIPTOR,
    payload: true
});

const suspenderSuscriptorExito = (info) => ({
    type: SUSPENDO_SUSCRIPTOR_EXITO,
    payload: info
});

const suspenderSuscriptorError = (msg) => ({
    type: SUSPENDO_SUSCRIPTOR_ERROR,
    payload: msg
});

export function activarSuscriptorAction(informacion) {
    return async (dispatch) => {
        dispatch (activarSuscriptor());
        try {
            const {config, payload, infoSuscripcion} = informacion;
            const {data} = await clienteAxios.post('/usuarios/activarsuscriptor/mobbex', payload, config);
            if(data.msg === 'Perfecto') {
            const newList = JSON.parse(JSON.stringify(infoSuscripcion));
            newList.suscriptor.activo = true;
            dispatch( activarSuscriptorExito(newList));
            } else {
                dispatch( activarSuscriptorError('Hubo un error') );
            }
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( activarSuscriptorError(error.response.data) );
            }
        }
    }
}

const activarSuscriptor = () => ({
    type: ACTIVO_SUSCRIPTOR,
    payload: true
});

const activarSuscriptorExito = (info) => ({
    type: ACTIVO_SUSCRIPTOR_EXITO,
    payload: info
});

const activarSuscriptorError = (msg) => ({
    type: ACTIVO_SUSCRIPTOR_ERROR,
    payload: msg
});

export function crearSuscriptorAction(informacion) {
    return async (dispatch) => {
        dispatch (crearSuscriptor());
        try {
            const {config, payload, infoSuscripcion} = informacion;
            const {data} = await clienteAxios.post('/usuarios/crearsuscriptor/mobbex', payload, config);
            const newList = JSON.parse(JSON.stringify(infoSuscripcion));
            newList.suscriptor = data.suscriptor;
            dispatch( crearSuscriptorExito(newList));

        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( crearSuscriptorError(error.response.data) );
            }
        }
    }
}

const crearSuscriptor = () => ({
    type: CREAR_SUSCRIPTOR,
    payload: true
});

const crearSuscriptorExito = (info) => ({
    type: CREAR_SUSCRIPTOR_EXITO,
    payload: info
});

const crearSuscriptorError = (msg) => ({
    type: CREAR_SUSCRIPTOR_ERROR,
    payload: msg
});

export function cambiarSuscripcionAction(informacion) {
    return async (dispatch) => {
        dispatch (cambiarSuscripcion());
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/usuarios/cambiarsuscripcion/mobbex', payload, config);
            if(data.msg === 'Perfecto'){
                dispatch( solicitoSuscripcionProfesionalAction(informacion) );
                dispatch (cambiarSuscripcionExito());
            }
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( cambiarSuscripcionError(error.response.data) );
            }
        }
    }
}

const cambiarSuscripcion = () => ({
    type: CAMBIAR_SUSCRIPCION,
    payload: true
});

const cambiarSuscripcionExito = () => ({
    type: CAMBIAR_SUSCRIPCION_EXITO,
    payload: true
});

const cambiarSuscripcionError = (msg) => ({
    type: CAMBIAR_SUSCRIPCION_ERROR,
    payload: msg
});

export function activarPruebaGratisAction(informacion) {
    return async (dispatch) => {
        try {
            const {config} = informacion;
            const {data} = await clienteAxios.get('/usuarios/activarpruebagratis', config);
            if(data.msg === 'Perfecto'){
                dispatch( probarGratis())
                Swal.fire({
                    icon: 'success',
                    title:'La demo fue activada'
                }).then(function() {
                    window.location = "https://metodoyogen.com/login";
                });
            }
        } catch (error) {
            const errores = error.response;
            console.log(errores)
        }
    }
}

export function cancelarPruebaGratisAction(informacion) {
    return async (dispatch) => {
        try {
            const {config} = informacion;
            const {data} = await clienteAxios.get('/usuarios/cancelarpruebagratis', config);
            if(data.msg === 'Perfecto'){
                dispatch( cancelarPruebaGratis())
                Swal.fire({
                    icon: 'success',
                    title:'La demo fue cancelada'
                }).then(function() {
                    window.location = "https://metodoyogen.com/login";
                });
            }
        } catch (error) {
            const errores = error.response;
            console.log(errores)
        }
    }
}

const probarGratis = () => ({
    type: PROBAR_GRATIS
});

const cancelarPruebaGratis = () => ({
    type: CANCELAR_PRUEBA_GRATIS
});