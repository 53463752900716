import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ImgSubL1 from '../../../Compartidos/ImgSubL1';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

// css

const instrucciones = 'Di rápidamente la cantidad de cebras que observas.';
const miniInstrucciones = 'Di rápidamente la cantidad de cebras que observas.';

const M1 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('M1') );
        dispatch(setearUrlAnteriorAction('matematica/subitizacion/nivel-1/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])
    
    return (
        <Fragment>
            <ImgSubL1 width="300px" wm='300px' wt='340px' wd='360px' wlg='380px'  margin='-3vh 0px 0px 0vh' data={data} indexes={indexes} ejercicio="M1" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} colorFinal={false} estrellas={true} carrito={true} h="20rem" w="20rem" />
        </Fragment>
    );
}
 
export default M1;