import styled from 'styled-components';
import {createGlobalStyle} from 'styled-components';
const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`
export const ContenedorCalendario = styled.div`
    width: 70vw;
    z-index:0;

`
export const Icon = styled.img`
    width: ${props => props.width};
    margin: ${props => props.margin};
`
export const Fondo = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    ${media.tablet}{
        width: ${props => props.ancho};
        height: ${props => props.alto};
    }
`
// MODAL AGREGAR CITA 
export const Agregar = styled.button`
    border: none;
    background-color: white;
    border-radius: 50px;
    position: absolute;
    top: 27vh;
    right: 5vw;
    padding: 8px;
    cursor:pointer;
    ${media.tablet}{
       right:3vw;
    }
`
export const StyledModal = styled.div`
    z-index:1;
    position:absolute;
    background-color:white;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family: 'Montserrat', sans-serif;
    width: 85vw;
    margin-top: 30px;
`
export const ModalLabel = styled.label`
    margin:15px 5px 0px 15px;
`
export const BotonNaranja = styled.button`
    border:none;
    background: #FF9B2A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    margin:20px;
    padding:10px 15px;
    justify-content: center;
    font-size: 12px;
    align-items: center;
    cursor:pointer;
`
export const BotonCerrar = styled.button`
    border:0;
    position:absolute;
    top: 3px;
    right: 3px;
    background:transparent;
    cursor:pointer;
`
export const Input = styled.input`
    margin:10px;
`