import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { TextArea } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { BtnColor, InputText, Pregunta, BtnBasurero } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { ContainerFlex, FormInput } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { Img } from '../../../../Css/StyledForm';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const cortoLargo = [
    { id: '1', comparar_1: '', comparar_2: '', comparar_3: '', comparar_4: '', comparar_5: '', comparar_6: '', comparar_7: '', comparar_8: '', comparar_9: '', comparar_10: '', comparar_11: '', comparar_12: '', comparar_13: '', }
]
const palabra = [
    { id: '1', tipo: 'corta' },
    { id: '2', tipo: 'larga' }
]

const instrucciones = 'Piensa en una oración. Indica la cantidad de palabras que presenta. Luego escribila.';
const miniInstrucciones = 'Piensa en una oración. Indica la cantidad de palabras que presenta. Luego escribila.';

const ESC11 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [count, setCount] = useState(1);
    const [asignar, setAsignar] = useState(palabra)
    const [asignado, setAsignado] = useState([]);
    const [numero, setNumero] = useState('');
    const [frase, setFrase] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if (frase === '' || numero === '' || asignado.comparar_1 === '') {
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false)
            }, 1000);
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if (count < indexes) {
                setDisable(false);
                setFrase('');
                setNumero('');
                setAsignado([]);
                setCount(count + 1);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1500);
            }
        }
    }

    const nada = (e) => {
        e.preventDefault(e);
    }

    const setearTipo = (e, tipo) => {
        e.preventDefault();

        const newList = JSON.parse(JSON.stringify(asignado));
        const obj = {tipo}
        newList.push(obj);

        setAsignado(newList);
    }

    const borrar = (e) => {
        e.preventDefault();
        setAsignado([]);
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC11') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/oraciones/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC11",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center" align="center"
                    isCorrect={isCorrect} isIncorrect={isIncorrect}
                >
                    <ContainerFlex display="flex" justify="center" align="center" margin="auto">
                        <ContainerFlex margin="0 1rem 0 0" display="flex" justify="center">
                            <Img alt='' width="220px" src={`/img/${comparar.imagen_url_1}.png`} />
                        </ContainerFlex>
                        <ContainerFlex width="auto" display="flex" direction="column">
                            <ContainerFlex display="flex" align="center" width="100%">
                                <Pregunta uppercase="uppercase" font="18px" color="rgba(100, 100, 100, 1)" margin="0 1rem">
                                    ¿Cuántas palabras tiene?
                                </Pregunta>
                                <FormInput >
                                    <InputText radius="75px" font="2rem" width="4rem" type="numeric" min="1" max="6" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                                </FormInput>
                            </ContainerFlex>
                            <ContainerFlex display="flex" margintop="2rem">
                                <ContainerFlex display="flex" direction="column">
                                    {asignar.map(item => (

                                        <BtnColor bg={item.tipo === "corta" ? "#FDB62C" : "#23AAAF"} width={item.tipo === "corta" ? "2.5rem" : "4rem"} height="2.5rem" color="white" font="1.8rem" radius="10px" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" margin="0 1rem 1rem 0" weight="bold"
                                            key={item.id}
                                            onClick={(e) => setearTipo(e, item.tipo)}
                                        >
                                            +
                                        </BtnColor>
                                    ))}
                                    <BtnBasurero bg="transparent" border="none"
                                        disabled={disable}
                                        onClick={(e) => borrar(e)}
                                    >
                                        <img alt='' src={`/img/icono_basurero.png`} />
                                    </BtnBasurero>
                                </ContainerFlex>
                                <ContainerFlex display="flex" align="center">
                                    <ContainerFlex display="flex" direction="row" wrap="wrap">
                                        {asignado.length === 0 ? null : asignado.map((item,i) => (
                                            <ContainerFlex bg={item.tipo === "corta" ? "#FDB62C" : "#23AAAF"} width={item.tipo === "corta" ? "2.5rem" : "4rem"} height="2.5rem" color="white" font="1.8rem" radius="10px" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" margin="0 1rem 1rem 0" weight="bold"
                                                disabled={true}
                                                key={i}>
                                            </ContainerFlex>
                                        ))}
                                    </ContainerFlex>
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex margintop="4rem">
                                <FormInput
                                    onSubmit={(e) => nada(e)}
                                >
                                    <TextArea width="35vw" widthmob="70vw" height="19vh" uppercase="uppercase" shadow="inset 0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" font="1rem" padding="1rem 0rem 0 1rem" type="text"  value={frase} onChange={(e) => setFrase(e.target.value)} disabled={disable} />
                                </FormInput>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlex>
                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC11;