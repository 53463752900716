import {
    CERRAR_SESION    
} from '../types';

// Cierro la sesión del usuario
export function cerrarSesionAction() {
    return async (dispatch) => {
        dispatch( cerrarSesion() );
    }
}

const cerrarSesion = () => ({
    type: CERRAR_SESION
});
