import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import HeaderUno from './Compartidos/HeaderUno';
import HeaderMetodos from '../AuxiliaresPaginas/HeaderMetodos';

// css
import { HrMetodos } from '../Css/StyledHeader';

const Cognicion = () => {
    return ( 
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} n1={"Métodos"} componente={"COGNICION"} paciente={true} fw='600' bgcolor="#D05F67" color="white" width='96vw' />
            <h1>Desde Cognicion</h1>

            <Link to={'/escritorio/metodos'}>Atras</Link>

        </Fragment>
    );
}
 
export default Cognicion;