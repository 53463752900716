import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, ImgB10, InputM50, AvanzarImg, AvanzarBtn, TextM50 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Escribe los números y resuelve la suma.';
const miniInstrucciones = 'Escribe los números y resuelve la suma.';

const M51 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState('');
    const [numeroUno, setNumeroUno] = useState('');
    const [numeroDos, setNumeroDos] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if(numeroUno.toString() !== comparar[count-1].cantidad_1.toString() || numeroDos.toString() !== comparar[count-1].cantidad_2.toString() || numero.toString() !== (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numeroUno.toString() !== comparar[count-1].cantidad_1.toString()) {
                    setNumeroUno('');
                }
                if(numeroDos.toString() !== comparar[count-1].cantidad_2.toString()) {
                    setNumeroDos('');
                }
                if(numero.toString() !== (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString()) {
                    setNumero('');
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setNumeroUno('');
                    setNumeroDos('');
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setNumeroUno('');
                    setNumeroDos('');
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(informacion));
        setComparar(newList);
        setIndexes(newList.length);
        setTipoEjercicio('unidad');
    }, [informacion])

    useEffect(() => {
        dispatch(setearEjercicioAction('M51') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/ejercitacion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M51",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex direction="column" margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                    {/* NO FUNCIONA */}
                    {/* <div>
                        {comparar[count-1] === undefined ? null :
                        <div>
                            <div>
                                <img src={`/img/${comparar[count-1].imagen_url_1}.png`} />
                            </div>    
                            <div>
                                <img src={`/img/${comparar[count-1].imagen_url_2}.png`} />
                            </div>
                        </div>
                        }
                    </div> */}
                    {/* FIN NO FUNCIONA */}
                    
                        {/* REEMPLAZO DE LO QUE NO FUNCIONA */}
                        <Flex>
                            <ImgB10 src={`/img/${comparar[count-1].imagen_url_1}.png`} w='27vw' wm='25.5vw' wt='19vw' wd='16vw' wlg='15vw' m='0px 3.5vw 0px 0vw' /> 
                            <ImgB10 src={`/img/${comparar[count-1].imagen_url_2}.png`} w='27vw' wm='25.5vw' wt='19vw' wd='16vw' wlg='15vw' m='0px 0vw 0px 3.5vw' /> 
                        </Flex>
                        {/* FIN REEMPLAZO DE LO QUE NO FUNCIONA */}
                        {/* SUMA */}
                        <Flex margin="2rem 0 0 0">
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM50 type="numeric" min="1" max="5" value={numeroUno} onChange={(e) => setNumeroUno(e.target.value)} disabled={disable} />
                            </form>
                            <TextM50 color='#D33B52'>+</TextM50>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM50 type="numeric" min="1" max="5" value={numeroDos} onChange={(e) => setNumeroDos(e.target.value)} disabled={disable} />
                            </form>
                            <TextM50 color='#D33B52'>=</TextM50>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM50 type="numeric" min="1" max="10" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                            </form>
                        </Flex>
                        {/* FIN SUMA */}
                    </Flex>
                        {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M51;