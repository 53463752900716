import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

import { setearListaAction } from '../../../../actions/estadoEjercicioActions';

// css
import { ContainerImg, ElegirLink, ContenedorLetras, BtnLetra, BtnElegir } from '../../../Css/MetodosCSS/StyledInstruccionExplicita';
import { ContainerFlex } from '../../../Css/MetodosCSS/StyledLN2Todos';
import { ContainerEjercicio } from '../../../Css/MetodosCSS/StyledEjercicios';
import { HrMetodos } from '../../../Css/StyledHeader';

const SilabaDirectaE = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ESC1, setESC1] = useState(false);
    const [ESC4, setESC4] = useState(false);
    const [ESC5, setESC5] = useState(false);
    const [ESC6, setESC6] = useState(false);
    const [nombre, setNombre] = useState('');
    const [checkeable, setCheckeable] = useState([]);

    const listas_check = useSelector(state => state.ejercicio.listas_check);

    const setearLista = (e, lista) => {
        e.preventDefault();
        dispatch(setearListaAction(lista));
        if(ESC1) {
            navigate('/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC1');
        } else if(ESC4) {
            navigate('/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC4');
        } else if(ESC5) {
            navigate('/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC5');
        } else if(ESC6) {
            navigate('/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC6');
        }
        
    }
    const borrarLetra = (e) => {
        e.preventDefault();
        setESC1(false);
        setESC4(false);
        setESC5(false);
        setESC6(false);
        setNombre('');
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(listas_check));
        setCheckeable(newList)
    }, [])    

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} l3={"escritura/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} n3={"ESCRITURA"} componente={"SILABADIRECTA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <ContainerFlex margintop="2rem" margintopmob="1.5rem"  display="flex" direction="column" justify="center" align="center">
                {ESC1 || ESC4 || ESC5 || ESC6 ? 
                 null
                :
                <ContainerEjercicio>
                    <ContainerEjercicio display="flex" justify="center" align="center" height="30vh" heightmob="15vh">
                        <ElegirLink marginright="2rem" onClick={() => (setESC1(true), setNombre('ESC1silaba_directa'))}>
                            <ContainerImg width="220px" height="140px" widthmob="159px" heightmob="105px" src={`/img/portadas/escritura1.jpg`} alt="" />
                        </ElegirLink>
                        <ElegirLink marginright="2rem" to={`/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC2`}>
                            <ContainerImg width="220px" height="140px" widthmob="159px" heightmob="105px" src={`/img/portadas/escritura2.jpg`} alt="" />
                        </ElegirLink>
                        <ElegirLink  to={`/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC3`}>
                            <ContainerImg width="220px" height="140px" widthmob="159px" heightmob="105px" src={`/img/portadas/escritura3.jpg`} alt="" />
                        </ElegirLink>
                    </ContainerEjercicio>

                    <ContainerEjercicio display="flex" justify="center" align="center" height="30vh" heightmob="15vh">
                        <ElegirLink marginright="2rem" onClick={() => (setESC4(true), setNombre('ESC4silaba_directa'))}>
                            <ContainerImg width="220px" height="140px" widthmob="159px" heightmob="105px" src={`/img/portadas/escritura4.jpg`} alt="" />
                        </ElegirLink>
                        <ElegirLink marginright="2rem" onClick={() => (setESC5(true), setNombre('ESC5silaba_directa'))}>
                            <ContainerImg width="220px" height="140px" widthmob="159px" heightmob="105px" src={`/img/portadas/escritura5.jpg`} alt="" />
                        </ElegirLink>
                        <ElegirLink onClick={() => (setESC6(true), setNombre('ESC6silaba_directa'))}>
                            <ContainerImg width="220px" height="140px" widthmob="159px" heightmob="105px" src={`/img/portadas/escritura6.jpg`} alt="" />
                        </ElegirLink>
                    </ContainerEjercicio>
                </ContainerEjercicio>
                }
                {ESC1 || ESC4 || ESC5 || ESC6 ? 
                <ContenedorLetras height="100%" width="100%" justify="center" align="center">
                    <BtnElegir
                        onClick={(e) => borrarLetra(e)}
                    >
                        ← Volver
                    </BtnElegir>
                    <ContenedorLetras margin="1rem" justify="center" align="center">
                    {checkeable.filter(item => item.tipo === nombre).map(item => (
                        <div key={item.id} >
                            <BtnLetra display="flex" justify="center" align="center" width= "13.5rem" height= "5rem" margin="0.6rem" font="1.8rem" shadow= "0px 4px 4px rgba(0, 0, 0, 0.25)" widthmob="13.5rem" heightmob="4rem"  fontmob="1rem" marginMob= "0.6rem" transform="uppercase" cursor="pointer"

                                onClick={(e) => setearLista(e, item.lista)}
                            >
                                {item.nombre_lista}
                            </BtnLetra>
                        </div>
                    ))}
                    </ContenedorLetras>
                </ContenedorLetras>
                :
                null
                }
            </ContainerFlex>
        </Fragment>
    );
}

export default SilabaDirectaE;