import React, {Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SumaCompartido from './SumaCompartido';
import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Resuelve la suma.';
const miniInstrucciones = 'Resuelve la suma.';

const M58 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [indexes, setIndexes] = useState(0);

    useEffect(() => {
        const min = 10;
        const max = 99;
        let lista = []
        for(let i = 0; i < 5; i++){
            let randomNumero = 0;
            do {
                randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
            } while (lista.includes(randomNumero) || [10,20,30,40,50,60,70,80,90].includes(randomNumero));
            lista.push(randomNumero)
        }
        const modelo = [
            {id:'1', cantidad_1:lista[0], cantidad_2:10},
            {id:'2', cantidad_1:lista[1], cantidad_2:10},
            {id:'3', cantidad_1:lista[2], cantidad_2:10},
            {id:'4', cantidad_1:lista[3], cantidad_2:10},
            {id:'5', cantidad_1:lista[4], cantidad_2:10}
        ]
        setData(modelo);
        setIndexes(modelo.length);
        dispatch(setearEjercicioAction('M58') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <SumaCompartido data={data} indexes={indexes} ejercicio="M58" tipo={"decena"} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} ayuda={true} width={"12vw"} inp={"15vw"} color={"#FF9B2A"} />
        </Fragment>
    );
}
 
export default M58;