import React, {Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';

import { ayudaVisualAction } from '../../../../../actions/configuradorActions';

import { Flex, FlexCuz, FlexFull, BtnAyudaM82P, Pasos, PasosM89, PasosT, PasosSubT, PasosSubTM89} from '../../../../Css/StyledMatematica';

const AyudaCuadricula = (props) => {

    const {tipo} = props;

    const dispatch = useDispatch();

    const [modalIsOpen, setIsOpen] = useState(false);

    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);

    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
        if(ayuVisualSolicitada){
            return;
        } else {
            dispatch( ayudaVisualAction(true) );
        }
    }
    const closeModal = (e) => {
        e.preventDefault();
        setIsOpen(false);
    }
    
    return (
        <Fragment>
            <div>
                <BtnAyudaM82P onClick={(e) => openModal(e)}>?</BtnAyudaM82P>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >
                    {tipo !== 'suma_tradicional' ? null :
                    <FlexFull direction='column' justify='center' align='center'>
                        <PasosT>
                            PASOS
                        </PasosT>
                        <PasosSubT>
                            SUMA TRADICIONAL
                        </PasosSubT>
                        <Pasos bgcolor='#D76970'>
                            1- COLOCO LOS NÚMEROS EN SU RESPECTIVO LUGAR
                        </Pasos>
                        <Pasos bgcolor='#23AAAF'>
                            2- COMIENZO SUMANDO LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#FF9B2A'>
                            3- ¿CÓMO ESCRIBO EL NÚMERO?
                        </Pasos>
                        <Pasos bgcolor='#FFDC73'>
                            4- AHORA SUMO LAS DECENAS
                        </Pasos>
                        <Pasos bgcolor='#655EE8'>
                            5- ¿CUÁL ES EL RESULTADO?
                        </Pasos>
                    </FlexFull>
                    }
                    {tipo !== 'suma_descomposicion' ? null :
                    <FlexFull direction='column' justify='center' align='center'>
                        <PasosT>
                            PASOS
                        </PasosT>
                        <PasosSubT>
                            SUMA DESCOMPOSICIÓN
                        </PasosSubT>
                        <Pasos bgcolor='#D76970'>
                            1- DESCOMPONGO EL PRIMER NÚMERO
                        </Pasos>
                        <Pasos bgcolor='#23AAAF'>
                            2- DESCOMPONGOO EL SEGUNDO NÚMERO
                        </Pasos>
                        <Pasos bgcolor='#FF9B2A'>
                            3- SUMO LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#FFDC73'>
                            4- SUMO LAS DECENAS
                        </Pasos>
                        <Pasos bgcolor='#D76970'>
                            5- HAGO LA SUMA
                        </Pasos>
                        <Pasos bgcolor='#655EE8'>
                            6- ¿CUÁL ES EL RESULTADO?
                        </Pasos>
                    </FlexFull>
                    }
                    {tipo !== 'suma_descomposicion_compleja' ? null :
                    <FlexFull direction='column' justify='center' align='center' height='76vh'>
                        <PasosT>
                            PASOS
                        </PasosT>
                        <PasosSubT>
                            SUMA POR DESCOMPOSICIÓN
                        </PasosSubT>
                        <Pasos bgcolor='#D76970'>
                            1- DESCOMPONGO EL PRIMER NÚMERO
                        </Pasos>
                        <Pasos bgcolor='#23AAAF'>
                            2- DESCOMPONGOO EL SEGUNDO NÚMERO
                        </Pasos>
                        <Pasos bgcolor='#FF9B2A'>
                            3- SUMO LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#FFDC73'>
                            4- SUMO LAS DECENAS
                        </Pasos>
                        <Pasos bgcolor='#655EE8'>
                            5- VUELVO A DESCOMPONER LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#D76970'>
                            6- SUMO LAS DECENAS
                        </Pasos>
                        <Pasos bgcolor='#23AAAF'>
                            7- VUELVO A ESCRIBIR LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#FF9B2A'>
                            8- HAGO LA SUMA
                        </Pasos>
                        <Pasos bgcolor='#FFDC73'>
                            9- ¿CUÁL ES EL RESULTADO?
                        </Pasos>
                    </FlexFull>
                    }
                    {tipo !== 'resta_tradicional' ? null :
                    <FlexFull direction='column' justify='center' align='center' >
                        <PasosT>
                            PASOS
                        </PasosT>
                        <PasosSubT>
                            RESTA TRADICIONAL
                        </PasosSubT>
                        <Pasos bgcolor='#D76970'>
                            1- COLOCO LOS NÚMEROS EN SU RESPECTIVO LUGAR
                        </Pasos>
                        <Pasos bgcolor='#23AAAF'>
                            2- EL NÚMERO MAS GRANDE SE COLOCA ARRIBA
                        </Pasos>
                        <Pasos bgcolor='#FF9B2A'>
                            3- COMIENZO RESTANDO LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#FFDC73'>
                            4- AHORA SUMO LAS DECENAS
                        </Pasos>
                        <Pasos bgcolor='#655EE8'>
                            5- ¿CUÁL ES EL RESULTADO?
                        </Pasos>
                    </FlexFull>
                    }
                    {tipo !== 'resta_tradicional_dificil' ? null :
                    <FlexFull direction='column' justify='center' align='center' height='76vh'>
                        <PasosT>
                            PASOS
                        </PasosT>
                        <PasosSubTM89>
                            RESTA TRADICIONAL CON DIFICULTAD
                        </PasosSubTM89>
                        <PasosM89 bgcolor='#D76970'>
                            1- COLOCO LOS NÚMEROS EN SU RESPECTIVO LUGAR
                        </PasosM89>
                        <PasosM89 bgcolor='#23AAAF'>
                            2- EL NÚMERO MAS GRANDE SE COLOCA ARRIBA
                        </PasosM89>
                        <PasosM89 bgcolor='#FF9B2A'>
                            3- COMIENZO RESTANDO LAS UNIDADES
                        </PasosM89>
                        <PasosM89 bgcolor='#FFDC73'>
                            4- ¿ME ALCANZA PARA QUITARLE?
                        </PasosM89>
                        <PasosM89 bgcolor='#655EE8'>
                            5- LE PIDO UNA DECENA AL COMPAÑERO
                        </PasosM89>
                        <PasosM89 bgcolor='#D76970'>
                            6- COLOCO LA DECENA DONDE CORRESPONDE
                        </PasosM89>
                        <PasosM89 bgcolor='#23AAAF'>
                            7- AHORA SI PUEDO RESTAR
                        </PasosM89>
                        <PasosM89 bgcolor='#FF9B2A'>
                            8- CONTINUO CON LAS DECENAS
                        </PasosM89>
                        <PasosM89 bgcolor='#FFDC73'>
                            9- ¿CÚAL ES EL RESULTADO?
                        </PasosM89>
                </FlexFull>
                    }
                    {tipo !== 'resta_descomposicion' ? null :
                   <FlexFull direction='column' justify='center' align='center'>
                        <PasosT>
                            PASOS
                        </PasosT>
                        <PasosSubT>
                            RESTA POR DESCOMPOSICIÓN
                        </PasosSubT>
                        <Pasos bgcolor='#D76970'>
                            1- DESCOMPONGO EL PRIMER NÚMERO
                        </Pasos>
                        <Pasos bgcolor='#23AAAF'>
                            2- DESCOMPONGOO EL SEGUNDO NÚMERO
                        </Pasos>
                        <Pasos bgcolor='#FF9B2A'>
                            3- RESTO LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#FFDC73'>
                            4- RESTO LAS DECENAS
                        </Pasos>
                        <Pasos bgcolor='#655EE8'>
                            5- HAGO LA SUMA
                        </Pasos>
                        <Pasos bgcolor='#D76970'>
                            6- ¿CUÁL ES EL RESULTADO?
                        </Pasos>
                    </FlexFull>
                    }
                    {tipo !== 'resta_descomposicion_compleja' ? null :
                    <FlexFull direction='column' justify='center' align='center' height='76vh'>
                        <PasosT>
                            PASOS
                        </PasosT>
                        <PasosSubT>
                            RESTA POR DESCOMPOSICIÓN
                        </PasosSubT>
                        <Pasos bgcolor='#D76970'>
                            1- DESCOMPONGO EL PRIMER NÚMERO
                        </Pasos>
                        <Pasos bgcolor='#23AAAF'>
                            2- DESCOMPONGOO EL SEGUNDO NÚMERO
                        </Pasos>
                        <Pasos bgcolor='#FF9B2A'>
                            3- ¿PUEDO RESTAR LAS UNIDADES?
                        </Pasos>
                        <Pasos bgcolor='#FFDC73'>
                            4- DESCOMPONGO EL NÚMERO UTILIZANDO DE GUÍA LAS UNIDADES DEL NÚMERO ANTERIOR
                        </Pasos>
                        <Pasos bgcolor='#655EE8'>
                            5- RESTO LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#D76970'>
                            6- RESTO LAS DECENAS
                        </Pasos>
                        <Pasos bgcolor='#23AAAF'>
                            7- ESCRIBO LAS UNIDADES
                        </Pasos>
                        <Pasos bgcolor='#FF9B2A'>
                            8- HAGO LA RESTA
                        </Pasos>
                        <Pasos bgcolor='#FFDC73'>
                            9- ¿CUÁL ES EL RESULTADO?
                        </Pasos>
                    </FlexFull>
                    }
                </Modal>
            </div>
        </Fragment>
    );
}
 
export default AyudaCuadricula;