import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import { ContainerFlex, FormInput } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { TextArea } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { Img } from '../../../../Css/StyledForm';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Escribe 4 oraciones describiendo la imagen.';
const miniInstrucciones = 'Escribe 4 oraciones describiendo la imagen.';

const ESC13 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [count, setCount] = useState(1);
    const [fraseUno, setFraseUno] = useState('');
    const [fraseDos, setFraseDos] = useState('');
    const [fraseTres, setFraseTres] = useState('');
    const [fraseCuatro, setFraseCuatro] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if (fraseUno === '' || fraseDos === '' || fraseTres === '' || fraseCuatro === '') {
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false)
            }, 1000);
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if (count < indexes) {
                setDisable(false)
                setFraseUno('');
                setFraseDos('');
                setFraseTres('');
                setFraseCuatro('');
                setCount(count + 1);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1500);
            }
        }
    }

    const nada = (e) => {
        e.preventDefault(e);
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC13') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC13",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center" align="center"
                    isCorrect={isCorrect} isIncorrect={isIncorrect}
                >

                    <ContainerFlex display="flex" justify="center" align="center" margin="auto">
                        <ContainerFlex  marginright="0.5rem" marginRightMob="0.5rem">
                            <Img width="200px" widthmob="170px" src={`/img/${comparar.imagen_url_1}.png`} />
                        </ContainerFlex>
                        <ContainerFlex display="flex" direction="column">
                            <TextArea width="50vw" height="15vh" radius="10px" font="1.3rem" margin="0.5rem 0" type="text" border="3px solid #23AAAF" value={fraseUno} onChange={(e) => setFraseUno(e.target.value)} disabled={disable} />
                            <TextArea width="50vw" height="15vh" radius="10px" font="1.3rem" margin="0.5rem 0" type="text" border="3px solid #23AAAF" value={fraseDos} onChange={(e) => setFraseDos(e.target.value)} disabled={disable} />
                            <TextArea width="50vw" height="15vh" radius="10px" font="1.3rem" margin="0.5rem 0" type="text" border="3px solid #23AAAF" value={fraseTres} onChange={(e) => setFraseTres(e.target.value)} disabled={disable} />
                            <TextArea width="50vw" height="15vh" radius="10px" font="1.3rem" margin="0.5rem 0" type="text" border="3px solid #23AAAF" value={fraseCuatro} onChange={(e) => setFraseCuatro(e.target.value)} disabled={disable} />
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlex>

                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC13;