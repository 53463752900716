import React, {Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import TipoDenoRapida from '../../../Compartidos/TipoDenoRapida';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const miniInstrucciones = 'Nombra la vocal que aparece.';

const data = [
    {id:1, palabra_1:'a', imagen:'', bgcolor: '#23AAAF'},
    {id:2, palabra_1:'e', imagen:'', bgcolor: '#FF9B2A'},
    {id:3, palabra_1:'i', imagen:'', bgcolor: '#FFDC73'},
    {id:4, palabra_1:'o', imagen:'', bgcolor: '#655EE8'},
    {id:5, palabra_1:'u', imagen:'', bgcolor: '#D05F67'}
]

const FluidezVocales = () => {

    const dispatch = useDispatch();

    const indexes = 5;

    useEffect(() => {
        dispatch(setearEjercicioAction('FluidezVocales') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/letras/vocales'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <Fragment>
            <TipoDenoRapida data={data} ejercicio="FluidezVocales" indexes={indexes} miniInstrucciones={miniInstrucciones} minMay={false} />
        </Fragment>
    );
}
 
export default FluidezVocales;