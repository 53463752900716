import React, {Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';

import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgB10, PM65, CalcPres, Container } from '../../../../Css/StyledMatematica';

const instrucciones = '';
const miniInstrucciones = '';

const M65 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);
    const contadorTerciario = useSelector(state => state.ejercicio.contadorTerciario);

    const indexes = 1;

    useEffect(() => {
        if(contador > indexes) {
            dispatch( finalizarEjercicioAction() );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contador])

    useEffect(() => {
        dispatch(setearEjercicioAction('M65') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(finalizar){
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Fragment>
                    {contador === 0 && 
                    <Flex direction='column' height='70vh'>
                        <FlexFull width='95vw' wm='90vw' wt='80vw' wd='80vw' wlg='80vw'>
                            <FlexFull justify='space-evenly' align='center' width='60vw' wm='60vw' wt='50vw' wd='50vw' wlg='50vw' height='70px'>
                                {contadorSecundario >= 0 && 
                                <Flex>
                                    <PM65 fs='2.8vw' fsm='2.4vw' fst='2.2vw' fsd='2vw' fslg='1.8vw' talign='center'>
                                        CUANDO QUITAMOS
                                    </PM65>
                                </Flex>
                                }
                                {contadorSecundario >= 0 && 
                                <Flex width='60px'> 
                                    <ImgB10 src={`/img/arrowRight.png`} w='40px' wm='60px' wt='60px' wd='60px' wlg='60px' m='0px 0px 0px -30px' mm='0px' mt='0px' md='0px' mlg='0px' />
                                </Flex>
                                }
                                <Container minw='80px'>
                                {contadorSecundario > 0 && 
                                    <CalcPres>
                                        RESTAMOS
                                    </CalcPres>
                                }
                                </Container>
                            </FlexFull>
                        </FlexFull>
                        <Flex direction='column' margin='8vh 0px 0px 0px' height='150px'>
                            {contadorSecundario >= 1 &&
                            <div>
                                {/* <ImgB10 src={`/img/arrowdown.png`} w='18px' /> */}
                                <ImgB10 src={`/img/arrowRight.png`} h='20px' tr='rotate(90deg)' />
                            </div>
                            }
                            <Container minh='115px'>
                            {contadorSecundario > 1 &&
                            <PM65 m='10vh 0px 0px 0px' fs='2.8vw' fsm='2.4vw' fst='2.2vw' fsd='2vw' fslg='1.8vw' talign='center'>
                                SI UN RATÓN TIENE <b>3</b> QUESOS Y SE COMIÓ <b>2</b> <br />
                                AHORA LE QUEDA SOLO <b>1</b>
                            </PM65>
                            }
                            </Container>
                        </Flex>
                        <FlexCuz position='fixed' right='0' bottom='0' margin='0px 0px 60px 0px'>
                            <ImgB10 src={`/img/raton3quesos.png`} w='27vw' wm='23vw' wt='22vw' wd='20vw' wlg='20vw' />
                        </FlexCuz>
                    </Flex>
                    }
                    {contador === 1 &&
                    <Flex> {/* border='2px solid green' */}
                        <FlexFull width='25%' wm='20%' wt='20%' wd='25%' wlg='20%'>
                        {contadorTerciario >= 0 &&
                        <div>
                            <ImgB10 src={`/img/nene.png`} w='50vw' wm='35vw' wt='30vw' wd='28vw' wlg='28vw' m='-70px'/>
                        </div>
                        }
                        </FlexFull>
                        <FlexFull direction='column' width='75%' wm='80%' wt='78%' wd='75%%' wlg='70%' height='68vh' justify='center' align='start'>
                            <Flex direction='column' height='50%'>
                                {contadorTerciario >= 0 &&
                                <div>
                                    <PM65 color='#23AAAF' fs='3.5vw' fsm='3vw' fst='2.6vw' fsd='2.4vw' fslg='2.2vw' fw='600'>
                                        ¡TE MUESTRO UN TRUQUITO PARA RESTAR!
                                    </PM65>
                                </div>
                                }
                            </Flex>
                            <FlexFull direction='column' height='50%' justify='center' align='start'>
                                {contadorTerciario > 0 &&
                                <Flex>
                                    <PM65 fs='2.8vw' fsm='2.4vw' fst='2.2vw' fsd='2vw' fslg='1.8vw'>
                                        PONEMOS LA MANO ABIERTA
                                    </PM65>
                                    <div>
                                        {/* <img src={`/img/manoabierta.png`} />   */}
                                        <ImgB10 src={`/img/dedos_5.png`} w='13vw' wm='9vw' wt='8vw' wd='6vw' wlg='6vw' m='0px 0px 0px 10px' />
                                    </div>
                                </Flex>
                                }
                                {contadorTerciario > 1 &&
                                <Flex margin='0px 0px 0px 5vw'>
                                    <PM65 fs='2.8vw' fsm='2.4vw' fst='2.2vw' fsd='2vw' fslg='1.8vw' d='inline-block'>
                                        BAJAMOS LA CANTIDAD DE DEDOS QUE ME INDICA EL NÚMERO
                                        <PM65 color='#FF9B2A' d='inline-block' fs='6vw' fsm='5vw' fst='4.5vw' fsd='3.9vw' fslg='3.7vw' fw='700' m='0px 0px 0px 10px'>
                                            3
                                        </PM65>
                                    </PM65>
                                    
                                </Flex>
                                }
                            </FlexFull>
                        </FlexFull>
                    </Flex>
                    }
                    <FlexCuz position='fixed' right='0' bottom='0'>
                    {contador === 0 && contadorSecundario === 0 && <BotoneraBottom aumentarSecundario={true} />}
                    {contador === 0 && contadorSecundario === 1 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />}
                    {contador === 0 && contadorSecundario === 2 && <BotoneraBottom reducirSecundario={true} aumentar={true} />}
                    {contador === 1 && contadorTerciario === 0 && <BotoneraBottom reducir={true} aumentarTerciario={true} />}
                    {contador === 1 && contadorTerciario === 1 && <BotoneraBottom reducirTerciario={true} aumentarTerciario={true} />}
                    {contador === 1 && contadorTerciario === 2 && <BotoneraBottom reducirTerciario={true} aumentar={true} />}
                    </FlexCuz>
                </Fragment>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M65;