import React, {Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, NumM37, PM37, ImgB10 } from '../../../../Css/StyledMatematica';

const instrucciones = 'Cuenta oralmente para atrás con la ayuda del ratoncito.';
const miniInstrucciones = 'Cuenta oralmente para atrás con la ayuda del ratoncito.';

const M37 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contador = useSelector(state => state.ejercicio.contador);

    const indexes = 9;

    useEffect(() => {
        dispatch(setearEjercicioAction('M37') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/ejercitacion-1-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(contador < indexes){
            return;
        } else {
            dispatch( finalizarEjercicioAction() );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contador])

    useEffect(() => {
        if(finalizar){
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <FlexFull direction="column" height="100%" width="100%">
                    <FlexFull direction="column" margin="auto" width="100%" justify="center" align="center">
                        <FlexFull height='23vh' width='90%' wm='80%' wt='70%' wd='70%' wlg='70%' justify='start' align='center'>
                            <Flex>
                                <ImgB10 src={`/img/ratonM37.png`} w='25vw' wm='22vw' wt='18vw' wd='15vw' wlg='15vw' />
                                <FlexFull wrap="wrap" justify='center' align='center'>
                                    <PM37 m='0px 0px -5vh 0px'>CONTEMOS PARA ATRÁS</PM37>
                                </FlexFull>
                            </Flex>
                        </FlexFull>
                        <FlexFull height='40vh' width='70%' wm='60%' wt='50%' wd='50%' wlg='50%' justify='start' align='center' margin='-11vw 0px 0px 0px' mm='-6.5vw 0px 0px 0px' mt='-2vw 0px 0px 0px' md='-1vw 0px 0px 0px' mlg='-1vw 0px 0px 0px'>
                            <Flex>
                                <NumM37 m='0px -1.5vw 17vh 0px' mm='0px -1.5vw 19vh 0px' mt='0px -1.5vw 22.5vh 0px' md='0px -1.5vw 25vh 0px' mlg='0px -1.5vw 25vh 0px'>9</NumM37>
                                {contador >= 1 && <NumM37>8</NumM37>}
                                {contador >= 2 && <NumM37 m='0px 0vw -17vh -1.5vw' mm='0px 0vw -19vh -1.5vw' mt='0px 0vw -22.5vh -1.5vw' md='0px 0vw -25vh -1.5vw' mlg='0px 0vw -25vh -1.5vw' >7</NumM37>}
                                {contador >= 3 && <NumM37 m='0px 0vw -10vh 0.8vw'>6</NumM37>}
                                {contador >= 4 && <NumM37 m='0px 0vw 3vh 0.8vw'>5</NumM37>}
                                {contador >= 5 && <NumM37 m='0px 0vw 15vh 0.8vw'>4</NumM37>}
                                {contador >= 6 && <NumM37 m='0px 0vw 30vh 0.8vw'>3</NumM37>}
                                {contador >= 7 && <NumM37 m='0px 0vw 21vh 0.8vw'>2</NumM37>}
                                {contador >= 8 && <NumM37 m='0px 0vw 11vh 0.8vw'>1</NumM37>}
                            </Flex>
                        </FlexFull>
                    </FlexFull>
                    <FlexCuz margin="0 0rem 0 1rem" width="95%" justify="space-between">
                        <FlexCuz>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </FlexCuz>
                        <FlexCuz>
                            {contador === 0 && <BotoneraBottom aumentar={true} /> }
                            {contador >= 1 && contador < 8 && <BotoneraBottom reducir={true} aumentar={true} /> }
                            {contador === 8 && <BotoneraBottom reducir={true} aumentar={true} cantidad={indexes} /> }
                        </FlexCuz>
                    </FlexCuz>
                </FlexFull>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M37;