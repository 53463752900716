import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
// css
import {ContImagenes, Flex, Btn, ContenedorIcons, ContInst, Icons} from '../../../../Css/MetodosCSS/StyledCCSilabica';

const instrucciones = 'Coloca el dibujo en el recuadro que corresponde según la cantidad de sílabas que presenta.';
const miniInstrucciones = 'Coloca el dibujo en el recuadro que corresponde según la cantidad de sílabas que presenta.';

const listAsignar = [
    {id: '1', items:[], silaba:'1'},
    {id: '2', items:[], silaba:'2'},
    {id: '3', items:[], silaba:'3'},
    {id: '4', items:[], silaba:'4'}
]

const LEN22 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(0);
    const [disable, setDisable] = useState(false);
    const [asignar, setAsignar] = useState();
    const [indexes, setIndexes] = useState();
    const [asignado, setAsignado] = useState(listAsignar);
    const [asignable, setAsignable] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignarItem = (e, silaba) => {
        e.preventDefault();
        // cambiar el css del button
        setAsignable(silaba);
    }

    const setearItem = (e, asignable, index) => {
        e.preventDefault();
        if(asignable === '' || asignable === undefined) {
            return;
        }
        let grpI = asignado.findIndex(grp => {
            return grp.silaba === index
        });

        let grpI2 = asignar.map(function(e) { return e.id; }).indexOf(asignable.id);
        if(asignado[grpI].silaba.toString() !== asignable.cantidad_silabas.toString()){
            console.log("mal")
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setAsignable('')
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
            }, 500);
        } else {
            console.log("bien")
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            const newList = JSON.parse(JSON.stringify(asignado));
            newList[grpI].items.push(asignable.imagen_url_1)
            setAsignado(newList);
            const newList2 = JSON.parse(JSON.stringify(asignar));
            delete newList2[grpI2].imagen_url_1;
            setAsignar(newList2);
            setAsignable('');
            setCount(count + 1);
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
            }, 500);
            if(count + 1 >= indexes ) {
                setUltimoTiempo(true);
                setTimeout(() => {
                    dispatch( finalizarEjercicioAction() )                        
                }, 1000);
            } else {
                return
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN22') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-silabica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setAsignar(informacion);
        setIndexes(Object.keys(informacion).length)
    }, [informacion])

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(contarTiempo));
        newList.push(new Date());
        setContarTiempo(newList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': "LEN22",
                'errores': contarErrores,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                'lista_usada': lista_usada,
                sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} ayuImagen={true} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto">
                        <Flex>
                            {asignado.map(item => (
                                <ContImagenes
                                    key={item.id}
                                    onClick={(e) => setearItem(e, asignable, item.silaba)}
                                    isCorrect={isCorrect} 
                                    isIncorrect={isIncorrect}
                                >
                                    {item.silaba}
                                    {item.items[0] ? <Icons src={`/img/${item.items[0]}.png`} /> : ""}
                                    {item.items[1] ? <Icons src={`/img/${item.items[1]}.png`} /> : ""}
                                    {item.items[2] ? <Icons src={`/img/${item.items[2]}.png`} /> : ""}
                                    {item.items[3] ? <Icons src={`/img/${item.items[3]}.png`} /> : ""}
                                    {item.items[4] ? <Icons src={`/img/${item.items[4]}.png`} /> : ""}
                                    {item.items[5] ? <Icons src={`/img/${item.items[5]}.png`} /> : ""}
                                    {item.items[6] ? <Icons src={`/img/${item.items[6]}.png`} /> : ""}
                                    {item.items[7] ? <Icons src={`/img/${item.items[7]}.png`} /> : ""}
                                    {item.items[8] ? <Icons src={`/img/${item.items[8]}.png`} /> : ""}
                                    {item.items[9] ? <Icons src={`/img/${item.items[9]}.png`} /> : ""}
                                </ContImagenes>
                            ))}
                        </Flex>
                        <Flex margin="0.5rem 0rem 0rem 0rem">
                            <ContenedorIcons>
                                {asignar.map(item => (
                                <Btn 
                                    disabled={disable}
                                    key={item.id}
                                    onClick={(e) => asignarItem(e, item)}
                                >
                                    <Icons src={item.imagen_url_1 ? `/img/${item.imagen_url_1}.png` : null} />
                                    {ayuda_imagen ? 
                                        <div>{item.imagen_url_1 ? item.ayuda_imagen_1 : null }</div>
                                    : null}
                                </Btn>
                            ))}
                            </ContenedorIcons>
                            
                        </Flex>
                    </Flex>
                    <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default LEN22;