import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

import HeaderUno from '../../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../../AuxiliaresPaginas/HeaderMetodos';

import { HrMetodos } from '../../../../Css/StyledHeader';
import { Flex, FlexFull, Links1Al10, ImgB10, PEj } from '../../../../Css/StyledMatematica';

const EjercitacionResta = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"calculo/"} l4={"resta/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"CALCULO"} n4={"RESTA"} componente={"EJERCITACION"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex height='100%'>
                <FlexFull align='center' justify='start' wrap='wrap' width='100%' wm='90%' wt='75%' wd='65%' wlg='60%'>
                    <Flex margin='3px 0px 3px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/ejercitacion/M66`}><ImgB10 src={`/img/portadas/matematica66.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>RESTA CON MANOS</PEj>
                    </Flex>
                    <Flex margin='3px 0px 3px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/ejercitacion/M67`}><ImgB10 src={`/img/portadas/matematica67.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>RESTA CON RECTA NUMÉRICA</PEj>
                    </Flex>
                    <Flex margin='3px 0px 3px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/ejercitacion/M68`}><ImgB10 src={`/img/portadas/matematica68.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>RESTA CON MATERIAL ABSTRACTO</PEj>
                    </Flex>
                    <Flex margin='3px 0px 3px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/ejercitacion/M70`}><ImgB10 src={`/img/portadas/matematica70.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>RESTA CON MATERIAL CONCRETO</PEj>
                    </Flex>
                    <Flex margin='3px 0px 3px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/resta/ejercitacion/M71`}><ImgB10 src={`/img/portadas/matematica71.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='2vw' fsm='1.5vw' fst='1.2vw' fsd='1.1vw' fslg='1vw'>RESTA</PEj>
                    </Flex>
                    {/* <Flex margin='3px 0px 3px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/suma/ejercitacion/8`}><ContainerEj color='#23AAAF' w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' h='13vh' hm='13vh' ht='16vh' hd='17vh' hlg='18vh' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw'></ContainerEj></Links1Al10>
                        <PEj m='-4px'>DESCOMPOSICIÓN</PEj>
                    </Flex> */}
                </FlexFull>
            </Flex>
        </Fragment>    
    );
}
 
export default EjercitacionResta;