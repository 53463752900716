import React, {Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import Instrucciones from '../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../Hooks/useConfigAuth';

import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';
import { finalizarEjercicioAction } from '../../../../actions/estadoEjercicioActions';
import { zeroContadorSecundarioAction, zeroContadorTerciarioAction, aumentarContadorSecundarioAction } from '../../../../actions/botoneraEjercicioActions';
import { ayudaVisualAction } from '../../../../actions/configuradorActions';

import { Flex, FlexFull, NumFlex, NumAyudaM30B, Numbers, ButtonAyuda, Number, BtnConteo, Btn, BtnNum, FlexCuz, NumbersM19B } from '../../../Css/StyledMatematica';

const data = [
    {id:'1', numero: 1, color: "#FFDC73"},
    {id:'2', numero: 2, color: "#23AAAF"},
    {id:'3', numero: 3, color: "#D76970"},
    {id:'4', numero: 4, color: "#FF9B2A"},
    {id:'5', numero: 5, color: "#655EE8"},
    {id:'6', numero: 6, color: "#FFDC73"},
    {id:'7', numero: 7, color: "#23AAAF"},
    {id:'8', numero: 8, color: "#D76970"},
    {id:'9', numero: 9, color: "#FF9B2A"}
]

const data2 = [
    {id:'10', numero: 1},
    {id:'11', numero: 2},
    {id:'12', numero: 3}
]
const data3 = [
    {id:'13', numero: 4},
    {id:'14', numero: 5},
    {id:'15', numero: 6}
]
const data4 = [
    {id:'16', numero: 1, color: 'amarillo'},
    {id:'17', numero: 2, color: 'rosa'},
    {id:'18', numero: 3, color: 'celeste'},
    {id:'19', numero: 4, color: 'violeta'},
    {id:'20', numero: 5, color: 'amarillo'},
    {id:'21', numero: 6, color: 'rosa'}
]
const data5 = [
    {id:'22', numero: 7},
    {id:'23', numero: 8},
    {id:'24', numero: 9}
]
const data6 = [
    {id:'25', numero: 1},
    {id:'26', numero: 2},
    {id:'27', numero: 3},
    {id:'28', numero: 4},
    {id:'29', numero: 5},
    {id:'30', numero: 6},
    {id:'31', numero: 7},
    {id:'32', numero: 8},
    {id:'33', numero: 9}
]

const colors = {
    amarillo: "#FFDC73",
    celeste: "#23AAAF",
    rosa: "#D76970",
    naranja: "#FF9B2A",
    violeta: "#655EE8",
  };

const Practica19 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { ejercicio, instrucciones, miniInstrucciones } = props;

    const [comparar, setComparar] = useState([]);
    const [comparar2, setComparar2] = useState([]);
    const [comparar3, setComparar3] = useState([]);
    const [comparar4, setComparar4] = useState([]);
    const [comparar5, setComparar5] = useState([]);
    const [comparar6, setComparar6] = useState([]);
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);
    const [recordError, setRecordError] = useState(false);
    const [tUnoAlTres, setTUnoAlTres] = useState([]);
    const [tCuatroAlSeis, setTCuatroAlSeis] = useState([]);
    const [tUnoAlSeis, setTUnoAlSeis] = useState([]);
    const [tResto, setTResto] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const error = useSelector(state => state.ejercicio.error);
    const contadorTerciario = useSelector(state => state.ejercicio.contadorTerciario);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
            dispatch( ayudaVisualAction(true) );
        }
    }

    useEffect(() => {
        const newList2 = JSON.parse(JSON.stringify(data2));
        const newList3 = JSON.parse(JSON.stringify(data3));
        const newList4 = JSON.parse(JSON.stringify(data4));
        const newList5 = JSON.parse(JSON.stringify(data5));
        const newList6 = JSON.parse(JSON.stringify(data6));

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const newShuffleList2 = shuffle(newList2);
        const newShuffleList3 = shuffle(newList3);
        const newShuffleList4 = shuffle(newList4);
        const newShuffleList5 = shuffle(newList5);
        const newShuffleList6 = shuffle(newList6);

        setComparar(data);
        setComparar2(newShuffleList2);
        setComparar3(newShuffleList3);
        setComparar4(newShuffleList4);
        setComparar5(newShuffleList5);
        setComparar6(newShuffleList6);
        const elOrdenDelId2 = newShuffleList2.map(item => item.numero);
        const elOrdenDelId3 = newShuffleList3.map(item => item.numero);
        const elOrdenDelId4 = newShuffleList4.map(item => item.numero);
        const elOrdenDelId5 = newShuffleList5.map(item => item.numero);
        const elOrdenDelId6 = newShuffleList6.map(item => item.numero);
        setIdOrden([...elOrdenDelId2, ...elOrdenDelId3, ...elOrdenDelId4, ...elOrdenDelId5, ...elOrdenDelId6]);
    }, [])

    useEffect(() => {
        if(contadorTerciario !==10){
            return;
        } else {
            dispatch( finalizarEjercicioAction() )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorTerciario])

    useEffect(() => {
        if(!recordError){
            return;
        }
        if(contadorSecundario < 9 && contadorTerciario < 3){
            return;
        }
        if(contadorSecundario === 9){
            setErroresOrden([...erroresOrden, error]);
            return;
        }
        const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
        setErroresOrden([...erroresOrden, err]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario, contadorTerciario])

    useEffect(() => {
        if(contadorTerciario > 3 ){
            return;
        }
        if(contadorSecundario > 7 && contadorTerciario > 1){
            const newList = JSON.parse(JSON.stringify(tUnoAlTres));
            newList.push(new Date());
            setTUnoAlTres(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario, contadorTerciario]);

    useEffect(() => {
        if(contadorTerciario > 5 ){
            return;
        }
        if(contadorSecundario > 9 && contadorTerciario > 3){
            const newList = JSON.parse(JSON.stringify(tCuatroAlSeis));
            newList.push(new Date());
            setTCuatroAlSeis(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario, contadorTerciario]);

    useEffect(() => {
        if(contadorTerciario > 7 ){
            return;
        }
        if(contadorSecundario > 11 && contadorTerciario > 5){
            const newList = JSON.parse(JSON.stringify(tUnoAlSeis));
            newList.push(new Date());
            setTUnoAlSeis(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario, contadorTerciario]);

    useEffect(() => {
        if(contadorSecundario > 16 && contadorTerciario > 7){
            const newList = JSON.parse(JSON.stringify(tResto));
            newList.push(new Date());
            setTResto(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario, contadorTerciario]);

    useEffect(() => {
        if(finalizar === true && contadorSecundario === 26 ){
            dispatch(aumentarContadorSecundarioAction());
        }
        if(finalizar && contadorSecundario === 27 ){
            const t1 = tUnoAlTres.slice(0,5);
            const t2 = tCuatroAlSeis.slice(0,5);
            const t3 = tUnoAlSeis.slice(0,8);
            const t4 = tResto.slice(0,14)
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const t1Final = conseguirTiempos(t1);
            const t2Final = conseguirTiempos(t2);
            const t3Final = conseguirTiempos(t3);
            const t4Final = conseguirTiempos(t4);
            const tiempoFinal = [...t1Final, ...t2Final, ...t3Final, ...t4Final];

            const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
            const erroresOrdenFinal = [...erroresOrden, err];

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'ayuda_visual': ayuVisualSolicitada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrdenFinal,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            dispatch( ayudaVisualAction(false) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        dispatch(zeroContadorSecundarioAction());
        dispatch(zeroContadorTerciarioAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            {iniciar
                ?
                <div>
                    {contadorTerciario === 0 &&
                    <div>
                        <Flex height='78vh'>
                            <FlexFull wrap='wrap' justify='start' align='start' height='60vh' width='89vw' wt='83vw' wd='66vw' wlg='63vw'>
                            {comparar.filter(item => item.id < contadorSecundario +2 ).map(item => 
                                <Numbers color={item.color} key={item.id}>
                                    {item.numero}
                                </Numbers>
                                )}
                            </FlexFull>
                        </Flex>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorSecundario < 8 && contadorSecundario === 0
                            ? 
                            <BotoneraBottom aumentarSecundario={true} />
                            : contadorSecundario < 8 && contadorSecundario > 0 ?
                            <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />
                            :
                            <BotoneraBottom aumentarTerciario={true} />
                            }
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 1 &&
                    <div>
                        <Flex height='78vh'>
                            <Flex>
                                <Numbers color='#FFDC73'>1</Numbers> 
                                <Numbers color='#23AAAF'>2</Numbers> 
                                <Numbers color='#D76970'>3</Numbers>
                            </Flex>
                        </Flex>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <div onClick={() => setRecordError(true)} >
                                <BotoneraBottom reducirTerciario={true} aumentarTerciario={true} />
                            </div>
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 2 &&
                    <div>
                        <FlexFull direction='column' justify='space-evenly' align='center' height='78vh'>
                            {comparar2.filter((item, index) => index === contadorSecundario -8).map(item =>
                                <NumbersM19B color='#FF9B2A' key={item.id}>
                                    {item.numero}
                                </NumbersM19B>
                                )}
                            <Flex margin='6vh 0px 0vh 0px'>
                                <ButtonAyuda
                                    onClick={(e) => setearAyuVisual(e)}
                                >
                                    ?
                                </ButtonAyuda>
                                {ayuVisual &&
                                <Flex direction='column' position='absolute' margin='0vh 0vw 0vh -1vw'>
                                    <Flex>
                                        {comparar.map(item => 
                                        <NumAyudaM30B key={item.id}>{item.numero}</NumAyudaM30B>
                                        )}
                                    </Flex>
                                </Flex>
                                }
                            </Flex>
                        </FlexFull>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorSecundario < 10 && contadorSecundario === 8
                            ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            : contadorSecundario < 10 && contadorSecundario > 8 ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            :
                            <div onClick={() => setRecordError(false)} >
                                <BotoneraBottom pausar={true} error={true} aumentarTerciario={true} />
                            </div>
                            }
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 3 &&
                    <div>
                        <Flex height='78vh'>
                            <Flex>
                                <Numbers color='#FFDC73'>4</Numbers> 
                                <Numbers color='#23AAAF'>5</Numbers> 
                                <Numbers color='#D76970'>6</Numbers>
                            </Flex>
                        </Flex>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <div onClick={() => setRecordError(true)} >
                                <BotoneraBottom aumentarTerciario={true} />
                            </div>
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 4 &&
                    <div>
                        <FlexFull direction='column' justify='space-evenly' align='center' height='78vh'>
                            {comparar3.filter((item, index) => index === contadorSecundario -10).map(item =>
                                <NumbersM19B color='#FF9B2A' key={item.id}>
                                    {item.numero}
                                </NumbersM19B>
                                )}
                            <Flex margin='6vh 0px 0vh 0px'>
                                <ButtonAyuda
                                    onClick={(e) => setearAyuVisual(e)}
                                >
                                    ?
                                </ButtonAyuda>
                                {ayuVisual &&
                                <Flex direction='column' position='absolute' margin='0vh 0vw 0vh -1vw'>
                                    <Flex>
                                        {comparar.map(item => 
                                        <NumAyudaM30B key={item.id}>{item.numero}</NumAyudaM30B>
                                        )}
                                    </Flex>
                                </Flex>
                                }
                            </Flex>
                        </FlexFull>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorSecundario < 12 && contadorSecundario === 10
                            ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            : contadorSecundario < 12 && contadorSecundario > 10 ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            :
                            <div onClick={() => setRecordError(false)} >
                                <BotoneraBottom pausar={true} error={true} aumentarTerciario={true} />
                            </div>
                            }
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 5 &&
                    <div>
                        <Flex height='78vh'>
                            <FlexFull wrap='wrap' justify='start' align='start' height='60vh' width='89vw' wt='83vw' wd='66vw' wlg='63vw'>
                                    <Numbers color='#FFDC73'>1</Numbers> 
                                    <Numbers color='#23AAAF'>2</Numbers> 
                                    <Numbers color='#D76970'>3</Numbers>
                                    <Numbers color='#FF9B2A'>4</Numbers> 
                                    <Numbers color='#655EE8'>5</Numbers> 
                                    <Numbers color='#FFDC73'>6</Numbers>
                            </FlexFull>
                        </Flex>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <div onClick={() => setRecordError(true)} >
                                <BotoneraBottom aumentarTerciario={true} />
                            </div>
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 6 &&
                    <div>
                        <FlexFull direction='column' justify='space-evenly' align='center' height='78vh'>
                            {comparar4.filter((item, index) => index === contadorSecundario -12).map(item =>
                                <NumbersM19B color='#FF9B2A' key={item.id}>
                                    {item.numero}
                                </NumbersM19B>
                                )}
                            <Flex margin='6vh 0px 0vh 0px'>
                                <ButtonAyuda
                                    onClick={(e) => setearAyuVisual(e)}
                                >
                                    ?
                                </ButtonAyuda>
                                {ayuVisual &&
                                <Flex direction='column' position='absolute' margin='0vh 0vw 0vh -1vw'>
                                    <Flex>
                                        {comparar.map(item => 
                                        <NumAyudaM30B key={item.id}>{item.numero}</NumAyudaM30B>
                                        )}
                                    </Flex>
                                </Flex>
                                }
                            </Flex>
                        </FlexFull>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorSecundario < 17 && contadorSecundario === 12
                            ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            : contadorSecundario < 17 && contadorSecundario > 12 ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            :
                            <div onClick={() => setRecordError(false)} >
                                <BotoneraBottom pausar={true} error={true} aumentarTerciario={true} />
                            </div>
                            }
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 7 &&
                    <div>
                        <Flex height='78vh'>
                            <Flex>
                                <Numbers color='#FFDC73'>7</Numbers> 
                                <Numbers color='#23AAAF'>8</Numbers> 
                                <Numbers color='#D76970'>9</Numbers>
                            </Flex>
                        </Flex>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <div onClick={() => setRecordError(true)} >
                                <BotoneraBottom aumentarTerciario={true} />
                            </div>
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 8 &&
                    <div>
                        <FlexFull direction='column' justify='space-evenly' align='center' height='78vh'>
                            {comparar5.filter((item, index) => index === contadorSecundario -17).map(item =>
                                <NumbersM19B color='#FF9B2A' key={item.id}>
                                    {item.numero}
                                </NumbersM19B>
                                )}
                            <Flex margin='6vh 0px 0vh 0px'>
                                <ButtonAyuda
                                    onClick={(e) => setearAyuVisual(e)}
                                >
                                    ?
                                </ButtonAyuda>
                                {ayuVisual &&
                                <Flex direction='column' position='absolute' margin='0vh 0vw 0vh -1vw'>
                                    <Flex>
                                        {comparar.map(item => 
                                        <NumAyudaM30B key={item.id}>{item.numero}</NumAyudaM30B>
                                        )}
                                    </Flex>
                                </Flex>
                                }
                            </Flex>
                        </FlexFull>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorSecundario < 19 && contadorSecundario === 17
                            ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            : contadorSecundario < 19 && contadorSecundario > 17 ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            :
                            <BotoneraBottom pausar={true} error={true} aumentarTerciario={true} />
                            }
                        </FlexCuz>
                    </div>
                    }
                    {contadorTerciario === 9 &&
                    <div>
                        <FlexFull direction='column' justify='space-evenly' align='center' height='78vh'>
                            {comparar6.filter((item, index) => index === contadorSecundario -19).map(item =>
                                <NumbersM19B color='#FF9B2A' key={item.id}>
                                    {item.numero}
                                </NumbersM19B>
                                )}
                            <Flex margin='6vh 0px 0vh 0px'>
                                <ButtonAyuda
                                    onClick={(e) => setearAyuVisual(e)}
                                >
                                    ?
                                </ButtonAyuda>
                                {ayuVisual &&
                                <Flex direction='column' position='absolute' margin='0vh 0vw 0vh -1vw'>
                                    <Flex>
                                        {comparar.map(item => 
                                        <NumAyudaM30B key={item.id}>{item.numero}</NumAyudaM30B>
                                        )}
                                    </Flex>
                                </Flex>
                                }
                            </Flex>
                        </FlexFull>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorSecundario < 27 && contadorSecundario === 19
                            ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            : contadorSecundario < 27 && contadorSecundario > 19 ?
                            <BotoneraBottom pausar={true} error={true} aumentarSecundario={true} />
                            :
                            <div onClick={() => setRecordError(false)} >
                                <BotoneraBottom pausar={true} error={true} aumentarTerciario={true} />
                            </div>
                            }
                        </FlexCuz>
                    </div>
                    }
                </div>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default Practica19;