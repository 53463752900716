import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TipoDenoRapida from '../../Compartidos/TipoDenoRapida';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../actions/estadoEjercicioActions';

// css

const instrucciones = 'Nombra lo más rápido que puedas los siguientes objetos.';
const miniInstrucciones = 'Nombra lo más rápido que puedas los siguientes objetos.';

const LEN11 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN11') );
        dispatch(setearUrlAnteriorAction('lenguaje/denominacion-rapida'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])
    
    return (
        <Fragment>
            <TipoDenoRapida data={data} indexes={indexes} ejercicio="LEN11" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} ayu_visual={false} />
        </Fragment>
    );
}
 
export default LEN11;