import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import useConfigAuth from '../../Hooks/useConfigAuth.js';

import { activarSuscriptorAction, cambiarSuscripcionAction, crearSuscriptorAction, suspenderSuscriptorAction, activarPruebaGratisAction, cancelarPruebaGratisAction } from '../../actions/sucriptorActions.js';

import {BotonPacienteDos, P, Input, Label1, Flex}  from '../Css/StyledPatient.js';
import { FlexLanding, TextoCorto } from '../Css/StyledLanding';
import { setearAlerta } from '../../actions/alertaActions.js';

const MiSuscripcionAux = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const colores = [
        {id:1, color:"#4AA4A5"},
        {id:2, color:"#E8BA35"},
        {id:3, color:"#D53A77"},
        {id:4, color:"#F6DEA9"},
        {id:5, color:"#6261D4"}
    ];

    const infoSuscripcion = useSelector((state) => state.usuario.suscripcion);
    const {suscripciones, suscriptor, estado_suscripcion} = infoSuscripcion;
    const profesional = useSelector((state) => state.usuario.nombre);
    const rol = useSelector((state) => state.usuario.rol);

    const [suscripcionElegida, setSuscripcionElegida] = useState('');
    const [infoSuscripciones, setInfoSuscripciones] = useState([]);
    const [dni, setDni] = useState('');
    const [email, setEmail] = useState('');
    const [nombre, setNombre] = useState('');
    const [disable, setDisable] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [mostrarOtrasSuscripciones, setMostrarOtrasSuscripciones] = useState(false);

    const crearSuscripcion = async (e) => {
        e.preventDefault();
        if(suscriptor){
            Swal.fire({
                title: "¿Esta seguro que quiere cambiar de suscripción?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, cambiar!",
                cancelButtonText:"No"
            }).then(function(result) {
                if(result.isConfirmed) {
                    const informacion = {
                        config,
                        payload: {},
                        infoSuscripcion
                    }
                    dispatch( activarSuscriptorAction(informacion) );
                }
            });

        } else {
            if(dni.trim() === '' || 
            email.trim() === '' || 
            nombre.trim() === '' ||
            suscripcionElegida === '') {
                const alerta = {
                    msg: 'Completar todos los campos de facturación'
                }
                dispatch( setearAlerta (alerta) );
                return;
            }
            setDisable(true);
            const informacion = {
                config,
                payload: {
                    id_plan: suscripcionElegida,
                    dni,
                    email,
                    nombre
                },
                infoSuscripcion
            }
            dispatch( crearSuscriptorAction(informacion) );
            setEmail('');
            setDni('');
            setNombre('');
            setSuscripcionElegida('');
            setDisable(false);
        }
    }

    const cambiarSuscripcion = async (e) => {
        e.preventDefault();
        if(suscripcionElegida === '') {
            const alerta = {
                msg: 'Elija una suscripción'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        Swal.fire({
            title: "¿Esta seguro? Si cambia a un mejor plan tendrá un cargo proporcional a los días restantes del mes teniendo en cuenta el monto nuevo",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, cambiar!",
            cancelButtonText:"No"
        }).then(function(result) {
            if(result.isConfirmed) {
                const planElegido = suscripciones.filter(item => item.id === suscripcionElegida)[0].cantidad_suscriptos;
                const pacientesActivos = estado_suscripcion[0].pacientes_activos;
                if(pacientesActivos > planElegido) {
                    setMensaje(`Usted tiene ${pacientesActivos} pacientes activos y el plan elegido solo permite ${planElegido}. Por favor elija otro plan o desactive pacientes`);
                    return;
                }
                setMensaje('');
                setDisable(true);
                const informacion = {
                    config,
                    payload: {
                        id_plan: suscripcionElegida
                    },
                    infoSuscripcion
                }
                dispatch( cambiarSuscripcionAction(informacion) );
            }
        });
    }

    const editarSuscripcion = (e) => {
        e.preventDefault();
        window.open(suscriptor.url_suscriptor);
    }

    const agregarTarjeta = (e) => {
        e.preventDefault();
        window.open(suscriptor.url_source);
    }

    const cancelarSuscripcion = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Esta seguro de que quiere suspender la suscripción?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, suspender!",
            cancelButtonText:"No"
        }).then(function(result) {
            if(result.isConfirmed) {
                const informacion = {
                    config,
                    payload: {},
                    infoSuscripcion
                }
                dispatch( suspenderSuscriptorAction(informacion) );
            }
        });
    }

    const activarSuscripcion = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Desea activar la suscripción?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, activar!",
            cancelButtonText:"No"
        }).then(function(result) {
            if(result.isConfirmed) {
                const informacion = {
                    config,
                    payload: {},
                    infoSuscripcion
                }
                dispatch( activarSuscriptorAction(informacion) );
            }
        });
    }

    const probarGratis = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Desea activar la versión Demo por 15 días?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, probar!",
            cancelButtonText:"No"
        }).then(function(result) {
            if(result.isConfirmed) {
                const informacion = {
                    config
                }
                dispatch( activarPruebaGratisAction(informacion) );
                setDisable(false);
            }
        });
    }

    const cancelarVersionDemo = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "¿Está seguro que quiere cancelar la versión Demo?",
            icon: "warning",
            text: "Atención: si cancela la versión Demo no podrá volver a solicitarla",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, cancelar!",
            cancelButtonText:"No"
        }).then(function(result) {
            if(result.isConfirmed) {
                const informacion = {
                    config
                }
                dispatch( cancelarPruebaGratisAction(informacion) );
                setDisable(false);
            }
        });
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(suscripciones));
        const arr = newList.sort((a,b) => a.monto - b.monto );
        if(suscriptor) {
            const id_suscripcion = suscriptor.id_suscripcion;
            for(let i = 0; i < arr.length; i++){
                if(arr[i].id_suscripcion === id_suscripcion) {
                    arr[i].suscripto = true;
                } else {
                    arr[i].suscripto = false;
                }
            }
        }
        setInfoSuscripciones(arr);
    }, [suscriptor])

    return (
        <div>
            <FlexLanding justify="center" direction="column">   
                {suscriptor && !mostrarOtrasSuscripciones ?
                <FlexLanding direction="column" justify="center" align="center">
                    <FlexLanding justify="center" align="center">
                        <FlexLanding justify="center" style={{textAlign: "left"}} wrap="wrap" width="50%">
                            {rol === '489431' ?
                                <P color="#514BBE" fontweight="bold">Hola {profesional}, actualmente tenes activa la siguiente suscripción.<br />Si deseas cambiarla apretá el botón de modificar suscripción.<br />Si deseas cancelarla apretá el botón de cancelar suscripción y volvé a activarla cuando lo requieras :)
                                </P>
                            :
                                <P color="#514BBE" fontweight="bold">Hola {profesional}, solo falta agregar una tarjeta para poder empezar a utilizar el Método.<br />Si ya agregaste y el pago fue exitoso, por favor espera unos minutos y volvé a loguearte nuevamente.<br />Sabrás que ya esta listo cuando no aparezca más "NO TIENE ACTIVO EL MÉTODO"
                                </P>
                            }
                            
                        </FlexLanding>
                        <FlexLanding justify="center">
                            {infoSuscripciones?.filter((item) => item.nombre_medio === 'mobbex' && item.suscripto ).map((item, i) => (
                                <FlexLanding border={`3px solid ${colores[i].color}`} borderradius="15px" direction="column"
                                xlg_margin="0rem 1rem 0 1rem" xlg_padding="1.5rem"
                                lg_margin="0rem 1rem 0 1rem" lg_padding="1.5rem"
                                dkt_margin="0rem 1rem 0 1rem" dkt_padding="1.5rem"
                                tb_margin="1rem 1rem 0 1rem" tb_padding="1.5rem"
                                min_margin="1rem 1rem 0 1rem" min_padding="1.5rem"
                                    key={item.id}
                                >
                                    <TextoCorto fontweight="bold">
                                        {item.cantidad_suscriptos} pacientes
                                    </TextoCorto>
                                    <TextoCorto
                                    xlg_margin="0.5rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                                    lg_margin="0.5rem 0 0rem 0rem" lg_fontsize="0.7rem"
                                    dkt_margin="0.5rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                                    tb_margin="0.5rem 0 0rem 0rem" tb_fontsize="0.7rem"
                                    min_margin="0.5rem 0 0rem 0rem" min_fontsize="0.7rem"
                                    >
                                        $ {item.monto} ARS<br/>mensuales
                                    </TextoCorto>
                                </FlexLanding>
                            ))}
                        </FlexLanding>
                    </FlexLanding>
                    <FlexLanding justify="center" margin="2rem 0 0 0">
                        {!estado_suscripcion[0].suscripcion_activo && suscriptor.activo ?
                            <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={(e) => agregarTarjeta(e)}
                            >
                                Agregar Tarjeta
                            </BotonPacienteDos>
                        :
                            <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={(e) => editarSuscripcion(e)}
                            >
                                Modificar tarjeta
                            </BotonPacienteDos>
                        }
                        
                        {suscriptor.activo ?
                            <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={(e) => cancelarSuscripcion(e)}
                            >
                                Cancelar Suscripción
                            </BotonPacienteDos>
                        :
                            <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={(e) => activarSuscripcion(e)}
                            >
                                Activar Suscripción
                            </BotonPacienteDos>
                        }
                    </FlexLanding>
                    <Flex direccion="column" margin="2rem 0 0 0">
                        {mensaje ? <Flex>{mensaje}</Flex> : null}
                        <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                            disabled={disable}
                            onClick={() => setMostrarOtrasSuscripciones(true)}
                        >
                            Cambiar suscripción
                        </BotonPacienteDos>
                    </Flex>
                    
                </FlexLanding>
                : !estado_suscripcion[0].prueba_gratis && !mostrarOtrasSuscripciones ? 
                    <FlexLanding justify="center">
                        <BotonPacienteDos style={{width: "auto"}}
                            onClick={(e) => probarGratis(e)}
                            disabled={disable}
                        >
                            Pruebe 15 días Gratis
                        </BotonPacienteDos>
                    </FlexLanding>
                : suscripcionElegida && !mostrarOtrasSuscripciones ?
                    <FlexLanding>
                        <FlexLanding align="center" justify="center" direction="column"
                        xlg_margin="1rem 0 0rem 0rem"
                        lg_margin="1rem 0 0rem 0rem"
                        dkt_margin="1rem 0 0rem 0rem"
                        tb_margin="1rem 0 0rem 0rem"
                        min_margin="1rem 0 0rem 0rem"
                        >   
                            <form
                                onSubmit={(e) => crearSuscripcion(e)}
                            >
                                <Flex>
                                    <Label1>DNI Facturación</Label1>
                                    <Input
                                        type="numeric"
                                        name="dni"
                                        placeholder="DNI Facturación"
                                        value={dni || ""}
                                        onChange={e => setDni(e.target.value)}
                                    />
                                </Flex>
                                <Flex>
                                    <Label1>Email</Label1>
                                    <Input
                                        type="email"
                                        name="email"
                                        placeholder="email Facturación"
                                        value={email || ""}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </Flex>
                                <Flex>
                                    <Label1>Nombre y Apellido</Label1>
                                    <Input
                                        type="text"
                                        name="nombre"
                                        placeholder="nombre Facturación"
                                        value={nombre || ""}
                                        onChange={e => setNombre(e.target.value)}
                                    />
                                </Flex>
                                <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                    disabled={disable}
                                    onClick={() => setSuscripcionElegida('')}
                                >
                                    Elegir otro plan
                                </BotonPacienteDos>
                                <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                    type="submit"
                                    disabled={disable}
                                >
                                    Crear Suscripción
                                </BotonPacienteDos>
                            </form>
                            <P>La activación puede tardar unos minutos, por favor cierre sesión y vuelva a loguearse</P>
                        </FlexLanding>
                        {infoSuscripciones?.filter((item) => item.nombre_medio === 'mobbex' && item.id === suscripcionElegida).map((item, i) => (
                            <FlexLanding border={`3px solid ${colores[i].color}`} height="100%" borderradius="15px" direction="column" cursor="pointer" bgcolor={item.id === suscripcionElegida ? '#23AAAF' : null}
                            xlg_margin="0rem 1rem 0 1rem" xlg_padding="1.5rem"
                            lg_margin="0rem 1rem 0 1rem" lg_padding="1.5rem"
                            dkt_margin="0rem 1rem 0 1rem" dkt_padding="1.5rem"
                            tb_margin="1rem 1rem 0 1rem" tb_padding="1.5rem"
                            min_margin="1rem 1rem 0 1rem" min_padding="1.5rem"
                                key={item.id}
                            >
                                <TextoCorto fontweight="bold">
                                    {item.cantidad_suscriptos} pacientes
                                </TextoCorto>
                                <TextoCorto
                                xlg_margin="0.5rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                                lg_margin="0.5rem 0 0rem 0rem" lg_fontsize="0.7rem"
                                dkt_margin="0.5rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                                tb_margin="0.5rem 0 0rem 0rem" tb_fontsize="0.7rem"
                                min_margin="0.5rem 0 0rem 0rem" min_fontsize="0.7rem"
                                >
                                    $ {item.monto} ARS<br/>mensuales
                                </TextoCorto>
                            </FlexLanding>
                        ))}
                    </FlexLanding>
                : mostrarOtrasSuscripciones ?
                    <FlexLanding justify="center" direction="column"
                    tb_wrap="wrap"
                    min_wrap="wrap"
                    > 
                        <FlexLanding>
                        {infoSuscripciones?.filter((item) => item.nombre_medio === 'mobbex' ).map((item, i) => (
                            <FlexLanding border={`3px solid ${colores[i].color}`} borderradius="15px" direction="column" height="100%" cursor="pointer" bgcolor={suscripcionElegida && item.id === suscripcionElegida ? '#23AAAF' : item.suscripto ? '#EFC340' : null}
                            xlg_margin="0rem 0.5rem 0 0.5rem" xlg_padding="1.2rem 0.5rem"
                            lg_margin="0rem 0.5rem 0 0.5rem" lg_padding="1.2rem 0.5rem"
                            dkt_margin="0rem 0.5rem 0 0.5rem" dkt_padding="1.2rem 0.5rem"
                            tb_margin="1rem 0.5rem 0 0.5rem" tb_padding="1.2rem 0.5rem"
                            min_margin="1rem 0.5rem 0 0.5rem" min_padding="1.2rem 0.5rem"
                                key={item.id}
                                onClick={() => setSuscripcionElegida(item.id)}
                            >
                                <TextoCorto fontweight="bold">
                                    {item.cantidad_suscriptos} pacientes
                                </TextoCorto>
                                <TextoCorto
                                xlg_margin="0.5rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                                lg_margin="0.5rem 0 0rem 0rem" lg_fontsize="0.7rem"
                                dkt_margin="0.5rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                                tb_margin="0.5rem 0 0rem 0rem" tb_fontsize="0.7rem"
                                min_margin="0.5rem 0 0rem 0rem" min_fontsize="0.7rem"
                                >
                                    $ {item.monto} ARS<br/>mensuales
                                </TextoCorto>
                            </FlexLanding>
                        ))}
                        </FlexLanding>
                        <FlexLanding justify="center" align="center" margin="2rem 0 0 0">
                            <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={() => setMostrarOtrasSuscripciones(false)}
                            >
                                Volver
                            </BotonPacienteDos>
                            <BotonPacienteDos style={{width: "auto", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={(e) => cambiarSuscripcion(e)}
                                disabled={disable}
                            >
                                Cambiar suscripción
                            </BotonPacienteDos>
                        </FlexLanding>
                        <FlexLanding justify="center" align="center" margin="2rem 0 0 0">
                            <P>La activación puede tardar unos minutos, por favor cierre sesión y vuelva a loguearse</P>
                        </FlexLanding>
                    </FlexLanding>
                :  estado_suscripcion[0].prueba_gratis && estado_suscripcion[0].cantidad_suscriptos === 0 && rol === '489431' ?
                        <FlexLanding direction="column" justify="center" align="center">
                            <FlexLanding justify="center" margin="0 0 1rem 0"
                            tb_wrap="wrap"
                            min_wrap="wrap"
                            > 
                                <P>Usted tiene activado la versión Demo. Una vez terminada esta podrá suscribirse o puede apretar el siguiente botón, cancelarla y suscribirse con todas sus funciones.</P>
                            </FlexLanding>
                            <BotonPacienteDos style={{width: "40%", backgroundColor: "#E7973C", color: "#FFFFFF", fontWeight: "bold"}}
                                onClick={(e) => cancelarVersionDemo(e)}
                                disabled={disable}
                            >
                                Cancelar versión Demo
                            </BotonPacienteDos>
                        </FlexLanding>
                    :
                    <FlexLanding justify="center"
                    tb_wrap="wrap"
                    min_wrap="wrap"
                    > 

                        {infoSuscripciones?.filter((item) => item.nombre_medio === 'mobbex').map((item, i) => (
                            <FlexLanding border={`3px solid ${colores[i].color}`} borderradius="15px" direction="column" cursor="pointer" bgcolor={item.id === suscripcionElegida ? '#23AAAF' : null}
                            xlg_margin="0rem 1rem 0 1rem" xlg_padding="1.5rem"
                            lg_margin="0rem 1rem 0 1rem" lg_padding="1.5rem"
                            dkt_margin="0rem 1rem 0 1rem" dkt_padding="1.5rem"
                            tb_margin="1rem 1rem 0 1rem" tb_padding="1.5rem"
                            min_margin="1rem 1rem 0 1rem" min_padding="1.5rem"
                                key={item.id}
                                onClick={() => setSuscripcionElegida(item.id)}
                            >
                                <TextoCorto fontweight="bold">
                                    {item.cantidad_suscriptos} pacientes
                                </TextoCorto>
                                <TextoCorto
                                xlg_margin="0.5rem 0 0rem 0rem" xlg_fontsize="0.7rem"
                                lg_margin="0.5rem 0 0rem 0rem" lg_fontsize="0.7rem"
                                dkt_margin="0.5rem 0 0rem 0rem" dkt_fontsize="0.7rem"
                                tb_margin="0.5rem 0 0rem 0rem" tb_fontsize="0.7rem"
                                min_margin="0.5rem 0 0rem 0rem" min_fontsize="0.7rem"
                                >
                                    $ {item.monto} ARS<br/>mensuales
                                </TextoCorto>
                            </FlexLanding>
                        ))}
                    </FlexLanding>
                }
            </FlexLanding>
        </div>
    );
}
 
export default MiSuscripcionAux;