import React, {Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { aumentarContadorAction, aumentarContadorTerciarioAction, zeroContadorTerciarioAction } from '../../../../../../actions/botoneraEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, PasosSubT, PasosSubTM89, PasosM89, AvanzarBtn, AvanzarImg, Numeros, InputCalcM82B, InputCompartido, InputTachado, PasosT, Pasos, NumCalculo, DivFlexM82, Menos, FlechaP, ResultLeft, ResultRight, Out, DownLeft, DownRight, UpLeft, UpRight, Flecha, Camila, InputM82B } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M89Bis = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [disableUno, setDisableUno] = useState(false);
    const [disableTres, setDisableTres] = useState(false);
    const [disableCuatro, setDisableCuatro] = useState(false);
    const [disableCinco, setDisableCinco] = useState(false);
    const [disableSeis, setDisableSeis] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState('');
    const [digitoD1, setDigitoD1] = useState('');
    const [digitoU2, setDigitoU2] = useState('');
    const [digitoD2, setDigitoD2] = useState('');
    const [digitoLePresto, setDigitoLePresto] = useState('');
    const [digitoFinalUno, setDigitoFinalUno] = useState('');
    const [digitoFinalDos, setDigitoFinalDos] = useState('');
    const [numeroUnidadUno, setNumeroUnidadUno] = useState('');
    const [numeroUnidadDos, setNumeroUnidadDos] = useState('');
    const [numeroDecenaUno, setNumeroDecenaUno] = useState('');
    const [numeroDecenaDos, setNumeroDecenaDos] = useState('');
    const [numeroLePresto, setNumeroLePresto] = useState('');
    const [numeroPrestado, setNumeroPrestado] = useState('');
    const [numeroFinalUno, setNumeroFinalUno] = useState('');
    const [numeroFinalDos, setNumeroFinalDos] = useState('');
    const [numeroFinal, setNumeroFinal] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');
    const [forzarAlmacenar, setForzarAlmacenar] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorTerciario = useSelector(state => state.ejercicio.contadorTerciario);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const verificar = (e) => {
        e.preventDefault();
        if(contadorTerciario === 2){
            if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString()) {
                console.log('mal');
                setDisableUno(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableUno(false);
                    if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString()) {
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableUno(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 5){
            if(digitoLePresto.toString() === numeroLePresto.toString()) {
                console.log("bien");
                setDisableCuatro(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableCuatro(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableCuatro(false);
                    setNumeroLePresto(0);
                }, 300);
            }
        }
        if(contadorTerciario === 6){
            if(numeroPrestado.toString() === '1') {
                console.log("bien");
                setDisableTres(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableTres(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableTres(false);
                    setNumeroPrestado(0);
                }, 300);
            }
        }
        if(contadorTerciario === 7){
            if(digitoFinalUno.toString() === numeroFinalUno.toString()) {
                console.log("bien");
                setDisableCinco(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableCinco(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableCinco(false);
                    setNumeroFinalUno(0);
                }, 300);
            }
        }
        if(contadorTerciario === 8){
            if(digitoFinalDos.toString() === numeroFinalDos.toString()) {
                console.log("bien");
                setDisableSeis(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableSeis(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableSeis(false);
                    setNumeroFinalDos(0);
                }, 300);
            }
        }
        if(contadorTerciario === 9){
            if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() === numeroFinal.toString()) {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setCount(count + 1);
                        dispatch( zeroContadorTerciarioAction() );
                    }, 300);
                } else {
                    setTimeout(() => {
                        if(props.mostrar){
                            setUltimoTiempo(true);
                            setForzarAlmacenar(true);
                            setTimeout(() => {
                                dispatch(aumentarContadorAction());
                                dispatch( zeroContadorTerciarioAction() );
                            }, 0);
                        } else {
                            setUltimoTiempo(true);
                            dispatch( finalizarEjercicioAction() );
                            dispatch( zeroContadorTerciarioAction() );
                        }
                    }, 1500);
                }
            } else {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setNumeroFinal(0);
                }, 300);
            }
        }
    }

    useEffect(() => {
        const randomNumeroUno = (function () {
            const minU = 0;
            const maxU = 8;
            const u = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 2;
            const maxD = 9;
            const d = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d.toString().concat(u.toString()));
            return numero;
        })();
        const randomNumeroDos = (function () {
            const u1 = Number(randomNumeroUno.toString().substring(1,2));
            const d1 = Number(randomNumeroUno.toString().substring(0,1));
            const minU = u1+1;
            const maxU = 9;
            const u2 = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 1;
            const maxD = d1-1;
            const d2 = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d2.toString().concat(u2.toString()));
            return numero;
        })();
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('resta_tradicional_dificil');
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        const u1 = newList.cantidad_1.toString().substring(1,2);
        const d1 = newList.cantidad_1.toString().substring(0,1);
        const u2 = newList.cantidad_2.toString().substring(1,2);
        const d2 = newList.cantidad_2.toString().substring(0,1);

        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(u1);
            setDigitoD1(d1);
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(u2);
            setDigitoD2(d2);
        }
        setDigitoLePresto(newList.cantidad_1.toString().substring(0,1) - 1)
        setDigitoFinalUno((Number(u1) + 10 - Number(u2)).toString());
        setDigitoFinalDos((Number(d1) - 1 - Number(d2)).toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M89Bis') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-tradicional/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar || forzarAlmacenar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M89Bis",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, forzarAlmacenar]);
    
    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment>
                    {contadorTerciario >= 0 &&
                    <FlexFull justify='space-evenly' align='center' height='80vh'>
                        {/* CALCULO Y CUADRICULA */}
                        <FlexFull direction='column' justify='center' align='center' width='40%' wm='40%' wt='50%' wd='50%' wlg='50%' height='76vh'>
                            {/* CALCULO */}
                            {comparar.length === 0 ? null :
                                <FlexFull justify='start' align='center' width='100%' height='35%'>
                                    <NumCalculo color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculo>
                                    <NumCalculo color='#646464'>-</NumCalculo>
                                    <NumCalculo color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculo>
                                    <NumCalculo color='#646464'>=</NumCalculo>
                                    {contadorTerciario >= 9 &&
                                    <form onSubmit={(e) => onSubmit(e)}> {/* 6 */}
                                        <InputCalcM82B type="numeric" min="0" max="10000" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                    </form>
                                    }
                                </FlexFull>
                            }
                            {/* FIN CALCULO */}

                            {/* CUADRICULA */}
                            <FlexFull justify='end' align='end' width='100%' height='65%' margin='0px 0px 3vh 0px' mm='0px 0px 3vh 0px' mt='0px 0px 3vh 0px' md='0px 0px 0px 0px' mlg='0px 0px 0px 0px'>
                                <Flex>
                                        <DivFlexM82>
                                            <Flex>
                                                <Flecha>
                                                    {contadorTerciario >= 8 &&
                                                        <FlechaP>↓</FlechaP>
                                                    }
                                                </Flecha>
                                                <Flecha> 
                                                    {contadorTerciario >= 3 &&
                                                        <FlechaP>↓</FlechaP>
                                                    }
                                                </Flecha>
                                            </Flex>
                                            <Flex>
                                                <UpRight>
                                                    

                                                    <Flex>
                                                        {contadorTerciario >= 2 && contadorTerciario < 5 ?
                                                            contadorTerciario >= 2 &&
                                                            <InputM82B type="numeric" min="0" max="10000" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disableUno} />
                                                            :
                                                            contadorTerciario >= 5 &&
                                                            <Flex>
                                                                <InputTachado type="numeric" min="0" max="10000" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disableUno} /> 
                                                                <InputCompartido type="numeric" min="0" max="10000" value={numeroLePresto} onChange={(e) => setNumeroLePresto(e.target.value)} disabled={disableCuatro} />
                                                            </Flex>
                                                        }
                                                    </Flex>
                                                </UpRight>
                                                <UpLeft>
                                                    <Flex>
                                                    {contadorTerciario >= 2 && contadorTerciario < 6 ?
                                                        contadorTerciario >= 2 &&
                                                        <InputM82B type="numeric" min="0" max="10000" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disableUno} /> 
                                                        :
                                                        contadorTerciario >= 6 &&
                                                        <Flex>
                                                            <InputCompartido type="numeric" min="0" max="10000" value={numeroPrestado} onChange={(e) => setNumeroPrestado(e.target.value)} disabled={disableTres} />
                                                            <InputCompartido type="numeric" min="0" max="10000" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disableUno} /> 
                                                        </Flex>
                                                    }
                                                    </Flex>
                                                </UpLeft>
                                            </Flex>
                                            <Flex>
                                                <Out>
                                                    {contadorTerciario >= 2 &&        
                                                    <Menos>-</Menos>
                                                    }
                                                </Out>
                                                <DownLeft>
                                                {contadorTerciario >= 2 &&
                                                <InputM82B type="numeric" min="0" max="10000" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disableUno} />
                                                }
                                                </DownLeft>
                                                <DownRight>
                                                {contadorTerciario >= 2 &&
                                                <InputM82B type="numeric" min="0" max="10000" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disableUno} />
                                                }
                                                </DownRight>
                                            </Flex>
                                            <Flex>
                                                <ResultLeft> 
                                                {contadorTerciario >= 8 &&
                                                <form onSubmit={(e) => onSubmit(e)}>
                                                    <InputM82B type="numeric" min="0" max="10000" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disableSeis} />
                                                </form>
                                                }
                                                </ResultLeft>
                                                <ResultRight> 
                                                    {contadorTerciario >= 7 &&
                                                    <InputM82B type="numeric" min="0" max="10000" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disableCinco} />
                                                    }
                                                </ResultRight>
                                            </Flex>
                                        </DivFlexM82>
                                </Flex>
                            </FlexFull>
                            {/* FIN CUADRICULA */}
                        </FlexFull>
                        {/* FIN CALCULO Y CUADRICULA */}
                        {/* PASOS */}
                        <FlexFull direction='column' justify='start' align='center' width='30%' height='65vh'>
                                <PasosT>
                                    PASOS
                                </PasosT>
                                <PasosSubTM89>
                                    RESTA TRADICIONAL CON DIFICULTAD
                                </PasosSubTM89>
                                {contadorTerciario >= 1 &&
                                <PasosM89 bgcolor='#D76970'>
                                    1- COLOCO LOS NÚMEROS EN SU RESPECTIVO LUGAR
                                </PasosM89>
                                }
                                {contadorTerciario >= 2 &&
                                <PasosM89 bgcolor='#23AAAF'>
                                    2- EL NÚMERO MAS GRANDE SE COLOCA ARRIBA
                                </PasosM89>
                                }
                                {contadorTerciario >= 3 &&
                                <PasosM89 bgcolor='#FF9B2A'>
                                    3- COMIENZO RESTANDO LAS UNIDADES
                                </PasosM89>
                                }
                                {contadorTerciario >= 4 &&
                                <PasosM89 bgcolor='#FFDC73'>
                                    4- ¿ME ALCANZA PARA QUITARLE?
                                </PasosM89>
                                }
                                {contadorTerciario >= 5 &&
                                <PasosM89 bgcolor='#655EE8'>
                                    5- LE PIDO UNA DECENA AL COMPAÑERO
                                </PasosM89>
                                }
                                {contadorTerciario >= 6 &&
                                <PasosM89 bgcolor='#D76970'>
                                    6- COLOCO LA DECENA DONDE CORRESPONDE
                                </PasosM89>
                                }
                                {contadorTerciario >= 7 &&
                                <PasosM89 bgcolor='#23AAAF'>
                                    7- AHORA SI PUEDO RESTAR
                                </PasosM89>
                                }
                                {contadorTerciario >= 8 &&
                                <PasosM89 bgcolor='#FF9B2A'>
                                    8- CONTINUO CON LAS DECENAS
                                </PasosM89>
                                }
                                {contadorTerciario >= 9 &&
                                <PasosM89 bgcolor='#FFDC73'>
                                    9- ¿CÚAL ES EL RESULTADO?
                                </PasosM89>
                                }
                            </FlexFull>
                            {/* FIN PASOS */}
                    </FlexFull>
                    }

                <FlexCuz  position='fixed' right='0' bottom='0'>
                    {contadorTerciario === 0 && <BotoneraBottom aumentarTerciario={true} />}
                    {contadorTerciario === 1 || contadorTerciario === 3  || contadorTerciario === 4 ? <BotoneraBottom aumentarTerciario={true} /> : null}
                    {contadorTerciario === 2 || contadorTerciario === 5 || contadorTerciario === 6 || contadorTerciario === 7 || contadorTerciario === 8 || contadorTerciario === 9 ?
                    <FlexCuz  position='fixed' right='0' bottom='0'>
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={`/img/icono_avanzar_ninos.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    :null
                    }
                </FlexCuz>

                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default M89Bis;