import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../../Metodos/Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { aumentarContadorAction, aumentarContadorTerciarioAction, zeroContadorTerciarioAction } from '../../../../../../actions/botoneraEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, PasosSubT, AvanzarBtn, AvanzarImg, InputCalcM82B, InputCompartido, PasosT, Pasos, NumCalculo, DivFlexM82, Menos, FlechaP, ResultLeft, ResultRight, Out, OutResult, DownLeft, DownRight, UpLeft, UpRight, Flecha, InputM82B } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la suma.';
const miniInstrucciones = 'Resuelve la suma.';

const M61Bis = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [disableUno, setDisableUno] = useState(false);
    const [disableTres, setDisableTres] = useState(false);
    const [disableCinco, setDisableCinco] = useState(false);
    const [disableSeis, setDisableSeis] = useState(false);
    const [disableSiete, setDisableSiete] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState('');
    const [digitoD1, setDigitoD1] = useState('');
    const [digitoU2, setDigitoU2] = useState('');
    const [digitoD2, setDigitoD2] = useState('');
    const [digitoMeLlevo, setDigitoMeLlevo] = useState('');
    const [digitoFinalUno, setDigitoFinalUno] = useState('');
    const [digitoFinalDos, setDigitoFinalDos] = useState('');
    const [digitoFinalTres, setDigitoFinalTres] = useState('');
    const [numeroUnidadUno, setNumeroUnidadUno] = useState('');
    const [numeroUnidadDos, setNumeroUnidadDos] = useState('');
    const [numeroMeLlevo, setNumeroMeLlevo] = useState('');
    const [numeroDecenaUno, setNumeroDecenaUno] = useState('');
    const [numeroDecenaDos, setNumeroDecenaDos] = useState('');
    const [numeroFinalUno, setNumeroFinalUno] = useState('');
    const [numeroFinalDos, setNumeroFinalDos] = useState('');
    const [numeroFinalTres, setNumeroFinalTres] = useState('');
    const [numeroFinal, setNumeroFinal] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');
    const [forzarAlmacenar, setForzarAlmacenar] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorTerciario = useSelector(state => state.ejercicio.contadorTerciario);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const verificar = (e) => {
        e.preventDefault();
        if(contadorTerciario === 1){
            if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString()) {
                console.log('mal');
                setDisableUno(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableUno(false);
                    if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString()) {
                        setNumeroUnidadUno('');
                        setNumeroDecenaUno('');
                        setNumeroUnidadDos('');
                        setNumeroDecenaDos('');
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableUno(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 3){
            if(digitoMeLlevo.toString() === numeroMeLlevo.toString()) {
                console.log("bien");
                setDisableTres(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableTres(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableTres(false);
                    setNumeroMeLlevo('');
                }, 300);
            }
        }
        if(contadorTerciario === 4){
            if(digitoFinalUno.toString() === numeroFinalUno.toString()) {
                console.log("bien");
                setDisableCinco(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableCinco(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableCinco(false);
                    setNumeroFinalUno('');
                }, 300);
            }
        }
        if(contadorTerciario === 5){
            if(digitoFinalDos.toString() === numeroFinalDos.toString() && digitoFinalTres.toString() === numeroFinalTres.toString()) {
                console.log("bien");
                setDisableSeis(true);
                setDisableSiete(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableSeis(true);
                setDisableSiete(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableSeis(false);
                    setDisableSiete(false);
                    setNumeroFinalDos('');
                    setNumeroFinalTres('');
                }, 300);
            }
        }
        if(contadorTerciario === 6){
            if((comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() === numeroFinal.toString()) {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setCount(count + 1);
                        dispatch( zeroContadorTerciarioAction() );
                    }, 300);
                } else {
                    setTimeout(() => {
                        if(props.mostrar){
                            setUltimoTiempo(true);
                            setForzarAlmacenar(true);
                            setTimeout(() => {
                                dispatch(aumentarContadorAction());
                                dispatch( zeroContadorTerciarioAction() );
                            }, 0);
                        } else {
                            setUltimoTiempo(true);
                            dispatch( finalizarEjercicioAction() );
                            dispatch( zeroContadorTerciarioAction() );
                        }
                    }, 1500);
                }
            } else {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setNumeroFinal('');
                }, 300);
            }
        }
    }

    useEffect(() => {
        const min = 10;
        const max = 99;
        const randomNumeroUno = Math.floor(Math.random() * (max - min +1)) + min;
        const randomNumeroDos = Math.floor(Math.random() * (max - min +1)) + min;
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('suma_tradicional');
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        const u1 = newList.cantidad_1.toString().substring(1,2);
        const u2 = newList.cantidad_2.toString().substring(1,2);
        const d1 = newList.cantidad_1.toString().substring(0,1);
        const d2 = newList.cantidad_2.toString().substring(0,1);

        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(u1);
            setDigitoD1(d1);
        }
        if(newList.cantidad_1.toString().length === 1 ){
            setDigitoU1(u1);
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(u2);
            setDigitoD2(d2);
        }
        if(newList.cantidad_2.toString().length === 1 ){
            setDigitoU2(d2);
        }
        if(Number(u1) + Number(u2) > 9){
            setDigitoMeLlevo((Number(u1) + Number(u2)).toString().substring(0,1));
        } else {
            setDigitoMeLlevo(0);
        }
        
        if(Number(d1) + Number(d2) > 9){
            const final = newList.cantidad_1 + newList.cantidad_2;
            setDigitoFinalTres((final).toString().substring(0,1));
            setDigitoFinalDos((final).toString().substring(1,2));
            setDigitoFinalUno((final).toString().substring(2,3));
        } else {
            setDigitoFinalUno((newList.cantidad_1 + newList.cantidad_2).toString().substring(1,2));
            setDigitoFinalDos((newList.cantidad_1 + newList.cantidad_2).toString().substring(0,1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M61Bis') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/suma-tradicional/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar || forzarAlmacenar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M61Bis",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, forzarAlmacenar]);

    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment>
                    {contadorTerciario >= 0 &&
                    <FlexFull justify='space-evenly' height='80vh'>
                        {comparar.length === 0 ? null :
                            <FlexFull justify='start' align='center' width='20vw' height='20%'> {/*  border='2px solid green' */}
                                <NumCalculo color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculo>
                                <NumCalculo color='#646464'>+</NumCalculo>
                                <NumCalculo color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculo>
                                <NumCalculo color='#646464'>=</NumCalculo>
                                {/* 7 */}
                                {contadorTerciario >= 6 &&
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <InputCalcM82B type="numeric" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                </form>
                                }
                            </FlexFull>
                        }
                        {/* CUADRICULA */}
                        <Flex width='10%'>
                        <Flex margin='0px 75px 0px -50px'>
                                <DivFlexM82>
                                    <Flex>
                                        <Flecha>
                                            {contadorTerciario >= 5 &&
                                                <FlechaP>↓</FlechaP>
                                            }
                                        </Flecha>
                                        <Flecha>
                                            {contadorTerciario >= 2 &&
                                                <FlechaP>↓</FlechaP>
                                            }
                                        </Flecha>
                                    </Flex>
                                    <Flex>
                                        {contadorTerciario >= 3 &&
                                            <InputM82B type="numeric" min="0" max="10000" value={numeroMeLlevo} onChange={(e) => setNumeroMeLlevo(e.target.value)} disabled={disableTres} z='2' />
                                        }
                                        <Flecha>
                                        </Flecha>
                                    </Flex>
                                    <Flex>
                                        <UpRight>
                                            {contadorTerciario >= 1 &&
                                            <InputM82B type="numeric" min="0" max="10000" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disableUno} z='2' />
                                                }
                                        </UpRight>

                                        <UpLeft>
                                            {contadorTerciario >= 1 &&
                                            <InputM82B type="numeric" min="0" max="10000" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disableUno} />
                                            }
                                        </UpLeft>
                                    </Flex>
                                    <Flex>
                                        <Out>
                                            {contadorTerciario >= 2 &&        
                                            <Menos>+</Menos>
                                            }
                                        </Out>
                                        <DownLeft>
                                            {contadorTerciario >= 1 &&
                                            <InputM82B type="numeric" min="0" max="10000" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disableUno} />
                                            }
                                        </DownLeft>
                                        <DownRight>
                                            {contadorTerciario >= 1 &&
                                            <InputM82B type="numeric" min="0" max="10000" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disableUno} />
                                            }
                                        </DownRight>
                                    </Flex>
                                    <Flex>
                                        <OutResult>
                                            {contadorTerciario >= 5 ?
                                                <Flex>
                                                    <InputM82B type="numeric" min="0" max="10000" value={numeroFinalTres} onChange={(e) => setNumeroFinalTres(e.target.value)} disabled={disableSiete} /> 
                                                </Flex>
                                                :
                                                null
                                            }
                                        </OutResult>
                                        <ResultLeft> 
                                            {contadorTerciario >= 5 ?
                                                <Flex>
                                                    <InputM82B type="numeric" min="0" max="10000" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disableSeis} />
                                                </Flex>
                                                :
                                                null
                                            }
                                        </ResultLeft>
                                        <ResultRight>
                                            {contadorTerciario >= 4 &&
                                            <InputM82B type="numeric" min="0" max="10000" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disableSeis} />
                                            }
                                        </ResultRight>
                                    </Flex>
                                </DivFlexM82>
                        </Flex>
                        </Flex>
                        {/* FIN CUADRICULA */}

                        {/* PASOS */}
                        <FlexFull direction='column' justify='start' align='center' width='25vw'>
                                <PasosT>
                                    PASOS
                                </PasosT>
                                <PasosSubT>
                                    SUMA TRADICIONAL
                                </PasosSubT>
                                {contadorTerciario >= 0 &&
                                <Pasos bgcolor='#D76970'>
                                    1- COLOCO LOS NÚMEROS EN SU RESPECTIVO LUGAR
                                </Pasos>
                                }
                                {contadorTerciario >= 2 &&
                                <Pasos bgcolor='#23AAAF'>
                                    2- COMIENZO SUMANDO LAS UNIDADES
                                </Pasos>
                                }
                                {contadorTerciario >= 3 &&
                                <Pasos bgcolor='#FF9B2A'>
                                    3- ¿CÓMO ESCRIBO EL NÚMERO?
                                </Pasos>
                                }
                                {contadorTerciario >= 5 &&
                                <Pasos bgcolor='#FFDC73'>
                                    4- AHORA SUMO LAS DECENAS
                                </Pasos>
                                }
                                {contadorTerciario >= 6 &&
                                <Pasos bgcolor='#655EE8'>
                                    5- ¿CUÁL ES EL RESULTADO?
                                </Pasos>
                                }
                            </FlexFull>
                        {/* FIN PASOS */}
                    </FlexFull>
                    }
                    <FlexCuz position='fixed' right='0' bottom='0'>
                    {contadorTerciario === 0 && <BotoneraBottom aumentarTerciario={true} />}
                    {contadorTerciario === 2 ? <BotoneraBottom aumentarTerciario={true} /> : null}
                    {contadorTerciario === 1 || contadorTerciario === 3 || contadorTerciario === 4 || contadorTerciario === 5 || contadorTerciario === 6 ?
                    <FlexCuz direction='row-reverse' position='fixed' right='0' bottom='0'>
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                        <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    :null
                    }
                    </FlexCuz>
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default M61Bis;