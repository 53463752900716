import React, {Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import Instrucciones from '../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../Hooks/useConfigAuth';

import { aumentarContadorSecundarioAction } from '../../../../actions/botoneraEjercicioActions';
import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';
import { finalizarEjercicioAction } from '../../../../actions/estadoEjercicioActions';

import { Flex, FlexM20, CentenaM30B, NumDec, NumUni, Number, NumXL, NumerosAyuda, ButtonAyuda, DivNum, ButtonNum, UnidadM31B, DecenaM31B, CentenaM31B, MilM31B, NumAyudaM30B, FlexCuz } from '../../../Css/StyledMatematica';

const data = [
    {id:'1', numero:100},
    {id:'2', numero:200},
    {id:'3', numero:300},
    {id:'4', numero:400},
    {id:'5', numero:500},
    {id:'6', numero:600},
    {id:'7', numero:700},
    {id:'8', numero:800},
    {id:'9', numero:900}
]

const data2 = [
    {id:'1', numero:10},
    {id:'2', numero:20},
    {id:'3', numero:30},
    {id:'4', numero:40},
    {id:'5', numero:50},
    {id:'6', numero:60},
    {id:'7', numero:70},
    {id:'8', numero:80},
    {id:'9', numero:90}
]

const PracticaCentena = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { ejercicio, instrucciones, miniInstrucciones } = props;

    const [comparar, setComparar] = useState([]);
    const [comparar2, setComparar2] = useState([]);
    const [decena, setDecena] = useState(0);
    const [centena, setCentena] = useState(0);
    const [miles, setMiles] = useState(0);
    const [decenaAyuda, setDecenaAyuda] = useState(false);
    const [centenaAyuda, setCentenaAyuda] = useState(false);
    const [milesAyuda, setMilesAyuda] = useState(false);
    const [numeroUnidad, setNumeroUnidad] = useState([]);
    const [numeroDecena, setNumeroDecena] = useState([]);
    const [numeroCentena, setNumeroCentena] = useState([]);
    const [numeroMiles, setNumeroMiles] = useState([]);
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista = useSelector(state => state.ejercicio.lista);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const error = useSelector(state => state.ejercicio.error);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const indexes = Object.keys(data).length;

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
        }
    }
    const setearMilesAyuda = (e) => {
        e.preventDefault();
        if(milesAyuda !== true){
            setMilesAyuda(true);
        } else {
            setMilesAyuda(false);
        }
        if(ayuVisualSolicitada === false){
            setAyuVisualSolicitada(true);
        }
    }

    const setearCentenaAyuda = (e) => {
        e.preventDefault();
        if(centenaAyuda !== true){
            setCentenaAyuda(true);
        } else {
            setCentenaAyuda(false);
        }
        if(ayuVisualSolicitada === false){
            setAyuVisualSolicitada(true);
        }
    }
    const setearDecenaAyuda = (e) => {
        e.preventDefault();
        if(decenaAyuda !== true){
            setDecenaAyuda(true);
        } else {
            setDecenaAyuda(false);
        }
        if(ayuVisualSolicitada === false){
            setAyuVisualSolicitada(true);
        }
    }

    useEffect(() => {
        const min = Math.ceil(1000);
        const max = Math.floor(1000000);
        const numero = () => Math.floor(Math.random() * (max - min + 1)) + min;
        const array = Array.from({length: indexes}, () => numero());
        const array2 = array.map(numero => ({longitud: numero.toString().length, hasta: numero.toString().length - 3, numero: numero}));
        setNumeroUnidad(array2.map(item => item.numero.toString().substring(item.longitud-1, item.longitud)));
        setNumeroDecena(array2.map(item => item.numero.toString().substring(item.longitud-2, item.longitud-1)));
        setNumeroCentena(array2.map(item => item.numero.toString().substring(item.longitud-3, item.longitud-2)));
        setNumeroMiles(array2.map(item => item.numero.toString().substring(0, item.hasta)));
        setDecena(array2.map(item => item.numero.toString().substring(item.longitud-2, item.longitud-1)+'0'));
        setCentena(array2.map(item => item.numero.toString().substring(item.longitud-3, item.longitud-2)+'00'));
        setMiles(array2.map(item => item.numero.toString().substring(0, 1)+'.000'));
        setComparar(data);
        setComparar2(data2);
        setIdOrden(array);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(indexes === 0){
            return;
        }
        if(contadorSecundario < indexes) {
            return;
        } else {
            dispatch( finalizarEjercicioAction() )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario]);

    useEffect(() => {
        if(contadorSecundario === 0){
            return;
        }
        if(contadorSecundario === 1){
            setErroresOrden([...erroresOrden, error]);
            return;
        }
        const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
        setErroresOrden([...erroresOrden, err]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario])

    useEffect(() => {
        if(iniciar === false && contadorSecundario === indexes ){
            return;
        }else if(iniciar === false && contadorSecundario === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorSecundario]);

    useEffect(() => {
        if(finalizar === true && contadorSecundario === indexes -1 ){
            dispatch(aumentarContadorSecundarioAction());
        }
        if(finalizar && contadorSecundario === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
            const erroresOrdenFinal = [...erroresOrden, err];

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda_visual': ayuVisualSolicitada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrdenFinal,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorSecundario]);

    return (
        <Fragment>
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto" width="100%">
                        <div>
                            {milesAyuda &&
                            <Flex Flex height='50vh' margin='0vh 0px 0px 0px'>
                                <Flex direction='column'>
                                    <ButtonNum
                                        onClick={(e) => setearMilesAyuda(e)}
                                    >
                                        M
                                    </ButtonNum>
                                    <NumXL onClick={(e) => setearMilesAyuda(e)} color='#655EE8' w='82.5vw' wm='59.5vw' wt='54.5vw' wd='40.5vw' wlg='34.5vw'>
                                        {miles[contadorSecundario]}
                                    </NumXL>
                                </Flex>
                            </Flex>
                            }
                            {centenaAyuda &&
                            <Flex Flex height='50vh' margin='0vh 0px 0px 0px'>
                                <Flex direction='column'>
                                    <ButtonNum
                                        onClick={(e) => setearCentenaAyuda(e)}
                                    >
                                        C
                                    </ButtonNum>
                                    <NumXL onClick={(e) => setearCentenaAyuda(e)} color='#23AAAF' w='69vw' wm='70vw' wt='55vw' wd='37vw' wlg='28vw'>
                                        {centena[contadorSecundario]}
                                    </NumXL>
                                </Flex>
                            </Flex>
                            }
                            {decenaAyuda &&
                            <Flex Flex height='50vh' margin='0vh 0px 0px 0px'>
                                <Flex direction='column'>
                                    <ButtonNum
                                        onClick={(e) => setearDecenaAyuda(e)}
                                    >
                                        D
                                    </ButtonNum>
                                    <NumXL onClick={(e) => setearDecenaAyuda(e)} color='#FF9B2A' w='56vw' wm='42vw' wt='34vw' wd='28vw' wlg='21vw'>
                                        {decena[contadorSecundario]}
                                    </NumXL>
                                </Flex>
                            </Flex>
                            }
                            {decenaAyuda || centenaAyuda || milesAyuda ? null :
                            <Flex height='50vh' margin='0vh 0px 0px 0px'>
                                <Flex direction='column'>
                                    <ButtonNum    
                                        onClick={(e) => setearMilesAyuda(e)}
                                    >
                                        M
                                    </ButtonNum>
                                    <MilM31B onClick={(e) => setearMilesAyuda(e)}>
                                        {numeroMiles[contadorSecundario]}
                                    </MilM31B>
                                </Flex>
                                <Flex direction='column' margin='0vh 0vw 0vh -26vw' mm='0vh 0vw 0vh -20vw' mt='0vh 0vw 0vh -15.3vw' md='0vh 0vw 0vh -12vw' mlg='0vh 0vw 0vh -10vw'>
                                    <ButtonNum
                                        onClick={(e) => setearCentenaAyuda(e)}
                                    >
                                        C
                                    </ButtonNum>
                                    <CentenaM30B onClick={(e) => setearCentenaAyuda(e)}>
                                        {numeroCentena[contadorSecundario]}
                                    </CentenaM30B>
                                </Flex>
                                <Flex direction='column' margin='0vh 0vw 0vh -26vw' mm='0vh 0vw 0vh -20vw' mt='0vh 0vw 0vh -15.3vw' md='0vh 0vw 0vh -12vw' mlg='0vh 0vw 0vh -10vw'>
                                <ButtonNum
                                        onClick={(e) => setearDecenaAyuda(e)}
                                    >
                                        D
                                    </ButtonNum>
                                    <NumDec onClick={(e) => setearDecenaAyuda(e)}>
                                        {numeroDecena[contadorSecundario]}
                                    </NumDec>
                                </Flex>
                                <Flex direction='column' margin='0vh 0vw 0vh -26vw' mm='0vh 0vw 0vh -20vw' mt='0vh 0vw 0vh -15.3vw' md='0vh 0vw 0vh -12vw' mlg='0vh 0vw 0vh -10vw'>
                                    <DivNum>
                                        U
                                    </DivNum>
                                    <NumUni>
                                        {numeroUnidad[contadorSecundario]}
                                    </NumUni>
                                </Flex>
                            </Flex>
                            }
                        </div>
                        <FlexM20 margin='16vh 0px 0vh 0px'>
                        <ButtonAyuda
                            onClick={(e) => setearAyuVisual(e)}
                        >
                            ?
                        </ButtonAyuda>
                        {ayuVisual &&
                        <FlexM20 direction='column' position='absolute' margin='0vh 0vw 0vh -1vw'>
                            <Flex>
                                {comparar2.map(item => 
                                <NumAyudaM30B key={item.id}>{item.numero}</NumAyudaM30B>
                                )}
                            </Flex>
                            <Flex>
                                {comparar.map(item => 
                                <NumAyudaM30B key={item.id}>{item.numero}</NumAyudaM30B>
                                )}
                            </Flex>
                        </FlexM20>
                        
                        }
                        </FlexM20>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <Flex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </Flex>
                        <Flex>
                            <BotoneraBottom error={true} aumentarSecundario={true} />
                        </Flex>
                    </Flex>
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            } 
        </Fragment>
    );
}
 
export default PracticaCentena;