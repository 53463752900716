import React from 'react';
import {CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const ResultadosGrafico = ({ resultados }) => {

    const avance = () => {
      let arrAvance = [];
      let arrCantidad = [];
      let arrItems = [];
      resultados?.map((res) => {
        res.Subejercicios.map((sub, i) => {
          arrCantidad.push(i + 1);
          arrItems.push(sub.Progreso);
        });
      });
      arrAvance.push(arrItems);
      arrAvance.push(arrCantidad);
      return arrAvance;
  };

    const arrAvances = avance();
    const ejercitacion = arrAvances[1];
    const items = arrAvances[0].reverse();

    const data = {
        labels: ejercitacion,
        datasets: [
          {
            label: "Evolución",
            data: items,
            tension: 0.5,
            fill: true,
            borderColor: "rgb(66, 118, 174)",
            backgroundColor: "rgb(80, 143, 211)",
            pointRadius: 5,
            pointBorderColor: "rgb(47, 88, 135)",
            pointBackgroundColor: "rgb(60, 108, 163)",
          },
        ],
    };

    const options = {
        scales: {
            y: {
                title: {
                display: true,
                text: "Avance %",
                },
                min: 0,
                max: 100,
            },
            x: {
                title: {
                display: true,
                text: "Ejercitación",
                },
                ticks: { color: "rgb(42, 74, 111)" },
            },
        },
        maintainAspectRatio: false,
        height: 400,
    };

    return <Line data={data} options={options} />;
};

export default ResultadosGrafico;
