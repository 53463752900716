import styled from 'styled-components';

export const ContenedorPrincipal = styled.div`
    font-family: 'Montserrat', sans-serif;
    background-color:#F2F2F2;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`

export const ContenedorFlexRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.$justifycontent};
    margin: ${props => props.$margin};
`

export const FormTemporal = styled.form`
    margin: 0px 20px 0 20px;
`

export const ContenedorSecundario = styled.div`
    margin: 0px 2.5rem 0 2.5rem;
    
` 

export const FlexColumn = styled.div`
    display:flex;  
    flex-direction: column; 
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};
    flex-grow: 1;
`

export const ContendorPaciente = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 1rem;
    margin-top: 2rem;
    width: 100%;
`

export const GridBox = styled.div`
    display: grid;
    text-align: ${props => props.$textalign};
    justify-content: left;
    align-items: center;
    grid-template-columns: ${props => props.$disposicion};
`

export const DivisionVertical = styled.hr`
    border-top: thick solid #ff0000;
    height: 1.2rem;
`

export const ContenedorGrid = styled.div`
    display: grid;
    grid-template-columns: ${props => props.$disposicion};
    align-items: ${props => props.$alignitems};
`
export const TituloTemp = styled.div`
    color: ${props => props.$color};
    font-size: 1.4rem;
    margin: ${props => props.$margin};
`

export const TituloDosTemp = styled.div`
    font-weight: bold;
    color: black;
    font-size: ${props => props.$fontsize};
`

export const LabelTemp = styled.label`
`

export const InputTemp = styled.input`
    width: 100%;
    border: 0.5px thick black;
    border-radius: 15px;
`
export const Selecciono = styled.select`
    width: 100%;
    border: 0.5px thick black;
    border-radius: 15px;
`

export const FaltaDato = styled.div`
    background-color: red;
    color: black;
`

export const Division = styled.hr`
    border: ${props => props.$border ? props.$border : "0.2px solid"};
    margin: ${props => props.$margin ? props.$margin : "0.75rem 0 0.75rem 0"};
`

export const BotonNaranja = styled.button`
    border:none;
    background: #FF9B2A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    width: ${props => props.$width ? props.$width : "159px"};
    height: 38px;
    margin:${props => props.margin};
    justify-content: center;
    font-size: ${props => props.$fontsize ? props.$fontsize : "12px"};
    font-weight:500;
    align-items: center;
    text-decoration: none;
    color: black;
    cursor:pointer;
    &:hover{
        background: #f0a34c;
    }
`

export const FlexButton = styled.div`
    display:flex;
    justify-content: ${props => props.justify};
    align-items:${props => props.align};
`

export const BtnImg = styled.img`
    width:20px;
    margin: 0 0.5rem 0 0.5rem;
    cursor: pointer;
`