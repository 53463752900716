import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { InputText, Pregunta, BtnBasurero } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { ContainerFlex, FormInput  } from '../../../../Css/MetodosCSS/StyledLN2Todos';

import { ContainerFlexx, DivFlex, BtnAbecedario, BtnLetra, Circulo, BtnColor } from '../../../../Css/MetodosCSS/StyledLectoEscritura';
import { Img } from '../../../../Css/StyledForm';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const array = [
    { id: 1, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 2, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 3, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 4, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 5, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 6, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 7, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 8, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 9, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 10, letra: '', tipo: '', color: '', colorCirculo: '', muestro: false }
]

const abc = [
    { id: '1', letra: 'a', estilo:false, bgcolor:'#23AAAF'}, { id: '2', letra: 'b', estilo:false, bgcolor:'#646464'} , { id: '3', letra: 'c', estilo:false, bgcolor:'#646464'} , { id: '4', letra: 'd', estilo:false, bgcolor:'#646464'} , { id: '5', letra: 'e', estilo:false, bgcolor:'#FF9B2A'} , { id: '6', letra: 'f', estilo:false, bgcolor:'#646464'} , { id: '7', letra: 'g', estilo:false, bgcolor:'#646464'} , { id: '8', letra: 'h', estilo:false, bgcolor:'#646464'} , { id: '9', letra: 'i', estilo:false, bgcolor:'#FFDC73'} , { id: '10', letra: 'j', estilo:false, bgcolor:'#646464'} , { id: '11', letra: 'k', estilo:false, bgcolor:'#646464'} , { id: '12', letra: 'l', estilo:false, bgcolor:'#646464'} , { id: '13', letra: 'm', estilo:false, bgcolor:'#646464'} , { id: '14', letra: 'n', estilo:false, bgcolor:'#646464'} , { id: '15', letra: 'ñ', estilo:false, bgcolor:'#646464'} , { id: '16', letra: 'o', estilo:false, bgcolor:'#655EE8'} , { id: '17', letra: 'p', estilo:false, bgcolor:'#646464'} , { id: '18', letra: 'q', estilo:false, bgcolor:'#646464'} , { id: '19', letra: 'r', estilo:false, bgcolor:'#646464'} , { id: '20', letra: 's', estilo:false, bgcolor:'#646464'} , { id: '21', letra: 't', estilo:false, bgcolor:'#646464'} , { id: '22', letra: 'u', estilo:false, bgcolor:'#D05F67'} , { id: '23', letra: 'v', estilo:false, bgcolor:'#646464'} , { id: '24', letra: 'w', estilo:false, bgcolor:'#646464'} , { id: '25', letra: 'x', estilo:false, bgcolor:'#646464'} , { id: '26', letra: 'y', estilo:false, bgcolor:'#646464'} , { id: '27', letra: 'z', estilo:false, bgcolor:'#646464'},
]

const instrucciones = 'A continuación se presenta un dibujo. En una primera instancia deberás indicar la cantidad de sonidos que presenta la palabra. Luego seleccionaras el círculo gris para las consonantes y el rosa para las vocales.  Por último, seleccionarás las letras.';
const miniInstrucciones = 'A continuación se presenta un dibujo. En una primera instancia deberás indicar la cantidad de sonidos que presenta la palabra. Luego seleccionaras el círculo gris para las consonantes y el rosa para las vocales.  Por último, seleccionarás las letras.';

const ESC1 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [disable, setDisable] = useState(false);
    const [abecedario, setAbecedario] = useState(abc);
    const [numero, setNumero] = useState('');
    const [matrix, setMatrix] = useState([]);
    const [split, setSplit] = useState('');
    const [tipo, setTipo] = useState('');
    const [count, setCount] = useState(1);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const lista = useSelector((state) => state.ejercicio.lista);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        const largo = matrix.filter(item => item.muestro === true).length;
        const newArray = matrix.filter(item => item.muestro === true).map(item => item.tipo);
        const comparoTipo = tipo.map((item, i) => item === newArray[i]);
        const newArray2 = matrix.filter(item => item.muestro === true).map(item => item.letra.toUpperCase());
        const comparoSplit = split.map((item, i) => item === newArray2[i]);
        if (largo !== comparar.palabra_1.length || comparoTipo.find(item => item === false) === false || comparoSplit.find(item => item === false) === false) {
            console.log('mal');
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setMatrix(array)
                setNumero('');
            }, 1000);
        } else {
            console.log('bien')
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setMatrix(array)
                    setNumero('');
                    setCount(count + 1);
                }, 1000);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1000);
            }
        }

    }

    const asignarLetra = (e, letra) => {
        e.preventDefault();
        const colorLetra = (function () {
            let color;
            switch (letra.toUpperCase()) {
                case 'A':
                    color = '#23AAAF';
                    return color;
                case 'E':
                    color = '#FF9B2A';
                    return color;
                case 'I':
                    color = '#FFDC73';
                    return color;
                case 'O':
                    color = '#655EE8';
                    return color;
                case 'U':
                    color = '#D05F67';
                    return color;
                default:
                    color = '#646464';
                    return color;
            }
        })();

        if(matrix.filter(item => item.letra !== "").length.toString() === numero.toString()){
            return;
        }
       
        const newList = JSON.parse(JSON.stringify(matrix));
        const index = matrix.findIndex(item => item.letra === '');
        newList[index].letra = letra;
        newList[index].color = colorLetra;
        setMatrix(newList);
    }

    const borrar = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(matrix));
        newList.map(item => item.colorCirculo = '')
        newList.map(item => item.tipo = '')
        setMatrix(newList)
    }
    const borrar2 = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(matrix));
        newList.map(item => item.letra = '');
        setMatrix(newList);
    }

    const setearTipo = (e, color, tipo) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(matrix));
        const index = matrix.findIndex(item => item.tipo === '' && item.muestro === true);
        if (index === -1) {
            return;
        } else {
            newList[index].colorCirculo = color;
            newList[index].tipo = tipo;
            setMatrix(newList);
        }
    }

    useEffect(() => {
        if (numero > 10) {
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(array));
            newList.filter(item => item.id <= numero).map(item => item.muestro = true);
            setMatrix(newList);
        }
    }, [numero]);

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC1') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/silaba-directa/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[count-1]));
        const newArray = newList.palabra_1.toUpperCase().split("");
        setSplit(newArray);
        const tipo = newArray.map(item => item.toUpperCase() === 'A' || item.toUpperCase() === 'Á' || item.toUpperCase() === 'E' || item.toUpperCase() === 'É' || item.toUpperCase() === 'I' || item.toUpperCase() === 'Í' || item.toUpperCase() === 'O' || item.toUpperCase() === 'Ó' || item.toUpperCase() === 'U' || item.toUpperCase() === 'Ú' ? item = 'vocal' : item = 'consonante');
        setTipo(tipo);
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC1",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    lista,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} ayuImagen={true} />
            {iniciar
                ?
                <ContainerFlexx direction="column" height="100%" width="100%">
                    <ContainerFlexx direction="column" margin="auto" width="100%"
                        isCorrect={isCorrect} isIncorrect={isIncorrect}
                    >
                        {/* PREGUNTA, INPUT E IMAGEN */}
                        <ContainerFlexx wrap='wrap'>
                            <ContainerFlex display="flex" direction="column" align="center" justify="center">
                                <Img alt='' width="160px" height="160px" src={`/img/${comparar.imagen_url_1}.png`} />
                                {ayuda_imagen ? 
                                    <div>{comparar.ayuda_imagen_1}</div>
                                : null}
                            </ContainerFlex>
                            <ContainerFlex display="flex" align="center" >
                                <Pregunta uppercase="uppercase" font="18px" color="rgba(100, 100, 100, 1)" margin="0 1rem">¿Cuántos sonidos tiene?</Pregunta>
                                <FormInput>
                                    <InputText width="4vw" height="6.5vh" widthmob="5vw" heightmob="6vh" radius="75px" font="2rem" type="numeric" min="1" max="6" color="#646464" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                                </FormInput>
                            </ContainerFlex>
                        </ContainerFlexx>
                        {/* FIN PREGUNTA, INPUT E IMAGEN */}
                        <ContainerFlexx>
                        {numero === '' ? null
                            :
                            <ContainerFlex display="flex" direction="column" align="center" margintop="1.5rem" >

                                <DivFlex width='98%' wrap='wrap' justify='center' jt='space-between' jd='space-between' jlg='space-between' align='center'>

                                    <ContainerFlexx>
                                        <ContainerFlex display="flex" direction="column" align="center">
                                            <BtnColor bg="rgba(208, 95, 103, 1)" border="none" width="1.5rem" height="1.5rem" color="white" font="1rem" radius="50px" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" margin="0 0 1rem 0" weight="bold"
                                                onClick={(e) => setearTipo(e, '#D05F67', 'vocal')}
                                            >
                                                +
                                            </BtnColor>

                                            <BtnColor bg="rgba(100, 100, 100, 1)" border="none" width="1.5rem" height="1.5rem" color="white" font="1rem" radius="50px" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" weight="bold"
                                                onClick={(e) => setearTipo(e, 'grey', 'consonante')}
                                            >
                                                +
                                            </BtnColor>
                                        </ContainerFlex>
                                        <ContainerFlex display="flex" align="center">
                                            <ContainerFlex display="flex">
                                                {matrix.filter(item => item.muestro === true).map(item =>
                                                    <ContainerFlex display="flex" padding="0.5rem 0.2rem"
                                                        key={item.id}
                                                    >
                                                        <Circulo style={{ backgroundColor: item.colorCirculo }}></Circulo>
                                                    </ContainerFlex>
                                                )}
                                            </ContainerFlex>
                                            <BtnBasurero bg="transparent" border="none"
                                                onClick={(e) => borrar(e)}
                                            >
                                                <img alt='' src={`/img/icono_basurero.png`} />
                                            </BtnBasurero>
                                        </ContainerFlex>
                                    </ContainerFlexx>

                                    {/* LETRAS A RELLENAR */}
                                    <ContainerFlexx>
                                        <ContainerFlexx bs="inset 0px 4px 4px rgba(0, 0, 0, 0.25)" padding="0.3rem" bradius="10px">
                                            {matrix.filter(item => item.muestro === true).map(item =>

                                                <BtnLetra  $minMay={minuscula} color={ayuda_lectura ? item.color : '#646464'} key={item.id} border='none'
                                                    disabled={true}
                                                >
                                                    {item.letra}
                                                </BtnLetra>
                                            )}
                                        </ContainerFlexx>
                                        <BtnBasurero bg="transparent" border="none"
                                            onClick={(e) => borrar2(e)}
                                        >
                                            <img alt='' src={`/img/icono_basurero.png`} />
                                        </BtnBasurero>
                                    </ContainerFlexx>
                                {/* LETRAS A RELLENAR */}
                                </DivFlex>

                                {/* ABECEDARIO */}
                                <DivFlex wrap='wrap' justify='center' align='center' margin='1rem 0 0 0'>
                                    {abecedario.map(item => (
                                            <BtnAbecedario $estilo={item.estilo} $background={"#69BEB8"} transf='uppercase' $minMay={minuscula} style={ayuda_lectura ? {color:`${item.bgcolor}`} : {color: '#646464'}}
                                                disabled={disable}
                                                key={item.id}
                                                onClick={(e) => asignarLetra(e, item.letra)}
                                            >
                                                {item.letra}
                                            </BtnAbecedario>
                                        ))
                                    }
                                </DivFlex>
                                {/* ABECEDARIO */}
                            </ContainerFlex>
                        }
                        </ContainerFlexx>
                    </ContainerFlexx>
                    <ContainerFlexx margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlexx>
                </ContainerFlexx >
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC1;