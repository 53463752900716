import React, {useEffect} from 'react';

const Tutores = () => {
   

    return (
        <>
           Tutores
        </>
    );
}
 
export default Tutores;
