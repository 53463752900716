import React, { useState } from 'react';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';

import SeleccionarActividadesSeleccionadas from './SeleccionarActividadesSeleccionadas';
import SeleccionarActividadesDisponibles from './SeleccionarActividadesDisponibles';
import SeleccionarActividadesAgendadas from './SeleccionarActividadesAgendadas';

// css
import {Cuerpo, FlexBox} from '../Css/StyledHeader';
import {BotonSeleccionado, FlexActividades} from '../Css/StyledActivities';

const SeleccionarActividades = () => {

    const [actividad, setActividad] = useState('disponible');

    return (
        <Cuerpo>
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes/paciente`} componente={"Ejercitación Domicilio"} paciente={true} />
            <FlexBox justify="center">
                <BotonSeleccionado name="disponible" width="40vw" padding="15px 0px" margin="20px 0px 0px 10px" backgroundcolor={actividad === 'disponible' ? true : false}
                    onClick={e => setActividad(e.target.name)}
                >
                    Todas las Actividades
                </BotonSeleccionado>
                <BotonSeleccionado name="seleccionada" width="40vw" padding="15px 0px" margin="20px 0px 0px 10px" backgroundcolor={actividad === 'seleccionada' ? true : false}
                    onClick={e => setActividad(e.target.name)}
                >
                    Actividades seleccionadas
                </BotonSeleccionado>
                <BotonSeleccionado name="agendada" width="40vw" padding="15px 0px" margin="20px 0px 0px 10px" backgroundcolor={actividad === 'agendada' ? true : false}
                    onClick={e => setActividad(e.target.name)}
                >
                    Actividades Agendadas
                </BotonSeleccionado>
            </FlexBox>
            <FlexBox justify="center">
                <FlexActividades>
                    {actividad === "disponible" ? <SeleccionarActividadesDisponibles /> : null}
                    {actividad === "seleccionada" ? <SeleccionarActividadesSeleccionadas /> : null}
                    {actividad === "agendada" ? <SeleccionarActividadesAgendadas /> : null }
                </FlexActividades>
            </FlexBox>
        </Cuerpo>
    );
}
export default SeleccionarActividades;