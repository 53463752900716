import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { borrarInfoDBAction, proximaRondaAction, proximoEjercicioAction, resetearEjercicioAction, setearItemGrupoAction, setearSesionTerminadaAction } from '../../../actions/estadoEjercicioActions';
import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
// css
import {Consignas, Flex, Btn, Hr} from '../../Css/MetodosCSS/StyledMethods';
import { ContainerFlex } from '../../Css/MetodosCSS/StyledLN2Todos';
import { Img } from '../../Css/StyledForm';
import { BtnTiempo } from '../../Css/MetodosCSS/StyledEjercicios';
import { aumentarRondaAction, zeroContadorConCorreccionAction } from '../../../actions/botoneraEjercicioActions';

const FinalEjercicio = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cambio, setCambio] = useState(false);
    const [disable, setDisable] = useState(true);

    const rol = useSelector(state => state.usuario.rol);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const sesionTer = useSelector((state) => state.ejercicio.sesionTerminada);
    const url = useSelector((state) => state.ejercicio.url);
    const urlAnterior = useSelector((state) => state.ejercicio.urlAnterior);
    const ejercicio = useSelector((state) => state.ejercicio.ejercicio);
    const ronda = useSelector((state) => state.ejercicio.ronda);
    const primerTiempo = useSelector((state) => state.ejercicio.primer_tiempo);
    const segundoTiempo = useSelector((state) => state.ejercicio.segundo_tiempo);
    const ejercicio_almacenado = useSelector((state) => state.ejercicio.ejercicio_almacenado);
    const grupo = useSelector((state) => state.ejercicio.grupo);
    const item_grupo = useSelector((state) => state.ejercicio.item_grupo);

    const siguienteEjercicio = (e) => {
        e.preventDefault();
        let informacionDos;
        const estadoEjercicio = sesion.find(item => item.estado_ejercicio === 'pendiente');
        if(estadoEjercicio){
            dispatch(borrarInfoDBAction());
            setCambio(true);
            return;
        } else {
            const verifico = sesionTer.find(item => item.status === 'pendiente');
            if(verifico) {
                dispatch(borrarInfoDBAction());
                setCambio(true);
                return;
            } else {
                informacionDos = {
                    sesionTerminada: []
                }
                navigate('/escritorio-paciente');
                dispatch(resetearEjercicioAction());
                dispatch(setearSesionTerminadaAction(informacionDos));
            }
        }
        
    }

    const resetearRonda = (e) => {
        e.preventDefault();
        dispatch(zeroContadorConCorreccionAction());
        dispatch(aumentarRondaAction())
        dispatch(proximaRondaAction());
        navigate(`../escritorio/metodos/${urlAnterior}${ejercicio}`, {replace: true});
    }

    const irProximoEjercicio = (e) => {
        e.preventDefault();
        const index = item_grupo+1;
        dispatch(proximoEjercicioAction());
        navigate(grupo[index].url);
        dispatch(setearItemGrupoAction(index));
    }

    const volverHacer = (e) => {
        e.preventDefault();
        dispatch(proximoEjercicioAction());
        navigate(url);
    }

    const resetFinalizar = () => {
        dispatch( resetearEjercicioAction() );
        navigate(`/escritorio/metodos/${urlAnterior}`);
    }

    useEffect(() => {
        if(!cambio){
            return
        }
        dispatch(proximoEjercicioAction());
        navigate(url);
    }, [cambio])

    useEffect(() => {
        setDisable(false);
    }, [sesion])
    
    useEffect(() => {
        if(!ejercicio_almacenado || rol !== '894616' || sesionTer.length === 0) {
            return;
        }
        const sesionTerminada = JSON.parse(JSON.stringify(sesionTer));
        const index = sesionTerminada.findIndex(item => item.url === url);
        sesionTerminada[index].nuevaRepeticion = sesionTerminada[index].nuevaRepeticion + 1;
        if(sesionTerminada[index].cantidad === sesionTerminada[index].nuevaRepeticion){
            sesionTerminada[index].status = 'completado';
        }
        const informacion = {
            sesionTerminada
        }
        dispatch(setearSesionTerminadaAction(informacion));
    }, [ejercicio_almacenado])
    

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {ejercicio === 'LN7' || ejercicio === 'LN7IS' ? 
                <ContainerFlex display="flex" direction="column">
                    <ContainerFlex display="flex" direction="column" align="center" justify="center">
                        <Img width="220px" src={`/img/cronometro.png`} />

                        <ContainerFlex display="flex" justify="space-evenly">
                            <BtnTiempo margin="1rem 2rem" width="4rem" height="2rem" bg="rgba(250, 250, 250, 1)" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" border="none" radius="10px" font="1rem">
                                {primerTiempo === '' ? null : primerTiempo}
                            </BtnTiempo>
                            <BtnTiempo margin="1rem 2rem" width="4rem" height="2rem" bg="rgba(250, 250, 250, 1)" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" border="none" radius="10px" font="1rem">
                                {segundoTiempo === '' ? null : segundoTiempo}
                            </BtnTiempo>
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex display="flex" direction="column"  align="center">
                        {ronda === 1 ?
                            <BtnTiempo
                                onClick={(e) => resetearRonda(e)}
                            >
                                Repetir
                            </BtnTiempo>
                            : null
                        }
                    </ContainerFlex>
                </ContainerFlex>
            :
                null
            }
            {rol ==='894616' ?
            <Flex>
                <Flex direction="column">
                    <Consignas>Finalizar  entrenamiento</Consignas>
                    <Hr/>
                    <Btn margin="30px"><img src={`/img/icono_finalizar_tratamiento.png`} alt=''/></Btn>
                </Flex>
                <Flex direction="column">
                    <button
                        disabled = {disable}
                        onClick={(e) => siguienteEjercicio(e)}
                    >
                        Continuar próximo ejercicio
                    </button>
                    <Hr/>
                    <Btn margin="30px"><img src={`/img/icono_avanzar_ninos.png`} alt=''/></Btn>
                </Flex>
            </Flex>
            :
            <Flex>
                 {/* <Flex direction="column">
                    <Consignas>Agendar para el domicilio</Consignas>
                    <Hr/>
                    <Btn margin="30px"><img src={`/img/icono_agendar_al_domicilio_color.png`} alt=''/></Btn>
                </Flex> */}
                <Flex direction="column">
                    <Consignas>Volver al Menú</Consignas>
                    <Hr/>
                    <Btn margin="30px"
                        onClick={resetFinalizar}
                    >
                        <img src={`/img/LogoAtras.png`} alt=''/>
                    </Btn>
                </Flex>
                <Flex direction="column">
                    <Consignas>Rehacer</Consignas>
                    <Hr/>
                    <Btn margin="30px"
                        onClick={(e) => volverHacer(e)}
                    >
                        <img src={`/img/icono_rehacer.png`} alt=''/>
                    </Btn>
                </Flex>
                {grupo.length === 0 || grupo.length === item_grupo +1 ? null : 
                <Flex direction="column">
                    <Consignas>Continuar próximo ejercicio</Consignas>
                    <Hr/>
                    <Btn margin="30px"><img src={`/img/siguiente_ejercicio.png`} alt=''
                        onClick={(e) => irProximoEjercicio(e)}
                    /></Btn>
                </Flex>
                }
            </Flex>
            }        
        </Fragment>
    );
}
 
export default FinalEjercicio;