import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import useConfigAuth from '../../Hooks/useConfigAuth';
import useCloseElement from '../../Hooks/useCloseElement';

// css
import {NombreProfesional, Listados, Icon, Item, DropdownPerfil, MenuProfesional} from '../Css/StyledHeader.js'

// Actions de Redux
import { cerrarSesionAction } from '../../actions/cerrarSesionActions';
import { solicitoPerfilProfesionalAction } from '../../actions/cargarPerfilActions';
import { solicitoSuscripcionProfesionalAction } from '../../actions/cargarSuscripcionActions';

const PerfilNavbar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const profesional = useSelector((state) => state.usuario.nombre);
    const nombrePaciente = useSelector((state) => state.usuario.nombrePaciente);
    const nombreTutor = useSelector((state) => state.usuario.nombreTutor);
    const perfil = useSelector((state) => state.usuario.perfil);
    const suscripcion = useSelector((state) => state.usuario.suscripcion);
    const rol = useSelector((state) => state.usuario.rol);

    const [config] = useConfigAuth();
    const wrapperRef = useRef();
    const [estadoClose] = useCloseElement(wrapperRef);

    const [open, setOpen] = useState(false);
    const [estadoPerfil, setEstadoPerfil] = useState(false);
    const [estadoSuscripcion, setEstadoSuscripcion] = useState(false);

    const cerrarSesion = () => dispatch( cerrarSesionAction ());

    const traerMiPerfil = (e) => {
        e.preventDefault();
        const informacion = {
            config
        }
        if(Object.keys(perfil).length) {
            navigate("/escritorio/miperfil");
        } else {
            dispatch( solicitoPerfilProfesionalAction(informacion) );
            Swal.fire({
                title: 'Please Wait',
                showConfirmButton: false
                })
            Swal.showLoading();
            setEstadoPerfil(true);
        }
    }

    const traerMiSuscripcion = (e) => {
        e.preventDefault();
        const informacion = {
            config
        }
        if(Object.keys(suscripcion).length) {
            navigate("/escritorio/misuscripcion");
        } else {
            dispatch( solicitoSuscripcionProfesionalAction(informacion) );
            Swal.fire({
                title: 'Please Wait',
                showConfirmButton: false
                })
            Swal.showLoading();
            setEstadoSuscripcion(true);
        }
    }

    useEffect(() => {
        if(estadoPerfil) {
            Swal.close();
            navigate("/escritorio/miperfil");
            setEstadoPerfil(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perfil])

    useEffect(() => {
        if(estadoSuscripcion) {
            Swal.close();
            navigate("/escritorio/misuscripcion");
            setEstadoSuscripcion(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suscripcion])

    useEffect(() => {
        if(estadoClose) {
            setOpen(false);
        }
    }, [estadoClose])

    return (
        <nav ref={wrapperRef}>
            <ul>
                <Listados>
                    <MenuProfesional onClick={() => setOpen(!open)}>
                        <NombreProfesional>{profesional ? profesional : nombrePaciente ? nombrePaciente : nombreTutor}</NombreProfesional>
                        <Icon src={`/img/FlechaAbajo.svg`} alt="" width="10px" margin ="0px 25px 0px 4px"/>
                    </MenuProfesional>  
                    {open ?
                        <DropdownPerfil >
                            {rol !== '894616'
                            ?
                            <>
                                <>
                                    <Icon src={`/img/usuario.svg`} alt="" width="10px" margin ="10px 4px 0px 4px "/>
                                    <Item margin ="10px 0px 0px 4px"
                                        onClick={(e) => traerMiPerfil(e)}
                                    >
                                        Mi perfil
                                    </Item>
                                </>
                                <>
                                    <Icon src={`/img/suscripcion.svg`} alt="" width="12px" margin ="10px 4px 0px 4px "/>
                                    <Item margin ="10px 0px 0px 4px"
                                        onClick={(e) => traerMiSuscripcion(e)}
                                    >
                                        Mi Suscripción
                                    </Item>
                                </>
                            </>
                            : null
                            }
                            <>
                                <Icon src={`/img/cerrarSesion.svg`} alt="" width="12px" margin ="10px 4px 0px 4px "/>
                                <Item to={"/login"} onClick={cerrarSesion} margin ="10px 0px 0px 4px">
                                Cerrar Sesión
                                </Item>
                            </>
                        </DropdownPerfil>
                    : null
                    }
                </Listados>
            </ul>
        </nav>
    );
}
 
export default PerfilNavbar;