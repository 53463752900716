import React, {Fragment} from 'react';

import HeaderUno from '../../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../../AuxiliaresPaginas/HeaderMetodos';

import { HrMetodos } from '../../../../Css/StyledHeader';
import { Flex, FlexFull, Links1Al10, ImgB10, PEj, P2 } from '../../../../Css/StyledMatematica';

const CalculoMentalSuma = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"calculo/"} l4={"suma/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"CALCULO"} n4={"SUMA"} componente={"CALCULO MENTAL"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex height='65vh'>
                <FlexFull align='center' justify='center' wrap='wrap' width='100%' wm='90%' wt='80%' wd='70%' wlg='60%'>
                    <Flex margin='8px 0px 8px 0px' direction='column'>
                        <div><ImgB10 src={`/img/portadas/matematica55.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></div>
                        <FlexFull justify='space-between' align='center' width='70%'>
                            <P2 to={`/escritorio/metodos/matematica/calculo/suma/calculo-mental/M55`} fs='1.7vw' fsm='1.6vw' fst='1.3vw' fsd='1.1vw' fslg='1vw' m='-4px'>PRESENTACIÓN</P2>
                            <P2 to={`/escritorio/metodos/matematica/calculo/suma/calculo-mental/M55Bis`} fs='1.7vw' fsm='1.6vw' fst='1.3vw' fsd='1.1vw' fslg='1vw' m='-4px'>PRÁCTICA</P2>
                        </FlexFull>
                    </Flex>
                    <Flex margin='8px 0px 8px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/suma/calculo-mental/M56`}><ImgB10 src={`/img/portadas/matematica56.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='1.7vw' fsm='1.6vw' fst='1.3vw' fsd='1.1vw' fslg='1vw'>SOBRECONTEO</PEj>
                    </Flex>
                    <Flex margin='8px 0px 8px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/suma/calculo-mental/M57`}><ImgB10 src={`/img/portadas/matematica57.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='1.7vw' fsm='1.6vw' fst='1.3vw' fsd='1.1vw' fslg='1vw'>+1O NÚMERO REDONDO</PEj>
                    </Flex>
                    <Flex margin='8px 0px 8px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/suma/calculo-mental/M58`}><ImgB10 src={`/img/portadas/matematica58.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='1.7vw' fsm='1.6vw' fst='1.3vw' fsd='1.1vw' fslg='1vw'>+1O CUALQUIER NÚMERO</PEj>
                    </Flex>
                    <Flex margin='8px 0px 8px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/suma/calculo-mental/M59`}><ImgB10 src={`/img/portadas/matematica59.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='1.7vw' fsm='1.6vw' fst='1.3vw' fsd='1.1vw' fslg='1vw'>+100 NÚMERO REDONDO</PEj>
                    </Flex>
                    <Flex margin='8px 0px 8px 0px' direction='column'>
                        <Links1Al10 to={`/escritorio/metodos/matematica/calculo/suma/calculo-mental/M60`}><ImgB10 src={`/img/portadas/matematica60.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 2.5vw 1vh 2.5vw' /></Links1Al10>
                        <PEj m='-4px' fs='1.7vw' fsm='1.6vw' fst='1.3vw' fsd='1.1vw' fslg='1vw'>+100 CUALQUIER NÚMERO</PEj>
                    </Flex>
                </FlexFull>
            </Flex>
        </Fragment>    
    );
}
 
export default CalculoMentalSuma;