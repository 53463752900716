import styled, {css, keyframes} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}

export const ContainerEjercicio = styled.div`
background-color: ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
flex-wrap: wrap ;

${media.tablet} {
  margin-top: ${(props) => props.marginMob};
}
${({isCorrect}) => isCorrect && 
  css`
  animation-name ${ZoomIn};
  animation-duration: 300ms;
`}
${({isIncorrect}) => isIncorrect && 
  css`
  animation-name ${ShakeImg};
  animation-duration: 300ms;
`}

`

export const ContInst = styled.div`
    position: absolute;
    bottom: 40px; 
    left: 25px; 
    text-transform: uppercase; 
    font-family: nunito;
    font-weight: 400;
    font-size: 16px; 
`

export const BtnElegir = styled.button`
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
background: ${(props) => props.bg};
color: ${(props) => props.color};
font-size: ${(props) => props.font};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
margin: ${(props) => props.margin};
font-family: nunito ;
font-weight: 400;
border-radius: 20px;
border: none;
cursor: pointer;
`

export const BtnLetra = styled.button`
background-color: ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
box-shadow: ${(props) => props.shadow};
flex-wrap: ${(props) => props.wrap};
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: ${(props) => props.radius};
font-size:  ${(props) => props.font};
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  ${(props) => props.weight};
cursor: pointer;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.tablet} {
  margin-top: ${(props) => props.marginMob};
  font-size:  ${(props) => props.fontmob};
  width: ${(props) => props.widthmob};
  height: ${(props) => props.heightmob};
  margin:  ${(props) => props.marginMob};
}

`

export const Palabra = styled.div `
background-color:  ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align};
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
font-size:  ${(props) => props.font};
font-family: nunito ;
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: ${(props) => props.radius};
font-weight:  ${(props) => props.weight};
letter-spacing:   ${(props) => props.spacing};
border-bottom: ${(props) => props.borderBottom};
text-transform: ${(props) => props.uppercase};
box-shadow: ${(props) => props.shadow};

${media.tablet} {

  font-size:  ${(props) => props.fontmob};
  width: ${(props) => props.widthmob}; 
  height: ${(props) => props.heightmob}; 

}
`

export const BtnColor = styled.button`
background-color: ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
box-shadow: ${(props) => props.shadow};
flex-wrap: ${(props) => props.wrap};
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: ${(props) => props.radius};
border:${props => props.border ? `solid 3px #23AAAF` : `0`};
font-size:  ${(props) => props.font};
font-family: nunito ;
font-weight:  ${(props) => props.weight};
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
cursor: pointer ;

${media.tablet} {
  width: ${(props) => props.widthmob}; 
}

`

export const InputCirculo = styled.input`
border-radius: ${(props) => props.radius};
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
font-size: 1.5rem ;


`

export const Pregunta = styled.div `
  font-size: ${(props) => props.font};
  text-transform: ${(props) => props.uppercase} ;
  font-family: nunito ;
  color: ${(props) => props.color};
  margin:  ${(props) => props.margin};
`

export const BtnBasurero = styled.button`
background-color:  ${(props) => props.bg};
border: ${(props) => props.border};
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
cursor: pointer;

img {
  width: 1.5rem;
  margin: 0 0 0 1rem ;
}

${media.tablet} {
  img {
  width: 1.2rem;
  margin: 0 0 0 0.5rem ;
}
}

`

export const Letra = styled.div `
font-family: nunito ;
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
font-size:  ${(props) => props.font};
font-weight:  ${(props) => props.weight};
color: ${(props) => props.color};
border-bottom: ${(props) => props.borderBottom};

${media.tablet} {
font-size:  ${(props) => props.fontmob};
margin:  ${(props) => props.marginMob};
}

`
export const Input = styled.input`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background: #F1F0F0;
    height: 60px;
    width: 35vw;
    border:0;
    border-radius: 15px;
    text-align:center;
    font-family: nunito; 
    font-size: 18px;
    z-index: 1;
    text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
    ${media.tablet}{
        width: ${props => props.widthTablet};
}
`

export const InputLetra = styled.input `
font-size:  ${(props) => props.font};
border-radius: ${(props) => props.radius};
text-transform: ${(props) => props.uppercase};
font-family: nunito;
border-bottom: ${(props) => props.borderBottom};
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
box-shadow: ${(props) => props.shadow}; 
background-color: ${(props) => props.bg}; 
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
border: none;
text-align:center;
padding-inline-start: 0px;
padding-inline-end: 0px;
padding-block-start: 0px;
padding-block-end: 0px;

${media.tablet} {
width: ${(props) => props.widthmob}; 
height: ${(props) => props.heightmob}; 
font-size:  ${(props) => props.fontmob};
margin:  ${(props) => props.marginMob};
}
`


export const InputText = styled.input `
font-size:  ${(props) => props.font};
border-radius: ${(props) => props.radius};
margin:  ${(props) => props.margin};
text-transform:  ${(props) => props.uppercase};
font-family: nunito ;
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
font-weight: ${(props) => props.weigth}; 
border: none;
text-align:center ;
color: ${(props) => props.color};
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.tablet} {
width: ${(props) => props.widthmob}; 
height: ${(props) => props.heightmob}; 
}
`



export const TextArea = styled.textarea `
font-size: ${(props) => props.font};
border-radius: ${(props) => props.radius};
margin: ${(props) => props.margin};
padding: ${(props) => props.padding};
text-transform: ${(props) => props.uppercase};
font-family: nunito ;
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
font-weight: ${(props) => props.weigth}; 
border: ${(props) => props.border ? props.border : "none"};
resize: none;
font-family: nunito ;


${media.tablet} {
width: ${(props) => props.widthmob}; 
height: ${(props) => props.heightmob}; 
}
`


export const Oracion = styled.div`
display: ${(props) => props.display};
border-bottom: ${(props) => props.borderBottom};
border-top: ${(props) => props.borderTop};
padding:  ${(props) => props.padding};
font-size:  ${(props) => props.font};
width: ${(props) => props.width};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align};
text-transform: ${(props) => props.$minMay ? 'none' : 'uppercase'};
flex-wrap: ${(props) => props.wrap};
`

export const Img = styled.img `
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 

${media.tablet} {
  width: ${(props) => props.widthmob}; 
height: ${(props) => props.heightmob}; 
}
`


export const BtnTiempo = styled.button`
background-color: ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
box-shadow: ${(props) => props.shadow};
flex-wrap: ${(props) => props.wrap};
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: ${(props) => props.radius};
font-size:  ${(props) => props.font};
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  ${(props) => props.weight};
cursor: pointer;

`

export const RecuadroAzul = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #23AAAF;
    border-radius: 5px;
    width: 60%;
    height: 60%;
    margin-left:  ${(props) => props.marginleft};
    margin-right:  ${(props) => props.marginright};
`

// ANIMACIONES
export const ShakeImg = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`

export const ZoomIn = keyframes`
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
`
 