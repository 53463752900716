import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { borrarLetraAction } from '../../../actions/estadoEjercicioActions';
import { solicitoListaEjerciciosAction } from '../../../actions/solicitoInformacionEjercicioActions';

// css
import { Container, BtnGroup, BtnsLink } from '../../Css/MetodosCSS/StyledLectura';
import { HrMetodos } from '../../Css/StyledHeader';

const Lectura = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const resetLetra = () => {
        dispatch(borrarLetraAction());
    }

    useEffect(() => {
        const informacion = {
            config,
            payload: {
                
            }
        }
        dispatch(solicitoListaEjerciciosAction(informacion))
    }, [])
    

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} componente={"LECTURA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Container justify="center"  >
                <Container flex-direction= "column" justifyt="center" align="center" >
                    <BtnGroup align="center">
                        <BtnsLink  margin= "30px 20px 30px 20px" padding= "20px 30px" font= "1.2rem" to={'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita'} onClick={(e) => resetLetra(e)}>Instrucción Explícita</BtnsLink>
                        <BtnsLink  margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem"  to={'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones'} onClick={(e) => resetLetra(e)}>Instrucción Sinfones</BtnsLink>
                    </BtnGroup>

                    <BtnGroup align="center" justify="center">
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem"  to={'/escritorio/metodos/lecto-escritura/lectura/silabas'}>Sílabas</BtnsLink>
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem"  to={'/escritorio/metodos/lecto-escritura/lectura/silaba-directa'}>Sílabas Directas</BtnsLink>
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem"  to={'/escritorio/metodos/lecto-escritura/lectura/silaba-inversa'}>Sílabas Inversas</BtnsLink>
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem"  to={'/escritorio/metodos/lecto-escritura/lectura/silaba-mixta'}>Sílabas Mixtas</BtnsLink>
                    </BtnGroup>
                    <BtnGroup align="center" justify="center">
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem"  to={'/escritorio/metodos/lecto-escritura/lectura/silaba-trabada'}>Sílaba Trabada</BtnsLink>
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem"  to={'/escritorio/metodos/lecto-escritura/lectura/silaba-conectores'}>Conectores</BtnsLink>
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem" to={'/escritorio/metodos/lecto-escritura/lectura/silaba-diptongo'}>Diptongo</BtnsLink>
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem" to={'/escritorio/metodos/lecto-escritura/lectura/silaba-hiato'}>Hiato</BtnsLink>
                    </BtnGroup>
                    <BtnGroup align="center" justify="center">
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem" to={'/escritorio/metodos/lecto-escritura/lectura/pseudopalabras'}>Pseudopalabras</BtnsLink>
                        <BtnsLink margin= "30px 20px 30px 20px" padding= "20px 40px" font= "1.2rem" to={'/escritorio/metodos/lecto-escritura/lectura/ejercicios'}>Ejercicios</BtnsLink>
                    </BtnGroup>
                </Container>
            </Container>
        </Fragment>
    );
}

export default Lectura;