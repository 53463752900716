import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LN2Todos from '../../../Compartidos/LN2Todos';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Construye la sílaba que se presenta. No olvides de decirla en voz alta a medida que la construyes.';
const miniInstrucciones = 'Construye la sílaba que se presenta. No olvides de decirla en voz alta a medida que la construyes.';

const LN2IS = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN2IS') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/instruccion-sinfones/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[0]));
        if(newList.muestro_letras?.toString() === '5') {
            const nuevaLista = [
                {id:'1', silaba:newList.letra_1, palabra:newList.silaba_11, primera_letra:newList.letra_6, segunda_letra:newList.silaba_1, tercera_letra:newList.silaba_6, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_3, tipo: newList.tipo_1, myStyle:{color:'#23AAAF'}},
                {id:'2', silaba:newList.letra_2, palabra:newList.silaba_12, primera_letra:newList.letra_7, segunda_letra:newList.silaba_2, tercera_letra:newList.silaba_7, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_4, tipo: newList.tipo_2, myStyle:{color:'#FDB62C'}},
                {id:'3', silaba:newList.letra_3, palabra:newList.silaba_13, primera_letra:newList.letra_8, segunda_letra:newList.silaba_3, tercera_letra:newList.silaba_8, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_5, tipo: newList.tipo_3, myStyle:{color:'#FED67F'}},
                {id:'4', silaba:newList.letra_4, palabra:newList.silaba_14, primera_letra:newList.letra_9, segunda_letra:newList.silaba_4, tercera_letra:newList.silaba_9, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_6, tipo: newList.tipo_4, myStyle:{color:'#655EE8'}},
                {id:'5', silaba:newList.letra_5, palabra:newList.silaba_15, primera_letra:newList.letra_10, segunda_letra:newList.silaba_5, tercera_letra:newList.silaba_10, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_7, tipo: newList.tipo_5, myStyle:{color:'#D76970'}}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else if (newList.muestro_letras?.toString() === '3'){
            const nuevaLista = [
                {id:'1', silaba:newList.letra_1, palabra:newList.silaba_11, primera_letra:newList.letra_6, segunda_letra:newList.silaba_1, tercera_letra:newList.silaba_6,imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_3, tipo: newList.tipo_1, myStyle:{color:'#FDB62C'}},
                {id:'2', silaba:newList.letra_2, palabra:newList.silaba_12, primera_letra:newList.letra_7, segunda_letra:newList.silaba_2, tercera_letra:newList.silaba_7,imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_4, tipo: newList.tipo_2, myStyle:{color:'#FED67F'}}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else {
            const nuevaLista = [
                {id:'1', silaba:newList.letra_1, palabra:newList.silaba_11, primera_letra:newList.letra_6, segunda_letra:newList.silaba_1, tercera_letra:newList.silaba_6,imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_3, tipo: newList.tipo_1, myStyle:{color:'#FDB62C'}},
                {id:'2', silaba:newList.letra_2, palabra:newList.silaba_12, primera_letra:newList.letra_7, segunda_letra:newList.silaba_2, tercera_letra:newList.silaba_7,imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_4, tipo: newList.tipo_2, myStyle:{color:'#FED67F'}}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        }
        
    }, [informacion])
    
    return (
        <Fragment>
            <LN2Todos data={data} indexes={indexes} ejercicio="LN2IS" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default LN2IS;