import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgFlex, ImgB10, CantCuadroM46, ComparacionM46, Separador, ComparadorM46, ButtonM44, AvanzarImg, AvanzarBtn  } from '../../../Css/StyledMatematica';

const instrucciones = 'Construye los números e indica cuál es mayor y cuál es menor.';
const miniInstrucciones = 'Construye los números e indica cuál es mayor y cuál es menor.';

const M46 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [imagenMiles, setImagenMiles] = useState([]);
    const [imagenCentena, setImagenCentena] = useState([]);
    const [imagenDecena, setImagenDecena] = useState([]);
    const [imagenUnidad, setImagenUnidad] = useState([]);
    const [imagenMiles2, setImagenMiles2] = useState([]);
    const [imagenCentena2, setImagenCentena2] = useState([]);
    const [imagenDecena2, setImagenDecena2] = useState([]);
    const [imagenUnidad2, setImagenUnidad2] = useState([]);
    const [unidad, setUnidad] = useState('');
    const [decena, setDecena] = useState('');
    const [centena, setCentena] = useState('');
    const [miles, setMiles] = useState('');
    const [unidad2, setUnidad2] = useState('');
    const [decena2, setDecena2] = useState('');
    const [centena2, setCentena2] = useState('');
    const [miles2, setMiles2] = useState('');
    const [menorMayor, setMenorMayor] = useState('<');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const tipoBase10 = useSelector(state => state.ejercicio.lista);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const setearMenorMayor = (e) => {
        e.preventDefault();
        if(menorMayor === '<'){
            setMenorMayor('>');
        } else {
            setMenorMayor('<')
        }
    }

    const agregarMiles = (e) => {
        e.preventDefault();
        if(imagenMiles.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles));
        newList.push('cuboUnidadDeMil')
        setImagenMiles(newList);
    }

    const reducirMiles = (e) => {
        e.preventDefault();
        if(imagenMiles.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles));
        newList.pop()
        setImagenMiles(newList);
    }

    const agregarCentena = (e) => {
        e.preventDefault();
        if(imagenCentena.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena));
        newList.push('cuboCentena')
        setImagenCentena(newList);
    }

    const reducirCentena = (e) => {
        e.preventDefault();
        if(imagenCentena.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena));
        newList.pop()
        setImagenCentena(newList);
    }

    const agregarDecena = (e) => {
        e.preventDefault();
        if(imagenDecena.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena));
        newList.push('cuboDecena')
        setImagenDecena(newList);
    }

    const reducirDecena = (e) => {
        e.preventDefault();
        if(imagenDecena.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena));
        newList.pop()
        setImagenDecena(newList);
    }

    const agregarUnidad = (e) => {
        e.preventDefault();
        if(imagenUnidad.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad));
        newList.push('cuboUnidad')
        setImagenUnidad(newList);
    }

    const reducirUnidad = (e) => {
        e.preventDefault();
        if(imagenUnidad.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad));
        newList.pop()
        setImagenUnidad(newList);
    }

    const agregarMiles2 = (e) => {
        e.preventDefault();
        if(imagenMiles2.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles2));
        newList.push('cuboUnidadDeMil')
        setImagenMiles2(newList);
    }

    const reducirMiles2 = (e) => {
        e.preventDefault();
        if(imagenMiles2.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles2));
        newList.pop()
        setImagenMiles2(newList);
    }

    const agregarCentena2 = (e) => {
        e.preventDefault();
        if(imagenCentena2.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena2));
        newList.push('cuboCentena')
        setImagenCentena2(newList);
    }

    const reducirCentena2 = (e) => {
        e.preventDefault();
        if(imagenCentena2.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena2));
        newList.pop()
        setImagenCentena2(newList);
    }

    const agregarDecena2 = (e) => {
        e.preventDefault();
        if(imagenDecena2.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena2));
        newList.push('cuboDecena')
        setImagenDecena2(newList);
    }

    const reducirDecena2 = (e) => {
        e.preventDefault();
        if(imagenDecena2.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena2));
        newList.pop()
        setImagenDecena2(newList);
    }

    const agregarUnidad2 = (e) => {
        e.preventDefault();
        if(imagenUnidad2.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad2));
        newList.push('cuboUnidad')
        setImagenUnidad2(newList);
    }

    const reducirUnidad2 = (e) => {
        e.preventDefault();
        if(imagenUnidad2.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad2));
        newList.pop()
        setImagenUnidad2(newList);
    }

    const verificar = (e) => {
        e.preventDefault();

        let imagenMilesCorregida = '';
        let imagenCentenaCorregida = '';
        let imagenDecenaCorregida = '';
        let imagenUnidadCorregida = '';

        let imagenMiles2Corregida = '';
        let imagenCentena2Corregida = '';
        let imagenDecena2Corregida = '';
        let imagenUnidad2Corregida = '';

        if(miles === '' && imagenMiles.length === 0){
            imagenMilesCorregida = '';
        } else {
            imagenMilesCorregida = imagenMiles.length;
        }
        if(centena === '' && imagenCentena.length === 0){
            imagenCentenaCorregida = '';
        } else {
            imagenCentenaCorregida = imagenCentena.length;
        }
        if(decena === '' && imagenDecena.length === 0){
            imagenDecenaCorregida = '';
        } else {
            imagenDecenaCorregida = imagenDecena.length;
        }
        if(unidad === '' && imagenUnidad.length === 0){
            imagenUnidadCorregida = '';
        } else {
            imagenUnidadCorregida = imagenUnidad.length;
        }

        if(miles2 === '' && imagenMiles2.length === 0){
            imagenMiles2Corregida = '';
        } else {
            imagenMiles2Corregida = imagenMiles2.length;
        }
        if(centena2 === '' && imagenCentena2.length === 0){
            imagenCentena2Corregida = '';
        } else {
            imagenCentena2Corregida = imagenCentena2.length;
        }
        if(decena2 === '' && imagenDecena2.length === 0){
            imagenDecena2Corregida = '';
        } else {
            imagenDecena2Corregida = imagenDecena2.length;
        }
        if(unidad2 === '' && imagenUnidad2.length === 0){
            imagenUnidad2Corregida = '';
        } else {
            imagenUnidad2Corregida = imagenUnidad2.length;
        }

        if(menorMayor === '>'){
        if(imagenMilesCorregida !== miles || imagenCentenaCorregida !== centena || imagenDecenaCorregida !== decena || imagenUnidadCorregida !== unidad || imagenMiles2Corregida !== miles2 || imagenCentena2Corregida !== centena2 || imagenDecena2Corregida !== decena2 || imagenUnidad2Corregida !== unidad2 || comparar[count-1].cantidad_1 < comparar[count-1].cantidad_2){
            console.log("mal");
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(imagenMilesCorregida !== miles){setImagenMiles([]);}
                if(imagenCentenaCorregida !== centena){setImagenCentena([]);}
                if(imagenDecenaCorregida !== decena){setImagenDecena([]);}
                if(imagenUnidadCorregida !== unidad){setImagenUnidad([]);}
                if(imagenMiles2Corregida !== miles2){setImagenMiles2([]);}
                if(imagenCentena2Corregida !== centena2){setImagenCentena2([]);}
                if(imagenDecena2Corregida !== decena2){setImagenDecena2([]);}
                if(imagenUnidad2Corregida !== unidad2){setImagenUnidad2([]);}
            }, 300);
        } else {
            console.log("bien");
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setMiles('');
                    setCentena('');
                    setDecena('');
                    setUnidad('');
                    setImagenMiles([]);
                    setImagenCentena([]);
                    setImagenDecena([]);
                    setImagenUnidad([]);
                    setMiles2('');
                    setCentena2('');
                    setDecena2('');
                    setUnidad2('');
                    setImagenMiles2([]);
                    setImagenCentena2([]);
                    setImagenDecena2([]);
                    setImagenUnidad2([]);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );                
                }, 1500);
            }
        }} else {
            if(imagenMilesCorregida !== miles || imagenCentenaCorregida !== centena || imagenDecenaCorregida !== decena || imagenUnidadCorregida !== unidad || imagenMiles2Corregida !== miles2 || imagenCentena2Corregida !== centena2 || imagenDecena2Corregida !== decena2 || imagenUnidad2Corregida !== unidad2 || comparar[count-1].cantidad_1 > comparar[count-1].cantidad_2){
                console.log("mal");
                setDisable(true);
                setTimeout(() => {
                    setDisable(false);
                    if(imagenMilesCorregida !== miles){setImagenMiles([]);}
                    if(imagenCentenaCorregida !== centena){setImagenCentena([]);}
                    if(imagenDecenaCorregida !== decena){setImagenDecena([]);}
                    if(imagenUnidadCorregida !== unidad){setImagenUnidad([]);}
                    if(imagenMiles2Corregida !== miles2){setImagenMiles2([]);}
                    if(imagenCentena2Corregida !== centena2){setImagenCentena2([]);}
                    if(imagenDecena2Corregida !== decena2){setImagenDecena2([]);}
                    if(imagenUnidad2Corregida !== unidad2){setImagenUnidad2([]);}
                }, 1000);
            } else {
                console.log("bien");
                setDisable(true);
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setMiles('');
                        setCentena('');
                        setDecena('');
                        setUnidad('');
                        setImagenMiles([]);
                        setImagenCentena([]);
                        setImagenDecena([]);
                        setImagenUnidad([]);
                        setMiles2('');
                        setCentena2('');
                        setDecena2('');
                        setUnidad2('');
                        setImagenMiles2([]);
                        setImagenCentena2([]);
                        setImagenDecena2([]);
                        setImagenUnidad2([]);
                        setCount(count + 1);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        dispatch( finalizarEjercicioAction() );                
                    }, 1500);
                }
            }
        }
    }

    useEffect(() => {
        if(comparar.length === 0) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        const nro = newList[count-1].cantidad_1;
        let u = nro%10;
        let d = Math.floor((nro/10)%10);
        let c = Math.floor((nro/100)%10);
        let m = Math.floor((nro/1000)%10);

        if(m === 0 && c === 0 && d === 0 && u === 0) {
            m = '';
            c = '';
            d = '';
            u = '';
        } else if(m === 0 && c === 0 && d === 0) {
            m = '';
            c = '';
            d = '';
        } else if(m === 0 && c === 0 ) {
            m = '';
            c = '';
        } else if(m === 0) {
            m = '';
        }

        setUnidad(u);
        setDecena(d);
        setCentena(c);
        setMiles(m);

        const newList2 = JSON.parse(JSON.stringify(comparar));
        const nro2 = newList2[count-1].cantidad_2;
        let u2 = nro2%10;
        let d2 = Math.floor((nro2/10)%10);
        let c2 = Math.floor((nro2/100)%10);
        let m2 = Math.floor((nro2/1000)%10);

        if(m2 === 0 && c2 === 0 && d2 === 0 && u2 === 0) {
            m2 = '';
            c2 = '';
            d2 = '';
            u2 = '';
        } else if(m2 === 0 && c2 === 0 && d2 === 0) {
            m2 = '';
            c2 = '';
            d2 = '';
        } else if(m2 === 0 && c2 === 0 ) {
            m2 = '';
            c2 = '';
        } else if(m2 === 0) {
            m2 = '';
        }

        setUnidad2(u2);
        setDecena2(d2);
        setCentena2(c2);
        setMiles2(m2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        const randomLista = (function () {
            let min = 0;
            let max = 0;
            const lista = [];
            switch (tipoBase10) {
                case 'unidad':
                    min = 0;
                    max = 9;
                    break;
                case 'decena':
                    min = 10;
                    max = 99;
                    break;
                case 'centena':
                    min = 100;
                    max = 999;
                    break;
                case 'unidadDeMil':
                    min = 1000;
                    max = 9999;
                    break;
                case 'todos':
                    min = 1;
                    max = 9999;
                    break;
                default:
                    min = 1;
                    max = 9999;
                    break;
            }
            for(let i = 0; i < 10; i++){
                let randomNumero = 0;
                do {
                    randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                } while (lista.includes(randomNumero));
                lista.push(randomNumero)
            }
            return lista;
        })();
        const modelo = [
            {id:'1', cantidad_1:randomLista[0], cantidad_2:randomLista[5]},
            {id:'2', cantidad_1:randomLista[1], cantidad_2:randomLista[6]},
            {id:'3', cantidad_1:randomLista[2], cantidad_2:randomLista[7]},
            {id:'4', cantidad_1:randomLista[3], cantidad_2:randomLista[8]},
            {id:'5', cantidad_1:randomLista[4], cantidad_2:randomLista[9]}
        ]
        setComparar(modelo);
        setIndexes(modelo.length);
        setLanzoEffect(true);
        dispatch(setearEjercicioAction('M46') );
        dispatch(setearUrlAnteriorAction('matematica/base-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M46",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'erroresOrden': erroresOrden,
                    'lista': tipoBase10,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex direction='column' margin="auto" width="100%" align='center'
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        {/* NUMEROS MAYOR - MENOR ↓ */}
                        <FlexCuz margin='6vh 0px 2vh 0px' mlg='0vh 0px 0vh 0px'>
                            <ComparacionM46 m='0px 6vw 0px 0vw'>
                                {comparar[count-1].cantidad_1}
                            </ComparacionM46>
                            <div>
                                <ComparadorM46
                                    disabled={disable}
                                    onClick={(e) => setearMenorMayor(e)}
                                >
                                    {menorMayor}
                                </ComparadorM46>
                            </div>
                            <ComparacionM46 m='0px 0vw 0px 6vw'>
                                {comparar[count-1].cantidad_2}
                            </ComparacionM46>
                        </FlexCuz>
                        {/* FIN NUMEROS MAYOR - MENOR ↑ */}
                        <FlexCuz justify='space-between' margin='6vh 0px 0px 0px'>
                            {/* PRIMEROS NÚMEROS ↓  */}
                            <Flex direction='column' margin='0px 3vw 0px 0px' mlg='0px 2vw 0px 0vw'>
                                {/* img arriba ↓ */}
                                <FlexFull align='end' justify='center' minw='45vw' maxw='45vw' minwm='45vw' maxwm='45vw' minwt='45vw' maxwt='45vw' minwlg='40vw' maxwlg='40vw' minh='40vh'  minhlg='52vh' maxhlg='52vh' margin='0px 1vw -5px 0px'>
                                    <ImgFlex direction='column' justify='end' align='center' maxw='25%' minh='27vw' maxh='27vw' minhm='28vh' maxhm='28vh' minht='37vh' maxht='37vh' minhd='44vh' maxhd='44vh' minhlg='50vh' maxhlg='50vh'> { /* br='2px solid red' */ }
                                        {imagenMiles.map((item,i) => 
                                            <div key={i}>
                                                {/* <ImgB10 src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/BASE10mil.png`} w='5vw' m='0px 1px 0px 1px' />
                                            </div>
                                        )}
                                    </ImgFlex>
                                    <ImgFlex direction='column' justify='end' align='center' minh='24vw' maxh='24vw' minhm='26vh' maxhm='26vh' minht='33vh' maxht='33vh' minhd='40vh' maxhd='40vh' minhlg='45vh' maxhlg='45vh'>
                                        {imagenCentena.map((item,i) => 
                                            <div key={i}>
                                                {/* <ImgB10 src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/BASE10centena.png`} w='3.5vw' m='0px' />
                                            </div>
                                        )}
                                    </ImgFlex>
                                    <ImgFlex direction='column' justify='end' align='start' minh='10vh' maxh='10vh' minhlg='10vh' maxhlg='10vh'>
                                        {imagenDecena.map((item,i) => 
                                            <div key={i}>
                                                {/* <ImgB10 src={`/img/${item}.png`} /> */}
                                                <ImgB10 key={i} src={`/img/b10_decena.png`} w='1vw' wt='1.1vw' wd='0.8vw' wlg='0.8vw' m='0.5px' /> {/* tr='rotate(90deg)' */}
                                            </div>
                                        )}
                                    </ImgFlex>
                                    <ImgFlex direction='column' justify='end' align='center' minh='100px' maxh='100px'>
                                        <FlexFull wrap='wrap' justify='center' align='center' minw='55px' maxw='55px' minwlg='65px' maxwlg='65px' minh='65px' maxh='65px'>
                                        {imagenUnidad.map((item,i) => 
                                            <ImgB10 src={`/img/b10_unidad.png`} key={i} w='14px' wt='14px' wd='16px' wlg='17px' m='0.5px' />
                                        )}
                                        </FlexFull>
                                    </ImgFlex>
                                </FlexFull>
                                {/* img arriba ↑ */}
                                <Separador w='46vw' wm='46vw' wt='46vw' wlg='42vw' />
                                {/* img abajo ↓ */}
                                <Flex minw='45vw' maxw='45vw' minwm='45vw' maxwm='45vw' minwt='45vw' maxwt='45vw' minwlg='40vw' maxwlg='40vw'  margin='-5px 0px 0px 0px'>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM46>U. MIL</CantCuadroM46>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarMiles(e)}
                                        >
                                            <ImgB10 src={`/img/BASE10mil.png`}  w='42px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirMiles(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM46>CENTENA</CantCuadroM46>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarCentena(e)}
                                        >
                                            <ImgB10 src={`/img/BASE10centena.png`} w='32px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirCentena(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM46>DECENA</CantCuadroM46>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarDecena(e)}
                                        >
                                            <ImgB10 src={`/img/b10_decena.png`} w='10px' m='-17px' tr='rotate(90deg)' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirDecena(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM46>UNIDAD</CantCuadroM46>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarUnidad(e)}
                                        >
                                            <ImgB10 src={`/img/b10_unidad.png`} w='18px' />
                                        </ButtonM44>
                                        <ButtonM44 
                                            disabled={disable}
                                            onClick={(e) => reducirUnidad(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                </Flex>
                                {/* img abajo ↑ */}
                            </Flex>
                            {/* FIN PRIMEROS NÚMEROS ↑ */}

                            {/* SEGUNDOS NÚMEROS ↓ */}
                            <Flex direction='column' mlg='0px 0vw 0px 2vw'>
                                {/* img arriba ↓  */}
                                <FlexFull align='end' justify='center' minw='45vw' maxw='45vw' minwm='45vw' maxwm='45vw' minwt='45vw' maxwt='45vw' minwlg='40vw' maxwlg='40vw' minh='40vh'  minhlg='52vh' maxhlg='52vh' margin='0px 1vw -5px 0px'>
                                    <ImgFlex direction='column' justify='end' align='center' maxw='25%' minh='27vw' maxh='27vw' minhm='28vh' maxhm='28vh' minht='37vh' maxht='37vh' minhd='44vh' maxhd='44vh' minhlg='50vh' maxhlg='50vh'>
                                        {imagenMiles2.map((item,i) => 
                                            <div key={i}>
                                                {/* <ImgB10 src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/BASE10mil.png`} w='5vw' m='0px 1px 0px 1px' />
                                            </div>
                                        )}
                                    </ImgFlex>
                                    <ImgFlex direction='column' justify='end' align='center' minh='24vw' maxh='24vw' minhm='26vh' maxhm='26vh' minht='33vh' maxht='33vh' minhd='40vh' maxhd='40vh' minhlg='45vh' maxhlg='45vh'>
                                        {imagenCentena2.map((item,i) => 
                                            <div key={i}>
                                                {/* <ImgB10 src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/BASE10centena.png`} w='3.5vw' m='0px' />
                                            </div>
                                        )}
                                    </ImgFlex>
                                    <ImgFlex direction='column' justify='end' align='start' minh='10vh' maxh='10vh' minhlg='10vh' maxhlg='10vh'>
                                        {imagenDecena2.map((item,i) => 
                                            <div key={i}>
                                                {/* <ImgB10 src={`/img/${item}.png`} /> */}
                                                <ImgB10 key={i} src={`/img/b10_decena.png`} w='1vw' wt='1.1vw' wd='0.8vw' wlg='0.8vw' m='0.5px' /> {/* tr='rotate(90deg)' */}
                                            </div>
                                        )}
                                    </ImgFlex>
                                    <ImgFlex direction='column' justify='end' align='center' minh='100px' maxh='100px'>
                                        <FlexFull wrap='wrap' justify='center' align='center' minw='55px' maxw='55px' minwlg='65px' maxwlg='65px' minh='65px' maxh='65px'>
                                        {imagenUnidad2.map((item,i) => 
                                            <ImgB10 src={`/img/b10_unidad.png`} key={i} w='14px' wt='14px' wd='16px' wlg='17px' m='0.5px' />
                                        )}
                                        </FlexFull>
                                    </ImgFlex>
                                </FlexFull>
                                {/* Fin img arriba ↑  */}
                                <Separador w='46vw' wm='46vw' wt='46vw' wlg='42vw' />
                                {/* img abajo ↓  */}
                                <Flex minw='45vw' maxw='45vw' minwm='45vw' maxwm='45vw' minwt='45vw' maxwt='45vw' minwlg='40vw' maxwlg='40vw'  margin='-5px 0px 0px 0px'>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM46>U. MIL</CantCuadroM46>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarMiles2(e)}
                                        >
                                            <ImgB10 src={`/img/BASE10mil.png`} w='42px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirMiles2(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM46>CENTENA</CantCuadroM46>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarCentena2(e)}
                                        >
                                            <ImgB10 src={`/img/BASE10centena.png`} w='32px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirCentena2(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM46>DECENA</CantCuadroM46>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarDecena2(e)}
                                        >
                                            <ImgB10 src={`/img/b10_decena.png`} w='10px' m='-17px' tr='rotate(90deg)' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirDecena2(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM46>UNIDAD</CantCuadroM46>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarUnidad2(e)}
                                        >
                                            <ImgB10 src={`/img/b10_unidad.png`} w='18px' />
                                        </ButtonM44>
                                        <ButtonM44 
                                            disabled={disable}
                                            onClick={(e) => reducirUnidad2(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                </Flex>
                                {/* Fin img abajo ↑  */}
                            </Flex>
                            {/* FIN SEGUNDOS NÚMEROS ↑ */}
                        </FlexCuz>
                    </Flex>

                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                    <Instrucciones miniInstrucciones={'Construye los números e indica cuál es mayor y cuál es menor.'} />
            }
        </Fragment>
    );
}
 
export default M46;