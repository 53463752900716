import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { zeroContadorTerciarioAction, zeroContadorAction, zeroContadorSecundarioAction } from '../../../../../../actions/botoneraEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgB10, PM72, P, InputM72, PRectaM67, BtnRectaM73, Container } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta utilizando la estrategia que corresponde.';
const miniInstrucciones = 'Resuelve la resta utilizando la estrategia que corresponde.';

const M75 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [numero, setNumero] = useState('');
    const [numero2, setNumero2] = useState('');
    const [curva, setCurva] = useState(new Array(7));
    const [numeroRegla, setNumeroRegla] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);
    const contadorTerciario = useSelector(state => state.ejercicio.contadorTerciario);

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const number = 14 - 7;
        const arr = Array.from(Array(15).keys());
        const newArr = arr.filter(item => item >= number)
        setNumeroRegla(newArr);
        setLanzoEffect(true);
    }, [])

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(curva));
        switch (contadorTerciario) {
            case 0:
                newList[0] = ''
                setCurva(newList);
                break;
            case 1:
                newList[0] = 'retrocede_inversa'
                newList[1] = ''
                setCurva(newList);
                break;
            case 2:
                newList[1] = 'retrocede_inversa'
                newList[2] = ''
                setCurva(newList);
                break;
            case 3:
                newList[2] = 'retrocede_inversa'
                newList[3] = ''
                setCurva(newList);
                break;
            case 4:
                newList[3] = 'retrocede_inversa'
                newList[4] = ''
                setCurva(newList);
                break;
            case 5:
                newList[4] = 'retrocede_inversa'
                newList[5] = ''
                setCurva(newList);
                break;
            case 6:
                newList[5] = 'retrocede_inversa'
                newList[6] = ''
                setCurva(newList);
                break;
            case 7:
                newList[6] = 'retrocede_inversa'
                setCurva(newList);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorTerciario, lanzoEffect]);

    useEffect(() => {
        if(contador > 2){
            dispatch( finalizarEjercicioAction() );
            dispatch(zeroContadorTerciarioAction());
            dispatch(zeroContadorSecundarioAction());
            dispatch(zeroContadorAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contador]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M75') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(finalizar){
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            <Fragment>
                {iniciar
                    ?
                    <Fragment>
                        {contador === 0 &&
                        <FlexFull direction='column' justify='space-evenly' align='center' height='76vh'> {/*  border='2px solid red' */}
                            <FlexFull justify='space-evenly' align='center'>
                                <P fs='5vw' fsm='4.7vw' fst='4vw' fsd='3.1vw' fslg='3.1vw' fw='bolder' color='#646464' m='0px 2vw 0px 0px'>ESTRATEGIA 2 :</P>
                                <P fs='4.7vw' fsm='4.3vw' fst='3.5vw' fsd='2.8vw' fslg='2.8vw' fw='500' color='#646464'> SALTO PARA ADELANTE</P>
                            </FlexFull>
                            <FlexFull direction='column' justify='center' align='center'>
                                <FlexFull height='50px' width='260px' wm='270px' wt='400px' wd='478px' wlg='485px' bradius='10px' margin='0px 42px -25px 0px' mm='0px 47px -30px 0px' mt='0px 106px -26px 0px' md='0px 95px -20px 0px' mlg='0px 81px -18px 0px' justify='end' align='center' z='1'>
                                    <Flex>
                                        <ImgB10 src={`/img/retrocede_inversa.png`} w='46.5px' wm='47.5px' wt='63px' wd='80px' wlg='82px' m='0px -2px -40px -2px' mm='-21px -2px -48px -2px' mt='-34px -3px -58px -2px' md='-32px -3px -58px -2px' mlg='0px 0px -37px -4px' />
                                        <ImgB10 src={`/img/retrocede_inversa.png`} w='46.5px' wm='47.5px' wt='63px' wd='80px' wlg='82px' m='0px -2px -40px -2px' mm='-21px -2px -48px -2px' mt='-34px -3px -58px -2px' md='-32px -3px -58px -2px' mlg='0px 0px -37px -4px' />
                                        <ImgB10 src={`/img/retrocede_inversa.png`} w='46.5px' wm='47.5px' wt='63px' wd='80px' wlg='82px' m='0px -2px -40px -2px' mm='-21px -2px -48px -2px' mt='-34px -3px -58px -2px' md='-32px -3px -58px -2px' mlg='0px 0px -37px -4px' />
                                        <ImgB10 src={`/img/retrocede_inversa.png`} w='46.5px' wm='47.5px' wt='63px' wd='80px' wlg='82px' m='0px -2px -40px -2px' mm='-21px -2px -48px -2px' mt='-34px -3px -58px -2px' md='-32px -3px -58px -2px' mlg='0px 0px -37px -4px' />
                                    </Flex>
                                </FlexFull>
                                <FlexFull direction='column' justify='center' align='center' margin='0.5vw' bradius='10px' color='#FFFFFF' >
                                    <ImgB10 src='/img/recta72.png' w='373px' wm='387px' wt='515px' wd='680px' wlg='695px' h='' hm='50px' ht='100px' hd='100px' hlg='100px' m='3vh 0px 0px 0px' />
                                </FlexFull>
                            </FlexFull>
                        </FlexFull>
                        }
                        {contador === 1 &&
                        <FlexFull direction='column' justify='space-evenly' align='start' height='76vh'> 
                            {contadorSecundario >= 0 &&
                            <FlexFull justify='center' align='center' height='55%' width='100%'>
                                <div>
                                    <ImgB10 src={`/img/nene.png`} w='36vw' wm='33vw' wt='26vw' wd='22vw' wlg='21vw' m='0px' />
                                </div>
                                <FlexFull direction='column' justify='space-evenly' align='center' minh='70px' margin='0px 0px 0px -15px'>
                                    <PM72 color='#23AAAF' fs='3.1vw' fsm='3vw' fst='2.7vw' fsd='2.6vw' fslg='2.6vw' m='0px'>
                                        VAMOS A VER OTRA MANERA DE RESTAR
                                    </PM72>
                                    <P color='#FF9B2A' fs='4.5vw' fw='600' m='0px'>
                                        15 - 11
                                    </P>
                                </FlexFull>
                            </FlexFull>
                            }
                            <FlexFull direction='column' justify='space-evenly' align='start' height='45%' width='100%'>
                                {contadorSecundario > 0 &&
                                <Flex>
                                    <PM72 fs='2.5vw' fsm='2.5vw' fst='2.4vw' fsd='2.3vw' fslg='2.2vw'>
                                        CUÁL ES EL NÚMERO MÁS CHICO
                                    </PM72>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <InputM72 type="numeric" min="1" max="20" value={numero} onChange={(e) => setNumero(e.target.value)} m='0px 0px 0px 1vw' />
                                    </form>
                                </Flex>
                                }
                                {contadorSecundario > 1 &&
                                <FlexFull justify='space-evenly' align='center'>
                                    <PM72 fs='2.5vw' fsm='2.5vw' fst='2.4vw' fsd='2.3vw' fslg='2.2vw'>
                                        EMPIEZO A CONTAR PARA ADELANTE DESDE EL 11.
                                    </PM72>
                                    <PM72 color='#23AAAF' fs='2.5vw' fsm='2.5vw' fst='2.4vw' fsd='2.3vw' fslg='2.2vw' fw='600' m='0px 0px 0px 15px'>
                                        ME AYUDO CON LOS DEDOS
                                    </PM72>
                                </FlexFull>
                                }
                                {contadorSecundario > 2 &&
                                <PM72 fs='2.5vw' fsm='2.5vw' fst='2.4vw' fsd='2.3vw' fslg='2.2vw'>
                                    ¿CUÁNTOS DEDOS TENGO LEVANTADOS?
                                </PM72>
                                }
                                {contadorSecundario > 3 &&
                                <PM72 d='flex' justify='center' align='center' fs='2.5vw' fsm='2.5vw' fst='2.4vw' fsd='2.3vw' fslg='2.2vw'>
                                    PERFECTO, TENGO 4 DEDOS LEVANTADOS, <P color='#FF9B2A' fs='2.5vw' fsm='2.5vw' fst='2.4vw' fsd='2.3vw' fslg='2.2vw' fw='600' m='0px 10px 0px 10px'>15 - 11</P> ES <P color='#FF9B2A' fs='2.5vw' fsm='2.5vw' fst='2.4vw' fsd='2.3vw' fslg='2.2vw' fw='600' m='0px 0px 0px 10px'>4</P>
                                </PM72>
                                }
                            </FlexFull>
                        </FlexFull>
                        }
                        {contador === 2 &&
                        <Flex direction='column' height='76vh'>
                            {contadorSecundario > 3 &&
                            <Flex direction='column'>
                                <P fs='3.5vw' fsm='3.2vw' fst='2.8vw' fsd='2.6vw' fslg='2.6vw' fw='600' color='#646464'>
                                    AHORA, ¡A PRACTICAR!
                                </P>
                                <P fs='2.7vw' fsm='2.5vw' fst='2.4vw' fsd='2.3vw' fslg='2.2vw' fw='500' m='0px' color='#646464'>
                                    ESTE MÉTODO ME SIRVE CUANDO TENGO NÚMEROS MÁS GRANDES
                                </P>
                                <FlexFull width='30%' justify='space-evenly' align='center'>
                                    <P color='#FF9B2A' fs='4.5vw' fsm='4vw' fst='3.8vw' fsd='3.6vw' fslg='3.4vw' fw='800'>
                                        14 - 7 = 
                                    </P>
                                    {contadorTerciario > 6 &&
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <InputM72 type="numeric" min="1" max="20" value={numero2} onChange={(e) => setNumero2(e.target.value)} />
                                    </form>
                                    }
                                </FlexFull>
                            </Flex>
                            }
                            <Container minh='100px' minhw='120px' minht='150px' minhd='170px' minhlg='180px'>
                            {contadorSecundario > 4 &&
                            <FlexFull direction='column' justify='center' align='center'>
                                <FlexFull height='50px' width='260px' wm='270px' wt='400px' wd='478px' wlg='485px' bradius='10px' margin='0px -34px -25px 0px' mm='0px -33px -20px 0px' mt='0px -3px -15px 0px' md='0px -61px -10px 0px' mlg='0px -61px -8px 0px' justify='end' align='center' z='1'>
                                    {curva.map((item,i) =>
                                    <div key={i}>
                                        <Flex>
                                            <ImgB10 src={item ? `/img/${item}.png` : null} w='45.5px' wm='46.5px' wt='61px' wd='81px' wlg='83px' m='0px -2px -36px -2px' mm='0px -2px -48px -2px' mt='0px -2px -51px -2px' md='-3px -2.3px -62px -3px' mlg='0px -4px -61px -2px' />
                                        </Flex>
                                    </div>
                                    )}
                                </FlexFull>
                                <FlexFull direction='column' justify='center' align='center' margin='0.5vw' bradius='10px' color='#FFFFFF' >
                                    <ImgB10 src='/img/recta72.png' w='373px' wm='387px' wt='515px' wd='680px' wlg='695px' m='3vh 0px 0px 0px' />
                                    <Flex width='52vw' wm='47vw' wt='43vw' wd='41vw' wlg='40vw' margin='-6px -5px 0px 0px' mm='-6px -8px 0px 0px' mt='-8px -10px 0px 0px' md='-10px -13px 0px 0px' mlg='-10px -15px 0px 0px'>
                                    {numeroRegla.map((item,i) =>
                                        <BtnRectaM73 m='0px 8.8px 0px 8.8px' mm='0px 9.3px 0px 9.3px' mt='0px 15px 0px 14px' md='0px 24px 0px 24px' mlg='0px 24.5px 0px 24.5px'
                                            key={i}
                                        >
                                            <PRectaM67 m='0px'>{item}</PRectaM67>
                                        </BtnRectaM73>
                                        )}
                                    </Flex>
                                </FlexFull>
                            </FlexFull>
                            }
                            </Container>
                        </Flex>
                        }
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            {contador === 0 && <BotoneraBottom aumentar={true} />}
                            {contador === 1 && contadorSecundario === 0 && <BotoneraBottom reducir={true} aumentarSecundario={true} />}
                            {contador === 1 && contadorSecundario > 0 && contadorSecundario < 4 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />}
                            {contador === 1 && contadorSecundario === 4 && <BotoneraBottom reducirSecundario={true} aumentar={true} />}
                            {contador === 2 && contadorSecundario === 4 && <BotoneraBottom reducir={true} aumentarSecundario={true} />}
                            {contador === 2 && contadorSecundario === 5 && contadorTerciario === 0 && <BotoneraBottom reducirSecundario={true} aumentarTerciario={true} />}
                            {contador === 2 && contadorSecundario === 5 && contadorTerciario > 0 && contadorTerciario < 7 && <BotoneraBottom reducirTerciario={true} aumentarTerciario={true} />}
                            {contador === 2 && contadorSecundario === 5 && contadorTerciario === 7 && <BotoneraBottom reducirTerciario={true} finalizarEjercicio={true} />}
                        </FlexCuz>
                    </Fragment>
                    :
                    <Instrucciones instrucciones={instrucciones} />
                }
            </Fragment>
        </Fragment>
    );
}
 
export default M75;