import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, Img, BtnLetraLN30, Palabra, FormInput, InputTextLN30, BtnAvanzar } from '../../../../Css/MetodosCSS/StyledLectoEscritura';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Observa la palabra rápidamente y escribela. Si no logras leerla puedes volver a hacer click en el recuadro. El objetivo es que logres leerla en el primer intento.';
const miniInstrucciones = 'Observa la palabra rápidamente y escribela.';

const LN30 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [disable, setDisable] = useState(false);
    const [palabra, setPalabra] = useState('');
    const [count, setCount] = useState(1);
    const [contar, setContar] = useState(1);
    const [contarAyuda, setContarAyuda] = useState(0);
    const [mostrarPalabra, setMostrarPalabra] = useState(false);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if (palabra.toUpperCase() !== comparar.palabra_1.toUpperCase()) {
            console.log('mal')
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setPalabra('');
            }, 1500);
            return;
        }
        if (count < indexes) {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            setTimeout(() => {
                setDisable(false);
                setCount(count + 1);
                setPalabra('');
                setContar(1);
                setMostrarPalabra(false);
            }, 1500);
        } else {
            console.log('bien')
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            setTimeout(() => {
                setDisable(false);
                setUltimoTiempo(true);
                dispatch(finalizarEjercicioAction());
            }, 1500);
        }
    }

    const mostrar = (e) => {
        e.preventDefault();
        if (contar === 1) {
            setMostrarPalabra(true);
            setTimeout(() => {
                setMostrarPalabra(false);
            }, 500);
        } else if (contar === 2) {
            setMostrarPalabra(true);
            setContarAyuda(contarAyuda+1);
            setTimeout(() => {
                setMostrarPalabra(false);
            }, 1000);
        } else if (contar === 3) {
            setMostrarPalabra(true);
            setContarAyuda(contarAyuda+1);
            setTimeout(() => {
                setMostrarPalabra(false);
            }, 1500);
        } else {
            setMostrarPalabra(true);
            setContarAyuda(contarAyuda+1);
        }
        setContar(contar+1);
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LN30') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/ejercicios/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LN30",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda': contarAyuda,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);


    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlexx height="100%" width="100%" display="flex" direction="column" justify="center" align="center">
                    <ContainerFlexx display="flex" direction="column" justify="center" align="center" margin="auto"
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}
                    >
                        
                        <ContainerFlexx width='23rem' height="6rem" margin="0 0 3rem 0">
                            {mostrarPalabra === true
                                ?
                                <Palabra>
                                    {comparar.palabra_1}
                                </Palabra>
                                : 
                                <BtnLetraLN30 width="100%" height='100%'
                                    onClick={(e) => mostrar(e)}
                                >
                                    <Img w='100%' h='100%' br='20px' src={`/img/reves_naranja.png`}/>
                                    {/* <Img w='100%' h='100%' br='20px' src={`/img/btn_naranja.png`}/> */}
                                </BtnLetraLN30>
                            }
                        </ContainerFlexx>

                        <FormInput height="6vh"  display="flex" justify="center"
                            onSubmit={(e) => verificar(e)}
                        >
                            <InputTextLN30 type="text" value={palabra} onChange={(e) => setPalabra(e.target.value)} disabled={disable} />
                        </FormInput>

                    </ContainerFlexx>

                    <ContainerFlexx margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlexx>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment >
    );
}

export default LN30;