import {
    SOLICITO_INFORMACION_EJERCICIO,
    SOLICITO_INFORMACION_EJERCICIO_ERROR,
    SOLICITO_INFORMACION_PACIENTE,
    SOLICITO_INFORMACION_PACIENTE_ERROR,
    ACTUALIZAR_INFORMACION_PACIENTE_ERROR,
    ALMACENAR,
    ALMACENAR_ERROR,
    CREAR_USUARIO,
    CREAR_USUARIO_EXITO,
    CREAR_USUARIO_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_EXITO,
    RESET_PASSWORD_ERROR,
    RESTABLECER_PASSWORD,
    RESTABLECER_PASSWORD_EXITO,
    RESTABLECER_PASSWORD_ERROR,
    LOGIN,
    LOGIN_ERROR,
    AUTENTICADO,
    AUTENTICADO_EXITO,
    AUTENTICADO_ERROR,
    CERRAR_SESION,
    CONFIRMAR_CUENTA,
    CONFIRMAR_CUENTA_EXITO,
    CONFIRMAR_CUENTA_ERROR,
    SOLICITAR_CONFIRMAR,
    SOLICITAR_CONFIRMAR_EXITO,
    SOLICITAR_CONFIRMAR_ERROR,
    CREAR_PACIENTE,
    CREAR_PACIENTE_EXITO,
    CREAR_PACIENTE_ERROR,
    EDITAR_PACIENTE,
    EDITAR_PACIENTE_EXITO,
    EDITAR_PACIENTE_ERROR,
    SOLICITAR_PASARSE,
    SOLICITAR_PASARSE_EXITO,
    SOLICITAR_PASARSE_ERROR,
    NUEVO_OBJETIVO,
    NUEVO_OBJETIVO_EXITO,
    NUEVO_OBJETIVO_ERROR,
    SOLICITAR_OBJETIVOS,
    SOLICITAR_OBJETIVOS_ERROR,
    MODIFICAR_OBJETIVO,
    MODIFICAR_OBJETIVO_ERROR,
    ESTADO_ERROR_INITIAL,
    ESTADO_LOADING_INITIAL,
    ESTADO_CORRECTO_INITIAL,
    BUSCO_PACIENTE,
    BUSCO_PACIENTE_ERROR,
    BUSCO_PACIENTE_EXITO,
    SOLICITO_NUEVO_PROFESIONAL,
    SOLICITO_NUEVO_PROFESIONAL_EXITO,
    SOLICITO_NUEVO_PROFESIONAL_ERROR,
    DAR_DE_BAJA_METODO,
    DAR_DE_BAJA_METODO_EXITO,
    DAR_DE_BAJA_METODO_ERROR,
    ACTIVAR_METODO,
    ACTIVAR_METODO_EXITO,
    ACTIVAR_METODO_ERROR,
    SOLICITO_ANAMNESIS,
    SOLICITO_ANAMNESIS_ERROR,
    SOLICITAR_HISTORIA_CLINICA,
    SOLICITAR_HISTORIA_CLINICA_ERROR,
    NUEVO_HISTORIA_CLINICA,
    NUEVO_HISTORIA_CLINICA_ERROR,
    MODIFICAR_HISTORIA_CLINICA,
    MODIFICAR_HISTORIA_CLINICA_ERROR,
    SOLICITAR_ACTIVIDADES,
    SOLICITAR_ACTIVIDADES_ERROR,
    SOLICITAR_ACTIVIDADES_AGENDADAS,
    SOLICITAR_ACTIVIDADES_AGENDADAS_ERROR,
    ACTUALIZAR_ACTIVIDADES,
    ACTUALIZAR_ACTIVIDADES_ERROR,
    ACTUALIZAR_INFO_ACTIVIDADES,
    ACTUALIZAR_INFO_ACTIVIDADES_ERROR,
    AGENDAR_ACTIVIDADES,
    AGENDAR_ACTIVIDADES_EXITO,
    AGENDAR_ACTIVIDADES_ERROR,
    SOLICITO_INFORMACION_PERFIL_PROFESIONAL,
    SOLICITO_INFORMACION_PERFIL_PROFESIONAL_ERROR,
    SOLICITO_INFORMACION_PERFIL_PROFESIONAL_EXITO,
    SOLICITO_TOKEN_PERFIL_PROFESIONAL,
    SOLICITO_TOKEN_PERFIL_PROFESIONAL_EXITO,
    SOLICITO_TOKEN_PERFIL_PROFESIONAL_ERROR,
    SOLICITO_SUSCRIPCION_PROFESIONAL_ERROR,
    SOLICITO_SUSCRIPCION_PROFESIONAL,
    ENVIAR_INVITACION_PACIENTE,
    ENVIAR_INVITACION_PACIENTE_EXITO,
    ENVIAR_INVITACION_PACIENTE_ERROR,
    CARGAR_DATOS_PACIENTE_NO_REGISTRADO_ERROR,
    CARGAR_DATOS_PACIENTE_NO_REGISTRADO,
    CREAR_USUARIO_PACIENTE,
    CREAR_USUARIO_PACIENTE_ERROR,
    CREAR_USUARIO_PACIENTE_EXITO,
    SOLICITO_CANTIDAD_SUSCRIPCION_ERROR,
    SOLICITO_CANTIDAD_SUSCRIPCION,
    CONSULTAR_ACTIVIDADES_RESULTADOS_ERROR,
    CONSULTAR_EJERCICIOS_RESULTADOS_ERROR,
    SUSPENDO_SUSCRIPTOR,
    SUSPENDO_SUSCRIPTOR_ERROR,
    ACTIVO_SUSCRIPTOR,
    ACTIVO_SUSCRIPTOR_ERROR,
    CREAR_SUSCRIPTOR,
    CREAR_SUSCRIPTOR_ERROR,
    CAMBIAR_SUSCRIPCION,
    CAMBIAR_SUSCRIPCION_EXITO,
    CAMBIAR_SUSCRIPCION_ERROR,
    SOLICITO_LISTA_EJERCICIO,
    SOLICITO_LISTA_EJERCICIO_ERROR,
    PROBAR_GRATIS,
    CANCELAR_PRUEBA_GRATIS
} from '../types';

const initialState = {
    error: null,
    loading: false,
    correcto: null
};

export default function estadoReducer(state = initialState, action) {
    switch(action.type) {
        case SOLICITO_INFORMACION_EJERCICIO:
        case SOLICITO_INFORMACION_PACIENTE:
        case ALMACENAR:
        case CREAR_USUARIO:
        case RESET_PASSWORD:
        case RESTABLECER_PASSWORD:
        case LOGIN:
        case AUTENTICADO:
        case CONFIRMAR_CUENTA:
        case SOLICITAR_CONFIRMAR:
        case CREAR_PACIENTE:
        case EDITAR_PACIENTE:
        case SOLICITAR_PASARSE:
        case NUEVO_OBJETIVO:
        case SOLICITAR_OBJETIVOS:
        case MODIFICAR_OBJETIVO:
        case BUSCO_PACIENTE:
        case SOLICITO_NUEVO_PROFESIONAL:
        case DAR_DE_BAJA_METODO:
        case ACTIVAR_METODO:
        case SOLICITO_ANAMNESIS:
        case SOLICITAR_HISTORIA_CLINICA:
        case NUEVO_HISTORIA_CLINICA:
        case MODIFICAR_HISTORIA_CLINICA:
        case SOLICITAR_ACTIVIDADES:
        case SOLICITAR_ACTIVIDADES_AGENDADAS:
        case ACTUALIZAR_ACTIVIDADES:
        case ACTUALIZAR_INFO_ACTIVIDADES:
        case AGENDAR_ACTIVIDADES:
        case SOLICITO_INFORMACION_PERFIL_PROFESIONAL:
        case SOLICITO_TOKEN_PERFIL_PROFESIONAL:
        case SOLICITO_SUSCRIPCION_PROFESIONAL:
        case ENVIAR_INVITACION_PACIENTE:
        case CARGAR_DATOS_PACIENTE_NO_REGISTRADO:
        case CREAR_USUARIO_PACIENTE:
        case SOLICITO_CANTIDAD_SUSCRIPCION:
        case SUSPENDO_SUSCRIPTOR:
        case ACTIVO_SUSCRIPTOR:
        case CREAR_SUSCRIPTOR:
        case CAMBIAR_SUSCRIPCION:
        case SOLICITO_LISTA_EJERCICIO:
            return {
                ...state,
                error: null,
                loading: action.payload,
                correcto: null
            }
        case CREAR_USUARIO_EXITO:
        case RESET_PASSWORD_EXITO:
        case RESTABLECER_PASSWORD_EXITO:
        case AUTENTICADO_EXITO:
        case CERRAR_SESION:
        case CONFIRMAR_CUENTA_EXITO:
        case SOLICITAR_CONFIRMAR_EXITO:
        case CREAR_PACIENTE_EXITO:
        case EDITAR_PACIENTE_EXITO:
        case SOLICITAR_PASARSE_EXITO:
        case NUEVO_OBJETIVO_EXITO:
        case BUSCO_PACIENTE_EXITO:
        case SOLICITO_NUEVO_PROFESIONAL_EXITO:
        case DAR_DE_BAJA_METODO_EXITO:
        case ACTIVAR_METODO_EXITO:
        case AGENDAR_ACTIVIDADES_EXITO:
        case CAMBIAR_SUSCRIPCION_EXITO:
            return {
                ...state,
                error: null,
                loading: false,
                correcto: action.payload
            }
        case SOLICITO_INFORMACION_PERFIL_PROFESIONAL_EXITO:
        case SOLICITO_TOKEN_PERFIL_PROFESIONAL_EXITO:
        case ENVIAR_INVITACION_PACIENTE_EXITO:
        case CREAR_USUARIO_PACIENTE_EXITO:
        case PROBAR_GRATIS:
        case CANCELAR_PRUEBA_GRATIS:
            return {
                ...state,
                error: null,
                loading: false,
                correcto: true
            }
        case SOLICITO_INFORMACION_EJERCICIO_ERROR:
        case SOLICITO_INFORMACION_PACIENTE_ERROR:
        case ALMACENAR_ERROR:
        case CREAR_USUARIO_ERROR:
        case RESET_PASSWORD_ERROR:
        case RESTABLECER_PASSWORD_ERROR:
        case LOGIN_ERROR:
        case AUTENTICADO_ERROR:
        case CONFIRMAR_CUENTA_ERROR:
        case SOLICITAR_CONFIRMAR_ERROR:
        case CREAR_PACIENTE_ERROR:
        case EDITAR_PACIENTE_ERROR:
        case SOLICITAR_PASARSE_ERROR:
        case NUEVO_OBJETIVO_ERROR:
        case SOLICITAR_OBJETIVOS_ERROR:
        case MODIFICAR_OBJETIVO_ERROR:
        case BUSCO_PACIENTE_ERROR:
        case SOLICITO_NUEVO_PROFESIONAL_ERROR:
        case DAR_DE_BAJA_METODO_ERROR:
        case ACTIVAR_METODO_ERROR:
        case SOLICITO_ANAMNESIS_ERROR:
        case SOLICITAR_HISTORIA_CLINICA_ERROR:
        case NUEVO_HISTORIA_CLINICA_ERROR:
        case MODIFICAR_HISTORIA_CLINICA_ERROR:
        case SOLICITAR_ACTIVIDADES_ERROR:
        case SOLICITAR_ACTIVIDADES_AGENDADAS_ERROR:
        case ACTUALIZAR_ACTIVIDADES_ERROR:
        case ACTUALIZAR_INFO_ACTIVIDADES_ERROR:
        case AGENDAR_ACTIVIDADES_ERROR:
        case SOLICITO_INFORMACION_PERFIL_PROFESIONAL_ERROR:
        case SOLICITO_TOKEN_PERFIL_PROFESIONAL_ERROR:
        case SOLICITO_SUSCRIPCION_PROFESIONAL_ERROR:
        case ENVIAR_INVITACION_PACIENTE_ERROR:
        case CARGAR_DATOS_PACIENTE_NO_REGISTRADO_ERROR:
        case CREAR_USUARIO_PACIENTE_ERROR:
        case ACTUALIZAR_INFORMACION_PACIENTE_ERROR:
        case SOLICITO_CANTIDAD_SUSCRIPCION_ERROR:
        case CONSULTAR_ACTIVIDADES_RESULTADOS_ERROR:
        case CONSULTAR_EJERCICIOS_RESULTADOS_ERROR:
        case SUSPENDO_SUSCRIPTOR_ERROR:
        case ACTIVO_SUSCRIPTOR_ERROR:
        case CREAR_SUSCRIPTOR_ERROR:
        case CAMBIAR_SUSCRIPCION_ERROR:
        case SOLICITO_LISTA_EJERCICIO_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                correcto: null
            }
        case ESTADO_ERROR_INITIAL:
            return {
                ...state,
                error: action.payload
            }
        case ESTADO_LOADING_INITIAL:
            return {
                ...state,
                loading: action.payload
            }
        case ESTADO_CORRECTO_INITIAL:
            return {
                ...state,
                correcto: action.payload
            }
        default:
            return state;
    }
}