import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import { objetivosNuevosAction } from '../../actions/objetivosActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';
import useConfigAuth from '../../Hooks/useConfigAuth';

//css
import {Icon, Error} from '../Css/StyledHeader'
import {Sumar, ModalStyled, ModalButton , ModalLabel, ModalInput} from '../Css/StyledActivities';

Modal.setAppElement('#root');

const ObjetivosNuevos = (props) => {

    const { id, seccion } = props.seccion;

    const [config] = useConfigAuth();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [nombreObjetivo, setNombreObjetivo] = useState('');

    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const cargando = useSelector( (state) => state.estado.cargando);
    const error = useSelector( (state) => state.estado.error);
    const alerta = useSelector( (state) => state.alertas.alerta);

    const dispatch = useDispatch();

    const submitObjetivosNuevos = e => {
        e.preventDefault();
        if(nombreObjetivo.trim() === '' ) {
            const alerta = {
                msg: 'El objetivo es obligatorio'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        dispatch( ocultarAlertaAction());

        const informacion = {
            config,
            payload: {
                nombreObjetivo,
                id_seccion_objetivo: id,
                seccion_objetivo: seccion,
                id_paciente
            }
        };

        dispatch( objetivosNuevosAction(informacion));
        setNombreObjetivo('');
        setModalIsOpen(false);
    }

    return (
        <Fragment>
            <Sumar onClick={() => setModalIsOpen(true)} ><Icon src={`/img/mas.svg`} alt="Agregar" width="20px" height="20px" padding="10px" /></Sumar>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                <ModalStyled>
                    <form
                        onSubmit={submitObjetivosNuevos}
                    >
                        <ModalStyled>
                            <ModalLabel>¿Qué objetivo quieres agregar a {seccion}? </ModalLabel>
                            <p>Ten en cuenta que una vez agregado no se podrá eliminar ni editar el objetivo. Busca que sea reutilizable para otros pacientes. </p>
                            <ModalInput
                                type="text"
                                placeholder="Nombre objetivo"
                                name="nombreObjetivo"
                                value={nombreObjetivo}
                                onChange={e => setNombreObjetivo(e.target.value)}
                            />            
                        <ModalButton 
                            type="submit"
                        >Agregar nuevo objetivo</ModalButton >
                        {cargando ? <p>Cargando...</p> : null}
                        {error ? <Error>Hubo un error</Error> : null}
                        {alerta ? <p>{alerta.msg}</p> : null}
                        </ModalStyled>
                    </form>
                </ModalStyled>
            </Modal>
        </Fragment>
    );
}
 
export default ObjetivosNuevos;