import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { ContenedorAlerta, MensajeAlerta } from '../../Css/StyledAlertas';
import { ocultarAlertaAction } from '../../../actions/alertaActions';

const Alertas = () => {

    const dispatch = useDispatch();

    const alerta = useSelector ( (state) => state.alertas.alerta);

    useEffect(() => {
        if(!alerta) {
            return;
        }
        setTimeout(() => {
            dispatch(ocultarAlertaAction())
        }, 1700);
        
    }, [alerta])
    

    return (
        <ContenedorAlerta>
            <MensajeAlerta>
                {alerta.msg}
            </MensajeAlerta>
        </ContenedorAlerta>
    );
}
 
export default Alertas;