import React, {Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import AyudaEstructuraOperaciones from '../../Suma/AyudaEstructuraOperaciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, InputM83Bis, ImgB10, DivM83, Container, AvanzarBtn, AvanzarImg, BtnImgM64, Lateral, NumRedondo, NumCuadrado, InputCuadrado, InputRedondo, NumberDivR, NumberDivL, ImgDiv, NumCalculoM83 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M85 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [complejo, setComplejo] = useState(false);
    const [count, setCount] = useState(1);
    const [countPaso, setCountPaso] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState('');
    const [digitoD1, setDigitoD1] = useState('');
    const [digitoU2, setDigitoU2] = useState('');
    const [digitoD2, setDigitoD2] = useState('');
    const [digitoIntermedioUno, setDigitoIntermedioUno] = useState('');
    const [digitoIntermedioDos, setDigitoIntermedioDos] = useState('');
    const [digitoFinalUno, setDigitoFinalUno] = useState('');
    const [digitoFinalDos, setDigitoFinalDos] = useState('');
    const [numeroUnidadUno, setNumeroUnidadUno] = useState('');
    const [numeroUnidadDos, setNumeroUnidadDos] = useState('');
    const [numeroDecenaUno, setNumeroDecenaUno] = useState('');
    const [numeroDecenaDos, setNumeroDecenaDos] = useState('');
    const [numeroIntermedioDos, setNumeroIntermedioDos] = useState('');
    const [numeroIntermedioUno, setNumeroIntermedioUno] = useState('');
    const [numeroFinalUno, setNumeroFinalUno] = useState('');
    const [numeroFinalDos, setNumeroFinalDos] = useState('');
    const [numeroFinal, setNumeroFinal] = useState('');
    const [numeroFinal2, setNumeroFinal2] = useState('');
    const [pasoUno, setPasoUno] = useState(false);
    const [pasoDos, setPasoDos] = useState(false);
    const [pasoTres, setPasoTres] = useState(false);
    const [pasoCuatro, setPasoCuatro] = useState(false);
    const [tipoPasoTres, setTipoPasoTres] = useState(false);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarErrorEstructura, setContarErrorEstructura] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const asignar = (e, tipo) => {
        e.preventDefault();
        if(complejo === false){
            if(countPaso === 1){
                if(tipo === 'cereza') {
                    setPasoUno(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 2){
                if(tipo === 'cereza') {
                    setPasoDos(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 3){
                if(tipo === 'resultado') {
                    setPasoTres(true);
                    setCountPaso(countPaso + 1);
                    setTipoPasoTres('resultado');
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
        }
        if(complejo === true){
            if(countPaso === 1){
                if(tipo === 'cereza') {
                    setPasoUno(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 2){
                if(tipo === 'cereza') {
                    setPasoDos(true);
                    setCountPaso(countPaso + 1);
                } else {
                    console.log('mostrar error');
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 3){
                if(tipo === 'cereza') {
                    setPasoTres(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
            if(countPaso === 4){
                if(tipo === 'resultado') {
                    setPasoCuatro(true);
                    setCountPaso(countPaso + 1);
                } else {
                    setContarErrorEstructura(contarErrorEstructura + 1);
                }
            }
        }
        
    }

    const verificar = (e) => {
        e.preventDefault();
        if(complejo === true) {
            if(tipoPasoTres === 'resultado') {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setNumeroUnidadUno(0);
                    setNumeroDecenaUno(0);
                    setNumeroUnidadDos(0);
                    setNumeroDecenaDos(0);
                    setNumeroFinalUno(0);
                    setNumeroFinalDos(0);
                    setNumeroIntermedioUno(0);
                    setNumeroIntermedioDos(0);
                    setNumeroFinal(0);
                    setTipoPasoTres('');
                    setCountPaso(1);
                    setPasoUno(false);
                    setPasoDos(false);
                    setPasoTres(false);
                    setPasoCuatro(false);
                }, 300);
            }
            if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal2.toString() || digitoIntermedioUno.toString() !== numeroIntermedioUno.toString() || digitoIntermedioDos.toString() !== numeroIntermedioDos.toString()) {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal2.toString() || digitoIntermedioUno.toString() !== numeroIntermedioUno.toString() || digitoIntermedioDos.toString() !== numeroIntermedioDos.toString()) {
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                        setNumeroFinalUno(0);
                        setNumeroFinalDos(0);
                        setNumeroIntermedioUno(0);
                        setNumeroIntermedioDos(0);
                        setNumeroFinal(0);
                        setNumeroFinal2(0);
                        setTipoPasoTres('');
                        setCountPaso(1);
                        setPasoUno(false);
                        setPasoDos(false);
                        setPasoTres(false);
                        setPasoCuatro(false);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                        setNumeroFinalUno(0);
                        setNumeroFinalDos(0);
                        setNumeroFinal(0);
                        setNumeroFinal2(0);
                        setDigitoU1(0);
                        setDigitoD1(0);
                        setDigitoU2(0);
                        setDigitoD2(0);
                        setDigitoFinalUno(0);
                        setDigitoFinalDos(0);
                        setNumeroIntermedioUno(0);
                        setNumeroIntermedioDos(0);
                        setTipoPasoTres('');
                        setCountPaso(1);
                        setPasoUno(false);
                        setPasoDos(false);
                        setPasoTres(false);
                        setPasoCuatro(false);
                        setComplejo(false);
                        setCount(count + 1);
                    }, 300);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch( finalizarEjercicioAction() );
                    }, 1500);
                }
            }
        } else {
            if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString()) {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString()) {
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                        setNumeroFinalUno(0);
                        setNumeroFinalDos(0);
                        setNumeroFinal(0);
                        setNumeroFinal2(0);
                        setTipoPasoTres('');
                        setCountPaso(1);
                        setPasoUno(false);
                        setPasoDos(false);
                        setPasoTres(false);
                        setPasoCuatro(false);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setNumeroUnidadUno(0);
                        setNumeroDecenaUno(0);
                        setNumeroUnidadDos(0);
                        setNumeroDecenaDos(0);
                        setNumeroFinalUno(0);
                        setNumeroFinalDos(0);
                        setNumeroFinal(0);
                        setNumeroFinal2(0);
                        setDigitoU1(0);
                        setDigitoD1(0);
                        setDigitoU2(0);
                        setDigitoD2(0);
                        setDigitoFinalUno(0);
                        setDigitoFinalDos(0);
                        setTipoPasoTres('');
                        setCountPaso(1);
                        setPasoUno(false);
                        setPasoDos(false);
                        setPasoTres(false);
                        setPasoCuatro(false);
                        setComplejo(false);
                        setCount(count + 1);
                    }, 300);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch( finalizarEjercicioAction() );
                    }, 1500);
                }
            }
        }
    }

    useEffect(() => {
        const min = 21;
        const max = 99;
        const randomNumeroUno = Math.floor(Math.random() * (max - min +1)) + min;
        const randomNumeroDos = (function () {
            const d1 = Number(randomNumeroUno.toString().substring(0,1));
            const minU = 1;
            const maxU = 9;
            const u2 = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 0;
            const maxD = d1-1;
            const d2 = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d2.toString().concat(u2.toString()));
            return numero;
        })();
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        const pasoUnoU2 = newList.cantidad_1.toString().substring(1,2);
        const pasoUnoU1 = newList.cantidad_2.toString().substring(1,2);
        const pasoUnoD2 = (Number(newList.cantidad_1.toString().substring(0,1))*10).toString();
        const pasoUnoD1 = (Number(newList.cantidad_2.toString().substring(0,1))*10).toString();
        const pasoDosU1 = (Number(pasoUnoU1) - Number(pasoUnoU2)).toString();
        const pasoDosD1 = pasoUnoU2.toString();
        const pasoTresD1 = (Number(pasoUnoD2) - Number(pasoUnoD1)).toString();
        
        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(pasoUnoU1);
            setDigitoD1(pasoUnoD1);
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(pasoUnoU2);
            setDigitoD2(pasoUnoD2);
        }
        if(pasoUnoU1 > pasoUnoU2){
            setComplejo(true);
            setTipoEjercicio('suma_descomposicion_compleja');
            setDigitoIntermedioUno(pasoDosU1);
            setDigitoIntermedioDos(pasoDosD1);
            setDigitoFinalUno(pasoTresD1);
            setDigitoFinalDos(pasoDosU1);
        } else {
            setTipoEjercicio('suma_descomposicion');
            setDigitoFinalUno((Number(pasoUnoD2) - Number(pasoUnoD1)).toString());
            setDigitoFinalDos((Number(pasoUnoU2) - Number(pasoUnoU1)).toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M85') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-descomposicion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M85",
                    'errores': contarErrores,
                    'error_estructura': contarErrorEstructura,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'ayuda_visual': ayuVisualSolicitada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);
    
    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment>
                    <FlexFull justify='space-evenly' align='center'>
                        <FlexFull direction='column' justify='space-around' align='start' width='48vw' height='75vh'>
                        {/* CALCULO */}
                        {comparar.length === 0 ? null :
                        <FlexFull justify='end' align='center' width='100%'> {/*  border='2px solid blue' */} 
                            <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                <NumCalculoM83 color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculoM83>
                                <NumCalculoM83 color='#646464'>-</NumCalculoM83>                                     
                                <NumCalculoM83 color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculoM83>
                                <NumCalculoM83 color='#646464'>=</NumCalculoM83> 
                                <NumCalculoM83 color='#646464'>
                                {pasoCuatro === true || tipoPasoTres === 'resultado' ?  
                                <div> 
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <InputM83Bis type="numeric" value={numeroFinal2} onChange={(e) => setNumeroFinal2(e.target.value)} disabled={disable} />
                                    </form>
                                </div>    
                                : null
                                }
                            </NumCalculoM83> 
                            </FlexFull>
                        </FlexFull>
                        }
                        {/* FIN CALCULO */}

                        {/* DESCOMPOSICIÓN */}
                        <FlexFull justify='space-around' align='center' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='-18px 0vw 0vh 15px'> {/* border='3px solid blue' */}
                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 2vw 0vh 0vw' mt='0px 1.5vw 0vh 0px' md='0px 1.2vw 0vh 0px' mlg='0px 2.3vw 0vh 0px'> { /* border='3px solid violet' */}
                            {pasoDos === true &&
                                <div>
                                    <ImgDiv>
                                        <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                    </ImgDiv>
                                    <Flex>
                                        <NumberDivL>
                                            <NumRedondo>
                                                <InputRedondo type="numeric" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disable} />
                                            </NumRedondo>
                                        </NumberDivL>
                                    
                                        <NumberDivR>
                                            <NumCuadrado>
                                                <InputCuadrado type="numeric" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disable} />
                                            </NumCuadrado>
                                        </NumberDivR>
                                    </Flex>
                                </div>
                            }
                            </Flex>
                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 0vw 0vh 2vw' mlg='0px'>  { /*   margin='0vh 0vw 0vh 4vw'   border='1px solid green' */}
                            {pasoUno === true &&
                                <div>
                                    <ImgDiv>
                                        <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                    </ImgDiv>
                                    <Flex>
                                        <NumberDivL>
                                            <NumRedondo>
                                                <InputRedondo type="numeric" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disable} />
                                            </NumRedondo>
                                        </NumberDivL>
                                        <NumberDivR>
                                            <NumCuadrado>
                                                <InputCuadrado type="numeric" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disable} />
                                            </NumCuadrado>
                                        </NumberDivR>
                                    </Flex>
                                </div>
                            }
                            </Flex>
                        </FlexFull>
                        {/* FIN DESCOMPOSICIÓN */}

                        {/* LA COSA RARA */}
                        <Container minh='20vh' minw='30vw'>
                        {pasoTres === false ? null : tipoPasoTres === 'resultado' ?
                        // {/* "DOS" CALCULO ABAJO "DOS" */}
                            <FlexFull justify='center' align='center' width='100%'> {/*  border='2px solid blue' height='30vh' */} 
                                
                                <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                    <DivM83>
                                        <div>
                                            <InputCuadrado type="numeric" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                        </div>
                                    </DivM83>
                                    <DivM83>
                                        <NumCalculoM83 color='#646464'>-</NumCalculoM83>
                                    </DivM83>
                                    <DivM83>
                                        <div>
                                            <InputRedondo type="numeric" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} />
                                        </div>
                                    </DivM83>
                                    <DivM83>
                                        <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                    </DivM83>
                                    <DivM83>
                                        <InputCuadrado type="numeric" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                    </DivM83>
                                </FlexFull>
                                
                            </FlexFull>
                        // {/* FIN "DOS" CALCULO ABAJO "DOS" */}
                        :
                        // {/* ------------INTERMEDIOS */
                        <FlexFull justify='end' align='center' width='32.3vw' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px'>  
                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='-23px -7.8vw 0vh 0vw' mm='-33px -7vw 0vh 0px' mt='-37px -5.2vw 0vh 0px' md='-43px -5vw 0vh 0px' mlg='-33px -45px 0vh 0px'>
                                <div>
                                    <ImgDiv>
                                        <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='11vh' hm='10vh' hlg='15vh' />
                                    </ImgDiv>
                                    <Flex>
                                        <NumberDivR>
                                            <InputCuadrado type="numeric" value={numeroIntermedioDos} onChange={(e) => setNumeroIntermedioDos(e.target.value)} disabled={disable} />
                                        </NumberDivR>
                                        <NumberDivL>
                                                <InputCuadrado type="numeric" value={numeroIntermedioUno} onChange={(e) => setNumeroIntermedioUno(e.target.value)} disabled={disable} />
                                        </NumberDivL>
                                    </Flex>
                                </div>
                            </Flex>
                        </FlexFull>
                        // {/* ------------FIN INTERMEDIOS */}
                        }
                        </Container>
                        {/* FIN LA COSA RARA */}

                        {/* CALCULO ABAJO */}
                        <FlexFull justify='center' align='center' width='100%' margin='4vh 0px 0px 0px'> {/*  border='2px solid blue' */} 
                            {pasoCuatro === true && complejo === true &&
                            <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                <DivM83>
                                    <div>
                                        <InputRedondo type="numeric" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} />
                                    </div>
                                </DivM83>
                                <DivM83>
                                    <NumCalculoM83 color='#646464'>-</NumCalculoM83>
                                </DivM83>
                                <DivM83>
                                    <div>
                                        <InputCuadrado type="numeric" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                    </div>
                                </DivM83>
                                <DivM83>
                                    <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                </DivM83>
                                <DivM83>
                                    <InputCuadrado type="numeric" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                </DivM83>
                            </FlexFull>
                            }
                        </FlexFull>
                        {/* FIN CALCULO ABAJO */}
                        </FlexFull>


                        <AyudaEstructuraOperaciones tipo={tipoEjercicio} />

                        {/* <Lateral>
                        <FlexFull direction='column' justify='center' align='center' width='25vw' height='80vh'>
                            <div>
                                <button
                                    disabled={disable}
                                    onClick={(e) => asignar(e, 'cereza')}
                                >
                                    Cereza
                                </button>
                                <button
                                    disabled={disable}
                                    onClick={(e) => asignar(e, 'resultado')}
                                >
                                    resultado
                                </button>
                            </div>
                        </FlexFull>
                    </Lateral> */}


                    <Lateral>
                        <Flex direction='column'>
                            <BtnImgM64 w='100px' h='70px' m='10px -1px 10px 5px'
                                disabled={disable}
                                onClick={(e) => asignar(e, 'cereza')}
                            >
                                <ImgB10 src={`/img/cerezaa.png`} w='100px' h='70px' bradius='20px' />
                            </BtnImgM64>
                            <BtnImgM64 w='100px' h='70px' m='10px -1px 10px 5px'
                                disabled={disable}
                                onClick={(e) => asignar(e, 'resultado')}
                            >
                                <ImgB10 src={`/img/resultado.png`} w='100px' h='55px' bradius='20px' />
                            </BtnImgM64>
                        </Flex>
                    </Lateral>

                    </FlexFull>


                    {/* ------------------------------------- */}

                    


                        {/* ------------------------------------- */}


                    {/* INSTRUCCIONES */}
                    {pasoCuatro === true && complejo === true &&
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <div>{miniInstrucciones}</div>
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    }
                    {pasoTres === true && tipoPasoTres === 'resultado' &&
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <div>{miniInstrucciones}</div>
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    }
                    {/* FIN INSTRUCCIONES */}
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M85;