import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Instrucciones from '../../../../Escritorio/Instrucciones';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { zeroContadorAction, setearContadorAction} from '../../../../../actions/botoneraEjercicioActions';

import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

// css
import {Cuerpo, ContenedorLateral, ListaLetras, Flex, Letra, ContenedorLetras, ImgSound, BtnSound, Icons, BocaImg, LetraSmall, BtnBottom} from '../../../../Css/MetodosCSS/StyledLetras';

const abc = [
    {
        id:1, nombre: "A", color:"#23AAAF", imagen:'boca_a'
    },
    {
        id:2, nombre: "E", color:"#FF9B2A", imagen:'boca_e'
    },
    {
        id:3, nombre: "I", color:"#FFDC73", imagen:'boca_i'
    },
    {
        id:4, nombre: "O", color:"#655EE8", imagen:'boca_o'
    },
    {
        id:5, nombre: "U", color:"#D9454F", imagen:'boca_u'
    }
];

const instrucciones = 'A continuación se presentan las vocales para ser trabajadas junto al estudiante. Un primer objetivo es que el estudiante pueda descubrir a partir de la información dada, como debe colocar la boca para emitir el sonido correspondiente. Un segundo objetivo es que observen y detallen las diferencias en los trazos de las distintas vocales. El mediador interviene como guía para que el estudiante vaya descubriendo.';
const miniInstrucciones = ''

const Introduccion = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [abcFinal, setAbcFinal] = useState(abc)

    const minuscula = useSelector(state => state.configuracion.minuscula);
    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contador = useSelector(state => state.ejercicio.contador);
       
    useEffect(() => {
        if(minuscula){
            setAbcFinal(abcFinal =>
                abcFinal.map(abc => ({
                    ...abc,
                    nombre: abc.nombre.toLowerCase()
            })))
        } else {
            setAbcFinal(abcFinal =>
                abcFinal.map(abc => ({
                    ...abc,
                    nombre: abc.nombre.toUpperCase()
            })))
        }
    }, [minuscula])

    const play = (nombre) => {
        switch(nombre.toUpperCase()){
            case "A":
                playA();
                break;
            case "E":
                playE();
                break;
            case "I":
                playI();
                break;
            case "O":
                playO();
                break;
            case "U":
                playU();
                break;
            default:
        }
    }
   
    const playA = () => new Audio(`/sounds/a.mp3`).play();
    const playE = () => new Audio(`/sounds/e.mp3`).play();
    const playI = () => new Audio(`/sounds/i.mp3`).play();
    const playO = () => new Audio(`/sounds/o.mp3`).play();
    const playU = () => new Audio(`/sounds/u.mp3`).play();

    const terminar = (e) => {
        e.preventDefault();
        dispatch( finalizarEjercicioAction() );
    }

    const zeroContador = (e) => {
        e.preventDefault();
        dispatch ( zeroContadorAction() );
    }

    const setearContador = (e, numero) => {
        e.preventDefault();
        dispatch ( setearContadorAction(numero) );
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('Introduccion') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/letras/vocales'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(finalizar){
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar])
    
    
    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} />
            <Cuerpo>
            {iniciar
                ?
                    <Flex>
                        <Flex width="20%" justify="start">
                            <ContenedorLateral>
                                <BtnSound onClick={(e) => zeroContador(e)}> <img src={process.env.PUBLIC_URL + '/img/aeiou.png'} alt="" /> </BtnSound>
                                {abcFinal.map(letras => 
                                    <ListaLetras key={letras.id}>
                                        <BtnSound  onClick={(e) => setearContador(e, letras.id)}><LetraSmall style={{color:`${letras.color}`}}>{letras.nombre}</LetraSmall></BtnSound >
                                    </ListaLetras>
                                )}            
                            </ContenedorLateral>
                        </Flex>

                        {contador === 0 ?
                            <Flex width="80%">
                                <ContenedorLetras>
                                    <ListaLetras> 
                                        <Letra color="#23AAAF" margin="0">{abcFinal[0].nombre}</Letra>
                                            <BtnSound onClick={playA}>
                                                <ImgSound src={`/img/altavoz.png`} alt="" />
                                            </BtnSound>
                                    </ListaLetras>
                                    <ListaLetras>
                                        <Letra color="#FF9B2A" margin="0">{abcFinal[1].nombre}</Letra>
                                        <BtnSound onClick={playE}>
                                            <ImgSound src={`/img/altavoz.png`} alt="" />
                                        </BtnSound>
                                    </ListaLetras>
                                    <ListaLetras> 
                                        <Letra color="#FFDC73" margin="0">{abcFinal[2].nombre}</Letra>
                                        <BtnSound onClick={playI}>
                                            <ImgSound src={`/img/altavoz.png`} alt="" />
                                        </BtnSound>
                                    </ListaLetras>
                                    <ListaLetras> 
                                        <Letra color="#655EE8" margin="0">{abcFinal[3].nombre}</Letra>
                                        <BtnSound onClick={playO}>
                                            <ImgSound src={`/img/altavoz.png`} alt="" />
                                        </BtnSound>
                                    </ListaLetras>
                                    <ListaLetras> 
                                        <Letra color="#D9454F" margin="0">{abcFinal[4].nombre}</Letra>
                                        <BtnSound onClick={playU}>
                                            <ImgSound src={`/img/altavoz.png`} alt="" />
                                        </BtnSound>
                                    </ListaLetras>        
                                </ContenedorLetras>
                            </Flex>
                            :
                            <Flex width="80%" justify="space-around" align="center">
                                <Flex>
                                    <ul>
                                        {abcFinal.filter(key => key.id === contador).map(letras => 
                                            <ListaLetras key={letras.id}>
                                                <Letra style={{color:`${letras.color}`}} margin="0">{letras.nombre}</Letra>
                                                <BtnSound onClick={() => play(letras.nombre)}><ImgSound src={`/img/altavoz.png`} alt="" /></BtnSound>
                                            </ListaLetras>
                                        )}            
                                    </ul>
                                </Flex>
                                <Flex>
                                    <BocaImg src={`/img/${abcFinal[contador-1].imagen}.png`} alt="" />
                                </Flex>
                            </Flex>
                        }
                        <Flex width="20%">
                        </Flex>
                        <Icons bottom="25px" right="25px">
                            {contador === 0 ?
                                <BotoneraBottom aumentar={true} />
                                : contador !== 5 ?
                                <div>
                                    <BotoneraBottom aumentar={true} reducir={true} />
                                </div>
                                : 
                                <div>
                                    <BotoneraBottom reducir={true} finalizarEjercicio={true}/>
                                </div>
                            }
                        </Icons>
                    </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
            </Cuerpo>
        </Fragment>
    );
}
 
export default Introduccion;