import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction} from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

//css
import { SilabasGrandes, DivFlex, Img, BtnAsignar, FormInput, InputText, BtnAbecedario, BotoneraFixed, BtnAvanzar, BtnBasurero } from '../../Css/MetodosCSS/StyledLectoEscritura';
import useColorHelp from '../../../Hooks/useColorHelp';

const abc = [
    { id: '1', letra: 'a', estilo:false, bgcolor:'#23AAAF'}, { id: '2', letra: 'b', estilo:false, bgcolor:'#646464'} , { id: '3', letra: 'c', estilo:false, bgcolor:'#646464'} , { id: '4', letra: 'd', estilo:false, bgcolor:'#646464'} , { id: '5', letra: 'e', estilo:false, bgcolor:'#FF9B2A'} , { id: '6', letra: 'f', estilo:false, bgcolor:'#646464'} , { id: '7', letra: 'g', estilo:false, bgcolor:'#646464'} , { id: '8', letra: 'h', estilo:false, bgcolor:'#646464'} , { id: '9', letra: 'i', estilo:false, bgcolor:'#FFDC73'} , { id: '10', letra: 'j', estilo:false, bgcolor:'#646464'} , { id: '11', letra: 'k', estilo:false, bgcolor:'#646464'} , { id: '12', letra: 'l', estilo:false, bgcolor:'#646464'} , { id: '13', letra: 'm', estilo:false, bgcolor:'#646464'} , { id: '14', letra: 'n', estilo:false, bgcolor:'#646464'} , { id: '15', letra: 'ñ', estilo:false, bgcolor:'#646464'} , { id: '16', letra: 'o', estilo:false, bgcolor:'#655EE8'} , { id: '17', letra: 'p', estilo:false, bgcolor:'#646464'} , { id: '18', letra: 'q', estilo:false, bgcolor:'#646464'} , { id: '19', letra: 'r', estilo:false, bgcolor:'#646464'} , { id: '20', letra: 's', estilo:false, bgcolor:'#646464'} , { id: '21', letra: 't', estilo:false, bgcolor:'#646464'} , { id: '22', letra: 'u', estilo:false, bgcolor:'#D05F67'} , { id: '23', letra: 'v', estilo:false, bgcolor:'#646464'} , { id: '24', letra: 'w', estilo:false, bgcolor:'#646464'} , { id: '25', letra: 'x', estilo:false, bgcolor:'#646464'} , { id: '26', letra: 'y', estilo:false, bgcolor:'#646464'} , { id: '27', letra: 'z', estilo:false, bgcolor:'#646464'}, { id: '28', letra: 'ü', estilo:false}
]

const LN2Todos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, ejercicio, indexes, instrucciones, miniInstrucciones } = props;

    const [disable, setDisable] = useState(false);
    const [count, setCount] = useState(1);
    const [comparar, setComparar] = useState([]);
    const [abecedario, setAbecedario] = useState(abc);
    const [asignadoA, setAsignadoA] = useState('');
    const [asignadoB, setAsignadoB] = useState('');
    const [asignadoC, setAsignadoC] = useState('');
    const [palabra, setPalabra] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [letraColorUno, transformarColorUno] = useColorHelp('');
    const [letraColorDos, transformarColorDos] = useColorHelp('');
    const [letraColorTres, transformarColorTres] = useColorHelp('');
    const [letraColorCuatro, transformarColorCuatro] = useColorHelp('');
    const [letraColorCinco, transformarColorCinco] = useColorHelp('');
     
    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const letra = useSelector(state => state.ejercicio.letra);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignar = (e, ltr) => {
        e.preventDefault();
        if(!asignadoA) {
            setAsignadoA(ltr);
            transformarColorTres(ltr);
        } else if(!asignadoB) {
            setAsignadoB(ltr);
            transformarColorCuatro(ltr);
        } else {
            setAsignadoC(ltr);
            transformarColorCinco(ltr);
        }
    }

    const borrar = (e) => {
        e.preventDefault();
        setAsignadoA("");
        setAsignadoB("");
        setAsignadoC("");
        transformarColorTres('');
        transformarColorCuatro('');
        transformarColorCinco('');
    } 

    const verificar = (e) => {
        e.preventDefault();
        if(comparar.tipo === 'tres'){
            if (asignadoA === '' || asignadoB === '' || asignadoC === '' ||palabra === '') {
                return;
            }
            if (asignadoA.toUpperCase() === comparar.primera_letra.toUpperCase() && asignadoB.toUpperCase() === comparar.segunda_letra.toUpperCase() && asignadoC.toUpperCase() === comparar.tercera_letra.toUpperCase() && palabra.toUpperCase() === comparar.palabra.toUpperCase()) {
                console.log('bien');
                setDisable(true);
                setIsCorrect(true);
                GoodWork();
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                if (count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setCount(count + 1);
                        setAsignadoA('');
                        setAsignadoB('');
                        setAsignadoC('');
                        transformarColorTres('');
                        transformarColorCuatro('');
                        transformarColorCinco('');
                        setPalabra('');
                    }, 1500);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch(finalizarEjercicioAction())
                    }, 1500);
                }
            } else {
                console.log('mal')
                setDisable(true);
                setIsIncorrect(true);
                setContarErrores(contarErrores + 1);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setAsignadoA('');
                    setAsignadoB('');
                    setAsignadoC('');
                    transformarColorTres('');
                    transformarColorCuatro('');
                    transformarColorCinco('');
                    setPalabra('');
                }, 1500);
            }
        } else {
            if (asignadoA === '' || asignadoB === '' || palabra === '') {
                return;
            }
            if (asignadoA.toUpperCase() === comparar.primera_letra.toUpperCase() && asignadoB.toUpperCase() === comparar.segunda_letra.toUpperCase() && palabra.toUpperCase() === comparar.palabra.toUpperCase()) {
                console.log('bien');
                setDisable(true);
                setIsCorrect(true);
                GoodWork();
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                if (count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setCount(count + 1);
                        setAsignadoA('');
                        setAsignadoB('');
                        transformarColorTres('');
                        transformarColorCuatro('');
                        transformarColorCinco('');
                        setPalabra('');
                    }, 1500);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch(finalizarEjercicioAction())
                    }, 1500);
                }
            } else {
                console.log('mal')
                setDisable(true);
                setIsIncorrect(true);
                setContarErrores(contarErrores + 1);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setAsignadoA('');
                    setAsignadoB('');
                    setAsignadoC('');
                    transformarColorTres('');
                    transformarColorCuatro('');
                    transformarColorCinco('');
                    setPalabra('');
                }, 1500);
            }
        }
    }

    useEffect(() => {
        if(comparar === undefined || comparar.length === 0 ){
            return;
        }
        transformarColorUno(comparar.segunda_letra);
        transformarColorDos(comparar.tercera_letra);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, comparar, ayuda_lectura])

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        setComparar(data[count-1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, iniciar])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': ejercicio,
                'letra': letra,
                'errores': contarErrores,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);
      
    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <DivFlex height="80vh" direction='column' justify='center' align='center'>
                    <DivFlex width='100%' height="70vh" justify='space-around' align='center' >
                        <DivFlex direction='column' justify='center' align='center'>
                            <DivFlex transf='uppercase'>
                                <SilabasGrandes color="#646464" m='0px 0.5rem 0px 0px' $minMay={minuscula}>
                                    {letra.toLowerCase() === 'r_suave' || letra.toLowerCase() === 'c' ? 
                                    <i>{comparar.silaba}</i>
                                    :
                                    comparar.silaba
                                    }
                                </SilabasGrandes>
                                <SilabasGrandes $minMay={minuscula}>
                                    {letraColorDos.length === 0 ? null :
                                    comparar.tipo === "tres"
                                    ?
                                    letraColorDos.map((item, i) =>
                                        <span
                                            key={i}
                                        >
                                            {item.map((itemInterior, i) =>
                                                <span
                                                key={i}
                                            >
                                                    <span style={{color: itemInterior.color}}
                                                    key={i}
                                                    >
                                                        {itemInterior.letra}
                                                    </span>
                                            </span>
                                            )}
                                        </span>
                                    )
                                    :
                                    letraColorUno.map((item, i) =>
                                        <span
                                            key={i}
                                        >
                                            {item.map((itemInterior, i) =>
                                                <span
                                                key={i}
                                            >
                                                <span style={{color: itemInterior.color}}
                                                    key={i}
                                                    >
                                                        {itemInterior.letra}
                                                    </span>
                                            </span>
                                            )}
                                        </span>
                                    )
                                    }
                                </SilabasGrandes>
                            </DivFlex>
                            <DivFlex width='100%' justify='space-around' align='center'>
                                    <Img w="13vw" wm='12vw' wt='9vw' wd='8vw' wlg='7vw'  h="18vh" src={`/img/${comparar.imagen_1}.png`} />
                                    <Img w="13vw" wm='12vw' wt='9vw' wd='8vw' wlg='7vw'  h="9vh" src={`/img/${comparar.imagen_2}.png`} />
                            </DivFlex>
                        </DivFlex>
                        <DivFlex display="flex" direction="column" justify='center' align='center'
                            isCorrect={isCorrect}
                            isIncorrect={isIncorrect}
                        >
                            <DivFlex>
                                <BtnAsignar display="flex" align="center" justify="center" $minMay={minuscula} color={'#646464'} font="4rem" m='0px 0.5rem 5vh 0px'>
                                    {letraColorTres.length === 0 ? null :
                                    letraColorTres.map((item, i) =>
                                    <span
                                        key={i}
                                    >
                                        {item.map((itemInterior, i) =>
                                            <span
                                            key={i}
                                        >
                                                <span style={{color: itemInterior.color}}
                                                key={i}
                                                >
                                                    {itemInterior.letra}
                                                </span>
                                        </span>
                                        )}
                                    </span>
                                    )}
                                </BtnAsignar>
                                <BtnAsignar display="flex" align="center" justify="center" $minMay={minuscula}>
                                    {letraColorCuatro.length === 0 ? null :
                                    letraColorCuatro.map((item, i) =>
                                    <span
                                        key={i}
                                    >
                                        {item.map((itemInterior, i) =>
                                            <span
                                            key={i}
                                        >
                                                <span style={{color: itemInterior.color}}
                                                key={i}
                                                >
                                                    {itemInterior.letra}
                                                </span>
                                        </span>
                                        )}
                                    </span>
                                    )}
                                </BtnAsignar>
                                {comparar.tipo === "tres"
                                ?
                                <BtnAsignar display="flex" align="center" justify="center" $minMay={minuscula}>
                                    {letraColorCinco.length === 0 ? null :
                                    letraColorCinco.map((item, i) =>
                                    <span
                                        key={i}
                                    >
                                        {item.map((itemInterior, i) =>
                                            <span
                                            key={i}
                                        >
                                                <span style={{color: itemInterior.color}}
                                                key={i}
                                                >
                                                    {itemInterior.letra}
                                                </span>
                                        </span>
                                        )}
                                    </span>
                                    )}
                                </BtnAsignar>
                                :
                                null
                                }
                                <DivFlex>
                                    <BtnBasurero bg="transparent" border="none"
                                        disabled={disable}
                                        onClick={(e) => borrar(e)}
                                    >
                                        <Img src={`/img/icono_basurero.png`} />
                                    </BtnBasurero>
                                </DivFlex>
                            </DivFlex>
                            <DivFlex justify='center' align='center'>
                                <FormInput onSubmit={(e) => verificar(e)}>
                                    <InputText $minMay={minuscula} color='#646464' type="text" value={palabra} onChange={(e) => setPalabra(e.target.value)} disabled={disable} 
                                    fontm="2rem" widthm="5rem"
                                    fontt="4rem" widtht="10rem"
                                    fontd="4rem" widthd="10rem"
                                    fontlg="6rem" widthlg="16rem"
                                    fontxlg="8rem" widthxlg="20rem"
                                    onKeyDown={(e) => e.key === 'Enter' ? verificar(e) : null} 
                                    />
                                </FormInput>
                            </DivFlex>
                        </DivFlex>
                    </DivFlex>


                    <DivFlex wrap='wrap' height="10vh" justify='center' align='center'>
                        {comparar.silaba === 'gü'
                        ?
                            abecedario.map(item => (
                                <BtnAbecedario  $minMay={minuscula} style={ayuda_lectura ? {color:`${item.bgcolor}`} : null}
                                    disabled={disable}
                                    key={item.id}
                                    onClick={(e) => asignar(e, item.letra)}
                                >
                                    {item.letra}
                                </BtnAbecedario>
                            ))
                        :
                            abecedario.filter(item => item.id !== '28').map(item => (
                                <BtnAbecedario style={ayuda_lectura ? {color:`${item.bgcolor}`} : null} $minMay={minuscula}
                                    disabled={disable}
                                    key={item.id}
                                    onClick={(e) => asignar(e, item.letra)}
                                >
                                    {item.letra}
                                </BtnAbecedario>
                            ))
                        }
                    </DivFlex>

                    <BotoneraFixed position='fixed' right='0' bottom='0'>
                        <BtnAvanzar
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <img alt='' src={`/img/icono_avanzar_ninos.png`} />
                        </BtnAvanzar>
                    </BotoneraFixed>
                </DivFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN2Todos;