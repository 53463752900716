import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';
//CAMI

import { Flex, LinksSubNivs, ImgSubNivs, DesSubNivs } from '../../../Css/StyledMatematica';
import { HrMetodos } from '../../../Css/StyledHeader';


const Nivel3 = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"subitizacion/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"SUBITIZACION"} componente={"NIVEL 3"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex wrap='wrap' width='70vw' margin='12vh 15vw 0px 15vw' mt='8.5vh 15vw 0px 15vw' md='7vh 15vw 0px 15vw' mlg='6.8vh 15vw 0px 15vw'>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-3/M11`}><ImgSubNivs src={'/img/portadas/matematica11.jpg'} /><DesSubNivs margin="1rem 0 0 0">puntos color</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-3/M12`}><ImgSubNivs src={'/img/portadas/matematica12.jpg'} /><DesSubNivs margin="1rem 0 0 0">¿Qué grupo tiene más?</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-3/M13`}><ImgSubNivs src={'/img/portadas/matematica13.jpg'} /><DesSubNivs margin="1rem 0 0 0">noción cantidad</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-3/M14`}><ImgSubNivs src={'/img/portadas/matematica14.jpg'} /><DesSubNivs margin="1rem 0 0 0">cálculos</DesSubNivs></LinksSubNivs>
            </Flex>
        </Fragment>    
    );
}
 
export default Nivel3;