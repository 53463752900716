import React, {Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Practica20 from '../Practica20';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'A continuación se presentan números diferenciados por su valor posicional. El niño deberá leer en voz alta dichos números. Si necesita ayuda se puede hacer click sobre el recuadro donde presenta dificultad. Asimismo se puede hacer click sobre el ícono de ayuda.';
const miniInstrucciones = 'Lee los números.';

const M20Bis = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setearEjercicioAction('M20Bis') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
            <Practica20 ejercicio="M20Bis" miniInstrucciones={"EXPRESEAR EL NÚMERO DE MANERA ORAL"} />
        </Fragment>
    );
}
 
export default M20Bis;