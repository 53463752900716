import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import useConfigAuth from '../../Hooks/useConfigAuth';
import useSelectDropwdown from '../../Hooks/useSelectDropwdown';
import { paisData, provinciaData, departamentoData, localidadData, nivelEstudiosData, tipoProfesionalData, ocupacionProfesionalData, generoData } from '../../Hooks/dropdowns';

// css
import {CuerpoCompleto,Icon, Flex, FlexBox, FlexColumn} from '../Css/StyledHeader';
import {FormularioPerfil, ContenedorPerfil, Titulo, BotonEditar, IconoEditar, ContenedorBotonEditar, BotonEditarSinLinea, 
CompletarSmall, ItemSmall, CambiarContraseña, Input, Label, Dato, Item, BotonGuardar,Completar, Aclaracion, Fondo} from '../Css/StyledProfile';


import { almacenarNombreApellidoAction, almacenarEmailAction, almacenarRestoAction, almacenarContraseniaAction } from '../../actions/almacenarPerfilActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';
import { solicitoPerfilProfesionalAction, solicitoTokenPerfilProfesionalAction } from '../../actions/cargarPerfilActions';


const AdministrarPerfil = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [incompletoNombreApellidoProfesional, setIncompletoNombreApellidoProfesional] = useState(false);
    const [incompletoEmailProfesional, setIncompletoEmailProfesional] = useState(false);
    const [incompletoPassword, setIncompletoPassword] = useState(false);
    const [incompletoPais, setIncompletoPais] = useState(false);
    const [incompletoProvincia, setIncompletoProvincia] = useState(false);
    const [incompletoDepartamento, setIncompletoDepartamento] = useState(false);
    const [incompletoLocalidad, setIncompletoLocalidad] = useState(false);
    const [incompletoFechaNacimientoProfesional, setIncompletoFechaNacimientoProfesional] = useState(false);
    const [incompletoProfesionProfesional, setIncompletoProfesionProfesional] = useState(false);

    const [openNombreApellido, setOpenNombreApellido] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openResto, setOpenResto] = useState(false);
    const [openContrasenia, setOpenContrasenia] = useState(false);
    const [cambiarEmail, setCambiarEmail] = useState(false);
    const [config] = useConfigAuth();

    // Vicky cambiar por redux store
    
    const cargando = useSelector( (state) => state.estado.loading);
    const error = useSelector ( (state) => state.estado.error);
    const alerta = useSelector ( (state) => state.alertas.alerta);
    const estado = useSelector ( (state) => state.estado.correcto);
    const {nombre, apellido, email, genero, fecha_nacimiento, nivel_estudios, ocupacion, matricula, profesion, otroProfesion, pais, provincia, departamento, localidad} = useSelector ( (state) => state.usuario.perfil);

    const nombreProfesional = useRef();
    const apellidoProfesional = useRef();
    const emailProfesional = useRef();
    const tokenRecupero = useRef();
    const password = useRef();
    const passwordNueva = useRef();
    const verificarPasswordNueva = useRef();
    const fechaNacimientoProfesional = useRef();
    const matriculaProfesional = useRef();
    const otroProfesionProfesional = useRef();
    const [paisProfesional, SelectDropdownPais] = useSelectDropwdown('Pais', paisData, pais, false);
    const [provinciaProfesional, SelectDropdownProvincia] = useSelectDropwdown('Provincia', provinciaData, provincia, true, paisProfesional);
    const [departamentoProfesional, SelectDropdownDepartamento] = useSelectDropwdown('Departamento', departamentoData, departamento, true, provinciaProfesional);
    const [localidadProfesional, SelectDropdownLocalidad] = useSelectDropwdown('Localidad', localidadData, localidad, true, departamentoProfesional);
    const [nivelEstudiosProfesional, SelectDropdownNivelEstudios] = useSelectDropwdown('Nivel de Estudios', nivelEstudiosData, nivel_estudios);
    const [profesionProfesional, SelectDropdownProfesion] = useSelectDropwdown('Profesion', tipoProfesionalData, profesion, false);
    const [ocupacionProfesional, SelectDropdownOcupacion] = useSelectDropwdown('Ocupacion', ocupacionProfesionalData, ocupacion, false);
    const [generoProfesional, SelectDropdownGenero] = useSelectDropwdown('Genero', generoData, genero, false);

    const guardarNombreApellidoProfesional = (e) => {
        e.preventDefault();
        if(nombreProfesional.current.value.trim() === '' || apellidoProfesional.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoNombreApellidoProfesional(true);
        }
        dispatch( ocultarAlertaAction());
        setOpenNombreApellido(false);
        const informacion = {
            payload: {'nombre': nombreProfesional.current.value, 'apellido': apellidoProfesional.current.value}, 
            config
        };
        dispatch( almacenarNombreApellidoAction(informacion) );
    }

    const guardarEmailProfesional = (e) => {
        e.preventDefault();
        if(emailProfesional.current.value.trim() === '' || tokenRecupero.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoEmailProfesional(true);
        }
        dispatch( ocultarAlertaAction());
        setOpenEmail(false);
        const informacion = {
            payload: {'email': emailProfesional.current.value, 'codigo': tokenRecupero.current.value}, 
            config
        };
        setCambiarEmail(false);
        dispatch( almacenarEmailAction(informacion) );
    }

    const guardarPassword = (e) => {
        e.preventDefault();
        if(passwordNueva.current.value !== verificarPasswordNueva.current.value) {
            const alerta = {
                msg: 'Las contraseñas deben coincidir'
            }
            dispatch ( setearAlerta(alerta) );
            setIncompletoPassword(true);
            return;
        }
        if(passwordNueva.current.value.length < 8) {
            const alerta = {
                msg: 'La contraseña debe contener al menos 8 caracteres'
            }
            dispatch ( setearAlerta(alerta) );
            setIncompletoPassword(true);
            return;
        }
        if(passwordNueva.current.value.length > 15) {
            const alerta = {
                msg: 'La contraseña debe contener como mucho 15 caracteres'
            }
            dispatch ( setearAlerta(alerta) );
            setIncompletoPassword(true);
            return;
        }
        const minuscula = new RegExp("(?=.*[a-z])");
        if(!minuscula.test(passwordNueva.current.value)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos una minúscula'
            }
            dispatch ( setearAlerta(alerta) );
            setIncompletoPassword(true);
            return;
        }
        const mayuscula = new RegExp("(?=.*[A-Z])");
        if(!mayuscula.test(passwordNueva.current.value)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos una mayúscula'
            }
            dispatch ( setearAlerta(alerta) );
            setIncompletoPassword(true);
            return;
        }
        const numero = new RegExp("(?=.*[0-9])");
        if(!numero.test(passwordNueva.current.value)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos un número'
            }
            dispatch ( setearAlerta(alerta) );
            setIncompletoPassword(true);
            return;
        }
        setOpenContrasenia(false);
        const informacion = {
            payload: {'password': password.current.value, 'passwordNueva': passwordNueva.current.value}, 
            config
        };
        dispatch( almacenarContraseniaAction(informacion) );
    }

    const guardarResto = (e) => {
        e.preventDefault();
        if(fechaNacimientoProfesional.current.value.trim() === ''){setIncompletoFechaNacimientoProfesional(true)};
        if(paisProfesional.trim() === ''){setIncompletoPais(true)};
        if(provinciaProfesional.trim() === ''){setIncompletoProvincia(true)};
        if(departamentoProfesional.trim() === ''){setIncompletoDepartamento(true)};
        if(localidadProfesional.trim() === ''){setIncompletoLocalidad(true)};
        if(profesionProfesional.trim() === ''){setIncompletoProfesionProfesional(true)};

        if(fechaNacimientoProfesional.current.value.trim() === '' || paisProfesional.trim() === '' || provinciaProfesional.trim() === '' || localidadProfesional.trim() === '' || departamentoProfesional.trim() === '' || profesionProfesional.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
        }
        dispatch( ocultarAlertaAction());
        setOpenResto(false);
        const informacion = {
            payload: {
                'profesion': profesionProfesional, 
                'otroProfesion': otroProfesionProfesional.current.value,
                'pais': paisProfesional, 
                'provincia': provinciaProfesional, 
                'departamento': departamentoProfesional, 
                'localidad': localidadProfesional, 
                'genero': generoProfesional, 
                'fecha_nacimiento': fechaNacimientoProfesional.current.value, 
                'nivel_estudios': nivelEstudiosProfesional, 
                'ocupacion': ocupacionProfesional, 
                'matricula': matriculaProfesional.current.value}, 
            config
        };
        dispatch( almacenarRestoAction(informacion) );
    }

    const enviarCambiarEmail = (e) => {
        e.preventDefault();
        setCambiarEmail(true);
        const informacion = {
            config
        };
        dispatch( solicitoTokenPerfilProfesionalAction(informacion) );
    }

    const habilitarIngresoCodigo = (e) => {
        e.preventDefault();
        setCambiarEmail(true);
    }

    useEffect(() => {
        if(!estado){return};
        if(estado.msg === 'Actualizado') {
            const informacion = {
                config
            };
            dispatch( solicitoPerfilProfesionalAction(informacion) )
            navigate('/escritorio');
        } else {
            const alerta = {msg: `${estado.msg}`}
            dispatch( setearAlerta (alerta) );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estado])

    useEffect(() => {
        if(alerta) {
            setTimeout(() => {
                dispatch (ocultarAlertaAction());    
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alerta])

    return (
        <CuerpoCompleto height="200vh">
            <HeaderUno />
            <HeaderDos link={"/escritorio"} componente={"Perfil"} paciente={true} />
            <ContenedorPerfil>
            {cargando ? <p>Cargando...</p> : null }
            {error ? <p> {error.msg} </p>: null }
            {alerta ? <p>{alerta.msg}</p> : null }
                <FormularioPerfil ancho="100vh">
                    <Fondo ancho="80vw">
                        <Titulo>Datos Personales</Titulo>
                        {!openNombreApellido ?
                            <Fragment>
                                <Flex>
                                    <Item> <Dato margin= "0px 0px 5px 0px">Nombre </Dato><br/> {nombre}</Item>
                                    <Item> <Dato margin= "0px 0px 5px 0px">Apellido </Dato><br/> {apellido}</Item>
                                </Flex>
                                <ContenedorBotonEditar>
                                    <BotonEditar onClick={() => setOpenNombreApellido(true)}>
                                        < IconoEditar src={`/img/editar.svg`} alt="Botón editar" />
                                    </BotonEditar>
                                </ContenedorBotonEditar>
                            </Fragment>
                        :   
                            <Fragment>
                                <Flex>
                                    <Completar>
                                        <FlexColumn>
                                            <Label>Nombre</Label>
                                            <Input
                                            disabled={!openNombreApellido}
                                            type="text"
                                            id="nombreProfesional"
                                            name="nombreProfesional"
                                            ref={nombreProfesional}
                                            defaultValue={nombre}
                                            />
                                        </FlexColumn>
                                    </Completar>
                                    <Completar>
                                        <FlexColumn>
                                            <Label>Apellido</Label>
                                            <Input
                                            disabled={!openNombreApellido}
                                            type="text"
                                            id="apellidoProfesional"
                                            name="apellidoProfesional"
                                            ref={apellidoProfesional}
                                            defaultValue={apellido}
                                            />
                                        </FlexColumn>
                                    </Completar>
                                </Flex>
                                <FlexBox justify= "center">
                                        <Aclaracion>Recuerde que si hace algún cambio no podrá volver a hacerlo por 90 días</Aclaracion>
                                </FlexBox>
                                <FlexBox justify= "flex-end">
                                    <BotonGuardar margin="0px 10px 0px 0px"
                                        onClick={(e) => guardarNombreApellidoProfesional(e)}
                                    >
                                        Guardar Cambios
                                    </BotonGuardar>
                                    <BotonGuardar onClick={() => setOpenNombreApellido(false)}>
                                        Volver a perfil sin grabar
                                    </BotonGuardar>
                                </FlexBox>
                            </Fragment>
                        }
                        {!incompletoNombreApellidoProfesional ? null : <div>X</div>}
                        {!openEmail ?
                            <Fragment>
                                <Item> <Dato margin= "0px 0px 5px 0px">Email </Dato><br/> {email}</Item>
                                <ContenedorBotonEditar>
                                    <BotonEditar onClick={() => setOpenEmail(true)}>
                                        < IconoEditar src={`/img/editar.svg`} alt="Botón editar" />
                                    </BotonEditar>
                                </ContenedorBotonEditar>
                            </Fragment>
                        :   
                            <>
                            {cambiarEmail 
                            ?
                                <>
                                    <Completar>
                                        <FlexColumn>
                                            <Label>Email</Label>
                                            <Input
                                            disabled={!openEmail}
                                            type="email"
                                            id="emailProfesional"
                                            name="emailProfesional"
                                            placeholder='Nuevo Email'
                                            ref={emailProfesional}
                                            defaultValue={email}
                                            />
                                        </FlexColumn>
                                    </Completar>
                                    <Completar>
                                        
                                        <FlexColumn>
                                            <Label font="12px">Se ha enviado un email a su correo con un código que deberá pegar a continuación</Label>
                                            <Input
                                            disabled={!openEmail}
                                            type="text"
                                            id="tokenRecupero"
                                            name="tokenRecupero"
                                            placeholder="Código"
                                            ref={tokenRecupero}
                                            />
                                        </FlexColumn>
                                    </Completar>
                                    <FlexBox justify= "center">
                                        <Aclaracion>Recuerde que si hace algún cambio no podrá volver a hacerlo por 90 días</Aclaracion>
                                    </FlexBox>
                                    <FlexBox justify= "flex-end">
                                        <BotonGuardar margin="0px 10px 0px 0px"
                                            onClick={(e) => guardarEmailProfesional(e)}
                                        >
                                            Guardar Cambios
                                        </BotonGuardar>
                                        <BotonGuardar onClick={() => setOpenEmail(false)} >
                                            Volver a perfil sin grabar
                                        </BotonGuardar>       
                                    </FlexBox>
                                </>
                            :
                                <>
                                    <button
                                        onClick={(e) => enviarCambiarEmail(e)}
                                    >
                                        Enviar código
                                    </button>
                                    <button
                                        onClick={(e) => habilitarIngresoCodigo(e)}
                                    >
                                        Ya solicité código
                                    </button>
                                </>
                            }
                            </>
                        }
                        {!incompletoEmailProfesional ? null : <div>X</div>}
                        {!openContrasenia ?
                            <Fragment>
                                <CambiarContraseña onClick={() => setOpenContrasenia(true)}>
                                    Cambiar Contraseña
                                </CambiarContraseña>
                            </Fragment>
                        :   
                            <Fragment>
                                <Completar>
                                    <FlexColumn>
                                        <Label>Contraseña Anterior</Label>
                                        <Input
                                        disabled={!openContrasenia}
                                        type="password"
                                        name="password"
                                        placeholder="Password anterior"
                                        ref={password}
                                        />
                                    </FlexColumn>
                                </Completar>
                                <Completar>
                                    <FlexColumn>
                                        <Label>Contraseña Nueva</Label>
                                        <Input
                                        disabled={!openContrasenia}
                                        type="password"
                                        name="passwordNueva"
                                        placeholder="Password nueva"
                                        ref={passwordNueva}
                                        />
                                    </FlexColumn>
                                </Completar>
                                <Completar>
                                    <FlexColumn>
                                        <Label>Repita Contraseña Nueva</Label>
                                        <Input
                                        disabled={!openContrasenia}
                                        type="password"
                                        name="verificarPasswordNueva"
                                        placeholder="Repita password nueva"
                                        ref={verificarPasswordNueva}
                                        />
                                    </FlexColumn>
                                </Completar>
                                <FlexBox justify= "flex-end">
                                    <BotonGuardar margin="0px 10px 0px 0px"
                                        onClick={(e) => guardarPassword(e)}
                                    >
                                        Guardar Cambios
                                    </BotonGuardar>
                                    <BotonGuardar onClick={() => setOpenContrasenia(false)}>
                                        Volver a perfil sin grabar
                                    </BotonGuardar>
                                </FlexBox>
                            </Fragment>
                        }
                        {!incompletoPassword ? null : <div>X</div>}
                        {!openResto ?
                            <Fragment>
                                <Flex>
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">Género</Dato> <br/><SelectDropdownGenero disable={true} /></ItemSmall>
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">Fecha Nacimiento </Dato> <br/> {fecha_nacimiento}</ItemSmall>
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">País </Dato> <br/> <SelectDropdownPais disable={true} incompleto={incompletoPais} /></ItemSmall>
                                </Flex>
                                <Flex>
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">Provincia </Dato> <br/><SelectDropdownProvincia disable={true} incompleto={incompletoProvincia} /></ItemSmall>
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">Departamento </Dato> <br/><SelectDropdownDepartamento disable={true} incompleto={incompletoDepartamento} /></ItemSmall>
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">Localidad </Dato> <br/><SelectDropdownLocalidad disable={true} incompleto={incompletoLocalidad} /></ItemSmall>
                                </Flex>
                                <Flex>
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">Nivel de estudios </Dato> <br/><SelectDropdownNivelEstudios disable={true} /></ItemSmall>
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">Profesión </Dato> <br/><SelectDropdownProfesion disable={true} incompleto={incompletoProfesionProfesional} /></ItemSmall>
                                    {profesion == '99' ? <div>{otroProfesion}</div> : null}
                                    <ItemSmall><Dato margin= "0px 0px 5px 0px">Ocupación </Dato> <br/><SelectDropdownOcupacion disable={true} /></ItemSmall>
                                </Flex>
                                <ItemSmall><Dato margin= "0px 0px 5px 0px">Matrícula </Dato> <br/> {matricula}</ItemSmall>

                                <ContenedorBotonEditar>
                                    <BotonEditarSinLinea onClick={() => setOpenResto(true)} margin="0px 0px 20px 0px">
                                        < IconoEditar src={`/img/editar.svg`} alt="Botón editar" />
                                    </BotonEditarSinLinea>
                                </ContenedorBotonEditar>
                            </Fragment>
                        :   
                            <Fragment>
                                <Flex>
                                    <CompletarSmall>
                                        <FlexColumn>
                                        <ItemSmall><Dato margin= "0px 0px 5px 0px">Genero </Dato> <br/><SelectDropdownGenero /></ItemSmall>
                                        </FlexColumn>
                                    </CompletarSmall>
                                    <Flex>
                                        <ItemSmall><Dato margin= "0px 0px 5px 0px">País </Dato> <br/> <SelectDropdownPais incompleto={incompletoPais} /></ItemSmall>
                                        <ItemSmall><Dato margin= "0px 0px 5px 0px">Provincia </Dato> <br/><SelectDropdownProvincia incompleto={incompletoProvincia} /></ItemSmall>
                                        <ItemSmall><Dato margin= "0px 0px 5px 0px">Departamento </Dato> <br/><SelectDropdownDepartamento incompleto={incompletoDepartamento} /></ItemSmall>
                                        <ItemSmall><Dato margin= "0px 0px 5px 0px">Localidad </Dato> <br/><SelectDropdownLocalidad incompleto={incompletoLocalidad} /></ItemSmall>
                                    </Flex>
                                    <CompletarSmall>
                                        <FlexColumn>
                                            <Label htmlFor="fechaNacimiento">Fecha de Nacimiento</Label>
                                            <Input
                                                type="date"
                                                id="fechaNacimiento"
                                                name="fechaNacimiento"
                                                ref={fechaNacimientoProfesional}
                                                defaultValue={fecha_nacimiento}
                                            />
                                            {!incompletoFechaNacimientoProfesional ? null : <div>X</div>}
                                        </FlexColumn>
                                    </CompletarSmall>
                                    <CompletarSmall>
                                        <FlexColumn>
                                            <SelectDropdownNivelEstudios />
                                        </FlexColumn>
                                    </CompletarSmall>    
                                </Flex>
                                <Flex>   
                                <CompletarSmall>
                                    <FlexColumn>
                                        <SelectDropdownOcupacion />
                                    </FlexColumn>
                                </CompletarSmall>
                                <CompletarSmall>
                                    <FlexColumn>
                                        <Label htmlFor="matricula">Matrícula</Label>
                                        <Input
                                            type="text"
                                            id="matriculaProfesional"
                                            name="matriculaProfesional"
                                            ref={matriculaProfesional}
                                            defaultValue={matricula}
                                        />
                                    </FlexColumn>
                                </CompletarSmall>
                                </Flex> 
                                <Flex> 
                                    <SelectDropdownProfesion incompleto={incompletoProfesionProfesional} />
                                    {profesion == '99' ?
                                        <Input
                                        type="text"
                                        id="otroProfesional"
                                        name="otroProfesional"
                                        ref={otroProfesionProfesional}
                                        defaultValue={otroProfesion}
                                        />
                                    :
                                        null
                                    }
                                </Flex>
                                <FlexBox justify= "flex-end">
                                    <BotonGuardar margin="0px 10px 0px 0px"
                                        onClick={(e) => guardarResto(e)}
                                    >
                                        Guardar Cambios
                                    </BotonGuardar>
                                    <BotonGuardar margin="20px 0px 0px 0px" onClick={() => setOpenResto(false)}>
                                        Volver a perfil sin grabar
                                    </BotonGuardar>
                                </FlexBox>
                            </Fragment>
                        }
                    </Fondo>
                </FormularioPerfil>
            </ContenedorPerfil>
        </CuerpoCompleto>
    );
}
 
export default AdministrarPerfil;