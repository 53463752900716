import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, CirculoMasMenos, ImgB10, PM39, InputCorto, InputLargo, LateralM39, AvanzarImg, AvanzarBtn } from '../../../../Css/StyledMatematica';

const instrucciones = '';
const miniInstrucciones = 'Construye el nombre';

const M39 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState(0);
    const [texto, setTexto] = useState('');
    const [agregoCirculo, setAgregoCirculo] = useState([]);
    const [agregoCuadrado, setAgregoCuadrado] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const quitarCirculo = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(agregoCirculo));
        if(agregoCirculo.length < 1 ){
            return;
        } else {
            newList.pop();
            setAgregoCirculo(newList);
        }
    }

    const agregarCirculo = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(agregoCirculo));
        if(agregoCirculo.length > 9 ){
            return;
        } else {
            newList.push('circulo');
            setAgregoCirculo(newList);
        }
    }

    const quitarCuadrado = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(agregoCuadrado));    
        if(agregoCuadrado.length < 1 ){
            return;
        } else {
            newList.pop();
            setAgregoCuadrado(newList);
        }
    }

    const agregarCuadrado = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(agregoCuadrado));

        if(agregoCuadrado.length > 9 ){
            return;
        } else {
            
            newList.push('cuadrado');
            setAgregoCuadrado(newList);
        }
    }

    const verificar = (e) => {
        e.preventDefault();
        if(numero.toString() !== comparar[count-1].cantidad_1.toString() || texto.toUpperCase() !== comparar[count-1].palabra.toUpperCase() || agregoCirculo.length !== comparar[count-1].cantidad_1 || agregoCuadrado.length !== comparar[count-1].cantidad_1) {
            console.log("mal");
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numero.toString() !== comparar[count-1].cantidad_1.toString()){
                    setNumero('');
                }
                if(texto.toUpperCase() !== comparar[count-1].palabra.toUpperCase()){
                    setTexto('')
                }
                if(agregoCirculo.length !== comparar[count-1].cantidad_1){
                    setAgregoCirculo([]);
                }
                if(agregoCuadrado.length !== comparar[count-1].cantidad_1){
                    setAgregoCuadrado([]);
                }
            }, 300);
        } else {
            console.log("bien");
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setNumero('');
                    setTexto('');
                    setAgregoCirculo([]);
                    setAgregoCuadrado([]);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setTexto('');
                    setAgregoCirculo([]);
                    setAgregoCuadrado([]);                     
                }, 1500);
            }
        }
        
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('M39') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/ejercitacion-1-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(informacion));
        setComparar(newList);
        setIndexes(Object.keys(informacion).length);
    }, [informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M39",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'erroresOrden': erroresOrden,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <FlexFull direction="column" height="100%" width="100%" align="center">
                    <FlexFull direction='column' margin="auto" width="100%" justify='space-evenly' align='center'
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        {comparar[count-1] === undefined ? null :
                        <FlexFull width='fit-content' justify='center' align='center' margin='0px 0px -7vh 0px'>
                            <ImgB10 src={`/img/${comparar[count-1].imagen_url_1}.png`} style={{zIndex: "0"}} w='17vw' wm='13vw' wt='10vw' wd='8.5vw' wlg='8vw' m='0px 1vw 0px 0px' bradius='10px' />
                            <ImgB10 src={`/img/${comparar[count-1].imagen_url_2}.png`} style={{zIndex: "0"}} w='17vw' wm='13vw' wt='10vw' wd='8.5vw' wlg='8vw' m='0px 0px 0px 1vw' bradius='10px' />
                        </FlexFull>
                        }
                        <FlexFull width='96vw' justify='space-around' align='end'>
                            {/* ESCRIBELO */}
                            <FlexFull direction='column' height='37vh' justify='space-between' align='start'>
                                <PM39>
                                    ESCRÍBELO
                                </PM39>
                                <FlexFull justify='center' align='center' margin='0px 0px 5vh 0px'>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <InputCorto type="text" min="1" max="9" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                                    </form>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <InputLargo type="text" value={texto} onChange={(e) => setTexto(e.target.value)} disabled={disable} />
                                    </form>
                                </FlexFull>
                            </FlexFull>
                            {/* FIN ESCRIBELO */}
                            {/* CONSTRUYELO */}
                            <FlexFull direction='column' height='37vh' justify='space-between' align='start'>
                                <PM39>
                                    CONSTRÚYELO
                                </PM39>
                                <FlexFull direction='column' justify='center' align='start'>
                                    <FlexFull direction='column' justify='center' align='center'>
                                        <Flex color='#FCF7BC' bradius='15px'>
                                            <FlexFull color='#FFFFFF' wrap='wrap' margin='0.5vw' bradius='15px' width='26vw' wm='24vw' wt='22vw' wd='21vw' wlg='21vw' height='9vh' hm='12vh' ht='15vh' hd='16vh' hlg='18vh' justify='start' align='start' padding='2vh 0px 0px 0px'>
                                            {agregoCirculo.map((item,i) => 
                                                <div key={i}>
                                                    {/* {item}   */}
                                                    <ImgB10 src={`/img/BolaCeleste.png`} style={{zIndex: "0"}} w='4.3vw' wm='4vw' wt='3.8vw' wd='3.6vw' wlg='3.3vw' m='0vh 0.3vw 0px 0.3vw' bradius='10px' />  
                                                </div>    
                                            )}
                                            </FlexFull>
                                        </Flex>
                                        <Flex>
                                            <CirculoMasMenos
                                                disabled={disable}
                                                onClick={(e) => quitarCirculo(e)}
                                            >
                                                -
                                            </CirculoMasMenos>
                                            <CirculoMasMenos
                                                disabled={disable}
                                                onClick={(e) => agregarCirculo(e)}
                                            >
                                                +
                                            </CirculoMasMenos>
                                        </Flex>
                                    </FlexFull>
                                </FlexFull>
                            </FlexFull>
                            {/* FIN CONSTRUYELO */}
                            {/* CUBOS */}
                            <FlexFull direction='column' justify='center' align='center'>
                                <LateralM39>
                                    <FlexFull direction='column' justify='center' align='center'>
                                    {agregoCuadrado.map((item,i) => 
                                        <div key={i}>
                                            {/* {item} */}
                                            <ImgB10 src={`/img/bloquenaranja.png`} style={{zIndex: "0"}} w='6vw' wm='4.3vw' wt='3.6vw' wd='3.2vw' wlg='2.9vw' m='-1vh 0px 0px 0px' bradius='10px' />
                                        </div>    
                                    )}
                                    </FlexFull>
                                </LateralM39>
                                <Flex>
                                    <CirculoMasMenos
                                        disabled={disable}
                                        onClick={(e) => quitarCuadrado(e)}
                                    >
                                        -
                                    </CirculoMasMenos>
                                    <CirculoMasMenos
                                        disabled={disable}
                                        onClick={(e) => agregarCuadrado(e)}
                                    >
                                        +
                                    </CirculoMasMenos>
                                </Flex>
                            </FlexFull>
                            {/* FIN CUBOS */}
                        </FlexFull>
                    </FlexFull>

                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </FlexFull>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M39;