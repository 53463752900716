import { combineReducers } from 'redux';
import usuariosReducer from './usuariosReducer';
import alertaReducer from './alertaReducer';
import solicitoInformacionReducer from './solicitoInformacionReducer';
import autenticarReducer from './autenticarReducer';
import cuentaReducer from './cuentaReducer';
import estadoReducer from './estadoReducer';
import configuracionReducer from './configuracionReducer';
import ejercicioReducer from './ejercicioReducer';
import actividadesReducer from './actividadesReducer';

export default combineReducers({
    usuario: usuariosReducer,
    alertas: alertaReducer,
    informacion: solicitoInformacionReducer,
    autenticado: autenticarReducer,
    cuenta: cuentaReducer,
    estado: estadoReducer,
    configuracion: configuracionReducer,
    ejercicio: ejercicioReducer,
    actividades: actividadesReducer
});