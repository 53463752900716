import React, {Fragment} from 'react';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';

import { HrMetodos } from '../../Css/StyledHeader';
import { Flex, LinksSub } from '../../Css/StyledMatematica';

const Subitizacion = () => {
    
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} n1={"Métodos"} n2={"MATEMATICA"} componente={"SUBITIZACION"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex>
                <LinksSub color='#FFFFFF' bgcolor='#23AAAF' fw='600' lh='100px' to={'/escritorio/metodos/matematica/subitizacion/nivel-1'}>
                    Nivel 1</LinksSub>
                <LinksSub color='#FFFFFF' bgcolor='#23AAAF' fw='600' lh='100px' to={'/escritorio/metodos/matematica/subitizacion/nivel-2'}>
                    Nivel 2</LinksSub>
                <LinksSub color='#FFFFFF' bgcolor='#23AAAF' fw='600' lh='100px' to={'/escritorio/metodos/matematica/subitizacion/nivel-3'}>
                    Nivel 3</LinksSub>
            </Flex>
        </Fragment>
    );
}
 
export default Subitizacion;