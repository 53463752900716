import {
    SOLICITAR_ACTIVIDADES,
    SOLICITAR_ACTIVIDADES_EXITO,
    SOLICITAR_ACTIVIDADES_ERROR,
    SOLICITAR_ACTIVIDADES_AGENDADAS,
    SOLICITAR_ACTIVIDADES_AGENDADAS_EXITO,
    SOLICITAR_ACTIVIDADES_AGENDADAS_ERROR,
    ACTUALIZAR_ACTIVIDADES,
    ACTUALIZAR_ACTIVIDADES_EXITO,
    ACTUALIZAR_ACTIVIDADES_ERROR,
    ACTUALIZAR_INFO_ACTIVIDADES,
    ACTUALIZAR_INFO_ACTIVIDADES_EXITO,
    ACTUALIZAR_INFO_ACTIVIDADES_ERROR,
    AGENDAR_ACTIVIDADES,
    AGENDAR_ACTIVIDADES_EXITO,
    AGENDAR_ACTIVIDADES_ERROR,
    SOLICITAR_HISTORIA_CLINICA_EXITO
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function actividadesSolicitarAction(informacion) {
    return async (dispatch) => {
        dispatch( actividadesSolicitar() );
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/solicitaractividadesdisponibles', payload, config);
            dispatch( actividadesSolicitarExito(data));
        } catch (error) {
            dispatch( actividadesSolicitarError(error.response.data));
        }
    }
};

const actividadesSolicitar = () => ({
    type: SOLICITAR_ACTIVIDADES,
    payload: true
});

const actividadesSolicitarExito = (data) => ({
    type: SOLICITAR_ACTIVIDADES_EXITO,
    payload: data
});

const actividadesSolicitarError = (msg) => ({
    type: SOLICITAR_ACTIVIDADES_ERROR,
    payload: msg
});

export function actividadesAgendadasSolicitarAction(informacion) {
    return async (dispatch) => {
        dispatch( actividadesAgendadasSolicitar() );
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/solicitaractividadesagendadas', payload, config);
            dispatch( actividadesAgendadasSolicitarExito(data));
        } catch (error) {
            dispatch( actividadesAgendadasSolicitarError(error.response.data));
        }
    }
};

const actividadesAgendadasSolicitar = () => ({
    type: SOLICITAR_ACTIVIDADES_AGENDADAS,
    payload: true
});

const actividadesAgendadasSolicitarExito = (data) => ({
    type: SOLICITAR_ACTIVIDADES_AGENDADAS_EXITO,
    payload: data
});

const actividadesAgendadasSolicitarError = (msg) => ({
    type: SOLICITAR_ACTIVIDADES_AGENDADAS_ERROR,
    payload: msg
});

export function actualizarActividadesAction(datos) {
    return async (dispatch) => {
        dispatch( actualizarActividades() );
        try {
            dispatch( actualizarActividadesExito(datos));
        } catch (error) {
            dispatch( actualizarActividadesError());
        }
    }
};

const actualizarActividades = () => ({
    type: ACTUALIZAR_ACTIVIDADES,
    payload: true
});

const actualizarActividadesExito = (datos) => ({
    type: ACTUALIZAR_ACTIVIDADES_EXITO,
    payload: datos
});

const actualizarActividadesError = () => ({
    type: ACTUALIZAR_ACTIVIDADES_ERROR,
});

export function actualizarInfoActividadesAction(datos) {
    return async (dispatch) => {
        dispatch( actualizarInfoActividades() );
        try {
            dispatch( actualizarInfoActividadesExito(datos));
        } catch (error) {
            dispatch( actualizarInfoActividadesError());
        }
    }
};

const actualizarInfoActividades = () => ({
    type: ACTUALIZAR_INFO_ACTIVIDADES,
    payload: true
});

const actualizarInfoActividadesExito = (datos) => ({
    type: ACTUALIZAR_INFO_ACTIVIDADES_EXITO,
    payload: datos
});

const actualizarInfoActividadesError = () => ({
    type: ACTUALIZAR_INFO_ACTIVIDADES_ERROR,
});

export function actividadesAgendarAction(informacion) {
    return async (dispatch) => {
        dispatch( actividadesAgendar() );

        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/agendaractividades', payload, config);
            dispatch( actividadesAgendarExito());
            dispatch( historiaClinicaSolicitarExito(data));
            Swal.fire(
                'Correcto',
                'El objetivo se agregó correctamente',
                'success'
            );
        } catch (error) {
            console.log(error)
            dispatch( actividadesAgendarError(error.response));
            Swal.fire(
                'Error',
                'El objetivo no se pudo agregar correctamente',
                'error'
            );
        }
    }
};

const actividadesAgendar = () => ({
    type: AGENDAR_ACTIVIDADES,
    payload: true
});

const actividadesAgendarExito = () => ({
    type: AGENDAR_ACTIVIDADES_EXITO,
    payload: true
});

const historiaClinicaSolicitarExito = (data) => ({
    type: SOLICITAR_HISTORIA_CLINICA_EXITO,
    payload: data
});

const actividadesAgendarError = (msg) => ({
    type: AGENDAR_ACTIVIDADES_ERROR,
    payload: msg
});