import React, { useState } from 'react';

import {FlexBox, FlexColumn} from '../Css/StyledHeader';
import {BotonSeleccionado} from '../Css/StyledActivities';
import CargaSuscripcion from './CargaSuscripcion';
import Suscripciones from './Suscripciones';

const Suscripcion = () => {

    const [objetivosEtapa, setObjetivosEtapa] = useState('verSuscripciones');

    return (
        <FlexColumn align="center">
            <FlexBox justify="center">
                <div>
                    <BotonSeleccionado name="cargarSuscripciones" onClick={e => setObjetivosEtapa(e.target.name)} backgroundcolor={objetivosEtapa === "cargarSuscripciones" ? true : false} width="40vw" padding="15px 0px" margin="20px 0px 0px 10px">Cargar Suscripción</BotonSeleccionado>
                </div>
                <div>
                    <BotonSeleccionado name="verSuscripciones" onClick={e => setObjetivosEtapa(e.target.name)} backgroundcolor={objetivosEtapa === "verSuscripciones" ? true : false} width="40vw" padding="15px 0px" margin="20px 0px 0px 10px">Suscripciones</BotonSeleccionado>
                </div>
            </FlexBox>
            <div>
                {objetivosEtapa === "cargarSuscripciones" ? <CargaSuscripcion /> : null }
                {objetivosEtapa === "verSuscripciones" ?  <Suscripciones />  : null }
            </div>
        </FlexColumn>
    );
}
 
export default Suscripcion;