import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, Container, Desktop, Mobile, TablaM45, CantTablaM45, InputM45, AvanzarBtn, AvanzarImg, ImgB10 } from '../../../Css/StyledMatematica';

const instrucciones = 'Escribe el número.';
const miniInstrucciones = 'Escribe el número.';

const M45 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numeroMiles, setNumeroMiles] = useState('');
    const [numeroCentena, setNumeroCentena] = useState('');
    const [numeroDecena, setNumeroDecena] = useState('');
    const [numeroUnidad, setNumeroUnidad] = useState('');
    const [imagenMiles, setImagenMiles] = useState([]);
    const [imagenCentena, setImagenCentena] = useState([]);
    const [imagenDecena, setImagenDecena] = useState([]);
    const [imagenUnidad, setImagenUnidad] = useState([]);
    const [unidad, setUnidad] = useState('');
    const [decena, setDecena] = useState('');
    const [centena, setCentena] = useState('');
    const [miles, setMiles] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [erroresOrden, setErroresOrden] = useState([]);
    const [idOrden, setIdOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const tipoBase10 = useSelector(state => state.ejercicio.lista);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if(numeroCentena.toString() !== centena.toString() || numeroDecena.toString() !== decena.toString() || numeroUnidad.toString() !== unidad.toString() ){
            console.log("mal");
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numeroMiles.toString() !== miles.toString() ){setNumeroMiles('');}
                if(numeroCentena.toString() !== centena.toString() ){setNumeroCentena('');}
                if(numeroDecena.toString() !== decena.toString() ){setNumeroDecena('');}
                if(numeroUnidad.toString() !== unidad.toString() ){setNumeroUnidad('');}
            }, 300);
        } else {
            console.log("bien");
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setMiles('');
                    setCentena('');
                    setDecena('');
                    setUnidad('');
                    setImagenMiles([]);
                    setImagenCentena([]);
                    setImagenDecena([]);
                    setImagenUnidad([]);
                    setNumeroMiles('');
                    setNumeroCentena('');
                    setNumeroDecena('');
                    setNumeroUnidad('');
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );                
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(comparar));
        const nro = newList[count-1];
        const u = nro%10;
        let d = Math.floor((nro/10)%10);
        let c = Math.floor((nro/100)%10);
        let m = Math.floor((nro/1000)%10);

        if(m === 0 && c === 0 && d === 0) {
            m = '';
            c = '';
            d = '';
        } else if(m === 0 && c === 0) {
            m = '';
            c = '';
        } else if(m === 0 ) {
            m = '';
        }

        setUnidad(u);
        setDecena(d);
        setCentena(c);
        setMiles(m);

        const fMiles = () => 'cuboMiles';
        const fCentena = () => 'cuboCentena';
        const fDecena = () => 'cuboDecena';
        const fUnidad = () => 'cuboUnidad';
        const arrayMiles = Array.from({length: m}, () => fMiles());
        const arrayCentena = Array.from({length: c}, () => fCentena());
        const arrayDecena = Array.from({length: d}, () => fDecena());
        const arrayUnidad = Array.from({length: u}, () => fUnidad());

        setImagenMiles(arrayMiles);
        setImagenCentena(arrayCentena);
        setImagenDecena(arrayDecena);
        setImagenUnidad(arrayUnidad);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        const randomLista = (function () {
            let min = 0;
            let max = 0;
            const lista = [];
            switch (tipoBase10) {
                case 'unidad':
                    min = 1;
                    max = 9;
                    break;
                case 'decena':
                    min = 10;
                    max = 99;
                    break;
                case 'centena':
                    min = 100;
                    max = 999;
                    break;
                case 'unidadDeMil':
                    min = 1000;
                    max = 9999;
                    break;
                case 'todos':
                    min = 1;
                    max = 9999;
                    break;
                default:
                    min = 1;
                    max = 9999;
                    break;
            }
            for(let i = 0; i < 5; i++){
                let randomNumero = 0;
                do {
                    randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                } while (lista.includes(randomNumero));
                lista.push(randomNumero)
            }
            return lista;
        })();
        setComparar(randomLista);
        setIndexes(randomLista.length);
        setIdOrden(randomLista);
        setLanzoEffect(true);
        dispatch(setearEjercicioAction('M45') );
        dispatch(setearUrlAnteriorAction('matematica/base-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M45",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrden,
                    'lista': tipoBase10,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex direction='column' margin="auto" width="100%" justify='space-evenly' align='center'
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <Desktop>
                            <FlexCuz justify='space-evenly' margin='0 auto'>
                                {/* IMAGENES ↓ */}
                                <Container dis='flex' direction='column' justify='center' align='start' minhd='75vh' maxhd='75vh' minhlg='72vh' maxhlg='72vh' margind='3vh 1vw 0px 0vw' margin='3vh 2vw 0px 2vw' > {/*  width='80vw' minwd='73vw' */}
                                    <FlexCuz direction='column' justify='flex-start' wrap='wrap' minw='60px' maxw='60px' maxh='65px' maxhlg='65px' margin='2vh 0vw 0px 0.5vw'>
                                        {imagenUnidad.map((item,i) => 
                                            <div key={i}>
                                                {/* unidad<img src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/b10_unidad.png`} w='14.5px' m='1px' />
                                            </div>
                                        )}
                                    </FlexCuz>
                                    <FlexCuz justify='flex-start' wrap='wrap' margin='2vh 0vw 0px 0vw'>
                                        {imagenDecena.map((item,i) =>  
                                            <div key={i}>
                                                {/* decena<img src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/b10_decena.png`} w='1vw' m='0.5vw' />
                                            </div>
                                        )}
                                    </FlexCuz>
                                    <FlexCuz justify='flex-start' wrap='wrap' margin='2vh 0vw 0px 0vw'>
                                        {imagenCentena.map((item,i) => 
                                            <div key={i}>
                                                {/* centena<img src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/BASE10centena.png`} w='5.5vw' m='0.5vw' />
                                            </div>
                                        )}
                                    </FlexCuz>
                                    <FlexCuz justify='flex-start' wrap='wrap' margin='2vh 0vw 0px 0.5vw'>
                                            {imagenMiles.map((item,i) => 
                                                <div key={i}> 
                                                {/* miles <img src={`/img/${item}.png`}  /> */}
                                                <ImgB10 src={`/img/BASE10mil.png`} w='7.5vw' m='0.2vw' />
                                                </div>
                                            )}
                                    </FlexCuz>
                                </Container>
                                {/* IMAGENES ↑ */}
                                {/* TABLA ↓ */}
                                <Flex margin='-3vh 2vw 0px 2vw' md='-3vh 1vw 0px 0vw'>
                                    <TablaM45 direction='column'>
                                        <CantTablaM45>M</CantTablaM45>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM45 type="numeric" min="0" max="9" value={numeroMiles} onChange={(e) => setNumeroMiles(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM45>
                                    <TablaM45 direction='column'>
                                        <CantTablaM45>C</CantTablaM45>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM45 type="numeric" min="0" max="9" value={numeroCentena} onChange={(e) => setNumeroCentena(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM45>
                                    <TablaM45 direction='column'>
                                        <CantTablaM45>D</CantTablaM45>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM45 type="numeric" min="0" max="9" value={numeroDecena} onChange={(e) => setNumeroDecena(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM45>
                                    <TablaM45 direction='column'>
                                        <CantTablaM45>U</CantTablaM45>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM45 type="numeric" min="0" max="9" value={numeroUnidad} onChange={(e) => setNumeroUnidad(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM45>
                                </Flex>
                                {/* TABLA ↑ */}

                            </FlexCuz>
                        </Desktop>
                        <Mobile>
                            <FlexCuz direction='column' justify='space-between' margin='5vh 0vw 0px 0vw'>
                                {/* TABLA ↓ */}
                                <Flex margin='0vh 2vw 0px 2vw'>
                                    <TablaM45 direction='column'>
                                        <CantTablaM45>M</CantTablaM45>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM45 type="text" min="0" max="9" value={numeroMiles} onChange={(e) => setNumeroMiles(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM45>
                                    <TablaM45 direction='column'>
                                        <CantTablaM45>C</CantTablaM45>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM45 type="text" min="0" max="9" value={numeroCentena} onChange={(e) => setNumeroCentena(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM45>
                                    <TablaM45 direction='column'>
                                        <CantTablaM45>D</CantTablaM45>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM45 type="text" min="0" max="9" value={numeroDecena} onChange={(e) => setNumeroDecena(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM45>
                                    <TablaM45 direction='column'>
                                        <CantTablaM45>U</CantTablaM45>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM45 type="text" min="0" max="9" value={numeroUnidad} onChange={(e) => setNumeroUnidad(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM45>
                                </Flex>
                                {/* TABLA ↑ */}
                                {/* IMAGENES ↓ */}
                                <Container minh='40vh' margin='8vh 2vw 0px 2vw' minht='45vh' maxht='45vh'> {/* minw='90vw' */}
                                    <FlexCuz direction='column' justify='flex-start' wrap='wrap' minw='60px' maxw='60px' maxh='65px' maxhlg='65px' margin='1vh 0vw 0px -1.5vw' mm='1vh 0vw 0px -0.6vw' mt='1vh 0vw 0px 0vw'> { /* br='2px solid red' */}
                                        {imagenUnidad.map((item,i) => 
                                            <div key={i}>
                                                {/* unidad<img src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/b10_unidad.png`} w='14.5px' m='1px' />
                                            </div>
                                        )}
                                    </FlexCuz>
                                    <FlexCuz justify='flex-start' wrap='wrap' margin='1vh 0vw 0px 0vw'>
                                        {imagenDecena.map((item,i) => 
                                            <div key={i}>
                                                {/* decena<img src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/b10_decena.png`} w='13px' /> {/* w='2vw' wt='1.3vw' m='0.5vw' */}
                                            </div>
                                        )}
                                    </FlexCuz>
                                    <FlexCuz justify='flex-start' wrap='wrap' margin='1vh 0vw 0px 0vw'>
                                        {imagenCentena.map((item,i) => 
                                            <div key={i}>
                                                {/* centena<img src={`/img/${item}.png`} /> */}
                                                <ImgB10 src={`/img/BASE10centena.png`} w='7.8vw' wt='7.3vw' m='0.5vw' />
                                            </div>
                                        )}
                                    </FlexCuz>
                                    <FlexCuz justify='flex-start' wrap='wrap' margin='1vh 0vw 0px 0vw'>
                                            {imagenMiles.map((item,i) => 
                                                <div key={i}> 
                                                {/* miles <img src={`/img/${item}.png`}  /> */}
                                                <ImgB10 src={`/img/BASE10mil.png`} w='9vw' wm='9.6vw' m='0.3vw' />
                                                </div>
                                            )}
                                    </FlexCuz>
                                </Container>
                                {/* IMAGENES ↑ */}
                            </FlexCuz>
                        </Mobile>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M45;