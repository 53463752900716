import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import BotoneraBottom from '../Compartidos/BotoneraBottom';
import useColorHelp from '../../../Hooks/useColorHelp';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { almacenarAction } from '../../../actions/almacenarEjercicioActions';
import { aumentarContadorConCorreccionAction } from '../../../actions/botoneraEjercicioActions';

//css
import { ContainerFlex } from '../../Css/MetodosCSS/StyledLN2Todos';
import { Centrar, Palabra } from '../../Css/MetodosCSS/StyledLN3Todos';

const LN4Todos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, instrucciones, miniInstrucciones } = props;

    const [randomPalabra, setRandomPalabra] = useState([]);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [arrayColor, transformarColor] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const letra = useSelector(state => state.ejercicio.letra);
    const contadorConCorreccion = useSelector(state => state.ejercicio.contadorConCorreccion);
    const error = useSelector(state => state.ejercicio.error);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const minuscula = useSelector(state => state.configuracion.minuscula);

    useEffect(() => {
        if(randomPalabra[contadorConCorreccion] === undefined || contadorConCorreccion === indexes){
            return;
        }
        transformarColor(randomPalabra[contadorConCorreccion].silaba);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, contadorConCorreccion, ayuda_lectura])
    
    useEffect(() => {
        if(data === undefined) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(data));

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList);
        transformarColor(shuffleList[contadorConCorreccion].silaba);
        setRandomPalabra(shuffleList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar])

    useEffect(() => {
        if(iniciar === false && contadorConCorreccion === indexes ){
            return;
        }else if(iniciar === false && contadorConCorreccion === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorConCorreccion]);

    useEffect(() => {
        if(finalizar === true && contadorConCorreccion === indexes -1 ){
            dispatch(aumentarContadorConCorreccionAction());
        }
        if(finalizar && contadorConCorreccion === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': ejercicio,
                'letra': letra,
                'errores': error,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                sesion
            }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorConCorreccion]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center" align="center">
                    <ContainerFlex display="flex" justify="center" align="center" margin="auto">
                        <Palabra width="270px" height="220px" font="8rem" $minMay={minuscula}>
                        {arrayColor === '' ? null :
                            arrayColor.map((item, i) =>
                                <Centrar
                                key={i}
                                >
                                {item.map((itemInterior, i) =>
                                    <Centrar style={{color: itemInterior.color}}
                                    key={i}
                                    >
                                        {itemInterior.letra}
                                    </Centrar>
                                )}
                                </Centrar>
                            )
                        }
                        </Palabra>
                    </ContainerFlex>
                    <ContainerFlex display="flex" width="95%" justify="space-between" justifyself="end">
                        <ContainerFlex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </ContainerFlex>
                        <ContainerFlex>
                            <BotoneraBottom aumentarConCorreccion={true} error={true} pausar={true} cantidad={indexes} />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                :

                <Instrucciones instrucciones={instrucciones} />

            }
        </Fragment>
    );
}

export default LN4Todos;