import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {Flex, Ilustracion, Btn, LetraXL} from '../../../../Css/MetodosCSS/StyledCCIntrasilabica';

const instrucciones = 'Selecciona la imagen que contenga la letra.';
const miniInstrucciones = 'Selecciona la imagen que contenga la letra.';

const LEN31 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [randomPalabra, setRandomPalabra] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();
    
    const verificar = (e, plbr, ltr) => {
        e.preventDefault();
        if(!plbr.toUpperCase().includes(ltr.toUpperCase())) {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
            }, 1000);
        } else {
            console.log('bien');
            setIsCorrect(true);
            setTimeout(() => {
               setIsCorrect(false); 
            }, 300);
            GoodWork();
            setDisable(true);
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 1000);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    setDisable(false);
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN31') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-fonemica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': "LEN31",
                'errores': contarErrores,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                'lista_usada': lista_usada,
                sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        const newComparar = [
            {id:'1', palabra:newList.palabra_1, imagen:newList.imagen_url_1, letra:newList.letra, ayuda_imagen: newList.ayuda_imagen_1},
            {id:'2', palabra:newList.palabra_2, imagen:newList.imagen_url_2, letra:newList.letra, ayuda_imagen: newList.ayuda_imagen_2},
            {id:'3', palabra:newList.palabra_3, imagen:newList.imagen_url_3, letra:newList.letra, ayuda_imagen: newList.ayuda_imagen_3}
        ]

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(newComparar);
        
        setRandomPalabra(
            newShuffleList
        )
    }, [comparar]);

    return (
        <Fragment>
        <HeaderEjercicios estrellas={true} carrito={true} ayuImagen={true} />
        {iniciar
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex direction="column" margin="auto">
                    <LetraXL  isCorrect={isCorrect} isIncorrect={isIncorrect}>
                        {comparar.letra.toUpperCase()}
                    </LetraXL>
                    <Flex margin="1.5rem 0 0 0">
                        {randomPalabra.map(item => 
                        <Btn margin="0 1.2rem 0 0"
                            disabled={disable}
                            key={item.id}
                            onClick={(e) => verificar(e, item.palabra, item.letra)}
                        >
                            <Ilustracion src={`/img/${item.imagen}.png`} width="8vw"/>
                            {ayuda_imagen ? 
                                <div>{item.ayuda_imagen}</div>
                            : null}
                        </Btn>
                        )}
                    </Flex>
                </Flex>
                <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                    <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                </Flex>
            </Flex>
            :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default LEN31;