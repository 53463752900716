import {
    CONFIRMAR_CUENTA,
    CONFIRMAR_CUENTA_EXITO,
    MOSTRAR_ALERTA
} from '../types';
import clienteAxios from '../config/axios';

import Swal from 'sweetalert2';

// Confirma la cuenta
export function confirmarCuentaAction(token) {
    return async (dispatch) => {
        dispatch( confirmarCuenta() );
        try {
            const respuesta = await clienteAxios.post('/usuarios/confirmar', token);
            dispatch( confirmarCuentaExito(respuesta.data) );

            Swal.fire({
                icon: 'success',
                title:'La solicitud ha sido un éxito',
                text: `${respuesta.data.msg}`,
            }).then(function() {
                window.location = "https://metodoyogen.com/login";
            });

        } catch (error) {
            const errores = { msg: error.response.data};
            if (errores !== undefined) {
                dispatch (mostrarAlerta(errores));
            }
        }
    }
}

const confirmarCuenta = () => ({
    type: CONFIRMAR_CUENTA,
    payload: true
});

// si solicita con éxito la confirmación
const confirmarCuentaExito = (msg) => ({
    type: CONFIRMAR_CUENTA_EXITO,
    payload: msg
});

// si hubo un error
const mostrarAlerta = (alerta) => ({
    type: MOSTRAR_ALERTA,
    payload: alerta
});