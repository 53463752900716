import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';

import { ayudaVisualAction } from '../../../../actions/configuradorActions';

import { FlexFull, AyudaM69, NumberAyuda, CloseM69 } from '../../../Css/StyledMatematica' ;

const AyudaCuadricula = () => {

    const dispatch = useDispatch();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [filaUno, setFilaUno] = useState([]);
    const [filaDos, setFilaDos] = useState([]);
    const [filaTres, setFilaTres] = useState([]);
    const [filaCuatro, setFilaCuatro] = useState([]);

    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);

    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
        if(ayuVisualSolicitada){
            return;
        } else {
            dispatch( ayudaVisualAction(true) );
        }
    }
    const closeModal = (e) => {
        e.preventDefault();
        setIsOpen(false);
    }

    useEffect(() => {
        const data = [
            {id:'1', numero:0, palabra:'cero'},
            {id:'2', numero:1, palabra:'uno'},
            {id:'3', numero:2, palabra:'dos'},
            {id:'4', numero:3, palabra:'tres'},
            {id:'5', numero:4, palabra:'cuatro'},
            {id:'6', numero:5, palabra:'cinco'},
            {id:'7', numero:6, palabra:'seis'},
            {id:'8', numero:7, palabra:'siete'},
            {id:'9', numero:8, palabra:'ocho'},
            {id:'10', numero:9, palabra:'nueve'},
        ]
        const data2 = [
            {id:'11', numero:10, palabra:'diez'},
            {id:'12', numero:11, palabra:'once'},
            {id:'13', numero:12, palabra:'doce'},
            {id:'14', numero:13, palabra:'trece'},
            {id:'15', numero:14, palabra:'catorce'},
            {id:'16', numero:15, palabra:'quince'},
            {id:'17', numero:16, palabra:'dieciseis'},
            {id:'18', numero:17, palabra:'diecisiete'},
            {id:'19', numero:18, palabra:'dieciocho'},
            {id:'20', numero:19, palabra:'diecinueve'},
        ]
        const data3 = [
            {id:'20', numero:20, palabra:'veinte'},
            {id:'21', numero:30, palabra:'treinta'},
            {id:'22', numero:40, palabra:'cuarenta'},
            {id:'23', numero:50, palabra:'cincuenta'},
            {id:'24', numero:60, palabra:'sesenta'},
            {id:'25', numero:70, palabra:'setenta'},
            {id:'26', numero:80, palabra:'ochenta'},
            {id:'27', numero:90, palabra:'noventa'},
            {id:'28', numero:100, palabra:'cien'},
            {id:'29', numero:'1.000', palabra:'mil'},
            {id:'30', numero:'1.000.000', palabra:'millón'}
        ]
        const data4 = [
            {id:'31', palabra:'veinti...'},
            {id:'32', palabra:'treinta y ...'},
            {id:'33', palabra:'cuarenta y ...'},
            {id:'34', palabra:'cincuenta y ...'},
            {id:'35', palabra:'sesenta y ...'},
            {id:'36', palabra:'setenta y ...'},
            {id:'37', palabra:'ochenta y ...'},
            {id:'38', palabra:'noventa y ...'},
            {id:'39', palabra:'ciento... ó 2 cientos'},
            {id:'40', palabra:'3 mil'},
            {id:'41', palabra:'1 millón, 2 millones'}
        ]
        setFilaUno(data);
        setFilaDos(data2);
        setFilaTres(data3);
        setFilaCuatro(data4);
    }, [])

    return (
        <Fragment>
            <div>
                <AyudaM69 onClick={(e) => openModal(e)}>?</AyudaM69>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >
                    <CloseM69 onClick={(e) => closeModal(e)}>X</CloseM69>
                    <FlexFull justify='space-between' align='center'>
                        <div>
                            {filaUno.map(item => 
                            <NumberAyuda key={item.id}>{item.numero} = {item.palabra.toUpperCase()}</NumberAyuda>
                            )}
                        </div>
                        <div>
                            {filaDos.map(item => 
                            <NumberAyuda key={item.id}>{item.numero} = {item.palabra.toUpperCase()}</NumberAyuda>
                            )}
                        </div>
                        <div>
                            {filaTres.map(item => 
                            <NumberAyuda key={item.id}>{item.numero} = {item.palabra.toUpperCase()}</NumberAyuda>
                            )}
                        </div>
                        <div>
                            {filaCuatro.map(item => 
                            <NumberAyuda key={item.id}>{item.numero}{item.palabra.toUpperCase()}</NumberAyuda>
                            )}
                        </div>
                    </FlexFull>
                </Modal>
            </div>
        </Fragment>
    );
}
 
export default AyudaCuadricula;