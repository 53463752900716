import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgB10, CantCuadroM47, Separador, InputM47, ButtonM44, AvanzarImg, AvanzarBtn, TextM47, ValueM47 } from '../../../Css/StyledMatematica';

const instrucciones = 'Construye los números e indica el anterior y el posterior.';
const miniInstrucciones = 'Construye los números e indica el anterior y el posterior.';

const M47 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [imagenMilesFija, setImagenMilesFija] = useState([]);
    const [imagenCentenaFija, setImagenCentenaFija] = useState([]);
    const [imagenDecenaFija, setImagenDecenaFija] = useState([]);
    const [imagenUnidadFija, setImagenUnidadFija] = useState([]);
    const [imagenMiles, setImagenMiles] = useState([]);
    const [imagenCentena, setImagenCentena] = useState([]);
    const [imagenDecena, setImagenDecena] = useState([]);
    const [imagenUnidad, setImagenUnidad] = useState([]);
    const [imagenMiles2, setImagenMiles2] = useState([]);
    const [imagenCentena2, setImagenCentena2] = useState([]);
    const [imagenDecena2, setImagenDecena2] = useState([]);
    const [imagenUnidad2, setImagenUnidad2] = useState([]);
    const [unidad, setUnidad] = useState('');
    const [decena, setDecena] = useState('');
    const [centena, setCentena] = useState('');
    const [miles, setMiles] = useState('');
    const [unidad2, setUnidad2] = useState('');
    const [decena2, setDecena2] = useState('');
    const [centena2, setCentena2] = useState('');
    const [miles2, setMiles2] = useState('');
    const [numeroAnterior, setNumeroAnterior] = useState('');
    const [numeroPosterior, setNumeroPosterior] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [erroresOrden, setErroresOrden] = useState([]);
    const [idOrden, setIdOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const tipoBase10 = useSelector(state => state.ejercicio.lista);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const agregarMiles = (e) => {
        e.preventDefault();
        if(imagenMiles.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles));
        newList.push('cuboUnidadDeMil')
        setImagenMiles(newList);
    }

    const reducirMiles = (e) => {
        e.preventDefault();
        if(imagenMiles.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles));
        newList.pop()
        setImagenMiles(newList);
    }

    const agregarCentena = (e) => {
        e.preventDefault();
        if(imagenCentena.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena));
        newList.push('cuboCentena')
        setImagenCentena(newList);
    }

    const reducirCentena = (e) => {
        e.preventDefault();
        if(imagenCentena.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena));
        newList.pop()
        setImagenCentena(newList);
    }

    const agregarDecena = (e) => {
        e.preventDefault();
        if(imagenDecena.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena));
        newList.push('cuboDecena')
        setImagenDecena(newList);
    }

    const reducirDecena = (e) => {
        e.preventDefault();
        if(imagenDecena.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena));
        newList.pop()
        setImagenDecena(newList);
    }

    const agregarUnidad = (e) => {
        e.preventDefault();
        if(imagenUnidad.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad));
        newList.push('cuboUnidad')
        setImagenUnidad(newList);
    }

    const reducirUnidad = (e) => {
        e.preventDefault();
        if(imagenUnidad.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad));
        newList.pop()
        setImagenUnidad(newList);
    }

    const agregarMiles2 = (e) => {
        e.preventDefault();
        if(imagenMiles2.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles2));
        newList.push('cuboUnidadDeMil')
        setImagenMiles2(newList);
    }

    const reducirMiles2 = (e) => {
        e.preventDefault();
        if(imagenMiles2.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles2));
        newList.pop()
        setImagenMiles2(newList);
    }

    const agregarCentena2 = (e) => {
        e.preventDefault();
        if(imagenCentena2.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena2));
        newList.push('cuboCentena')
        setImagenCentena2(newList);
    }

    const reducirCentena2 = (e) => {
        e.preventDefault();
        if(imagenCentena2.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena2));
        newList.pop()
        setImagenCentena2(newList);
    }

    const agregarDecena2 = (e) => {
        e.preventDefault();
        if(imagenDecena2.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena2));
        newList.push('cuboDecena')
        setImagenDecena2(newList);
    }

    const reducirDecena2 = (e) => {
        e.preventDefault();
        if(imagenDecena2.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena2));
        newList.pop()
        setImagenDecena2(newList);
    }

    const agregarUnidad2 = (e) => {
        e.preventDefault();
        if(imagenUnidad2.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad2));
        newList.push('cuboUnidad')
        setImagenUnidad2(newList);
    }

    const reducirUnidad2 = (e) => {
        e.preventDefault();
        if(imagenUnidad2.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad2));
        newList.pop()
        setImagenUnidad2(newList);
    }

    const verificar = (e) => {
        e.preventDefault();
        let imagenMilesCorregida = '';
        let imagenCentenaCorregida = '';
        let imagenDecenaCorregida = '';
        let imagenUnidadCorregida = '';

        let imagenMiles2Corregida = '';
        let imagenCentena2Corregida = '';
        let imagenDecena2Corregida = '';
        let imagenUnidad2Corregida = '';

        if(miles === '' && imagenMiles.length === 0){
            imagenMilesCorregida = '';
        } else {
            imagenMilesCorregida = imagenMiles.length;
        }
        if(centena === '' && imagenCentena.length === 0){
            imagenCentenaCorregida = '';
        } else {
            imagenCentenaCorregida = imagenCentena.length;
        }
        if(decena === '' && imagenDecena.length === 0){
            imagenDecenaCorregida = '';
        } else {
            imagenDecenaCorregida = imagenDecena.length;
        }
        if(unidad === '' && imagenUnidad.length === 0){
            imagenUnidadCorregida = '';
        } else {
            imagenUnidadCorregida = imagenUnidad.length;
        }

        if(miles2 === '' && imagenMiles2.length === 0){
            imagenMiles2Corregida = '';
        } else {
            imagenMiles2Corregida = imagenMiles2.length;
        }
        if(centena2 === '' && imagenCentena2.length === 0){
            imagenCentena2Corregida = '';
        } else {
            imagenCentena2Corregida = imagenCentena2.length;
        }
        if(decena2 === '' && imagenDecena2.length === 0){
            imagenDecena2Corregida = '';
        } else {
            imagenDecena2Corregida = imagenDecena2.length;
        }
        if(unidad2 === '' && imagenUnidad2.length === 0){
            imagenUnidad2Corregida = '';
        } else {
            imagenUnidad2Corregida = imagenUnidad2.length;
        }

        if(imagenMilesCorregida !== miles || imagenCentenaCorregida !== centena || imagenDecenaCorregida !== decena || imagenUnidadCorregida !== unidad || imagenMiles2Corregida !== miles2 || imagenCentena2Corregida !== centena2 || imagenDecena2Corregida !== decena2 || imagenUnidad2Corregida !== unidad2 || (comparar[count-1] - 1).toString() !== numeroAnterior || (comparar[count-1] + 1).toString() !== numeroPosterior ){
            console.log("mal");
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(imagenMilesCorregida !== miles){setImagenMiles([]);}
                if(imagenCentenaCorregida !== centena){setImagenCentena([]);}
                if(imagenDecenaCorregida !== decena){setImagenDecena([]);}
                if(imagenUnidadCorregida !== unidad){setImagenUnidad([]);}
                if(imagenMiles2Corregida !== miles2){setImagenMiles2([]);}
                if(imagenCentena2Corregida !== centena2){setImagenCentena2([]);}
                if(imagenDecena2Corregida !== decena2){setImagenDecena2([]);}
                if(imagenUnidad2Corregida !== unidad2){setImagenUnidad2([]);}
                if((comparar[count-1] - 1).toString() !== numeroAnterior){setNumeroAnterior('');}
                if((comparar[count-1] + 1).toString() !== numeroPosterior){setNumeroPosterior('');}
            }, 300);
        } else {
            console.log("bien");
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setMiles(1);
                    setCentena(1);
                    setDecena(1);
                    setUnidad(1);
                    setImagenMiles([]);
                    setImagenCentena([]);
                    setImagenDecena([]);
                    setImagenUnidad([]);
                    setMiles2(1);
                    setCentena2(1);
                    setDecena2(1);
                    setUnidad2(1);
                    setImagenMiles2([]);
                    setImagenCentena2([]);
                    setImagenDecena2([]);
                    setImagenUnidad2([]);
                    setNumeroAnterior('');
                    setNumeroPosterior('');
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );                
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(comparar));
        const nro = newList[count-1];
        let u = nro%10;
        let d = Math.floor((nro/10)%10);
        let c = Math.floor((nro/100)%10);
        let m = Math.floor((nro/1000)%10);

        if(m === 0 && c === 0 && d === 0 && u === 0) {
            m = '';
            c = '';
            d = '';
            u = '';
        } else if(m === 0 && c === 0 && d === 0) {
            m = '';
            c = '';
            d = '';
        } else if(m === 0 && c === 0 ) {
            m = '';
            c = '';
        } else if(m === 0) {
            m = '';
        }

        const fMiles = () => 'cuboMiles';
        const fCentena = () => 'cuboCentena';
        const fDecena = () => 'cuboDecena';
        const fUnidad = () => 'cuboUnidad';
        const arrayMiles = Array.from({length: m}, () => fMiles());
        const arrayCentena = Array.from({length: c}, () => fCentena());
        const arrayDecena = Array.from({length: d}, () => fDecena());
        const arrayUnidad = Array.from({length: u}, () => fUnidad());

        setImagenMilesFija(arrayMiles);
        setImagenCentenaFija(arrayCentena);
        setImagenDecenaFija(arrayDecena);
        setImagenUnidadFija(arrayUnidad);

        const newList2 = JSON.parse(JSON.stringify(comparar));
        const nro2 = newList2[count-1] - 1;
        let u1 = nro2%10;
        let d1 = Math.floor((nro2/10)%10);
        let c1 = Math.floor((nro2/100)%10);
        let m1 = Math.floor((nro2/1000)%10);

        if(m1 === 0 && c1 === 0 && d1 === 0 && u1 === 0) {
            m1 = '';
            c1 = '';
            d1 = '';
            u1 = '';
        } else if(m1 === 0 && c1 === 0 && d1 === 0) {
            m1 = '';
            c1 = '';
            d1 = '';
        } else if(m1 === 0 && c1 === 0 ) {
            m1 = '';
            c1 = '';
        } else if(m1 === 0) {
            m1 = '';
        }

        setUnidad(u1);
        setDecena(d1);
        setCentena(c1);
        setMiles(m1);

        const newList3 = JSON.parse(JSON.stringify(comparar));
        const nro3 = newList3[count-1] + 1;
        let u2 = nro3%10;
        let d2 = Math.floor((nro3/10)%10);
        let c2 = Math.floor((nro3/100)%10);
        let m2 = Math.floor((nro3/1000)%10);

        if(m2 === 0 && c2 === 0 && d2 === 0 && u2 === 0) {
            m2 = '';
            c2 = '';
            d2 = '';
            u2 = '';
        } else if(m2 === 0 && c2 === 0 && d2 === 0) {
            m2 = '';
            c2 = '';
            d2 = '';
        } else if(m2 === 0 && c2 === 0 ) {
            m2 = '';
            c2 = '';
        } else if(m2 === 0) {
            m2 = '';
        }

        setUnidad2(u2);
        setDecena2(d2);
        setCentena2(c2);
        setMiles2(m2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        const randomLista = (function () {
            let min = 0;
            let max = 0;
            const lista = [];
            switch (tipoBase10) {
                case 'unidad':
                    min = 1;
                    max = 9;
                    break;
                case 'decena':
                    min = 10;
                    max = 99;
                    break;
                case 'centena':
                    min = 100;
                    max = 999;
                    break;
                case 'unidadDeMil':
                    min = 1000;
                    max = 9999;
                    break;
                case 'todos':
                    min = 1;
                    max = 9999;
                    break;
                default:
                    min = 1;
                    max = 9999;
                    break;
            }
            for(let i = 0; i < 5; i++){
                let randomNumero = 0;
                do {
                    randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                } while (lista.includes(randomNumero));
                lista.push(randomNumero)
            }
            return lista;
        })();
        setComparar(randomLista);
        setIndexes(randomLista.length);
        setIdOrden(randomLista);
        setLanzoEffect(true);
        dispatch(setearEjercicioAction('M47') );
        dispatch(setearUrlAnteriorAction('matematica/base-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M47",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrden,
                    'lista': tipoBase10,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex direction="column" margin="auto" width="100%" align='center'
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <Flex>
                            <Flex direction='column' justify='center'>
                                <TextM47>ANTERIOR -1</TextM47>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <InputM47 type="numeric" min="0" max="9999" value={numeroAnterior} onChange={(e) => setNumeroAnterior(e.target.value)} disabled={disable} />
                                </form>
                            </Flex>
                            <div>
                                <ValueM47>
                                    {comparar[count-1]}
                                </ValueM47>
                            </div>
                            <Flex direction='column' justify='center'>
                            <TextM47>POSTERIOR +1</TextM47>
                                <form onSubmit={(e) => onSubmit(e)}>
                                        <InputM47 type="numeric" min="0" max="9999" value={numeroPosterior} onChange={(e) => setNumeroPosterior(e.target.value)} disabled={disable} />
                                </form>
                            </Flex>
                        </Flex>

                        <FlexCuz justify='space-between'>
                            {/* PRIMERAS IMAGENES */}
                            <div>
                                {/* IMG ARRIBA */}
                                <FlexFull direction='column' justify='end' align='center' minh='45vh' minhlg='49vh' minw='31vw' maxw='31vw'>  {/* border='2px solid red' */}
                                    <FlexFull wrap='wrap' maxw='6vw' maxwt='4vw' maxwd='4vw' maxwlg='4vw'> { /* maxw='4.5vw' */}
                                        {imagenUnidad.map((item,i) => 
                                            // <img src={`/img/${item}.png`} key={i} />
                                            <ImgB10 key={i} src={`/img/b10_unidad.png`} w='1.5vw' wt='1vw' wd='1vw' wlg='1vw' m='1px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenDecena.map((item,i) => 
                                            // <img src={`/img/${item}.png`} key={i} />
                                            <ImgB10 key={i} src={`/img/b10_decena.png`} w='1.3vw' wt='1.1vw' wd='0.8vw' wlg='0.8vw' m='0.5px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenCentena.map((item,i) => 
                                            // <img src={`/img/${item}.png`} key={i} />
                                            <ImgB10 key={i} src={`/img/BASE10centena.png`} w='4.7vw' wt='4.2vw' wd='3.6vw' wlg='3.4vw' m='1.5px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenMiles.map((item,i) => 
                                            // <img src={`/img/${item}.png`} key={i} />
                                            <ImgB10 key={i} src={`/img/BASE10mil.png`} w='5.8vw' wt='5.3vw' wd='5vw' wlg='5vw' m='0px 1px 0px 1px' /> 
                                        )}
                                    </FlexFull>
                                </FlexFull>  
                                {/* FIN IMG ARRIBA */}
                                <Separador w='32vw' /> 
                                {/* IMG ABAJO */}
                                <Flex minw='31vw' maxw='31vw' maxwd='25vw' margin='-5px 0px 0px 0px'>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM47>U. MIL</CantCuadroM47>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarMiles(e)}
                                        >
                                            <ImgB10 src={`/img/BASE10mil.png`} w='42px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirMiles(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM47>CENTENA</CantCuadroM47>
                                        <ButtonM44 
                                            disabled={disable}
                                            onClick={(e) => agregarCentena(e)}
                                        >
                                            <ImgB10 src={`/img/BASE10centena.png`} w='32px' />
                                        </ButtonM44>
                                        <ButtonM44 
                                            disabled={disable}
                                            onClick={(e) => reducirCentena(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM47>DECENA</CantCuadroM47>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarDecena(e)}
                                        >
                                            <ImgB10 src={`/img/b10_decena.png`} w='8px' wm='8.5px' wt='10px' wd='10px' wlg='10px' m='-17px' tr='rotate(90deg)' />
                                        </ButtonM44>
                                        <ButtonM44 
                                            disabled={disable}
                                            onClick={(e) => reducirDecena(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM47>UNIDAD</CantCuadroM47>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarUnidad(e)}
                                        >
                                            <ImgB10 src={`/img/b10_unidad.png`} w='18px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirUnidad(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                </Flex>
                                {/* FIN IMG ABAJO */}
                            </div>
                            {/* FIN PRIMERAS IMAGENES */}


                            {/* PRUEBA DEL MEDIO */}
                            <div>
                                {/* IMG ARRIBA */}
                                <FlexFull direction='column' justify='end' align='center' minh='45vh' minhlg='49vh' minw='28vw' maxw='31vw' maxwd='25vw' margin='0px 0vw 0vh 0.5vw'>
                                    <FlexFull wrap='wrap' maxw='6vw' maxwt='4vw' maxwd='4vw' maxwlg='4vw'>
                                        {imagenUnidadFija.map((item,i) => 
                                            <ImgB10 key={i} src={`/img/b10_unidad.png`} w='1.5vw' wt='1vw' wd='1vw' wlg='1vw' m='1px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenDecenaFija.map((item,i) => 
                                            <ImgB10 key={i} src={`/img/b10_decena.png`} w='1.3vw' wt='1.1vw' wd='0.8vw' wlg='0.8vw' m='0.5px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenCentenaFija.map((item,i) => 
                                            <ImgB10 key={i} src={`/img/BASE10centena.png`} w='4.7vw' wt='4.2vw' wd='3.6vw' wlg='3.4vw' m='1.5px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenMilesFija.map((item,i) => 
                                            <ImgB10 key={i} src={`/img/BASE10mil.png`} w='5.8vw' wt='5.3vw' wd='5vw' wlg='5vw' m='0px 1px 0px 1px' />
                                        )}
                                    </FlexFull>
                                </FlexFull>
                                {/* FIN IMG ARRIBA */}
                                <Separador w='33vw' op='0' /> 
                                {/* IMG ABAJO */}
                                <Flex minw='31vw' maxw='31vw' maxwd='25vw' margin='-3vh 0px 0px 0px' op='0'>
                                    <FlexCuz width='25%' direction='column'>
                                        <ButtonM44 minh='80px'
                                            disabled={disable}
                                        >
                                            <ImgB10 src={`/img/BASE10mil.png`} w='5vw' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='1.5vw' m='1vh 0px 0px 0px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column'>
                                        <ButtonM44 minh='80px'
                                            disabled={disable}
                                        >
                                            <ImgB10 src={`/img/BASE10centena.png`} w='3.5vw' m='0px' />
                                        </ButtonM44>
                                        <ButtonM44 
                                            disabled={disable}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='1.5vw' m='1vh 0px 0px 0px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column'>
                                        <ButtonM44 minh='40px'
                                            disabled={disable}
                                        >
                                            <ImgB10 src={`/img/b10_decena.png`} w='0.9vw' m='1vh 1vw 0.5vh 1.5vw' tr='rotate(90deg)' />
                                        </ButtonM44>
                                        <ButtonM44 
                                            disabled={disable}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='1.5vw' m='1vh 0px 0px 0px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column'>
                                        <ButtonM44 minh='80px'
                                            disabled={disable}
                                        >
                                            <ImgB10 src={`/img/b10_unidad.png`} w='1.2vw' m='0px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='1.5vw' m='1vh 0px 0px 0px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                </Flex>
                                {/* FIN IMG ABAJO */}
                            </div>
                            {/* FIN PRUEBA DEL MEDIO */}




                            

                            {/* SEGUNDAS IMAGENES */}
                            <div>
                                {/* IMG ARRIBA */}
                                <FlexFull direction='column' justify='end' align='center' minh='45vh' minhlg='49vh' minw='31vw' maxw='31vw'> 
                                    <FlexFull wrap='wrap' maxw='6vw' maxwt='4vw' maxwd='4vw' maxwlg='4vw'>
                                        {imagenUnidad2.map((item,i) => 
                                            <ImgB10 key={i} src={`/img/b10_unidad.png`} w='1.5vw' wt='1vw' wd='1vw' wlg='1vw' m='1px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenDecena2.map((item,i) => 
                                            <ImgB10 key={i} src={`/img/b10_decena.png`} w='1.3vw' wt='1.1vw' wd='0.8vw' wlg='0.8vw' m='0.5px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenCentena2.map((item,i) => 
                                            <ImgB10 key={i} src={`/img/BASE10centena.png`} w='4.7vw' wt='4.2vw' wd='3.6vw' wlg='3.4vw' m='1.5px' />
                                        )}
                                    </FlexFull>
                                    <FlexFull wrap='wrap'>
                                        {imagenMiles2.map((item,i) => 
                                            <ImgB10 key={i} src={`/img/BASE10mil.png`} w='5.8vw' wt='5.3vw' wd='5vw' wlg='5vw' m='0px 1px 0px 1px' />
                                        )}
                                    </FlexFull>
                                </FlexFull>  
                                {/* FIN IMG ARRIBA */}
                                <Separador w='32vw' /> 
                                {/* IMG ABAJO */}
                                <Flex minw='31vw' maxw='31vw' maxwd='25vw' margin='-5px 0px 0px 0px'>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM47>U. MIL</CantCuadroM47>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarMiles2(e)}
                                        >
                                            <ImgB10 src={`/img/BASE10mil.png`} w='42px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirMiles2(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM47>CENTENA</CantCuadroM47>
                                        <ButtonM44 
                                            disabled={disable}
                                            onClick={(e) => agregarCentena2(e)}
                                        >
                                            <ImgB10 src={`/img/BASE10centena.png`} w='32px' />
                                        </ButtonM44>
                                        <ButtonM44 
                                            disabled={disable}
                                            onClick={(e) => reducirCentena2(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM47>DECENA</CantCuadroM47>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarDecena2(e)}
                                        >
                                            <ImgB10 src={`/img/b10_decena.png`} w='8px' wm='8.5px' wt='10px' wd='10px' wlg='10px' m='-17px' tr='rotate(90deg)' />
                                        </ButtonM44>
                                        <ButtonM44 
                                            disabled={disable}
                                            onClick={(e) => reducirDecena2(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                    <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                        <CantCuadroM47>UNIDAD</CantCuadroM47>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => agregarUnidad2(e)}
                                        >
                                            <ImgB10 src={`/img/b10_unidad.png`} w='18px' />
                                        </ButtonM44>
                                        <ButtonM44
                                            disabled={disable}
                                            onClick={(e) => reducirUnidad2(e)}
                                        >
                                            <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                        </ButtonM44>
                                    </FlexCuz>
                                </Flex>
                                {/* FIN IMG ABAJO */}
                            </div>
                            {/* FIN SEGUNDAS IMAGENES */}
                        </FlexCuz>
                    {/* </FlexFull> */}
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                    <Instrucciones miniInstrucciones={'Construye los números e indica el anterior y el posterior.'} />
            }
        </Fragment>
    );
}
 
export default M47;