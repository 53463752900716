import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useParams } from "react-router";

import Alertas from '../Metodos/Compartidos/Alertas.js';

// action de redux
import { confirmarCuentaAction } from '../../actions/confirmarCuentaActions';
import { solicitaNuevamenteAction } from '../../actions/solicitaNuevamenteActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';

// css
import {LogIn, Ilustracion, Formulario, Cuerpo, Logo, FlexColumn, Div, Form, Input, Titulo, Button, ContenedorLogo, ContenedorForm, Error, ContenedorLogin, ContenedorHeader} from '../Css/StyledForm.js';

const ConfirmarCuenta = () => {

    const {token} = useParams();

    // utilizar use dispatch y te crea una función
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [email, guardarEmail] = useState('');

    // mandar llamar el action de nuevoUsuarioAction
    const confirmarCuenta = (token) => dispatch( confirmarCuentaAction(token) );

    const cargando = useSelector( (state) => state.usuario.loading);
    const error = useSelector ( (state) => state.usuario.error);
    const confirmado = useSelector ( (state) => state.usuario.confirmado);
    const reenviado = useSelector ( (state) => state.usuario.solicitarConfirmar);
    const alerta = useSelector ( (state) => state.alertas.alerta);

    const submitDispatch = e => {
        e.preventDefault();

        confirmarCuenta({
            token
        });
    }

    const openSolicitar = (e) => {
        e.preventDefault();
        if(open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }

    const submitNuevoToken = e => {
        e.preventDefault();
        if(email.trim() === ''){
            const alerta = {
                msg: 'Todos los campos son obligatorios'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        // si no hay errores
        dispatch( ocultarAlertaAction());

        const informacion = {
            payload: {
                email
            }
        }
        dispatch( solicitaNuevamenteAction(informacion) );
    }

    return (
        <ContenedorLogin>
            {alerta ? <Alertas /> : null}
            <ContenedorHeader>
                <ContenedorLogo>
                    <Link to={"/"}>
                        <Logo src={`/img/metodoYogenLogo.png`} alt="" width="20vh"/>
                    </Link>
                </ContenedorLogo>    
                <LogIn to={"/Login"} margin="0 5rem 0 0">Iniciar Sesion</LogIn>
            </ContenedorHeader>
            <ContenedorForm>
                <Ilustracion src={`/img/ilustracionConfirmarCuenta.png`} alt="" width="30vw"/>
                <Formulario padding="4rem" margin="0 7rem 0 0">
                    <h1>         
                    {cargando ? <p>Cargando...</p> : null }
                    {error ? <Error> {error} </Error>: null }
                    {confirmado ? <p>{confirmado.msg}</p> : null }
                    {reenviado ? <p>{reenviado.msg}</p> : null }
                    {alerta ? <p>{alerta.msg}</p> : null }
                    </h1>

                    <FlexColumn>
                        
                    <Titulo>Confirmar cuenta</Titulo>
                    <Div>
                        <Button margin="10px 0px 10px 0px"
                            onClick={(e) => submitDispatch(e)}
                        >
                            Haz Click para confirmar
                        </Button>
                        {open ?
                            <Form
                                onSubmit={submitNuevoToken}
                            >   
                                <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email de la cuenta"
                                    value={email}
                                    onChange={e => guardarEmail(e.target.value)}
                                />
                                <Button
                                    type="submit"
                                >
                                    Solicita nuevo token
                                </Button>

                            </Form>
                        :
                            <Button
                                type="submit"
                                onClick={(e) => openSolicitar(e)}
                            >
                                Solicita nuevo token
                            </Button>
                        }
                    </Div>
                    </FlexColumn>
                </Formulario>
            </ContenedorForm>
        </ContenedorLogin>
    );
}
 
export default ConfirmarCuenta;