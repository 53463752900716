import {
    SOLICITO_INFORMACION_EJERCICIO,
    SOLICITO_INFORMACION_EJERCICIO_EXITO,
    SOLICITO_INFORMACION_EJERCICIO_ERROR,
    SOLICITO_LISTA_EJERCICIO,
    SOLICITO_LISTA_EJERCICIO_EXITO,
    SOLICITO_LISTA_EJERCICIO_ERROR
} from '../types';
import clienteAxios from '../config/axios';

/* Solicito datos de la DB */
export function solicitarInformacionEjercicioAction(informacion) {
    return async (dispatch) => {
        dispatch (solicitarInformacionEjercicio());
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/inicioejercicio', payload, config);
            dispatch( solicitarInformacionEjercicioExito(data));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitarInformacionEjercicioError(error.response.data) );
            }
        }
    }
}

export function solicitoListaEjerciciosAction(informacion) {
    return async (dispatch) => {
        dispatch (solicitoListaEjercicios());
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/listaejercicios', payload, config);
            dispatch( solicitoListaEjerciciosExito(data.informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitoListaEjerciciosError(error.response.data) );
            }
        }
    }
}


const solicitarInformacionEjercicio = () => ({
    type: SOLICITO_INFORMACION_EJERCICIO,
    payload: true
});

// si logro obtener la informacion de la BD
const solicitarInformacionEjercicioExito = (data) => ({
    type: SOLICITO_INFORMACION_EJERCICIO_EXITO,
    payload: data
});

// si hubo un error
const solicitarInformacionEjercicioError = (msg) => ({
    type: SOLICITO_INFORMACION_EJERCICIO_ERROR,
    payload: msg
});

const solicitoListaEjercicios = () => ({
    type: SOLICITO_LISTA_EJERCICIO,
    payload: true
});

// si logro obtener la informacion de la BD
const solicitoListaEjerciciosExito = (data) => ({
    type: SOLICITO_LISTA_EJERCICIO_EXITO,
    payload: data
});

// si hubo un error
const solicitoListaEjerciciosError = (msg) => ({
    type: SOLICITO_LISTA_EJERCICIO_ERROR,
    payload: msg
});