import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, AvanzarImg, CirculoMasMenos, InputM40, AvanzarBtn, ImgB10 } from '../../../../Css/StyledMatematica';

const instrucciones = 'Escribe el anterior y el posterior.';
const miniInstrucciones = 'Escribe el anterior y el posterior.';

const M40 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [countAnterior, setCountAnterior] = useState(0);
    const [countPosterior, setCountPosterior] = useState(0);
    const [numero, setNumero] = useState('');
    const [numeroAnterior, setNumeroAnterior] = useState('');
    const [numeroPosterior, setNumeroPosterior] = useState('');
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [imagenAnterior, setImagenAnterior] = useState('');
    const [imagen, setImagen] = useState('');
    const [imagenPosterior, setImagenPosterior] = useState('');
    const [comparoAnterior, setComparoAnterior] = useState('');
    const [comparoPosterior, setComparoPosterior] = useState('');
    const [agregoAnterior, setAgregoAnterior] = useState('');
    const [agregoPosterior, setAgregoPosterior] = useState('');
    const [imagenesDados, setImagenesDados] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [erroresOrden, setErroresOrden] = useState([]);
    const [idOrden, setIdOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const quitarAnterior = (e) => {
        e.preventDefault();
        if(countAnterior === 0){
            return;
        }
        setCountAnterior(countAnterior - 1)
    }

    const agregarAnterior = (e) => {
        e.preventDefault();
        if(countAnterior === 10){
            return;
        }
        setCountAnterior(countAnterior + 1)
    }

    const quitarPosterior = (e) => {
        e.preventDefault();
        if(countPosterior === 0){
            return;
        }
        setCountPosterior(countPosterior - 1)
    }

    const agregarPosterior = (e) => {
        e.preventDefault();
        if(countPosterior === 10){
            return;
        }
        setCountPosterior(countPosterior + 1)
    }

    const verificar = (e) => {
        e.preventDefault();
        if(comparoAnterior !== agregoAnterior || comparoPosterior !== agregoPosterior || numeroAnterior.toString() !== (comparar[count-1] - 1).toString() || numeroPosterior.toString() !== (comparar[count-1] + 1).toString() || numero.toString() !== (comparar[count-1]).toString()) {
            console.log("mal");
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numero.toString() !== (comparar[count-1]).toString()){
                    setNumero('');
                }
                if(numeroAnterior.toString() !== (comparar[count-1] - 1).toString()){
                    setNumeroAnterior('');
                }
                if(numeroPosterior.toString() !== (comparar[count-1] + 1).toString()){
                    setNumeroPosterior('');
                }
                if(comparoAnterior !== agregoAnterior){
                    setAgregoAnterior('');
                    setCountAnterior(0);
                }
                if(comparoPosterior !== agregoPosterior){
                    setAgregoPosterior('');
                    setCountPosterior(0);
                }
            }, 300);
        } else {
            console.log("bien");
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setNumeroAnterior('');
                    setNumeroPosterior('');
                    setAgregoAnterior('');
                    setCountAnterior(0);
                    setAgregoPosterior('');
                    setCountPosterior(0);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setNumeroAnterior('');
                    setNumeroPosterior('');
                    setAgregoAnterior('');
                    setCountAnterior('');
                    setAgregoPosterior('');
                    setCountPosterior('');                    
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }
 
    useEffect(() => {
        switch (countAnterior) {
            case 0: 
                setAgregoAnterior('dominoCero');
                setImagenAnterior(countAnterior);
                break;
            case 1:
                setAgregoAnterior('dominoUno');
                setImagenAnterior(countAnterior);
                break;
            case 2:
                setAgregoAnterior('dominoDos');
                setImagenAnterior(countAnterior);
                break;
            case 3:
                setAgregoAnterior('dominoTres');
                setImagenAnterior(countAnterior);
                break;
            case 4:
                setAgregoAnterior('dominoCuatro');
                setImagenAnterior(countAnterior);
                break;
            case 5:
                setAgregoAnterior('dominoCinco');
                setImagenAnterior(countAnterior);
                break;
            case 6:
                setAgregoAnterior('dominoSeis');
                setImagenAnterior(countAnterior);
                break;
            case 7:
                setAgregoAnterior('dominoSiete');
                setImagenAnterior(countAnterior);
                break;
            case 8:
                setAgregoAnterior('dominoOcho');
                setImagenAnterior(countAnterior);
                break;
            case 9:
                setAgregoAnterior('dominoNueve');
                setImagenAnterior(countAnterior);
                break;
            case 10:
                setAgregoAnterior('dominoDiez');
                setImagenAnterior(countAnterior);
                break;
            default:
                break;
        }
    }, [countAnterior, lanzoEffect])

    useEffect(() => {
        switch (countPosterior) {
            case 0: 
                setAgregoPosterior('dominoCero');
                setImagenPosterior(countPosterior);
                break;
            case 1:
                setAgregoPosterior('dominoUno');
                setImagenPosterior(countPosterior);
                break;
            case 2:
                setAgregoPosterior('dominoDos');
                setImagenPosterior(countPosterior);
                break;
            case 3:
                setAgregoPosterior('dominoTres');
                setImagenPosterior(countPosterior);
                break;
            case 4:
                setAgregoPosterior('dominoCuatro');
                setImagenPosterior(countPosterior);
                break;
            case 5:
                setAgregoPosterior('dominoCinco');
                setImagenPosterior(countPosterior);
                break;
            case 6:
                setAgregoPosterior('dominoSeis');
                setImagenPosterior(countPosterior);
                break;
            case 7:
                setAgregoPosterior('dominoSiete');
                setImagenPosterior(countPosterior);
                break;
            case 8:
                setAgregoPosterior('dominoOcho');
                setImagenPosterior(countPosterior);
                break;
            case 9:
                setAgregoPosterior('dominoNueve');
                setImagenPosterior(countPosterior);
                break;
            case 10:
                setAgregoPosterior('dominoDiez');
                setImagenPosterior(countPosterior);
                break;
            default:
                break;
        }
    }, [countPosterior, lanzoEffect])

    useEffect(() => {
        const lista = [1,2,3,4,5,6,7,8,9];
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
            
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = 
                [array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const listaRandom = shuffle(lista).slice(0,5);
        setComparar(listaRandom);
        setLanzoEffect(true);
        setIndexes(listaRandom.length);
        setIdOrden(listaRandom);
    }, []);    

    useEffect(() => {
        switch (comparar[count-1]) {
            case 1:
                setImagenAnterior(0);
                setComparoAnterior('dominoCero');
                setImagen(1);
                setImagenPosterior(0);
                setComparoPosterior('dominoDos');
                break;
            case 2:
                setImagenAnterior(0);
                setComparoAnterior('dominoUno');
                setImagen(2);
                setImagenPosterior(0);
                setComparoPosterior('dominoTres');
                break;
            case 3:
                setImagenAnterior(0);
                setComparoAnterior('dominoDos');
                setImagen(3);
                setImagenPosterior(0);
                setComparoPosterior('dominoCuatro');
                break;
            case 4:
                setImagenAnterior(0);
                setComparoAnterior('dominoTres');
                setImagen(4);
                setImagenPosterior(0);
                setComparoPosterior('dominoCinco');
                break;
            case 5:
                setImagenAnterior(0);
                setComparoAnterior('dominoCuatro');
                setImagen(5);
                setImagenPosterior(0);
                setComparoPosterior('dominoSeis');
                break;
            case 6:
                setImagenAnterior(0);
                setComparoAnterior('dominoCinco');
                setImagen(6);
                setImagenPosterior(0);
                setComparoPosterior('dominoSiete');
                break;
            case 7:
                setImagenAnterior(0);
                setComparoAnterior('dominoSeis');
                setImagen(7);
                setImagenPosterior(0);
                setComparoPosterior('dominoOcho');
                break;
            case 8:
                setImagenAnterior(0);
                setComparoAnterior('dominoSiete');
                setImagen(8);
                setImagenPosterior(0);
                setComparoPosterior('dominoNueve');
                break;
            case 9:
                setImagenAnterior(0);
                setComparoAnterior('dominoOcho');
                setImagen(9);
                setImagenPosterior(0);
                setComparoPosterior('dominoDiez');
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect])

    useEffect(() => {
        dispatch(setearEjercicioAction('M40') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/ejercitacion-1-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M40",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrden,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <FlexFull direction="column" height="100%" width="100%" align="center">
                    <FlexFull direction='column' margin="auto" width="100%" justify='space-evenly' align='center'
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <FlexFull width='55%' wm='45%' wt='40%' wd='40%' wlg='40%' justify='space-evenly' align='center'>
                            <FlexFull direction='column' justify='center' align='center'>
                                <div>
                                    <ImgB10 src={`/img/dado_verde_${imagenAnterior}.png`} w='16vw' wm='13vw' wt='11vw' wd='9vw' wlg='8vw' bradius='6px' />
                                </div>
                                <div>
                                    <CirculoMasMenos
                                        disabled={disable}
                                        onClick={(e) => quitarAnterior(e)}
                                    >
                                        -
                                    </CirculoMasMenos>
                                    <CirculoMasMenos
                                        disabled={disable}
                                        onClick={(e) => agregarAnterior(e)}
                                    >
                                        +
                                    </CirculoMasMenos>
                                </div>
                            </FlexFull>
                            <FlexFull direction='column' justify='center' align='center'>
                                <div>
                                    <ImgB10 src={`/img/dado_amarillo_${imagen}.png`} w='16vw' wm='13vw' wt='11vw' wd='9vw' wlg='8vw' bradius='6px' /> 
                                </div>
                                <div>
                                    <CirculoMasMenos op='0'
                                    >
                                        -
                                    </CirculoMasMenos>
                                    <CirculoMasMenos op='0'
                                    >
                                        +
                                    </CirculoMasMenos>
                                </div>
                            </FlexFull>
                            <FlexFull direction='column' justify='center' align='center'>
                                    <div>
                                        <ImgB10 src={`/img/dado_rojo_${imagenPosterior}.png`}  w='16vw' wm='13vw' wt='11vw' wd='9vw' wlg='8vw' bradius='6px' />
                                    </div>
                                    <div>
                                        <CirculoMasMenos
                                            disabled={disable}
                                            onClick={(e) => quitarPosterior(e)}
                                        >
                                            -
                                        </CirculoMasMenos>
                                        <CirculoMasMenos
                                            disabled={disable}
                                            onClick={(e) => agregarPosterior(e)}
                                        >
                                            +
                                        </CirculoMasMenos>
                                    </div>
                            </FlexFull>
                        </FlexFull>

                        <FlexFull width='55%' wm='45%' wt='40%' wd='40%' wlg='40%' justify='space-evenly' align='center'>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM40 type="numeric" value={numeroAnterior} onChange={(e) => setNumeroAnterior(e.target.value)} disabled={disable} />
                            </form>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM40 type="numeric" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                            </form>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM40 type="numeric" value={numeroPosterior} onChange={(e) => setNumeroPosterior(e.target.value)} disabled={disable} />
                            </form>
                        </FlexFull>
                    </FlexFull>
                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </FlexFull>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M40;