import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// css
import {CuerpoCompleto} from '../Css/StyledHeader.js'
import {FlexTabla, TextoCorto, FlechaAbajo} from '../Css/StyledPatient.js'

import { setearIdPacienteAction } from '../../actions/setearIdPacienteActions';
import { resetearConfigPacienteAction } from '../../actions/resetStoreActions';

import useReturnInitialState from '../../Hooks/useReturnInitialState.js';

export const TablaPacientes = ({pacientes}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [returnState] = useReturnInitialState();


    const [listaPaciente, setListaPaciente] = useState(pacientes);
    const [orden, setOrden] = useState('asc');
    const [tipo, setTipo] = useState('')

    const Ordenar = (e, key, ordeno) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(listaPaciente));
        if(ordeno === 'asc') {
            if(key === 'apellido') {
                newList.sort(function (a, b) {
                    if (a.apellido < b.apellido) {return -1;}
                    if (a.apellido > b.apellido) {return 1;}
                    return 0;
                });
            } else if (key === 'servicio') {
                newList.sort(function (a, b) {
                    if (a.servicio < b.servicio) {return -1;}
                    if (a.servicio > b.servicio) {return 1;}
                    return 0;
                });
            } else if (key === 'cobertura_medica') {
                newList.sort(function (a, b) {
                    if (a.cobertura_medica < b.cobertura_medica) {return -1;}
                    if (a.cobertura_medica > b.cobertura_medica) {return 1;}
                    return 0;
                });
            } else if (key === 'edad') {
                newList.sort(function (a, b) {
                    if (a.edad < b.edad) {return -1;}
                    if (a.edad > b.edad) {return 1;}
                    return 0;
                });
            } else if (key === 'registro') {
                newList.sort(function (a, b) {
                    if (a.registro < b.registro) {return -1;}
                    if (a.registro > b.registro) {return 1;}
                    return 0;
                });
            }
        } else {
            if(key === 'apellido') {
                newList.sort(function (a, b) {
                    if (a.apellido > b.apellido) {return -1;}
                    if (a.apellido < b.apellido) {return 1;}
                    return 0;
                });
            } else if (key === 'servicio') {
                newList.sort(function (a, b) {
                    if (a.servicio > b.servicio) {return -1;}
                    if (a.servicio < b.servicio) {return 1;}
                    return 0;
                });
            } else if (key === 'cobertura_medica') {
                newList.sort(function (a, b) {
                    if (a.cobertura_medica > b.cobertura_medica) {return -1;}
                    if (a.cobertura_medica < b.cobertura_medica) {return 1;}
                    return 0;
                });
            } else if (key === 'edad') {
                newList.sort(function (a, b) {
                    if (a.edad > b.edad) {return -1;}
                    if (a.edad < b.edad) {return 1;}
                    return 0;
                });
            } else if (key === 'registro') {
                newList.sort(function (a, b) {
                    if (a.registro > b.registro) {return -1;}
                    if (a.registro < b.registro) {return 1;}
                    return 0;
                });
            }
        }
        setListaPaciente(newList);
    }

    const seteoIdPaciente = (id, nombre, apellido) => {
        dispatch( resetearConfigPacienteAction() );
        const informacion = {
            payload: {
                id,
                nombre,
                apellido
            }
        }
        dispatch( setearIdPacienteAction(informacion) );
        navigate(`/escritorio/pacientes/paciente`);
    }    

    useEffect(() => {
        setListaPaciente(pacientes);
    }, [pacientes])

    return ( 
        <CuerpoCompleto>
            <FlexTabla direction="column">
                <FlexTabla bgcolor="#E5E5E5" borderradius="10px" justify="space-between" align="center" margin="0.5rem 0 0 1rem" padding="0.7rem 1rem" width="10rem">
                    <TextoCorto>
                        Fecha de creación
                    </TextoCorto>
                    <FlechaAbajo alignself="center" flecha={tipo === 'registro' ? true : false} ordenb={orden === 'desc' ? true : false} ordent={orden === 'asc' ? true : false} bgcolor={tipo === 'registro' ? false : true}
                        onClick={(e) => (Ordenar(e, "registro", orden === 'asc' ? 'desc' : 'asc'), setTipo('registro'), orden === 'asc' ? setOrden('desc') : setOrden('asc'))}
                    ></FlechaAbajo>
                </FlexTabla>
                <FlexTabla>
                    <FlexTabla direction="column"
                    xlg_margin="0.5rem 1rem 0rem 1rem" xlg_width="93%"
                    lg_margin="0.5rem 1rem 0rem 1rem" lg_width="93%"
                    dkt_margin="0.5rem 1rem 0rem 1rem" dkt_width="93%"
                    tb_margin="0.5rem 1rem 0rem 1rem" tb_width="93%"
                    min_margin="0.5rem 1rem 0rem 1rem" min_width="93%"
                    >
                        <FlexTabla>
                            <FlexTabla bgcolor="#E5E5E5" borderradius="10px" justify="space-between"
                            xlg_margin="0rem 0.5rem 0rem 0" xlg_padding="0.7rem 1rem" xlg_width="35%"
                            lg_margin="0rem 0.5rem 0rem 0" lg_padding="0.7rem 1rem" lg_width="35%"
                            dkt_margin="0rem 0.5rem 0rem 0" dkt_padding="0.7rem 1rem" dkt_width="35%"
                            tb_margin="0rem 0.5rem 0rem 0" tb_padding="0.7rem 1rem" tb_width="35%"
                            min_margin="0rem 0.5rem 0rem 0" min_padding="0.7rem 1rem" min_width="35%"
                            >
                                <TextoCorto>
                                    Apellido, Nombre
                                </TextoCorto>
                                <FlechaAbajo alignself="center" flecha={tipo === 'apellido' ? true : false} ordenb={orden === 'desc' ? true : false} ordent={orden === 'asc' ? true : false} bgcolor={tipo === 'apellido' ? false : true}
                                    onClick={(e) => (Ordenar(e, "apellido", orden === 'asc' ? 'desc' : 'asc'), setTipo('apellido'), orden === 'asc' ? setOrden('desc') : setOrden('asc'))}
                                ></FlechaAbajo>
                            </FlexTabla>
                            
                            
                            <FlexTabla bgcolor="#E5E5E5" borderradius="10px" justify="space-between"
                            xlg_margin="0rem 0.5rem 0rem 0" xlg_padding="0.7rem 1rem" xlg_width="25%"
                            lg_margin="0rem 0.5rem 0rem 0" lg_padding="0.7rem 1rem" lg_width="25%"
                            dkt_margin="0rem 0.5rem 0rem 0" dkt_padding="0.7rem 1rem" dkt_width="25%"
                            tb_margin="0rem 0.5rem 0rem 0" tb_padding="0.7rem 1rem" tb_width="25%"
                            min_margin="0rem 0.5rem 0rem 0" min_padding="0.7rem 1rem" min_width="25%"
                            >
                                <TextoCorto>
                                    Servicio
                                </TextoCorto>
                                <FlechaAbajo alignself="center" flecha={tipo === 'servicio' ? true : false} ordenb={orden === 'desc' ? true : false} ordent={orden === 'asc' ? true : false} bgcolor={tipo === 'servicio' ? false : true}
                                    onClick={(e) => (Ordenar(e, "servicio", orden === 'asc' ? 'desc' : 'asc'), setTipo('servicio'), orden === 'asc' ? setOrden('desc') : setOrden('asc'))}
                                ></FlechaAbajo>
                            </FlexTabla>
                            <FlexTabla bgcolor="#E5E5E5" borderradius="10px" justify="space-between"
                            xlg_margin="0rem 0.5rem 0rem 0" xlg_padding="0.7rem 1rem" xlg_width="33%"
                            lg_margin="0rem 0.5rem 0rem 0" lg_padding="0.7rem 1rem" lg_width="33%"
                            dkt_margin="0rem 0.5rem 0rem 0" dkt_padding="0.7rem 1rem" dkt_width="33%"
                            tb_margin="0rem 0.5rem 0rem 0" tb_padding="0.7rem 1rem" tb_width="33%"
                            min_margin="0rem 0.5rem 0rem 0" min_padding="0.7rem 1rem" min_width="33%"
                            >
                                <TextoCorto>
                                    Cobertura Médica
                                </TextoCorto>
                                <FlechaAbajo alignself="center" flecha={tipo === 'cobertura_medica' ? true : false} ordenb={orden === 'desc' ? true : false} ordent={orden === 'asc' ? true : false} bgcolor={tipo === 'cobertura_medica' ? false : true}
                                    onClick={(e) => (Ordenar(e, "cobertura_medica", orden === 'asc' ? 'desc' : 'asc'), setTipo('cobertura_medica'), orden === 'asc' ? setOrden('desc') : setOrden('asc'))}
                                ></FlechaAbajo>
                            </FlexTabla>
                            <FlexTabla bgcolor="#E5E5E5" borderradius="10px" justify="space-between"
                            xlg_margin="0rem 0rem 0rem 0" xlg_padding="0.7rem 1rem" xlg_width="7%"
                            lg_margin="0rem 0rem 0rem 0" lg_padding="0.7rem 1rem" lg_width="7%"
                            dkt_margin="0rem 0rem 0rem 0" dkt_padding="0.7rem 1rem" dkt_width="7%"
                            tb_margin="0rem 0rem 0rem 0" tb_padding="0.7rem 1rem" tb_width="7%"
                            min_margin="0rem 0rem 0rem 0" min_padding="0.7rem 1rem" min_width="7%"
                            >
                                <TextoCorto>
                                    Edad
                                </TextoCorto>
                                <FlechaAbajo alignself="center" flecha={tipo === 'edad' ? true : false} ordenb={orden === 'desc' ? true : false} ordent={orden === 'asc' ? true : false} bgcolor={tipo === 'edad' ? false : true}
                                    onClick={(e) => (Ordenar(e, "edad", orden === 'asc' ? 'desc' : 'asc'), setTipo('edad'), orden === 'asc' ? setOrden('desc') : setOrden('asc'))}
                                ></FlechaAbajo>
                            </FlexTabla>
                        </FlexTabla>
                        <div>
                            {listaPaciente.map((item,i) => (
                                <FlexTabla border="2px solid #FF9B2A" borderradius="10px" boxshadow="2px 4px 2px 1px rgba(229, 229, 229, 1)"
                                key={i}
                                xlg_margin="1.8rem 0rem 0rem 0" xlg_padding="0.5rem 0.2rem 0.5rem 0.4rem"
                                lg_margin="1.8rem 0rem 0rem 0" lg_padding="0.5rem 0.2rem 0.5rem 0.4rem"
                                dkt_margin="1.8rem 0rem 0rem 0" dkt_padding="0.5rem 0.2rem 0.5rem 0.4rem"
                                tb_margin="1.8rem 0rem 0rem 0" tb_padding="0.5rem 0.2rem 0.5rem 0.4rem"
                                min_margin="1.8rem 0rem 0rem 0" min_padding="0.5rem 0.2rem 0.5rem 0.4rem"
                                >
                                    <TextoCorto textalign="start" borderright="1px solid black" cursor="pointer"
                                    xlg_margin="0rem 0 0rem 0" xlg_padding="0.3rem 0rem 0.3rem 0.4rem" xlg_width="33%"
                                    lg_margin="0rem 0 0rem 0" lg_padding="0.3rem 0rem 0.3rem 0.4rem" lg_width="33%"
                                    dkt_margin="0rem 0 0rem 0" dkt_padding="0.3rem 0rem 0.3rem 0.4rem" dkt_width="33%"
                                    tb_margin="0rem 0 0rem 0" tb_padding="0.3rem 0rem 0.3rem 0.4rem" tb_width="33%"
                                    min_margin="0rem 0 0rem 0" min_padding="0.3rem 0rem 0.3rem 0.4rem" min_width="33%"
                                        onClick={() => seteoIdPaciente(item.id_paciente, item.nombre, item.apellido)}
                                    >
                                        {item.apellido}, {item.nombre}
                                    </TextoCorto>
                                    <TextoCorto textalign="start" borderright="1px solid black" cursor="pointer"
                                    xlg_margin="0rem 0 0rem 0" xlg_padding="0.3rem 0rem 0.3rem 0.4rem" xlg_width="25%"
                                    lg_margin="0rem 0 0rem 0" lg_padding="0.3rem 0rem 0.3rem 0.4rem" lg_width="25%"
                                    dkt_margin="0rem 0 0rem 0" dkt_padding="0.3rem 0rem 0.3rem 0.4rem" dkt_width="25%"
                                    tb_margin="0rem 0 0rem 0" tb_padding="0.3rem 0rem 0.3rem 0.4rem" tb_width="25%"
                                    min_margin="0rem 0 0rem 0" min_padding="0.3rem 0rem 0.3rem 0.4rem" min_width="25%"
                                    >
                                        {item.servicio}
                                    </TextoCorto>
                                    <TextoCorto textalign="start" borderright="1px solid black" cursor="pointer"
                                    xlg_margin="0rem 0 0rem 0" xlg_padding="0.3rem 0rem 0.3rem 0.4rem" xlg_width="33%"
                                    lg_margin="0rem 0 0rem 0" lg_padding="0.3rem 0rem 0.3rem 0.4rem" lg_width="33%"
                                    dkt_margin="0rem 0 0rem 0" dkt_padding="0.3rem 0rem 0.3rem 0.4rem" dkt_width="33%"
                                    tb_margin="0rem 0 0rem 0" tb_padding="0.3rem 0rem 0.3rem 0.4rem" tb_width="33%"
                                    min_margin="0rem 0 0rem 0" min_padding="0.3rem 0rem 0.3rem 0.4rem" min_width="33%"
                                    >
                                        {item.cobertura_medica}
                                    </TextoCorto>
                                    <TextoCorto textalign="center" cursor="pointer"
                                    xlg_margin="0rem 0 0rem 0" xlg_padding="0.3rem 0rem 0.3rem 0.4rem" xlg_width="9%"
                                    lg_margin="0rem 0 0rem 0" lg_padding="0.3rem 0rem 0.3rem 0.4rem" lg_width="9%"
                                    dkt_margin="0rem 0 0rem 0" dkt_padding="0.3rem 0rem 0.3rem 0.4rem" dkt_width="9%"
                                    tb_margin="0rem 0 0rem 0" tb_padding="0.3rem 0rem 0.3rem 0.4rem" tb_width="9%"
                                    min_margin="0rem 0 0rem 0" min_padding="0.3rem 0rem 0.3rem 0.4rem" min_width="9%"
                                    >
                                        {item.edad} a {item.mes} m
                                    </TextoCorto>
                                </FlexTabla>
                            ))}
                        </div>
                    </FlexTabla>
                    <FlexTabla
                    xlg_margin="0rem 0rem 0rem 0rem" xlg_width="7%"
                    lg_margin="0rem 0rem 0rem 0rem" lg_width="7%"
                    dkt_margin="0rem 0rem 0rem 0rem" dkt_width="7%"
                    tb_margin="0rem 0rem 0rem 0rem" tb_width="7%"
                    min_margin="0rem 0rem 0rem 0rem" min_width="7%"
                    >
                        <Link to={"/escritorio/pacientes/nuevo-paciente"} style={{ textDecoration: 'none' }}>
                            <TextoCorto fontsize="3rem" cursor="pointer" color="#000000"
                                onClick={() => returnState(['estado.error','estado.loading'])}
                            >
                                    +
                            </TextoCorto>
                        </Link>
                    </FlexTabla>
                </FlexTabla>
            </FlexTabla>
        </CuerpoCompleto>
    );
}
 