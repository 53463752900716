import React, { Fragment } from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, BtnColores } from '../../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../../Css/StyledHeader';

const Vocales = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} l3={"letras/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} n3={"LETRAS"} componente={"VOCALES"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Container justify="space-around" height="100%" width="100%" align="center">
            <div>
                <BtnColores bgcolor="#23AAAF" to={'/escritorio/metodos/lecto-escritura/letras/vocales/introduccion'}>Introducción</BtnColores>
            </div>
            <div>
                <BtnColores bgcolor="#FF9B2A" to={'/escritorio/metodos/lecto-escritura/letras/vocales/repaso'}>Repaso</BtnColores>
            </div>
            <div>
                <BtnColores bgcolor="#FFDC73" to={'/escritorio/metodos/lecto-escritura/letras/vocales/fluidez-vocales'}>Fluidez</BtnColores>
            </div>
            </Container>
        </Fragment>
    );
}
 
export default Vocales;