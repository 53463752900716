import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';

import { ayudaVisualAction } from '../../../../../actions/configuradorActions';

import { Flex, BtnAyudaCuad, Cursor, ImgB10, NumerosFlexxx } from '../../../../Css/StyledMatematica'

const AyudaCuadricula = () => {

    const dispatch = useDispatch();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [numeros, setNumeros] = useState([]);

    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);

    const customStyles = {
        content: {
            margin: 'auto',
            width: '70%',
            height: '70%'
        }
    }

    const openModal = (e) => {
        e.preventDefault();
        setIsOpen(true);
        if(ayuVisualSolicitada){
            return;
        } else {
            dispatch( ayudaVisualAction(true) );
        }
    }
    const closeModal = (e) => {
        e.preventDefault();
        setIsOpen(false);
    }

    useEffect(() => {
        const arr = Array.from(Array(100).keys())
        setNumeros(arr);
    }, [])

    return (
        <Fragment>
            <Cursor>
                <BtnAyudaCuad onClick={(e) => openModal(e)}>?</BtnAyudaCuad>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                    {/* <button onClick={(e) => closeModal(e)}>X</button> */}
                    {/* <NumerosFlexxx>
                        {numeros.map((item,i) => 
                        <div key={i}>{item}</div>
                        )}
                    </NumerosFlexxx> */}
                    <Flex height='96%'>
                        <NumerosFlexxx>
                            <ImgB10 src={`/img/castillo.png`} w='330px' wm='340px' wt='450px' wd='450px' wlg='450px' h='350px' hlg='350px' style={{zIndex:0}}/>
                        </NumerosFlexxx>
                    </Flex>
                </Modal>
            </Cursor>
        </Fragment>
    );
}
 
export default AyudaCuadricula;