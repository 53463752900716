import styled from 'styled-components'
import {
    Link
  } from "react-router-dom"; 
import {createGlobalStyle} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`
export const Cuerpo = styled.div`
    display:flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
`
export const Titulo = styled.h1`
    font-size: 18px;
    padding-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
`

export const ContenedorLogin = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`
export const ContenedorHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

// IMÁGENES
export const Img = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin};
    background-color:  ${props => props.bg};
    border-radius:  ${props => props.radius};

    ${media.tablet}{
    width: ${props => props.widthmob};
    height: ${props => props.heightmob};
    margin: ${props => props.marginMob};
     }

`
export const Ilustracion = styled (Img)`
    padding: 0.5rem;
    ${media.tablet}{
       width: ${props => props.ancho}
        }
    `
export const FalsoDiv = styled.div`
    width: 40vw;
`
export const IlusNuevaCuenta = styled (Img)`
${media.tablet}{
    display:none;
     }
`
export const Logo = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
`
export const ContenedorLogo = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    margin-left: 2rem;
`
export const ContenedorForm = styled.div`
    display:flex;
    justify-content:space-between;
    height: 100%;
`
// FORMULARIO
export const Formulario = styled.div`
    padding-top: ${props => props.padding};
    display:flex;
    flex-direction:column;
    align-items:center;
    align-self: center;
    margin: ${props => props.margin};
    ${media.tablet} {
        margin: ${props => props.margintablet};
    }
`
export const Input = styled.input`
    border:0;
    background-color:#f2f2f2;
    padding: 7px;
    border-radius: 15px;
    margin: 0px 0px 0px 7px;
    width: ${props => props.width};
`
export const InputLarge = styled (Input)`
    padding:${props => props.padding};
    margin: ${props => props.margin};
    box-shadow: 0px 14px 5px -10px rgba(0, 0, 0, 0.2);
    width:${props => props.width};
`
export const Label = styled.label`
    background-color:#f2f2f2;
    box-shadow: 0px 14px 5px -10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: ${props => props.margin};
    padding:${props => props.padding};
    font-size: 12px;
    width:${props => props.width};
    display: flex;
    align-items: center;
    justify-content: left;
    
`;
export const LabelRes = styled(Label)`
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.tablet}{
        width:45vw;
}
`
export const Form = styled.form`
    display:flex;
    flex-direction:column;
    margin: ${props => props.margin};
    ${media.tablet} {
        margin: ${props => props.margintablet};
    }
`

export const Div = styled.div`
    display:flex;
    flex-direction:column;
`

export const Select = styled.select`
    background: transparent;
     border: none;
     font-size: 12px;
     height: 30px;
     padding: 5px;
     width: 245px;
     font-size: 12px;
     font-family: 'Montserrat', sans-serif;
`
export const SelectLocalidad = styled.select`
    background: transparent;
    border: none;
    font-size: 14px;
    height: 30px;
    padding: 5px;
    width: 118px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    `
export const FechaDeNacimiento = styled(Input)`
    padding: 0;
    font-size: 11px;
    `
// BOTONES
export const Boton = styled.div`
    display:flex;
    justify-content:center;
    margin: 20px;
`

export const Button = styled.button`
    padding:10px 60px 10px 60px;
    border-radius: 10px;
    border:0;
    background:#23AAAF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color:white;
    font-weight: 600;
    font-size: 16px;
    margin:${props => props.margin};
    &:hover{
        background: #78e2db;
    }
`
export const LogIn = styled (Link)`
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    background: #BE6061;
    text-decoration: none;
    border-radius: 15px;
    color:black;
    padding: 5px 15px 5px 15px;
    margin-right: 2rem;
    font-size:14px;
    box-shadow: 1px 4px 3px 0px rgba(0, 0, 0, 0.2);
    margin:${props => props.margin};
    &:hover{
        background: #df9293;
    }

`
export const TerminosCondiciones = styled.button`
    border:none;
    text-decoration: none;
    color:#64c2c8;
    &:hover{
        color: #9cc7ca;
    }
    background-color:white;
`
export const Enlace = styled (Link)`
    text-decoration:none;
    color: black;
    font-size: 12px;
    margin-top: 1.5rem;
    &:hover{
        color: #666;
    }
`
export const FixedAlert = styled.div`
    position: fixed;
    border: 3px solid #45B2B6;
    border-radius: 5px;
    transform: ${(props) => props.transform};
`

export const UlAlert = styled.ul`
    padding-right: 5px;
    font-family: nunito;

`
export const ButtonTipo = styled.button`
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    background: #FF9B2A;
    text-decoration: none;
    border-radius: 15px;
    color:black;
    font-size:14px;
    box-shadow: 1px 4px 3px 0px rgba(0, 0, 0, 0.2);
    width: 10rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
`

// MODAL TÉRMINOS Y CONDICIONES
export const Texto = styled.p`
    font-family: 'Montserrat', sans-serif;
`
export const StyledModal = styled.div`
    width:80vw;
    padding: 20px;
`
// FLEXBOX
export const Flex = styled.div`
    display:flex;
    width: ${props => props.width}
`
export const FlexColumn = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`
export const Alinear = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
`
// TEXTOS
export const Error = styled.p`
    background: #F9B875;
    border-radius: 10px;
    padding: 5px;
`