import {
    RESTABLECER_PASSWORD,
    RESTABLECER_PASSWORD_EXITO,
    RESTABLECER_PASSWORD_ERROR
} from '../types';
import clienteAxios from '../config/axios';

import Swal from 'sweetalert2';

// Restablece la contraseña
export function restablecerPasswordAction(informacion) {
    return async (dispatch) => {
        dispatch( restablecerPassword() );
        try {
            const {payload} = informacion;
            const respuesta = await clienteAxios.post('/auth/restablecer', payload);
            dispatch( restablecerPasswordExito(respuesta.data) );

            //alerta
            Swal.fire({
                icon: 'success',
                title:'La solicitud ha sido un éxito',
                text: `${respuesta.data.msg}`,
            }).then(function() {
                window.location = "https://metodoyogen.com/reset-password";
            });

        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( restablecerPasswordError(error.response.data) );
            }
        }
    }
}

const restablecerPassword = () => ({
    type: RESTABLECER_PASSWORD,
    payload: true
});

// si solicita con éxito restablecer password
const restablecerPasswordExito = () => ({
    type: RESTABLECER_PASSWORD_EXITO
});

// si hubo un error
const restablecerPasswordError = (msg) => ({
    type: RESTABLECER_PASSWORD_ERROR,
    payload: msg
});