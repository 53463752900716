import React, {Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CualEsMayor from '../../../Compartidos/CualEsMayor';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const modelo = [
    {id:'1', numero_1:'', numero_2:'', cantidad_1:'', cantidad_2:'', operatoria: true, mayor:''},
    {id:'2', numero_1:'', numero_2:'', cantidad_1:'', cantidad_2:'', operatoria: true, mayor:''},
    {id:'3', numero_1:'', numero_2:'', cantidad_1:'', cantidad_2:'', operatoria: true, mayor:''},
    {id:'4', numero_1:'', numero_2:'', cantidad_1:'', cantidad_2:'', operatoria: true, mayor:''},
    {id:'5', numero_1:'', numero_2:'', cantidad_1:'', cantidad_2:'', operatoria: true, mayor:''}
]

const instrucciones = 'Indica qué número es mayor.';
const miniInstrucciones = 'Indica qué número es mayor.';

const M9 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(modelo));
        for(let i=0; i < 5; i++){
            const max = 10;
            const min = 3;
            const mayor = Math.floor(Math.random() * (max - min +1)) + min;
            const max2 = mayor-1 ;
            const min2 = 1;
            const menor = Math.floor(Math.random() * (max2 - min2 +1)) + min2;
            const list = [mayor, menor];
    
            function shuffle(array) {
                let currentIndex = array.length, randomIndex;
    
                // While there remain elements to shuffle...
                while (currentIndex !== 0) {
    
                    // Pick a remaining element...
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;
    
                    // And swap it with the current element.
                    [array[currentIndex], array[randomIndex]] = [
                        array[randomIndex], array[currentIndex]];
                }
                return array;
            }
            const shuffleList = shuffle(list);
    
            newList[i].numero_1 = shuffleList[0];
            newList[i].cantidad_1 = shuffleList[0];
            newList[i].numero_2 = shuffleList[1];
            newList[i].cantidad_2 = shuffleList[1];

            if(shuffleList[0] > shuffleList[1]){
                newList[i].mayor = 'dos';
            } else {
                newList[i].mayor = 'uno';
            }
        }
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M9') );
        dispatch(setearUrlAnteriorAction('matematica/subitizacion/nivel-2/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <Fragment>
            <CualEsMayor bgcolor='#FFDC73' data={data} indexes={indexes} ejercicio="M9" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} minMay={false} estrellas={true} carrito={true} />
        </Fragment>
    );
}
 
export default M9;