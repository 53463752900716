import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useColorHelp from '../../../../../Hooks/useColorHelp';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { Palabra, Oracion } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { ContainerFlex, FormInput } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';
import { TextArea } from '../../../../Css/MetodosCSS/StyledEjercicios';

const instrucciones = 'Escribe la palabra con los espacios correspondientes.';
const miniInstrucciones = 'Escribe la palabra con los espacios correspondientes.';

const ESC12 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [count, setCount] = useState(1);
    const [frase, setFrase] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [arrayColor, transformarColor] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    useEffect(() => {
        if(comparar === undefined || comparar.length === 0){
            return;
        }
        transformarColor(comparar.frase);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, comparar, ayuda_lectura])

    const verificar = (e) => {
        e.preventDefault();
        if(minuscula) {
            if (frase !== comparar.frase_respuesta) {
                setDisable(true);
                setIsIncorrect(true);
                setContarErrores(contarErrores + 1);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setFrase('');
                }, 1000);
            } else {
                console.log('bien');
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if (count < indexes) {
                    setDisable(false);
                    setFrase('');
                    setCount(count + 1);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch(finalizarEjercicioAction());
                    }, 1500);
                }
            }
        } else {
            if (!minuscula && frase.toUpperCase() !== comparar.frase_respuesta.toUpperCase()){
                setDisable(true);
                setIsIncorrect(true);
                setContarErrores(contarErrores + 1);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setFrase('');
                }, 1000);
            } else {
                console.log('bien');
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if (count < indexes) {
                    setDisable(false);
                    setFrase('');
                    setCount(count + 1);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch(finalizarEjercicioAction());
                    }, 1500);
                }
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC12') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/oraciones/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC12",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center" align="center"
                    isCorrect={isCorrect} isIncorrect={isIncorrect}
                >
                    <ContainerFlex display="flex" direction="column" justify="center" align="center" margin="auto">
                        <ContainerFlex display="flex" justify="center" width="100%">
                            <Palabra width="100%" height="20vh" bg="rgba(35, 170, 175, 1)" shadow= "0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" display="flex" align="center" justify="center">
                                <ContainerFlex width="100%" borderTop="1px solid black" borderBottom="1px solid black" display="flex" justify="center" padding="0.5rem 0 0 0">
                                    <Oracion display="flex" justify="center" align="center" font="2.5rem" padding="0.3rem 0 0 0" width="100%" $minMay={minuscula}>
                                    {arrayColor === '' ? null :
                                        arrayColor.map((item, i) =>
                                            <span
                                            key={i}
                                            >
                                            {item.map((itemInterior, i) =>
                                                <span style={{color: itemInterior.color}}
                                                key={i}
                                                >
                                                    {itemInterior.letra}
                                                </span>
                                            )}
                                            &nbsp;
                                            </span>
                                        )
                                    }
                                    </Oracion>
                                </ContainerFlex>
                            </Palabra>
                        </ContainerFlex>
                        <ContainerFlex display="flex"  margintop="3.5rem" width="100%">
                            <FormInput width="100%"
                                onSubmit={(e) => verificar(e)}
                            >
                                <TextArea width="100%" height="19vh" radius="10px" font="2rem" shadow="inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)" $minMay={minuscula} type="text" value={frase} onChange={(e) => setFrase(e.target.value)} disabled={disable} />
                            </FormInput>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlex>
                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC12;