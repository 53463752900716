import styled from 'styled-components';
import {createGlobalStyle} from 'styled-components';
import {
    Link
  } from "react-router-dom"; 

const media = {
    min: '@media (min-width:505px) and (max-width:699px)',
    tablet: '@media (min-width:700px) and (max-width:996px)',
    desktop: '@media(min-width:996px) and (max-width:1200px)',
    desktopLg: '@media (min-width:1200px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`


// IMÁGENES
export const LogoBackground = styled.img`
    width: ${props => props.width};
    margin: ${props => props.margin};
`
export const Background = styled.div`
    background-image: url('/img/fondo.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
`
export const BackgroundLecto = styled.div`
    height: 100%;
    background-image: url('/img/backgroundLecto.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 30px 15px 0px 15px;
`

export const BackgroundLenguaje = styled.div`
    height: 100%;
    background-image: url('/img/portadas/lenguaje_caratula_principal.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 30px 15px 0px 15px;
`
// CONTENEDORES
export const Container = styled.div`
    display: flex;
    justify-content: ${props => props.justify};
    flex-direction: ${props => props.direction};
    align-items: ${props => props.align};
    align-content: ${props => props.alignCont};
    margin: ${props => props.margin};  
    height: ${props => props.height};  
    flex-wrap: ${props => props.wrap};

    ${media.min}{
        display: flex;
        justify-content: ${props => props.justify};
        flex-direction: ${props => props.direction};
        align-items: ${props => props.align};
        align-content: ${props => props.alignCont};
        margin: ${props => props.margin};  
        height: ${props => props.height};  
        flex-wrap: ${props => props.wrap};
        }
    ${media.tablet}{
        display: flex;
        justify-content: ${props => props.justify};
        flex-direction: ${props => props.direction};
        align-items: ${props => props.align};
        align-content: ${props => props.alignCont};
        margin: ${props => props.margin};  
        height: ${props => props.height};  
        flex-wrap: ${props => props.wrap};
        }
    ${media.desktop}{
        display: flex;
        justify-content: ${props => props.justify};
        flex-direction: ${props => props.direction};
        align-items: ${props => props.align};
        align-content: ${props => props.alignCont};
        margin: ${props => props.margin};  
        height: ${props => props.height};  
        flex-wrap: ${props => props.wrap};
        }
    ${media.desktopLg}{
        display: flex;
        justify-content: ${props => props.justify};
        flex-direction: ${props => props.direction};
        align-items: ${props => props.align};
        align-content: ${props => props.alignCont};
        margin: ${props => props.margin};  
        height: ${props => props.height};  
        flex-wrap: ${props => props.wrap};
        }

`
export const ContainerImg = styled.img`
    border:solid 2px #D05F67;
    width: 250px;
    height: 153px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    ${media.tablet}{
        width:200px;
        height: 130px;
    }
`
export const FlexLink = styled (Link)`
    display:flex;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    color: black;
    
`
export const Flex = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    height: ${props => props.height};
    width: ${props => props.width};
    flex-grow: ${props => props.$flexgrow};
`
// TEXTOS
export const Texto = styled.p`
    font-weight:${props => props.weight};
    color: ${props => props.color};
    margin: ${props => props.margin};
`
export const Titulo = styled.h1`
    font-family: nunito;
    text-transform: uppercase;
    font-weight: 300;
    color: #646464;
    margin: 0 0 2rem 0;
`
export const Consignas = styled.p`
    font-family: nunito;
    text-transform: uppercase;
    font-weight: 300;
    color: #646464;
    margin: 150px 20px 0px 20px;
    width: 180px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    height: 10vh;
`
export const Consigna = styled.p`
    font-family: nunito;
    text-transform: uppercase;
    font-weight: 300;
    color: #646464;
    margin: 0 10rem 2rem 10rem;
`
export const Hr = styled.hr`
width: 120px;
`
export const Descripcion = styled.p`
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
font-weight: 700;
`
// BOTONES
export const BtnMethods = styled.button`
    width: 213px;
    height: 74px;
    background: #23AAAF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border:0;
    margin: 0.5rem;
    color:black;
    
`
export const BtnBottom = styled.button`
    border: 0; 
    background: transparent;
    &:hover{
        transform: scale(1.1);
    }
`
export const BtnLecto = styled.div`
    background-color:#FFFFFF;
    width: 25vw;
    height: 78px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border:0;
    margin-top: 70px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align:center;
`
export const BtnLinkLecto = styled(Link)`
    text-decoration:none;
    text-transform: uppercase;
    color: white;
    background-color:${props => props.bgcolor};
    width: 283px;
    height: 116px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
`
export const BtnLink = styled (Link)`
    color: black;
    font-size: 18px;
    text-decoration:none;
    text-transform: uppercase;
`
export const BtnColores = styled (BtnLinkLecto)`
    background-color:${props => props.bgcolor};
    width: 243px;
    height: 112px;
    font-size: 26px;
    font-size: ${props => props.font};
    margin: ${props => props.margin};
`
export const Btn = styled.button`
    border: 0;
    background: transparent; 
    margin: ${props => props.margin};
    cursor: pointer;
`
