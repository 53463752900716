import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, NumberM38, ButtonM38, AvanzarImg, AvanzarBtn} from '../../../../../Css/StyledMatematica';

const instrucciones = 'A continuación observarás un número. Deberás seleccionar dos números que juntos formen el número.';
const miniInstrucciones = 'Seleccionar dos números que juntos formen el número.';

const info = [
    {id:1, numero: '', index: ''},
    {id:2, numero: '', index: ''},
]

const M38 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [randomNumeros, setRandomNumeros] = useState([]);
    const [numerosElegidos, setNumerosElegidos] = useState();
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if(!numerosElegidos[0].numero || !numerosElegidos[1].numero) {
            return;
        }
        if(comparar[count-1] !== (numerosElegidos[0].numero + numerosElegidos[1].numero)) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                const newList = JSON.parse(JSON.stringify(randomNumeros));
                newList[numerosElegidos[0].index].bgColor = '';
                newList[numerosElegidos[1].index].bgColor = '';
                setRandomNumeros(newList);
                setNumerosElegidos(info);
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumerosElegidos(info);
                    setRandomNumeros([]);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumerosElegidos(info);
                    setRandomNumeros([]);
                }, 1500);
            }
        }
    }

    const asignarNumero = (e, nro, i) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(randomNumeros));
        const newList2 = JSON.parse(JSON.stringify(numerosElegidos));
        const buscarIndex = newList2.filter(obj => {
            return obj.index === i
        });
        
        if(buscarIndex.length !== 0){
            if(buscarIndex[0].id === 1){
                newList2[0].numero = '';
                newList2[0].index = '';
                newList[i].bgColor = '';
                setRandomNumeros(newList);
                setNumerosElegidos(newList2);
            } else {
                newList2[1].numero = '';
                newList2[1].index = '';
                newList[i].bgColor = '';
                setRandomNumeros(newList);
                setNumerosElegidos(newList2);
            }
        } else if(!newList2[0].numero) {
            newList2[0].numero = nro;
            newList2[0].index = i;
            newList[i].bgColor = '#23AAAF';
            setRandomNumeros(newList);
            setNumerosElegidos(newList2);
        } else {
            newList2[1].numero = nro;
            newList2[1].index = i;
            newList[i].bgColor = '#23AAAF';
            setRandomNumeros(newList);
            setNumerosElegidos(newList2);
        }
    }

    useEffect(() => {
        const newList = [2,3,4,5,6,7,8,9,10];
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList);
        shuffleList.splice(0,4);
        setComparar(shuffleList);
        setIndexes(shuffleList.length);
        setTipoEjercicio('unidad');
        setNumerosElegidos(info);
    }, [])

    useEffect(() => {
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const shuffleList1 = [1,2,3,4,5];
        const shuffleList2 = [1,2,3,4,5];
        const newShuffleList1 = shuffle(shuffleList1);
        const newShuffleList2 = shuffle(shuffleList2);
        const arr = newShuffleList1.concat(newShuffleList2);

        const arrObj = [
            {id:1, nro: arr[0], bgColor: ''},
            {id:2, nro: arr[1], bgColor: ''},
            {id:3, nro: arr[2], bgColor: ''},
            {id:4, nro: arr[3], bgColor: ''},
            {id:5, nro: arr[4], bgColor: ''},
            {id:6, nro: arr[5], bgColor: ''},
            {id:7, nro: arr[6], bgColor: ''},
            {id:8, nro: arr[7], bgColor: ''},
            {id:9, nro: arr[8], bgColor: ''},
            {id:10, nro: arr[9], bgColor: ''},
        ]

        setRandomNumeros(arrObj);
    }, [count])

    useEffect(() => {
        dispatch(setearEjercicioAction('M38') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/ejercitacion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M38",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        {/* NÚMERO */}
                        <div>
                            <NumberM38 color='#E80808' ft='15vw' ftt='12vw' ftd='11vw' ftlg='10vw'>{comparar[count-1]}</NumberM38>
                        </div>
                        {/* FIN NÚMERO */}
                        {/* NÚMEROS RANDOM */}
                        <Flex direction='column' color='#FCF7BC' bradius='10px'>
                            <Flex wrap='wrap'  width='40vw' maxw='40vw' maxh='30vh' margin='0.5vw' bradius='10px' of='hidden'>
                                {randomNumeros.map((item,i) => 
                                <ButtonM38 w='19.5%' h='12vh' m='0.08vw' color={item.bgColor}
                                    key={i}
                                    disabled={disable}
                                    onClick={(e) => asignarNumero(e, item.nro, i)}
                                >     {/*  p='4vh 3vw 4vh 3vw' */}
                                    {item.nro}
                                </ButtonM38>
                                )}
                            </Flex>
                        </Flex>
                        {/* FIN NÚMEROS RANDOM */}
                    </Flex>

                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M38;