import React, {Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import PracticaDecena from '../PracticaDecena';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const data = [
    {id:'1', numero:50},
    {id:'2', numero:51},
    {id:'3', numero:52},
    {id:'4', numero:53},
    {id:'5', numero:54},
    {id:'6', numero:55},
    {id:'7', numero:56},
    {id:'8', numero:57},
    {id:'9', numero:58},
    {id:'10', numero:59}
]

const instrucciones = 'A continuación se presentan números diferenciados por su valor posicional. El niño deberá leer en voz alta dichos números. Si necesita ayuda se puede hacer click sobre el recuadro donde presenta dificultad. Asimismo se puede hacer click sobre el ícono de ayuda.';
const miniInstrucciones = 'Lee los números.';

const M25Bis = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setearEjercicioAction('M25Bis') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
            <PracticaDecena data={data} ejercicio="M25Bis" miniInstrucciones={"EXPRESEAR EL NÚMERO DE MANERA ORAL"} />
        </Fragment>
    );
}
 
export default M25Bis;