import React from 'react';
import styled from '@emotion/styled';

const ContenedorMain = styled.main`
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    background-color: #ffffff;
    @media (min-width: 768px) {
        display: flex;
        text-align: center;
        justify-content: space-between;
    }
`;

// const Boton = styled.button`
//     background-color: violet;
//     color: #ffffff;
//     font-size: 1.6rem;
//     width: 100%;
//     text-transform: uppercase;
//     font-weight: bold;
//     border: none;
//     transition: background-color .3s ease;

//     &:hover {
//         background-color: #26c6da;
//         cursor: pointer;
//     }
// `;

// const FlexContenido = styled.div`
//     @media (min-width: 768px) {
//         flex: 0 0 calc(50% - 1rem);
//     }
// `;

// const ImagenAprendiendo = styled.img`
//     width: 100%;
//     margin-top: 1rem;
//     @media (min-width: 768px) {
//         margin: 0;
//         margin: 1rem;
//         flex: 0 0 calc(50% - 1rem);
//     }
// `; 

const Parrafo = styled.p`
    font-size: 1.6rem;
    text-align: left;
`;

const QuienesSomos = () => {
    return ( 
        <ContenedorMain>
                <Parrafo>Desde quienes somos.</Parrafo>
                
        </ContenedorMain>
    );
}
 
export default QuienesSomos;