import {
    CREAR_PACIENTE,
    CREAR_PACIENTE_EXITO,
    CREAR_PACIENTE_ERROR,
    CREAR_USUARIO_PACIENTE,
    CREAR_USUARIO_PACIENTE_ERROR,
    CREAR_USUARIO_PACIENTE_EXITO
} from '../types';
import { cargarPacientesAction } from './cargarPacientesActions';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// Mando al store los datos de residencia
export function crearPacienteAction(informacion) {
    return async (dispatch) => {
        dispatch( crearPaciente() );
        try {
            const {config, payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/crearpacienteadministrativo', payload,config);
            dispatch( crearPacienteExito(respuesta.data.msg) );
            dispatch (cargarPacientesAction(informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( crearPacienteError(error.response.data) );
            }
        }
    }
}

const crearPaciente = () => ({
    type: CREAR_PACIENTE,
    payload: true
});
const crearPacienteExito = (msg) => ({
    type: CREAR_PACIENTE_EXITO,
    payload: msg
});
const crearPacienteError = (msg) => ({
    type: CREAR_PACIENTE_ERROR,
    payload: msg
});

export function crearUsuarioPacienteAction(informacion) {
    return async (dispatch) => {
        dispatch( crearUsuarioPaciente() );
        try {
            const {payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/crearusuariopaciente', payload);
            dispatch( crearUsuarioPacienteExito(respuesta.data.msg) );
            Swal.fire({
                icon: 'success',
                title:'La solicitud ha sido un éxito',
                text: `${respuesta.data.msg}`,
            }).then(function() {
                window.location = "https://metodoyogen.com/login";
            });

        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( crearUsuarioPacienteError(error.response.data) );
            }
        }
    }
}

const crearUsuarioPaciente = () => ({
    type: CREAR_USUARIO_PACIENTE,
    payload: true
});
const crearUsuarioPacienteExito = (msg) => ({
    type: CREAR_USUARIO_PACIENTE_EXITO,
    payload: msg
});
const crearUsuarioPacienteError = (msg) => ({
    type: CREAR_USUARIO_PACIENTE_ERROR,
    payload: msg
});