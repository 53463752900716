import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { BtnColor, TextArea } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { ContainerFlex, FormInput } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { Img } from '../../../../Css/StyledForm';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Escribe una historia "loca" a partir de las imagenes que aparecen. En el primero recuadro coloca la introducción, en el segundo el desarrollo y en el tercero la conclusión.';
const miniInstrucciones = 'Escribe una historia "loca" a partir de las imagenes que aparecen.';

const ESC14 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [countIntro, setCountIntro] = useState(0);
    const [countDesa, setCountDesa] = useState(0);
    const [countDesen, setCountDesen] = useState(0);
    const [fraseIntroduccion, setFraseIntroduccion] = useState('');
    const [fraseDesarrollo, setFraseDesarrollo] = useState('');
    const [fraseDesenlace, setFraseDesenlace] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if (fraseDesenlace === '' || fraseDesarrollo === '' || fraseIntroduccion === '') {
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false)
            }, 1000);
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            setTimeout(() => {
                setUltimoTiempo(true);
                dispatch(finalizarEjercicioAction());
            }, 1500);
        }
    }
    const cambiarIntroIzq = (e) => {
        e.preventDefault();
        if (countIntro === 0) {
            return;
        }
        setCountIntro(countIntro - 1)
    }
    const cambiarIntroDer = (e) => {
        e.preventDefault();
        if (countIntro === comparar.filter(item=> item.grupo === '1').length-1) {
            return;
        }
        setCountIntro(countIntro + 1)
    }
    const cambiarDesaIzq = (e) => {
        e.preventDefault();
        if (countDesa === 0) {
            return;
        }
        setCountDesa(countDesa - 1)
    }
    const cambiarDesaDer = (e) => {
        e.preventDefault();
        if (countDesa === comparar.filter(item=> item.grupo === '2').length-1) {
            return;
        }
        setCountDesa(countDesa + 1)
    }
    const cambiarDesenIzq = (e) => {
        e.preventDefault();
        if (countDesen === 0) {
            return;
        }
        setCountDesen(countDesen - 1)
    }
    const cambiarDesenDer = (e) => {
        e.preventDefault();
        if (countDesen === comparar.filter(item=> item.grupo === '3').length-1) {
            return;
        }
        setCountDesen(countDesen + 1)
    }

    const nada = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC14') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/cuentos/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion);
    }, [informacion])

    useEffect(() => {
        if(iniciar === false){
            return;
        }else if(iniciar === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC14",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center" align="center"
                    isCorrect={isCorrect} isIncorrect={isIncorrect}
                >
                    <ContainerFlex display="flex" direction="column" justify="center" align="center" margin="auto">
                        <ContainerFlex display="flex" justify="center" align="center">
                            <ContainerFlex display="flex" justify="end">
                                <BtnColor bg="transparent"
                                    onClick={(e) => cambiarIntroIzq(e)}
                                >
                                    <img alt='' src={`/img/btnIzquierda.png`} />
                                </BtnColor>

                                <Img alt='' height="140px" width="100px" src={`/img/${comparar.filter(item=> item.grupo === '1')[countIntro].imagen_url_1}.png`} />

                                <BtnColor bg="transparent"
                                    onClick={(e) => cambiarIntroDer(e)}
                                >
                                    <img alt='' src={`/img/btnDerecha.png`} />
                                </BtnColor>
                            </ContainerFlex>

                            <ContainerFlex width="60vw" display="flex" marginleft="3rem">
                                <FormInput width="50vw" widthmob="50vw" height="20vh" display="flex"
                                    onSubmit={(e) => nada(e)}
                                >
                                    {/* textarea en vez de input?, para que el placeholder quede arriba */}
                                    <TextArea width="70vw" widthmob="70vw" shadow="inset 0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" font="1rem" padding="1rem 0rem 0 1rem" type="text" value={fraseIntroduccion} onChange={(e) => setFraseIntroduccion(e.target.value)} disabled={disable} />
                                </FormInput>
                            </ContainerFlex>
                        </ContainerFlex>

                        <ContainerFlex display="flex" justify="center" align="center" margintop="1.5rem" marginMob="1rem">
                            <ContainerFlex display="flex" justify="end">
                                <BtnColor bg="transparent"
                                    onClick={(e) => cambiarDesaIzq(e)}
                                >
                                    <img alt='' src={`/img/btnIzquierda.png`} />
                                </BtnColor>

                                <Img alt='' height="140px" width="100px" src={`/img/${comparar.filter(item=> item.grupo === '2')[countDesa].imagen_url_1}.png`} />

                                <BtnColor bg="transparent"
                                    onClick={(e) => cambiarDesaDer(e)}
                                >
                                    <img alt='' src={`/img/btnDerecha.png`} />
                                </BtnColor>
                            </ContainerFlex>

                            <ContainerFlex width="60vw" display="flex" marginleft="3rem">
                                <FormInput width="50vw" widthmob="50vw" height="20vh" display="flex"
                                    onSubmit={(e) => nada(e)}
                                >
                                    <TextArea width="70vw" widthmob="70vw" shadow="inset 0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" font="1rem" padding="1rem 0rem 0 1rem" type="text" value={fraseDesarrollo} onChange={(e) => setFraseDesarrollo(e.target.value)} disabled={disable} />
                                </FormInput>
                            </ContainerFlex>
                        </ContainerFlex>

                        <ContainerFlex display="flex" justify="center" align="center" margintop="1.5rem" marginMob="1rem">
                            <ContainerFlex display="flex" justify="end">
                                <BtnColor bg="transparent"
                                    onClick={(e) => cambiarDesenIzq(e)}
                                >
                                    <Img alt='' src={`/img/btnIzquierda.png`} />
                                </BtnColor>

                                <Img alt='' height="140px" width="100px" src={`/img/${comparar.filter(item=> item.grupo === '3')[countDesen].imagen_url_1}.png`} />

                                <BtnColor bg="transparent"
                                    onClick={(e) => cambiarDesenDer(e)}
                                >
                                    <Img alt='' src={`/img/btnDerecha.png`} />
                                </BtnColor>
                            </ContainerFlex>

                            <ContainerFlex width="60vw" display="flex" marginleft="3rem">
                                <FormInput width="50vw" widthmob="50vw" height="20vh" display="flex"
                                    onSubmit={(e) => nada(e)}
                                >
                                    <TextArea width="70vw" widthmob="70vw" shadow="inset 0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" padding="1rem 0rem 0 1rem" font="1rem" type="text" value={fraseDesenlace} onChange={(e) => setFraseDesenlace(e.target.value)} disabled={disable} />
                                </FormInput>
                            </ContainerFlex>
                        </ContainerFlex>

                    </ContainerFlex>
                    <ContainerFlex margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlex>

                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC14;