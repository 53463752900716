import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, ContainerImg, FlexLink, Descripcion } from '../../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../../Css/StyledHeader';

const CCIntrasilabica = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lenguaje/"} l3={"conciencia-fonologica/"} n1={"Métodos"} n2={"LENGUAJE"} n3={"CONCIENCIA FONOLÓGICA"} componente={"CCINTRASILABICA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Container justify="center" direction="column" width="100%" height="100%">
                <Container justify="space-around">
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-intrasilabica/LEN27'}><ContainerImg src={`/img/portadas/LEN27.jpg`} alt=""/> <Descripcion>Descubrir nueva palabra (palabra)</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-intrasilabica/LEN28'}><ContainerImg src={`/img/portadas/LEN28.jpg`} alt=""/> <Descripcion>Descubrir nueva palabra (sonido)</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-intrasilabica/LEN29'}><ContainerImg src={`/img/portadas/LEN29.jpg`} alt=""/> <Descripcion>Descubrir nueva palabra (dibujo)</Descripcion></FlexLink>
                </Container>
            </Container>
        </Fragment>    
    );
}
 
export default CCIntrasilabica;