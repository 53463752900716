import {
    LOGIN,
    LOGIN_EXITO,
    LOGIN_ERROR
} from '../types';
import clienteAxios from '../config/axios';

// Restablece la contraseña
export function iniciarSesionAction(datos) {
    return async (dispatch) => {
        dispatch( login() );
        try {
            const respuesta = await clienteAxios.post('/auth', datos);
            const token = respuesta.data.token;
            const decodePayload = (tkn) => {
                const base64Url = tkn.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                return jsonPayload;
            }
            const {info_header} = respuesta.data;
            const payloadToken = JSON.parse(decodePayload(token));
            const informacion = {
                payloadToken,
                token,
                info_header
            }
            dispatch( loginExito( informacion));

        } catch (error) {
            console.log(error)
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( loginError(error.response.data) );
            }
        }
    }
}

const login = () => ({
    type: LOGIN,
    payload: true
});


const loginExito = (info) => ({
    type: LOGIN_EXITO,
    payload: info
});

// si hubo un error
const loginError = (msg) => ({
    type: LOGIN_ERROR,
    payload: msg
});