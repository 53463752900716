import React, {Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import Instrucciones from '../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../Hooks/useConfigAuth';

import { aumentarContadorSecundarioAction } from '../../../../actions/botoneraEjercicioActions';
import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';
import { finalizarEjercicioAction } from '../../../../actions/estadoEjercicioActions';

import { Flex, FlexFull, FlexM20, NumXL, NumerosAyuda, ButtonAyuda, ButtonNum, DivNum, NumDec, NumUni } from '../../../Css/StyledMatematica';

const data2 = [
    {id:'1', numero:10},
    {id:'2', numero:20},
    {id:'3', numero:30},
    {id:'4', numero:40},
    {id:'5', numero:50},
    {id:'6', numero:60},
    {id:'7', numero:70},
    {id:'8', numero:80},
    {id:'9', numero:90}
]

const PracticaDecena = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { ejercicio, data, instrucciones, miniInstrucciones } = props;

    const [comparar, setComparar] = useState([]);
    const [decena, setDecena] = useState(0);
    const [numeroDecena, setNumeroDecena] = useState(0);
    const [randomNumeros, setRandomNumeros] = useState([]);
    const [decenaAyuda, setDecenaAyuda] = useState(false);
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista = useSelector(state => state.ejercicio.lista);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const error = useSelector(state => state.ejercicio.error);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const indexes = Object.keys(randomNumeros).length;

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
        }
    }

    const setearDecenaAyuda = (e) => {
        e.preventDefault();
        if(decenaAyuda !== true){
            setDecenaAyuda(true);
        } else {
            setDecenaAyuda(false);
        }
        if(ayuVisualSolicitada === false){
            setAyuVisualSolicitada(true);
        }
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(data));
        const armoDecena = newList[0].numero;
        const armoPrimero = armoDecena.toString().substring(0,1)
        const newList2 = newList.filter(item => item.id > 1).map(item => 
            item.numero.toString().substring(1, item.numero.length)
        );

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList2);
        setRandomNumeros(shuffleList);
        setNumeroDecena(armoPrimero);
        setDecena(armoDecena);
        setComparar(data2);
        const newList3 = JSON.parse(JSON.stringify(shuffleList));
        const primerNumero = String(data[0].numero).slice(0,1);
        const elOrdenDelId = newList3.map(item => primerNumero + item);
        setIdOrden(elOrdenDelId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(indexes === 0){
            return;
        }
        if(contadorSecundario < indexes) {
            return;
        } else {
            dispatch( finalizarEjercicioAction() )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario]);

    useEffect(() => {
        if(contadorSecundario === 0){
            return;
        }
        if(contadorSecundario === 1){
            setErroresOrden([...erroresOrden, error]);
            return;
        }
        const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
        setErroresOrden([...erroresOrden, err]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario])

    useEffect(() => {
        if(iniciar === false && contadorSecundario === indexes ){
            return;
        }else if(iniciar === false && contadorSecundario === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorSecundario]);

    useEffect(() => {
        if(finalizar === true && contadorSecundario === indexes -1 ){
            dispatch(aumentarContadorSecundarioAction());
        }
        if(finalizar && contadorSecundario === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
            const erroresOrdenFinal = [...erroresOrden, err];

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda_visual': ayuVisualSolicitada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrdenFinal,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorSecundario]);

    return (
        <Fragment>
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto" width="100%">
                        {decenaAyuda ?
                        <FlexFull direction='column' height='50vh' justify='center' align='center' margin='0vh 0px 0px 0px'>
                            <ButtonNum
                                onClick={(e) => setearDecenaAyuda(e)}
                            >
                                D
                            </ButtonNum>
                            <NumXL color='#FF9B2A' w='56vw' wm='42vw' wt='34vw' wd='28vw' wlg='21vw' onClick={(e) => setearDecenaAyuda(e)}>
                                {decena}
                            </NumXL>
                        </FlexFull>
                        :
                        <Flex height='50vh' margin='0vh 0px 0px 0px'>
                            <Flex direction='column'>
                                <ButtonNum
                                    onClick={(e) => setearDecenaAyuda(e)}
                                >
                                    D
                                </ButtonNum>
                                <NumDec onClick={(e) => setearDecenaAyuda(e)}>
                                    {numeroDecena}
                                </NumDec>
                            </Flex>
                            
                            <Flex direction='column' margin='0vh 0vw 0vh -25vw' mm='0vh 0vw 0vh -10vw' mt='0vh 0vw 0vh -10vw' md='0vh 0vw 0vh -10vw' mlg='0vh 0vw 0vh -10vw' >
                                <DivNum>
                                    U
                                </DivNum>
                                <NumUni>
                                    {randomNumeros[contadorSecundario]}
                                </NumUni>
                            </Flex>
                        </Flex>
                        }
                        <FlexM20 margin='10vh 0px 0vh 0px'>
                            <ButtonAyuda
                                onClick={(e) => setearAyuVisual(e)}
                            >
                                ?
                            </ButtonAyuda>
                            {ayuVisual &&
                            <FlexM20 position='absolute' margin='0vh 0vw 0vh -1vw'>
                                {comparar.map(item => 
                                <NumerosAyuda key={item.id}>{item.numero}</NumerosAyuda>
                                )}
                            </FlexM20>
                            }
                        </FlexM20>
                    </Flex>
                        {/* FIN DIV PRUEBA */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <Flex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </Flex>
                        <Flex>
                            <BotoneraBottom error={true} aumentarSecundario={true} />
                        </Flex>
                    </Flex>
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default PracticaDecena;