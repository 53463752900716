import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import {
    Link
} from "react-router-dom";
const media = {
    tablet: '@media (max-width:996px)',
    desktop: '@media(min-width:996px) and (max-width:1200px)',
    desktopLg: '@media (min-width:1200px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`

//instruccion explicita

export const Contenedor = styled.div `

/* margin: 70px auto; */
display: flex;
direction:  ${(props) => props.direction};
margin: ${(props) => props.margin};
background-color:  ${(props) => props.bg};
width:  ${(props) => props.width};
height: ${(props) => props.height};

${media.tablet}{
    
}
`

export const ContenedorLetras = styled.div `
display: flex;
flex-wrap: wrap;
direction:  ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align};
width:  ${(props) => props.width};
height:  ${(props) => props.height};
background-color:  ${(props) => props.bg};
margin: ${(props) => props.margin};
align-self:  ${(props) => props.alignself};
${media.tablet}{
    width: ${(props) => props.widthmob};
    margin: ${(props) => props.marginMob};
    justify-content:  ${(props) => props.justifymob};
}
`

export const BtnLetra = styled.div `
 display:  ${(props) => props.display};
 justify-content:  ${(props) => props.justify};
 align-items:  ${(props) => props.align};
 color: white;
 font-size:  ${(props) => props.font};
 background-color: #D05F67;
 border: none;
 border-radius: 15px;
 width:  ${(props) => props.width};
 height:  ${(props) => props.height};
 margin: ${(props) => props.margin};
 font-family: nunito;
 font-weight: bold;
 box-shadow: ${(props) => props.shadow};
 text-transform: ${(props) => props.transform};
 cursor: ${(props) => props.cursor};
 
 ${media.tablet}{
    width: ${(props) => props.widthmob};
    height: ${(props) => props.heightmob};
    margin: ${(props) => props.marginMob};
}
 
`

export const InputCheck = styled.input `
 display: none;
`


export const ContenedorEjercicios = styled.div `
 display: flex;
 flex-direction: ${(props) => props.direction};
 justify-content: ${(props) => props.justify};
 align-items: ${(props) => props.align};
 background-color: ${(props) => props.bg};
 width: ${(props) => props.width};
 height: ${(props) => props.height};
 margin: ${(props) => props.margin};

 ${media.tablet}{
    height: ${(props) => props.heightmob};
    width: ${(props) => props.widthmob};
    margin: ${(props) => props.marginMob};
    
}
`

export const Container = styled.div `
 display: flex;
 flex-direction: column;
 flex-wrap: wrap;
 margin: 0 15px; 
 width: ${(props) => props.width};
 background-color: ${(props) => props.bg}

 ${media.tablet}{
    width: ${(props) => props.widthmob};
}
 
`

export const BtnElegir = styled.button `
color: white;
font-size: 1.2rem;
font-family: nunito;
font-weight: 500;
background-color: #D05F67;
border: none;
border-radius: 10px;
cursor: pointer;
padding: 20px 40px;
margin-bottom: 25px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
background-color: ${(props) => props.bg};
${media.tablet}{
    padding: 15px 20px;
    font-size: 1rem;
    font-weight: 600;
}

`

export const ContainerLink = styled.div `
display:  ${(props) => props.display};
 justify-content:  ${(props) => props.justify};
 align-items:  ${(props) => props.align};
background-color: ${(props) => props.bg};

`

export const ContainerImg = styled.img `
border: 3px solid #D05F67; 
border-radius: 10px;
height: ${(props) => props.height};
padding:  ${(props) => props.padding}; 
margin: ${(props) => props.margin};
background-color: ${(props) => props.bg};
display:  ${(props) => props.display};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

${media.tablet}{
    height: ${(props) => props.heightmob};
    width: ${(props) => props.widthmob};
}
`

export const ElegirLink = styled (Link)`
  color: ${(props) => props.color};
  text-decoration: none;
  font-size: ${(props) => props.font};
  margin-right: ${(props) => props.marginright};
  margin-left: ${(props) => props.marginleft};
  margin-bottom: ${(props) => props.marginBottom};

  ${media.tablet}{
    margin-right: ${(props) => props.marginrightmob};
    margin-left: ${(props) => props.marginleftmob};
    margin-left: ${(props) => props.marginleftmob};
    margin-bottom: ${(props) => props.marginBottomMob};
    height: ${(props) => props.heightmob};
    width: ${(props) => props.widthmob};
    
}
 `