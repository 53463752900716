import styled, {css} from 'styled-components';
import {ShakeImg, ZoomIn} from './StyledCCSilabica';
const media = {
    tablet: '@media (max-width:768px)'
}


export const BtnSonido = styled.button`
  border: none;
  width: 140px;
  height: 140px;
  background-color: transparent;


 img {
    width: 140px;
    height: 140px;
    cursor: pointer;
    background-color: white;
 }
`

export const BtnSilaba = styled.button`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-family: nunito;
  font-size: ${(props) => props.font};
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-weight: bold;
  border: none;
  margin: 0 1rem;
  color: #646464;
  cursor: pointer;
  padding:  ${(props) => props.padding};
  text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

  ${media.tablet} {
    padding:  ${(props) => props.paddingMob};
    width: ${(props) => props.widthmob};
  height: ${(props) => props.heightmob};
  font-size: ${(props) => props.fontmob};
  }
  ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
  `}
  ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
  `}

`

export const Palabra = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-family: nunito;
  font-size: ${(props) => props.font};
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-weight: bold;
  color: #646464;
  padding:  ${(props) => props.padding};
  text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

  ${media.tablet} {
    padding:  ${(props) => props.paddingMob};
    width: ${(props) => props.widthmob};
  height: ${(props) => props.heightmob};
  font-size: ${(props) => props.fontmob};
  }
  ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
  `}
  ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
  `}  
`

export const Centrar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
`