import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

//css
import {Select, Label, Input} from '../Css/StyledForm'

// Actions de Redux
import { almacenarProfesionAction } from '../../actions/almacenarProfesionActions';

const DropdownProfesion = (props) => {

    const [profession, setProfession] = useState('');
    const [otroProfesional, setOtroProfesional] = useState('');

    // utilizar use dispatch y te crea una función
    const dispatch = useDispatch();

    const profesiones = [
        {id:'1', parametro: "Psicopedagoga"},
        {id:'2', parametro: "Lic. en psicopedagogía"},
        {id:'3', parametro: "Fonoaudióloga"},
        {id:'4', parametro: "Lic. en fonoaudiología"},
        {id:'5', parametro: "Lic. en psicología"},
        {id:'6', parametro: "Maestra especial"},
        {id:'7', parametro: "Lic. en ciencias de la educación"},
        {id:'8', parametro: "Docente"},
        {id:'99', parametro: "Otro"}
    ];

    useEffect(() => {
        const informacion = {
            profession,
            otroProfesional
        }
        dispatch( almacenarProfesionAction(informacion) );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profession, otroProfesional])

    return (
        <Fragment >
            <Label margin={props.margin} padding={props.padding}>
            <Select value={profesiones.id} onChange={e => setProfession(e.target.value)}>
                <option>Seleccione Profesión</option>
                {profesiones.map((profesion)=> (
                    <option key={profesion.id} >{profesion.parametro}</option>
                ))}
                
            </Select>
            {profession === 'Otro' ?
                <Input
                type="text"
                id="otroProfesional"
                name="otroProfesional"
                value={otroProfesional}
                onChange={e => setOtroProfesional(e.target.value)}
                />
            :
                null
            }
            </Label>
        </Fragment>
    )
};

export default DropdownProfesion;