import styled, {css, keyframes} from 'styled-components';
import {createGlobalStyle} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`
// BOTONES
export const Btn = styled.button`
    border: 0;
    background: transparent;
    margin: ${props => props.margin};
    border: ${props => props.border ? `solid 3px #23AAAF` : `0`};
    border-radius: ${props => props.borderradius ? `10px` : null};
    text-transform: ${props => props.transform};
    font-size: ${props => props.fontsize};
    ${({isCorrect}) => isCorrect && 'border: solid 2px red'}
    ${({isIncorrect}) => isIncorrect && 'border: solid 10px black'}
    cursor: pointer;
`
export const BotonMas = styled.button`
    background: #23AAAF;
    border:0;
    color: white;
    font-size: 20px; 
    border-radius: 100px;
    height: 50px;
    width: 50px;
    cursor: pointer;
`
export const BtnConTexto = styled.button`
    border: ${props => props.border ? `solid 3px #23AAAF` : `0`};
    border-radius: ${props => props.borderradius ? `10px` : null};
    background: transparent;
    display: flex;
    flex-direction: column;
    font-family: nunito;
    text-transform: uppercase;
    align-items: center;
    margin: 10px;
`
export const YellowBtn = styled.button`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin};
    background:#FFDC73;
    color: white; 
    font-family: nunito;
    font-size: 30px;
    border-radius: 10px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 0;
`
// IMAGENES
export const Ilustracion = styled.img`
    width: ${props => props.width};
    ${media.tablet}{
        width: ${props => props.widthTablet};;
    }
    margin: ${props => props.margin};
    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `}
`
export const Icon = styled.img`
    position: absolute;
    right: ${props => props.right};
    bottom: ${props => props.bottom};
    ${media.tablet}{
    right: ${props => props.rightTablet};
    bottom: ${props => props.bottomTablet};
}
`
export const Circulo = styled.div`
    width: 55px;
    height: 55px;
    background: #23AAAF;
    border-radius: 100px;
    color:transparent;
    margin: 0px 5px;
    ${media.tablet}{
        width:50px;
        height:50px;
}
`
export const ImgBottom = styled.img`
position: absolute;
right: ${props => props.right};
bottom: ${props => props.bottom};
    &:hover{
        transform: scale(1.1);
    }
`
// ANIMACIONES
export const ShakeImg = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`
export const ZoomIn = keyframes`
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
`
// CONTENEDORES
export const Flex = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    height: ${props => props.height};
    width: ${props => props.width};
`

export const ContInst = styled.div`
    position: absolute;
    bottom: 40px; 
    left: 25px; 
    text-transform: uppercase; 
    font-family: nunito;
    font-weight: 400;
    font-size: 16px; 
`
export const Contador = styled.div`
    background: #23AAAF;
    border-radius: 50px;
    font-size: 45px;
    padding: 25px;
`
export const ContIcons = styled.div`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 55px;
    border-radius: 20px;
`
export const ContLetters = styled.div`
    width: 65vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${props => props.justify};
`
// PALABRAS
export const BtnCeleste = styled.button`
    background:#23AAAF;
    width: 16vw;
    width: ${props => props.width};
    height: 60px;
    border: 0;
    border-radius: 10px;
    color: white;
    font-size: 30px;
    font-family: nunito;
    margin: 40px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: ${props => props.margin};
    cursor: pointer;
`
export const TextoBtn = styled.p`
    font-size: 25px;
    font-weight: 300;
`
export const Letra = styled.p`
    background:${props => props.bgcolor};
    width: 60px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 25px;
    display: flex; 
    justify-content: center; 
    align-items: center;
    margin: 0.4rem;
    width: ${props => props.width};
    height: ${props => props.height};
    ${media.tablet}{
        width: ${props => props.widthTablet};
        height: ${props => props.heightTablet};
}

`
// LISTADOS
export const Listado = styled.ul`
    list-style: none;
    display: flex; 
`
export const Li = styled.li`
    
    display: flex; 
    flex-direction: column;
`