import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';
import M89Bis from './M89Bis';
import M89Practica from './M89Practica';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, PasosSubTM89, Numeros, NumeroTachado, PasosT, PasosM89, NumCalculo, DivFlexM82, Menos, FlechaP, ResultLeft, ResultRight, Out, DownLeft, DownRight, UpLeft, UpRight, Flecha } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M86 = () => {

    const dispatch = useDispatch();

    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);

    useEffect(() => {
        dispatch(setearEjercicioAction('M89') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-tradicional/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
                <Fragment>
                    {iniciar || fuerzoInicio
                        ?
                        <Fragment>
                            {contador=== 0 &&
                            <div>
                                {contadorSecundario >= 0 &&
                                <FlexFull justify='space-evenly' align='center' height='80vh'>
                                    {/* CALCULO Y CUADRICULA */}
                                    <FlexFull direction='column' justify='center' align='center' width='40%' wm='40%' wt='50%' wd='50%' wlg='50%' height='76vh'>
                                        {/* CALCULO */}
                                        <FlexFull justify='start' align='center' width='100%' height='35%'>
                                            <NumCalculo color='#D76970'>54</NumCalculo>
                                            <NumCalculo color='#646464'>-</NumCalculo>
                                            <NumCalculo color='#23AAAF'>35</NumCalculo>
                                            <NumCalculo color='#646464'>=</NumCalculo>{contadorSecundario >= 18 && <NumCalculo color='#646464'>19</NumCalculo>}
                                        </FlexFull>
                                        {/* FIN CALCULO */}
                                        {/* CUADRICULA */}
                                        <FlexFull justify='end' align='end' width='100%' height='65%' margin='0px 0px 3vh 0px' mm='0px 0px 3vh 0px' mt='0px 0px 3vh 0px' md='0px 0px 0px 0px' mlg='0px 0px 0px 0px'>
                                            <Flex>
                                                <DivFlexM82>
                                                <Flex>
                                                    <Flecha>
                                                        {contadorSecundario >= 15 &&
                                                            <FlechaP>↓</FlechaP>
                                                        }
                                                    </Flecha>
                                                    <Flecha>
                                                        {contadorSecundario >= 7 &&
                                                            <FlechaP>↓</FlechaP>
                                                        }
                                                    </Flecha>
                                                </Flex>
                                                <Flex>
                                                    <UpRight>
                                                    {contadorSecundario >= 3 && contadorSecundario < 10 ?
                                                        <Numeros>5</Numeros> :
                                                        contadorSecundario <= 2?
                                                        null :
                                                        <NumeroTachado>5</NumeroTachado> 
                                                    }   
                                                    </UpRight>
                                                    <UpLeft>
                                                    {contadorSecundario >= 4 && contadorSecundario <= 11 ?    
                                                        <Numeros>4</Numeros> :
                                                        contadorSecundario >= 12?
                                                        <Numeros>14</Numeros> :
                                                        <Numeros></Numeros>
                                                    } 
                                                    </UpLeft>
                                                </Flex>
                                                <Flex>
                                                    <Out>
                                                        {contadorSecundario >= 6 &&
                                                        <Menos>-</Menos>
                                                        }
                                                    </Out>
                                                    <DownLeft>
                                                    {contadorSecundario >= 5 &&
                                                    <Numeros>3</Numeros>
                                                    }
                                                    </DownLeft>
                                                    <DownRight>
                                                    {contadorSecundario >= 6 &&
                                                    <Numeros>5</Numeros>
                                                    }
                                                    </DownRight>
                                                </Flex>
                                                    <Flex>
                                                        <ResultLeft>
                                                            {contadorSecundario >= 16 &&
                                                            <Numeros>1</Numeros>
                                                            }
                                                        </ResultLeft>
                                                        <ResultRight>
                                                            {contadorSecundario >= 14 &&
                                                            <Numeros>9</Numeros>
                                                            }
                                                        </ResultRight>
                                                    </Flex>
                                                </DivFlexM82>
                                            </Flex>
                                        </FlexFull>
                                        {/* FIN CUADRICULA */}
                                    </FlexFull>
                                    {/* FIN CALCULO Y CUADRICULA */}
                                    {/* PASOS */}
                                    <FlexFull FlexFull direction='column' justify='start' align='center' width='30%' height='65vh'>
                                        <PasosT>
                                            PASOS
                                        </PasosT>
                                        <PasosSubTM89>
                                            RESTA TRADICIONAL CON DIFICULTAD
                                        </PasosSubTM89>
                                        {contadorSecundario >= 1 &&
                                        <PasosM89 bgcolor='#D76970'>
                                            1- COLOCO LOS NÚMEROS EN SU RESPECTIVO LUGAR
                                        </PasosM89>
                                        }
                                        {contadorSecundario >= 2 &&
                                        <PasosM89 bgcolor='#23AAAF'>
                                            2- EL NÚMERO MAS GRANDE SE COLOCA ARRIBA
                                        </PasosM89>
                                        }
                                        {contadorSecundario >= 7 &&
                                        <PasosM89 bgcolor='#FF9B2A'>
                                            3- COMIENZO RESTANDO LAS UNIDADES
                                        </PasosM89>
                                        }
                                        {contadorSecundario >= 8 &&
                                        <PasosM89 bgcolor='#FFDC73'>
                                            4- ¿ME ALCANZA PARA QUITARLE?
                                        </PasosM89>
                                        }
                                        {contadorSecundario >= 9 &&
                                        <PasosM89 bgcolor='#655EE8'>
                                            5- LE PIDO UNA DECENA AL COMPAÑERO
                                        </PasosM89>
                                        }
                                        {contadorSecundario >= 11 &&
                                        <PasosM89 bgcolor='#D76970'>
                                            6- COLOCO LA DECENA DONDE CORRESPONDE
                                        </PasosM89>
                                        }
                                        {contadorSecundario >= 13 &&
                                        <PasosM89 bgcolor='#23AAAF'>
                                            7- AHORA SI PUEDO RESTAR
                                        </PasosM89>
                                        }
                                        {contadorSecundario >= 15 &&
                                        <PasosM89 bgcolor='#FF9B2A'>
                                            8- CONTINUO CON LAS DECENAS
                                        </PasosM89>
                                        }
                                        {contadorSecundario >= 17 &&
                                        <PasosM89 bgcolor='#FFDC73'>
                                            9- ¿CÚAL ES EL RESULTADO?
                                        </PasosM89>
                                        }
                                    </FlexFull>
                                    {/* FIN PASOS */}
                                </FlexFull>
                                }
                                <FlexCuz  position='fixed' right='0' bottom='0'>
                                {contadorSecundario === 0 && <BotoneraBottom aumentarSecundario={true} />}
                                {contadorSecundario > 0 && contadorSecundario < 19 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />}
                                {contadorSecundario === 19 && <BotoneraBottom reducirSecundario={true} aumentar={true} />}
                                </FlexCuz>
                            </div>
                            }
                            {contador === 1 && <M89Bis mostrar={true} />}
                            {contador === 2 && <M89Practica mostrar={true} />}
                        </Fragment>
                        :
                        <Instrucciones instrucciones={instrucciones} />
                    }
                </Fragment>
        </Fragment>
    );
}
 
export default M86;