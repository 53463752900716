import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {Ilustracion, Flex, Letra, ContInst, Listado, Btn, Li} from '../../../../Css/MetodosCSS/StyledFonemica';

const instrucciones = 'Selecciona la vocal con la que comienza el dibujo.';
const miniInstrucciones = 'Selecciona la vocal con la que comienza el dibujo.';

const LEN9 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [ltrComparar, setLtrComparar] = useState('');
    const [indexes, setIndexes] = useState();
    const [abcFinal, setAbcFinal] = useState([]);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const abc = [
        {id:1, letra:'A', bgcolor:'#23AAAF'},
        {id:2, letra:'E', bgcolor:'#FF9B2A'},
        {id:3, letra:'I', bgcolor:'#FFDC73'},
        {id:4, letra:'O', bgcolor:'#655EE8'},
        {id:5, letra:'U', bgcolor:'#D05F67'}
    ];

    const play = (ltr) => {
        switch(ltr.toUpperCase()){
            case "A":
                playA();
                break;
            case "E":
                playE();
                break;
            case "I":
                playI();
                break;
            case "O":
                playO();
                break;
            case "U":
                playU();
                break;
            default:
        }
    }
   
    const playA = () => new Audio(`/sounds/${abc[0].letra}.mp3`).play()
    const playE = () => new Audio(`/sounds/${abc[1].letra}.mp3`).play()
    const playI = () => new Audio(`/sounds/${abc[2].letra}.mp3`).play()
    const playO = () => new Audio(`/sounds/${abc[3].letra}.mp3`).play()
    const playU = () => new Audio(`/sounds/${abc[4].letra}.mp3`).play()

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, ltr) => {
        e.preventDefault();
        if(ltr.substring(0,1).toUpperCase() !== ltrComparar.substring(0,1).toUpperCase()) {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
            }, 300);
        } else {
            console.log('bien');
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            setDisable(true);
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count+1);
                }, 300);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN9') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-fonemica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setAbcFinal(abc)
        setIndexes(Object.keys(informacion).length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, informacion])

    useEffect(() => {
        if(comparar === undefined || comparar.length === 0) {
            return;
        }
        switch (comparar.palabra_1.substring(0,1).toUpperCase()) {
            case 'Á':
                setLtrComparar('A')
                break;
            case 'É':
                setLtrComparar('E')
                break;
            case 'Í':
                setLtrComparar('I')
                break;
            case 'Ó':
                setLtrComparar('O')
                break;
            case 'Ú':
                setLtrComparar('U')
                break;
            default:
                setLtrComparar(comparar.palabra_1.substring(0,1).toUpperCase())
                break;
        }
    }, [comparar])
    

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN9",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
        <HeaderEjercicios estrellas={true} carrito={true} ayuImagen={true} />
        {iniciar
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex direction="column" margin="auto">
                    <Flex margin="0rem 0 2rem 0" direction="column">
                        <Ilustracion src={`/img/${comparar.imagen_url_1}.png`} width="12vw" isCorrect={isCorrect} isIncorrect={isIncorrect}/>
                        {ayuda_imagen ? 
                            <div>{comparar.ayuda_imagen_1}</div>
                        : null}
                    </Flex>
                    <Flex>
                        <Listado>
                            {abcFinal.map(letras => 
                                <Li key={letras.id}>
                                    <Btn
                                        disabled={disable}
                                        onClick={(e) => verificar(e, letras.letra)}
                                    >
                                        <Letra style={{background:`${letras.bgcolor}`}} width="5vw" height="5vw" widthTablet="8vw" heightTablet="8vw">{letras.letra}</Letra> 
                                    </Btn>
                                    <Btn 
                                        onClick={() => play(letras.letra)}
                                    >
                                        <Ilustracion src={`/img/altavoz.png`} width="2vw"/>
                                    </Btn>
                                </Li>
                            )}            
                        </Listado>
                    </Flex>
                </Flex>
                <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                    <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                </Flex>
            </Flex>
            :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default LEN9;