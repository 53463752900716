import styled from 'styled-components'
import {
    Link
  } from "react-router-dom"; 
import {createGlobalStyle} from 'styled-components';

const media = {
    min: '@media (min-width:505px) and (max-width:767px)',
    tablet: '@media (min-width:768px) and (max-width:1023px)',
    desktop: '@media(min-width:1024px) and (max-width:1439px)',
    desktopLg: '@media (min-width:1440px) and (max-width:1899px)',
    desktopXlg: '@media (min-width:1900px)'
}

export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`

export const Flex = styled.div`
    display: flex;
    flex-direction: ${props => props.direccion};
    justify-content: ${props => props.justify ? props.justify : "center"};
    align-items: center;
    margin: ${props => props.margin};
`

export const FlexClinica = styled.div`
    display:flex; 
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    margin: 5px;
`

export const Table2 = styled.table`
    display: block;
    table-layout: fixed;
    flex-direction: column;
    padding: 5px 5px;
    border-collapse: collapse;
    width: 94%;
    font-size: 15px;
    ${media.tablet}{
        font-size: 2.1vw;
    }
`

export const FondoModalClinica = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    margin-top: 20px;
    width: ${props => props.width};
    height: ${props => props.height};
    ${media.tablet}{
        width: ${props => props.ancho};
        height: ${props => props.alto};
    }
`

export const FlexButton = styled.div`
    display: flex;
    align-self: start;
    justify-self: end;
    padding: 5px;
`

export const Fondo = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    margin-top: 20px;
    width: ${props => props.width};
    height: ${props => props.height};
    ${media.tablet}{
        width: ${props => props.ancho};
        height: ${props => props.alto};
    }
`
export const Div = styled.div`
    margin:${props => props.margin};
    padding: ${props => props.padding};
`
// CUADRO MI SUSCRIPCION
export const Fila = styled.tr`

    ${media.tablet}{
        font-size:12px;
    }
`
export const Th = styled.th`
    border: 1px solid black;
    font-weight: 400;
    width: ${props => props.width};
    padding: ${props => props.padding};
`
export const Tbody = styled.tbody`
    border: solid 1px;
    padding: 10px 60px 0px 60px;
`

export const Td = styled.td`
    border: 1px solid black;
    font-weight: 400;
    width: ${props => props.width};
`

// TABLA PACIENTES

export const FlexTabla = styled.div`
    display: flex;
    text-align: center;
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    background-color: ${props => props.bgcolor};
    align-self: ${props => props.alignself};
    order: ${props => props.order};
    border: ${props => props.border};
    border-radius: ${props => props.borderradius};
    box-shadow: ${props => props.boxshadow};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    width: ${props => props.width};

    ${media.min} {
        margin: ${props => props.min_margin};
        padding: ${props => props.min_padding};
        width: ${props => props.min_width};
        height: ${props => props.min_height};
        flex-wrap: ${props => props.min_wrap};
    }

    ${media.tablet} {
        margin: ${props => props.tb_margin};
        padding: ${props => props.tb_padding};
        width: ${props => props.tb_width};
        height: ${props => props.tb_height};
        flex-wrap: ${props => props.tb_wrap};
    }

    ${media.desktop} {
        margin: ${props => props.dkt_margin};
        padding: ${props => props.dkt_padding};
        width: ${props => props.dkt_width};
        height: ${props => props.dkt_height};
        flex-wrap: ${props => props.dkt_wrap};
    }

    ${media.desktopLg} {
        margin: ${props => props.lg_margin};
        padding: ${props => props.lg_padding};
        width: ${props => props.lg_width};
        height: ${props => props.lg_height};
        flex-wrap: ${props => props.lg_wrap};
    }

    ${media.desktopXlg} {
        margin: ${props => props.xlg_margin};
        padding: ${props => props.xlg_padding};
        width: ${props => props.xlg_width};
        height: ${props => props.xlg_height};
        flex-wrap: ${props => props.xlg_wrap};
    }
`

export const TextoCorto = styled.div`
    font-size: ${props => props.fontsize};
    font-weight: ${props => props.fontweight};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    text-align: ${props => props.textalign};
    line-height: ${props => props.lineheight};
    border-radius: ${props => props.borderradius};
    text-shadow: ${props => props.textshadow};
    cursor: ${props => props.cursor};
    border-right: ${props => props.borderright};
    
    ${media.min} {
        margin: ${props => props.min_margin};
        padding: ${props => props.min_padding};
        width: ${props => props.min_width};
        font-size: ${props => props.min_fontsize};
    }

    ${media.tablet} {
        margin: ${props => props.tb_margin};
        padding: ${props => props.tb_padding};
        width: ${props => props.tb_width};
        font-size: ${props => props.tb_fontsize};
    }

    ${media.desktop} {
        margin: ${props => props.dkt_margin};
        padding: ${props => props.dkt_padding};
        width: ${props => props.dkt_width};
        font-size: ${props => props.dkt_fontsize};
    }

    ${media.desktopLg} {
        margin: ${props => props.lg_margin};
        padding: ${props => props.lg_padding};
        width: ${props => props.lg_width};
        font-size: ${props => props.lg_fontsize};
    }

    ${media.desktopXlg} {
        margin: ${props => props.xlg_margin};
        padding: ${props => props.xlg_padding};
        width: ${props => props.xlg_width};
        font-size: ${props => props.xlg_fontsize};
    }
`
export const FlechaAbajo = styled.div`
    width: 0; 
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: ${props => props.ordent ? "10px solid #646464" : null}; 
    border-bottom: ${props => props.ordenb ? "10px solid #646464" : null};
    background-color: ${props => props.bgcolor ? "#646464" : null };
    align-self: ${props => props.alignself};
    cursor: pointer;
`

export const Table = styled.table`
    width: 50vw;
    
`
export const Overflow = styled.div`
    overflow-x: auto;
    ${media.tablet}{
        width: 95%;
    }
   
`
export const Tr = styled.tr`
    text-align: ${props => props.textalign};
    background-color: ${props => props.bgcolor};
`
export const Td1 = styled.td`
    width: ${props => props.width};
    border: 1px solid;
    text-align: ${props => props.textalign};
    padding: ${props => props.padding};
    @media (min-width: 960px) {
        width: ${props => props.ancho};
    }
`
export const Thead = styled.thead`
    padding-left: ${props => props.padding};
`
export const Tebody = styled.tbody`
    padding-left: ${props => props.padding};
`
// BOTONES

export const BotonRedondo = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border: 2px solid;
    border-radius: 50%;
    font-size: 2rem;
    cursor: pointer;
`

export const BotonCeleste = styled.button`
    padding:10px 60px 10px 60px;
    border-radius: 10px;
    border:0;
    background:#23AAAF;
    color:black;
    font-weight: 500;
    margin:${props => props.margin};
    &:hover{
        background: #78e2db;
    }
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size:20px;
    width: ${props => props.width};
`
export const BotonNaranja = styled.button`
    border:none;
    background: #FF9B2A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    width: 159px;
    height: 38px;
    margin:${props => props.margin};
    justify-content: center;
    font-size: 12px;
    font-weight:500;
    align-items: center;
    position: relative;
    top: ${props => props.top};
    right: ${props => props.right};
    cursor:pointer;
    &:hover{
        background: #f0a34c;
    }
    
`
export const BotonNaranjaLink = styled (Link)`
    border:none;
    background-color:${props => props.bgcolor ? props.bgcolor : "#FF9B2A"};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    width: 159px;
    height: 38px;
    margin:${props => props.margin};
    justify-content: center;
    font-size: 12px;
    font-weight:500;
    align-items: center;
    text-decoration: none;
    color: black;
    cursor:pointer;
    &:hover{
        background: #f0a34c;
    }
`
export const BotonNaranjaButton = styled.button`
    border:none;
    background-color:${props => props.bgcolor ? props.bgcolor : "#FF9B2A"};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    width: 159px;
    width:${props => props.width};
    height: 38px;
    margin:${props => props.margin};
    justify-content: center;
    font-size: 12px;
    font-weight:500;
    align-items: center;
    text-decoration: none;
    color: black;
    cursor:pointer;
    &:hover{
        background: #f0a34c;
    }
`
export const BotonVolver = styled(BotonNaranjaButton)`
    position:absolute;
    top:159vh;
    left:4vh;
    ${media.tablet}{
        top:186vh;
        left:4vh;
    }
`
export const BotonPaciente = styled (Link)`
    background-color:${props => props.bgcolor ? props.bgcolor : "#FFF"};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px 15px;
    width: 10vw;
    text-align:center;
    margin: 10px;
    text-decoration:none;
    color: black;
    text-transform: uppercase;
    font-size: 12px;
`

export const HiddenText = styled.div`
    position: relative;
    left: 120px;
    top: 5px;
    background: #655EE8;
    display: ${props => props.display};
    width:fit-content;
    font-family: nunito;
    padding: 2px;
    border-radius: 10px;
`

export const BotonPacienteDos = styled.button`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px 15px;
    width: 10vw;
    text-align:center;
    margin: 10px;
    text-decoration:none;
    font-family: 'Nunito';
    color: black;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
`

export const BotonMas = styled (BotonPaciente)`
    padding: 0;
    border-radius: 50px;
    width: 45px;
    background-color:${props => props.bgcolor};
`
export const BotonEditar = styled.button`
    border: none;
    display: flex;
    border-bottom: solid 1px;
    width: 90vw;
    justify-content: flex-end;
`
export const BotonEdit = styled.button`
    border: none;
    background-color: white;
`
export const BotonClose = styled.button`
    border:none;
    background-color:white;
`
// TEXTOS
export const Texto = styled.div`
    font-size: 16px;
    padding: ${props => props.padding}; 
`
export const P = styled.p`
    font-size: 14px;
    font-family: 'Nunito';
    top: ${props => props.top};
    right: ${props => props.right};
    color: ${props => props.color};
    font-weight: ${props => props.fontweight};
`
export const Titulo = styled.h1`
    font-size: 20px;
    padding: 20px;
    padding: ${props => props.padding};
`
export const Dato = styled.p`
    padding: 10px 20px 10px 10px;
    margin:0;
    font-size:14px;
    width:${props => props.width};
`
export const Pe = styled.p`
    margin: 4px 0px;
    font-size: 14px;
    margin:${props => props.margin};
`
export const Text = styled.p`
    font-size:${props => props.font}; 
    margin:${props => props.margin};
`
export const Celeste = styled.div`
    padding:10px 60px 10px 60px;
    border-radius: 10px;
    background:#23AAAF;
    color:black;
    font-weight: 500;
    margin:${props => props.margin};
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size:20px;
    width: ${props => props.width};
`
// LINEAS
export const Division =styled.hr`
    border:solid 0.px;
    width: 90vw;
    width:${props => props.width};
    margin:0;
`
export const DivisionVertical = styled.hr`
    margin:${props => props.margin};
    height:${props => props.height};
`
// FORM
export const FlexClinicaPosition = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
`
export const ClinicaModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:${props => props.width};
    margin: 0 2px 0 2px;
`

export const Label = styled.div`
    background: linear-gradient(0deg, #E5E5E5, #E5E5E5), linear-gradient(0deg, #E5E5E5, #E5E5E5), #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 30vw;
    padding: 5px;
    font-size: 14px;
`
export const StyledLabel = styled.input`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width:${props => props.width};
    margin:${props => props.margin};
    font-size: 14px;
    background-color:#E5E5E5;
    border:0;
    padding: ${props => props.padding};
`
export const Input = styled.input`
    background-color:#E5E5E5;
    border:0;
    padding: 3px;
    width:${props => props.width};
    height:${props => props.height};
    border-radius:${props => props.radius};
    margin:${props => props.margin};
`
export const Input1 = styled.input`
    border:0;
    border-radius: 15px;
    padding: 10px 0px 10px 10px;
    width: ${props => props.width};
`

export const Select = styled.select`
    background: linear-gradient(0deg, #E5E5E5, #E5E5E5), linear-gradient(0deg, #E5E5E5, #E5E5E5), #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    width: 7vw;
    margin: 7px;
    padding: ${props => props.padding};
    border: 0;
    padding:5px;
`

export const TextAreaClinica = styled.textarea`
    background-color:#E5E5E5;
    border:0;
    padding: 3px;
    width:${props => props.width};
    height:${props => props.height};
    border-radius:${props => props.radius};
    margin:${props => props.margin};
`

export const Label1 = styled.label`
    background-color:#f2f2f2;
    box-shadow: 0px 14px 5px -10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin:10px 10px 10px 0px;
    font-size: ${props => props.font};
    padding: 10px 0px 10px 10px;
    width: ${props => props.width};
`
export const Contenedor = styled.div`
    width: ${props => props.width};
`
// IMÁGENES
export const Agregar = styled.img`
    position: absolute;
    top: 30vh;
    left: 93.5vw;
    width: ${props => props.width};
`
// MODALES
export const ModalStyled = styled.div`
display: flex;
flex-direction: column;
align-items:center;
padding: 4vw;
font-family: 'Montserrat', sans-serif;
`

export const ModalStyledClinica = styled.div`
font-family: 'Montserrat', sans-serif;
`
