import React, {Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import AyudaEstructuraOperaciones from '../../Suma/AyudaEstructuraOperaciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, AvanzarBtn, AvanzarImg, InputCalcM82B, NumCalculo, DivFlexM82P, Menos, ResultLeftP, ResultRightP, OutP, DownLeftP, DownRightP, UpLeftP, UpRightP, InputM82BP } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M82Practica = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState('');
    const [digitoD1, setDigitoD1] = useState('');
    const [digitoU2, setDigitoU2] = useState('');
    const [digitoD2, setDigitoD2] = useState('');
    const [digitoFinalUno, setDigitoFinalUno] = useState('');
    const [digitoFinalDos, setDigitoFinalDos] = useState('');
    const [numeroUnidadUno, setNumeroUnidadUno] = useState('');
    const [numeroUnidadDos, setNumeroUnidadDos] = useState('');
    const [numeroDecenaUno, setNumeroDecenaUno] = useState('');
    const [numeroDecenaDos, setNumeroDecenaDos] = useState('');
    const [numeroFinalUno, setNumeroFinalUno] = useState('');
    const [numeroFinalDos, setNumeroFinalDos] = useState('');
    const [numeroFinal, setNumeroFinal] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const verificar = (e) => {
        e.preventDefault();
        if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString()) {
                    setNumeroUnidadUno(0);
                    setNumeroDecenaUno(0);
                    setNumeroUnidadDos(0);
                    setNumeroDecenaDos(0);
                    setNumeroFinalUno(0);
                    setNumeroFinalDos(0);
                    setNumeroFinal(0);
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumeroUnidadUno(0);
                    setNumeroDecenaUno(0);
                    setNumeroUnidadDos(0);
                    setNumeroDecenaDos(0);
                    setNumeroFinalUno(0);
                    setNumeroFinalDos(0);
                    setNumeroFinal(0);
                    setDigitoU1(0);
                    setDigitoD1(0);
                    setDigitoU2(0);
                    setDigitoD2(0);
                    setDigitoFinalUno(0);
                    setDigitoFinalDos(0);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                }, 1500);
            }
        }
    }

    useEffect(() => {
        const min = 10;
        const max = 99;
        const randomNumeroUno = Math.floor(Math.random() * (max - min +1)) + min;
        const randomNumeroDos = (function () {
            const u1 = Number(randomNumeroUno.toString().substring(1,2));
            const d1 = Number(randomNumeroUno.toString().substring(0,1));
            const minU = 0;
            const maxU = u1;
            const u2 = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 1;
            const maxD = d1-1;
            const d2 = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d2.toString().concat(u2.toString()));
            return numero;
        })();
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('resta_tradicional');
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));

        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(newList.cantidad_1.toString().substring(1,2));
            setDigitoD1(newList.cantidad_1.toString().substring(0,1));
        }
        if(newList.cantidad_1.toString().length === 1 ){
            setDigitoU1(newList.cantidad_1.toString().substring(0,1));
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(newList.cantidad_2.toString().substring(1,2));
            setDigitoD2(newList.cantidad_2.toString().substring(0,1));
        }
        if(newList.cantidad_2.toString().length === 1 ){
            setDigitoU2(newList.cantidad_2.toString().substring(0,1));
        }
        setDigitoFinalUno((newList.cantidad_1 - newList.cantidad_2).toString().substring(1,2));
        setDigitoFinalDos((newList.cantidad_1 - newList.cantidad_2).toString().substring(0,1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M82Practica') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-tradicional/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M82Practica",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'ayuda_visual': ayuVisualSolicitada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);
    
    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment>
                    <FlexFull justify='space-evenly' align='center' height='80vh'>
                        {/* CALCULO Y CUADRICULA */}
                        <FlexFull direction='column' justify='center' align='center' width='40%' wm='40%' wt='50%' wd='50%' wlg='50%' height='76vh'>
                            {/* CALCULO */}
                            {comparar.length === 0 ? null :
                            <FlexFull justify='start' align='center' width='100%' height='35%'> 
                                <FlexFull justify='start' align='center' height='fit-content'> 
                                    <NumCalculo color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculo>
                                    <NumCalculo color='#646464'>-</NumCalculo>
                                    <NumCalculo color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculo>
                                    <NumCalculo color='#646464'>=</NumCalculo>
                                    <form onSubmit={(e) => onSubmit(e)}> {/* 6 */}
                                        <InputCalcM82B type="numeric" min="0" max="10000" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                    </form>
                                </FlexFull>
                            </FlexFull>
                            }
                            {/* FIN CALCULO */}
                            {/* CUADRICULA */}
                            <FlexFull justify='end' align='end' width='100%' height='65%' margin='0px 0px 3vh 0px' mm='0px 0px 3vh 0px' mt='0px 0px 3vh 0px' md='0px 0px 0px 0px' mlg='0px 0px 0px 0px'>
                                <Flex>
                                            <DivFlexM82P>
                                                <Flex>
                                                    <UpRightP>
                                                    <InputM82BP type="numeric" min="0" max="10000" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disable} />
                                                    </UpRightP>
                                                    <UpLeftP>
                                                    <InputM82BP type="numeric" min="0" max="10000" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disable} />
                                                    </UpLeftP>
                                                </Flex>
                                                <Flex>
                                                    <OutP> {/*contadorSecundario >= 7 && */}  
                                                        <Menos>-</Menos>
                                                    </OutP>
                                                    <DownLeftP>
                                                    <InputM82BP type="numeric" min="0" max="10000" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disable} />
                                                    </DownLeftP>
                                                    <DownRightP>
                                                    <InputM82BP type="numeric" min="0" max="10000" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disable} />
                                                    </DownRightP>
                                                </Flex>
                                                <Flex>
                                                    <ResultLeftP>  {/* 5 */}
                                                    <form onSubmit={(e) => onSubmit(e)}>
                                                        <InputM82BP type="numeric" min="0" max="10000" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} />
                                                    </form>
                                                    </ResultLeftP>
                                                    <ResultRightP> {/* 3 */}
                                                        <InputM82BP type="numeric" min="0" max="10000" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                                    </ResultRightP>
                                                </Flex>
                                            </DivFlexM82P>
                                </Flex>
                            </FlexFull>
                            {/* FIN CUADRICULA */}
                        </FlexFull>
                        {/* FIN CALCULO Y CUADRICULA */}
                        {/* AYUDA */}
                        <FlexFull direction='column' justify='center' align='center' width='30%' height='76vh'>
                            <AyudaEstructuraOperaciones tipo={tipoEjercicio} />
                        </FlexFull>
                        {/* FIN AYUDA */}
                    </FlexFull>

                    {/* INSTARUCCIONES */}
                    <FlexCuz direction='row-reverse' position='fixed' right='0' bottom='0'>
                            <AvanzarBtn
                                disabled={disable}
                                onClick={(e) => verificar(e)}
                            >
                            <AvanzarImg src={`/img/play.png`} /> 
                            </AvanzarBtn>
                        </FlexCuz>
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M82Practica;