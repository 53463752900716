import {
    CONFIRMAR_CUENTA_EXITO,
    SOLICITAR_CONFIRMAR_EXITO,
    CERRAR_SESION
} from '../types';


const initialState = {
    confirmado: null,
    solicitarConfirmar: null
}
export default function cuentaReducer(state = initialState, action) {
    switch(action.type) {
        case CERRAR_SESION:
            return {
                confirmado: null,
                solicitarConfirmar: null
            }
        case CONFIRMAR_CUENTA_EXITO:
            return {
                ...state,
                confirmado: action.payload
            }
        case SOLICITAR_CONFIRMAR_EXITO:
            return {
                ...state,
                solicitarConfirmar: action.payload
            }
        default:
            return state;
    }
}