import {
    SOLICITAR_OBJETIVOS,
    SOLICITAR_OBJETIVOS_EXITO,
    SOLICITAR_OBJETIVOS_ERROR,
    MODIFICAR_OBJETIVO,
    MODIFICAR_OBJETIVO_EXITO,
    MODIFICAR_OBJETIVO_ERROR,
    NUEVO_OBJETIVO,
    NUEVO_OBJETIVO_EXITO,
    NUEVO_OBJETIVO_ERROR
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function objetivosSolicitarAction(informacion) {
    return async (dispatch) => {
        dispatch( objetivosSolicitar() );
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/buscarobjetivos', payload, config);
            dispatch( objetivosSolicitarExito(data));
        } catch (error) {
            dispatch( objetivosSolicitarError(error.response.data));
        }
    }
};

const objetivosSolicitar = () => ({
    type: SOLICITAR_OBJETIVOS,
    payload: true
});

const objetivosSolicitarExito = (data) => ({
    type: SOLICITAR_OBJETIVOS_EXITO,
    payload: data
});

const objetivosSolicitarError = (msg) => ({
    type: SOLICITAR_OBJETIVOS_ERROR,
    payload: msg
});

export function modificarObjetivoAction(informacion) {
    return async (dispatch) => {
        dispatch( modificarObjetivo() );
        try {
            const {config, payload} = informacion;
            await clienteAxios.put('/paciente/modificarobjetivo', payload, config);
            dispatch( modificarObjetivoExito(payload.objetivosNuevo));
        } catch (error) {
            dispatch( modificarObjetivoError());
        }
    }
};

const modificarObjetivo = () => ({
    type: MODIFICAR_OBJETIVO,
    payload: true
});

const modificarObjetivoExito = (objetivos) => ({
    type: MODIFICAR_OBJETIVO_EXITO,
    payload: objetivos
});

const modificarObjetivoError = () => ({
    type: MODIFICAR_OBJETIVO_ERROR,
    payload: true
});

export function objetivosNuevosAction(informacion) {
    return async (dispatch) => {
        dispatch( objetivosNuevos() );
        try {
            const {config, payload} = informacion;
            await clienteAxios.post('/paciente/objetivonuevo', payload, config);
            dispatch( objetivosNuevosExito() );
            objetivosSolicitarAction(informacion);
            Swal.fire(
                'Correcto',
                'El objetivo se agregó correctamente',
                'success'
            )
        } catch (error) {
            dispatch( objetivosNuevosError(true) );
            Swal.fire(
                'Error',
                'El objetivo no se pudo agregar correctamente',
                'error'
            )
        }
    }
}


const objetivosNuevos = () => ({
    type: NUEVO_OBJETIVO,
    payload: true
});

const objetivosNuevosExito = () => ({
    type: NUEVO_OBJETIVO_EXITO,
    payload: true
});

const objetivosNuevosError = (estado) => ({
    type: NUEVO_OBJETIVO_ERROR,
    payload: estado
});