import React, {Fragment, useState} from 'react';
import { useDispatch } from 'react-redux';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';

import { setearListaAction } from '../../../actions/estadoEjercicioActions';
import { Flex, FlexCuz, ImgBase, ButtonBase, ButtonBack, Description, SubButtonBase, LinkBase } from '../../Css/StyledMatematica';
import { HrMetodos } from '../../Css/StyledHeader';

const Base10 = () => {

    const dispatch = useDispatch();

    const [presentacion, setPresentacion] = useState(false);
    const [construccion, setConstruccion] = useState(false);
    const [interpretacion, setInterpretacion] = useState(false);
    const [mayorYMenor, setMayorYMenor] = useState(false);
    const [anteriorYPosterior, setAnteriorYPosterior] = useState(false);

    const unidades = (e) => {
        e.preventDefault();
        dispatch(setearListaAction('unidad'));
    }
    const decenas = (e) => {
        e.preventDefault();
        dispatch(setearListaAction('decena'));
    }
    const centenas = (e) => {
        e.preventDefault();
        dispatch(setearListaAction('centena'));
    }
    const unidadDeMil = (e) => {
        e.preventDefault();
        dispatch(setearListaAction('unidadDeMil'));
    }
    const todos = (e) => {
        e.preventDefault();
        dispatch(setearListaAction('todos'));
    }

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} n1={"Métodos"} n2={"MATEMATICA"} componente={"BASE10"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            {!presentacion && !construccion && !interpretacion && !mayorYMenor && !anteriorYPosterior &&
            <Flex wrap='wrap' margin='9vh 0vw 0px 0vw' mt='4vh 0vw 0px 0vw' md='4vh 0vw 0px 0vw' mlg='3vh 0vw 0px 0vw'>
                <LinkBase m='3vh 2vw 3px 2vw' to={'/escritorio/metodos/matematica/base-10/M43'}> <ImgBase src={'/img/portadas/matematica43.jpg'} /><Description margin='0 auto'>PRESENTACIÓN</Description></LinkBase>
                <ButtonBase m='3vh 2vw 3px 2vw' onClick={() => setConstruccion(true)}> <ImgBase src={'/img/portadas/matematica44.jpg'} /><Description margin='0 auto'>CONSTRUCCIÓN</Description></ButtonBase>
                <ButtonBase m='3vh 2vw 3px 2vw' onClick={() => setInterpretacion(true)}> <ImgBase src={'/img/portadas/matematica45.jpg'} /><Description margin='0 auto'>INTERPRETACIÓN</Description></ButtonBase>
                <ButtonBase m='3vh 2vw 3px 2vw' onClick={() => setMayorYMenor(true)}> <ImgBase src={'/img/portadas/matematica46.jpg'} /><Description margin='0 auto'>MAYOR Y MENOR</Description></ButtonBase>
                <ButtonBase m='5vh 2vw 3px 2vw' mm='6vh 2vw 3px 2vw' mt='5vh 2vw 3px 2vw' md='5vh 2vw 3px 2vw' mlg='4vh 2vw 3px 2vw' onClick={() => setAnteriorYPosterior(true)}> <ImgBase src={'/img/portadas/matematica47.jpg'} /><Description margin='0 auto'>ANTERIOR Y POSTERIOR</Description></ButtonBase>
            </Flex>
            }
            
            {presentacion &&
            <div>
                <Flex wrap='wrap' margin='20vh 0vw 2vh 0vw' mt='18vh 10vw 2vh 10vw' md='16vh 17vw 2vh 17vw' mlg='12.5vh 16vw 2vh 16vw'>
                    <SubButtonBase onClick={(e) => unidades(e)}><LinkBase to={'/escritorio/metodos/matematica/base-10/M43'}>Unidades<br/>1-9</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => decenas(e)}><LinkBase to={'/escritorio/metodos/matematica/base-10/M43'}>Decenas<br/>10-99</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => centenas(e)}><LinkBase to={'/escritorio/metodos/matematica/base-10/M43'}>Centenas<br/>100-999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => unidadDeMil(e)}><LinkBase to={'/escritorio/metodos/matematica/base-10/M43'}>Unidad de Mil<br/>1000-9999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => todos(e)}><LinkBase to={'/escritorio/metodos/matematica/base-10/M43'}>TODOS</LinkBase></SubButtonBase>
                </Flex>
                <FlexCuz position='fixed' right='0' bottom='0'>
                    <ButtonBack onClick={() => setPresentacion(false)}> Volver </ButtonBack>
                </FlexCuz>
            </div>
            }
            {construccion &&
            <div>
                <Flex wrap='wrap' margin='20vh 0vw 2vh 0vw' mt='18vh 10vw 2vh 10vw' md='16vh 17vw 2vh 17vw' mlg='12.5vh 16vw 2vh 16vw'>
                    <SubButtonBase onClick={(e) => unidades(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M44'}>Unidades<br/>1-9</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => decenas(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M44'}>Decenas<br/>10-99</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => centenas(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M44'}>Centenas 100-999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => unidadDeMil(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M44'}>Unidad de Mil<br/>1000-9999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => todos(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M44'}>TODOS</LinkBase></SubButtonBase>
                </Flex>
                <FlexCuz position='fixed' right='0' bottom='0'>
                    <ButtonBack onClick={() => setConstruccion(false)}> Volver </ButtonBack>
                </FlexCuz>
            </div>
            }
            {interpretacion &&
            <div>
                <Flex wrap='wrap' margin='20vh 0vw 2vh 0vw' mt='18vh 10vw 2vh 10vw' md='16vh 17vw 2vh 17vw' mlg='12.5vh 16vw 2vh 16vw'>
                    <SubButtonBase onClick={(e) => unidades(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M45'}>Unidades<br/>1-9</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => decenas(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M45'}>Decenas<br/>10-99</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => centenas(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M45'}>Centenas 100-999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => unidadDeMil(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M45'}>Unidad de Mil<br/>1000-9999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => todos(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M45'}>TODOS</LinkBase></SubButtonBase>
                </Flex>
                <FlexCuz position='fixed' right='0' bottom='0'>
                    <ButtonBack onClick={() => setInterpretacion(false)}> Volver </ButtonBack>
                </FlexCuz>
            </div>
            }
            {mayorYMenor &&
            <div>
                <Flex wrap='wrap' margin='20vh 0vw 2vh 0vw' mt='18vh 10vw 2vh 10vw' md='16vh 17vw 2vh 17vw' mlg='12.5vh 16vw 2vh 16vw'>
                    <SubButtonBase onClick={(e) => unidades(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M46'}>Unidades<br/>1-9</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => decenas(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M46'}>Decenas<br/>10-99</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => centenas(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M46'}>Centenas 100-999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => unidadDeMil(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M46'}>Unidad de Mil<br/>1000-9999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => todos(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M46'}>TODOS</LinkBase></SubButtonBase>
                </Flex>
                <FlexCuz position='fixed' right='0' bottom='0'>
                    <ButtonBack onClick={() => setMayorYMenor(false)}> Volver </ButtonBack>
                </FlexCuz>
            </div>
            }
            {anteriorYPosterior &&
            <div>
                <Flex wrap='wrap' margin='20vh 0vw 2vh 0vw' mt='18vh 10vw 2vh 10vw' md='16vh 17vw 2vh 17vw' mlg='12.5vh 16vw 2vh 16vw'>
                    <SubButtonBase onClick={(e) => unidades(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M47'}>Unidades<br/>1-9</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => decenas(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M47'}>Decenas<br/>10-99</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => centenas(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M47'}>Centenas 100-999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => unidadDeMil(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M47'}>Unidad de Mil<br/>1000-9999</LinkBase></SubButtonBase>
                    <SubButtonBase onClick={(e) => todos(e)}> <LinkBase to={'/escritorio/metodos/matematica/base-10/M47'}>TODOS</LinkBase></SubButtonBase>
                </Flex>
                <FlexCuz position='fixed' right='0' bottom='0'>
                    <ButtonBack onClick={() => setAnteriorYPosterior(false)}> Volver </ButtonBack>
                </FlexCuz>
            </div>
            }
        </Fragment>
    );
}
 
export default Base10;