const paisData = [
    {id:'1', parametro:"Argentina"},
    {id:'2', parametro:"Chile"},
    {id:'3', parametro:"Uruguay"},
    {id:'4', parametro:"Perú"},
    {id:'5', parametro:"Paraguay"}
];

const provinciaData = [
    {id_padre:"1",id:2,parametro:"Ciudad Autónoma de Buenos Aires"},
    {id_padre:"1",id:6,parametro:"Buenos Aires"},
    {id_padre:"1",id:10,parametro:"Catamarca"},
    {id_padre:"1",id:14,parametro:"Córdoba"},
    {id_padre:"1",id:18,parametro:"Corrientes"},
    {id_padre:"1",id:22,parametro:"Chaco"},
    {id_padre:"1",id:26,parametro:"Chubut"},
    {id_padre:"1",id:30,parametro:"Entre Ríos"},
    {id_padre:"1",id:34,parametro:"Formosa"},
    {id_padre:"1",id:38,parametro:"Jujuy"},
    {id_padre:"1",id:42,parametro:"La Pampa"},
    {id_padre:"1",id:46,parametro:"La Rioja"},
    {id_padre:"1",id:50,parametro:"Mendoza"},
    {id_padre:"1",id:54,parametro:"Misiones"},
    {id_padre:"1",id:58,parametro:"Neuquén"},
    {id_padre:"1",id:62,parametro:"Río Negro"},
    {id_padre:"1",id:66,parametro:"Salta"},
    {id_padre:"1",id:70,parametro:"San Juan"},
    {id_padre:"1",id:74,parametro:"San Luis"},
    {id_padre:"1",id:78,parametro:"Santa Cruz"},
    {id_padre:"1",id:82,parametro:"Santa Fe"},
    {id_padre:"1",id:86,parametro:"Santiago del Estero"},
    {id_padre:"1",id:90,parametro:"Tucumán"},
    {id_padre:"1",id:94,parametro:"Tierra del Fuego, Antártida e Islas del Atlántico Sur"}
];

const departamentoData = [
{id_pais:1,id_padre:2,id:1,parametro:"Ciudad Autónoma de Buenos Aires"}, 
{id_pais:1,id_padre:6,id:2,parametro:"Adolfo Alsina"}, 
{id_pais:1,id_padre:6,id:3,parametro:"Adolfo Gonzales Chaves"}, 
{id_pais:1,id_padre:6,id:4,parametro:"Alberti"}, 
{id_pais:1,id_padre:6,id:5,parametro:"Almirante Brown"}, 
{id_pais:1,id_padre:6,id:6,parametro:"Avellaneda"}, 
{id_pais:1,id_padre:6,id:7,parametro:"Ayacucho"}, 
{id_pais:1,id_padre:6,id:8,parametro:"Azul"}, 
{id_pais:1,id_padre:6,id:9,parametro:"Bahía Blanca"}, 
{id_pais:1,id_padre:6,id:10,parametro:"Balcarce"}, 
{id_pais:1,id_padre:6,id:11,parametro:"Baradero"}, 
{id_pais:1,id_padre:6,id:12,parametro:"Arrecifes"}, 
{id_pais:1,id_padre:6,id:13,parametro:"Benito Juárez"}, 
{id_pais:1,id_padre:6,id:14,parametro:"Berazategui"}, 
{id_pais:1,id_padre:6,id:15,parametro:"Berisso"}, 
{id_pais:1,id_padre:6,id:16,parametro:"Bolívar"}, 
{id_pais:1,id_padre:6,id:17,parametro:"Bragado"}, 
{id_pais:1,id_padre:6,id:18,parametro:"Brandsen"}, 
{id_pais:1,id_padre:6,id:19,parametro:"Campana"}, 
{id_pais:1,id_padre:6,id:20,parametro:"Cañuelas"}, 
{id_pais:1,id_padre:6,id:21,parametro:"Capitán Sarmiento"}, 
{id_pais:1,id_padre:6,id:22,parametro:"Carlos Casares"}, 
{id_pais:1,id_padre:6,id:23,parametro:"Carlos Tejedor"}, 
{id_pais:1,id_padre:6,id:24,parametro:"Carmen de Areco"}, 
{id_pais:1,id_padre:6,id:25,parametro:"Castelli"}, 
{id_pais:1,id_padre:6,id:26,parametro:"Colón"}, 
{id_pais:1,id_padre:6,id:27,parametro:"Coronel de Marina L. Rosales"}, 
{id_pais:1,id_padre:6,id:28,parametro:"Coronel Dorrego"}, 
{id_pais:1,id_padre:6,id:29,parametro:"Coronel Pringles"}, 
{id_pais:1,id_padre:6,id:30,parametro:"Coronel Suárez"}, 
{id_pais:1,id_padre:6,id:31,parametro:"Chacabuco"}, 
{id_pais:1,id_padre:6,id:32,parametro:"Chascomús"}, 
{id_pais:1,id_padre:6,id:33,parametro:"Chivilcoy"}, 
{id_pais:1,id_padre:6,id:34,parametro:"Daireaux"}, 
{id_pais:1,id_padre:6,id:35,parametro:"Dolores"}, 
{id_pais:1,id_padre:6,id:36,parametro:"Ensenada"}, 
{id_pais:1,id_padre:6,id:37,parametro:"Escobar"}, 
{id_pais:1,id_padre:6,id:38,parametro:"Esteban Echeverría"}, 
{id_pais:1,id_padre:6,id:39,parametro:"Exaltación de la Cruz"}, 
{id_pais:1,id_padre:6,id:40,parametro:"Ezeiza"}, 
{id_pais:1,id_padre:6,id:41,parametro:"Florencio Varela"}, 
{id_pais:1,id_padre:6,id:42,parametro:"Florentino Ameghino"}, 
{id_pais:1,id_padre:6,id:43,parametro:"General Alvarado"}, 
{id_pais:1,id_padre:6,id:44,parametro:"General Alvear"}, 
{id_pais:1,id_padre:6,id:45,parametro:"General Arenales"}, 
{id_pais:1,id_padre:6,id:46,parametro:"General Belgrano"}, 
{id_pais:1,id_padre:6,id:47,parametro:"General Guido"}, 
{id_pais:1,id_padre:6,id:48,parametro:"General Juan Madariaga"}, 
{id_pais:1,id_padre:6,id:49,parametro:"General La Madrid"}, 
{id_pais:1,id_padre:6,id:50,parametro:"General Las Heras"}, 
{id_pais:1,id_padre:6,id:51,parametro:"General Lavalle"}, 
{id_pais:1,id_padre:6,id:52,parametro:"General Paz"}, 
{id_pais:1,id_padre:6,id:53,parametro:"General Pinto"}, 
{id_pais:1,id_padre:6,id:54,parametro:"General Pueyrredón"}, 
{id_pais:1,id_padre:6,id:55,parametro:"General Rodríguez"}, 
{id_pais:1,id_padre:6,id:56,parametro:"General San Martín"}, 
{id_pais:1,id_padre:6,id:57,parametro:"General Viamonte"}, 
{id_pais:1,id_padre:6,id:58,parametro:"General Villegas"}, 
{id_pais:1,id_padre:6,id:59,parametro:"Guaminí"}, 
{id_pais:1,id_padre:6,id:60,parametro:"Hipólito Yrigoyen"}, 
{id_pais:1,id_padre:6,id:61,parametro:"Hurlingham"}, 
{id_pais:1,id_padre:6,id:62,parametro:"Ituzaingó"}, 
{id_pais:1,id_padre:6,id:63,parametro:"José C. Paz"}, 
{id_pais:1,id_padre:6,id:64,parametro:"Junín"}, 
{id_pais:1,id_padre:6,id:65,parametro:"La Costa"}, 
{id_pais:1,id_padre:6,id:66,parametro:"La Matanza"}, 
{id_pais:1,id_padre:6,id:67,parametro:"Lanús"}, 
{id_pais:1,id_padre:6,id:68,parametro:"La Plata"}, 
{id_pais:1,id_padre:6,id:69,parametro:"Laprida"}, 
{id_pais:1,id_padre:6,id:70,parametro:"Las Flores"}, 
{id_pais:1,id_padre:6,id:71,parametro:"Leandro N. Alem"}, 
{id_pais:1,id_padre:6,id:72,parametro:"Lincoln"}, 
{id_pais:1,id_padre:6,id:73,parametro:"Lobería"}, 
{id_pais:1,id_padre:6,id:74,parametro:"Lobos"}, 
{id_pais:1,id_padre:6,id:75,parametro:"Lomas de Zamora"}, 
{id_pais:1,id_padre:6,id:76,parametro:"Luján"}, 
{id_pais:1,id_padre:6,id:77,parametro:"Magdalena"}, 
{id_pais:1,id_padre:6,id:78,parametro:"Maipú"}, 
{id_pais:1,id_padre:6,id:79,parametro:"Malvinas Argentinas"}, 
{id_pais:1,id_padre:6,id:80,parametro:"Mar Chiquita"}, 
{id_pais:1,id_padre:6,id:81,parametro:"Marcos Paz"}, 
{id_pais:1,id_padre:6,id:82,parametro:"Mercedes"}, 
{id_pais:1,id_padre:6,id:83,parametro:"Merlo"}, 
{id_pais:1,id_padre:6,id:84,parametro:"Monte"}, 
{id_pais:1,id_padre:6,id:85,parametro:"Monte Hermoso"}, 
{id_pais:1,id_padre:6,id:86,parametro:"Moreno"}, 
{id_pais:1,id_padre:6,id:87,parametro:"Morón"}, 
{id_pais:1,id_padre:6,id:88,parametro:"Navarro"}, 
{id_pais:1,id_padre:6,id:89,parametro:"Necochea"}, 
{id_pais:1,id_padre:6,id:90,parametro:"9 de Julio"}, 
{id_pais:1,id_padre:6,id:91,parametro:"Olavarría"}, 
{id_pais:1,id_padre:6,id:92,parametro:"Patagones"}, 
{id_pais:1,id_padre:6,id:93,parametro:"Pehuajó"}, 
{id_pais:1,id_padre:6,id:94,parametro:"Pellegrini"}, 
{id_pais:1,id_padre:6,id:95,parametro:"Pergamino"}, 
{id_pais:1,id_padre:6,id:96,parametro:"Pila"}, 
{id_pais:1,id_padre:6,id:97,parametro:"Pilar"}, 
{id_pais:1,id_padre:6,id:98,parametro:"Pinamar"}, 
{id_pais:1,id_padre:6,id:99,parametro:"Presidente Perón"}, 
{id_pais:1,id_padre:6,id:100,parametro:"Puán"}, 
{id_pais:1,id_padre:6,id:101,parametro:"Punta Indio"}, 
{id_pais:1,id_padre:6,id:102,parametro:"Quilmes"}, 
{id_pais:1,id_padre:6,id:103,parametro:"Ramallo"}, 
{id_pais:1,id_padre:6,id:104,parametro:"Rauch"}, 
{id_pais:1,id_padre:6,id:105,parametro:"Rivadavia"}, 
{id_pais:1,id_padre:6,id:106,parametro:"Rojas"}, 
{id_pais:1,id_padre:6,id:107,parametro:"Roque Pérez"}, 
{id_pais:1,id_padre:6,id:108,parametro:"Saavedra"}, 
{id_pais:1,id_padre:6,id:109,parametro:"Saladillo"}, 
{id_pais:1,id_padre:6,id:110,parametro:"Salto"}, 
{id_pais:1,id_padre:6,id:111,parametro:"Salliqueló"}, 
{id_pais:1,id_padre:6,id:112,parametro:"San Andrés de Giles"}, 
{id_pais:1,id_padre:6,id:113,parametro:"San Antonio de Areco"}, 
{id_pais:1,id_padre:6,id:114,parametro:"San Cayetano"}, 
{id_pais:1,id_padre:6,id:115,parametro:"San Fernando"}, 
{id_pais:1,id_padre:6,id:116,parametro:"San Isidro"}, 
{id_pais:1,id_padre:6,id:117,parametro:"San Miguel"}, 
{id_pais:1,id_padre:6,id:118,parametro:"San Nicolás"}, 
{id_pais:1,id_padre:6,id:119,parametro:"San Pedro"}, 
{id_pais:1,id_padre:6,id:120,parametro:"San Vicente"}, 
{id_pais:1,id_padre:6,id:121,parametro:"Suipacha"}, 
{id_pais:1,id_padre:6,id:122,parametro:"Tandil"}, 
{id_pais:1,id_padre:6,id:123,parametro:"Tapalqué"}, 
{id_pais:1,id_padre:6,id:124,parametro:"Tigre"}, 
{id_pais:1,id_padre:6,id:125,parametro:"Tordillo"}, 
{id_pais:1,id_padre:6,id:126,parametro:"Tornquist"}, 
{id_pais:1,id_padre:6,id:127,parametro:"Trenque Lauquen"}, 
{id_pais:1,id_padre:6,id:128,parametro:"Tres Arroyos"}, 
{id_pais:1,id_padre:6,id:129,parametro:"Tres de Febrero"}, 
{id_pais:1,id_padre:6,id:130,parametro:"Tres Lomas"}, 
{id_pais:1,id_padre:6,id:131,parametro:"25 de Mayo"}, 
{id_pais:1,id_padre:6,id:132,parametro:"Vicente López"}, 
{id_pais:1,id_padre:6,id:133,parametro:"Villa Gesell"}, 
{id_pais:1,id_padre:6,id:134,parametro:"Villarino"}, 
{id_pais:1,id_padre:6,id:135,parametro:"Zárate"}, 
{id_pais:1,id_padre:10,id:136,parametro:"Ambato"}, 
{id_pais:1,id_padre:10,id:137,parametro:"Ancasti"}, 
{id_pais:1,id_padre:10,id:138,parametro:"Andalgalá"}, 
{id_pais:1,id_padre:10,id:139,parametro:"Antofagasta de la Sierra"}, 
{id_pais:1,id_padre:10,id:140,parametro:"Belén"}, 
{id_pais:1,id_padre:10,id:141,parametro:"Capayán"}, 
{id_pais:1,id_padre:10,id:142,parametro:"Capital"}, 
{id_pais:1,id_padre:10,id:143,parametro:"El Alto"}, 
{id_pais:1,id_padre:10,id:144,parametro:"Fray Mamerto Esquiú"}, 
{id_pais:1,id_padre:10,id:145,parametro:"La Paz"}, 
{id_pais:1,id_padre:10,id:146,parametro:"Paclín"}, 
{id_pais:1,id_padre:10,id:147,parametro:"Pomán"}, 
{id_pais:1,id_padre:10,id:148,parametro:"Santa María"}, 
{id_pais:1,id_padre:10,id:149,parametro:"Santa Rosa"}, 
{id_pais:1,id_padre:10,id:150,parametro:"Tinogasta"}, 
{id_pais:1,id_padre:10,id:151,parametro:"Valle Viejo"}, 
{id_pais:1,id_padre:14,id:152,parametro:"Calamuchita"}, 
{id_pais:1,id_padre:14,id:153,parametro:"Capital"}, 
{id_pais:1,id_padre:14,id:154,parametro:"Colón"}, 
{id_pais:1,id_padre:14,id:155,parametro:"Cruz del Eje"}, 
{id_pais:1,id_padre:14,id:156,parametro:"General Roca"}, 
{id_pais:1,id_padre:14,id:157,parametro:"General San Martín"}, 
{id_pais:1,id_padre:14,id:158,parametro:"Ischilín"}, 
{id_pais:1,id_padre:14,id:159,parametro:"Juárez Celman"}, 
{id_pais:1,id_padre:14,id:160,parametro:"Marcos Juárez"}, 
{id_pais:1,id_padre:14,id:161,parametro:"Minas"}, 
{id_pais:1,id_padre:14,id:162,parametro:"Pocho"}, 
{id_pais:1,id_padre:14,id:163,parametro:"Presidente Roque Sáenz Peña"}, 
{id_pais:1,id_padre:14,id:164,parametro:"Punilla"}, 
{id_pais:1,id_padre:14,id:165,parametro:"Río Cuarto"}, 
{id_pais:1,id_padre:14,id:166,parametro:"Río Primero"}, 
{id_pais:1,id_padre:14,id:167,parametro:"Río Seco"}, 
{id_pais:1,id_padre:14,id:168,parametro:"Río Segundo"}, 
{id_pais:1,id_padre:14,id:169,parametro:"San Alberto"}, 
{id_pais:1,id_padre:14,id:170,parametro:"San Javier"}, 
{id_pais:1,id_padre:14,id:171,parametro:"San Justo"}, 
{id_pais:1,id_padre:14,id:172,parametro:"Santa María"}, 
{id_pais:1,id_padre:14,id:173,parametro:"Sobremonte"}, 
{id_pais:1,id_padre:14,id:174,parametro:"Tercero Arriba"}, 
{id_pais:1,id_padre:14,id:175,parametro:"Totoral"}, 
{id_pais:1,id_padre:14,id:176,parametro:"Totoral"}, 
{id_pais:1,id_padre:14,id:177,parametro:"Tulumba"}, 
{id_pais:1,id_padre:14,id:178,parametro:"Unión"}, 
{id_pais:1,id_padre:18,id:179,parametro:"Bella Vista"}, 
{id_pais:1,id_padre:18,id:180,parametro:"Berón de Astrada"}, 
{id_pais:1,id_padre:18,id:181,parametro:"Capital"}, 
{id_pais:1,id_padre:18,id:182,parametro:"Concepción"}, 
{id_pais:1,id_padre:18,id:183,parametro:"Curuzú Cuatiá"}, 
{id_pais:1,id_padre:18,id:184,parametro:"Empedrado"}, 
{id_pais:1,id_padre:18,id:185,parametro:"Esquina"}, 
{id_pais:1,id_padre:18,id:186,parametro:"General Alvear"}, 
{id_pais:1,id_padre:18,id:187,parametro:"General Paz"}, 
{id_pais:1,id_padre:18,id:188,parametro:"Goya"}, 
{id_pais:1,id_padre:18,id:189,parametro:"Itatí"}, 
{id_pais:1,id_padre:18,id:190,parametro:"Ituzaingó"}, 
{id_pais:1,id_padre:18,id:191,parametro:"Lavalle"}, 
{id_pais:1,id_padre:18,id:192,parametro:"Mburucuyá"}, 
{id_pais:1,id_padre:18,id:193,parametro:"Mercedes"}, 
{id_pais:1,id_padre:18,id:194,parametro:"Monte Caseros"}, 
{id_pais:1,id_padre:18,id:195,parametro:"Paso de los Libres"}, 
{id_pais:1,id_padre:18,id:196,parametro:"Saladas"}, 
{id_pais:1,id_padre:18,id:197,parametro:"San Cosme"}, 
{id_pais:1,id_padre:18,id:198,parametro:"San Luis del Palmar"}, 
{id_pais:1,id_padre:18,id:199,parametro:"San Martín"}, 
{id_pais:1,id_padre:18,id:200,parametro:"San Miguel"}, 
{id_pais:1,id_padre:18,id:201,parametro:"San Roque"}, 
{id_pais:1,id_padre:18,id:202,parametro:"Santo Tomé"}, 
{id_pais:1,id_padre:18,id:203,parametro:"Sauce"}, 
{id_pais:1,id_padre:22,id:204,parametro:"Almirante Brown"}, 
{id_pais:1,id_padre:22,id:205,parametro:"Bermejo"}, 
{id_pais:1,id_padre:22,id:206,parametro:"Comandante Fernández"}, 
{id_pais:1,id_padre:22,id:207,parametro:"Chacabuco"}, 
{id_pais:1,id_padre:22,id:208,parametro:"12 de Octubre"}, 
{id_pais:1,id_padre:22,id:209,parametro:"2 de Abril"}, 
{id_pais:1,id_padre:22,id:210,parametro:"Fray Justo Santa María de Oro"}, 
{id_pais:1,id_padre:22,id:211,parametro:"General Belgrano"}, 
{id_pais:1,id_padre:22,id:212,parametro:"General Donovan"}, 
{id_pais:1,id_padre:22,id:213,parametro:"General Güemes"}, 
{id_pais:1,id_padre:22,id:214,parametro:"Independencia"}, 
{id_pais:1,id_padre:22,id:215,parametro:"Libertad"}, 
{id_pais:1,id_padre:22,id:216,parametro:"Libertador General San Martín"}, 
{id_pais:1,id_padre:22,id:217,parametro:"Maipú"}, 
{id_pais:1,id_padre:22,id:218,parametro:"Mayor Luis J. Fontana"}, 
{id_pais:1,id_padre:22,id:219,parametro:"9 de Julio"}, 
{id_pais:1,id_padre:22,id:220,parametro:"O' Higgins"}, 
{id_pais:1,id_padre:22,id:221,parametro:"Presidencia de la Plaza"}, 
{id_pais:1,id_padre:22,id:222,parametro:"1° de Mayo"}, 
{id_pais:1,id_padre:22,id:223,parametro:"Quitilipi"}, 
{id_pais:1,id_padre:22,id:224,parametro:"San Fernando"}, 
{id_pais:1,id_padre:22,id:225,parametro:"San Lorenzo"}, 
{id_pais:1,id_padre:22,id:226,parametro:"San Lorenzo"}, 
{id_pais:1,id_padre:22,id:227,parametro:"Sargento Cabral"}, 
{id_pais:1,id_padre:22,id:228,parametro:"Tapenagá"}, 
{id_pais:1,id_padre:22,id:229,parametro:"25 de Mayo"}, 
{id_pais:1,id_padre:26,id:230,parametro:"Biedma"}, 
{id_pais:1,id_padre:26,id:231,parametro:"Cushamen"}, 
{id_pais:1,id_padre:26,id:232,parametro:"Escalante"}, 
{id_pais:1,id_padre:26,id:233,parametro:"Florentino Ameghino"}, 
{id_pais:1,id_padre:26,id:234,parametro:"Futaleufú"}, 
{id_pais:1,id_padre:26,id:235,parametro:"Gaiman"}, 
{id_pais:1,id_padre:26,id:236,parametro:"Gastre"}, 
{id_pais:1,id_padre:26,id:237,parametro:"Languiñeo"}, 
{id_pais:1,id_padre:26,id:238,parametro:"Mártires"}, 
{id_pais:1,id_padre:26,id:239,parametro:"Paso de Indios"}, 
{id_pais:1,id_padre:26,id:240,parametro:"Rawson"}, 
{id_pais:1,id_padre:26,id:241,parametro:"Río Senguer"}, 
{id_pais:1,id_padre:26,id:242,parametro:"Sarmiento"}, 
{id_pais:1,id_padre:26,id:243,parametro:"Tehuelches"}, 
{id_pais:1,id_padre:26,id:244,parametro:"Telsen"}, 
{id_pais:1,id_padre:30,id:245,parametro:"Colón"}, 
{id_pais:1,id_padre:30,id:246,parametro:"Concordia"}, 
{id_pais:1,id_padre:30,id:247,parametro:"Diamante"}, 
{id_pais:1,id_padre:30,id:248,parametro:"Federación"}, 
{id_pais:1,id_padre:30,id:249,parametro:"Federal"}, 
{id_pais:1,id_padre:30,id:250,parametro:"Feliciano"}, 
{id_pais:1,id_padre:30,id:251,parametro:"Gualeguay"}, 
{id_pais:1,id_padre:30,id:252,parametro:"Gualeguaychú"}, 
{id_pais:1,id_padre:30,id:253,parametro:"Islas del Ibicuy"}, 
{id_pais:1,id_padre:30,id:254,parametro:"La Paz"}, 
{id_pais:1,id_padre:30,id:255,parametro:"Nogoyá"}, 
{id_pais:1,id_padre:30,id:256,parametro:"Paraná"}, 
{id_pais:1,id_padre:30,id:257,parametro:"San Salvador"}, 
{id_pais:1,id_padre:30,id:258,parametro:"Tala"}, 
{id_pais:1,id_padre:30,id:259,parametro:"Uruguay"}, 
{id_pais:1,id_padre:30,id:260,parametro:"Victoria"}, 
{id_pais:1,id_padre:30,id:261,parametro:"Villaguay"}, 
{id_pais:1,id_padre:34,id:262,parametro:"Bermejo"}, 
{id_pais:1,id_padre:34,id:263,parametro:"Formosa"}, 
{id_pais:1,id_padre:34,id:264,parametro:"Laishi"}, 
{id_pais:1,id_padre:34,id:265,parametro:"Matacos"}, 
{id_pais:1,id_padre:34,id:266,parametro:"Patiño"}, 
{id_pais:1,id_padre:34,id:267,parametro:"Pilagás"}, 
{id_pais:1,id_padre:34,id:268,parametro:"Pilcomayo"}, 
{id_pais:1,id_padre:34,id:269,parametro:"Pirané"}, 
{id_pais:1,id_padre:34,id:270,parametro:"Ramón Lista"}, 
{id_pais:1,id_padre:38,id:271,parametro:"Cochinoca"}, 
{id_pais:1,id_padre:38,id:272,parametro:"El Carmen"}, 
{id_pais:1,id_padre:38,id:273,parametro:"Dr. Manuel Belgrano"}, 
{id_pais:1,id_padre:38,id:274,parametro:"Humahuaca"}, 
{id_pais:1,id_padre:38,id:275,parametro:"Ledesma"}, 
{id_pais:1,id_padre:38,id:276,parametro:"Palpalá"}, 
{id_pais:1,id_padre:38,id:277,parametro:"Rinconada"}, 
{id_pais:1,id_padre:38,id:278,parametro:"San Antonio"}, 
{id_pais:1,id_padre:38,id:279,parametro:"San Pedro"}, 
{id_pais:1,id_padre:38,id:280,parametro:"Santa Bárbara"}, 
{id_pais:1,id_padre:38,id:281,parametro:"Santa Catalina"}, 
{id_pais:1,id_padre:38,id:282,parametro:"Susques"}, 
{id_pais:1,id_padre:38,id:283,parametro:"Tilcara"}, 
{id_pais:1,id_padre:38,id:284,parametro:"Tumbaya"}, 
{id_pais:1,id_padre:38,id:285,parametro:"Valle Grande"}, 
{id_pais:1,id_padre:38,id:286,parametro:"Yavi"}, 
{id_pais:1,id_padre:42,id:287,parametro:"Atreucó"}, 
{id_pais:1,id_padre:42,id:288,parametro:"Caleu Caleu"}, 
{id_pais:1,id_padre:42,id:289,parametro:"Capital"}, 
{id_pais:1,id_padre:42,id:290,parametro:"Catriló"}, 
{id_pais:1,id_padre:42,id:291,parametro:"Conhelo"}, 
{id_pais:1,id_padre:42,id:292,parametro:"Curacó"}, 
{id_pais:1,id_padre:42,id:293,parametro:"Chalileo"}, 
{id_pais:1,id_padre:42,id:294,parametro:"Chapaleufú"}, 
{id_pais:1,id_padre:42,id:295,parametro:"Chicalcó"}, 
{id_pais:1,id_padre:42,id:296,parametro:"Guatraché"}, 
{id_pais:1,id_padre:42,id:297,parametro:"Hucal"}, 
{id_pais:1,id_padre:42,id:298,parametro:"Lihuel Calel"}, 
{id_pais:1,id_padre:42,id:299,parametro:"Limay Mahuida"}, 
{id_pais:1,id_padre:42,id:300,parametro:"Loventué"}, 
{id_pais:1,id_padre:42,id:301,parametro:"Maracó"}, 
{id_pais:1,id_padre:42,id:302,parametro:"Puelén"}, 
{id_pais:1,id_padre:42,id:303,parametro:"Quemú Quemú"}, 
{id_pais:1,id_padre:42,id:304,parametro:"Rancul"}, 
{id_pais:1,id_padre:42,id:305,parametro:"Realicó"}, 
{id_pais:1,id_padre:42,id:306,parametro:"Toay"}, 
{id_pais:1,id_padre:42,id:307,parametro:"Trenel"}, 
{id_pais:1,id_padre:42,id:308,parametro:"Utracán"}, 
{id_pais:1,id_padre:46,id:309,parametro:"Arauco"}, 
{id_pais:1,id_padre:46,id:310,parametro:"Capital"}, 
{id_pais:1,id_padre:46,id:311,parametro:"Castro Barros"}, 
{id_pais:1,id_padre:46,id:312,parametro:"Coronel Felipe Varela"}, 
{id_pais:1,id_padre:46,id:313,parametro:"Chamical"}, 
{id_pais:1,id_padre:46,id:314,parametro:"Chilecito"}, 
{id_pais:1,id_padre:46,id:315,parametro:"Famatina"}, 
{id_pais:1,id_padre:46,id:316,parametro:"General Angel V. Peñaloza"}, 
{id_pais:1,id_padre:46,id:317,parametro:"General Belgrano"}, 
{id_pais:1,id_padre:46,id:318,parametro:"General Juan F. Quiroga"}, 
{id_pais:1,id_padre:46,id:319,parametro:"General Lamadrid"}, 
{id_pais:1,id_padre:46,id:320,parametro:"General Ocampo"}, 
{id_pais:1,id_padre:46,id:321,parametro:"General San Martín"}, 
{id_pais:1,id_padre:46,id:322,parametro:"Vinchina"}, 
{id_pais:1,id_padre:46,id:323,parametro:"Independencia"}, 
{id_pais:1,id_padre:46,id:324,parametro:"Rosario Vera Peñaloza"}, 
{id_pais:1,id_padre:46,id:325,parametro:"San Blas de los Sauces"}, 
{id_pais:1,id_padre:46,id:326,parametro:"Sanagasta"}, 
{id_pais:1,id_padre:50,id:327,parametro:"Capital"}, 
{id_pais:1,id_padre:50,id:328,parametro:"General Alvear"}, 
{id_pais:1,id_padre:50,id:329,parametro:"Godoy Cruz"}, 
{id_pais:1,id_padre:50,id:330,parametro:"Guaymallén"}, 
{id_pais:1,id_padre:50,id:331,parametro:"Junín"}, 
{id_pais:1,id_padre:50,id:332,parametro:"La Paz"}, 
{id_pais:1,id_padre:50,id:333,parametro:"Las Heras"}, 
{id_pais:1,id_padre:50,id:334,parametro:"Lavalle"}, 
{id_pais:1,id_padre:50,id:335,parametro:"Luján de Cuyo"}, 
{id_pais:1,id_padre:50,id:336,parametro:"Maipú"}, 
{id_pais:1,id_padre:50,id:337,parametro:"Malargüe"}, 
{id_pais:1,id_padre:50,id:338,parametro:"Rivadavia"}, 
{id_pais:1,id_padre:50,id:339,parametro:"San Carlos"}, 
{id_pais:1,id_padre:50,id:340,parametro:"San Martín"}, 
{id_pais:1,id_padre:50,id:341,parametro:"San Rafael"}, 
{id_pais:1,id_padre:50,id:342,parametro:"Santa Rosa"}, 
{id_pais:1,id_padre:50,id:343,parametro:"Tunuyán"}, 
{id_pais:1,id_padre:50,id:344,parametro:"Tupungato"}, 
{id_pais:1,id_padre:54,id:345,parametro:"Apóstoles"}, 
{id_pais:1,id_padre:54,id:346,parametro:"Cainguás"}, 
{id_pais:1,id_padre:54,id:347,parametro:"Candelaria"}, 
{id_pais:1,id_padre:54,id:348,parametro:"Capital"}, 
{id_pais:1,id_padre:54,id:349,parametro:"Concepción"}, 
{id_pais:1,id_padre:54,id:350,parametro:"Eldorado"}, 
{id_pais:1,id_padre:54,id:351,parametro:"General Manuel Belgrano"}, 
{id_pais:1,id_padre:54,id:352,parametro:"Guaraní"}, 
{id_pais:1,id_padre:54,id:353,parametro:"Iguazú"}, 
{id_pais:1,id_padre:54,id:354,parametro:"Leandro N. Alem"}, 
{id_pais:1,id_padre:54,id:355,parametro:"Libertador Grl. San Martín"}, 
{id_pais:1,id_padre:54,id:356,parametro:"Montecarlo"}, 
{id_pais:1,id_padre:54,id:357,parametro:"Oberá"}, 
{id_pais:1,id_padre:54,id:358,parametro:"San Ignacio"}, 
{id_pais:1,id_padre:54,id:359,parametro:"San Javier"}, 
{id_pais:1,id_padre:54,id:360,parametro:"San Pedro"}, 
{id_pais:1,id_padre:54,id:361,parametro:"25 de Mayo"}, 
{id_pais:1,id_padre:58,id:362,parametro:"Aluminé"}, 
{id_pais:1,id_padre:58,id:363,parametro:"Añelo"}, 
{id_pais:1,id_padre:58,id:364,parametro:"Catán Lil"}, 
{id_pais:1,id_padre:58,id:365,parametro:"Collón Curá"}, 
{id_pais:1,id_padre:58,id:366,parametro:"Confluencia"}, 
{id_pais:1,id_padre:58,id:367,parametro:"Chos Malal"}, 
{id_pais:1,id_padre:58,id:368,parametro:"Huiliches"}, 
{id_pais:1,id_padre:58,id:369,parametro:"Lácar"}, 
{id_pais:1,id_padre:58,id:370,parametro:"Loncopué"}, 
{id_pais:1,id_padre:58,id:371,parametro:"Los Lagos"}, 
{id_pais:1,id_padre:58,id:372,parametro:"Minas"}, 
{id_pais:1,id_padre:58,id:373,parametro:"Ñorquín"}, 
{id_pais:1,id_padre:58,id:374,parametro:"Pehuenches"}, 
{id_pais:1,id_padre:58,id:375,parametro:"Picún Leufú"}, 
{id_pais:1,id_padre:58,id:376,parametro:"Picunches"}, 
{id_pais:1,id_padre:58,id:377,parametro:"Zapala"}, 
{id_pais:1,id_padre:62,id:378,parametro:"Adolfo Alsina"}, 
{id_pais:1,id_padre:62,id:379,parametro:"Avellaneda"}, 
{id_pais:1,id_padre:62,id:380,parametro:"Bariloche"}, 
{id_pais:1,id_padre:62,id:381,parametro:"Conesa"}, 
{id_pais:1,id_padre:62,id:382,parametro:"El Cuy"}, 
{id_pais:1,id_padre:62,id:383,parametro:"General Roca"}, 
{id_pais:1,id_padre:62,id:384,parametro:"9 de Julio"}, 
{id_pais:1,id_padre:62,id:385,parametro:"Ñorquincó"}, 
{id_pais:1,id_padre:62,id:386,parametro:"Pichi Mahuida"}, 
{id_pais:1,id_padre:62,id:387,parametro:"Pilcaniyeu"}, 
{id_pais:1,id_padre:62,id:388,parametro:"San Antonio"}, 
{id_pais:1,id_padre:62,id:389,parametro:"Valcheta"}, 
{id_pais:1,id_padre:62,id:390,parametro:"25 de Mayo"}, 
{id_pais:1,id_padre:66,id:391,parametro:"Anta"}, 
{id_pais:1,id_padre:66,id:392,parametro:"Cachi"}, 
{id_pais:1,id_padre:66,id:393,parametro:"Cafayate"}, 
{id_pais:1,id_padre:66,id:394,parametro:"Capital"}, 
{id_pais:1,id_padre:66,id:395,parametro:"Cerrillos"}, 
{id_pais:1,id_padre:66,id:396,parametro:"Chicoana"}, 
{id_pais:1,id_padre:66,id:397,parametro:"General Güemes"}, 
{id_pais:1,id_padre:66,id:398,parametro:"Grl. José de San Martín"}, 
{id_pais:1,id_padre:66,id:399,parametro:"Guachipas"}, 
{id_pais:1,id_padre:66,id:400,parametro:"Iruya"}, 
{id_pais:1,id_padre:66,id:401,parametro:"La Caldera"}, 
{id_pais:1,id_padre:66,id:402,parametro:"La Candelaria"}, 
{id_pais:1,id_padre:66,id:403,parametro:"La Poma"}, 
{id_pais:1,id_padre:66,id:404,parametro:"La Viña"}, 
{id_pais:1,id_padre:66,id:405,parametro:"Los Andes"}, 
{id_pais:1,id_padre:66,id:406,parametro:"Metán"}, 
{id_pais:1,id_padre:66,id:407,parametro:"Molinos"}, 
{id_pais:1,id_padre:66,id:408,parametro:"Orán"}, 
{id_pais:1,id_padre:66,id:409,parametro:"Rivadavia"}, 
{id_pais:1,id_padre:66,id:410,parametro:"Rosario de la Frontera"}, 
{id_pais:1,id_padre:66,id:411,parametro:"Rosario de la Frontera"}, 
{id_pais:1,id_padre:66,id:412,parametro:"Rosario de Lerma"}, 
{id_pais:1,id_padre:66,id:413,parametro:"San Carlos"}, 
{id_pais:1,id_padre:66,id:414,parametro:"Santa Victoria"}, 
{id_pais:1,id_padre:66,id:415,parametro:"Santa Victoria"}, 
{id_pais:1,id_padre:70,id:416,parametro:"Albardón"}, 
{id_pais:1,id_padre:70,id:417,parametro:"Angaco"}, 
{id_pais:1,id_padre:70,id:418,parametro:"Calingasta"}, 
{id_pais:1,id_padre:70,id:419,parametro:"Capital"}, 
{id_pais:1,id_padre:70,id:420,parametro:"Caucete"}, 
{id_pais:1,id_padre:70,id:421,parametro:"Chimbas"}, 
{id_pais:1,id_padre:70,id:422,parametro:"Iglesia"}, 
{id_pais:1,id_padre:70,id:423,parametro:"Jáchal"}, 
{id_pais:1,id_padre:70,id:424,parametro:"9 de Julio"}, 
{id_pais:1,id_padre:70,id:425,parametro:"Pocito"}, 
{id_pais:1,id_padre:70,id:426,parametro:"Rawson"}, 
{id_pais:1,id_padre:70,id:427,parametro:"Rivadavia"}, 
{id_pais:1,id_padre:70,id:428,parametro:"San Martín"}, 
{id_pais:1,id_padre:70,id:429,parametro:"Santa Lucía"}, 
{id_pais:1,id_padre:70,id:430,parametro:"Sarmiento"}, 
{id_pais:1,id_padre:70,id:431,parametro:"Ullúm"}, 
{id_pais:1,id_padre:70,id:432,parametro:"Valle Fértil"}, 
{id_pais:1,id_padre:70,id:433,parametro:"25 de Mayo"}, 
{id_pais:1,id_padre:70,id:434,parametro:"Zonda"}, 
{id_pais:1,id_padre:74,id:435,parametro:"Ayacucho"}, 
{id_pais:1,id_padre:74,id:436,parametro:"Belgrano"}, 
{id_pais:1,id_padre:74,id:437,parametro:"Coronel Pringles"}, 
{id_pais:1,id_padre:74,id:438,parametro:"Chacabuco"}, 
{id_pais:1,id_padre:74,id:439,parametro:"General Pedernera"}, 
{id_pais:1,id_padre:74,id:440,parametro:"Gobernador Dupuy"}, 
{id_pais:1,id_padre:74,id:441,parametro:"Junín"}, 
{id_pais:1,id_padre:74,id:442,parametro:"La Capital"}, 
{id_pais:1,id_padre:74,id:443,parametro:"Libertador General San Martín"}, 
{id_pais:1,id_padre:78,id:444,parametro:"Corpen Aike"}, 
{id_pais:1,id_padre:78,id:445,parametro:"Deseado"}, 
{id_pais:1,id_padre:78,id:446,parametro:"Güer Aike"}, 
{id_pais:1,id_padre:78,id:447,parametro:"Lago Argentino"}, 
{id_pais:1,id_padre:78,id:448,parametro:"Lago Buenos Aires"}, 
{id_pais:1,id_padre:78,id:449,parametro:"Magallanes"}, 
{id_pais:1,id_padre:78,id:450,parametro:"Río Chico"}, 
{id_pais:1,id_padre:82,id:451,parametro:"Belgrano"}, 
{id_pais:1,id_padre:82,id:452,parametro:"Caseros"}, 
{id_pais:1,id_padre:82,id:453,parametro:"Castellanos"}, 
{id_pais:1,id_padre:82,id:454,parametro:"Constitución"}, 
{id_pais:1,id_padre:82,id:455,parametro:"Garay"}, 
{id_pais:1,id_padre:82,id:456,parametro:"General López"}, 
{id_pais:1,id_padre:82,id:457,parametro:"General Obligado"}, 
{id_pais:1,id_padre:82,id:458,parametro:"Iriondo"}, 
{id_pais:1,id_padre:82,id:459,parametro:"La Capital"}, 
{id_pais:1,id_padre:82,id:460,parametro:"Las Colonias"}, 
{id_pais:1,id_padre:82,id:461,parametro:"9 de Julio"}, 
{id_pais:1,id_padre:82,id:462,parametro:"Rosario"}, 
{id_pais:1,id_padre:82,id:463,parametro:"San Cristóbal"}, 
{id_pais:1,id_padre:82,id:464,parametro:"San Javier"}, 
{id_pais:1,id_padre:82,id:465,parametro:"San Jerónimo"}, 
{id_pais:1,id_padre:82,id:466,parametro:"San Justo"}, 
{id_pais:1,id_padre:82,id:467,parametro:"San Lorenzo"}, 
{id_pais:1,id_padre:82,id:468,parametro:"San Martín"}, 
{id_pais:1,id_padre:82,id:469,parametro:"Vera"}, 
{id_pais:1,id_padre:86,id:470,parametro:"Aguirre"}, 
{id_pais:1,id_padre:86,id:471,parametro:"Alberdi"}, 
{id_pais:1,id_padre:86,id:472,parametro:"Atamisqui"}, 
{id_pais:1,id_padre:86,id:473,parametro:"Avellaneda"}, 
{id_pais:1,id_padre:86,id:474,parametro:"Banda"}, 
{id_pais:1,id_padre:86,id:475,parametro:"Belgrano"}, 
{id_pais:1,id_padre:86,id:476,parametro:"Capital"}, 
{id_pais:1,id_padre:86,id:477,parametro:"Copo"}, 
{id_pais:1,id_padre:86,id:478,parametro:"Choya"}, 
{id_pais:1,id_padre:86,id:479,parametro:"Figueroa"}, 
{id_pais:1,id_padre:86,id:480,parametro:"General Taboada"}, 
{id_pais:1,id_padre:86,id:481,parametro:"Guasayán"}, 
{id_pais:1,id_padre:86,id:482,parametro:"Jiménez"}, 
{id_pais:1,id_padre:86,id:483,parametro:"Juan F. Ibarra"}, 
{id_pais:1,id_padre:86,id:484,parametro:"Loreto"}, 
{id_pais:1,id_padre:86,id:485,parametro:"Mitre"}, 
{id_pais:1,id_padre:86,id:486,parametro:"Moreno"}, 
{id_pais:1,id_padre:86,id:487,parametro:"Ojo de Agua"}, 
{id_pais:1,id_padre:86,id:488,parametro:"Pellegrini"}, 
{id_pais:1,id_padre:86,id:489,parametro:"Quebrachos"}, 
{id_pais:1,id_padre:86,id:490,parametro:"Quebrachos"}, 
{id_pais:1,id_padre:86,id:491,parametro:"Río Hondo"}, 
{id_pais:1,id_padre:86,id:492,parametro:"Rivadavia"}, 
{id_pais:1,id_padre:86,id:493,parametro:"Robles"}, 
{id_pais:1,id_padre:86,id:494,parametro:"Salavina"}, 
{id_pais:1,id_padre:86,id:495,parametro:"San Martín"}, 
{id_pais:1,id_padre:86,id:496,parametro:"Sarmiento"}, 
{id_pais:1,id_padre:86,id:497,parametro:"Silípica"}, 
{id_pais:1,id_padre:90,id:498,parametro:"Burruyacú"}, 
{id_pais:1,id_padre:90,id:499,parametro:"Cruz Alta"}, 
{id_pais:1,id_padre:90,id:500,parametro:"Chicligasta"}, 
{id_pais:1,id_padre:90,id:501,parametro:"Famaillá"}, 
{id_pais:1,id_padre:90,id:502,parametro:"Graneros"}, 
{id_pais:1,id_padre:90,id:503,parametro:"Juan Bautista Alberdi"}, 
{id_pais:1,id_padre:90,id:504,parametro:"La Cocha"}, 
{id_pais:1,id_padre:90,id:505,parametro:"Leales"}, 
{id_pais:1,id_padre:90,id:506,parametro:"Lules"}, 
{id_pais:1,id_padre:90,id:507,parametro:"Monteros"}, 
{id_pais:1,id_padre:90,id:508,parametro:"Río Chico"}, 
{id_pais:1,id_padre:90,id:509,parametro:"Capital"}, 
{id_pais:1,id_padre:90,id:510,parametro:"Simoca"}, 
{id_pais:1,id_padre:90,id:511,parametro:"Tafí del Valle"}, 
{id_pais:1,id_padre:90,id:512,parametro:"Tafí Viejo"}, 
{id_pais:1,id_padre:90,id:513,parametro:"Trancas"}, 
{id_pais:1,id_padre:90,id:514,parametro:"Yerba Buena"}, 
{id_pais:1,id_padre:94,id:515,parametro:"Río Grande"}, 
{id_pais:1,id_padre:94,id:516,parametro:"Ushuaia"}
];

const localidadData = [
    {id_pais:1,id_provincia:2,id_padre:1,id:1,parametro:"Agronomía"},
    {id_pais:1,id_provincia:2,id_padre:1,id:2,parametro:"Balvanera"},
    {id_pais:1,id_provincia:2,id_padre:1,id:3,parametro:"Barracas"},
    {id_pais:1,id_provincia:2,id_padre:1,id:4,parametro:"Belgrano"},
    {id_pais:1,id_provincia:2,id_padre:1,id:5,parametro:"Boedo"},
    {id_pais:1,id_provincia:2,id_padre:1,id:6,parametro:"Caballito"},
    {id_pais:1,id_provincia:2,id_padre:1,id:7,parametro:"Chacarita"},
    {id_pais:1,id_provincia:2,id_padre:1,id:8,parametro:"Coghlan"},
    {id_pais:1,id_provincia:2,id_padre:1,id:9,parametro:"Colegiales"},
    {id_pais:1,id_provincia:2,id_padre:1,id:10,parametro:"Constitución"},
    {id_pais:1,id_provincia:2,id_padre:1,id:11,parametro:"Flores"},
    {id_pais:1,id_provincia:2,id_padre:1,id:12,parametro:"Floresta"},
    {id_pais:1,id_provincia:2,id_padre:1,id:13,parametro:"La Boca"},
    {id_pais:1,id_provincia:2,id_padre:1,id:14,parametro:"La Paternal"},
    {id_pais:1,id_provincia:2,id_padre:1,id:15,parametro:"Liniers"},
    {id_pais:1,id_provincia:2,id_padre:1,id:16,parametro:"Mataderos"},
    {id_pais:1,id_provincia:2,id_padre:1,id:17,parametro:"Monte Castro"},
    {id_pais:1,id_provincia:2,id_padre:1,id:18,parametro:"Montserrat"},
    {id_pais:1,id_provincia:2,id_padre:1,id:19,parametro:"Nueva Pompeya"},
    {id_pais:1,id_provincia:2,id_padre:1,id:20,parametro:"Nuñez"},
    {id_pais:1,id_provincia:2,id_padre:1,id:21,parametro:"Palermo"},
    {id_pais:1,id_provincia:2,id_padre:1,id:22,parametro:"Parque Avellaneda"},
    {id_pais:1,id_provincia:2,id_padre:1,id:23,parametro:"Parque Chacabuco"},
    {id_pais:1,id_provincia:2,id_padre:1,id:24,parametro:"Parque Chas"},
    {id_pais:1,id_provincia:2,id_padre:1,id:25,parametro:"Parque Patricios"},
    {id_pais:1,id_provincia:2,id_padre:1,id:26,parametro:"Puerto Madero"},
    {id_pais:1,id_provincia:2,id_padre:1,id:27,parametro:"Recoleta"},
    {id_pais:1,id_provincia:2,id_padre:1,id:28,parametro:"Retiro"},
    {id_pais:1,id_provincia:2,id_padre:1,id:29,parametro:"Saavedra"},
    {id_pais:1,id_provincia:2,id_padre:1,id:30,parametro:"San Cristóbal"},
    {id_pais:1,id_provincia:2,id_padre:1,id:31,parametro:"San Nicolás"},
    {id_pais:1,id_provincia:2,id_padre:1,id:32,parametro:"San Telmo"},
    {id_pais:1,id_provincia:2,id_padre:1,id:33,parametro:"Versalles"},
    {id_pais:1,id_provincia:2,id_padre:1,id:34,parametro:"Villa Crespo"},
    {id_pais:1,id_provincia:2,id_padre:1,id:35,parametro:"Villa Devoto"},
    {id_pais:1,id_provincia:2,id_padre:1,id:36,parametro:"Villa General Mitre"},
    {id_pais:1,id_provincia:2,id_padre:1,id:37,parametro:"Villa Lugano"},
    {id_pais:1,id_provincia:2,id_padre:1,id:38,parametro:"Villa Luro"},
    {id_pais:1,id_provincia:2,id_padre:1,id:39,parametro:"Villa Ortúzar"},
    {id_pais:1,id_provincia:2,id_padre:1,id:40,parametro:"Villa Pueyrredón"},
    {id_pais:1,id_provincia:2,id_padre:1,id:41,parametro:"Villa Real"},
    {id_pais:1,id_provincia:2,id_padre:1,id:42,parametro:"Villa Riachuelo"},
    {id_pais:1,id_provincia:2,id_padre:1,id:43,parametro:"Villa Santa Rita"},
    {id_pais:1,id_provincia:2,id_padre:1,id:44,parametro:"Villa Soldati"},
    {id_pais:1,id_provincia:2,id_padre:1,id:45,parametro:"Villa Urquiza"},
    {id_pais:1,id_provincia:2,id_padre:1,id:46,parametro:"Villa del Parque"},
    {id_pais:1,id_provincia:2,id_padre:1,id:47,parametro:"Vélez Sarfield"},
    {id_pais:1,id_provincia:6,id_padre:7,id:48,parametro:"Carhué"},
    {id_pais:1,id_provincia:6,id_padre:7,id:49,parametro:"Colonia San Miguel Arcángel"},
    {id_pais:1,id_provincia:6,id_padre:7,id:50,parametro:"Delfín Huergo"},
    {id_pais:1,id_provincia:6,id_padre:7,id:51,parametro:"Espartillar"},
    {id_pais:1,id_provincia:6,id_padre:7,id:52,parametro:"Esteban Agustín Gascón"},
    {id_pais:1,id_provincia:6,id_padre:7,id:53,parametro:"La Pala"},
    {id_pais:1,id_provincia:6,id_padre:7,id:54,parametro:"Maza"},
    {id_pais:1,id_provincia:6,id_padre:7,id:55,parametro:"Rivera"},
    {id_pais:1,id_provincia:6,id_padre:7,id:56,parametro:"Villa Margarita"},
    {id_pais:1,id_provincia:6,id_padre:7,id:57,parametro:"Yutuyaco"},
    {id_pais:1,id_provincia:6,id_padre:14,id:58,parametro:"Adolfo Gonzales Chaves"},
    {id_pais:1,id_provincia:6,id_padre:14,id:59,parametro:"De La Garma"},
    {id_pais:1,id_provincia:6,id_padre:14,id:60,parametro:"Juan E. Barra"},
    {id_pais:1,id_provincia:6,id_padre:14,id:61,parametro:"Vásquez"},
    {id_pais:1,id_provincia:6,id_padre:21,id:62,parametro:"Alberti"},
    {id_pais:1,id_provincia:6,id_padre:21,id:63,parametro:"Coronel Seguí"},
    {id_pais:1,id_provincia:6,id_padre:21,id:64,parametro:"Mechita"},
    {id_pais:1,id_provincia:6,id_padre:21,id:65,parametro:"Pla"},
    {id_pais:1,id_provincia:6,id_padre:21,id:66,parametro:"Villa Grisolía"},
    {id_pais:1,id_provincia:6,id_padre:21,id:67,parametro:"Villa María"},
    {id_pais:1,id_provincia:6,id_padre:21,id:68,parametro:"Villa Ortiz"},
    {id_pais:1,id_provincia:6,id_padre:28,id:69,parametro:"Almirante Brown"},
    {id_pais:1,id_provincia:6,id_padre:35,id:70,parametro:"Avellaneda"},
    {id_pais:1,id_provincia:6,id_padre:42,id:71,parametro:"Ayacucho"},
    {id_pais:1,id_provincia:6,id_padre:42,id:72,parametro:"La Constancia"},
    {id_pais:1,id_provincia:6,id_padre:42,id:73,parametro:"Solanet"},
    {id_pais:1,id_provincia:6,id_padre:42,id:74,parametro:"Udaquiola"},
    {id_pais:1,id_provincia:6,id_padre:49,id:75,parametro:"Ariel"},
    {id_pais:1,id_provincia:6,id_padre:49,id:76,parametro:"Azul"},
    {id_pais:1,id_provincia:6,id_padre:49,id:77,parametro:"Cacharí"},
    {id_pais:1,id_provincia:6,id_padre:49,id:78,parametro:"Chillar"},
    {id_pais:1,id_provincia:6,id_padre:49,id:79,parametro:"16 de Julio"},
    {id_pais:1,id_provincia:6,id_padre:56,id:80,parametro:"Bahía Blanca"},
    {id_pais:1,id_provincia:6,id_padre:56,id:81,parametro:"Cabildo"},
    {id_pais:1,id_provincia:6,id_padre:56,id:82,parametro:"General Daniel Cerri"},
    {id_pais:1,id_provincia:6,id_padre:63,id:83,parametro:"Balcarce"},
    {id_pais:1,id_provincia:6,id_padre:63,id:84,parametro:"Los Pinos"},
    {id_pais:1,id_provincia:6,id_padre:63,id:85,parametro:"Napaleofú"},
    {id_pais:1,id_provincia:6,id_padre:63,id:86,parametro:"Ramos Otero"},
    {id_pais:1,id_provincia:6,id_padre:63,id:87,parametro:"San Agustín"},
    {id_pais:1,id_provincia:6,id_padre:63,id:88,parametro:"Villa Laguna La Brava"},
    {id_pais:1,id_provincia:6,id_padre:70,id:89,parametro:"Baradero"},
    {id_pais:1,id_provincia:6,id_padre:70,id:90,parametro:"Irineo Portela"},
    {id_pais:1,id_provincia:6,id_padre:70,id:91,parametro:"Santa Coloma"},
    {id_pais:1,id_provincia:6,id_padre:70,id:92,parametro:"Villa Alsina"},
    {id_pais:1,id_provincia:6,id_padre:77,id:93,parametro:"Arrecifes"},
    {id_pais:1,id_provincia:6,id_padre:77,id:94,parametro:"Todd"},
    {id_pais:1,id_provincia:6,id_padre:77,id:95,parametro:"Viña"},
    {id_pais:1,id_provincia:6,id_padre:84,id:96,parametro:"Barker"},
    {id_pais:1,id_provincia:6,id_padre:84,id:97,parametro:"Benito Juárez"},
    {id_pais:1,id_provincia:6,id_padre:84,id:98,parametro:"López"},
    {id_pais:1,id_provincia:6,id_padre:84,id:99,parametro:"Tedín Uriburu"},
    {id_pais:1,id_provincia:6,id_padre:84,id:100,parametro:"Villa Cacique"},
    {id_pais:1,id_provincia:6,id_padre:91,id:101,parametro:"Berazategui"},
    {id_pais:1,id_provincia:6,id_padre:98,id:102,parametro:"Berisso"},
    {id_pais:1,id_provincia:6,id_padre:105,id:103,parametro:"Hale"},
    {id_pais:1,id_provincia:6,id_padre:105,id:104,parametro:"Juan F. Ibarra"},
    {id_pais:1,id_provincia:6,id_padre:105,id:105,parametro:"Paula"},
    {id_pais:1,id_provincia:6,id_padre:105,id:106,parametro:"Pirovano"},
    {id_pais:1,id_provincia:6,id_padre:105,id:107,parametro:"San Carlos de Bolívar"},
    {id_pais:1,id_provincia:6,id_padre:105,id:108,parametro:"Urdampilleta"},
    {id_pais:1,id_provincia:6,id_padre:105,id:109,parametro:"Villa Lynch Pueyrredón"},
    {id_pais:1,id_provincia:6,id_padre:112,id:110,parametro:"Asamblea"},
    {id_pais:1,id_provincia:6,id_padre:112,id:111,parametro:"Bragado"},
    {id_pais:1,id_provincia:6,id_padre:112,id:112,parametro:"Comodoro Py"},
    {id_pais:1,id_provincia:6,id_padre:112,id:113,parametro:"General O'Brien"},
    {id_pais:1,id_provincia:6,id_padre:112,id:114,parametro:"Irala"},
    {id_pais:1,id_provincia:6,id_padre:112,id:115,parametro:"La Limpia"},
    {id_pais:1,id_provincia:6,id_padre:112,id:116,parametro:"Juan F. Salaberry"},
    {id_pais:1,id_provincia:6,id_padre:112,id:117,parametro:"Mechita"},
    {id_pais:1,id_provincia:6,id_padre:112,id:118,parametro:"Olascoaga"},
    {id_pais:1,id_provincia:6,id_padre:112,id:119,parametro:"Warnes"},
    {id_pais:1,id_provincia:6,id_padre:119,id:120,parametro:"Altamirano"},
    {id_pais:1,id_provincia:6,id_padre:119,id:121,parametro:"Barrio El Mirador"},
    {id_pais:1,id_provincia:6,id_padre:119,id:122,parametro:"Barrio Las Golondrinas"},
    {id_pais:1,id_provincia:6,id_padre:119,id:123,parametro:"Barrio Los Bosquecitos"},
    {id_pais:1,id_provincia:6,id_padre:119,id:124,parametro:"Barrio Parque Las Acacias"},
    {id_pais:1,id_provincia:6,id_padre:119,id:125,parametro:"Campos de Roca"},
    {id_pais:1,id_provincia:6,id_padre:119,id:126,parametro:"Coronel Brandsen"},
    {id_pais:1,id_provincia:6,id_padre:119,id:127,parametro:"Club de Campo Las Malvinas"},
    {id_pais:1,id_provincia:6,id_padre:119,id:128,parametro:"Gómez"},
    {id_pais:1,id_provincia:6,id_padre:119,id:129,parametro:"Jeppener"},
    {id_pais:1,id_provincia:6,id_padre:119,id:130,parametro:"Oliden"},
    {id_pais:1,id_provincia:6,id_padre:119,id:131,parametro:"Posada de los Lagos"},
    {id_pais:1,id_provincia:6,id_padre:119,id:132,parametro:"Samborombón"},
    {id_pais:1,id_provincia:6,id_padre:126,id:133,parametro:"Los Cardales"},
    {id_pais:1,id_provincia:6,id_padre:126,id:134,parametro:"Barrio Los Pioneros (Barrio Tavella)"},
    {id_pais:1,id_provincia:6,id_padre:126,id:135,parametro:"Campana"},
    {id_pais:1,id_provincia:6,id_padre:126,id:136,parametro:"Chacras del Río Luján"},
    {id_pais:1,id_provincia:6,id_padre:126,id:137,parametro:"Río Luján"},
    {id_pais:1,id_provincia:6,id_padre:134,id:138,parametro:"Alejandro Petión"},
    {id_pais:1,id_provincia:6,id_padre:134,id:139,parametro:"Barrio El Taladro"},
    {id_pais:1,id_provincia:6,id_padre:134,id:140,parametro:"Cañuelas"},
    {id_pais:1,id_provincia:6,id_padre:134,id:141,parametro:"Gobernador Udaondo"},
    {id_pais:1,id_provincia:6,id_padre:134,id:142,parametro:"Máximo Paz"},
    {id_pais:1,id_provincia:6,id_padre:134,id:143,parametro:"Santa Rosa"},
    {id_pais:1,id_provincia:6,id_padre:134,id:144,parametro:"Uribelarrea"},
    {id_pais:1,id_provincia:6,id_padre:134,id:145,parametro:"Vicente Casares"},
    {id_pais:1,id_provincia:6,id_padre:140,id:146,parametro:"Capitán Sarmiento"},
    {id_pais:1,id_provincia:6,id_padre:140,id:147,parametro:"La Luisa"},
    {id_pais:1,id_provincia:6,id_padre:147,id:148,parametro:"Bellocq"},
    {id_pais:1,id_provincia:6,id_padre:147,id:149,parametro:"Cadret"},
    {id_pais:1,id_provincia:6,id_padre:147,id:150,parametro:"Carlos Casares"},
    {id_pais:1,id_provincia:6,id_padre:147,id:151,parametro:"Colonia Mauricio"},
    {id_pais:1,id_provincia:6,id_padre:147,id:152,parametro:"Hortensia"},
    {id_pais:1,id_provincia:6,id_padre:147,id:153,parametro:"La Sofía"},
    {id_pais:1,id_provincia:6,id_padre:147,id:154,parametro:"Mauricio Hirsch"},
    {id_pais:1,id_provincia:6,id_padre:147,id:155,parametro:"Moctezuma"},
    {id_pais:1,id_provincia:6,id_padre:147,id:156,parametro:"Ordoqui"},
    {id_pais:1,id_provincia:6,id_padre:147,id:157,parametro:"Santo Tomás"},
    {id_pais:1,id_provincia:6,id_padre:147,id:158,parametro:"Smith"},
    {id_pais:1,id_provincia:6,id_padre:154,id:159,parametro:"Carlos Tejedor"},
    {id_pais:1,id_provincia:6,id_padre:154,id:160,parametro:"Colonia Seré"},
    {id_pais:1,id_provincia:6,id_padre:154,id:161,parametro:"Curarú"},
    {id_pais:1,id_provincia:6,id_padre:154,id:162,parametro:"Timote"},
    {id_pais:1,id_provincia:6,id_padre:154,id:163,parametro:"Tres Algarrobos"},
    {id_pais:1,id_provincia:6,id_padre:161,id:164,parametro:"Carmen de Areco"},
    {id_pais:1,id_provincia:6,id_padre:161,id:165,parametro:"Pueblo Gouin"},
    {id_pais:1,id_provincia:6,id_padre:161,id:166,parametro:"Tres Sargentos"},
    {id_pais:1,id_provincia:6,id_padre:168,id:167,parametro:"Castelli"},
    {id_pais:1,id_provincia:6,id_padre:168,id:168,parametro:"Centro Guerrero"},
    {id_pais:1,id_provincia:6,id_padre:168,id:169,parametro:"Cerro de la Gloria"},
    {id_pais:1,id_provincia:6,id_padre:175,id:170,parametro:"Colón"},
    {id_pais:1,id_provincia:6,id_padre:175,id:171,parametro:"Villa Manuel Pomar"},
    {id_pais:1,id_provincia:6,id_padre:175,id:172,parametro:"Pearson"},
    {id_pais:1,id_provincia:6,id_padre:175,id:173,parametro:"Sarasa"},
    {id_pais:1,id_provincia:6,id_padre:182,id:174,parametro:"Bajo Hondo"},
    {id_pais:1,id_provincia:6,id_padre:182,id:175,parametro:"Balneario Pehuen Co"},
    {id_pais:1,id_provincia:6,id_padre:182,id:176,parametro:"Pago Chico"},
    {id_pais:1,id_provincia:6,id_padre:182,id:177,parametro:"Punta Alta"},
    {id_pais:1,id_provincia:6,id_padre:182,id:178,parametro:"Villa General Arias"},
    {id_pais:1,id_provincia:6,id_padre:189,id:179,parametro:"Aparicio"},
    {id_pais:1,id_provincia:6,id_padre:189,id:180,parametro:"Marisol"},
    {id_pais:1,id_provincia:6,id_padre:189,id:181,parametro:"Coronel Dorrego"},
    {id_pais:1,id_provincia:6,id_padre:189,id:182,parametro:"El Perdido"},
    {id_pais:1,id_provincia:6,id_padre:189,id:183,parametro:"Faro"},
    {id_pais:1,id_provincia:6,id_padre:189,id:184,parametro:"Irene"},
    {id_pais:1,id_provincia:6,id_padre:189,id:185,parametro:"Oriente"},
    {id_pais:1,id_provincia:6,id_padre:189,id:186,parametro:"Paraje La Ruta"},
    {id_pais:1,id_provincia:6,id_padre:189,id:187,parametro:"San Román"},
    {id_pais:1,id_provincia:6,id_padre:196,id:188,parametro:"Coronel Pringles"},
    {id_pais:1,id_provincia:6,id_padre:196,id:189,parametro:"El Divisorio"},
    {id_pais:1,id_provincia:6,id_padre:196,id:190,parametro:"El Pensamiento"},
    {id_pais:1,id_provincia:6,id_padre:196,id:191,parametro:"Indio Rico"},
    {id_pais:1,id_provincia:6,id_padre:196,id:192,parametro:"Lartigau"},
    {id_pais:1,id_provincia:6,id_padre:203,id:193,parametro:"Cascada"},
    {id_pais:1,id_provincia:6,id_padre:203,id:194,parametro:"Coronel Suárez"},
    {id_pais:1,id_provincia:6,id_padre:203,id:195,parametro:"Curamalal"},
    {id_pais:1,id_provincia:6,id_padre:203,id:196,parametro:"D'Orbigny"},
    {id_pais:1,id_provincia:6,id_padre:203,id:197,parametro:"Huanguelén"},
    {id_pais:1,id_provincia:6,id_padre:203,id:198,parametro:"Pasman"},
    {id_pais:1,id_provincia:6,id_padre:203,id:199,parametro:"San José"},
    {id_pais:1,id_provincia:6,id_padre:203,id:200,parametro:"Santa María"},
    {id_pais:1,id_provincia:6,id_padre:203,id:201,parametro:"Santa Trinidad"},
    {id_pais:1,id_provincia:6,id_padre:203,id:202,parametro:"Villa La Arcadia"},
    {id_pais:1,id_provincia:6,id_padre:210,id:203,parametro:"Castilla"},
    {id_pais:1,id_provincia:6,id_padre:210,id:204,parametro:"Chacabuco"},
    {id_pais:1,id_provincia:6,id_padre:210,id:205,parametro:"Los Angeles"},
    {id_pais:1,id_provincia:6,id_padre:210,id:206,parametro:"O'Higgins"},
    {id_pais:1,id_provincia:6,id_padre:210,id:207,parametro:"Rawson"},
    {id_pais:1,id_provincia:6,id_padre:217,id:208,parametro:"Barrio Lomas Altas"},
    {id_pais:1,id_provincia:6,id_padre:217,id:209,parametro:"Chascomús"},
    {id_pais:1,id_provincia:6,id_padre:217,id:210,parametro:"Laguna Vitel"},
    {id_pais:1,id_provincia:6,id_padre:217,id:211,parametro:"Manuel J. Cobo"},
    {id_pais:1,id_provincia:6,id_padre:217,id:212,parametro:"Villa Parque Girado"},
    {id_pais:1,id_provincia:6,id_padre:224,id:213,parametro:"Benitez"},
    {id_pais:1,id_provincia:6,id_padre:224,id:214,parametro:"Chivilcoy"},
    {id_pais:1,id_provincia:6,id_padre:224,id:215,parametro:"Emilio Ayarza"},
    {id_pais:1,id_provincia:6,id_padre:224,id:216,parametro:"Gorostiaga"},
    {id_pais:1,id_provincia:6,id_padre:224,id:217,parametro:"La Rica"},
    {id_pais:1,id_provincia:6,id_padre:224,id:218,parametro:"Moquehuá"},
    {id_pais:1,id_provincia:6,id_padre:224,id:219,parametro:"Ramón Biaus"},
    {id_pais:1,id_provincia:6,id_padre:224,id:220,parametro:"San Sebastián"},
    {id_pais:1,id_provincia:6,id_padre:231,id:221,parametro:"Andant"},
    {id_pais:1,id_provincia:6,id_padre:231,id:222,parametro:"Arboledas"},
    {id_pais:1,id_provincia:6,id_padre:231,id:223,parametro:"Daireaux"},
    {id_pais:1,id_provincia:6,id_padre:231,id:224,parametro:"La Larga"},
    {id_pais:1,id_provincia:6,id_padre:231,id:225,parametro:"Salazar"},
    {id_pais:1,id_provincia:6,id_padre:238,id:226,parametro:"Dolores"},
    {id_pais:1,id_provincia:6,id_padre:238,id:227,parametro:"Sevigne"},
    {id_pais:1,id_provincia:6,id_padre:245,id:228,parametro:"Ensenada"},
    {id_pais:1,id_provincia:6,id_padre:252,id:229,parametro:"Escobar"},
    {id_pais:1,id_provincia:6,id_padre:260,id:230,parametro:"Esteban Echeverría"},
    {id_pais:1,id_provincia:6,id_padre:266,id:231,parametro:"Arroyo de la Cruz"},
    {id_pais:1,id_provincia:6,id_padre:266,id:232,parametro:"Capilla del Señor"},
    {id_pais:1,id_provincia:6,id_padre:266,id:233,parametro:"Diego Gaynor"},
    {id_pais:1,id_provincia:6,id_padre:266,id:234,parametro:"Los Cardales"},
    {id_pais:1,id_provincia:6,id_padre:266,id:235,parametro:"Parada Orlando"},
    {id_pais:1,id_provincia:6,id_padre:266,id:236,parametro:"Parada Robles - Pavón"},
    {id_pais:1,id_provincia:6,id_padre:270,id:237,parametro:"Ezeiza"},
    {id_pais:1,id_provincia:6,id_padre:274,id:238,parametro:"Florencio Varela"},
    {id_pais:1,id_provincia:6,id_padre:277,id:239,parametro:"Blaquier"},
    {id_pais:1,id_provincia:6,id_padre:277,id:240,parametro:"Florentino Ameghino"},
    {id_pais:1,id_provincia:6,id_padre:277,id:241,parametro:"Porvenir"},
    {id_pais:1,id_provincia:6,id_padre:280,id:242,parametro:"Centinela del Mar"},
    {id_pais:1,id_provincia:6,id_padre:280,id:243,parametro:"Comandante Nicanor Otamendi"},
    {id_pais:1,id_provincia:6,id_padre:280,id:244,parametro:"Mar del Sur"},
    {id_pais:1,id_provincia:6,id_padre:280,id:245,parametro:"Mechongué"},
    {id_pais:1,id_provincia:6,id_padre:280,id:246,parametro:"Miramar"},
    {id_pais:1,id_provincia:6,id_padre:287,id:247,parametro:"General Alvear"},
    {id_pais:1,id_provincia:6,id_padre:294,id:248,parametro:"Arribeños"},
    {id_pais:1,id_provincia:6,id_padre:294,id:249,parametro:"Ascensión"},
    {id_pais:1,id_provincia:6,id_padre:294,id:250,parametro:"Estación Arenales"},
    {id_pais:1,id_provincia:6,id_padre:294,id:251,parametro:"Ferré"},
    {id_pais:1,id_provincia:6,id_padre:294,id:252,parametro:"General Arenales"},
    {id_pais:1,id_provincia:6,id_padre:294,id:253,parametro:"La Angelita"},
    {id_pais:1,id_provincia:6,id_padre:294,id:254,parametro:"La Trinidad"},
    {id_pais:1,id_provincia:6,id_padre:301,id:255,parametro:"General Belgrano"},
    {id_pais:1,id_provincia:6,id_padre:301,id:256,parametro:"Gorchs"},
    {id_pais:1,id_provincia:6,id_padre:308,id:257,parametro:"General Guido"},
    {id_pais:1,id_provincia:6,id_padre:308,id:258,parametro:"Labardén"},
    {id_pais:1,id_provincia:6,id_padre:315,id:259,parametro:"General Juan Madariaga"},
    {id_pais:1,id_provincia:6,id_padre:322,id:260,parametro:"General La Madrid"},
    {id_pais:1,id_provincia:6,id_padre:322,id:261,parametro:"La Colina"},
    {id_pais:1,id_provincia:6,id_padre:322,id:262,parametro:"Las Martinetas"},
    {id_pais:1,id_provincia:6,id_padre:322,id:263,parametro:"Líbano"},
    {id_pais:1,id_provincia:6,id_padre:322,id:264,parametro:"Pontaut"},
    {id_pais:1,id_provincia:6,id_padre:329,id:265,parametro:"General Hornos"},
    {id_pais:1,id_provincia:6,id_padre:329,id:266,parametro:"General Las Heras"},
    {id_pais:1,id_provincia:6,id_padre:329,id:267,parametro:"La Choza"},
    {id_pais:1,id_provincia:6,id_padre:329,id:268,parametro:"Plomer"},
    {id_pais:1,id_provincia:6,id_padre:329,id:269,parametro:"Villars"},
    {id_pais:1,id_provincia:6,id_padre:336,id:270,parametro:"General Lavalle"},
    {id_pais:1,id_provincia:6,id_padre:336,id:271,parametro:"Pavón"},
    {id_pais:1,id_provincia:6,id_padre:343,id:272,parametro:"Barrio Río Salado"},
    {id_pais:1,id_provincia:6,id_padre:343,id:273,parametro:"Loma Verde"},
    {id_pais:1,id_provincia:6,id_padre:343,id:274,parametro:"Ranchos"},
    {id_pais:1,id_provincia:6,id_padre:343,id:275,parametro:"Villanueva"},
    {id_pais:1,id_provincia:6,id_padre:351,id:276,parametro:"Colonia San Ricardo"},
    {id_pais:1,id_provincia:6,id_padre:351,id:277,parametro:"General Pinto"},
    {id_pais:1,id_provincia:6,id_padre:351,id:278,parametro:"Germania"},
    {id_pais:1,id_provincia:6,id_padre:351,id:279,parametro:"Gunther"},
    {id_pais:1,id_provincia:6,id_padre:351,id:280,parametro:"Villa Francia"},
    {id_pais:1,id_provincia:6,id_padre:351,id:281,parametro:"Villa Roth"},
    {id_pais:1,id_provincia:6,id_padre:357,id:282,parametro:"Barrio El Boquerón"},
    {id_pais:1,id_provincia:6,id_padre:357,id:283,parametro:"Barrio La Gloria"},
    {id_pais:1,id_provincia:6,id_padre:357,id:284,parametro:"Barrio Santa Paula"},
    {id_pais:1,id_provincia:6,id_padre:357,id:285,parametro:"Batán"},
    {id_pais:1,id_provincia:6,id_padre:357,id:286,parametro:"Chapadmalal"},
    {id_pais:1,id_provincia:6,id_padre:357,id:287,parametro:"El Marquesado"},
    {id_pais:1,id_provincia:6,id_padre:357,id:288,parametro:"Estación Chapadmalal"},
    {id_pais:1,id_provincia:6,id_padre:357,id:289,parametro:"Mar del Plata"},
    {id_pais:1,id_provincia:6,id_padre:357,id:290,parametro:"Sierra de los Padres"},
    {id_pais:1,id_provincia:6,id_padre:364,id:291,parametro:"General Rodríguez"},
    {id_pais:1,id_provincia:6,id_padre:371,id:292,parametro:"General San Martín"},
    {id_pais:1,id_provincia:6,id_padre:385,id:293,parametro:"Baigorrita"},
    {id_pais:1,id_provincia:6,id_padre:385,id:294,parametro:"La Delfina"},
    {id_pais:1,id_provincia:6,id_padre:385,id:295,parametro:"Los Toldos"},
    {id_pais:1,id_provincia:6,id_padre:385,id:296,parametro:"San Emilio"},
    {id_pais:1,id_provincia:6,id_padre:385,id:297,parametro:"Zavalía"},
    {id_pais:1,id_provincia:6,id_padre:392,id:298,parametro:"Banderaló"},
    {id_pais:1,id_provincia:6,id_padre:392,id:299,parametro:"Cañada Seca"},
    {id_pais:1,id_provincia:6,id_padre:392,id:300,parametro:"Coronel Charlone"},
    {id_pais:1,id_provincia:6,id_padre:392,id:301,parametro:"Emilio V. Bunge"},
    {id_pais:1,id_provincia:6,id_padre:392,id:302,parametro:"General Villegas"},
    {id_pais:1,id_provincia:6,id_padre:392,id:303,parametro:"Massey"},
    {id_pais:1,id_provincia:6,id_padre:392,id:304,parametro:"Pichincha"},
    {id_pais:1,id_provincia:6,id_padre:392,id:305,parametro:"Piedritas"},
    {id_pais:1,id_provincia:6,id_padre:392,id:306,parametro:"Santa Eleodora"},
    {id_pais:1,id_provincia:6,id_padre:392,id:307,parametro:"Santa Regina"},
    {id_pais:1,id_provincia:6,id_padre:392,id:308,parametro:"Villa Saboya"},
    {id_pais:1,id_provincia:6,id_padre:392,id:309,parametro:"Villa Sauze"},
    {id_pais:1,id_provincia:6,id_padre:399,id:310,parametro:"Arroyo Venado"},
    {id_pais:1,id_provincia:6,id_padre:399,id:311,parametro:"Casbas"},
    {id_pais:1,id_provincia:6,id_padre:399,id:312,parametro:"Garré"},
    {id_pais:1,id_provincia:6,id_padre:399,id:313,parametro:"Guaminí"},
    {id_pais:1,id_provincia:6,id_padre:399,id:314,parametro:"Laguna Alsina"},
    {id_pais:1,id_provincia:6,id_padre:406,id:315,parametro:"Henderson"},
    {id_pais:1,id_provincia:6,id_padre:406,id:316,parametro:"Herrera Vegas"},
    {id_pais:1,id_provincia:6,id_padre:408,id:317,parametro:"Hurlingham"},
    {id_pais:1,id_provincia:6,id_padre:410,id:318,parametro:"Ituzaingó"},
    {id_pais:1,id_provincia:6,id_padre:412,id:319,parametro:"José C. Paz"},
    {id_pais:1,id_provincia:6,id_padre:413,id:320,parametro:"Agustín Roca"},
    {id_pais:1,id_provincia:6,id_padre:413,id:321,parametro:"Agustina"},
    {id_pais:1,id_provincia:6,id_padre:413,id:322,parametro:"Balneario Laguna de Gómez"},
    {id_pais:1,id_provincia:6,id_padre:413,id:323,parametro:"Fortín Tiburcio"},
    {id_pais:1,id_provincia:6,id_padre:413,id:324,parametro:"Junín"},
    {id_pais:1,id_provincia:6,id_padre:413,id:325,parametro:"Paraje La Agraria"},
    {id_pais:1,id_provincia:6,id_padre:413,id:326,parametro:"Laplacette"},
    {id_pais:1,id_provincia:6,id_padre:413,id:327,parametro:"Morse"},
    {id_pais:1,id_provincia:6,id_padre:413,id:328,parametro:"Saforcada"},
    {id_pais:1,id_provincia:6,id_padre:420,id:329,parametro:"Las Toninas"},
    {id_pais:1,id_provincia:6,id_padre:420,id:330,parametro:"Mar de Ajó - San Bernardo"},
    {id_pais:1,id_provincia:6,id_padre:420,id:331,parametro:"San Clemente del Tuyú"},
    {id_pais:1,id_provincia:6,id_padre:420,id:332,parametro:"Santa Teresita - Mar del Tuyú"},
    {id_pais:1,id_provincia:6,id_padre:427,id:333,parametro:"La Matanza"},
    {id_pais:1,id_provincia:6,id_padre:434,id:334,parametro:"Lanús"},
    {id_pais:1,id_provincia:6,id_padre:441,id:335,parametro:"Country Club El Rodeo"},
    {id_pais:1,id_provincia:6,id_padre:441,id:336,parametro:"Ignacio Correas"},
    {id_pais:1,id_provincia:6,id_padre:441,id:337,parametro:"La Plata"},
    {id_pais:1,id_provincia:6,id_padre:441,id:338,parametro:"Lomas de Copello"},
    {id_pais:1,id_provincia:6,id_padre:441,id:339,parametro:"Ruta Sol"},
    {id_pais:1,id_provincia:6,id_padre:448,id:340,parametro:"Laprida"},
    {id_pais:1,id_provincia:6,id_padre:448,id:341,parametro:"Pueblo Nuevo"},
    {id_pais:1,id_provincia:6,id_padre:448,id:342,parametro:"Pueblo San Jorge"},
    {id_pais:1,id_provincia:6,id_padre:455,id:343,parametro:"Coronel Boerr"},
    {id_pais:1,id_provincia:6,id_padre:455,id:344,parametro:"El Trigo"},
    {id_pais:1,id_provincia:6,id_padre:455,id:345,parametro:"Las Flores"},
    {id_pais:1,id_provincia:6,id_padre:455,id:346,parametro:"Pardo"},
    {id_pais:1,id_provincia:6,id_padre:462,id:347,parametro:"Alberdi Viejo"},
    {id_pais:1,id_provincia:6,id_padre:462,id:348,parametro:"El Dorado"},
    {id_pais:1,id_provincia:6,id_padre:462,id:349,parametro:"Fortín Acha"},
    {id_pais:1,id_provincia:6,id_padre:462,id:350,parametro:"Juan Bautista Alberdi"},
    {id_pais:1,id_provincia:6,id_padre:462,id:351,parametro:"Leandro N. Alem"},
    {id_pais:1,id_provincia:6,id_padre:462,id:352,parametro:"Vedia"},
    {id_pais:1,id_provincia:6,id_padre:469,id:353,parametro:"Arenaza"},
    {id_pais:1,id_provincia:6,id_padre:469,id:354,parametro:"Bayauca"},
    {id_pais:1,id_provincia:6,id_padre:469,id:355,parametro:"Bermúdez"},
    {id_pais:1,id_provincia:6,id_padre:469,id:356,parametro:"Carlos Salas"},
    {id_pais:1,id_provincia:6,id_padre:469,id:357,parametro:"Coronel Martínez de Hoz"},
    {id_pais:1,id_provincia:6,id_padre:469,id:358,parametro:"El Triunfo"},
    {id_pais:1,id_provincia:6,id_padre:469,id:359,parametro:"Las Toscas"},
    {id_pais:1,id_provincia:6,id_padre:469,id:360,parametro:"Lincoln"},
    {id_pais:1,id_provincia:6,id_padre:469,id:361,parametro:"Pasteur"},
    {id_pais:1,id_provincia:6,id_padre:469,id:362,parametro:"Roberts"},
    {id_pais:1,id_provincia:6,id_padre:469,id:363,parametro:"Triunvirato"},
    {id_pais:1,id_provincia:6,id_padre:476,id:364,parametro:"Arenas Verdes"},
    {id_pais:1,id_provincia:6,id_padre:476,id:365,parametro:"Licenciado Matienzo"},
    {id_pais:1,id_provincia:6,id_padre:476,id:366,parametro:"Lobería"},
    {id_pais:1,id_provincia:6,id_padre:476,id:367,parametro:"Pieres"},
    {id_pais:1,id_provincia:6,id_padre:476,id:368,parametro:"San Manuel"},
    {id_pais:1,id_provincia:6,id_padre:476,id:369,parametro:"Tamangueyú"},
    {id_pais:1,id_provincia:6,id_padre:483,id:370,parametro:"Antonio Carboni"},
    {id_pais:1,id_provincia:6,id_padre:483,id:371,parametro:"Elvira"},
    {id_pais:1,id_provincia:6,id_padre:483,id:372,parametro:"Laguna de Lobos"},
    {id_pais:1,id_provincia:6,id_padre:483,id:373,parametro:"Lobos"},
    {id_pais:1,id_provincia:6,id_padre:483,id:374,parametro:"Salvador María"},
    {id_pais:1,id_provincia:6,id_padre:490,id:375,parametro:"Lomas de Zamora"},
    {id_pais:1,id_provincia:6,id_padre:497,id:376,parametro:"Carlos Keen"},
    {id_pais:1,id_provincia:6,id_padre:497,id:377,parametro:"Club de Campo Los Puentes"},
    {id_pais:1,id_provincia:6,id_padre:497,id:378,parametro:"Luján"},
    {id_pais:1,id_provincia:6,id_padre:497,id:379,parametro:"Olivera"},
    {id_pais:1,id_provincia:6,id_padre:497,id:380,parametro:"Torres"},
    {id_pais:1,id_provincia:6,id_padre:505,id:381,parametro:"Atalaya"},
    {id_pais:1,id_provincia:6,id_padre:505,id:382,parametro:"General Mansilla"},
    {id_pais:1,id_provincia:6,id_padre:505,id:383,parametro:"Los Naranjos"},
    {id_pais:1,id_provincia:6,id_padre:505,id:384,parametro:"Magdalena"},
    {id_pais:1,id_provincia:6,id_padre:505,id:385,parametro:"Roberto J. Payró"},
    {id_pais:1,id_provincia:6,id_padre:505,id:386,parametro:"Vieytes"},
    {id_pais:1,id_provincia:6,id_padre:511,id:387,parametro:"Las Armas"},
    {id_pais:1,id_provincia:6,id_padre:511,id:388,parametro:"Maipú"},
    {id_pais:1,id_provincia:6,id_padre:511,id:389,parametro:"Santo Domingo"},
    {id_pais:1,id_provincia:6,id_padre:515,id:390,parametro:"Malvinas Argentinas"},
    {id_pais:1,id_provincia:6,id_padre:518,id:391,parametro:"Coronel Vidal"},
    {id_pais:1,id_provincia:6,id_padre:518,id:392,parametro:"General Pirán"},
    {id_pais:1,id_provincia:6,id_padre:518,id:393,parametro:"La Armonía"},
    {id_pais:1,id_provincia:6,id_padre:518,id:394,parametro:"Mar Chiquita"},
    {id_pais:1,id_provincia:6,id_padre:518,id:395,parametro:"Mar de Cobo"},
    {id_pais:1,id_provincia:6,id_padre:518,id:396,parametro:"Santa Clara del Mar"},
    {id_pais:1,id_provincia:6,id_padre:518,id:397,parametro:"Vivoratá"},
    {id_pais:1,id_provincia:6,id_padre:525,id:398,parametro:"Barrio Santa Rosa"},
    {id_pais:1,id_provincia:6,id_padre:525,id:399,parametro:"Marcos Paz"},
    {id_pais:1,id_provincia:6,id_padre:532,id:400,parametro:"Goldney"},
    {id_pais:1,id_provincia:6,id_padre:532,id:401,parametro:"Gowland"},
    {id_pais:1,id_provincia:6,id_padre:532,id:402,parametro:"Mercedes"},
    {id_pais:1,id_provincia:6,id_padre:532,id:403,parametro:"Jorge Born"},
    {id_pais:1,id_provincia:6,id_padre:539,id:404,parametro:"Merlo"},
    {id_pais:1,id_provincia:6,id_padre:547,id:405,parametro:"Abbott"},
    {id_pais:1,id_provincia:6,id_padre:547,id:406,parametro:"San Miguel del Monte"},
    {id_pais:1,id_provincia:6,id_padre:547,id:407,parametro:"Zenón Videla Dorna"},
    {id_pais:1,id_provincia:6,id_padre:553,id:408,parametro:"Balneario Sauce Grande"},
    {id_pais:1,id_provincia:6,id_padre:553,id:409,parametro:"Monte Hermoso"},
    {id_pais:1,id_provincia:6,id_padre:560,id:410,parametro:"Moreno"},
    {id_pais:1,id_provincia:6,id_padre:568,id:411,parametro:"Morón"},
    {id_pais:1,id_provincia:6,id_padre:574,id:412,parametro:"José Juan Almeyra"},
    {id_pais:1,id_provincia:6,id_padre:574,id:413,parametro:"Las Marianas"},
    {id_pais:1,id_provincia:6,id_padre:574,id:414,parametro:"Navarro"},
    {id_pais:1,id_provincia:6,id_padre:574,id:415,parametro:"Villa Moll"},
    {id_pais:1,id_provincia:6,id_padre:581,id:416,parametro:"Claraz"},
    {id_pais:1,id_provincia:6,id_padre:581,id:417,parametro:"Energía"},
    {id_pais:1,id_provincia:6,id_padre:581,id:418,parametro:"Juan N. Fernández"},
    {id_pais:1,id_provincia:6,id_padre:581,id:419,parametro:"Necochea - Quequén"},
    {id_pais:1,id_provincia:6,id_padre:581,id:420,parametro:"Nicanor Olivera"},
    {id_pais:1,id_provincia:6,id_padre:581,id:421,parametro:"Ramón Santamarina"},
    {id_pais:1,id_provincia:6,id_padre:588,id:422,parametro:"Alfredo Demarchi"},
    {id_pais:1,id_provincia:6,id_padre:588,id:423,parametro:"Carlos María Naón"},
    {id_pais:1,id_provincia:6,id_padre:588,id:424,parametro:"12 de Octubre"},
    {id_pais:1,id_provincia:6,id_padre:588,id:425,parametro:"Dudignac"},
    {id_pais:1,id_provincia:6,id_padre:588,id:426,parametro:"La Aurora"},
    {id_pais:1,id_provincia:6,id_padre:588,id:427,parametro:"Manuel B. Gonnet"},
    {id_pais:1,id_provincia:6,id_padre:588,id:428,parametro:"Marcelino Ugarte"},
    {id_pais:1,id_provincia:6,id_padre:588,id:429,parametro:"Morea"},
    {id_pais:1,id_provincia:6,id_padre:588,id:430,parametro:"Norumbega"},
    {id_pais:1,id_provincia:6,id_padre:588,id:431,parametro:"9 de Julio"},
    {id_pais:1,id_provincia:6,id_padre:588,id:432,parametro:"Patricios"},
    {id_pais:1,id_provincia:6,id_padre:588,id:433,parametro:"Villa Fournier"},
    {id_pais:1,id_provincia:6,id_padre:595,id:434,parametro:"Blancagrande"},
    {id_pais:1,id_provincia:6,id_padre:595,id:435,parametro:"Colonia Nievas"},
    {id_pais:1,id_provincia:6,id_padre:595,id:436,parametro:"Colonia San Miguel"},
    {id_pais:1,id_provincia:6,id_padre:595,id:437,parametro:"Espigas"},
    {id_pais:1,id_provincia:6,id_padre:595,id:438,parametro:"Hinojo"},
    {id_pais:1,id_provincia:6,id_padre:595,id:439,parametro:"Olavarría"},
    {id_pais:1,id_provincia:6,id_padre:595,id:440,parametro:"Recalde"},
    {id_pais:1,id_provincia:6,id_padre:595,id:441,parametro:"Santa Luisa"},
    {id_pais:1,id_provincia:6,id_padre:595,id:442,parametro:"Sierra Chica"},
    {id_pais:1,id_provincia:6,id_padre:595,id:443,parametro:"Sierras Bayas"},
    {id_pais:1,id_provincia:6,id_padre:595,id:444,parametro:"Villa Alfredo Fortabat"},
    {id_pais:1,id_provincia:6,id_padre:595,id:445,parametro:"Villa La Serranía"},
    {id_pais:1,id_provincia:6,id_padre:602,id:446,parametro:"Bahía San Blas"},
    {id_pais:1,id_provincia:6,id_padre:602,id:447,parametro:"Cardenal Cagliero"},
    {id_pais:1,id_provincia:6,id_padre:602,id:448,parametro:"Carmen de Patagones"},
    {id_pais:1,id_provincia:6,id_padre:602,id:449,parametro:"José B. Casas"},
    {id_pais:1,id_provincia:6,id_padre:602,id:450,parametro:"Juan A. Pradere"},
    {id_pais:1,id_provincia:6,id_padre:602,id:451,parametro:"Stroeder"},
    {id_pais:1,id_provincia:6,id_padre:602,id:452,parametro:"Villalonga"},
    {id_pais:1,id_provincia:6,id_padre:609,id:453,parametro:"Capitán Castro"},
    {id_pais:1,id_provincia:6,id_padre:609,id:454,parametro:"San Esteban"},
    {id_pais:1,id_provincia:6,id_padre:609,id:455,parametro:"Francisco Madero"},
    {id_pais:1,id_provincia:6,id_padre:609,id:456,parametro:"Inocencio Sosa"},
    {id_pais:1,id_provincia:6,id_padre:609,id:457,parametro:"Juan José Paso"},
    {id_pais:1,id_provincia:6,id_padre:609,id:458,parametro:"Magdala"},
    {id_pais:1,id_provincia:6,id_padre:609,id:459,parametro:"Mones Cazón"},
    {id_pais:1,id_provincia:6,id_padre:609,id:460,parametro:"Nueva Plata"},
    {id_pais:1,id_provincia:6,id_padre:609,id:461,parametro:"Pehuajó"},
    {id_pais:1,id_provincia:6,id_padre:609,id:462,parametro:"San Bernardo"},
    {id_pais:1,id_provincia:6,id_padre:616,id:463,parametro:"Bocayuva"},
    {id_pais:1,id_provincia:6,id_padre:616,id:464,parametro:"De Bary"},
    {id_pais:1,id_provincia:6,id_padre:616,id:465,parametro:"Pellegrini"},
    {id_pais:1,id_provincia:6,id_padre:623,id:466,parametro:"Acevedo"},
    {id_pais:1,id_provincia:6,id_padre:623,id:467,parametro:"Fontezuela"},
    {id_pais:1,id_provincia:6,id_padre:623,id:468,parametro:"Guerrico"},
    {id_pais:1,id_provincia:6,id_padre:623,id:469,parametro:"Juan A. de la Peña"},
    {id_pais:1,id_provincia:6,id_padre:623,id:470,parametro:"Juan Anchorena"},
    {id_pais:1,id_provincia:6,id_padre:623,id:471,parametro:"La Violeta"},
    {id_pais:1,id_provincia:6,id_padre:623,id:472,parametro:"Manuel Ocampo"},
    {id_pais:1,id_provincia:6,id_padre:623,id:473,parametro:"Mariano Benítez"},
    {id_pais:1,id_provincia:6,id_padre:623,id:474,parametro:"Mariano H. Alfonzo"},
    {id_pais:1,id_provincia:6,id_padre:623,id:475,parametro:"Pergamino"},
    {id_pais:1,id_provincia:6,id_padre:623,id:476,parametro:"Pinzón"},
    {id_pais:1,id_provincia:6,id_padre:623,id:477,parametro:"Rancagua"},
    {id_pais:1,id_provincia:6,id_padre:623,id:478,parametro:"Villa Angélica"},
    {id_pais:1,id_provincia:6,id_padre:623,id:479,parametro:"Villa San José"},
    {id_pais:1,id_provincia:6,id_padre:630,id:480,parametro:"Casalins"},
    {id_pais:1,id_provincia:6,id_padre:630,id:481,parametro:"Pila"},
    {id_pais:1,id_provincia:6,id_padre:638,id:482,parametro:"Pilar"},
    {id_pais:1,id_provincia:6,id_padre:644,id:483,parametro:"Pinamar"},
    {id_pais:1,id_provincia:6,id_padre:648,id:484,parametro:"Presidente Perón"},
    {id_pais:1,id_provincia:6,id_padre:651,id:485,parametro:"Azopardo"},
    {id_pais:1,id_provincia:6,id_padre:651,id:486,parametro:"Bordenave"},
    {id_pais:1,id_provincia:6,id_padre:651,id:487,parametro:"Darregueira"},
    {id_pais:1,id_provincia:6,id_padre:651,id:488,parametro:"17 de Agosto"},
    {id_pais:1,id_provincia:6,id_padre:651,id:489,parametro:"Estela"},
    {id_pais:1,id_provincia:6,id_padre:651,id:490,parametro:"Felipe Solá"},
    {id_pais:1,id_provincia:6,id_padre:651,id:491,parametro:"López Lecube"},
    {id_pais:1,id_provincia:6,id_padre:651,id:492,parametro:"Puán"},
    {id_pais:1,id_provincia:6,id_padre:651,id:493,parametro:"San Germán"},
    {id_pais:1,id_provincia:6,id_padre:651,id:494,parametro:"Villa Castelar"},
    {id_pais:1,id_provincia:6,id_padre:651,id:495,parametro:"Villa Iris"},
    {id_pais:1,id_provincia:6,id_padre:655,id:496,parametro:"Alvarez Jonte"},
    {id_pais:1,id_provincia:6,id_padre:655,id:497,parametro:"Pipinas"},
    {id_pais:1,id_provincia:6,id_padre:655,id:498,parametro:"Punta Indio"},
    {id_pais:1,id_provincia:6,id_padre:655,id:499,parametro:"Verónica"},
    {id_pais:1,id_provincia:6,id_padre:658,id:500,parametro:"Quilmes"},
    {id_pais:1,id_provincia:6,id_padre:665,id:501,parametro:"El Paraíso"},
{id_pais:1,id_provincia:6,id_padre:665,id:502,parametro:"Las Bahamas"},
{id_pais:1,id_provincia:6,id_padre:665,id:503,parametro:"Pérez Millán"},
{id_pais:1,id_provincia:6,id_padre:665,id:504,parametro:"Ramallo"},
{id_pais:1,id_provincia:6,id_padre:665,id:505,parametro:"Villa General Savio"},
{id_pais:1,id_provincia:6,id_padre:665,id:506,parametro:"Villa Ramallo"},
{id_pais:1,id_provincia:6,id_padre:672,id:507,parametro:"Rauch"},
{id_pais:1,id_provincia:6,id_padre:679,id:508,parametro:"América"},
{id_pais:1,id_provincia:6,id_padre:679,id:509,parametro:"Fortín Olavarría"},
{id_pais:1,id_provincia:6,id_padre:679,id:510,parametro:"González Moreno"},
{id_pais:1,id_provincia:6,id_padre:679,id:511,parametro:"Mira Pampa"},
{id_pais:1,id_provincia:6,id_padre:679,id:512,parametro:"Roosevelt"},
{id_pais:1,id_provincia:6,id_padre:679,id:513,parametro:"San Mauricio"},
{id_pais:1,id_provincia:6,id_padre:679,id:514,parametro:"Sansinena"},
{id_pais:1,id_provincia:6,id_padre:679,id:515,parametro:"Sundblad"},
{id_pais:1,id_provincia:6,id_padre:686,id:516,parametro:"La Beba"},
{id_pais:1,id_provincia:6,id_padre:686,id:517,parametro:"Las Carabelas"},
{id_pais:1,id_provincia:6,id_padre:686,id:518,parametro:"Los Indios"},
{id_pais:1,id_provincia:6,id_padre:686,id:519,parametro:"Rafael Obligado"},
{id_pais:1,id_provincia:6,id_padre:686,id:520,parametro:"Roberto Cano"},
{id_pais:1,id_provincia:6,id_padre:686,id:521,parametro:"Rojas"},
{id_pais:1,id_provincia:6,id_padre:686,id:522,parametro:"Sol de Mayo"},
{id_pais:1,id_provincia:6,id_padre:686,id:523,parametro:"Villa Manuel Pomar"},
{id_pais:1,id_provincia:6,id_padre:693,id:524,parametro:"Carlos Beguerie"},
{id_pais:1,id_provincia:6,id_padre:693,id:525,parametro:"Roque Pérez"},
{id_pais:1,id_provincia:6,id_padre:700,id:526,parametro:"Arroyo Corto"},
{id_pais:1,id_provincia:6,id_padre:700,id:527,parametro:"Colonia San Martín"},
{id_pais:1,id_provincia:6,id_padre:700,id:528,parametro:"Dufaur"},
{id_pais:1,id_provincia:6,id_padre:700,id:529,parametro:"Espartillar"},
{id_pais:1,id_provincia:6,id_padre:700,id:530,parametro:"Goyena"},
{id_pais:1,id_provincia:6,id_padre:700,id:531,parametro:"Las Encadenadas"},
{id_pais:1,id_provincia:6,id_padre:700,id:532,parametro:"Pigüé"},
{id_pais:1,id_provincia:6,id_padre:700,id:533,parametro:"Saavedra"},
{id_pais:1,id_provincia:6,id_padre:707,id:534,parametro:"álvarez de Toledo"},
{id_pais:1,id_provincia:6,id_padre:707,id:535,parametro:"Cazón"},
{id_pais:1,id_provincia:6,id_padre:707,id:536,parametro:"Del Carril"},
{id_pais:1,id_provincia:6,id_padre:707,id:537,parametro:"Polvaredas"},
{id_pais:1,id_provincia:6,id_padre:707,id:538,parametro:"Saladillo"},
{id_pais:1,id_provincia:6,id_padre:714,id:539,parametro:"Arroyo Dulce"},
{id_pais:1,id_provincia:6,id_padre:714,id:540,parametro:"Berdier"},
{id_pais:1,id_provincia:6,id_padre:714,id:541,parametro:"Gahan"},
{id_pais:1,id_provincia:6,id_padre:714,id:542,parametro:"Inés Indart"},
{id_pais:1,id_provincia:6,id_padre:714,id:543,parametro:"La Invencible"},
{id_pais:1,id_provincia:6,id_padre:714,id:544,parametro:"Salto"},
{id_pais:1,id_provincia:6,id_padre:721,id:545,parametro:"Quenumá"},
{id_pais:1,id_provincia:6,id_padre:721,id:546,parametro:"Salliqueló"},
{id_pais:1,id_provincia:6,id_padre:728,id:547,parametro:"Azcuénaga"},
{id_pais:1,id_provincia:6,id_padre:728,id:548,parametro:"Cucullú"},
{id_pais:1,id_provincia:6,id_padre:728,id:549,parametro:"Franklin"},
{id_pais:1,id_provincia:6,id_padre:728,id:550,parametro:"San Andrés de Giles"},
{id_pais:1,id_provincia:6,id_padre:728,id:551,parametro:"Solís"},
{id_pais:1,id_provincia:6,id_padre:728,id:552,parametro:"Villa Espil"},
{id_pais:1,id_provincia:6,id_padre:728,id:553,parametro:"Villa Ruiz"},
{id_pais:1,id_provincia:6,id_padre:735,id:554,parametro:"Duggan"},
{id_pais:1,id_provincia:6,id_padre:735,id:555,parametro:"San Antonio de Areco"},
{id_pais:1,id_provincia:6,id_padre:735,id:556,parametro:"Villa Lía"},
{id_pais:1,id_provincia:6,id_padre:742,id:557,parametro:"Balneario San Cayetano"},
{id_pais:1,id_provincia:6,id_padre:742,id:558,parametro:"Ochandío"},
{id_pais:1,id_provincia:6,id_padre:742,id:559,parametro:"San Cayetano"},
{id_pais:1,id_provincia:6,id_padre:749,id:560,parametro:"San Fernando"},
{id_pais:1,id_provincia:6,id_padre:756,id:561,parametro:"San Isidro"},
{id_pais:1,id_provincia:6,id_padre:760,id:562,parametro:"San Miguel"},
{id_pais:1,id_provincia:6,id_padre:763,id:563,parametro:"Conesa"},
{id_pais:1,id_provincia:6,id_padre:763,id:564,parametro:"Erezcano"},
{id_pais:1,id_provincia:6,id_padre:763,id:565,parametro:"General Rojo"},
{id_pais:1,id_provincia:6,id_padre:763,id:566,parametro:"La Emilia"},
{id_pais:1,id_provincia:6,id_padre:763,id:567,parametro:"San Nicolás de los Arroyos"},
{id_pais:1,id_provincia:6,id_padre:763,id:568,parametro:"Villa Esperanza"},
{id_pais:1,id_provincia:6,id_padre:770,id:569,parametro:"Gobernador Castro"},
{id_pais:1,id_provincia:6,id_padre:770,id:570,parametro:"Ingeniero Moneta"},
{id_pais:1,id_provincia:6,id_padre:770,id:571,parametro:"Obligado"},
{id_pais:1,id_provincia:6,id_padre:770,id:572,parametro:"Pueblo Doyle"},
{id_pais:1,id_provincia:6,id_padre:770,id:573,parametro:"Río Tala"},
{id_pais:1,id_provincia:6,id_padre:770,id:574,parametro:"San Pedro"},
{id_pais:1,id_provincia:6,id_padre:770,id:575,parametro:"Santa Lucía"},
{id_pais:1,id_provincia:6,id_padre:778,id:576,parametro:"San Vicente"},
{id_pais:1,id_provincia:6,id_padre:784,id:577,parametro:"General Rivas"},
{id_pais:1,id_provincia:6,id_padre:784,id:578,parametro:"Suipacha"},
{id_pais:1,id_provincia:6,id_padre:791,id:579,parametro:"De la Canal"},
{id_pais:1,id_provincia:6,id_padre:791,id:580,parametro:"Gardey"},
{id_pais:1,id_provincia:6,id_padre:791,id:581,parametro:"María Ignacia"},
{id_pais:1,id_provincia:6,id_padre:791,id:582,parametro:"Tandil"},
{id_pais:1,id_provincia:6,id_padre:798,id:583,parametro:"Crotto"},
{id_pais:1,id_provincia:6,id_padre:798,id:584,parametro:"Tapalqué"},
{id_pais:1,id_provincia:6,id_padre:798,id:585,parametro:"Velloso"},
{id_pais:1,id_provincia:6,id_padre:805,id:586,parametro:"Tigre"},
{id_pais:1,id_provincia:6,id_padre:812,id:587,parametro:"General Conesa"},
{id_pais:1,id_provincia:6,id_padre:819,id:588,parametro:"Chasicó"},
{id_pais:1,id_provincia:6,id_padre:819,id:589,parametro:"Saldungaray"},
{id_pais:1,id_provincia:6,id_padre:819,id:590,parametro:"Sierra de la Ventana"},
{id_pais:1,id_provincia:6,id_padre:819,id:591,parametro:"Tornquist"},
{id_pais:1,id_provincia:6,id_padre:819,id:592,parametro:"Tres Picos"},
{id_pais:1,id_provincia:6,id_padre:819,id:593,parametro:"La Gruta"},
{id_pais:1,id_provincia:6,id_padre:819,id:594,parametro:"Villa Ventana"},
{id_pais:1,id_provincia:6,id_padre:826,id:595,parametro:"Berutti"},
{id_pais:1,id_provincia:6,id_padre:826,id:596,parametro:"Girodias"},
{id_pais:1,id_provincia:6,id_padre:826,id:597,parametro:"La Carreta"},
{id_pais:1,id_provincia:6,id_padre:826,id:598,parametro:"30 de Agosto"},
{id_pais:1,id_provincia:6,id_padre:826,id:599,parametro:"Trenque Lauquen"},
{id_pais:1,id_provincia:6,id_padre:826,id:600,parametro:"Trongé"},
{id_pais:1,id_provincia:6,id_padre:833,id:601,parametro:"Balneario Orense"},
{id_pais:1,id_provincia:6,id_padre:833,id:602,parametro:"Claromecó"},
{id_pais:1,id_provincia:6,id_padre:833,id:603,parametro:"Copetonas"},
{id_pais:1,id_provincia:6,id_padre:833,id:604,parametro:"Lin Calel"},
{id_pais:1,id_provincia:6,id_padre:833,id:605,parametro:"Micaela Cascallares"},
{id_pais:1,id_provincia:6,id_padre:833,id:606,parametro:"Orense"},
{id_pais:1,id_provincia:6,id_padre:833,id:607,parametro:"Reta"},
{id_pais:1,id_provincia:6,id_padre:833,id:608,parametro:"San Francisco de Bellocq"},
{id_pais:1,id_provincia:6,id_padre:833,id:609,parametro:"San Mayol"},
{id_pais:1,id_provincia:6,id_padre:833,id:610,parametro:"Tres Arroyos"},
{id_pais:1,id_provincia:6,id_padre:833,id:611,parametro:"Villa Rodríguez"},
{id_pais:1,id_provincia:6,id_padre:840,id:612,parametro:"Tres de Febrero"},
{id_pais:1,id_provincia:6,id_padre:847,id:613,parametro:"Ingeniero Thompson"},
{id_pais:1,id_provincia:6,id_padre:847,id:614,parametro:"Tres Lomas"},
{id_pais:1,id_provincia:6,id_padre:854,id:615,parametro:"Agustín Mosconi"},
{id_pais:1,id_provincia:6,id_padre:854,id:616,parametro:"Del Valle"},
{id_pais:1,id_provincia:6,id_padre:854,id:617,parametro:"Ernestina"},
{id_pais:1,id_provincia:6,id_padre:854,id:618,parametro:"Gobernador Ugarte"},
{id_pais:1,id_provincia:6,id_padre:854,id:619,parametro:"Lucas Monteverde"},
{id_pais:1,id_provincia:6,id_padre:854,id:620,parametro:"Norberto de la Riestra"},
{id_pais:1,id_provincia:6,id_padre:854,id:621,parametro:"Pedernales"},
{id_pais:1,id_provincia:6,id_padre:854,id:622,parametro:"San Enrique"},
{id_pais:1,id_provincia:6,id_padre:854,id:623,parametro:"Valdés"},
{id_pais:1,id_provincia:6,id_padre:854,id:624,parametro:"25 de Mayo"},
{id_pais:1,id_provincia:6,id_padre:861,id:625,parametro:"Vicente López"},
{id_pais:1,id_provincia:6,id_padre:868,id:626,parametro:"Mar Azul"},
{id_pais:1,id_provincia:6,id_padre:868,id:627,parametro:"Villa Gesell"},
{id_pais:1,id_provincia:6,id_padre:875,id:628,parametro:"Argerich"},
{id_pais:1,id_provincia:6,id_padre:875,id:629,parametro:"Colonia San Adolfo"},
{id_pais:1,id_provincia:6,id_padre:875,id:630,parametro:"Country Los Medanos"},
{id_pais:1,id_provincia:6,id_padre:875,id:631,parametro:"Hilario Ascasubi"},
{id_pais:1,id_provincia:6,id_padre:875,id:632,parametro:"Juan Cousté"},
{id_pais:1,id_provincia:6,id_padre:875,id:633,parametro:"Mayor Buratovich"},
{id_pais:1,id_provincia:6,id_padre:875,id:634,parametro:"Médanos"},
{id_pais:1,id_provincia:6,id_padre:875,id:635,parametro:"Pedro Luro"},
{id_pais:1,id_provincia:6,id_padre:875,id:636,parametro:"Teniente Origone"},
{id_pais:1,id_provincia:6,id_padre:882,id:637,parametro:"Country Club El Casco"},
{id_pais:1,id_provincia:6,id_padre:882,id:638,parametro:"Escalada"},
{id_pais:1,id_provincia:6,id_padre:882,id:639,parametro:"Lima"},
{id_pais:1,id_provincia:6,id_padre:882,id:640,parametro:"Zárate"},
{id_pais:1,id_provincia:10,id_padre:7,id:641,parametro:"Chuchucaruana"},
{id_pais:1,id_provincia:10,id_padre:7,id:642,parametro:"Colpes"},
{id_pais:1,id_provincia:10,id_padre:7,id:643,parametro:"El Bolsón"},
{id_pais:1,id_provincia:10,id_padre:7,id:644,parametro:"El Rodeo"},
{id_pais:1,id_provincia:10,id_padre:7,id:645,parametro:"Huaycama"},
{id_pais:1,id_provincia:10,id_padre:7,id:646,parametro:"La Puerta"},
{id_pais:1,id_provincia:10,id_padre:7,id:647,parametro:"Las Chacritas"},
{id_pais:1,id_provincia:10,id_padre:7,id:648,parametro:"Las Juntas"},
{id_pais:1,id_provincia:10,id_padre:7,id:649,parametro:"Los Castillos"},
{id_pais:1,id_provincia:10,id_padre:7,id:650,parametro:"Los Talas"},
{id_pais:1,id_provincia:10,id_padre:7,id:651,parametro:"Los Varela"},
{id_pais:1,id_provincia:10,id_padre:7,id:652,parametro:"Singuil"},
{id_pais:1,id_provincia:10,id_padre:14,id:653,parametro:"Ancasti"},
{id_pais:1,id_provincia:10,id_padre:14,id:654,parametro:"Anquincila"},
{id_pais:1,id_provincia:10,id_padre:14,id:655,parametro:"La Candelaria"},
{id_pais:1,id_provincia:10,id_padre:14,id:656,parametro:"La Majada"},
{id_pais:1,id_provincia:10,id_padre:21,id:657,parametro:"Amanao"},
{id_pais:1,id_provincia:10,id_padre:21,id:658,parametro:"Andalgalá"},
{id_pais:1,id_provincia:10,id_padre:21,id:659,parametro:"Chaquiago"},
{id_pais:1,id_provincia:10,id_padre:21,id:660,parametro:"Choya"},
{id_pais:1,id_provincia:10,id_padre:21,id:661,parametro:"El Alamito"},
{id_pais:1,id_provincia:10,id_padre:21,id:662,parametro:"El Lindero"},
{id_pais:1,id_provincia:10,id_padre:21,id:663,parametro:"El Potrero"},
{id_pais:1,id_provincia:10,id_padre:21,id:664,parametro:"La Aguada"},
{id_pais:1,id_provincia:10,id_padre:28,id:665,parametro:"Antofagasta de la Sierra"},
{id_pais:1,id_provincia:10,id_padre:28,id:666,parametro:"Antofalla"},
{id_pais:1,id_provincia:10,id_padre:28,id:667,parametro:"El Peñón"},
{id_pais:1,id_provincia:10,id_padre:28,id:668,parametro:"Los Nacimientos"},
{id_pais:1,id_provincia:10,id_padre:35,id:669,parametro:"Barranca Larga"},
{id_pais:1,id_provincia:10,id_padre:35,id:670,parametro:"Belén"},
{id_pais:1,id_provincia:10,id_padre:35,id:671,parametro:"Cóndor Huasi"},
{id_pais:1,id_provincia:10,id_padre:35,id:672,parametro:"Corral Quemado"},
{id_pais:1,id_provincia:10,id_padre:35,id:673,parametro:"El Durazno"},
{id_pais:1,id_provincia:10,id_padre:35,id:674,parametro:"Farallón Negro"},
{id_pais:1,id_provincia:10,id_padre:35,id:675,parametro:"Hualfín"},
{id_pais:1,id_provincia:10,id_padre:35,id:676,parametro:"Jacipunco"},
{id_pais:1,id_provincia:10,id_padre:35,id:677,parametro:"La Puntilla"},
{id_pais:1,id_provincia:10,id_padre:35,id:678,parametro:"Las Juntas"},
{id_pais:1,id_provincia:10,id_padre:35,id:679,parametro:"Londres"},
{id_pais:1,id_provincia:10,id_padre:35,id:680,parametro:"Los Nacimientos"},
{id_pais:1,id_provincia:10,id_padre:35,id:681,parametro:"Puerta de Corral Quemado"},
{id_pais:1,id_provincia:10,id_padre:35,id:682,parametro:"Puerta de San José"},
{id_pais:1,id_provincia:10,id_padre:35,id:683,parametro:"Villa Vil"},
{id_pais:1,id_provincia:10,id_padre:42,id:684,parametro:"Adolfo E. Carranza"},
{id_pais:1,id_provincia:10,id_padre:42,id:685,parametro:"Balde de la Punta"},
{id_pais:1,id_provincia:10,id_padre:42,id:686,parametro:"Capayán"},
{id_pais:1,id_provincia:10,id_padre:42,id:687,parametro:"Chumbicha"},
{id_pais:1,id_provincia:10,id_padre:42,id:688,parametro:"Colonia del Valle"},
{id_pais:1,id_provincia:10,id_padre:42,id:689,parametro:"Colonia Nueva Coneta"},
{id_pais:1,id_provincia:10,id_padre:42,id:690,parametro:"Concepción"},
{id_pais:1,id_provincia:10,id_padre:42,id:691,parametro:"Coneta"},
{id_pais:1,id_provincia:10,id_padre:42,id:692,parametro:"El Bañado"},
{id_pais:1,id_provincia:10,id_padre:42,id:693,parametro:"Huillapima"},
{id_pais:1,id_provincia:10,id_padre:42,id:694,parametro:"Los Angeles"},
{id_pais:1,id_provincia:10,id_padre:42,id:695,parametro:"Miraflores"},
{id_pais:1,id_provincia:10,id_padre:42,id:696,parametro:"San Martín"},
{id_pais:1,id_provincia:10,id_padre:42,id:697,parametro:"San Pablo"},
{id_pais:1,id_provincia:10,id_padre:42,id:698,parametro:"San Pedro"},
{id_pais:1,id_provincia:10,id_padre:49,id:699,parametro:"El Pantanillo"},
{id_pais:1,id_provincia:10,id_padre:49,id:700,parametro:"San Fernando del Valle de Catamarca"},
{id_pais:1,id_provincia:10,id_padre:56,id:701,parametro:"El Alto"},
{id_pais:1,id_provincia:10,id_padre:56,id:702,parametro:"Guayamba"},
{id_pais:1,id_provincia:10,id_padre:56,id:703,parametro:"Infanzón"},
{id_pais:1,id_provincia:10,id_padre:56,id:704,parametro:"Los Corrales"},
{id_pais:1,id_provincia:10,id_padre:56,id:705,parametro:"Tapso"},
{id_pais:1,id_provincia:10,id_padre:56,id:706,parametro:"Vilismán"},
{id_pais:1,id_provincia:10,id_padre:63,id:707,parametro:"Collagasta"},
{id_pais:1,id_provincia:10,id_padre:63,id:708,parametro:"Pomancillo Este"},
{id_pais:1,id_provincia:10,id_padre:63,id:709,parametro:"Pomancillo Oeste"},
{id_pais:1,id_provincia:10,id_padre:63,id:710,parametro:"San José"},
{id_pais:1,id_provincia:10,id_padre:63,id:711,parametro:"Villa Las Pirquitas"},
{id_pais:1,id_provincia:10,id_padre:70,id:712,parametro:"Casa de Piedra"},
{id_pais:1,id_provincia:10,id_padre:70,id:713,parametro:"El Aybal"},
{id_pais:1,id_provincia:10,id_padre:70,id:714,parametro:"El Bañado"},
{id_pais:1,id_provincia:10,id_padre:70,id:715,parametro:"El Divisadero"},
{id_pais:1,id_provincia:10,id_padre:70,id:716,parametro:"El Quimilo"},
{id_pais:1,id_provincia:10,id_padre:70,id:717,parametro:"Esquiú"},
{id_pais:1,id_provincia:10,id_padre:70,id:718,parametro:"Icaño"},
{id_pais:1,id_provincia:10,id_padre:70,id:719,parametro:"La Dorada"},
{id_pais:1,id_provincia:10,id_padre:70,id:720,parametro:"La Guardia"},
{id_pais:1,id_provincia:10,id_padre:70,id:721,parametro:"Las Esquinas"},
{id_pais:1,id_provincia:10,id_padre:70,id:722,parametro:"Las Palmitas"},
{id_pais:1,id_provincia:10,id_padre:70,id:723,parametro:"Quirós"},
{id_pais:1,id_provincia:10,id_padre:70,id:724,parametro:"Ramblones"},
{id_pais:1,id_provincia:10,id_padre:70,id:725,parametro:"Recreo"},
{id_pais:1,id_provincia:10,id_padre:70,id:726,parametro:"San Antonio"},
{id_pais:1,id_provincia:10,id_padre:77,id:727,parametro:"Amadores"},
{id_pais:1,id_provincia:10,id_padre:77,id:728,parametro:"El Rosario"},
{id_pais:1,id_provincia:10,id_padre:77,id:729,parametro:"La Bajada"},
{id_pais:1,id_provincia:10,id_padre:77,id:730,parametro:"La Higuera"},
{id_pais:1,id_provincia:10,id_padre:77,id:731,parametro:"La Merced"},
{id_pais:1,id_provincia:10,id_padre:77,id:732,parametro:"La Viña"},
{id_pais:1,id_provincia:10,id_padre:77,id:733,parametro:"Las Lajas"},
{id_pais:1,id_provincia:10,id_padre:77,id:734,parametro:"Monte Potrero"},
{id_pais:1,id_provincia:10,id_padre:77,id:735,parametro:"Palo Labrado"},
{id_pais:1,id_provincia:10,id_padre:77,id:736,parametro:"San Antonio"},
{id_pais:1,id_provincia:10,id_padre:77,id:737,parametro:"Villa de Balcozna"},
{id_pais:1,id_provincia:10,id_padre:84,id:738,parametro:"Apoyaco"},
{id_pais:1,id_provincia:10,id_padre:84,id:739,parametro:"Colana"},
{id_pais:1,id_provincia:10,id_padre:84,id:740,parametro:"Colpes"},
{id_pais:1,id_provincia:10,id_padre:84,id:741,parametro:"El Pajonal"},
{id_pais:1,id_provincia:10,id_padre:84,id:742,parametro:"Joyango"},
{id_pais:1,id_provincia:10,id_padre:84,id:743,parametro:"Mutquin"},
{id_pais:1,id_provincia:10,id_padre:84,id:744,parametro:"Pomán"},
{id_pais:1,id_provincia:10,id_padre:84,id:745,parametro:"Rincón"},
{id_pais:1,id_provincia:10,id_padre:84,id:746,parametro:"San Miguel"},
{id_pais:1,id_provincia:10,id_padre:84,id:747,parametro:"Saujil"},
{id_pais:1,id_provincia:10,id_padre:84,id:748,parametro:"Siján"},
{id_pais:1,id_provincia:10,id_padre:91,id:749,parametro:"Andalhualá"},
{id_pais:1,id_provincia:10,id_padre:91,id:750,parametro:"Caspichango"},
{id_pais:1,id_provincia:10,id_padre:91,id:751,parametro:"Chañar Punco"},
{id_pais:1,id_provincia:10,id_padre:91,id:752,parametro:"El Cajón"},
{id_pais:1,id_provincia:10,id_padre:91,id:753,parametro:"El Desmonte"},
{id_pais:1,id_provincia:10,id_padre:91,id:754,parametro:"El Puesto"},
{id_pais:1,id_provincia:10,id_padre:91,id:755,parametro:"Famatanca"},
{id_pais:1,id_provincia:10,id_padre:91,id:756,parametro:"Fuerte Quemado"},
{id_pais:1,id_provincia:10,id_padre:91,id:757,parametro:"La Hoyada"},
{id_pais:1,id_provincia:10,id_padre:91,id:758,parametro:"La Loma"},
{id_pais:1,id_provincia:10,id_padre:91,id:759,parametro:"Las Mojarras"},
{id_pais:1,id_provincia:10,id_padre:91,id:760,parametro:"Loro Huasi"},
{id_pais:1,id_provincia:10,id_padre:91,id:761,parametro:"Punta de Balasto"},
{id_pais:1,id_provincia:10,id_padre:91,id:762,parametro:"San José"},
{id_pais:1,id_provincia:10,id_padre:91,id:763,parametro:"Santa María"},
{id_pais:1,id_provincia:10,id_padre:91,id:764,parametro:"Yapes"},
{id_pais:1,id_provincia:10,id_padre:98,id:765,parametro:"Alijilán"},
{id_pais:1,id_provincia:10,id_padre:98,id:766,parametro:"Bañado de Ovanta"},
{id_pais:1,id_provincia:10,id_padre:98,id:767,parametro:"Las Cañas"},
{id_pais:1,id_provincia:10,id_padre:98,id:768,parametro:"Lavalle"},
{id_pais:1,id_provincia:10,id_padre:98,id:769,parametro:"Los Altos"},
{id_pais:1,id_provincia:10,id_padre:98,id:770,parametro:"Manantiales"},
{id_pais:1,id_provincia:10,id_padre:98,id:771,parametro:"San Pedro"},
{id_pais:1,id_provincia:10,id_padre:105,id:772,parametro:"Anillaco"},
{id_pais:1,id_provincia:10,id_padre:105,id:773,parametro:"Antinaco"},
{id_pais:1,id_provincia:10,id_padre:105,id:774,parametro:"Banda de Lucero"},
{id_pais:1,id_provincia:10,id_padre:105,id:775,parametro:"Cerro Negro"},
{id_pais:1,id_provincia:10,id_padre:105,id:776,parametro:"Copacabana"},
{id_pais:1,id_provincia:10,id_padre:105,id:777,parametro:"Cordobita"},
{id_pais:1,id_provincia:10,id_padre:105,id:778,parametro:"Costa de Reyes"},
{id_pais:1,id_provincia:10,id_padre:105,id:779,parametro:"El Pueblito"},
{id_pais:1,id_provincia:10,id_padre:105,id:780,parametro:"El Puesto"},
{id_pais:1,id_provincia:10,id_padre:105,id:781,parametro:"El Salado"},
{id_pais:1,id_provincia:10,id_padre:105,id:782,parametro:"Fiambalá"},
{id_pais:1,id_provincia:10,id_padre:105,id:783,parametro:"Los Balverdis"},
{id_pais:1,id_provincia:10,id_padre:105,id:784,parametro:"Medanitos"},
{id_pais:1,id_provincia:10,id_padre:105,id:785,parametro:"Palo Blanco"},
{id_pais:1,id_provincia:10,id_padre:105,id:786,parametro:"Punta del Agua"},
{id_pais:1,id_provincia:10,id_padre:105,id:787,parametro:"Saujil"},
{id_pais:1,id_provincia:10,id_padre:105,id:788,parametro:"Tatón"},
{id_pais:1,id_provincia:10,id_padre:105,id:789,parametro:"Tinogasta"},
{id_pais:1,id_provincia:10,id_padre:112,id:790,parametro:"El Portezuelo"},
{id_pais:1,id_provincia:10,id_padre:112,id:791,parametro:"Huaycama"},
{id_pais:1,id_provincia:10,id_padre:112,id:792,parametro:"Las Tejas"},
{id_pais:1,id_provincia:10,id_padre:112,id:793,parametro:"San Isidro"},
{id_pais:1,id_provincia:10,id_padre:112,id:794,parametro:"Santa Cruz"},
{id_pais:1,id_provincia:14,id_padre:7,id:795,parametro:"Amboy"},
{id_pais:1,id_provincia:14,id_padre:7,id:796,parametro:"Arroyo San Antonio"},
{id_pais:1,id_provincia:14,id_padre:7,id:797,parametro:"Cañada del Sauce"},
{id_pais:1,id_provincia:14,id_padre:7,id:798,parametro:"Capilla Vieja"},
{id_pais:1,id_provincia:14,id_padre:7,id:799,parametro:"El Corcovado - El Torreón"},
{id_pais:1,id_provincia:14,id_padre:7,id:800,parametro:"El Durazno"},
{id_pais:1,id_provincia:14,id_padre:7,id:801,parametro:"Embalse"},
{id_pais:1,id_provincia:14,id_padre:7,id:802,parametro:"La Cruz"},
{id_pais:1,id_provincia:14,id_padre:7,id:803,parametro:"La Cumbrecita"},
{id_pais:1,id_provincia:14,id_padre:7,id:804,parametro:"Las Bajadas"},
{id_pais:1,id_provincia:14,id_padre:7,id:805,parametro:"Las Caleras"},
{id_pais:1,id_provincia:14,id_padre:7,id:806,parametro:"Los Cóndores"},
{id_pais:1,id_provincia:14,id_padre:7,id:807,parametro:"Los Molinos"},
{id_pais:1,id_provincia:14,id_padre:7,id:808,parametro:"Los Reartes"},
{id_pais:1,id_provincia:14,id_padre:7,id:809,parametro:"Lutti"},
{id_pais:1,id_provincia:14,id_padre:7,id:810,parametro:"Parque Calmayo"},
{id_pais:1,id_provincia:14,id_padre:7,id:811,parametro:"Río de los Sauces"},
{id_pais:1,id_provincia:14,id_padre:7,id:812,parametro:"San Agustín"},
{id_pais:1,id_provincia:14,id_padre:7,id:813,parametro:"San Ignacio (Loteo San Javier)"},
{id_pais:1,id_provincia:14,id_padre:7,id:814,parametro:"Santa Rosa de Calamuchita"},
{id_pais:1,id_provincia:14,id_padre:7,id:815,parametro:"Segunda Usina"},
{id_pais:1,id_provincia:14,id_padre:7,id:816,parametro:"Solar de los Molinos"},
{id_pais:1,id_provincia:14,id_padre:7,id:817,parametro:"Villa Alpina"},
{id_pais:1,id_provincia:14,id_padre:7,id:818,parametro:"Villa Amancay"},
{id_pais:1,id_provincia:14,id_padre:7,id:819,parametro:"Villa Berna"},
{id_pais:1,id_provincia:14,id_padre:7,id:820,parametro:"Villa Ciudad Parque Los Reartes (1a. Sección)"},
{id_pais:1,id_provincia:14,id_padre:7,id:821,parametro:"Villa del Dique"},
{id_pais:1,id_provincia:14,id_padre:7,id:822,parametro:"Villa El Tala"},
{id_pais:1,id_provincia:14,id_padre:7,id:823,parametro:"Villa General Belgrano"},
{id_pais:1,id_provincia:14,id_padre:7,id:824,parametro:"Villa La Rivera"},
{id_pais:1,id_provincia:14,id_padre:7,id:825,parametro:"Villa Quillinzo"},
{id_pais:1,id_provincia:14,id_padre:7,id:826,parametro:"Villa Rumipal"},
{id_pais:1,id_provincia:14,id_padre:7,id:827,parametro:"Villa Yacanto"},
{id_pais:1,id_provincia:14,id_padre:14,id:828,parametro:"Córdoba"},
{id_pais:1,id_provincia:14,id_padre:21,id:829,parametro:"Agua de Oro"},
{id_pais:1,id_provincia:14,id_padre:21,id:830,parametro:"Ascochinga"},
{id_pais:1,id_provincia:14,id_padre:21,id:831,parametro:"Barrio Nuevo Río Ceballos"},
{id_pais:1,id_provincia:14,id_padre:21,id:832,parametro:"Canteras El Sauce"},
{id_pais:1,id_provincia:14,id_padre:21,id:833,parametro:"Casa Bamba"},
{id_pais:1,id_provincia:14,id_padre:21,id:834,parametro:"Colonia Caroya"},
{id_pais:1,id_provincia:14,id_padre:21,id:835,parametro:"Colonia Tirolesa"},
{id_pais:1,id_provincia:14,id_padre:21,id:836,parametro:"Colonia Vicente Agüero"},
{id_pais:1,id_provincia:14,id_padre:21,id:837,parametro:"Villa Corazón de María"},
{id_pais:1,id_provincia:14,id_padre:21,id:838,parametro:"Corral Quemado"},
{id_pais:1,id_provincia:14,id_padre:21,id:839,parametro:"Country San Isidro - Country Chacras de la Villa"},
{id_pais:1,id_provincia:14,id_padre:21,id:840,parametro:"El Manzano"},
{id_pais:1,id_provincia:14,id_padre:21,id:841,parametro:"Estación Colonia Tirolesa"},
{id_pais:1,id_provincia:14,id_padre:21,id:842,parametro:"General Paz"},
{id_pais:1,id_provincia:14,id_padre:21,id:843,parametro:"Jesús María"},
{id_pais:1,id_provincia:14,id_padre:21,id:844,parametro:"La Calera"},
{id_pais:1,id_provincia:14,id_padre:21,id:845,parametro:"La Granja"},
{id_pais:1,id_provincia:14,id_padre:21,id:846,parametro:"La Morada"},
{id_pais:1,id_provincia:14,id_padre:21,id:847,parametro:"La Puerta"},
{id_pais:1,id_provincia:14,id_padre:21,id:848,parametro:"Las Corzuelas"},
{id_pais:1,id_provincia:14,id_padre:21,id:849,parametro:"Los Molles"},
{id_pais:1,id_provincia:14,id_padre:21,id:850,parametro:"Malvinas Argentinas"},
{id_pais:1,id_provincia:14,id_padre:21,id:851,parametro:"Mendiolaza"},
{id_pais:1,id_provincia:14,id_padre:21,id:852,parametro:"Mi Granja"},
{id_pais:1,id_provincia:14,id_padre:21,id:853,parametro:"Pajas Blancas"},
{id_pais:1,id_provincia:14,id_padre:21,id:854,parametro:"Río Ceballos"},
{id_pais:1,id_provincia:14,id_padre:21,id:855,parametro:"Saldán"},
{id_pais:1,id_provincia:14,id_padre:21,id:856,parametro:"Salsipuedes"},
{id_pais:1,id_provincia:14,id_padre:21,id:857,parametro:"Santa Elena"},
{id_pais:1,id_provincia:14,id_padre:21,id:858,parametro:"Tinoco"},
{id_pais:1,id_provincia:14,id_padre:21,id:859,parametro:"Unquillo"},
{id_pais:1,id_provincia:14,id_padre:21,id:860,parametro:"Villa Allende"},
{id_pais:1,id_provincia:14,id_padre:21,id:861,parametro:"Villa Cerro Azul"},
{id_pais:1,id_provincia:14,id_padre:21,id:862,parametro:"Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N"},
{id_pais:1,id_provincia:14,id_padre:21,id:863,parametro:"Villa Los Llanos - Juárez Celman"},
{id_pais:1,id_provincia:14,id_padre:28,id:864,parametro:"Alto de los Quebrachos"},
{id_pais:1,id_provincia:14,id_padre:28,id:865,parametro:"Bañado de Soto"},
{id_pais:1,id_provincia:14,id_padre:28,id:866,parametro:"Canteras Quilpo"},
{id_pais:1,id_provincia:14,id_padre:28,id:867,parametro:"Cruz de Caña"},
{id_pais:1,id_provincia:14,id_padre:28,id:868,parametro:"Cruz del Eje"},
{id_pais:1,id_provincia:14,id_padre:28,id:869,parametro:"El Brete"},
{id_pais:1,id_provincia:14,id_padre:28,id:870,parametro:"El Rincón"},
{id_pais:1,id_provincia:14,id_padre:28,id:871,parametro:"Guanaco Muerto"},
{id_pais:1,id_provincia:14,id_padre:28,id:872,parametro:"La Banda"},
{id_pais:1,id_provincia:14,id_padre:28,id:873,parametro:"La Batea"},
{id_pais:1,id_provincia:14,id_padre:28,id:874,parametro:"La Higuera"},
{id_pais:1,id_provincia:14,id_padre:28,id:875,parametro:"Las Cañadas"},
{id_pais:1,id_provincia:14,id_padre:28,id:876,parametro:"Las Playas"},
{id_pais:1,id_provincia:14,id_padre:28,id:877,parametro:"Los Chañaritos"},
{id_pais:1,id_provincia:14,id_padre:28,id:878,parametro:"Media Naranja"},
{id_pais:1,id_provincia:14,id_padre:28,id:879,parametro:"Paso Viejo"},
{id_pais:1,id_provincia:14,id_padre:28,id:880,parametro:"San Marcos Sierra"},
{id_pais:1,id_provincia:14,id_padre:28,id:881,parametro:"Serrezuela"},
{id_pais:1,id_provincia:14,id_padre:28,id:882,parametro:"Tuclame"},
{id_pais:1,id_provincia:14,id_padre:28,id:883,parametro:"Villa de Soto"},
{id_pais:1,id_provincia:14,id_padre:35,id:884,parametro:"Del Campillo"},
{id_pais:1,id_provincia:14,id_padre:35,id:885,parametro:"Estación Lecueder"},
{id_pais:1,id_provincia:14,id_padre:35,id:886,parametro:"Hipólito Bouchard"},
{id_pais:1,id_provincia:14,id_padre:35,id:887,parametro:"Huinca Renancó"},
{id_pais:1,id_provincia:14,id_padre:35,id:888,parametro:"Italó"},
{id_pais:1,id_provincia:14,id_padre:35,id:889,parametro:"Mattaldi"},
{id_pais:1,id_provincia:14,id_padre:35,id:890,parametro:"Nicolás Bruzzone"},
{id_pais:1,id_provincia:14,id_padre:35,id:891,parametro:"Onagoity"},
{id_pais:1,id_provincia:14,id_padre:35,id:892,parametro:"Pincén"},
{id_pais:1,id_provincia:14,id_padre:35,id:893,parametro:"Ranqueles"},
{id_pais:1,id_provincia:14,id_padre:35,id:894,parametro:"Santa Magdalena"},
{id_pais:1,id_provincia:14,id_padre:35,id:895,parametro:"Villa Huidobro"},
{id_pais:1,id_provincia:14,id_padre:35,id:896,parametro:"Villa Sarmiento"},
{id_pais:1,id_provincia:14,id_padre:35,id:897,parametro:"Villa Valeria"},
{id_pais:1,id_provincia:14,id_padre:42,id:898,parametro:"Arroyo Algodón"},
{id_pais:1,id_provincia:14,id_padre:42,id:899,parametro:"Arroyo Cabral"},
{id_pais:1,id_provincia:14,id_padre:42,id:900,parametro:"Ausonia"},
{id_pais:1,id_provincia:14,id_padre:42,id:901,parametro:"Chazón"},
{id_pais:1,id_provincia:14,id_padre:42,id:902,parametro:"Etruria"},
{id_pais:1,id_provincia:14,id_padre:42,id:903,parametro:"La Laguna"},
{id_pais:1,id_provincia:14,id_padre:42,id:904,parametro:"La Palestina"},
{id_pais:1,id_provincia:14,id_padre:42,id:905,parametro:"La Playosa"},
{id_pais:1,id_provincia:14,id_padre:42,id:906,parametro:"Las Mojarras"},
{id_pais:1,id_provincia:14,id_padre:42,id:907,parametro:"Luca"},
{id_pais:1,id_provincia:14,id_padre:42,id:908,parametro:"Pasco"},
{id_pais:1,id_provincia:14,id_padre:42,id:909,parametro:"Sanabria"},
{id_pais:1,id_provincia:14,id_padre:42,id:910,parametro:"Silvio Pellico"},
{id_pais:1,id_provincia:14,id_padre:42,id:911,parametro:"Ticino"},
{id_pais:1,id_provincia:14,id_padre:42,id:912,parametro:"Tío Pujio"},
{id_pais:1,id_provincia:14,id_padre:42,id:913,parametro:"Villa Albertina"},
{id_pais:1,id_provincia:14,id_padre:42,id:914,parametro:"Villa María"},
{id_pais:1,id_provincia:14,id_padre:42,id:915,parametro:"Villa Nueva"},
{id_pais:1,id_provincia:14,id_padre:42,id:916,parametro:"Villa Oeste"},
{id_pais:1,id_provincia:14,id_padre:49,id:917,parametro:"Avellaneda"},
{id_pais:1,id_provincia:14,id_padre:49,id:918,parametro:"Cañada de Río Pinto"},
{id_pais:1,id_provincia:14,id_padre:49,id:919,parametro:"Chuña"},
{id_pais:1,id_provincia:14,id_padre:49,id:920,parametro:"Copacabana"},
{id_pais:1,id_provincia:14,id_padre:49,id:921,parametro:"Deán Funes"},
{id_pais:1,id_provincia:14,id_padre:49,id:922,parametro:"Esquina del Alambre"},
{id_pais:1,id_provincia:14,id_padre:49,id:923,parametro:"Los Pozos"},
{id_pais:1,id_provincia:14,id_padre:49,id:924,parametro:"Olivares de San Nicolás"},
{id_pais:1,id_provincia:14,id_padre:49,id:925,parametro:"Quilino"},
{id_pais:1,id_provincia:14,id_padre:49,id:926,parametro:"San Pedro de Toyos"},
{id_pais:1,id_provincia:14,id_padre:49,id:927,parametro:"Villa Gutiérrez"},
{id_pais:1,id_provincia:14,id_padre:49,id:928,parametro:"Villa Quilino"},
{id_pais:1,id_provincia:14,id_padre:56,id:929,parametro:"Alejandro Roca"},
{id_pais:1,id_provincia:14,id_padre:56,id:930,parametro:"Assunta"},
{id_pais:1,id_provincia:14,id_padre:56,id:931,parametro:"Bengolea"},
{id_pais:1,id_provincia:14,id_padre:56,id:932,parametro:"Carnerillo"},
{id_pais:1,id_provincia:14,id_padre:56,id:933,parametro:"Charras"},
{id_pais:1,id_provincia:14,id_padre:56,id:934,parametro:"El Rastreador"},
{id_pais:1,id_provincia:14,id_padre:56,id:935,parametro:"General Cabrera"},
{id_pais:1,id_provincia:14,id_padre:56,id:936,parametro:"General Deheza"},
{id_pais:1,id_provincia:14,id_padre:56,id:937,parametro:"Huanchillas"},
{id_pais:1,id_provincia:14,id_padre:56,id:938,parametro:"La Carlota"},
{id_pais:1,id_provincia:14,id_padre:56,id:939,parametro:"Los Cisnes"},
{id_pais:1,id_provincia:14,id_padre:56,id:940,parametro:"Olaeta"},
{id_pais:1,id_provincia:14,id_padre:56,id:941,parametro:"Pacheco de Melo"},
{id_pais:1,id_provincia:14,id_padre:56,id:942,parametro:"Paso del Durazno"},
{id_pais:1,id_provincia:14,id_padre:56,id:943,parametro:"Santa Eufemia"},
{id_pais:1,id_provincia:14,id_padre:56,id:944,parametro:"Ucacha"},
{id_pais:1,id_provincia:14,id_padre:56,id:945,parametro:"Villa Reducción"},
{id_pais:1,id_provincia:14,id_padre:63,id:946,parametro:"Alejo Ledesma"},
{id_pais:1,id_provincia:14,id_padre:63,id:947,parametro:"Arias"},
{id_pais:1,id_provincia:14,id_padre:63,id:948,parametro:"Camilo Aldao"},
{id_pais:1,id_provincia:14,id_padre:63,id:949,parametro:"Capitán General Bernardo O'Higgins"},
{id_pais:1,id_provincia:14,id_padre:63,id:950,parametro:"Cavanagh"},
{id_pais:1,id_provincia:14,id_padre:63,id:951,parametro:"Colonia Barge"},
{id_pais:1,id_provincia:14,id_padre:63,id:952,parametro:"Colonia Italiana"},
{id_pais:1,id_provincia:14,id_padre:63,id:953,parametro:"Colonia Veinticinco"},
{id_pais:1,id_provincia:14,id_padre:63,id:954,parametro:"Corral de Bustos"},
{id_pais:1,id_provincia:14,id_padre:63,id:955,parametro:"Cruz Alta"},
{id_pais:1,id_provincia:14,id_padre:63,id:956,parametro:"General Baldissera"},
{id_pais:1,id_provincia:14,id_padre:63,id:957,parametro:"General Roca"},
{id_pais:1,id_provincia:14,id_padre:63,id:958,parametro:"Guatimozín"},
{id_pais:1,id_provincia:14,id_padre:63,id:959,parametro:"Inriville"},
{id_pais:1,id_provincia:14,id_padre:63,id:960,parametro:"Isla Verde"},
{id_pais:1,id_provincia:14,id_padre:63,id:961,parametro:"Leones"},
{id_pais:1,id_provincia:14,id_padre:63,id:962,parametro:"Los Surgentes"},
{id_pais:1,id_provincia:14,id_padre:63,id:963,parametro:"Marcos Juárez"},
{id_pais:1,id_provincia:14,id_padre:63,id:964,parametro:"Monte Buey"},
{id_pais:1,id_provincia:14,id_padre:63,id:965,parametro:"Saira"},
{id_pais:1,id_provincia:14,id_padre:63,id:966,parametro:"Saladillo"},
{id_pais:1,id_provincia:14,id_padre:63,id:967,parametro:"Villa Elisa"},
{id_pais:1,id_provincia:14,id_padre:70,id:968,parametro:"Ciénaga del Coro"},
{id_pais:1,id_provincia:14,id_padre:70,id:969,parametro:"El Chacho"},
{id_pais:1,id_provincia:14,id_padre:70,id:970,parametro:"Estancia de Guadalupe"},
{id_pais:1,id_provincia:14,id_padre:70,id:971,parametro:"Guasapampa"},
{id_pais:1,id_provincia:14,id_padre:70,id:972,parametro:"La Playa"},
{id_pais:1,id_provincia:14,id_padre:70,id:973,parametro:"San Carlos Minas"},
{id_pais:1,id_provincia:14,id_padre:70,id:974,parametro:"Talaini"},
{id_pais:1,id_provincia:14,id_padre:70,id:975,parametro:"Tosno"},
{id_pais:1,id_provincia:14,id_padre:77,id:976,parametro:"Chancani"},
{id_pais:1,id_provincia:14,id_padre:77,id:977,parametro:"Las Palmas"},
{id_pais:1,id_provincia:14,id_padre:77,id:978,parametro:"Los Talares"},
{id_pais:1,id_provincia:14,id_padre:77,id:979,parametro:"Salsacate"},
{id_pais:1,id_provincia:14,id_padre:77,id:980,parametro:"San Gerónimo"},
{id_pais:1,id_provincia:14,id_padre:77,id:981,parametro:"Tala Cañada"},
{id_pais:1,id_provincia:14,id_padre:77,id:982,parametro:"Taninga"},
{id_pais:1,id_provincia:14,id_padre:77,id:983,parametro:"Villa de Pocho"},
{id_pais:1,id_provincia:14,id_padre:84,id:984,parametro:"General Levalle"},
{id_pais:1,id_provincia:14,id_padre:84,id:985,parametro:"La Cesira"},
{id_pais:1,id_provincia:14,id_padre:84,id:986,parametro:"Laboulaye"},
{id_pais:1,id_provincia:14,id_padre:84,id:987,parametro:"Leguizamón"},
{id_pais:1,id_provincia:14,id_padre:84,id:988,parametro:"Melo"},
{id_pais:1,id_provincia:14,id_padre:84,id:989,parametro:"Río Bamba"},
{id_pais:1,id_provincia:14,id_padre:84,id:990,parametro:"Rosales"},
{id_pais:1,id_provincia:14,id_padre:84,id:991,parametro:"San Joaquín"},
{id_pais:1,id_provincia:14,id_padre:84,id:992,parametro:"Serrano"},
{id_pais:1,id_provincia:14,id_padre:84,id:993,parametro:"Villa Rossi"},
{id_pais:1,id_provincia:14,id_padre:91,id:994,parametro:"Barrio Santa Isabel"},
{id_pais:1,id_provincia:14,id_padre:91,id:995,parametro:"Cabalango"},
{id_pais:1,id_provincia:14,id_padre:91,id:996,parametro:"Capilla del Monte"},
{id_pais:1,id_provincia:14,id_padre:91,id:997,parametro:"Casa Grande"},
{id_pais:1,id_provincia:14,id_padre:91,id:998,parametro:"Charbonier"},
{id_pais:1,id_provincia:14,id_padre:91,id:999,parametro:"Cosquín"},
{id_pais:1,id_provincia:14,id_padre:91,id:1000,parametro:"Cuesta Blanca"},
{id_pais:1,id_provincia:14,id_padre:91,id:1001,parametro:"Estancia Vieja"},
{id_pais:1,id_provincia:14,id_padre:91,id:1002,parametro:"Huerta Grande"},
{id_pais:1,id_provincia:14,id_padre:91,id:1003,parametro:"La Cumbre"},
{id_pais:1,id_provincia:14,id_padre:91,id:1004,parametro:"La Falda"},
{id_pais:1,id_provincia:14,id_padre:91,id:1005,parametro:"Las Jarillas"},
{id_pais:1,id_provincia:14,id_padre:91,id:1006,parametro:"Los Cocos"},
{id_pais:1,id_provincia:14,id_padre:91,id:1007,parametro:"Mallín"},
{id_pais:1,id_provincia:14,id_padre:91,id:1008,parametro:"Mayu Sumaj"},
{id_pais:1,id_provincia:14,id_padre:91,id:1009,parametro:"Quebrada de Luna"},
{id_pais:1,id_provincia:14,id_padre:91,id:1010,parametro:"San Antonio de Arredondo"},
{id_pais:1,id_provincia:14,id_padre:91,id:1011,parametro:"San Esteban"},
{id_pais:1,id_provincia:14,id_padre:91,id:1012,parametro:"San Roque"},
{id_pais:1,id_provincia:14,id_padre:91,id:1013,parametro:"Santa María de Punilla"},
{id_pais:1,id_provincia:14,id_padre:91,id:1014,parametro:"Tala Huasi"},
{id_pais:1,id_provincia:14,id_padre:91,id:1015,parametro:"Tanti"},
{id_pais:1,id_provincia:14,id_padre:91,id:1016,parametro:"Valle Hermoso"},
{id_pais:1,id_provincia:14,id_padre:91,id:1017,parametro:"Villa Carlos Paz"},
{id_pais:1,id_provincia:14,id_padre:91,id:1018,parametro:"Villa Flor Serrana"},
{id_pais:1,id_provincia:14,id_padre:91,id:1019,parametro:"Villa Giardino"},
{id_pais:1,id_provincia:14,id_padre:91,id:1020,parametro:"Villa Lago Azul"},
{id_pais:1,id_provincia:14,id_padre:91,id:1021,parametro:"Villa Parque Siquimán"},
{id_pais:1,id_provincia:14,id_padre:91,id:1022,parametro:"Villa Río Icho Cruz"},
{id_pais:1,id_provincia:14,id_padre:91,id:1023,parametro:"Villa San José"},
{id_pais:1,id_provincia:14,id_padre:91,id:1024,parametro:"Villa Santa Cruz del Lago"},
{id_pais:1,id_provincia:14,id_padre:98,id:1025,parametro:"Achiras"},
{id_pais:1,id_provincia:14,id_padre:98,id:1026,parametro:"Adelia María"},
{id_pais:1,id_provincia:14,id_padre:98,id:1027,parametro:"Alcira Gigena"},
{id_pais:1,id_provincia:14,id_padre:98,id:1028,parametro:"Alpa Corral"},
{id_pais:1,id_provincia:14,id_padre:98,id:1029,parametro:"Berrotarán"},
{id_pais:1,id_provincia:14,id_padre:98,id:1030,parametro:"Bulnes"},
{id_pais:1,id_provincia:14,id_padre:98,id:1031,parametro:"Chaján"},
{id_pais:1,id_provincia:14,id_padre:98,id:1032,parametro:"Chucul"},
{id_pais:1,id_provincia:14,id_padre:98,id:1033,parametro:"Coronel Baigorria"},
{id_pais:1,id_provincia:14,id_padre:98,id:1034,parametro:"Coronel Moldes"},
{id_pais:1,id_provincia:14,id_padre:98,id:1035,parametro:"Elena"},
{id_pais:1,id_provincia:14,id_padre:98,id:1036,parametro:"La Carolina"},
{id_pais:1,id_provincia:14,id_padre:98,id:1037,parametro:"La Cautiva"},
{id_pais:1,id_provincia:14,id_padre:98,id:1038,parametro:"La Gilda"},
{id_pais:1,id_provincia:14,id_padre:98,id:1039,parametro:"Las Acequias"},
{id_pais:1,id_provincia:14,id_padre:98,id:1040,parametro:"Las Albahacas"},
{id_pais:1,id_provincia:14,id_padre:98,id:1041,parametro:"Las Higueras"},
{id_pais:1,id_provincia:14,id_padre:98,id:1042,parametro:"Las Peñas"},
{id_pais:1,id_provincia:14,id_padre:98,id:1043,parametro:"Las Vertientes"},
{id_pais:1,id_provincia:14,id_padre:98,id:1044,parametro:"Malena"},
{id_pais:1,id_provincia:14,id_padre:98,id:1045,parametro:"Monte de los Gauchos"},
{id_pais:1,id_provincia:14,id_padre:98,id:1046,parametro:"Paso del Durazno"},
{id_pais:1,id_provincia:14,id_padre:98,id:1047,parametro:"Río Cuarto"},
{id_pais:1,id_provincia:14,id_padre:98,id:1048,parametro:"Sampacho"},
{id_pais:1,id_provincia:14,id_padre:98,id:1049,parametro:"San Basilio"},
{id_pais:1,id_provincia:14,id_padre:98,id:1050,parametro:"Santa Catalina Holmberg"},
{id_pais:1,id_provincia:14,id_padre:98,id:1051,parametro:"Suco"},
{id_pais:1,id_provincia:14,id_padre:98,id:1052,parametro:"Tosquitas"},
{id_pais:1,id_provincia:14,id_padre:98,id:1053,parametro:"Vicuña Mackenna"},
{id_pais:1,id_provincia:14,id_padre:98,id:1054,parametro:"Villa El Chacay"},
{id_pais:1,id_provincia:14,id_padre:98,id:1055,parametro:"Villa Santa Eugenia"},
{id_pais:1,id_provincia:14,id_padre:98,id:1056,parametro:"Washington"},
{id_pais:1,id_provincia:14,id_padre:105,id:1057,parametro:"Atahona"},
{id_pais:1,id_provincia:14,id_padre:105,id:1058,parametro:"Cañada de Machado"},
{id_pais:1,id_provincia:14,id_padre:105,id:1059,parametro:"Capilla de los Remedios"},
{id_pais:1,id_provincia:14,id_padre:105,id:1060,parametro:"Chalacea"},
{id_pais:1,id_provincia:14,id_padre:105,id:1061,parametro:"Colonia Las Cuatro Esquinas"},
{id_pais:1,id_provincia:14,id_padre:105,id:1062,parametro:"Diego de Rojas"},
{id_pais:1,id_provincia:14,id_padre:105,id:1063,parametro:"El Alcalde"},
{id_pais:1,id_provincia:14,id_padre:105,id:1064,parametro:"El Crispín"},
{id_pais:1,id_provincia:14,id_padre:105,id:1065,parametro:"Esquina"},
{id_pais:1,id_provincia:14,id_padre:105,id:1066,parametro:"Kilómetro 658"},
{id_pais:1,id_provincia:14,id_padre:105,id:1067,parametro:"La Para"},
{id_pais:1,id_provincia:14,id_padre:105,id:1068,parametro:"La Posta"},
{id_pais:1,id_provincia:14,id_padre:105,id:1069,parametro:"La Puerta"},
{id_pais:1,id_provincia:14,id_padre:105,id:1070,parametro:"La Quinta"},
{id_pais:1,id_provincia:14,id_padre:105,id:1071,parametro:"Las Gramillas"},
{id_pais:1,id_provincia:14,id_padre:105,id:1072,parametro:"Las Saladas"},
{id_pais:1,id_provincia:14,id_padre:105,id:1073,parametro:"Maquinista Gallini"},
{id_pais:1,id_provincia:14,id_padre:105,id:1074,parametro:"Monte del Rosario"},
{id_pais:1,id_provincia:14,id_padre:105,id:1075,parametro:"Montecristo"},
{id_pais:1,id_provincia:14,id_padre:105,id:1076,parametro:"Obispo Trejo"},
{id_pais:1,id_provincia:14,id_padre:105,id:1077,parametro:"Piquillín"},
{id_pais:1,id_provincia:14,id_padre:105,id:1078,parametro:"Plaza de Mercedes"},
{id_pais:1,id_provincia:14,id_padre:105,id:1079,parametro:"Pueblo Comechingones"},
{id_pais:1,id_provincia:14,id_padre:105,id:1080,parametro:"Río Primero"},
{id_pais:1,id_provincia:14,id_padre:105,id:1081,parametro:"Sagrada Familia"},
{id_pais:1,id_provincia:14,id_padre:105,id:1082,parametro:"Santa Rosa de Río Primero"},
{id_pais:1,id_provincia:14,id_padre:105,id:1083,parametro:"Villa Fontana"},
{id_pais:1,id_provincia:14,id_padre:112,id:1084,parametro:"Cerro Colorado"},
{id_pais:1,id_provincia:14,id_padre:112,id:1085,parametro:"Chañar Viejo"},
{id_pais:1,id_provincia:14,id_padre:112,id:1086,parametro:"Eufrasio Loza"},
{id_pais:1,id_provincia:14,id_padre:112,id:1087,parametro:"Gütemberg"},
{id_pais:1,id_provincia:14,id_padre:112,id:1088,parametro:"La Rinconada"},
{id_pais:1,id_provincia:14,id_padre:112,id:1089,parametro:"Los Hoyos"},
{id_pais:1,id_provincia:14,id_padre:112,id:1090,parametro:"Puesto de Castro"},
{id_pais:1,id_provincia:14,id_padre:112,id:1091,parametro:"Rayo Cortado"},
{id_pais:1,id_provincia:14,id_padre:112,id:1092,parametro:"San Pedro de Gütemberg"},
{id_pais:1,id_provincia:14,id_padre:112,id:1093,parametro:"Santa Elena"},
{id_pais:1,id_provincia:14,id_padre:112,id:1094,parametro:"Sebastián Elcano"},
{id_pais:1,id_provincia:14,id_padre:112,id:1095,parametro:"Villa Candelaria"},
{id_pais:1,id_provincia:14,id_padre:112,id:1096,parametro:"Villa de María"},
{id_pais:1,id_provincia:14,id_padre:119,id:1097,parametro:"Calchín"},
{id_pais:1,id_provincia:14,id_padre:119,id:1098,parametro:"Calchín Oeste"},
{id_pais:1,id_provincia:14,id_padre:119,id:1099,parametro:"Capilla del Carmen"},
{id_pais:1,id_provincia:14,id_padre:119,id:1100,parametro:"Carrilobo"},
{id_pais:1,id_provincia:14,id_padre:119,id:1101,parametro:"Colazo"},
{id_pais:1,id_provincia:14,id_padre:119,id:1102,parametro:"Colonia Videla"},
{id_pais:1,id_provincia:14,id_padre:119,id:1103,parametro:"Costasacate"},
{id_pais:1,id_provincia:14,id_padre:119,id:1104,parametro:"Impira"},
{id_pais:1,id_provincia:14,id_padre:119,id:1105,parametro:"Laguna Larga"},
{id_pais:1,id_provincia:14,id_padre:119,id:1106,parametro:"Las Junturas"},
{id_pais:1,id_provincia:14,id_padre:119,id:1107,parametro:"Los Chañaritos"},
{id_pais:1,id_provincia:14,id_padre:119,id:1108,parametro:"Luque"},
{id_pais:1,id_provincia:14,id_padre:119,id:1109,parametro:"Manfredi"},
{id_pais:1,id_provincia:14,id_padre:119,id:1110,parametro:"Matorrales"},
{id_pais:1,id_provincia:14,id_padre:119,id:1111,parametro:"Oncativo"},
{id_pais:1,id_provincia:14,id_padre:119,id:1112,parametro:"Pilar"},
{id_pais:1,id_provincia:14,id_padre:119,id:1113,parametro:"Pozo del Molle"},
{id_pais:1,id_provincia:14,id_padre:119,id:1114,parametro:"Rincón"},
{id_pais:1,id_provincia:14,id_padre:119,id:1115,parametro:"Río Segundo"},
{id_pais:1,id_provincia:14,id_padre:119,id:1116,parametro:"Santiago Temple"},
{id_pais:1,id_provincia:14,id_padre:119,id:1117,parametro:"Villa del Rosario"},
{id_pais:1,id_provincia:14,id_padre:126,id:1118,parametro:"Ambul"},
{id_pais:1,id_provincia:14,id_padre:126,id:1119,parametro:"Arroyo Los Patos"},
{id_pais:1,id_provincia:14,id_padre:126,id:1120,parametro:"El Huayco"},
{id_pais:1,id_provincia:14,id_padre:126,id:1121,parametro:"La Cortadera"},
{id_pais:1,id_provincia:14,id_padre:126,id:1122,parametro:"Las Calles"},
{id_pais:1,id_provincia:14,id_padre:126,id:1123,parametro:"Las Oscuras"},
{id_pais:1,id_provincia:14,id_padre:126,id:1124,parametro:"Las Rabonas"},
{id_pais:1,id_provincia:14,id_padre:126,id:1125,parametro:"Los Callejones"},
{id_pais:1,id_provincia:14,id_padre:126,id:1126,parametro:"Mina Clavero"},
{id_pais:1,id_provincia:14,id_padre:126,id:1127,parametro:"Mussi"},
{id_pais:1,id_provincia:14,id_padre:126,id:1128,parametro:"Nono"},
{id_pais:1,id_provincia:14,id_padre:126,id:1129,parametro:"Panaholma"},
{id_pais:1,id_provincia:14,id_padre:126,id:1130,parametro:"San Huberto"},
{id_pais:1,id_provincia:14,id_padre:126,id:1131,parametro:"San Lorenzo"},
{id_pais:1,id_provincia:14,id_padre:126,id:1132,parametro:"San Martín"},
{id_pais:1,id_provincia:14,id_padre:126,id:1133,parametro:"San Pedro"},
{id_pais:1,id_provincia:14,id_padre:126,id:1134,parametro:"San Vicente"},
{id_pais:1,id_provincia:14,id_padre:126,id:1135,parametro:"Sauce Arriba"},
{id_pais:1,id_provincia:14,id_padre:126,id:1136,parametro:"Tasna"},
{id_pais:1,id_provincia:14,id_padre:126,id:1137,parametro:"Villa Cura Brochero"},
{id_pais:1,id_provincia:14,id_padre:126,id:1138,parametro:"Villa Sarmiento"},
{id_pais:1,id_provincia:14,id_padre:133,id:1139,parametro:"Conlara"},
{id_pais:1,id_provincia:14,id_padre:133,id:1140,parametro:"Cruz Caña"},
{id_pais:1,id_provincia:14,id_padre:133,id:1141,parametro:"Dos Arroyos"},
{id_pais:1,id_provincia:14,id_padre:133,id:1142,parametro:"El Pantanillo"},
{id_pais:1,id_provincia:14,id_padre:133,id:1143,parametro:"La Paz"},
{id_pais:1,id_provincia:14,id_padre:133,id:1144,parametro:"La Población"},
{id_pais:1,id_provincia:14,id_padre:133,id:1145,parametro:"La Ramada"},
{id_pais:1,id_provincia:14,id_padre:133,id:1146,parametro:"La Travesía"},
{id_pais:1,id_provincia:14,id_padre:133,id:1147,parametro:"Las Chacras"},
{id_pais:1,id_provincia:14,id_padre:133,id:1148,parametro:"Las Tapias"},
{id_pais:1,id_provincia:14,id_padre:133,id:1149,parametro:"Loma Bola"},
{id_pais:1,id_provincia:14,id_padre:133,id:1150,parametro:"Los Cerrillos"},
{id_pais:1,id_provincia:14,id_padre:133,id:1151,parametro:"Los Hornillos"},
{id_pais:1,id_provincia:14,id_padre:133,id:1152,parametro:"Los Molles"},
{id_pais:1,id_provincia:14,id_padre:133,id:1153,parametro:"Luyaba"},
{id_pais:1,id_provincia:14,id_padre:133,id:1154,parametro:"Quebracho Ladeado"},
{id_pais:1,id_provincia:14,id_padre:133,id:1155,parametro:"Quebrada de los Pozos"},
{id_pais:1,id_provincia:14,id_padre:133,id:1156,parametro:"San Javier y Yacanto"},
{id_pais:1,id_provincia:14,id_padre:133,id:1157,parametro:"San José"},
{id_pais:1,id_provincia:14,id_padre:133,id:1158,parametro:"Villa de las Rosas"},
{id_pais:1,id_provincia:14,id_padre:133,id:1159,parametro:"Villa Dolores"},
{id_pais:1,id_provincia:14,id_padre:133,id:1160,parametro:"Villa La Viña"},
{id_pais:1,id_provincia:14,id_padre:140,id:1161,parametro:"Alicia"},
{id_pais:1,id_provincia:14,id_padre:140,id:1162,parametro:"Altos de Chipión"},
{id_pais:1,id_provincia:14,id_padre:140,id:1163,parametro:"Arroyito"},
{id_pais:1,id_provincia:14,id_padre:140,id:1164,parametro:"Balnearia"},
{id_pais:1,id_provincia:14,id_padre:140,id:1165,parametro:"Brinkmann"},
{id_pais:1,id_provincia:14,id_padre:140,id:1166,parametro:"Colonia Anita"},
{id_pais:1,id_provincia:14,id_padre:140,id:1167,parametro:"Colonia 10 de Julio"},
{id_pais:1,id_provincia:14,id_padre:140,id:1168,parametro:"Colonia Las Pichanas"},
{id_pais:1,id_provincia:14,id_padre:140,id:1169,parametro:"Colonia Marina"},
{id_pais:1,id_provincia:14,id_padre:140,id:1170,parametro:"Colonia Prosperidad"},
{id_pais:1,id_provincia:14,id_padre:140,id:1171,parametro:"Colonia San Bartolomé"},
{id_pais:1,id_provincia:14,id_padre:140,id:1172,parametro:"Colonia San Pedro"},
{id_pais:1,id_provincia:14,id_padre:140,id:1173,parametro:"Colonia Santa María"},
{id_pais:1,id_provincia:14,id_padre:140,id:1174,parametro:"Colonia Valtelina"},
{id_pais:1,id_provincia:14,id_padre:140,id:1175,parametro:"Colonia Vignaud"},
{id_pais:1,id_provincia:14,id_padre:140,id:1176,parametro:"Devoto"},
{id_pais:1,id_provincia:14,id_padre:140,id:1177,parametro:"El Arañado"},
{id_pais:1,id_provincia:14,id_padre:140,id:1178,parametro:"El Fortín"},
{id_pais:1,id_provincia:14,id_padre:140,id:1179,parametro:"El Fuertecito"},
{id_pais:1,id_provincia:14,id_padre:140,id:1180,parametro:"El Tío"},
{id_pais:1,id_provincia:14,id_padre:140,id:1181,parametro:"Estación Luxardo"},
{id_pais:1,id_provincia:14,id_padre:140,id:1182,parametro:"Colonia Iturraspe"},
{id_pais:1,id_provincia:14,id_padre:140,id:1183,parametro:"Freyre"},
{id_pais:1,id_provincia:14,id_padre:140,id:1184,parametro:"La Francia"},
{id_pais:1,id_provincia:14,id_padre:140,id:1185,parametro:"La Paquita"},
{id_pais:1,id_provincia:14,id_padre:140,id:1186,parametro:"La Tordilla"},
{id_pais:1,id_provincia:14,id_padre:140,id:1187,parametro:"Las Varas"},
{id_pais:1,id_provincia:14,id_padre:140,id:1188,parametro:"Las Varillas"},
{id_pais:1,id_provincia:14,id_padre:140,id:1189,parametro:"Marull"},
{id_pais:1,id_provincia:14,id_padre:140,id:1190,parametro:"Miramar"},
{id_pais:1,id_provincia:14,id_padre:140,id:1191,parametro:"Morteros"},
{id_pais:1,id_provincia:14,id_padre:140,id:1192,parametro:"Plaza Luxardo"},
{id_pais:1,id_provincia:14,id_padre:140,id:1193,parametro:"Plaza San Francisco"},
{id_pais:1,id_provincia:14,id_padre:140,id:1194,parametro:"Porteña"},
{id_pais:1,id_provincia:14,id_padre:140,id:1195,parametro:"Quebracho Herrado"},
{id_pais:1,id_provincia:14,id_padre:140,id:1196,parametro:"Sacanta"},
{id_pais:1,id_provincia:14,id_padre:140,id:1197,parametro:"San Francisco"},
{id_pais:1,id_provincia:14,id_padre:140,id:1198,parametro:"Saturnino María Laspiur"},
{id_pais:1,id_provincia:14,id_padre:140,id:1199,parametro:"Seeber"},
{id_pais:1,id_provincia:14,id_padre:140,id:1200,parametro:"Toro Pujio"},
{id_pais:1,id_provincia:14,id_padre:140,id:1201,parametro:"Tránsito"},
{id_pais:1,id_provincia:14,id_padre:140,id:1202,parametro:"Villa Concepción del Tío"},
{id_pais:1,id_provincia:14,id_padre:140,id:1203,parametro:"Villa del Tránsito"},
{id_pais:1,id_provincia:14,id_padre:140,id:1204,parametro:"Villa San Esteban"},
{id_pais:1,id_provincia:14,id_padre:147,id:1205,parametro:"Alta Gracia"},
{id_pais:1,id_provincia:14,id_padre:147,id:1206,parametro:"Anisacate"},
{id_pais:1,id_provincia:14,id_padre:147,id:1207,parametro:"Barrio Gilbert (1° de Mayo) - Tejas Tres"},
{id_pais:1,id_provincia:14,id_padre:147,id:1208,parametro:"Bouwer"},
{id_pais:1,id_provincia:14,id_padre:147,id:1209,parametro:"Campos del Virrey"},
{id_pais:1,id_provincia:14,id_padre:147,id:1210,parametro:"Caseros Centro"},
{id_pais:1,id_provincia:14,id_padre:147,id:1211,parametro:"Causana"},
{id_pais:1,id_provincia:14,id_padre:147,id:1212,parametro:"Costa Azul"},
{id_pais:1,id_provincia:14,id_padre:147,id:1213,parametro:"Despeñaderos"},
{id_pais:1,id_provincia:14,id_padre:147,id:1214,parametro:"Dique Chico"},
{id_pais:1,id_provincia:14,id_padre:147,id:1215,parametro:"El Potrerillo"},
{id_pais:1,id_provincia:14,id_padre:147,id:1216,parametro:"Falda del Cañete"},
{id_pais:1,id_provincia:14,id_padre:147,id:1217,parametro:"Falda del Carmen"},
{id_pais:1,id_provincia:14,id_padre:147,id:1218,parametro:"José de la Quintana"},
{id_pais:1,id_provincia:14,id_padre:147,id:1219,parametro:"La Boca del Río"},
{id_pais:1,id_provincia:14,id_padre:147,id:1220,parametro:"La Carbonada"},
{id_pais:1,id_provincia:14,id_padre:147,id:1221,parametro:"La Paisanita"},
{id_pais:1,id_provincia:14,id_padre:147,id:1222,parametro:"La Perla"},
{id_pais:1,id_provincia:14,id_padre:147,id:1223,parametro:"La Rancherita y Las Cascadas"},
{id_pais:1,id_provincia:14,id_padre:147,id:1224,parametro:"La Serranita"},
{id_pais:1,id_provincia:14,id_padre:147,id:1225,parametro:"Los Cedros"},
{id_pais:1,id_provincia:14,id_padre:147,id:1226,parametro:"Lozada"},
{id_pais:1,id_provincia:14,id_padre:147,id:1227,parametro:"Malagueño"},
{id_pais:1,id_provincia:14,id_padre:147,id:1228,parametro:"Milenica"},
{id_pais:1,id_provincia:14,id_padre:147,id:1229,parametro:"Monte Ralo"},
{id_pais:1,id_provincia:14,id_padre:147,id:1230,parametro:"Potrero de Garay"},
{id_pais:1,id_provincia:14,id_padre:147,id:1231,parametro:"Rafael García"},
{id_pais:1,id_provincia:14,id_padre:147,id:1232,parametro:"San Clemente"},
{id_pais:1,id_provincia:14,id_padre:147,id:1233,parametro:"San Nicolás - Tierra Alta"},
{id_pais:1,id_provincia:14,id_padre:147,id:1234,parametro:"Socavones"},
{id_pais:1,id_provincia:14,id_padre:147,id:1235,parametro:"Toledo"},
{id_pais:1,id_provincia:14,id_padre:147,id:1236,parametro:"Valle Alegre"},
{id_pais:1,id_provincia:14,id_padre:147,id:1237,parametro:"Valle de Anisacate"},
{id_pais:1,id_provincia:14,id_padre:147,id:1238,parametro:"Villa Ciudad de América"},
{id_pais:1,id_provincia:14,id_padre:147,id:1239,parametro:"Villa del Prado"},
{id_pais:1,id_provincia:14,id_padre:147,id:1240,parametro:"Villa La Bolsa"},
{id_pais:1,id_provincia:14,id_padre:147,id:1241,parametro:"Villa Los Aromos"},
{id_pais:1,id_provincia:14,id_padre:147,id:1242,parametro:"Villa Parque Santa Ana"},
{id_pais:1,id_provincia:14,id_padre:147,id:1243,parametro:"Villa San Isidro"},
{id_pais:1,id_provincia:14,id_padre:147,id:1244,parametro:"Villa Sierras De Oro"},
{id_pais:1,id_provincia:14,id_padre:147,id:1245,parametro:"Yocsina"},
{id_pais:1,id_provincia:14,id_padre:154,id:1246,parametro:"Caminiaga"},
{id_pais:1,id_provincia:14,id_padre:154,id:1247,parametro:"Chuña Huasi"},
{id_pais:1,id_provincia:14,id_padre:154,id:1248,parametro:"Pozo Nuevo"},
{id_pais:1,id_provincia:14,id_padre:154,id:1249,parametro:"San Francisco del Chañar"},
{id_pais:1,id_provincia:14,id_padre:161,id:1250,parametro:"Almafuerte"},
{id_pais:1,id_provincia:14,id_padre:161,id:1251,parametro:"Colonia Almada"},
{id_pais:1,id_provincia:14,id_padre:161,id:1252,parametro:"Corralito"},
{id_pais:1,id_provincia:14,id_padre:161,id:1253,parametro:"Dalmacio Vélez"},
{id_pais:1,id_provincia:14,id_padre:161,id:1254,parametro:"General Fotheringham"},
{id_pais:1,id_provincia:14,id_padre:161,id:1255,parametro:"Hernando"},
{id_pais:1,id_provincia:14,id_padre:161,id:1256,parametro:"James Craik"},
{id_pais:1,id_provincia:14,id_padre:161,id:1257,parametro:"Las Isletillas"},
{id_pais:1,id_provincia:14,id_padre:161,id:1258,parametro:"Las Perdices"},
{id_pais:1,id_provincia:14,id_padre:161,id:1259,parametro:"Los Zorros"},
{id_pais:1,id_provincia:14,id_padre:161,id:1260,parametro:"Oliva"},
{id_pais:1,id_provincia:14,id_padre:161,id:1261,parametro:"Pampayasta Norte"},
{id_pais:1,id_provincia:14,id_padre:161,id:1262,parametro:"Pampayasta Sud"},
{id_pais:1,id_provincia:14,id_padre:161,id:1263,parametro:"Punta del Agua"},
{id_pais:1,id_provincia:14,id_padre:161,id:1264,parametro:"Río Tercero"},
{id_pais:1,id_provincia:14,id_padre:161,id:1265,parametro:"Tancacha"},
{id_pais:1,id_provincia:14,id_padre:161,id:1266,parametro:"Villa Ascasubi"},
{id_pais:1,id_provincia:14,id_padre:168,id:1267,parametro:"Candelaria Sur"},
{id_pais:1,id_provincia:14,id_padre:168,id:1268,parametro:"Cañada de Luque"},
{id_pais:1,id_provincia:14,id_padre:168,id:1269,parametro:"Capilla de Sitón"},
{id_pais:1,id_provincia:14,id_padre:168,id:1270,parametro:"La Pampa"},
{id_pais:1,id_provincia:14,id_padre:168,id:1271,parametro:"Las Peñas"},
{id_pais:1,id_provincia:14,id_padre:168,id:1272,parametro:"Los Mistoles"},
{id_pais:1,id_provincia:14,id_padre:168,id:1273,parametro:"Santa Catalina"},
{id_pais:1,id_provincia:14,id_padre:168,id:1274,parametro:"Sarmiento"},
{id_pais:1,id_provincia:14,id_padre:168,id:1275,parametro:"Simbolar"},
{id_pais:1,id_provincia:14,id_padre:168,id:1276,parametro:"Sinsacate"},
{id_pais:1,id_provincia:14,id_padre:168,id:1277,parametro:"Villa del Totoral"},
{id_pais:1,id_provincia:14,id_padre:175,id:1278,parametro:"Churqui Cañada"},
{id_pais:1,id_provincia:14,id_padre:175,id:1279,parametro:"El Rodeo"},
{id_pais:1,id_provincia:14,id_padre:175,id:1280,parametro:"El Tuscal"},
{id_pais:1,id_provincia:14,id_padre:175,id:1281,parametro:"Las Arrias"},
{id_pais:1,id_provincia:14,id_padre:175,id:1282,parametro:"Lucio V. Mansilla"},
{id_pais:1,id_provincia:14,id_padre:175,id:1283,parametro:"Rosario del Saladillo"},
{id_pais:1,id_provincia:14,id_padre:175,id:1284,parametro:"San José de la Dormida"},
{id_pais:1,id_provincia:14,id_padre:175,id:1285,parametro:"San José de las Salinas"},
{id_pais:1,id_provincia:14,id_padre:175,id:1286,parametro:"San Pedro Norte"},
{id_pais:1,id_provincia:14,id_padre:175,id:1287,parametro:"Villa Tulumba"},
{id_pais:1,id_provincia:14,id_padre:182,id:1288,parametro:"Aldea Santa María"},
{id_pais:1,id_provincia:14,id_padre:182,id:1289,parametro:"Alto Alegre"},
{id_pais:1,id_provincia:14,id_padre:182,id:1290,parametro:"Ana Zumarán"},
{id_pais:1,id_provincia:14,id_padre:182,id:1291,parametro:"Ballesteros"},
{id_pais:1,id_provincia:14,id_padre:182,id:1292,parametro:"Ballesteros Sud"},
{id_pais:1,id_provincia:14,id_padre:182,id:1293,parametro:"Bell Ville"},
{id_pais:1,id_provincia:14,id_padre:182,id:1294,parametro:"Benjamín Gould"},
{id_pais:1,id_provincia:14,id_padre:182,id:1295,parametro:"Canals"},
{id_pais:1,id_provincia:14,id_padre:182,id:1296,parametro:"Chilibroste"},
{id_pais:1,id_provincia:14,id_padre:182,id:1297,parametro:"Cintra"},
{id_pais:1,id_provincia:14,id_padre:182,id:1298,parametro:"Colonia Bismarck"},
{id_pais:1,id_provincia:14,id_padre:182,id:1299,parametro:"Colonia Bremen"},
{id_pais:1,id_provincia:14,id_padre:182,id:1300,parametro:"Idiazabal"},
{id_pais:1,id_provincia:14,id_padre:182,id:1301,parametro:"Justiniano Posse"},
{id_pais:1,id_provincia:14,id_padre:182,id:1302,parametro:"Laborde"},
{id_pais:1,id_provincia:14,id_padre:182,id:1303,parametro:"Monte Leña"},
{id_pais:1,id_provincia:14,id_padre:182,id:1304,parametro:"Monte Maíz"},
{id_pais:1,id_provincia:14,id_padre:182,id:1305,parametro:"Morrison"},
{id_pais:1,id_provincia:14,id_padre:182,id:1306,parametro:"Noetinger"},
{id_pais:1,id_provincia:14,id_padre:182,id:1307,parametro:"Ordoñez"},
{id_pais:1,id_provincia:14,id_padre:182,id:1308,parametro:"Pascanas"},
{id_pais:1,id_provincia:14,id_padre:182,id:1309,parametro:"Pueblo Italiano"},
{id_pais:1,id_provincia:14,id_padre:182,id:1310,parametro:"Ramón J. Cárcano"},
{id_pais:1,id_provincia:14,id_padre:182,id:1311,parametro:"San Antonio de Litín"},
{id_pais:1,id_provincia:14,id_padre:182,id:1312,parametro:"San Marcos"},
{id_pais:1,id_provincia:14,id_padre:182,id:1313,parametro:"San Severo"},
{id_pais:1,id_provincia:14,id_padre:182,id:1314,parametro:"Viamonte"},
{id_pais:1,id_provincia:14,id_padre:182,id:1315,parametro:"Villa Los Patos"},
{id_pais:1,id_provincia:14,id_padre:182,id:1316,parametro:"Wenceslao Escalante"},
{id_pais:1,id_provincia:18,id_padre:7,id:1317,parametro:"Bella Vista"},
{id_pais:1,id_provincia:18,id_padre:14,id:1318,parametro:"Berón de Astrada"},
{id_pais:1,id_provincia:18,id_padre:14,id:1319,parametro:"Yahapé"},
{id_pais:1,id_provincia:18,id_padre:21,id:1320,parametro:"Corrientes"},
{id_pais:1,id_provincia:18,id_padre:21,id:1321,parametro:"Laguna Brava"},
{id_pais:1,id_provincia:18,id_padre:21,id:1322,parametro:"Riachuelo"},
{id_pais:1,id_provincia:18,id_padre:21,id:1323,parametro:"San Cayetano"},
{id_pais:1,id_provincia:18,id_padre:28,id:1324,parametro:"Concepción"},
{id_pais:1,id_provincia:18,id_padre:28,id:1325,parametro:"Santa Rosa"},
{id_pais:1,id_provincia:18,id_padre:28,id:1326,parametro:"Tabay"},
{id_pais:1,id_provincia:18,id_padre:28,id:1327,parametro:"Tatacua"},
{id_pais:1,id_provincia:18,id_padre:35,id:1328,parametro:"Cazadores Correntinos"},
{id_pais:1,id_provincia:18,id_padre:35,id:1329,parametro:"Curuzú Cuatiá"},
{id_pais:1,id_provincia:18,id_padre:35,id:1330,parametro:"Perugorría"},
{id_pais:1,id_provincia:18,id_padre:42,id:1331,parametro:"El Sombrero"},
{id_pais:1,id_provincia:18,id_padre:42,id:1332,parametro:"Empedrado"},
{id_pais:1,id_provincia:18,id_padre:49,id:1333,parametro:"Esquina"},
{id_pais:1,id_provincia:18,id_padre:49,id:1334,parametro:"Pueblo Libertador"},
{id_pais:1,id_provincia:18,id_padre:56,id:1335,parametro:"Alvear"},
{id_pais:1,id_provincia:18,id_padre:56,id:1336,parametro:"Estación Torrent"},
{id_pais:1,id_provincia:18,id_padre:63,id:1337,parametro:"Itá Ibaté"},
{id_pais:1,id_provincia:18,id_padre:63,id:1338,parametro:"Lomas de Vallejos"},
{id_pais:1,id_provincia:18,id_padre:63,id:1339,parametro:"Nuestra Señora del Rosario de Caá Catí"},
{id_pais:1,id_provincia:18,id_padre:63,id:1340,parametro:"Palmar Grande"},
{id_pais:1,id_provincia:18,id_padre:70,id:1341,parametro:"Carolina"},
{id_pais:1,id_provincia:18,id_padre:70,id:1342,parametro:"Goya"},
{id_pais:1,id_provincia:18,id_padre:77,id:1343,parametro:"Itatí"},
{id_pais:1,id_provincia:18,id_padre:77,id:1344,parametro:"Ramada Paso"},
{id_pais:1,id_provincia:18,id_padre:84,id:1345,parametro:"Colonia Liebig's"},
{id_pais:1,id_provincia:18,id_padre:84,id:1346,parametro:"Ituzaingó"},
{id_pais:1,id_provincia:18,id_padre:84,id:1347,parametro:"San Antonio"},
{id_pais:1,id_provincia:18,id_padre:84,id:1348,parametro:"San Carlos"},
{id_pais:1,id_provincia:18,id_padre:84,id:1349,parametro:"Villa Olivari"},
{id_pais:1,id_provincia:18,id_padre:91,id:1350,parametro:"Cruz de los Milagros"},
{id_pais:1,id_provincia:18,id_padre:91,id:1351,parametro:"Gobernador Juan E. Martínez"},
{id_pais:1,id_provincia:18,id_padre:91,id:1352,parametro:"Lavalle"},
{id_pais:1,id_provincia:18,id_padre:91,id:1353,parametro:"Santa Lucía"},
{id_pais:1,id_provincia:18,id_padre:91,id:1354,parametro:"Villa Córdoba"},
{id_pais:1,id_provincia:18,id_padre:91,id:1355,parametro:"Yatayti Calle"},
{id_pais:1,id_provincia:18,id_padre:98,id:1356,parametro:"Mburucuyá"},
{id_pais:1,id_provincia:18,id_padre:105,id:1357,parametro:"Felipe Yofré"},
{id_pais:1,id_provincia:18,id_padre:105,id:1358,parametro:"Mariano I. Loza"},
{id_pais:1,id_provincia:18,id_padre:105,id:1359,parametro:"Mercedes"},
{id_pais:1,id_provincia:18,id_padre:112,id:1360,parametro:"Colonia Libertad"},
{id_pais:1,id_provincia:18,id_padre:112,id:1361,parametro:"Estación Libertad"},
{id_pais:1,id_provincia:18,id_padre:112,id:1362,parametro:"Juan Pujol"},
{id_pais:1,id_provincia:18,id_padre:112,id:1363,parametro:"Mocoretá"},
{id_pais:1,id_provincia:18,id_padre:112,id:1364,parametro:"Monte Caseros"},
{id_pais:1,id_provincia:18,id_padre:112,id:1365,parametro:"Parada Acuña"},
{id_pais:1,id_provincia:18,id_padre:112,id:1366,parametro:"Parada Labougle"},
{id_pais:1,id_provincia:18,id_padre:119,id:1367,parametro:"Bonpland"},
{id_pais:1,id_provincia:18,id_padre:119,id:1368,parametro:"Parada Pucheta"},
{id_pais:1,id_provincia:18,id_padre:119,id:1369,parametro:"Paso de los Libres"},
{id_pais:1,id_provincia:18,id_padre:119,id:1370,parametro:"Tapebicuá"},
{id_pais:1,id_provincia:18,id_padre:126,id:1371,parametro:"Saladas"},
{id_pais:1,id_provincia:18,id_padre:126,id:1372,parametro:"San Lorenzo"},
{id_pais:1,id_provincia:18,id_padre:133,id:1373,parametro:"Ingenio Primer Correntino"},
{id_pais:1,id_provincia:18,id_padre:133,id:1374,parametro:"Paso de la Patria"},
{id_pais:1,id_provincia:18,id_padre:133,id:1375,parametro:"San Cosme"},
{id_pais:1,id_provincia:18,id_padre:133,id:1376,parametro:"Santa Ana"},
{id_pais:1,id_provincia:18,id_padre:140,id:1377,parametro:"San Luis del Palmar"},
{id_pais:1,id_provincia:18,id_padre:147,id:1378,parametro:"Colonia Carlos Pellegrini"},
{id_pais:1,id_provincia:18,id_padre:147,id:1379,parametro:"Guaviraví"},
{id_pais:1,id_provincia:18,id_padre:147,id:1380,parametro:"La Cruz"},
{id_pais:1,id_provincia:18,id_padre:147,id:1381,parametro:"Yapeyú"},
{id_pais:1,id_provincia:18,id_padre:154,id:1382,parametro:"Loreto"},
{id_pais:1,id_provincia:18,id_padre:154,id:1383,parametro:"San Miguel"},
{id_pais:1,id_provincia:18,id_padre:161,id:1384,parametro:"Chavarría"},
{id_pais:1,id_provincia:18,id_padre:161,id:1385,parametro:"Colonia Pando"},
{id_pais:1,id_provincia:18,id_padre:161,id:1386,parametro:"9 de Julio"},
{id_pais:1,id_provincia:18,id_padre:161,id:1387,parametro:"Pedro R. Fernández"},
{id_pais:1,id_provincia:18,id_padre:161,id:1388,parametro:"San Roque"},
{id_pais:1,id_provincia:18,id_padre:168,id:1389,parametro:"José Rafael Gómez"},
{id_pais:1,id_provincia:18,id_padre:168,id:1390,parametro:"Garruchos"},
{id_pais:1,id_provincia:18,id_padre:168,id:1391,parametro:"Gobernador Igr. Valentín Virasoro"},
{id_pais:1,id_provincia:18,id_padre:168,id:1392,parametro:"Santo Tomé"},
{id_pais:1,id_provincia:18,id_padre:175,id:1393,parametro:"Sauce"},
{id_pais:1,id_provincia:22,id_padre:7,id:1394,parametro:"Concepción del Bermejo"},
{id_pais:1,id_provincia:22,id_padre:7,id:1395,parametro:"Los Frentones"},
{id_pais:1,id_provincia:22,id_padre:7,id:1396,parametro:"Pampa del Infierno"},
{id_pais:1,id_provincia:22,id_padre:7,id:1397,parametro:"Río Muerto"},
{id_pais:1,id_provincia:22,id_padre:7,id:1398,parametro:"Taco Pozo"},
{id_pais:1,id_provincia:22,id_padre:14,id:1399,parametro:"General Vedia"},
{id_pais:1,id_provincia:22,id_padre:14,id:1400,parametro:"Isla del Cerrito"},
{id_pais:1,id_provincia:22,id_padre:14,id:1401,parametro:"La Leonesa"},
{id_pais:1,id_provincia:22,id_padre:14,id:1402,parametro:"Las Palmas"},
{id_pais:1,id_provincia:22,id_padre:14,id:1403,parametro:"Puerto Bermejo Nuevo"},
{id_pais:1,id_provincia:22,id_padre:14,id:1404,parametro:"Puerto Bermejo Viejo"},
{id_pais:1,id_provincia:22,id_padre:14,id:1405,parametro:"Puerto Eva Perón"},
{id_pais:1,id_provincia:22,id_padre:21,id:1406,parametro:"Presidencia Roque Sáenz Peña"},
{id_pais:1,id_provincia:22,id_padre:28,id:1407,parametro:"Charata"},
{id_pais:1,id_provincia:22,id_padre:36,id:1408,parametro:"Gancedo"},
{id_pais:1,id_provincia:22,id_padre:36,id:1409,parametro:"General Capdevila"},
{id_pais:1,id_provincia:22,id_padre:36,id:1410,parametro:"General Pinedo"},
{id_pais:1,id_provincia:22,id_padre:36,id:1411,parametro:"Mesón de Fierro"},
{id_pais:1,id_provincia:22,id_padre:36,id:1412,parametro:"Pampa Landriel"},
{id_pais:1,id_provincia:22,id_padre:39,id:1413,parametro:"Hermoso Campo"},
{id_pais:1,id_provincia:22,id_padre:39,id:1414,parametro:"Itín"},
{id_pais:1,id_provincia:22,id_padre:43,id:1415,parametro:"Chorotis"},
{id_pais:1,id_provincia:22,id_padre:43,id:1416,parametro:"Santa Sylvina"},
{id_pais:1,id_provincia:22,id_padre:43,id:1417,parametro:"Venados Grandes"},
{id_pais:1,id_provincia:22,id_padre:49,id:1418,parametro:"Corzuela"},
{id_pais:1,id_provincia:22,id_padre:56,id:1419,parametro:"La Escondida"},
{id_pais:1,id_provincia:22,id_padre:56,id:1420,parametro:"La Verde"},
{id_pais:1,id_provincia:22,id_padre:56,id:1421,parametro:"Lapachito"},
{id_pais:1,id_provincia:22,id_padre:56,id:1422,parametro:"Makallé"},
{id_pais:1,id_provincia:22,id_padre:63,id:1423,parametro:"El Espinillo"},
{id_pais:1,id_provincia:22,id_padre:63,id:1424,parametro:"El Sauzal"},
{id_pais:1,id_provincia:22,id_padre:63,id:1425,parametro:"El Sauzalito"},
{id_pais:1,id_provincia:22,id_padre:63,id:1426,parametro:"Fortín Lavalle"},
{id_pais:1,id_provincia:22,id_padre:63,id:1427,parametro:"Fuerte Esperanza"},
{id_pais:1,id_provincia:22,id_padre:63,id:1428,parametro:"Juan José Castelli"},
{id_pais:1,id_provincia:22,id_padre:63,id:1429,parametro:"Miraflores"},
{id_pais:1,id_provincia:22,id_padre:63,id:1430,parametro:"Nueva Pompeya"},
{id_pais:1,id_provincia:22,id_padre:63,id:1431,parametro:"Villa Río Bermejito"},
{id_pais:1,id_provincia:22,id_padre:63,id:1432,parametro:"Wichi"},
{id_pais:1,id_provincia:22,id_padre:63,id:1433,parametro:"Zaparinqui"},
{id_pais:1,id_provincia:22,id_padre:70,id:1434,parametro:"Avia Terai"},
{id_pais:1,id_provincia:22,id_padre:70,id:1435,parametro:"Campo Largo"},
{id_pais:1,id_provincia:22,id_padre:70,id:1436,parametro:"Fortín Las Chuñas"},
{id_pais:1,id_provincia:22,id_padre:70,id:1437,parametro:"Napenay"},
{id_pais:1,id_provincia:22,id_padre:77,id:1438,parametro:"Colonia Popular"},
{id_pais:1,id_provincia:22,id_padre:77,id:1439,parametro:"Estación General Obligado"},
{id_pais:1,id_provincia:22,id_padre:77,id:1440,parametro:"Laguna Blanca"},
{id_pais:1,id_provincia:22,id_padre:77,id:1441,parametro:"Puerto Tirol"},
{id_pais:1,id_provincia:22,id_padre:84,id:1442,parametro:"Ciervo Petiso"},
{id_pais:1,id_provincia:22,id_padre:84,id:1443,parametro:"General José de San Martín"},
{id_pais:1,id_provincia:22,id_padre:84,id:1444,parametro:"La Eduvigis"},
{id_pais:1,id_provincia:22,id_padre:84,id:1445,parametro:"Laguna Limpia"},
{id_pais:1,id_provincia:22,id_padre:84,id:1446,parametro:"Pampa Almirón"},
{id_pais:1,id_provincia:22,id_padre:84,id:1447,parametro:"Pampa del Indio"},
{id_pais:1,id_provincia:22,id_padre:84,id:1448,parametro:"Presidencia Roca"},
{id_pais:1,id_provincia:22,id_padre:84,id:1449,parametro:"Selvas del Río de Oro"},
{id_pais:1,id_provincia:22,id_padre:91,id:1450,parametro:"Tres Isletas"},
{id_pais:1,id_provincia:22,id_padre:98,id:1451,parametro:"Coronel Du Graty"},
{id_pais:1,id_provincia:22,id_padre:98,id:1452,parametro:"Enrique Urien"},
{id_pais:1,id_provincia:22,id_padre:98,id:1453,parametro:"Villa Angela"},
{id_pais:1,id_provincia:22,id_padre:105,id:1454,parametro:"Las Breñas"},
{id_pais:1,id_provincia:22,id_padre:112,id:1455,parametro:"La Clotilde"},
{id_pais:1,id_provincia:22,id_padre:112,id:1456,parametro:"La Tigra"},
{id_pais:1,id_provincia:22,id_padre:112,id:1457,parametro:"San Bernardo"},
{id_pais:1,id_provincia:22,id_padre:119,id:1458,parametro:"Presidencia de la Plaza"},
{id_pais:1,id_provincia:22,id_padre:126,id:1459,parametro:"Barrio de los Pescadores"},
{id_pais:1,id_provincia:22,id_padre:126,id:1460,parametro:"Colonia Benítez"},
{id_pais:1,id_provincia:22,id_padre:126,id:1461,parametro:"Margarita Belén"},
{id_pais:1,id_provincia:22,id_padre:133,id:1462,parametro:"Quitilipi"},
{id_pais:1,id_provincia:22,id_padre:133,id:1463,parametro:"Villa El Palmar"},
{id_pais:1,id_provincia:22,id_padre:140,id:1464,parametro:"Barranqueras"},
{id_pais:1,id_provincia:22,id_padre:140,id:1465,parametro:"Basail"},
{id_pais:1,id_provincia:22,id_padre:140,id:1466,parametro:"Colonia Baranda"},
{id_pais:1,id_provincia:22,id_padre:140,id:1467,parametro:"Fontana"},
{id_pais:1,id_provincia:22,id_padre:140,id:1468,parametro:"Puerto Vilelas"},
{id_pais:1,id_provincia:22,id_padre:140,id:1469,parametro:"Resistencia"},
{id_pais:1,id_provincia:22,id_padre:147,id:1470,parametro:"Samuhú"},
{id_pais:1,id_provincia:22,id_padre:147,id:1471,parametro:"Villa Berthet"},
{id_pais:1,id_provincia:22,id_padre:154,id:1472,parametro:"Capitán Solari"},
{id_pais:1,id_provincia:22,id_padre:154,id:1473,parametro:"Colonia Elisa"},
{id_pais:1,id_provincia:22,id_padre:154,id:1474,parametro:"Colonias Unidas"},
{id_pais:1,id_provincia:22,id_padre:154,id:1475,parametro:"Ingeniero Barbet"},
{id_pais:1,id_provincia:22,id_padre:154,id:1476,parametro:"Las Garcitas"},
{id_pais:1,id_provincia:22,id_padre:161,id:1477,parametro:"Charadai"},
{id_pais:1,id_provincia:22,id_padre:161,id:1478,parametro:"Cote Lai"},
{id_pais:1,id_provincia:22,id_padre:161,id:1479,parametro:"Haumonia"},
{id_pais:1,id_provincia:22,id_padre:161,id:1480,parametro:"Horquilla"},
{id_pais:1,id_provincia:22,id_padre:161,id:1481,parametro:"La Sabana"},
{id_pais:1,id_provincia:22,id_padre:168,id:1482,parametro:"Colonia Aborigen"},
{id_pais:1,id_provincia:22,id_padre:168,id:1483,parametro:"Machagai"},
{id_pais:1,id_provincia:22,id_padre:168,id:1484,parametro:"Napalpí"},
{id_pais:1,id_provincia:26,id_padre:7,id:1485,parametro:"Arroyo Verde"},
{id_pais:1,id_provincia:26,id_padre:7,id:1486,parametro:"Puerto Madryn"},
{id_pais:1,id_provincia:26,id_padre:7,id:1487,parametro:"Puerto Pirámides"},
{id_pais:1,id_provincia:26,id_padre:7,id:1488,parametro:"Quintas El Mirador"},
{id_pais:1,id_provincia:26,id_padre:7,id:1489,parametro:"Reserva Area Protegida El Doradillo"},
{id_pais:1,id_provincia:26,id_padre:14,id:1490,parametro:"Buenos Aires Chico"},
{id_pais:1,id_provincia:26,id_padre:14,id:1491,parametro:"Cholila"},
{id_pais:1,id_provincia:26,id_padre:14,id:1492,parametro:"Costa del Chubut"},
{id_pais:1,id_provincia:26,id_padre:14,id:1493,parametro:"Cushamen Centro"},
{id_pais:1,id_provincia:26,id_padre:14,id:1494,parametro:"El Hoyo"},
{id_pais:1,id_provincia:26,id_padre:14,id:1495,parametro:"El Maitén"},
{id_pais:1,id_provincia:26,id_padre:14,id:1496,parametro:"Epuyén"},
{id_pais:1,id_provincia:26,id_padre:14,id:1497,parametro:"Fofo Cahuel"},
{id_pais:1,id_provincia:26,id_padre:14,id:1498,parametro:"Gualjaina"},
{id_pais:1,id_provincia:26,id_padre:14,id:1499,parametro:"Lago Epuyén"},
{id_pais:1,id_provincia:26,id_padre:14,id:1500,parametro:"Lago Puelo"},
{id_pais:1,id_provincia:26,id_padre:14,id:1501,parametro:"Leleque"},
{id_pais:1,id_provincia:26,id_padre:21,id:1502,parametro:"Astra"},
{id_pais:1,id_provincia:26,id_padre:21,id:1503,parametro:"Bahía Bustamante"},
{id_pais:1,id_provincia:26,id_padre:21,id:1504,parametro:"Comodoro Rivadavia"},
{id_pais:1,id_provincia:26,id_padre:21,id:1505,parametro:"Diadema Argentina"},
{id_pais:1,id_provincia:26,id_padre:21,id:1506,parametro:"Rada Tilly"},
{id_pais:1,id_provincia:26,id_padre:28,id:1507,parametro:"Camarones"},
{id_pais:1,id_provincia:26,id_padre:28,id:1508,parametro:"Garayalde"},
{id_pais:1,id_provincia:26,id_padre:35,id:1509,parametro:"Aldea Escolar (Los Rápidos)"},
{id_pais:1,id_provincia:26,id_padre:35,id:1510,parametro:"Corcovado"},
{id_pais:1,id_provincia:26,id_padre:35,id:1511,parametro:"Esquel"},
{id_pais:1,id_provincia:26,id_padre:35,id:1512,parametro:"Lago Rosario"},
{id_pais:1,id_provincia:26,id_padre:35,id:1513,parametro:"Los Cipreses"},
{id_pais:1,id_provincia:26,id_padre:35,id:1514,parametro:"Trevelín"},
{id_pais:1,id_provincia:26,id_padre:35,id:1515,parametro:"Villa Futalaufquen"},
{id_pais:1,id_provincia:26,id_padre:42,id:1516,parametro:"Dique Florentino Ameghino"},
{id_pais:1,id_provincia:26,id_padre:42,id:1517,parametro:"Dolavon"},
{id_pais:1,id_provincia:26,id_padre:42,id:1518,parametro:"Gaiman"},
{id_pais:1,id_provincia:26,id_padre:42,id:1519,parametro:"28 de Julio"},
{id_pais:1,id_provincia:26,id_padre:49,id:1520,parametro:"Blancuntre"},
{id_pais:1,id_provincia:26,id_padre:49,id:1521,parametro:"El Escorial"},
{id_pais:1,id_provincia:26,id_padre:49,id:1522,parametro:"Gastre"},
{id_pais:1,id_provincia:26,id_padre:49,id:1523,parametro:"Lagunita Salada"},
{id_pais:1,id_provincia:26,id_padre:49,id:1524,parametro:"Yala Laubat"},
{id_pais:1,id_provincia:26,id_padre:56,id:1525,parametro:"Aldea Epulef"},
{id_pais:1,id_provincia:26,id_padre:56,id:1526,parametro:"Carrenleufú"},
{id_pais:1,id_provincia:26,id_padre:56,id:1527,parametro:"Colan Conhué"},
{id_pais:1,id_provincia:26,id_padre:56,id:1528,parametro:"Paso del Sapo"},
{id_pais:1,id_provincia:26,id_padre:56,id:1529,parametro:"Tecka"},
{id_pais:1,id_provincia:26,id_padre:63,id:1530,parametro:"El Mirasol"},
{id_pais:1,id_provincia:26,id_padre:63,id:1531,parametro:"Las Plumas"},
{id_pais:1,id_provincia:26,id_padre:70,id:1532,parametro:"Cerro Cóndor"},
{id_pais:1,id_provincia:26,id_padre:70,id:1533,parametro:"Los Altares"},
{id_pais:1,id_provincia:26,id_padre:70,id:1534,parametro:"Paso de Indios"},
{id_pais:1,id_provincia:26,id_padre:77,id:1535,parametro:"Playa Magagna"},
{id_pais:1,id_provincia:26,id_padre:77,id:1536,parametro:"Playa Unión"},
{id_pais:1,id_provincia:26,id_padre:77,id:1537,parametro:"Rawson"},
{id_pais:1,id_provincia:26,id_padre:77,id:1538,parametro:"Trelew"},
{id_pais:1,id_provincia:26,id_padre:84,id:1539,parametro:"Aldea Apeleg"},
{id_pais:1,id_provincia:26,id_padre:84,id:1540,parametro:"Aldea Beleiro"},
{id_pais:1,id_provincia:26,id_padre:84,id:1541,parametro:"Alto Río Senguer"},
{id_pais:1,id_provincia:26,id_padre:84,id:1542,parametro:"Doctor Ricardo Rojas"},
{id_pais:1,id_provincia:26,id_padre:84,id:1543,parametro:"Facundo"},
{id_pais:1,id_provincia:26,id_padre:84,id:1544,parametro:"Lago Blanco"},
{id_pais:1,id_provincia:26,id_padre:84,id:1545,parametro:"Río Mayo"},
{id_pais:1,id_provincia:26,id_padre:91,id:1546,parametro:"Buen Pasto"},
{id_pais:1,id_provincia:26,id_padre:91,id:1547,parametro:"Sarmiento"},
{id_pais:1,id_provincia:26,id_padre:98,id:1548,parametro:"Doctor Oscar Atilio Viglione (Frontera de Río Pico)"},
{id_pais:1,id_provincia:26,id_padre:98,id:1549,parametro:"Gobernador Costa"},
{id_pais:1,id_provincia:26,id_padre:98,id:1550,parametro:"José de San Martín"},
{id_pais:1,id_provincia:26,id_padre:98,id:1551,parametro:"Río Pico"},
{id_pais:1,id_provincia:26,id_padre:105,id:1552,parametro:"Gan Gan"},
{id_pais:1,id_provincia:26,id_padre:105,id:1553,parametro:"Telsen"},
{id_pais:1,id_provincia:30,id_padre:8,id:1554,parametro:"Arroyo Barú"},
{id_pais:1,id_provincia:30,id_padre:8,id:1555,parametro:"Colón"},
{id_pais:1,id_provincia:30,id_padre:8,id:1556,parametro:"Colonia Hugues"},
{id_pais:1,id_provincia:30,id_padre:8,id:1557,parametro:"Hambis"},
{id_pais:1,id_provincia:30,id_padre:8,id:1558,parametro:"Hocker"},
{id_pais:1,id_provincia:30,id_padre:8,id:1559,parametro:"La Clarita"},
{id_pais:1,id_provincia:30,id_padre:8,id:1560,parametro:"Pueblo Cazes"},
{id_pais:1,id_provincia:30,id_padre:8,id:1561,parametro:"Pueblo Liebig's"},
{id_pais:1,id_provincia:30,id_padre:8,id:1562,parametro:"San José"},
{id_pais:1,id_provincia:30,id_padre:8,id:1563,parametro:"Ubajay"},
{id_pais:1,id_provincia:30,id_padre:8,id:1564,parametro:"Villa  Elisa"},
{id_pais:1,id_provincia:30,id_padre:15,id:1565,parametro:"Calabacilla"},
{id_pais:1,id_provincia:30,id_padre:15,id:1566,parametro:"Clodomiro Ledesma"},
{id_pais:1,id_provincia:30,id_padre:15,id:1567,parametro:"Colonia Ayuí"},
{id_pais:1,id_provincia:30,id_padre:15,id:1568,parametro:"Colonia General Roca"},
{id_pais:1,id_provincia:30,id_padre:15,id:1569,parametro:"Concordia"},
{id_pais:1,id_provincia:30,id_padre:15,id:1570,parametro:"Estación Yeruá"},
{id_pais:1,id_provincia:30,id_padre:15,id:1571,parametro:"Estación Yuquerí"},
{id_pais:1,id_provincia:30,id_padre:15,id:1572,parametro:"Estancia Grande"},
{id_pais:1,id_provincia:30,id_padre:15,id:1573,parametro:"La Criolla"},
{id_pais:1,id_provincia:30,id_padre:15,id:1574,parametro:"Los Charrúas"},
{id_pais:1,id_provincia:30,id_padre:15,id:1575,parametro:"Nueva Escocia"},
{id_pais:1,id_provincia:30,id_padre:15,id:1576,parametro:"Osvaldo Magnasco"},
{id_pais:1,id_provincia:30,id_padre:15,id:1577,parametro:"Pedernal"},
{id_pais:1,id_provincia:30,id_padre:15,id:1578,parametro:"Puerto Yeruá"},
{id_pais:1,id_provincia:30,id_padre:21,id:1579,parametro:"Aldea Brasilera"},
{id_pais:1,id_provincia:30,id_padre:21,id:1580,parametro:"Aldea Grapschental"},
{id_pais:1,id_provincia:30,id_padre:21,id:1581,parametro:"Aldea Protestante"},
{id_pais:1,id_provincia:30,id_padre:21,id:1582,parametro:"Aldea Salto"},
{id_pais:1,id_provincia:30,id_padre:21,id:1583,parametro:"Aldea San Francisco"},
{id_pais:1,id_provincia:30,id_padre:21,id:1584,parametro:"Aldea Spatzenkutter"},
{id_pais:1,id_provincia:30,id_padre:21,id:1585,parametro:"Aldea Valle María"},
{id_pais:1,id_provincia:30,id_padre:21,id:1586,parametro:"Colonia Ensayo"},
{id_pais:1,id_provincia:30,id_padre:21,id:1587,parametro:"Diamante"},
{id_pais:1,id_provincia:30,id_padre:21,id:1588,parametro:"Estación Camps"},
{id_pais:1,id_provincia:30,id_padre:21,id:1589,parametro:"General Alvear"},
{id_pais:1,id_provincia:30,id_padre:21,id:1590,parametro:"General Racedo (El Carmen)"},
{id_pais:1,id_provincia:30,id_padre:21,id:1591,parametro:"General Ramírez"},
{id_pais:1,id_provincia:30,id_padre:21,id:1592,parametro:"La Juanita"},
{id_pais:1,id_provincia:30,id_padre:21,id:1593,parametro:"Las Jaulas"},
{id_pais:1,id_provincia:30,id_padre:21,id:1594,parametro:"Paraje La Virgen"},
{id_pais:1,id_provincia:30,id_padre:21,id:1595,parametro:"Puerto Las Cuevas"},
{id_pais:1,id_provincia:30,id_padre:21,id:1596,parametro:"Villa Libertador San Martín"},
{id_pais:1,id_provincia:30,id_padre:28,id:1597,parametro:"Chajarí"},
{id_pais:1,id_provincia:30,id_padre:28,id:1598,parametro:"Colonia Alemana"},
{id_pais:1,id_provincia:30,id_padre:28,id:1599,parametro:"Colonia La Argentina"},
{id_pais:1,id_provincia:30,id_padre:28,id:1600,parametro:"Colonia Peña"},
{id_pais:1,id_provincia:30,id_padre:28,id:1601,parametro:"Federación"},
{id_pais:1,id_provincia:30,id_padre:28,id:1602,parametro:"Los Conquistadores"},
{id_pais:1,id_provincia:30,id_padre:28,id:1603,parametro:"San Jaime de la Frontera"},
{id_pais:1,id_provincia:30,id_padre:28,id:1604,parametro:"San Pedro"},
{id_pais:1,id_provincia:30,id_padre:28,id:1605,parametro:"San Ramón"},
{id_pais:1,id_provincia:30,id_padre:28,id:1606,parametro:"Santa Ana"},
{id_pais:1,id_provincia:30,id_padre:28,id:1607,parametro:"Villa del Rosario"},
{id_pais:1,id_provincia:30,id_padre:35,id:1608,parametro:"Conscripto Bernardi"},
{id_pais:1,id_provincia:30,id_padre:35,id:1609,parametro:"Aldea San Isidro (El Cimarrón)"},
{id_pais:1,id_provincia:30,id_padre:35,id:1610,parametro:"Federal"},
{id_pais:1,id_provincia:30,id_padre:35,id:1611,parametro:"Nueva Vizcaya"},
{id_pais:1,id_provincia:30,id_padre:35,id:1612,parametro:"Sauce de Luna"},
{id_pais:1,id_provincia:30,id_padre:42,id:1613,parametro:"San José de Feliciano"},
{id_pais:1,id_provincia:30,id_padre:42,id:1614,parametro:"San Víctor"},
{id_pais:1,id_provincia:30,id_padre:49,id:1615,parametro:"Aldea Asunción"},
{id_pais:1,id_provincia:30,id_padre:49,id:1616,parametro:"Estación Lazo"},
{id_pais:1,id_provincia:30,id_padre:49,id:1617,parametro:"General Galarza"},
{id_pais:1,id_provincia:30,id_padre:49,id:1618,parametro:"Gualeguay"},
{id_pais:1,id_provincia:30,id_padre:49,id:1619,parametro:"Puerto Ruiz"},
{id_pais:1,id_provincia:30,id_padre:56,id:1620,parametro:"Aldea San Antonio"},
{id_pais:1,id_provincia:30,id_padre:56,id:1621,parametro:"Aldea San Juan"},
{id_pais:1,id_provincia:30,id_padre:56,id:1622,parametro:"Enrique Carbó"},
{id_pais:1,id_provincia:30,id_padre:56,id:1623,parametro:"Estación Escriña"},
{id_pais:1,id_provincia:30,id_padre:56,id:1624,parametro:"Faustino M. Parera"},
{id_pais:1,id_provincia:30,id_padre:56,id:1625,parametro:"General Almada"},
{id_pais:1,id_provincia:30,id_padre:56,id:1626,parametro:"Gilbert"},
{id_pais:1,id_provincia:30,id_padre:56,id:1627,parametro:"Gualeguaychú"},
{id_pais:1,id_provincia:30,id_padre:56,id:1628,parametro:"Irazusta"},
{id_pais:1,id_provincia:30,id_padre:56,id:1629,parametro:"Larroque"},
{id_pais:1,id_provincia:30,id_padre:56,id:1630,parametro:"Pastor Britos"},
{id_pais:1,id_provincia:30,id_padre:56,id:1631,parametro:"Pueblo General Belgrano"},
{id_pais:1,id_provincia:30,id_padre:56,id:1632,parametro:"Urdinarrain"},
{id_pais:1,id_provincia:30,id_padre:63,id:1633,parametro:"Ceibas"},
{id_pais:1,id_provincia:30,id_padre:63,id:1634,parametro:"Ibicuy"},
{id_pais:1,id_provincia:30,id_padre:63,id:1635,parametro:"Médanos"},
{id_pais:1,id_provincia:30,id_padre:63,id:1636,parametro:"Villa Paranacito"},
{id_pais:1,id_provincia:30,id_padre:70,id:1637,parametro:"Alcaraz"},
{id_pais:1,id_provincia:30,id_padre:70,id:1638,parametro:"Bovril"},
{id_pais:1,id_provincia:30,id_padre:70,id:1639,parametro:"Colonia Avigdor"},
{id_pais:1,id_provincia:30,id_padre:70,id:1640,parametro:"El Solar"},
{id_pais:1,id_provincia:30,id_padre:70,id:1641,parametro:"La Paz"},
{id_pais:1,id_provincia:30,id_padre:70,id:1642,parametro:"Piedras Blancas"},
{id_pais:1,id_provincia:30,id_padre:70,id:1643,parametro:"San Gustavo"},
{id_pais:1,id_provincia:30,id_padre:70,id:1644,parametro:"Santa Elena"},
{id_pais:1,id_provincia:30,id_padre:70,id:1645,parametro:"Sir Leonard"},
{id_pais:1,id_provincia:30,id_padre:77,id:1646,parametro:"Aranguren"},
{id_pais:1,id_provincia:30,id_padre:77,id:1647,parametro:"Betbeder"},
{id_pais:1,id_provincia:30,id_padre:77,id:1648,parametro:"Don Cristóbal"},
{id_pais:1,id_provincia:30,id_padre:77,id:1649,parametro:"Febré"},
{id_pais:1,id_provincia:30,id_padre:77,id:1650,parametro:"Hernández"},
{id_pais:1,id_provincia:30,id_padre:77,id:1651,parametro:"Lucas González"},
{id_pais:1,id_provincia:30,id_padre:77,id:1652,parametro:"Nogoyá"},
{id_pais:1,id_provincia:30,id_padre:77,id:1653,parametro:"XX de Setiembre"},
{id_pais:1,id_provincia:30,id_padre:84,id:1654,parametro:"Aldea María Luisa"},
{id_pais:1,id_provincia:30,id_padre:84,id:1655,parametro:"Aldea San Juan"},
{id_pais:1,id_provincia:30,id_padre:84,id:1656,parametro:"Aldea San Rafael"},
{id_pais:1,id_provincia:30,id_padre:84,id:1657,parametro:"Aldea Santa María"},
{id_pais:1,id_provincia:30,id_padre:84,id:1658,parametro:"Aldea Santa Rosa"},
{id_pais:1,id_provincia:30,id_padre:84,id:1659,parametro:"Cerrito"},
{id_pais:1,id_provincia:30,id_padre:84,id:1660,parametro:"Colonia Avellaneda"},
{id_pais:1,id_provincia:30,id_padre:84,id:1661,parametro:"Colonia Crespo"},
{id_pais:1,id_provincia:30,id_padre:84,id:1662,parametro:"Crespo"},
{id_pais:1,id_provincia:30,id_padre:84,id:1663,parametro:"El Palenque"},
{id_pais:1,id_provincia:30,id_padre:84,id:1664,parametro:"El Pingo"},
{id_pais:1,id_provincia:30,id_padre:84,id:1665,parametro:"El Ramblón"},
{id_pais:1,id_provincia:30,id_padre:84,id:1666,parametro:"Hasenkamp"},
{id_pais:1,id_provincia:30,id_padre:84,id:1667,parametro:"Hernandarias"},
{id_pais:1,id_provincia:30,id_padre:84,id:1668,parametro:"La Picada"},
{id_pais:1,id_provincia:30,id_padre:84,id:1669,parametro:"Las Tunas"},
{id_pais:1,id_provincia:30,id_padre:84,id:1670,parametro:"María Grande"},
{id_pais:1,id_provincia:30,id_padre:84,id:1671,parametro:"Oro Verde"},
{id_pais:1,id_provincia:30,id_padre:84,id:1672,parametro:"Paraná"},
{id_pais:1,id_provincia:30,id_padre:84,id:1673,parametro:"Pueblo Bellocq (Las Garzas)"},
{id_pais:1,id_provincia:30,id_padre:84,id:1674,parametro:"Pueblo Brugo"},
{id_pais:1,id_provincia:30,id_padre:84,id:1675,parametro:"Pueblo General San Martín"},
{id_pais:1,id_provincia:30,id_padre:84,id:1676,parametro:"San Benito"},
{id_pais:1,id_provincia:30,id_padre:84,id:1677,parametro:"Sauce Montrull"},
{id_pais:1,id_provincia:30,id_padre:84,id:1678,parametro:"Sauce Pinto"},
{id_pais:1,id_provincia:30,id_padre:84,id:1679,parametro:"Seguí"},
{id_pais:1,id_provincia:30,id_padre:84,id:1680,parametro:"Sosa"},
{id_pais:1,id_provincia:30,id_padre:84,id:1681,parametro:"Tabossi"},
{id_pais:1,id_provincia:30,id_padre:84,id:1682,parametro:"Tezanos Pinto"},
{id_pais:1,id_provincia:30,id_padre:84,id:1683,parametro:"Viale"},
{id_pais:1,id_provincia:30,id_padre:84,id:1684,parametro:"Villa Fontana"},
{id_pais:1,id_provincia:30,id_padre:84,id:1685,parametro:"Villa Gdor. Luis F. Etchevehere"},
{id_pais:1,id_provincia:30,id_padre:84,id:1686,parametro:"Villa Urquiza"},
{id_pais:1,id_provincia:30,id_padre:88,id:1687,parametro:"General Campos"},
{id_pais:1,id_provincia:30,id_padre:88,id:1688,parametro:"San Salvador"},
{id_pais:1,id_provincia:30,id_padre:91,id:1689,parametro:"Altamirano Sur"},
{id_pais:1,id_provincia:30,id_padre:91,id:1690,parametro:"Durazno"},
{id_pais:1,id_provincia:30,id_padre:91,id:1691,parametro:"Estación Arroyo Clé"},
{id_pais:1,id_provincia:30,id_padre:91,id:1692,parametro:"Gobernador Echagüe"},
{id_pais:1,id_provincia:30,id_padre:91,id:1693,parametro:"Gobernador Mansilla"},
{id_pais:1,id_provincia:30,id_padre:91,id:1694,parametro:"Gobernador Solá"},
{id_pais:1,id_provincia:30,id_padre:91,id:1695,parametro:"Guardamonte"},
{id_pais:1,id_provincia:30,id_padre:91,id:1696,parametro:"Las Guachas"},
{id_pais:1,id_provincia:30,id_padre:91,id:1697,parametro:"Maciá"},
{id_pais:1,id_provincia:30,id_padre:91,id:1698,parametro:"Rosario del Tala"},
{id_pais:1,id_provincia:30,id_padre:98,id:1699,parametro:"Basavilbaso"},
{id_pais:1,id_provincia:30,id_padre:98,id:1700,parametro:"Caseros"},
{id_pais:1,id_provincia:30,id_padre:98,id:1701,parametro:"Colonia Elía"},
{id_pais:1,id_provincia:30,id_padre:98,id:1702,parametro:"Concepción del Uruguay"},
{id_pais:1,id_provincia:30,id_padre:98,id:1703,parametro:"Herrera"},
{id_pais:1,id_provincia:30,id_padre:98,id:1704,parametro:"Las Moscas"},
{id_pais:1,id_provincia:30,id_padre:98,id:1705,parametro:"Líbaros"},
{id_pais:1,id_provincia:30,id_padre:98,id:1706,parametro:"1° de Mayo"},
{id_pais:1,id_provincia:30,id_padre:98,id:1707,parametro:"Pronunciamiento"},
{id_pais:1,id_provincia:30,id_padre:98,id:1708,parametro:"Rocamora"},
{id_pais:1,id_provincia:30,id_padre:98,id:1709,parametro:"Santa Anita"},
{id_pais:1,id_provincia:30,id_padre:98,id:1710,parametro:"Villa Mantero"},
{id_pais:1,id_provincia:30,id_padre:98,id:1711,parametro:"Villa San Justo"},
{id_pais:1,id_provincia:30,id_padre:98,id:1712,parametro:"Villa San Marcial (Est. Gobernador Urquiza)"},
{id_pais:1,id_provincia:30,id_padre:105,id:1713,parametro:"Antelo"},
{id_pais:1,id_provincia:30,id_padre:105,id:1714,parametro:"Molino Doll"},
{id_pais:1,id_provincia:30,id_padre:105,id:1715,parametro:"Victoria"},
{id_pais:1,id_provincia:30,id_padre:113,id:1716,parametro:"Estación Raíces"},
{id_pais:1,id_provincia:30,id_padre:113,id:1717,parametro:"Ingeniero Miguel Sajaroff"},
{id_pais:1,id_provincia:30,id_padre:113,id:1718,parametro:"Jubileo"},
{id_pais:1,id_provincia:30,id_padre:113,id:1719,parametro:"Paso de la Laguna"},
{id_pais:1,id_provincia:30,id_padre:113,id:1720,parametro:"Villa Clara"},
{id_pais:1,id_provincia:30,id_padre:113,id:1721,parametro:"Villa Domínguez"},
{id_pais:1,id_provincia:30,id_padre:113,id:1722,parametro:"Villaguay"},
{id_pais:1,id_provincia:34,id_padre:7,id:1723,parametro:"Fortín Soledad"},
{id_pais:1,id_provincia:34,id_padre:7,id:1724,parametro:"Guadalcazar"},
{id_pais:1,id_provincia:34,id_padre:7,id:1725,parametro:"La Rinconada"},
{id_pais:1,id_provincia:34,id_padre:7,id:1726,parametro:"Laguna Yema"},
{id_pais:1,id_provincia:34,id_padre:7,id:1727,parametro:"Lamadrid"},
{id_pais:1,id_provincia:34,id_padre:7,id:1728,parametro:"Los Chiriguanos"},
{id_pais:1,id_provincia:34,id_padre:7,id:1729,parametro:"Pozo de Maza"},
{id_pais:1,id_provincia:34,id_padre:7,id:1730,parametro:"Pozo del Mortero"},
{id_pais:1,id_provincia:34,id_padre:7,id:1731,parametro:"Vaca Perdida"},
{id_pais:1,id_provincia:34,id_padre:14,id:1732,parametro:"Colonia Pastoril"},
{id_pais:1,id_provincia:34,id_padre:14,id:1733,parametro:"Formosa"},
{id_pais:1,id_provincia:34,id_padre:14,id:1734,parametro:"Gran Guardia"},
{id_pais:1,id_provincia:34,id_padre:14,id:1735,parametro:"Mariano Boedo"},
{id_pais:1,id_provincia:34,id_padre:14,id:1736,parametro:"Mojón de Fierro"},
{id_pais:1,id_provincia:34,id_padre:14,id:1737,parametro:"San Hilario"},
{id_pais:1,id_provincia:34,id_padre:14,id:1738,parametro:"Villa del Carmen"},
{id_pais:1,id_provincia:34,id_padre:21,id:1739,parametro:"Banco Payaguá"},
{id_pais:1,id_provincia:34,id_padre:21,id:1740,parametro:"General Lucio V. Mansilla"},
{id_pais:1,id_provincia:34,id_padre:21,id:1741,parametro:"Herradura"},
{id_pais:1,id_provincia:34,id_padre:21,id:1742,parametro:"San Francisco de Laishi"},
{id_pais:1,id_provincia:34,id_padre:21,id:1743,parametro:"Tatané"},
{id_pais:1,id_provincia:34,id_padre:21,id:1744,parametro:"Villa Escolar"},
{id_pais:1,id_provincia:34,id_padre:28,id:1745,parametro:"Ingeniero Guillermo N. Juárez"},
{id_pais:1,id_provincia:34,id_padre:35,id:1746,parametro:"Bartolomé de las Casas"},
{id_pais:1,id_provincia:34,id_padre:35,id:1747,parametro:"Colonia Sarmiento"},
{id_pais:1,id_provincia:34,id_padre:35,id:1748,parametro:"Comandante Fontana"},
{id_pais:1,id_provincia:34,id_padre:35,id:1749,parametro:"El Recreo"},
{id_pais:1,id_provincia:34,id_padre:35,id:1750,parametro:"Estanislao del Campo"},
{id_pais:1,id_provincia:34,id_padre:35,id:1751,parametro:"Fortín Cabo 1° Lugones"},
{id_pais:1,id_provincia:34,id_padre:35,id:1752,parametro:"Fortín Sargento 1° Leyes"},
{id_pais:1,id_provincia:34,id_padre:35,id:1753,parametro:"Ibarreta"},
{id_pais:1,id_provincia:34,id_padre:35,id:1754,parametro:"Juan G. Bazán"},
{id_pais:1,id_provincia:34,id_padre:35,id:1755,parametro:"Las Lomitas"},
{id_pais:1,id_provincia:34,id_padre:35,id:1756,parametro:"Posta Cambio Zalazar"},
{id_pais:1,id_provincia:34,id_padre:35,id:1757,parametro:"Pozo del Tigre"},
{id_pais:1,id_provincia:34,id_padre:35,id:1758,parametro:"San Martín I"},
{id_pais:1,id_provincia:34,id_padre:35,id:1759,parametro:"San Martín II"},
{id_pais:1,id_provincia:34,id_padre:35,id:1760,parametro:"Subteniente Perín"},
{id_pais:1,id_provincia:34,id_padre:35,id:1761,parametro:"Villa General Güemes"},
{id_pais:1,id_provincia:34,id_padre:35,id:1762,parametro:"Villa General Manuel Belgrano"},
{id_pais:1,id_provincia:34,id_padre:42,id:1763,parametro:"Buena Vista"},
{id_pais:1,id_provincia:34,id_padre:42,id:1764,parametro:"El Espinillo"},
{id_pais:1,id_provincia:34,id_padre:42,id:1765,parametro:"Laguna Gallo"},
{id_pais:1,id_provincia:34,id_padre:42,id:1766,parametro:"Misión Tacaaglé"},
{id_pais:1,id_provincia:34,id_padre:42,id:1767,parametro:"Portón Negro"},
{id_pais:1,id_provincia:34,id_padre:42,id:1768,parametro:"Tres Lagunas"},
{id_pais:1,id_provincia:34,id_padre:49,id:1769,parametro:"Clorinda"},
{id_pais:1,id_provincia:34,id_padre:49,id:1770,parametro:"Laguna Blanca"},
{id_pais:1,id_provincia:34,id_padre:49,id:1771,parametro:"Laguna Naick-Neck"},
{id_pais:1,id_provincia:34,id_padre:49,id:1772,parametro:"Palma Sola"},
{id_pais:1,id_provincia:34,id_padre:49,id:1773,parametro:"Puerto Pilcomayo"},
{id_pais:1,id_provincia:34,id_padre:49,id:1774,parametro:"Riacho He-He"},
{id_pais:1,id_provincia:34,id_padre:49,id:1775,parametro:"Riacho Negro"},
{id_pais:1,id_provincia:34,id_padre:49,id:1776,parametro:"Siete Palmas"},
{id_pais:1,id_provincia:34,id_padre:56,id:1777,parametro:"Colonia Campo Villafañe"},
{id_pais:1,id_provincia:34,id_padre:56,id:1778,parametro:"El Colorado"},
{id_pais:1,id_provincia:34,id_padre:56,id:1779,parametro:"Palo Santo"},
{id_pais:1,id_provincia:34,id_padre:56,id:1780,parametro:"Pirané"},
{id_pais:1,id_provincia:34,id_padre:56,id:1781,parametro:"Villa Kilómetro 213"},
{id_pais:1,id_provincia:34,id_padre:63,id:1782,parametro:"El Potrillo"},
{id_pais:1,id_provincia:34,id_padre:63,id:1783,parametro:"General Mosconi"},
{id_pais:1,id_provincia:34,id_padre:63,id:1784,parametro:"El Quebracho"},
{id_pais:1,id_provincia:38,id_padre:7,id:1785,parametro:"Abdón Castro Tolay"},
{id_pais:1,id_provincia:38,id_padre:7,id:1786,parametro:"Abra Pampa"},
{id_pais:1,id_provincia:38,id_padre:7,id:1787,parametro:"Abralaite"},
{id_pais:1,id_provincia:38,id_padre:7,id:1788,parametro:"Agua de Castilla"},
{id_pais:1,id_provincia:38,id_padre:7,id:1789,parametro:"Casabindo"},
{id_pais:1,id_provincia:38,id_padre:7,id:1790,parametro:"Cochinoca"},
{id_pais:1,id_provincia:38,id_padre:7,id:1791,parametro:"La Redonda"},
{id_pais:1,id_provincia:38,id_padre:7,id:1792,parametro:"Puesto del Marquéz"},
{id_pais:1,id_provincia:38,id_padre:7,id:1793,parametro:"Quebraleña"},
{id_pais:1,id_provincia:38,id_padre:7,id:1794,parametro:"Quera"},
{id_pais:1,id_provincia:38,id_padre:7,id:1795,parametro:"Rinconadillas"},
{id_pais:1,id_provincia:38,id_padre:7,id:1796,parametro:"San Francisco de Alfarcito"},
{id_pais:1,id_provincia:38,id_padre:7,id:1797,parametro:"Santa Ana de la Puna"},
{id_pais:1,id_provincia:38,id_padre:7,id:1798,parametro:"Santuario de Tres Pozos"},
{id_pais:1,id_provincia:38,id_padre:7,id:1799,parametro:"Tambillos"},
{id_pais:1,id_provincia:38,id_padre:7,id:1800,parametro:"Tusaquillas"},
{id_pais:1,id_provincia:38,id_padre:14,id:1801,parametro:"Aguas Calientes"},
{id_pais:1,id_provincia:38,id_padre:14,id:1802,parametro:"Barrio El Milagro"},
{id_pais:1,id_provincia:38,id_padre:14,id:1803,parametro:"Barrio La Unión"},
{id_pais:1,id_provincia:38,id_padre:14,id:1804,parametro:"El Carmen"},
{id_pais:1,id_provincia:38,id_padre:14,id:1805,parametro:"Los Lapachos"},
{id_pais:1,id_provincia:38,id_padre:14,id:1806,parametro:"Loteo San Vicente"},
{id_pais:1,id_provincia:38,id_padre:14,id:1807,parametro:"Manantiales"},
{id_pais:1,id_provincia:38,id_padre:14,id:1808,parametro:"Monterrico"},
{id_pais:1,id_provincia:38,id_padre:14,id:1809,parametro:"Pampa Blanca"},
{id_pais:1,id_provincia:38,id_padre:14,id:1810,parametro:"Perico"},
{id_pais:1,id_provincia:38,id_padre:14,id:1811,parametro:"Puesto Viejo"},
{id_pais:1,id_provincia:38,id_padre:14,id:1812,parametro:"San Isidro"},
{id_pais:1,id_provincia:38,id_padre:14,id:1813,parametro:"San Juancito"},
{id_pais:1,id_provincia:38,id_padre:21,id:1814,parametro:"Guerrero"},
{id_pais:1,id_provincia:38,id_padre:21,id:1815,parametro:"La Almona"},
{id_pais:1,id_provincia:38,id_padre:21,id:1816,parametro:"León"},
{id_pais:1,id_provincia:38,id_padre:21,id:1817,parametro:"Lozano"},
{id_pais:1,id_provincia:38,id_padre:21,id:1818,parametro:"Ocloyas"},
{id_pais:1,id_provincia:38,id_padre:21,id:1819,parametro:"San Salvador de Jujuy"},
{id_pais:1,id_provincia:38,id_padre:21,id:1820,parametro:"Tesorero"},
{id_pais:1,id_provincia:38,id_padre:21,id:1821,parametro:"Yala"},
{id_pais:1,id_provincia:38,id_padre:28,id:1822,parametro:"Aparzo"},
{id_pais:1,id_provincia:38,id_padre:28,id:1823,parametro:"Cianzo"},
{id_pais:1,id_provincia:38,id_padre:28,id:1824,parametro:"Coctaca"},
{id_pais:1,id_provincia:38,id_padre:28,id:1825,parametro:"El Aguilar"},
{id_pais:1,id_provincia:38,id_padre:28,id:1826,parametro:"Hipólito Yrigoyen"},
{id_pais:1,id_provincia:38,id_padre:28,id:1827,parametro:"Humahuaca"},
{id_pais:1,id_provincia:38,id_padre:28,id:1828,parametro:"Palca de Aparzo"},
{id_pais:1,id_provincia:38,id_padre:28,id:1829,parametro:"Palca de Varas"},
{id_pais:1,id_provincia:38,id_padre:28,id:1830,parametro:"Rodero"},
{id_pais:1,id_provincia:38,id_padre:28,id:1831,parametro:"Tres Cruces"},
{id_pais:1,id_provincia:38,id_padre:28,id:1832,parametro:"Uquía"},
{id_pais:1,id_provincia:38,id_padre:35,id:1833,parametro:"Bananal"},
{id_pais:1,id_provincia:38,id_padre:35,id:1834,parametro:"Bermejito"},
{id_pais:1,id_provincia:38,id_padre:35,id:1835,parametro:"Caimancito"},
{id_pais:1,id_provincia:38,id_padre:35,id:1836,parametro:"Calilegua"},
{id_pais:1,id_provincia:38,id_padre:35,id:1837,parametro:"Chalicán"},
{id_pais:1,id_provincia:38,id_padre:35,id:1838,parametro:"Fraile Pintado"},
{id_pais:1,id_provincia:38,id_padre:35,id:1839,parametro:"Libertad"},
{id_pais:1,id_provincia:38,id_padre:35,id:1840,parametro:"Libertador General San Martín"},
{id_pais:1,id_provincia:38,id_padre:35,id:1841,parametro:"Maíz Negro"},
{id_pais:1,id_provincia:38,id_padre:35,id:1842,parametro:"Paulina"},
{id_pais:1,id_provincia:38,id_padre:35,id:1843,parametro:"Yuto"},
{id_pais:1,id_provincia:38,id_padre:42,id:1844,parametro:"Carahunco"},
{id_pais:1,id_provincia:38,id_padre:42,id:1845,parametro:"Centro Forestal"},
{id_pais:1,id_provincia:38,id_padre:42,id:1846,parametro:"Palpalá"},
{id_pais:1,id_provincia:38,id_padre:49,id:1847,parametro:"Casa Colorada"},
{id_pais:1,id_provincia:38,id_padre:49,id:1848,parametro:"Coyaguaima"},
{id_pais:1,id_provincia:38,id_padre:49,id:1849,parametro:"Lagunillas de Farallón"},
{id_pais:1,id_provincia:38,id_padre:49,id:1850,parametro:"Liviara"},
{id_pais:1,id_provincia:38,id_padre:49,id:1851,parametro:"Loma Blanca"},
{id_pais:1,id_provincia:38,id_padre:49,id:1852,parametro:"Nuevo Pirquitas"},
{id_pais:1,id_provincia:38,id_padre:49,id:1853,parametro:"Orosmayo"},
{id_pais:1,id_provincia:38,id_padre:49,id:1854,parametro:"Rinconada"},
{id_pais:1,id_provincia:38,id_padre:56,id:1855,parametro:"El Ceibal"},
{id_pais:1,id_provincia:38,id_padre:56,id:1856,parametro:"Los Alisos"},
{id_pais:1,id_provincia:38,id_padre:56,id:1857,parametro:"Loteo Navea"},
{id_pais:1,id_provincia:38,id_padre:56,id:1858,parametro:"Nuestra Señora del Rosario"},
{id_pais:1,id_provincia:38,id_padre:56,id:1859,parametro:"San Antonio"},
{id_pais:1,id_provincia:38,id_padre:63,id:1860,parametro:"Arrayanal"},
{id_pais:1,id_provincia:38,id_padre:63,id:1861,parametro:"Arroyo Colorado"},
{id_pais:1,id_provincia:38,id_padre:63,id:1862,parametro:"Don Emilio"},
{id_pais:1,id_provincia:38,id_padre:63,id:1863,parametro:"El Acheral"},
{id_pais:1,id_provincia:38,id_padre:63,id:1864,parametro:"El Puesto"},
{id_pais:1,id_provincia:38,id_padre:63,id:1865,parametro:"El Quemado"},
{id_pais:1,id_provincia:38,id_padre:63,id:1866,parametro:"La Esperanza"},
{id_pais:1,id_provincia:38,id_padre:63,id:1867,parametro:"La Manga"},
{id_pais:1,id_provincia:38,id_padre:63,id:1868,parametro:"La Mendieta"},
{id_pais:1,id_provincia:38,id_padre:63,id:1869,parametro:"Miraflores"},
{id_pais:1,id_provincia:38,id_padre:63,id:1870,parametro:"Palos Blancos"},
{id_pais:1,id_provincia:38,id_padre:63,id:1871,parametro:"Parapetí"},
{id_pais:1,id_provincia:38,id_padre:63,id:1872,parametro:"Piedritas"},
{id_pais:1,id_provincia:38,id_padre:63,id:1873,parametro:"Rodeito"},
{id_pais:1,id_provincia:38,id_padre:63,id:1874,parametro:"Rosario de Río Grande (ex Barro Negro)"},
{id_pais:1,id_provincia:38,id_padre:63,id:1875,parametro:"San Antonio"},
{id_pais:1,id_provincia:38,id_padre:63,id:1876,parametro:"San Lucas"},
{id_pais:1,id_provincia:38,id_padre:63,id:1877,parametro:"San Pedro"},
{id_pais:1,id_provincia:38,id_padre:63,id:1878,parametro:"Sauzal"},
{id_pais:1,id_provincia:38,id_padre:70,id:1879,parametro:"El Fuerte"},
{id_pais:1,id_provincia:38,id_padre:70,id:1880,parametro:"El Piquete"},
{id_pais:1,id_provincia:38,id_padre:70,id:1881,parametro:"El Talar"},
{id_pais:1,id_provincia:38,id_padre:70,id:1882,parametro:"Palma Sola"},
{id_pais:1,id_provincia:38,id_padre:70,id:1883,parametro:"Puente Lavayén"},
{id_pais:1,id_provincia:38,id_padre:70,id:1884,parametro:"Santa Clara"},
{id_pais:1,id_provincia:38,id_padre:70,id:1885,parametro:"Vinalito"},
{id_pais:1,id_provincia:38,id_padre:77,id:1886,parametro:"Casira"},
{id_pais:1,id_provincia:38,id_padre:77,id:1887,parametro:"Ciénega de Paicone"},
{id_pais:1,id_provincia:38,id_padre:77,id:1888,parametro:"Cieneguillas"},
{id_pais:1,id_provincia:38,id_padre:77,id:1889,parametro:"Cusi Cusi"},
{id_pais:1,id_provincia:38,id_padre:77,id:1890,parametro:"El Angosto"},
{id_pais:1,id_provincia:38,id_padre:77,id:1891,parametro:"La Ciénega"},
{id_pais:1,id_provincia:38,id_padre:77,id:1892,parametro:"Misarrumi"},
{id_pais:1,id_provincia:38,id_padre:77,id:1893,parametro:"Oratorio"},
{id_pais:1,id_provincia:38,id_padre:77,id:1894,parametro:"Paicone"},
{id_pais:1,id_provincia:38,id_padre:77,id:1895,parametro:"San Juan de Oros"},
{id_pais:1,id_provincia:38,id_padre:77,id:1896,parametro:"Santa Catalina"},
{id_pais:1,id_provincia:38,id_padre:77,id:1897,parametro:"Yoscaba"},
{id_pais:1,id_provincia:38,id_padre:84,id:1898,parametro:"Catua"},
{id_pais:1,id_provincia:38,id_padre:84,id:1899,parametro:"Coranzuli"},
{id_pais:1,id_provincia:38,id_padre:84,id:1900,parametro:"El Toro"},
{id_pais:1,id_provincia:38,id_padre:84,id:1901,parametro:"Huáncar"},
{id_pais:1,id_provincia:38,id_padre:84,id:1902,parametro:"Jama"},
{id_pais:1,id_provincia:38,id_padre:84,id:1903,parametro:"Mina Providencia"},
{id_pais:1,id_provincia:38,id_padre:84,id:1904,parametro:"Olacapato"},
{id_pais:1,id_provincia:38,id_padre:84,id:1905,parametro:"Olaroz Chico"},
{id_pais:1,id_provincia:38,id_padre:84,id:1906,parametro:"Pastos Chicos"},
{id_pais:1,id_provincia:38,id_padre:84,id:1907,parametro:"Puesto Sey"},
{id_pais:1,id_provincia:38,id_padre:84,id:1908,parametro:"San Juan de Quillaqués"},
{id_pais:1,id_provincia:38,id_padre:84,id:1909,parametro:"Susques"},
{id_pais:1,id_provincia:38,id_padre:94,id:1910,parametro:"Colonia San José"},
{id_pais:1,id_provincia:38,id_padre:94,id:1911,parametro:"Huacalera"},
{id_pais:1,id_provincia:38,id_padre:94,id:1912,parametro:"Juella"},
{id_pais:1,id_provincia:38,id_padre:94,id:1913,parametro:"Maimará"},
{id_pais:1,id_provincia:38,id_padre:94,id:1914,parametro:"Tilcara"},
{id_pais:1,id_provincia:38,id_padre:98,id:1915,parametro:"Bárcena"},
{id_pais:1,id_provincia:38,id_padre:98,id:1916,parametro:"El Moreno"},
{id_pais:1,id_provincia:38,id_padre:98,id:1917,parametro:"Puerta de Colorados"},
{id_pais:1,id_provincia:38,id_padre:98,id:1918,parametro:"Purmamarca"},
{id_pais:1,id_provincia:38,id_padre:98,id:1919,parametro:"Tumbaya"},
{id_pais:1,id_provincia:38,id_padre:98,id:1920,parametro:"Volcán"},
{id_pais:1,id_provincia:38,id_padre:105,id:1921,parametro:"Caspalá"},
{id_pais:1,id_provincia:38,id_padre:105,id:1922,parametro:"Pampichuela"},
{id_pais:1,id_provincia:38,id_padre:105,id:1923,parametro:"San Francisco"},
{id_pais:1,id_provincia:38,id_padre:105,id:1924,parametro:"Santa Ana"},
{id_pais:1,id_provincia:38,id_padre:105,id:1925,parametro:"Valle Colorado"},
{id_pais:1,id_provincia:38,id_padre:105,id:1926,parametro:"Valle Grande"},
{id_pais:1,id_provincia:38,id_padre:112,id:1927,parametro:"Barrios"},
{id_pais:1,id_provincia:38,id_padre:112,id:1928,parametro:"Cangrejillos"},
{id_pais:1,id_provincia:38,id_padre:112,id:1929,parametro:"El Cóndor"},
{id_pais:1,id_provincia:38,id_padre:112,id:1930,parametro:"La Intermedia"},
{id_pais:1,id_provincia:38,id_padre:112,id:1931,parametro:"La Quiaca"},
{id_pais:1,id_provincia:38,id_padre:112,id:1932,parametro:"Llulluchayoc"},
{id_pais:1,id_provincia:38,id_padre:112,id:1933,parametro:"Pumahuasi"},
{id_pais:1,id_provincia:38,id_padre:112,id:1934,parametro:"Yavi"},
{id_pais:1,id_provincia:38,id_padre:112,id:1935,parametro:"Yavi Chico"},
{id_pais:1,id_provincia:42,id_padre:7,id:1936,parametro:"Doblas"},
{id_pais:1,id_provincia:42,id_padre:7,id:1937,parametro:"Macachín"},
{id_pais:1,id_provincia:42,id_padre:7,id:1938,parametro:"Miguel Riglos"},
{id_pais:1,id_provincia:42,id_padre:7,id:1939,parametro:"Rolón"},
{id_pais:1,id_provincia:42,id_padre:7,id:1940,parametro:"Tomás M. Anchorena"},
{id_pais:1,id_provincia:42,id_padre:14,id:1941,parametro:"Anzoátegui"},
{id_pais:1,id_provincia:42,id_padre:14,id:1942,parametro:"La Adela"},
{id_pais:1,id_provincia:42,id_padre:21,id:1943,parametro:"Anguil"},
{id_pais:1,id_provincia:42,id_padre:21,id:1944,parametro:"Santa Rosa"},
{id_pais:1,id_provincia:42,id_padre:28,id:1945,parametro:"Catriló"},
{id_pais:1,id_provincia:42,id_padre:28,id:1946,parametro:"La Gloria"},
{id_pais:1,id_provincia:42,id_padre:28,id:1947,parametro:"Lonquimay"},
{id_pais:1,id_provincia:42,id_padre:28,id:1948,parametro:"Uriburu"},
{id_pais:1,id_provincia:42,id_padre:35,id:1949,parametro:"Conhelo"},
{id_pais:1,id_provincia:42,id_padre:35,id:1950,parametro:"Eduardo Castex"},
{id_pais:1,id_provincia:42,id_padre:35,id:1951,parametro:"Mauricio Mayer"},
{id_pais:1,id_provincia:42,id_padre:35,id:1952,parametro:"Monte Nievas"},
{id_pais:1,id_provincia:42,id_padre:35,id:1953,parametro:"Rucanelo"},
{id_pais:1,id_provincia:42,id_padre:35,id:1954,parametro:"Winifreda"},
{id_pais:1,id_provincia:42,id_padre:42,id:1955,parametro:"Gobernador Duval"},
{id_pais:1,id_provincia:42,id_padre:42,id:1956,parametro:"Puelches"},
{id_pais:1,id_provincia:42,id_padre:49,id:1957,parametro:"Santa Isabel"},
{id_pais:1,id_provincia:42,id_padre:56,id:1958,parametro:"Bernardo Larroude"},
{id_pais:1,id_provincia:42,id_padre:56,id:1959,parametro:"Ceballos"},
{id_pais:1,id_provincia:42,id_padre:56,id:1960,parametro:"Coronel Hilario Lagos"},
{id_pais:1,id_provincia:42,id_padre:56,id:1961,parametro:"Intendente Alvear"},
{id_pais:1,id_provincia:42,id_padre:56,id:1962,parametro:"Sarah"},
{id_pais:1,id_provincia:42,id_padre:56,id:1963,parametro:"Vértiz"},
{id_pais:1,id_provincia:42,id_padre:63,id:1964,parametro:"Algarrobo del águila"},
{id_pais:1,id_provincia:42,id_padre:63,id:1965,parametro:"La Humada"},
{id_pais:1,id_provincia:42,id_padre:70,id:1966,parametro:"Alpachiri"},
{id_pais:1,id_provincia:42,id_padre:70,id:1967,parametro:"General Manuel J. Campos"},
{id_pais:1,id_provincia:42,id_padre:70,id:1968,parametro:"Guatraché"},
{id_pais:1,id_provincia:42,id_padre:70,id:1969,parametro:"Perú"},
{id_pais:1,id_provincia:42,id_padre:70,id:1970,parametro:"Santa Teresa"},
{id_pais:1,id_provincia:42,id_padre:77,id:1971,parametro:"Abramo"},
{id_pais:1,id_provincia:42,id_padre:77,id:1972,parametro:"Bernasconi"},
{id_pais:1,id_provincia:42,id_padre:77,id:1973,parametro:"General San Martín"},
{id_pais:1,id_provincia:42,id_padre:77,id:1974,parametro:"Hucal"},
{id_pais:1,id_provincia:42,id_padre:77,id:1975,parametro:"Jacinto Aráuz"},
{id_pais:1,id_provincia:42,id_padre:84,id:1976,parametro:"Cuchillo Co"},
{id_pais:1,id_provincia:42,id_padre:91,id:1977,parametro:"La Reforma"},
{id_pais:1,id_provincia:42,id_padre:91,id:1978,parametro:"Limay Mahuida"},
{id_pais:1,id_provincia:42,id_padre:98,id:1979,parametro:"Carro Quemado"},
{id_pais:1,id_provincia:42,id_padre:98,id:1980,parametro:"Loventué"},
{id_pais:1,id_provincia:42,id_padre:98,id:1981,parametro:"Luan Toro"},
{id_pais:1,id_provincia:42,id_padre:98,id:1982,parametro:"Telén"},
{id_pais:1,id_provincia:42,id_padre:98,id:1983,parametro:"Victorica"},
{id_pais:1,id_provincia:42,id_padre:105,id:1984,parametro:"Agustoni"},
{id_pais:1,id_provincia:42,id_padre:105,id:1985,parametro:"Dorila"},
{id_pais:1,id_provincia:42,id_padre:105,id:1986,parametro:"General Pico"},
{id_pais:1,id_provincia:42,id_padre:105,id:1987,parametro:"Speluzzi"},
{id_pais:1,id_provincia:42,id_padre:105,id:1988,parametro:"Trebolares"},
{id_pais:1,id_provincia:42,id_padre:112,id:1989,parametro:"Casa de Piedra"},
{id_pais:1,id_provincia:42,id_padre:112,id:1990,parametro:"Puelén"},
{id_pais:1,id_provincia:42,id_padre:112,id:1991,parametro:"25 de Mayo"},
{id_pais:1,id_provincia:42,id_padre:119,id:1992,parametro:"Colonia Barón"},
{id_pais:1,id_provincia:42,id_padre:119,id:1993,parametro:"Colonia San José"},
{id_pais:1,id_provincia:42,id_padre:119,id:1994,parametro:"Miguel Cané"},
{id_pais:1,id_provincia:42,id_padre:119,id:1995,parametro:"Quemú Quemú"},
{id_pais:1,id_provincia:42,id_padre:119,id:1996,parametro:"Relmo"},
{id_pais:1,id_provincia:42,id_padre:119,id:1997,parametro:"Villa Mirasol"},
{id_pais:1,id_provincia:42,id_padre:126,id:1998,parametro:"Caleufú"},
{id_pais:1,id_provincia:42,id_padre:126,id:1999,parametro:"Ingeniero Foster"},
{id_pais:1,id_provincia:42,id_padre:126,id:2000,parametro:"La Maruja"},
{id_pais:1,id_provincia:42,id_padre:126,id:2001,parametro:"Parera"},
{id_pais:1,id_provincia:42,id_padre:126,id:2002,parametro:"Pichi Huinca"},
{id_pais:1,id_provincia:42,id_padre:126,id:2003,parametro:"Quetrequén"},
{id_pais:1,id_provincia:42,id_padre:126,id:2004,parametro:"Rancul"},
{id_pais:1,id_provincia:42,id_padre:133,id:2005,parametro:"Adolfo Van Praet"},
{id_pais:1,id_provincia:42,id_padre:133,id:2006,parametro:"Alta Italia"},
{id_pais:1,id_provincia:42,id_padre:133,id:2007,parametro:"Damián Maisonave"},
{id_pais:1,id_provincia:42,id_padre:133,id:2008,parametro:"Embajador Martini"},
{id_pais:1,id_provincia:42,id_padre:133,id:2009,parametro:"Falucho"},
{id_pais:1,id_provincia:42,id_padre:133,id:2010,parametro:"Ingeniero Luiggi"},
{id_pais:1,id_provincia:42,id_padre:133,id:2011,parametro:"Ojeda"},
{id_pais:1,id_provincia:42,id_padre:133,id:2012,parametro:"Realicó"},
{id_pais:1,id_provincia:42,id_padre:140,id:2013,parametro:"Cachirulo"},
{id_pais:1,id_provincia:42,id_padre:140,id:2014,parametro:"Naicó"},
{id_pais:1,id_provincia:42,id_padre:140,id:2015,parametro:"Toay"},
{id_pais:1,id_provincia:42,id_padre:147,id:2016,parametro:"Arata"},
{id_pais:1,id_provincia:42,id_padre:147,id:2017,parametro:"Metileo"},
{id_pais:1,id_provincia:42,id_padre:147,id:2018,parametro:"Trenel"},
{id_pais:1,id_provincia:42,id_padre:154,id:2019,parametro:"Ataliva Roca"},
{id_pais:1,id_provincia:42,id_padre:154,id:2020,parametro:"Chacharramendi"},
{id_pais:1,id_provincia:42,id_padre:154,id:2021,parametro:"Colonia Santa María"},
{id_pais:1,id_provincia:42,id_padre:154,id:2022,parametro:"General Acha"},
{id_pais:1,id_provincia:42,id_padre:154,id:2023,parametro:"Quehué"},
{id_pais:1,id_provincia:42,id_padre:154,id:2024,parametro:"Unanué"},
{id_pais:1,id_provincia:46,id_padre:7,id:2025,parametro:"Aimogasta"},
{id_pais:1,id_provincia:46,id_padre:7,id:2026,parametro:"Arauco"},
{id_pais:1,id_provincia:46,id_padre:7,id:2027,parametro:"Bañado de los Pantanos"},
{id_pais:1,id_provincia:46,id_padre:7,id:2028,parametro:"Estación Mazán"},
{id_pais:1,id_provincia:46,id_padre:7,id:2029,parametro:"Termas de Santa Teresita"},
{id_pais:1,id_provincia:46,id_padre:7,id:2030,parametro:"Villa Mazán"},
{id_pais:1,id_provincia:46,id_padre:14,id:2031,parametro:"La Rioja"},
{id_pais:1,id_provincia:46,id_padre:21,id:2032,parametro:"Aminga"},
{id_pais:1,id_provincia:46,id_padre:21,id:2033,parametro:"Anillaco"},
{id_pais:1,id_provincia:46,id_padre:21,id:2034,parametro:"Anjullón"},
{id_pais:1,id_provincia:46,id_padre:21,id:2035,parametro:"Chuquis"},
{id_pais:1,id_provincia:46,id_padre:21,id:2036,parametro:"Los Molinos"},
{id_pais:1,id_provincia:46,id_padre:21,id:2037,parametro:"Pinchas"},
{id_pais:1,id_provincia:46,id_padre:21,id:2038,parametro:"San Pedro"},
{id_pais:1,id_provincia:46,id_padre:21,id:2039,parametro:"Santa Vera Cruz"},
{id_pais:1,id_provincia:46,id_padre:28,id:2040,parametro:"Aicuñá"},
{id_pais:1,id_provincia:46,id_padre:28,id:2041,parametro:"Guandacol"},
{id_pais:1,id_provincia:46,id_padre:28,id:2042,parametro:"Los Palacios"},
{id_pais:1,id_provincia:46,id_padre:28,id:2043,parametro:"Pagancillo"},
{id_pais:1,id_provincia:46,id_padre:28,id:2044,parametro:"Villa Unión"},
{id_pais:1,id_provincia:46,id_padre:35,id:2045,parametro:"Chamical"},
{id_pais:1,id_provincia:46,id_padre:35,id:2046,parametro:"Polco"},
{id_pais:1,id_provincia:46,id_padre:42,id:2047,parametro:"Chilecito"},
{id_pais:1,id_provincia:46,id_padre:42,id:2048,parametro:"Colonia Anguinán"},
{id_pais:1,id_provincia:46,id_padre:42,id:2049,parametro:"Colonia Catinzaco"},
{id_pais:1,id_provincia:46,id_padre:42,id:2050,parametro:"Colonia Malligasta"},
{id_pais:1,id_provincia:46,id_padre:42,id:2051,parametro:"Colonia Vichigasta"},
{id_pais:1,id_provincia:46,id_padre:42,id:2052,parametro:"Guanchín"},
{id_pais:1,id_provincia:46,id_padre:42,id:2053,parametro:"Malligasta"},
{id_pais:1,id_provincia:46,id_padre:42,id:2054,parametro:"Miranda"},
{id_pais:1,id_provincia:46,id_padre:42,id:2055,parametro:"Nonogasta"},
{id_pais:1,id_provincia:46,id_padre:42,id:2056,parametro:"San Nicolás"},
{id_pais:1,id_provincia:46,id_padre:42,id:2057,parametro:"Santa Florentina"},
{id_pais:1,id_provincia:46,id_padre:42,id:2058,parametro:"Sañogasta"},
{id_pais:1,id_provincia:46,id_padre:42,id:2059,parametro:"Tilimuqui"},
{id_pais:1,id_provincia:46,id_padre:42,id:2060,parametro:"Vichigasta"},
{id_pais:1,id_provincia:46,id_padre:49,id:2061,parametro:"Alto Carrizal"},
{id_pais:1,id_provincia:46,id_padre:49,id:2062,parametro:"Angulos"},
{id_pais:1,id_provincia:46,id_padre:49,id:2063,parametro:"Antinaco"},
{id_pais:1,id_provincia:46,id_padre:49,id:2064,parametro:"Bajo Carrizal"},
{id_pais:1,id_provincia:46,id_padre:49,id:2065,parametro:"Campanas"},
{id_pais:1,id_provincia:46,id_padre:49,id:2066,parametro:"Chañarmuyo"},
{id_pais:1,id_provincia:46,id_padre:49,id:2067,parametro:"Famatina"},
{id_pais:1,id_provincia:46,id_padre:49,id:2068,parametro:"La Cuadra"},
{id_pais:1,id_provincia:46,id_padre:49,id:2069,parametro:"Pituil"},
{id_pais:1,id_provincia:46,id_padre:49,id:2070,parametro:"Plaza Vieja"},
{id_pais:1,id_provincia:46,id_padre:49,id:2071,parametro:"Santa Cruz"},
{id_pais:1,id_provincia:46,id_padre:49,id:2072,parametro:"Santo Domingo"},
{id_pais:1,id_provincia:46,id_padre:56,id:2073,parametro:"Punta de los Llanos"},
{id_pais:1,id_provincia:46,id_padre:56,id:2074,parametro:"Tama"},
{id_pais:1,id_provincia:46,id_padre:63,id:2075,parametro:"Castro Barros"},
{id_pais:1,id_provincia:46,id_padre:63,id:2076,parametro:"Chañar"},
{id_pais:1,id_provincia:46,id_padre:63,id:2077,parametro:"Loma Blanca"},
{id_pais:1,id_provincia:46,id_padre:63,id:2078,parametro:"Olta"},
{id_pais:1,id_provincia:46,id_padre:70,id:2079,parametro:"Malanzán"},
{id_pais:1,id_provincia:46,id_padre:70,id:2080,parametro:"Nácate"},
{id_pais:1,id_provincia:46,id_padre:70,id:2081,parametro:"Portezuelo"},
{id_pais:1,id_provincia:46,id_padre:70,id:2082,parametro:"San Antonio"},
{id_pais:1,id_provincia:46,id_padre:77,id:2083,parametro:"Villa Castelli"},
{id_pais:1,id_provincia:46,id_padre:84,id:2084,parametro:"Ambil"},
{id_pais:1,id_provincia:46,id_padre:84,id:2085,parametro:"Colonia Ortiz de Ocampo"},
{id_pais:1,id_provincia:46,id_padre:84,id:2086,parametro:"Milagro"},
{id_pais:1,id_provincia:46,id_padre:84,id:2087,parametro:"Olpas"},
{id_pais:1,id_provincia:46,id_padre:84,id:2088,parametro:"Santa Rita de Catuna"},
{id_pais:1,id_provincia:46,id_padre:91,id:2089,parametro:"Ulapes"},
{id_pais:1,id_provincia:46,id_padre:98,id:2090,parametro:"Jagüé"},
{id_pais:1,id_provincia:46,id_padre:98,id:2091,parametro:"Villa San José de Vinchina"},
{id_pais:1,id_provincia:46,id_padre:105,id:2092,parametro:"Amaná"},
{id_pais:1,id_provincia:46,id_padre:105,id:2093,parametro:"Patquía"},
{id_pais:1,id_provincia:46,id_padre:112,id:2094,parametro:"Chepes"},
{id_pais:1,id_provincia:46,id_padre:112,id:2095,parametro:"Desiderio Tello"},
{id_pais:1,id_provincia:46,id_padre:119,id:2096,parametro:"Salicas - San Blas"},
{id_pais:1,id_provincia:46,id_padre:126,id:2097,parametro:"Villa Sanagasta"},
{id_pais:1,id_provincia:50,id_padre:7,id:2098,parametro:"Mendoza"},
{id_pais:1,id_provincia:50,id_padre:14,id:2099,parametro:"Bowen"},
{id_pais:1,id_provincia:50,id_padre:14,id:2100,parametro:"Carmensa"},
{id_pais:1,id_provincia:50,id_padre:14,id:2101,parametro:"General Alvear"},
{id_pais:1,id_provincia:50,id_padre:14,id:2102,parametro:"Los Compartos"},
{id_pais:1,id_provincia:50,id_padre:21,id:2103,parametro:"Godoy Cruz"},
{id_pais:1,id_provincia:50,id_padre:28,id:2104,parametro:"Colonia Segovia"},
{id_pais:1,id_provincia:50,id_padre:28,id:2105,parametro:"Guaymallén"},
{id_pais:1,id_provincia:50,id_padre:28,id:2106,parametro:"La Primavera"},
{id_pais:1,id_provincia:50,id_padre:28,id:2107,parametro:"Los Corralitos"},
{id_pais:1,id_provincia:50,id_padre:28,id:2108,parametro:"Puente de Hierro"},
{id_pais:1,id_provincia:50,id_padre:35,id:2109,parametro:"Ingeniero Giagnoni"},
{id_pais:1,id_provincia:50,id_padre:35,id:2110,parametro:"Junín"},
{id_pais:1,id_provincia:50,id_padre:35,id:2111,parametro:"La Colonia"},
{id_pais:1,id_provincia:50,id_padre:35,id:2112,parametro:"Los Barriales"},
{id_pais:1,id_provincia:50,id_padre:35,id:2113,parametro:"Medrano"},
{id_pais:1,id_provincia:50,id_padre:35,id:2114,parametro:"Phillips"},
{id_pais:1,id_provincia:50,id_padre:35,id:2115,parametro:"Rodríguez Peña"},
{id_pais:1,id_provincia:50,id_padre:42,id:2116,parametro:"Desaguadero"},
{id_pais:1,id_provincia:50,id_padre:42,id:2117,parametro:"La Paz"},
{id_pais:1,id_provincia:50,id_padre:42,id:2118,parametro:"Villa Antigua"},
{id_pais:1,id_provincia:50,id_padre:49,id:2119,parametro:"Blanco Encalada"},
{id_pais:1,id_provincia:50,id_padre:49,id:2120,parametro:"Jocolí"},
{id_pais:1,id_provincia:50,id_padre:49,id:2121,parametro:"Las Cuevas"},
{id_pais:1,id_provincia:50,id_padre:49,id:2122,parametro:"Las Heras"},
{id_pais:1,id_provincia:50,id_padre:49,id:2123,parametro:"Los Penitentes"},
{id_pais:1,id_provincia:50,id_padre:49,id:2124,parametro:"Polvaredas"},
{id_pais:1,id_provincia:50,id_padre:49,id:2125,parametro:"Puente del Inca"},
{id_pais:1,id_provincia:50,id_padre:49,id:2126,parametro:"Punta de Vacas"},
{id_pais:1,id_provincia:50,id_padre:49,id:2127,parametro:"Uspallata"},
{id_pais:1,id_provincia:50,id_padre:56,id:2128,parametro:"Barrio Alto del Olvido"},
{id_pais:1,id_provincia:50,id_padre:56,id:2129,parametro:"Barrio Jocolí II"},
{id_pais:1,id_provincia:50,id_padre:56,id:2130,parametro:"Barrio La Palmera"},
{id_pais:1,id_provincia:50,id_padre:56,id:2131,parametro:"Barrio La Pega"},
{id_pais:1,id_provincia:50,id_padre:56,id:2132,parametro:"Barrio Lagunas de Bartoluzzi"},
{id_pais:1,id_provincia:50,id_padre:56,id:2133,parametro:"Barrio Los Jarilleros"},
{id_pais:1,id_provincia:50,id_padre:56,id:2134,parametro:"Barrio Los Olivos"},
{id_pais:1,id_provincia:50,id_padre:56,id:2135,parametro:"Barrio Virgen del Rosario"},
{id_pais:1,id_provincia:50,id_padre:56,id:2136,parametro:"Costa de Araujo"},
{id_pais:1,id_provincia:50,id_padre:56,id:2137,parametro:"El Paramillo"},
{id_pais:1,id_provincia:50,id_padre:56,id:2138,parametro:"El Vergel"},
{id_pais:1,id_provincia:50,id_padre:56,id:2139,parametro:"Ingeniero Gustavo André"},
{id_pais:1,id_provincia:50,id_padre:56,id:2140,parametro:"Jocolí"},
{id_pais:1,id_provincia:50,id_padre:56,id:2141,parametro:"Jocolí Viejo"},
{id_pais:1,id_provincia:50,id_padre:56,id:2142,parametro:"Las Violetas"},
{id_pais:1,id_provincia:50,id_padre:56,id:2143,parametro:"3 de Mayo"},
{id_pais:1,id_provincia:50,id_padre:56,id:2144,parametro:"Villa Tulumaya"},
{id_pais:1,id_provincia:50,id_padre:63,id:2145,parametro:"Agrelo"},
{id_pais:1,id_provincia:50,id_padre:63,id:2146,parametro:"Barrio Perdriel IV"},
{id_pais:1,id_provincia:50,id_padre:63,id:2147,parametro:"Cacheuta"},
{id_pais:1,id_provincia:50,id_padre:63,id:2148,parametro:"Costa Flores"},
{id_pais:1,id_provincia:50,id_padre:63,id:2149,parametro:"El Carrizal"},
{id_pais:1,id_provincia:50,id_padre:63,id:2150,parametro:"El Salto"},
{id_pais:1,id_provincia:50,id_padre:63,id:2151,parametro:"Las Compuertas"},
{id_pais:1,id_provincia:50,id_padre:63,id:2152,parametro:"Las Vegas"},
{id_pais:1,id_provincia:50,id_padre:63,id:2153,parametro:"Luján de Cuyo"},
{id_pais:1,id_provincia:50,id_padre:63,id:2154,parametro:"Perdriel"},
{id_pais:1,id_provincia:50,id_padre:63,id:2155,parametro:"Potrerillos"},
{id_pais:1,id_provincia:50,id_padre:63,id:2156,parametro:"Ugarteche"},
{id_pais:1,id_provincia:50,id_padre:70,id:2157,parametro:"Barrancas"},
{id_pais:1,id_provincia:50,id_padre:70,id:2158,parametro:"Barrio Jesús de Nazaret"},
{id_pais:1,id_provincia:50,id_padre:70,id:2159,parametro:"Cruz de Piedra"},
{id_pais:1,id_provincia:50,id_padre:70,id:2160,parametro:"El Pedregal"},
{id_pais:1,id_provincia:50,id_padre:70,id:2161,parametro:"Fray Luis Beltrán"},
{id_pais:1,id_provincia:50,id_padre:70,id:2162,parametro:"Maipú"},
{id_pais:1,id_provincia:50,id_padre:70,id:2163,parametro:"Rodeo del Medio"},
{id_pais:1,id_provincia:50,id_padre:70,id:2164,parametro:"Russell"},
{id_pais:1,id_provincia:50,id_padre:70,id:2165,parametro:"San Roque"},
{id_pais:1,id_provincia:50,id_padre:70,id:2166,parametro:"Villa Teresa"},
{id_pais:1,id_provincia:50,id_padre:77,id:2167,parametro:"Agua Escondida"},
{id_pais:1,id_provincia:50,id_padre:77,id:2168,parametro:"Las Leñas"},
{id_pais:1,id_provincia:50,id_padre:77,id:2169,parametro:"Malargüe"},
{id_pais:1,id_provincia:50,id_padre:84,id:2170,parametro:"Andrade"},
{id_pais:1,id_provincia:50,id_padre:84,id:2171,parametro:"Barrio Cooperativa Los Campamentos"},
{id_pais:1,id_provincia:50,id_padre:84,id:2172,parametro:"Barrio Rivadavia"},
{id_pais:1,id_provincia:50,id_padre:84,id:2173,parametro:"El Mirador"},
{id_pais:1,id_provincia:50,id_padre:84,id:2174,parametro:"La Central"},
{id_pais:1,id_provincia:50,id_padre:84,id:2175,parametro:"La Esperanza"},
{id_pais:1,id_provincia:50,id_padre:84,id:2176,parametro:"La Florida"},
{id_pais:1,id_provincia:50,id_padre:84,id:2177,parametro:"La Libertad"},
{id_pais:1,id_provincia:50,id_padre:84,id:2178,parametro:"Los árboles"},
{id_pais:1,id_provincia:50,id_padre:84,id:2179,parametro:"Los Campamentos"},
{id_pais:1,id_provincia:50,id_padre:84,id:2180,parametro:"Medrano"},
{id_pais:1,id_provincia:50,id_padre:84,id:2181,parametro:"Mundo Nuevo"},
{id_pais:1,id_provincia:50,id_padre:84,id:2182,parametro:"Reducción de Abajo"},
{id_pais:1,id_provincia:50,id_padre:84,id:2183,parametro:"Rivadavia"},
{id_pais:1,id_provincia:50,id_padre:84,id:2184,parametro:"Santa María de Oro"},
{id_pais:1,id_provincia:50,id_padre:91,id:2185,parametro:"Barrio Carrasco"},
{id_pais:1,id_provincia:50,id_padre:91,id:2186,parametro:"Barrio El Cepillo"},
{id_pais:1,id_provincia:50,id_padre:91,id:2187,parametro:"Chilecito"},
{id_pais:1,id_provincia:50,id_padre:91,id:2188,parametro:"Eugenio Bustos"},
{id_pais:1,id_provincia:50,id_padre:91,id:2189,parametro:"La Consulta"},
{id_pais:1,id_provincia:50,id_padre:91,id:2190,parametro:"Pareditas"},
{id_pais:1,id_provincia:50,id_padre:91,id:2191,parametro:"San Carlos"},
{id_pais:1,id_provincia:50,id_padre:98,id:2192,parametro:"Alto Verde"},
{id_pais:1,id_provincia:50,id_padre:98,id:2193,parametro:"Barrio Chivilcoy"},
{id_pais:1,id_provincia:50,id_padre:98,id:2194,parametro:"Barrio Emanuel"},
{id_pais:1,id_provincia:50,id_padre:98,id:2195,parametro:"Barrio La Estación"},
{id_pais:1,id_provincia:50,id_padre:98,id:2196,parametro:"Barrio Los Charabones"},
{id_pais:1,id_provincia:50,id_padre:98,id:2197,parametro:"Barrio Ntra. Sra. De Fátima"},
{id_pais:1,id_provincia:50,id_padre:98,id:2198,parametro:"Chapanay"},
{id_pais:1,id_provincia:50,id_padre:98,id:2199,parametro:"Chivilcoy"},
{id_pais:1,id_provincia:50,id_padre:98,id:2200,parametro:"El Espino"},
{id_pais:1,id_provincia:50,id_padre:98,id:2201,parametro:"El Ramblón"},
{id_pais:1,id_provincia:50,id_padre:98,id:2202,parametro:"Montecaseros"},
{id_pais:1,id_provincia:50,id_padre:98,id:2203,parametro:"Nueva California"},
{id_pais:1,id_provincia:50,id_padre:98,id:2204,parametro:"San Martín"},
{id_pais:1,id_provincia:50,id_padre:98,id:2205,parametro:"Tres Porteñas"},
{id_pais:1,id_provincia:50,id_padre:105,id:2206,parametro:"Barrio Campos El Toledano"},
{id_pais:1,id_provincia:50,id_padre:105,id:2207,parametro:"Barrio El Nevado"},
{id_pais:1,id_provincia:50,id_padre:105,id:2208,parametro:"Barrio Empleados de Comercio"},
{id_pais:1,id_provincia:50,id_padre:105,id:2209,parametro:"Barrio Intendencia"},
{id_pais:1,id_provincia:50,id_padre:105,id:2210,parametro:"Capitán Montoya"},
{id_pais:1,id_provincia:50,id_padre:105,id:2211,parametro:"Cuadro Benegas"},
{id_pais:1,id_provincia:50,id_padre:105,id:2212,parametro:"El Nihuil"},
{id_pais:1,id_provincia:50,id_padre:105,id:2213,parametro:"El Sosneado"},
{id_pais:1,id_provincia:50,id_padre:105,id:2214,parametro:"El Tropezón"},
{id_pais:1,id_provincia:50,id_padre:105,id:2215,parametro:"Goudge"},
{id_pais:1,id_provincia:50,id_padre:105,id:2216,parametro:"Jaime Prats"},
{id_pais:1,id_provincia:50,id_padre:105,id:2217,parametro:"La Llave Nueva"},
{id_pais:1,id_provincia:50,id_padre:105,id:2218,parametro:"Las Malvinas"},
{id_pais:1,id_provincia:50,id_padre:105,id:2219,parametro:"Los Reyunos"},
{id_pais:1,id_provincia:50,id_padre:105,id:2220,parametro:"Monte Comán"},
{id_pais:1,id_provincia:50,id_padre:105,id:2221,parametro:"Pobre Diablo"},
{id_pais:1,id_provincia:50,id_padre:105,id:2222,parametro:"Punta del Agua"},
{id_pais:1,id_provincia:50,id_padre:105,id:2223,parametro:"Rama Caída"},
{id_pais:1,id_provincia:50,id_padre:105,id:2224,parametro:"Real del Padre"},
{id_pais:1,id_provincia:50,id_padre:105,id:2225,parametro:"Salto de las Rosas"},
{id_pais:1,id_provincia:50,id_padre:105,id:2226,parametro:"San Rafael"},
{id_pais:1,id_provincia:50,id_padre:105,id:2227,parametro:"25 de Mayo"},
{id_pais:1,id_provincia:50,id_padre:105,id:2228,parametro:"Villa Atuel"},
{id_pais:1,id_provincia:50,id_padre:105,id:2229,parametro:"Villa Atuel Norte"},
{id_pais:1,id_provincia:50,id_padre:112,id:2230,parametro:"Barrio 12 de Octubre"},
{id_pais:1,id_provincia:50,id_padre:112,id:2231,parametro:"Barrio María Auxiliadora"},
{id_pais:1,id_provincia:50,id_padre:112,id:2232,parametro:"Barrio Molina Cabrera"},
{id_pais:1,id_provincia:50,id_padre:112,id:2233,parametro:"La Dormida"},
{id_pais:1,id_provincia:50,id_padre:112,id:2234,parametro:"Las Catitas"},
{id_pais:1,id_provincia:50,id_padre:112,id:2235,parametro:"Santa Rosa"},
{id_pais:1,id_provincia:50,id_padre:119,id:2236,parametro:"Barrio San Cayetano"},
{id_pais:1,id_provincia:50,id_padre:119,id:2237,parametro:"Campo Los Andes"},
{id_pais:1,id_provincia:50,id_padre:119,id:2238,parametro:"Colonia Las Rosas"},
{id_pais:1,id_provincia:50,id_padre:119,id:2239,parametro:"El Manzano"},
{id_pais:1,id_provincia:50,id_padre:119,id:2240,parametro:"Los Sauces"},
{id_pais:1,id_provincia:50,id_padre:119,id:2241,parametro:"Tunuyán"},
{id_pais:1,id_provincia:50,id_padre:119,id:2242,parametro:"Vista Flores"},
{id_pais:1,id_provincia:50,id_padre:126,id:2243,parametro:"Barrio Belgrano Norte"},
{id_pais:1,id_provincia:50,id_padre:126,id:2244,parametro:"Cordón del Plata"},
{id_pais:1,id_provincia:50,id_padre:126,id:2245,parametro:"El Peral"},
{id_pais:1,id_provincia:50,id_padre:126,id:2246,parametro:"El Zampal"},
{id_pais:1,id_provincia:50,id_padre:126,id:2247,parametro:"La Arboleda"},
{id_pais:1,id_provincia:50,id_padre:126,id:2248,parametro:"San José"},
{id_pais:1,id_provincia:50,id_padre:126,id:2249,parametro:"Tupungato"},
{id_pais:1,id_provincia:54,id_padre:7,id:2250,parametro:"Apóstoles"},
{id_pais:1,id_provincia:54,id_padre:7,id:2251,parametro:"Azara"},
{id_pais:1,id_provincia:54,id_padre:7,id:2252,parametro:"Barrio Rural"},
{id_pais:1,id_provincia:54,id_padre:7,id:2253,parametro:"Estación Apóstoles"},
{id_pais:1,id_provincia:54,id_padre:7,id:2254,parametro:"Pindapoy"},
{id_pais:1,id_provincia:54,id_padre:7,id:2255,parametro:"Rincón de Azara"},
{id_pais:1,id_provincia:54,id_padre:7,id:2256,parametro:"San José"},
{id_pais:1,id_provincia:54,id_padre:7,id:2257,parametro:"Tres Capones"},
{id_pais:1,id_provincia:54,id_padre:14,id:2258,parametro:"Aristóbulo del Valle"},
{id_pais:1,id_provincia:54,id_padre:14,id:2259,parametro:"Campo Grande"},
{id_pais:1,id_provincia:54,id_padre:14,id:2260,parametro:"Dos de Mayo"},
{id_pais:1,id_provincia:54,id_padre:14,id:2261,parametro:"Dos de Mayo Nucleo III (B° Bernardino Rivadavia)"},
{id_pais:1,id_provincia:54,id_padre:14,id:2262,parametro:"Kilómetro 17"},
{id_pais:1,id_provincia:54,id_padre:14,id:2263,parametro:"1° de Mayo"},
{id_pais:1,id_provincia:54,id_padre:14,id:2264,parametro:"Pueblo Illia"},
{id_pais:1,id_provincia:54,id_padre:14,id:2265,parametro:"Salto Encantado"},
{id_pais:1,id_provincia:54,id_padre:21,id:2266,parametro:"Barrio del Lago"},
{id_pais:1,id_provincia:54,id_padre:21,id:2267,parametro:"Bonpland"},
{id_pais:1,id_provincia:54,id_padre:21,id:2268,parametro:"Candelaria"},
{id_pais:1,id_provincia:54,id_padre:21,id:2269,parametro:"Cerro Corá"},
{id_pais:1,id_provincia:54,id_padre:21,id:2270,parametro:"Loreto"},
{id_pais:1,id_provincia:54,id_padre:21,id:2271,parametro:"Mártires"},
{id_pais:1,id_provincia:54,id_padre:21,id:2272,parametro:"Profundidad"},
{id_pais:1,id_provincia:54,id_padre:21,id:2273,parametro:"Puerto Santa Ana"},
{id_pais:1,id_provincia:54,id_padre:21,id:2274,parametro:"Santa Ana"},
{id_pais:1,id_provincia:54,id_padre:28,id:2275,parametro:"Barrio Nuevo Garupa"},
{id_pais:1,id_provincia:54,id_padre:28,id:2276,parametro:"Garupá"},
{id_pais:1,id_provincia:54,id_padre:28,id:2277,parametro:"Nemesio Parma"},
{id_pais:1,id_provincia:54,id_padre:28,id:2278,parametro:"Posadas"},
{id_pais:1,id_provincia:54,id_padre:28,id:2279,parametro:"Posadas (Extensión)"},
{id_pais:1,id_provincia:54,id_padre:35,id:2280,parametro:"Barra Concepción"},
{id_pais:1,id_provincia:54,id_padre:35,id:2281,parametro:"Concepción de la Sierra"},
{id_pais:1,id_provincia:54,id_padre:35,id:2282,parametro:"La Corita"},
{id_pais:1,id_provincia:54,id_padre:35,id:2283,parametro:"Santa María"},
{id_pais:1,id_provincia:54,id_padre:42,id:2284,parametro:"Colonia Victoria"},
{id_pais:1,id_provincia:54,id_padre:42,id:2285,parametro:"Eldorado"},
{id_pais:1,id_provincia:54,id_padre:42,id:2286,parametro:"María Magdalena"},
{id_pais:1,id_provincia:54,id_padre:42,id:2287,parametro:"Nueva Delicia"},
{id_pais:1,id_provincia:54,id_padre:42,id:2288,parametro:"9 de Julio Kilómetro 28"},
{id_pais:1,id_provincia:54,id_padre:42,id:2289,parametro:"9 de Julio Kilómetro 20"},
{id_pais:1,id_provincia:54,id_padre:42,id:2290,parametro:"Pueblo Nuevo"},
{id_pais:1,id_provincia:54,id_padre:42,id:2291,parametro:"Puerto Mado"},
{id_pais:1,id_provincia:54,id_padre:42,id:2292,parametro:"Puerto Pinares"},
{id_pais:1,id_provincia:54,id_padre:42,id:2293,parametro:"Santiago de Liniers"},
{id_pais:1,id_provincia:54,id_padre:42,id:2294,parametro:"Valle Hermoso"},
{id_pais:1,id_provincia:54,id_padre:42,id:2295,parametro:"Villa Roulet"},
{id_pais:1,id_provincia:54,id_padre:49,id:2296,parametro:"Comandante Andresito"},
{id_pais:1,id_provincia:54,id_padre:49,id:2297,parametro:"Bernardo de Irigoyen"},
{id_pais:1,id_provincia:54,id_padre:49,id:2298,parametro:"Caburei"},
{id_pais:1,id_provincia:54,id_padre:49,id:2299,parametro:"Dos Hermanas"},
{id_pais:1,id_provincia:54,id_padre:49,id:2300,parametro:"Integración"},
{id_pais:1,id_provincia:54,id_padre:49,id:2301,parametro:"Piñalito Norte"},
{id_pais:1,id_provincia:54,id_padre:49,id:2302,parametro:"Puerto Andresito"},
{id_pais:1,id_provincia:54,id_padre:49,id:2303,parametro:"Puerto Deseado"},
{id_pais:1,id_provincia:54,id_padre:49,id:2304,parametro:"San Antonio"},
{id_pais:1,id_provincia:54,id_padre:56,id:2305,parametro:"El Soberbio"},
{id_pais:1,id_provincia:54,id_padre:56,id:2306,parametro:"Fracrán"},
{id_pais:1,id_provincia:54,id_padre:56,id:2307,parametro:"San Vicente"},
{id_pais:1,id_provincia:54,id_padre:63,id:2308,parametro:"Puerto Esperanza"},
{id_pais:1,id_provincia:54,id_padre:63,id:2309,parametro:"Puerto Libertad"},
{id_pais:1,id_provincia:54,id_padre:63,id:2310,parametro:"Puerto Iguazú"},
{id_pais:1,id_provincia:54,id_padre:63,id:2311,parametro:"Villa Cooperativa"},
{id_pais:1,id_provincia:54,id_padre:63,id:2312,parametro:"Colonia Wanda"},
{id_pais:1,id_provincia:54,id_padre:70,id:2313,parametro:"Almafuerte"},
{id_pais:1,id_provincia:54,id_padre:70,id:2314,parametro:"Arroyo del Medio"},
{id_pais:1,id_provincia:54,id_padre:70,id:2315,parametro:"Caá - Yarí"},
{id_pais:1,id_provincia:54,id_padre:70,id:2316,parametro:"Cerro Azul"},
{id_pais:1,id_provincia:54,id_padre:70,id:2317,parametro:"Dos Arroyos"},
{id_pais:1,id_provincia:54,id_padre:70,id:2318,parametro:"Gobernador López"},
{id_pais:1,id_provincia:54,id_padre:70,id:2319,parametro:"Leandro N. Alem"},
{id_pais:1,id_provincia:54,id_padre:70,id:2320,parametro:"Olegario V. Andrade"},
{id_pais:1,id_provincia:54,id_padre:70,id:2321,parametro:"Villa Libertad"},
{id_pais:1,id_provincia:54,id_padre:77,id:2322,parametro:"Capioví"},
{id_pais:1,id_provincia:54,id_padre:77,id:2323,parametro:"Capioviciño"},
{id_pais:1,id_provincia:54,id_padre:77,id:2324,parametro:"El Alcázar"},
{id_pais:1,id_provincia:54,id_padre:77,id:2325,parametro:"Garuhapé"},
{id_pais:1,id_provincia:54,id_padre:77,id:2326,parametro:"Mbopicuá"},
{id_pais:1,id_provincia:54,id_padre:77,id:2327,parametro:"Puerto Leoni"},
{id_pais:1,id_provincia:54,id_padre:77,id:2328,parametro:"Puerto Rico"},
{id_pais:1,id_provincia:54,id_padre:77,id:2329,parametro:"Ruiz de Montoya"},
{id_pais:1,id_provincia:54,id_padre:77,id:2330,parametro:"San Alberto"},
{id_pais:1,id_provincia:54,id_padre:77,id:2331,parametro:"San Gotardo"},
{id_pais:1,id_provincia:54,id_padre:77,id:2332,parametro:"San Miguel"},
{id_pais:1,id_provincia:54,id_padre:77,id:2333,parametro:"Villa Akerman"},
{id_pais:1,id_provincia:54,id_padre:77,id:2334,parametro:"Villa Urrutia"},
{id_pais:1,id_provincia:54,id_padre:84,id:2335,parametro:"Barrio Cuatro Bocas"},
{id_pais:1,id_provincia:54,id_padre:84,id:2336,parametro:"Barrio Guatambu"},
{id_pais:1,id_provincia:54,id_padre:84,id:2337,parametro:"Bario Ita"},
{id_pais:1,id_provincia:54,id_padre:84,id:2338,parametro:"Caraguatay"},
{id_pais:1,id_provincia:54,id_padre:84,id:2339,parametro:"Laharrague"},
{id_pais:1,id_provincia:54,id_padre:84,id:2340,parametro:"Montecarlo"},
{id_pais:1,id_provincia:54,id_padre:84,id:2341,parametro:"Piray Kilómetro 18"},
{id_pais:1,id_provincia:54,id_padre:84,id:2342,parametro:"Puerto Piray"},
{id_pais:1,id_provincia:54,id_padre:84,id:2343,parametro:"Tarumá"},
{id_pais:1,id_provincia:54,id_padre:84,id:2344,parametro:"Villa Parodi"},
{id_pais:1,id_provincia:54,id_padre:91,id:2345,parametro:"Colonia Alberdi"},
{id_pais:1,id_provincia:54,id_padre:91,id:2346,parametro:"Barrio Escuela 461"},
{id_pais:1,id_provincia:54,id_padre:91,id:2347,parametro:"Barrio Escuela 633"},
{id_pais:1,id_provincia:54,id_padre:91,id:2348,parametro:"Campo Ramón"},
{id_pais:1,id_provincia:54,id_padre:91,id:2349,parametro:"Campo Viera"},
{id_pais:1,id_provincia:54,id_padre:91,id:2350,parametro:"El Salto"},
{id_pais:1,id_provincia:54,id_padre:91,id:2351,parametro:"General Alvear"},
{id_pais:1,id_provincia:54,id_padre:91,id:2352,parametro:"Guaraní"},
{id_pais:1,id_provincia:54,id_padre:91,id:2353,parametro:"Los Helechos"},
{id_pais:1,id_provincia:54,id_padre:91,id:2354,parametro:"Oberá"},
{id_pais:1,id_provincia:54,id_padre:91,id:2355,parametro:"Panambí"},
{id_pais:1,id_provincia:54,id_padre:91,id:2356,parametro:"Panambí Kilómetro 8"},
{id_pais:1,id_provincia:54,id_padre:91,id:2357,parametro:"Panambi Kilómetro 15"},
{id_pais:1,id_provincia:54,id_padre:91,id:2358,parametro:"San Martín"},
{id_pais:1,id_provincia:54,id_padre:91,id:2359,parametro:"Villa Bonita"},
{id_pais:1,id_provincia:54,id_padre:98,id:2360,parametro:"Barrio Tungoil"},
{id_pais:1,id_provincia:54,id_padre:98,id:2361,parametro:"Colonia Polana"},
{id_pais:1,id_provincia:54,id_padre:98,id:2362,parametro:"Corpus"},
{id_pais:1,id_provincia:54,id_padre:98,id:2363,parametro:"Domingo Savio"},
{id_pais:1,id_provincia:54,id_padre:98,id:2364,parametro:"General Urquiza"},
{id_pais:1,id_provincia:54,id_padre:98,id:2365,parametro:"Gobernador Roca"},
{id_pais:1,id_provincia:54,id_padre:98,id:2366,parametro:"Helvecia"},
{id_pais:1,id_provincia:54,id_padre:98,id:2367,parametro:"Hipólito Yrigoyen"},
{id_pais:1,id_provincia:54,id_padre:98,id:2368,parametro:"Jardín América"},
{id_pais:1,id_provincia:54,id_padre:98,id:2369,parametro:"Oasis"},
{id_pais:1,id_provincia:54,id_padre:98,id:2370,parametro:"Roca Chica"},
{id_pais:1,id_provincia:54,id_padre:98,id:2371,parametro:"San Ignacio"},
{id_pais:1,id_provincia:54,id_padre:98,id:2372,parametro:"Santo Pipó"},
{id_pais:1,id_provincia:54,id_padre:105,id:2373,parametro:"Florentino Ameghino"},
{id_pais:1,id_provincia:54,id_padre:105,id:2374,parametro:"Itacaruaré"},
{id_pais:1,id_provincia:54,id_padre:105,id:2375,parametro:"Mojón Grande"},
{id_pais:1,id_provincia:54,id_padre:105,id:2376,parametro:"San Javier"},
{id_pais:1,id_provincia:54,id_padre:112,id:2377,parametro:"Cruce Caballero"},
{id_pais:1,id_provincia:54,id_padre:112,id:2378,parametro:"Paraíso"},
{id_pais:1,id_provincia:54,id_padre:112,id:2379,parametro:"Piñalito Sur"},
{id_pais:1,id_provincia:54,id_padre:112,id:2380,parametro:"San Pedro"},
{id_pais:1,id_provincia:54,id_padre:112,id:2381,parametro:"Tobuna"},
{id_pais:1,id_provincia:54,id_padre:119,id:2382,parametro:"Alba Posse"},
{id_pais:1,id_provincia:54,id_padre:119,id:2383,parametro:"Alicia Alta"},
{id_pais:1,id_provincia:54,id_padre:119,id:2384,parametro:"Alicia Baja"},
{id_pais:1,id_provincia:54,id_padre:119,id:2385,parametro:"Colonia Aurora"},
{id_pais:1,id_provincia:54,id_padre:119,id:2386,parametro:"San Francisco de Asís"},
{id_pais:1,id_provincia:54,id_padre:119,id:2387,parametro:"Santa Rita"},
{id_pais:1,id_provincia:54,id_padre:119,id:2388,parametro:"25 de Mayo"},
{id_pais:1,id_provincia:58,id_padre:7,id:2389,parametro:"Aluminé"},
{id_pais:1,id_provincia:58,id_padre:7,id:2390,parametro:"Moquehue"},
{id_pais:1,id_provincia:58,id_padre:7,id:2391,parametro:"Villa Pehuenia"},
{id_pais:1,id_provincia:58,id_padre:14,id:2392,parametro:"Aguada San Roque"},
{id_pais:1,id_provincia:58,id_padre:14,id:2393,parametro:"Añelo"},
{id_pais:1,id_provincia:58,id_padre:14,id:2394,parametro:"San Patricio del Chañar"},
{id_pais:1,id_provincia:58,id_padre:21,id:2395,parametro:"Las Coloradas"},
{id_pais:1,id_provincia:58,id_padre:28,id:2396,parametro:"Piedra del águila"},
{id_pais:1,id_provincia:58,id_padre:28,id:2397,parametro:"Santo Tomás"},
{id_pais:1,id_provincia:58,id_padre:35,id:2398,parametro:"Arroyito"},
{id_pais:1,id_provincia:58,id_padre:35,id:2399,parametro:"Barrio Ruca Luhé"},
{id_pais:1,id_provincia:58,id_padre:35,id:2400,parametro:"Centenario"},
{id_pais:1,id_provincia:58,id_padre:35,id:2401,parametro:"Cutral Có"},
{id_pais:1,id_provincia:58,id_padre:35,id:2402,parametro:"El Chocón"},
{id_pais:1,id_provincia:58,id_padre:35,id:2403,parametro:"Mari Menuco"},
{id_pais:1,id_provincia:58,id_padre:35,id:2404,parametro:"Neuquén"},
{id_pais:1,id_provincia:58,id_padre:35,id:2405,parametro:"11 de Octubre"},
{id_pais:1,id_provincia:58,id_padre:35,id:2406,parametro:"Plaza Huincul"},
{id_pais:1,id_provincia:58,id_padre:35,id:2407,parametro:"Plottier"},
{id_pais:1,id_provincia:58,id_padre:35,id:2408,parametro:"Senillosa"},
{id_pais:1,id_provincia:58,id_padre:35,id:2409,parametro:"Villa El Chocón"},
{id_pais:1,id_provincia:58,id_padre:35,id:2410,parametro:"Vista Alegre Norte"},
{id_pais:1,id_provincia:58,id_padre:35,id:2411,parametro:"Vista Alegre Sur"},
{id_pais:1,id_provincia:58,id_padre:42,id:2412,parametro:"Chos Malal"},
{id_pais:1,id_provincia:58,id_padre:42,id:2413,parametro:"Tricao Malal"},
{id_pais:1,id_provincia:58,id_padre:42,id:2414,parametro:"Villa del Curi Leuvú"},
{id_pais:1,id_provincia:58,id_padre:49,id:2415,parametro:"Junín de los Andes"},
{id_pais:1,id_provincia:58,id_padre:56,id:2416,parametro:"San Martín de los Andes"},
{id_pais:1,id_provincia:58,id_padre:56,id:2417,parametro:"Villa Lago Meliquina"},
{id_pais:1,id_provincia:58,id_padre:63,id:2418,parametro:"Chorriaca"},
{id_pais:1,id_provincia:58,id_padre:63,id:2419,parametro:"Loncopué"},
{id_pais:1,id_provincia:58,id_padre:70,id:2420,parametro:"Villa La Angostura"},
{id_pais:1,id_provincia:58,id_padre:70,id:2421,parametro:"Villa Traful"},
{id_pais:1,id_provincia:58,id_padre:77,id:2422,parametro:"Andacollo"},
{id_pais:1,id_provincia:58,id_padre:77,id:2423,parametro:"Huinganco"},
{id_pais:1,id_provincia:58,id_padre:77,id:2424,parametro:"Las Ovejas"},
{id_pais:1,id_provincia:58,id_padre:77,id:2425,parametro:"Los Miches"},
{id_pais:1,id_provincia:58,id_padre:77,id:2426,parametro:"Manzano Amargo"},
{id_pais:1,id_provincia:58,id_padre:77,id:2427,parametro:"Varvarco"},
{id_pais:1,id_provincia:58,id_padre:77,id:2428,parametro:"Villa del Nahueve"},
{id_pais:1,id_provincia:58,id_padre:84,id:2429,parametro:"Caviahue"},
{id_pais:1,id_provincia:58,id_padre:84,id:2430,parametro:"Copahue"},
{id_pais:1,id_provincia:58,id_padre:84,id:2431,parametro:"El Cholar"},
{id_pais:1,id_provincia:58,id_padre:84,id:2432,parametro:"El Huecú"},
{id_pais:1,id_provincia:58,id_padre:84,id:2433,parametro:"Taquimilán"},
{id_pais:1,id_provincia:58,id_padre:91,id:2434,parametro:"Barrancas"},
{id_pais:1,id_provincia:58,id_padre:91,id:2435,parametro:"Buta Ranquil"},
{id_pais:1,id_provincia:58,id_padre:91,id:2436,parametro:"Octavio Pico"},
{id_pais:1,id_provincia:58,id_padre:91,id:2437,parametro:"Rincón de los Sauces"},
{id_pais:1,id_provincia:58,id_padre:98,id:2438,parametro:"El Sauce"},
{id_pais:1,id_provincia:58,id_padre:98,id:2439,parametro:"Paso Aguerre"},
{id_pais:1,id_provincia:58,id_padre:98,id:2440,parametro:"Picún Leufú"},
{id_pais:1,id_provincia:58,id_padre:105,id:2441,parametro:"Bajada del Agrio"},
{id_pais:1,id_provincia:58,id_padre:105,id:2442,parametro:"La Buitrera"},
{id_pais:1,id_provincia:58,id_padre:105,id:2443,parametro:"Las Lajas"},
{id_pais:1,id_provincia:58,id_padre:105,id:2444,parametro:"Quili Malal"},
{id_pais:1,id_provincia:58,id_padre:112,id:2445,parametro:"Los Catutos"},
{id_pais:1,id_provincia:58,id_padre:112,id:2446,parametro:"Mariano Moreno"},
{id_pais:1,id_provincia:58,id_padre:112,id:2447,parametro:"Ramón M. Castro"},
{id_pais:1,id_provincia:58,id_padre:112,id:2448,parametro:"Zapala"},
{id_pais:1,id_provincia:62,id_padre:7,id:2449,parametro:"Bahía Creek"},
{id_pais:1,id_provincia:62,id_padre:7,id:2450,parametro:"El Cóndor"},
{id_pais:1,id_provincia:62,id_padre:7,id:2451,parametro:"El Juncal"},
{id_pais:1,id_provincia:62,id_padre:7,id:2452,parametro:"Guardia Mitre"},
{id_pais:1,id_provincia:62,id_padre:7,id:2453,parametro:"La Lobería"},
{id_pais:1,id_provincia:62,id_padre:7,id:2454,parametro:"Loteo Costa de Río"},
{id_pais:1,id_provincia:62,id_padre:7,id:2455,parametro:"Pozo Salado"},
{id_pais:1,id_provincia:62,id_padre:7,id:2456,parametro:"San Javier"},
{id_pais:1,id_provincia:62,id_padre:7,id:2457,parametro:"Viedma"},
{id_pais:1,id_provincia:62,id_padre:14,id:2458,parametro:"Barrio Unión"},
{id_pais:1,id_provincia:62,id_padre:14,id:2459,parametro:"Chelforó"},
{id_pais:1,id_provincia:62,id_padre:14,id:2460,parametro:"Chimpay"},
{id_pais:1,id_provincia:62,id_padre:14,id:2461,parametro:"Choele Choel"},
{id_pais:1,id_provincia:62,id_padre:14,id:2462,parametro:"Coronel Belisle"},
{id_pais:1,id_provincia:62,id_padre:14,id:2463,parametro:"Darwin"},
{id_pais:1,id_provincia:62,id_padre:14,id:2464,parametro:"Lamarque"},
{id_pais:1,id_provincia:62,id_padre:14,id:2465,parametro:"Luis Beltrán"},
{id_pais:1,id_provincia:62,id_padre:14,id:2466,parametro:"Pomona"},
{id_pais:1,id_provincia:62,id_padre:21,id:2467,parametro:"Arelauquen"},
{id_pais:1,id_provincia:62,id_padre:21,id:2468,parametro:"Barrio El Pilar"},
{id_pais:1,id_provincia:62,id_padre:21,id:2469,parametro:"Colonia Suiza"},
{id_pais:1,id_provincia:62,id_padre:21,id:2470,parametro:"El Bolsón"},
{id_pais:1,id_provincia:62,id_padre:21,id:2471,parametro:"El Foyel"},
{id_pais:1,id_provincia:62,id_padre:21,id:2472,parametro:"Mallín Ahogado"},
{id_pais:1,id_provincia:62,id_padre:21,id:2473,parametro:"Río Villegas"},
{id_pais:1,id_provincia:62,id_padre:21,id:2474,parametro:"San Carlos de Bariloche"},
{id_pais:1,id_provincia:62,id_padre:21,id:2475,parametro:"Villa Catedral"},
{id_pais:1,id_provincia:62,id_padre:21,id:2476,parametro:"Villa Los Coihues"},
{id_pais:1,id_provincia:62,id_padre:21,id:2477,parametro:"Villa Mascardi"},
{id_pais:1,id_provincia:62,id_padre:28,id:2478,parametro:"Barrio Colonia Conesa"},
{id_pais:1,id_provincia:62,id_padre:28,id:2479,parametro:"General Conesa"},
{id_pais:1,id_provincia:62,id_padre:28,id:2480,parametro:"Barrio Planta Compresora de Gas"},
{id_pais:1,id_provincia:62,id_padre:35,id:2481,parametro:"Aguada Guzmán"},
{id_pais:1,id_provincia:62,id_padre:35,id:2482,parametro:"Cerro Policía"},
{id_pais:1,id_provincia:62,id_padre:35,id:2483,parametro:"El Cuy"},
{id_pais:1,id_provincia:62,id_padre:35,id:2484,parametro:"Las Perlas"},
{id_pais:1,id_provincia:62,id_padre:35,id:2485,parametro:"Mencué"},
{id_pais:1,id_provincia:62,id_padre:35,id:2486,parametro:"Naupa Huen"},
{id_pais:1,id_provincia:62,id_padre:35,id:2487,parametro:"Paso Córdova"},
{id_pais:1,id_provincia:62,id_padre:35,id:2488,parametro:"Valle Azul"},
{id_pais:1,id_provincia:62,id_padre:42,id:2489,parametro:"Allen"},
{id_pais:1,id_provincia:62,id_padre:42,id:2490,parametro:"Paraje Arroyón (Bajo San Cayetano)"},
{id_pais:1,id_provincia:62,id_padre:42,id:2491,parametro:"Barda del Medio"},
{id_pais:1,id_provincia:62,id_padre:42,id:2492,parametro:"Barrio Blanco"},
{id_pais:1,id_provincia:62,id_padre:42,id:2493,parametro:"Barrio Calle Ciega N° 10"},
{id_pais:1,id_provincia:62,id_padre:42,id:2494,parametro:"Barrio Calle Ciega N° 6"},
{id_pais:1,id_provincia:62,id_padre:42,id:2495,parametro:"Barrio Canale"},
{id_pais:1,id_provincia:62,id_padre:42,id:2496,parametro:"Barrio Chacra Monte"},
{id_pais:1,id_provincia:62,id_padre:42,id:2497,parametro:"Barrio Costa Este"},
{id_pais:1,id_provincia:62,id_padre:42,id:2498,parametro:"Barrio Costa Linda"},
{id_pais:1,id_provincia:62,id_padre:42,id:2499,parametro:"Barrio Costa Oeste"},
{id_pais:1,id_provincia:62,id_padre:42,id:2500,parametro:"Barrio Destacamento"},
{id_pais:1,id_provincia:62,id_padre:42,id:2501,parametro:"Barrio El Labrador"},
{id_pais:1,id_provincia:62,id_padre:42,id:2502,parametro:"Barrio El Maruchito"},
{id_pais:1,id_provincia:62,id_padre:42,id:2503,parametro:"Barrio El Petróleo"},
{id_pais:1,id_provincia:62,id_padre:42,id:2504,parametro:"Barrio Emergente"},
{id_pais:1,id_provincia:62,id_padre:42,id:2505,parametro:"Barrio Fátima"},
{id_pais:1,id_provincia:62,id_padre:42,id:2506,parametro:"Barrio Frontera"},
{id_pais:1,id_provincia:62,id_padre:42,id:2507,parametro:"Barrio Guerrico"},
{id_pais:1,id_provincia:62,id_padre:42,id:2508,parametro:"Barrio Isla 10"},
{id_pais:1,id_provincia:62,id_padre:42,id:2509,parametro:"Barrio La Barda"},
{id_pais:1,id_provincia:62,id_padre:42,id:2510,parametro:"Barrio La Costa"},
{id_pais:1,id_provincia:62,id_padre:42,id:2511,parametro:"Barrio La Costa"},
{id_pais:1,id_provincia:62,id_padre:42,id:2512,parametro:"Barrio La Defensa"},
{id_pais:1,id_provincia:62,id_padre:42,id:2513,parametro:"Barrio La Herradura"},
{id_pais:1,id_provincia:62,id_padre:42,id:2514,parametro:"Barrio La Ribera - Barrio APYCAR"},
{id_pais:1,id_provincia:62,id_padre:42,id:2515,parametro:"Puente Cero"},
{id_pais:1,id_provincia:62,id_padre:42,id:2516,parametro:"Barrio Luisillo"},
{id_pais:1,id_provincia:62,id_padre:42,id:2517,parametro:"Barrio Mar del Plata"},
{id_pais:1,id_provincia:62,id_padre:42,id:2518,parametro:"Barrio María Elvira"},
{id_pais:1,id_provincia:62,id_padre:42,id:2519,parametro:"Barrio Moño Azul"},
{id_pais:1,id_provincia:62,id_padre:42,id:2520,parametro:"Barrio Mosconi"},
{id_pais:1,id_provincia:62,id_padre:42,id:2521,parametro:"Barrio Norte"},
{id_pais:1,id_provincia:62,id_padre:42,id:2522,parametro:"Barrio Pinar"},
{id_pais:1,id_provincia:62,id_padre:42,id:2523,parametro:"Barrio Porvenir"},
{id_pais:1,id_provincia:62,id_padre:42,id:2524,parametro:"Barrio Puente 83"},
{id_pais:1,id_provincia:62,id_padre:42,id:2525,parametro:"Barrio Santa Lucia"},
{id_pais:1,id_provincia:62,id_padre:42,id:2526,parametro:"Barrio Santa Rita"},
{id_pais:1,id_provincia:62,id_padre:42,id:2527,parametro:"Barrio Unión"},
{id_pais:1,id_provincia:62,id_padre:42,id:2528,parametro:"Catriel"},
{id_pais:1,id_provincia:62,id_padre:42,id:2529,parametro:"Cervantes"},
{id_pais:1,id_provincia:62,id_padre:42,id:2530,parametro:"Chichinales"},
{id_pais:1,id_provincia:62,id_padre:42,id:2531,parametro:"Cinco Saltos"},
{id_pais:1,id_provincia:62,id_padre:42,id:2532,parametro:"Cipolletti"},
{id_pais:1,id_provincia:62,id_padre:42,id:2533,parametro:"Contralmirante Cordero"},
{id_pais:1,id_provincia:62,id_padre:42,id:2534,parametro:"Ferri"},
{id_pais:1,id_provincia:62,id_padre:42,id:2535,parametro:"General Enrique Godoy"},
{id_pais:1,id_provincia:62,id_padre:42,id:2536,parametro:"General Fernández Oro"},
{id_pais:1,id_provincia:62,id_padre:42,id:2537,parametro:"General Roca"},
{id_pais:1,id_provincia:62,id_padre:42,id:2538,parametro:"Ingeniero Luis A. Huergo"},
{id_pais:1,id_provincia:62,id_padre:42,id:2539,parametro:"Ingeniero Otto Krause"},
{id_pais:1,id_provincia:62,id_padre:42,id:2540,parametro:"Mainqué"},
{id_pais:1,id_provincia:62,id_padre:42,id:2541,parametro:"Paso Córdova"},
{id_pais:1,id_provincia:62,id_padre:42,id:2542,parametro:"Península Ruca Co"},
{id_pais:1,id_provincia:62,id_padre:42,id:2543,parametro:"Sargento Vidal"},
{id_pais:1,id_provincia:62,id_padre:42,id:2544,parametro:"Villa del Parque"},
{id_pais:1,id_provincia:62,id_padre:42,id:2545,parametro:"Villa Manzano"},
{id_pais:1,id_provincia:62,id_padre:42,id:2546,parametro:"Villa Regina"},
{id_pais:1,id_provincia:62,id_padre:42,id:2547,parametro:"Villa San Isidro"},
{id_pais:1,id_provincia:62,id_padre:49,id:2548,parametro:"Comicó"},
{id_pais:1,id_provincia:62,id_padre:49,id:2549,parametro:"Cona Niyeu"},
{id_pais:1,id_provincia:62,id_padre:49,id:2550,parametro:"Ministro Ramos Mexía"},
{id_pais:1,id_provincia:62,id_padre:49,id:2551,parametro:"Prahuaniyeu"},
{id_pais:1,id_provincia:62,id_padre:49,id:2552,parametro:"Sierra Colorada"},
{id_pais:1,id_provincia:62,id_padre:49,id:2553,parametro:"Treneta"},
{id_pais:1,id_provincia:62,id_padre:49,id:2554,parametro:"Yaminué"},
{id_pais:1,id_provincia:62,id_padre:56,id:2555,parametro:"Las Bayas"},
{id_pais:1,id_provincia:62,id_padre:56,id:2556,parametro:"Mamuel Choique"},
{id_pais:1,id_provincia:62,id_padre:56,id:2557,parametro:"Ñorquincó"},
{id_pais:1,id_provincia:62,id_padre:56,id:2558,parametro:"Ojos de Agua"},
{id_pais:1,id_provincia:62,id_padre:56,id:2559,parametro:"Río Chico"},
{id_pais:1,id_provincia:62,id_padre:63,id:2560,parametro:"Barrio Esperanza"},
{id_pais:1,id_provincia:62,id_padre:63,id:2561,parametro:"Colonia Juliá y Echarren"},
{id_pais:1,id_provincia:62,id_padre:63,id:2562,parametro:"Juventud Unida"},
{id_pais:1,id_provincia:62,id_padre:63,id:2563,parametro:"Pichi Mahuida"},
{id_pais:1,id_provincia:62,id_padre:63,id:2564,parametro:"Río Colorado"},
{id_pais:1,id_provincia:62,id_padre:63,id:2565,parametro:"Salto Andersen"},
{id_pais:1,id_provincia:62,id_padre:70,id:2566,parametro:"Cañadón Chileno"},
{id_pais:1,id_provincia:62,id_padre:70,id:2567,parametro:"Comallo"},
{id_pais:1,id_provincia:62,id_padre:70,id:2568,parametro:"Dina Huapi"},
{id_pais:1,id_provincia:62,id_padre:70,id:2569,parametro:"Laguna Blanca"},
{id_pais:1,id_provincia:62,id_padre:70,id:2570,parametro:"Ñirihuau"},
{id_pais:1,id_provincia:62,id_padre:70,id:2571,parametro:"Pilcaniyeu"},
{id_pais:1,id_provincia:62,id_padre:70,id:2572,parametro:"Pilquiniyeu del Limay"},
{id_pais:1,id_provincia:62,id_padre:70,id:2573,parametro:"Villa Llanquín"},
{id_pais:1,id_provincia:62,id_padre:77,id:2574,parametro:"El Empalme"},
{id_pais:1,id_provincia:62,id_padre:77,id:2575,parametro:"Las Grutas"},
{id_pais:1,id_provincia:62,id_padre:77,id:2576,parametro:"Playas Doradas"},
{id_pais:1,id_provincia:62,id_padre:77,id:2577,parametro:"Puerto San Antonio Este"},
{id_pais:1,id_provincia:62,id_padre:77,id:2578,parametro:"Punta Colorada"},
{id_pais:1,id_provincia:62,id_padre:77,id:2579,parametro:"Saco Viejo"},
{id_pais:1,id_provincia:62,id_padre:77,id:2580,parametro:"San Antonio Oeste"},
{id_pais:1,id_provincia:62,id_padre:77,id:2581,parametro:"Sierra Grande"},
{id_pais:1,id_provincia:62,id_padre:84,id:2582,parametro:"Aguada Cecilio"},
{id_pais:1,id_provincia:62,id_padre:84,id:2583,parametro:"Arroyo Los Berros"},
{id_pais:1,id_provincia:62,id_padre:84,id:2584,parametro:"Arroyo Ventana"},
{id_pais:1,id_provincia:62,id_padre:84,id:2585,parametro:"Nahuel Niyeu"},
{id_pais:1,id_provincia:62,id_padre:84,id:2586,parametro:"Sierra Pailemán"},
{id_pais:1,id_provincia:62,id_padre:84,id:2587,parametro:"Valcheta"},
{id_pais:1,id_provincia:62,id_padre:91,id:2588,parametro:"Aguada de Guerra"},
{id_pais:1,id_provincia:62,id_padre:91,id:2589,parametro:"Clemente Onelli"},
{id_pais:1,id_provincia:62,id_padre:91,id:2590,parametro:"Colan Conhue"},
{id_pais:1,id_provincia:62,id_padre:91,id:2591,parametro:"El Caín"},
{id_pais:1,id_provincia:62,id_padre:91,id:2592,parametro:"Ingeniero Jacobacci"},
{id_pais:1,id_provincia:62,id_padre:91,id:2593,parametro:"Los Menucos"},
{id_pais:1,id_provincia:62,id_padre:91,id:2594,parametro:"Maquinchao"},
{id_pais:1,id_provincia:62,id_padre:91,id:2595,parametro:"Mina Santa Teresita"},
{id_pais:1,id_provincia:62,id_padre:91,id:2596,parametro:"Pilquiniyeu"},
{id_pais:1,id_provincia:66,id_padre:7,id:2597,parametro:"Apolinario Saravia"},
{id_pais:1,id_provincia:66,id_padre:7,id:2598,parametro:"Ceibalito"},
{id_pais:1,id_provincia:66,id_padre:7,id:2599,parametro:"Centro 25 de Junio"},
{id_pais:1,id_provincia:66,id_padre:7,id:2600,parametro:"Coronel Mollinedo"},
{id_pais:1,id_provincia:66,id_padre:7,id:2601,parametro:"Coronel Olleros"},
{id_pais:1,id_provincia:66,id_padre:7,id:2602,parametro:"El Quebrachal"},
{id_pais:1,id_provincia:66,id_padre:7,id:2603,parametro:"Gaona"},
{id_pais:1,id_provincia:66,id_padre:7,id:2604,parametro:"General Pizarro"},
{id_pais:1,id_provincia:66,id_padre:7,id:2605,parametro:"Joaquín V. González"},
{id_pais:1,id_provincia:66,id_padre:7,id:2606,parametro:"Las Lajitas"},
{id_pais:1,id_provincia:66,id_padre:7,id:2607,parametro:"Luis Burela"},
{id_pais:1,id_provincia:66,id_padre:7,id:2608,parametro:"Macapillo"},
{id_pais:1,id_provincia:66,id_padre:7,id:2609,parametro:"Nuestra Señora de Talavera"},
{id_pais:1,id_provincia:66,id_padre:7,id:2610,parametro:"Piquete Cabado"},
{id_pais:1,id_provincia:66,id_padre:7,id:2611,parametro:"Río del Valle"},
{id_pais:1,id_provincia:66,id_padre:7,id:2612,parametro:"Tolloche"},
{id_pais:1,id_provincia:66,id_padre:14,id:2613,parametro:"Cachi"},
{id_pais:1,id_provincia:66,id_padre:14,id:2614,parametro:"Payogasta"},
{id_pais:1,id_provincia:66,id_padre:21,id:2615,parametro:"Cafayate"},
{id_pais:1,id_provincia:66,id_padre:21,id:2616,parametro:"Tolombón"},
{id_pais:1,id_provincia:66,id_padre:28,id:2617,parametro:"Atocha"},
{id_pais:1,id_provincia:66,id_padre:28,id:2618,parametro:"La Ciénaga y Barrio San Rafael"},
{id_pais:1,id_provincia:66,id_padre:28,id:2619,parametro:"Las Costas"},
{id_pais:1,id_provincia:66,id_padre:28,id:2620,parametro:"Salta"},
{id_pais:1,id_provincia:66,id_padre:28,id:2621,parametro:"Villa San Lorenzo"},
{id_pais:1,id_provincia:66,id_padre:35,id:2622,parametro:"Cerrillos"},
{id_pais:1,id_provincia:66,id_padre:35,id:2623,parametro:"La Merced"},
{id_pais:1,id_provincia:66,id_padre:35,id:2624,parametro:"San Agustín"},
{id_pais:1,id_provincia:66,id_padre:35,id:2625,parametro:"Villa Los álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos"},
{id_pais:1,id_provincia:66,id_padre:42,id:2626,parametro:"Barrio Finca La Maroma"},
{id_pais:1,id_provincia:66,id_padre:42,id:2627,parametro:"Barrio La Rotonda"},
{id_pais:1,id_provincia:66,id_padre:42,id:2628,parametro:"Barrio Santa Teresita"},
{id_pais:1,id_provincia:66,id_padre:42,id:2629,parametro:"Chicoana"},
{id_pais:1,id_provincia:66,id_padre:42,id:2630,parametro:"El Carril"},
{id_pais:1,id_provincia:66,id_padre:49,id:2631,parametro:"Campo Santo"},
{id_pais:1,id_provincia:66,id_padre:49,id:2632,parametro:"Cobos"},
{id_pais:1,id_provincia:66,id_padre:49,id:2633,parametro:"El Bordo"},
{id_pais:1,id_provincia:66,id_padre:49,id:2634,parametro:"General Güemes"},
{id_pais:1,id_provincia:66,id_padre:56,id:2635,parametro:"Aguaray"},
{id_pais:1,id_provincia:66,id_padre:56,id:2636,parametro:"Campamento Vespucio"},
{id_pais:1,id_provincia:66,id_padre:56,id:2637,parametro:"Campichuelo"},
{id_pais:1,id_provincia:66,id_padre:56,id:2638,parametro:"Campo Durán"},
{id_pais:1,id_provincia:66,id_padre:56,id:2639,parametro:"Capiazuti"},
{id_pais:1,id_provincia:66,id_padre:56,id:2640,parametro:"Carboncito"},
{id_pais:1,id_provincia:66,id_padre:56,id:2641,parametro:"Coronel Cornejo"},
{id_pais:1,id_provincia:66,id_padre:56,id:2642,parametro:"Dragones"},
{id_pais:1,id_provincia:66,id_padre:56,id:2643,parametro:"Embarcación"},
{id_pais:1,id_provincia:66,id_padre:56,id:2644,parametro:"General Ballivián"},
{id_pais:1,id_provincia:66,id_padre:56,id:2645,parametro:"General Mosconi"},
{id_pais:1,id_provincia:66,id_padre:56,id:2646,parametro:"Hickman"},
{id_pais:1,id_provincia:66,id_padre:56,id:2647,parametro:"Misión Chaqueña"},
{id_pais:1,id_provincia:66,id_padre:56,id:2648,parametro:"Misión El Cruce - El Milagro - El Jardín de San Martín"},
{id_pais:1,id_provincia:66,id_padre:56,id:2649,parametro:"Misión Kilómetro 6"},
{id_pais:1,id_provincia:66,id_padre:56,id:2650,parametro:"Pacará"},
{id_pais:1,id_provincia:66,id_padre:56,id:2651,parametro:"Padre Lozano"},
{id_pais:1,id_provincia:66,id_padre:56,id:2652,parametro:"Piquirenda"},
{id_pais:1,id_provincia:66,id_padre:56,id:2653,parametro:"Profesor Salvador Mazza"},
{id_pais:1,id_provincia:66,id_padre:56,id:2654,parametro:"Tartagal"},
{id_pais:1,id_provincia:66,id_padre:56,id:2655,parametro:"Tobantirenda"},
{id_pais:1,id_provincia:66,id_padre:56,id:2656,parametro:"Tranquitas"},
{id_pais:1,id_provincia:66,id_padre:56,id:2657,parametro:"Yacuy"},
{id_pais:1,id_provincia:66,id_padre:63,id:2658,parametro:"Guachipas"},
{id_pais:1,id_provincia:66,id_padre:70,id:2659,parametro:"Iruya"},
{id_pais:1,id_provincia:66,id_padre:70,id:2660,parametro:"Isla de Cañas"},
{id_pais:1,id_provincia:66,id_padre:70,id:2661,parametro:"Pueblo Viejo"},
{id_pais:1,id_provincia:66,id_padre:77,id:2662,parametro:"La Caldera"},
{id_pais:1,id_provincia:66,id_padre:77,id:2663,parametro:"Vaqueros"},
{id_pais:1,id_provincia:66,id_padre:84,id:2664,parametro:"El Jardín"},
{id_pais:1,id_provincia:66,id_padre:84,id:2665,parametro:"El Tala"},
{id_pais:1,id_provincia:66,id_padre:84,id:2666,parametro:"La Candelaria"},
{id_pais:1,id_provincia:66,id_padre:91,id:2667,parametro:"Cobres"},
{id_pais:1,id_provincia:66,id_padre:91,id:2668,parametro:"La Poma"},
{id_pais:1,id_provincia:66,id_padre:98,id:2669,parametro:"Ampascachi"},
{id_pais:1,id_provincia:66,id_padre:98,id:2670,parametro:"Cabra Corral"},
{id_pais:1,id_provincia:66,id_padre:98,id:2671,parametro:"Coronel Moldes"},
{id_pais:1,id_provincia:66,id_padre:98,id:2672,parametro:"La Viña"},
{id_pais:1,id_provincia:66,id_padre:98,id:2673,parametro:"Talapampa"},
{id_pais:1,id_provincia:66,id_padre:105,id:2674,parametro:"Olacapato"},
{id_pais:1,id_provincia:66,id_padre:105,id:2675,parametro:"San Antonio de los Cobres"},
{id_pais:1,id_provincia:66,id_padre:105,id:2676,parametro:"Santa Rosa de los Pastos Grandes"},
{id_pais:1,id_provincia:66,id_padre:105,id:2677,parametro:"Tolar Grande"},
{id_pais:1,id_provincia:66,id_padre:112,id:2678,parametro:"El Galpón"},
{id_pais:1,id_provincia:66,id_padre:112,id:2679,parametro:"El Tunal"},
{id_pais:1,id_provincia:66,id_padre:112,id:2680,parametro:"Lumbreras"},
{id_pais:1,id_provincia:66,id_padre:112,id:2681,parametro:"San José de Metán (Est. Metán)"},
{id_pais:1,id_provincia:66,id_padre:112,id:2682,parametro:"Metán Viejo"},
{id_pais:1,id_provincia:66,id_padre:112,id:2683,parametro:"Río Piedras"},
{id_pais:1,id_provincia:66,id_padre:112,id:2684,parametro:"San José de Orquera"},
{id_pais:1,id_provincia:66,id_padre:119,id:2685,parametro:"La Puerta"},
{id_pais:1,id_provincia:66,id_padre:119,id:2686,parametro:"Molinos"},
{id_pais:1,id_provincia:66,id_padre:119,id:2687,parametro:"Seclantás"},
{id_pais:1,id_provincia:66,id_padre:126,id:2688,parametro:"Aguas Blancas"},
{id_pais:1,id_provincia:66,id_padre:126,id:2689,parametro:"Colonia Santa Rosa"},
{id_pais:1,id_provincia:66,id_padre:126,id:2690,parametro:"El Tabacal"},
{id_pais:1,id_provincia:66,id_padre:126,id:2691,parametro:"Hipólito Yrigoyen"},
{id_pais:1,id_provincia:66,id_padre:126,id:2692,parametro:"Pichanal"},
{id_pais:1,id_provincia:66,id_padre:126,id:2693,parametro:"San Ramón de la Nueva Orán"},
{id_pais:1,id_provincia:66,id_padre:126,id:2694,parametro:"Urundel"},
{id_pais:1,id_provincia:66,id_padre:133,id:2695,parametro:"Alto de la Sierra"},
{id_pais:1,id_provincia:66,id_padre:133,id:2696,parametro:"Capitán Juan Pagé"},
{id_pais:1,id_provincia:66,id_padre:133,id:2697,parametro:"Coronel Juan Solá"},
{id_pais:1,id_provincia:66,id_padre:133,id:2698,parametro:"Hito 1"},
{id_pais:1,id_provincia:66,id_padre:133,id:2699,parametro:"La Unión"},
{id_pais:1,id_provincia:66,id_padre:133,id:2700,parametro:"Los Blancos"},
{id_pais:1,id_provincia:66,id_padre:133,id:2701,parametro:"Pluma de Pato"},
{id_pais:1,id_provincia:66,id_padre:133,id:2702,parametro:"Rivadavia"},
{id_pais:1,id_provincia:66,id_padre:133,id:2703,parametro:"Santa María"},
{id_pais:1,id_provincia:66,id_padre:133,id:2704,parametro:"Santa Rosa"},
{id_pais:1,id_provincia:66,id_padre:133,id:2705,parametro:"Santa Victoria Este"},
{id_pais:1,id_provincia:66,id_padre:140,id:2706,parametro:"Antillá"},
{id_pais:1,id_provincia:66,id_padre:140,id:2707,parametro:"Copo Quile"},
{id_pais:1,id_provincia:66,id_padre:140,id:2708,parametro:"El Naranjo"},
{id_pais:1,id_provincia:66,id_padre:140,id:2709,parametro:"El Potrero"},
{id_pais:1,id_provincia:66,id_padre:140,id:2710,parametro:"Rosario de la Frontera"},
{id_pais:1,id_provincia:66,id_padre:140,id:2711,parametro:"San Felipe"},
{id_pais:1,id_provincia:66,id_padre:147,id:2712,parametro:"Campo Quijano"},
{id_pais:1,id_provincia:66,id_padre:147,id:2713,parametro:"La Merced del Encón"},
{id_pais:1,id_provincia:66,id_padre:147,id:2714,parametro:"La Silleta"},
{id_pais:1,id_provincia:66,id_padre:147,id:2715,parametro:"Rosario de Lerma"},
{id_pais:1,id_provincia:66,id_padre:154,id:2716,parametro:"Angastaco"},
{id_pais:1,id_provincia:66,id_padre:154,id:2717,parametro:"Animaná"},
{id_pais:1,id_provincia:66,id_padre:154,id:2718,parametro:"El Barrial"},
{id_pais:1,id_provincia:66,id_padre:154,id:2719,parametro:"San Carlos"},
{id_pais:1,id_provincia:66,id_padre:161,id:2720,parametro:"Acoyte"},
{id_pais:1,id_provincia:66,id_padre:161,id:2721,parametro:"Campo La Cruz"},
{id_pais:1,id_provincia:66,id_padre:161,id:2722,parametro:"Los Toldos"},
{id_pais:1,id_provincia:66,id_padre:161,id:2723,parametro:"Nazareno"},
{id_pais:1,id_provincia:66,id_padre:161,id:2724,parametro:"Poscaya"},
{id_pais:1,id_provincia:66,id_padre:161,id:2725,parametro:"San Marcos"},
{id_pais:1,id_provincia:66,id_padre:161,id:2726,parametro:"Santa Victoria"},
{id_pais:1,id_provincia:70,id_padre:7,id:2727,parametro:"El Rincón"},
{id_pais:1,id_provincia:70,id_padre:7,id:2728,parametro:"Villa General San Martín - Campo Afuera"},
{id_pais:1,id_provincia:70,id_padre:14,id:2729,parametro:"Las Tapias"},
{id_pais:1,id_provincia:70,id_padre:14,id:2730,parametro:"Villa El Salvador - Villa Sefair"},
{id_pais:1,id_provincia:70,id_padre:21,id:2731,parametro:"Barreal  - Villa Pituil"},
{id_pais:1,id_provincia:70,id_padre:21,id:2732,parametro:"Calingasta"},
{id_pais:1,id_provincia:70,id_padre:21,id:2733,parametro:"Tamberías"},
{id_pais:1,id_provincia:70,id_padre:28,id:2734,parametro:"San Juan"},
{id_pais:1,id_provincia:70,id_padre:35,id:2735,parametro:"Bermejo"},
{id_pais:1,id_provincia:70,id_padre:35,id:2736,parametro:"Barrio Justo P. Castro IV"},
{id_pais:1,id_provincia:70,id_padre:35,id:2737,parametro:"Caucete"},
{id_pais:1,id_provincia:70,id_padre:35,id:2738,parametro:"El Rincón"},
{id_pais:1,id_provincia:70,id_padre:35,id:2739,parametro:"Las Talas - Los Médanos"},
{id_pais:1,id_provincia:70,id_padre:35,id:2740,parametro:"Marayes"},
{id_pais:1,id_provincia:70,id_padre:35,id:2741,parametro:"Pie de Palo"},
{id_pais:1,id_provincia:70,id_padre:35,id:2742,parametro:"Vallecito"},
{id_pais:1,id_provincia:70,id_padre:35,id:2743,parametro:"Villa Independencia"},
{id_pais:1,id_provincia:70,id_padre:42,id:2744,parametro:"Chimbas"},
{id_pais:1,id_provincia:70,id_padre:49,id:2745,parametro:"Angualasto"},
{id_pais:1,id_provincia:70,id_padre:49,id:2746,parametro:"Bella Vista"},
{id_pais:1,id_provincia:70,id_padre:49,id:2747,parametro:"Iglesia"},
{id_pais:1,id_provincia:70,id_padre:49,id:2748,parametro:"Las Flores"},
{id_pais:1,id_provincia:70,id_padre:49,id:2749,parametro:"Pismanta"},
{id_pais:1,id_provincia:70,id_padre:49,id:2750,parametro:"Rodeo"},
{id_pais:1,id_provincia:70,id_padre:49,id:2751,parametro:"Tudcum"},
{id_pais:1,id_provincia:70,id_padre:56,id:2752,parametro:"El Médano"},
{id_pais:1,id_provincia:70,id_padre:56,id:2753,parametro:"Gran China"},
{id_pais:1,id_provincia:70,id_padre:56,id:2754,parametro:"Huaco"},
{id_pais:1,id_provincia:70,id_padre:56,id:2755,parametro:"Mogna"},
{id_pais:1,id_provincia:70,id_padre:56,id:2756,parametro:"Niquivil"},
{id_pais:1,id_provincia:70,id_padre:56,id:2757,parametro:"Pampa Vieja"},
{id_pais:1,id_provincia:70,id_padre:56,id:2758,parametro:"San Isidro"},
{id_pais:1,id_provincia:70,id_padre:56,id:2759,parametro:"San José de Jáchal"},
{id_pais:1,id_provincia:70,id_padre:56,id:2760,parametro:"Tamberías"},
{id_pais:1,id_provincia:70,id_padre:56,id:2761,parametro:"Villa Malvinas Argentinas"},
{id_pais:1,id_provincia:70,id_padre:56,id:2762,parametro:"Villa Mercedes"},
{id_pais:1,id_provincia:70,id_padre:63,id:2763,parametro:"Alto de Sierra"},
{id_pais:1,id_provincia:70,id_padre:63,id:2764,parametro:"Colonia Fiorito"},
{id_pais:1,id_provincia:70,id_padre:63,id:2765,parametro:"Las Chacritas"},
{id_pais:1,id_provincia:70,id_padre:63,id:2766,parametro:"9 de Julio"},
{id_pais:1,id_provincia:70,id_padre:70,id:2767,parametro:"Barrio Municipal"},
{id_pais:1,id_provincia:70,id_padre:70,id:2768,parametro:"Barrio Ruta 40"},
{id_pais:1,id_provincia:70,id_padre:70,id:2769,parametro:"Carpintería"},
{id_pais:1,id_provincia:70,id_padre:70,id:2770,parametro:"Las Piedritas"},
{id_pais:1,id_provincia:70,id_padre:70,id:2771,parametro:"Quinto Cuartel"},
{id_pais:1,id_provincia:70,id_padre:70,id:2772,parametro:"Villa Aberastain - La Rinconada"},
{id_pais:1,id_provincia:70,id_padre:70,id:2773,parametro:"Villa Barboza - Villa Nacusi"},
{id_pais:1,id_provincia:70,id_padre:70,id:2774,parametro:"Villa Centenario"},
{id_pais:1,id_provincia:70,id_padre:77,id:2775,parametro:"Rawson"},
{id_pais:1,id_provincia:70,id_padre:77,id:2776,parametro:"Villa Bolaños (Médano de Oro)"},
{id_pais:1,id_provincia:70,id_padre:84,id:2777,parametro:"Rivadavia"},
{id_pais:1,id_provincia:70,id_padre:91,id:2778,parametro:"Barrio Sadop - Bella Vista"},
{id_pais:1,id_provincia:70,id_padre:91,id:2779,parametro:"Dos Acequias"},
{id_pais:1,id_provincia:70,id_padre:91,id:2780,parametro:"San Isidro"},
{id_pais:1,id_provincia:70,id_padre:91,id:2781,parametro:"Villa del Salvador"},
{id_pais:1,id_provincia:70,id_padre:91,id:2782,parametro:"Villa Dominguito"},
{id_pais:1,id_provincia:70,id_padre:91,id:2783,parametro:"Villa Don Bosco"},
{id_pais:1,id_provincia:70,id_padre:91,id:2784,parametro:"Villa San Martín"},
{id_pais:1,id_provincia:70,id_padre:98,id:2785,parametro:"Santa Lucía"},
{id_pais:1,id_provincia:70,id_padre:105,id:2786,parametro:"Cañada Honda"},
{id_pais:1,id_provincia:70,id_padre:105,id:2787,parametro:"Cienaguita"},
{id_pais:1,id_provincia:70,id_padre:105,id:2788,parametro:"Colonia Fiscal"},
{id_pais:1,id_provincia:70,id_padre:105,id:2789,parametro:"Divisadero"},
{id_pais:1,id_provincia:70,id_padre:105,id:2790,parametro:"Guanacache"},
{id_pais:1,id_provincia:70,id_padre:105,id:2791,parametro:"Las Lagunas"},
{id_pais:1,id_provincia:70,id_padre:105,id:2792,parametro:"Los Berros"},
{id_pais:1,id_provincia:70,id_padre:105,id:2793,parametro:"Pedernal"},
{id_pais:1,id_provincia:70,id_padre:105,id:2794,parametro:"Punta del Médano"},
{id_pais:1,id_provincia:70,id_padre:105,id:2795,parametro:"Villa Media Agua"},
{id_pais:1,id_provincia:70,id_padre:112,id:2796,parametro:"Villa Ibáñez"},
{id_pais:1,id_provincia:70,id_padre:119,id:2797,parametro:"Astica"},
{id_pais:1,id_provincia:70,id_padre:119,id:2798,parametro:"Balde del Rosario"},
{id_pais:1,id_provincia:70,id_padre:119,id:2799,parametro:"Chucuma"},
{id_pais:1,id_provincia:70,id_padre:119,id:2800,parametro:"Los Baldecitos"},
{id_pais:1,id_provincia:70,id_padre:119,id:2801,parametro:"Usno"},
{id_pais:1,id_provincia:70,id_padre:119,id:2802,parametro:"Villa San Agustín"},
{id_pais:1,id_provincia:70,id_padre:126,id:2803,parametro:"El Encón"},
{id_pais:1,id_provincia:70,id_padre:126,id:2804,parametro:"Tupelí"},
{id_pais:1,id_provincia:70,id_padre:126,id:2805,parametro:"Villa Borjas - La Chimbera"},
{id_pais:1,id_provincia:70,id_padre:126,id:2806,parametro:"Villa El Tango"},
{id_pais:1,id_provincia:70,id_padre:126,id:2807,parametro:"Villa Santa Rosa"},
{id_pais:1,id_provincia:70,id_padre:133,id:2808,parametro:"Villa Basilio Nievas"},
{id_pais:1,id_provincia:74,id_padre:7,id:2809,parametro:"Candelaria"},
{id_pais:1,id_provincia:74,id_padre:7,id:2810,parametro:"Leandro N. Alem"},
{id_pais:1,id_provincia:74,id_padre:7,id:2811,parametro:"Luján"},
{id_pais:1,id_provincia:74,id_padre:7,id:2812,parametro:"Quines"},
{id_pais:1,id_provincia:74,id_padre:7,id:2813,parametro:"San Francisco del Monte de Oro"},
{id_pais:1,id_provincia:74,id_padre:14,id:2814,parametro:"La Calera"},
{id_pais:1,id_provincia:74,id_padre:14,id:2815,parametro:"Nogolí"},
{id_pais:1,id_provincia:74,id_padre:14,id:2816,parametro:"Villa de la Quebrada"},
{id_pais:1,id_provincia:74,id_padre:14,id:2817,parametro:"Villa General Roca"},
{id_pais:1,id_provincia:74,id_padre:21,id:2818,parametro:"Carolina"},
{id_pais:1,id_provincia:74,id_padre:21,id:2819,parametro:"El Trapiche"},
{id_pais:1,id_provincia:74,id_padre:21,id:2820,parametro:"Estancia Grande"},
{id_pais:1,id_provincia:74,id_padre:21,id:2821,parametro:"Fraga"},
{id_pais:1,id_provincia:74,id_padre:21,id:2822,parametro:"La Bajada"},
{id_pais:1,id_provincia:74,id_padre:21,id:2823,parametro:"La Florida"},
{id_pais:1,id_provincia:74,id_padre:21,id:2824,parametro:"La Toma"},
{id_pais:1,id_provincia:74,id_padre:21,id:2825,parametro:"Riocito"},
{id_pais:1,id_provincia:74,id_padre:21,id:2826,parametro:"Río Grande"},
{id_pais:1,id_provincia:74,id_padre:21,id:2827,parametro:"Saladillo"},
{id_pais:1,id_provincia:74,id_padre:28,id:2828,parametro:"Concarán"},
{id_pais:1,id_provincia:74,id_padre:28,id:2829,parametro:"Cortaderas"},
{id_pais:1,id_provincia:74,id_padre:28,id:2830,parametro:"Naschel"},
{id_pais:1,id_provincia:74,id_padre:28,id:2831,parametro:"Papagayos"},
{id_pais:1,id_provincia:74,id_padre:28,id:2832,parametro:"Renca"},
{id_pais:1,id_provincia:74,id_padre:28,id:2833,parametro:"San Pablo"},
{id_pais:1,id_provincia:74,id_padre:28,id:2834,parametro:"Tilisarao"},
{id_pais:1,id_provincia:74,id_padre:28,id:2835,parametro:"Villa del Carmen"},
{id_pais:1,id_provincia:74,id_padre:28,id:2836,parametro:"Villa Larca"},
{id_pais:1,id_provincia:74,id_padre:35,id:2837,parametro:"Juan Jorba"},
{id_pais:1,id_provincia:74,id_padre:35,id:2838,parametro:"Juan Llerena"},
{id_pais:1,id_provincia:74,id_padre:35,id:2839,parametro:"Justo Daract"},
{id_pais:1,id_provincia:74,id_padre:35,id:2840,parametro:"La Punilla"},
{id_pais:1,id_provincia:74,id_padre:35,id:2841,parametro:"Lavaisse"},
{id_pais:1,id_provincia:74,id_padre:35,id:2842,parametro:"Nación Ranquel"},
{id_pais:1,id_provincia:74,id_padre:35,id:2843,parametro:"San José del Morro"},
{id_pais:1,id_provincia:74,id_padre:35,id:2844,parametro:"Villa Mercedes"},
{id_pais:1,id_provincia:74,id_padre:35,id:2845,parametro:"Villa Reynolds"},
{id_pais:1,id_provincia:74,id_padre:35,id:2846,parametro:"Villa Salles"},
{id_pais:1,id_provincia:74,id_padre:42,id:2847,parametro:"Anchorena"},
{id_pais:1,id_provincia:74,id_padre:42,id:2848,parametro:"Arizona"},
{id_pais:1,id_provincia:74,id_padre:42,id:2849,parametro:"Bagual"},
{id_pais:1,id_provincia:74,id_padre:42,id:2850,parametro:"Batavia"},
{id_pais:1,id_provincia:74,id_padre:42,id:2851,parametro:"Buena Esperanza"},
{id_pais:1,id_provincia:74,id_padre:42,id:2852,parametro:"Fortín El Patria"},
{id_pais:1,id_provincia:74,id_padre:42,id:2853,parametro:"Fortuna"},
{id_pais:1,id_provincia:74,id_padre:42,id:2854,parametro:"La Maroma"},
{id_pais:1,id_provincia:74,id_padre:42,id:2855,parametro:"Los Overos"},
{id_pais:1,id_provincia:74,id_padre:42,id:2856,parametro:"Martín de Loyola"},
{id_pais:1,id_provincia:74,id_padre:42,id:2857,parametro:"Nahuel Mapá"},
{id_pais:1,id_provincia:74,id_padre:42,id:2858,parametro:"Navia"},
{id_pais:1,id_provincia:74,id_padre:42,id:2859,parametro:"Nueva Galia"},
{id_pais:1,id_provincia:74,id_padre:42,id:2860,parametro:"Unión"},
{id_pais:1,id_provincia:74,id_padre:49,id:2861,parametro:"Carpintería"},
{id_pais:1,id_provincia:74,id_padre:49,id:2862,parametro:"Cerro de Oro"},
{id_pais:1,id_provincia:74,id_padre:49,id:2863,parametro:"Lafinur"},
{id_pais:1,id_provincia:74,id_padre:49,id:2864,parametro:"Los Cajones"},
{id_pais:1,id_provincia:74,id_padre:49,id:2865,parametro:"Los Molles"},
{id_pais:1,id_provincia:74,id_padre:49,id:2866,parametro:"Merlo"},
{id_pais:1,id_provincia:74,id_padre:49,id:2867,parametro:"Santa Rosa del Conlara"},
{id_pais:1,id_provincia:74,id_padre:49,id:2868,parametro:"Talita"},
{id_pais:1,id_provincia:74,id_padre:56,id:2869,parametro:"Alto Pelado"},
{id_pais:1,id_provincia:74,id_padre:56,id:2870,parametro:"Alto Pencoso"},
{id_pais:1,id_provincia:74,id_padre:56,id:2871,parametro:"Balde"},
{id_pais:1,id_provincia:74,id_padre:56,id:2872,parametro:"Beazley"},
{id_pais:1,id_provincia:74,id_padre:56,id:2873,parametro:"Cazador"},
{id_pais:1,id_provincia:74,id_padre:56,id:2874,parametro:"Chosmes"},
{id_pais:1,id_provincia:74,id_padre:56,id:2875,parametro:"Desaguadero"},
{id_pais:1,id_provincia:74,id_padre:56,id:2876,parametro:"El Volcán"},
{id_pais:1,id_provincia:74,id_padre:56,id:2877,parametro:"Jarilla"},
{id_pais:1,id_provincia:74,id_padre:56,id:2878,parametro:"Juana Koslay"},
{id_pais:1,id_provincia:74,id_padre:56,id:2879,parametro:"La Punta"},
{id_pais:1,id_provincia:74,id_padre:56,id:2880,parametro:"Mosmota"},
{id_pais:1,id_provincia:74,id_padre:56,id:2881,parametro:"Potrero de los Funes"},
{id_pais:1,id_provincia:74,id_padre:56,id:2882,parametro:"Salinas del Bebedero"},
{id_pais:1,id_provincia:74,id_padre:56,id:2883,parametro:"San Jerónimo"},
{id_pais:1,id_provincia:74,id_padre:56,id:2884,parametro:"San Luis"},
{id_pais:1,id_provincia:74,id_padre:56,id:2885,parametro:"Zanjitas"},
{id_pais:1,id_provincia:74,id_padre:63,id:2886,parametro:"La Vertiente"},
{id_pais:1,id_provincia:74,id_padre:63,id:2887,parametro:"Las Aguadas"},
{id_pais:1,id_provincia:74,id_padre:63,id:2888,parametro:"Las Chacras"},
{id_pais:1,id_provincia:74,id_padre:63,id:2889,parametro:"Las Lagunas"},
{id_pais:1,id_provincia:74,id_padre:63,id:2890,parametro:"Paso Grande"},
{id_pais:1,id_provincia:74,id_padre:63,id:2891,parametro:"Potrerillo"},
{id_pais:1,id_provincia:74,id_padre:63,id:2892,parametro:"San Martín"},
{id_pais:1,id_provincia:74,id_padre:63,id:2893,parametro:"Villa de Praga"},
{id_pais:1,id_provincia:78,id_padre:7,id:2894,parametro:"Comandante Luis Piedrabuena"},
{id_pais:1,id_provincia:78,id_padre:7,id:2895,parametro:"Puerto Santa Cruz"},
{id_pais:1,id_provincia:78,id_padre:14,id:2896,parametro:"Caleta Olivia"},
{id_pais:1,id_provincia:78,id_padre:14,id:2897,parametro:"Cañadón Seco"},
{id_pais:1,id_provincia:78,id_padre:14,id:2898,parametro:"Fitz Roy"},
{id_pais:1,id_provincia:78,id_padre:14,id:2899,parametro:"Jaramillo"},
{id_pais:1,id_provincia:78,id_padre:14,id:2900,parametro:"Koluel Kaike"},
{id_pais:1,id_provincia:78,id_padre:14,id:2901,parametro:"Las Heras"},
{id_pais:1,id_provincia:78,id_padre:14,id:2902,parametro:"Pico Truncado"},
{id_pais:1,id_provincia:78,id_padre:14,id:2903,parametro:"Puerto Deseado"},
{id_pais:1,id_provincia:78,id_padre:14,id:2904,parametro:"Tellier"},
{id_pais:1,id_provincia:78,id_padre:21,id:2905,parametro:"El Turbio"},
{id_pais:1,id_provincia:78,id_padre:21,id:2906,parametro:"Julia Dufour"},
{id_pais:1,id_provincia:78,id_padre:21,id:2907,parametro:"Mina 3"},
{id_pais:1,id_provincia:78,id_padre:21,id:2908,parametro:"Río Gallegos"},
{id_pais:1,id_provincia:78,id_padre:21,id:2909,parametro:"Rospentek"},
{id_pais:1,id_provincia:78,id_padre:21,id:2910,parametro:"28 de Noviembre"},
{id_pais:1,id_provincia:78,id_padre:21,id:2911,parametro:"Yacimientos Río Turbio"},
{id_pais:1,id_provincia:78,id_padre:28,id:2912,parametro:"El Calafate"},
{id_pais:1,id_provincia:78,id_padre:28,id:2913,parametro:"El Chaltén"},
{id_pais:1,id_provincia:78,id_padre:28,id:2914,parametro:"Tres Lagos"},
{id_pais:1,id_provincia:78,id_padre:35,id:2915,parametro:"Los Antiguos"},
{id_pais:1,id_provincia:78,id_padre:35,id:2916,parametro:"Perito Moreno"},
{id_pais:1,id_provincia:78,id_padre:42,id:2917,parametro:"Puerto San Julián"},
{id_pais:1,id_provincia:78,id_padre:49,id:2918,parametro:"Bajo Caracoles"},
{id_pais:1,id_provincia:78,id_padre:49,id:2919,parametro:"Gobernador Gregores"},
{id_pais:1,id_provincia:78,id_padre:49,id:2920,parametro:"Hipólito Yrigoyen"},
{id_pais:1,id_provincia:82,id_padre:7,id:2921,parametro:"Armstrong"},
{id_pais:1,id_provincia:82,id_padre:7,id:2922,parametro:"Bouquet"},
{id_pais:1,id_provincia:82,id_padre:7,id:2923,parametro:"Las Parejas"},
{id_pais:1,id_provincia:82,id_padre:7,id:2924,parametro:"Las Rosas"},
{id_pais:1,id_provincia:82,id_padre:7,id:2925,parametro:"Montes de Oca"},
{id_pais:1,id_provincia:82,id_padre:7,id:2926,parametro:"Tortugas"},
{id_pais:1,id_provincia:82,id_padre:14,id:2927,parametro:"Arequito"},
{id_pais:1,id_provincia:82,id_padre:14,id:2928,parametro:"Arteaga"},
{id_pais:1,id_provincia:82,id_padre:14,id:2929,parametro:"Beravebú"},
{id_pais:1,id_provincia:82,id_padre:14,id:2930,parametro:"Bigand"},
{id_pais:1,id_provincia:82,id_padre:14,id:2931,parametro:"Casilda"},
{id_pais:1,id_provincia:82,id_padre:14,id:2932,parametro:"Chabas"},
{id_pais:1,id_provincia:82,id_padre:14,id:2933,parametro:"Chañar Ladeado"},
{id_pais:1,id_provincia:82,id_padre:14,id:2934,parametro:"G?Âdeken"},
{id_pais:1,id_provincia:82,id_padre:14,id:2935,parametro:"Los Molinos"},
{id_pais:1,id_provincia:82,id_padre:14,id:2936,parametro:"Los Nogales"},
{id_pais:1,id_provincia:82,id_padre:14,id:2937,parametro:"Los Quirquinchos"},
{id_pais:1,id_provincia:82,id_padre:14,id:2938,parametro:"San José de la Esquina"},
{id_pais:1,id_provincia:82,id_padre:14,id:2939,parametro:"Sanford"},
{id_pais:1,id_provincia:82,id_padre:14,id:2940,parametro:"Villada"},
{id_pais:1,id_provincia:82,id_padre:21,id:2941,parametro:"Aldao"},
{id_pais:1,id_provincia:82,id_padre:21,id:2942,parametro:"Angélica"},
{id_pais:1,id_provincia:82,id_padre:21,id:2943,parametro:"Ataliva"},
{id_pais:1,id_provincia:82,id_padre:21,id:2944,parametro:"Aurelia"},
{id_pais:1,id_provincia:82,id_padre:21,id:2945,parametro:"Barrios Acapulco y Veracruz"},
{id_pais:1,id_provincia:82,id_padre:21,id:2946,parametro:"Bauer y Sigel"},
{id_pais:1,id_provincia:82,id_padre:21,id:2947,parametro:"Bella Italia"},
{id_pais:1,id_provincia:82,id_padre:21,id:2948,parametro:"Castellanos"},
{id_pais:1,id_provincia:82,id_padre:21,id:2949,parametro:"Colonia Bicha"},
{id_pais:1,id_provincia:82,id_padre:21,id:2950,parametro:"Colonia Cello"},
{id_pais:1,id_provincia:82,id_padre:21,id:2951,parametro:"Colonia Margarita"},
{id_pais:1,id_provincia:82,id_padre:21,id:2952,parametro:"Colonia Raquel"},
{id_pais:1,id_provincia:82,id_padre:21,id:2953,parametro:"Coronel Fraga"},
{id_pais:1,id_provincia:82,id_padre:21,id:2954,parametro:"Egusquiza"},
{id_pais:1,id_provincia:82,id_padre:21,id:2955,parametro:"Esmeralda"},
{id_pais:1,id_provincia:82,id_padre:21,id:2956,parametro:"Estación Clucellas"},
{id_pais:1,id_provincia:82,id_padre:21,id:2957,parametro:"Estación Saguier"},
{id_pais:1,id_provincia:82,id_padre:21,id:2958,parametro:"Eusebia y Carolina"},
{id_pais:1,id_provincia:82,id_padre:21,id:2959,parametro:"Eustolia"},
{id_pais:1,id_provincia:82,id_padre:21,id:2960,parametro:"Frontera"},
{id_pais:1,id_provincia:82,id_padre:21,id:2961,parametro:"Garibaldi"},
{id_pais:1,id_provincia:82,id_padre:21,id:2962,parametro:"Humberto Primo"},
{id_pais:1,id_provincia:82,id_padre:21,id:2963,parametro:"Josefina"},
{id_pais:1,id_provincia:82,id_padre:21,id:2964,parametro:"Lehmann"},
{id_pais:1,id_provincia:82,id_padre:21,id:2965,parametro:"María Juana"},
{id_pais:1,id_provincia:82,id_padre:21,id:2966,parametro:"Nueva Lehmann"},
{id_pais:1,id_provincia:82,id_padre:21,id:2967,parametro:"Plaza Clucellas"},
{id_pais:1,id_provincia:82,id_padre:21,id:2968,parametro:"Plaza Saguier"},
{id_pais:1,id_provincia:82,id_padre:21,id:2969,parametro:"Presidente Roca"},
{id_pais:1,id_provincia:82,id_padre:21,id:2970,parametro:"Pueblo Marini"},
{id_pais:1,id_provincia:82,id_padre:21,id:2971,parametro:"Rafaela"},
{id_pais:1,id_provincia:82,id_padre:21,id:2972,parametro:"Ramona"},
{id_pais:1,id_provincia:82,id_padre:21,id:2973,parametro:"San Antonio"},
{id_pais:1,id_provincia:82,id_padre:21,id:2974,parametro:"San Vicente"},
{id_pais:1,id_provincia:82,id_padre:21,id:2975,parametro:"Santa Clara de Saguier"},
{id_pais:1,id_provincia:82,id_padre:21,id:2976,parametro:"Sunchales"},
{id_pais:1,id_provincia:82,id_padre:21,id:2977,parametro:"Susana"},
{id_pais:1,id_provincia:82,id_padre:21,id:2978,parametro:"Tacural"},
{id_pais:1,id_provincia:82,id_padre:21,id:2979,parametro:"Vila"},
{id_pais:1,id_provincia:82,id_padre:21,id:2980,parametro:"Villa Josefina"},
{id_pais:1,id_provincia:82,id_padre:21,id:2981,parametro:"Villa San José"},
{id_pais:1,id_provincia:82,id_padre:21,id:2982,parametro:"Virginia"},
{id_pais:1,id_provincia:82,id_padre:21,id:2983,parametro:"Zenón Pereyra"},
{id_pais:1,id_provincia:82,id_padre:28,id:2984,parametro:"Alcorta"},
{id_pais:1,id_provincia:82,id_padre:28,id:2985,parametro:"Barrio Arroyo del Medio"},
{id_pais:1,id_provincia:82,id_padre:28,id:2986,parametro:"Barrio Mitre"},
{id_pais:1,id_provincia:82,id_padre:28,id:2987,parametro:"Bombal"},
{id_pais:1,id_provincia:82,id_padre:28,id:2988,parametro:"Cañada Rica"},
{id_pais:1,id_provincia:82,id_padre:28,id:2989,parametro:"Cepeda"},
{id_pais:1,id_provincia:82,id_padre:28,id:2990,parametro:"Empalme Villa Constitución"},
{id_pais:1,id_provincia:82,id_padre:28,id:2991,parametro:"Firmat"},
{id_pais:1,id_provincia:82,id_padre:28,id:2992,parametro:"General Gelly"},
{id_pais:1,id_provincia:82,id_padre:28,id:2993,parametro:"Godoy"},
{id_pais:1,id_provincia:82,id_padre:28,id:2994,parametro:"Juan B. Molina"},
{id_pais:1,id_provincia:82,id_padre:28,id:2995,parametro:"Juncal"},
{id_pais:1,id_provincia:82,id_padre:28,id:2996,parametro:"La Vanguardia"},
{id_pais:1,id_provincia:82,id_padre:28,id:2997,parametro:"Máximo Paz"},
{id_pais:1,id_provincia:82,id_padre:28,id:2998,parametro:"Pavón"},
{id_pais:1,id_provincia:82,id_padre:28,id:2999,parametro:"Pavón Arriba"},
{id_pais:1,id_provincia:82,id_padre:28,id:3000,parametro:"Peyrano"},
{id_pais:1,id_provincia:82,id_padre:28,id:3001,parametro:"Rueda"},
{id_pais:1,id_provincia:82,id_padre:28,id:3002,parametro:"Santa Teresa"},
{id_pais:1,id_provincia:82,id_padre:28,id:3003,parametro:"Sargento Cabral"},
{id_pais:1,id_provincia:82,id_padre:28,id:3004,parametro:"Stephenson"},
{id_pais:1,id_provincia:82,id_padre:28,id:3005,parametro:"Theobald"},
{id_pais:1,id_provincia:82,id_padre:28,id:3006,parametro:"Villa Constitución"},
{id_pais:1,id_provincia:82,id_padre:35,id:3007,parametro:"Cayastá"},
{id_pais:1,id_provincia:82,id_padre:35,id:3008,parametro:"Helvecia"},
{id_pais:1,id_provincia:82,id_padre:35,id:3009,parametro:"Los Zapallos"},
{id_pais:1,id_provincia:82,id_padre:35,id:3010,parametro:"Saladero Mariano Cabal"},
{id_pais:1,id_provincia:82,id_padre:35,id:3011,parametro:"Santa Rosa de Calchines"},
{id_pais:1,id_provincia:82,id_padre:42,id:3012,parametro:"Aarón Castellanos"},
{id_pais:1,id_provincia:82,id_padre:42,id:3013,parametro:"Amenábar"},
{id_pais:1,id_provincia:82,id_padre:42,id:3014,parametro:"Cafferata"},
{id_pais:1,id_provincia:82,id_padre:42,id:3015,parametro:"Cañada del Ucle"},
{id_pais:1,id_provincia:82,id_padre:42,id:3016,parametro:"Carmen"},
{id_pais:1,id_provincia:82,id_padre:42,id:3017,parametro:"Carreras"},
{id_pais:1,id_provincia:82,id_padre:42,id:3018,parametro:"Chapuy"},
{id_pais:1,id_provincia:82,id_padre:42,id:3019,parametro:"Chovet"},
{id_pais:1,id_provincia:82,id_padre:42,id:3020,parametro:"Christophersen"},
{id_pais:1,id_provincia:82,id_padre:42,id:3021,parametro:"Diego de Alvear"},
{id_pais:1,id_provincia:82,id_padre:42,id:3022,parametro:"Elortondo"},
{id_pais:1,id_provincia:82,id_padre:42,id:3023,parametro:"Firmat"},
{id_pais:1,id_provincia:82,id_padre:42,id:3024,parametro:"Hughes"},
{id_pais:1,id_provincia:82,id_padre:42,id:3025,parametro:"La Chispa"},
{id_pais:1,id_provincia:82,id_padre:42,id:3026,parametro:"Labordeboy"},
{id_pais:1,id_provincia:82,id_padre:42,id:3027,parametro:"Lazzarino"},
{id_pais:1,id_provincia:82,id_padre:42,id:3028,parametro:"Maggiolo"},
{id_pais:1,id_provincia:82,id_padre:42,id:3029,parametro:"María Teresa"},
{id_pais:1,id_provincia:82,id_padre:42,id:3030,parametro:"Melincué"},
{id_pais:1,id_provincia:82,id_padre:42,id:3031,parametro:"Miguel Torres"},
{id_pais:1,id_provincia:82,id_padre:42,id:3032,parametro:"Murphy"},
{id_pais:1,id_provincia:82,id_padre:42,id:3033,parametro:"Rufino"},
{id_pais:1,id_provincia:82,id_padre:42,id:3034,parametro:"San Eduardo"},
{id_pais:1,id_provincia:82,id_padre:42,id:3035,parametro:"San Francisco de Santa Fe"},
{id_pais:1,id_provincia:82,id_padre:42,id:3036,parametro:"San Gregorio"},
{id_pais:1,id_provincia:82,id_padre:42,id:3037,parametro:"Sancti Spiritu"},
{id_pais:1,id_provincia:82,id_padre:42,id:3038,parametro:"Santa Isabel"},
{id_pais:1,id_provincia:82,id_padre:42,id:3039,parametro:"Teodelina"},
{id_pais:1,id_provincia:82,id_padre:42,id:3040,parametro:"Venado Tuerto"},
{id_pais:1,id_provincia:82,id_padre:42,id:3041,parametro:"Villa Cañás"},
{id_pais:1,id_provincia:82,id_padre:42,id:3042,parametro:"Wheelwright"},
{id_pais:1,id_provincia:82,id_padre:49,id:3043,parametro:"Arroyo Ceibal"},
{id_pais:1,id_provincia:82,id_padre:49,id:3044,parametro:"Avellaneda"},
{id_pais:1,id_provincia:82,id_padre:49,id:3045,parametro:"Berna"},
{id_pais:1,id_provincia:82,id_padre:49,id:3046,parametro:"El Araza"},
{id_pais:1,id_provincia:82,id_padre:49,id:3047,parametro:"El Rabón"},
{id_pais:1,id_provincia:82,id_padre:49,id:3048,parametro:"Florencia"},
{id_pais:1,id_provincia:82,id_padre:49,id:3049,parametro:"Guadalupe Norte"},
{id_pais:1,id_provincia:82,id_padre:49,id:3050,parametro:"Ingeniero Chanourdie"},
{id_pais:1,id_provincia:82,id_padre:49,id:3051,parametro:"La Isleta"},
{id_pais:1,id_provincia:82,id_padre:49,id:3052,parametro:"La Sarita"},
{id_pais:1,id_provincia:82,id_padre:49,id:3053,parametro:"Lanteri"},
{id_pais:1,id_provincia:82,id_padre:49,id:3054,parametro:"Las Garzas"},
{id_pais:1,id_provincia:82,id_padre:49,id:3055,parametro:"Las Toscas"},
{id_pais:1,id_provincia:82,id_padre:49,id:3056,parametro:"Los Laureles"},
{id_pais:1,id_provincia:82,id_padre:49,id:3057,parametro:"Malabrigo"},
{id_pais:1,id_provincia:82,id_padre:49,id:3058,parametro:"Paraje San Manuel"},
{id_pais:1,id_provincia:82,id_padre:49,id:3059,parametro:"Puerto Reconquista"},
{id_pais:1,id_provincia:82,id_padre:49,id:3060,parametro:"Reconquista"},
{id_pais:1,id_provincia:82,id_padre:49,id:3061,parametro:"San Antonio de Obligado"},
{id_pais:1,id_provincia:82,id_padre:49,id:3062,parametro:"Tacuarendí"},
{id_pais:1,id_provincia:82,id_padre:49,id:3063,parametro:"Villa Ana"},
{id_pais:1,id_provincia:82,id_padre:49,id:3064,parametro:"Villa Guillermina"},
{id_pais:1,id_provincia:82,id_padre:49,id:3065,parametro:"Villa Ocampo"},
{id_pais:1,id_provincia:82,id_padre:56,id:3066,parametro:"Barrio Cicarelli"},
{id_pais:1,id_provincia:82,id_padre:56,id:3067,parametro:"Bustinza"},
{id_pais:1,id_provincia:82,id_padre:56,id:3068,parametro:"Cañada de Gómez"},
{id_pais:1,id_provincia:82,id_padre:56,id:3069,parametro:"Carrizales"},
{id_pais:1,id_provincia:82,id_padre:56,id:3070,parametro:"Classon"},
{id_pais:1,id_provincia:82,id_padre:56,id:3071,parametro:"Colonia Médici"},
{id_pais:1,id_provincia:82,id_padre:56,id:3072,parametro:"Correa"},
{id_pais:1,id_provincia:82,id_padre:56,id:3073,parametro:"Larguía"},
{id_pais:1,id_provincia:82,id_padre:56,id:3074,parametro:"Lucio V. López"},
{id_pais:1,id_provincia:82,id_padre:56,id:3075,parametro:"Oliveros"},
{id_pais:1,id_provincia:82,id_padre:56,id:3076,parametro:"Pueblo Andino"},
{id_pais:1,id_provincia:82,id_padre:56,id:3077,parametro:"Salto Grande"},
{id_pais:1,id_provincia:82,id_padre:56,id:3078,parametro:"Serodino"},
{id_pais:1,id_provincia:82,id_padre:56,id:3079,parametro:"Totoras"},
{id_pais:1,id_provincia:82,id_padre:56,id:3080,parametro:"Villa Eloísa"},
{id_pais:1,id_provincia:82,id_padre:56,id:3081,parametro:"Villa La Rivera (Oliveros)"},
{id_pais:1,id_provincia:82,id_padre:56,id:3082,parametro:"Villa La Rivera (Pueblo Andino)"},
{id_pais:1,id_provincia:82,id_padre:63,id:3083,parametro:"Angel Gallardo"},
{id_pais:1,id_provincia:82,id_padre:63,id:3084,parametro:"Arroyo Aguiar"},
{id_pais:1,id_provincia:82,id_padre:63,id:3085,parametro:"Arroyo Leyes"},
{id_pais:1,id_provincia:82,id_padre:63,id:3086,parametro:"Cabal"},
{id_pais:1,id_provincia:82,id_padre:63,id:3087,parametro:"Campo Andino"},
{id_pais:1,id_provincia:82,id_padre:63,id:3088,parametro:"Candioti"},
{id_pais:1,id_provincia:82,id_padre:63,id:3089,parametro:"Emilia"},
{id_pais:1,id_provincia:82,id_padre:63,id:3090,parametro:"Laguna Paiva"},
{id_pais:1,id_provincia:82,id_padre:63,id:3091,parametro:"Llambi Campbell"},
{id_pais:1,id_provincia:82,id_padre:63,id:3092,parametro:"Monte Vera"},
{id_pais:1,id_provincia:82,id_padre:63,id:3093,parametro:"Paraje Chaco Chico"},
{id_pais:1,id_provincia:82,id_padre:63,id:3094,parametro:"Paraje La Costa"},
{id_pais:1,id_provincia:82,id_padre:63,id:3095,parametro:"Recreo"},
{id_pais:1,id_provincia:82,id_padre:63,id:3096,parametro:"Rincón Potrero"},
{id_pais:1,id_provincia:82,id_padre:63,id:3097,parametro:"San José del Rincón"},
{id_pais:1,id_provincia:82,id_padre:63,id:3098,parametro:"Santa Fe"},
{id_pais:1,id_provincia:82,id_padre:63,id:3099,parametro:"Santo Tomé"},
{id_pais:1,id_provincia:82,id_padre:63,id:3100,parametro:"Sauce Viejo"},
{id_pais:1,id_provincia:82,id_padre:63,id:3101,parametro:"Villa Laura"},
{id_pais:1,id_provincia:82,id_padre:70,id:3102,parametro:"Cavour"},
{id_pais:1,id_provincia:82,id_padre:70,id:3103,parametro:"Cululú"},
{id_pais:1,id_provincia:82,id_padre:70,id:3104,parametro:"Elisa"},
{id_pais:1,id_provincia:82,id_padre:70,id:3105,parametro:"Empalme San Carlos"},
{id_pais:1,id_provincia:82,id_padre:70,id:3106,parametro:"Esperanza"},
{id_pais:1,id_provincia:82,id_padre:70,id:3107,parametro:"Felicia"},
{id_pais:1,id_provincia:82,id_padre:70,id:3108,parametro:"Franck"},
{id_pais:1,id_provincia:82,id_padre:70,id:3109,parametro:"Grutly"},
{id_pais:1,id_provincia:82,id_padre:70,id:3110,parametro:"Hipatía"},
{id_pais:1,id_provincia:82,id_padre:70,id:3111,parametro:"Humboldt"},
{id_pais:1,id_provincia:82,id_padre:70,id:3112,parametro:"Jacinto L. Aráuz"},
{id_pais:1,id_provincia:82,id_padre:70,id:3113,parametro:"La Pelada"},
{id_pais:1,id_provincia:82,id_padre:70,id:3114,parametro:"Las Tunas"},
{id_pais:1,id_provincia:82,id_padre:70,id:3115,parametro:"María Luisa"},
{id_pais:1,id_provincia:82,id_padre:70,id:3116,parametro:"Matilde"},
{id_pais:1,id_provincia:82,id_padre:70,id:3117,parametro:"Nuevo Torino"},
{id_pais:1,id_provincia:82,id_padre:70,id:3118,parametro:"Pilar"},
{id_pais:1,id_provincia:82,id_padre:70,id:3119,parametro:"Plaza Matilde"},
{id_pais:1,id_provincia:82,id_padre:70,id:3120,parametro:"Progreso"},
{id_pais:1,id_provincia:82,id_padre:70,id:3121,parametro:"Providencia"},
{id_pais:1,id_provincia:82,id_padre:70,id:3122,parametro:"Sa Pereyra"},
{id_pais:1,id_provincia:82,id_padre:70,id:3123,parametro:"San Agustín"},
{id_pais:1,id_provincia:82,id_padre:70,id:3124,parametro:"San Carlos Centro"},
{id_pais:1,id_provincia:82,id_padre:70,id:3125,parametro:"San Carlos Norte"},
{id_pais:1,id_provincia:82,id_padre:70,id:3126,parametro:"San Carlos Sud"},
{id_pais:1,id_provincia:82,id_padre:70,id:3127,parametro:"San Jerónimo del Sauce"},
{id_pais:1,id_provincia:82,id_padre:70,id:3128,parametro:"San Jerónimo Norte"},
{id_pais:1,id_provincia:82,id_padre:70,id:3129,parametro:"San Mariano"},
{id_pais:1,id_provincia:82,id_padre:70,id:3130,parametro:"Santa Clara de Buena Vista"},
{id_pais:1,id_provincia:82,id_padre:70,id:3131,parametro:"Santo Domingo"},
{id_pais:1,id_provincia:82,id_padre:70,id:3132,parametro:"Sarmiento"},
{id_pais:1,id_provincia:82,id_padre:77,id:3133,parametro:"Esteban Rams"},
{id_pais:1,id_provincia:82,id_padre:77,id:3134,parametro:"Gato Colorado"},
{id_pais:1,id_provincia:82,id_padre:77,id:3135,parametro:"Gregoria Pérez de Denis"},
{id_pais:1,id_provincia:82,id_padre:77,id:3136,parametro:"Logroño"},
{id_pais:1,id_provincia:82,id_padre:77,id:3137,parametro:"Montefiore"},
{id_pais:1,id_provincia:82,id_padre:77,id:3138,parametro:"Pozo Borrado"},
{id_pais:1,id_provincia:82,id_padre:77,id:3139,parametro:"San Bernardo"},
{id_pais:1,id_provincia:82,id_padre:77,id:3140,parametro:"Santa Margarita"},
{id_pais:1,id_provincia:82,id_padre:77,id:3141,parametro:"Tostado"},
{id_pais:1,id_provincia:82,id_padre:77,id:3142,parametro:"Villa Minetti"},
{id_pais:1,id_provincia:82,id_padre:84,id:3143,parametro:"Acébal"},
{id_pais:1,id_provincia:82,id_padre:84,id:3144,parametro:"Albarellos"},
{id_pais:1,id_provincia:82,id_padre:84,id:3145,parametro:"álvarez"},
{id_pais:1,id_provincia:82,id_padre:84,id:3146,parametro:"Alvear"},
{id_pais:1,id_provincia:82,id_padre:84,id:3147,parametro:"Arbilla"},
{id_pais:1,id_provincia:82,id_padre:84,id:3148,parametro:"Arminda"},
{id_pais:1,id_provincia:82,id_padre:84,id:3149,parametro:"Arroyo Seco"},
{id_pais:1,id_provincia:82,id_padre:84,id:3150,parametro:"Carmen del Sauce"},
{id_pais:1,id_provincia:82,id_padre:84,id:3151,parametro:"Coronel Bogado"},
{id_pais:1,id_provincia:82,id_padre:84,id:3152,parametro:"Coronel Rodolfo S. Domínguez"},
{id_pais:1,id_provincia:82,id_padre:84,id:3153,parametro:"Cuatro Esquinas"},
{id_pais:1,id_provincia:82,id_padre:84,id:3154,parametro:"El Caramelo"},
{id_pais:1,id_provincia:82,id_padre:84,id:3155,parametro:"Fighiera"},
{id_pais:1,id_provincia:82,id_padre:84,id:3156,parametro:"Funes"},
{id_pais:1,id_provincia:82,id_padre:84,id:3157,parametro:"General Lagos"},
{id_pais:1,id_provincia:82,id_padre:84,id:3158,parametro:"Granadero Baigorria"},
{id_pais:1,id_provincia:82,id_padre:84,id:3159,parametro:"Ibarlucea"},
{id_pais:1,id_provincia:82,id_padre:84,id:3160,parametro:"Kilómetro 101"},
{id_pais:1,id_provincia:82,id_padre:84,id:3161,parametro:"Los Muchachos - La Alborada"},
{id_pais:1,id_provincia:82,id_padre:84,id:3162,parametro:"Monte Flores"},
{id_pais:1,id_provincia:82,id_padre:84,id:3163,parametro:"Pérez"},
{id_pais:1,id_provincia:82,id_padre:84,id:3164,parametro:"Piñero"},
{id_pais:1,id_provincia:82,id_padre:84,id:3165,parametro:"Pueblo Esther"},
{id_pais:1,id_provincia:82,id_padre:84,id:3166,parametro:"Pueblo Muñóz"},
{id_pais:1,id_provincia:82,id_padre:84,id:3167,parametro:"Pueblo Uranga"},
{id_pais:1,id_provincia:82,id_padre:84,id:3168,parametro:"Puerto Arroyo Seco"},
{id_pais:1,id_provincia:82,id_padre:84,id:3169,parametro:"Rosario"},
{id_pais:1,id_provincia:82,id_padre:84,id:3170,parametro:"Soldini"},
{id_pais:1,id_provincia:82,id_padre:84,id:3171,parametro:"Villa Amelia"},
{id_pais:1,id_provincia:82,id_padre:84,id:3172,parametro:"Villa del Plata"},
{id_pais:1,id_provincia:82,id_padre:84,id:3173,parametro:"Villa Gobernador Gálvez"},
{id_pais:1,id_provincia:82,id_padre:84,id:3174,parametro:"Zavalla"},
{id_pais:1,id_provincia:82,id_padre:91,id:3175,parametro:"Aguará Grande"},
{id_pais:1,id_provincia:82,id_padre:91,id:3176,parametro:"Ambrosetti"},
{id_pais:1,id_provincia:82,id_padre:91,id:3177,parametro:"Arrufo"},
{id_pais:1,id_provincia:82,id_padre:91,id:3178,parametro:"Balneario La Verde"},
{id_pais:1,id_provincia:82,id_padre:91,id:3179,parametro:"Capivara"},
{id_pais:1,id_provincia:82,id_padre:91,id:3180,parametro:"Ceres"},
{id_pais:1,id_provincia:82,id_padre:91,id:3181,parametro:"Colonia Ana"},
{id_pais:1,id_provincia:82,id_padre:91,id:3182,parametro:"Colonia Bossi"},
{id_pais:1,id_provincia:82,id_padre:91,id:3183,parametro:"Colonia Rosa"},
{id_pais:1,id_provincia:82,id_padre:91,id:3184,parametro:"Constanza"},
{id_pais:1,id_provincia:82,id_padre:91,id:3185,parametro:"Curupaytí"},
{id_pais:1,id_provincia:82,id_padre:91,id:3186,parametro:"Hersilia"},
{id_pais:1,id_provincia:82,id_padre:91,id:3187,parametro:"Huanqueros"},
{id_pais:1,id_provincia:82,id_padre:91,id:3188,parametro:"La Cabral"},
{id_pais:1,id_provincia:82,id_padre:91,id:3189,parametro:"La Lucila"},
{id_pais:1,id_provincia:82,id_padre:91,id:3190,parametro:"La Rubia"},
{id_pais:1,id_provincia:82,id_padre:91,id:3191,parametro:"Las Avispas"},
{id_pais:1,id_provincia:82,id_padre:91,id:3192,parametro:"Las Palmeras"},
{id_pais:1,id_provincia:82,id_padre:91,id:3193,parametro:"Moisés Ville"},
{id_pais:1,id_provincia:82,id_padre:91,id:3194,parametro:"Monigotes"},
{id_pais:1,id_provincia:82,id_padre:91,id:3195,parametro:"Ñanducita"},
{id_pais:1,id_provincia:82,id_padre:91,id:3196,parametro:"Palacios"},
{id_pais:1,id_provincia:82,id_padre:91,id:3197,parametro:"San Cristóbal"},
{id_pais:1,id_provincia:82,id_padre:91,id:3198,parametro:"San Guillermo"},
{id_pais:1,id_provincia:82,id_padre:91,id:3199,parametro:"Santurce"},
{id_pais:1,id_provincia:82,id_padre:91,id:3200,parametro:"Soledad"},
{id_pais:1,id_provincia:82,id_padre:91,id:3201,parametro:"Suardi"},
{id_pais:1,id_provincia:82,id_padre:91,id:3202,parametro:"Villa Saralegui"},
{id_pais:1,id_provincia:82,id_padre:91,id:3203,parametro:"Villa Trinidad"},
{id_pais:1,id_provincia:82,id_padre:98,id:3204,parametro:"Alejandra"},
{id_pais:1,id_provincia:82,id_padre:98,id:3205,parametro:"Cacique Ariacaiquín"},
{id_pais:1,id_provincia:82,id_padre:98,id:3206,parametro:"Colonia Durán"},
{id_pais:1,id_provincia:82,id_padre:98,id:3207,parametro:"La Brava"},
{id_pais:1,id_provincia:82,id_padre:98,id:3208,parametro:"Romang"},
{id_pais:1,id_provincia:82,id_padre:98,id:3209,parametro:"San Javier"},
{id_pais:1,id_provincia:82,id_padre:105,id:3210,parametro:"Arocena"},
{id_pais:1,id_provincia:82,id_padre:105,id:3211,parametro:"Balneario Monje"},
{id_pais:1,id_provincia:82,id_padre:105,id:3212,parametro:"Barrancas"},
{id_pais:1,id_provincia:82,id_padre:105,id:3213,parametro:"Barrio Caima"},
{id_pais:1,id_provincia:82,id_padre:105,id:3214,parametro:"Barrio El Pacaá - Barrio Comipini"},
{id_pais:1,id_provincia:82,id_padre:105,id:3215,parametro:"Bernardo de Irigoyen"},
{id_pais:1,id_provincia:82,id_padre:105,id:3216,parametro:"Casalegno"},
{id_pais:1,id_provincia:82,id_padre:105,id:3217,parametro:"Centeno"},
{id_pais:1,id_provincia:82,id_padre:105,id:3218,parametro:"Coronda"},
{id_pais:1,id_provincia:82,id_padre:105,id:3219,parametro:"Desvío Arijón"},
{id_pais:1,id_provincia:82,id_padre:105,id:3220,parametro:"Díaz"},
{id_pais:1,id_provincia:82,id_padre:105,id:3221,parametro:"Gaboto"},
{id_pais:1,id_provincia:82,id_padre:105,id:3222,parametro:"Gálvez"},
{id_pais:1,id_provincia:82,id_padre:105,id:3223,parametro:"Gessler"},
{id_pais:1,id_provincia:82,id_padre:105,id:3224,parametro:"Irigoyen"},
{id_pais:1,id_provincia:82,id_padre:105,id:3225,parametro:"Larrechea"},
{id_pais:1,id_provincia:82,id_padre:105,id:3226,parametro:"Loma Alta"},
{id_pais:1,id_provincia:82,id_padre:105,id:3227,parametro:"López"},
{id_pais:1,id_provincia:82,id_padre:105,id:3228,parametro:"Maciel"},
{id_pais:1,id_provincia:82,id_padre:105,id:3229,parametro:"Monje"},
{id_pais:1,id_provincia:82,id_padre:105,id:3230,parametro:"Puerto Aragón"},
{id_pais:1,id_provincia:82,id_padre:105,id:3231,parametro:"San Eugenio"},
{id_pais:1,id_provincia:82,id_padre:105,id:3232,parametro:"San Fabián"},
{id_pais:1,id_provincia:82,id_padre:105,id:3233,parametro:"San Genaro"},
{id_pais:1,id_provincia:82,id_padre:105,id:3234,parametro:"San Genaro Norte"},
{id_pais:1,id_provincia:82,id_padre:112,id:3235,parametro:"Angeloni"},
{id_pais:1,id_provincia:82,id_padre:112,id:3236,parametro:"Cayastacito"},
{id_pais:1,id_provincia:82,id_padre:112,id:3237,parametro:"Colonia Dolores"},
{id_pais:1,id_provincia:82,id_padre:112,id:3238,parametro:"Esther"},
{id_pais:1,id_provincia:82,id_padre:112,id:3239,parametro:"Gobernador Crespo"},
{id_pais:1,id_provincia:82,id_padre:112,id:3240,parametro:"La Criolla"},
{id_pais:1,id_provincia:82,id_padre:112,id:3241,parametro:"La Penca y Caraguatá"},
{id_pais:1,id_provincia:82,id_padre:112,id:3242,parametro:"Marcelino Escalada"},
{id_pais:1,id_provincia:82,id_padre:112,id:3243,parametro:"Naré"},
{id_pais:1,id_provincia:82,id_padre:112,id:3244,parametro:"Pedro Gómez Cello"},
{id_pais:1,id_provincia:82,id_padre:112,id:3245,parametro:"Ramayón"},
{id_pais:1,id_provincia:82,id_padre:112,id:3246,parametro:"San Bernardo"},
{id_pais:1,id_provincia:82,id_padre:112,id:3247,parametro:"San Justo"},
{id_pais:1,id_provincia:82,id_padre:112,id:3248,parametro:"San Martín Norte"},
{id_pais:1,id_provincia:82,id_padre:112,id:3249,parametro:"Silva"},
{id_pais:1,id_provincia:82,id_padre:112,id:3250,parametro:"Vera y Pintado"},
{id_pais:1,id_provincia:82,id_padre:112,id:3251,parametro:"Videla"},
{id_pais:1,id_provincia:82,id_padre:119,id:3252,parametro:"Aldao"},
{id_pais:1,id_provincia:82,id_padre:119,id:3253,parametro:"Capitán Bermúdez"},
{id_pais:1,id_provincia:82,id_padre:119,id:3254,parametro:"Carcarañá"},
{id_pais:1,id_provincia:82,id_padre:119,id:3255,parametro:"Coronel Arnold"},
{id_pais:1,id_provincia:82,id_padre:119,id:3256,parametro:"Fray Luis Beltrán"},
{id_pais:1,id_provincia:82,id_padre:119,id:3257,parametro:"Fuentes"},
{id_pais:1,id_provincia:82,id_padre:119,id:3258,parametro:"Luis Palacios"},
{id_pais:1,id_provincia:82,id_padre:119,id:3259,parametro:"Puerto General San Martín"},
{id_pais:1,id_provincia:82,id_padre:119,id:3260,parametro:"Pujato"},
{id_pais:1,id_provincia:82,id_padre:119,id:3261,parametro:"Ricardone"},
{id_pais:1,id_provincia:82,id_padre:119,id:3262,parametro:"Roldán"},
{id_pais:1,id_provincia:82,id_padre:119,id:3263,parametro:"San Jerónimo Sud"},
{id_pais:1,id_provincia:82,id_padre:119,id:3264,parametro:"San Lorenzo"},
{id_pais:1,id_provincia:82,id_padre:119,id:3265,parametro:"Timbúes"},
{id_pais:1,id_provincia:82,id_padre:119,id:3266,parametro:"Villa Elvira"},
{id_pais:1,id_provincia:82,id_padre:119,id:3267,parametro:"Villa Mugueta"},
{id_pais:1,id_provincia:82,id_padre:126,id:3268,parametro:"Cañada Rosquín"},
{id_pais:1,id_provincia:82,id_padre:126,id:3269,parametro:"Carlos Pellegrini"},
{id_pais:1,id_provincia:82,id_padre:126,id:3270,parametro:"Casas"},
{id_pais:1,id_provincia:82,id_padre:126,id:3271,parametro:"Castelar"},
{id_pais:1,id_provincia:82,id_padre:126,id:3272,parametro:"Colonia Belgrano"},
{id_pais:1,id_provincia:82,id_padre:126,id:3273,parametro:"Crispi"},
{id_pais:1,id_provincia:82,id_padre:126,id:3274,parametro:"El Trébol"},
{id_pais:1,id_provincia:82,id_padre:126,id:3275,parametro:"Landeta"},
{id_pais:1,id_provincia:82,id_padre:126,id:3276,parametro:"Las Bandurrias"},
{id_pais:1,id_provincia:82,id_padre:126,id:3277,parametro:"Las Petacas"},
{id_pais:1,id_provincia:82,id_padre:126,id:3278,parametro:"Los Cardos"},
{id_pais:1,id_provincia:82,id_padre:126,id:3279,parametro:"María Susana"},
{id_pais:1,id_provincia:82,id_padre:126,id:3280,parametro:"Piamonte"},
{id_pais:1,id_provincia:82,id_padre:126,id:3281,parametro:"San Jorge"},
{id_pais:1,id_provincia:82,id_padre:126,id:3282,parametro:"San Martín de las Escobas"},
{id_pais:1,id_provincia:82,id_padre:126,id:3283,parametro:"Sastre"},
{id_pais:1,id_provincia:82,id_padre:126,id:3284,parametro:"Traill"},
{id_pais:1,id_provincia:82,id_padre:126,id:3285,parametro:"Wildermuth"},
{id_pais:1,id_provincia:82,id_padre:133,id:3286,parametro:"Calchaquí"},
{id_pais:1,id_provincia:82,id_padre:133,id:3287,parametro:"Cañada Ombú"},
{id_pais:1,id_provincia:82,id_padre:133,id:3288,parametro:"Colmena"},
{id_pais:1,id_provincia:82,id_padre:133,id:3289,parametro:"Fortín Olmos"},
{id_pais:1,id_provincia:82,id_padre:133,id:3290,parametro:"Garabato"},
{id_pais:1,id_provincia:82,id_padre:133,id:3291,parametro:"Golondrina"},
{id_pais:1,id_provincia:82,id_padre:133,id:3292,parametro:"Intiyaco"},
{id_pais:1,id_provincia:82,id_padre:133,id:3293,parametro:"Kilómetro 115"},
{id_pais:1,id_provincia:82,id_padre:133,id:3294,parametro:"La Gallareta"},
{id_pais:1,id_provincia:82,id_padre:133,id:3295,parametro:"Los Amores"},
{id_pais:1,id_provincia:82,id_padre:133,id:3296,parametro:"Margarita"},
{id_pais:1,id_provincia:82,id_padre:133,id:3297,parametro:"Paraje 29"},
{id_pais:1,id_provincia:82,id_padre:133,id:3298,parametro:"Pozo de los Indios"},
{id_pais:1,id_provincia:82,id_padre:133,id:3299,parametro:"Pueblo Santa Lucía"},
{id_pais:1,id_provincia:82,id_padre:133,id:3300,parametro:"Tartagal"},
{id_pais:1,id_provincia:82,id_padre:133,id:3301,parametro:"Toba"},
{id_pais:1,id_provincia:82,id_padre:133,id:3302,parametro:"Vera"},
{id_pais:1,id_provincia:86,id_padre:7,id:3303,parametro:"Argentina"},
{id_pais:1,id_provincia:86,id_padre:7,id:3304,parametro:"Casares"},
{id_pais:1,id_provincia:86,id_padre:7,id:3305,parametro:"Malbrán"},
{id_pais:1,id_provincia:86,id_padre:7,id:3306,parametro:"Villa General Mitre"},
{id_pais:1,id_provincia:86,id_padre:14,id:3307,parametro:"Campo Gallo"},
{id_pais:1,id_provincia:86,id_padre:14,id:3308,parametro:"Coronel Manuel L. Rico"},
{id_pais:1,id_provincia:86,id_padre:14,id:3309,parametro:"Donadeu"},
{id_pais:1,id_provincia:86,id_padre:14,id:3310,parametro:"Sachayoj"},
{id_pais:1,id_provincia:86,id_padre:14,id:3311,parametro:"Santos Lugares"},
{id_pais:1,id_provincia:86,id_padre:21,id:3312,parametro:"Estación Atamisqui"},
{id_pais:1,id_provincia:86,id_padre:21,id:3313,parametro:"Medellín"},
{id_pais:1,id_provincia:86,id_padre:21,id:3314,parametro:"Villa Atamisqui"},
{id_pais:1,id_provincia:86,id_padre:28,id:3315,parametro:"Colonia Dora"},
{id_pais:1,id_provincia:86,id_padre:28,id:3316,parametro:"Herrera"},
{id_pais:1,id_provincia:86,id_padre:28,id:3317,parametro:"Icaño"},
{id_pais:1,id_provincia:86,id_padre:28,id:3318,parametro:"Lugones"},
{id_pais:1,id_provincia:86,id_padre:28,id:3319,parametro:"Real Sayana"},
{id_pais:1,id_provincia:86,id_padre:28,id:3320,parametro:"Villa Mailín"},
{id_pais:1,id_provincia:86,id_padre:35,id:3321,parametro:"Abra Grande"},
{id_pais:1,id_provincia:86,id_padre:35,id:3322,parametro:"Antajé"},
{id_pais:1,id_provincia:86,id_padre:35,id:3323,parametro:"Ardiles"},
{id_pais:1,id_provincia:86,id_padre:35,id:3324,parametro:"Cañada Escobar"},
{id_pais:1,id_provincia:86,id_padre:35,id:3325,parametro:"Chaupi Pozo"},
{id_pais:1,id_provincia:86,id_padre:35,id:3326,parametro:"Clodomira"},
{id_pais:1,id_provincia:86,id_padre:35,id:3327,parametro:"Huyamampa"},
{id_pais:1,id_provincia:86,id_padre:35,id:3328,parametro:"La Aurora"},
{id_pais:1,id_provincia:86,id_padre:35,id:3329,parametro:"La Banda"},
{id_pais:1,id_provincia:86,id_padre:35,id:3330,parametro:"La Dársena"},
{id_pais:1,id_provincia:86,id_padre:35,id:3331,parametro:"Los Quiroga"},
{id_pais:1,id_provincia:86,id_padre:35,id:3332,parametro:"Los Soria"},
{id_pais:1,id_provincia:86,id_padre:35,id:3333,parametro:"Simbolar"},
{id_pais:1,id_provincia:86,id_padre:35,id:3334,parametro:"Tramo 16"},
{id_pais:1,id_provincia:86,id_padre:35,id:3335,parametro:"Tramo 20"},
{id_pais:1,id_provincia:86,id_padre:42,id:3336,parametro:"Bandera"},
{id_pais:1,id_provincia:86,id_padre:42,id:3337,parametro:"Cuatro Bocas"},
{id_pais:1,id_provincia:86,id_padre:42,id:3338,parametro:"Fortín Inca"},
{id_pais:1,id_provincia:86,id_padre:42,id:3339,parametro:"Guardia Escolta"},
{id_pais:1,id_provincia:86,id_padre:49,id:3340,parametro:"El Deán"},
{id_pais:1,id_provincia:86,id_padre:49,id:3341,parametro:"El Mojón"},
{id_pais:1,id_provincia:86,id_padre:49,id:3342,parametro:"El Zanjón"},
{id_pais:1,id_provincia:86,id_padre:49,id:3343,parametro:"Los Cardozos"},
{id_pais:1,id_provincia:86,id_padre:49,id:3344,parametro:"Maco"},
{id_pais:1,id_provincia:86,id_padre:49,id:3345,parametro:"Maquito"},
{id_pais:1,id_provincia:86,id_padre:49,id:3346,parametro:"Morales"},
{id_pais:1,id_provincia:86,id_padre:49,id:3347,parametro:"Puesto de San Antonio"},
{id_pais:1,id_provincia:86,id_padre:49,id:3348,parametro:"San Pedro"},
{id_pais:1,id_provincia:86,id_padre:49,id:3349,parametro:"Santa María"},
{id_pais:1,id_provincia:86,id_padre:49,id:3350,parametro:"Santiago del Estero"},
{id_pais:1,id_provincia:86,id_padre:49,id:3351,parametro:"Vuelta de la Barranca"},
{id_pais:1,id_provincia:86,id_padre:49,id:3352,parametro:"Yanda"},
{id_pais:1,id_provincia:86,id_padre:56,id:3353,parametro:"El Caburé"},
{id_pais:1,id_provincia:86,id_padre:56,id:3354,parametro:"La Firmeza"},
{id_pais:1,id_provincia:86,id_padre:56,id:3355,parametro:"Los Pirpintos"},
{id_pais:1,id_provincia:86,id_padre:56,id:3356,parametro:"Los Tigres"},
{id_pais:1,id_provincia:86,id_padre:56,id:3357,parametro:"Monte Quemado"},
{id_pais:1,id_provincia:86,id_padre:56,id:3358,parametro:"Nueva Esperanza"},
{id_pais:1,id_provincia:86,id_padre:56,id:3359,parametro:"Pampa de los Guanacos"},
{id_pais:1,id_provincia:86,id_padre:56,id:3360,parametro:"San José del Boquerón"},
{id_pais:1,id_provincia:86,id_padre:56,id:3361,parametro:"Urutaú"},
{id_pais:1,id_provincia:86,id_padre:63,id:3362,parametro:"Ancaján"},
{id_pais:1,id_provincia:86,id_padre:63,id:3363,parametro:"Choya"},
{id_pais:1,id_provincia:86,id_padre:63,id:3364,parametro:"Estación La Punta"},
{id_pais:1,id_provincia:86,id_padre:63,id:3365,parametro:"Frías"},
{id_pais:1,id_provincia:86,id_padre:63,id:3366,parametro:"Laprida"},
{id_pais:1,id_provincia:86,id_padre:63,id:3367,parametro:"San Pedro"},
{id_pais:1,id_provincia:86,id_padre:63,id:3368,parametro:"Tapso"},
{id_pais:1,id_provincia:86,id_padre:63,id:3369,parametro:"Villa La Punta"},
{id_pais:1,id_provincia:86,id_padre:70,id:3370,parametro:"Bandera Bajada"},
{id_pais:1,id_provincia:86,id_padre:70,id:3371,parametro:"Caspi Corral"},
{id_pais:1,id_provincia:86,id_padre:70,id:3372,parametro:"Colonia San Juan"},
{id_pais:1,id_provincia:86,id_padre:70,id:3373,parametro:"El Crucero"},
{id_pais:1,id_provincia:86,id_padre:70,id:3374,parametro:"Kilómetro 30"},
{id_pais:1,id_provincia:86,id_padre:70,id:3375,parametro:"La Cañada"},
{id_pais:1,id_provincia:86,id_padre:70,id:3376,parametro:"La Invernada"},
{id_pais:1,id_provincia:86,id_padre:70,id:3377,parametro:"Minerva"},
{id_pais:1,id_provincia:86,id_padre:70,id:3378,parametro:"Vaca Huañuna"},
{id_pais:1,id_provincia:86,id_padre:70,id:3379,parametro:"Villa Figueroa"},
{id_pais:1,id_provincia:86,id_padre:77,id:3380,parametro:"Añatuya"},
{id_pais:1,id_provincia:86,id_padre:77,id:3381,parametro:"Averías"},
{id_pais:1,id_provincia:86,id_padre:77,id:3382,parametro:"Estación Tacañitas"},
{id_pais:1,id_provincia:86,id_padre:77,id:3383,parametro:"La Nena"},
{id_pais:1,id_provincia:86,id_padre:77,id:3384,parametro:"Los Juríes"},
{id_pais:1,id_provincia:86,id_padre:77,id:3385,parametro:"Tomás Young"},
{id_pais:1,id_provincia:86,id_padre:84,id:3386,parametro:"Lavalle"},
{id_pais:1,id_provincia:86,id_padre:84,id:3387,parametro:"San Pedro"},
{id_pais:1,id_provincia:86,id_padre:91,id:3388,parametro:"El Arenal"},
{id_pais:1,id_provincia:86,id_padre:91,id:3389,parametro:"El Bobadal"},
{id_pais:1,id_provincia:86,id_padre:91,id:3390,parametro:"El Charco"},
{id_pais:1,id_provincia:86,id_padre:91,id:3391,parametro:"El Rincón"},
{id_pais:1,id_provincia:86,id_padre:91,id:3392,parametro:"Gramilla"},
{id_pais:1,id_provincia:86,id_padre:91,id:3393,parametro:"Isca Yacu"},
{id_pais:1,id_provincia:86,id_padre:91,id:3394,parametro:"Isca Yacu Semaul"},
{id_pais:1,id_provincia:86,id_padre:91,id:3395,parametro:"Pozo Hondo"},
{id_pais:1,id_provincia:86,id_padre:91,id:3396,parametro:"San Pedro"},
{id_pais:1,id_provincia:86,id_padre:98,id:3397,parametro:"El Colorado"},
{id_pais:1,id_provincia:86,id_padre:98,id:3398,parametro:"El Cuadrado"},
{id_pais:1,id_provincia:86,id_padre:98,id:3399,parametro:"Matará"},
{id_pais:1,id_provincia:86,id_padre:98,id:3400,parametro:"Suncho Corral"},
{id_pais:1,id_provincia:86,id_padre:98,id:3401,parametro:"Vilelas"},
{id_pais:1,id_provincia:86,id_padre:98,id:3402,parametro:"Yuchán"},
{id_pais:1,id_provincia:86,id_padre:105,id:3403,parametro:"Villa San Martín (Est. Loreto)"},
{id_pais:1,id_provincia:86,id_padre:112,id:3404,parametro:"Villa Unión"},
{id_pais:1,id_provincia:86,id_padre:119,id:3405,parametro:"Aerolito"},
{id_pais:1,id_provincia:86,id_padre:119,id:3406,parametro:"Alhuampa"},
{id_pais:1,id_provincia:86,id_padre:119,id:3407,parametro:"Hasse"},
{id_pais:1,id_provincia:86,id_padre:119,id:3408,parametro:"Hernán Mejía Miraval"},
{id_pais:1,id_provincia:86,id_padre:119,id:3409,parametro:"Las Tinajas"},
{id_pais:1,id_provincia:86,id_padre:119,id:3410,parametro:"Libertad"},
{id_pais:1,id_provincia:86,id_padre:119,id:3411,parametro:"Lilo Viejo"},
{id_pais:1,id_provincia:86,id_padre:119,id:3412,parametro:"Patay"},
{id_pais:1,id_provincia:86,id_padre:119,id:3413,parametro:"Pueblo Pablo Torelo"},
{id_pais:1,id_provincia:86,id_padre:119,id:3414,parametro:"Quimili"},
{id_pais:1,id_provincia:86,id_padre:119,id:3415,parametro:"Roversi"},
{id_pais:1,id_provincia:86,id_padre:119,id:3416,parametro:"Tintina"},
{id_pais:1,id_provincia:86,id_padre:119,id:3417,parametro:"Weisburd"},
{id_pais:1,id_provincia:86,id_padre:126,id:3418,parametro:"El 49"},
{id_pais:1,id_provincia:86,id_padre:126,id:3419,parametro:"Sol de Julio"},
{id_pais:1,id_provincia:86,id_padre:126,id:3420,parametro:"Villa Ojo de Agua"},
{id_pais:1,id_provincia:86,id_padre:133,id:3421,parametro:"El Mojón"},
{id_pais:1,id_provincia:86,id_padre:133,id:3422,parametro:"Las Delicias"},
{id_pais:1,id_provincia:86,id_padre:133,id:3423,parametro:"Nueva Esperanza"},
{id_pais:1,id_provincia:86,id_padre:133,id:3424,parametro:"Pozo Betbeder"},
{id_pais:1,id_provincia:86,id_padre:133,id:3425,parametro:"Rapelli"},
{id_pais:1,id_provincia:86,id_padre:133,id:3426,parametro:"Santo Domingo"},
{id_pais:1,id_provincia:86,id_padre:140,id:3427,parametro:"Ramírez de Velazco"},
{id_pais:1,id_provincia:86,id_padre:140,id:3428,parametro:"Sumampa"},
{id_pais:1,id_provincia:86,id_padre:140,id:3429,parametro:"Sumampa Viejo"},
{id_pais:1,id_provincia:86,id_padre:147,id:3430,parametro:"Chañar Pozo de Abajo"},
{id_pais:1,id_provincia:86,id_padre:147,id:3431,parametro:"Chauchillas"},
{id_pais:1,id_provincia:86,id_padre:147,id:3432,parametro:"Colonia Tinco"},
{id_pais:1,id_provincia:86,id_padre:147,id:3433,parametro:"El Charco"},
{id_pais:1,id_provincia:86,id_padre:147,id:3434,parametro:"Gramilla"},
{id_pais:1,id_provincia:86,id_padre:147,id:3435,parametro:"La Nueva Donosa"},
{id_pais:1,id_provincia:86,id_padre:147,id:3436,parametro:"Los Miranda"},
{id_pais:1,id_provincia:86,id_padre:147,id:3437,parametro:"Los Núñez"},
{id_pais:1,id_provincia:86,id_padre:147,id:3438,parametro:"Mansupa"},
{id_pais:1,id_provincia:86,id_padre:147,id:3439,parametro:"Pozuelos"},
{id_pais:1,id_provincia:86,id_padre:147,id:3440,parametro:"Rodeo de Valdez"},
{id_pais:1,id_provincia:86,id_padre:147,id:3441,parametro:"El Sauzal"},
{id_pais:1,id_provincia:86,id_padre:147,id:3442,parametro:"Termas de Río Hondo"},
{id_pais:1,id_provincia:86,id_padre:147,id:3443,parametro:"Villa Giménez"},
{id_pais:1,id_provincia:86,id_padre:147,id:3444,parametro:"Villa Río Hondo"},
{id_pais:1,id_provincia:86,id_padre:147,id:3445,parametro:"Villa Turística del Embalse de Rio Hondo"},
{id_pais:1,id_provincia:86,id_padre:147,id:3446,parametro:"Vinará"},
{id_pais:1,id_provincia:86,id_padre:154,id:3447,parametro:"Colonia Alpina"},
{id_pais:1,id_provincia:86,id_padre:154,id:3448,parametro:"Palo Negro"},
{id_pais:1,id_provincia:86,id_padre:154,id:3449,parametro:"Selva"},
{id_pais:1,id_provincia:86,id_padre:161,id:3450,parametro:"Beltrán"},
{id_pais:1,id_provincia:86,id_padre:161,id:3451,parametro:"Colonia El Simbolar"},
{id_pais:1,id_provincia:86,id_padre:161,id:3452,parametro:"Fernández"},
{id_pais:1,id_provincia:86,id_padre:161,id:3453,parametro:"Ingeniero Forres"},
{id_pais:1,id_provincia:86,id_padre:161,id:3454,parametro:"Vilmer"},
{id_pais:1,id_provincia:86,id_padre:168,id:3455,parametro:"Chilca Juliana"},
{id_pais:1,id_provincia:86,id_padre:168,id:3456,parametro:"Los Telares"},
{id_pais:1,id_provincia:86,id_padre:168,id:3457,parametro:"Villa Salavina"},
{id_pais:1,id_provincia:86,id_padre:175,id:3458,parametro:"Brea Pozo"},
{id_pais:1,id_provincia:86,id_padre:175,id:3459,parametro:"Estación Robles"},
{id_pais:1,id_provincia:86,id_padre:175,id:3460,parametro:"Estación Taboada"},
{id_pais:1,id_provincia:86,id_padre:175,id:3461,parametro:"Villa Nueva"},
{id_pais:1,id_provincia:86,id_padre:182,id:3462,parametro:"Garza"},
{id_pais:1,id_provincia:86,id_padre:189,id:3463,parametro:"árraga"},
{id_pais:1,id_provincia:86,id_padre:189,id:3464,parametro:"Nueva Francia"},
{id_pais:1,id_provincia:86,id_padre:189,id:3465,parametro:"Simbol"},
{id_pais:1,id_provincia:86,id_padre:189,id:3466,parametro:"Sumamao"},
{id_pais:1,id_provincia:86,id_padre:189,id:3467,parametro:"Villa Silípica"},
{id_pais:1,id_provincia:90,id_padre:7,id:3468,parametro:"Barrio San Jorge"},
{id_pais:1,id_provincia:90,id_padre:7,id:3469,parametro:"El Chañar"},
{id_pais:1,id_provincia:90,id_padre:7,id:3470,parametro:"El Naranjo"},
{id_pais:1,id_provincia:90,id_padre:7,id:3471,parametro:"Garmendia"},
{id_pais:1,id_provincia:90,id_padre:7,id:3472,parametro:"La Ramada"},
{id_pais:1,id_provincia:90,id_padre:7,id:3473,parametro:"Macomitas"},
{id_pais:1,id_provincia:90,id_padre:7,id:3474,parametro:"Piedrabuena"},
{id_pais:1,id_provincia:90,id_padre:7,id:3475,parametro:"7 de Abril"},
{id_pais:1,id_provincia:90,id_padre:7,id:3476,parametro:"Villa Benjamín Aráoz"},
{id_pais:1,id_provincia:90,id_padre:7,id:3477,parametro:"Villa Burruyacú"},
{id_pais:1,id_provincia:90,id_padre:7,id:3478,parametro:"Villa  Padre Monti"},
{id_pais:1,id_provincia:90,id_padre:14,id:3479,parametro:"Alderetes"},
{id_pais:1,id_provincia:90,id_padre:14,id:3480,parametro:"Banda del Río Salí"},
{id_pais:1,id_provincia:90,id_padre:14,id:3481,parametro:"Colombres"},
{id_pais:1,id_provincia:90,id_padre:14,id:3482,parametro:"Colonia Mayo - Barrio La Milagrosa"},
{id_pais:1,id_provincia:90,id_padre:14,id:3483,parametro:"Delfín Gallo"},
{id_pais:1,id_provincia:90,id_padre:14,id:3484,parametro:"El Bracho"},
{id_pais:1,id_provincia:90,id_padre:14,id:3485,parametro:"La Florida"},
{id_pais:1,id_provincia:90,id_padre:14,id:3486,parametro:"Las Cejas"},
{id_pais:1,id_provincia:90,id_padre:14,id:3487,parametro:"Los Ralos"},
{id_pais:1,id_provincia:90,id_padre:14,id:3488,parametro:"Pacará"},
{id_pais:1,id_provincia:90,id_padre:14,id:3489,parametro:"Ranchillos"},
{id_pais:1,id_provincia:90,id_padre:14,id:3490,parametro:"San Andrés"},
{id_pais:1,id_provincia:90,id_padre:21,id:3491,parametro:"Alpachiri"},
{id_pais:1,id_provincia:90,id_padre:21,id:3492,parametro:"Alto Verde"},
{id_pais:1,id_provincia:90,id_padre:21,id:3493,parametro:"Arcadia"},
{id_pais:1,id_provincia:90,id_padre:21,id:3494,parametro:"Barrio San Roque"},
{id_pais:1,id_provincia:90,id_padre:21,id:3495,parametro:"Concepción"},
{id_pais:1,id_provincia:90,id_padre:21,id:3496,parametro:"Iltico"},
{id_pais:1,id_provincia:90,id_padre:21,id:3497,parametro:"La Trinidad"},
{id_pais:1,id_provincia:90,id_padre:21,id:3498,parametro:"Medina"},
{id_pais:1,id_provincia:90,id_padre:28,id:3499,parametro:"Barrio Casa Rosada"},
{id_pais:1,id_provincia:90,id_padre:28,id:3500,parametro:"Campo de Herrera"},
{id_pais:1,id_provincia:90,id_padre:28,id:3501,parametro:"Famaillá"},
{id_pais:1,id_provincia:90,id_padre:28,id:3502,parametro:"Ingenio Fronterita"},
{id_pais:1,id_provincia:90,id_padre:35,id:3503,parametro:"Graneros"},
{id_pais:1,id_provincia:90,id_padre:35,id:3504,parametro:"Lamadrid"},
{id_pais:1,id_provincia:90,id_padre:35,id:3505,parametro:"Taco Ralo"},
{id_pais:1,id_provincia:90,id_padre:42,id:3506,parametro:"Juan Bautista Alberdi"},
{id_pais:1,id_provincia:90,id_padre:42,id:3507,parametro:"Villa Belgrano"},
{id_pais:1,id_provincia:90,id_padre:49,id:3508,parametro:"La Cocha"},
{id_pais:1,id_provincia:90,id_padre:49,id:3509,parametro:"San José de La Cocha"},
{id_pais:1,id_provincia:90,id_padre:56,id:3510,parametro:"Bella Vista"},
{id_pais:1,id_provincia:90,id_padre:56,id:3511,parametro:"Estación Aráoz"},
{id_pais:1,id_provincia:90,id_padre:56,id:3512,parametro:"Los Puestos"},
{id_pais:1,id_provincia:90,id_padre:56,id:3513,parametro:"Manuel García Fernández"},
{id_pais:1,id_provincia:90,id_padre:56,id:3514,parametro:"Pala Pala"},
{id_pais:1,id_provincia:90,id_padre:56,id:3515,parametro:"Río Colorado"},
{id_pais:1,id_provincia:90,id_padre:56,id:3516,parametro:"Santa Rosa de Leales"},
{id_pais:1,id_provincia:90,id_padre:56,id:3517,parametro:"Villa Fiad - Ingenio Leales"},
{id_pais:1,id_provincia:90,id_padre:56,id:3518,parametro:"Villa de Leales"},
{id_pais:1,id_provincia:90,id_padre:63,id:3519,parametro:"Barrio San Felipe"},
{id_pais:1,id_provincia:90,id_padre:63,id:3520,parametro:"El Manantial"},
{id_pais:1,id_provincia:90,id_padre:63,id:3521,parametro:"Ingenio San Pablo"},
{id_pais:1,id_provincia:90,id_padre:63,id:3522,parametro:"La Reducción"},
{id_pais:1,id_provincia:90,id_padre:63,id:3523,parametro:"Lules"},
{id_pais:1,id_provincia:90,id_padre:70,id:3524,parametro:"Acheral"},
{id_pais:1,id_provincia:90,id_padre:70,id:3525,parametro:"Capitán Cáceres"},
{id_pais:1,id_provincia:90,id_padre:70,id:3526,parametro:"Monteros"},
{id_pais:1,id_provincia:90,id_padre:70,id:3527,parametro:"Pueblo Independencia"},
{id_pais:1,id_provincia:90,id_padre:70,id:3528,parametro:"Río Seco"},
{id_pais:1,id_provincia:90,id_padre:70,id:3529,parametro:"Santa Lucía"},
{id_pais:1,id_provincia:90,id_padre:70,id:3530,parametro:"Sargento Moya"},
{id_pais:1,id_provincia:90,id_padre:70,id:3531,parametro:"Soldado Maldonado"},
{id_pais:1,id_provincia:90,id_padre:70,id:3532,parametro:"Teniente Berdina"},
{id_pais:1,id_provincia:90,id_padre:70,id:3533,parametro:"Villa Quinteros"},
{id_pais:1,id_provincia:90,id_padre:77,id:3534,parametro:"Aguilares"},
{id_pais:1,id_provincia:90,id_padre:77,id:3535,parametro:"Los Sarmientos"},
{id_pais:1,id_provincia:90,id_padre:77,id:3536,parametro:"Río Chico"},
{id_pais:1,id_provincia:90,id_padre:77,id:3537,parametro:"Santa Ana"},
{id_pais:1,id_provincia:90,id_padre:77,id:3538,parametro:"Villa Clodomiro Hileret"},
{id_pais:1,id_provincia:90,id_padre:84,id:3539,parametro:"San Miguel de Tucumán"},
{id_pais:1,id_provincia:90,id_padre:91,id:3540,parametro:"Atahona"},
{id_pais:1,id_provincia:90,id_padre:91,id:3541,parametro:"Monteagudo"},
{id_pais:1,id_provincia:90,id_padre:91,id:3542,parametro:"Nueva Trinidad"},
{id_pais:1,id_provincia:90,id_padre:91,id:3543,parametro:"Santa Cruz"},
{id_pais:1,id_provincia:90,id_padre:91,id:3544,parametro:"Simoca"},
{id_pais:1,id_provincia:90,id_padre:91,id:3545,parametro:"Villa Chicligasta"},
{id_pais:1,id_provincia:90,id_padre:98,id:3546,parametro:"Amaicha del Valle"},
{id_pais:1,id_provincia:90,id_padre:98,id:3547,parametro:"Colalao del Valle"},
{id_pais:1,id_provincia:90,id_padre:98,id:3548,parametro:"El Mollar"},
{id_pais:1,id_provincia:90,id_padre:98,id:3549,parametro:"Tafí del Valle"},
{id_pais:1,id_provincia:90,id_padre:105,id:3550,parametro:"Barrio El Cruce"},
{id_pais:1,id_provincia:90,id_padre:105,id:3551,parametro:"Barrio Lomas de Tafí"},
{id_pais:1,id_provincia:90,id_padre:105,id:3552,parametro:"Barrio Mutual San Martín"},
{id_pais:1,id_provincia:90,id_padre:105,id:3553,parametro:"Barrio Parada 14"},
{id_pais:1,id_provincia:90,id_padre:105,id:3554,parametro:"Barrio U.T.A. II"},
{id_pais:1,id_provincia:90,id_padre:105,id:3555,parametro:"Diagonal Norte - Luz y Fuerza - Los Pocitos - Villa Nueva Italia"},
{id_pais:1,id_provincia:90,id_padre:105,id:3556,parametro:"El Cadillal"},
{id_pais:1,id_provincia:90,id_padre:105,id:3557,parametro:"Tafí Viejo"},
{id_pais:1,id_provincia:90,id_padre:105,id:3558,parametro:"Villa Las Flores"},
{id_pais:1,id_provincia:90,id_padre:105,id:3559,parametro:"Villa Mariano Moreno - El Colmenar"},
{id_pais:1,id_provincia:90,id_padre:112,id:3560,parametro:"Choromoro"},
{id_pais:1,id_provincia:90,id_padre:112,id:3561,parametro:"San Pedro de Colalao"},
{id_pais:1,id_provincia:90,id_padre:112,id:3562,parametro:"Villa de Trancas"},
{id_pais:1,id_provincia:90,id_padre:119,id:3563,parametro:"Barrio San José III"},
{id_pais:1,id_provincia:90,id_padre:119,id:3564,parametro:"Villa Carmela"},
{id_pais:1,id_provincia:90,id_padre:119,id:3565,parametro:"Yerba Buena - Marcos Paz"},
{id_pais:1,id_provincia:94,id_padre:7,id:3566,parametro:"Río Grande"},
{id_pais:1,id_provincia:94,id_padre:7,id:3567,parametro:"Tolhuin"},
{id_pais:1,id_provincia:94,id_padre:14,id:3568,parametro:"Laguna Escondida"},
{id_pais:1,id_provincia:94,id_padre:14,id:3569,parametro:"Ushuaia"}
];

const tipoProfesionalData = [
    {id:'1', parametro: "Psicopedagoga"},
    {id:'2', parametro: "Lic. en psicopedagogía"},
    {id:'3', parametro: "Fonoaudióloga"},
    {id:'4', parametro: "Lic. en fonoaudiología"},
    {id:'5', parametro: "Lic. en psicología"},
    {id:'6', parametro: "Maestra especial"},
    {id:'7', parametro: "Lic. en ciencias de la educación"},
    {id:'8', parametro: "Docente"},
    {id:'99', parametro: "Otro"}
];

const ocupacionProfesionalData = [
    {id:'1', parametro: "Clínica privada"},
    {id:'2', parametro: "Clínica hospitalaria"},
    {id:'3', parametro: "EOE"},
    {id:'4', parametro: "APND"},
    {id:'5', parametro: "MAI"},
    {id:'6', parametro: "Tutor"},
    {id:'7', parametro: "Profesor particular"}
];

const terapiasActualesData = [
    {id:'1', parametro: "Psicología"},
    {id:'2', parametro: "Psicopedagogía"},
    {id:'3', parametro: "Fonoaudiología"},
    {id:'4', parametro: "Terapia ocupacional"},
    {id:'5', parametro: "Estimulación temprana"},
    {id:'6', parametro: "Musicoterapia"},
    {id:'7', parametro: "Habilidades sociales"},
    {id:'8', parametro: "Tutoría"},
    {id:'9', parametro: "Maestra particular"}
];

const nivelEstudiosData = [
    {id:'1', parametro: "Primario incompleto"},
    {id:'2', parametro: "Primario completo"},
    {id:'3', parametro: "Secundario incompleto"},
    {id:'4', parametro: "Secundario completo"},
    {id:'5', parametro: "Terciario incompleto"},
    {id:'6', parametro: "Terciario completo"},
    {id:'7', parametro: "Universitario incompleto"},
    {id:'8', parametro: "Universitario completo"},
    {id:'9', parametro: "Posgrado incompleto"},
    {id:'10', parametro: "Posgrado completo"},
    {id:'11', parametro: "Maestría incompleto"},
    {id:'12', parametro: "Maestría completo"},
    {id:'13', parametro: "Doctorado incompleto"},
    {id:'14', parametro: "Doctorado completo"}
];

const nacionalidadData = [
    {id:'1', parametro: "Argentina"},
    {id:'2', parametro: "Boliviana"},
    {id:'3', parametro: "Brasilera"},
    {id:'4', parametro: "Chilena"},
    {id:'5', parametro: "Costarricense"},
    {id:'6', parametro: "Cubana"},
    {id:'7', parametro: "Ecuatoriana"},
    {id:'8', parametro: "Española"},
    {id:'9', parametro: "Paraguaya"},
    {id:'10', parametro: "Uruguaya"}
];

const siNoData = [
    {id:'1', parametro: "Si"},
    {id:'2', parametro: "No"}
]

const generoData = [
    {id:'1', parametro: "Femenino"},
    {id:'2', parametro: "Masculino"}
]

export {
 paisData,
 provinciaData,
 departamentoData,
 localidadData,
 tipoProfesionalData,
 ocupacionProfesionalData,
 terapiasActualesData,
 nivelEstudiosData,
 nacionalidadData,
 siNoData,
 generoData
}