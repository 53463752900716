import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useConfigAuth from '../../../Hooks/useConfigAuth';
import BotoneraBottom from './BotoneraBottom';

import { finalizarEjercicioAction} from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

// css
import {Ilustracion, Btn, Flex, Letra, ContIcons, ContLetters} from '../../Css/MetodosCSS/StyledFonemica';

const AsignarEnABC = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, data2, ejercicio, ayuImagen, instrucciones, miniInstrucciones } = props;

    const [disable, setDisable] = useState(false);
    const [cuadrados, setCuadrados] = useState([]);
    const [barra, setBarra] = useState([]);
    const [index, setIndex] = useState('');
    const [objeto, setObjeto] =useState('');
    const [imagen, setImagen] =useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignar = (e, obj, img, i) => {
        e.preventDefault();
        if(!index) {
            const newList = JSON.parse(JSON.stringify(barra));
            newList[i].border = true;
            setBarra(newList);
            setIndex(i);
            setObjeto(obj);
            setImagen(img);
        } else if(index !== i) {
            const newList = JSON.parse(JSON.stringify(barra));
            newList[index].border = false;
            newList[i].border = true;
            setBarra(newList);
            setIndex(i);
            setObjeto(obj);
            setImagen(img);
        } else {
            const newList = JSON.parse(JSON.stringify(barra));
            newList[i].border = false;
            setBarra(newList);
            setIndex('');
            setObjeto('');
            setImagen('');
        }
    }

    const verificar = (e, correcto) => {
        e.preventDefault();
        if(objeto === '' || objeto === undefined) {
            return;
        }
        const queSoy = typeof cuadrados[0].correcto;
        if(queSoy === 'string' ){
            if(objeto.substring(0,1).toUpperCase() !== correcto.toUpperCase()){
                console.log('mal');
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setTimeout(() => {
                    const newList = JSON.parse(JSON.stringify(barra));
                    newList[index].border = false;
                    setBarra(newList);
                    setIndex('');
                    setObjeto('');
                    setImagen('');
                    setDisable(false);
                }, 300);
            } else {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                let grpI = cuadrados.findIndex(grp => {
                    return grp.correcto.toUpperCase() === objeto.substring(0, 1).toUpperCase()
                });
                const newList = JSON.parse(JSON.stringify(cuadrados));
                newList[grpI].seteado = true;
                newList[grpI].asignado = imagen;
                setCuadrados(newList);

                let grpI2 = barra.findIndex(grp => {
                    return grp.palabra_1 === objeto
                });
                const newList2 = JSON.parse(JSON.stringify(barra));
                newList2[grpI2].seteado = true;
                newList2[index].border = false;
                setBarra(newList2)
                setTimeout(() => {
                    setIndex('');
                    setObjeto('');
                    setImagen('');
                    setDisable(false);   
                }, 300);
            }
        } else {
            if(objeto !== correcto){
                console.log('mal');
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setTimeout(() => {
                    setObjeto('');
                    setImagen('');
                    setDisable(false);
                }, 300);
            } else {
                console.log("bien");
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                setDisable(true);
                let grpI = cuadrados.findIndex(grp => {
                    return grp.correcto === objeto
                });
                const newList = JSON.parse(JSON.stringify(cuadrados));
                newList[grpI].seteado = true
                newList[grpI].asignado = imagen
                setCuadrados(newList)
                const newList2 = JSON.parse(JSON.stringify(barra));
                newList2[index].seteado = true;
                setBarra(newList2)
                setTimeout(() => {
                    setObjeto('');
                    setImagen('');
                    setDisable(false);   
                }, 300);
            }    
        }
    }

    useEffect(() => {
        if(iniciar === false){
            return
        }
        if(!data2[0].random){
            setCuadrados(data2);
        } else {
            const newList2 = JSON.parse(JSON.stringify(data2));
            function shuffle (array) {
                let currentIndex = array.length, randomIndex;
              
                // While there remain elements to shuffle...
                while (currentIndex !== 0) {
                  // Pick a remaining element...
                  randomIndex = Math.floor(Math.random() * currentIndex);
                  currentIndex--;
                  // And swap it with the current element.
                  [array[currentIndex], array[randomIndex]] = 
                  [array[randomIndex], array[currentIndex]];
                }
                return array;
            }
            const shuffleList2 = shuffle(newList2);
            setCuadrados(shuffleList2);
        }
        if(!data[0].random){
            setBarra(data);
        } else {
            const newList = JSON.parse(JSON.stringify(data));
            function shuffle (array) {
                let currentIndex = array.length, randomIndex;
            
                // While there remain elements to shuffle...
                while (currentIndex !== 0) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = 
                [array[randomIndex], array[currentIndex]];
                }
                return array;
            }
            const shuffleList = shuffle(newList);
            setBarra(shuffleList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar])

    useEffect(() => {
        if(cuadrados.length === 0) {
            return;
        } else {
            if(cuadrados.find(item => 
                item.seteado === false
            )) {
                return
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cuadrados])

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(contarTiempo));
        newList.push(new Date());
        setContarTiempo(newList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, ultimoTiempo]);
    
    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} ayuImagen={ayuImagen} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto">
                        <Flex margin="0 0 0 0">
                            <ContLetters justify="center">
                                {cuadrados.map(item => (
                                    <Btn
                                        key={item.id}
                                        disabled={disable}
                                        onClick={(e) => verificar(e, item.correcto)}
                                    >
                                        {(() => {
                                            if (item.seteado){
                                                if(barra[0].imagen){
                                                return <Letra style={{background:`${item.bgcolor}`}}><Ilustracion src={`/img/${item.asignado}.png`} width="20px" isCorrect={isCorrect} isIncorrect={isIncorrect} /></Letra>
                                                } else {
                                                    return <Letra style={{background:`${item.bgcolor}`}}>{item.asignado}</Letra> 
                                                }
                                            } else {
                                                if(item.imagen){
                                                    return <Letra style={{background:`${item.bgcolor}`}}><Ilustracion src={`/img/${item.objeto}.png`} width="20px" isCorrect={isCorrect} isIncorrect={isIncorrect} /></Letra> 
                                                } else {
                                                    return <Letra style={{background:`${item.bgcolor}`}}>{item.objeto}</Letra> 
                                                }
                                            }
                                        })()}
                                    </Btn>
                                ))}
                            </ContLetters>
                        </Flex>
                        <Flex margin="2.5rem 0px 0px 0px">
                            <ContIcons> 
                                {barra.map((item, i) => (
                                    <Btn border={item.border} borderradius={item.border} fontsize="1.5rem" transform={ejercicio === "LN45" ? "uppercase" : false}
                                        key={item.id}
                                        disabled={disable}
                                        onClick={(e) => asignar(e, item.palabra_1 || item.objeto, item.imagen_url_1, i)}
                                    >
                                        {item.seteado ? null 
                                        : item.imagen ? 
                                        <Flex direction="column">
                                            <Ilustracion src={`/img/${item.imagen_url_1}.png`} width="3.5vw" isCorrect={isCorrect} isIncorrect={isIncorrect} />
                                            {ayuda_imagen ? 
                                                <div>{item.ayuda_imagen_1}</div>
                                            : null}
                                        </Flex> 
                                        : item.palabra_1 
                                        ? item.palabra_1 
                                        : item.objeto}
                                    </Btn>
                                ))}
                            </ContIcons>
                        </Flex>
                    </Flex>
                    <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default AsignarEnABC;