import React, {useState} from 'react';
import { useSelector } from 'react-redux';

import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import Alertas from '../Metodos/Compartidos/Alertas';

//css
import {Cuerpo, FlexColumn, FlexBox} from '../Css/StyledHeader.js'
import {BotonSeleccionado} from '../Css/StyledActivities';
import {Th, Fila, Tbody, Td}  from '../Css/StyledPatient.js';
import MiSuscripcionAux from '../AuxiliaresPaginas/MiSuscripcionAux';


const MiSuscripcion = () => {

    const infoSuscripcion = useSelector((state) => state.usuario.suscripcion);
    const {pagos, estado_suscripcion} = infoSuscripcion;
    const rol = useSelector((state) => state.usuario.rol);
    const alerta = useSelector ( (state) => state.alertas.alerta);

    const [pago, setPago] = useState('suscribir');

    return (
        <Cuerpo>
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes`} componente={estado_suscripcion[0].cantidad_suscriptos > 0 ? `Usuario Premium` : rol === '489431' ? `Versión DEMO - Tiempo restante de uso: ${estado_suscripcion[0].tiempo_restante} días` : `No tiene activado el método`} paciente={false} color="black" />
            {alerta ? <Alertas /> : null}
            <FlexColumn align="center">
                <FlexBox justify="center" margin="0 0 2rem 0">
                    <div>
                        <BotonSeleccionado name="suscribir" onClick={e => setPago(e.target.name)} backgroundcolor={pago === "suscribir" ? true : false} width="40vw" padding="15px 0px" margin="20px 0px 0px 10px">Suscripciones</BotonSeleccionado>
                    </div>
                    <div>
                        <BotonSeleccionado name="efectuados" onClick={e => setPago(e.target.name)} backgroundcolor={pago === "efectuados" ? true : false} width="40vw" padding="15px 0px" margin="20px 0px 0px 10px">Pagos Efectuados</BotonSeleccionado>
                    </div>
                </FlexBox>
                {pago === 'efectuados' 
                ? 
                <table width="50vw" height="40vh" ancho="80vw">
                    <thead>               
                        <Fila> 
                            <Th>Número de pago</Th>
                            <Th>Fecha de pago</Th>
                            <Th>Monto de pago</Th>
                            <Th>Moneda</Th>
                            <Th>Comprobante</Th>
                            <Th>Cantidad Suscriptos</Th>
                        </Fila>               
                    </thead>
                    <Tbody>
                    {pagos.map(item => (
                        <Fila key={item.numero_pago}>
                            <Td>{item.numero_pago}</Td> 
                            <Td>{item.fecha_pago}</Td>
                            <Td>{item.monto_pago}</Td>
                            <Td>{item.moneda}</Td>
                            <Td>{item.comprobante}</Td>
                            <Td>{item.unSuscripciones.cantidad_suscriptos}</Td>
                        </Fila>
                        ))}
                    </Tbody>
                </table>
                :
                <MiSuscripcionAux />
                }
            </FlexColumn>
        </Cuerpo>
    );
}
 
export default MiSuscripcion;