import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import Practica19 from '../Practica19';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgB10, NumM19, NumIndM19, NumRellenoM19 } from '../../../../Css/StyledMatematica';

const instrucciones = 'A continuación se presentan números diferenciados por su valor posicional. El niño deberá leer en voz alta dichos números. Si necesita ayuda se puede hacer click sobre el recuadro donde presenta dificultad. Asimismo se puede hacer click sobre el ícono de ayuda.';
const miniInstrucciones = '';

const data = [
    {id:'1', imagen:'unaCebra'},
    {id:'2', imagen:'unaCebra'},
    {id:'3', imagen:'unaCebra'},
    {id:'4', imagen:'unaCebra'},
    {id:'5', imagen:'unaCebra'},
    {id:'6', imagen:'unaCebra'},
    {id:'7', imagen:'unaCebra'},
    {id:'8', imagen:'unaCebra'},
    {id:'9', imagen:'unaCebra'}
]

const colors = {
    amarillo: "#FFDC73",
    celeste: "#23AAAF",
    rosa: "#D76970",
    naranja: "#FF9B2A",
    violeta: "#655EE8",
  };

const M19 = () => {

    const dispatch = useDispatch();

    const [comparar, setComparar] = useState([]);
    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);
    const contadorTerciario = useSelector(state => state.ejercicio.contadorTerciario);
    const contadorCuaternario = useSelector(state => state.ejercicio.contadorCuaternario);

    if(contador === 10){
        dispatch(setearEjercicioAction('M19Bis') );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        setComparar(data);
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M19') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar || fuerzoInicio
                ?
                <div>
                    {contador === 0 &&
                    <FlexFull direction='column' align='start' margin='0px 0vw 0px 0vw' mm='0px 0vw 0px 5vw' mt='0px 0vw 0px 5vw' md='0px 0vw 0px 5vw' mlg='0px 0vw 0px 5vw' >
                        {contadorSecundario >= 0 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>1</NumM19>
                                <ImgB10 src={`/img/guionRosa.png`} w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaRosa.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                            {contadorCuaternario < 2 ? 
                            comparar.filter(item => item.id < contadorCuaternario+1).map(item =>
                                <div key={item.id}>
                                    <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                </div>
                            ):
                                <div>
                                    <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                </div>
                            }
                            </FlexFull>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 0 && contadorSecundario === 0 && <BotoneraBottom aumentarCuaternario={true} /> }
                            {contadorCuaternario > 0 && contadorSecundario === 0 && <BotoneraBottom reducirCuaternario={true} aumentarSecundario={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario >= 1 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>2</NumM19>
                                <ImgB10 src={`/img/guionVerde.png`}  w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaVerde.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                <Flex>
                            {contadorCuaternario < 3 ? 
                                comparar.filter(item => item.id < contadorCuaternario).map(item =>
                                    <div key={item.id}>
                                        <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                    </div>
                                ):
                                comparar.filter(item => item.id < 3).map(item =>
                                    <div key={item.id}>
                                        <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                    </div>
                                )}
                            </Flex>
                            </FlexFull>

                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 1 && contadorSecundario === 1 && <BotoneraBottom reducirSecundario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario > 1 && contadorCuaternario < 3 && contadorSecundario === 1 &&  <BotoneraBottom reducirCuaternario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario === 3 && contadorSecundario === 1 &&  <BotoneraBottom reducirCuaternario={true} aumentarSecundario={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario >= 2 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>3</NumM19>
                                <ImgB10 src={`/img/guionNaranja.png`} w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaNaranja.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                <Flex>
                                    {contadorCuaternario < 7 ? 
                                    comparar.filter(item => item.id < contadorCuaternario-2).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    ):
                                    comparar.filter(item => item.id < 4).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    )}
                                </Flex>
                            </FlexFull>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 3 && contadorSecundario === 2 && <BotoneraBottom reducirSecundario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario > 3 && contadorCuaternario < 6 && contadorSecundario === 2 &&  <BotoneraBottom reducirCuaternario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario === 6 && contadorSecundario === 2 &&  <BotoneraBottom reducirCuaternario={true} aumentarSecundario={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario >= 3 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>4</NumM19>
                                <ImgB10 src={`/img/guionRosa.png`} w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaRosa.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                <Flex>
                                    {contadorCuaternario < 11 ? 
                                    comparar.filter(item => item.id < contadorCuaternario-5).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    ):
                                    comparar.filter(item => item.id < 5).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    )}
                                </Flex>
                            </FlexFull>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 6 && contadorSecundario === 3 && <BotoneraBottom reducirSecundario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario > 6 && contadorCuaternario < 10 && contadorSecundario === 3 &&   <BotoneraBottom reducirCuaternario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario === 10 && contadorSecundario === 3 &&  <BotoneraBottom reducirCuaternario={true} aumentarSecundario={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario >= 4 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>5</NumM19>
                                <ImgB10 src={`/img/guionVerde.png`} w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaVerde.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                <Flex>
                                    {contadorCuaternario < 16 ? 
                                    comparar.filter(item => item.id < contadorCuaternario-9).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    ):
                                    comparar.filter(item => item.id < 6).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    )}
                                </Flex>
                            </FlexFull>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 10 && contadorSecundario === 4 && <BotoneraBottom reducirSecundario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario > 10 && contadorCuaternario < 15 && contadorSecundario === 4 &&  <BotoneraBottom reducirCuaternario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario === 15 && contadorSecundario === 4 &&  <BotoneraBottom reducirCuaternario={true} aumentarSecundario={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario >= 5 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>6</NumM19>
                                <ImgB10 src={`/img/guionNaranja.png`} w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaNaranja.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                <Flex>
                                    {contadorCuaternario < 22 ? 
                                    comparar.filter(item => item.id < contadorCuaternario-14).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    ):
                                    comparar.filter(item => item.id < 7).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    )}
                                </Flex>
                            </FlexFull>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 15 && contadorSecundario === 5 && <BotoneraBottom reducirSecundario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario > 15 && contadorCuaternario < 21 && contadorSecundario === 5 &&  <BotoneraBottom reducirCuaternario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario === 21 && contadorSecundario === 5 &&  <BotoneraBottom reducirCuaternario={true} aumentarSecundario={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario >= 6 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>7</NumM19>
                                <ImgB10 src={`/img/guionRosa.png`} w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaRosa.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                <Flex>
                                    {contadorCuaternario < 29 ? 
                                    comparar.filter(item => item.id < contadorCuaternario-20).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    ):
                                    comparar.filter(item => item.id < 8).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    )}
                                </Flex>
                            </FlexFull>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 21 && contadorSecundario === 6 && <BotoneraBottom reducirSecundario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario > 21 && contadorCuaternario < 28 && contadorSecundario === 6 &&  <BotoneraBottom reducirCuaternario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario === 28 && contadorSecundario === 6 &&  <BotoneraBottom reducirCuaternario={true} aumentarSecundario={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario >= 7 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>8</NumM19>
                                <ImgB10 src={`/img/guionVerde.png`} w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaVerde.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                <Flex>
                                    {contadorCuaternario < 37 ? 
                                    comparar.filter(item => item.id < contadorCuaternario-27).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    ):
                                    comparar.filter(item => item.id < 9).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    )}
                                </Flex>
                            </FlexFull>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 28 && contadorSecundario === 7 && <BotoneraBottom reducirSecundario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario > 28 && contadorCuaternario < 36 && contadorSecundario === 7 &&  <BotoneraBottom reducirCuaternario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario === 36 && contadorSecundario === 7 &&  <BotoneraBottom reducirCuaternario={true} aumentarSecundario={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario >= 8 && 
                        <div>
                            <FlexFull justify='center' align='center' height='9vh'>
                                <NumM19>9</NumM19>
                                <ImgB10 src={`/img/guionNaranja.png`} w='4vw' wm='4vw' wt='3.8vw' wd='4vw' />
                                <ImgB10 src={`/img/flechaNaranja.png`} w='4vw' wm='4vw' wt='4vw' wd='4vw' m='0px 1.5vw 0px 1vw' mm='0px 3vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                <Flex>
                                    {contadorCuaternario < 46 ? 
                                    comparar.filter(item => item.id < contadorCuaternario-35).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    ):
                                    comparar.filter(item => item.id < 10).map(item =>
                                        <div key={item.id}>
                                            <ImgB10 src={`/img/unaCebra.png`} w='7vw' wm='6vw' wt='4.6vw' wd='4vw' wlg='4vw' m='0px 1.5vw 0px 0vw' />
                                        </div>
                                    )}
                                </Flex>
                            </FlexFull>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                            {contadorCuaternario === 36 && contadorSecundario === 8 && <BotoneraBottom reducirSecundario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario > 36 && contadorCuaternario < 45 && contadorSecundario === 8 &&  <BotoneraBottom reducirCuaternario={true} aumentarCuaternario={true} /> }
                            {contadorCuaternario === 45 && contadorSecundario === 8 &&  <BotoneraBottom reducirCuaternario={true} aumentar={true} /> }
                            </FlexCuz>
                        </div>
                        }
                        {contadorSecundario === 17 && 
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <BotoneraBottom reducirSecundario={true} aumentar={true} />
                        </FlexCuz> 
                        }
                    </FlexFull>
                    }
                    {contador === 1 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='40%'>
                                    <ImgB10 src={`/img/cebra_1.png`} w='55vw' wm='40vw' wt='34vw' wd='33vw' wlg='35vw' m='0px -17vw 0px -10vw' mm='0px -10vw 0px -10vw' mt='0px -10vw 0px -10vw' md='0px -10vw 0px -10vw' mlg='0px -10vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='40%'>
                                    <ImgB10 src={`/img/cebra_1.png`} w='55vw' wm='40vw' wt='34vw' wd='33vw' wlg='35vw' m='0px -17vw 0px -10vw' mm='0px -10vw 0px -10vw' mt='0px -10vw 0px -10vw' md='0px -10vw 0px -10vw' mlg='0px -10vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>1</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 2 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='40%'>
                                    <ImgB10 src={`/img/cebra_conteo_2.png`} w='42vw' wm='38vw' wt='35vw' wd='30vw' wlg='30vw' m='0px 0vw 0px -10vw' mm='0px 0vw 0px -10vw' mt='0px 0vw 0px -10vw' md='0px 0vw 0px -10vw' mlg='0px 0vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_2.png`} w='42vw' wm='38vw' wt='35vw' wd='30vw' wlg='30vw' m='0px 0vw 0px -10vw' mm='0px 0vw 0px -10vw' mt='0px 0vw 0px -10vw' md='0px -0vw 0px -10vw' mlg='0px 0vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>2</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 3 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_3.png`} w='40vw' wm='34vw' wt='30vw' wd='25vw' wlg='25vw' m='0px 0vw 0px -10vw' mm='0px 0vw 0px -10vw' mt='0px 0vw 0px -10vw' md='0px -0vw 0px -10vw' mlg='0px 0vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_3.png`} w='40vw' wm='34vw' wt='30vw' wd='25vw' wlg='25vw' m='0px 0vw 0px -10vw' mm='0px 0vw 0px -10vw' mt='0px 0vw 0px -10vw' md='0px -0vw 0px -10vw' mlg='0px 0vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>3</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 4 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_4.png`} w='40vw' wm='34vw' wt='30vw' wd='25vw' wlg='25vw' m='0px 0vw 0px -10vw' mm='0px 0vw 0px -10vw' mt='0px 0vw 0px -10vw' md='0px -0vw 0px -10vw' mlg='0px 0vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_4.png`} w='40vw' wm='34vw' wt='30vw' wd='25vw' wlg='25vw' m='0px 0vw 0px -10vw' mm='0px 0vw 0px -10vw' mt='0px 0vw 0px -10vw' md='0px -0vw 0px -10vw' mlg='0px 0vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>4</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 5 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_5.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_5.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>5</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 6 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_6.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_6.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>6</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 7 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_7.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_7.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>7</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 8 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_8.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_8.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>8</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 9 &&
                    <div>
                        {contadorTerciario === 0 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_9.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' /> 
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumRellenoM19></NumRellenoM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducir={true} aumentarTerciario={true} />
                            </FlexCuz>
                        </div>
                        }

                        {contadorTerciario === 1 &&
                        <div>
                            <Flex height='78vh'>
                                <Flex height='30vh' width='70%' wm='60%'wt='50%' wd='45%' wlg='50%'>
                                    <ImgB10 src={`/img/cebra_conteo_9.png`} w='35vw' wm='29vw' wt='25vw' wd='21vw' wlg='20vw' m='0px 2vw 0px -10vw' mm='0px 2vw 0px -10vw' mt='0px 2vw 0px -10vw' md='0px 2vw 0px -10vw' mlg='0px 2vw 0px -10vw' />
                                    <ImgB10 src={`/img/guionGris.png`} w='5vw' wm='5vw' wt='3.8vw' wd='4vw' wlg='4vw' />
                                    <ImgB10 src={`/img/flechaGris.png`} w='5vw' wm='5vw' wt='4vw' wd='4vw' wlg='4vw' m='0px 4vw 0px 1vw' mm='0px 4vw 0px 1vw' mt='0px 3vw 0px 1vw' md='0px 3vw 0px 1vw' mlg='0px 3vw 0px 1vw' />
                                    <NumIndM19>9</NumIndM19>
                                </Flex>
                            </Flex>
                            <FlexCuz position='fixed' right='0' bottom='0'>
                                <BotoneraBottom reducirTerciario={true} aumentar={true} />
                            </FlexCuz>
                        </div>
                        }
                    </div>
                    }
                    {contador === 10 &&
                    <div>
                        <Practica19 ejercicio="M19Bis" />
                    </div>
                    }
                </div>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M19;