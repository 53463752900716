import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {Ilustracion, Flex, Btn, BotonMas, Circulo} from '../../../../Css/MetodosCSS/StyledFonemica';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Indicale al robot la cantidad de sonidos que tiene el dibujo.';
const miniInstrucciones = 'Indicale al robot la cantidad de sonidos que tiene el dibujo.';

const asignar = [
    {id: 1, sonido:''},
    {id: 2, sonido:''},
    {id: 3, sonido:''},
    {id: 4, sonido:''},
    {id: 5, sonido:''},
    {id: 6, sonido:''},
    {id: 7, sonido:''},
    {id: 8, sonido:''}
]

const LEN8 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [agregarSonido, setAgregarSonido] = useState(0)
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [sonidos, setSonidos] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);
    
    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const agregar = (e) => {
        e.preventDefault();
        if(agregarSonido < 8) {
            setAgregarSonido(agregarSonido + 1);
        } else {
            return
        }
    }

    const borrar = (e) => {
        e.preventDefault();
        if(agregarSonido === 0) {
            return
        } else {
            const newList = JSON.parse(JSON.stringify(sonidos));
            newList[agregarSonido-1].sonido = '';
            setSonidos(newList);
            setAgregarSonido(agregarSonido-1);
        }
    }

    const verificar = (e, cantSonidos) => {
        e.preventDefault();
        if(agregarSonido === 0){
            return
        }
        if(sonidos[agregarSonido-1].sonido !== cantSonidos) {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
                setSonidos(asignar)
                setAgregarSonido(0);
            }, 1000);
        } else {
            console.log('bien');
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            setDisable(true);
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setSonidos(asignar)
                    setAgregarSonido(0);
                }, 1000);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    setDisable(false);
                    setSonidos(asignar)
                    setAgregarSonido(0);
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }

    useEffect(() => {
        if(agregarSonido === 0) {
            return
        } else {
        const newList = JSON.parse(JSON.stringify(sonidos));
        newList[agregarSonido-1].sonido = agregarSonido;
        setSonidos(newList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agregarSonido])

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN8') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-fonemica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setSonidos(asignar)
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN8",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
        <HeaderEjercicios estrellas={true} carrito={true} ayuImagen={true} />
        {iniciar
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex direction="column" margin="auto" width="100%">
                    <Flex>
                        <Ilustracion src={`/img/${comparar.imagen_url_1}.png`} width="10vw"
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}/>
                        {ayuda_imagen ? 
                            <div>{comparar.ayuda_imagen_1}</div>
                        : null}
                        
                    </Flex>
                    <Flex direction="column" margin="2rem">
                        <Flex align="center" margin="0 0 1rem 0">
                            <Ilustracion src={`/img/RobotAmarillo.png`} width="5vw" widthTablet="15vw"/>
                            <Flex margin="0 0 0 1rem">
                                <BotonMas
                                    disabled={disable}
                                    onClick={(e) => agregar(e)}
                                >
                                    +
                                </BotonMas>
                                <Btn margin="0 0 0 1rem"
                                    disabled={disable}
                                    onClick={(e) => borrar(e)}
                                >
                                    <Ilustracion src={`/img/icono_basurero.png`} alt=""/>
                                </Btn>
                            </Flex>
                        </Flex>
                        <Flex >
                            {sonidos.filter(obj => obj.sonido !== '').map(obj => (
                                <div
                                    key={obj.id}
                                >
                                    <Circulo></Circulo> 
                                </div>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
                <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                    <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    <AvanzarBtn
                        disabled={disable}
                        onClick={(e) => verificar(e, comparar.cantidad_sonido)}
                    >
                        <AvanzarImg src={'/img/play.png'} /> 
                    </AvanzarBtn>
                </Flex>
            </Flex>
            :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default LEN8;