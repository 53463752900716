import React, {Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import PracticaCentena from '../PracticaCentena';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const data = [
    {id:'1', numero:100},
    {id:'2', numero:200},
    {id:'3', numero:300},
    {id:'4', numero:400},
    {id:'5', numero:500},
    {id:'6', numero:600},
    {id:'7', numero:700},
    {id:'8', numero:800},
    {id:'9', numero:900}
]

const M30Bis = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setearEjercicioAction('M30Bis') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            <PracticaCentena data={data} ejercicio="M30Bis" miniInstrucciones={"EXPRESEAR EL NÚMERO DE MANERA ORAL"} />
        </Fragment>
    );
}
 
export default M30Bis;