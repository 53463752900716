import React, { Fragment } from 'react';
import Sidebar from '../AuxiliaresPaginas/Sidebar';

const Contacto = () => {
    return ( 
        <Fragment>
            <Sidebar />
            <h1>Desde Contacto</h1>
        </Fragment>
    );
}
 
export default Contacto;