import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, ContainerImg, FlexLink, Descripcion } from '../../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../../Css/StyledHeader';

const CCSilabica = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lenguaje/"} l3={"conciencia-fonologica/"} n1={"Métodos"} n2={"LENGUAJE"} n3={"CONCIENCIA FONOLÓGICA"} componente={"CCSILABICA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Container justify="center" direction="column" width="100%" height="100%">
                <Container justify="space-around">
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN20'}><ContainerImg src={`/img/portadas/LEN20.jpg`} alt=""/> <Descripcion>SILABA INICIAL</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN21'}><ContainerImg src={`/img/portadas/LEN21.jpg`} alt=""/> <Descripcion>CANTIDAD DE SÍLABAS</Descripcion></FlexLink>
                </Container>
                <Container justify="space-around" margin="50px 0px 0px 0px">
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN22'}><ContainerImg src={`/img/portadas/LEN22.jpg`} alt=""/> <Descripcion>CANTIDAD DE SÍLABAS</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN23'}><ContainerImg src={`/img/portadas/LEN23.jpg`} alt=""/> <Descripcion>SÍLABA FINAL</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN24'}><ContainerImg src={`/img/portadas/LEN24.jpg`} alt=""/> <Descripcion>SÍLABA FINAL</Descripcion></FlexLink>
                </Container>
            </Container>
        </Fragment>     
    );
}
 
export default CCSilabica;