import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// css
import {LogIn, Ilustracion, Formulario, Cuerpo, Logo, InputLarge, Label,  Form, Boton, Titulo, Button, ContenedorLogo,ContenedorForm,FlexColumn, Error, ContenedorLogin, ContenedorHeader, Flex, ButtonTipo} from '../Css/StyledForm.js';

// Actions de Redux
import { restablecerPasswordAction } from '../../actions/restablecerPasswordActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';


const RestablecerPassword = () => {

    const dispatch = useDispatch();

    // state del componente
    const [email, guardarEmail] = useState('');
    const [tipoUsuario, setTipoUsuario] = useState('________');

    // Acceder el state del store
    const cargando = useSelector( (state) => state.estado.loading);
    const error = useSelector ( (state) => state.estado.error);
    const alerta = useSelector ( (state) => state.alertas.alerta);

    const definirProfesional = () => {
        setTipoUsuario("Profesional");
    }
    const definirPaciente = () => {
        setTipoUsuario("Paciente");
    }
    const definirTutor = () => {
        setTipoUsuario("Tutor");
    }

    // cuando el usuario haga submit
    const submitRestablecerPassword = (e) => {
        e.preventDefault();

        // validar formulario
        if(email.trim() === '') {
            const alerta = {
                msg: 'Falta completar el email'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        if(tipoUsuario === "________") {
            const alerta = {
                msg: 'Elegir tipo de usuario'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }

        // si no hay errores
        dispatch( ocultarAlertaAction());
        const informacion = {
            payload: {
                email,
                tipoUsuario
            }
        }
        // restablece la password
        dispatch( restablecerPasswordAction(informacion) );
    }

    return (
        <ContenedorLogin>
            <ContenedorHeader>
                <ContenedorLogo>
                    <Link to={"/"}>
                        <Logo src={`/img/metodoYogenLogo.png`} alt="" width="20vh"/>
                    </Link>
                </ContenedorLogo>
                <Flex>
                    <LogIn to={"/nueva-cuenta"} margin="0 5rem 0 0">Nueva Cuenta</LogIn>
                    <LogIn to={"/login"} margin="0 5rem 0 0" >Login</LogIn>
                </Flex>
            </ContenedorHeader>
            <ContenedorForm>
                
                <Ilustracion src={`/img/ilustracionRestablecerPassword.png`} alt="" width="30vw"/>
                <Formulario padding="4rem" margin="0 7rem 0 0">
                    <Titulo>¿Has olvidado tu contraseña?</Titulo>
                    <ButtonTipo onClick={definirProfesional}>Profesional</ButtonTipo>
                    <ButtonTipo onClick={definirPaciente}>Entrenado</ButtonTipo>
                    <ButtonTipo onClick={definirTutor}>Tutor</ButtonTipo>

                    <Form
                        onSubmit={submitRestablecerPassword}
                    >
                        <FlexColumn>
                            <label htmlFor="email">Introduce tu correo electrónico del <b>{tipoUsuario === "Paciente" ? "Entrenado" : tipoUsuario}</b> y te enviaremos un enlace.</label>
                            <br/>
                            <Label margin="10px 0px 7px 0px" >
                            <InputLarge padding="9px 0px 9px 10px" margin="5px 0px 0px 0px" width="30vw"
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Correo electrónico"
                                value={email}
                                onChange={e => guardarEmail(e.target.value)}
                            />
                            </Label>
                        </FlexColumn>
                        <Boton>
                            <Button
                                    type="submit"
                            >Restablecer contraseña
                            </Button>
                        </Boton> 
                    </Form>
                    
                    {cargando ? <p>Cargando...</p> : null }
                    {error ? <Error> {error.msg} </Error>: null }
                    {alerta ? <p>{alerta.msg}</p> : null }  
                </Formulario>
            </ContenedorForm>
        </ContenedorLogin>
    );
}
 
export default RestablecerPassword;