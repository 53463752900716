import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {Btn, Ilustracion, Flex, BtnCeleste, ContInst} from '../../../../Css/MetodosCSS/StyledFonemica';

const instrucciones = 'Seleccional el robot que dice adecuadamente los sonidos de la palabra.';
const miniInstrucciones = 'Seleccional el robot que dice adecuadamente los sonidos de la palabra.';

const LEN1 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [indexes, setIndexes] = useState();
    const [randomSonido, setRandomSonido] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const play1 = new Audio(`/sounds/${randomSonido[0]?.sonido}.mp3`);
    const play2 = new Audio(`/sounds/${randomSonido[1]?.sonido}.mp3`);

    const play = (valor) => {
        play1.pause();
        play2.pause();
        play1.currentTime = 0;
        play2.currentTime = 0;
        switch(valor){
            case 1:
                play1.play();
                break;
            case 2:
                play2.play();
                break;
            default:
        }
    }

    const verificar = (e, sonido) => {
        e.preventDefault();
        if(sonido !== comparar.sonido_correcto) {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
              setIsIncorrect(false);  
            }, 300);
            TryAgain();
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
            }, 1500);
        } else {
            console.log('bien');
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            setDisable(true);
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 1500);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    setDisable(false);
                    dispatch( finalizarEjercicioAction() )
                }, 1500);
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN1') );
        dispatch(setearUrlAnteriorAction ('lenguaje/conciencia-fonologica/cc-fonemica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN1",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        const newComparar = [
            {id:'1', sonido_url:newList.sonido_url_1, sonido:newList.sonido_correcto},
            {id:'2', sonido_url:newList.sonido_url_2, sonido:newList.sonido_incorrecto}
        ]

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(newComparar);
        
        setRandomSonido(
            newShuffleList
        )
    }, [comparar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto" width="65%">
                        <Flex margin="0rem 0 0 0">
                            <BtnCeleste width="200px">{comparar.palabra_1.toUpperCase()}</BtnCeleste> 
                        </Flex>
                        <Flex width="100%" justify="space-around">
                            <Flex direction="column" margin="2rem 0 0 0">
                                <Btn margin="0 0 1rem 0"
                                    disabled={disable}
                                    onClick={(e) => verificar(e, randomSonido[0].sonido)}
                                >
                                    <Ilustracion src={`/img/RobotCeleste.png`} width="15vw"
                                    isCorrect={isCorrect} 
                                    isIncorrect={isIncorrect}/>
                                </Btn>
                                <Btn
                                    disabled={disable}
                                    onClick={() => play(1)}
                                >
                                    <Ilustracion src={`/img/altavoz.png`} alt="" width="50px" />
                                </Btn>
                            </Flex>
                            <Flex direction="column">
                                <Btn margin="0 0 1rem 0"
                                    disabled={disable}
                                    onClick={(e) => verificar(e, randomSonido[1].sonido)}
                                >
                                    <Ilustracion src={`/img/robot1.png`} width="15vw" 
                                    isCorrect={isCorrect} 
                                    isIncorrect={isIncorrect}/>
                                </Btn>
                                <Btn
                                    disabled={disable}
                                    onClick={() => play(2)}
                                >
                                    <Ilustracion src={`/img/altavoz.png`} alt="" width="50px"/>
                                </Btn>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default LEN1;