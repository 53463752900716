import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, AvanzarImg, BtnImg, Operador, Lateral, AvanzarBtn, ImgB10 } from '../../../../Css/StyledMatematica';

const instrucciones = 'Indica con el cocodrilo que dominó es mayor.';
const miniInstrucciones = 'Indica con el cocodrilo que dominó es mayor.';

const M36 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [operadores, setOperadores] = useState([]);
    const [elegido, setElegido] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const seleccionarOperador = (e, item) => {
        e.preventDefault();
        setElegido(item);
    }
    
    const verificar = (e) => {
        e.preventDefault();
        if(elegido === 'operador_mayor'){
            if(comparar.cantidad_1 > comparar.cantidad_2) {
                console.log("bien");
                if(count === 1){
                    setErroresOrden([...erroresOrden, contarErrores]);
                } else {
                    const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                    setErroresOrden([...erroresOrden, err]);
                }
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setElegido('')
                        setCount(count + 1);
                    }, 300);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch( finalizarEjercicioAction() )                        
                    }, 1500);
                }
            } else {
                console.log("mal");
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setElegido('');
                }, 300);
            }
        } else {
            if(comparar.cantidad_1 < comparar.cantidad_2) {
                console.log("bien");
                if(count === 1){
                    setErroresOrden([...erroresOrden, contarErrores]);
                } else {
                    const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                    setErroresOrden([...erroresOrden, err]);
                }
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setElegido('')
                        setCount(count + 1);
                    }, 1500);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch( finalizarEjercicioAction() )                        
                    }, 1500);
                }
            } else {
                console.log("mal");
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setElegido('');
                }, 300);
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('M36') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/ejercitacion-1-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIndexes(Object.keys(informacion).length);
    }, [informacion])

    useEffect(() => {
        if(informacion[count-1] === undefined){
            return;
        }
        let randomNumero = Math.floor(Math.random() * (1 - 0 +1)) + 0;
        if(randomNumero === 1){
            setComparar(informacion[count-1]);
        } else {
            let revertir = {id:informacion[count-1].id, imagen_url_1:informacion[count-1].imagen_url_2, imagen_url_2:informacion[count-1].imagen_url_1, cantidad_1:informacion[count-1].cantidad_2, cantidad_2:informacion[count-1].cantidad_1}
            setComparar(revertir);
        }
        const array = ['operador_mayor', 'operador_menor'];
        setOperadores(array);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M36",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'erroresOrden': erroresOrden,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <FlexFull direction="column" height="100%" width="100%">
                    <FlexFull direction='column' margin="auto" width="100%" justify='space-evenly' align='center'
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}
                    >
                        <FlexFull width='50vw' height='50vh' justify='space-between' align='center'>
                            <ImgB10 src={`/img/${comparar.imagen_url_1}.png`} style={{zIndex: "0"}} w='16vw' wm='15.5vw' wt='14.5vw' wd='13.8vw' wlg='13vw' />
                            <Operador>
                                {elegido === '' ? null : <ImgB10 src={`/img/${elegido}.png`} style={{zIndex: "0"}} w='11vw' wm='10vw' wt='9vw' wd='8vw' wlg='8vw' m='1vw' />}
                            </Operador>
                                <ImgB10 src={`/img/${comparar.imagen_url_2}.png`} w='16vw' wm='15.5vw' wt='14.5vw' wd='13.8vw' wlg='13vw' />
                        </FlexFull>

                        <Lateral>
                            <Flex>
                                {operadores.map((item, i) => 
                                <BtnImg
                                    key={i}
                                    disabled={disable}                                
                                    onClick={(e) => seleccionarOperador(e, item)}
                                >
                                    <ImgB10 src={`/img/${item}.png`} style={{zIndex: "0"}} w='8.5vw' wm='7vw' wt='6.5vw' wd='5.5vw' wlg='5vw' />
                                </BtnImg>
                                
                                )}
                            </Flex>
                        </Lateral>
                    </FlexFull>

                    {/* INSTRUCCIONES */}
                    <FlexCuz margin="0 0rem 0 1rem" width="95%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    {/* FIN INSTRUCCIONES */}
                </FlexFull>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M36;