import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

import { Flex, LinksEst, ImgEst, DesEst } from '../../../Css/StyledMatematica';
import { HrMetodos } from '../../../Css/StyledHeader';

const NocionDeCantidad = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"estimacion-y-nocion-de-cantidad/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"ESTIMACION Y NOCION DE CANTIDAD"} componente={"NOCION DE CANTIDAD"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex wrap='wrap' width='70vw' margin='22vh 15vw 2vh 15vw' mt='24vh 15vw 2vh 15vw' md='24vh 15vw 2vh 15vw' mlg='24vh 15vw 2vh 15vw'>
                <LinksEst to={`/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/nocion-de-cantidad/M17`}><ImgEst src={'/img/portadas/matematica17.jpg'} /><DesEst></DesEst></LinksEst>
                <LinksEst to={`/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/nocion-de-cantidad/M18`}><ImgEst src={'/img/portadas/matematica18.jpg'} /><DesEst></DesEst></LinksEst>
            </Flex>
        </Fragment>
    );
}
 
export default NocionDeCantidad;