import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import './memoTest.css'

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useColorHelp from '../../../Hooks/useColorHelp';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction } from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, BtnMemo, BtnMemoBack, MemoPalabra, ImgLN8 } from '../../Css/MetodosCSS/StyledLectoEscritura';

const LN8Todos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, ejercicio, instrucciones, miniInstrucciones } = props;

    //No se porque si borro setDisable no funciona
    const [disable, setDisable] = useState(false);
    const [palabraSeleccionada, setPalabraSeleccionada] = useState('');
    const [indexSeleccionada, setIndexSeleccionada] = useState('');
    const [indexSeleccionadaDos, setIndexSeleccionadaDos] = useState('');
    const [error, setError] = useState(false);
    const [randomPalabra, setRandomPalabra] = useState([]);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [arrayColorUno, transformarColorUno] = useColorHelp('');
    const [arrayColorDos, transformarColorDos] = useColorHelp('');
    const [arrayColorTres, transformarColorTres] = useColorHelp('');
    const [arrayColorCuatro, transformarColorCuatro] = useColorHelp('');
    const [arrayColorCinco, transformarColorCinco] = useColorHelp('');
    const [arrayColorSeis, transformarColorSeis] = useColorHelp('');
    const [arrayColorSiete, transformarColorSiete] = useColorHelp('');
    const [arrayColorOcho, transformarColorOcho] = useColorHelp('');
    const [arrayColorNueve, transformarColorNueve] = useColorHelp('');
    const [arrayColorDiez, transformarColorDiez] = useColorHelp('');
    const [arrayColorOnce, transformarColorOnce] = useColorHelp('');
    const [arrayColorDoce, transformarColorDoce] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const letra = useSelector(state => state.ejercicio.letra);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const setearPalabra = (e, plb, index, desaparecer) => {
        e.preventDefault();
        if (palabraSeleccionada !== '' || desaparecer === true) {
            return;
        } else {
            randomPalabra[index].oculto = true;
            setPalabraSeleccionada(plb);
            setIndexSeleccionada(index);
        }
    }

    const verificar = (e, plb, index, desaparecer) => {
        e.preventDefault();
        if (index === indexSeleccionada || desaparecer === true) {
            return;
        }
        if (palabraSeleccionada === '') {
            return;
        } else {
            randomPalabra[index].oculto = true;
            setIndexSeleccionadaDos(index);
        }
        if (palabraSeleccionada !== plb) {
            console.log('mal');
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setError(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            // setTimeout(() => {
            //     randomPalabra[index].oculto = false;
            //     randomPalabra[indexSeleccionada].oculto = false;
            //     setIndexSeleccionada('');
            //     setPalabraSeleccionada('');
            //     setDisable(false);
            // }, 2250);
            TryAgain();
        } else {
            console.log('bien')
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            setTimeout(() => {
                setDisable(false);
                randomPalabra[index].desaparecer = true;
                randomPalabra[indexSeleccionada].desaparecer = true;
                setIndexSeleccionada('');
                setIndexSeleccionadaDos('');
                setPalabraSeleccionada('');
            }, 300);
            GoodWork();
            if (randomPalabra.find(item => item.oculto === false) === undefined) {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1000);
                GoodWork();
            }
        }
    }

    /*Agregado para voltear las cartas solo si hacen click afuera*/
    const voltearCartas = (e) => {
        e.preventDefault();
        if(disable && error) {
            randomPalabra[indexSeleccionadaDos].oculto = false;
            randomPalabra[indexSeleccionada].oculto = false;
            setIndexSeleccionada('');
            setIndexSeleccionadaDos('');
            setPalabraSeleccionada('');
            setError(false);
            setDisable(false);
        }
    }

    useEffect(() => {
        if(randomPalabra[0] === undefined ){
            return;
        }
        transformarColorUno(randomPalabra[0].palabra);
        if(randomPalabra[1] === undefined ) return;
        transformarColorDos(randomPalabra[1].palabra);
        if(randomPalabra[2] === undefined ) return;
        transformarColorTres(randomPalabra[2].palabra);
        if(randomPalabra[3] === undefined ) return;
        transformarColorCuatro(randomPalabra[3].palabra);
        if(randomPalabra[4] === undefined ) return;
        transformarColorCinco(randomPalabra[4].palabra);
        if(randomPalabra[5] === undefined ) return;
        transformarColorSeis(randomPalabra[5].palabra);
        if(randomPalabra[6] === undefined ) return;
        transformarColorSiete(randomPalabra[6].palabra);
        if(randomPalabra[7] === undefined ) return;
        transformarColorOcho(randomPalabra[7].palabra);
        if(randomPalabra[8] === undefined ) return;
        transformarColorNueve(randomPalabra[8].palabra);
        if(randomPalabra[9] === undefined ) return;
        transformarColorDiez(randomPalabra[9].palabra);
        if(randomPalabra[10] === undefined ) return;
        transformarColorOnce(randomPalabra[10].palabra);
        if(randomPalabra[11] === undefined ) return;
        transformarColorDoce(randomPalabra[11].palabra);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [randomPalabra, ayuda_lectura])

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(data));

        // Unifico las silabas y las pongo en 1 array
        function obtenerPalabra(info, newArray) {
            for (let i = 0; i < info.length; i++) {
                const palabra = info[i].palabra_1;
                newArray.push(palabra);
            }
            return newArray
        }
        const newArray = []
        const listaPalabras = obtenerPalabra(newList, newArray);
        //Duplico el array y le hago shuffle
        const shuffleList = [...listaPalabras, ...listaPalabras];
        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const newShuffleList = shuffle(shuffleList);
        setRandomPalabra(newShuffleList.map((palabra, i) => ({ index: i, palabra, oculto: false, desaparecer: false })))
    }, [data])

    useEffect(() => {
        if(iniciar === false){
            return;
        }else if(iniciar === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': ejercicio,
                'letra': letra,
                'errores': contarErrores,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <ContainerFlexx height="100%" width="100%"
                    onClick={(e) => voltearCartas(e)}
                >
                        <ContainerFlexx wrap='wrap' width='auto'
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}
                        >
                            <BtnMemo 
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[0].palabra, randomPalabra[0].index, randomPalabra[0].desaparecer); setearPalabra(e, randomPalabra[0].palabra, randomPalabra[0].index, randomPalabra[0].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[0]?.oculto && 'memo-block-flipped'}`}> 
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[0]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorUno.length === 0 ? null :
                                            arrayColorUno.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[1].palabra, randomPalabra[1].index, randomPalabra[1].desaparecer); setearPalabra(e, randomPalabra[1].palabra, randomPalabra[1].index, randomPalabra[1].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[1]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[1]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorDos.length === 0 ? null :
                                            arrayColorDos.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[2].palabra, randomPalabra[2].index, randomPalabra[2].desaparecer); setearPalabra(e, randomPalabra[2].palabra, randomPalabra[2].index, randomPalabra[2].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[2]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[2]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorTres.length === 0 ? null :
                                            arrayColorTres.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[3].palabra, randomPalabra[3].index, randomPalabra[3].desaparecer); setearPalabra(e, randomPalabra[3].palabra, randomPalabra[3].index, randomPalabra[3].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[3]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />     
                                        <BtnMemoBack className={`${randomPalabra[3]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorCuatro.length === 0 ? null :
                                            arrayColorCuatro.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            {randomPalabra[4] === undefined ? null :
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[4].palabra, randomPalabra[4].index, randomPalabra[4].desaparecer); setearPalabra(e, randomPalabra[4].palabra, randomPalabra[4].index, randomPalabra[4].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[4]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[4]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorCinco.length === 0 ? null :
                                            arrayColorCinco.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            }
                            {randomPalabra[5] === undefined ? null :
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[5].palabra, randomPalabra[5].index, randomPalabra[5].desaparecer); setearPalabra(e, randomPalabra[5].palabra, randomPalabra[5].index, randomPalabra[5].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[5]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[5]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorSeis.length === 0 ? null :
                                            arrayColorSeis.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            }
                            {randomPalabra[6] === undefined ? null :
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[6].palabra, randomPalabra[6].index, randomPalabra[6].desaparecer); setearPalabra(e, randomPalabra[6].palabra, randomPalabra[6].index, randomPalabra[6].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[6]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[6]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorSiete.length === 0 ? null :
                                            arrayColorSiete.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            }
                            {randomPalabra[7] === undefined ? null :
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[7].palabra, randomPalabra[7].index, randomPalabra[7].desaparecer); setearPalabra(e, randomPalabra[7].palabra, randomPalabra[7].index, randomPalabra[7].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[7]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[7]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorOcho.length === 0 ? null :
                                            arrayColorOcho.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            }
                            {randomPalabra[8] === undefined ? null :
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[8].palabra, randomPalabra[8].index, randomPalabra[8].desaparecer); setearPalabra(e, randomPalabra[8].palabra, randomPalabra[8].index, randomPalabra[8].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[8]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[8]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorNueve.length === 0 ? null :
                                            arrayColorNueve.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            }
                            {randomPalabra[9] === undefined ? null :
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[9].palabra, randomPalabra[9].index, randomPalabra[9].desaparecer); setearPalabra(e, randomPalabra[9].palabra, randomPalabra[9].index, randomPalabra[9].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[9]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[9]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorDiez.length === 0 ? null :
                                            arrayColorDiez.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            }
                            {randomPalabra[10] === undefined ? null :
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[10].palabra, randomPalabra[10].index, randomPalabra[10].desaparecer); setearPalabra(e, randomPalabra[10].palabra, randomPalabra[10].index, randomPalabra[10].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[10]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[10]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorOnce.length === 0 ? null :
                                            arrayColorOnce.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            }
                            {randomPalabra[11] === undefined ? null :
                            <BtnMemo
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomPalabra[11].palabra, randomPalabra[11].index, randomPalabra[11].desaparecer); setearPalabra(e, randomPalabra[11].palabra, randomPalabra[11].index, randomPalabra[11].desaparecer) }}
                                className="memo-block"
                            >
                                <ContainerFlexx className={`memo-block-inner ${randomPalabra[11]?.oculto && 'memo-block-flipped'}`}>
                                        <ImgLN8 src={`/img/reves_verde.png`} w='200px' h='120px' boxs='0px' className={'memo-block-front'} />
                                        <BtnMemoBack className={`${randomPalabra[11]?.oculto && 'memo-block-back'}`}>
                                            <MemoPalabra $minMay={minuscula}>
                                            {arrayColorDoce.length === 0 ? null :
                                            arrayColorDoce.map((item, i) =>
                                                <span
                                                    key={i}
                                                >
                                                    {item.map((itemInterior, i) =>
                                                        <span style={{color: itemInterior.color}}
                                                        key={i}
                                                        >
                                                            {itemInterior.letra}
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            </MemoPalabra>
                                        </BtnMemoBack >
                                </ContainerFlexx>
                            </BtnMemo>
                            }
                        </ContainerFlexx>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN8Todos;