import { useState } from "react";
import { useSelector } from 'react-redux';

const useColorHelp = () => {

    const [arrayColor, setArrayColor] = useState('');

    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);

    const transformarColor = (algo) => {
        if(!ayuda_lectura) {
            const array = [[{letra: algo}]]
            setArrayColor(array);
            return;
        }
        const arrayPalabras = algo.split(" ");
        const nuevoArray = arrayPalabras.map((item, i) => {
            const divido = item.split("");
            const array = divido.map((letra, i) => {
                if(i === 0){
                    if(letra.toUpperCase() === 'A' || letra.toUpperCase() === 'Á'){
                        return ({letra, color: '#23AAAF'})
                    }
                    if(letra.toUpperCase() === 'E' || letra.toUpperCase() === 'É'){
                        return ({letra, color: '#FF9B2A'})
                    }
                    if(letra.toUpperCase() === 'I' || letra.toUpperCase() === 'Í'){
                        return ({letra, color: '#FFDC73'})
                    }
                    if(letra.toUpperCase() === 'O' || letra.toUpperCase() === 'Ó'){
                        return ({letra, color: '#655EE8'})
                    }
                    if(letra.toUpperCase() === 'U' || letra.toUpperCase() === 'Ú'){
                        return ({letra, color: '#D05F67'})
                    }
                    if(letra.toUpperCase() !== 'A' || letra.toUpperCase() !== 'Á' || letra.toUpperCase() !== 'E' || letra.toUpperCase() !== 'É' || letra.toUpperCase() !== 'I' || letra.toUpperCase() !== 'Í' || letra.toUpperCase() !== 'O' || letra.toUpperCase() !== 'Ó' || letra.toUpperCase() !== 'U' || letra.toUpperCase() !== 'Ú') {
                        return ({letra, color: '#646464'})
                    }
                } else {
                    if(divido[i-1].toUpperCase() !== 'Q' && divido[i-1].toUpperCase() !== 'G'){
                        if(letra.toUpperCase() === 'A' || letra.toUpperCase() === 'Á'){
                            return ({letra, color: '#23AAAF'})
                        }
                        if(letra.toUpperCase() === 'E' || letra.toUpperCase() === 'É'){
                            return ({letra, color: '#FF9B2A'})
                        }
                        if(letra.toUpperCase() === 'I' || letra.toUpperCase() === 'Í'){
                            return ({letra, color: '#FFDC73'})
                        }
                        if(letra.toUpperCase() === 'O' || letra.toUpperCase() === 'Ó'){
                            return ({letra, color: '#655EE8'})
                        }
                        if(letra.toUpperCase() === 'U' || letra.toUpperCase() === 'Ú'){
                            return ({letra, color: '#D05F67'})
                        }
                        if(letra.toUpperCase() !== 'A' || letra.toUpperCase() !== 'Á' || letra.toUpperCase() !== 'E' || letra.toUpperCase() !== 'É' || letra.toUpperCase() !== 'I' || letra.toUpperCase() !== 'Í' || letra.toUpperCase() !== 'O' || letra.toUpperCase() !== 'Ó' || letra.toUpperCase() !== 'U' || letra.toUpperCase() !== 'Ú') {
                            return ({letra, color: '#646464'})
                        }
                    } else {
                        if(divido[i+1] === undefined) {
                            if(letra.toUpperCase() === 'A' || letra.toUpperCase() === 'Á'){
                                return ({letra, color: '#23AAAF'})
                            }
                            if(letra.toUpperCase() === 'E' || letra.toUpperCase() === 'É'){
                                return ({letra, color: '#FF9B2A'})
                            }
                            if(letra.toUpperCase() === 'I' || letra.toUpperCase() === 'Í'){
                                return ({letra, color: '#FFDC73'})
                            }
                            if(letra.toUpperCase() === 'O' || letra.toUpperCase() === 'Ó'){
                                return ({letra, color: '#655EE8'})
                            }
                            if(letra.toUpperCase() === 'U' || letra.toUpperCase() === 'Ú'){
                                return ({letra, color: '#D05F67'})
                            }
                            if(letra.toUpperCase() !== 'A' || letra.toUpperCase() !== 'Á' || letra.toUpperCase() !== 'E' || letra.toUpperCase() !== 'É' || letra.toUpperCase() !== 'I' || letra.toUpperCase() !== 'Í' || letra.toUpperCase() !== 'O' || letra.toUpperCase() !== 'Ó' || letra.toUpperCase() !== 'U' || letra.toUpperCase() !== 'Ú') {
                                return ({letra, color: '#646464'})
                            }
                        } else {
                            if(divido[i+1].toUpperCase() === 'E' || divido[i+1].toUpperCase() === 'I') {
                                return ({letra, color: '#646464'})
                            } else {
                                if(letra.toUpperCase() === 'A' || letra.toUpperCase() === 'Á'){
                                    return ({letra, color: '#23AAAF'})
                                }
                                if(letra.toUpperCase() === 'E' || letra.toUpperCase() === 'É'){
                                    return ({letra, color: '#FF9B2A'})
                                }
                                if(letra.toUpperCase() === 'I' || letra.toUpperCase() === 'Í'){
                                    return ({letra, color: '#FFDC73'})
                                }
                                if(letra.toUpperCase() === 'O' || letra.toUpperCase() === 'Ó'){
                                    return ({letra, color: '#655EE8'})
                                }
                                if(letra.toUpperCase() === 'U' || letra.toUpperCase() === 'Ú'){
                                    return ({letra, color: '#646464'})
                                }
                                if(letra.toUpperCase() !== 'A' || letra.toUpperCase() !== 'Á' || letra.toUpperCase() !== 'E' || letra.toUpperCase() !== 'É' || letra.toUpperCase() !== 'I' || letra.toUpperCase() !== 'Í' || letra.toUpperCase() !== 'O' || letra.toUpperCase() !== 'Ó' || letra.toUpperCase() !== 'U' || letra.toUpperCase() !== 'Ú') {
                                    return ({letra, color: '#646464'})
                                }
                            }
                        }
                    }
                }
            })
            const newArray = [...array];
            return (newArray);
        })
        setArrayColor(nuevoArray);
    }

    return [arrayColor, transformarColor];
}

export default useColorHelp;