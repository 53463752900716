import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import Practica20 from '../Practica20';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, NumFlex, Numbers, Number, NumberM21, BtnConteo, Btn, BtnNum } from '../../../../Css/StyledMatematica';

const data = [
    {id:'1', numero: 10, color: "#FFDC73"},
    {id:'2', numero: 20, color: "#23AAAF"},
    {id:'3', numero: 30, color: "#D76970"},
    {id:'4', numero: 40, color: "#FF9B2A"},
    {id:'5', numero: 50, color: "#655EE8"},
    {id:'6', numero: 60, color: "#FFDC73"},
    {id:'7', numero: 70, color: "#23AAAF"},
    {id:'8', numero: 80, color: "#D76970"},
    {id:'9', numero: 90, color: "#FF9B2A"}
]

const colors = {
    amarillo: "#FFDC73",
    celeste: "#23AAAF",
    rosa: "#D76970",
    naranja: "#FF9B2A",
    violeta: "#655EE8",
  };

  const instrucciones = 'A continuación se presentan números diferenciados por su valor posicional. El niño deberá leer en voz alta dichos números. Si necesita ayuda se puede hacer click sobre el recuadro donde presenta dificultad. Asimismo se puede hacer click sobre el ícono de ayuda.';
  const miniInstrucciones = 'Lee los números.';

const M20 = () => {

    const dispatch = useDispatch();

    const [comparar, setComparar] = useState([]);
    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);

    if(contador === 10){
        dispatch(setearEjercicioAction('M20Bis') );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        setComparar(data);
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M20') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar || fuerzoInicio
                ?
                <Flex direction="column" height="100%" width="100%">
                    {contador === 0 &&
                        <NumFlex>
                            {comparar.map(item => 
                            <Numbers color={item.color} key={item.id} >
                                {item.numero}
                            </Numbers>
                            )}
                        </NumFlex>
                    }
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        {
                        contador === 0 &&
                        <Btn>
                            <BtnConteo aumentar={true} />
                        </Btn>
                        }
                    </FlexCuz>
                    {contador > 0 && contador < 10 &&
                    <div>
                        <FlexCuz wrap='wrap' justify='start' align='start' height='60vh' width='89vw' wt='83vw' wd='66vw' wlg='63vw'>  
                            {comparar.filter(item => item.id < contador +1).map(item => 
                                <Numbers color={item.color} key={item.id}>
                                    {item.numero}
                                </Numbers>
                            )}
                        </FlexCuz>
                        <FlexCuz position='fixed' right='0' bottom='0'>
                            <BotoneraBottom reducir={true} aumentar={true} />
                        </FlexCuz>
                    </div>
                    }
                    {contador >= 10 &&
                        <Practica20 ejercicio="M20Bis" />
                    }
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M20;