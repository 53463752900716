import React, {Fragment} from 'react';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';

import { Flex, FlexConteo, DivConteo, Divs, DivsLink, LinksConteo } from '../../Css/StyledMatematica'
import { HrMetodos } from '../../Css/StyledHeader';

const Conteo = () => {
    

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} n1={"Métodos"} n2={"MATEMATICA"} componente={"CONTEO"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Flex wrap='wrap' margin='2vh 0px 1vh 0px' mm='3vh 0px 1vh 0px' mt='3vh 0px 1vh 0px' md='4vh 0px 1vh 0px' mlg='6vh 0px 1vh 0px'>
                <FlexConteo direction='column'>
                    <Divs>1-9</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/1-9/M19'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/1-9/M19Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>10-19</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/10-19/M21'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/10-19/M21Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>20-29</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/20-29/M22'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/20-29/M22Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>30-39</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/30-39/M23'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/30-39/M23Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>40-49</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/40-49/M24'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/40-49/M24Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>50-59</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/50-59/M25'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/50-59/M25Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>60-69</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/60-69/M26'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/60-69/M26Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>70-79</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/70-79/M27'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/70-79/M27Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>80-89</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/80-89/M28'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/80-89/M28Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>90-99</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/90-99/M29'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/90-99/M29Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>10-99</Divs>
                    <DivConteo>
                        <LinksConteo to={'/escritorio/metodos/matematica/conteo/10-99/M86Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>Decena</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/decena/M20'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/decena/M20Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>Centena</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/centena/M30'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/centena/M30Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>Unidad de Mil</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/unidad-de-mil/M31'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/unidad-de-mil/M31Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo direction='column'>
                    <Divs>Millón</Divs>
                    <DivConteo>
                        <LinksConteo m='0px 0.2vw 0px 0px' to={'/escritorio/metodos/matematica/conteo/millon/M32'}>Presentación</LinksConteo>
                        <LinksConteo m='0px 0px 0px 0.2vw' to={'/escritorio/metodos/matematica/conteo/millon/M32Bis'}>Práctica</LinksConteo>
                    </DivConteo>
                </FlexConteo>
                <FlexConteo>
                    <DivsLink to={'/escritorio/metodos/matematica/conteo/ejercitacion-1-10'}>Ejercitación 1 al 10</DivsLink>
                </FlexConteo>
            </Flex>
            
        </Fragment>
    );
}
 
export default Conteo;