import {
    ALMACENAR_ANAMNESIS,
    ALMACENAR_ANAMNESIS_EXITO,
    ALMACENAR_ANAMNESIS_ERROR
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

/* Almaceno información en la base de datos */
export function almacenarAnamnesisAction(informacion) {
    return async (dispatch) => {
        dispatch (almacenarAnamnesis());
        try {
            const {config,payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/almacenaranamnesis', payload,config);
            dispatch( almacenarAnamnesisExito(respuesta.data));
            Swal.fire(
                'Correcto',
                'Guardado con éxito',
                'success'
            )
        } catch (error) {
            const errores = error.response;
            Swal.fire(
                'Error',
                'El objetivo no se pudo agregar correctamente',
                'error'
            )
            if (errores !== undefined) {
                dispatch( almacenarAnamnesisError(error.response.data) );
            }
        }
    }
}

const almacenarAnamnesis = () => ({
    type: ALMACENAR_ANAMNESIS,
    payload: true
});

// si se almacena correctamente y reseteo el state de subitización
const almacenarAnamnesisExito = (data) => ({
    type: ALMACENAR_ANAMNESIS_EXITO,
    payload: data
});

// si hubo un error
const almacenarAnamnesisError = (msg) => ({
    type: ALMACENAR_ANAMNESIS_ERROR,
    payload: msg
});