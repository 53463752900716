import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

import { HrMetodos } from '../../../Css/StyledHeader';
import { Flex, FlexFull, Links1Al10, ImgB10 } from '../../../Css/StyledMatematica';

const Ejercitacion1Al10 = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"conteo/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"CONTEO"} componente={"EJERCITACION 1 AL 10"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex height='100%'>
                <FlexFull align='center' justify='center' wrap='wrap' width='100%' wm='' wt='90%' wd='82%' wlg='70%'>
                    <Links1Al10 to={`/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M33`}><ImgB10 src={`/img/portadas/matematica33.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 3vw 1vh 3vw' /></Links1Al10>
                    <Links1Al10 to={`/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M34`}><ImgB10 src={`/img/portadas/matematica34.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 3vw 1vh 3vw' /></Links1Al10>
                    <Links1Al10 to={`/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M35`}><ImgB10 src={`/img/portadas/matematica35.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 3vw 1vh 3vw' /></Links1Al10>
                    <Links1Al10 to={`/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M36`}><ImgB10 src={`/img/portadas/matematica36.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 3vw 1vh 3vw' /></Links1Al10>
                    <Links1Al10 to={`/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M37`}><ImgB10 src={`/img/portadas/matematica37.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 3vw 1vh 3vw' /></Links1Al10>
                    <Links1Al10 to={`/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M39`}><ImgB10 src={`/img/portadas/matematica39.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 3vw 1vh 3vw' /></Links1Al10>
                    <Links1Al10 to={`/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M40`}><ImgB10 src={`/img/portadas/matematica40.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 3vw 1vh 3vw' /></Links1Al10>
                    <Links1Al10 to={`/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M41`}><ImgB10 src={`/img/portadas/matematica41.jpg`} w='26vw' wm='24vw' wt='19vw' wd='16vw' wlg='14vw' border='solid 2px #23AAAF' bradius='10px' m='1vh 3vw 1vh 3vw' /></Links1Al10>
                </FlexFull>
            </Flex>
        </Fragment>    
    );
}
 
export default Ejercitacion1Al10;