import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import BotoneraBottom from './BotoneraBottom';
import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction } from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

import { Flex, BtnNumberM9, Img } from '../../Css/StyledMatematica';

const CualEsMayor = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, m, w, wm, wt, wd, wlg, instrucciones, miniInstrucciones } = props;

    const [comparar, setComparar] = useState([]);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [nuevoEstilo1, setNuevoEstilo1] = useState();
    const [nuevoEstilo2, setNuevoEstilo2] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar1 = (e, cant) => {
        e.preventDefault();
        if(cant < comparar.cantidad_2) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
            }, 300);
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setDisable(false);
                setCount(count + 1);
            } else {
                setTimeout(() => {
                    setDisable(false);
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }
    const verificar2 = (e, cant) => {
        e.preventDefault();
        if(cant < comparar.cantidad_1) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
            }, 300);
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setDisable(false);
                setNuevoEstilo1();
                setNuevoEstilo2();
                setCount(count + 1);
            } else {
                setTimeout(() => {
                    setDisable(false);
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        if(data[count-1].operatoria){
            setComparar(data[count-1]);
        } else {
            let random = Math.floor(Math.random() * (1 - 0 +1)) + 0;
            if(random === 1){
                setComparar(data[count-1]);
            } else {
                let revertir = {id:data[count-1].id, imagen_url_1:data[count-1].imagen_url_2, imagen_url_2:data[count-1].imagen_url_1, cantidad_1:data[count-1].cantidad_2, cantidad_2:data[count-1].cantidad_1}
                setComparar(revertir);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, iniciar])

    useEffect(() => {
        if(!comparar.operatoria){
            if(comparar.numero_1 > comparar.numero_2){
                setNuevoEstilo2({
                    fontWeight: "bold",
                    fontSize: "50px"
                })
            } else {
                setNuevoEstilo1({
                    fontWeight: "bold",
                    fontSize: "50px"
                })
            }
        } else {
            setNuevoEstilo2();
            setNuevoEstilo1();
        }
    }, [comparar])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);
    
    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex margin="auto" width="100%"
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}
                    >
                        <BtnNumberM9
                            disabled={disable}
                            onClick={(e) => verificar1(e, comparar.cantidad_1)}
                            style={nuevoEstilo1} bgcolor={props.bgcolor}
                        >
                            {comparar.operatoria === '+' || comparar.operatoria === '-'?
                                <div>{comparar.numero_1} {comparar.operatoria} {comparar.numero_3}</div>
                                :
                                comparar.operatoria ? 
                                    <div>{comparar.numero_1}</div>
                                    :
                                    <div>
                                        {comparar.numero_1 ? comparar.numero_1 : <Img src={`/img/${comparar.imagen_url_1}.png`} margin={m} width={w} wm={wm} wt={wt} wd={wd} wlg={wlg} />}
                                    </div>
                            }
                        </BtnNumberM9>
                        <BtnNumberM9
                            disabled={disable}
                            onClick={(e) => verificar2(e, comparar.cantidad_2)}
                            style={nuevoEstilo2} bgcolor={props.bgcolor}
                        >   
                            {comparar.operatoria === '+' || comparar.operatoria === '-'? 
                                <div>{comparar.numero_2} {comparar.operatoria} {comparar.numero_4}</div>
                                :
                                comparar.operatoria ? 
                                <div>{comparar.numero_2}</div>
                                    :
                                    <div>
                                        {comparar.numero_2 ? comparar.numero_2 : <Img src={`/img/${comparar.imagen_url_2}.png`} margin={m} width={w} wm={wm} wt={wt} wd={wd} wlg={wlg} />}
                                    </div>
                            }
                        </BtnNumberM9>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="start">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones}/>
                    </Flex>
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default CualEsMayor;