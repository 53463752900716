import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import {Flex, YellowBorder, YellowBtn, PalabraXL } from '../../../../Css/MetodosCSS/StyledCCIntrasilabica';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Selecciona las palabras iguales.';
const miniInstrucciones = 'Selecciona las palabras iguales.';

const LEN30 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [randomPalabra, setRandomPalabra] = useState([]);
    const [indexes, setIndexes] = useState();
    const [indexUno, setIndexUno] = useState('');
    const [indexDos, setIndexDos] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const elegirUno = (e, index) => {
        e.preventDefault();
        if(indexUno === index) {
            const newList = JSON.parse(JSON.stringify(randomPalabra));
            newList[index].background = false;
            setRandomPalabra(newList);
            setIndexUno('');
            return;
        }
        if(indexUno !== '' || indexDos === index){
            return;
        }
        if(indexUno === undefined || indexUno === '') {
            const newList = JSON.parse(JSON.stringify(randomPalabra));
            newList[index].background = true;
            setRandomPalabra(newList);
            setIndexUno(index);
        }
    }

    const elegirDos = (e, index) => {
        e.preventDefault();
        if(indexDos === index) {
            const newList = JSON.parse(JSON.stringify(randomPalabra));
            newList[index].background = false;
            setRandomPalabra(newList);
            setIndexDos('');
            return;
        }
        if(indexUno === '' || indexUno === index){
            return;
        }
        if(indexDos === undefined || indexDos === '') {
            const newList = JSON.parse(JSON.stringify(randomPalabra));
            newList[index].background = true;
            setRandomPalabra(newList);
            setIndexDos(index);
        }
    }
    
    const verificar = (e) => {
        e.preventDefault();
        if(indexUno === '' || indexDos === ''){
            return;
        }
        if(randomPalabra[indexUno].palabra.toUpperCase() !== randomPalabra[indexDos].palabra.toUpperCase()) {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setDisable(true);
            setTimeout(() => {
                const newList = JSON.parse(JSON.stringify(randomPalabra));
                newList[indexUno].background = false;
                newList[indexDos].background = false;
                setRandomPalabra(newList);
                setDisable(false);
                setIndexUno('');
                setIndexDos('');
            }, 1000);
        } else {
            console.log('bien');
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            setDisable(true);
            if(count < indexes) {
                setTimeout(() => {
                    const newList = JSON.parse(JSON.stringify(randomPalabra));
                    newList[indexUno].background = false;
                    newList[indexDos].background = false;
                    setRandomPalabra(newList);
                    setDisable(false);
                    setIndexUno('');
                    setIndexDos('');
                    setCount(count + 1);
                }, 1000);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    setDisable(false);
                    setIndexUno('');
                    setIndexDos('');
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN30') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-lexica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN30",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));

        const newComparar = [
            {id:'1', palabra:newList.palabra_1, background: false},
            {id:'2', palabra:newList.palabra_1, background: false},
            {id:'3', palabra:newList.palabra_2, background: false},
            {id:'4', palabra:newList.palabra_2, background: false}
        ]

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(newComparar);
        
        setRandomPalabra(
            newShuffleList
        )
    }, [comparar]);

    return (
        <Fragment>
        <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
        {iniciar
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex margin="auto" width="100%">
                    <YellowBorder padding="0" radius="10px">
                    {randomPalabra.map((item, index) =>
                        <YellowBtn padding="0" background={item.background}
                            key={item.id}
                            disabled={disable}
                            onClick={(e) => {elegirUno(e, index); elegirDos(e, index)}}
                            isCorrect={isCorrect} isIncorrect={isIncorrect}
                        >
                            <YellowBorder><PalabraXL>{item.palabra.toUpperCase()}</PalabraXL></YellowBorder> 
                        </YellowBtn>
                    )}
                    </YellowBorder>
                </Flex>
                <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                    <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    <AvanzarBtn
                        disabled={disable}
                        onClick={(e) => verificar(e)}
                    >
                        <AvanzarImg src={'/img/play.png'} /> 
                    </AvanzarBtn>
                </Flex>
            </Flex>
            :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default LEN30;