import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction} from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

import { ContainerFlexx, DivFlex, BtnSilabaLN3, BtnSonido, Img } from '../../Css/MetodosCSS/StyledLectoEscritura';

const LN3Todos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, instrucciones, miniInstrucciones } = props;

    const [disable, setDisable] = useState(false);
    const [count, setCount] = useState(1);
    const [comparar, setComparar] = useState([]);
    const [randomSonido, setRandomSonido] = useState([]);
    const [ronda, setRonda] = useState(1);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const letra = useSelector(state => state.ejercicio.letra);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const play1 = () => new Audio(`/sounds/${randomSonido[count-1].sonido}.mp3`).play()

    const verificar = (e, slb) => {
        e.preventDefault();
        if (slb.toUpperCase() !== randomSonido[count-1].silaba.toUpperCase()) {
            setDisable(true);
            console.log('mal');
            setDisable(false);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
        } else {
            console.log('bien')
            GoodWork();
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            if (count < indexes) {
                setTimeout(() => {
                    setCount(count + 1);
                    setDisable(false);
                }, 300);
            } else if (ronda < 2) {
                setTimeout(() => {
                    setRonda(ronda + 1);
                    setDisable(false);
                    setCount(1);
                }, 1500);
            } else {
                setTimeout(() => {
                    setDisable(false);
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction())
                }, 1500);
            }
        }
    }

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        setComparar(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': ejercicio,
                'letra': letra,
                'errores': contarErrores,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                sesion
            }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(data));

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList);
        setRandomSonido(shuffleList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,ronda]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true}  />
            {iniciar
                ?
                <DivFlex direction='column' height="85vh" justify='space-around' align='center'
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                >
                    <ContainerFlexx>
                        <BtnSonido
                            onClick={() => play1()}
                        >
                            <Img alt='' src={`/img/altavoz.png`} w='120px' h='120px' wm='120px' wt='140px' wd='140px' wlg='140px' hm='120px' ht='140px' hd='140px' hlg='140px' />
                        </BtnSonido>
                        {randomSonido[count-1] === undefined ? null : <audio src={`/sounds/${randomSonido[count-1].sonido}.mp3`} autoPlay />}
                    </ContainerFlexx>

                    <ContainerFlexx>
                        {comparar.map(item => (
                            <BtnSilabaLN3 width= "14rem" height= "130px" widthmob= "14rem" heightmob= "100px" font= "5rem" fontmob= "3.5rem" $minMay={minuscula}
                                disabled={disable}
                                key={item.id}
                                onClick={(e) => verificar(e, item.silaba)}
                            >
                                {letra.toLowerCase() === 'r_suave' || letra.toLowerCase() === 'c' ? 
                                <i>{item.letra}</i>
                                :
                                item.letra
                                }<span style={ayuda_lectura ? item.myStyle : null}>{item.segunda_letra}</span>
                            </BtnSilabaLN3>
                        ))}
                    </ContainerFlexx>
                </DivFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN3Todos;