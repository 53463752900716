import {
    RESET_PASSWORD,
    RESET_PASSWORD_EXITO,
    RESET_PASSWORD_ERROR
} from '../types';
import clienteAxios from '../config/axios';

import Swal from 'sweetalert2';


// Resetea la contraseña

export function resetPasswordAction(informacion) {
    return async (dispatch) => {
        dispatch( resetPassword() );
        try {
            const {payload} = informacion;
            const respuesta = await clienteAxios.post('/auth/reset', payload);
            dispatch (resetPasswordExito(respuesta.data));
            //alerta
            Swal.fire({
                icon: 'success',
                title:'La solicitud ha sido un éxito',
                text: `${respuesta.data.msg}`,
            }).then(function() {
                window.location = "https://metodoyogen.com/login";
            });
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( resetPasswordError(error.response.data) );
            }
        } 
    }
};

const resetPassword = () => ({
    type: RESET_PASSWORD,
    payload: true
});

const resetPasswordExito = () => ({
    type: RESET_PASSWORD_EXITO
});

const resetPasswordError = (msg) => ({
    type: RESET_PASSWORD_ERROR,
    payload: msg
});


