import styled, {css, keyframes} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}

// CONTENEDORES
export const Cuerpo = styled.div`
`

export const ContInst = styled.div`
    position: absolute;
    bottom: 40px; 
    left: 25px; 
    text-transform: uppercase; 
    font-family: nunito;
    font-weight: 400;
    font-size: 16px; 
`
export const ContenedorLateral = styled.ul`
    display: flex;
    justify-content: center;
    width: 75px;
    height: 80vh;
    background-color: #646464;
    flex-direction: column;
    border-radius: 10px;
    padding-left: 0px;
    align-items: center;
`
export const Contenedor = styled.div`
    display: flex;
    align-items: center;
`
export const Flex = styled.div`
    display: flex;
    justify-content: center;
    justify-content: ${props => props.justify};
    flex-direction: ${props => props.direction};
    align-items: ${props => props.align};
    margin: ${props => props.margin};
    width: ${props => props.width};
    height: ${props => props.height};
    ${media.tablet}{
        margin: ${props => props.marginTablet};
    }
`
export const Recuadro = styled.div`
    display: flex;
    width: 650px; 
    flex-wrap: wrap;
    border: solid 4px #FCF8C9;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `}
`
// ANIMACIONES
export const ShakeImg = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`
export const ZoomIn = keyframes`
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
`
// LISTADOS
export const ListaLetras = styled.li`
    list-style:none;
    margin: 0;
    display: flex; 
    flex-direction: column;
    justify-content: center;
`
export const ContenedorLetras = styled.ul`
    display: flex;
    width: 70vw;
    justify-content: space-around;
`
// LETRAS
export const Letra = styled.p`
    color: ${props => props.color};
    font-family: nunito;
    font-weight: 800;
    font-size: 144px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: ${props => props.margin};
    ${media.tablet}{
        font-size: 100px;
}
`
export const LetraSmall = styled.p`
    font-family: nunito;
    font-size: 36px;
    margin: 0;
`
export const LetraRepaso = styled.div`
    font-family: nunito;
    color: white;
    font-weight:800;
    font-size: 30px;
    background-color: ${props => props.bgcolor};
    width: 70px;
    height: 70px;
    border-radius: 10px; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 3vw;
    border: ${props => props.border ? '3px solid black' : 0};
    cursor: pointer;
`
export const ContLetra = styled.button`
    border: 0;
    background: transparent; 
`
export const ContenedorXL = styled.div`
    width: 10vw;
    height: 10vw; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    color: white;
    font-family: nunito;
    font-size: 15vw;
`
export const LetraS = styled.div`
    font-family: nunito;
    font-size: 30px;
    color:#646464;
    cursor: pointer;
`
// IMAGENES
export const ImgSound = styled.img`
    width: 30px;
`
export const BtnSound = styled.button`
    border: 0; 
    background: transparent;
`
export const BtnBottom = styled.button`
    border: 0; 
    background: transparent;
    cursor: pointer;
`
export const Icons = styled.div`
    position: absolute;
    bottom: ${props => props.bottom};
    right: ${props => props.right};
`
export const aeiouBtn = styled.button`
    border: 0; 
    background: transparent;
`
export const BocaImg = styled.img`
    width: 176px;
    height: 136px;
    ${media.tablet}{
       width: 150px;
       height: 110px;
       margin-top: 150px;
}
`
export const IconsBtn = styled.div`
    position: absolute;
    bottom: ${props => props.bottom};
    right: ${props => props.right};
    border: 0; 
    background: transparent;
`
