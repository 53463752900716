import React, { Fragment } from 'react';

import HeaderUno from './Compartidos/HeaderUno';
// css
import {LogoBackground, Container, Texto, Background, BtnMethods, BtnLink} from '../Css/MetodosCSS/StyledMethods'

const Metodos = () => {

    const isActive = false;

    return ( 
        <Fragment>
            <Background>
            <HeaderUno />
            <Container height="99%" width="100%" display="flex" direction="column" justify="center" align="center">
                <Container justify="center" direction="column" align="center">
                    <LogoBackground src={`/img/logoBackground.png`} alt=""  width="30vw" margin="40px 0px 0px 0px"/>
                    <Texto weight="600" color="#4E4E4E" margin="25px 0px">Programa de enseñanza explícita</Texto>
                </Container>
                <Container justify="space-around" alignC="flex-end" wrap="wrap">
                    <BtnMethods><BtnLink to={'/escritorio/metodos/lenguaje'}>Lenguaje</BtnLink></BtnMethods>
                    <BtnMethods><BtnLink to={'/escritorio/metodos/lecto-escritura'}>Lectura y Escritura</BtnLink></BtnMethods>
                    <BtnMethods><BtnLink to={'/escritorio/metodos/matematica'}>Matematica</BtnLink></BtnMethods>
                    <BtnMethods style={{backgroundColor: "grey"}} ><BtnLink to={isActive ? '/escritorio/metodos/cognicion' : '#'}>Cognición</BtnLink></BtnMethods>
                    <BtnMethods style={{backgroundColor: "grey"}} ><BtnLink to={isActive ? '/escritorio/metodos/emocion' : '#'}>Emocion</BtnLink></BtnMethods>
                </Container>
            </Container>
            </Background>
        </Fragment>
    );
}
 
export default Metodos;