import styled, {css} from 'styled-components'
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom"; 
import BotoneraBottom from '../Metodos/Compartidos/BotoneraBottom.js';

// QUERYS
const media = {
    min: '@media (min-width:505px) and (max-width:699px)',
    tablet: '@media (min-width:700px) and (max-width:996px)',
    desktop: '@media(min-width:996px) and (max-width:1200px)',
    desktopLg: '@media (min-width:1200px)'
}

// BÁSICOS



export const Flex = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    justify-content: ${props => props.justify};
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    max-width: ${props => props.maxw};
    min-width: ${props => props.minw};
    max-height: ${props => props.maxh};
    min-height: ${props => props.minh};
    background-color: ${props => props.color};
    position: ${props => props.position};
    left: ${props => props.left};
    bottom: ${props => props.bottom};
    border: ${props => props.border};
    border-bottom: ${props => props.bordb};
    border-radius: ${props => props.bradius};
    opacity: ${props => props.op};
    box-shadow: ${props => props.bs};
    overflow: ${props => props.of};
    z-index: ${props => props.z};

    ${media.min}{
        width: ${props => props.wm};
        height: ${props => props.hm};
        margin: ${props => props.mm};
        max-width: ${props => props.maxwm};
        min-width: ${props => props.minwm};
        max-height: ${props => props.maxhm};
        min-height: ${props => props.minhm};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        width: ${props => props.wt};
        height: ${props => props.ht};
        max-width: ${props => props.maxwt};
        min-width: ${props => props.minwt};
        max-height: ${props => props.maxht};
        min-height: ${props => props.minht};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        width: ${props => props.wd};
        height: ${props => props.hd};
        max-width: ${props => props.maxwd};
        min-width: ${props => props.minwd};
        max-height: ${props => props.maxhd};
        min-height: ${props => props.minhd};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        height: ${props => props.hlg};
        max-width: ${props => props.maxwlg};
        min-width: ${props => props.minwlg};
        max-height: ${props => props.maxhlg};
        min-height: ${props => props.minhlg};
        }
`

export const FlexCuz = styled.div`
    display: flex; 
    justify-content: ${props => props.justify}; 
    align-items: center;
    align-self: ${props => props.$alignself};
    order: ${props => props.$order};
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    max-width: ${props => props.maxw};
    min-width: ${props => props.minw};
    max-height: ${props => props.maxh};
    min-height: ${props => props.minh};
    background-color: ${props => props.color};
    position: ${props => props.position};
    left: ${props => props.left};
    right: ${props => props.right};
    bottom: ${props => props.bottom};
    border: ${props => props.br};

    ${media.min}{
        margin: ${props => props.mm};
        min-height: ${props => props.minhm};
        max-height: ${props => props.maxhm};
        min-width: ${props => props.minwm};
        max-width: ${props => props.maxwm};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        width: ${props => props.wt};
        min-height: ${props => props.minht};
        max-height: ${props => props.maxht};
        min-width: ${props => props.minwt};
        max-width: ${props => props.maxwt};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        width: ${props => props.wd};
        min-height: ${props => props.minhd};
        max-height: ${props => props.maxhd};
        min-width: ${props => props.minwd};
        max-width: ${props => props.maxwd};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        min-height: ${props => props.minhlg};
        max-height: ${props => props.maxhlg};
        min-width: ${props => props.minwlg};
        max-width: ${props => props.maxwlg};
        }
`
export const FlexFull = styled.div`
    display: flex; 
    justify-content: ${props => props.justify}; 
    align-items: ${props => props.align};  
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    max-width: ${props => props.maxw};
    min-width: ${props => props.minw};
    max-height: ${props => props.maxh};
    min-height: ${props => props.minh};
    background-color: ${props => props.color};
    position: ${props => props.position};
    left: ${props => props.left};
    right: ${props => props.right};
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    border: ${props => props.border};
    border-radius: ${props => props.bradius};
    z-index: ${props => props.z};

    ${media.min}{
        // background-color: orange;
        width: ${props => props.wm};
        height: ${props => props.hm};
        margin: ${props => props.mm};
        max-width: ${props => props.maxwm};
        min-width: ${props => props.minwm};
        max-height: ${props => props.maxhm};
        min-height: ${props => props.minhm};
        }
    ${media.tablet}{
        // background-color: blue;
        margin: ${props => props.mt};
        width: ${props => props.wt};
        height: ${props => props.ht};
        max-width: ${props => props.maxwt};
        min-width: ${props => props.minwt};
        max-height: ${props => props.maxht};
        min-height: ${props => props.minht};
        }
    ${media.desktop}{
        // background-color: violet;
        margin: ${props => props.md};
        width: ${props => props.wd};
        height: ${props => props.hd};
        max-width: ${props => props.maxwd};
        min-width: ${props => props.minwd};
        max-height: ${props => props.maxhd};
        min-height: ${props => props.minhd};
        }
    ${media.desktopLg}{
        // background-color: green;
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        height: ${props => props.hlg};
        max-width: ${props => props.maxwlg};
        min-width: ${props => props.minwlg};
        max-height: ${props => props.maxhlg};
        min-height: ${props => props.minhlg};
        }
`

export const HeaderMat = styled.div`
    width: ${props => props.width};
    height: 70px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: ${props => props.fw}; 
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};

    ${media.min}{
        color: ${props => props.colorm};  
        background-color: ${props => props.bgcolorm};
        width: ${props => props.wm}; 
        height: ${props => props.hm}; 
        margin: ${props => props.marginm};
        padding: ${props => props.paddingm}; 
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        background-color: ${props => props.bgcolort};
        width: ${props => props.wt}; 
        height: ${props => props.ht}; 
        margin: ${props => props.margint};
        padding: ${props => props.paddingt}; 
        }
    ${media.desktop}{
        color: ${props => props.colord}; 
        background-color: ${props => props.bgcolord};
        width: ${props => props.wd}; 
        height: ${props => props.hd}; 
        margin: ${props => props.margind};
        padding: ${props => props.paddingd}; 
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        background-color: ${props => props.bgcolorlg};
        width: ${props => props.wlg}; 
        height: ${props => props.hlg}; 
        margin: ${props => props.marginlg};
        padding: ${props => props.paddinglg}; 
        }
`

export const Container = styled.div`
    display: ${props => props.dis};
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justify}; 
    align-items: ${props => props.align}; 
    width: ${props => props.width};
    height: ${props => props.height};
    max-width: ${props => props.maxw};
    min-width: ${props => props.minw};
    max-height: ${props => props.maxh};
    min-height: ${props => props.minh};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    border-radius: ${props => props.br};
    font-weight: ${props => props.fw}; 
    // color: white;
    // border: 2px solid violet;
    background-color: ${props => props.bgcolor};

    ${media.min}{
        color: ${props => props.colorm};  
        background-color: ${props => props.bgcolorm};
        // background-color: orange;
        width: ${props => props.wm}; 
        height: ${props => props.hm}; 
        min-height: ${props => props.minhm};
        max-height: ${props => props.maxhm};
        min-width: ${props => props.minwm};
        max-width: ${props => props.maxwm};
        margin: ${props => props.marginm};
        padding: ${props => props.paddingm}; 
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        background-color: ${props => props.bgcolort};
        // background-color: blue;
        width: ${props => props.wt}; 
        height: ${props => props.ht}; 
        min-height: ${props => props.minht};
        max-height: ${props => props.maxht};
        min-width: ${props => props.minwt};
        max-width: ${props => props.maxwt};
        margin: ${props => props.margint};
        padding: ${props => props.paddingt}; 
        }
    ${media.desktop}{
        color: ${props => props.colord}; 
        background-color: ${props => props.bgcolord};
        // background-color: violet;
        width: ${props => props.wd}; 
        height: ${props => props.hd}; 
        min-height: ${props => props.minhd};
        max-height: ${props => props.maxhd};
        min-width: ${props => props.minwd};
        max-width: ${props => props.maxwd};
        margin: ${props => props.margind};
        padding: ${props => props.paddingd}; 
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        background-color: ${props => props.bgcolorlg};
        // background-color: green;
        width: ${props => props.wlg}; 
        height: ${props => props.hlg}; 
        min-height: ${props => props.minhlg};
        max-height: ${props => props.maxhlg};
        min-width: ${props => props.minwlg};
        max-width: ${props => props.maxwlg};
        margin: ${props => props.marginlg};
        padding: ${props => props.paddinglg}; 
        }
`

export const Links = styled (Link)`
    color: ${props => props.color}; 
    background-color: ${props => props.bgcolor};
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding}; 
    font-size: ${props => props.fs}; 
    font-weight: ${props => props.fw}; 
    font-family: 'Nunito';
    font-style: normal;
    text-decoration: none;
    border-radius: 7px;
    line-height: ${props => props.lh};
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.min}{
        color: ${props => props.colorm};  
        font-size: ${props => props.fsm};
        width: ${props => props.wm}; 
        height: ${props => props.hm}; 
        padding: ${props => props.paddingm}; 
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        font-size: ${props => props.fst}; 
        width: ${props => props.wt}; 
        height: ${props => props.ht}; 
        }
    ${media.desktop}{
        width: ${props => props.wd}; 
        height: ${props => props.hd}; 
        font-size: ${props => props.fsd}; 
        padding: ${props => props.paddingd}; 
        color: ${props => props.colord}; 
        }
    ${media.desktopLg}{
        width: ${props => props.wlg}; 
        height: ${props => props.hlg}; 
        font-size: ${props => props.fslg}; 
        padding: ${props => props.paddinglg}; 
        color: ${props => props.colorlg}; 
        }
`

export const HrMath = styled.hr`
    background-color: #646464;
    height: 1px;
    width: 97vw;
    margin: ${props => props.margin};
    display: ${props => props.display};
`










// ------ CARATULAS ------

// MATEMATICA

// BACKGROUND
export const BackgroundMath = styled.div`
    background-image: url('/img/portadas/matematica_caratula_principal.png');
    // background-size: 100vw 100vh;
    background-size: 100% 100%;
    height: 62.5vh;
    // width: 98vw;
    background-repeat: no-repeat;
    margin: 30px 15px 0px 15px;

    // border: 2px solid red;

    ${media.min}{
        height: 65vh;
        }
    ${media.tablet}{
        height: 65vh;
        }
    ${media.desktop}{
        height: 66vh;
        }
    ${media.desktopLg}{
        height: 66vh;
        }
`

export const BackgroundLecto = styled.div`
    /* background-image: ; */
    background-size: 100% 100%;
    height: 80vh;
    background-repeat: no-repeat;
    margin: 30px 15px 0px 15px;
`

// LINKS CHICOS
export const LinksMathSmall = styled (Link)`
    color: ${props => props.color}; 
    background-color: ${props => props.bgcolor};
    width: 23vw;
    height: 6vh;
    margin: 6vh 1.5vw 0px 1.5vw;
    padding: 1.5vh 1.9vw 1.5vh 1.9vw; 
    font-size: 2.7vw; 
    font-weight: ${props => props.fw}; 
    font-family: 'Nunito';
    font-style: normal;
    text-decoration: none;
    border-radius: 7px;
    line-height: ${props => props.lh};
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.min}{
        color: ${props => props.colorm};  
        font-size: ${props => props.fsm};
        width: 21vw; 
        height: 7.5vh; 
        padding: ${props => props.paddingm}; 
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        font-size: 2vw; 
        width: 17vw; 
        height: 9vh; 
        }
    ${media.desktop}{
        color: ${props => props.colord}; 
        width: 17.2vw; 
        height: 10vh; 
        font-size: 2vw; 
        padding: ${props => props.paddingd}; 
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        width: 17.3vw; 
        height: 10vh; 
        font-size: 1.8vw; 
        padding: ${props => props.paddinglg}; 
        }
`

// LINKS GRANDES

export const LinksMathBig = styled (Link)`
    color: ${props => props.color}; 
    background-color: ${props => props.bgcolor};
    width: 23vw;
    height: 6vh;
    margin: 6vh 1.5vw 0px 1.5vw;
    padding: 1.5vh 1.9vw 1.5vh 1.9vw; 
    font-size: 2.6vw; 
    font-weight: ${props => props.fw}; 
    font-family: 'Nunito';
    font-style: normal;
    text-decoration: none;
    border-radius: 7px;
    line-height: ${props => props.lh};
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.min}{
        color: ${props => props.colorm};  
        font-size: 2.3vw;
        width: 21vw; 
        height: 7.5vh; 
        padding: 0 auto;
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        font-size: 1.7vw;
        width: 17vw; 
        height: 9vh; 
        }
    ${media.desktop}{
        color: ${props => props.colord}; 
        width: 17.2vw;
        height: 10vh;
        font-size: 1.9vw;
        padding: ${props => props.paddingd}; 
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        width: 17.3vw; 
        height: 10vh; 
        font-size: 1.8vw; 
        padding: ${props => props.paddinglg}; 
        }
`

// FIN MATEMATICA

// SUBITIZACIÓN

export const LinksSub = styled (Link)`
    color: ${props => props.color}; 
    background-color: ${props => props.bgcolor};
    width: 27vw;
    height: 18vh;
    margin: 23vh 2vw 6px 2vw;
    // padding: 1.5vh 1.9vw 1.5vh 1.9vw; 
    font-size: 5vw; 
    font-weight: ${props => props.fw}; 
    font-family: 'Nunito';
    font-style: normal;
    text-decoration: none;
    border-radius: 7px;
    line-height: ${props => props.lh};
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.min}{
        color: ${props => props.colorm};  
        width: 24vw; 
        height: 20vh; 
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        width: 19vw;
        height: 23vh;
        }
    ${media.desktop}{
        color: ${props => props.colord}; 
        width: 17vw;
        height: 25vh;
        font-size: 4vw;
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        width: 17vw; 
        height: 25vh; 
        font-size: 4vw; 
        padding: ${props => props.paddinglg}; 
        }
`

// FIN SUBITIZACIÓN

// ESTIMACIÓN Y NOCION DE CANTIDAD, CALCULO, LECTURA Y ESCRITUDA DE NÚMEROS

export const LinksVarios = styled (Link)`
    color: ${props => props.color}; 
    background-color: ${props => props.bgcolor};
    width: 35vw;
    height: 21vh;
    margin: 21vh 4vw 6px 4vw;
    font-size: 4.5vw; 
    font-weight: ${props => props.fw}; 
    font-family: 'Nunito';
    font-style: normal;
    text-decoration: none;
    border-radius: 7px;
    line-height: ${props => props.lh};
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.min}{
        color: ${props => props.colorm};  
        width: 27vw;
        height: 22vh;
        font-size: 3.5vw; 
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        width: 22vw;
        height: 22vh;
        font-size: 2.8vw; 
        }
    ${media.desktop}{
        color: ${props => props.colord}; 
        width: 19vw;
        height: 21vh;
        font-size: 2.4vw; 
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        width: 17vw;
        height: 22vh;
        font-size: 2.3vw; 
        }
`

// FIN ESTIMACIÓN Y NOCION DE CANTIDAD, CALCULO, LECTURA Y ESCRITUDA DE NÚMEROS


// BASE 10

// BUTTON
export const ButtonBase = styled.button`
    background: transparent;
    width: 27vw;
    border-radius: 10px;
    border:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:${props => props.m};

    ${media.min}{
        // background-color: orange;  
        margin:${props => props.mm};
        }
    ${media.tablet}{
        // background-color: blue;  
        margin:${props => props.mt};
        width: 26vw;
    }
    ${media.desktop}{
        // background-color: violet;  
        margin:${props => props.md};
        width: 24vw;
        }
    ${media.desktopLg}{
        // background-color: green;  
        margin:${props => props.mlg};
        width: 22vw;
        }
`

// BUTTON
export const ButtonBack = styled.button`
    background: transparent;
    // width: 27vw;
    border-radius: 10px;
    border:0;
`

// DESCRIPTION
export const Description = styled.p`
    font-size: 2.8VW;
    font-weight: 600;
    color: #000000;
    word-break: word-break;
    padding: 1vh 0px 0px 0px;
    cursor: pointer;
    margin: ${props => props.margin};

    ${media.min}{
        font-size: 2.3vw;
        }
    ${media.tablet}{
        font-size: 1.8vw;
    }
    ${media.desktop}{
        font-size: 1.5vw;
        }
    ${media.desktopLg}{
        font-size: 1.4vw;
        }
`

// IMAGENES
export const ImgBase = styled.img`
    border: solid 2px #23AAAF;
    width: 29vw; 
    height: 17vh; 
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    ${media.min}{ 
        width: 29vw; 
        height: 20vh; 
        }
    ${media.tablet}{
        width: 25vw; 
        height: 23vh; 
    }
    ${media.desktop}{
        width: 20vw; 
        height: 23.5vh; 
        }
    ${media.desktopLg}{
        width: 18.5vw; 
        height: 24vh;  
        }
`

// FIN BASE 10





// CONTEO

// LINKS

export const FlexConteo = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: 2vh 1.5vw 2vh 1.5vw;
    width: 22vw;
    padding: ${props => props.padding};
    height: ${props => props.height};
    border-radius: 8px;
    // background-color: ${props => props.bgcolor};
    // background-color: pink;

    ${media.min}{
        // background-color: orange;
        width: 22.3vw;
        margin: 2.5vh 1.1vw 2vh 1.1vw;
        }
    ${media.tablet}{
        // background-color: blue;
        width: 20vw;
        margin: 2.5vh 1.1vw 2vh 1.1vw;
        }
    ${media.desktop}{
        // background-color: violet;
        width: 17vw;
        margin: 2.8vh 1.1vw 2vh 1.1vw;
        }
    ${media.desktopLg}{
        // background-color: green;
        width: 15vw;
        margin: 2.5vh 1.1vw 2vh 1.1vw;
        }
`
export const LinksConteo = styled (Link)`
    color: ${props => props.color}; 
    // background-color: ${props => props.bgcolor};
    background-color: #23AAAF;
    color: white;
    width: 11vw;
    // height: 8vh;
    margin: ${props => props.m};
    // padding: 1.5vh 1.9vw 1.5vh 1.9vw; 
    font-size: 1.8vw; 
    font-weight: ${props => props.fw}; 
    font-family: 'Nunito';
    font-style: normal;
    text-decoration: none;
    border-radius: 6px;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.min}{
        color: ${props => props.colorm};  
        font-size: 1.8vw;
        width: 11vw;
        // height: 7.5vh; 
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        font-size: 1.5vw; 
        width: 9.8vw; 
        }
    ${media.desktop}{
        color: ${props => props.colord}; 
        font-size: 1.3vw; 
        width: 8.3vw; 
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        font-size: 1.1vw; 
        width: 7.3vw; 
        }
`
export const DivConteo = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    background-color: ${props => props.color};

    ${media.min}{
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        }
`

export const Divs = styled.div`
    width: 100%;
    height: ${props => props.height};
    text-align: center;
    border-radius: 8px;
    color: white;
    background-color: #23AAAF;
    font-size: 3vw;
    font-weight: 600;
    line-height: 50px;
    margin: 0px 0px 5px 0px;
    padding: ${props => props.padding};
    // background-color: ${props => props.color};

    ${media.min}{
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2.2vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        font-size: 2vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        font-size: 2vw;
        margin: ${props => props.mlg};
    }
`

export const DivsLink = styled (Link)`
    width: 100%;
    height: ${props => props.height};
    text-align: center;
    border-radius: 8px;
    color: white;
    background-color: #23AAAF;
    font-size: 2.3vw;
    font-weight: 600;
    text-decoration: none;
    line-height: 50px;
    margin: 0px 0px 5px 0px;
    padding: ${props => props.padding};
    // background-color: ${props => props.color};

    ${media.min}{
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        font-size: 1.8vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        font-size: 1.6vw;
        margin: ${props => props.mlg};
    }
`

// LINKS
export const Links1Al10 = styled (Link)`
    // width: 30vw;
    height: fit-content;
    text-align: center;
    border-radius: 8px;
    color: white;
    // background-color: #23AAAF;
    // background-color: pink;
    font-size: 2.3vw;
    font-weight: 600;
    text-decoration: none;
    line-height: 50px;
    margin: ${props => props.m};
    padding: ${props => props.padding};

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 2;

    ${media.min}{
        // background-color: orange;  
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2vw;
        // background-color: blue;  
        // width: 27vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // background-color: violet;  
        // width: 24vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // background-color: green;  
        // width: 25vw;
        margin: ${props => props.mlg};
    }
`
// FIN CONTEO


// ------ FIN CARATULAS ------

// ------ SUBITIZACIÓN CARATULAS ------

// NIVEL 1, NIVEL 2 Y NIVEL 3

// LINKS
export const LinksSubNivs = styled (Link)`
    width: 30vw;
    height: fit-content;
    text-align: center;
    border-radius: 8px;
    color: white;
    // background-color: #23AAAF;
    // background-color: pink;
    font-size: 2.3vw;
    font-weight: 600;
    text-decoration: none;
    line-height: 50px;
    margin: 0px 1vw 5px 1vw;
    padding: ${props => props.padding};

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    ${media.min}{
        // background-color: orange;  
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2vw;
        // background-color: blue;  
        width: 27vw;
        margin: 0px 1vw 5px 1vw;
        }
    ${media.desktop}{
        // background-color: violet;  
        width: 24vw;
        margin: 0px 1vw 1.3vh 1vw;
        }
    ${media.desktopLg}{
        // background-color: green;  
        width: 25vw;
        margin: 0px 0.8vw 1.4vh 0.8vw;
    }
`

// IMAGENES
export const ImgSubNivs = styled.img`
    border: solid 2px #23AAAF;
    width: 29vw; 
    height: 17vh; 
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    ${media.min}{ 
        width: 29vw; 
        height: 20vh; 
        }
    ${media.tablet}{
        width: 25vw; 
        height: 23vh; 
    }
    ${media.desktop}{
        width: 20vw; 
        height: 23.5vh; 
        }
    ${media.desktopLg}{
        width: 18.5vw; 
        height: 24vh;  
        }
`

// DESCRIPTION
export const DesSubNivs = styled.p`
    font-size: 3.5vw;
    font-weight: 600;
    color: #000000;
    text-decoration: none;
    margin: -2vh 0px 0px 0px;
    cursor: pointer;
    margin: ${props => props.margin};

    ${media.min}{
        font-size: 2.6vw;
        }
    ${media.tablet}{
        font-size: 1.8vw;
    }
    ${media.desktop}{
        font-size: 1.5vw;
        }
    ${media.desktopLg}{
        font-size: 1.4vw;
        }
`

// FIN NIVEL 1, NIVEL 2 Y NIVEL 3

// ------ FIN SUBITIZACIÓN CARATULAS ------

// ------ ESTIMACIÓN Y NOCIÓN DE LA CANTIDAD CARATULAS ------

// ESTIMACIÓN DE CANTIDAD

// LINKS
export const LinksEst = styled (Link)`
    width: 30vw;
    height: fit-content;
    text-align: center;
    border-radius: 8px;
    color: white;
    // background-color: #23AAAF;
    // background-color: pink;
    font-size: 2.3vw;
    font-weight: 600;
    text-decoration: none;
    line-height: 50px;
    margin: 0px 1vw 5px 1vw;
    padding: ${props => props.padding};

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    ${media.min}{
        // background-color: orange;  
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2vw;
        // background-color: blue;  
        width: 27vw;
        margin: 0px 1vw 5px 1vw;
        }
    ${media.desktop}{
        // background-color: violet;  
        width: 24vw;
        margin: 0px 1vw 1.3vh 1vw;
        }
    ${media.desktopLg}{
        // background-color: green;  
        width: 25vw;
        margin: 0px 0.8vw 1.4vh 0.8vw;
    }
`

// IMAGENES
export const ImgEst = styled.img`
    border: solid 2px #23AAAF;
    width: 29vw; 
    height: 17vh; 
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    ${media.min}{ 
        width: 29vw; 
        height: 20vh; 
        }
    ${media.tablet}{
        width: 25vw; 
        height: 23vh; 
    }
    ${media.desktop}{
        width: 20vw; 
        height: 23.5vh; 
        }
    ${media.desktopLg}{
        width: 18.5vw; 
        height: 24vh;  
        }
`

// DESCRIPTION
export const DesEst = styled.p`
    font-size: 3.5vw;
    font-weight: 600;
    color: #000000;
    text-decoration: none;
    word-break: word-break;
    margin: -2vh 0px 0px 0px;
    cursor: pointer;
    margin: ${props => props.margin};

    ${media.min}{
        font-size: 2.6vw;
        }
    ${media.tablet}{
        font-size: 1.8vw;
    }
    ${media.desktop}{
        font-size: 1.5vw;
        }
    ${media.desktopLg}{
        font-size: 1.4vw;
        }
`

// ------ FIN ESTIMACIÓN Y NOCIÓN DE LA CANTIDAD CARATULAS ------

// ------ BASE 10 CARATULAS ------

// TODOS

// LINKS
export const SubButtonBase = styled.button`
    width: 30vw;
    height: 15vh;
    text-align: center;
    border: 1px solid #23AAAF;
    border-radius: 8px;
    color: white;
    background-color: #23AAAF;
    margin: 0px 1vw 1.8vh 1vw;
    padding: ${props => props.padding};

    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;

    ${media.min}{
        width: 26vw;
        height: 18vh;
        }
    ${media.tablet}{
        width: 21vw;
        height: 19vh;
        margin: 0px 1vw 3vh 1vw;
        }
    ${media.desktop}{
        width: 18vw;
        height: 20vh;
        }
    ${media.desktopLg}{
        width: 18vw;
        height: 23vh;
        margin: 0vh 1vw 5vh 2.5vw;
    }
`

export const LinkBase = styled (Link)`
    width: fit-content;
    text-align: center;
    color: white;
    font-size: 3.4vw;
    font-weight: 600;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    justify-content: center; 
    flex-wrap: wrap;
    margin:${props => props.m};

    ${media.min}{
        // color: orange;  
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // color: blue;  
        font-size: 2vw;
        }
    ${media.desktop}{
        // color: violet;  
        font-size: 1.9vw;
        }
    ${media.desktopLg}{
        // color: green;  
        font-size: 2vw;
    }
`

// ------ FIN BASE 10 CARATULAS ------


// ------ CALCULO CARATULAS ------

export const LinksCalculo = styled (Link)`
    color: white;
    background-color: #23AAAF;
    width: 30vw;
    height: 15vh;
    text-align: center;
    font-size: 3.4vw;
    font-weight: 600;
    text-decoration: none;
    margin: 0vh 1vw 3.5vh 1vw;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center; 
    flex-wrap: wrap;

    ${media.min}{
        // color: orange;  
        width: 26vw;
        height: 18vh;
        font-size: 3.2vw;
        }
    ${media.tablet}{
        // color: blue;  
        width: 22vw;
        height: 21vh;
        font-size: 2.3vw;
        // margin: 0px 1vw 3vh 1vw;
        }
    ${media.desktop}{
        // color: violet;  
        width: 20vw;
        height: 21vh;
        font-size: 2vw;
        }
    ${media.desktopLg}{
        // color: green;  
        width: 18vw;
        height: 23vh;
        font-size: 2vw;
        // margin: 0vh 1vw 5vh 2.5vw;
    }
`
// ------ FIN CALCULO CARATULAS ------


// ------ LECTURA Y ESCRITURA DE NÚMEROS CARATULAS ------

// LECTURA

export const FlexLecEsc = styled.div`
    display: flex; 
    justify-content: flex-start;
    align-items: center; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.w};
    height: ${props => props.h};
    background-color: ${props => props.color};

    ${media.min}{
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        width: ${props => props.wt};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        width: ${props => props.wd};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        }
`

export const InputLecEsc = styled.input`
    background-color: pink;  //#23AAAF
    width: 0px;
    height: 0px;

    // opacity: 0;
    display: none;
`

export const LinksLec = styled (Link)`
    color: black;
    text-align: center;
    font-size: 3.4vw;
    font-weight: 600;
    text-decoration: none;
    margin: 0 auto;
    border-radius: 10px;

    ${media.min}{
        // color: orange;  
        font-size: 2.7vw;
        }
    ${media.tablet}{
        // color: blue;  
        font-size: 2.2vw;
        }
    ${media.desktop}{
        // color: violet;  
        font-size: 2vw;
        }
    ${media.desktopLg}{
        // color: green;  
        font-size: 1.8vw;
    }
`

export const ButtonLec = styled.button`
    background-color: transparent; //brown
    width: 30vw;
    border-radius: 10px;
    border: 0;
    color:white;
    font-weight: 600;
    font-size: 16px;
    margin: 0px 1vw 2vw 1vw;

    display: flex;
    flex-direction: column;

    ${media.min}{
        // color: orange;  
        width: 28vw;
        }
    ${media.tablet}{
        // color: blue;  
        width: 25vw;
        }
    ${media.desktop}{
        // color: violet;  
        width: 23vw;
        }
    ${media.desktopLg}{
        // color: green;  
        width: 20vw;
    }
`

export const ContainerLec = styled (Link)`
    background-color: #23AAAF;
    width: 30vw;
    height: 15vh;
    border-radius: 10px;
    margin: 0 auto;

    ${media.min}{
        // color: orange;  
        width: 27vw;
        height: 17vh;
        }
    ${media.tablet}{
        // color: blue;  
        width: 22vw;
        height: 18vh;
        }
    ${media.desktop}{
        // color: violet;  
        width: 20vw;
        height: 21vh;
        }
    ${media.desktopLg}{
        // color: green;  
        width: 17vw;
        height: 21vh;
    }
`

// ESCRITURA

export const ButtonEsc = styled.button`
    background-color: transparent; // brown
    width: 30vw;
    border-radius: 10px;
    border: 0;
    color:white;
    font-weight: 600;
    font-size: 16px;
    margin: 0px 1vw 2vw 1vw;

    display: flex;
    flex-direction: column;

    ${media.min}{
        // color: orange;  
        width: 28vw;
        }
    ${media.tablet}{
        // color: blue;  
        width: 25vw;
        }
    ${media.desktop}{
        // color: violet;  
        width: 23vw;
        margin: 0px 1vw 1vw 1vw;
        }
    ${media.desktopLg}{
        // color: green;  
        width: 20vw;
        margin: 0px 1vw 1vw 1vw;
    }
`

export const LinksEsc = styled (Link)`
    color: black;
    text-align: center;
    font-size: 3.4vw;
    font-weight: 600;
    text-decoration: none;
    margin: 0 auto;
    border-radius: 10px;

    ${media.min}{
        // color: orange;  
        font-size: 2.7vw;
        }
    ${media.tablet}{
        // color: blue;  
        font-size: 2.2vw;
        }
    ${media.desktop}{
        // color: violet;  
        font-size: 2vw;
        }
    ${media.desktopLg}{
        // color: green;  
        font-size: 1.6vw;
    }
`

export const ContainerEsc = styled (Link)`
    background-color: #23AAAF;
    width: 28vw;
    height: 13vh;
    border-radius: 10px;
    margin: 0 auto;

    ${media.min}{
        // color: orange;    
        width: 24vw;
        height: 14vh;
        }
    ${media.tablet}{
        // color: blue;  
        width: 19vw;
        height: 16vh;
        }
    ${media.desktop}{
        // color: violet;  
        width: 16vw;
        height: 17vh;
        }
    ${media.desktopLg}{
        // color: green;  
        width: 15vw;
        height: 17vh;
    }
`


// ------ FIN LECTURA Y ESCRITURA DE NÚMEROS CARATULAS ------


// ------ JUEGOS ------

// ------ SUBITIZACIÓN --- JUEGOS

// NIVEL 1

// Ya lo había hecho, revisar y traer lo que haga falta

// IMAGENES

export const Img = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin};
    background-color:  ${props => props.bg};
    border-radius:  ${props => props.radius};

    ${media.min}{
        color: ${props => props.colorm};  
        width: ${props => props.wm}; 
        height: ${props => props.hm}; 
        margin: ${props => props.marginm};
        padding: ${props => props.paddingm}; 
        // background-color: orange;  
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        width: ${props => props.wt}; 
        height: ${props => props.ht}; 
        margin: ${props => props.margint};
        padding: ${props => props.paddingt}; 
        // background-color: blue; 
        }
    ${media.desktop}{
        width: ${props => props.wd}; 
        width: ${props => props.wd}; 
        height: ${props => props.hd}; 
        margin: ${props => props.margind};
        padding: ${props => props.paddingd}; 
        // background-color: violet; 
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        width: ${props => props.wlg}; 
        height: ${props => props.hlg}; 
        margin: ${props => props.marginlg};
        padding: ${props => props.paddinglg}; 
        // background-color: green; 
        }
`

// FIN NIVEL 1

// NIVEL 2

// M6 → usa Img ↑
 
// M9 → cual es mayor

// BOTON IMG
export const BtnNumberM9 = styled.button`
    // background-color: #FFDC73; 
    background-color: ${props => props.bgcolor}; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 40vw;
    height: 30vh;
    border-radius: 10px;
    border: 0;
    color: #646464;
    font-size: 11vw;
    font-weight: 600;
    margin: 0px 1vw 2vw 1vw;
    cursor: pointer;
    transition: all 0.5s;

    &:hover{
        transform: scale(1.02);
    }

    ${media.min}{
        // background-color: orange;  
        width: 33vw;
        height: 31vh;
        font-size: 11vw;
        }
    ${media.tablet}{
        // background-color: blue;  
        width: 27vw;
        height: 32vh;
        font-size: 11vw;
        }
    ${media.desktop}{
        // background-color: violet;  
        width: 23vw;
        height: 32vh;
        font-size: 9vw;
        }
    ${media.desktopLg}{
        // background-color: green;  
        width: 21vw;
        height: 33vh;
        font-size: 7vw;
    }
`

export const BotonMano = styled.button`
    background-color: transparent;
    border: none;
`

// FIN NIVEL 2
// ------ FIN SUBITIZACIÓN JUEGOS ------

// ESTIMACIÓN Y NOCIÓN DE CANTIDAD --- JUEGOS

// M16 ------------

// BOTONES SI - NO
export const BtnM16 = styled.button`
    background-color: ${props => props.bgcolor}; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 28vw;
    // height: 10vh;
    border-radius: 10px;
    border: 0;
    color: #000000;
    font-family: 'Nunito';
    font-style: normal;
    font-size: 5vw;
    font-weight: 700;
    margin: ${props => props.m}; 
    text-transform: uppercase;

    // ${media.min}{
    //     // background-color: orange;  
    //     width: 33vw;
    //     height: 31vh;
    //     font-size: 11vw;
    //     }
    ${media.tablet}{
        // background-color: blue;  
        width: 25vw;
        // height: 32vh;
        font-size: 4vw;
        }
    ${media.desktop}{
        // background-color: violet;  
        width: 21vw;
        // height: 32vh;
        font-size: 3.3vw;
        }
    ${media.desktopLg}{
        // background-color: green;  
        width: 18vw;
        // height: 32vh;
        font-size: 2.5vw;
    }
`

// INSTRUCCIONES
export const DescriptionM16 = styled.div`
    // background-color: #23AAAF; 
    color: #23AAAF;
    font-family: Nunito;
    font-size: 5vw;
    font-weight: 600;
    text-align: center;
    margin: 18vh 0vw 12vw 0vw;

    ${media.min}{
        // color: orange;  
        font-size: 5vw;
        margin: 14vh 0vw 11vw 0vw;
        }
    ${media.tablet}{
        // color: blue;  
        font-size: 4vw;
        margin: 11vh 0vw 8vw 0vw;
        }
    ${media.desktop}{
        // color: violet;  
        font-size: 3vw;
        margin: 6vh 0vw 4vw 0vw;
        }
    ${media.desktopLg}{
        // color: green;  
        font-size: 2.5vw;
        margin: 4vh 0vw 3vw 0vw;
    }
`
// FIN M16 ------------

// M17
// INSTRUCCIONES
export const DescriptionEstNoc = styled.div`
    // background-color: #23AAAF; 
    color: #23AAAF;
    font-family: Nunito;
    font-size: 5vw;
    font-weight: 600;
    text-align: center;
    margin: 18vh 0vw 12vw 0vw;

    ${media.min}{
        // color: orange;  
        font-size: 5vw;
        }
    ${media.tablet}{
        // color: blue;  
        font-size: 4vw;
        margin: 12vh 0vw 8vw 0vw;
        }
    ${media.desktop}{
        // color: violet;  
        font-size: 3vw;
        margin: 12vh 0vw 9vw 0vw;
        }
    ${media.desktopLg}{
        // color: green;  
        font-size: 2.5vw;
        margin: 12vh 0vw 7vw 0vw;
    }
`
// FIN M17

// M18

// INSTRUCCIONES
export const DescriptionM18 = styled.div`
    // background-color: #23AAAF; 
    color: #23AAAF;
    font-family: Nunito;
    font-size: 4vw;
    font-weight: 600;
    text-align: center;
    margin: 10vh 0vw 14vw 0vw;

    ${media.min}{
        // color: orange;  
        font-size: 3.6vw;
        margin: 10vh 0vw 8vw 0vw;
        }
    ${media.tablet}{
        // color: blue;  
        font-size: 3vw;
        margin: 10vh 0vw 5vw 0vw;
        }
    ${media.desktop}{
        // color: violet;  
        font-size: 2.5vw;
        margin: 10vh 0vw 4vw 0vw;
        }
    ${media.desktopLg}{
        // color: green;  
        font-size: 2.5vw;
        margin: 8vh 0vw 4vw 0vw;
    }
`

// BOTONES SI - NO
export const BtnSiNo = styled.button`
    background-color: ${props => props.bgcolor}; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 25vw;
    height: 10vh;
    border-radius: 10px;
    border: 0;
    color: #FFFFFF; 
    font-family: 'Nunito';
    font-style: normal;
    font-size: 6.5vw;
    font-weight: 700;
    margin: 10vh 1.5vw 0vw 1.5vw;

    // ${media.min}{
    //     // background-color: orange;  
    //     width: 33vw;
    //     height: 31vh;
    //     font-size: 11vw;
    //     }
    ${media.tablet}{
        // background-color: blue;  
        width: 22vw;
        // height: 32vh;
        font-size: 4.5vw;
        }
    ${media.desktop}{
        // background-color: violet;  
        width: 18vw;
        // height: 32vh;
        font-size: 3.6vw;
        }
    ${media.desktopLg}{
        // background-color: green;  
        width: 15vw;
        // height: 32vh;
        font-size: 3vw;
    }
`

// FIN M18

// FIN ESTIMACIÓN Y NOCIÓN DE CANTIDAD --- JUEGOS


// CONTEO

// M20

export const NumFlex = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap;
    justify-content: ${props => props.justify};
    // align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    margin: 8vh 0px 11vh 0px;
    height: ${props => props.height};
    // width: 100vw;
    background-color: ${props => props.color};

    // border: 2px solid green;

    ${media.min}{
        margin: 1vh 0px 13vh 0px;
    }
    ${media.tablet}{
        margin: 1vh 0px 4vh 0px;
    }
    ${media.desktop}{
        width: 80vw;
        margin: 0vh 0vw 4vh 0vw;
    }
    ${media.desktopLg}{
        width: 80vw;
        margin: 0vh 0vw 2vh 0vw;
        }
`

export const Btn = styled.div`
    margin: -5vh 0px 0px 70vw;
    ${media.tablet}{
        margin: 0px 0px 0px 70vw;
        }
    ${media.desktop}{
        margin: 0px 0vw 0vh 70vw;
    }
    ${media.desktopLg}{
        margin: 0px 0vw 0vh 70vw;
    }
`

export const Numbers = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    border-radius: 20px;
    width: 21vw;
    height: 16vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 10vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 3vh 4.2vw 0vh 4.2vw;
    background-color: ${props => props.color};
    cursor: pointer;
    ${media.min}{
        // color: orange;  
        width: 19vw; 
        height: 18vh; 
        }
    ${media.tablet}{
        // color: blue; 
        width: 17vw;
        height: 20vh;
        margin: 3vh 4.2vw 0vh 4.2vw;
        font-size: 9vw;
        }
    ${media.desktop}{
        // color: violet; 
        width: 13vw;
        height: 19vh;
        margin: 3vh 4.2vw 0vh 4.2vw;
        font-size: 8vw;
        }
    ${media.desktopLg}{
        // color: green; 
        width: 12vw;
        height: 19vh;
        margin: 3vh 4.2vw 0vh 4.2vw;
        font-size: 6vw;
        }
`

export const Number = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    color: white;
    border-radius: 20px;
    width: 40vw;
    height: 32vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15vw;
    line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 8vh 4.2vw -5vh 4.2vw;
    background-color: #FFDC73;
    cursor: pointer;

    ${media.min}{
        // background-color: orange;  
        width: 40vw; 
        height: 32vh; 
        margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 29vw; 
        height: 31vh; 
        margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 23vw; 
        height: 29vh; 
        margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 18vw; 
        height: 27vh; 
        margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

export const BtnNum = styled.div`
    margin: 40vh 0px 0px 70vw;
`

export const BtnConteo = styled (BotoneraBottom)`
    color: blue;
`


// ------- M19

// NÚMERO
export const NumM19 = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center; 
    color: #FFFFFF;
    border-radius: 10px;
    width: 10vw;
    height: 7vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 3vw;
    // line-height: 131px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0vh 1vw 0vh 0vw;
    background-color: #FF9B2A;

    ${media.min}{
        // color: orange;  
        width: 9vw; 
        margin: 0vh 3vw 0vh 0vw;
        }
    ${media.tablet}{
        // color: blue; 
        width: 9vw; 
        margin: 0vh 3vw 0vh 0vw;
        }
    ${media.desktop}{
        // color: violet; 
        width: 9vw; 
        margin: 0vh 3vw 0vh 0vw;
        }
    ${media.desktopLg}{
        // color: green; 
        width: 7vw; 
        margin: 0vh 3vw 0vh 0vw;
        }
`

// NÚMERO
export const NumIndM19 = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center; 
    color: #FFFFFF;
    border-radius: 10px;
    width: 19vw;
    height: 15vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 6vw;
    // line-height: 131px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0vh 1vw 0vh 0vw;
    background-color: #FFDC73;

    ${media.min}{
        // color: orange;  
        width: 15vw;
        height: 15vh;
        font-size: 6vw;
        // margin: 0vh 3vw 0vh 0vw;
        }
    ${media.tablet}{
        // color: blue; 
        width: 12vw;
        height: 16vh; 
        font-size: 6vw;
        // margin: 0vh 3vw 0vh 0vw;
        }
    ${media.desktop}{
        // color: violet; 
        width: 10vw;
        height: 19vh;
        font-size: 6vw;
        // margin: 0vh 3vw 0vh 0vw;
        }
    ${media.desktopLg}{
        // color: green; 
        width: 10vw; 
        height: 20vh;
        font-size: 6vw;
        // margin: 0vh 3vw 0vh 0vw;
        }
`

// NÚMERO PARA RELLENO
// NÚMERO
export const NumRellenoM19 = styled.div`
width: 19vw;
height: 15vh;
margin: 0vh 1vw 0vh 0vw;

${media.min}{
    // color: orange;  
    width: 15vw;
    height: 15vh;
    }
${media.tablet}{
    // color: blue; 
    width: 12vw;
    height: 16vh; 
    }
${media.desktop}{
    // color: violet; 
    width: 10vw;
    height: 19vh;
    }
${media.desktopLg}{
    // color: green; 
    width: 10vw; 
    height: 20vh;
    }
`




// ------- FIN M19

// --------- M19 Bis
// NUMBERS
export const NumbersM19B = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    border-radius: 20px;
    // width: 18vw;
    width: 25vw;
    height: 23vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 10vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 3vh 2.5vw 0vh 2.5vw;
    background-color: ${props => props.color};
    // cursor: pointer;
    ${media.min}{
        // background-color: orange;  
        // color: orange;  
        width: 20vw;
        height: 22vh;
        margin: 3vh 3vw 0vh 3vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        // color: blue; 
        width: 20vw;
        height: 25vh;
        margin: 3vh 3vw 0vh 3vw;
        font-size: 8vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        // color: violet; 
        width: 16vw;
        height: 25vh;
        margin: 3vh 3vw 0vh 3vw;
        font-size: 8vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        // color: green; 
        width: 15vw;
        height: 25vh;
        margin: 3vh 3vw 0vh 3vw;
        font-size: 7vw;
        }
`
// --------- M19 Bis


// ------- M20 BIS // PRACTICA 20 ------- 

// export const AyudaVisual = styled.div`
// width: 50vw;
// background: pink;
// margin-left: 100px;
// transition: all 2s;
// `

export const FlexM20 = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    background-color: ${props => props.color};
    position: ${props => props.position};
    left: ${props => props.left};
    bottom: ${props => props.bottom};

    ${media.min}{
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        width: ${props => props.wt};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        width: ${props => props.wd};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        }
`

export const NumerosAyuda = styled.div`
width: 7.5vw;
background: #FF9B2A;
text-align: center;
transition: all 1.5s;

font-size: 4vw;
font-weight: 600;
border-radius: 15px;
margin: 0px 0.5vw 0px 0.5vw;
line-height: 5vh;

${media.min}{
    font-size: 3.5vw;
    }
${media.tablet}{
    width: 6vw;
    font-size: 2.4vw;
    }
${media.desktop}{
    width: 6vw;
    font-size: 2vw;
    }
${media.desktopLg}{
    width: 5vw;
    font-size: 1.6vw;
    }

// &:hover{
//     transform: rotate(-360deg); // -90deg
// }

`

export const ButtonAyuda = styled.button`
    // padding: 5px 12px 5px 12px;
    padding: 1vh 3vw 1vh 3vw;
    border-radius: 50%;
    border:0;
    background: #545454;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color:white;
    font-weight: 600;
    font-size: 5vw;
    margin: 0px 5vw 0px -84vw;
    position: relative;

    ${media.min}{
        padding: 1vh 2.3vw 1vh 2.3vw;
        font-size: 4.3vw;
        }
    ${media.tablet}{
        padding: 0.4vh 1.5vw 0.4vh 1.5vw;
        font-size: 4vw;
        margin: 0px 5vw 0px -72vw;
        }
    ${media.desktop}{
        padding: 0.4vh 1.5vw 0.4vh 1.5vw;
        font-size: 3vw;
        margin: 0px 5vw 0px -71vw;
        }
    ${media.desktopLg}{
        padding: 0.2vh 1vw 0.2vh 1vw;
        font-size: 2.8vw;
        margin: 0px 5vw 0px -62vw;
        }
`

// M21 ----- 

// NUMBERS
export const NumbersM21 = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    border-radius: 20px;
    // width: 18vw;
    min-width: 18vw;
    height: 16vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 10vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 3vh 2.5vw 0vh 2.5vw;
    background-color: ${props => props.color};
    // cursor: pointer;
    ${media.min}{
        // background-color: orange;  
        // color: orange;  
        min-width: 17vw;
        height: 16vh;
        margin: 3vh 3vw 0vh 3vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        // color: blue; 
        min-width: 15vw;
        height: 20vh;
        margin: 3vh 3vw 0vh 3vw;
        font-size: 8vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        // color: violet; 
        min-width: 15vw;
        height: 20vh;
        margin: 3vh 3vw 0vh 3vw;
        font-size: 8vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        // color: green; 
        min-width: 12vw;
        height: 20vh;
        margin: 3vh 3vw 0vh 3vw;
        font-size: 7vw;
        }
`

// CUADRICULA
export const NumFlexM21 = styled.div`
    display: flex; 
    // justify-content: center; 
    align-items: center; 
    flex-wrap: wrap;
    justify-content: ${props => props.justify};
    // align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    // margin: 1vh 0px 13vh 0px;
    height: ${props => props.height};
    width: 100vw;
    background-color: ${props => props.color};

    // border: 2px solid blue;

    ${media.min}{
        width: 72vw;
        // margin: 1vh 0px 13vh 0px;
    }
    ${media.tablet}{
        width: 72vw;
        // margin: 1vh 0px 4vh 0px;
    }
    ${media.desktop}{
        width: 72vw;
        // margin: 0vh 0vw 4vh 0vw;
    }
    ${media.desktopLg}{
        width: 60vw;
        // margin: 15vh 0px 8vh 0px;
        }
`

// NUMEROS SOLOS
export const NumberM21 = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    border-radius: 20px;
    // width: 18vw;
    width: 40vw;
    height: 32vh;
    // margin: -12vh 3vw 38vh 3vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 10vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: ${props => props.color};
    cursor: pointer;
    ${media.min}{
        // background-color: orange;  
        width: 40vw; 
        height: 32vh; 
        // margin: -12vh 3vw 35vh 3vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 29vw; 
        height: 31vh; 
        // margin: -2vh 3vw 40vh 3vw;
        font-size: 8vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 23vw; 
        height: 29vh; 
        // margin: -2vh 3vw 40vh 3vw;
        font-size: 8vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 19vw; 
        height: 28vh; 
        // margin: -2vh 3vw 43vh 3vw;
        font-size: 7vw;
        }
`

// FIN M21 ----- 


// M21 BIS ----- 

// BUTTON DECENAS
export const ButtonNum = styled.button`
    padding: ${props => props.p};
    // border-radius: 10px;
    border: 0px;
    background-color: transparent;
    color: #646464;
    // font-weight: 600;
    // font-size: 30px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 65px;
    margin: ${props => props.margin};

    text-align: start;
`

// BUTTON DECENAS LARGE
export const NumXL = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    border-radius: 20px;
    width: ${props => props.w};
    height: 32vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 22vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: ${props => props.color}; 
    cursor: pointer;
    // text-align: start;

    ${media.min}{
        // background-color: orange;  
        width: ${props => props.wm};
        height: 31vh;  
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 15vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: ${props => props.wt};
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 14vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: ${props => props.wd};
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: ${props => props.wlg};
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

// DIV UNIDADES
export const DivNum = styled.div`
    padding: 0px 0px 0px 40px;
    // border-radius: 10px;
    border: 0px;
    background-color: transparent;
    color: #646464;
    // font-weight: 600;
    // font-size: 30px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    // line-height: 65px;
    margin:${props => props.margin};
`

// NUMEROS DECENAS
export const NumDec = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start; 
    // align-items: center; 
    align-items: center;
    border-radius: 20px;
    width: 40vw;
    height: 32vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 22vw;
    // padding: 0vh 0vw 0vh 2vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: #FF9B2A;
    cursor: pointer;
    padding: 0px 0px 0px 1.5vw;

    ${media.min}{
        // background-color: orange;  
        width: 29vw; 
        height: 31vh;  
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 15vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 24vw; 
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 14vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw; 
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 15vw; 
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

// NUMEROS UNIDADES
export const NumUni = styled.div`
    display: flex;
    align-items: center; 
    justify-content: start;
    padding: 0px 0px 0px 1.5vw;
    color: white;
    border-radius: 20px;
    width: 36vw;
    height: 32vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 22vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh -6vw;
    background-color: #D76970;
    cursor: pointer;

    ${media.min}{
        // color: orange;  
        width: 28vw; 
        height: 31vh; 
        font-size: 15vw;
        }
    ${media.tablet}{
        // color: blue; 
        width: 24vw; 
        height: 31vh; 
        font-size: 14vw;
        }
    ${media.desktop}{
        // color: violet; 
        width: 18vw; 
        height: 29vh; 
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // color: green; 
        width: 15vw; 
        height: 28vh; 
        font-size: 8vw;
        }
`


// FIN M21 BIS ----- 

// -----------------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------------
// PRUEBAS M21 BIS
// -----------------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------------

// NUMEROS DECENAS
export const NumDecP = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    border-radius: 20px;
    width: 40vw;
    height: 32vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15vw;
    line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: #FF9B2A;
    cursor: pointer;

    ${media.min}{
        // background-color: orange;  
        width: 29vw; 
        height: 31vh;  
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 24vw; 
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw; 
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 15vw; 
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`


// -----------------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------------
// FIN PRUEBAS M21 BIS
// -----------------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------------


// M30 ----- 

// NUMEROS
export const Numero = styled.div`
    // font-size: 288px;
    color: #23AAAF;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 40vw;

    ${media.min}{
        // color: orange;  
        font-size: 32vw;
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 25vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 18vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 16vw;
        }
`

// RAYITA
export const Rayita = styled.div`
    width: 42vw;
    height: 4vh;
    margin: -8vh 0px 0px 23vw;
    // background-color: #D76970;
    border-top: 0px;
    border-bottom: 3px solid black;
    border-right: 3px solid black;
    border-left: 3px solid black;

    ${media.min}{
        // background-color: orange;  
        width: 33vw;
        height: 4vh;
        margin: -8vh 0px 0px 19vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 26vw;
        height: 4vh;
        margin: -8vh 0px 0px 16vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw;
        height: 4vh;
        margin: -8vh 0px 0px 12vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 17vw;
        height: 4vh;
        margin: -8vh 0px 0px 10vw;
        }
`

// CUADRICULA
export const NumFlexM30 = styled.div`
    display: flex; 
    flex-wrap: wrap;
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    height: 75vh;
    width: 100vw;
    background-color: ${props => props.color};

    // border: 2px solid blue;

    ${media.min}{
        width: 85vw;
        // margin: 1vh 0px 13vh 0px;
    }
    ${media.tablet}{
        width: 80vw;
        // margin: 1vh 0px 4vh 0px;
    }
    ${media.desktop}{
        width: 75vw;
        // margin: 0vh 0vw 4vh 0vw;
    }
    ${media.desktopLg}{
        width: 65vw;
        // margin: 15vh 0px 8vh 0px;
        }
`

// NUMEROS CUADRICULA
export const NumbersM30 = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    border-radius: 20px;
    width: 21vw;
    height: 16vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 10vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 3vh 4.2vw 0vh 4.2vw;
    background-color: ${props => props.color};
    cursor: pointer;
    ${media.min}{
        // color: orange;  
        width: 19vw; 
        height: 18vh; 
        }
    ${media.tablet}{
        // color: blue; 
        width: 17vw;
        height: 20vh;
        margin: 3vh 4.2vw 0vh 4.2vw;
        font-size: 9vw;
        }
    ${media.desktop}{
        // color: violet; 
        width: 15vw;
        height: 21vh;
        margin: 3vh 4.2vw 0vh 4.2vw;
        font-size: 7vw;
        }
    ${media.desktopLg}{
        // color: green; 
        width: 13vw;
        height: 20vh;
        margin: 3vh 4.2vw 0vh 4.2vw;
        font-size: 6.5vw;
        }
`

// FIN M30 ----- 


// M30 BIS ----- 

// BUTTON AYUDA
export const BtnAyudaM30B = styled.button`
    // padding: 5px 12px 5px 12px;
    padding: 1vh 3vw 1vh 3vw;
    border-radius: 50%;
    border:0;
    background: #545454;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color:white;
    font-weight: 600;
    font-size: 5vw;
    margin: 0px 5vw 0px -84vw;
    position: relative;

    ${media.min}{
        padding: 1vh 2.3vw 1vh 2.3vw;
        font-size: 4.3vw;
        }
    ${media.tablet}{
        padding: 0.4vh 1.5vw 0.4vh 1.5vw;
        font-size: 4vw;
        margin: 0px 5vw 0px -72vw;
        }
    ${media.desktop}{
        padding: 0.4vh 1.5vw 0.4vh 1.5vw;
        font-size: 3vw;
        margin: 0px 5vw 0px -71vw;
        }
    ${media.desktopLg}{
        padding: 0.2vh 1vw 0.2vh 1vw;
        font-size: 2.8vw;
        margin: 0px 5vw 0px -62vw;
        }
`
// NUMEROS AYUDA
export const NumAyudaM30B = styled.div`
width: 7.5vw;
// background: #FF9B2A;
color: #646464;
text-align: center;
transition: all 1.5s;
font-family: 'Nunito';
font-style: normal;
font-size: 4vw;
font-weight: 600;
border-radius: 15px;
margin: 0px 0.5vw 0px 0.5vw;
line-height: 5vh;

${media.min}{
    font-size: 3.5vw;
    }
${media.tablet}{
    width: 6vw;
    font-size: 2.4vw;
    }
${media.desktop}{
    width: 6vw;
    font-size: 2vw;
    }
${media.desktopLg}{
    width: 5vw;
    font-size: 1.6vw;
    }

`

// NUMEROS CENTENAS
export const deededededededede = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    color: white;
    border-radius: 20px;
    width: 37vw;
    height: 29vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15vw;
    line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: #23AAAF;
    cursor: pointer;

    ${media.min}{
        // color: orange;  
        width: 29vw; 
        height: 31vh;  
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.tablet}{
        // color: blue; 
        width: 24vw; 
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.desktop}{
        // color: violet; 
        width: 18vw; 
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // color: green; 
        width: 15vw; 
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

// NUMEROS DECENAS
export const CentenaM30B = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start; 
    // align-items: center; 
    align-items: center;
    border-radius: 20px;
    width: 40vw;
    height: 32vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 22vw;
    // padding: 0vh 0vw 0vh 2vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: #23AAAF;
    cursor: pointer;
    padding: 0px 0px 0px 1.5vw;

    ${media.min}{
        // background-color: orange;  
        width: 29vw; 
        height: 31vh;  
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 15vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 24vw; 
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 14vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw; 
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 15vw; 
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

// NUMEROS DECENAS
export const DecenaM30B = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    color: white;
    border-radius: 20px;
    // width: 40vw;
    // height: 32vh;
    width: 37vw;
    height: 29vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15vw;
    line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: #FF9B2A;
    cursor: pointer;

    ${media.min}{
        // background-color: orange;  
        width: 29vw; 
        height: 31vh;  
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 24vw; 
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw; 
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 15vw; 
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

// NUMEROS UNIDADES
export const UnidadM30B = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    color: white;
    border-radius: 20px;
    width: 37vw;
    height: 29vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15vw;
    line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh -6vw;
    background-color: #D76970;
    cursor: pointer;

    ${media.min}{
        // background-color: orange;  
        width: 32vw; 
        height: 31vh; 
        font-size: 13vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 24vw; 
        height: 31vh; 
        font-size: 13vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw; 
        height: 29vh; 
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 15vw; 
        height: 28vh; 
        font-size: 8vw;
        }
`

// FIN M30 BIS ----- 

// M31 ----- 

// NUMEROS
export const NumeroM31 = styled.div`
    // font-size: 288px;
    color: #655EE8;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 33vw;
    opacity: ${props => props.op};

    ${media.min}{
        // color: orange;  
        font-size: 28vw;
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 24vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 17vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 15vw;
        }
`

// NUMEROS
export const NumeroM31R = styled.div`
    // font-size: 288px;
    color: #655EE8;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 24vw;
    opacity: ${props => props.op};

    ${media.min}{
        color: orange;  
        font-size: 19vw;
        }
    ${media.tablet}{
        color: blue; 
        font-size: 17vw;
        }
    ${media.desktop}{
        color: violet; 
        font-size: 16vw;
        }
    ${media.desktopLg}{
        color: green; 
        font-size: 15vw;
        }
`

// RAYITA
export const RayitaM31 = styled.div`
    width: 53vw;
    height: 4vh;
    margin: -7vh 0px 0px 29vw;
    // background-color: #D76970;
    border-top: 0px;
    border-bottom: 3px solid black;
    border-right: 3px solid black;
    border-left: 3px solid black;

    ${media.min}{
        // background-color: orange;  
        width: 46vw;
        height: 4vh;
        margin: -8vh 0px 0px 23vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 38vw;
        height: 4vh;
        margin: -8vh 0px 0px 20vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 27vw;
        height: 4vh;
        margin: -7vh 0px 0px 15vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 24vw;
        height: 4vh;
        margin: -7vh 0px 0px 13vw;
        }
`

// FLECHA ABAJO
export const FlechaM31 = styled.div`
    width: 3px;
    height: 12vh;
    margin: -3vh 0px 0px -40vw;
    background-color: black;

    ${media.min}{
        // background-color: orange;  
        margin: -2.5vh 0px 0px -33.5vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        margin: -3vh 0px 0px -28.5vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        margin: -3vh 0px 0px -20vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        margin: -2vh 0px 0px -18vw;
        }
`

// FLECHA ABAJO
export const FlechaM31T = styled.div`
    width: 3px;
    height: 14vh;
    background-color: #646464;
    margin: ${props => props.m};

    ${media.min}{
        // background-color: orange;  
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // background-color: blue; 
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // background-color: violet; 
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // background-color: green; 
        margin: ${props => props.mlg};
        }
`

// MIL
export const NumberDiv = styled.div`
    // padding:10px 60px 10px 60px;
    // border-radius: 10px;
    border: 0px;
    background-color: transparent;
    color: #646464;
    // font-weight: 600;
    // font-size: 30px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 65px;
    margin: 0px 0px 0px -36vw;

    ${media.min}{
        // background-color: orange;  
        margin: 0vh 0px 0px -33.5vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        margin: 0vh 0px 0px -27vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        margin: 0vh 0px 0px -20vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        margin: 0vh 0px 0px -18vw;
        }
`

// MIL
export const NumberDivM31 = styled.div`
    border: 0px;
    background-color: transparent;
    color: #646464;
    // font-weight: 600;
    // font-size: 30px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 65px;
    margin: ${props => props.m};
    // margin: 0px 0px 0px -61vw;

    ${media.min}{
        // background-color: orange;  
        margin: ${props => props.mm};
        // margin: -1.5vh 0px 0px -59vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        margin: ${props => props.mt};
        // margin: -1.5vh 0px 0px -52vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        margin: ${props => props.md};
        // margin: -1vh 0px 0px -49vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        margin: ${props => props.mlg};
        // margin: -1vh 0px 0px -42vw;
        }
`

// MIL
export const Bloque = styled.div`
    border: 0px;
    background-color: #D76970;
    line-height: 65px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    // margin: 0px 0px 0px -36vw;
    width: fit-content;
    height: 30vh;

    ${media.min}{
        // background-color: orange; 
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        }
`

// FIN M31 ----- 

// M31 BIS --- PRACTICA MILES ------

// NUMEROS DECENAS
export const MilM31B = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start; 
    // align-items: center; 
    align-items: center;
    border-radius: 20px;
    width: 40vw;
    height: 32vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 22vw;
    // padding: 0vh 0vw 0vh 2vw;
    // line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: #655EE8;
    cursor: pointer;
    padding: 0px 0px 0px 1.5vw;

    ${media.min}{
        // background-color: orange;  
        width: 29vw; 
        height: 31vh;  
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 15vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 24vw; 
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 14vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw; 
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 15vw; 
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

// NUMEROS CENTENAS
export const CentenaM31B = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    color: white;
    border-radius: 20px;
    width: 28vw;
    height: 23vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15vw;
    line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: #23AAAF;
    cursor: pointer;

    ${media.min}{
        color: orange;  
        width: 26vw; 
        height: 29vh;  
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.tablet}{
        color: blue; 
        width: 24vw; 
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.desktop}{
        color: violet; 
        width: 18vw; 
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        color: green; 
        width: 15vw; 
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

// NUMEROS DECENAS
export const DecenaM31B = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    color: white;
    border-radius: 20px;
    // width: 40vw;
    // height: 32vh;
    width: 28vw;
    height: 23vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15vw;
    line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh 0vw;
    background-color: #FF9B2A;
    cursor: pointer;

    ${media.min}{
        // background-color: orange;  
        width: 26vw; 
        height: 29vh;   
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 24vw; 
        height: 31vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 13vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw; 
        height: 29vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 15vw; 
        height: 28vh; 
        // margin: 8vh 4.2vw -5vh 4.2vw;
        font-size: 8vw;
        }
`

// NUMEROS UNIDADES
export const UnidadM31B = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    color: white;
    border-radius: 20px;
    width: 28vw;
    height: 23vh;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 15vw;
    line-height: 131px;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // margin: 0vh 0vw 0vh -6vw;
    background-color: #D76970;
    cursor: pointer;

    ${media.min}{
        // background-color: orange;  
        width: 26vw; 
        height: 29vh;  
        font-size: 13vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 24vw; 
        height: 31vh; 
        font-size: 13vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 18vw; 
        height: 29vh; 
        font-size: 10vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 15vw; 
        height: 28vh; 
        font-size: 8vw;
        }
`

// FIN M31 BIS ----- 


// M32

// NUMEROS
export const NumeroM32 = styled.div`
    // font-size: 288px;
    color: #FFDC73;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 20vw;

    ${media.min}{
        // color: orange;  
        font-size: 19vw;
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 17vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 15vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 13vw;
        }
`

// RAYITA
export const RayitaM32 = styled.div`
    width: 160px;
    height: 4vh;
    // margin: -8vh 0px 0px 15vw;
    margin: ${props => props.m};
    // background-color: #D76970;
    border-top: 0px;
    border-bottom: 3px solid black;
    border-right: 3px solid black;
    border-left: 3px solid black;

    ${media.min}{
        // background-color: orange;  
        width: 30vw;
        height: 4vh;
        // margin: -11vh 0px 0px 23vw;
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // background-color: blue; 
        width: 26vw;
        height: 4vh;
        // margin: -11vh 0px 0px 20vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // background-color: violet; 
        width: 23.5vw;
        height: 4vh;
        // margin: -11vh 0px 0px 15vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // background-color: green; 
        width: 20.5vw;
        height: 4vh;
        // margin: -12vh 0px 0px 13vw;
        margin: ${props => props.mlg};
        }
`

// FLECHA ABAJO
export const FlechaM32 = styled.div`
    width: 3px;
    height: 15vh;
    // margin: -3vh 0px 0px -65vw;
    margin: ${props => props.m};
    background-color: black;

    ${media.min}{
        // background-color: orange;  
        // margin: -2.5vh 0px 0px -61vw;
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // background-color: blue; 
        // margin: -3vh 0px 0px -54.5vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // background-color: violet; 
        // margin: -3vh 0px 0px -49vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // background-color: green; 
        // margin: -2vh 0px 0px -42vw;
        margin: ${props => props.mlg};
        }
`

// MIL
export const NumberDivM32 = styled.div`
    border: 0px;
    background-color: transparent;
    color: #646464;
    // font-weight: 600;
    // font-size: 30px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 65px;
    margin: ${props => props.m};
    // margin: 0px 0px 0px -61vw;

    ${media.min}{
        // background-color: orange;  
        margin: ${props => props.mm};
        // margin: -1.5vh 0px 0px -59vw;
        }
    ${media.tablet}{
        // background-color: blue; 
        margin: ${props => props.mt};
        // margin: -1.5vh 0px 0px -52vw;
        }
    ${media.desktop}{
        // background-color: violet; 
        margin: ${props => props.md};
        // margin: -1vh 0px 0px -49vw;
        }
    ${media.desktopLg}{
        // background-color: green; 
        margin: ${props => props.mlg};
        // margin: -1vh 0px 0px -42vw;
        }
`

// FIN M32

// M32 BIS ---

export const NumeroM32B = styled.div`
    // font-size: 288px;
    // color: #FFDC73;
    color: white;
    background-color: #FFDC73;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 18vw;
    padding: 0px 1vw 0px 1vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    // transform: rotate(-90deg);

    ${media.min}{
        // color: orange;  
        font-size: 17vw;
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 15vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 13vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 11vw;
        }
`

// FIN M32 BIS ---

// EJERCITACIÓN DEL 1 AL 10

// NÚMEROS
export const NumM33 = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 5vw;
    width: 5vw;
    text-align: center;
    padding: 0.5rem;
    color: #FFFFFF;
    background: #23AAAF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

// BOTON PARA LAS IMG
export const BtnImg = styled.div`
    // display: flex;
    // justify-content: center;
    // font-family: 'Nunito';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 5vw;
    width: 5vw;
    // text-align: center;
    padding: 0.5rem;
    // color: #FFFFFF;
    // border: 2px solid black;
    border: 0px;
    background: transparent;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // border-radius: 6px;
    margin: ${props => props.m};

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

// MENOS
export const CirculoMenosM33 = styled.button`
    padding: 2px 7px 2px 7px;
    color: #000000;
    background: #FFFFFF;
    border-radius: 50%;
    border: 0;
    font-weight: 600;
    font-size: 30px;
    margin: 0.3vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

// DIV BARRA LATERAL
export const Lateral = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 10px 10px 5px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    // background: red;

    background: #FCFBFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
`

// DIV BARRA LATERAL
export const Line = styled.div`
    width: 100%;
    height: 2px;
    margin: ${props => props.m};
    background: #646464;
`

// FIN EJERCITACIÓN DEL 1 AL 10 --- M33

// EJERCITACIÓN DEL 1 AL 10 --- M34

// NÚMEROS
export const NumM34 = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 4.4vw;
    width: 3vw;
    text-align: center;
    padding: 0.5rem;
    color: "#23AAAF";
    background: #FFDC73;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    cursor: pointer;

    ${media.min}{
        color: "#23AAAF"; 
        font-size: 3.5vw; 
        }
    ${media.tablet}{
        color: bl"#23AAAF"ue; 
        font-size: 3vw;
        }
    ${media.desktop}{
        color: "#23AAAF"; 
        font-size: 2.5vw;
        }
    ${media.desktopLg}{
        color: "#23AAAF"; 
        font-size: 2vw;
        }
`

// DIV BARRA LATERAL
export const LateralM34 = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 10px 20px 10px 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    // background: red;

    background: #FCFBFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
`

// BOTON PARA LAS IMG
export const BtnImgM34 = styled.div`
    // display: flex;
    // justify-content: center;
    // font-family: 'Nunito';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 5vw;
    // width: 5vw;
    // text-align: center;
    padding: 0.3rem;
    margin: 0.3vh 0.3vw 0.3vh 0.3vw;
    // color: #FFFFFF;
    // border: 2px solid black;
    border: 0px;
    background: #FCFBFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        }
`

// FIN EJERCITACIÓN DEL 1 AL 10 --- M34

// EJERCITACIÓN DEL 1 AL 10 --- M35

// NÚMEROS
export const NumM35 = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 4.4vw;
    width: 4vw;
    text-align: center;
    padding: 0.5rem;
    color: #FFFFFF;
    background: #FFDC73;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    cursor: pointer;

    ${media.min}{
        // color: orange; 
        font-size: 4vw; 
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 3.7vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 3.4vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 3vw;
        }
`

// BOTON PARA LAS IMG
export const Operador = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 5vw;
    // padding: 0.5rem;
    // border: 0px;
    background: transparent;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        }
`

// BOTON PARA LAS IMG
export const BtnM35 = styled.div`
    // display: flex;
    // justify-content: center;
    // font-family: 'Nunito';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 5vw;
    // width: 5vw;
    // text-align: center;
    padding: 0.5rem;
    // color: #FFFFFF;
    // border: 2px solid black;
    background: transparent;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // border-radius: 6px;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

// FIN EJERCITACIÓN DEL 1 AL 10 --- M35

// EJERCITACIÓN DEL 1 AL 10 --- M37

// P CANTIDAD
export const PM37 = styled.p`
    font-size: 3vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    text-align: center;
    margin: ${props => props.m};

    ${media.min}{
        font-size: 2.5vw; 
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        font-size: 1.5vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        font-size: 1.5vw;
        margin: ${props => props.mlg};
        }
`

// NÚMEROS
export const NumM37 = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 4.4vw;
    width: 5vw;
    text-align: center;
    padding: 0.5rem;
    color: #FFFFFF;
    background: #FFDC73;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    cursor: pointer;
    margin: ${props => props.m};

    ${media.min}{
        // color: orange; 
        font-size: 4vw; 
        width: 5vw;
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 3.7vw;
        width: 4.6vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 3.4vw;
        width: 4.2vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 3vw;
        width: 4vw;
        margin: ${props => props.mlg};
        }
`

// FIN EJERCITACIÓN DEL 1 AL 10 --- M37

// EJERCITACIÓN DEL 1 AL 10 --- M39

// INPUT LARGO
export const InputLargo = styled.input`
    width: 22vw;
    height: 5vh;
    text-align: center; 
    font-size: 6vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    border-radius: 10px;
    border: 0px solid transparent;
    // color: white;
    margin: 1vh 0vw 0vh 1vw;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        width: 19vw;
        height: 5vh;
        font-size: 5vw;
    }
    ${media.tablet}{
        // color: blue;
        width: 18vw;
        height: 6vh;
        font-size: 4vw;
    }
    ${media.desktop}{
        // color: violet;
        width: 17vw;
        height: 6vh;
        font-size: 3.3vw;
        }
    ${media.desktopLg}{
        // color: green;
        width: 16vw;
        height: 6vh;
        font-size: 3vw;
        }
`

// INPUT CUADRADO
export const InputCorto = styled.input`
    width: 14vw;
    height: 10vh;
    text-align: center; 
    font-size: 11vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    border-radius: 10px;
    border: 0px solid transparent;
    // color: white;
    // margin: 2vh 3vw 2.5vh 3vw;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        width: 11vw;
        height: 10vh;
        font-size: 10vw;
    }
    ${media.tablet}{
        // color: blue;
        width: 10vw;
        height: 12vh;
        font-size: 9vw;
    }
    ${media.desktop}{
        // color: violet;
        width: 8vw;
        height: 12vh;
        font-size: 7.3vw;
        }
    ${media.desktopLg}{
        // color: green;
        width: 7vw;
        height: 12vh;
        font-size: 6vw;
        }
`

// DIV BARRA LATERAL
export const LateralM39 = styled.div`
    width: 4.5vw;
    height: 42vh;
    padding: 10px 10px 2px 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-content: center;
    align-items: center;
    // background: red;

    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    ${media.min}{
        height: 48vh;
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        height: 51vh;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        height: 54vh;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        height: 54vh;
        margin: ${props => props.mlg};
        }
`

// P CANTIDAD
export const PM39 = styled.p`
    font-size: 3vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // line-height: 49px;
    text-align: center;
    margin: ${props => props.m};

    ${media.min}{
        font-size: 2.5vw; 
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        font-size: 1.5vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        font-size: 1.5vw;
        margin: ${props => props.mlg};
        }
`


// FIN EJERCITACIÓN DEL 1 AL 10 --- M39

// EJERCITACIÓN DEL 1 AL 10 --- M40

// INPUT 
export const InputM40 = styled.input`
    width: 14vw;
    height: 11vh;
    text-align: center; 
    font-size: 11vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    border-radius: 10px;
    border: 0px solid transparent;
    // color: white;
    // margin: 2vh 3vw 2.5vh 3vw;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        width: 11vw;
        height: 11vh;
        font-size: 10vw;
    }
    ${media.tablet}{
        // color: blue;
        width: 10vw;
        height: 14vh;
        font-size: 9vw;
    }
    ${media.desktop}{
        // color: violet;
        width: 8vw;
        height: 14vh;
        font-size: 7.3vw;
        }
    ${media.desktopLg}{
        // color: green;
        width: 7vw;
        height: 14vh;
        font-size: 6vw;
        }
`

// FIN EJERCITACIÓN DEL 1 AL 10 --- M40

// EJERCITACIÓN DEL 1 AL 10 --- M41

// P CANTIDAD
export const PM41 = styled.p`
    font-size: 3vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    text-align: center;
    background: #655EE8;
    margin: ${props => props.m};

    ${media.min}{
        font-size: 2.5vw; 
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        font-size: 1.5vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        font-size: 1.5vw;
        margin: ${props => props.mlg};
        }
`

// P CANTIDAD
export const PRecta = styled.p`
    font-size: 3vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // line-height: 49px;
    text-align: center;
    margin: ${props => props.m};

    ${media.min}{
        font-size: 2.5vw; 
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        font-size: 2vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        font-size: 1.5vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        font-size: 1.5vw;
        margin: ${props => props.mlg};
        }
`

// NÚMEROS
export const NumRecta = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 4.4vw;
    width: 4vw;
    text-align: center;
    padding: 0.5rem;
    color: #FFFFFF;
    background: #655EE8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin: ${props => props.margin};

    ${media.min}{
        // color: orange; 
        font-size: 4vw; 
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 3.7vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 3.4vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 3vw;
        }
`

// BOTON PARA LAS IMG
export const BtnRecta = styled.div`
    // display: flex;
    // justify-content: center;
    // font-family: 'Nunito';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 5vw;
    text-align: center;
    width: 10px;
    min-width: 10px;

    // text-align: center;
    padding: 0.5rem;
    // color: #FFFFFF;
    border: 1px solid transparent;
    // border: 1px solid black;
    // border: 0px;
    background: transparent;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // border-radius: 6px;
    margin: ${props => props.m};

    ${media.min}{
        // background-color: orange;  
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // background-color: blue; 
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // background-color: violet; 
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // background-color: green; 
        margin: ${props => props.mlg};
        font-size: 3vw;
        }
`

// FIN EJERCITACIÓN DEL 1 AL 10 --- M41


// FIN EJERCITACIÓN DEL 1 AL 10

// FIN CONTEO


// BASE 10 -------

// PRESENTACIÓN --

// P CANTIDAD
export const CantB10M43 = styled.p`
    font-size: 4vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    margin: 0px 0px 3vh 0px;

    ${media.min}{
        // color: orange;  
        font-size: 3vw;
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 2.5vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 2vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 2vw;
        }
`

// P CANTIDAD
export const CantMob = styled.p`
    font-size: 3vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    margin: 0px 0px 3vh 0px;

    ${media.min}{
        // color: orange;  
        font-size: 3vw;
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 2.5vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 2vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 2vw;
        }
`

// IMAGEN
export const ImgB10 = styled.img`
    position: relative;
    width: ${props => props.w}; 
    height: ${props => props.h};
    margin: ${props => props.m};
    padding: ${props => props.p};
    border: ${props => props.border};
    border-radius: ${props => props.bradius};
    box-shadow: ${props => props.bs};
    
    transform: ${props => props.tr}; // rotate(-360deg); // -90deg
    z-index:-1;

    ${media.min}{
        // background-color: orange;  
        // border: 2px solid orange;
        width: ${props => props.wm}; 
        height: ${props => props.hm};
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // background-color: blue; 
        // border: 2px solid blue;
        width: ${props => props.wt}; 
        height: ${props => props.ht};
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // background-color: violet; 
        // border: 2px solid violet;
        width: ${props => props.wd}; 
        height: ${props => props.hd};
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // background-color: green; 
        // border: 2px solid green;
        width: ${props => props.wlg}; 
        height: ${props => props.hlg};
        margin: ${props => props.mlg};
        }
    z-index: ${props => props.z};
`

// DIV TABLA
export const TablaB10 = styled.div`
    display: flex;
    flex-direction: ${props => props.direction};
    border: 5px solid #FCF7BC;
    // height: fit-content;
    // max-width: 5vh;
    min-height: 20vh;
    // padding: 1rem;

    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);

    // border-style: solid;
    // border-color: #FCF7BC;
    // border-top-width: 3px;
    // border-bottom-width: 3px;
    border-right-width: ${props => props.br};
    border-left-width: ${props => props.bl};
`

// P UNI, DEC, CENT
export const CantTabla = styled.p`

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 3vw;
    padding: 1rem;
    margin: 0px 0px 0px 0px;

    ${media.min}{
        // color: orange;  
        font-size: 3vw;
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 2.5vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 2vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 2vw;
        }
`

// NUMERO UNI, DEC, CENT, MIL
export const NumCant = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 3vw;
    padding: 0.5rem;
    margin: 0 auto;
    opacity: ${props => props.op};

    ${media.min}{
        // color: orange;  
        font-size: 3vw;
        }
    ${media.tablet}{
        // color: blue; 
        font-size: 2.5vw;
        }
    ${media.desktop}{
        // color: violet; 
        font-size: 2vw;
        }
    ${media.desktopLg}{
        // color: green; 
        font-size: 2vw;
        }
`

// FIN PRESENTACIÓN --

// CONSTRUCCIÓN -- M44   ------------------------------------------- M44

// MOBILE
export const Mobile = styled.div`
    display: block;
    ${media.min}{
        display: block;
    }
    ${media.tablet}{
        display: block;
        }
    ${media.desktop}{
        display: none;
        }
    ${media.desktopLg}{
        display: none;
        }
`   

// DESKTOP
export const Desktop = styled.div`
    display: none;
    ${media.min}{
        display: none;
    }
    ${media.tablet}{
        display: none;
        }
    ${media.desktop}{
        display: block;
        }
    ${media.desktopLg}{
        display: block;
        }
`  

// --------------- COMPONENTES DE LA TABLA -----------------

// DIV TABLA
export const TablaM44 = styled.div`
display: flex;
flex-direction: ${props => props.direction};
border: 5px solid #FCF7BC;
min-height: 11vh;
max-height: 12vh;
box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
border-right-width: ${props => props.br};
border-left-width: ${props => props.bl};

${media.min}{
    // min-height: 15vh;
    // max-height: 15vh;
    }
${media.tablet}{
    min-height: 15vh;
    max-height: 15vh;
    }
${media.desktop}{
    min-height: 15vh;
    max-height: 15vh;
    }
${media.desktopLg}{
    min-height: 15vh;
    max-height: 15vh;   
    }
`

// NÚMEROS
export const NumM44 = styled.div`
    display: flex;
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 5vw;
    min-width: 5vw;
    text-align:'center';
    padding: 0.5rem;
    background: #FFDC73;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

export const InputM44 = styled.input`
    width: 40px;
    height: 40px;
    text-align: center; 
    font-size: 3.5vw;
    font-family: 'Nunito';
    font-style: normal;
    border: 0px solid transparent;
    &:focus{
        outline: none;
    }
    // &::-webkit-outer-spin-button,
    // -webkit-inner-spin-button  {
    //     -webkit-appearance: none;
    //     margin: 0;
    // }

    ${media.min}{
    }
    ${media.tablet}{
        }
    ${media.desktop}{
        font-size: 2vw;
        }
    ${media.desktopLg}{
        font-size: 2vw;
        }
`
// P UNI, DEC, CENT
export const CantTablaM44 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 3vw;
    padding: 0.2rem;
    margin: 0px 0px 0px 0px;
    text-align: center;
    // background-color: red; 

    ${media.min}{
        // padding: 0.3rem;
        // color: orange;
    }
    ${media.tablet}{
        // color: blue;
        }
    ${media.desktop}{
        // color: violet;
        }
    ${media.desktopLg}{
        // color: green;
        padding: 0.5rem;
        font-size: 2vw;
        }
`

// --------------- FIN COMPONENTES DE LA TABLA -----------------


// --------------- COMPONENTE DEL CUADRO DE LAS IMAGENES -----------------

// CONTENDOR FLEX DE IMAGENES 
export const ImgFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    height: ${props => props.height};
    min-height: ${props => props.minh};
    max-height: ${props => props.maxh};
    min-width: 25%;
    max-width: ${props => props.maxw};
    margin: ${props => props.m};
    padding: ${props => props.p};
    background-color: ${props => props.bgcolor};
    border: ${props => props.br};

    ${media.min}{
        // background-color: orange;  
        min-height: ${props => props.minhm};
        max-height: ${props => props.maxhm};
        }
    ${media.tablet}{
        // background-color: blue; 
        min-height: ${props => props.minht};
        max-height: ${props => props.maxht};
        }
    ${media.desktop}{
        // background-color: violet; 
        min-height: ${props => props.minhd};
        max-height: ${props => props.maxhd};
        }
    ${media.desktopLg}{
        // background-color: green; 
        min-height: ${props => props.minhlg};
        max-height: ${props => props.maxhlg};
        }
`

// SEPARADOR
export const Separador = styled.div`
    width: ${props => props.w};
    height: 0.3vh;
    background-color: black;
    margin: 1.5vh 0px 1.5vh 0px;
    opacity: ${props => props.op};

    ${media.min}{
        width: ${props => props.wm};
    }
    ${media.tablet}{
        width: ${props => props.wt};
        }
    ${media.desktop}{
        width: ${props => props.wd};
        }
    ${media.desktopLg}{
        width: ${props => props.wlg};
        }
`

// UNIDAD, DECENA, CENTENA, MILES DEL CUADRO DE IMAGENES
export const CantCuadroM44 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin: 0px;
`
// BOTON DE IMAGEN
export const ButtonM44 = styled.button`
    background: transparent;
    border: 0px solid transparent;
    width: 50%;
    min-width: ${props => props.minw};
    min-height: ${props => props.minh};
    margin: ${props => props.margin};
    cursor: pointer;
`

// --------------- FIN COMPONENTE DEL CUADRO DE LAS IMAGENES -----------------



 
// --------------- BOTON SIGUIENE -----------------

// BOTON AVANZAR
export const AvanzarBtn = styled.button`
    border: 0; 
    background: transparent;
    // background: linear-gradient(116.81deg, #23AAAF 43.58%, #655EE8 100%);
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin:${props => props.margin};
    transition: all 0.2s;
    &:hover{
        transform: scale(1.1)
    } 
`

// IMG AVANZAR
export const AvanzarImg = styled.img`
    width: 50px;
    height: 40px;
`

// --------------- FIN BOTON SIGUIENE -----------------



// FIN CONSTRUCCIÓN --


// INTERPRETACIÓN -- M44   ------------------------------------------- M45

// --------------- COMPONENTES DE LA TABLA -----------------

// DIV TABLA
export const TablaM45 = styled.div`
display: flex;
flex-direction: ${props => props.direction};
border: 5px solid #FCF7BC;
min-height: 11vh;
max-height: 12vh;
box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
border-right-width: ${props => props.br};
border-left-width: ${props => props.bl};

${media.min}{
    // min-height: 15vh;
    // max-height: 15vh;
    }
${media.tablet}{
    min-height: 15vh;
    max-height: 15vh;
    }
${media.desktop}{
    min-height: 15vh;
    max-height: 15vh;
    }
${media.desktopLg}{
    min-height: 15vh;
    max-height: 15vh;   
    }
`

// NÚMEROS
export const NumM45 = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 5vw;
    padding: 0.5rem;
    background: #FFDC73;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

export const InputM45 = styled.input`
    width: 40px;
    height: 40px;
    text-align: center; 
    font-size: 3.5vw;
    font-family: 'Nunito';
    font-style: normal;
    border: 0px solid transparent;
    &:focus{
        outline: none;
    }
    // &::-webkit-outer-spin-button,
    // -webkit-inner-spin-button  {
    //     -webkit-appearance: none;
    //     margin: 0;
    // }

    ${media.min}{
    }
    ${media.tablet}{
        }
    ${media.desktop}{
        font-size: 2vw;
        }
    ${media.desktopLg}{
        font-size: 2vw;
        }
`

// P UNI, DEC, CENT
export const CantTablaM45 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 3vw;
    padding: 0.2rem;
    margin: 0px 0px 0px 0px;
    text-align: center;
    // background-color: red; 

    ${media.min}{
        // color: orange;
        // padding: 0.3rem;
    }
    ${media.tablet}{
        // color: blue;
        }
    ${media.desktop}{
        // color: violet;
        }
    ${media.desktopLg}{
        // color: green;
        padding: 0.5rem;
        font-size: 2vw;
        }
`

// --------------- FIN COMPONENTES DE LA TABLA -----------------

// --------------- COMPONENTES DE AGREGAR Y BORRAR IMGS -----------------

// DIV DE LA PALABRA UNIDAD, DECENA, CENTENA
export const DivP = styled.div`
        width: fit-content;
        height: fit-content;

        border: 2px solid violet;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

// DIV DEL BOTON PARA AGREGAR IMGS
export const DivA = styled.div`
    width: fit-content;
    height: 35px;

    border: 2px solid violet;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

// DIV DEL BOTON PARA BORRAR
export const DivB = styled.div`
    width: fit-content;
    height: 15px;

    border: 2px solid violet;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

// --------------- FIN COMPONENTES DE AGREGAR Y BORRAR IMGS -----------------



// FIN INTERPRETACIÓN -- M44   ------------------------------------------- M45

// -- M46   ------------------------------------------- M46

// UNIDAD, DECENA, CENTENA, MILES DEL CUADRO DE IMAGENES
export const CantCuadroM46 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 2vw;
    margin: 0px;

    ${media.min}{
        font-size: 2vw;
    }
    ${media.tablet}{
        font-size: 2vw;
        }
    ${media.desktop}{
        font-size: 1.5vw;
        }
    ${media.desktopLg}{
        font-size: 1.5vw;
        }
`

// NUMEROS DE COMPARACIÓN
export const ComparacionM46 = styled.p`
    min-width: 14vw;
    padding: 1vh 1vw 1vh 1vw;
    background-color: #FFDC73;
    // background-color: red;
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 5vw;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: ${props => props.m};

    ${media.min}{
        font-size: 4vw;
        min-width: 14vw;
        padding: 1vh 1vw 1vh 1vw;
    }
    ${media.tablet}{
        font-size: 3.5vw;
        min-width: 10vw;
        padding: 1vh 1vw 1vh 1vw;
        }
    ${media.desktop}{
        font-size: 3vw;
        min-width: 8vw;
        padding: 0.5vw;
        }
    ${media.desktopLg}{
        min-width: 5vw;
        font-size: 2.5vw;
        padding: 0.5vw;
        }

`

// COMPARADOR
export const ComparadorM46 = styled.button`
    width: 6vw;
    // padding: 1vh 1vw 1vh 1vw;
    // background-color: red;
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 5vw;
    color: #000000;
    border: 0px;
    border-radius: 50%;
    background: #F1F0F0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: ${props => props.m};

    ${media.min}{
        // color: orange;
        font-size: 4vw;
    }
    ${media.tablet}{
        // color: blue;
        font-size: 3.3vw;
        }
    ${media.desktop}{
        // color: violet;
        font-size: 3vw;
        }
    ${media.desktopLg}{
        // color: green;
        width: 5vw;
        font-size: 2vw;
        padding: 0.5vw;
        }
`


// -- FIN M46   ------------------------------------------- M46


// -- M47   ------------------------------------------- M47

// INPUT 
export const InputM47 = styled.input`
    width: 12vw;
    height: 8vh;
    text-align: center; 
    font-size: 4vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    color: white;
    border-radius: 10px;
    border: 0px solid transparent;
    background: #FFDC73;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 2vh 3vw 2.5vh 3vw;

    &:focus{
        outline: none;
    }

    ${media.min}{
        width: 10vw;
        height: 9vh;
    }
    ${media.tablet}{
        width: 10vw;
        height: 10vh;
        }
    ${media.desktop}{
        font-size: 2vw;
        width: 5vw;
        height: 8vh;
        }
    ${media.desktopLg}{
        font-size: 2vw;
        width: 5vw;
        height: 8vh;
        }
`

export const ValueM47 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12vw;
    height: 8vh;
    text-align: center; 
    font-size: 4vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    color: white;
    border-radius: 10px;
    border: 0px solid transparent;
    background: #FFDC73;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 2vh 3vw 2.5vh 3vw;

    ${media.min}{
        width: 10vw;
        height: 9vh;
    }
    ${media.tablet}{
        width: 10vw;
        height: 10vh;
        }
    ${media.desktop}{
        font-size: 2vw;
        width: 5vw;
        height: 8vh;
        }
    ${media.desktopLg}{
        font-size: 2vw;
        width: 5vw;
        height: 8vh;
        }
`

export const TextM47 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 2vw;
    margin: -5px;
    color: #646464;

    ${media.min}{
        font-size: 1.5vw;
    }
    ${media.tablet}{
        font-size: 1.7vw;
        }
    ${media.desktop}{
        font-size: 1vw;
        }
    ${media.desktopLg}{
        font-size: 1vw;
        }
`

export const CantCuadroM47 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    margin: 0px;

    ${media.min}{
        font-size: 1.5vw;
    }
    ${media.tablet}{
        font-size: 1.5vw;
        }
    ${media.desktop}{
        font-size: 1.5vw;
        }
    ${media.desktopLg}{
        font-size: 1.5vw;
        }
`


// -- FIN M47   ------------------------------------------- M47

// FIN BASE 10 --------

// ------ FIN JUEGOS ------

// CALCULO

// -- FIN M49   ------------------------------------------- M49

export const DivFlex = styled.div`
    display: ${props => props.flex}; 
    justify-content: ${props => props.justify};
    align-items: ${props => props.align}; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    max-width: ${props => props.maxw};
    min-width: ${props => props.minw};
    max-height: ${props => props.maxh};
    min-height: ${props => props.minh};
    background-color: ${props => props.color};
    position: ${props => props.position};
    left: ${props => props.left};
    bottom: ${props => props.bottom};
    border: ${props => props.border};
    border-radius: ${props => props.bradius};
    opacity: ${props => props.op};
    box-shadow: ${props => props.bs}; // 0px 4px 4px rgba(0, 0, 0, 0.25);

    ${media.min}{
        margin: ${props => props.mm};
        padding: ${props => props.pm};
        width: ${props => props.wm};
        height: ${props => props.hm};
        max-width: ${props => props.maxwm};
        min-width: ${props => props.minwm};
        max-height: ${props => props.maxhm};
        min-height: ${props => props.minhm};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        padding: ${props => props.pt};
        width: ${props => props.wt};
        height: ${props => props.ht};
        max-width: ${props => props.maxwt};
        min-width: ${props => props.minwt};
        max-height: ${props => props.maxht};
        min-height: ${props => props.minht};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        padding: ${props => props.pd};
        width: ${props => props.wd};
        height: ${props => props.hd};
        max-width: ${props => props.maxwd};
        min-width: ${props => props.minwd};
        max-height: ${props => props.maxhd};
        min-height: ${props => props.minhd};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        padding: ${props => props.plg};
        width: ${props => props.wlg};
        height: ${props => props.hlg};
        max-width: ${props => props.maxwlg};
        min-width: ${props => props.minwlg};
        max-height: ${props => props.maxhlg};
        min-height: ${props => props.minhlg};
        }
`

export const TextM49 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-style: normal;
    font-weight: 700;
    font-size: 13vw;
    margin: 0.2vw;

    // ${media.min}{
    //     font-size: 1.5vw;
    // }
    ${media.tablet}{
        font-size: 13vw;
        }
    ${media.desktop}{
        font-size: 9vw;
        }
    ${media.desktopLg}{
        font-size: 6.5vw;
        }
`

// INPUT 
export const InputM49 = styled.input`
    width: 20vw;
    height: 12.5vh;
    text-align: center; 
    font-size: 13vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // color: white;
    border-radius: 20px;
    border: 0px solid transparent;
    // margin: 2vh 3vw 2.5vh 3vw;

    background: #FCFBFB;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:focus{
        outline: none;
    }

    // ${media.min}{
    //     width: 10vw;
    //     height: 9vh;
    // }
    ${media.tablet}{
        font-size: 11vw;
        width: 14vw;
        height: 16vh;
    }
    ${media.desktop}{
        font-size: 9vw;
        width: 12vw;
        height: 18vh;
        }
    ${media.desktopLg}{
        font-size: 6.5vw;
        width: 10vw;
        height: 18vh;
        }
`

// BUTTONS TRANSPARENT
export const ButtonM49 = styled.button`
    width: 6vw;
    // padding: 1vh 1vw 1vh 1vw;
    background-color: transparent;
    border: 0px solid transparent;
    text-align: center;
    font-size: 5vw;
    margin: ${props => props.m};
    padding: ${props => props.p};

    // ${media.min}{
    //     font-size: 4vw;
    // }
    // ${media.tablet}{
    //     font-size: 3.3vw;
    //     }
    // ${media.desktop}{
    //     font-size: 3vw;
    //     }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        }
`

// SIDEBAR IMG
export const DivNoFlex = styled.div`
    display: ${props => props.flex}; 
    justify-content: ${props => props.justify};
    align-items: ${props => props.align}; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};

    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    max-width: ${props => props.maxw};
    min-width: ${props => props.minw};
    max-height: ${props => props.maxh};
    min-height: ${props => props.minh};
    background-color: ${props => props.color};
    position: ${props => props.position};
    left: ${props => props.left};
    bottom: ${props => props.bottom};
    border: ${props => props.border};
    opacity: ${props => props.op};

    background: #FCFBFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    ${media.min}{
        margin: ${props => props.mm};
        width: ${props => props.wm};
        height: ${props => props.hm};
        max-width: ${props => props.maxwm};
        min-width: ${props => props.minwm};
        max-height: ${props => props.maxhm};
        min-height: ${props => props.minhm};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        width: ${props => props.wt};
        height: ${props => props.ht};
        max-width: ${props => props.maxwt};
        min-width: ${props => props.minwt};
        max-height: ${props => props.maxht};
        min-height: ${props => props.minht};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        width: ${props => props.wd};
        height: ${props => props.ht};
        max-width: ${props => props.maxwd};
        min-width: ${props => props.minwd};
        max-height: ${props => props.maxhd};
        min-height: ${props => props.minhd};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        height: ${props => props.hlg};
        max-width: ${props => props.maxwlg};
        min-width: ${props => props.minwlg};
        max-height: ${props => props.maxhlg};
        min-height: ${props => props.minhlg};
        }
`


// -- FIN M49   ------------------------------------------- M49


// -- M50   ------------------------------------------- M50

// TEXTO DE SUMA
export const TextM50 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-style: normal;
    font-weight: 700;
    font-size: 11vw;
    margin: 0.2vw;
    color: ${props => props.color};

    // ${media.min}{
    //     font-size: 1.5vw;
    // }
    // ${media.tablet}{
    //     font-size: 13vw;
    //     }
    ${media.desktop}{
        font-size: 8vw;
        }
    ${media.desktopLg}{
        font-size: 6.5vw;
        }
`

// INPUT 
export const InputM50 = styled.input`
    width: 16vw;
    height: 12.5vh;
    text-align: center; 
    font-size: 11vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // color: white;
    border-radius: 20px;
    border: 0px solid transparent;
    // margin: 2vh 3vw 2.5vh 3vw;

    background: #FCFBFB;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:focus{
        outline: none;
    }

    // ${media.min}{
    //     width: 10vw;
    //     height: 9vh;
    // }
    ${media.tablet}{
        font-size: 11vw;
        width: 14vw;
        height: 16vh;
    }
    ${media.desktop}{
        font-size: 8vw;
        width: 12vw;
        height: 18vh;
        }
    ${media.desktopLg}{
        font-size: 6.5vw;
        width: 10vw;
        height: 18vh;
        }
`


// -- FIN M50   ------------------------------------------- M50

// -- FIN M54   ------------------------------------------- M54

// TEXTO DE SUMA
export const TextM54 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: bolder;
    font-size: 16vw;
    margin: 0.5vw;
    color: ${props => props.color};

    // ${media.min}{
    //     font-size: 1.5vw;
    // }
    ${media.tablet}{
        font-size: 12vw;
        }
    ${media.desktop}{
        font-size: 8vw;
        }
    ${media.desktopLg}{
        font-size: 7vw;
        }
`

// INPUT 
export const InputM54 = styled.input`
    width: 16vw;
    height: 12.5vh;
    text-align: center; 
    font-size: 11vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // color: white;
    border-radius: 20px;
    border: 0px solid transparent;
    // margin: 2vh 3vw 2.5vh 3vw;

    background: #FCFBFB;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:focus{
        outline: none;
    }

    // ${media.min}{
    //     width: 10vw;
    //     height: 9vh;
    // }
    ${media.tablet}{
        font-size: 11vw;
        width: 14vw;
        height: 16vh;
    }
    ${media.desktop}{
        font-size: 8vw;
        width: 12vw;
        height: 18vh;
        }
    ${media.desktopLg}{
        font-size: 6.5vw;
        width: 10vw;
        height: 18vh;
        }
`


// -- FIN M50   ------------------------------------------- M54


// -- FIN M42   ------------------------------------------- M42

// TEXTO DE SUMA
export const TextM42 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: bolder;
    font-size: 7vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    width: ${props => props.w};
    color: ${props => props.color};

    ${media.min}{
        margin: ${props => props.mm};
    }
    ${media.tablet}{
        font-size: 6.3vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        font-size: 5.8vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        font-size: 5.5vw;
        margin: ${props => props.mlg};
        }
`

// -- FIN M42   ------------------------------------------- M42


// -- M38   ------------------------------------------- M38

// BUTTON
export const ButtonM38 = styled.button`
    width: ${props => props.w};
    height: ${props => props.h};
    margin: ${props => props.m};
    padding: ${props => props.p};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    border: 0;
    background: ${props => props.color};
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: black;
    font-weight: 600;
    font-size: 2vw;
`

// NUMBER
export const NumberM38 = styled.p`
    width: ${props => props.w};
    height: ${props => props.h};
    margin: ${props => props.m};
    padding: ${props => props.p};
    font-size: ${props => props.ft};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};

    ${media.min}{
        font-size: ${props => props.ftm};
    }
    ${media.tablet}{
        font-size: ${props => props.ftt};
        }
    ${media.desktop}{
        font-size: ${props => props.ftd};
        }
    ${media.desktopLg}{
        font-size: ${props => props.ftlg};
        }
`


// -- FIN M38   ------------------------------------------- M38

// -- M87   ------------------------------------------- M87

// DIV LINEAS
export const LineaM87 = styled.div`
    width: ${props => props.w};
    height: ${props => props.h};
    text-align: center; 
    margin: ${props => props.m};
    padding: ${props => props.p};
    background-color: ${props => props.color};
    transform: ${props => props.r}; // rotate(-360deg); // -90deg
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: -1;
`

// NUMBER
export const NumberM87 = styled.p`
    width: 15vw;
    height: 12vh;
    text-align: center; 
    font-size: 11vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border-radius: ${props => props.bradius};
    border: ${props => props.border};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 3;

    ${media.min}{
            // width: 14vw;
            // height: 11vh;
            // color: orange;
        }
        ${media.tablet}{
            font-size: 11vw;
            width: 14vw;
            height: 16vh;
            // color: blue;
        }
        ${media.desktop}{
            font-size: 8vw;
            width: 12vw;
            height: 18vh;
            // color: violet;
            }
        ${media.desktopLg}{
            font-size: 6.5vw;
            width: 8vw;
            height: 18vh;
            // color: green;
            }
`

// NUMBER M87
export const NumberM877 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 12vw;
    text-align: center;
    z-index: 2;

    width: fit-content;
    // padding: 3vh 5vw 3vh 5vw; 
    padding: 30px 55px 30px 55px; 
    color: #FFFFFF;
    background-color: #CF223D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-transform: uppercase;

    ${media.min}{
            // color: orange;
            font-size: 11vw;
        }
        ${media.tablet}{
            // color: blue;
            font-size: 8.5vw;
        }
        ${media.desktop}{
            // color: violet;
            font-size: 6.5vw;
            }
        ${media.desktopLg}{
            // color: green;
            font-size: 6vw;
            }
`

// INPUT 
export const InputM87 = styled.input`
    width: 16vw;
    height: 12.5vh;
    text-align: center; 
    font-size: 11vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // color: white;
    border-radius: ${props => props.bradius};
    border: 0px solid transparent;
    // border: ${props => props.border};
    margin: ${props => props.m};
    z-index: 8;

    background: #FCFBFB;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:focus{
        outline: none;
    }

    // ${media.min}{
    //     width: 10vw;
    //     height: 9vh;
    // }
    ${media.tablet}{
        font-size: 10vw;
        width: 12vw;
        height: 16vh;
    }
    ${media.desktop}{
        font-size: 8vw;
        width: 12vw;
        height: 18vh;
        }
    ${media.desktopLg}{
        font-size: 6.5vw;
        width: 10vw;
        height: 18vh;
        }
`

// -- FIN M87   ------------------------------------------- M87

// -- FIN M87   ------------------------------------------- M87





// -- SUMA - EJERCITACIÓN   ------------------------------------------- SUMA - EJERCITACIÓN

export const ContainerEj = styled.div`
    background-color: ${props => props.color};
    width: ${props => props.w}; 
    height: ${props => props.h};
    margin: ${props => props.m};
    border: ${props => props.border};
    border-radius: ${props => props.bradius};
    z-index: -1;

    ${media.min}{
        // color: orange;  
        // background-color: orange;  
        width: ${props => props.wm}; 
        height: ${props => props.hm};
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // color: blue;  
        // background-color: blue;  
        width: ${props => props.wt}; 
        height: ${props => props.ht};
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // color: violet;  
        // background-color: violet;  
        width: ${props => props.wd}; 
        height: ${props => props.hd};
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // color: green;  
        // background-color: green;  
        width: ${props => props.wlg}; 
        height: ${props => props.hlg};
        margin: ${props => props.mlg};
    }
`

// P
export const PEj = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-weight: ${props => props.fw};
    font-size: ${props => props.fsm};
    font-size: ${props => props.fs};
    margin: ${props => props.m};
    color: ${props => props.color};

    // // text-shadow: ${props => props.texts};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        // color: orange;
        font-size: ${props => props.fsm};
        }
    ${media.tablet}{
        // color: blue;
        font-size: ${props => props.fst};
    }
    ${media.desktop}{
        // color: violet;
        font-size: ${props => props.fsd};
        }
    ${media.desktopLg}{
        // color: green;
        font-size: ${props => props.fslg};
        }
`

// P
export const P2 = styled (Link)`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    color: white;
    border-radius: 3px;
    // border: 1px solid black;
    text-decoration: none;
    background: #23AAAF;
    font-weight: ${props => props.fw};
    font-size: ${props => props.fsm};
    font-size: ${props => props.fs};
    margin: ${props => props.m};
    padding: 0px 2px 0px 2px;
    color: ${props => props.color};

    // // text-shadow: ${props => props.texts};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        // color: orange;
        margin: ${props => props.mm};
        font-size: ${props => props.fsm};
        }
    ${media.tablet}{
        // color: blue;
        margin: ${props => props.mt};
        font-size: ${props => props.fst};
    }
    ${media.desktop}{
        // color: violet;
        margin: ${props => props.md};
        font-size: ${props => props.fsd};
        }
    ${media.desktopLg}{
        // color: green;
        margin: ${props => props.mlg};
        font-size: ${props => props.fslg};
        }
`

// -- FIN SUMA - EJERCITACIÓN   ------------------------------------------- SUMA - EJERCITACIÓN 


// FIN EJERCITACION - CALCULO

// CALCULO --- CALCULO MENTAL

// NUMBER
export const NumSumaM55 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // width: 15vw;
    width: ${props => props.w};
    // height: 12vh;
    text-align: center; 
    font-size: 15vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border-radius: ${props => props.bradius};
    // text-shadow: ${props => props.texts};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: 13vw;
            // color: orange;
        }
    ${media.tablet}{
        font-size: 12vw;
        // color: blue;
    }
    ${media.desktop}{
        font-size: 11vw;
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: 11vw;
        // color: green;
        }
`

// NUMBER
export const NumResultM55 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 15vw;
    height: 12vh;
    text-align: center; 
    font-size: 11vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border-radius: ${props => props.bradius};

    // ${media.min}{
    //         // width: 14vw;
    //         // height: 11vh;
    //         // color: orange;
    //     }
    //     ${media.tablet}{
    //         font-size: 11vw;
    //         width: 14vw;
    //         height: 16vh;
    //         // color: blue;
    //     }
    //     ${media.desktop}{
    //         font-size: 8vw;
    //         width: 12vw;
    //         height: 18vh;
    //         // color: violet;
    //         }
    //     ${media.desktopLg}{
    //         font-size: 6.5vw;
    //         width: 8vw;
    //         height: 18vh;
    //         // color: green;
    //         }
`

// INPUT
export const InputM55 = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // width: 15vw;
    width: ${props => props.w};
    // height: 12vh;
    text-align: center; 
    font-size: 15vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border: 0px;
    border-radius: ${props => props.bradius};
    box-shadow: ${props => props.bs};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    &:focus{
        outline: none;
    }

    ${media.min}{
        font-size: 13vw;
            // color: orange;
        }
    ${media.tablet}{
        font-size: 12vw;
        // color: blue;
    }
    ${media.desktop}{
        font-size: 11vw;
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: 11vw;
        // color: green;
        }
`


// SUMA COMPARTIDO ---------------------------

// BTN AYUDA
export const BtnAyudaCuad = styled.button`
    // padding: 5px 12px 5px 12px;
    border-radius: 50%;
    border:0;
    background: #545454;
    height: 6.5rem;
    width: 6.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color:white;
    font-weight: 600;
    font-size: 5vw;
`

// -- FIN M55   ------------------------------------------- M55

// -- M56   ------------------------------------------- M56

// BOTON PARA LAS IMG
export const BtnImgM56 = styled.div`
    display: flex;
    justify-content: center;
    // font-family: 'Nunito';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 5vw;
    width: 5vw;
    // text-align: center;
    padding: 0.5rem;
    // color: #FFFFFF;
    border: 0px solid transparent;
    // border: 2px solid blue;
    // background: red;
    background: transparent;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    // margin: ${props => props.m};
    margin: 0px 0px 0px 10px;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

// BOTON PARA LAS IMG
export const BtnR = styled.div`
    // display: flex;
    // justify-content: center;
    // font-family: 'Nunito';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 5vw;
    width: 30px;
    height: 30px;
    // text-align: center;
    padding: 0.5rem;
    // color: #FFFFFF;
    border: 0px solid transparent;
    // border: 2px solid blue;
    // background: red;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    margin: ${props => props.m};

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        font-size: 3vw;
        }
`

// NUMBER
export const NumSumaM56 = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // width: 15vw;
    // width: ${props => props.w};
    // height: 12vh;
    text-align: center; 
    font-size: 70px;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border-radius: ${props => props.bradius};
    // text-shadow: ${props => props.texts};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: 90px;
            // color: orange;
        }
    ${media.tablet}{
        font-size: 110px;
        // color: blue;
    }
    ${media.desktop}{
        font-size: 130px;
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: 150px;
        // color: green;
        }
`

// INPUT
export const InputM56 = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 110px;
    height: 110px;
    text-align: center; 
    font-size: 70px;
    // margin: ${props => props.m};
    margin: 0px;
    padding: ${props => props.p};
    color: ${props => props.color};
    // background-color: ${props => props.bgcolor};
    border: 0px;
    // border-radius: ${props => props.bradius};
    // box-shadow: ${props => props.bs};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        font-size: 90px;
        width: 120px;
        height: 120px;
        }
    ${media.tablet}{
        // color: blue;
        font-size: 110px;
        width: 130px;
        height: 130px;
    }
    ${media.desktop}{
        // color: violet;
        font-size: 130px;
        width: 145px;
        height: 145px;
        }
    ${media.desktopLg}{
        // color: green;
        font-size: 150px;
        width: 150px;
        height: 150px;
        }
`

// DIV BARRA LATERAL
export const LateralM56 = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 10px 18px 5px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    // background: red;

    background: #FCFBFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    ${media.min}{
        padding: 10px 18px 5px 8px;
        }
    ${media.tablet}{
        padding: 10px 15px 5px 6px;
    }
    ${media.desktop}{
        padding: 10px 10px 5px 0px;
        }
    ${media.desktopLg}{
        padding: 10px 10px 5px 0px;
        }
`

// -- FIN M56   ------------------------------------------- M56

// -- M71   ------------------------------------------- M71

// DIV BARRA LATERAL
export const LateralM71 = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 55px 10px 55px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    // background: red;

    background: #FCFBFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    ${media.min}{
        padding: 55px 10px 55px 10px;
        }
    ${media.tablet}{
        padding: 55px 10px 55px 10px;
    }
    ${media.desktop}{
        padding: 55px 10px 55px 10px;
        }
    ${media.desktopLg}{
        padding: 55px 10px 55px 10px;
        }
`

// -- FIN M71   ------------------------------------------- M71

// -- M72   ------------------------------------------- M72

// NUMBER
export const PM72 = styled.div`
    display: ${props => props.d};
    justify-content: ${props => props.justify}; 
    align-items: ${props => props.align}; 
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-weight: ${props => props.fw};
    font-size: 1.6vw;
    font-size: ${props => props.fs};
    margin: 1vh 0px 1vh 0px;
    margin: ${props => props.m};
    color: ${props => props.color};

    // // text-shadow: ${props => props.texts};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: ${props => props.fsm};
        // color: orange;
        }
    ${media.tablet}{
        font-size: ${props => props.fst};
        // color: blue;
    }
    ${media.desktop}{
        font-size: ${props => props.fsd};
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: ${props => props.fslg};
        // color: green;
        }
`

// NUMBER
export const P = styled.p`
    display: ${props => props.d};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: ${props => props.fw};
    font-size: ${props => props.fs};
    margin: ${props => props.m};
    color: ${props => props.color};
    
    // // text-shadow: ${props => props.texts};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: ${props => props.fsm};
        // color: orange;
        }
    ${media.tablet}{
        font-size: ${props => props.fst};
        // color: blue;
    }
    ${media.desktop}{
        font-size: ${props => props.fsd};
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: ${props => props.fslg};
        // color: green;
        }
`

//NUMEROS CALCULO 
export const InputM72 = styled.input`
// max-width: 9vw;
// min-width: 9vw;
// min-height: 12vh;
// max-height: 12vh;

width: 9vw;
height: 6vh;
font-family: 'Nunito';
font-size: 5.5vw;
color: ${props => props.color};
margin: ${props => props.m};
font-weight: 700;
text-align: center;
// border: 2px solid black;
border: 0px transparent;
background: #F1F0F0;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;

&:focus{
    outline: none;
}

${media.min}{
    font-size: 5.2vw;
    width: 9vw;
    height: 6vh;
    }
${media.tablet}{
    font-size: 4.5vw;
    width: 7vw;
    height: 7vh;
    }
${media.desktop}{
    font-size: 3.7vw;
    width: 6vw;
    height: 8vh;
    }
${media.desktopLg}{
    font-size: 3.5vw;
    width: 6vw;
    height: 8vh;
    }
`


// -- FIN M72   ------------------------------------------- M72

// -- M73   ------------------------------------------- M73

// BOTON PARA LAS IMG
export const BtnRectaM73 = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 8px;
    min-width: 8px; 

    padding: 0.5rem;
    // border: 1px solid black;
    border: 0px;
    background: transparent;
    margin: ${props => props.m};

    ${media.min}{
        // background-color: orange; 
        width: 8px;
        min-width: 8px; 
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // background-color: blue; 
        margin: ${props => props.mt};
        width: 12px;
        min-width: 12px;
        }
    ${media.desktop}{
        // background-color: violet; 
        margin: ${props => props.md};
        width: 12px;
        min-width: 12px;
        }
    ${media.desktopLg}{
        // background-color: green; 
        margin: ${props => props.mlg};
        font-size: 3vw;
        width: 12px;
        min-width: 12px;
        }
`

// P CANTIDAD
export const PRectaM73 = styled.p`
    font-size: 3.4vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    color: ${props => props.color ? props.color : "#FF9B2A" };
    // line-height: 49px;
    text-align: center;
    margin: ${props => props.m};
    cursor: pointer;
    z-index: 1;

    ${media.min}{
        // color: orange;
        font-size: 2.6vw; 
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // color: blue;
        font-size: 2.3vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // color: violet;
        font-size: 1.8vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // color: green;
        font-size: 1.8vw;
        margin: ${props => props.mlg};
        }
`

// DIV BARRA LATERAL
export const LateralM73 = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 55px 10px 55px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    // background: red;

    background: #FCFBFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    ${media.min}{
        padding: 55px 10px 55px 10px;
        }
    ${media.tablet}{
        padding: 55px 10px 55px 10px;
    }
    ${media.desktop}{
        padding: 55px 10px 55px 10px;
        }
    ${media.desktopLg}{
        padding: 55px 10px 55px 10px;
    }
`

// NUMBER
export const NumSumaM73 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // width: 15vw;
    // width: ${props => props.w};
    // height: 12vh;
    text-align: center; 
    font-size: 60px;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border-radius: ${props => props.bradius};
    // text-shadow: ${props => props.texts};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: 60px;
            // color: orange;
        }
    ${media.tablet}{
        font-size: 65px;
        // color: blue;
    }
    ${media.desktop}{
        font-size: 70px;
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: 70px;
        // color: green;
        }
`

// INPUT
export const InputM73 = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 80px;
    height: 80px;
    text-align: center; 
    font-size: 60px;
    // margin: ${props => props.m};
    margin: 0px;
    padding: ${props => props.p};
    color: ${props => props.color};
    // background-color: ${props => props.bgcolor};
    border: 0px;
    // border-radius: ${props => props.bradius};
    // box-shadow: ${props => props.bs};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        font-size: 60px;
        width: 85px;
        height: 85px;
        }
    ${media.tablet}{
        // color: blue;
        font-size: 65px;
        width: 95px;
        height: 95px;
    }
    ${media.desktop}{
        // color: violet;
        font-size: 70px;
        width: 105px;
        height: 105px;
        }
    ${media.desktopLg}{
        // color: green;
        font-size: 70px;
        width: 110px;
        height: 110px;
        }
`



// -- FIN M73   ------------------------------------------- M73


// FIN CALCULO --- CALCULO MENTAL

// ----------------------------------------- RESTA

// M66 ------------------------- M66

// NUMBER
export const NumSumaM66 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // width: 15vw;
    width: ${props => props.w};
    // height: 12vh;
    text-align: center; 
    // font-size: ${props => props.fs};
    font-size: 16vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border-radius: ${props => props.bradius};
    // text-shadow: ${props => props.texts};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: 15vw;
            // color: orange;
        }
    ${media.tablet}{
        font-size: 13vw;
        // color: blue;
    }
    ${media.desktop}{
        font-size: 11vw;
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: 11vw;
        // color: green;
        }
`

// NUMBER
export const NumResultM66 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 15vw;
    height: 12vh;
    text-align: center; 
    font-size: 11vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border-radius: ${props => props.bradius};

    // ${media.min}{
    //         // width: 14vw;
    //         // height: 11vh;
    //         // color: orange;
    //     }
    //     ${media.tablet}{
    //         font-size: 11vw;
    //         width: 14vw;
    //         height: 16vh;
    //         // color: blue;
    //     }
    //     ${media.desktop}{
    //         font-size: 8vw;
    //         width: 12vw;
    //         height: 18vh;
    //         // color: violet;
    //         }
    //     ${media.desktopLg}{
    //         font-size: 6.5vw;
    //         width: 8vw;
    //         height: 18vh;
    //         // color: green;
    //         }
`

// INPUT
export const InputM66 = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // width: 15vw;
    width: ${props => props.w};
    // height: 12vh;
    text-align: center; 
    font-size: 15vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border: 0px;
    border-radius: ${props => props.bradius};
    box-shadow: ${props => props.bs};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    &:focus{
        outline: none;
    }

    ${media.min}{
        font-size: 13vw;
            // color: orange;
        }
    ${media.tablet}{
        font-size: 12vw;
        // color: blue;
    }
    ${media.desktop}{
        font-size: 11vw;
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: 11vw;
        // color: green;
        }
`


// FIN M66 ------------------------- FIN M66

// M67 ------------------------- M67y

// P CANTIDAD
export const PRectaM67 = styled.p`
    font-size: 3.4vw;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    color: ${props => props.color ? props.color : "#000000" };
    text-align: center;
    margin: ${props => props.m};
    cursor: pointer;

    ${media.min}{
        // color: orange;
        font-size: 2.6vw; 
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // color: blue;
        font-size: 2.3vw;
        margin: ${props => props.mt};
        }
    ${media.desktop}{
        // color: violet;
        font-size: 1.8vw;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        // color: green;
        font-size: 1.8vw;
        margin: ${props => props.mlg};
        }
`

export const BtnRectaM67 = styled.button`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 8px;
    min-width: 8px; 

    padding: 0.5rem;
    // border: 1px solid black;
    border: 0px;
    background: transparent;
    margin: ${props => props.m};
    position: relative;

    ${media.min}{
        // background-color: orange; 
        width: 8px;
        min-width: 8px; 
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        // background-color: blue; 
        margin: ${props => props.mt};
        width: 12px;
        min-width: 12px;
        }
    ${media.desktop}{
        // background-color: violet; 
        margin: ${props => props.md};
        width: 12px;
        min-width: 12px;
        }
    ${media.desktopLg}{
        // background-color: green; 
        margin: ${props => props.mlg};
        font-size: 3vw;
        width: 12px;
        min-width: 12px;
        }
`
export const BotonRetrocede = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
`

// FIN M67 ------------------------- FIN M67


// M68 ------------------------- M68

export const CuadradoLleno = styled.button`
    // padding: 10px 60px 10px 60px;
    width: 8vw;
    height: 8vh;
    color: #FF9B2A;
    background: ${props => props.bgcolor ? props.bgcolor : "#23AAAF"};
    border: 0;
    border-radius: 10px;
    font-weight: 600;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0vh 0.3vw 0vh 0.3vw;

    ${media.min}{
        width: 8vw;
        height: 9vh;
        }
    ${media.tablet}{
        width: 6vw;
        height: 9vh;
    }
    ${media.desktop}{
        width: 5vw;
        height: 10vh;
        }
    ${media.desktopLg}{
        width: 5vw;
        height: 9vh;
        }
`

export const Equis = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;

    ${media.min}{
        font-size: 3.3em;
        }
    ${media.tablet}{
        font-size: 3.2em;
    }
    ${media.desktop}{
        font-size: 3.1em;
        }
    ${media.desktopLg}{
        font-size: 3em;
        }
`

export const CuadradoVacio = styled.button`
    padding:10px 60px 10px 60px;
    border-radius: 10px;
    border:0;
    background:#23AAAF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color:white;
    font-weight: 600;
    font-size: 16px;
    margin: ${props => props.margin};
`

export const CirculoMasMenos = styled.button`
    // padding:10px 60px 10px 60px;
    color: #000000;
    background: #FFFFFF;
    border-radius: 50%;
    border: 0;
    font-weight: 600;
    font-size: 16px;
    margin: 0.3vw;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: ${props => props.op};
`

export const NumerosM68 = styled.p`
    // padding:10px 60px 10px 60px;
    font-family: 'Nunito';
    color: #FF9B2A;
    font-weight: 600;
    // margin: 0.3vw; 
    margin: 0vh 1vw 0vh 1vh; 
    width: ${props => props.w};
    font-size: 17vw;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: 14vw;
        }
    ${media.tablet}{
        font-size: 13vw;
    }
    ${media.desktop}{
        font-size: 12vw;
        }
    ${media.desktopLg}{
        font-size: 9vw;
        }
`


// INPUT
export const InputM68 = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 20vw;
    // height: 12vh;
    text-align: center; 
    font-size: 17vw;
    margin: 0px 0px 0px 1vw;
    padding: ${props => props.p};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    border: 0px;
    border-radius: ${props => props.bradius};
    box-shadow: ${props => props.bs};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    &:focus{
        outline: none;
    }

    ${media.min}{
        width: 18vw;
        font-size: 13vw;
        }
    ${media.tablet}{
        width: 16vw;
        font-size: 12vw;
    }
    ${media.desktop}{
        width: 14vw;
        font-size: 11vw;
        }
    ${media.desktopLg}{
        width: 12vw;
        font-size: 8vw;
        }
`


// FIN M68 ------------------------- FIN M68


// RESTA TRADICIONAL

// M82 ------------------------- FIN M82


// CUADRICULA ------------------------- CUADRICULA
export const DivFlexM82 = styled.div`
    width: fit-content;
    // border: 3px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    `

//FLECHAS
export const Flecha = styled.div`
    border: 3px solid transparent;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO ARRIBA DERECHO
export const UpRight = styled.div`
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    // width: 8vw;
    // height: 7vh;
    width: 13vw;
    height: 11vh;
    text-align: center;
    margin: 0px 0px -0.5vh 0px;

    ${media.min}{
        // width: 8vw;
        // height: 7vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 8vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO ARRIBA IZQUIERDO
export const UpLeft = styled.div`
    border-right: 2px solid #000000;
    border-left: 0px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    // width: 8vw;
    // height: 7vh;
    width: 13vw;
    height: 11vh;
    text-align: center;
    margin: 0px 0px -0.5vh 0px;

    ${media.min}{
        // width: 8vw;
        // height: 7vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 8vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO ABAJO DERECHO
export const DownRight = styled.div`
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;
    border-top: 0px solid transparent;
    border-bottom: 2px solid #000000;
    // width: 8vw;
    // height: 7vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 8vw;
        // height: 7vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 8vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO ABAJO IZQUIERDO
export const DownLeft = styled.div`
    border-right: 0px solid transparent;
    border-left: 2px solid #000000;
    border-top: 0px solid transparent;
    border-bottom: 2px solid #000000;
    // width: 8vw;
    // height: 7vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 8vw;
        // height: 7vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 8vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//OUT
export const Out = styled.div`
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 2px solid #000000;
    // width: 8vw;
    // height: 7vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 8vw;
        // height: 7vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 8vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//OUT
export const OutResult = styled.div`
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    // width: 8vw;
    // height: 7vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 8vw;
        // height: 7vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 8vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO RESULTADO DERECHO
export const ResultRight = styled.div`
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    // width: 8vw;
    // height: 7vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 8vw;
        // height: 7vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 8vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO RESULTADO IZQUIERDO
export const ResultLeft = styled.div`
    border-right: 0px solid transparent;
    border-left: 2px solid #000000;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    // width: 8vw;
    // height: 7vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 8vw;
        // height: 7vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 8vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//TEXTO FLECHA
export const FlechaP = styled.p`
    font-size: 6vw;
    color: red;
    margin: 0px;
    font-weight: 700;

    ${media.min}{
        font-size: 5.5vw;
    }
    ${media.tablet}{
        font-size: 5vw;
    }
    ${media.desktop}{
        font-size: 4vw;
        }
    ${media.desktopLg}{
        font-size: 4vw;
        }
    `

//TEXTO MENOS
export const Menos = styled.p`
font-size: 5vw;
color: red;
margin: 0vh 0px 0vh 0px;
font-weight: 700;
`

export const Numeros = styled.p`
    font-family: 'Nunito';      
    // font-size: 4vw;
    font-size: 6vw;
    color: #646464;
    margin: 0px;
    font-weight: 700;

    ${media.min}{
        // color: orange;
        // font-size: 4.2vw;
    }
    ${media.tablet}{
        // color: blue;
        font-size: 5vw;
    }
    ${media.desktop}{
        // color: violet;
        font-size: 4.6vw;
        }
    ${media.desktopLg}{
        // color: green;
        font-size: 4.2vw;
        }
`

export const NumeroTachado = styled.p`
    font-family: 'Nunito';      
    // font-size: 4vw;
    font-size: 6vw;
    color: #646464;
    margin: 0px;
    font-weight: 700;
    text-decoration: line-through;
    text-decoration-color: red;

    ${media.min}{
        // color: orange;
        // font-size: 4.2vw;
    }
    ${media.tablet}{
        // color: blue;
        font-size: 5vw;
    }
    ${media.desktop}{
        // color: violet;
        font-size: 4.6vw;
        }
    ${media.desktopLg}{
        // color: green;
        font-size: 4.2vw;
        }
`

// FIN CUADRICULA ------------------------- FIN CUADRICULA


// CALCULO ------------------------- CALCULO

//TEXTO MENOS
export const NumCalculo = styled.p`
// max-width: 8.7vw;
// min-width: 8.7vw;
// min-height: 12vh;
// max-height: 12vh;
// width: ${props => props.w};
// height: ${props => props.h};
font-family: 'Nunito';
// font-size: 5vw;
font-size: 6vw;
color: ${props => props.color};
// margin: 0vh 0.5vw 0vh 0.5vw;
margin: 0px;
font-weight: 700;
text-align: center;
// border: 2px solid blue;

${media.min}{
    // font-size: 5vw;
    font-size: 6vw;
}
${media.tablet}{
    font-size: 5vw;
}
${media.desktop}{
    font-size: 4.5vw;
    }
${media.desktopLg}{
    font-size: 4.5vw;
    }
`

// FIN CALCULO ------------------------- FIN CALCULO

// PASOS ------------------------- PASOS

//TEXTO PASOS
export const Pasos = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-size: 2vw;
font-family: Nunito;
font-weight: bolder;
color: #000000;
width: 35vw;
height: 8vh;
text-align: center;
// padding: 1vh 2vw 0vh 0.5vw;
margin: 1.5vh 0vw 1.5vh 0vw;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
background-color: ${props => props.bgcolor};

${media.min}{
    width: 33vw;
    font-size: 1.6vw;
}
${media.tablet}{
    width: 28vw;
    font-size: 1vw;
}
${media.desktop}{
    width: 24vw;
    font-size: 1vw;
    }
${media.desktopLg}{
    width: 24vw;
    font-size: 1vw;
    }
`

//TEXTO PASOS TITULO
export const PasosT = styled.div`
font-family: Nunito;
font-size: 7vw;
font-weight: bolder;
color: #000000;
width: 25vw;
text-align: center;
// padding: 1vh 2vw 0vh 0.5vw;
// margin: 0vh 0.5vw 0vh 0.5vw;
background-color: ${props => props.bgcolor};
// border: 2px solid red;

${media.min}{
    font-size: 5vw;
    width: 25vw;
}
${media.tablet}{
    font-size: 5vw;
    width: 20vw;
}
${media.desktop}{
    font-size: 3vw;
    width: 15vw;
    }
${media.desktopLg}{
    font-size: 3.6vw;
    width: 15vw;
    }
`

//TEXTO PASOS SUBTITULO
export const PasosSubT = styled.div`
font-family: Nunito;
font-size: 2vw;
font-weight: bolder;
color: #000000;
width: 25vw;
text-align: center;
// padding: 1vh 2vw 0vh 0.5vw;
// margin: 0vh 0.5vw 0vh 0.5vw;
background-color: ${props => props.bgcolor};
// border: 2px solid red;

${media.min}{
    font-size: 2vw;
    width: 25vw;
}
${media.tablet}{
    font-size: 1.3vw;
    width: 20vw;
}
${media.desktop}{
    font-size: 1vw;
    width: 15vw;
    }
${media.desktopLg}{
    font-size: 1vw;
    width: 15vw;
    }
`

// FIN PASOS ------------------------- FIN PASOS


// FIN M82 ------------------------- FIN M82


// M82 BIS ------------------------- M82 BIS

// INPUT DE CUARICULA
export const InputM82B = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 9vw;
    height: 8vh;
    text-align: center; 
    font-size: 4vw;
    margin: 1vh 0px 0px 0vw;
    padding: ${props => props.p};
    color: ${props => props.color};
    border: 0px;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: ${props => props.z};

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        width: 8vw;
        height: 8vh;
        }
    ${media.tablet}{
        // color: blue;
        width: 7vw;
        height: 9vh;
    }
    ${media.desktop}{
        // color: violet;
        width: 6vw;
        height: 9vh;
        }
    ${media.desktopLg}{
        // color: green;
        // width: 6vw;
        // height: 9vh;
        width: 70px;
        height: 60px;
        }
`

// INPUT DE CALCULO
export const InputCalcM82B = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // width: 6vw;
    // height: 6vh;
    width: 11vw;
    height: 8vh;
    // font-size: 4.3vw;
    font-size: 5.3vw;
    text-align: center; 
    // margin: 1vh 0px 0px 0vw;
    padding: ${props => props.p};
    color: ${props => props.color};
    border: 0px;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: ${props => props.z};

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        // width: 6vw;
        // height: 5.5vh;
        width: 11vw;
        height: 9.6vh;
        // font-size: 4.3vw;
        font-size: 5.6vw;
        }
    ${media.tablet}{
        // color: blue;
        width: 9vw;
        height: 9vh;
        font-size: 4.6vw;
    }
    ${media.desktop}{
        // color: violet;
        width: 9vw;
        height: 9vh;
        font-size: 4.3vw;
        }
    ${media.desktopLg}{
        // color: green;
        width: 9vw;
        height: 10vh;
        font-size: 4.3vw;
        }
`


// FIN M82 BIS ------------------------- FIN M82 BIS


// M89 ------------------------- M89

//TEXTO PASOS
export const PasosM89 = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-family: Nunito;
font-size: 2vw;
font-weight: bolder;
color: #000000;
width: 35vw;
height: 5vh;
text-align: center;
// padding: 1vh 2vw 0vh 0.5vw;
margin: 1vh 0vw 1vh 0vw;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
background-color: ${props => props.bgcolor};

${media.min}{
    width: 33vw;
    font-size: 1.6vw;
}
${media.tablet}{
    width: 28vw;
    font-size: 1vw;
}
${media.desktop}{
    width: 24vw;
    font-size: 1vw;
    }
${media.desktopLg}{
    width: 28vw;
    height: 6vh;
    font-size: 1vw;
    }
`

//TEXTO PASOS SUBTITULO
export const PasosSubTM89 = styled.div`
font-family: Nunito;
font-size: 2vw;
font-weight: bolder;
color: #000000;
width: 25vw;
text-align: center;
// padding: 1vh 2vw 0vh 0.5vw;
// margin: 0vh 0.5vw 0vh 0.5vw;
background-color: ${props => props.bgcolor};
// border: 2px solid red;

${media.min}{
    font-size: 2vw;
    width: 25vw;
}
${media.tablet}{
    font-size: 1.3vw;
    width: 20vw;
}
${media.desktop}{
    font-size: 1vw;
    width: 15vw;
    }
${media.desktopLg}{
    font-size: 1vw;
    width: 20vw;
    }
`
// FIN M89 ------------------------- FIN M89


// M89 ------------------------- M89 BIS

// INPUT DE CUARICULA COMPARTIDO
export const InputCompartido = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 3vw;
    height: 8vh;
    text-align: center; 
    font-size: 4vw;
    margin: 1vh 0px 0px 0vw;
    margin: ${props => props.m};
    padding: ${props => props.p};
    color: ${props => props.color};
    border: 0px;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        width: 3vw;
        height: 8vh;
        }
    ${media.tablet}{
        // color: blue;
        width: 3vw;
        height: 9vh;
    }
    ${media.desktop}{
        // color: violet;
        width: 3vw;
        height: 9vh;
        }
    ${media.desktopLg}{
        // color: green;
        width: 3vw;
        height: 9vh;
        }
`

// INPUT DE CUARICULA COMPARTIDO
export const InputTachado = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 3vw;
    height: 5vh;
    text-align: center; 
    font-size: 4vw;
    margin: 1vh 0px 0px 0vw;
    padding: ${props => props.p};
    color: ${props => props.color};
    border: 0px;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-decoration: line-through;
    text-decoration-color: red;

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        width: 3vw;
        height: 5vh;
        }
    ${media.tablet}{
        // color: blue;
        width: 3vw;
        height: 6vh;
    }
    ${media.desktop}{
        // color: violet;
        width: 3vw;
        height: 9vh;
        }
    ${media.desktopLg}{
        // color: green;
        width: 3vw;
        height: 9vh;
        }
`

// FIN M89 BIS ------------------------- FIN M89 BIS




// M82 PRACTICA ------------------------- M82 PRACTICA

// CUADRICULA ------------------------- CUADRICULA
export const DivFlexM82P = styled.div`
    width: fit-content;
    // border: 3px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
`


//NUMERO ARRIBA DERECHO
export const UpRightP = styled.div`
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    // width: 10vw;
    // height: 10vh;
    width: 13vw;
    height: 11vh;
    text-align: center;
    margin: 0px 0px -0.5vh 0px;

    ${media.min}{
        // width: 9.5vw;
        // height: 10vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 12vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO ARRIBA IZQUIERDO
export const UpLeftP = styled.div`
    border-right: 2px solid #000000;
    border-left: 0px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    // width: 10vw;
    // height: 10vh;
    width: 13vw;
    height: 11vh;
    text-align: center;
    margin: 0px 0px -0.5vh 0px;

    ${media.min}{
        // width: 9.5vw;
        // height: 10vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 12vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO ABAJO DERECHO
export const DownRightP = styled.div`
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;
    border-top: 0px solid transparent;
    border-bottom: 2px solid #000000;
    // width: 10vw;
    // height: 10vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 9.5vw;
        // height: 10vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 12vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO ABAJO IZQUIERDO
export const DownLeftP = styled.div`
    border-right: 0px solid transparent;
    border-left: 2px solid #000000;
    border-top: 0px solid transparent;
    border-bottom: 2px solid #000000;
    // width: 10vw;
    // height: 10vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 9.5vw;
        // height: 10vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 12vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//OUT
export const OutP = styled.div`
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 2px solid #000000;
    // width: 10vw;
    // height: 10vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 9.5vw;
        // height: 10vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 12vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO RESULTADO DERECHO
export const ResultRightP = styled.div`
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    // width: 10vw;
    // height: 10vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 9.5vw;
        // height: 10vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 12vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//NUMERO RESULTADO IZQUIERDO
export const ResultLeftP = styled.div`
    border-right: 0px solid transparent;
    border-left: 2px solid #000000;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    // width: 10vw;
    // height: 10vh;
    width: 13vw;
    height: 11vh;
    text-align: center;

    ${media.min}{
        // width: 9.5vw;
        // height: 10vh;
        width: 11.5vw;
        height: 11vh;
    }
    ${media.tablet}{
        // width: 8vw;
        // height: 12vh;
        width: 10vw;
        height: 11vh;
    }
    ${media.desktop}{
        width: 8vw;
        height: 12vh;
        }
    ${media.desktopLg}{
        width: 8vw;
        height: 12vh;
        }
`

//TEXTO MENOS
export const MenosP = styled.p`
font-size: 5vw;
color: red;
margin: -2vh 0px 0vh 0px;
font-weight: 700;
`

// INPUT DE CUARICULA
export const InputM82BP = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    // width: 6.5vw;
    // height: 6vh;
    width: 9vw;
    height: 8vh;
    text-align: center; 
    font-size: 4vw;
    margin: 1vh 0px 0px 0vw;
    padding: ${props => props.p};
    color: ${props => props.color};
    border: 0px;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    &:focus{
        outline: none;
    }

    ${media.min}{
        // color: orange;
        // width: 6vw;
        // height: 7vh;
        width: 8vw;
        height: 8vh;
        }
    ${media.tablet}{
        // color: blue;
        // width: 6vw;
        width: 7vw;
        height: 9vh;
    }
    ${media.desktop}{
        // color: violet;
        width: 6vw;
        height: 9vh;
        }
    ${media.desktopLg}{
        // color: green;
        // width: 6vw;
        // height: 9vh;
        width: 70px;
        height: 60px;
        }
`

// FIN CUADRICULA ------------------------- FIN CUADRICULA


// AYUDA ------------------------------------- AYUDA

export const BtnAyudaM82P = styled.button`
    // padding: 5px 12px 5px 12px;
    padding: 1vh 3vw 1vh 3vw;
    border-radius: 50%;
    border:0;
    background: #545454;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color:white;
    font-weight: 600;
    font-size: 5vw;
    margin: 0px 0vw 0px 0vw;
    position: relative;

    ${media.min}{
        padding: 1vh 2.3vw 1vh 2.3vw;
        font-size: 4.3vw;
        }
    ${media.tablet}{
        padding: 0.4vh 1.5vw 0.4vh 1.5vw;
        font-size: 4vw;
        }
    ${media.desktop}{
        padding: 0.4vh 1.5vw 0.4vh 1.5vw;
        font-size: 3vw;
        }
    ${media.desktopLg}{
        padding: 0.2vh 1vw 0.2vh 1vw;
        font-size: 2.8vw;
        }
`
// FIN AYUDA ------------------------------------- FIN AYUDA


// FIN M82 PRACTICA ------------------------- FIN M82 PRACTICA


// ----------------------------------------- FIN RESTA


// ----------------------------------------- LECTURA Y ESCRITURA DE NÚMEROS

// NUMBER M74
export const NumberM74 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 4.5vw;

    width: fit-content;
    padding: 4vh 4vw 4vh 4vw; 
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-transform: uppercase;

    ${media.min}{
            // color: orange;
            font-size: 4.2vw;
        }
        ${media.tablet}{
            // color: blue;
            font-size: 3.8vw;
        }
        ${media.desktop}{
            // color: violet;
            font-size: 3.4vw;
            }
        ${media.desktopLg}{
            // color: green;
            font-size: 3vw;
            }
`

// NUMBER M74
export const NumberM69 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 12vw;

    width: fit-content;
    padding: 2vh 7vw 2vh 7vw; 
    color: #FFFFFF;
    background-color: #23AAAF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-transform: uppercase;

    ${media.min}{
            // color: orange;
            font-size: 10vw;
        }
        ${media.tablet}{
            // color: blue;
            font-size: 8vw;
        }
        ${media.desktop}{
            // color: violet;
            font-size: 6vw;
            }
        ${media.desktopLg}{
            // color: green;
            font-size: 5vw;
            }
`

// INPUT
export const InputM69 = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    width: 80vw;
    // height: 12vh;
    line-height: 8vh;
    text-align: center; 
    font-size: 10vw;
    margin: 0px 0px 0px 1vw;
    padding: ${props => props.p};
    color: ${props => props.color};
    border: 0px;
    background: #F1F0F0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px; 

    &:focus{
        outline: none;
    }

    ${media.min}{
        width: 75vw;
        font-size: 8.5vw;
        // color: orange;
        }
    ${media.tablet}{
        width: 65vw;
        font-size: 7vw;
        // color: blue;
    }
    ${media.desktop}{
        width: 60vw;
        font-size: 6vw;
        // color: violet;
        }
    ${media.desktopLg}{
        width: 55vw;
        font-size: 4.5vw;
        // color: green;
        }
`



// BTN AYUDA
export const AyudaM69 = styled.button`
    // padding: 5px 12px 5px 12px;
    padding: 1vh 3vw 1vh 3vw;
    border-radius: 50%;
    border:0;
    background: #545454;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color:white;
    font-weight: 600;
    font-size: 5vw;
    margin: 15vh 22vw 0px 0vw;
    position: relative;
    cursor: pointer;

    ${media.min}{
        padding: 1vh 2.3vw 1vh 2.3vw;
        font-size: 4.3vw;
        }
    ${media.tablet}{
        padding: 0.4vh 1.5vw 0.4vh 1.5vw;
        font-size: 4vw;
        margin: 8vh 22vw 0px 0vw;
        }
    ${media.desktop}{
        padding: 0.4vh 1.5vw 0.4vh 1.5vw;
        font-size: 3vw;
        margin: 8vh 22vw 0px 0vw;
        }
    ${media.desktopLg}{
        padding: 0.2vh 1vw 0.2vh 1vw;
        font-size: 2.8vw;
        margin: 8vh 22vw 0px 0vw;
        }
`

// NUMBER M74
export const NumberAyuda = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 2vw;
    color: #525252;
    margin: 1vh 0px 1vh 0px;

    ${media.min}{
            // color: orange;
            font-size: 2vw;
        }
        ${media.tablet}{
            // color: blue;
            font-size: 2vw;
        }
        ${media.desktop}{
            // color: violet;
            font-size: 1.8vw;
            margin: 0px;
            }
        ${media.desktopLg}{
            // color: green;
            font-size: 1.6vw;
            margin: 0px;
            }
`

// BTN AYUDA
export const CloseM69 = styled.button`
    border: 0;
    background: transparent;
    color: #545454;
    font-weight: 600;
    font-size: 4vw;
    margin: 0vh 0vw 0px 70vw;
    cursor: pointer;

    ${media.min}{
        margin: 0vh 0vw 0px 73vw;
        }
    ${media.tablet}{
        margin: 0vh 0vw 0px 81vw;
        }
    ${media.desktop}{
        margin: 0vh 0vw 0px 85vw;
        }
    ${media.desktopLg}{
        margin: 0vh 0vw 0px 87vw;
        }
`


// ----------------------------------------- FIN LECTURA Y ESCRITURA DE NÚMEROS

// CALCULO --- RESTA DESCOMPOSICIÓN ------ M83

// DIV
export const DivM83 = styled.div`
    display: flex;
    justify-content: center;
    min-width: 7vw;
    min-height: 13vh;
    // max-height: 10vh;
    text-align: center;
    // border: 2px solid yellow;

    // ${media.min}{
    //         // color: orange;
    //         font-size: 2vw;
    //     }
    //     ${media.tablet}{
    //         // color: blue;
    //         font-size: 2vw;
    //     }
    //     ${media.desktop}{
    //         // color: violet;
    //         font-size: 1.8vw;
    //         margin: 0px;
    //         }
    //     ${media.desktopLg}{
    //         // color: green;
    //         font-size: 1.6vw;
    //         margin: 0vh 3vw 0vh 0vw;
    //         }
`

    //NUMEROS CALCULO 
    export const NumCalculoM83 = styled.div`
    max-width: 9vw;
    min-width: 9vw;
    min-height: 12vh;
    max-height: 12vh;
    font-family: 'Nunito';
    font-size: 7vw;
    color: ${props => props.color};
    // margin: 0vh 0.5vw 0vh 0.5vw;
    margin: 0px;
    font-weight: 700;
    text-align: center;
    // border: 2px solid black;

    ${media.min}{
        font-size: 6vw;
        }
    ${media.tablet}{
        font-size: 5vw;
        }
    ${media.desktop}{
        font-size: 4.5vw;
        }
    ${media.desktopLg}{
        font-size: 4.5vw;

        max-width: 8.7vw;
        min-width: 8.7vw;
        min-height: 12vh;
        max-height: 12vh;
        }
    `
    export const FormM83 = styled.form`
    max-width: 9vw;
    min-width: 9vw;
    min-height: 12vh;
    max-height: 12vh;
    font-family: 'Nunito';
    font-size: 7vw;
    color: ${props => props.color};
    // margin: 0vh 0.5vw 0vh 0.5vw;
    margin: 0px;
    font-weight: 700;
    text-align: center;
    // border: 2px solid black;

    ${media.min}{
        font-size: 6vw;
        }
    ${media.tablet}{
        font-size: 5vw;
        }
    ${media.desktop}{
        font-size: 4.5vw;
        }
    ${media.desktopLg}{
        font-size: 4.5vw;

        max-width: 8.7vw;
        min-width: 8.7vw;
        min-height: 12vh;
        max-height: 12vh;
        }
    `

// NUMERO REDONDO
export const NumRedondo = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 6.5vw;
    width: 9vw;
    min-height: 8.3vh;
    text-align: center;
    color: #646464;
    background: #F1F0F0;
    border-radius: 50%;
    // border: 2px solid pink;
    // margin: 0vh 3vw 0vh 0vw;
    margin: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    ${media.min}{
            color: orange;
            font-size: 5vw;
            width: 7.5vw;
            min-height: 8vh;
            max-height: 8vh;
        }
        ${media.tablet}{
            color: blue;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
        }
        ${media.desktop}{
            color: violet;
            font-size: 3.5vw;
            width: 5vw;
            min-height: 8.8vh;
            max-height: 8.8vh;
            margin: 0px;
            }
        ${media.desktopLg}{
            color: green;
            font-size: 4vw;
            width: 6vw;
            min-height: 12vh;
            margin: 0px;
            // margin: 0vh 3vw 0vh 0vw;
            }
`

// NUMERO CUADRADO
export const NumCuadrado = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 7vw;
    width: 8.5vw;
    min-height: 8vh;
    max-height: 8vh;
    text-align: center;
    color: #646464;
    background: #F1F0F0;
    // margin: 0vh 0vw 0vh 3vw;
    margin: 0px;
    // border: 2px solid violet;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    ${media.min}{
            // color: orange;
            font-size: 5.5vw;
            width: 7.5vw;
            min-height: 7.5vh;
            max-height: 7.5vh;
        }
        ${media.tablet}{
            // color: blue;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
        }
        ${media.desktop}{
            // color: violet;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
            margin: 0px;
            }
        ${media.desktopLg}{
            // color: green;
            font-size: 4vw;
            width: 5vw;
            min-height: 12vh;
            max-height: 12vh;
            margin: 0px;
            }
`

export const ImgDiv = styled.div`
    // border: 2px solid orange;
    display: Flex;
    justify-content: center;
    // min-width: 5vw;
    // min-height: 2vh;
    // height: 25vh;
    margin: 0vh 0vw -3vh 0vw;
    `

export const NumberDivR = styled.div`
    // border: 2px solid green;
    min-width: 6vw;
    min-height: 12vh;
    // margin: 0vh 0vw 0vh 3vw;
    margin: 0vh 0vw 0vh 1vw;
    `

export const NumberDivL = styled.div`
    // border: 2px solid pink;
    min-width: 6vw;
    min-height: 12vh;
    // margin: 0vh 3vw 0vh 0vw;
    margin: 0vh 1vw 0vh 0vw;
    `
// FIN CALCULO --- RESTA DESCOMPOSICIÓN ------ M83

// CALCULO --- RESTA DESCOMPOSICIÓN ------ M83Bis

//NUMEROS CALCULO 
export const InputM83Bis = styled.input`
max-width: 9vw;
min-width: 9vw;
min-height: 12vh;
max-height: 12vh;
font-family: 'Nunito';
font-size: 7vw;
background: #F1F0F0;
color: ${props => props.color};
// margin: 0vh 0.5vw 0vh 0.5vw;
margin: 0px;
font-weight: 700;
text-align: center;
// border: 2px solid black;
border: 0px transparent;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 3px;

&:focus{
    outline: none;
}

${media.min}{
    font-size: 6vw;
    }
${media.tablet}{
    font-size: 5vw;
    }
${media.desktop}{
    font-size: 4.5vw;
    }
${media.desktopLg}{
    font-size: 4.5vw;

    max-width: 8.7vw;
    min-width: 8.7vw;
    min-height: 12vh;
    max-height: 12vh;
    }
`

// NUMERO REDONDO
export const InputRedondo = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 6.5vw;
    width: 9vw;
    min-height: 8.3vh;
    text-align: center;
    color: #646464;
    background: #F1F0F0;
    border-radius: 50%;
    border: 0px transparent;
    // border: 2px solid pink;
    // margin: 0vh 3vw 0vh 0vw;
    margin: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:focus{
        outline: none;
    }

    ${media.min}{
            color: orange;
            font-size: 5vw;
            width: 7.5vw;
            min-height: 8vh;
            max-height: 8vh;
        }
        ${media.tablet}{
            color: blue;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
        }
        ${media.desktop}{
            color: violet;
            font-size: 3.5vw;
            width: 5vw;
            min-height: 8.8vh;
            max-height: 8.8vh;
            }
        ${media.desktopLg}{
            color: green;
            font-size: 4vw;
            width: 6vw;
            min-height: 12vh;
            margin: 0px;
            // margin: 0vh 3vw 0vh 0vw;
            }
`

// NUMERO CUADRADO
export const InputCuadrado = styled.input`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 7vw;
    width: 8.5vw;
    min-height: 8vh;
    max-height: 8vh;
    text-align: center;
    color: #646464;
    background: #F1F0F0;
    // margin: 0vh 0vw 0vh 3vw;
    margin: 0px;
    border: 0px transparent;
    // border: 2px solid violet;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    // z-index: 1;

    &:focus{
        outline: none;
    }

    ${media.min}{
            // color: orange;
            font-size: 5.5vw;
            width: 7.5vw;
            min-height: 7.5vh;
            max-height: 7.5vh;
        }
        ${media.tablet}{
            // color: blue;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
        }
        ${media.desktop}{
            // color: violet;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
            }
        ${media.desktopLg}{
            // color: green;
            font-size: 4vw;
            width: 5vw;
            min-height: 12vh;
            max-height: 12vh;
            margin: 0px;
            }
`

// FIN CALCULO --- RESTA DESCOMPOSICIÓN ------ M83Bis


// CALCULO --- RESTA DESCOMPOSICIÓN ------ M89

// NUMERO REDONDO
export const NumRedondoTachado = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 6.5vw;
    width: 9vw;
    min-height: 8.3vh;
    text-align: center;
    color: #646464;
    background: #F1F0F0;
    border-radius: 50%;
    // border: 2px solid pink;
    // margin: 0vh 3vw 0vh 0vw;
    margin: 0px;

    text-decoration: line-through;
    text-decoration-color: red;

    display: flex;
    justify-content: center;
    align-items: center;

    ${media.min}{
            color: orange;
            font-size: 5vw;
            width: 7.5vw;
            min-height: 8vh;
            max-height: 8vh;
        }
        ${media.tablet}{
            color: blue;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
        }
        ${media.desktop}{
            color: violet;
            font-size: 3.5vw;
            width: 5vw;
            min-height: 8.8vh;
            max-height: 8.8vh;
            }
        ${media.desktopLg}{
            color: green;
            font-size: 4vw;
            width: 6vw;
            min-height: 12vh;
            margin: 0px;
            // margin: 0vh 3vw 0vh 0vw;
            }
`

// NUMERO CUADRADO
export const NumCuadradoTachado = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 7vw;
    width: 8.5vw;
    min-height: 8vh;
    max-height: 8vh;
    text-align: center;
    color: #646464;
    background: #F1F0F0;
    // margin: 0vh 0vw 0vh 3vw;
    margin: 0px;
    // border: 2px solid violet;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: line-through;
    text-decoration-color: red;

    ${media.min}{
            // color: orange;
            font-size: 5.5vw;
            width: 7.5vw;
            min-height: 7.5vh;
            max-height: 7.5vh;
        }
        ${media.tablet}{
            // color: blue;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
        }
        ${media.desktop}{
            // color: violet;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
            }
        ${media.desktopLg}{
            // color: green;
            font-size: 4vw;
            width: 5vw;
            min-height: 12vh;
            max-height: 12vh;
            margin: 0px;
            }
`
// FIN CALCULO --- RESTA DESCOMPOSICIÓN ------ M89


// CALCULO --- SUMA DESCOMPOSICIÓN ------ M63

// NUMERO REDONDO
export const NumRedondo63 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 6.5vw;
    width: 9vw;
    min-height: 8.3vh;
    text-align: center;
    color: #646464;
    background: #F1F0F0;
    border-radius: 50%;
    // border: 2px solid pink;
    // margin: 0vh 3vw 0vh 0vw;
    // margin: 0px;
    margin: 0vh 5vw 0vh 0vw;

    display: flex;
    justify-content: center;
    align-items: center;

    ${media.min}{
            color: orange;
            font-size: 5vw;
            width: 7.5vw;
            min-height: 8vh;
            max-height: 8vh;
            margin: 0vh 6vw 0vh 0vw;
        }
        ${media.tablet}{
            color: blue;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
            margin: 0vh 7vw 0vh 0vw;
        }
        ${media.desktop}{
            color: violet;
            font-size: 3.5vw;
            width: 5vw;
            min-height: 8.8vh;
            max-height: 8.8vh;
            margin: 0vh 7.5vw 0vh 0vw;
            }
        ${media.desktopLg}{
            color: green;
            font-size: 4vw;
            width: 6vw;
            min-height: 12vh;
            // margin: 0px;
            margin: 0vh 8vw 0vh 0vw;
            }
`

// NUMERO CUADRADO
export const NumCuadrado63 = styled.p`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 7vw;
    width: 8.5vw;
    min-height: 8vh;
    max-height: 8vh;
    text-align: center;
    color: #646464;
    background: #F1F0F0;
    // margin: 0vh 0vw 0vh 3vw;
    margin: 0px;
    // border: 2px solid violet;
    border-radius: 10px;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    ${media.min}{
            // color: orange;
            font-size: 5.5vw;
            width: 7.5vw;
            min-height: 7.5vh;
            max-height: 7.5vh;
        }
        ${media.tablet}{
            // color: blue;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
        }
        ${media.desktop}{
            // color: violet;
            font-size: 3.8vw;
            width: 5.7vw;
            min-height: 7.9vh;
            max-height: 7.9vh;
            }
        ${media.desktopLg}{
            // color: green;
            font-size: 4vw;
            width: 5vw;
            min-height: 12vh;
            max-height: 12vh;
            // margin: 0px;
            margin: 0vh 0px 0vh 0vw;
            }
`

export const ImgDivM63 = styled.div`
    // border: 2px solid orange;
    display: Flex;
    justify-content: center;
    // min-width: 5vw;
    // min-height: 2vh;
    // height: 25vh;
    margin: 0vh 0vw -2vh 0vw;
    `

    // NUMERO REDONDO
export const NumRedondoM63 = styled.p`
font-family: 'Nunito';
font-style: normal;
font-weight: 900;
font-size: 6.5vw;
width: 9vw;
min-height: 8.3vh;
text-align: center;
color: #646464;
background: #F1F0F0;
border-radius: 50%;
// border: 2px solid pink;
// margin: 0vh 3vw 0vh 0vw;
margin: ${props => props.m};

display: flex;
justify-content: center;
align-items: center;

${media.min}{
        color: orange;
        font-size: 5vw;
        width: 7.5vw;
        min-height: 8vh;
        max-height: 8vh;
        margin: ${props => props.mm};
    }
    ${media.tablet}{
        color: blue;
        font-size: 3.8vw;
        width: 5.7vw;
        min-height: 7.9vh;
        max-height: 7.9vh;
        margin: ${props => props.mt};
    }
    ${media.desktop}{
        color: violet;
        font-size: 3.5vw;
        width: 5vw;
        min-height: 8.8vh;
        max-height: 8.8vh;
        margin: 0px;
        margin: ${props => props.md};
        }
    ${media.desktopLg}{
        color: green;
        font-size: 4vw;
        width: 6vw;
        min-height: 12vh;
        max-height: 12vh;
        // margin: 0vh 3vw 0vh 0vw;
        margin: ${props => props.mlg};
        }
`
// FIN CALCULO --- SUMA DESCOMPOSICIÓN ------ M63
// CALCULO --- SUMA DESCOMPOSICIÓN ------ M64

// BOTON PARA LAS IMG
export const BtnImgM64 = styled.button`
    display: flex;
    justify-content: center;
    align-items: center; 
    width: ${props => props.w};
    height: ${props => props.h};
    // padding: 0.5rem;
    // color: #FFFFFF;
    border: 2px solid transparent;
    background: transparent;
    margin: ${props => props.m};
    cursor: pointer;

    ${media.min}{
        // background-color: orange;  
        }
    ${media.tablet}{
        // background-color: blue; 
        }
    ${media.desktop}{
        // background-color: violet; 
        }
    ${media.desktopLg}{
        // background-color: green; 
        }
`

// FIN CALCULO --- SUMA DESCOMPOSICIÓN ------ M64


// M65 --- PRESENTACIÓN RESTA --- M65

// NUMBER
export const PM65 = styled.div`
    display: ${props => props.d};
    justify-content: ${props => props.justify}; 
    align-items: ${props => props.align}; 
    font-family: 'Nunito';
    font-style: normal;
    // font-weight: 400;
    font-weight: ${props => props.fw};
    // font-size: 1.6vw;
    font-size: ${props => props.fs};
    // margin: 1vh 0px 1vh 0px;
    margin: ${props => props.m};
    color: ${props => props.color};
    text-align: ${props => props.talign};

    // // text-shadow: ${props => props.texts};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: ${props => props.fsm};
        // color: orange;
        }
    ${media.tablet}{
        font-size: ${props => props.fst};
        // color: blue;
    }
    ${media.desktop}{
        font-size: ${props => props.fsd};
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: ${props => props.fslg};
        // color: green;
        }
`

// NUMBER
export const DPM65 = styled.div`
    display: ${props => props.d};
    justify-content: ${props => props.justify}; 
    align-items: ${props => props.align}; 
    font-family: 'Nunito';
    font-style: normal;
    // font-weight: 400;
    font-weight: ${props => props.fw};
    // font-size: 1.6vw;
    font-size: ${props => props.fs};
    // margin: 1vh 0px 1vh 0px;
    margin: ${props => props.m};
    color: ${props => props.color};
    text-align: ${props => props.talign};

    // // text-shadow: ${props => props.texts};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: ${props => props.fsm};
        color: orange;
        }
    ${media.tablet}{
        font-size: ${props => props.fst};
        color: blue;
    }
    ${media.desktop}{
        font-size: ${props => props.fsd};
        color: violet;
        }
    ${media.desktopLg}{
        font-size: ${props => props.fslg};
        color: green;
        }
`

// SUMA RESTA PRESENTACIÓN - RESTAMOS / SUMAMOS
export const CalcPres = styled.p`
    display: ${props => props.d};
    justify-content: ${props => props.justify}; 
    align-items: ${props => props.align}; 
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-weight: ${props => props.fw};
    font-size: 3.5vw;
    width: fit-content;
    margin: ${props => props.m};
    // padding: ${props => props.p};
    padding: 10px 10px 10px 10px;
    color: #FFFFFF;
    background: #FFDC73;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    // // text-shadow: ${props => props.texts};
    // text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    ${media.min}{
        font-size: 3vw;
        // color: orange;
        }
    ${media.tablet}{
        font-size: 2.6vw;
        // color: blue;
    }
    ${media.desktop}{
        font-size: 2.4vw;
        // color: violet;
        }
    ${media.desktopLg}{
        font-size: 2.3vw;
        // color: green;
        }
`
// FIN M65 --- PRESENTACIÓN RESTA --- M65



// EXAMPLE
export const Buttonsx = styled.button`
    padding:10px 60px 10px 60px;
    border-radius: 10px;
    border:0;
    background:#23AAAF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color:white;
    font-weight: 600;
    font-size: 16px;
    margin:${props => props.margin};
    &:hover{
        background: #78e2db;
    }
`

    export const Cursor = styled.div`
    // background: red;
    `

    export const NumerosFlexxx = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        // width: 410px;
        // height: 400px;
        width: fit-content;
        padding: 20px 0px 0px 40px;
        // background: pink;
        cursor: url(/img/mas_menos_diez3.png), auto;

        ${media.min}{
            cursor: url(/img/mas_menos_diez2.png), auto;
            // background-color: orange;  
            }
        ${media.tablet}{
            // background-color: blue; 
            cursor: url(/img/mas_menos_diez.png), auto;
            }
        ${media.desktop}{
            // background-color: violet; 
            cursor: url(/img/mas_menos_diez.png), auto;
            }
        ${media.desktopLg}{
            // background-color: green; 
            cursor: url(/img/mas_menos_diez.png), auto;
            width: 600px;
            height: 400px;
            }
    `
