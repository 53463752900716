import React, {Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { aumentarContadorAction, aumentarContadorTerciarioAction, zeroContadorTerciarioAction } from '../../../../../../actions/botoneraEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, InputM83Bis, ImgB10, NumRedondoTachado, PasosSubT, DivM83, NumCuadradoTachado, PasosT, Pasos, NumRedondo, NumCuadrado, InputCuadrado, InputRedondo, NumberDivR, NumberDivL, ImgDiv, NumCalculoM83, AvanzarBtn, AvanzarImg, FormM83 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M84Bis = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [disableUno, setDisableUno] = useState(false);
    const [disableDos, setDisableDos] = useState(false);
    const [disableTres, setDisableTres] = useState(false);
    const [disableCuatro, setDisableCuatro] = useState(false);
    const [disableCinco, setDisableCinco] = useState(false);
    const [disableSeis, setDisableSeis] = useState(false);
    const [disableOcho, setDisableOcho] = useState(false);
    const [disableNueve, setDisableNueve] = useState(false);
    const [disableDiez, setDisableDiez] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState('');
    const [digitoD1, setDigitoD1] = useState('');
    const [digitoU2, setDigitoU2] = useState('');
    const [digitoD2, setDigitoD2] = useState('');
    const [digitoIntermedioD1, setDigitoIntermedioD1] = useState('');
    const [digitoIntermedioU1, setDigitoIntermedioU1] = useState('');
    const [digitoFinalUno, setDigitoFinalUno] = useState('');
    const [digitoFinalDos, setDigitoFinalDos] = useState('');
    const [numeroUnidadUno, setNumeroUnidadUno] = useState('');
    const [numeroUnidadDos, setNumeroUnidadDos] = useState('');
    const [numeroDecenaUno, setNumeroDecenaUno] = useState('');
    const [numeroDecenaDos, setNumeroDecenaDos] = useState('');
    const [numeroIntermedioD1, setNumeroIntermedioD1] = useState('');
    const [numeroIntermedioU1, setNumeroIntermedioU1] = useState('');
    const [numeroFinalUno, setNumeroFinalUno] = useState('');
    const [numeroFinalDos, setNumeroFinalDos] = useState('');
    const [numeroFinal, setNumeroFinal] = useState('');
    const [numeroFinal2, setNumeroFinal2] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');
    const [forzarAlmacenar, setForzarAlmacenar] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorTerciario = useSelector(state => state.ejercicio.contadorTerciario);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const verificar = (e) => {
        e.preventDefault();
        if(contadorTerciario === 1){
            if( numeroDecenaUno.toString() !== digitoD1.toString()) {
                console.log('mal');
                setDisableUno(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableUno(false);
                    if(numeroDecenaUno.toString() !== digitoD1.toString()) {
                        setNumeroDecenaUno(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableUno(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 2){
            if(numeroUnidadUno.toString() !== digitoU1.toString()) {
                console.log('mal');
                setDisableDos(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableDos(false);
                    if(numeroUnidadUno.toString() !== digitoU1.toString()) {
                        setNumeroUnidadDos(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableDos(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 3){
            if( numeroDecenaDos.toString() !== digitoD2.toString()) {
                console.log('mal');
                setDisableTres(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableTres(false);
                    if(numeroDecenaDos.toString() !== digitoD2.toString()) {
                        setNumeroDecenaDos(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableTres(true);
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 4){
            if(numeroUnidadDos.toString() !== digitoU2.toString()) {
                console.log('mal');
                setDisableCuatro(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableCuatro(false);
                    if(numeroUnidadDos.toString() !== digitoU2.toString()) {
                        setNumeroUnidadDos(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableCuatro(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 5){
            if(numeroIntermedioD1.toString() !== digitoIntermedioD1.toString()) {
                console.log('mal');
                setDisableCinco(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableCinco(false);
                    if(numeroIntermedioD1.toString() !== digitoIntermedioD1.toString()) {
                        setNumeroIntermedioD1(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableCinco(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 6){
            if(numeroIntermedioU1.toString() !== digitoIntermedioU1.toString()) {
                console.log('mal');
                setDisableSeis(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableSeis(false);
                    if(numeroIntermedioU1.toString() !== digitoIntermedioU1.toString()) {
                        setNumeroIntermedioU1(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableSeis(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 8){
            if(digitoFinalDos.toString() === numeroFinalDos.toString()) {
                console.log("bien");
                setDisableOcho(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableOcho(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableOcho(false);
                    setNumeroFinalDos(0);
                }, 300);
            }
        }
        if(contadorTerciario === 9){
            if(digitoFinalUno.toString() === numeroFinalUno.toString()) {
                console.log("bien");
                setDisableNueve(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableNueve(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableNueve(false);
                    setNumeroFinalUno(0);
                }, 300);
            }
        }
        if(contadorTerciario === 10){
            if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() === numeroFinal.toString()) {
                console.log("bien");
                setDisableDiez(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableDiez(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableDiez(false);
                    setNumeroFinal(0);
                }, 300);
            }
        }
        if(contadorTerciario === 11){
            if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() === numeroFinal.toString()) {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setNumeroFinal(0);
                        setNumeroFinal2(0);
                        setCount(count + 1);
                        dispatch( zeroContadorTerciarioAction() );
                    }, 300);
                } else {
                    setTimeout(() => {
                        if(props.mostrar){
                            setUltimoTiempo(true);
                            setForzarAlmacenar(true);
                            dispatch(aumentarContadorAction());
                            dispatch( zeroContadorTerciarioAction() );
                        } else {
                            setUltimoTiempo(true);
                            dispatch( finalizarEjercicioAction() );
                            dispatch( zeroContadorTerciarioAction() );
                        }
                    }, 1500);
                }
            } else {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setNumeroFinal2(0);
                }, 300);
            }
        }
    }

    useEffect(() => {
        const randomNumeroUno = (function () {
            const minU = 0;
            const maxU = 8;
            const u = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 2;
            const maxD = 9;
            const d = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d.toString().concat(u.toString()));
            return numero;
        })();
        const randomNumeroDos = (function () {
            const u1 = Number(randomNumeroUno.toString().substring(1,2));
            const d1 = Number(randomNumeroUno.toString().substring(0,1));
            const minU = u1+1;
            const maxU = 9;
            const u2 = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 1;
            const maxD = d1-1;
            const d2 = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d2.toString().concat(u2.toString()));
            return numero;
        })();
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('resta_descomposicion_compleja');
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(newList.cantidad_1.toString().substring(1,2));
            setDigitoD1((Number(newList.cantidad_1.toString().substring(0,1))*10).toString());
        }
        if(newList.cantidad_1.toString().length === 1 ){
            setDigitoU1(newList.cantidad_1.toString().substring(0,1));
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(newList.cantidad_2.toString().substring(1,2));
            setDigitoD2((Number(newList.cantidad_2.toString().substring(0,1))*10).toString());
        }
        if(newList.cantidad_2.toString().length === 1 ){
            setDigitoU2(newList.cantidad_2.toString().substring(0,1));
        }
        setDigitoIntermedioD1(newList.cantidad_1.toString().substring(1,2));
        setDigitoIntermedioU1((Number(newList.cantidad_2.toString().substring(1,2)) - Number(newList.cantidad_1.toString().substring(1,2))).toString());
        setDigitoFinalUno((Number(newList.cantidad_2.toString().substring(1,2)) - Number(newList.cantidad_1.toString().substring(1,2))).toString());
        setDigitoFinalDos(((Number(newList.cantidad_1.toString().substring(0,1))*10) - (Number(newList.cantidad_2.toString().substring(0,1))*10)).toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M84Bis') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-descomposicion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar || forzarAlmacenar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M84Bis",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, forzarAlmacenar]);
    
    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment>
                    {contadorTerciario >= 0 &&
                    <FlexFull justify='space-evenly' align='center'>
                        <FlexFull direction='column' justify='space-around' align='start' width='48vw' height='60vh' hlg='70vh'>
                            <FlexFull direction='column' justify='center' align='start' width='48vw'>
                                {/* CALCULO */}
                                <FlexFull justify='end' align='center' width='100%'> {/*  border='2px solid blue' */} 
                                    <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                        <NumCalculoM83 color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculoM83>
                                        <NumCalculoM83 color='#646464'>-</NumCalculoM83>                                     
                                        <NumCalculoM83 color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculoM83>
                                        <NumCalculoM83 color='#646464'>=</NumCalculoM83>  
                                        {contadorTerciario >= 11 && 
                                        <FormM83 color='#646464'
                                            onSubmit={(e) => onSubmit(e)}
                                        >
                                            <InputM83Bis type="numeric" value={numeroFinal2} onChange={(e) => setNumeroFinal2(e.target.value)} disabled={disable} />
                                        </FormM83>
                                        }
                                    </FlexFull>
                                </FlexFull>
                                {/* FIN CALCULO */}

                                {/* DESCOMPOSICIÓN */}
                                <FlexFull justify='space-around' align='center' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='-18px 0vw 0vh 15px' > {/* width='35vw' border='3px solid blue' */}
                                    {contadorTerciario >= 1 &&
                                    <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 2vw 0vh 0vw' mt='0px 1.5vw 0vh 0px' md='0px 1.2vw 0vh 0px' mlg='0px 2.3vw 0vh 0px'> { /* border='3px solid violet'     mt='0vh 1.5vw 0vh 0vw' mlg='0px' */}
                                    <div>
                                        <ImgDiv>
                                            <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                        </ImgDiv>
                                        {contadorTerciario >= 1 &&
                                        <Flex>
                                            <NumberDivL>
                                                {contadorTerciario >= 1 &&
                                                <NumRedondo>
                                                    <InputRedondo type="numeric" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disableUno} />
                                                </NumRedondo>
                                                }
                                            </NumberDivL>
                                            <NumberDivR>
                                                {contadorTerciario >= 2 && contadorTerciario <= 6 ?
                                                    <InputCuadrado type="numeric" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disableDos} /> :
                                                    contadorTerciario <= 1 ?
                                                    null :
                                                    <NumCuadradoTachado>{numeroUnidadUno}</NumCuadradoTachado>
                                                }
                                            </NumberDivR>
                                        </Flex>
                                        }
                                    </div>
                                    </Flex>
                                    }
                                    <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 0vw 0vh 2vw' mlg='0px'>  { /*   margin='0vh 0vw 0vh 4vw'   border='1px solid green' */}
                                    {contadorTerciario >= 3 &&
                                    <div>
                                        <ImgDiv>
                                            <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                        </ImgDiv>
                                        <Flex>
                                            <NumberDivL>
                                                {contadorTerciario >= 3 &&
                                                <NumRedondo>
                                                    <InputRedondo type="numeric" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disableTres} />
                                                </NumRedondo>
                                                }
                                            </NumberDivL>
                                            <NumberDivR>
                                                {contadorTerciario >= 4 &&
                                                <NumCuadrado>
                                                    <InputCuadrado type="numeric" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disableCuatro} /> 
                                                </NumCuadrado>
                                                }
                                            </NumberDivR>
                                        </Flex>
                                    </div>
                                    }
                                    </Flex>
                                </FlexFull>
                                {/* ------------NUEVO */}
                                <FlexFull justify='end' align='center' width='32.3vw' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px'>  { /* border='1px solid green' */}
                                    <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='-23px -7.8vw 0vh 0vw' mm='-33px -7vw 0vh 0px' mt='-37px -5.2vw 0vh 0px' md='-43px -5vw 0vh 0px' mlg='-33px -45px 0vh 0px'>
                                        {contadorTerciario >= 5 &&
                                        <div>
                                            <ImgDiv>
                                                <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='11vh' hm='10vh' hlg='15vh' />
                                            </ImgDiv>
                                            <Flex>
                                                <NumberDivL>
                                                    {contadorTerciario >= 5 && contadorTerciario <= 6 ?
                                                        <InputCuadrado type="numeric" value={numeroIntermedioD1} onChange={(e) => setNumeroIntermedioD1(e.target.value)} disabled={disableCinco} /> :
                                                        contadorTerciario <= 4 ?
                                                        null :
                                                        <NumRedondoTachado>{numeroIntermedioD1}</NumRedondoTachado>
                                                    }
                                                </NumberDivL>
                                                <NumberDivR>
                                                    {contadorTerciario >= 6 &&
                                                    <InputCuadrado type="numeric" value={numeroIntermedioU1} onChange={(e) => setNumeroIntermedioU1(e.target.value)} disabled={disableSeis} />
                                                    }
                                                </NumberDivR>
                                            </Flex>
                                        </div>
                                        }
                                    </Flex>
                                </FlexFull>
                                {/* FIN DESCOMPOSICIÓN */}
                            </FlexFull>

                            {/* CALCULO ABAJO */}
                            <FlexFull justify='center' align='center' width='100%'> {/*  border='2px solid blue' */} 
                                <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                <DivM83>
                                    <div>
                                        {contadorTerciario >= 8 &&
                                        <InputRedondo type="numeric" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disableOcho} />
                                        }
                                    </div>
                                </DivM83>
                                <DivM83>
                                {contadorTerciario >= 10 &&
                                    <NumCalculoM83 color='#646464'>-</NumCalculoM83>
                                }
                                </DivM83>
                                <DivM83>
                                {contadorTerciario >= 9 &&
                                    <div>
                                        {contadorTerciario >= 9 &&
                                        <InputCuadrado type="numeric" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disableNueve} />
                                        }
                                    </div>
                                }
                                </DivM83>
                                <DivM83>
                                {contadorTerciario >= 10 &&
                                    <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                }
                                </DivM83>
                                <DivM83>
                                {contadorTerciario >= 10 &&  
                                    <InputCuadrado type="numeric" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disableDiez} />
                                }
                                </DivM83>
                                </FlexFull>
                            </FlexFull>
                            {/* FIN CALCULO ABAJO */}
                        </FlexFull>
                            
                        {/* PASOS */}
                        <FlexFull direction='column' justify='start' align='center' width='25vw' height='80vh'>
                        <PasosT>
                            PASOS
                        </PasosT>
                        <PasosSubT>
                            RESTA POR DESCOMPOSICIÓN
                        </PasosSubT>
                            {contadorTerciario >= 1 &&
                                <Pasos bgcolor='#D76970'>
                                    1- DESCOMPONGO EL PRIMER NÚMERO
                                </Pasos>
                            }
                            {contadorTerciario >= 3 &&
                                <Pasos bgcolor='#23AAAF'>
                                    2- DESCOMPONGOO EL SEGUNDO NÚMERO
                                </Pasos>
                            }
                            {contadorTerciario >= 4 &&
                                <Pasos bgcolor='#FF9B2A'>
                                    3- ¿PUEDO RESTAR LAS UNIDADES?
                                </Pasos>
                            }
                            {contadorTerciario >= 5 &&
                                <Pasos bgcolor='#FFDC73'>
                                    4- DESCOMPONGO EL NÚMERO UTILIZANDO DE GUÍA LAS UNIDADES DEL NÚMERO ANTERIOR
                                </Pasos>
                            }
                            {contadorTerciario >= 7 &&
                                <Pasos bgcolor='#655EE8'>
                                    5- RESTO LAS UNIDADES
                                </Pasos>
                            }
                        {contadorTerciario >= 8 &&
                            <Pasos bgcolor='#D76970'>
                                6- RESTO LAS DECENAS
                            </Pasos>
                        }
                        {contadorTerciario >= 9 &&
                            <Pasos bgcolor='#23AAAF'>
                                7- ESCRIBO LAS UNIDADES
                            </Pasos>
                        }
                        {contadorTerciario >= 10 &&
                            <Pasos bgcolor='#FF9B2A'>
                                8- HAGO LA RESTA
                            </Pasos>
                        }
                        {contadorTerciario >= 11 &&
                            <Pasos bgcolor='#FFDC73'>
                                9- ¿CUÁL ES EL RESULTADO?
                            </Pasos>
                        }
                        </FlexFull>
                        {/* FIN PASOS */}


                        {/* --------------------------------- */}
                    </FlexFull>
                    }
                    <FlexCuz  position='fixed' right='0' bottom='0'>
                    {contadorTerciario === 0 && <BotoneraBottom aumentarTerciario={true} />}
                    {contadorTerciario === 7 && <BotoneraBottom aumentarTerciario={true} />}
                    {contadorTerciario === 1 || contadorTerciario === 2 || contadorTerciario === 3 || contadorTerciario === 4 || contadorTerciario === 5  || contadorTerciario === 6 || contadorTerciario === 8 || contadorTerciario === 9 || contadorTerciario === 10 || contadorTerciario === 11 ?
                    <FlexCuz  position='fixed' right='0' bottom='0'>
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    :null
                    }
                    </FlexCuz>
                    
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M84Bis;