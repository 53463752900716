import React, {Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';

// css
import {Palabra, Flex, ImgBottom} from '../../../../Css/MetodosCSS/StyledCCLexica';
import { ContInst } from '../../../../Css/MetodosCSS/StyledFonemica';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

const miniInstrucciones = 'Esta actividad es una presentación, el objetivo es mostrarle al niño/a como simbolizar una palabra larga y una palabra corta.';

const LEN15 = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN15') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-lexica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            <Flex direction="column" height="100%" width="100%">
                <Flex margin="auto">
                    <Palabra background="#FF9B2A" margin="0rem 2rem 0rem 0rem" padding="20px">LA</Palabra>
                    <Palabra background="#23AAAF" margin="0rem 0rem 0rem 0rem" padding= "20px 50px">MESA</Palabra>
                </Flex>
                <Flex align="center" justify="flex-end" width="90%" margin="0 0 1rem 0">
                    <Link to={`/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/`}><ImgBottom src={`/img/icono_avanzar.png`} alt="" right="60px" bottom="60px"/></Link>
                </Flex>
            </Flex>
        </Fragment>
    );
}
 
export default LEN15;