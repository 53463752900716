import {
    ALMACENAR,
    ALMACENAR_EXITO,
    ALMACENAR_ERROR,
    SOLICITAR_HISTORIA_CLINICA_EXITO,
    SOLICITAR_ACTIVIDADES_AGENDADAS_EXITO,
    ALMACENAR_CHECK_EJERCICIO_EXITO
} from '../types';
import clienteAxios from '../config/axios';
import { setearSesionAction } from './estadoEjercicioActions';

/* Almaceno información en la base de datos */
export function almacenarAction(informacion) {
    return async (dispatch) => {
        dispatch (almacenar());
        try {
            const {config, payload} = informacion;
            if(payload.id_paciente === 'TEST'){
                return;
            }
            const respuesta = await clienteAxios.post('/almacenar', payload, config);
            dispatch( almacenarExito());
            if(respuesta.data.historia_clinica){
                dispatch( historiaClinicaSolicitarExito(respuesta.data.historia_clinica));
            }
            if(respuesta.data.actividadesCorregido){
                const newList = JSON.parse(JSON.stringify(respuesta.data.actividadesCorregido));
                const sesion = newList.filter(item => item.sesion === payload.sesion[0].sesion);
                const informacion = {
                    sesion
                }
                dispatch(setearSesionAction(informacion));
                dispatch( actividadesAgendadasSolicitarExito(respuesta.data.actividadesCorregido));
            }
        } catch (error) {
            console.log(error)
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( almacenarError(error.response.data) );
            }
        }
    }
}

export function almacenarCheckEjerciciosAction(informacion) {
    return async (dispatch) => {
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/modificarlistaejercicios', payload, config);
            dispatch(almacenarCheckEjerciciosExito(data.informacion))
        } catch (error) {
            const errores = error.response;
            console.log(errores)
        }
    }
}

const almacenar = () => ({
    type: ALMACENAR,
    payload: true
});

const almacenarExito = () => ({
    type: ALMACENAR_EXITO
});

// si hubo un error
const almacenarError = (msg) => ({
    type: ALMACENAR_ERROR,
    payload: msg
});

const historiaClinicaSolicitarExito = (data) => ({
    type: SOLICITAR_HISTORIA_CLINICA_EXITO,
    payload: data
});

const actividadesAgendadasSolicitarExito = (data) => ({
    type: SOLICITAR_ACTIVIDADES_AGENDADAS_EXITO,
    payload: data
});

const almacenarCheckEjerciciosExito = (data) => ({
    type: ALMACENAR_CHECK_EJERCICIO_EXITO,
    payload: data
});