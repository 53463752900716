import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import { Flex, BtnNumberM9, Img, DescriptionEstNoc } from '../../../../Css/StyledMatematica';

const instrucciones = 'Selecciona el dibujo correcto.';
const miniInstrucciones = 'Selecciona el dibujo correcto.';

const M17 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    
    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, cant) => {
        e.preventDefault();
        if(cant.toString() === comparar.correcta.toString()) {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes){
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() )                        
                }, 1000);
            }
        } else {
            console.log('mal')
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
            }, 300);
        }
    }
    
    useEffect(() => {
        dispatch(setearEjercicioAction('M17') );
        dispatch(setearUrlAnteriorAction('matematica/estimacion-y-nocion-de-cantidad/nocion-de-cantidad/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M17",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
        {iniciar
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex direction="column" margin="auto" width="100%">
                    <DescriptionEstNoc>
                        {comparar.frase}
                    </DescriptionEstNoc>
                    <Flex
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <BtnNumberM9
                            disabled={disable}
                            onClick={(e) => verificar(e, comparar.cantidad_1)}
                        >   
                            <Img src={`/img/${comparar.imagen_url_1}.png`} width='32vw' wm='25vw' wt='18vw' wd='15vw' wlg='13vw' margin='2vh 0px 0px 0px' />
                        </BtnNumberM9>
                        <BtnNumberM9
                            disabled={disable}
                            onClick={(e) => verificar(e, comparar.cantidad_2)}
                        >   
                            <Img src={`/img/${comparar.imagen_url_2}.png`} width='32vw' wm='25vw' wt='18vw' wd='15vw' wlg='13vw' margin='2vh 0px 0px 0px' />
                        </BtnNumberM9>
                    </Flex>
                </Flex>
            </Flex>
            :
            <Instrucciones instrucciones={instrucciones} />
        }
        </Fragment>
    );
}
 
export default M17;