import React, {Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import AyudaEstructuraOperaciones from '../../Suma/AyudaEstructuraOperaciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, InputM83Bis, ImgB10, DivM83, NumRedondo, NumCuadrado, InputCuadrado, InputRedondo, NumberDivR, NumberDivL, ImgDiv, NumCalculoM83, AvanzarBtn, AvanzarImg } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M84Practica = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState('');
    const [digitoD1, setDigitoD1] = useState('');
    const [digitoU2, setDigitoU2] = useState('');
    const [digitoD2, setDigitoD2] = useState('');
    const [digitoFinalUno, setDigitoFinalUno] = useState('');
    const [digitoFinalDos, setDigitoFinalDos] = useState('');
    const [digitoIntermedioD1, setDigitoIntermedioD1] = useState('');
    const [digitoIntermedioU1, setDigitoIntermedioU1] = useState('');
    const [numeroUnidadUno, setNumeroUnidadUno] = useState('');
    const [numeroUnidadDos, setNumeroUnidadDos] = useState('');
    const [numeroDecenaUno, setNumeroDecenaUno] = useState('');
    const [numeroDecenaDos, setNumeroDecenaDos] = useState('');
    const [numeroIntermedioD1, setNumeroIntermedioD1] = useState('');
    const [numeroIntermedioU1, setNumeroIntermedioU1] = useState('');
    const [numeroFinalUno, setNumeroFinalUno] = useState('');
    const [numeroFinalDos, setNumeroFinalDos] = useState('');
    const [numeroFinal, setNumeroFinal] = useState('');
    const [numeroFinal2, setNumeroFinal2] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const verificar = (e) => {
        e.preventDefault();
        if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal2.toString() || numeroIntermedioU1.toString() !== digitoIntermedioU1.toString() || numeroIntermedioD1.toString() !== digitoIntermedioD1.toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() !== numeroFinalDos.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numeroFinal2.toString() || numeroIntermedioU1.toString() !== digitoIntermedioU1.toString() || numeroIntermedioD1.toString() !== digitoIntermedioD1.toString()) {
                    setNumeroUnidadUno(0);
                    setNumeroDecenaUno(0);
                    setNumeroUnidadDos(0);
                    setNumeroDecenaDos(0);
                    setNumeroIntermedioD1(0);
                    setNumeroIntermedioU1(0);
                    setNumeroFinalUno(0);
                    setNumeroFinalDos(0);
                    setNumeroFinal(0);
                    setNumeroFinal2(0);
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumeroUnidadUno(0);
                    setNumeroDecenaUno(0);
                    setNumeroUnidadDos(0);
                    setNumeroDecenaDos(0);
                    setNumeroIntermedioD1(0);
                    setNumeroIntermedioU1(0);
                    setNumeroFinalUno(0);
                    setNumeroFinalDos(0);
                    setNumeroFinal(0);
                    setNumeroFinal2(0);
                    setDigitoU1(0);
                    setDigitoD1(0);
                    setDigitoU2(0);
                    setDigitoD2(0);
                    setDigitoIntermedioD1(0);
                    setDigitoIntermedioU1(0);
                    setDigitoFinalUno(0);
                    setDigitoFinalDos(0);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                }, 1500);
            }
        }
    }

    useEffect(() => {
        const randomNumeroUno = (function () {
            const minU = 0;
            const maxU = 8;
            const u = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 2;
            const maxD = 9;
            const d = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d.toString().concat(u.toString()));
            return numero;
        })();
        const randomNumeroDos = (function () {
            const u1 = Number(randomNumeroUno.toString().substring(1,2));
            const d1 = Number(randomNumeroUno.toString().substring(0,1));
            const minU = u1+1;
            const maxU = 9;
            const u2 = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 1;
            const maxD = d1-1;
            const d2 = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d2.toString().concat(u2.toString()));
            return numero;
        })();
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('resta_descomposicion_compleja');
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(newList.cantidad_1.toString().substring(1,2));
            setDigitoD1((Number(newList.cantidad_1.toString().substring(0,1))*10).toString());
        }
        if(newList.cantidad_1.toString().length === 1 ){
            setDigitoU1(newList.cantidad_1.toString().substring(0,1));
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(newList.cantidad_2.toString().substring(1,2));
            setDigitoD2((Number(newList.cantidad_2.toString().substring(0,1))*10).toString());
        }
        if(newList.cantidad_2.toString().length === 1 ){
            setDigitoU2(newList.cantidad_2.toString().substring(0,1));
        }
        setDigitoIntermedioD1(newList.cantidad_1.toString().substring(1,2));
        setDigitoIntermedioU1((Number(newList.cantidad_2.toString().substring(1,2)) - Number(newList.cantidad_1.toString().substring(1,2))).toString());
        setDigitoFinalUno((Number(newList.cantidad_2.toString().substring(1,2)) - Number(newList.cantidad_1.toString().substring(1,2))).toString());
        setDigitoFinalDos(((Number(newList.cantidad_1.toString().substring(0,1))*10) - (Number(newList.cantidad_2.toString().substring(0,1))*10)).toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M84Practica') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-descomposicion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M84Practica",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'ayuda_visual': ayuVisualSolicitada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);
    
    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment>
                    <FlexFull justify='space-evenly' align='center'>
                        <FlexFull direction='column' justify='space-around' align='start' width='48vw' height='75vh'>
                        {/* CALCULO */}
                        {comparar.length === 0 ? null :
                        <FlexFull justify='end' align='center' width='100%'> {/*  border='2px solid blue' */} 
                            <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                <NumCalculoM83 color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculoM83>
                                <NumCalculoM83 color='#646464'>-</NumCalculoM83>                                     
                                <NumCalculoM83 color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculoM83>
                                <NumCalculoM83 color='#646464'>=</NumCalculoM83>         
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <InputM83Bis type="numeric" value={numeroFinal2} onChange={(e) => setNumeroFinal2(e.target.value)} disabled={disable} />
                                </form>
                            </FlexFull>
                        </FlexFull>
                        }
                        {/* FIN CALCULO */}

                        {/* DESCOMPOSICIÓN */}
                        <FlexFull justify='space-around' align='center' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='-18px 0vw 0vh 15px'> {/* border='3px solid blue' */}
                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 2vw 0vh 0vw' mt='0px 1.5vw 0vh 0px' md='0px 1.2vw 0vh 0px' mlg='0px 2.3vw 0vh 0px'> { /* border='3px solid violet' */}
                                <div>
                                    <ImgDiv>
                                        <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                    </ImgDiv>
                                    <Flex>
                                        <NumberDivL>
                                            <NumRedondo>
                                                <InputRedondo type="numeric" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disable} />
                                            </NumRedondo>
                                        </NumberDivL>
                                    
                                        <NumberDivR>
                                            <NumCuadrado>
                                                <InputCuadrado type="numeric" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disable} />
                                            </NumCuadrado>
                                        </NumberDivR>
                                    </Flex>
                                </div>
                            </Flex>
                                    <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 0vw 0vh 2vw' mlg='0px'>  { /*   margin='0vh 0vw 0vh 4vw'   border='1px solid green' */}
                                    <div>
                                        <ImgDiv>
                                            <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                        </ImgDiv>
                                        <Flex>
                                            <NumberDivL>
                                                <NumRedondo>
                                                    <InputRedondo type="numeric" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disable} />
                                                </NumRedondo>
                                            </NumberDivL>
                                            <NumberDivR>
                                                <NumCuadrado>
                                                    <InputCuadrado type="numeric" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disable} />
                                                </NumCuadrado>
                                            </NumberDivR>
                                        </Flex>
                                    </div>
                                    </Flex>
                        </FlexFull>
                        {/* FIN DESCOMPOSICIÓN */}
                        {/* ------------INTERMEDIOS */}
                        <FlexFull justify='end' align='center' width='32.3vw' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px'>  { /* border='1px solid green' */}
                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='-23px -7.8vw 0vh 0vw' mm='-33px -7vw 0vh 0px' mt='-37px -5.2vw 0vh 0px' md='-43px -5vw 0vh 0px' mlg='-33px -45px 0vh 0px'>
                                <div>
                                    <ImgDiv>
                                        <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='11vh' hm='10vh' hlg='15vh' />
                                    </ImgDiv>
                                    <Flex>
                                        <NumberDivL>
                                                <InputCuadrado type="numeric" value={numeroIntermedioD1} onChange={(e) => setNumeroIntermedioD1(e.target.value)} disabled={disable} />
                                        </NumberDivL>
                                        <NumberDivR>
                                            <InputCuadrado type="numeric" value={numeroIntermedioU1} onChange={(e) => setNumeroIntermedioU1(e.target.value)} disabled={disable} />
                                        </NumberDivR>
                                    </Flex>
                                </div>
                            </Flex>
                        </FlexFull>
                        {/* ------------FIN INTERMEDIOS */}

                        {/* CALCULO ABAJO */}
                        <FlexFull justify='center' align='center' width='100%'> {/*  border='2px solid blue' */} 
                            <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                <DivM83>
                                    <div>
                                        <InputRedondo type="numeric" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} />
                                    </div>
                                </DivM83>
                                <DivM83>
                                    <NumCalculoM83 color='#646464'>-</NumCalculoM83>
                                </DivM83>
                                <DivM83>
                                    <div>
                                        <InputCuadrado type="numeric" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                    </div>
                                </DivM83>
                                <DivM83>
                                    <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                </DivM83>
                                <DivM83>
                                    <InputCuadrado type="numeric" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                </DivM83>
                            </FlexFull>
                        </FlexFull>
                        {/* FIN CALCULO ABAJO */}
                        </FlexFull>
                        <FlexFull direction='column' justify='center' align='center' width='25vw' height='80vh'>
                            <AyudaEstructuraOperaciones tipo={tipoEjercicio} />
                        </FlexFull>
                    </FlexFull>

                    <FlexCuz  position='fixed' right='0' bottom='0'>
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M84Practica;