import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useColorHelp from '../../../../../Hooks/useColorHelp';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlex } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { BtnSilaba } from '../../../../Css/MetodosCSS/StyledLN3Todos';
import { BtnElegir, ContInst } from '../../../../Css/MetodosCSS/StyledEjercicios';

const instrucciones = 'Indica si la siguiente palabra existe.';
const miniInstrucciones = 'Indica si la siguiente palabra existe.';

const LN14 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [disable, setDisable] = useState(false);
    const [count, setCount] = useState(1);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [arrayColor, transformarColor] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    useEffect(() => {
        if(comparar === undefined || comparar.length === 0){
            return;
        }
        transformarColor(comparar.palabra_1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comparar, ayuda_lectura])

    const verificar = (e, resultado) => {
        e.preventDefault();
        if (comparar.existe && resultado === "si") {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if (count < indexes) {
                setCount(count + 1);
                setDisable(false);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1500);
            }
        } else if (!comparar.existe && resultado === "no") {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if (count < indexes) {
                setCount(count + 1);
                setDisable(false);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1500);
            }
        } else {
            setDisable(true);
            console.log('mal')
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setDisable(false);
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LN14') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/ejercicios/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LN14",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <ContainerFlex height="85vh">
                    <ContainerFlex height="75vh" display="flex" direction="column" justify="space-evenly" align="center">
                        <BtnSilaba padding="1rem 4rem" font="4rem" $minMay={minuscula}
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}>
                            {arrayColor === '' ? null :
                                arrayColor.map((item, i) =>
                                    <span
                                    key={i}
                                    >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                    </span>
                                )
                            }
                        </BtnSilaba>

                        <ContainerFlex>
                            <BtnElegir width="100px" height="70px" color="white" bg="#F3D946" font="3rem" margin="0 1rem 0 1rem"
                                disabled={disable}
                                onClick={(e) => verificar(e, "si")}
                            >
                                Si
                            </BtnElegir>
                            <BtnElegir width="100px" height="70px" color="white" bg="#F3D946" font="3rem" margin="0 1rem 0 1rem"
                                disabled={disable}
                                onClick={(e) => verificar(e, "no")}
                            >
                                No
                            </BtnElegir>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContInst><BotoneraBottom miniInstrucciones={miniInstrucciones} /></ContInst>
                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN14;