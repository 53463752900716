import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';

import useConfigAuth from '../../Hooks/useConfigAuth';
import { solicitudNuevoProfesionalAction } from '../../actions/solicitudNuevoProfesionalActions';
import { activarMetodoAction, darDeBajaMetodoAction } from '../../actions/gestionarMetodoActions';
import { enviarInvitacionPacienteAction } from '../../actions/invitarPacienteActions';
import { actualizarPacientesAction, cargarPacientesAction } from '../../actions/cargarPacientesActions';
import { estadoCorrectoInitialAction } from '../../actions/resetStoreActions';

//css
import {Cuerpo, FlexColumn, FlexBox } from '../Css/StyledHeader.js';
import {Fondo, BotonNaranjaButton, Text} from '../Css/StyledPatient.js';

const GestionarMetodo = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [config] = useConfigAuth();

    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const pacientes = useSelector((state) => state.informacion.lista_pacientes);
    const correcto = useSelector((state) => state.estado.correcto);
    const cantidad_suscripciones = useSelector((state) => state.usuario.cantidad_suscripcion);
    const {servicio, email, email_app, solicitud_tutor, bloqueo_metodo, t_inv_restante} = pacientes.find(item => item.id_paciente === id_paciente);

    const [disable, setDisable] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [actualizarInfo, setActualizarInfo] = useState(false);

    const cantidadSuscriptos = pacientes.filter(item => item.servicio === "Método Yögen");
    const suscripcionesRestantes = cantidad_suscripciones - cantidadSuscriptos.length;

    const enviarSolicitudTutores = (e) => {
        e.preventDefault();
        const informacion = {
            config,
            payload: {
                email
            }
        }
        dispatch( solicitudNuevoProfesionalAction(informacion) );
        setActualizarInfo(true);
    }

    const invitarPaciente = (e) => {
        e.preventDefault();
        setDisable(true);
        const informacion = {
            config,
            payload: {
                id_paciente
            }
        }
        dispatch( enviarInvitacionPacienteAction(informacion) );
    }

    const darDeBaja = (e) => {
        e.preventDefault();
        setDisable(true);
        const informacion = {
            config,
            payload: {
                id_paciente
            }
        }
        dispatch( darDeBajaMetodoAction(informacion) );
        setActualizar(true);
    }

    const activarMetodo = (e) => {
        e.preventDefault();
        setDisable(true);
        const informacion = {
            config,
            payload: {
                id_paciente
            }
        }
        dispatch( activarMetodoAction(informacion));
        setActualizar(true);
    }

    useEffect(() => {
        if(!correcto){
            return;
        }
        const informacion = {
            config
        }
        if(actualizar) {
            dispatch (cargarPacientesAction(informacion));
        }
        if(actualizarInfo){
            const newList = JSON.parse(JSON.stringify(pacientes));
            const index = newList.findIndex(item => item.id_paciente === id_paciente);
            newList[index].solicitud_tutor = true;
            dispatch (actualizarPacientesAction(newList));
        }
        dispatch(estadoCorrectoInitialAction())
        navigate('/escritorio/pacientes');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correcto]);

    return (
        <Cuerpo>
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes/paciente`} componente={"Gestionar Método"} paciente={true} />
            <FlexColumn align="center">
            <div>
                {servicio === "Solo Administrativo"
                    ?
                    <Fondo>
                        {email_app ?
                        <div>
                            <Text font="14px" margin="20px 0px 50px 20px">El email {email} del paciente corresponde a un paciente registrado. En caso de que sea correcto envíe la solicitud a los tutores apretando el siguiente botón. Caso contrario cambie el email del paciente.</Text>
                            <button
                                disabled={solicitud_tutor ? true : false}
                                onClick={(e) => enviarSolicitudTutores(e)}
                            >
                                {solicitud_tutor ? `La solicitud a los padres fue enviada` :  `Enviar solicitud a padres` }
                            </button>
                        </div>
                        :
                        <div>
                            {!email ?
                            <div>
                                <Text font="14px" margin="20px 0px 50px 20px">El paciente no tiene email cargado. Por favor vaya al perfil del mismo y agréguelo. De lo contrario no podrá ingresar a las actividades, con este usuario, para realizar el tratamiento</Text>
                                <button
                                disabled={true}
                                onClick={(e) => invitarPaciente(e)}
                                >
                                    Invitar Paciente
                                </button>
                            </div>
                            :
                            <div>
                                <Text font="14px" margin="20px 0px 50px 20px">El email del paciente no se encuentra registrado. En caso de que el paciente ya tenga un usuario, pregunte el email, modifíquelo en el perfil y intente nuevamente. <br /> Si el paciente no tiene usuario, envíe una invitación al email del paciente. Los tutores deberán crear el perfil y sus cuentas. <br /> <b>{t_inv_restante > 0 ? `Se ha enviado una invitación restan ${t_inv_restante} horas para poder enviar nuevamente`: null}</b></Text>
                                <button
                                disabled={t_inv_restante > 0 ? true : disable}
                                onClick={(e) => invitarPaciente(e)}
                                >
                                    Invitar Paciente
                                </button>
                            </div>
                            }
                        </div>
                        }
                    </Fondo>
                    :
                    null
                }
                {servicio === "Apto para Método"
                    ?
                    <Fondo height="20vh">
                        <Text font="14px" margin="20px 0px 50px 10px">Usted cuenta con {cantidad_suscripciones} cupos para utilizar el Método Yögen, va utilizando {cantidadSuscriptos.length}, le restan {suscripcionesRestantes} cupos</Text>
                        <FlexBox justify="center">
                        <BotonNaranjaButton
                        disabled={suscripcionesRestantes > 0 && !disable && bloqueo_metodo === 0 ? false : true}
                        onClick={(e) => activarMetodo(e)}
                        >
                            {bloqueo_metodo > 0 ? `El paciente esta bloqueado por ${bloqueo_metodo} días` : suscripcionesRestantes === 0 ? `Insuficientes Suscripciones`: `Activar Método Yögen`}
                        </BotonNaranjaButton>
                        </FlexBox>
                    </Fondo>
                    :
                    null
                }
                {servicio === "Método Yögen"
                    ?
                    <Fondo height="25vh">
                        <Text font="14px" margin="20px 0px 50px 10px">Usted esta dando de baja la suscripción de un paciente. Tenga en cuenta que no podrá volver a activarlo en el Método Yögen por un periodo de 30 días</Text>
                        <FlexBox justify="center">
                            <BotonNaranjaButton margin="0px 10px 0px 0px" width="20vw"
                            disabled={disable}
                            onClick={(e) => darDeBaja(e)}
                            >
                                Dar de baja al paciente de Método Yögen
                            </BotonNaranjaButton>
                            <BotonNaranjaButton width="20vw">Cambiar plan de suscripción</BotonNaranjaButton>
                        </FlexBox>
                    </Fondo>
                    :
                    null
                }
            </div>
            </FlexColumn>
        </Cuerpo>
    );
}
 
export default GestionarMetodo;