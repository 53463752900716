import styled from 'styled-components';
import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`
export const Fondo = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 85vw;
    height: ${props=> props.height}

`
export const Margen = styled.div`
    padding: 0px 15px 20px 15px;
`
// BOTONES
export const Boton = styled.button`
    background: #FFFFFF;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 84vw;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align: center;
    border: 0;
    padding: 10px;
    margin: 5px;

`
export const BotonSmall = styled.button`
    margin: 3vh 0vw 3vh 7vw;
    border:none;
    padding:10px 15px;
    display: flex;
    justify-content:center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width:${props => props.width};
    background: #FF9B2A;
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    cursor:pointer;
    &:hover{
        background: #f0a34c;
    }
`
// FORM
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

`
export const Input = styled.input`
    background: linear-gradient(0deg, #E5E5E5, #E5E5E5), linear-gradient(0deg, #E5E5E5, #E5E5E5), #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    width: 50vw;
    margin: 7px;
    padding: ${props => props.padding};
    border: 0;
    padding:5px;
`
export const InputSmall = styled (Input)`
    width: 42vw;
`
export const Select = styled.select`
    background: linear-gradient(0deg, #E5E5E5, #E5E5E5), linear-gradient(0deg, #E5E5E5, #E5E5E5), #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    width: 7vw;
    margin: 7px;
    padding: ${props => props.padding};
    border: 0;
    padding:5px;
`
export const Label = styled.label`
    width:25vw;
    font-size: 14px;
`
 