import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useSelectDropwdown from '../../Hooks/useSelectDropwdown';
import DropdownProfesion from '../AuxiliaresPaginas/DropdownProfesion';
import TerminosCondicionesModal from './TerminosCondicionesModal';
import { paisData, provinciaData, departamentoData, localidadData } from '../../Hooks/dropdowns';

// CSS
import {LogIn, IlusNuevaCuenta, Formulario, Cuerpo, Logo, Input, Label, FechaDeNacimiento, Flex, Form, Select, Boton, Titulo, Button, ContenedorLogo, Error, Img, FixedAlert, UlAlert, ContenedorLogin, ContenedorHeader, ContenedorForm} from '../Css/StyledForm.js';

// Actions de Redux
import { crearNuevoUsuarioAction } from '../../actions/nuevoUsuarioActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';
import Alertas from '../Metodos/Compartidos/Alertas';

const NuevaCuenta = () => {

    // state del componente
    const [nombre, guardarNombre] = useState('');
    const [apellido, guardarApellido] = useState('');
    const [email, guardarEmail] = useState('');
    const [password, guardarPassword] = useState('');
    const [verificarpassword, guardarVerificarPassword] = useState('');
    const [genero, guardarGenero] = useState('');
    const [fechaNacimiento, guardarFechaNacimiento] = useState('');
    const [nivelEstudio, guardarNivelEstudio] = useState('');
    const [ocupacion, guardarOcupacion] = useState('');
    const [matricula, guardarMatricula] = useState('');
    const [aceptoCondiciones, guardarAceptoCondiciones] = useState('');
    const [focused, setFocused] = useState(false);

    const [pais, SelectDropdownPais] = useSelectDropwdown('Pais', paisData, 'pais');
    const [provincia, SelectDropdownProvincia] = useSelectDropwdown('Provincia', provinciaData);
    const [departamento, SelectDropdownDepartamento] = useSelectDropwdown('Departamento', departamentoData);
    const [localidad, SelectDropdownLocalidad] = useSelectDropwdown('Localidad', localidadData);
    const [visibilidadPassword, setVisibilidadPassword] = useState(false);

    // utilizar use dispatch y te crea una función
    const dispatch = useDispatch();

    // Acceder el state del store
    const cargando = useSelector( (state) => state.estado.loading);
    const error = useSelector ( (state) => state.estado.error);
    const alerta = useSelector ( (state) => state.alertas.alerta);

    const profesion = useSelector ( (state) => state.usuario.profesion);
    const otroProfesion = useSelector ( (state) => state.usuario.otroProfesional);
 
    // mandar llamar el action de nuevoUsuarioAction
    const crearUsuario = (usuario) => dispatch( crearNuevoUsuarioAction(usuario) );

    // Checkbox de términos y condiciones
    const checkboxHandler = () => {
        guardarAceptoCondiciones(!aceptoCondiciones);
    }

    // cuando el usuario haga submit
    const submitNuevoUsuario = e => {
        e.preventDefault();

        // validar formulario
        if(nombre.trim() === '' || apellido.trim() === '' || email.trim() === '' || profesion.trim() === 'Seleccione Profesión' || profesion.trim() === '' || pais.trim() === 'Seleccione País' || pais.trim() === '' || provincia.trim() === 'Seleccione Provincia' || provincia.trim() === '' || departamento.trim() === 'Seleccione Departamento' || departamento.trim() === '' || localidad.trim() === 'Seleccione Localidad' || localidad.trim() === '' || genero.trim() === 'Seleccione Género' || genero.trim() === '' || fechaNacimiento.trim() === '' || nivelEstudio.trim() === 'Seleccione nivel de estudio' || nivelEstudio.trim() === '' || ocupacion.trim() === 'Seleccione nivel de estudio' || ocupacion.trim() === '' || matricula.trim() === '' ) {
            const alerta = {
                msg: 'Todos los campos son obligatorios'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        
        if(password !== verificarpassword) {
            const alerta = {
                msg: 'Las contraseñas deben coincidir'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        if(password.length < 8) {
            const alerta = {
                msg: 'La contraseña debe contener al menos 8 caracteres'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        if(password.length > 15) {
            const alerta = {
                msg: 'La contraseña debe contener como mucho 15 caracteres'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const minuscula = new RegExp("(?=.*[a-z])");
        if(!minuscula.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos una minúscula'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const mayuscula = new RegExp("(?=.*[A-Z])");
        if(!mayuscula.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos una mayúscula'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const numero = new RegExp("(?=.*[0-9])");
        if(!numero.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos un número'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        if(!aceptoCondiciones) {
            const alerta = {
                msg: 'Debe aceptar términos y condiciones'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }

        // si no hay errores

        dispatch( ocultarAlertaAction());

        // crear el nuevo usuario
        crearUsuario({
            nombre,
            apellido,
            email,
            password,
            profesion,
            otroProfesion,
            pais,
            provincia,
            departamento,
            localidad,
            tipoUsuario: "Profesional",
            genero,
            fechaNacimiento,
            nivelEstudio,
            ocupacion,
            matricula
        });
    }

    const mostrarPassword = (e) => {
        e.preventDefault();
        if(visibilidadPassword) {
            setVisibilidadPassword(false);
        } else {
            setVisibilidadPassword(true);
        }
        
    }

    return (
        <ContenedorLogin>
            {alerta ? <Alertas /> : null}
            <ContenedorHeader>
                <ContenedorLogo>
                    <Link to={"/"}>
                        <Logo src={`/img/metodoYogenLogo.png`} alt="" width="20vh"/>
                    </Link>
                </ContenedorLogo>
                <LogIn to={"/Login"} margin="0 5rem 0 0">Iniciar Sesion</LogIn>
            </ContenedorHeader>
            <ContenedorForm>
                <IlusNuevaCuenta src={`/img/IlustracionNuevaCuenta.png`} alt="" width="40vh"/>
                <Formulario margin="0 7rem 0 0">
                    <Titulo>Completa los datos</Titulo>

                    <Form
                        onSubmit={submitNuevoUsuario}
                    >
                        <Flex>
                        <Label padding="2px 12px 2px 7px">
                            <label htmlFor="nombre">Nombre</label>
                            <Input
                                type="text"
                                id="nombre"
                                name="nombre"
                                placeholder="Tu Nombre"
                                value={nombre}
                                onChange={e => guardarNombre(e.target.value)}
                            />
                        </Label>
                        <Label padding="2px 12px 2px 7px" margin="0px 0px 0px 4px">
                            <label htmlFor="apellido">Apellido</label>
                            <Input
                                type="text"
                                id="apellido"
                                name="apellido"
                                placeholder="Tu Apellido"
                                value={apellido}
                                onChange={e => guardarApellido(e.target.value)}
                            />
                        </Label>
                        </Flex>
                        <Label margin="7px 0px 7px 0px" padding="2px 0px 2px 7px">
                            <label htmlFor="email">Email</label>
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Se comparte con paciente"
                                value={email}
                                onChange={e => guardarEmail(e.target.value)}
                            />
                        </Label>
                        <Flex>
                            <Flex width="50%">
                                <SelectDropdownPais />
                            </Flex>
                            <Flex width="50%">
                                <SelectDropdownProvincia />
                            </Flex>
                        </Flex>
                        <Flex>
                            <Flex width="50%">
                                <SelectDropdownDepartamento />
                            </Flex>
                            <Flex width="50%">
                                <SelectDropdownLocalidad />
                            </Flex>
                        </Flex>
                        <Flex>
                            <Label padding="4px 0px 2px 7px" margin="4px 0px 0px 0px">
                                {/* <label>Nivel de estudio</label> */}
                                <Select value={nivelEstudio} onChange={e => guardarNivelEstudio(e.target.value)}>
                                    <option>Seleccione nivel de estudio</option>
                                    <option>Estudiante</option>
                                    <option>Grado</option>
                                    <option>Posgrado</option>
                                    <option>Magister</option>
                                    <option>Doctorado</option>
                                    <option>Especialización</option>
                                </Select>
                            </Label>
                            <DropdownProfesion margin={"7px 0px 0px 4px"} padding={"2px 0px 2px 10px"}/>
                        </Flex>
                        <Flex>
                            <Label padding="4px 0px 2px 7px" margin="4px 0px 0px 0px">
                                {/* <label>ocupacion</label> */}
                                <Select value={ocupacion} onChange={e => guardarOcupacion(e.target.value)}>
                                    <option>Seleccione ocupación</option>
                                    <option>Clínica privada</option>
                                    <option>Clínica hospitalaria</option>
                                    <option>APND</option>
                                    <option>Maestra integradora</option>
                                    <option>Gabinete psicopedagógico</option>
                                    <option>EOE</option>
                                </Select>
                            </Label>
                            <Label padding="2px 10px 2px 10px" margin="4px 0px 0px 4px" width="100%">
                                <label htmlFor="matricula">Matrícula</label>
                                <Input
                                    type="text"
                                    id="matricula"
                                    name="matricula"
                                    placeholder="Tu matrícula"
                                    value={matricula}
                                    onChange={e => guardarMatricula(e.target.value)}
                                />
                            </Label>
                        </Flex>
                        <Flex>
                            <Label padding="4px 0px 0px 7px" margin="4px 4px 0px 0px">
                                {/* <label>Género</label> */}
                                <Select value={genero} onChange={e => guardarGenero(e.target.value)}>
                                    <option>Seleccione Género</option>
                                    <option>Femenino</option>
                                    <option>Másculino</option>
                                    <option>Prefiero no decirlo</option>
                                </Select>
                            </Label>
                            <Label padding="4px 0px 2px 7px" margin="4px 0px 0px 0px" width="100%">
                                <label htmlFor="fechaNacimiento">Fecha de Nacimiento</label>
                                <FechaDeNacimiento
                                    type="date"
                                    id="fechaNacimiento"
                                    name="fechaNacimiento"
                                    placeholder="Fecha"
                                    value={fechaNacimiento}
                                    onChange={e => guardarFechaNacimiento(e.target.value)}
                                />
                            </Label>
                        </Flex>
                        <Label margin="20px 0px 0px 0px" padding="4px 0px 2px 7px">
                            {focused ?
                            <FixedAlert transform="translate(-105%, -0%)">
                                <UlAlert>
                                    <li>Contener entre 8-15 caracteres</li>
                                    <li>Al menos una minúscula y una mayúscula</li>
                                    <li>Al menos un número</li>
                                </UlAlert>
                            </FixedAlert>
                            : null
                            }
                            <label htmlFor="password">Contraseña</label>
                            <Input width="60%"
                                type={visibilidadPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                placeholder="Tu Contraseña"
                                autoComplete='new-password'
                                value={password}
                                onChange={e => guardarPassword(e.target.value)}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                            />
                            <Img onClick={e => mostrarPassword(e)} height="1.5rem" width="1.5rem" alt='' src={visibilidadPassword ? `/img/ojo_invisible.png` : `/img/ojo_visible.png`} />
                        </Label>
                        <Label margin="7px 0px 20px 0px" padding="4px 0px 2px 7px" >
                            <label htmlFor="verificacion">Confirma tu Contraseña</label>
                            <Input width="50%"
                                type={visibilidadPassword ? "text" : "password"}
                                id="verificacion"
                                name="verificacion"
                                placeholder="Repeti tu Contraseña"
                                value={verificarpassword}
                                onChange={e => guardarVerificarPassword(e.target.value)}
                            />
                            <Img onClick={e => mostrarPassword(e)} height="1.5rem" width="1.5rem" alt='' src={visibilidadPassword ? `/img/ojo_invisible.png` : `/img/ojo_visible.png`} />
                        </Label>
                        <div>
                            <input type="checkbox" id="aceptoCondiciones" onChange={checkboxHandler} />
                            <label htmlFor="aceptoCondiciones"> Acepto los <b><TerminosCondicionesModal /></b></label>
                        </div>
                        <Boton>
                        <Button
                            type="submit"
                        >Crear Cuenta</Button></Boton>
                    </Form>
                    {cargando ? <p>Cargando...</p> : null }
                    {error ? <Error> {error.msg} </Error>: null }
                    
                </Formulario>
            </ContenedorForm>
        </ContenedorLogin>
    );
}
 
export default NuevaCuenta;