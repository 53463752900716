import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { cerrarSesionAction } from '../../actions/cerrarSesionActions';

const AuthRol = ({rolPermitido}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const error = useSelector ( (state) => state.estado.error);
    const rol = useSelector( (state) => state.usuario.rol);

    useEffect(() => {
        if(!error){return};
        if(error.msg === 'La sesión expiró o el token no es válido') {
            dispatch( cerrarSesionAction() );
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])
    
    return(
        <>
            {rolPermitido.find(item => item.toString() === rol.toString())
            ? <Outlet />
            : <Navigate to="/login" replace />
            }
        </>
    );
}

export default AuthRol;
