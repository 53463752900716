import styled, {css} from 'styled-components';
import {ShakeImg, ZoomIn} from './StyledCCSilabica';



const media = {
    tablet: '@media (max-width:768px)'
}

export const ContainerFlex = styled.div `
display: ${(props) => props.display};
justify-content: ${(props) => props.justify};
align-items: ${(props) => props.align};
flex-direction: ${(props) => props.direction};
background-color: ${(props) => props.bg};
padding: ${(props) => props.padding};
height: ${(props) => props.height};
width: ${(props) => props.width};
margin: ${(props) => props.margin};
margin-top: ${(props) => props.margintop};
margin-bottom: ${(props) => props.marginBottom};
margin-right: ${(props) => props.marginright};
margin-left: ${(props) => props.marginleft};
position: ${(props) => props.position};
flex-wrap:  ${(props) => props.wrap};
border:  ${(props) => props.border};
color:  ${(props) => props.color};
font-weight:  ${(props) => props.weight};
font-size:  ${(props) => props.font};
border-radius:  ${(props) => props.radius};
box-shadow: ${(props) => props.shadow};
position: ${(props) => props.position};
top: ${(props) => props.top};
left: ${(props) => props.left};
font-size: ${(props) => props.font};
text-transform:  ${(props) => props.uppercase};
text-decoration: ${(props) => props.underline};
border-bottom: ${(props) => props.borderBottom};
border-top: ${(props) => props.borderTop};
font-family: nunito;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
justify-self: ${(props) => props.justifyself};
align-self: ${(props) => props.alignself};
text-transform: ${(props) => props.$mantengoMay ? 'none' : 'uppercase'};

${media.tablet} {
  margin-top: ${(props) => props.margintopmob};
  margin-left: ${(props) => props.marginleftmob};
  margin-right: ${(props) => props.marginrightmob};
  top: ${(props) => props.topmob};
  left: ${(props) => props.leftmob};
  width: ${(props) => props.widthmob};
  height: ${(props) => props.heightmob};
  font-size:  ${(props) => props.fontmob};
  border:  ${(props) => props.borderMob};
  justify-content: ${(props) => props.justifymob};
  flex-direction: ${(props) => props.directionmob};
  align-items: ${(props) => props.alignmob};
}
${({isCorrect}) => isCorrect && 
  css`
  animation-name ${ZoomIn};
  animation-duration: 300ms;
`}
${({isIncorrect}) => isIncorrect && 
  css`
  animation-name ${ShakeImg};
  animation-duration: 300ms;
`}

`


export const Abecedario = styled.div `
display: flex;
justify-content: center;
align-items: ${(props) => props.align};
height: ${(props) => props.height};
margin: ${(props) => props.margin};

`

export const BtnAbecedario = styled.button `
  ${(props) => {
      return css`
        width: 40px;
        height: 35px;
        text-align: center ;
        background: ${props.$estilo ? props.$background : '#FAFAFA'};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
        text-transform: ${props.$minMay ? 'lowercase' : 'uppercase'};
        color: #646464;
        font-family: nunito;
        font-weight: bold;
        cursor: pointer;
        margin-right: 0.5rem;
        font-size: 1.5rem;

        &:active{
          background-color: rgba(35, 170, 175, 1);
        }
        ${media.tablet} {
            width: 30px;
            height: 25px;
            margin-right: 0.1rem;
            font-size: 1.1rem;
        }
      `
    }
  }
`

export const SilabaImg = styled.div `
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  background-color:  ${(props) => props.bg};
  top: ${(props) => props.top};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  margin-bottom: 2rem;
  
`



export const BtnAsignar = styled.button`
width: 160px;
height: 130px;
border:none;
margin-right: 2rem;
background: #FAFAFA;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
font-family: nunito;
font-size: 3.5rem;
color: ${(props) => props.color};
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.tablet} {
   width: 130px;
   height: 100px;
   margin-right: 1rem;
}

`

export const FormInput = styled.form `
margin: ${(props) => props.margin};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
border-radius: ${(props) => props.radius};
font-size: ${(props) => props.font};
text-transform:  ${(props) => props.uppercase};
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
border: none;

Input {
    border:none;
    width: ${(props) => props.widthmob}; 
    height: ${(props) => props.heightmob}; 
    margin: ${(props) => props.marginMob};
    background: #FAFAFA;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: ${(props) => props.radius};
  
    
} 

${media.tablet} {
  width: ${(props) => props.widthmob}; 
   height: ${(props) => props.heightmob}; 
   margin: ${(props) => props.marginMob};
}

`
export const BtnAvanzar = styled.button`
width: 55px;
height: 40px;
margin-right: 3rem;
border: none;
background-color: transparent;

img {
    width: 50px;
    height: 40px;
    margin-right: 2.5rem;
    cursor: pointer;  
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

${media.tablet} {
    img {
        width: 40px;
        height: 30px;
        margin-right: 1rem;
    }
}
`
export const Circulo = styled.div`
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
`
