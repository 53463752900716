import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AsignarEnABC from '../../../Compartidos/AsignarEnABC';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Coloca cada dibujo en su letra inicial.';
const miniInstrucciones = 'Coloca cada dibujo en su letra inicial.';

const LEN4 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    const data2 = [
        {id:'1', objeto:'a', imagen: false, correcto:'a', seteado: false, asignado:'', random: false, bgcolor:'#FF9B2A'},
        {id:'2', objeto:'b', imagen: false, correcto:'b', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'3', objeto:'c', imagen: false, correcto:'c', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'4', objeto:'d', imagen: false, correcto:'d', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'5', objeto:'e', imagen: false, correcto:'e', seteado: false, asignado:'', random: false, bgcolor:'#FF9B2A'},
        {id:'6', objeto:'f', imagen: false, correcto:'f', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'7', objeto:'g', imagen: false, correcto:'g', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'8', objeto:'h', imagen: false, correcto:'h', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'9', objeto:'i', imagen: false, correcto:'i', seteado: false, asignado:'', random: false, bgcolor:'#FF9B2A'},
        {id:'10', objeto:'j', imagen: false, correcto:'j', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'11', objeto:'k', imagen: false, correcto:'k', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'12', objeto:'l', imagen: false, correcto:'l', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'13', objeto:'m', imagen: false, correcto:'m', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'14', objeto:'n', imagen: false, correcto:'n', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'15', objeto:'ñ', imagen: false, correcto:'ñ', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'16', objeto:'o', imagen: false, correcto:'o', seteado: false, asignado:'', random: false, bgcolor:'#FF9B2A'},
        {id:'17', objeto:'p', imagen: false, correcto:'p', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'18', objeto:'q', imagen: false, correcto:'q', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'19', objeto:'r', imagen: false, correcto:'r', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'20', objeto:'s', imagen: false, correcto:'s', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'21', objeto:'t', imagen: false, correcto:'t', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'22', objeto:'u', imagen: false, correcto:'u', seteado: false, asignado:'', random: false, bgcolor:'#FF9B2A'},
        {id:'23', objeto:'v', imagen: false, correcto:'v', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'24', objeto:'w', imagen: false, correcto:'w', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'25', objeto:'x', imagen: false, correcto:'x', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'26', objeto:'y', imagen: false, correcto:'y', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'},
        {id:'27', objeto:'z', imagen: false, correcto:'z', seteado: false, asignado:'', random: false, bgcolor:'#23AAAF'}
    ]

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN4') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-fonemica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const newArray = informacion.map((elemento) => {
            return {
                ...elemento,
                border: false
            }
        });
        setData(newArray);
    }, [informacion])

    return (
        <AsignarEnABC data={data} data2={data2} ejercicio="LEN4" ayuImagen={true} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
    );
}
 
export default LEN4;