import {
  CONSULTAR_ACTIVIDADES_RESULTADOS_SUCCESS,
  CONSULTAR_ACTIVIDADES_RESULTADOS_ERROR,
  CONSULTAR_EJERCICIOS_RESULTADOS_SUCCESS,
  CONSULTAR_EJERCICIOS_RESULTADOS_ERROR,
} from "../types";
import clienteAxios from "../config/axios";

export function consultarActividadesAction(informacion) {
  return async (dispatch) => {
      try {
          const {config} = informacion;
          const {data} = await clienteAxios.get('/paciente/actividadesdisponibles', config);
          dispatch( consultarActividadesExito(data));
      } catch (error) {
        console.log(error)
          dispatch( consultarActividadesError(error.response.data));
      }
  }
};

export function consultarEjerciciosTablaAction(informacion) {
  return async (dispatch) => {
      try {
          const {config, payload} = informacion;
          const {data} = await clienteAxios.post('/paciente/actividadestabla', payload, config);
          dispatch( consultarEjerciciosTablaExito(data));
      } catch (error) {
          dispatch( consultarEjerciciosTablaError(error.response.data));
      }
  }
};


const consultarActividadesExito = (data) => ({
  type: CONSULTAR_ACTIVIDADES_RESULTADOS_SUCCESS,
  payload: data,
});

const consultarActividadesError = (msg) => ({
  type: CONSULTAR_ACTIVIDADES_RESULTADOS_ERROR,
  payload: msg,
});

const consultarEjerciciosTablaExito = (data) => ({
  type: CONSULTAR_EJERCICIOS_RESULTADOS_SUCCESS,
  payload: data,
});

const consultarEjerciciosTablaError = (msg) => ({
  type: CONSULTAR_EJERCICIOS_RESULTADOS_ERROR,
  payload: msg,
});
