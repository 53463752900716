import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { Palabra, BtnColor, InputLetra, RecuadroAzul } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { ContainerFlex, FormInput } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';
import { BotoneraFixed, Img } from '../../../../Css/MetodosCSS/StyledLectoEscritura';

const instrucciones = 'Escribe la palabra utilizando la B o V según corresponda.';
const miniInstrucciones = 'Escribe la palabra utilizando la B o V según corresponda.';

const ESC10 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [count, setCount] = useState(1);
    const [palabra, setPalabra] = useState('');
    const [letra, setLetra] = useState('');
    const [indexes, setIndexes] = useState();
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if (palabra.toUpperCase() !== comparar.palabra_1.toUpperCase() || letra.toUpperCase() !== comparar.faltante.toUpperCase()) {
            console.log('mal');
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setPalabra('');
                setLetra('');
            }, 1000);
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setPalabra('');
                    setLetra('');
                }, 1000);
            } else {
                setTimeout(() => {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch(finalizarEjercicioAction());
                    }, 1500);
                }, 1000);
            }
        }
    }

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC10') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/ortografia/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC10",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda_visual': ayuVisualSolicitada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center" align="center"
                    isCorrect={isCorrect} isIncorrect={isIncorrect}
                >
                    <ContainerFlex display="flex" justify="center" align="center" margin="auto">
                        <BotoneraFixed position='fixed' right='0' top='0' margin='5.5rem 2rem 0 0'>
                            <ContainerFlex height="5vh" margin='0 0 0 0.3rem'>
                                <BtnColor bg="transparent"
                                    onClick={(e) => setearAyuVisual(e)}
                                >
                                    {ayuVisual ? 
                                    <Img width="45px" src={`/img/ayuda_clickeado.png`} />
                                    :
                                    <Img width="45px" src={`/img/icono_ayuda.png`} />
                                    }
                                </BtnColor>
                            </ContainerFlex>
                        </BotoneraFixed>
                        <ContainerFlex display="flex" direction="column" align="center" justify="center" >
                            {ayuVisual 
                            ?
                                <ContainerFlex display="flex" align="center" justify="space-between" height="20vh" width="35%">
                                    <RecuadroAzul marginright="3px">
                                        <Palabra font="3.5rem" color="white" weight="bold">MB</Palabra>
                                    </RecuadroAzul>
                                    <RecuadroAzul marginleft="3px">
                                        <Palabra font="3.5rem" color="white" weight="bold">NV</Palabra>
                                    </RecuadroAzul>
                                </ContainerFlex>
                            : 
                                null
                            }

                            <ContainerFlex display="flex" align="center" justify="center" height="20vh" marginleftmob="3rem">

                                <Palabra font="5.5rem" fontmob="5rem" margin="0 0.5rem" color="rgba(100, 100, 100, 1)" weight="bold" borderBottom="4px solid grey" uppercase="uppercase" spacing="0.5rem">
                                    {comparar.primera_parte === '' ? null : comparar.primera_parte}
                                </Palabra>

                                <FormInput width="9vw" height="16vh"
                                    onSubmit={(e) => verificar(e)}
                                >
                                    <InputLetra width="9vw" height="16vh" font="5rem" fontmob="4rem" radius="10px" uppercase="uppercase" type="text" value={letra} onChange={(e) => setLetra(e.target.value)} disabled={disable} />
                                </FormInput>

                                <Palabra font="5.5rem" fontmob="5rem" margin="0 0.5rem"  color="rgba(100, 100, 100, 1)" weight="bold" borderBottom="4px solid grey" uppercase="uppercase" spacing="0.5rem">
                                    {comparar.segunda_parte === '' ? null : comparar.segunda_parte}
                                </Palabra>
                            </ContainerFlex>

                            <ContainerFlex display="flex" height="20vh" margintop="2.5rem"  marginRightMob="20vw" width="95%" justify="center" align="center" >
                                <InputLetra font="4rem" radius="10px" uppercase="uppercase" type="text" shadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset" bg="#F1F0F0" width="100%"
                                value={palabra}
                                onChange={(e) => setPalabra(e.target.value)}
                                disabled={disable}
                                onKeyDown={(e) => e.key === 'Enter' ? verificar(e) : null}
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlex>
                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC10;