import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import {
    Link
} from "react-router-dom";
const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`


export const Contenedor = styled.div `
width: ${(props) => props.width};
height: ${(props) => props.height};
display: flex;
direction:  ${(props) => props.direction};
background-color:  ${(props) => props.bg};

${media.tablet}{
   
}
`

export const AyudaVisual = styled.div`
width: ${(props) => props.width};
height: ${(props) => props.height};
margin: ${(props) => props.margin};
display: flex ;
direction:  ${(props) => props.direction};

${media.tablet} {
        height: ${(props) => props.heightmob};
        width: ${(props) => props.widthmob};
        margin: ${(props) => props.marginMob};
  }


`

export const BtnLetraInner = styled.button  `
width:  ${(props) => props.width};
 height:  ${(props) => props.height};
 margin: ${(props) => props.margin};
 font-size: ${(props) => props.font};
 color: ${(props) => props.color};
 border: none;
 background-color:  ${(props) => props.bg};
 border-radius: 15px;

 ${media.tablet} {
   height: ${(props) => props.heightmob};
   width: ${(props) => props.widthmob};
   font-size: ${(props) => props.fontmob};

}

`

export const BtnLink = styled (Link)  `
width:  ${(props) => props.width};
 height:  ${(props) => props.height};
 margin: ${(props) => props.margin};
 font-size: ${(props) => props.font};
 color: ${(props) => props.color};
 border: none;
 font-family: nunito;
 text-decoration: none;
 font-weight: bold;
 background-color:  ${(props) => props.bg};

 ${media.tablet} {
    height: ${(props) => props.heightmob};
    width: ${(props) => props.widthmob};
    font-size: ${(props) => props.fontmob};
 
 }
`

export const BtnLinkLista = styled (BtnLink) `
text-transform: uppercase ;
`

export const Btn = styled.button`
    border: 0;
    background: transparent; 
    padding: ${props => props.padding};
    margin: ${props => props.margin};
`

export const ImgBottom = styled.img`
    height: ${props => props.height};
    width: ${props => props.width};
    &:hover{
        transform: scale(1.1);
    }
`