import {
    INICIAR_EJERCICIO,
    RESET_EJERCICIO,
    NUEVA_RONDA_EJERCICIO,
    FINALIZAR_EJERCICIO,
    AUMENTAR_CONTADOR,
    REDUCIR_CONTADOR,
    ZERO_CONTADOR,
    ZERO_CONTADOR_SECUNDARIO,
    ZERO_CONTADOR_TERCIARIO,
    ZERO_CONTADOR_CUATERNARIO,
    ZERO_CONTADOR_CON_CORRECCION,
    SETEAR_EJERCICIO,
    SETEAR_CONTADOR,
    SETEAR_LETRA,
    SETEAR_LISTA,
    SETEAR_SESION,
    SETEAR_SESION_TERMINADA,
    SETEAR_URL,
    SETEAR_URL_ANTERIOR,
    SETEAR_PRIMER_TIEMPO,
    SETEAR_SEGUNDO_TIEMPO,
    BORRAR_EJERCICIO,
    BORRAR_LETRA,
    BORRAR_LISTA,
    BORRAR_URL,
    BORRAR_URL_ANTERIOR,
    BORRAR_PRIMER_TIEMPO,
    BORRAR_SEGUNDO_TIEMPO,
    AUMENTAR_RONDA,
    AUMENTAR_CONTADOR_SECUNDARIO,
    AUMENTAR_CONTADOR_TERCIARIO,
    AUMENTAR_CONTADOR_CUATERNARIO,
    REDUCIR_CONTADOR_SECUNDARIO,
    REDUCIR_CONTADOR_TERCIARIO,
    REDUCIR_CONTADOR_CUATERNARIO,
    AUMENTAR_CONTADOR_CON_CORRECCION,
    REDUCIR_CONTADOR_CON_CORRECCION,
    AUMENTAR_CONTADOR_ERROR,
    CERRAR_SESION,
    PROXIMO_EJERCICIO,
    PROXIMA_RONDA,
    ALMACENAR_EXITO,
    SETEAR_GRUPO_EJERCICIO,
    SETEAR_ITEM_GRUPO,
    SOLICITO_LISTA_EJERCICIO_EXITO
} from '../types';


const initialState = {
    iniciar: false,
    finalizar: false,
    ejercicio: '',
    contador: 0,
    contadorConCorreccion:0,
    contadorSecundario: 0,
    contadorTerciario: 0,
    contadorCuaternario: 0,
    error: 0,
    ronda: 1,
    primer_tiempo:'',
    segundo_tiempo:'',
    letra:'',
    lista:'',
    url:'',
    urlAnterior:'',
    sesion:[],
    sesionTerminada:[],
    ejercicio_almacenado: false,
    grupo:[],
    item_grupo: 0,
    listas_check: []
}
export default function ejercicioReducer(state = initialState, action) {
    switch(action.type) {
        case INICIAR_EJERCICIO:
            return {
                ...state,
                iniciar: action.payload,
                ejercicio_almacenado: false
            }
        case RESET_EJERCICIO:
            return {
                ...state,
                iniciar: false,
                finalizar: false,
                ejercicio: '',
                contador: 0,
                contadorConCorreccion: 0,
                contadorSecundario: 0,
                contadorTerciario: 0,
                contadorCuaternario: 0,
                error: 0,
                ronda: 1,
                primer_tiempo: '',
                segundo_tiempo: '',
                lista:'',
                url:'',
                urlAnterior:'',
                sesion:[],
                sesionTerminada:[],
                ejercicio_almacenado: false,
                grupo:[],
                item_grupo: 0
            }
        case NUEVA_RONDA_EJERCICIO:
            return {
                ...state,
                iniciar: false,
                finalizar: false,
                contador: 0,
                contadorConCorreccion: 0,
                contadorSecundario: 0,
                contadorTerciario: 0,
                contadorCuaternario: 0,
                error: 0,
                ronda: 1,
                primer_tiempo: '',
                segundo_tiempo: '',
                ejercicio_almacenado: false,
            }
        case CERRAR_SESION:
            return {
                ...state,
                iniciar: false,
                finalizar: false,
                ejercicio: '',
                contador: 0,
                contadorConCorreccion: 0,
                contadorSecundario: 0,
                contadorTerciario: 0,
                contadorCuaternario: 0,
                error: 0,
                ronda: 1,
                primer_tiempo: '',
                segundo_tiempo: '',
                letra: '',
                lista:'',
                url:'',
                urlAnterior:'',
                sesion:[],
                sesionTerminada:[],
                ejercicio_almacenado: false,
                grupo:[],
                item_grupo: 0
            }
        case FINALIZAR_EJERCICIO:
            return {
                ...state,
                iniciar: false,
                finalizar: action.payload
            }
        case AUMENTAR_CONTADOR:
            return {
                ...state,
                contador: state.contador + 1
            }
        case REDUCIR_CONTADOR:
            return {
                ...state,
                contador: state.contador - 1
            }
        case ZERO_CONTADOR:
            return {
                ...state,
                contador: 0
            }
        case ZERO_CONTADOR_SECUNDARIO:
            return {
                ...state,
                contadorSecundario: 0
            }
        case ZERO_CONTADOR_TERCIARIO:
            return {
                ...state,
                contadorTerciario: 0
            }
        case ZERO_CONTADOR_CUATERNARIO:
            return {
                ...state,
                contadorCuaternario: 0
            }
        case ZERO_CONTADOR_CON_CORRECCION:
            return {
                ...state,
                contadorConCorreccion: 0
            }
        case SETEAR_EJERCICIO:
            return {
                ...state,
                ejercicio: action.payload
            }
        case SETEAR_CONTADOR:
            return {
                ...state,
                contador: action.payload
            }
        case SETEAR_PRIMER_TIEMPO:
            return {
                ...state,
                primer_tiempo: action.payload
            }
        case SETEAR_SEGUNDO_TIEMPO:
            return {
                ...state,
                segundo_tiempo: action.payload
            }
        case SETEAR_LETRA:
            return {
                ...state,
                letra: action.payload
            }
        case SETEAR_LISTA:
            return {
                ...state,
                lista: action.payload
            }
        case SETEAR_URL:
            return {
                ...state,
                url: action.payload
            }
        case SETEAR_URL_ANTERIOR:
            return {
                ...state,
                urlAnterior: action.payload
            }
        case SETEAR_SESION:
            return {
                ...state,
                sesion: action.payload
            }
        case SETEAR_SESION_TERMINADA:
            return {
                ...state,
                sesionTerminada: action.payload
            }
        case BORRAR_EJERCICIO:
            return {
                ...state,
                ejercicio: ''
            }
        case BORRAR_PRIMER_TIEMPO:
            return {
                ...state,
                primer_tiempo: ''
            }
        case BORRAR_SEGUNDO_TIEMPO:
            return {
                ...state,
                segundo_tiempo: ''
            }
        case BORRAR_LETRA:
            return {
                ...state,
                letra: ''
            }
        case BORRAR_LISTA:
            return {
                ...state,
                lista: ''
            }
        case BORRAR_URL:
            return {
                ...state,
                url: ''
            }
        case BORRAR_URL_ANTERIOR:
            return {
                ...state,
                urlAnterior: ''
            }
        case AUMENTAR_RONDA:
            return {
                ...state,
                ronda: state.ronda + 1
            }
        case AUMENTAR_CONTADOR_SECUNDARIO:
            return {
                ...state,
                contadorSecundario: state.contadorSecundario + 1
            }
        case AUMENTAR_CONTADOR_TERCIARIO:
            return {
                ...state,
                contadorTerciario: state.contadorTerciario + 1
            }
        case AUMENTAR_CONTADOR_CUATERNARIO:
            return {
                ...state,
                contadorCuaternario: state.contadorCuaternario + 1
            }
        case REDUCIR_CONTADOR_SECUNDARIO:
            return {
                ...state,
                contadorSecundario: state.contadorSecundario - 1
            }
        case REDUCIR_CONTADOR_TERCIARIO:
            return {
                ...state,
                contadorTerciario: state.contadorTerciario - 1
            }
        case REDUCIR_CONTADOR_CUATERNARIO:
            return {
                ...state,
                contadorCuaternario: state.contadorCuaternario - 1
            }
        case AUMENTAR_CONTADOR_CON_CORRECCION:
            return {
                ...state,
                contadorConCorreccion: state.contadorConCorreccion + 1
            }
        case REDUCIR_CONTADOR_CON_CORRECCION:
            return {
                ...state,
                contadorConCorreccion: state.contadorConCorreccion - 1
            }
        case AUMENTAR_CONTADOR_ERROR:
            return {
                ...state,
                error: state.error + 1
            }
        case PROXIMO_EJERCICIO:
            return {
                ...state,
                iniciar: false,
                finalizar: false,
                contador: 0,
                contadorConCorreccion: 0,
                contadorSecundario: 0,
                contadorTerciario: 0,
                contadorCuaternario: 0,
                error: 0,
                ronda: 1,
                primer_tiempo: '',
                segundo_tiempo: '',
                ejercicio_almacenado: false
            }
        case PROXIMA_RONDA:
            return {
                ...state,
                iniciar: false,
                finalizar: false,
                contador: 0,
                contadorConCorreccion: 0,
                contadorSecundario: 0,
                contadorTerciario: 0,
                contadorCuaternario: 0,
                error: 0,
                ejercicio_almacenado: false
            }
        case SETEAR_GRUPO_EJERCICIO:
            return{
                ...state,
                grupo: action.payload
            }
        case SETEAR_ITEM_GRUPO:
            return{
                ...state,
                item_grupo: action.payload
            }   
        case ALMACENAR_EXITO:
            return {
                ...state,
                ejercicio_almacenado: true
            }
        case SOLICITO_LISTA_EJERCICIO_EXITO:
            return {
                ...state,
                listas_check: action.payload
            }
        default:
            return state;
    }
}