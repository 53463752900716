import styled from 'styled-components';
import {createGlobalStyle} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`
export const FormularioPerfil = styled.div`
    width: 70vw;
    display: flex;
    flex-direction:column;
    align-content:center;
    ${media.tablet}{
        width:${props => props.ancho};
    }
`
export const ContenedorPerfil = styled.div`
    display: flex;
    justify-content: center;
`
export const Titulo = styled.h2`
    border-bottom: solid 1px;
    box-sizing: border-box;
    width: 30vh;
    padding-bottom: 5px;
`
export const Fondo = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    width: ${props => props.width};
    height: ${props => props.height};
    ${media.tablet}{
        width:${props => props.ancho};
        height:${props => props.alto};
    }
`
// BOTONES
export const IconoEditar = styled.img`
    width: 20px;
    
`
export const BotonEditar = styled.button`
    border: none;
    display: flex;
    border-bottom: solid 1px;
    width: 90vw;
    justify-content: flex-end;
    background:transparent;
`
export const BotonEditarSinLinea = styled (BotonEditar)`
    border-bottom: none;
    margin: ${props => props.margin};
`
export const ContenedorBotonEditar = styled.div`
    display: flex;
    justify-content: flex-end;
`
export const BotonGuardar = styled.button`
    border:none;
    background: #FF9B2A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    width: 159px;
    height: 38px;
    margin:${props => props.margin};
    justify-content: center;
    font-size: 12px;
    align-items: center;
    font-weight: 500;
`
export const CambiarContraseña = styled.button`
    position: absolute;
    top: 54vh;
    right: 32vh;
    border: 1px solid;
    padding: 7px; 
    border-radius: 10px;
    background-color:white;
    ${media.tablet}{
        top: 64vh;
        right: 22vh;
    }
`
// FORMULARIO
export const Input = styled.input`
    border:0;
    border-radius: 15px;
    color:#646464;
`
export const Aclaracion = styled.p`
    font-size: 14px;
`
export const Item = styled.div`
    border-bottom: solid 1px;
    box-sizing: border-box;
    width: 50vh;
    color:#646464;
`
export const ItemSmall = styled (Item)`
    width: 30vh;
`
export const Completar = styled.div`
    border-bottom: solid 1px;
    box-sizing: border-box;
    width: 50vh;
`
export const CompletarSmall = styled (Completar)`
    width: 30vh;    
`
export const Dato = styled.p`
    margin:${props => props.margin};
    color: black;
`
export const Label = styled.label`
    margin:10px 10px 10px 0px;
    font-size: ${props => props.font};
`
export const Select = styled.select`
    border:0;
    border-radius: 15px;
    color:#646464;
`