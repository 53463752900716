import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {LetraRepaso, ContLetra, Flex,Recuadro, ImgSound, LetraS } from '../../../../Css/MetodosCSS/StyledLetras';

const instrucciones = 'Une las mismas vocales según corresponda.';
const miniInstrucciones = 'Une las mismas vocales según corresponda.'

const listAsignado = [
    {id: '1', items:'A', letra:'A', ejercicio:['1'], tipo:'asignado', sonido:'', color:'', background:'', disable:false},
    {id: '2', items:'E', letra:'E', ejercicio:['1'], tipo:'asignado', sonido:'', color:'', background:'', disable:false},
    {id: '3', items:'I', letra:'I', ejercicio:['1'], tipo:'asignado', sonido:'', color:'', background:'', disable:false},
    {id: '4', items:'O', letra:'O', ejercicio:['1'], tipo:'asignado', sonido:'', color:'', background:'', disable:false},
    {id: '5', items:'U', letra:'U', ejercicio:['1'], tipo:'asignado', sonido:'', color:'', background:'', disable:false},
    {id: '6', items: '', letra:'A', ejercicio:['1'], tipo:'asignadoI', sonido:'A', color:'', background:'', disable:false},
    {id: '7', items: '' , letra:'E', ejercicio:['1'], tipo:'asignadoI', sonido:'E', color:'', background:'', disable:false},
    {id: '8', items: '', letra:'I', ejercicio:['1'], tipo:'asignadoI', sonido:'I', color:'', background:'', disable:false},
    {id: '9', items: '', letra:'O', ejercicio:['1'], tipo:'asignadoI', sonido:'O', color:'', background:'', disable:false},
    {id: '10', items: '', letra:'U', ejercicio:['1'], tipo:'asignadoI', sonido:'U',color:'', background:'', disable:false}
]
const listAsignar = [
    {id: '1', items:'', letra:'A', ejercicio:['1'], tipo:'asignable', sonido:'', bgcolor:'#23AAAF', seleccionado: false},
    {id: '2', items:'', letra:'E', ejercicio:['1'], tipo:'asignable', sonido:'', bgcolor:'#FF9B2A', seleccionado: false},
    {id: '3', items:'', letra:'I', ejercicio:['1'], tipo:'asignable', sonido:'', bgcolor:'#FFDC73', seleccionado: false},
    {id: '4', items:'', letra:'O', ejercicio:['1'], tipo:'asignable', sonido:'', bgcolor:'#655EE8', seleccionado: false},
    {id: '5', items:'', letra:'U', ejercicio:['1'], tipo:'asignable', sonido:'', bgcolor:'#D05F67', seleccionado: false},
]

const Test = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 130px;
    width: 130px;
    border-radius: 5px;
    background-color: ${props => props.background || 'white' };
    color: ${props => props.color ||'black' };
    border: 1px solid #FCF8C9;
`

const Repaso = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [asignar, setAsignar] = useState(listAsignar)
    const [asignado, setAsignado] = useState(listAsignado);
    const [asignable, setAsignable] = useState('');
    const [asignableIndex, setAsignableIndex] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [disable, setDisable] = useState(false);

    const minuscula = useSelector(state => state.configuracion.minuscula);
    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    
       
    useEffect(() => {
        if(minuscula){
            setAsignado(asignado =>
                asignado.map(obj => ({
                    ...obj,
                    items: obj.items.toLowerCase()
            })));
            setAsignar(asignar =>
                asignar.map(obj => ({
                    ...obj,
                    letra: obj.letra.toLowerCase()
            })));
        } else {
            setAsignado(asignado =>
                asignado.map(obj => ({
                    ...obj,
                    items: obj.items.toUpperCase()
            })));
            setAsignar(asignar =>
                asignar.map(obj => ({
                    ...obj,
                    letra: obj.letra.toUpperCase()
            })));
        }
    }, [minuscula])
    
    useEffect(() => {

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(asignado);
        setAsignado(newShuffleList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setearEjercicioAction('Repaso') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/letras/vocales'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const playA = new Audio(`/sounds/a.mp3`);
    const playE = new Audio(`/sounds/e.mp3`);
    const playI = new Audio(`/sounds/i.mp3`);
    const playO = new Audio(`/sounds/o.mp3`);
    const playU = new Audio(`/sounds/u.mp3`);

    const play = (nombre) => {
        playA.pause();
        playE.pause();
        playI.pause();
        playO.pause();
        playU.pause();
        playA.currentTime = 0;
        playE.currentTime = 0;
        playI.currentTime = 0;
        playO.currentTime = 0;
        playU.currentTime = 0;
        switch(nombre.toUpperCase()){
            case "A":
                playA.play();
                break;
            case "E":
                playE.play();
                break;
            case "I":
                playI.play();
                break;
            case "O":
                playO.play();
                break;
            case "U":
                playU.play();
                break;
            default:
        }
    }



    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignarItem = (item, i) => {
        const newList = JSON.parse(JSON.stringify(asignar));
        if(newList[i].seleccionado === true) {
            newList[i].seleccionado = false;
            setAsignar(newList);
            setAsignable("");
            setAsignableIndex("");
            return;
        } else {
            if(asignableIndex) {
                newList[asignableIndex].seleccionado = false;
            }
            newList[i].seleccionado = true;
            setAsignar(newList);
            setAsignableIndex(i);
        }
        setAsignable(item);
    }
    const setearItem = (asignable, id) => {
        if(asignable === '' || asignable === undefined) {
            return;
        }
        let grpI = asignado.findIndex(grp => {
            if(grp.id === id)
                return true;
        })
        if(asignado[grpI].letra.toLocaleLowerCase() !== asignable.toLocaleLowerCase()){
            // Incorrecto
            const newList = JSON.parse(JSON.stringify(asignado));
            newList[grpI].color = 'white';
            newList[grpI].background = '#D05F67';
            const newList2 = JSON.parse(JSON.stringify(asignar));
            newList2[asignableIndex].seleccionado = false;
            setAsignar(newList2);
            setDisable(true)
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
                setDisable(false);
            }, 300);
            TryAgain();
            setAsignado(newList);
            setAsignable("");
            setAsignableIndex("");
        } else {
            //correcto
            const newList = JSON.parse(JSON.stringify(asignado));
            newList[grpI].color = 'white';
            newList[grpI].background = '#23AAAF';
            newList[grpI].disable = true;
            const newList2 = JSON.parse(JSON.stringify(asignar));
            newList2[asignableIndex].seleccionado = false;
            setAsignar(newList2);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            setAsignado(newList);
            setAsignable("");
            setAsignableIndex("");
        }
        if(asignado[grpI].letra.toLocaleLowerCase() !== asignable.toLocaleLowerCase()){
            // vuelvo a la normalidad cuando se equivocan
            setTimeout(() => {
                const newList = JSON.parse(JSON.stringify(asignado));
                newList[grpI].color = "";
                newList[grpI].background = "";
                setAsignado(newList);
            }, 300);
        }
    }

    useEffect(() => {
        const verifico = asignado.filter(item => item.background === '');
        if(verifico.length === 0){
            dispatch( finalizarEjercicioAction() );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asignado])
    
    useEffect(() => {
        if(finalizar){
            const informacion = {
                config,
                payload: {
                'ejercicio': 'repaso',
                'id_paciente': id_paciente,
                sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto">
                        <Flex margin="0 0px 0px 0px">
                            {asignar.filter(tipo => tipo.tipo === "asignable").map((item,i) => (
                                <ContLetra
                                    key={item.id}
                                    onClick={() => asignarItem(item.letra, i)}
                                >
                                    <LetraRepaso border={item.seleccionado ? true : false} bgcolor={item.bgcolor}>{item.letra}</LetraRepaso>
                                </ContLetra>
                            ))}
                        </Flex >
                        <Flex >
                            <Recuadro  
                            isCorrect={isCorrect} 
                            isIncorrect={isIncorrect}>
                            {asignado.map(item => (
                                <Test
                                    key={item.id}
                                    onClick={() => {setearItem(asignable, item.id); play(item.sonido)}}
                                    color={item.color}
                                    background={item.background}
                                    disabled={disable === true ? disable : item.disable}
                                >
                                    <LetraS>
                                        {
                                        item.items === '' ? <ImgSound src={`/img/altavoz.png`} alt="" /> : item.items
                                    }  
                                    </LetraS>
                                    
                                    {/* {item.items} */}
                                </Test>
                            ))}
                        </Recuadro>
                        </Flex>
                    </Flex>
                        <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }

        </Fragment>
    );
}
 
export default Repaso;