import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useColorHelp from '../../../../../Hooks/useColorHelp';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlex } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { BtnLetra } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Selecciona las oraciones que son exactamente iguales.';
const miniInstrucciones = 'Selecciona las oraciones que son exactamente iguales.';

const LN21 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [disable, setDisable] = useState(false);
    const [randomPalabra, setRandomPalabra] = useState([]);
    const [fraseElegidaUno, setFraseElegidaUno] = useState('');
    const [fraseElegidaDos, setFraseElegidaDos] = useState('');
    const [count, setCount] = useState(1);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [arrayColorUno, transformarColorUno] = useColorHelp('');
    const [arrayColorDos, transformarColorDos] = useColorHelp('');
    const [arrayColorTres, transformarColorTres] = useColorHelp('');
    const [arrayColorCuatro, transformarColorCuatro] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    useEffect(() => {
        if(randomPalabra[0] === undefined){
            return;
        }
        transformarColorUno(randomPalabra[0]);
        transformarColorDos(randomPalabra[1]);
        transformarColorTres(randomPalabra[2]);
        transformarColorCuatro(randomPalabra[3]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [randomPalabra, ayuda_lectura])

    const almacenar = (e, index) => {
        e.preventDefault();
        if(fraseElegidaUno !== '' && fraseElegidaDos !== '') {
            if(fraseElegidaUno !== index && fraseElegidaDos !== index) {
                return;
            }
        }
        if(fraseElegidaUno === index){
            setFraseElegidaUno('');
            return;
        }
        if(fraseElegidaDos=== index){
            setFraseElegidaDos('');
            return;
        }
        if (fraseElegidaUno === '') {
            setFraseElegidaUno(index)
            return;
        }
        if(fraseElegidaDos === '') {
            setFraseElegidaDos(index);
        }
    }

    const verificar = (e) => {
        e.preventDefault();
        if (fraseElegidaUno === '' || fraseElegidaDos === '') {
            return;
        }
        if (randomPalabra[fraseElegidaUno].toUpperCase() !== randomPalabra[fraseElegidaDos].toUpperCase()) {
            console.log('mal')
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setFraseElegidaUno('');
                setFraseElegidaDos('');
            }, 1000);
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setFraseElegidaUno('');
                    setFraseElegidaDos('');
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1000);
            }
        }

    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LN21') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/ejercicios/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LN21",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return;
        }
        const lista = [];
        lista.push(comparar.frase_1);
        lista.push(comparar.frase_1);
        lista.push(comparar.frase_2);
        lista.push(comparar.frase_3);

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const newShuffleList = shuffle(lista);
        setRandomPalabra(newShuffleList)
    }, [comparar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center">
                    <ContainerFlex display="flex" direction="column" justify="center" align="center" margin="auto">
                        <ContainerFlex display="flex" direction="column" padding="3rem" width="70vw" widthmob="70vw" height="50vh" heightmob="70vh"
                            isCorrect={isCorrect}
                            isIncorrect={isIncorrect}
                        >

                            <BtnLetra $minMay={minuscula} margin="1rem 0" font="1.2rem" bg={fraseElegidaUno === 0 || fraseElegidaDos === 0 ? "#23AAAF" : "rgba(250,250,250,1)"} shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" radius="25px" border="none" height="12vh" heightmob="10vh"
                                disabled={disable}
                                onClick={(e) => { almacenar(e, 0) }}
                            >
                                {arrayColorUno === '' ? null :
                                arrayColorUno.map((item, i) =>
                                    <span
                                    key={i}
                                    >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                    &nbsp;
                                    </span>
                                )
                            }
                            </BtnLetra>

                            <BtnLetra $minMay={minuscula} margin="1rem 0" font="1.2rem" bg={fraseElegidaUno === 1 || fraseElegidaDos === 1 ? "#23AAAF" : "rgba(250,250,250,1)"} shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" border="none" height="12vh" heightmob="10vh"
                                disabled={disable}
                                onClick={(e) => { almacenar(e, 1) }}
                            >
                                {arrayColorDos === '' ? null :
                                arrayColorDos.map((item, i) =>
                                    <span
                                    key={i}
                                    >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                    &nbsp;
                                    </span>
                                )
                            }
                            </BtnLetra>

                            <BtnLetra $minMay={minuscula} margin="1rem 0" font="1.2rem" bg={fraseElegidaUno === 2 || fraseElegidaDos === 2 ? "#23AAAF" : "rgba(250,250,250,1)"} shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" border="none" height="12vh" heightmob="10vh"
                                disabled={disable}
                                onClick={(e) => { almacenar(e, 2) }}
                            >
                                {arrayColorTres === '' ? null :
                                arrayColorTres.map((item, i) =>
                                    <span
                                    key={i}
                                    >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                    &nbsp;
                                    </span>
                                )
                            }
                            </BtnLetra>

                            <BtnLetra $minMay={minuscula} margin="1rem 0" font="1.2rem" bg={fraseElegidaUno === 3 || fraseElegidaDos === 3 ? "#23AAAF" : "rgba(250,250,250,1)"} shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" radius="20px" border="none" height="12vh" heightmob="10vh"
                                disabled={disable}
                                onClick={(e) => { almacenar(e, 3) }}
                            >
                                {arrayColorCuatro === '' ? null :
                                arrayColorCuatro.map((item, i) =>
                                    <span
                                    key={i}
                                    >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                    &nbsp;
                                    </span>
                                )
                            }
                            </BtnLetra>

                        </ContainerFlex>
                    </ContainerFlex>
                    
                    <ContainerFlex margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlex>
                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN21;