import React from 'react';
import Modal from 'react-modal';

import DiagramaTabla from './ResultadosDiagramaTabla';
import Grafico from './ResultadosGrafico';

import { Skeleton, Tab, TabButton, TabContent } from '../Css/StyledResultados';

Modal.setAppElement('#root');

const ResultadosTablaResultados = ({
    ejercicios,
    selectedButtons,
    onTabClick,
    active,
    seccion,
    loading,
    }) => {
    let resultados = [];
    return (
        <TabContent borderColor="#3ebcc1">
        {ejercicios?.length > 0 && !loading ? (
            <>
            <Tab width="15%" borderRightColor="#3ebcc1">
                {ejercicios?.map((ejercicio) => {
                if (ejercicio.Id === seccion) {
                    return ejercicio.Temas.map((tema) => {
                    return (
                        <TabButton
                        backgroundColorDisabled="#ffffff"
                        key={tema.Id}
                        selected={selectedButtons.colButton1.Id === tema.Id}
                        onClick={() =>
                            onTabClick( "colButton1", tema.Id, tema.Nombre)
                        }
                        >
                        {tema.Nombre}
                        </TabButton>
                    );
                    });
                }
                return null;
                })}
            </Tab>
            <Tab width="15%" borderRightColor="#3ebcc1">
                {ejercicios?.map((ejercicio) => {
                if (ejercicio.Id === seccion) {
                    return ejercicio.Temas.map((tema) => {
                    if (tema.Id === selectedButtons.colButton1.Id) {
                        return tema.Niveles.map((nivel) => {
                        return (
                            <TabButton
                            backgroundColorDisabled="#ffffff"
                            key={nivel.Id}
                            selected={selectedButtons.colButton2.Id === nivel.Id}
                            onClick={() =>
                                onTabClick("colButton2", nivel.Id, nivel.Nombre)
                            }
                            >
                            {nivel.Nombre}
                            </TabButton>
                        );
                        });
                    }
                    return null;
                    });
                }
                return null;
                })}
            </Tab>
            <Tab width="15%" borderRightColor="#3ebcc1">
                {ejercicios?.map((ejercicio) => {
                if (ejercicio.Id === seccion) {
                    return ejercicio.Temas.map((tema) => {
                    if (tema.Id === selectedButtons.colButton1.Id) {
                        return tema.Niveles.map((nivel) => {
                        if (nivel.Id === selectedButtons.colButton2.Id) {
                            return nivel.Contenido.map((cont, i) => {
                            if (
                                selectedButtons.colButton3.Id === cont.Id &&
                                !cont.Ejercicios[0].Id
                            ) {
                                resultados = [];
                                resultados.push(...cont.Ejercicios);
                            }
                            return (
                                <TabButton
                                backgroundColorDisabled="#ffffff"
                                key={i}
                                selected={selectedButtons.colButton3.Id === cont.Id}
                                onClick={() =>
                                    onTabClick("colButton3", cont.Id, cont.Nombre)
                                }
                                >
                                {!cont.Ejercicios[0].Id ? cont.NombreMostrar : cont.Nombre}
                                </TabButton>
                            );
                            });
                        }
                        return null;
                        });
                    }
                    return null;
                    });
                }
                return null;
                })}
            </Tab>
            {ejercicios?.filter((ej) => {
                return (
                ej.Id === seccion &&
                ej.Temas.filter((tema) => {
                    return(
                    tema.Id === selectedButtons.colButton1.Id &&
                    tema.Niveles.filter((niv) => {
                        return (
                        niv.Id === selectedButtons.colButton2.Id &&
                        niv.Contenido.filter((cont) => {
                            return (
                            cont.Id === selectedButtons.colButton3.Id &&
                            cont.Ejercicios.filter((e) => e.Id > 0).length > 0
                            );
                        }).length > 0
                        );
                    }).length > 0
                    );
                }).length > 0
                );
            }).length > 0 ? (
                <Tab width="15%" borderRightColor="#3ebcc1">
                {ejercicios?.map((ejercicio) => {
                    if(ejercicio.Id === seccion) {
                    return ejercicio.Temas.map((tema) => {
                        if (tema.Id === selectedButtons.colButton1.Id) {
                        return tema.Niveles.map((nivel) => {
                            if (nivel.Id === selectedButtons.colButton2.Id) {
                            return nivel.Contenido.map((cont) => {
                                if (cont.Id === selectedButtons.colButton3.Id) {
                                return cont.Ejercicios.map((ej, i) => {
                                    if (ej.Id !== 0) {
                                    if (selectedButtons.colButton4.Id === ej.Id) {
                                        resultados = [];
                                        resultados.push(ej);
                                    }
                                    return (
                                        <TabButton
                                        backgroundColorDisabled="#ffffff"
                                        key={i}
                                        selected={
                                            selectedButtons.colButton4.Id === ej.Id
                                        }
                                        onClick={() =>
                                            onTabClick("colButton4", ej.Id, ej.NombreMostrar)
                                        }
                                        >
                                        {ej.NombreMostrar}
                                        </TabButton>
                                    );
                                    }
                                    return null;
                                });
                                }
                                return null;
                            });
                            }
                            return null;
                        });
                        }
                        return null;
                    });
                    }
                    return null;
                })}
                </Tab>
            ) : null}
            {active === 2 ? (
                <Tab width="55%">
                <DiagramaTabla resultados={resultados} />
                </Tab>
            ) : (
                <Tab width="55%">
                <Grafico resultados={resultados} />
                </Tab>
            )}
            </>
        ) : (
            <Tab width="15%" borderRightColor="#3ebcc1">
            <Skeleton width="100%" height="40px" />
            <Skeleton width="100%" height="40px" />
            <Skeleton width="100%" height="40px" />
            <Skeleton width="100%" height="40px" />
            </Tab>
        )}
        </TabContent>
    );
};

export default ResultadosTablaResultados;
