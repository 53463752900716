import React, {Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgB10, PM65, CalcPres, Container } from '../../../../Css/StyledMatematica';

const instrucciones = '';
const miniInstrucciones = '';

const M48 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contador = useSelector(state => state.ejercicio.contador);

    const indexes = 2;

    useEffect(() => {
        if(contador > indexes) {
            dispatch( finalizarEjercicioAction() )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contador])

    useEffect(() => {
        dispatch(setearEjercicioAction('M48') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(finalizar){
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Fragment>
                    <Flex direction='column' height='70vh'>
                        <FlexFull width='95vw' wm='90vw' wt='80vw' wd='80vw' wlg='80vw'>
                            <FlexFull justify='space-evenly' align='center' width='60vw' wm='60vw' wt='50vw' wd='50vw' wlg='50vw' height='70px'>
                                {contador >= 0 && 
                                <Flex>
                                    <PM65 fs='2.8vw' fsm='2.4vw' fst='2.2vw' fsd='2vw' fslg='1.8vw' talign='center'>
                                        CUANDO JUNTAMOS
                                    </PM65>
                                </Flex>
                                }
                                {contador >= 0 && 
                                <Flex width='60px'> 
                                    <ImgB10 src={`/img/arrowRight.png`} w='40px' wm='60px' wt='60px' wd='60px' wlg='60px' m='0px 0px 0px -30px' mm='0px' mt='0px' md='0px' mlg='0px' />
                                </Flex>
                                }
                                <Container minw='80px'>
                                {contador > 0 && 
                                    <CalcPres>
                                        SUMAMOS
                                    </CalcPres>
                                }
                                </Container>
                            </FlexFull>
                        </FlexFull>
                        <Flex direction='column' margin='8vh 0px 0px 0px' height='150px'>
                            {contador > 0 && 
                            <div>
                                <ImgB10 src={`/img/arrowRight.png`} h='20px' tr='rotate(90deg)' />
                            </div>
                            }
                            <Container minh='115px'>
                                {contador > 1 &&
                                <PM65 m='10vh 0px 0px 0px' fs='2.8vw' fsm='2.4vw' fst='2vw' fsd='1.8vw' fslg='1.6vw' talign='center'>
                                    SI UN RATÓN TIENE <b>3</b> QUESOS Y OTRO RATÓN TIENE <b>2</b> <br /> JUNTAMOS TODOS LOS QUESOS PARA SABER CUANTOS HAY EN TOTAL 
                                </PM65>
                                }
                            </Container>
                        </Flex>
                    </Flex>
                    {contador >= 0 &&
                    <div>
                        <FlexCuz position='fixed' left='0' bottom='0' margin='0px 0px 60px 0px'>
                            <ImgB10 src={`/img/raton3quesos.png`} w='27vw' wm='23vw' wt='22vw' wd='20vw' wlg='20vw' />
                        </FlexCuz>
                        <FlexCuz position='fixed' right='0' bottom='0' margin='0px 0px 60px 0px'>
                            <ImgB10 src={`/img/raton2quesos.png`} w='27vw' wm='23vw' wt='22vw' wd='20vw' wlg='20vw' />
                        </FlexCuz>
                    </div>
                    }
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        {contador === 0 && <BotoneraBottom aumentar={true} />}
                        {contador > 0 && <BotoneraBottom reducir={true} aumentar={true} />}
                    </FlexCuz>
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M48;