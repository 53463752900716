import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

import { borrarLetraAction, setearGrupoEjercicioAction, setearLetraAction } from '../../../../actions/estadoEjercicioActions';

// css
import { BtnLetra, Contenedor, ContenedorLetras, InputCheck, BtnElegir, Container, ContenedorEjercicios, ContainerLink, ContainerImg, ElegirLink } from '../../../Css/MetodosCSS/StyledInstruccionExplicita';
import { grupoInstruccionExplicita } from '../../../AuxiliaresPaginas/Grupos';
import { HrMetodos } from '../../../Css/StyledHeader';

const InstruccionExplicita = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [checkeable, setCheckeable] = useState([]);

    const letra = useSelector(state => state.ejercicio.letra);
    const listas_check = useSelector(state => state.ejercicio.listas_check);

    const setearLetra = (e, ltr) => {
        e.preventDefault();
        dispatch(setearLetraAction(ltr));
    }
    const borrarLetra = (e) => {
        e.preventDefault();
        dispatch(borrarLetraAction());
    }
    const checkear = (e, id) => {
        const newList = JSON.parse(JSON.stringify(checkeable));
        if (e.target.checked) {
            newList[id - 1].check = true;
            setCheckeable(newList);
        } else {
            newList[id - 1].check = false;
            setCheckeable(newList);
        }
    }

    const setearTodosExplicita = (e) => {
        e.preventDefault();
        dispatch(setearGrupoEjercicioAction(grupoInstruccionExplicita));
        navigate(`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN1`)
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(listas_check));
        setCheckeable(newList)
    }, [])


    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} l3={"lectura/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} n3={"LECTURA"} componente={"INSTRUCCION EXPLICITA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Contenedor width="100%" height="100%">
                {letra !== ''
                    ?
                    <ContenedorEjercicios  margin="auto" display="flex" >
                        <Container>
                            <BtnElegir
                                onClick={(e) => setearTodosExplicita(e)}
                            >
                                TODOS
                            </BtnElegir>

                            <BtnElegir
                                onClick={(e) => borrarLetra(e)}
                            >
                                ← Elegir otra letra
                            </BtnElegir>
                        </Container>

                        <Container>
                            <ContainerLink display="flex">
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN1`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura1_${letra.toLowerCase()}.jpg`} alt="" />
                                </ElegirLink>
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN2`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura2_${letra.toLowerCase()}.jpg`} alt="" />
                                </ElegirLink>
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN3`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura3_${letra.toLowerCase()}.jpg`} alt="" />
                                </ElegirLink>
                            </ContainerLink>

                            <ContainerLink display="flex">
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN4`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura4_${letra.toLowerCase()}.jpg`} alt="" />
                                </ElegirLink>
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN5`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura5.jpg`} alt="" />
                                </ElegirLink>
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN6`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura6_${letra.toLowerCase()}.jpg`} alt="" />
                                </ElegirLink>
                            </ContainerLink>

                            <ContainerLink display="flex">
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN7`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura7_${letra.toLowerCase()}.jpg`} alt="" />
                                </ElegirLink>
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN8`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura8.jpg`} alt="" />
                                </ElegirLink>
                                <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN9`}>
                                    <ContainerImg height="130px" heightmob="95px" margin="0 1.5rem 1rem 0" src={`/img/portadas/lectura9.jpg`} alt="" />
                                </ElegirLink>
                            </ContainerLink>
                        </Container>

                    </ContenedorEjercicios>
                    :
                    <ContenedorLetras height="100%" width="100%" justify="center" align="center">
                        <ContenedorLetras margin="1rem" justify="center" align="center">
                        {checkeable.filter(item => item.tipo === 'instruccion_explicita').map(item => (
                            <div key={item.id} >
                                <BtnLetra display="flex" justify="center" align="center" width= "6.5rem" height= "5rem" margin="0.6rem" font="1.8rem" shadow= "0px 4px 4px rgba(0, 0, 0, 0.25)" widthmob="4.5rem" heightmob="3rem"  fontmob="1rem" marginMob= "0.6rem" transform="uppercase"

                                    onClick={(e) => setearLetra(e, item.lista)}
                                >
                                    {item.nombre_lista}
                                </BtnLetra>
                                <InputCheck
                                    type="checkbox"
                                    id={item.id}
                                    onChange={(e) => checkear(e, item.id)}
                                    checked={item.check}
                                     />
                            </div>
                        ))}
                        </ContenedorLetras>
                    </ContenedorLetras>
                }


            </Contenedor>
        </Fragment>
    );
}

export default InstruccionExplicita;