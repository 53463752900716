import React, {Fragment} from 'react';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, BtnColores } from '../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../Css/StyledHeader';

const DenominacionRapida = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lenguaje/"} n1={"Métodos"} n2={"LENGUAJE"} componente={"DENOMINACIÓN RÁPIDA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Container align="space-around" justify="center" height="100%" width="100%" direction="column">
                <Container justify="center" align="center" wrap="wrap">
                    <BtnColores bgcolor="#23AAAF" margin="0.75rem" to={'/escritorio/metodos/lenguaje/denominacion-rapida/LEN11'}>Objetos</BtnColores>
                    <BtnColores bgcolor="#E0923A" margin="0.75rem" to={'/escritorio/metodos/lenguaje/denominacion-rapida/LEN12'}>Letras</BtnColores>
                    <BtnColores bgcolor="#655EE8" margin="0.75rem" to={'/escritorio/metodos/lenguaje/denominacion-rapida/LEN13'}>Numeros</BtnColores>
                    <BtnColores bgcolor="#D05F67" margin="0.75rem" to={'/escritorio/metodos/lenguaje/denominacion-rapida/LEN14'}>Todos</BtnColores>
                </Container>
            </Container>
        </Fragment>
    );
}
 
export default DenominacionRapida;