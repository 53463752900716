import styled, {css, keyframes} from 'styled-components';

// CONTENEDORES
export const Flex = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    height: ${props => props.height};
    width: ${props => props.width};
`
export const ContInst = styled.div`
    position: absolute;
    bottom: 40px; 
    left: 25px; 
    text-transform: uppercase; 
    font-family: nunito;
    font-weight: 400;
    font-size: 16px; 
`
export const ContBottom = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
` 

// IMAGENES
export const Ilustracion = styled.img`
    width: ${props => props.width};
    margin: ${props => props.margin};
    ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
    `}
`

export const Icon = styled.img`
    position: absolute;
    right: ${props => props.right};
    bottom: ${props => props.bottom};
`
// ANIMACIONES
export const ShakeImg = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`
export const ZoomIn = keyframes`
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
`
// BOTONES
export const Btn = styled.button`
    border: 0;
    background: transparent; 
`
export const Button = styled.button`
    border:${props => props.border ? `solid 3px #23AAAF` : `0`};
    border-radius:${props => props.borderradius ? `20px` : `0`};
    background: transparent;
`
export const YellowBtn = styled.button`
    background:#FF9B2A;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 80px;
    height:70px;
    border-radius:10px;
    color: white;
    font-size:24px;
    text-transform:uppercase;
    border:0;
    margin: 40px;
   
`