import styled from 'styled-components'
import {Link} from "react-router-dom"; 
import {createGlobalStyle} from 'styled-components';

export const ContenedorAlerta = styled.div`
    border: 1px;
    position: fixed;
    top: 5rem;
    border-radius: 7px;
    background-color: red;
    `

export const MensajeAlerta = styled.div`
    padding: 5px;
    color: white;
`
