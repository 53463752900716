import styled, { keyframes } from "styled-components";
const media = {
  tablet: "@media (max-width:768px)",
};

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  margin-top: 20px;
  width: ${(props) => props.width};
  height: 10%;
  ${media.tablet} {
    width: ${(props) => props.ancho};
    height: ${(props) => props.alto};
  }
`;

export const TabContainer = styled.div`
  display: inline-block;
  background-color: #eaeaea;
  height: 35px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
`;

export const TabButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 10px 10px 10px 0px;
  background-color: ${({
    backgroundColorEnabled,
    backgroundColorDisabled,
    active,
  }) =>
    active
      ? backgroundColorEnabled
      : backgroundColorDisabled
      ? backgroundColorDisabled
      : "lightgray"};
  word-break: break-word;
  font-size: 0.7rem;
  ${(props) =>
    props.selected &&
    `
    background-color: #f69a24;
    color: #000;
  `}
`;

export const TabContent = styled.div`
  padding: 10px;
  ${({ borderColor }) =>
    borderColor &&
    `
    border: 2px solid ${borderColor};
    border-radius: 10px;
  `}
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

export const Tab = styled.div`
  display: block;
  padding: 10px;
  width: ${({ width }) => (width ? width : "20%")};
  max-height: 400px;
  position: relative;
  ${({ borderRightColor }) =>
    borderRightColor &&
    `
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 2px solid ${borderRightColor};
  `}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #afafaf;
    border-radius: 5px;
  }
`;

/**
 * Skeleton
 */
const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: 200px 0;
  }
`;

export const Skeleton = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: #f2f2f2;
  background-image: linear-gradient(
    to right,
    #ffffff 0%,
    #e6e6e6 20%,
    #e2e2e2 40%,
    #b7b7b7 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;
`;

export const TableContainer = styled.div`
  max-height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #319195;
    border-radius: 5px;
  }
`;

export const Table = styled.table`
  width: ${({ width }) => width};
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  background-color: #f2f2f2;
  font-weight: normal;
  border-radius: 15px;
  padding: 3px;
  font-size: 0.8rem;
`;

export const TableRow = styled.tr`
  transition: opacity 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #ebeef2;
    opacity: 0.8;
  }

  &.row-entered {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const TableCell = styled.td`
  padding-top: 7px;
  border-top: 2px solid #8e8e8e;
  border-bottom: 2px solid #8e8e8e;
  padding: 4px;
  vertical-align: middle;
  text-align: center;
  font-size: 0.8rem;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  position: relative;
`;

const Progress = styled.div`
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: #f69a24;
  border-radius: 5px;
  max-width: 100%;
`;

const ProgressText = styled.p`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  margin: 0;
  text-align: center;
  color: black;
  font-size: 0.7rem;
`;

export const ProgressBar = ({ value }) => {
  return (
    <ProgressBarContainer>
      <Progress progress={value}>
        <ProgressText>{value}%</ProgressText>
      </Progress>
    </ProgressBarContainer>
  );
};
