import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LN4Todos from '../../../Compartidos/LN4Todos';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Lee lo más rápido que puedas las siguientes sílabas.';
const miniInstrucciones = 'Lee lo más rápido que puedas las siguientes sílabas.';

const LN4IS = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN4IS') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/instruccion-sinfones/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[0]));
        if(newList.tipo_1 === '2') {
            const nuevaLista = [
                {id:'1', silaba:newList.silaba_1},
                {id:'2', silaba:newList.silaba_2},
                {id:'3', silaba:newList.silaba_3},
                {id:'4', silaba:newList.silaba_4},
                {id:'5', silaba:newList.silaba_5},
                {id:'6', silaba:newList.silaba_6},
                {id:'7', silaba:newList.silaba_7},
                {id:'8', silaba:newList.silaba_8},
                {id:'9', silaba:newList.silaba_9},
                {id:'10', silaba:newList.silaba_10}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else if (newList.tipo_1 === '3') {
            const nuevaLista = [
                {id:'1', silaba:newList.silaba_1},
                {id:'2', silaba:newList.silaba_2},
                {id:'3', silaba:newList.silaba_3},
                {id:'4', silaba:newList.silaba_4},
                {id:'5', silaba:newList.silaba_5},
                {id:'6', silaba:newList.silaba_6},
                {id:'7', silaba:newList.silaba_7},
                {id:'8', silaba:newList.silaba_8},
                {id:'9', silaba:newList.silaba_9},
                {id:'10', silaba:newList.silaba_10},
                {id:'11', silaba:newList.silaba_11},
                {id:'12', silaba:newList.silaba_12},
                {id:'13', silaba:newList.silaba_13},
                {id:'14', silaba:newList.silaba_14},
                {id:'15', silaba:newList.silaba_15}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else {
            const nuevaLista = [
                {id:'1', silaba:newList.silaba_1},
                {id:'2', silaba:newList.silaba_2},
                {id:'3', silaba:newList.silaba_3},
                {id:'4', silaba:newList.silaba_4},
                {id:'5', silaba:newList.silaba_5},
                {id:'6', silaba:newList.silaba_6},
                {id:'7', silaba:newList.silaba_7},
                {id:'8', silaba:newList.silaba_8},
                {id:'9', silaba:newList.silaba_9},
                {id:'10', silaba:newList.silaba_10},
                {id:'11', silaba:newList.silaba_11},
                {id:'12', silaba:newList.silaba_12}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        }
    }, [informacion])

    return (
        <Fragment>
            <LN4Todos data={data} indexes={indexes} ejercicio="LN4IS" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default LN4IS;