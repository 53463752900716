import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';
import M55Bis from './M55Bis';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { aumentarContadorAction } from '../../../../../../actions/botoneraEjercicioActions';

import { Flex, FlexCuz, NumSumaM55, AvanzarImg, AvanzarBtn, InputM55 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve las sumas.';
const miniInstrucciones = 'Resuelve las sumas.';

const M55 = () => {
    
    const dispatch = useDispatch();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [ejercicioUno, setEjercicioUno] = useState([]);
    const [ejercicioDos, setEjercicioDos] = useState([]);
    const [numeroUno, setNumeroUno] = useState('');
    const [numeroDos, setNumeroDos] = useState('');
    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);

    const indexes1 = Object.keys(ejercicioUno).length;
    const indexes2 = Object.keys(ejercicioDos).length;

    const verificar1 = (e) => {
        e.preventDefault();
        if(ejercicioUno[count-1].resultado.toString() !== numeroUno.toString()){
            console.log('mal');
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
                setNumeroUno('');
            }, 1500);
        } else {
            console.log("bien");
            setDisable(true);
            if(count < indexes1) {
                setTimeout(() => {
                    setDisable(false);
                    setNumeroUno('');
                    setCount(count + 1);
                }, 1500);
            } else {
                setTimeout(() => {
                    setNumeroUno('');
                    dispatch(aumentarContadorAction())
                    setCount(1);
                    setDisable(false);
                }, 1500);
            }
        }
    }
    const verificar2 = (e) => {
        e.preventDefault();
        if(ejercicioDos[count-1].resultado.toString() !== numeroDos.toString()){
            console.log('mal');
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
                setNumeroDos('');
            }, 1500);
        } else {
            console.log("bien");
            setDisable(true);
            if(count < indexes2) {
                setTimeout(() => {
                    setDisable(false);
                    setNumeroDos('');
                    setCount(count + 1);
                }, 1500);
            } else {
                setTimeout(() => {
                    setNumeroDos('');
                    setCount(1);
                    dispatch(aumentarContadorAction())
                    setDisable(false);
                }, 1500);
            }
        }
    }

    useEffect(() => {

        const data = [
            {id:'1', numero:1, resultado:2},
            {id:'2', numero:2, resultado:4},
            {id:'3', numero:3, resultado:6},
            {id:'4', numero:4, resultado:8},
            {id:'5', numero:5, resultado:10},
            {id:'6', numero:6, resultado:12},
            {id:'7', numero:7, resultado:14},
            {id:'8', numero:8, resultado:16},
            {id:'9', numero:9, resultado:18},
            {id:'10', numero:10, resultado:20}
        ];
        setComparar(data);

        const newList1 = JSON.parse(JSON.stringify(data));
        const newList2 = JSON.parse(JSON.stringify(data));
        const data1 = newList1.slice(0,4);
        const data2 = newList2.slice(4,7);

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList1 = shuffle(data1);
        const shuffleList2 = shuffle(data2);

        setEjercicioUno(shuffleList1);
        setEjercicioDos(shuffleList2);

    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M55') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar || fuerzoInicio
                ?
                <Fragment>
                    {contador === 0 &&
                    <Flex height='75vh'>
                        {comparar.filter(item => item.id === (contadorSecundario + 1).toString() ).map(item => 
                            <Flex key={item.id} height='fit-content'>
                                <NumSumaM55 color='#FF9B2A' w='8vw'>{item.numero}</NumSumaM55>
                                <NumSumaM55 color='#646464' w='8vw'>+</NumSumaM55>
                                <NumSumaM55 color='#FF9B2A' w='8vw'>{item.numero}</NumSumaM55>
                                <NumSumaM55 color='#646464' w='8vw'>=</NumSumaM55>
                                <NumSumaM55 color='#FFFFFF' bgcolor='#23AAAF' bradius='20px' w='15vw'>{item.resultado}</NumSumaM55>
                            </Flex>
                        )}
                    </Flex>
                    }

                    {contador === 1 &&
                    <Flex height='75vh'>
                            <NumSumaM55 color='#FF9B2A' w='8vw'>{ejercicioUno[count-1].numero}</NumSumaM55>
                            <NumSumaM55 color='#646464' w='8vw'>+</NumSumaM55>
                            <NumSumaM55 color='#FF9B2A' w='8vw'>{ejercicioUno[count-1].numero}</NumSumaM55>
                            <NumSumaM55 color='#646464' w='8vw'>=</NumSumaM55>
                            <form onSubmit={(e) => verificar1(e)}>
                                <InputM55 type="numeric" min="1" max="20" value={numeroUno} onChange={(e) => setNumeroUno(e.target.value)} disabled={disable} color='#FF9B2A' w='15vw' bs='inset 0px 4px 4px rgba(0, 0, 0, 0.25)' bradius='20px' />
                            </form>
                    </Flex>
                    }
                    {contador === 2 &&
                    <Flex height='75vh'>
                        {comparar.filter(item => item.id === (contadorSecundario + 2).toString() ).map(item => 
                            <Flex key={item.id}>
                                <NumSumaM55 color='#FF9B2A' w='8vw'>{item.numero}</NumSumaM55>
                                <NumSumaM55 color='#646464' w='8vw'>+</NumSumaM55>
                                <NumSumaM55 color='#FF9B2A' w='8vw'>{item.numero}</NumSumaM55>
                                <NumSumaM55 color='#646464' w='8vw'>=</NumSumaM55>
                                <NumSumaM55 color='#FFFFFF' bgcolor='#23AAAF' bradius='20px' w='15vw'>{item.resultado}</NumSumaM55>
                            </Flex>
                        )}
                    </Flex>
                    }
                    {contador === 3 &&
                    <Flex height='75vh'>
                            <NumSumaM55 color='#FF9B2A' w='8vw'>{ejercicioDos[count-1].numero}</NumSumaM55>
                            <NumSumaM55 color='#646464' w='8vw'>+</NumSumaM55>
                            <NumSumaM55 color='#FF9B2A' w='8vw'>{ejercicioDos[count-1].numero}</NumSumaM55>
                            <NumSumaM55 color='#646464' w='8vw'>=</NumSumaM55>
                            <form onSubmit={(e) => verificar2(e)}>
                                <InputM55 type="numeric" min="1" max="20" value={numeroDos} onChange={(e) => setNumeroDos(e.target.value)} disabled={disable} color='#FF9B2A' w='15vw' bs='inset 0px 4px 4px rgba(0, 0, 0, 0.25)' bradius='20px' />
                            </form>
                    </Flex>
                    }
                    {contador === 4 &&
                    <Flex height='75vh'>
                        {comparar.filter(item => item.id === (contadorSecundario + 3).toString() ).map(item => 
                            <Flex key={item.id}>
                                <NumSumaM55 color='#FF9B2A' w='12vw'>{item.numero}</NumSumaM55>
                                <NumSumaM55 color='#646464' w='8vw'>+</NumSumaM55>
                                <NumSumaM55 color='#FF9B2A' w='12vw'>{item.numero}</NumSumaM55>
                                <NumSumaM55 color='#646464' w='8vw'>=</NumSumaM55>
                                <NumSumaM55 color='#FFFFFF' bgcolor='#23AAAF' bradius='20px' w='15vw'>{item.resultado}</NumSumaM55>
                            </Flex>
                        )}
                    </Flex>
                    }
                    {contador === 5 &&
                    <M55Bis mostrar={true} />
                    }

                    <FlexCuz position='fixed' right='0' bottom='0'>    
                        {contador === 0 && contadorSecundario === 0 && <BotoneraBottom aumentarSecundario={true} /> }
                        {contador === 0 && contadorSecundario < 3 && contadorSecundario > 0 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} /> }
                        {contador === 0 && contadorSecundario === 3 && <BotoneraBottom reducirSecundario={true} aumentar={true} /> }
                        {contador === 1 && 
                        <FlexCuz direction='row-reverse'  position='fixed' right='0' bottom='0'>
                            <div>{miniInstrucciones}</div>
                            <AvanzarBtn
                                disabled={disable}
                                onClick={(e) => verificar1(e)}
                            >
                                <AvanzarImg src={`/img/play.png`} /> 
                            </AvanzarBtn>
                        </FlexCuz>
                        }
                        {contador === 2 && contadorSecundario === 3 && <BotoneraBottom aumentarSecundario={true} /> }
                        {contador === 2 && contadorSecundario < 5  && contadorSecundario > 3  && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} /> }
                        {contador === 2 && contadorSecundario === 5  && <BotoneraBottom reducirSecundario={true} aumentar={true} /> }
                        {contador === 3 && 
                        <FlexCuz direction='row-reverse'  position='fixed' right='0' bottom='0'>
                            <div>{miniInstrucciones}</div>
                            <AvanzarBtn
                                disabled={disable}
                                onClick={(e) => verificar2(e)}
                            >
                                <AvanzarImg src={`/img/play.png`} /> 
                            </AvanzarBtn>
                        </FlexCuz>
                        }
                        {contador === 4 && contadorSecundario === 5 && <BotoneraBottom aumentarSecundario={true} /> }
                        {contador === 4 && contadorSecundario < 7  && contadorSecundario > 5  && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} /> }
                        {contador === 4 && contadorSecundario === 7  && <BotoneraBottom reducirSecundario={true} aumentar={true} /> }
                    </FlexCuz>    
                </Fragment>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M55;