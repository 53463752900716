import {
    SOLICITO_INFORMACION_EJERCICIO_EXITO,
    SOLICITO_INFORMACION_PACIENTE_EXITO,
    SOLICITAR_OBJETIVOS_EXITO,
    MODIFICAR_OBJETIVO_EXITO,
    SOLICITO_ANAMNESIS_EXITO,
    SOLICITAR_HISTORIA_CLINICA_EXITO,
    MODIFICAR_HISTORIA_CLINICA_EXITO,
    SOLICITAR_ACTIVIDADES_EXITO,
    SOLICITAR_ACTIVIDADES_AGENDADAS_EXITO,
    ACTUALIZAR_INFO_ACTIVIDADES_EXITO,
    ACTUALIZAR_INFORMACION_PACIENTE_EXITO,
    ESTADO_PACIENTE_INITIAL,
    ESTADO_USUARIO_PACIENTE_INITIAL,
    CERRAR_SESION,
    RESET_EJERCICIO,
    CONSULTAR_ACTIVIDADES_RESULTADOS_SUCCESS,
    CONSULTAR_EJERCICIOS_RESULTADOS_SUCCESS,
    MODIFICAR_PACIENTES,
    ALMACENAR_CHECK_EJERCICIO_EXITO,
    BORRAR_INFO_DB
} from '../types';

const initialState = {
    info_DB: [],
    lista_pacientes:[],
    lista_ejercicios_check:[],
    lista_usada:[],
    info_anamnesis: [],
    info_objetivos: [],
    info_historia_clinica: [],
    info_actividades: [],
    info_actividades_agendadas: [],
    info_resultados: []
};

export default function solicitoInformacionReducer(state = initialState, action) {
    switch(action.type) {
        case CERRAR_SESION:
            return {
                info_DB: [],
                lista_pacientes:[],
                lista_ejercicios_check:[],
                lista_usada:[],
                info_anamnesis: [],
                info_objetivos: [],
                info_historia_clinica: [],
                info_actividades: [],
                info_actividades_agendadas: [],
                info_resultados: [],
                info_restuladosDos: []
            }
        case SOLICITO_INFORMACION_EJERCICIO_EXITO:
            return {
                ...state,
                info_DB: action.payload.info_ejercicio,
                lista_usada: action.payload.lista_utilizada
            }
        case SOLICITO_INFORMACION_PACIENTE_EXITO:
        case ACTUALIZAR_INFORMACION_PACIENTE_EXITO:
            return {
                ...state,
                lista_pacientes: action.payload.lista_pacientes,
                lista_ejercicios_check: action.payload.lista_ejercicios_check
            }
        case SOLICITAR_OBJETIVOS_EXITO:
            return {
                ...state,
                info_objetivos: action.payload
            }
        case MODIFICAR_OBJETIVO_EXITO:
            return {
                ...state,
                info_objetivos: action.payload
            }
        case SOLICITO_ANAMNESIS_EXITO:
            return {
                ...state,
                info_anamnesis: action.payload
            }
        case SOLICITAR_HISTORIA_CLINICA_EXITO:
        case MODIFICAR_HISTORIA_CLINICA_EXITO:
            return {
                ...state,
                info_historia_clinica: action.payload
            }
        case SOLICITAR_ACTIVIDADES_EXITO:
        case ACTUALIZAR_INFO_ACTIVIDADES_EXITO:
            return {
                ...state,
                info_actividades: action.payload
            }
        case SOLICITAR_ACTIVIDADES_AGENDADAS_EXITO:
            return {
                ...state,
                info_actividades_agendadas: action.payload
            }
        case CONSULTAR_ACTIVIDADES_RESULTADOS_SUCCESS:
            return {
                ...state,
                info_resultadosDos: action.payload
            }
        case CONSULTAR_EJERCICIOS_RESULTADOS_SUCCESS:
            return {
                ...state,
                info_resultados: action.payload
            }
        case ESTADO_PACIENTE_INITIAL:
            return {
                ...state,
                info_anamnesis: [],
                info_historia_clinica: [],
                info_objetivos: [],
                info_actividades_agendadas: [],
                info_resultados: []
            }
        case ESTADO_USUARIO_PACIENTE_INITIAL:
            return {
                ...state,
                info_DB: [],
                lista_pacientes:[],
                lista_ejercicios_check:[],
                lista_usada:[],
                info_anamnesis: [],
                info_objetivos: [],
                info_historia_clinica: [],
                info_actividades: [],
                info_resultados: []
            }
        case RESET_EJERCICIO:
        case BORRAR_INFO_DB:
            return {
                ...state,
                info_DB:[]
            }
        case MODIFICAR_PACIENTES:
            return {
                ...state,
                lista_pacientes: action.payload
            }
        case ALMACENAR_CHECK_EJERCICIO_EXITO:
            return {
                ...state,
                lista_ejercicios_check: action.payload
            }
        default:
            return state;
    }
}