import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, ContainerImg, FlexLink, Descripcion } from '../../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../../Css/StyledHeader';

const CCRimaLexica = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lenguaje/"} l3={"conciencia-fonologica/"} n1={"Métodos"} n2={"LENGUAJE"} n3={"CONCIENCIA FONOLÓGICA"} componente={"CCRIMALEXICA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Container justify="space-around" align="center" width="100%" height="100%">
                <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-rima-lexica/LEN25'}><ContainerImg src={`/img/portadas/LEN25.jpg`} alt=""/> <Descripcion>SÍLABA FINAL</Descripcion></FlexLink>
                <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-rima-lexica/LEN26'}><ContainerImg src={`/img/portadas/LEN26.jpg`} alt=""/> <Descripcion>SÍLABA FINAL</Descripcion></FlexLink>
            </Container>
        </Fragment>    
    );
}
 
export default CCRimaLexica;