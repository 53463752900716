import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ImgSubL1 from '../../../Compartidos/ImgSubL1';
//CAMI

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Di la cantidad de puntos que observas a la derecha y la cantidad de puntos que observas a la izquierda. Hazlo lo más rápido que puedas.';
const miniInstrucciones = 'Di la cantidad de puntos que observas a la derecha y la cantidad de puntos que observas a la izquierda. ';

const M4 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('M4') );
        dispatch(setearUrlAnteriorAction('matematica/subitizacion/nivel-1/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])
    
    return (
        <Fragment>
            <ImgSubL1 width="450px" wm='80vw' wt='65vw' wd='59vw' wlg='52vw' height='40vh' ht='50vh' hd='51vh' hlg='60vh' margin='-3vh 0px 0px 0vh' data={data} indexes={indexes} ejercicio="M4" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} estrellas={true} carrito={true} h="20rem" w="40rem" />
        </Fragment>
    );
}
 
export default M4;