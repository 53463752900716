import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, DivFlex, Img, LetraESC6, InputLetra, FormInputESC6, DivLetraESC6 } from '../../../../Css/MetodosCSS/StyledLectoEscritura';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Completa las letras que faltan.';
const miniInstrucciones = 'Completa las letras que faltan.';

const ESC6 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [completar, setCompletar] = useState([]);
    const [palabra, setPalabra] = useState('');
    const [palabra1, setPalabra1] = useState('');
    const [palabra2, setPalabra2] = useState('');
    const [palabra3, setPalabra3] = useState('');
    const [palabra4, setPalabra4] = useState('');
    const [palabra5, setPalabra5] = useState('');
    const [palabra6, setPalabra6] = useState('');
    const [palabra7, setPalabra7] = useState('');
    const [palabra8, setPalabra8] = useState('');
    const [palabra9, setPalabra9] = useState('');
    const [count, setCount] = useState(1);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const lista = useSelector((state) => state.ejercicio.lista);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const handlePalabra = (e, posicion, letra) => {
        e.preventDefault();
        if(e.target.value.length === 2) {
            return
        }
        switch (posicion) {
            case '0':
                setPalabra(letra);
                break;
            case '1':
                setPalabra1(letra);
                break;
            case '2':
                setPalabra2(letra);
                break;
            case '3':
                setPalabra3(letra);
                break;
            case '4':
                setPalabra4(letra);
                break;
            case '5':
                setPalabra5(letra);
                break;
            case '6':
                setPalabra6(letra);
                break;
            case '7':
                setPalabra7(letra);
                break;
            case '8':
                setPalabra8(letra);
                break;
            case '9':
                setPalabra9(letra);
                break;
            default:
                break;
        }
    }

    const verificar = (e) => {
        e.preventDefault();
        const arrayPalabra = [];
        arrayPalabra.push(completar[0], palabra, completar[1], palabra1, completar[2], palabra2, completar[3], palabra3, completar[4], palabra4, completar[5], palabra5, completar[6], palabra6, completar[7], palabra7, completar[8], palabra8, completar[9], palabra9);
        const arrayPalabraFinal = arrayPalabra.filter(item => item !== '');
        const palabraFinal = arrayPalabraFinal.join('');
        if (palabraFinal.toUpperCase() !== informacion[count-1].palabra_1.toUpperCase()) {
            console.log('mal');
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setPalabra('');
                setPalabra1('');
                setPalabra2('');
                setPalabra3('');
                setPalabra4('');
                setPalabra5('');
                setPalabra6('');
                setPalabra7('');
                setPalabra8('');
                setPalabra9('');
            }, 1000);
        } else {
            console.log('bien')
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setPalabra('');
                    setPalabra1('');
                    setPalabra2('');
                    setPalabra3('');
                    setPalabra4('');
                    setPalabra5('');
                    setPalabra6('');
                    setPalabra7('');
                    setPalabra8('');
                    setPalabra9('');
                }, 1000);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1000);
            }
        }
    }

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[count-1]));
        const split = newList.palabra_1.toUpperCase().split("");
        const index1 = split.findIndex((ltr, i) => ltr === informacion[count-1].letra_1.toUpperCase());
        const index2 = split.findIndex((ltr, i) => ltr === informacion[count-1].letra_2.toUpperCase() && i !== index1);
        const index3 = split.findIndex((ltr, i) => ltr === informacion[count-1].letra_3.toUpperCase() && i !== index1 && i !== index2);
        const index4 = split.findIndex((ltr, i) => ltr === informacion[count-1].letra_4.toUpperCase() && i !== index1 && i !== index2  && i !== index3);
        const index5 = split.findIndex((ltr, i) => ltr === informacion[count-1].letra_5.toUpperCase() && i !== index1 && i !== index2  && i !== index3  && i !== index4);
        split[index1] = '';
        split[index2] = '';
        split[index3] = '';
        split[index4] = '';
        split[index5] = '';
        setCompletar(split);
        const newList3 = JSON.parse(JSON.stringify(informacion[count-1]));
        setComparar(newList3);
        setIndexes(Object.keys(informacion).length);
    }, [informacion, count])

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC6') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/silaba-directa/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC6",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    lista,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuImagen={true} />
            {iniciar
                ?
                <ContainerFlexx height="100%" width="100%" display="flex" direction="column" justify="center" align="center">
                    <DivFlex display="flex" wrap='wrap' justify='space-evenly' align='center' margin="auto"
                    isCorrect={isCorrect} 
                    isIncorrect={isIncorrect}
                    >
                        <ContainerFlexx display="flex" direction="column" justify="center" align="center">
                            <Img width="190px" height="200px" src={`/img/${comparar.imagen_url_1}.png`} />
                            {ayuda_imagen ? 
                                <div>{comparar.ayuda_imagen_1}</div>
                            : null
                            }
                        </ContainerFlexx>
                        <ContainerFlexx>
                            {completar[0] === undefined ? null : completar[0] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[0]}</LetraESC6>
                                </DivLetraESC6>
                                : completar[0] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh"
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra} onChange={(e) => handlePalabra(e, '0', e.target.value)} disabled={disable} />
                                    </FormInputESC6>

                                    : null
                            }
                            {completar[1] === undefined ? null : completar[1] !== undefined && completar[1] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[1]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[1] !== undefined && completar[1] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh"
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra1} onChange={(e) => handlePalabra(e, '1', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }
                        
                            {completar[2] === undefined ? null : completar[2] !== undefined && completar[2] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[2]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[2] !== undefined && completar[2] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh" 
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra2} onChange={(e) => handlePalabra(e, '2', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }
                            {completar[3] === undefined ? null : completar[3] !== undefined && completar[3] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[3]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[3] !== undefined && completar[3] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh" 
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra3} onChange={(e) => handlePalabra(e, '3', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }

                            {completar[4] === undefined ? null : completar[4] !== undefined && completar[4] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[4]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[4] !== undefined && completar[4] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh"  
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra4} onChange={(e) => handlePalabra(e, '4', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }

                            {completar[5] === undefined ? null : completar[5] !== undefined && completar[5] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[5]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[5] !== undefined && completar[5] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh"  
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra5} onChange={(e) => handlePalabra(e, '5', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }

                            {completar[6] === undefined ? null : completar[6] !== undefined && completar[6] !== '' ?
                                <DivLetraESC6>
                                <LetraESC6 $minMay={minuscula}>{completar[6]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[6] !== undefined && completar[6] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh" 
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra6} onChange={(e) => handlePalabra(e, '6', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }

                            {completar[7] === undefined ? null : completar[7] !== undefined && completar[7] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[7]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[7] !== undefined && completar[7] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh" 
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra7} onChange={(e) => handlePalabra(e, '7', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }

                            {completar[8] === undefined ? null : completar[8] !== undefined && completar[8] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[8]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[8] !== undefined && completar[8] === '' ?
                                    <FormInputESC6  
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra8} onChange={(e) => handlePalabra(e, '8', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }

                            {completar[9] === undefined ? null : completar[9] !== undefined && completar[9] !== '' ?
                                <DivLetraESC6>
                                    <LetraESC6 $minMay={minuscula}>{completar[9]}</LetraESC6>
                                </DivLetraESC6>

                                : completar[9] !== undefined && completar[9] === '' ?
                                    <FormInputESC6 width="6vw" height="12vh" 
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputLetra $minMay={minuscula} type="text" value={palabra9} onChange={(e) => handlePalabra(e, '9', e.target.value)} disabled={disable} />
                                    </FormInputESC6>
                                    : null
                            }
                        </ContainerFlexx>
                    </DivFlex>

                    <ContainerFlexx margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlexx>

                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC6;