import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../../Metodos/Compartidos/BotoneraBottom';
import M63Bis from './M63Bis';
import M63Practica from './M63Practica';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgB10, PasosSubT, DivM83, Container, PasosT, Pasos, NumRedondo, NumRedondoM63, NumCuadrado, NumCuadrado63, NumRedondo63, NumberDivR, NumberDivL, ImgDiv, ImgDivM63, NumCalculoM83 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la suma.';
const miniInstrucciones = 'Resuelve la suma.';

const M63 = () => {
    
    const dispatch = useDispatch();

    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);

    useEffect(() => {
        dispatch(setearEjercicioAction('M63') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/suma-descomposicion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
                <Fragment>
                    {iniciar || fuerzoInicio
                        ?
                        <Fragment>
                            {contador=== 0 &&
                            <div>
                                {contadorSecundario >= 0 &&
                                <FlexFull justify='space-evenly' align='center'> {/*  border='2px solid blue' */} 
                                    <FlexFull direction='column' justify='space-around' align='start' width='48vw' height='60vh' hlg='70vh'> {/*  border='2px solid red' */} 
                                        <FlexFull direction='column' justify='center' align='start' width='48vw'>
                                            {/* CALCULO */}
                                            <FlexFull justify='end' align='center' width='100%'> {/*  border='2px solid blue' */} 
                                                <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                                    <NumCalculoM83 color='#D76970'>56</NumCalculoM83>
                                                    <NumCalculoM83 color='#646464'>+</NumCalculoM83>                                     
                                                    <NumCalculoM83 color='#23AAAF'>35</NumCalculoM83>
                                                    <NumCalculoM83 color='#646464'>=</NumCalculoM83>                                     
                                                    <NumCalculoM83 color='#646464'>
                                                    {contadorSecundario >= 20 && <div>85</div>}
                                                    </NumCalculoM83>
                                                </FlexFull>
                                            </FlexFull>
                                            {/* FIN CALCULO */}
                                            {/* DESCOMPOSICIÓN */}
                                            <FlexFull justify='space-around' align='center' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='-18px 0vw 0vh 15px'> {/* border='3px solid blue' */}
                                                <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 2vw 0vh 0vw' mt='0px 1.5vw 0vh 0px' md='0px 1.2vw 0vh 0px' mlg='0px 2.3vw 0vh 0px'> { /* border='3px solid violet'     mt='0vh 1.5vw 0vh 0vw' mlg='0px' */}
                                                {contadorSecundario >= 1 &&
                                                <div>
                                                    <ImgDiv>
                                                        <ImgB10 src={`/img/lineasDescomposicion.png`}  w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                                    </ImgDiv>
                                                    <Flex>
                                                        <NumberDivL>
                                                            {contadorSecundario >= 2 &&
                                                            <NumRedondo>50</NumRedondo>
                                                            }
                                                        </NumberDivL>
                                                        <NumberDivR>
                                                            {contadorSecundario >= 3 &&
                                                                <NumCuadrado>6</NumCuadrado>
                                                            }
                                                        </NumberDivR>
                                                    </Flex>
                                                </div>
                                                }
                                                </Flex>
                                                <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 0vw 0vh 2vw' mlg='0px'>  { /*   margin='0vh 0vw 0vh 4vw'   border='1px solid green' */}
                                                    {contadorSecundario >= 4 &&
                                                    <div>
                                                        <ImgDiv>
                                                            <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                                        </ImgDiv>
                                                        <Flex>
                                                            <NumberDivL>
                                                                {contadorSecundario >= 5 &&
                                                                <NumRedondo>30</NumRedondo>
                                                                }
                                                            </NumberDivL>
                                                            <NumberDivR>
                                                                {contadorSecundario >= 6 &&
                                                                <NumCuadrado>5</NumCuadrado>
                                                                }
                                                            </NumberDivR>
                                                        </Flex>
                                                    </div>
                                                    }
                                                </Flex>
                                            </FlexFull>
                                            {/* ------------------ MÁS NUEVO */}
                                            <FlexFull justify='space-evenly' align='center' width='32.3vw' minw='32.3vw'  margin='-27px 0vw 0vh 0px' mm='-25px 0vw 0vh 0px' mt='-23px 0vw 0vh -5px' md='-20px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px'> {/* border='2px solid blue' margin='-7.5vh 0vw 0vh 0px' mm='-43px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px' */}
                                                <Container minw='6vw'>
                                                    {contadorSecundario >= 9 &&
                                                        <NumRedondo63>80</NumRedondo63>
                                                    }
                                                </Container>
                                                <Container minw='6vw'>
                                                    {contadorSecundario >= 8 &&
                                                        <NumCuadrado63>11</NumCuadrado63>
                                                    }
                                                </Container>
                                            </FlexFull>
                                            {/* ------------------ FIN MÁS NUEVO */}
                                            {/* ---------------- NUEVO */}
                                            <FlexFull justify='end' align='center' width='32.3vw' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' margin='53px 0vw 0vh 0px' mm='38px 0vw 0vh 0px' mt='23px 0vw 0vh -5px' md='3px 0vw 0vh -5px' mlg='-5px 0vw 5px 15px'>  { /* width='32.3vw' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' margin='0vh 0vw 0vh 0px' mm='0px 0vw 0vh 0px' mt='0px 0vw 0vh -5px' md='0px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px' border='2px solid green' */}
                                                <FlexFull justify='center' align='flex-end' minw='50%' minh='100%' maxh='100%'>
                                                    {contadorSecundario >= 14 &&
                                                        <NumRedondoM63 m='0px 10px 20px 0px' mm='0px 0px 20px 0px' mt='0px 0px 20px 0px' md='0px 0px 15px 0px' mlg='0px 0px 0px 0px'>80</NumRedondoM63> 
                                                    }
                                                </FlexFull>
                                                <FlexFull justify='end' align='end' minw='50%' minh='100%' maxh='100%'> {/* width='10vw' minw='15vw' height='30vh' margin='-23px -7.8vw 0vh 0vw' mm='-33px -7vw 0vh 0px' mt='-37px -5.2vw 0vh 0px' md='-43px -5vw 0vh 0px' mlg='-33px -45px 0vh 0px' */}
                                                    {contadorSecundario >= 11 &&
                                                    <FlexFull direction='column' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' justify='end' align='center' padding='0px' margin='0px'>
                                                        <ImgDivM63>
                                                            <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='12vh' m='50px 0px 0px 20px' mm='50px 0px 0px 15px' mt='50px 0px 0px 10px' md='50px 0px 0px 5px' mlg='50px 0px 0px 0px'  />
                                                        </ImgDivM63>
                                                        <Flex>
                                                            <NumberDivL>
                                                                {contadorSecundario >= 5 && 
                                                                // contadorSecundario <= 12 ?
                                                                    <NumRedondo>10</NumRedondo> 
                                                                    // contadorSecundario <= 4 ?
                                                                    // null :
                                                                    // <NumRedondoTachado>5</NumRedondoTachado>
                                                                }
                                                            </NumberDivL>
                                                            <NumberDivR>
                                                                {contadorSecundario >= 10 &&
                                                                <NumCuadrado>1</NumCuadrado>
                                                                }
                                                            </NumberDivR>
                                                        </Flex>
                                                    </FlexFull>
                                                    }
                                                </FlexFull>
                                            </FlexFull>
                                            {/* ---------------- NUEVO */}
                                            {/* FIN DESCOMPOSICIÓN */}
                                        </FlexFull>
                                        {/* CALCULO ABAJO */}
                                        <FlexFull justify='center' align='center' width='100%'> {/*  border='2px solid blue' */} 
                                            <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                                <DivM83>
                                                {contadorSecundario >= 15 &&
                                                    <div>
                                                        {contadorSecundario >= 16 &&
                                                        <NumRedondo>90</NumRedondo>
                                                        }
                                                    </div>
                                                }
                                                </DivM83>
                                                <DivM83>
                                                {contadorSecundario >= 19 &&
                                                    <NumCalculoM83 color='#646464'>+</NumCalculoM83>
                                                }
                                                </DivM83>
                                                <DivM83>
                                                {contadorSecundario >= 17 &&
                                                    <div>
                                                        {contadorSecundario >= 18 &&
                                                        <NumCuadrado>1</NumCuadrado>
                                                        }
                                                    </div>
                                                }
                                                </DivM83>
                                                <DivM83>
                                                {contadorSecundario >= 19 &&
                                                    <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                                }
                                                </DivM83>
                                                <DivM83>
                                                {contadorSecundario >= 20 && <NumCuadrado>85</NumCuadrado>}
                                                </DivM83>
                                            </FlexFull>
                                        </FlexFull>
                                        {/* FIN CALCULO ABAJO */}
                                    </FlexFull>
                                    {/* PASOS */}
                                    <FlexFull direction='column' justify='start' align='center' width='25vw' height='80vh'>
                                        <PasosT>
                                            PASOS
                                        </PasosT>
                                        <PasosSubT>
                                        SUMA DESCOMPOSICIÓN
                                        </PasosSubT>
                                        {contadorSecundario >= 1 &&
                                        <Pasos bgcolor='#D76970'>
                                            1- DESCOMPONGO EL PRIMER NÚMERO
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 4 &&
                                        <Pasos bgcolor='#23AAAF'>
                                            2- DESCOMPONGOO EL SEGUNDO NÚMERO
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 7 &&
                                        <Pasos bgcolor='#FF9B2A'>
                                            3- SUMO LAS UNIDADES
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 9 &&
                                        <Pasos bgcolor='#FFDC73'>
                                            4- SUMO LAS DECENAS
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 10 &&
                                        <Pasos bgcolor='#655EE8'>
                                            5- VUELVO A DESCOMPONER LAS UNIDADES
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 15 &&
                                        <Pasos bgcolor='#D76970'>
                                            6- SUMO LAS DECENAS
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 17 &&
                                        <Pasos bgcolor='#23AAAF'>
                                            7- VUELVO A ESCRIBIR LAS UNIDADES
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 19 &&
                                        <Pasos bgcolor='#FF9B2A'>
                                            8- HAGO LA SUMA
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 20 &&
                                        <Pasos bgcolor='#FFDC73'>
                                            9- ¿CUÁL ES EL RESULTADO?
                                        </Pasos>
                                        }
                                    </FlexFull>
                                    {/* FIN PASOS */}
                                </FlexFull>
                                }
                                <FlexCuz  position='fixed' right='0' bottom='0'>
                                    {contadorSecundario === 0 && <BotoneraBottom aumentarSecundario={true} />}
                                    {contadorSecundario > 0 && contadorSecundario < 20 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />}
                                    {contadorSecundario === 20 && <BotoneraBottom reducirSecundario={true} aumentar={true} />}
                                </FlexCuz>
                            </div>
                            }
                            {contador === 1 && <M63Bis mostrar={true} />}
                            {contador === 2 && <M63Practica mostrar={true} />}
                        </Fragment>
                        :
                        <Instrucciones instrucciones={instrucciones} />
                    }
                </Fragment>
        </Fragment>
    );
}
 
export default M63;