import {
    LOGIN_EXITO,
    LOGIN_ERROR,
    AUTENTICADO_EXITO,
    AUTENTICADO_ERROR,
    CERRAR_SESION
} from '../types';


const initialState = {
    token: localStorage.getItem('token'),
    autenticado: false,
    tiempo: ''
}

export default function autenticarReducer(state = initialState, action) {
    switch(action.type) {
        case LOGIN_EXITO:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                autenticado: true,
                token: action.payload.token
            }
        case LOGIN_ERROR:
            localStorage.removeItem('token');
            return {
                ...state,
                tiempo: '',
                autenticado: false,
                token: ''
            }
        case AUTENTICADO_EXITO:
            return {
                ...state,
                tiempo: action.payload,
                autenticado: true
            }
        case AUTENTICADO_ERROR:
            localStorage.removeItem('token');
            return {
                ...state,
                tiempo: '',
                autenticado: false              
            }
        case CERRAR_SESION:
            localStorage.removeItem('token');
            return {
                ...state,
                tiempo: '',
                autenticado: false
            }
        default:
            return state;
    }
}