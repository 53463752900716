import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ObjetivosNuevos from './ObjetivosNuevos';
import { modificarObjetivoAction } from '../../actions/objetivosActions';
import useConfigAuth from '../../Hooks/useConfigAuth';

//css
import {CuerpoCompleto, FlexBox, Icon} from '../Css/StyledHeader'
import {Fondo, P,  Ul, ListadoObj, Circulo} from '../Css/StyledActivities';

const ObjetivosDisponibles = (props) => {

    const { id } = props.seccion;

    const dispatch = useDispatch();

    const [config] = useConfigAuth();

    const informacionObjetivos = useSelector((state) => state.informacion.info_objetivos);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);

    const [disable, setDisable] = useState(false);
    const [objetivosNuevo, setObjetivosNuevo] = useState(informacionObjetivos);
    const [objetivoBD, setObjetivoBD] = useState([]);

    const cambiarEtapa = (id_objetivo, custom_objetivo, etapa) => {
        if(etapa === "2") {
            const newList = JSON.parse(JSON.stringify(objetivosNuevo));
            for(let i = 0; i < newList.length; i++){
                if(newList[i].id === id_objetivo && newList[i].custom === custom_objetivo){
                    newList[i].id_etapa_objetivo = '1'
                    setObjetivoBD(newList[i]);
                }
            }
            setObjetivosNuevo(newList);
            setDisable(true);
        } else {
            const newList = JSON.parse(JSON.stringify(objetivosNuevo));
            for(let i = 0; i < newList.length; i++){
                if(newList[i].id === id_objetivo && newList[i].custom === custom_objetivo){
                    newList[i].id_etapa_objetivo = '2'
                    setObjetivoBD(newList[i]);
                }
            }
            setObjetivosNuevo(newList);
            setDisable(true);
        }        
    }

    useEffect(() => {
        if(objetivoBD.length === 0){
            return;
        }
        const informacion = {
            config,
            payload: {
                objetivosNuevo,
                objetivoBD,
                id_paciente,
                tipo: "etapa"
            }
        };

        dispatch(modificarObjetivoAction(informacion));
        setObjetivoBD([]);
        setTimeout(() => {
            setDisable(false);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objetivosNuevo])

    useEffect(() => {
        setObjetivosNuevo(
            informacionObjetivos
        );
    }, [informacionObjetivos])
    

    return (
        <CuerpoCompleto height="75vh" width="100vw" margin="0.5rem">
          <FlexBox justify="center">
          <Fondo width="80vw">
            <FlexBox justify="center">
                <FlexBox>
                  <P margin="10px 0px">Objetivo no agregado</P>
                  <Icon src={`/img/circuloRosa.png`} alt="Rosa" margin="15px 20px 0px 10px" width="10px" height="10px"/>
                </FlexBox>
                <FlexBox>
                  <P margin="10px 0px">Objetivo agregado</P>
                  <Icon src={`/img/circuloVerde.png`} alt="Verde" margin="15px 0px 0px 10px" width="10px" height="10px"/>
                </FlexBox>
              </FlexBox>
            <div>
                <Ul espacio="0px">
                    {informacionObjetivos.filter(objetivo => objetivo.id_seccion_objetivo.toString() === id.toString()).map(objetivo => (
                        <ListadoObj key={objetivo.id}>
                                <P padding="0px 10px">{objetivo.nombre_objetivo}</P>
                                <FlexBox justify="flex-end">
                                {objetivo.id_etapa_objetivo === "2"
                                ?(<Circulo
                                    disabled={disable}
                                    type="button"
                                    onClick={() => cambiarEtapa(objetivo.id, objetivo.custom, objetivo.id_etapa_objetivo)}
                                    ><Icon src={`/img/circuloVerde.png`} alt="Verde" width="10px" height="10px"/></Circulo>)
                                :(<Circulo
                                    disabled={disable}
                                    type="button"
                                    onClick={() => cambiarEtapa(objetivo.id, objetivo.custom, objetivo.id_etapa_objetivo)}
                                    ><Icon src={`/img/circuloRosa.png`} alt="Rosa" width="10px" height="10px" /></Circulo>)
                                }
                                </FlexBox>
                        </ListadoObj>
                    ))}
                </Ul>
            </div>
            <div>
            <ObjetivosNuevos seccion={props.seccion} />
            </div>
          </Fondo>  
          </FlexBox>
        </CuerpoCompleto>
    );
}
 
export default ObjetivosDisponibles;