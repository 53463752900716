import { useDispatch } from 'react-redux';

import { estadoErrorInitialAction, estadoLoadingInitialAction, estadoCorrectoInitialAction } from "../actions/resetStoreActions";

const useReturnInitialState = () => {

    const dispatch = useDispatch();

    const returnState = (array) => {
        const arrayLength = array.length;

        for(let i = 0; i< arrayLength; i++){
            switch (array[i]) {
                case 'estado.error':
                  dispatch(estadoErrorInitialAction())
                  break;
                case 'estado.loading':
                  dispatch(estadoLoadingInitialAction())
                  break;
                case 'estado.correcto':
                  dispatch(estadoCorrectoInitialAction())
                  break;
                default:
                    break;
            }
        }
    }
    return [returnState];
}

export default useReturnInitialState;