import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import PracticaCentena from '../PracticaCentena';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, Numero, Rayita, NumbersM30, NumFlexM30 } from '../../../../Css/StyledMatematica';

const data = [
    {id:'1', numero:100},
    {id:'2', numero:200},
    {id:'3', numero:300},
    {id:'4', numero:400},
    {id:'5', numero:500},
    {id:'6', numero:600},
    {id:'7', numero:700},
    {id:'8', numero:800},
    {id:'9', numero:900}
]

const M30 = () => {

    const dispatch = useDispatch();

    const [comparar, setComparar] = useState([]);
    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);

    if(contador === 11){
        dispatch(setearEjercicioAction('M30Bis') );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        setComparar(data);
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M30') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar || fuerzoInicio
            ?
            <Flex direction="column" height="100%" width="100%">
                {contador === 0 &&
                <Flex direction='column'>
                    {/* <div>1</div>
                    <div>00</div> */}
                    <Numero>100</Numero>
                    {/* <BotoneraBottom aumentar={true} /> */}
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom aumentar={true} />
                    </FlexCuz>
                </Flex>
                }
                {contador === 1 &&
                <Flex direction='column'>
                    <Numero>100</Numero>
                    {/* <div>00</div> */}
                    {/* <div>rayita</div> */}
                    <Rayita></Rayita>
                    {/* <Flex margin='20vh 0px 0px 40vw'>
                        <BotoneraBottom reducir={true} aumentar={true} />
                    </Flex> */}
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom reducir={true} aumentar={true} />
                    </FlexCuz>
                </Flex>
                }
                {contador > 1 && contador <= 10 &&
                <Flex height='78vh'>
                    <NumFlexM30>
                    {comparar.filter(item => item.id < contador).map(item =>
                            <NumbersM30 color='#23AAAF' key={item.id}>
                            {item.numero}
                            </NumbersM30>
                    )}
                    </NumFlexM30>
                </Flex>
                }
                {contador > 1 && contador <= 10 &&
                <FlexCuz position='fixed' right='0' bottom='0'>
                    <BotoneraBottom reducir={true} aumentar={true} />
                </FlexCuz>
                }
                {contador > 10 &&
                    <PracticaCentena data={data} ejercicio="M30Bis" />
                }
            </Flex>
            :
            <Instrucciones miniInstrucciones={''} />
            }
        </Fragment>
    );
}
 
export default M30;