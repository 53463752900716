import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, ContInst, BtnLetraLN19 } from '../../../../Css/MetodosCSS/StyledLectoEscritura';

const instrucciones = 'Selecciona las mismas letras.';
const miniInstrucciones = 'Selecciona las mismas letras.';

const LN19 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [disable, setDisable] = useState(false);
    const [randomImagen, setRandomImagen] = useState([]);
    const [letraElegida, setLetraElegida] = useState('');
    const [index, setIndex] = useState('');
    const [indexDos, setIndexDos] = useState('');
    const [count, setCount] = useState(1);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const almacenar = (e, ltr, i) => {
        e.preventDefault();
        if (letraElegida === '') {
            setLetraElegida(ltr);
            setIndex(i);
        }
        if (letraElegida !== '' && index === i) {
            setLetraElegida('');
            setIndex('');
        } else {
            return;
        }
    }

    const verificar = (e, ltr, i) => {
        e.preventDefault();
        if (letraElegida === '' || index === i) {
            return;
        }
        setIndexDos(i);
        if (ltr.toUpperCase() !== letraElegida.toUpperCase()) {
            console.log('mal')
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            setTimeout(() => {
                setDisable(false);
                setLetraElegida('');
                setIndex('');
                setIndexDos('');
            }, 1000);
            TryAgain();
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setLetraElegida('');
                    setIndex('');
                    setIndexDos('');
                }, 1000);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1000);
            }
        }

    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LN19') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/ejercicios/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LN19",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return;
        }
        const lista = [];
        lista.push(comparar.letra);
        lista.push(comparar.letra);
        lista.push(comparar.letra_incorrecta_1);
        lista.push(comparar.letra_incorrecta_2);

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const newShuffleList = shuffle(lista);
        setRandomImagen(newShuffleList)
    }, [comparar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlexx height="85vh" direction="column">
                    <ContainerFlexx height="80vh">
                        <ContainerFlexx direction='column' padding="2rem" border="25px solid rgba(252, 247, 188, 1)" bradius='20px'>
                            <ContainerFlexx bradius='20px' isCorrect={isCorrect} isIncorrect={isIncorrect}>
                                <BtnLetraLN19 bgcolor={index === "0" || indexDos === "0" ? "#23AAAF" : null} color="black"
                                    disabled={disable}
                                    onClick={(e) => { verificar(e, randomImagen[0], "0"); almacenar(e, randomImagen[0], "0")}}
                                >
                                    {randomImagen[0]}
                                </BtnLetraLN19>
                                <BtnLetraLN19 bgcolor={index === "2" || indexDos === "2" ? "#23AAAF" : null} color="black"
                                    disabled={disable}
                                    onClick={(e) => { verificar(e, randomImagen[2], "2"); almacenar(e, randomImagen[2], "2")}}
                                >
                                    {randomImagen[2]}
                                </BtnLetraLN19>
                            </ContainerFlexx>

                            <ContainerFlexx isCorrect={isCorrect} isIncorrect={isIncorrect}>
                                <BtnLetraLN19 bgcolor={index === "1" || indexDos === "1" ? "#23AAAF" : null} color="black"
                                    disabled={disable}
                                    onClick={(e) => { verificar(e, randomImagen[1], "1"); almacenar(e, randomImagen[1], "1")}}
                                >
                                    {randomImagen[1]}
                                </BtnLetraLN19>
                                
                                <BtnLetraLN19 bgcolor={index === "3" || indexDos === "3" ? "#23AAAF" : null} color="black"
                                disabled={disable}
                                onClick={(e) => { verificar(e, randomImagen[3], "3"); almacenar(e, randomImagen[3], "3")}}
                                >
                                    {randomImagen[3]}
                                </BtnLetraLN19>
                            </ContainerFlexx>
                        </ContainerFlexx>
                    </ContainerFlexx>
                    <ContInst>
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </ContInst>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN19;