import Modal from 'react-modal';

import { ProgressBar, Table, TableCell, TableContainer, TableHeader, TableRow } from '../Css/StyledResultados';

Modal.setAppElement('#root');

const ResultadosDiagramaTabla = ({ resultados }) => {
    return (
        <TableContainer>
        <Table width="100%">
            <thead>
            <TableRow>
                <TableHeader>Fecha</TableHeader>
                <TableHeader>Tiempo</TableHeader>
                <TableHeader>Errores</TableHeader>
                <TableHeader>Pistas</TableHeader>
                <TableHeader>Progreso</TableHeader>
            </TableRow>
            </thead>
            <tbody>
            {resultados?.map((res) => {
                return res.Subejercicios.map((sub, j) => {
                return (
                    <TableRow key={j}>
                    <TableCell>{sub.Fecha}</TableCell>
                    <TableCell>{sub.Tiempo}</TableCell>
                    <TableCell>{sub.Errores}</TableCell>
                    <TableCell>{sub.Pistas}</TableCell>
                    <TableCell>
                        <ProgressBar value={sub.Progreso} />
                    </TableCell>
                    </TableRow>
                );
                });
            })}
            </tbody>
        </Table>
        </TableContainer>
    );
};

export default ResultadosDiagramaTabla;