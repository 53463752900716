import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgFlex, ImgB10, CantCuadroM44, NumM44, Separador, TablaM44, CantTablaM44, InputM44, ButtonM44, Mobile, Desktop, AvanzarImg, AvanzarBtn  } from '../../../Css/StyledMatematica';

const instrucciones = 'Construye el número.';
const miniInstrucciones = 'Construye el número.';

const M44 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numeroMiles, setNumeroMiles] = useState('');
    const [numeroCentena, setNumeroCentena] = useState('');
    const [numeroDecena, setNumeroDecena] = useState('');
    const [numeroUnidad, setNumeroUnidad] = useState('');
    const [imagenMiles, setImagenMiles] = useState([]);
    const [imagenCentena, setImagenCentena] = useState([]);
    const [imagenDecena, setImagenDecena] = useState([]);
    const [imagenUnidad, setImagenUnidad] = useState([]);
    const [unidad, setUnidad] = useState('');
    const [decena, setDecena] = useState('');
    const [centena, setCentena] = useState('');
    const [miles, setMiles] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [erroresOrden, setErroresOrden] = useState([]);
    const [idOrden, setIdOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const tipoBase10 = useSelector(state => state.ejercicio.lista);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const agregarMiles = (e) => {
        e.preventDefault();
        if(imagenMiles.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles));
        newList.push('cuboUnidadDeMil')
        setImagenMiles(newList);
    }

    const reducirMiles = (e) => {
        e.preventDefault();
        if(imagenMiles.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenMiles));
        newList.pop()
        setImagenMiles(newList);
    }

    const agregarCentena = (e) => {
        e.preventDefault();
        if(imagenCentena.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena));
        newList.push('cuboCentena')
        setImagenCentena(newList);
    }

    const reducirCentena = (e) => {
        e.preventDefault();
        if(imagenCentena.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenCentena));
        newList.pop()
        setImagenCentena(newList);
    }

    const agregarDecena = (e) => {
        e.preventDefault();
        if(imagenDecena.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena));
        newList.push('cuboDecena')
        setImagenDecena(newList);
    }

    const reducirDecena = (e) => {
        e.preventDefault();
        if(imagenDecena.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenDecena));
        newList.pop()
        setImagenDecena(newList);
    }

    const agregarUnidad = (e) => {
        e.preventDefault();
        if(imagenUnidad.length === 9){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad));
        newList.push('cuboUnidad')
        setImagenUnidad(newList);
    }

    const reducirUnidad = (e) => {
        e.preventDefault();
        if(imagenUnidad.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(imagenUnidad));
        newList.pop()
        setImagenUnidad(newList);
    }

    const verificar = (e) => {
        e.preventDefault();

        let imagenMilesCorregida = '';
        let imagenCentenaCorregida = '';
        let imagenDecenaCorregida = '';

        if(miles === '' && imagenMiles.length === 0){
            imagenMilesCorregida = '';
        } else {
            imagenMilesCorregida = imagenMiles.length;
        }
        if(centena === '' && imagenCentena.length === 0){
            imagenCentenaCorregida = '';
        } else {
            imagenCentenaCorregida = imagenCentena.length;
        }
        if(decena === '' && imagenDecena.length === 0){
            imagenDecenaCorregida = '';
        } else {
            imagenDecenaCorregida = imagenDecena.length;
        }

        if(imagenMilesCorregida !== miles || imagenCentenaCorregida !== centena || imagenDecenaCorregida !== decena || imagenUnidad.length !== unidad || numeroMiles.toString() !== miles.toString() || numeroCentena.toString() !== centena.toString() || numeroDecena.toString() !== decena.toString() || numeroUnidad.toString() !== unidad.toString() ){
            console.log("mal");
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(imagenMilesCorregida !== miles){setImagenMiles([]);}
                if(imagenCentenaCorregida !== centena){setImagenCentena([]);}
                if(imagenDecenaCorregida !== decena){setImagenDecena([]);}
                if(imagenUnidad.length !== unidad){setImagenUnidad([]);}
                if(numeroMiles.toString() !== miles.toString() ){setNumeroMiles('');}
                if(numeroCentena.toString() !== centena.toString() ){setNumeroCentena('');}
                if(numeroDecena.toString() !== decena.toString() ){setNumeroDecena('');}
                if(numeroUnidad.toString() !== unidad.toString() ){setNumeroUnidad('');}
            }, 300);
        } else {
            console.log("bien");
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setMiles('');
                    setCentena('');
                    setDecena('');
                    setUnidad('');
                    setCount(count + 1);
                    setImagenMiles([]);
                    setImagenCentena([]);
                    setImagenDecena([]);
                    setImagenUnidad([]);
                    setNumeroMiles('');
                    setNumeroCentena('');
                    setNumeroDecena('');
                    setNumeroUnidad('');
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );                
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(comparar));
        const nro = newList[count-1];
        const u = nro%10;
        let d = Math.floor((nro/10)%10);
        let c = Math.floor((nro/100)%10);
        let m = Math.floor((nro/1000)%10);

        if(m === 0 && c === 0 && d === 0) {
            m = '';
            c = '';
            d = '';
        } else if(m === 0 && c === 0) {
            m = '';
            c = '';
        } else if(m === 0 ) {
            m = '';
        }

        setUnidad(u);
        setDecena(d);
        setCentena(c);
        setMiles(m);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        const randomLista = (function () {
            let min = 0;
            let max = 0;
            const lista = [];
            switch (tipoBase10) {
                case 'unidad':
                    min = 1;
                    max = 9;
                    break;
                case 'decena':
                    min = 10;
                    max = 99;
                    break;
                case 'centena':
                    min = 100;
                    max = 999;
                    break;
                case 'unidadDeMil':
                    min = 1000;
                    max = 9999;
                    break;
                case 'todos':
                    min = 1;
                    max = 9999;
                    break;
                default:
                    min = 1;
                    max = 9999;
                    break;
            }
            for(let i = 0; i < 5; i++){
                let randomNumero = 0;
                do {
                    randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                } while (lista.includes(randomNumero));
                lista.push(randomNumero)
            }
            return lista;
        })();
        setComparar(randomLista);
        setIndexes(randomLista.length);
        setIdOrden(randomLista);
        setLanzoEffect(true);
        dispatch(setearEjercicioAction('M44') );
        dispatch(setearUrlAnteriorAction('matematica/base-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M44",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrden,
                    'lista': tipoBase10,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);


    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <FlexFull direction="column" height="100%" width="100%" align="center"
                isCorrect={isCorrect}
                isIncorrect={isIncorrect}
                >
                    <FlexFull direction='column' margin="auto" width="100%" justify='space-evenly' align='center'>
                        {/* DESKTOP ↓ */}
                        <Desktop>
                            <FlexCuz justify='space-between'> 
                                <Flex margin='-40vh 2vw 0vh 1vw'>
                                    <NumM44>{comparar[count-1]}</NumM44>
                                </Flex>
                                <Flex direction='column' minh='70vh' maxh='70vh' margin='4vh 0vw 0vh 0vw' md='5vh 0px 0px 0px'>
                                    {/* imagenes de arriba */}
                                    <FlexFull width='50vw' wt='70vw' margin='0vh 0vw 0vh 0vw' align='end' justify='center'>
                                        <ImgFlex direction='column' minhd='40vh' maxhd='40vh' maxhlg='40vh' minhlg='40vh' justify='end' align='center'>  
                                            {imagenMiles.map((item,i) => 
                                                <div key={i}>
                                                {/* <ImgB10 src={`/img/BASE10mil.png`} w='5vw' wlg='4vw' m='0px' /> */}
                                                <ImgB10 src={`/img/BASE10mil.png`} w='53px' m='0px' />
                                                </div>
                                            )}
                                        </ImgFlex>
                                        <ImgFlex direction='column' minhd='40vh' maxhd='40vh' maxhlg='40vh' minhlg='40vh' justify='end' align='center'>
                                            {imagenCentena.map((item,i) => 
                                                <div key={i}>
                                                    {/* <ImgB10 src={`/img/BASE10centena.png`} w='3.8vw' wlg='2.8vw' m='0px' /> */}
                                                    <ImgB10 src={`/img/BASE10centena.png`} w='40px' m='0px' />
                                                </div>
                                            )}
                                        </ImgFlex>
                                        <ImgFlex direction='column' minhd='35vh' maxhd='35vh' maxhlg='40vh' minhlg='40vh' justify='end' align='center' m='0px 0px -25px 0px'>
                                            {imagenDecena.map((item,i) => 
                                                <div key={i}>
                                                    {/* <ImgB10 src={`/img/b10_decena.png`} w='1.5vw' wlg='1.3vw' m='-50px 0px -40px 0px' tr='rotate(90deg)' border='2px solid green' /> */}
                                                    {/* m='-51px 0px 0px 0px' mm='-51px 0px 0px 0px' mt='-51px 0px 0px 0px' */}
                                                    <ImgB10 src={`/img/b10_decena.png`} w='13px' m='-65px 0px 0px 0px' tr='rotate(90deg)' />
                                                </div>
                                            )}
                                        </ImgFlex>
                                        {/* <ImgFlex direction='column' minhd='13vh' maxhd='13vh' maxhlg='12vh' minhlg='12vh' justify='end' align='center' mlg='0px 0px -5vh 0px'>
                                            {imagenUnidad.map((item,i) => 
                                                <div key={i}>
                                                    <ImgB10 src={`/img/b10_unidad.png`} w='1.5vw' wlg='1.2vw' />
                                                </div>
                                            )}
                                        </ImgFlex> */}
                                        <ImgFlex direction='column' minhd='13vh' maxhd='13vh' maxhlg='12vh' minhlg='12vh' justify='end' align='center' mlg='0px 0px -5vh 0px'>
                                            {/* <FlexFull wrap='wrap' justify='start' align='center' maxw='6vw' maxwt='4vw' maxwd='4vw' maxwlg='4vw' border='2px solid blue'> */}
                                            <FlexFull wrap='wrap' justify='start' align='center' maxw='58px'>
                                                {imagenUnidad.map((item,i) => 
                                                    // <ImgB10 key={i} src={`/img/b10_unidad.png`} w='1.5vw' wt='1vw' wd='1vw' wlg='1vw' m='1px' />
                                                    <ImgB10 key={i} src={`/img/b10_unidad.png`} w='16px' m='1px' />
                                                )}
                                            </FlexFull>
                                        </ImgFlex>
                                    </FlexFull> 
                                    {/* Fin imagenes de arriba */}
                                    <Separador w='60vw' />
                                    {/* imagenes abajo ↓ */}
                                    <Flex width='50vw'>
                                        <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'> 
                                            <CantCuadroM44>U. MIL</CantCuadroM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => agregarMiles(e)}
                                            >
                                                <ImgB10 src={`/img/BASE10mil.png`} w='42px' />
                                            </ButtonM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => reducirMiles(e)}
                                            >
                                                <ImgB10 src={`/img/icono_basurero.png`} w='18px' /> {/* m='1vh 0px 0px 0px' */}
                                            </ButtonM44>
                                        </FlexCuz>
                                        <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                            <CantCuadroM44>CENTENA</CantCuadroM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => agregarCentena(e)}
                                            >
                                                <ImgB10 src={`/img/BASE10centena.png`} w='32px' />
                                            </ButtonM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => reducirCentena(e)}
                                            >
                                                <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                            </ButtonM44>
                                        </FlexCuz>
                                        <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                            <CantCuadroM44>DECENA</CantCuadroM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => agregarDecena(e)}
                                            >
                                                <ImgB10 src={`/img/b10_decena.png`} w='12px' m='-17px' tr='rotate(90deg)' />
                                            </ButtonM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => reducirDecena(e)}
                                            >
                                                <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                            </ButtonM44>
                                        </FlexCuz>
                                        <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'>
                                            <CantCuadroM44>UNIDAD</CantCuadroM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => agregarUnidad(e)}
                                            >
                                                <ImgB10 src={`/img/b10_unidad.png`} w='18px' />
                                            </ButtonM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => reducirUnidad(e)}
                                            >
                                                <ImgB10 src={`/img/icono_basurero.png`} w='18px' />
                                            </ButtonM44>
                                        </FlexCuz>
                                    </Flex>
                                    {/* Fin imagenes abajo ↑ */}
                                </Flex>
                                {/* TABLA ↓ */}
                                <Flex margin='-40vh 2vw 0vh 2vw'>
                                    <TablaM44 direction='column'>
                                        <CantTablaM44>M</CantTablaM44>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM44 type="numeric" min="0" max="9" value={numeroMiles} onChange={(e) => setNumeroMiles(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM44>
                                    <TablaM44 direction='column' br='0px' bl='0px'>
                                        <CantTablaM44>C</CantTablaM44>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM44 type="numeric" min="0" max="9" value={numeroCentena} onChange={(e) => setNumeroCentena(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM44>
                                    <TablaM44 direction='column' br='0px'>
                                        <CantTablaM44>D</CantTablaM44>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM44 type="numeric" min="0" max="9" value={numeroDecena} onChange={(e) => setNumeroDecena(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM44>
                                    <TablaM44 direction='column'>
                                        <CantTablaM44>U</CantTablaM44>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputM44 type="numeric" min="0" max="9" value={numeroUnidad} onChange={(e) => setNumeroUnidad(e.target.value)} disabled={disable} />
                                        </form>
                                    </TablaM44>
                                </Flex>
                                {/* FIN TABLA ↑ */}
                            </FlexCuz>
                        </Desktop>
                        {/* DESKTOP ↑ */}

                        {/* MOBILE ↓ */}
                        <Mobile>
                            <FlexCuz direction='column' justify='space-between'>
                                {/* NUMERO Y TABLA ↓ */}
                                <Flex margin='0vh 0vw 10vh 0vw' mm='0vh 0vw 6vh 0vw' mt='0vh 0vw 3vh 0vw'>            
                                    <Flex margin='0vh 25vw 0vh 0vw'>
                                        <NumM44>{comparar[count-1]}</NumM44>
                                    </Flex>
                                    {/* TABLA ↓ */}
                                    <Flex>
                                        <TablaM44 direction='column'>
                                            <CantTablaM44>M</CantTablaM44>
                                            <form onSubmit={(e) => onSubmit(e)}>
                                                <InputM44 type="numeric" min="0" max="9" value={numeroMiles} onChange={(e) => setNumeroMiles(e.target.value)} disabled={disable} />
                                            </form>
                                        </TablaM44>
                                        <TablaM44 direction='column' br='0px' bl='0px'>
                                            <CantTablaM44>C</CantTablaM44>
                                            <form onSubmit={(e) => onSubmit(e)}>
                                                <InputM44 type="numeric" min="0" max="9" value={numeroCentena} onChange={(e) => setNumeroCentena(e.target.value)} disabled={disable} />
                                            </form>
                                        </TablaM44>
                                        <TablaM44 direction='column' br='0px'>
                                            <CantTablaM44>D</CantTablaM44>
                                            <form onSubmit={(e) => onSubmit(e)}>
                                                <InputM44 type="numeric" min="0" max="9" value={numeroDecena} onChange={(e) => setNumeroDecena(e.target.value)} disabled={disable} />
                                            </form>
                                        </TablaM44>
                                        <TablaM44 direction='column'>
                                            <CantTablaM44>U</CantTablaM44>
                                            <form onSubmit={(e) => onSubmit(e)}>
                                                <InputM44 type="numeric" min="0" max="9" value={numeroUnidad} onChange={(e) => setNumeroUnidad(e.target.value)} disabled={disable} />
                                            </form>
                                        </TablaM44>
                                    </Flex>
                                    {/* FIN TABLA ↑ */}
                                </Flex>
                                {/* NUMERO Y TABLA ↑ */}
                                {/* COSA CON IMAGENES ↓ */}
                                <Flex direction='column'>
                                    {/* imagenes de arriba */}
                                    <FlexFull width='80vw' wt='70vw' margin='0vh 0vw 0vh 0vw' align='end' justify='center'>
                                        <ImgFlex direction='column' justify='end' align='center' minh='230px' maxh='230px'> 
                                            {imagenMiles.map((item,i) => 
                                                <div key={i}>
                                                {/* <ImgB10 src={`/img/BASE10mil.png`} w='7.5vw' wm='6.5vw' wt='5vw' /> */}
                                                <ImgB10 src={`/img/BASE10mil.png`} w='51px' />
                                                </div>
                                            )}
                                        </ImgFlex> 
                                        {/* <ImgFlex direction='column' justify='end' align='center' minh='30vh' maxh='30vh' minhm='35vh' maxhm='35vh' minht='37vh' maxht='37vh' br='2px solid red'> */}
                                        <ImgFlex direction='column' justify='end' align='center' minh='230px' maxh='230px'>
                                            {imagenCentena.map((item,i) => 
                                                <div key={i}>
                                                    {/* <ImgB10 src={`/img/BASE10centena.png`} w='6vw' wm='5vw' wt='4vw' /> */}
                                                    <ImgB10 src={`/img/BASE10centena.png`} w='40px' />
                                                </div>
                                            )}
                                        </ImgFlex>
                                        {/* <ImgFlex direction='column' justify='end' align='center' minh='30vh' maxh='30vh' minhm='35vh' maxhm='35vh' m='0px 0px 0vh 0px'>
                                            {imagenDecena.map((item,i) => 
                                                <div key={i}>
                                                    <ImgB10 src={`/img/b10_decena.png`} w='2.5vw' wt='2vw' m='-40px 0px -25px 0px' mm='-60px 0px -45px 0px' mt='-65px 0px -45px 0px' tr='rotate(90deg)' border='2px solid green' />
                                                </div>
                                            )}
                                        </ImgFlex> */}
                                        <ImgFlex direction='column' justify='end' align='center' minh='30vh' maxh='30vh' minhm='35vh' maxhm='35vh' m='0px 0px -25px 0px'>
                                            {imagenDecena.map((item,i) => 
                                                // <div key={i}>
                                                    <ImgB10 src={`/img/b10_decena.png`}  key={i} w='12px'  m='-51px 0px 0px 0px' mm='-51px 0px 0px 0px' mt='-51px 0px 0px 0px' tr='rotate(90deg)' /> 
                                                // </div>
                                            )}
                                        </ImgFlex>

                                        {/* wt='2vw' */}


                                        {/* <FlexFull wrap='wrap'>
                                            {imagenDecena.map((item,i) => 
                                                <ImgB10 key={i} src={`/img/b10_decena.png`} w='1.3vw' wt='1.1vw' wd='0.8vw' wlg='0.8vw' m='0.5px' />
                                            )}
                                        </FlexFull> */}
                                        {/* <ImgFlex direction='column' justify='end' align='center' minh='12vh' maxh='12vh' minhm='15vh' maxhm='15vh' minht='17vh' maxht='17vh'>
                                            {imagenUnidad.map((item,i) => 
                                                <div key={i}>
                                                    <ImgB10 src={`/img/b10_unidad.png`} w='3vw' wt='2.5vw' /> 
                                                </div>
                                            )}
                                        </ImgFlex> */}
                                        <ImgFlex direction='column'  justify='center' align='center' mlg='0px 0px -5vh 0px'> {/* minhd='13vh' maxhd='13vh' maxhlg='12vh' minhlg='12vh'   maxh='12vh' */}
                                            <FlexFull wrap='wrap' justify='center' align='end' maxw='60px'  maxwd='4vw' maxwlg='4vw' maxh='100px'>
                                                {imagenUnidad.map((item,i) => 
                                                    // <ImgB10 key={i} src={`/img/b10_unidad.png`} w='1.5vw' wt='1vw' wd='1vw' wlg='1vw' m='1px' />
                                                    <ImgB10 key={i} src={`/img/b10_unidad.png`} w='17px' m='1px' />
                                                )}
                                            </FlexFull>
                                        </ImgFlex>
                                    </FlexFull> 
                                    {/* Fin imagenes de arriba */}
                                    <Separador w='90vw' wt='80vw' />
                                    {/* imagenes de abajo */}
                                    <Flex width='80vw' wt='70vw'>
                                        <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between'> 
                                            <CantCuadroM44>U. MIL</CantCuadroM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => agregarMiles(e)}
                                            >
                                                <ImgB10 src={`/img/BASE10mil.png`} w='40px' /> {/*  w='8.5vw' wt='6.5vw' */}
                                            </ButtonM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => reducirMiles(e)}
                                            > 
                                                <ImgB10 src={`/img/icono_basurero.png`} w='18px'  /> {/* m='1vh 0px 0px 0px' */}
                                            </ButtonM44>
                                        </FlexCuz>
                                        {/* <FlexCuz width='25%' direction='column' minh='85px' maxh='85px' justify='space-between' br='2px solid red'> 
                                            <DivP>
                                                <CantCuadroM44>U. MIL</CantCuadroM44>
                                            </DivP>
                                            <DivA>
                                                <ButtonM44
                                                    disabled={disable}
                                                    onClick={(e) => agregarMiles(e)}
                                                >
                                                    <ImgB10 src={`/img/BASE10mil.png`} w='8.5vw' wt='6.5vw' />
                                                </ButtonM44>
                                            </DivA>
                                            <DivB>
                                                <ButtonM44
                                                    disabled={disable}
                                                    onClick={(e) => reducirMiles(e)}
                                                >
                                                    <ImgB10 src={`/img/icono_basurero.png`} w='2vw' /> 
                                                </ButtonM44>
                                            </DivB>
                                        </FlexCuz> */}
                                        {/*  m='1vh 0px 0px 0px' */}
                                        <FlexCuz direction='column' width='25%' minh='85px' maxh='85px' justify='space-between'>
                                            <CantCuadroM44>CENTENA</CantCuadroM44>
                                                <ButtonM44
                                                    disabled={disable}
                                                    onClick={(e) => agregarCentena(e)}
                                                >
                                                    <ImgB10 src={`/img/BASE10centena.png`}  w='32px' /> {/* w='7.5vw' wt='5.5vw' */}
                                                </ButtonM44>
                                                <ButtonM44
                                                    disabled={disable}
                                                    onClick={(e) => reducirCentena(e)}
                                                >
                                                    <ImgB10 src={`/img/icono_basurero.png`} w='18px'  /> {/* m='1vh 0px 0px 0px' */}
                                                </ButtonM44>
                                        </FlexCuz>
                                        <FlexCuz direction='column' width='25%' minh='85px' maxh='85px' justify='space-between'>
                                            <CantCuadroM44>DECENA</CantCuadroM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => agregarDecena(e)}
                                            >
                                                <ImgB10 src={`/img/b10_decena.png`} w='10px' m='-11px' tr='rotate(90deg)' /> {/* m='-11px' */}
                                            </ButtonM44>
                                            <ButtonM44
                                                disabled={disable}
                                                onClick={(e) => reducirDecena(e)}
                                            >
                                                <ImgB10 src={`/img/icono_basurero.png`} w='18px' m='0px' /> {/* m='2vh 0px 0px 0px' mm='-1vh 0px 0px 0px' mt='-3vh 0px 0px 0px' */}
                                            </ButtonM44>
                                        </FlexCuz>
                                        <FlexCuz direction='column' width='25%' minh='85px' maxh='85px' justify='space-between'>
                                            <CantCuadroM44>UNIDAD</CantCuadroM44>
                                                <ButtonM44
                                                    disabled={disable}
                                                    onClick={(e) => agregarUnidad(e)}
                                                >
                                                    <ImgB10 src={`/img/b10_unidad.png`} w='17px'  /> 
                                                </ButtonM44>
                                                <ButtonM44
                                                    disabled={disable}
                                                    onClick={(e) => reducirUnidad(e)}
                                                >
                                                    <ImgB10 src={`/img/icono_basurero.png`} w='18px'  /> {/* m='4vh 0px 0px 0px' mm='3vh 0px 0px 0px' */}
                                                </ButtonM44>
                                        </FlexCuz>
                                    </Flex>
                                </Flex>
                                {/* FIN COSA CON IMAGENES ↑ */}
                            </FlexCuz>
                        </Mobile>
                    </FlexFull>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </FlexFull>
                :
                    <Instrucciones instrucciones={'Construye el número.'} />
            }
        </Fragment>
    );
}
 
export default M44;