import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';
//CAMI

import { Flex, LinksSubNivs, ImgSubNivs, DesSubNivs } from '../../../Css/StyledMatematica';
import { HrMetodos } from '../../../Css/StyledHeader';


const Nivel1 = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"subitizacion/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"SUBITIZACION"} componente={"NIVEL 1"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex wrap='wrap' margin='12vh 0vw 0px 0vw' mt='8.5vh 0vw 0px 0vw' md='7vh 0vw 0px 0vw' mlg='6.5vh 0vw 0px 0vw'>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-1/M1`}><ImgSubNivs src={'/img/portadas/matematica1.jpg'} /><DesSubNivs margin="1rem 0 0 0">Animales</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-1/M2`}><ImgSubNivs src={'/img/portadas/matematica2.jpg'} /><DesSubNivs margin="1rem 0 0 0">Manos</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-1/M3`}><ImgSubNivs src={'/img/portadas/matematica3.jpg'} /><DesSubNivs margin="1rem 0 0 0">puntos</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-1/M4`}><ImgSubNivs src={'/img/portadas/matematica4.jpg'} /><DesSubNivs margin="1rem 0 0 0">Subitización puntos segmentados</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-1/M5`}><ImgSubNivs src={'/img/portadas/matematica5.jpg'} /><DesSubNivs margin="1rem 0 0 0">diferenciación cantidad</DesSubNivs></LinksSubNivs>
            </Flex>
        </Fragment>    
    );
}
 
export default Nivel1;