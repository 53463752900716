import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, AvanzarImg, PRecta, NumRecta, BtnRecta, AvanzarBtn, ImgB10 } from '../../../../Css/StyledMatematica';

const instrucciones = 'Coloca el número donde corresponda en la recta numérica.';
const miniInstrucciones = 'Coloca el número donde corresponda en la recta numérica.';

const M41 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]); 
    const [regla, setRegla] = useState([]);
    const [numero, setNumero] = useState(0);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [erroresOrden, setErroresOrden] = useState([]);
    const [idOrden, setIdOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignarNumero = (e,id) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(regla));
        const array = newList.map(item => {
            if(item.id === id){
                return {...item, numero: comparar[count-1].numero_correcto}
            } else {
                return item
            }
        })
        setNumero(id-1)
        setRegla(array);
    }

    const verificar = (e) => {
        e.preventDefault();
        if(numero !== comparar[count-1].numero_correcto){
            console.log("mal");
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                const newList = JSON.parse(JSON.stringify(comparar));
                const borrar1 = newList[count-1].numero_correcto;
                const borrar2 = newList[count-1].numero_incorrecto;
                const newList2 = JSON.parse(JSON.stringify(regla));
                newList2[borrar1].numero = '';
                newList2[borrar2].numero = '';
                setNumero('');
                setRegla(newList2);
            }, 300);
        } else {
            console.log("bien");
            if(count === 1){
                setErroresOrden([...erroresOrden, contarErrores]);
            } else {
                const err = contarErrores - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
                setErroresOrden([...erroresOrden, err]);
            }
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setNumero('');
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );                
                }, 1500);
            }
        }
    }

    useEffect(() => {
        const lista = [0,1,2,3,4,5,6,7,8,9,10];
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
            
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = 
                [array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const listaCorrectaRandom = shuffle(lista).slice(0,5);
        const listaIncorrectaRandom = (function () {
            const arr = [];
            for(let i = 0; i < 5; i++){
                const min = 0;
                const max = 10;
                const randomNumero = Math.floor(Math.random() * (max - min + 1)) + min;
                if(randomNumero === listaCorrectaRandom[i]){
                    const randomNumeroFinal = randomNumero + 1;
                    arr.push(randomNumeroFinal);
                } else {
                    arr.push(randomNumero);
                }
                
            }
            return arr;
        })();
        const data = [
            {id:'1', numero_correcto:listaCorrectaRandom[0], numero_incorrecto:listaIncorrectaRandom[0]},
            {id:'2', numero_correcto:listaCorrectaRandom[1], numero_incorrecto:listaIncorrectaRandom[1]},
            {id:'3', numero_correcto:listaCorrectaRandom[2], numero_incorrecto:listaIncorrectaRandom[2]},
            {id:'4', numero_correcto:listaCorrectaRandom[3], numero_incorrecto:listaIncorrectaRandom[3]},
            {id:'5', numero_correcto:listaCorrectaRandom[4], numero_incorrecto:listaIncorrectaRandom[4]}
        ]
        setComparar(data);
        setIndexes(Object.keys(data).length);
        const newList2 = JSON.parse(JSON.stringify(data));
        const elOrdenDelId = newList2.map(item => item.numero_correcto)
        setIdOrden(elOrdenDelId);
    }, [])
    

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        const borrar1 = newList[count-1].numero_correcto;
        const borrar2 = newList[count-1].numero_incorrecto;
        const array = [
            {id:'1', numero:0, disable:true},
            {id:'2', numero:1, disable:true},
            {id:'3', numero:2, disable:true},
            {id:'4', numero:3, disable:true},
            {id:'5', numero:4, disable:true},
            {id:'6', numero:5, disable:true},
            {id:'7', numero:6, disable:true},
            {id:'8', numero:7, disable:true},
            {id:'9', numero:8, disable:true},
            {id:'10', numero:9, disable:true},
            {id:'11', numero:10, disable:true}
        ];
        array[borrar1].numero = '';
        array[borrar2].numero = '';
        array[borrar1].disable = false;
        array[borrar2].disable = false;
        setRegla(array);
    }, [count, comparar])

    useEffect(() => {
        dispatch(setearEjercicioAction('M41') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/ejercitacion-1-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M41",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrden,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <FlexFull direction="column" height="100%" width="100%" align="center">
                    {/* <FlexFull height='70vh' direction='column' justify='space-evenly' align='center'>
                        <Flex color='#CF223D' bradius='10px' >
                            <FlexFull direction='column' justify='center' align='center' margin='0.5vw' bradius='10px' color='#FFFFFF' >
                                <ImgB10 src='/img/rectanumerica.png' w='44vw' wm='44vw' wt='42.5vw' wd='40.5vw' wlg='39.5vw' m='3vh 0px 0px 0px' />
                                <Flex width='52vw' wm='47vw' wt='43vw' wd='41vw' wlg='40vw' margin='-3vh 0px 0px 0px'>
                                {regla.map(item => 
                                    <BtnImg
                                        key={item.id}
                                        disabled={item.disable}
                                        onClick={(e) => asignarNumero(e, item.id)}
                                    >
                                        <PM37 m='0px'>{item.numero}</PM37>
                                    </BtnImg>
                                )}
                                </Flex>
                            </FlexFull>
                        </Flex>
                        <NumRecta>{comparar[count-1].numero_correcto}</NumRecta>
                    </FlexFull> */}

                    <FlexFull direction='column' margin="auto" width="100%" justify='space-evenly' align='center'
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        {/* <Flex color='#CF223D' bradius='10px' > */}
                            <FlexFull direction='column' justify='center' align='center' margin='0.5vw' bradius='10px' >
                                {/* <ImgB10 src='/img/rectanumerica1.png' w='85vw' wm='75vw' wt='55vw' wd='49vw' wlg='48vw' m='3vh 0px 0px 0px' /> */}
                                <ImgB10 src='/img/rectanumerica1.png' w='450px' wm='470px' wt='490px' wd='510px' wlg='530px' m='3vh 0px 0px 0px' />
                                {/* <Flex width='52vw' wm='47vw' wt='43vw' wd='41vw' wlg='40vw' margin='-7vh 0px 0px 7px' mm='-8vh 0px 0px 7px' mt='-10vh 0px 0px 7px' md='-13vh 0px 0px 7px' mlg='-15vh 0px 0px 7px' > */}
                                <Flex width='52vw' wm='47vw' wt='43vw' wd='41vw' wlg='40vw' margin='-65px 0px 0px 10px' mm='-70px 0px 0px 10px' mt='-75px 0px 0px 10px' md='-80px 0px 0px 10px' mlg='-80px 0px 0px 10px' >
                                    {regla.map(item => 
                                        // <BtnRecta m='0vh 0.4vw 0vh 0.6vw'
                                        <BtnRecta m='0px 2px 0px 1.8px' mm='0px 2.3px 0px 2.5px' mt='0px 3px 0px 3px' md='0px 4px 0px 3.6px' mlg='0px 5px 0px 3.8px'
                                            key={item.id}
                                            disabled={item.disable}
                                            onClick={(e) => asignarNumero(e, item.id)}
                                        >
                                            <PRecta m='0px'>{item.numero}</PRecta>
                                        </BtnRecta>
                                    )}
                                </Flex>
                            </FlexFull>
                        {/* </Flex> */}
                        <NumRecta margin="3.5rem 0 0 0">{comparar[count-1].numero_correcto}</NumRecta>
                    </FlexFull>

                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </FlexFull>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M41;