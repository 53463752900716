import {
    AUMENTAR_CONTADOR,
    REDUCIR_CONTADOR,
    ZERO_CONTADOR,
    ZERO_CONTADOR_SECUNDARIO,
    ZERO_CONTADOR_TERCIARIO,
    ZERO_CONTADOR_CUATERNARIO,
    ZERO_CONTADOR_CON_CORRECCION,
    SETEAR_CONTADOR,
    AUMENTAR_RONDA,
    AUMENTAR_CONTADOR_SECUNDARIO,
    AUMENTAR_CONTADOR_TERCIARIO,
    AUMENTAR_CONTADOR_CUATERNARIO,
    REDUCIR_CONTADOR_SECUNDARIO,
    REDUCIR_CONTADOR_TERCIARIO,
    REDUCIR_CONTADOR_CUATERNARIO,
    AUMENTAR_CONTADOR_CON_CORRECCION,
    REDUCIR_CONTADOR_CON_CORRECCION,
    AUMENTAR_CONTADOR_ERROR
} from '../types';

export function aumentarContadorAction() {
    return (dispatch) => {
        dispatch( aumentarContador() );
    }
}

export function reducirContadorAction() {
    return (dispatch) => {
        dispatch( reducirContador() );
    }
}

export function zeroContadorAction() {
    return (dispatch) => {
        dispatch( zeroContador() );
    }
}

export function zeroContadorSecundarioAction() {
    return (dispatch) => {
        dispatch( zeroContadorSecundario() );
    }
}

export function zeroContadorTerciarioAction() {
    return (dispatch) => {
        dispatch( zeroContadorTerciario() );
    }
}

export function zeroContadorCuaternarioAction() {
    return (dispatch) => {
        dispatch( zeroContadorCuaternario() );
    }
}

export function zeroContadorConCorreccionAction() {
    return (dispatch) => {
        dispatch( zeroContadorConCorreccion() );
    }
}

export function setearContadorAction(numero) {
    return (dispatch) => {
        dispatch( setearContador(numero) );
    }
}

export function aumentarRondaAction() {
    return (dispatch) => {
        dispatch( aumentarRonda() );
    }
}

export function aumentarContadorSecundarioAction() {
    return (dispatch) => {
        dispatch( aumentarContadorSecundario() );
    }
}

export function aumentarContadorTerciarioAction() {
    return (dispatch) => {
        dispatch( aumentarContadorTerciario() );
    }
}

export function aumentarContadorCuaternarioAction() {
    return (dispatch) => {
        dispatch( aumentarContadorCuaternario() );
    }
}

export function reducirContadorSecundarioAction() {
    return (dispatch) => {
        dispatch( reducirContadorSecundario() );
    }
}

export function reducirContadorTerciarioAction() {
    return (dispatch) => {
        dispatch( reducirContadorTerciario() );
    }
}

export function reducirContadorCuaternarioAction() {
    return (dispatch) => {
        dispatch( reducirContadorCuaternario() );
    }
}

export function aumentarContadorConCorreccionAction() {
    return (dispatch) => {
        dispatch( aumentarContadorConCorreccion() );
    }
}

export function reducirContadorConCorreccionAction() {
    return (dispatch) => {
        dispatch( reducirContadorConCorreccion() );
    }
}

export function aumentarContadorErrorAction() {
    return (dispatch) => {
        dispatch( aumentarContadorError() );
    }
}

const aumentarContador = () => ({
    type: AUMENTAR_CONTADOR
});

const reducirContador = () => ({
    type: REDUCIR_CONTADOR
});

const zeroContador = () => ({
    type: ZERO_CONTADOR
});

const zeroContadorSecundario = () => ({
    type: ZERO_CONTADOR_SECUNDARIO
});

const zeroContadorTerciario = () => ({
    type: ZERO_CONTADOR_TERCIARIO
});

const zeroContadorCuaternario = () => ({
    type: ZERO_CONTADOR_CUATERNARIO
});

const zeroContadorConCorreccion = () => ({
    type: ZERO_CONTADOR_CON_CORRECCION
});

const setearContador = (numero) => ({
    type: SETEAR_CONTADOR,
    payload: numero
});

const aumentarRonda = () => ({
    type: AUMENTAR_RONDA
});

const aumentarContadorSecundario = () => ({
    type: AUMENTAR_CONTADOR_SECUNDARIO
});

const aumentarContadorTerciario = () => ({
    type: AUMENTAR_CONTADOR_TERCIARIO
});

const aumentarContadorCuaternario = () => ({
    type: AUMENTAR_CONTADOR_CUATERNARIO
});

const reducirContadorSecundario = () => ({
    type: REDUCIR_CONTADOR_SECUNDARIO
});

const reducirContadorTerciario = () => ({
    type: REDUCIR_CONTADOR_TERCIARIO
});

const reducirContadorCuaternario = () => ({
    type: REDUCIR_CONTADOR_CUATERNARIO
});

const aumentarContadorConCorreccion = () => ({
    type: AUMENTAR_CONTADOR_CON_CORRECCION
});

const reducirContadorConCorreccion = () => ({
    type: REDUCIR_CONTADOR_CON_CORRECCION
});

const aumentarContadorError = () => ({
    type: AUMENTAR_CONTADOR_ERROR
});