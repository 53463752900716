import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

// CSS
import {Icon, Agregar, StyledModal,ModalLabel, BotonNaranja, BotonCerrar, Input} from '../Css/StyledCalendar.js'
import {FlexColumn} from '../Css/StyledHeader.js'

// Actions de Redux
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';

Modal.setAppElement('#root');

const AgendarCitaModal = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fechaCita, guardarFechaCita] = useState('');
    const [horaInicioCita, guardarHoraInicioCita] = useState('');
    const [horaFinalizacionCita, guardarHoraFinalizacionCita] = useState('');

    const arr1 = horaInicioCita.split(":")
    const arr1T = parseInt(`${arr1[0]}${arr1[1]}`)
    const arr2 = horaFinalizacionCita.split(":")
    const arr2T = parseInt(`${arr2[0]}${arr2[1]}`)


    // utilizar use dispatch y te crea una función
    const dispatch = useDispatch();

    //Store
    const alerta = useSelector( (state) => state.alertas.alerta);


    // mandar llamar el action de nuevoUsuarioAction

    const submitCita = e => {
        e.preventDefault();
        
        if(fechaCita.trim() === '' || horaInicioCita.trim() === '' || horaFinalizacionCita.trim() === ''  ) {
            const alerta = {
                msg: 'Todos los campos son obligatorios'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        if(arr1T >= arr2T) {
            const alerta = {
                msg: 'La hora de finalización debe ser mayor a la de inicio'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        // si no hay errores

        dispatch( ocultarAlertaAction());

        // crearCita({
        //     fechaCita,
        //     horaInicioCita,
        //     horaFinalizacionCita
        // });
    }

    return (
        <Fragment>
            <Agregar onClick={() => setModalIsOpen(true)} ><Icon src={`/img/mas.svg`} alt="Boton" width="3vh"/></Agregar>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                <StyledModal>                  
                <h2>Agendar cita con paciente</h2>
                <p>Para agendar la cita por favor complete los siguientes campos</p>
                <form
                    onSubmit={submitCita}
                >
                    <FlexColumn align="center">
                        <Fragment>
                        <ModalLabel htmlFor="fechaCita">Fecha de Cita</ModalLabel>
                        <Input
                            type="date"
                            id="fechaCita"
                            name="fechaCita"
                            placeholder="Fecha"
                            value={fechaCita}
                            onChange={e => guardarFechaCita(e.target.value)}
                        />
                        </Fragment>
                        <Fragment>
                        <ModalLabel htmlFor="horaInicioCita">Hora de Inicio</ModalLabel>
                        <Input
                            type="time"
                            id="horaInicioCita"
                            name="horaInicioCita"
                            placeholder="Hora"
                            value={horaInicioCita}
                            onChange={e => guardarHoraInicioCita(e.target.value)}
                        />
                        </Fragment>
                        <Fragment>
                        <ModalLabel htmlFor="horaFinalizacionCita">Hora de Finalización</ModalLabel>
                        <Input
                            type="time"
                            id="horaFinalizacionCita"
                            name="horaFinalizacionCita"
                            placeholder="Hora"
                            value={horaFinalizacionCita}
                            onChange={e => guardarHoraFinalizacionCita(e.target.value)}
                        />
                        </Fragment>
                    </FlexColumn>  
                </form>
                <BotonNaranja
                type="submit"
                >Agendar
                </BotonNaranja>
                        {alerta ? 
                        <Fragment>
                            <p>{alerta.msg}</p>
                        </Fragment>
                    : null }
                <Fragment>
                    <BotonCerrar onClick={() => setModalIsOpen(false)}> <Icon src={`/img/cerrar.svg`} alt="Cerrar" width="15px"/> </BotonCerrar>
                </Fragment>
                </StyledModal>
            </Modal>
        </Fragment>
    );
}
 
export default AgendarCitaModal;