import React from 'react';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';

//css
import {Cuerpo,Icon, FlexColumn } from '../Css/StyledHeader.js'
import {Table2,Th, Td1, Thead, Tebody, BotonNaranjaButton} from '../Css/StyledPatient.js';

const InformesPrevios = () => {

    const informes = [
        {id:1, fecha:"15/05/2021", profesional:"Ceci"},
        {id:2, fecha:"22/05/2021", profesional:"Ceci"},
        {id:3, fecha:"29/05/2021", profesional:"Juli"},
        {id:4, fecha:"05/06/2021", profesional:"Balu"},
        {id:5, fecha:"12/06/2021", profesional:"Balu"}
    ]

    return (
        <Cuerpo>
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes/paciente`} componente={"Informes Previos"} paciente={true} />
            <FlexColumn align="center">
            <Table2 height="30vh" width="70vw" >
                <Thead padding="8vw">
                    <tr>
                        <Th width="5vw">Fecha</Th>
                        <Th width="34vw">Profesional</Th>
                        <th></th> 
                    </tr>
                </Thead>
                {informes.map(informes => (
                    <Tebody padding="8vw"
                    key={informes.id}
                    >
                        <tr>                        
                            <Td1 width="9vw">{informes.fecha}</Td1>
                            <Td1 width="35vw">{informes.profesional}</Td1>   
                            <Td1 width="5vw"><Icon src={`/img/ver.svg`} alt="Ver" width="15px" margin="0px 0px 0px 10px"/></Td1>
                        </tr>
                    </Tebody>
                ))}
            </Table2>
            <BotonNaranjaButton margin="20px 0px">Subir Informe</BotonNaranjaButton>
            </FlexColumn>
        </Cuerpo>
    );
}
 
export default InformesPrevios;