import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { BtnColor, Input } from '../../../../Css/MetodosCSS/StyledEjercicios';
import { ContainerFlex } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';
import { Img } from '../../../../Css/StyledForm';

const instrucciones = 'Observa el dibujo, selecciona la palabra bien escrita y escribela en el recuadro.';
const miniInstrucciones = 'Observa el dibujo, selecciona la palabra bien escrita y escribela en el recuadro.';

const ESC8 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [count, setCount] = useState(1);
    const [randomPalabras, setRandomPalabras] = useState([]);
    const [palabra, setPalabra] = useState('');
    const [asignarPalabra, setAsignarPalabra] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [borde, setBorde] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if (asignarPalabra.toUpperCase() !== comparar.palabra_1.toUpperCase() || palabra.toUpperCase() !== comparar.palabra_1.toUpperCase()) {
            console.log('mal');
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setAsignarPalabra('');
                setPalabra('');
                setBorde('');
            }, 1000);
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setAsignarPalabra('');
                setPalabra('');
                setBorde('');
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setAsignarPalabra('');
                    setPalabra('');
                    setBorde('');
                }, 1000);
            } else {
                setTimeout(() => {
                    setDisable(false);
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction())
                }, 1000);
            }
        }
    }

    const setearPalabra = (e, plbr, posicion) => {
        e.preventDefault();
        if(posicion === borde) {
            setAsignarPalabra('');
            setBorde('')
        } else {
            setAsignarPalabra(plbr);
            setBorde(posicion);
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC8') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/sinfones/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC8",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        
        if(comparar === undefined){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        delete newList.id;
        delete newList.imagen_url_1;
        const shuffleList = Object.values(newList);

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(shuffleList);

        setRandomPalabras(newShuffleList);
    }, [comparar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} />
            {iniciar
                ?
                <ContainerFlex height="100%" width="100%" display="flex" direction="column" justify="center" align="center"
                    isCorrect={isCorrect} isIncorrect={isIncorrect}
                >
                    <ContainerFlex display="flex" direction="column" justify="center" align="center" margin="auto">
                        <ContainerFlex height="30vh" display="flex" align="center">
                            <Img width="200px" src={`/img/${comparar.imagen_url_1}.png`} />
                        </ContainerFlex>


                        <ContainerFlex display="flex" align="center" justify="center" height="25vh" width="100vw" >
                        <BtnColor width="auto" height="8vh" font="2rem" color="rgba(100, 100, 100, 1)" bg="rgba(253, 182, 44, 1)" $minMay={minuscula} margin="0 1rem" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" radius="10px" border={borde === 0 ? true : false} borderradius={borde === 0 ? true : false}
                                    onClick={(e) => setearPalabra(e, randomPalabras[0], 0)}
                                >
                                    {randomPalabras[0]}
                                </BtnColor>
                                <BtnColor width="auto" height="8vh" font="2rem" color="rgba(100, 100, 100, 1)" bg="rgba(253, 182, 44, 1)" $minMay={minuscula} margin="0 1rem" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" radius="10px" border={borde === 1 ? true : false} borderradius={borde === 1 ? true : false}
                                    onClick={(e) => setearPalabra(e, randomPalabras[1], 1)}
                                >
                                    {randomPalabras[1]}
                                </BtnColor>
                                <BtnColor width="auto" height="8vh" font="2rem" color="rgba(100, 100, 100, 1)" bg="rgba(253, 182, 44, 1)" $minMay={minuscula} margin="0 1rem" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" radius="10px" border={borde === 2 ? true : false} borderradius={borde === 2 ? true : false}
                                    onClick={(e) => setearPalabra(e, randomPalabras[2], 2)}
                                >
                                    {randomPalabras[2]}
                                </BtnColor>
                        </ContainerFlex>
                        <ContainerFlex display="flex" justify="center" align="center" margin="1.5rem 0 0 0">
                            <Input widthTablet="30vw" $minMay={minuscula} type="text"
                            value={palabra}  
                            disabled={disable}
                            onChange={(e) => setPalabra(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' ? verificar(e) : null}
                            />
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex margin="0 0rem 0 0rem" width="90%" justify="space-between" display="flex" align="center">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlex>

                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC8;