import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, DivFlex, BtnAbecedario, Img, BtnLetraESC3, BtnBasurero } from '../../../../Css/MetodosCSS/StyledLectoEscritura';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const array = [
    { id: 1, letra: '', color: '', muestro: false },
    { id: 2, letra: '', color: '', muestro: false },
    { id: 3, letra: '', color: '', muestro: false },
    { id: 4, letra: '', color: '', muestro: false },
    { id: 5, letra: '', color: '', muestro: false },
    { id: 6, letra: '', color: '', muestro: false },
    { id: 7, letra: '', color: '', muestro: false },
    { id: 8, letra: '', color: '', muestro: false }
]

const abc = [
    { id: '1', letra: 'a', estilo:false, bgcolor:'#23AAAF'}, { id: '2', letra: 'b', estilo:false, bgcolor:'#646464'} , { id: '3', letra: 'c', estilo:false, bgcolor:'#646464'} , { id: '4', letra: 'd', estilo:false, bgcolor:'#646464'} , { id: '5', letra: 'e', estilo:false, bgcolor:'#FF9B2A'} , { id: '6', letra: 'f', estilo:false, bgcolor:'#646464'} , { id: '7', letra: 'g', estilo:false, bgcolor:'#646464'} , { id: '8', letra: 'h', estilo:false, bgcolor:'#646464'} , { id: '9', letra: 'i', estilo:false, bgcolor:'#FFDC73'} , { id: '10', letra: 'j', estilo:false, bgcolor:'#646464'} , { id: '11', letra: 'k', estilo:false, bgcolor:'#646464'} , { id: '12', letra: 'l', estilo:false, bgcolor:'#646464'} , { id: '13', letra: 'm', estilo:false, bgcolor:'#646464'} , { id: '14', letra: 'n', estilo:false, bgcolor:'#646464'} , { id: '15', letra: 'ñ', estilo:false, bgcolor:'#646464'} , { id: '16', letra: 'o', estilo:false, bgcolor:'#655EE8'} , { id: '17', letra: 'p', estilo:false, bgcolor:'#646464'} , { id: '18', letra: 'q', estilo:false, bgcolor:'#646464'} , { id: '19', letra: 'r', estilo:false, bgcolor:'#646464'} , { id: '20', letra: 's', estilo:false, bgcolor:'#646464'} , { id: '21', letra: 't', estilo:false, bgcolor:'#646464'} , { id: '22', letra: 'u', estilo:false, bgcolor:'#D05F67'} , { id: '23', letra: 'v', estilo:false, bgcolor:'#646464'} , { id: '24', letra: 'w', estilo:false, bgcolor:'#646464'} , { id: '25', letra: 'x', estilo:false, bgcolor:'#646464'} , { id: '26', letra: 'y', estilo:false, bgcolor:'#646464'} , { id: '27', letra: 'z', estilo:false, bgcolor:'#646464'},
]

const instrucciones = 'Selecciona la primer letra de cada sonido.';
const miniInstrucciones = 'Selecciona la primer letra de cada sonido.';

const ESC3 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [disable, setDisable] = useState(false);
    const [abecedario, setAbecedario] = useState(abc);
    const [matrix, setMatrix] = useState(array);
    const [count, setCount] = useState(1);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if (comparar.letra_1 !== matrix[0].letra || comparar.letra_2 !== matrix[1].letra || comparar.letra_3 !== matrix[2].letra || comparar.letra_4 !== matrix[3].letra || comparar.letra_5 !== matrix[4].letra || comparar.letra_6 !== matrix[5].letra || comparar.letra_7 !== matrix[6].letra || comparar.letra_8 !== matrix[7].letra) {
            console.log('mal');
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                const newList = JSON.parse(JSON.stringify(matrix));
                newList.map(item => item.letra = '')
                setMatrix(newList);
            }, 1000);
        } else {
            console.log('bien')
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setMatrix(array)
                    setCount(count + 1);
                }, 1000);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1000);
            }
        }
    }

    const borrar = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(matrix));
        newList.forEach((item) => {
            item.letra = '';
            item.color = '';
        });
        setMatrix(newList);
    }

    const asignarLetra = (e, letra) => {
        e.preventDefault();
        const colorLetra = (function () {
            let color;
            switch (letra.toUpperCase()) {
                case 'A':
                    color = '#23AAAF';
                    return color;
                case 'E':
                    color = '#FF9B2A';
                    return color;
                case 'I':
                    color = '#FFDC73';
                    return color;
                case 'O':
                    color = '#655EE8';
                    return color;
                case 'U':
                    color = '#D05F67';
                    return color;
                default:
                    color = '#646464';
                    return color;
            }
        })();
        if(matrix.filter(item => item.muestro === true).length.toString() === matrix.filter(item => item.letra !== "").length.toString()){
            return;
        }

        const newList = JSON.parse(JSON.stringify(matrix));
        const index = matrix.findIndex(item => item.letra === '');
        newList[index].letra = letra;
        newList[index].color = colorLetra;
        setMatrix(newList);
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC3') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/silaba-directa/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC3",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[count-1]));
        delete newList.id;
        delete newList.palabra_1;
        delete newList.letra_1;
        delete newList.letra_2;
        delete newList.letra_3;
        delete newList.letra_4;
        delete newList.letra_5;
        delete newList.letra_6;
        delete newList.letra_7;
        delete newList.letra_8;
        delete newList.ayuda_imagen_1;
        delete newList.ayuda_imagen_2;
        delete newList.ayuda_imagen_3;
        delete newList.ayuda_imagen_4;
        delete newList.ayuda_imagen_5;
        delete newList.ayuda_imagen_6;
        delete newList.ayuda_imagen_7;
        delete newList.ayuda_imagen_8;
        const list = Object.values(newList);
        const filtered = list.filter(item => { return item !== '' });

        const newList5 = JSON.parse(JSON.stringify(informacion[count-1]));
        delete newList5.id;
        delete newList5.palabra_1;
        delete newList5.letra_1;
        delete newList5.letra_2;
        delete newList5.letra_3;
        delete newList5.letra_4;
        delete newList5.letra_5;
        delete newList5.letra_6;
        delete newList5.letra_7;
        delete newList5.letra_8;
        delete newList5.imagen_url_1;
        delete newList5.imagen_url_2;
        delete newList5.imagen_url_3;
        delete newList5.imagen_url_4;
        delete newList5.imagen_url_5;
        delete newList5.imagen_url_6;
        delete newList5.imagen_url_7;
        delete newList5.imagen_url_8;
        const listTwo = Object.values(newList5);
        const filteredTwo = listTwo.filter(item => { return item !== '' })

        const newList3 = JSON.parse(JSON.stringify(informacion[count-1]));
        setComparar(newList3);
        setIndexes(Object.keys(informacion).length);
        const newList4 = JSON.parse(JSON.stringify(informacion[count-1]));
        const numero = newList4.palabra_1.length;
        const newList2 = JSON.parse(JSON.stringify(array));
        newList2.filter(item => item.id <= numero).map(item => item.muestro = true);
        const newArray = newList2.map((obj,index) => ({
            ...obj,
            imagen: filtered[index],
            ayuda_imagen: filteredTwo[index]
        }))
        setMatrix(newArray);
        
    }, [informacion, count]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} ayuImagen={true} />
            {iniciar
                ?
                <ContainerFlexx direction="column" height="100%" width="100%">
                    <ContainerFlexx direction="column" margin="auto" width="100%"
                        isCorrect={isCorrect} isIncorrect={isIncorrect}
                    >
                        <ContainerFlexx display="flex" justify="center" margin='2rem 0 2rem 0'>
                            {matrix.filter(item => item.muestro === true).map((item, index) =>
                                <ContainerFlexx display="flex" direction="column" align="center" key={index}>
                                    <ContainerFlexx  >
                                        <ContainerFlexx display="flex" direction="column" justify="center" align="center">
                                            <Img w="90px" wd="55px" wt="45px" wm="35px" m="0 0.2rem" mm='0 0.2rem' mt='0 0.9rem' md='0 0.9rem' mlg='0 0.9rem' src={`/img/${item.imagen}.png`} />
                                            {ayuda_imagen ? 
                                            <div>{item.ayuda_imagen}</div>
                                            : null
                                            }
                                            <br></br><br></br>
                                            {/* img fija de flecha hacia abajo */}
                                        <Img width="25px" src={`/img/arrowdown.png`} />
                                        </ContainerFlexx>
                                    </ContainerFlexx>
                                    <ContainerFlexx bs="inset 0px 4px 4px rgba(0, 0, 0, 0.25)" padding="0.3rem" bradius="10px" margin='0 0.3rem' mm='0 0.3rem' mt='0 0.5rem' md='0 0.5rem' mlg='0 0.3rem'
                                        key={item.id}
                                    >
                                        <BtnLetraESC3 border="none"  $minMay={minuscula} font="3.5rem" color={ayuda_lectura ? item.color : '#646464'}
                                            
                                            disabled={disable}
                                        >
                                            {item.letra}
                                        </BtnLetraESC3>
                                    </ContainerFlexx>
                                </ContainerFlexx>
                            )}
                            <ContainerFlexx>
                                <BtnBasurero bg="transparent" border="none"
                                    disabled={disable}
                                    onClick={(e) => borrar(e)}
                                >
                                    <Img src={`/img/icono_basurero.png`} />
                                </BtnBasurero>
                            </ContainerFlexx>
                        </ContainerFlexx>
                        <DivFlex wrap='wrap' justify='center' align='center'>
                            {abecedario.map(item => (
                                    <BtnAbecedario $estilo={item.estilo} $background={"#69BEB8"} transf='uppercase' $minMay={minuscula} style={ayuda_lectura ? {color:`${item.bgcolor}`} : {color: '#646464'}}
                                        disabled={disable}
                                        key={item.id}
                                        onClick={(e) => asignarLetra(e, item.letra)}
                                    >
                                        {item.letra}
                                    </BtnAbecedario>
                                ))
                            }
                        </DivFlex>
                        
                    </ContainerFlexx>
                    <ContainerFlexx margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlexx>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment >
    );
}

export default ESC3;