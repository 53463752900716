import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { modificarObjetivoAction } from '../../actions/objetivosActions';
import useConfigAuth from '../../Hooks/useConfigAuth';

//css
import {Icon, FlexBox} from '../Css/StyledHeader'
import { Actividad, Fondo,Li,P, Vertical,Titulos, Columna, Ul, Boton} from '../Css/StyledActivities';

const ObjetivosAsignados = (props) => {

    const {id} = props.seccion;

    const dispatch = useDispatch();

    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const informacionObjetivos = useSelector((state) => state.informacion.info_objetivos);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);

    const [objetivosNuevo, setObjetivosNuevo] = useState(informacionObjetivos);
    const [objetivoBD, setObjetivoBD] = useState([]);

    const avanzarEstado = (id_objetivo, custom_objetivo, id_estado_objetivo) => {
        if(id_estado_objetivo === '3') {
            return;
        }
        let nuevoEstado = '';
        switch (id_estado_objetivo.toString()) {
            case '1':
                nuevoEstado = '2';
                break;
            case '2':
                nuevoEstado = '3';
                break;
            default:
                break;
        }
        const newList = JSON.parse(JSON.stringify(objetivosNuevo));
        for(let i = 0; i < newList.length; i++){
            if(newList[i].id === id_objetivo && newList[i].custom === custom_objetivo){
                newList[i].id_estado_objetivo = nuevoEstado;
                setObjetivoBD(newList[i]);
            }
        }
        setObjetivosNuevo(newList);
        setDisable(true);
    }

    const retrocederEstado = (id_objetivo, custom_objetivo, id_estado_objetivo) => {
        if(id_estado_objetivo === '1') {
            return;
        }
        let nuevoEstado = '';
        switch (id_estado_objetivo.toString()) {
            case '2':
                nuevoEstado = '1';
                break;
            case '3':
                nuevoEstado = '2';
                break;
            default:
                break;
        }
        const newList = JSON.parse(JSON.stringify(objetivosNuevo));
        for(let i = 0; i < newList.length; i++){
            if(newList[i].id === id_objetivo && newList[i].custom === custom_objetivo){
                newList[i].id_estado_objetivo = nuevoEstado;
                setObjetivoBD(newList[i]);
            }
        }
        setObjetivosNuevo(newList);
        setDisable(true);
    }

    useEffect(() => {
        if(objetivoBD.length === 0){
            return;
        }
        const informacion = {
            config,
            payload: {
                objetivosNuevo,
                objetivoBD,
                id_paciente,
                tipo: "estado"
            }
        };

        dispatch(modificarObjetivoAction(informacion));
        setObjetivoBD([]);
        setTimeout(() => {
            setDisable(false);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objetivosNuevo])

    useEffect(() => {
        setObjetivosNuevo(
            informacionObjetivos
        );
    }, [informacionObjetivos])

    return (
        <Fondo width="75vw" height="45vh" padding="0px" ancho="90vw">
        <FlexBox >
            <Columna>
                <Titulos weight="600" align="center">Objetivos establecidos</Titulos>
                <div>
                    <Ul padding="0px">
                        {informacionObjetivos.filter(objetivo => objetivo.id_seccion_objetivo.toString() === id.toString() && objetivo.id_etapa_objetivo === "2" && objetivo.id_estado_objetivo === "1").map(objetivo => (
                            <Li key={objetivo.id}>
                                <Actividad>   
                                    <P size="12px" padding="10px">{objetivo.nombre_objetivo}</P>
                                    <Boton color="#F2F2F2"
                                        disabled={disable}
                                        type="button" 
                                        onClick={() => avanzarEstado(objetivo.id, objetivo.custom, objetivo.id_estado_objetivo)}
                                    ><Icon src={`/img/flechaDerecha.png`} alt="Derecha" width="15px" height="15px" /></Boton>
                                </Actividad>
                            </Li>
                        ))}
                    </Ul>
                </div>
            </Columna>
            <Vertical/>
            <Columna>
                <Titulos weight="600">En proceso</Titulos>
                <div>
                    <Ul padding="0px">
                        {informacionObjetivos.filter(objetivo => objetivo.id_seccion_objetivo.toString() === id.toString() && objetivo.id_etapa_objetivo === "2" && objetivo.id_estado_objetivo === "2").map(objetivo => (
                            <Li key={objetivo.id}> 
                                <Actividad>                           
                                <P size="12px" padding="10px">{objetivo.nombre_objetivo}</P>
                                   <div>
                                        <Boton color="#F2F2F2"
                                            disabled={disable}
                                            type="button" 
                                            onClick={() => retrocederEstado(objetivo.id, objetivo.custom, objetivo.id_estado_objetivo)}
                                        ><Icon src={`/img/flechaIzquierda.png`} alt="Izquierda" width="15px" height="15px"/></Boton>
                                        <Boton color="#F2F2F2"
                                            disabled={disable}
                                            type="button" 
                                            onClick={() => avanzarEstado(objetivo.id, objetivo.custom, objetivo.id_estado_objetivo)}
                                        ><Icon src={`/img/flechaDerecha.png`} alt="Derecha" width="15px" height="15px" /></Boton>
                                    </div>
                                    </Actividad>  
                            </Li>
                        ))}
                    </Ul>
                </div>
            </Columna>
            <Vertical/>
            <Columna>
                <Titulos weight="600">Finalizados</Titulos>
                <div>
                    <Ul padding="0px">
                        {informacionObjetivos.filter(objetivo => objetivo.id_seccion_objetivo.toString() === id.toString() && objetivo.id_etapa_objetivo === "2" && objetivo.id_estado_objetivo === "3").map(objetivo => (
                            <Li key={objetivo.id}>
                                 <Actividad>  
                                <P size="12px" padding="10px">{objetivo.nombre_objetivo}</P>
                                    <Boton color="#F2F2F2"
                                        disabled={disable}
                                        type="button" 
                                        onClick={() => retrocederEstado(objetivo.id, objetivo.custom, objetivo.id_estado_objetivo)}
                                    ><Icon src={`/img/flechaIzquierda.png`} alt="Izquierda" width="15px" height="15px"/></Boton>
                                 </Actividad>  
                            </Li>
                        ))}
                    </Ul>
                </div>
            </Columna>
            
        </FlexBox>
       </Fondo>
    );
}
 
export default ObjetivosAsignados;

