import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, BtnLetraLN18, ContainerLN18, BotoneraFixed } from '../../../../Css/MetodosCSS/StyledLectoEscritura';

const instrucciones = 'Selecciona las letras que se indican en la consigna interna.';

const LN18 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [randomImagen, setRandomImagen] = useState([]);
    const [count, setCount] = useState(1);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, ltr, index) => {
        e.preventDefault();
        if (ltr.toUpperCase() === comparar.letra.toUpperCase()) {
            console.log('bien')
            const newList = JSON.parse(JSON.stringify(randomImagen));
            newList[index].disable = true;
            setRandomImagen(newList);
            setCount(count + 1);
            setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
            if (count < 21) {
                return;
            } else {
                setUltimoTiempo(true);
                dispatch(finalizarEjercicioAction());
            }
        } else {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            const newList = JSON.parse(JSON.stringify(randomImagen));
            newList[index].disable = true;
            setRandomImagen(newList)
            const newList2 = JSON.parse(JSON.stringify(randomImagen));
            newList2[index].disable = false;
            setRandomImagen(newList2);
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LN18') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/ejercicios/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[0]);
    }, [informacion])

    useEffect(() => {
        if(iniciar === false ){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LN18",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return;
        }
        const lista = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',];
        if (!comparar.letra_incorrecta_2) {
            lista.fill(comparar.letra, 0, 21)
            lista.fill(comparar.letra_incorrecta_1, 21, 54);
        } else {
            lista.fill(comparar.letra, 0, 21)
            lista.fill(comparar.letra_incorrecta_1, 21, 38);
            lista.fill(comparar.letra_incorrecta_2, 38, 54);
        }

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const newShuffleList = shuffle(lista);
        setRandomImagen(newShuffleList.map((letra, i) => ({ index: i, letra, disable: false })))
    }, [comparar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <ContainerFlexx height="85vh">
                    <ContainerFlexx height="80vh"  display="flex" justify="center" align="center">
                        <ContainerFlexx color="rgba(249, 249, 249, 1)" width="28rem" wm='30rem' wt='30rem' wd='30rem' wlg='29rem' height="21rem" hm='20rem' ht='22rem' hd='23rem' hlg='22rem' wrap="wrap" bs="0px 4px 4px rgba(0, 0, 0, 0.25)" padding="1rem 0.5rem 1rem 0.5rem" bradius="20px">
                            {randomImagen.map(item =>
                                <ContainerLN18 display="flex"
                                    isCorrect={isCorrect}
                                    isIncorrect={isIncorrect}
                                    key={item.index}
                                >
                                    <BtnLetraLN18 $minMay={true}
                                        onClick={(e) => verificar(e, item.letra, item.index)}
                                        disabled={item.disable}
                                    >
                                        {item.letra}
                                    </BtnLetraLN18>
                                </ContainerLN18>)}
                        </ContainerFlexx>
                    </ContainerFlexx>
                    <BotoneraFixed position='fixed' left='0' bottom='0'>
                        <BotoneraBottom miniInstrucciones={`Selecciona las letras que se indican en la consigna interna: ${informacion[0].letra}`} />
                    </BotoneraFixed>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN18;