import React from 'react';

import PerfilNavbar from './PerfilNavbar';
// css
import {LogoHeader} from '../Css/StyledHeader.js'
import { ContenedorHeaderPaciente, LineaHorizontal } from '../Css/StyledPaciente';

const HeaderPaciente = () => {

    return (
        <div>
            <ContenedorHeaderPaciente>
                <LogoHeader src={`/img/LogoHorizontal.png`} alt="Logo" height="9vh" />
                <PerfilNavbar />
            </ContenedorHeaderPaciente>
            <LineaHorizontal></LineaHorizontal>
        </div>
    );
}
 
export default HeaderPaciente;