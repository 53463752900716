import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LN7Todos from '../../../Compartidos/LN7Todos';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Lee lo más rápido que puedas la siguiente lista de palabras. Puedes repetir la lectura para observar si estás leyendo más rápido con cada repetición.';
const miniInstrucciones = 'Lee lo más rápido que puedas la siguiente lista de palabras.';

const LN7 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN7') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/instruccion-explicita/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])

    return (
        <Fragment>
            <LN7Todos data={data} indexes={indexes} ejercicio="LN7" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default LN7;