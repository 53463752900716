import React, {Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import PracticaMillones from '../PracticaMillones';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'A continuación se presentan números diferenciados por su valor posicional. El niño deberá leer en voz alta dichos números. Si necesita ayuda se puede hacer click sobre el recuadro donde presenta dificultad. Asimismo se puede hacer click sobre el ícono de ayuda.';
const miniInstrucciones = 'Lee los números.';

const M32Bis = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setearEjercicioAction('M32Bis') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
            <PracticaMillones ejercicio="M32Bis" miniInstrucciones={"EXPRESEAR EL NÚMERO DE MANERA ORAL"} />
        </Fragment>
    );
}
 
export default M32Bis;