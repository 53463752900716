import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LN5Todos from '../../../Compartidos/LN5Todos';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Escucha las sílabas y escribelas en el recuadro.';
const miniInstrucciones = 'Escucha las sílabas y escribelas en el recuadro.';

const LN5 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN5') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/instruccion-explicita/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[0]));
        if(newList.tipo_1 === '2') {
            const nuevaLista = [
                {id:'1', silaba:newList.silaba_1, sonido:newList.sonido_url_1},
                {id:'2', silaba:newList.silaba_2, sonido:newList.sonido_url_2},
                {id:'3', silaba:newList.silaba_3, sonido:newList.sonido_url_3},
                {id:'4', silaba:newList.silaba_4, sonido:newList.sonido_url_4},
                {id:'5', silaba:newList.silaba_5, sonido:newList.sonido_url_5},
                {id:'6', silaba:newList.silaba_6, sonido:newList.sonido_url_6},
                {id:'7', silaba:newList.silaba_7, sonido:newList.sonido_url_7},
                {id:'8', silaba:newList.silaba_8, sonido:newList.sonido_url_8},
                {id:'9', silaba:newList.silaba_9, sonido:newList.sonido_url_9},
                {id:'10', silaba:newList.silaba_10, sonido:newList.sonido_url_10}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else if (newList.tipo_1 === '3') {
            const nuevaLista = [
                {id:'1', silaba:newList.silaba_1, sonido:newList.sonido_url_1},
                {id:'2', silaba:newList.silaba_2, sonido:newList.sonido_url_2},
                {id:'3', silaba:newList.silaba_3, sonido:newList.sonido_url_3},
                {id:'4', silaba:newList.silaba_4, sonido:newList.sonido_url_4},
                {id:'5', silaba:newList.silaba_5, sonido:newList.sonido_url_5},
                {id:'6', silaba:newList.silaba_6, sonido:newList.sonido_url_6},
                {id:'7', silaba:newList.silaba_7, sonido:newList.sonido_url_7},
                {id:'8', silaba:newList.silaba_8, sonido:newList.sonido_url_8},
                {id:'9', silaba:newList.silaba_9, sonido:newList.sonido_url_9},
                {id:'10', silaba:newList.silaba_10, sonido:newList.sonido_url_10},
                {id:'11', silaba:newList.silaba_11, sonido:newList.sonido_url_11},
                {id:'12', silaba:newList.silaba_12, sonido:newList.sonido_url_12},
                {id:'13', silaba:newList.silaba_13, sonido:newList.sonido_url_13},
                {id:'14', silaba:newList.silaba_14, sonido:newList.sonido_url_14},
                {id:'15', silaba:newList.silaba_15, sonido:newList.sonido_url_15}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else {
            const nuevaLista = [
                {id:'1', silaba:newList.silaba_1, sonido:newList.sonido_url_1},
                {id:'2', silaba:newList.silaba_2, sonido:newList.sonido_url_2},
                {id:'3', silaba:newList.silaba_3, sonido:newList.sonido_url_3},
                {id:'4', silaba:newList.silaba_4, sonido:newList.sonido_url_4},
                {id:'5', silaba:newList.silaba_5, sonido:newList.sonido_url_5},
                {id:'6', silaba:newList.silaba_6, sonido:newList.sonido_url_6},
                {id:'7', silaba:newList.silaba_7, sonido:newList.sonido_url_7},
                {id:'8', silaba:newList.silaba_8, sonido:newList.sonido_url_8},
                {id:'9', silaba:newList.silaba_9, sonido:newList.sonido_url_9},
                {id:'10', silaba:newList.silaba_10, sonido:newList.sonido_url_10},
                {id:'11', silaba:newList.silaba_11, sonido:newList.sonido_url_11},
                {id:'12', silaba:newList.silaba_12, sonido:newList.sonido_url_12}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        }
    }, [informacion])

    return (
        <Fragment>
            <LN5Todos data={data} indexes={indexes} ejercicio="LN5" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default LN5;