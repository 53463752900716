import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import BotoneraBottom from './BotoneraBottom';
import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { almacenarAction } from '../../../actions/almacenarEjercicioActions';
import { aumentarContadorConCorreccionAction } from '../../../actions/botoneraEjercicioActions';

// css
import { ContainerFlex } from '../../Css/MetodosCSS/StyledLN2Todos';
import { Img } from '../../Css/StyledMatematica';

const ImgSubL1 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, ronda, instrucciones, miniInstrucciones, h, w } = props;

    const [randomImagen, setRandomImagen] = useState([]);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista = useSelector(state => state.ejercicio.lista);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const contadorConCorreccion = useSelector(state => state.ejercicio.contadorConCorreccion);
    const error = useSelector(state => state.ejercicio.error);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    useEffect(() => {
        if(contadorConCorreccion === 0){
            return;
        }
        if(contadorConCorreccion === 1){
            setErroresOrden([...erroresOrden, error]);
            return;
        }
        const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
        setErroresOrden([...erroresOrden, err]);

    }, [contadorConCorreccion])
    
    useEffect(() => {
        if(data === undefined) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(data));

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex != 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList);
        setRandomImagen(shuffleList);
        const newList2 = JSON.parse(JSON.stringify(shuffleList));
        const elOrdenDelId = newList2.map(item => item.id)
        setIdOrden(elOrdenDelId);
    }, [iniciar])

    useEffect(() => {
        if(iniciar === false && contadorConCorreccion === indexes ){
            return;
        }else if(iniciar === false && contadorConCorreccion === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
    }, [iniciar,contadorConCorreccion]);

    useEffect(() => {
        if(finalizar === true && contadorConCorreccion === indexes -1 ){
            dispatch(aumentarContadorConCorreccionAction());
        }
        if(finalizar && contadorConCorreccion === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
            const erroresOrdenFinal = [...erroresOrden, err];

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrdenFinal,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
    }, [finalizar, contadorConCorreccion]);


    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} />
            {iniciar
                ?
                <ContainerFlex display="flex" direction="column" height="100%" width="100%">
                    <ContainerFlex margin="auto">
                        <ContainerFlex display="flex" align="center" justify="center">
                            {randomImagen.length === 0 ? null :
                            <Img height={h} width={w} src={`/img/${randomImagen[contadorConCorreccion].imagen_url_1}.png`} />
                            }
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex display="flex" justify="space-between" justifyself="end">
                        <ContainerFlex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </ContainerFlex>
                        <ContainerFlex display="flex" justify="end" align="center">
                            <BotoneraBottom aumentarConCorreccion={true} error={true} pausar={true} cantidad={indexes} ronda={ronda} />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ImgSubL1;