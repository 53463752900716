import React, {Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import RestaCompartido from './RestaCompartido';
import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Resuelve la resta mentalmente.';
const miniInstrucciones = 'Resuelve la resta mentalmente.';

const M81 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [indexes, setIndexes] = useState(0);

    useEffect(() => {
        const min = 100;
        const max = 999;
        let lista = []
        for(let i = 0; i < 5; i++){
            let randomNumero = 0;
            do {
                randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
            } while (lista.includes(randomNumero) || [100,200,300,400,500,600,700,800,900].includes(randomNumero));
            lista.push(randomNumero)
        }
        const modelo = [
            {id:'1', cantidad_1:lista[0], cantidad_2:100},
            {id:'2', cantidad_1:lista[1], cantidad_2:100},
            {id:'3', cantidad_1:lista[2], cantidad_2:100},
            {id:'4', cantidad_1:lista[3], cantidad_2:100},
            {id:'5', cantidad_1:lista[4], cantidad_2:100}
        ]
        setData(modelo);
        setIndexes(modelo.length);
        dispatch(setearEjercicioAction('M81') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <RestaCompartido data={data} indexes={indexes} ejercicio="M81" tipo={"centena"} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} width={"12vw"} inp={"27vw"} color={"#FF9B2A"} />
        </Fragment>
    );
}
 
export default M81;