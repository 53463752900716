import React, { Fragment, useState } from 'react';
import Modal from 'react-modal';

// CSS
import {TerminosCondiciones, StyledModal, Titulo, Texto, Button} from '../Css/StyledForm'

Modal.setAppElement('#root');

const TerminosCondicionesModal = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <Fragment>
            <TerminosCondiciones onClick={() => setModalIsOpen(true)} >Política de Privacidad y Términos de Servicio</TerminosCondiciones>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                <StyledModal>
                    <Titulo>Fecha de última actualización: 22/04/2024</Titulo>
                    <Texto>En Método Yögen, tomamos la privacidad de nuestros usuarios con la máxima seriedad. Valoramos y respetamos la confianza que depositan en nosotros al elegirnos como su plataforma educativa. Esta Política de Privacidad y Términos de Servicio tiene como objetivo brindar transparencia sobre cómo recopilamos, utilizamos y protegemos la información personal de nuestros usuarios, en estricto cumplimiento con el Reglamento 2016/679 del Parlamento Europeo y del Consejo (Reglamento General de Protección de Datos, GDPR) y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales en Argentina. 
                    <br/><br/>
Nos comprometemos a implementar medidas sólidas para salvaguardar la privacidad de cada usuario, asegurándonos de que la información personal se utilice únicamente para los fines establecidos y garantizando un entorno educativo seguro y confiable. A continuación, detallamos cómo protegemos y gestionamos los datos personales de nuestros usuarios, así como los derechos y opciones disponibles para cada individuo. 
<br/><br/>
En caso de preguntas o inquietudes sobre nuestra política de privacidad, no dudes en ponerte en contacto con nosotros a través de metodoyogen@gmail.com. Agradecemos la confianza que nos brindas al elegir MétodoYögen para tu experiencia educativa y trabajaremos incansablemente para mantener esa confianza a lo largo de tu viaje educativo con nosotros. 
<br/><br/>
Aceptación de los términos y condiciones:
<br/><br/>
Al acceder y utilizar nuestra plataforma web de enseñanza para menores en Argentina, aceptas cumplir con los siguientes términos y condiciones. Si no estás de acuerdo con alguno de estos términos, te solicitamos que no utilices nuestros servicios. 
<br/><br/>
Uso de la plataforma:
<br/><br/>
Nuestra plataforma web de enseñanza está destinada exclusivamente a menores de edad en Argentina y a sus padres o tutores legales. Al utilizar la plataforma, garantizas que tienes la autorización y el consentimiento adecuado para hacerlo. 
<br/><br/>
Responsabilidad de los padres o tutores legales:
<br/><br/>
Los padres o tutores legales son los responsables de supervisar y controlar el acceso y uso de la plataforma por parte de los menores a su cargo. Recomendamos encarecidamente que los padres o tutores legales participen activamente en el proceso de aprendizaje y monitoreen las actividades realizadas por los menores en la plataforma. 
<br/><br/>
Contenido y propiedad intelectual:
<br/><br/>
Todo el contenido disponible en nuestra plataforma, incluyendo pero no limitado a textos, imágenes, videos, materiales didácticos y recursos educativos, están protegidos por derechos de autor y son propiedad exclusiva de sus respectivos dueños. Queda estrictamente prohibida la reproducción, distribución o modificación no autorizada de dicho contenido. 
<br/><br/>
Privacidad y protección de datos personales: 
<br/><br/>
Respetamos tu privacidad y nos comprometemos a proteger tus datos personales de acuerdo con la Ley de Protección de Datos Personales de Argentina (Ley N° 25.326) y las regulaciones de protección de datos a nivel mundial, como el Reglamento General de Protección de Datos de la Unión Europea (RGPD). Al utilizar nuestra plataforma, aceptas nuestra Política de Privacidad, que describe cómo recopilamos, utilizamos y protegemos tus datos personales de acuerdo con estas leyes y regulaciones. 
<br/><br/>
Responsabilidad y exención de responsabilidad: 
<br/><br/>
Nos esforzamos por brindar un servicio de calidad, pero no garantizamos la exactitud, confiabilidad o disponibilidad continua de nuestra plataforma. No seremos responsables por cualquier daño o perjuicio causado por el uso de la plataforma, incluyendo pero no limitado a pérdida de datos, interrupción del servicio o cualquier otro daño indirecto, incidental o consecuente. 
<br/><br/>
1. Información Recopilada y Utilización:
<br/><br/>
En Método Yögen, nos comprometemos a recopilar y utilizar la información personal de nuestros usuarios de manera responsable y segura. La recopilación de datos se limita a lo estrictamente necesario para brindar servicios educativos personalizados. Esto incluye datos como el nombre, la dirección de correo electrónico, la información de contacto y detalles de perfil (1.1 Datos de Usuario). Es importante destacar que bajo ninguna circunstancia compartiremos estos datos con terceros. Además, en el caso de transacciones relacionadas con servicios de pago, recopilaremos y procesaremos únicamente la información necesaria para completar dichas transacciones, siempre en conformidad con las leyes de protección de datos aplicables (1.2 Datos de Pago). 
<br/><br/>
Para mejorar continuamente la experiencia del usuario y personalizar el contenido educativo, recopilamos información sobre cómo los usuarios interactúan con la plataforma (1.3 Datos de Uso). Esta información se utiliza internamente y de manera agregada para garantizar un entorno educativo más efectivo y adaptado a las necesidades individuales, sin comprometer la privacidad de nuestros usuarios. En Método Yögen, creemos en la confidencialidad y seguridad de los datos, asegurando que la información recopilada sirva exclusivamente para mejorar la calidad de nuestros servicios educativos. 
<br/><br/>
2. Finalidad del Tratamiento:
<br/><br/>
   - 2.1 **Provisión de Servicios:** Utilizamos la información recopilada para ofrecer servicios educativos, gestionar cuentas de usuario y mejorar la calidad de nuestros contenidos. 
   - 2.2 **Comunicaciones:** Podemos utilizar la información para enviar comunicaciones relacionadas con el servicio, actualizaciones, y ofertas educativas. 
   <br/><br/>
3. Base Legal para el Tratamiento:
<br/><br/>
Reconocemos que el procesamiento de datos es esencial para cumplir con el contrato establecido entre el usuario y la plataforma. Esta base legal respalda el tratamiento de información necesaria para proporcionar servicios educativos personalizados y garantizar la ejecución eficaz del contrato educativo. La recopilación de datos en este contexto está destinada exclusivamente a proporcionar una experiencia educativa eficiente y adaptada a las necesidades individuales del usuario. 
<br/><br/>
Valoramos la importancia de la investigación educativa y nos comprometemos a contribuir al avance del conocimiento en este campo. En algunos casos, podremos utilizar datos parametrizados de manera anónima y agregada con fines de investigación, siempre respetando las políticas y leyes de privacidad vigentes. Estos datos parametrizados se utilizarán exclusivamente para mejorar nuestros servicios, analizar tendencias educativas y desarrollar investigaciones relacionadas con la enseñanza para menores. Garantizamos que cualquier información personal identificable será eliminada o anonimizada antes de utilizar los datos con fines de investigación. 
<br/><br/>
Nuestro compromiso es cumplir con todas las leyes y regulaciones aplicables relacionadas con la privacidad y protección de datos, incluyendo la Ley de Protección de Datos Personales de Argentina (Ley N° 25.326) y otras leyes de privacidad a nivel mundial, como el RGPD. Aseguramos que la utilización de los datos parametrizados para fines de investigación se llevará a cabo de acuerdo con estas políticas y leyes. 
<br/><br/>
Si tienes alguna pregunta o inquietud sobre el uso de datos parametrizados para fines de investigación, te invitamos a consultar nuestra Política de Privacidad, donde se detalla cómo tratamos la información y cómo protegemos tu privacidad. 
<br/><br/>
Adicionalmente, Método Yögen recaba datos desde el sector de anamnesis para facilitar la personalización de la experiencia educativa. Los usuarios que opten por no compartir datos en este apartado pueden elegir no utilizar dicho sector. En el marco de la ejecución del contrato, se reserva el derecho de utilizar datos parametrizados con fines de investigación, siempre excluyendo cualquier información que pueda identificar a la persona. Esta práctica contribuye al mejoramiento continuo de nuestros servicios, asegurando la confidencialidad de los usuarios y promoviendo avances en la educación sin comprometer la privacidad. 
<br/><br/>
4. Derechos del Usuario:
<br/><br/>
   - 4.1 Acceso y Rectificación: Los usuarios tienen el derecho de acceder y corregir sus datos personales. 
   - 4.2 Supresión: Los usuarios pueden solicitar la eliminación de sus datos personales en determinadas circunstancias. 
   - 4.3 Oposición al Tratamiento: Los usuarios pueden oponerse al procesamiento de sus datos en situaciones específicas. 
   <br/><br/>
5. Seguridad de los Datos:
<br/><br/>
   - 5.1 Medidas de Seguridad: Implementamos medidas técnicas y organizativas para garantizar la seguridad de los datos personales. 
   <br/><br/>
6. Divulgación de Información:
<br/><br/>
   - 6.1 Terceros: Podemos compartir información con terceros en casos específicos, siempre cumpliendo con las leyes de protección de datos. 
   <br/><br/>
7. Cookies y Tecnologías Similares:
<br/><br/>
   - 7.1 Uso de Cookies: Utilizamos cookies para mejorar la experiencia del usuario y analizar el tráfico. 
   <br/><br/>
8. Cambios en la Política de Privacidad: 
<br/><br/>
Creemos en la importancia de la transparencia, y eso incluye contarte sobre cualquier cambio en nuestra política de privacidad. Nos reservamos el derecho de realizar actualizaciones para asegurarnos de estar siempre a la vanguardia en la protección de tus datos. Pero tranquilo/a, cualquier cambio que realicemos será notificado directamente a ti. Queremos que estés informado/a en todo momento y te sientas cómodo/a con la forma en que manejamos tus datos. ¡Somos un equipo!
<br/><br/>
Ley aplicable y jurisdicción: 
<br/><br/>
Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes de Argentina y la Ley de Protección de Datos Personales de Argentina (Ley N° 25.326), así como las regulaciones de protección de datos a nivel mundial, incluyendo el RGPD. Cualquier disputa que surja en relación con la plataforma estará sujeta a la jurisdicción exclusiva de los tribunales competentes en Argentina. 
Al utilizar Método Yögen, el usuario acepta y comprende esta política de privacidad. Para cualquier pregunta o inquietud, contáctenos en metodoyogen@gmail.com.  </Texto>
                    <Texto>Usted acepta todas las condiciones</Texto>
                    <div>
                        <Button onClick={() => setModalIsOpen(false)}>Cerrar</Button>
                    </div>
                </StyledModal>
            </Modal>
        </Fragment>
    );
}
 
export default TerminosCondicionesModal;