import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {Icono, Btn, Corto, Largo, Flex} from '../../../../Css/MetodosCSS/StyledCCLexica';

const instrucciones = 'A continuación escucharás unas palabras, deberás seleccionar si es una palabra corta (cuadrado naranja) o una palabra larga (cuadrado verde).';
const miniInstrucciones = 'Selecciona si es una palabra corta o una palabra larga.';

const LEN16 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    /*Borrar si funciona con una sola linea*/
    // const play = (count) => {
    //     switch(count){
    //         case 1:
    //             play1();
    //             break;
    //         case 2:
    //             play2();
    //             break;
    //         case 3:
    //             play3();
    //             break;
    //         case 4:
    //             play4();
    //             break;
    //         case 5:
    //             play5();
    //             break;
    //     }
    // }
    // const play1 = () => new Audio(`/sounds/${comparar.sonido_url_1}.mp3`).play()
    // const play2 = () => new Audio(`/sounds/${data[1].sonido}.mp3`).play()
    // const play3 = () => new Audio(`/sounds/${data[2].sonido}.mp3`).play()
    // const play4 = () => new Audio(`/sounds/${data[3].sonido}.mp3`).play()
    // const play5 = () => new Audio(`/sounds/${data[4].sonido}.mp3`).play()

    const play = () =>  new Audio(`/sounds/${comparar.sonido_url_1}.mp3`).play()

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();
    
    const verificar = (tipo, tipoDato) => {
        if(tipo === tipoDato) {
            console.log("correcto")
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);         
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setCount(count + 1)
                    setDisable(false);
                }, 300);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    dispatch( finalizarEjercicioAction() );       
                }, 1500);
            }
        } else {
            console.log("mal")
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN16') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-lexica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length)
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN16",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);
    
    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex margin="auto" direction="column">
                        <Flex margin="0 0rem 3.5rem 0">
                            <Btn onClick={() => play()} > 
                                <Icono src={`/img/altavoz.png`} alt="" margin="0px 0px 0px 0px"  
                                isCorrect={isCorrect} isIncorrect={isIncorrect}
                                /> 
                            </Btn>
                            <audio src={`/sounds/${comparar.sonido_url_1}.mp3`} autoPlay />
                        </Flex>
                        <Flex>
                            <Corto
                                disabled={disable}
                                onClick={() => verificar("corta", comparar.tipo)}
                            ></Corto>
                            <Largo
                                disabled={disable}
                                onClick={() => verificar("larga", comparar.tipo)}
                            ></Largo>
                        </Flex>
                    </Flex>
                    <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default LEN16;