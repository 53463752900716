import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction } from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, DivFlex, BtnSonido, BotoneraFixed, Img, FormInputLN5, InputTextLN5 } from '../../Css/MetodosCSS/StyledLectoEscritura';

const LN5Todos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, instrucciones, miniInstrucciones } = props;

    const [disable, setDisable] = useState(false);
    const [count, setCount] = useState(1);
    const [randomSonido, setRandomSonido] = useState([]);
    const [palabra, setPalabra] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [sonido, setSonido] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const letra = useSelector(state => state.ejercicio.letra);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const play1 = () => new Audio(`/sounds/${sonido}.mp3`).play()

    const verificar = (e) => {
        e.preventDefault();
        if (palabra.toUpperCase() !== randomSonido[count-1].silaba.toUpperCase()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setPalabra('');
            }, 1500);
            return;
        } else {
            console.log('bien');
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setSonido('');
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                    setSonido(randomSonido[count].sonido);
                    setPalabra('');
                }, 300);
            } else {
                setTimeout(() => {
                    setDisable(false);
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1500);
            }
        }
    }

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(data));
        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList);
        setRandomSonido(shuffleList);
        setSonido(shuffleList[count-1].sonido)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': ejercicio,
                'letra': letra,
                'errores': contarErrores,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                sesion
            }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <DivFlex justify='space-evenly' align='center' height="85vh"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                >
                        <ContainerFlexx>
                            <BtnSonido
                                onClick={() => play1()}
                            >
                                <Img alt='' src={`/img/altavoz.png`} w='120px' h='120px' wm='120px' wt='140px' wd='140px' wlg='140px' hm='120px' ht='140px' hd='140px' hlg='140px' />
                            </BtnSonido>
                            {randomSonido[count-1] === undefined ? null : <audio src={`/sounds/${sonido}.mp3`} autoPlay />}
                        </ContainerFlexx>

                        <ContainerFlexx>
                            <FormInputLN5
                                onSubmit={(e) => verificar(e)}
                            >
                                <InputTextLN5 type="text" value={palabra} onChange={(e) => setPalabra(e.target.value)} disabled={disable} />
                            </FormInputLN5>
                        </ContainerFlexx>

                        <BotoneraFixed position='fixed' right='0' bottom='0'
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <img alt='' src={`/img/icono_avanzar_ninos.png`} />
                        </BotoneraFixed>
                </DivFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN5Todos;