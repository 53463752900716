import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';
import M82Bis from './M82Bis';
import M82Practica from './M82Practica';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, PasosSubT, Numeros, PasosT, Pasos, NumCalculo, DivFlexM82, Menos, FlechaP, ResultLeft, ResultRight, Out, DownLeft, DownRight, UpLeft, UpRight, Flecha } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M82 = () => {

    const dispatch = useDispatch();

    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);

    useEffect(() => {
        dispatch(setearEjercicioAction('M82') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-tradicional/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
                <Fragment>
                    {iniciar || fuerzoInicio
                        ?
                        <Fragment>
                            {contador=== 0 &&
                            <div>
                                {contadorSecundario >= 0 &&
                                <FlexFull justify='space-evenly' align='center' height='80vh'>
                                    {/* CALCULO Y CUADRICULA */}
                                    <FlexFull direction='column' justify='center' align='center' width='40%' wm='40%' wt='50%' wd='50%' wlg='50%' height='76vh'>
                                        {/* CALCULO */}
                                        <FlexFull justify='start' align='center' width='100%' height='35%'>
                                            <FlexFull justify='start' align='center' height='fit-content'> 
                                                <NumCalculo color='#D76970'>56</NumCalculo>
                                                <NumCalculo color='#646464'>-</NumCalculo>
                                                <NumCalculo color='#23AAAF'>35</NumCalculo>
                                                <NumCalculo color='#646464'>=</NumCalculo>
                                                {contadorSecundario >= 14 && <NumCalculo color='#646464'>21</NumCalculo>}
                                            </FlexFull>
                                        </FlexFull>
                                        {/* FIN CALCULO */}
                                        {/* CUADRICULA */}
                                        <FlexFull justify='end' align='end' width='100%' height='65%' margin='0px 0px 3vh 0px' mm='0px 0px 3vh 0px' mt='0px 0px 3vh 0px' md='0px 0px 0px 0px' mlg='0px 0px 0px 0px'>
                                            <Flex>
                                                <DivFlexM82>
                                                    <Flex>
                                                        <Flecha>
                                                            {contadorSecundario >= 11 &&
                                                                <FlechaP>↓</FlechaP>
                                                            }
                                                        </Flecha>
                                                        <Flecha>
                                                            {contadorSecundario >= 8 &&
                                                                <FlechaP>↓</FlechaP>
                                                            }
                                                        </Flecha>
                                                    </Flex>
                                                    <Flex>
                                                        <UpRight>
                                                        {contadorSecundario >= 3 &&
                                                        <Numeros>5</Numeros>
                                                        }
                                                        </UpRight>
                                                        <UpLeft>
                                                        {contadorSecundario >= 4 &&
                                                        <Numeros>6</Numeros>
                                                        }
                                                        </UpLeft>
                                                    </Flex>
                                                    <Flex>
                                                        <Out>
                                                            {contadorSecundario >= 7 &&
                                                            <Menos>-</Menos>
                                                            }
                                                        </Out>
                                                        <DownLeft>
                                                        {contadorSecundario >= 5 &&
                                                        <Numeros>3</Numeros>
                                                        }
                                                        </DownLeft>
                                                        <DownRight>
                                                        {contadorSecundario >= 6 &&
                                                        <Numeros>5</Numeros>
                                                        }
                                                        </DownRight>
                                                    </Flex>
                                                    <Flex>
                                                        <ResultLeft>
                                                            {contadorSecundario >= 12 &&
                                                            <Numeros>2</Numeros>
                                                            }
                                                        </ResultLeft>
                                                        <ResultRight>
                                                            {contadorSecundario >= 9 &&
                                                            <Numeros>1</Numeros>
                                                            }
                                                        </ResultRight>
                                                    </Flex>
                                                </DivFlexM82>
                                            </Flex>
                                        </FlexFull>
                                        {/* FIN CUADRICULA */}
                                    </FlexFull>
                                    {/* FIN CALCULO Y CUADRICULA */}
                                    {/* PASOS */}
                                    <FlexFull direction='column' justify='start' align='center' width='30%' height='65vh'>
                                        <PasosT>
                                            PASOS
                                        </PasosT>
                                        <PasosSubT>
                                            RESTA TRADICIONAL
                                        </PasosSubT>
                                        {contadorSecundario >= 1 &&
                                        <Pasos bgcolor='#D76970'>
                                            1- COLOCO LOS NÚMEROS EN SU RESPECTIVO LUGAR
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 2 &&
                                        <Pasos bgcolor='#23AAAF'>
                                            2- EL NÚMERO MAS GRANDE SE COLOCA ARRIBA
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 7 &&
                                        <Pasos bgcolor='#FF9B2A'>
                                            3- COMIENZO RESTANDO LAS UNIDADES
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 10 &&
                                        <Pasos bgcolor='#FFDC73'>
                                            4- AHORA RESTO LAS DECENAS
                                        </Pasos>
                                        }
                                        {contadorSecundario >= 13 &&
                                        <Pasos bgcolor='#655EE8'>
                                            5- ¿CUÁL ES EL RESULTADO?
                                        </Pasos>
                                        }
                                    </FlexFull>
                                    {/* FIN PASOS */}
                                </FlexFull>
                                }
                                <FlexCuz position='fixed' right='0' bottom='0'>
                                    {contadorSecundario === 0 && <BotoneraBottom aumentarSecundario={true} />}
                                    {contadorSecundario > 0 && contadorSecundario < 14 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />}
                                    {contadorSecundario === 14 && <BotoneraBottom reducirSecundario={true} aumentar={true} />}
                                </FlexCuz>
                            </div>
                            }
                            {contador === 1 && <M82Bis mostrar={true} />}
                            {contador === 2 && <M82Practica mostrar={true} />}
                        </Fragment>
                        :
                        <Instrucciones instrucciones={instrucciones} />
                    }
                </Fragment>
        </Fragment>
    );
}
 
export default M82;