import React, { Fragment } from 'react';

import HeaderUno from './Compartidos/HeaderUno';
import HeaderMetodos from '../AuxiliaresPaginas/HeaderMetodos';

import { HrMetodos } from '../Css/StyledHeader';
import { BackgroundMath, Container, Flex, LinksMathBig, LinksMathSmall } from '../Css/StyledMatematica';

const Matematica = () => {
    return ( 
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} n1={"Métodos"} componente={"MATEMATICA"} paciente={true} fw='600' bgcolor="#23AAAF" color="white" width='96vw' />

            <BackgroundMath>
                <Container padding='12vh 0px 0px 0px'>
                    <Flex wrap='wrap'>
                    {/* //color='#000000' */}
                        <LinksMathSmall  fw='400'  bgcolor='#FFFFFF'color='#000000'
                        to={'/escritorio/metodos/matematica/subitizacion'}>
                            Subitización
                        </LinksMathSmall>
                        <LinksMathBig fw='400' bgcolor='#FFFFFF' color='#000000' to={'/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad'}>
                            Estimación y Noción de Cantidad  
                        </LinksMathBig>
                        <LinksMathSmall fw='400'  bgcolor='#FFFFFF' color='#000000' to={'/escritorio/metodos/matematica/conteo'}>
                            Conteo
                        </LinksMathSmall>
                        <LinksMathSmall fw='400'  bgcolor='#FFFFFF' color='#000000' to={'/escritorio/metodos/matematica/base-10'}>
                            Base 10
                        </LinksMathSmall>
                        <LinksMathSmall fw='400'  bgcolor='#FFFFFF'color='#000000' to={'/escritorio/metodos/matematica/calculo'}>
                            Cálculo
                        </LinksMathSmall>
                        <LinksMathBig fw='400' bgcolor='#FFFFFF' color='#000000' to={'/escritorio/metodos/matematica/lectura-y-escritura-de-numeros'}>
                            Lectura y Escritura de Números
                        </LinksMathBig>
                    </Flex>
                </Container>
            </BackgroundMath>
        </Fragment>
    );
}
 
export default Matematica;