import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ImgSubL1 from '../../../Compartidos/ImgSubL1';
//CAMI

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Di la cantidad de puntos que observasen la imagen.';
const miniInstrucciones = 'Di la cantidad de puntos que observasen la imagen.';

const M7 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('M7') );
        dispatch(setearUrlAnteriorAction('matematica/subitizacion/nivel-2/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])
    
    return (
        <Fragment>
            <ImgSubL1 width="250px" wm='270px' wt='290px' wd='310px' wlg='330px' data={data} indexes={indexes} ejercicio="M7" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} estrellas={true} carrito={true} h="20rem" w="20rem" />
        </Fragment>
    );
}
 
export default M7;