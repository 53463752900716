import {
    ALMACENAR,
    ALMACENAR_EXITO,
    ALMACENAR_ERROR
} from '../types';
import clienteAxios from '../config/axios';

/* Almaceno información en la base de datos */
export function almacenarNombreApellidoAction(informacion) {
    return async (dispatch) => {
        dispatch (almacenarNombreApellido());
        try {
            const {payload, config} = informacion;
            const respuesta = await clienteAxios.post('/usuarios/modificonombreapellidoperfilprofesional', payload, config);
            dispatch( almacenarNombreApellidoExito(respuesta.data));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( almacenarNombreApellidoError(error.response.data) );
            }
        }
    }
}

const almacenarNombreApellido = () => ({
    type: ALMACENAR,
    payload: true
});

// si se almacena correctamente y reseteo el state de subitización
const almacenarNombreApellidoExito = (data) => ({
    type: ALMACENAR_EXITO,
    payload: data
});

// si hubo un error
const almacenarNombreApellidoError = (msg) => ({
    type: ALMACENAR_ERROR,
    payload: msg
});

/* Almaceno información en la base de datos */
export function almacenarEmailAction(informacion) {
    return async (dispatch) => {
        dispatch (almacenarEmail());
        try {
            const {payload, config} = informacion;
            const respuesta = await clienteAxios.post('/usuarios/modificoemailperfilprofesional', payload, config);
            dispatch( almacenarEmailExito(respuesta.data));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( almacenarEmailError(error.response.data) );
            }
        }
    }
}

const almacenarEmail = () => ({
    type: ALMACENAR,
    payload: true
});

// si se almacena correctamente y reseteo el state de subitización
const almacenarEmailExito = (data) => ({
    type: ALMACENAR_EXITO,
    payload: data
});

// si hubo un error
const almacenarEmailError = (msg) => ({
    type: ALMACENAR_ERROR,
    payload: msg
});

/* Almaceno información en la base de datos */
export function almacenarRestoAction(informacion) {
    return async (dispatch) => {
        dispatch (almacenarResto());
        try {
            const {payload, config} = informacion;
            const respuesta = await clienteAxios.post('/usuarios/modificorestoperfilprofesional', payload, config);
            dispatch( almacenarRestoExito(respuesta.data));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( almacenarRestoError(error.response.data) );
            }
        }
    }
}

const almacenarResto = () => ({
    type: ALMACENAR,
    payload: true
});

// si se almacena correctamente y reseteo el state de subitización
const almacenarRestoExito = (data) => ({
    type: ALMACENAR_EXITO,
    payload: data
});

// si hubo un error
const almacenarRestoError = (msg) => ({
    type: ALMACENAR_ERROR,
    payload: msg
});

/* Almaceno información en la base de datos */
export function almacenarContraseniaAction(informacion) {
    return async (dispatch) => {
        dispatch (almacenarContrasenia());
        try {
            const {payload, config} = informacion;
            await clienteAxios.post('/usuarios/modificocontraseniaperfilprofesional', payload, config);
            dispatch( almacenarContraseniaExito());
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( almacenarContraseniaError(error.response.data) );
            }
        }
    }
}

const almacenarContrasenia = () => ({
    type: ALMACENAR,
    payload: true
});

// si se almacena correctamente y reseteo el state de subitización
const almacenarContraseniaExito = () => ({
    type: ALMACENAR_EXITO,
});

// si hubo un error
const almacenarContraseniaError = (msg) => ({
    type: ALMACENAR_ERROR,
    payload: msg
});