import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';

import useCloseElement from '../../Hooks/useCloseElement';

// CSS
import {TerminosCondiciones, Titulo } from '../Css/StyledForm';
import {Listados, LinkPaciente, Icon, FlexMenu, Ul, StyledModal, ImgClose, BtnClose,Min, DropdownPerfil, FlexHeaderEjercicio, Contenedor, LineaVertical} from '../Css/StyledHeader'

import { minusculaAction, ayudaLecturaAction, ayudaImagenAction } from '../../actions/configuradorActions';
import { resetearEjercicioAction } from '../../actions/estadoEjercicioActions';
import { resetearConfigUsuarioPacienteAction } from '../../actions/resetStoreActions';
import { Circulo, ContAvanceEj, ContHeaderEjPa, ContPrinAvanceEj, IconoHeaderPaciente } from '../Css/StyledPaciente';

Modal.setAppElement('#root');

const HeaderEjercicios = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const wrapperRef = useRef();
    const [estadoClose] = useCloseElement(wrapperRef);

    const palabra = useSelector((state) => state.configuracion.palabra);
    const rol = useSelector((state) => state.usuario.rol);
    const nombre = useSelector((state) => state.usuario.nombrePaciente);
    const apellido = useSelector((state) => state.usuario.apellidoPaciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const sesionTerminada = useSelector((state) => state.ejercicio.sesionTerminada);
    const urlAnterior = useSelector((state) => state.ejercicio.urlAnterior);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const pacientes = useSelector((state) => state.informacion.lista_pacientes);
    const pacienteAyudaLectura = useSelector((state) => state.usuario.ayuda_lectura);
    const pacienteAyudaLetra = useSelector((state) => state.usuario.ayuda_letra);

    let minuscula;
    let ayuda_lectura;
    
    if(rol !== '894616') {
        const infoPaciente = pacientes?.find(item => item.id_paciente === id_paciente);
        minuscula = infoPaciente.minuscula;
        ayuda_lectura = infoPaciente.ayuda_lectura;
    } else {
        minuscula = pacienteAyudaLetra;
        ayuda_lectura = pacienteAyudaLectura;
    }


    const [open, setOpen] = useState(false);
    const [pacienteMinuscula, setPacienteMinuscula] = useState(minuscula);
    const [ayudaLec, setAyudaLec] = useState(ayuda_lectura);
    const [ayudaImg, setAyudaImg] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [avanceSesion, setAvanceSesion] = useState('');

    const resetConfiguracion = (e) => {
        e.preventDefault();
        dispatch(resetearConfigUsuarioPacienteAction());
        dispatch(resetearEjercicioAction());
        navigate('/escritorio-paciente');
    }

    const resetFinalizar = () => {
        dispatch( resetearEjercicioAction() )
    }

    useEffect(() => {
        dispatch(minusculaAction(pacienteMinuscula))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pacienteMinuscula])

    useEffect(() => {
        dispatch(ayudaLecturaAction({'palabra':palabra,'ayudaLectura':ayudaLec}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ayudaLec])

    useEffect(() => {
        dispatch(ayudaImagenAction(ayudaImg))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ayudaImg])

    useEffect(() => {
        if(sesion.length < 1) {
            return;
        }
        if(sesionTerminada.length > 0) {
            const newList = JSON.parse(JSON.stringify(sesionTerminada));
            const longitud = newList.length;
            const pendiente = newList.filter(item => item.status === "pendiente");
            let completo;
            if(pendiente.length < 1){
                completo = longitud;
                setAvanceSesion(100);
                return;
            } else {
                completo = longitud - pendiente.map(item => item.cantidad/item.cantidad).reduce((acumulador, currValue) => acumulador + currValue);
                setAvanceSesion(completo*100/longitud);
                return;
            }
        } else {
            const newList = JSON.parse(JSON.stringify(sesion));
            const longitud = newList.length;
            const pendiente = newList.filter(item => item.estado_ejercicio === "pendiente");
            let completo;
            if(pendiente.length < 1){
                completo = longitud;
                setAvanceSesion(100);
                return;
            } else {
                completo = longitud - pendiente.map(item => item.cantidad/item.cantidad).reduce((acumulador, currValue) => acumulador + currValue);
                setAvanceSesion(completo*100/longitud);
                return;
            }
        }
    }, [sesion, sesionTerminada])

    useEffect(() => {
        if(estadoClose) {
            setOpen(false);
        }
    }, [estadoClose])

    return (
        <Fragment>
            {rol === '894616'
            ?
            <ContHeaderEjPa>
                <ContPrinAvanceEj $completo={avanceSesion}>
                    {sesion.map(item =>
                        <ContAvanceEj key={item.id}>
                            <Circulo></Circulo>
                        </ContAvanceEj>
                    )}
                </ContPrinAvanceEj>
                <FlexMenu margin="0 0 0 0.6rem">
                    {props.ayuImagen ? 
                        <Contenedor margin="0 0.1rem 0 0.1rem">
                            {ayudaImg ? <Icon height="2rem" src={`/img/icono_ayudaimagen_activo.png`} onClick={() => setAyudaImg(false)}/> : <Icon height="2rem" src={`/img/icono_ayudaimagen_desactivo.png`} onClick={() => setAyudaImg(true)}/>}
                        </Contenedor> 
                        : null
                    }
                </FlexMenu>
                <IconoHeaderPaciente onClick={e => resetConfiguracion(e)} >
                        <Icon src={`/img/paciente.png`} alt="" margin= "0px 5px"/>
                </IconoHeaderPaciente>
            </ContHeaderEjPa>
            :

            <FlexHeaderEjercicio>
                <FlexMenu margin="0 0 0 0.6rem">
                    {props.minMay ? 
                        <Contenedor margin="0 0.1rem 0 0.1rem">
                            {pacienteMinuscula ? <Icon height="1rem" src={`/img/icono_minuscula.png`} onClick={() => setPacienteMinuscula(false)} /> : <Icon height="1rem" src={`/img/icono_mayuscula.png`} onClick={() => setPacienteMinuscula(true)} />}
                            {props.ayuLectura ? <LineaVertical></LineaVertical> : props.ayuImagen ? <LineaVertical></LineaVertical> : null}
                        </Contenedor> 
                        : null
                    }
                    {props.ayuLectura ? 
                        <Contenedor margin="0 0.1rem 0 0.1rem">
                            {ayudaLec ? <Icon height="1rem" src={`/img/icono_ayudavocal_activo.png`} onClick={() => setAyudaLec(false)} /> : <Icon height="1rem" src={`/img/icono_ayudavocal_desactivo.png`} onClick={() => setAyudaLec(true)}/>}
                            {props.ayuImagen ? <LineaVertical></LineaVertical> : null}
                        </Contenedor> 
                        : null
                    }
                    {props.ayuImagen ? 
                        <Contenedor margin="0 0.1rem 0 0.1rem">
                            {ayudaImg ? <Icon height="2rem" src={`/img/icono_ayudaimagen_activo.png`} onClick={() => setAyudaImg(false)}/> : <Icon height="2rem" src={`/img/icono_ayudaimagen_desactivo.png`} onClick={() => setAyudaImg(true)}/>}
                        </Contenedor> 
                        : null
                    }
                </FlexMenu>
                <FlexMenu justify="flex-end" margin="0 0.6rem 0 0">
                    <LinkPaciente onClick={resetFinalizar} to={"/escritorio/pacientes/paciente"}>
                        <Icon src={`/img/paciente.png`} alt="" margin= "0px 5px"/>
                        <p>{apellido}, {nombre}</p>
                    </LinkPaciente>
                    {/* <Icon src={`/img/iconoNotas.png`} alt="" margin= "0px 10px"/> */}
                    <Link onClick={resetFinalizar} to={`/escritorio/metodos/${urlAnterior}`} >
                        <Icon src={`/img/LogoAtras.png`} alt="" margin= "0px 10px"/>
                    </Link>
                    {/* <TerminosCondiciones onClick={() => setModalIsOpen(true)} > <Icon src={`/img/IconoSemaforo.png`} alt="" margin= "0px 10px 0px 0px"/></TerminosCondiciones> */}
                    {/* <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                        <StyledModal>
                            <Titulo> <img src={`/img/semaforo.png`} alt="semaforo"/> </Titulo>
                            <div>
                                <BtnClose onClick={() => setModalIsOpen(false)}><ImgClose src={`/img/cerrarBtn.png`} alt="cerrar"/></BtnClose>
                            </div>
                        </StyledModal>
                    </Modal> */}
                </FlexMenu>
                {/* <FlexMenu justify="flex-start">
                    {props.reloj ? <Icon src={`/img/reloj.png`} alt="" margin= "0px 10px"/> : null}
                    {props.notas ? <Icon src={`/img/iconoEstadisticas.png`} alt="" /> : null}
                </FlexMenu> */}

            </FlexHeaderEjercicio>
            }

        </Fragment>
        
        
    );
}
 
export default HeaderEjercicios;