import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import BotoneraBottom from '../Compartidos/BotoneraBottom';
import useColorHelp from '../../../Hooks/useColorHelp';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { zeroContadorConCorreccionAction, aumentarContadorConCorreccionAction } from '../../../actions/botoneraEjercicioActions';
import { iniciarEjercicioAction, nuevaRondaAction } from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, BotoneraFixed, DivFlex, BtnSilabaLN13, BtnColor, BtnTiempo, Img } from '../../Css/MetodosCSS/StyledLectoEscritura';

const LN13Todos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, instrucciones, miniInstrucciones } = props;

    const [comparar, setComparar] = useState([]);
    const [tiempoInicial, setTiempoInicial] = useState(0);
    const [tiempoInicialDos, setTiempoInicialDos] = useState(0);
    const [primerTiempo, setPrimerTiempo] = useState('');
    const [segundoTiempo, setSegundoTiempo] = useState('');
    const [ronda, setRonda] = useState(1);
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [arrayColorUno, transformarColorUno] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorConCorreccion = useSelector(state => state.ejercicio.contadorConCorreccion);
    const error = useSelector(state => state.ejercicio.error);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista = useSelector(state => state.ejercicio.lista);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const resetear = (e) => {
        e.preventDefault();
        dispatch(zeroContadorConCorreccionAction());
        setRonda(ronda + 1);
        setAyuVisual(false);
        setAyuVisualSolicitada(false);
        dispatch(nuevaRondaAction());
        dispatch(iniciarEjercicioAction());
    }

    const finalizarEjercicio = (e) => {
        e.preventDefault();
        navigate("/escritorio/metodos/finalizar-ejercicio");
    }

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
        }
    }

    useEffect(() => {
        if(data === undefined || contadorConCorreccion === indexes) {
            return;
        }
        if(ronda === 1){
            const newList = JSON.parse(JSON.stringify(data[contadorConCorreccion]));
            setComparar(newList)
        }
        if(ronda === 2){
            const newList = JSON.parse(JSON.stringify(data[contadorConCorreccion]));
            setComparar(newList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorConCorreccion, data, ronda]);

    useEffect(() => {
        if (ronda === 1) {
            const inicial = new Date();
            setTiempoInicial(inicial);
        }
        if (ronda === 2) {
            const inicialDos = new Date();
            setTiempoInicialDos(inicialDos);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar]);

    useEffect(() => {
        if (ronda === 1 && finalizar === true) {
            const final = new Date();
            const segundos = Math.round(date.subtract(final, tiempoInicial).toSeconds());
            const minutos = Math.floor(segundos / 60);
            const segundos2 = segundos - (Math.floor(segundos / 60) * 60)
            setPrimerTiempo(`${minutos}' ${segundos2}"`);
        }
        if (ronda === 2 && finalizar === true) {
            const final = new Date();
            const segundos = Math.round(date.subtract(final, tiempoInicialDos).toSeconds());
            const minutos = Math.floor(segundos / 60);
            const segundos2 = segundos - (Math.floor(segundos / 60) * 60)
            setSegundoTiempo(`${minutos}' ${segundos2}"`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar.length === 0 ){
            return;
        }
        transformarColorUno(comparar.palabra_1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, comparar, ayuda_lectura])

    useEffect(() => {
        if(iniciar === false && contadorConCorreccion === indexes ){
            return;
        }else if(iniciar === false && contadorConCorreccion === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorConCorreccion]);

    useEffect(() => {
        if(finalizar === true && contadorConCorreccion === indexes -1 ){
            dispatch(aumentarContadorConCorreccionAction());
        }
        if(finalizar && contadorConCorreccion === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);
            if(ronda === 1) {
                const informacion = {
                    config,
                    payload: {
                    'ejercicio': ejercicio,
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'ronda': ronda,
                    'ayuda_visual': ayuVisualSolicitada,
                    sesion
                    }
                }
                dispatch( almacenarAction(informacion) );
                setContarTiempo([]);
            } else {
                const informacion = {
                    config,
                    payload: {
                    'ejercicio': ejercicio,
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'ronda': ronda,
                    'ayuda_visual': ayuVisualSolicitada,
                    sesion
                    }
                }
                dispatch( almacenarAction(informacion) );
                setContarTiempo([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorConCorreccion]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuVisual={true} ayuLectura={true} />
            {finalizar ?
            
            <ContainerFlexx margin="auto">
                <ContainerFlexx display="flex" direction="column" align="center" justify="center">
                    <Img width="220px" src={`/img/cronometro.png`} />

                    <ContainerFlexx display="flex" justify="space-evenly">
                        <BtnTiempo margin="1rem 2rem" width="4rem" height="2rem" bg="rgba(250, 250, 250, 1)" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" border="none" radius="10px" font="1rem">
                            {primerTiempo === '' ? null : primerTiempo}
                        </BtnTiempo>
                        <BtnTiempo margin="1rem 2rem" width="4rem" height="2rem" bg="rgba(250, 250, 250, 1)" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" border="none" radius="10px" font="1rem">
                            {segundoTiempo === '' ? null : segundoTiempo}
                        </BtnTiempo>
                    </ContainerFlexx>
                </ContainerFlexx>

                <ContainerFlexx display="flex" direction="column"  align="center">
                    {ronda === 1 ?
                        <ContainerFlexx direction="column">
                            <BtnTiempo m="0 0 1rem 0"
                                onClick={(e) => resetear(e)}
                            >
                                Repetir
                            </BtnTiempo>
                            <BtnTiempo
                                onClick={(e) => finalizarEjercicio(e)}
                            >
                                Finalizar
                            </BtnTiempo>
                        </ContainerFlexx>
                        : 
                        <BtnTiempo
                            onClick={(e) => finalizarEjercicio(e)}
                        >
                            Finalizar
                        </BtnTiempo>
                    }
                </ContainerFlexx>
            </ContainerFlexx>

            :
            <ContainerFlexx height="100%" width="100%">
                {iniciar
                    ?
                    <ContainerFlexx display="flex" direction="column" height="100%" width="100%">
                        <BotoneraFixed position='fixed' right='0' top='0' margin='5.5rem 2rem 0 0'>
                            <ContainerFlexx height="5vh" margin='0 0 0 0.3rem'>
                                <BtnColor bg="transparent" border="none"
                                    onClick={(e) => setearAyuVisual(e)}
                                >   
                                    {ayuVisual ? 
                                    <Img width="45px" src={`/img/ayuda_clickeado.png`} />
                                    :
                                    <Img width="45px" src={`/img/icono_ayuda.png`} />
                                    }
                                    
                                </BtnColor>
                            </ContainerFlexx>
                        </BotoneraFixed>
                        <DivFlex margin="auto" wrap='wrap' justify='space-around' align='space-around' direction='column-reverse' directM='column-reverse' directT='column-reverse' directD='row' directLG='row'>
                            <ContainerFlexx  direction="column">
                                <Img w="130px" wm='130px' wt='140px' wd='200px' wlg='215px' src={`/img/cronometro.png`} />
    
                                <DivFlex width='100%' justify='center' align='center'>
                                    <BtnTiempo m='0 1rem 0 0'>
                                        {primerTiempo === '' ? null : primerTiempo}
                                    </BtnTiempo>
                                    <BtnTiempo m='0 0 0 1rem'>
                                        {segundoTiempo === '' ? null : segundoTiempo}
                                    </BtnTiempo>
                                </DivFlex>
                            </ContainerFlexx>
                            <ContainerFlexx margin="0 0 1.5rem 0">
                                <ContainerFlexx>
                                    {ayuVisual && comparar.imagen_url_1 !== '' ? <Img src={`/img/${comparar.imagen_url_1}.png`} w="70px" wm='70px' wt='80px' wd='130px' wlg='130px' h="80px" hm='80px' ht='85px' hd='130px' hlg='130px' /> : null}
                                </ContainerFlexx>
                                {comparar.palabra_1 === '' || arrayColorUno.length === 0 ? null :
                                <BtnSilabaLN13 width="170px" height="130px" widthmob="140px" heightmob="100px" font="6rem" fontmob="4rem" $minMay={minuscula}>
                                    {arrayColorUno.map((item, i) =>
                                        <span
                                            key={i}
                                        >
                                            {item.map((itemInterior, i) =>
                                                <span style={{color: itemInterior.color}}
                                                key={i}
                                                >
                                                    {itemInterior.letra}
                                                </span>
                                            )}
                                        </span>
                                    )}
                                </BtnSilabaLN13>
                                }
                                <ContainerFlexx>
                                    {ayuVisual && comparar.imagen_url_2 !== '' ? <Img src={`/img/${comparar.imagen_url_2}.png`} w="70px" wm='70px' wt='80px' wd='130px' wlg='130px' h="80px" hm='80px' ht='85px' hd='130px' hlg='130px' /> : null}
                                </ContainerFlexx>
                            </ContainerFlexx>
                        </DivFlex>
                        <ContainerFlexx display="flex" width="95%" justify="space-between" justifyself="end">
                            <ContainerFlexx>
                                <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                            </ContainerFlexx>
                            <ContainerFlexx>
                                <BotoneraBottom aumentarConCorreccion={true} error={true} pausar={true} cantidad={indexes} ronda={true}/>
                            </ContainerFlexx>
                        </ContainerFlexx>
                    </ContainerFlexx>
                    :
                    <Instrucciones instrucciones={instrucciones} />
                }
            </ContainerFlexx>
            }
        </Fragment>
    );
}

export default LN13Todos;