const grupoInstruccionExplicita = [
    {id:'1', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN1'},
    {id:'2', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN2'},
    {id:'3', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN3'},
    {id:'4', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN4'},
    {id:'5', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN5'},
    {id:'6', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN6'},
    {id:'7', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN7'},
    {id:'8', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN8'},
    {id:'9', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN9'},
];

const grupoInstruccionSinfones = [
    {id:'1', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN1IS'},
    {id:'2', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN2IS'},
    {id:'3', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN3IS'},
    {id:'4', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN4IS'},
    {id:'5', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN5IS'},
    {id:'6', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN6IS'},
    {id:'7', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN7IS'},
    {id:'8', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN8IS'},
    {id:'9', url:'/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN9IS'},
];

export {
    grupoInstruccionExplicita,
    grupoInstruccionSinfones
   }