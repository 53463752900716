import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, ContInst, BtnLetraLN20, ContainerEjercicio } from '../../../../Css/MetodosCSS/StyledLectoEscritura';

const instrucciones = 'Has click sobre los recuadros que tienen palabras distintas';
const miniInstrucciones = 'Has click sobre los recuadros que tienen palabras distintas';
const colours = ["#23AAAF", "#655EE8", "#FDB62C", "#F3D946", "#D05F67"];

const LN20 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [randomPalabra, setRandomPalabra] = useState([]);
    const [count, setCount] = useState(1);
    const [countInterno, setCountInterno] = useState(1);
    const [indexes, setIndexes] = useState();
    const [indexesInterno, setIndexesInterno] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, ltr_1, ltr_2, index) => {
        e.preventDefault();
        if(ltr_1.toUpperCase() === ltr_2.toUpperCase()) {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
        } else {
            console.log('bien')
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            const newList = JSON.parse(JSON.stringify(randomPalabra));
            newList[index].disable = true;
            setRandomPalabra(newList);
            if(countInterno < indexesInterno/2) {
                setCountInterno(countInterno+1);
            } else if(count < indexes){
                setCountInterno(1);
                setCount(count + 1);
                } else {
                    setTimeout(() => {
                        setUltimoTiempo(true);
                        dispatch(finalizarEjercicioAction());
                    }, [1000])
                }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LN20') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/ejercicios/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        const lista = [newList[0],newList[1],newList[2],newList[3],newList[4],newList[5],newList[18],newList[19],newList[20],newList[21],newList[22],newList[23],newList[6],newList[7],newList[8],newList[9],newList[10],newList[11],newList[24],newList[25],newList[26],newList[27],newList[28],newList[29],newList[12],newList[13],newList[14],newList[15],newList[16],newList[17],newList[30],newList[31],newList[32],newList[33],newList[34],newList[35]];
        const listaUno = lista.filter((item, i) => i < 12).map(item => ({palabra_1:item.palabra_1, palabra_2:item.palabra_2, disable: false}));
        const listaDos = lista.filter((item, i) => i > 11 && i < 24).map(item => ({palabra_1:item.palabra_1, palabra_2:item.palabra_2, disable: false}));
        const listaTres = lista.filter((item, i) => i > 23).map(item => ({palabra_1:item.palabra_1, palabra_2:item.palabra_2, disable: false}));
        const listadoFinal = [listaUno, listaDos, listaTres];
        setComparar(listadoFinal);
        setIndexes(Object.keys(listadoFinal).length);
    }, [informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LN20",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined || comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const newShuffleList = shuffle(newList);
        setRandomPalabra(newShuffleList);
        setIndexesInterno(Object.keys(newShuffleList).length);
    }, [comparar, count]);

    return (
        <Fragment>
        <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
        {iniciar
            ?
            <ContainerFlexx height="85vh">
                <ContainerFlexx width="98vw" wm='98vw' wt='85vw' wd='70vw' wlg='70vw' height="65vh" wrap="wrap">
                {randomPalabra.map((item, i) => 
                    <ContainerEjercicio  display="flex" direction="column"
                        key={i}
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}
                    >
                        <BtnLetraLN20 disabledColor={colours[Math.floor(Math.random() * colours.length)]}
                            disabled={item.disable}
                            onClick={(e) => verificar(e, item.palabra_1, item.palabra_2, i)}
                        >
                            {item.palabra_1} <br></br>
                            {item.palabra_2}
                        </BtnLetraLN20>
                    </ContainerEjercicio>
                    )}
                </ContainerFlexx>
                <ContInst>
                    <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                </ContInst>
            </ContainerFlexx>
            :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default LN20;