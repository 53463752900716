import styled, {css, keyframes} from 'styled-components';

// QUERYS
const media = {
    min: '@media (min-width:505px) and (max-width:699px)',
    tablet: '@media (min-width:700px) and (max-width:996px)',
    desktop: '@media(min-width:996px) and (max-width:1200px)',
    desktopLg: '@media (min-width:1200px)'
}


// CONTAINER FLEX - EL QUE ENVUELVE TODO
export const ContainerFlexx = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    // width: 100%;
    justify-content: ${props => props.justify};
    height: ${props => props.height};
    max-width: ${props => props.maxw};
    min-width: ${props => props.minw};
    max-height: ${props => props.maxh};
    min-height: ${props => props.minh};
    background-color: ${props => props.color};
    position: ${props => props.position};
    left: ${props => props.left};
    bottom: ${props => props.bottom};
    border: ${props => props.border};
    border-bottom: ${props => props.bordb};
    border-radius: ${props => props.bradius};
    opacity: ${props => props.op};
    box-shadow: ${props => props.bs};
    overflow: ${props => props.of};
    z-index: ${props => props.z};

    ${media.min}{
        width: ${props => props.wm};
        height: ${props => props.hm};
        margin: ${props => props.mm};
        max-width: ${props => props.maxwm};
        min-width: ${props => props.minwm};
        max-height: ${props => props.maxhm};
        min-height: ${props => props.minhm};
        border: ${props => props.borderm};
        flex-direction: ${props => props.directM};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        width: ${props => props.wt};
        height: ${props => props.ht};
        max-width: ${props => props.maxwt};
        min-width: ${props => props.minwt};
        max-height: ${props => props.maxht};
        min-height: ${props => props.minht};
        border: ${props => props.bordert};
        flex-direction: ${props => props.directT};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        width: ${props => props.wd};
        height: ${props => props.hd};
        max-width: ${props => props.maxwd};
        min-width: ${props => props.minwd};
        max-height: ${props => props.maxhd};
        min-height: ${props => props.minhd};
        border: ${props => props.borderd};
        flex-direction: ${props => props.directD};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        height: ${props => props.hlg};
        max-width: ${props => props.maxwlg};
        min-width: ${props => props.minwlg};
        max-height: ${props => props.maxhlg};
        min-height: ${props => props.minhlg};
        border: ${props => props.borderlg};
        flex-direction: ${props => props.directLG};
        }

        ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
    `}
`

// DIV FLEX - PARA USAR JUSTIFY Y ALIGN 
export const DivFlex = styled.div`
    display: flex; 
    justify-content: ${props => props.justify}; 
    align-items: ${props => props.align}; 
    align-content: ${props => props.alignC}; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    border: ${props => props.border};
    border-radius: ${props => props.bradius};
    box-shadow: ${props => props.bs};
    text-transform: ${props => props.transf};

    ${media.min}{
        width: ${props => props.wm};
        height: ${props => props.hm};
        margin: ${props => props.mm};
        border: ${props => props.borderm};
        justify-content: ${props => props.jm};
        flex-direction: ${props => props.directM};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        width: ${props => props.wt};
        height: ${props => props.ht};
        border: ${props => props.bordert};
        justify-content: ${props => props.jt};
        flex-direction: ${props => props.directT};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        width: ${props => props.wd};
        height: ${props => props.hd};
        border: ${props => props.borderd};
        justify-content: ${props => props.jd};
        flex-direction: ${props => props.directD};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        height: ${props => props.hlg};
        border: ${props => props.borderlg};
        justify-content: ${props => props.jlg};
        flex-direction: ${props => props.directLG};
        }

        ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
    `}
`

// BOTTONERA FLEX FIXED
export const BotoneraFixed = styled.div`
    display: flex; 
    justify-content: ${props => props.justify}; 
    align-items: center; 
    flex-wrap: ${props => props.wrap};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: ${props => props.width};
    height: ${props => props.height};
    position: ${props => props.position};
    left: ${props => props.left};
    right: ${props => props.right};
    bottom: ${props => props.bottom};
    top: ${props => props.top};
    border: ${props => props.br};
`

// BOTON AVANZAR
export const BtnAvanzar = styled.button`
width: 55px;
height: 40px;
// margin-right: 3rem;
border: none;
background-color: transparent;

img {
    width: 50px;
    height: 40px;
    // margin-right: 2.5rem;
    cursor: pointer;  
    // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

${media.tablet} {
    img {
        width: 40px;
        height: 30px;
        // margin-right: 1rem;
    }
}
`

// IMÁGENES
export const Img = styled.img`
    width: ${props => props.w};
    height: ${props => props.h};
    margin: ${props => props.m};
    background-color:  ${props => props.bg};
    border:  ${props => props.border};
    border-radius:  ${props => props.br};
    box-shadow: ${(props) => props.boxs};

    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `} 

    ${media.min}{
        width: ${props => props.wm};
        height: ${props => props.hm};
        margin: ${props => props.mm};
        }
    ${media.tablet}{
        margin: ${props => props.mt};
        width: ${props => props.wt};
        height: ${props => props.ht};
        }
    ${media.desktop}{
        margin: ${props => props.md};
        width: ${props => props.wd};
        height: ${props => props.hd};
        }
    ${media.desktopLg}{
        margin: ${props => props.mlg};
        width: ${props => props.wlg};
        height: ${props => props.hlg};
        }

`

// -------------- LN1TODOS --------------
// CUADRO LETRA
export const CuadradoLetra = styled.div `
 background-color: #FAFAFA;
 box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
 color: #646464;
 padding: 35px 75px;
//  margin-top: 40px;
 border-radius: 15px;
 position: relative;
 font-size: 13rem;
 text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
 font-family: 'Nunito';
 font-style: normal;
 font-weight: 800;
 
 ${media.min}{
    width: ${props => props.wm};
    height: ${props => props.hm};
    margin: ${props => props.mm};
    padding: 35px 75px;
    font-size: 14rem;
    }
${media.tablet}{
    margin: ${props => props.mt};
    width: ${props => props.wt};
    height: ${props => props.ht};
    padding: 35px 75px;
    font-size: 15rem;
    
    }
${media.desktop}{
    margin: ${props => props.md};
    width: ${props => props.wd};
    height: ${props => props.hd};
    padding: 35px 75px;
    font-size: 15rem;
    }
${media.desktopLg}{
    margin: ${props => props.mlg};
    width: ${props => props.wlg};
    height: ${props => props.hlg};
    padding: 35px 75px;
    font-size: 17rem;
    }

`

// SILABAS GRANDES
export const SilabasGrandes = styled.div`
// display: ${(props) => props.display};
// flex-direction: ${(props) => props.direction};
// align-items: ${(props) => props.align};
// justify-content: ${(props) => props.justify};
display: flex;
align-items: center;
justify-content: center;
// padding: 50px 100px;
margin: ${(props) => props.m};
width: 25vw;
height: 20vh;
background-color: ${(props) => props.bg};
// box-shadow: ${(props) => props.shadow};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
font-family: 'Nunito';
font-style: normal;
font-weight: 800;
color: ${(props) => props.color ? props.color : '#646464'};
text-align: center;
font-size: 8rem;
border: ${(props) => props.border};
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    width: 140px;
    height: 140px;
    font-size: 5rem;
    }
${media.tablet}{
    width: 160px;
    height: 160px;
    font-size: 6rem;
    
    }
${media.desktop}{
    width: 170px;
    height: 170px;
    font-size: 7.5rem;
    }
${media.desktopLg}{
    width: 180px;
    height: 180px;
    font-size: 8rem;
    }
`

// SILABAS CHICAS
export const SilabasChicas = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: ${(props) => props.width};
height: 9.5vh;
margin: 5px 0px 5px 0px;
// box-shadow: ${(props) => props.shadow};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 15px;
font-size: 8vw;
font-weight: 800;
font-style: normal;
font-family: 'Nunito';
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
color: ${(props) => props.color};
border: ${(props) => props.border};
background-color: ${(props) => props.bg};

${media.min}{
    height: 9.5vh;
    font-size: 8vw;
    margin: 5px 0px 5px 0px;
    }
${media.tablet}{
    height: 11vh;
    font-size: 7vw;
    margin: 5px 0px 5px 0px;
    }
${media.desktop}{
    margin: 5px 0px 5px 0px;
    height: 12vh;
    font-size: 6vw;
    }
${media.desktopLg}{
    margin: 5px 0px 5px 0px;
    height: 13vh;
    font-size: 6vw;
    }
`


// -------------- FIN LN1TODOS --------------

// -------------- LN2TODOS --------------
export const BtnAsignar = styled.button`
width: 105px;
height: 105px;
border:none;
margin: ${(props) => props.m};
background: #FAFAFA;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
font-family: nunito;
font-size: 3.5rem;
color: ${(props) => props.color};
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
display: ${(props) => props.display};
align-items: ${(props) => props.align};
justify-content: ${(props) => props.justify};

${media.min}{
    width: 95px;
    height: 95px;
    font-size: 12vw;
    }
${media.tablet}{
    width: 160px;
    height: 160px;
    font-size: 13vw;
    }
${media.desktop}{
    width: 170px;
    height: 170px;
    font-size: 12vw;
    }
${media.desktopLg}{
    width: 180px;
    height: 180px;
    font-size: 11vw;
    }

`

export const FormInput = styled.form `
margin: ${(props) => props.margin};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
border-radius: ${(props) => props.radius};
font-size: ${(props) => props.font};
text-transform:  ${(props) => props.uppercase};
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
// border: none;

Input {
    border:none;
    width: ${(props) => props.widthmob}; 
    height: ${(props) => props.heightmob}; 
    margin: ${(props) => props.marginMob};
    background: #FAFAFA;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: ${(props) => props.radius};
} 

${media.tablet} {
  width: ${(props) => props.widthmob}; 
   height: ${(props) => props.heightmob}; 
   margin: ${(props) => props.marginMob};
}
`

export const InputText = styled.input `
font-size:  2rem;
border-radius: 5px;
margin:  ${(props) => props.margin};
text-transform:  ${(props) => props.uppercase};
font-family: nunito ;
width: 160px; 
height: ${(props) => props.height}; 
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
font-weight: ${(props) => props.weigth}; 
border: none;
text-align:center ;
color: ${(props) => props.color};
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
outline: none;

${media.min}{
    width: 180px;
    }
// ${media.tablet}{
//     width: 160px;
//     }
// ${media.desktop}{
//     width: 170px;
//     }
// ${media.desktopLg}{
//     width: 180px;
//     }
`

export const BtnAbecedario = styled.button `
    width: 31px;
    height: 30px;
    text-align: center ;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: none;
    color: ${(props) => props.color};
    font-family: nunito;
    font-weight: bold;
    cursor: pointer;
    margin: 2px 2px;
    font-size: 1.2rem;
    text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

    &:focus{
        color: #FFFFFF;
        background-color: rgba(35, 170, 175, 1);
    }
    
    ${media.min}{
        width: 35px;
        height: 35px;
        font-size: 1.2rem;
        }
    ${media.tablet}{
        width: 38px;
        height: 38px;
        font-size: 1.4rem;
        }
    ${media.desktop}{
        width: 38px;
        height: 38px;
        font-size: 1.4rem;
        }
    ${media.desktopLg}{
        width: 40px;
        height: 40px;
        font-size: 1.6rem;
        }
`

export const Letraa = styled.p `
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: ${(props) => props.color};
    font-family: nunito;
    font-weight: bold;
    cursor: pointer;
    margin: 0px;
    font-size: 11vw;
    text-transform: ${props => props.transf};

    ${media.min}{
        font-size: 12vw;
        }
    ${media.tablet}{
        font-size: 13vw;
        
        }
    ${media.desktop}{
        font-size: 12vw;
        }
    ${media.desktopLg}{
        font-size: 11vw;
        }
`

export const BtnSilaba = styled.button`
    width: 170px;
    height: 130px;
    font-size: 6rem;
    font-family: nunito;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    background: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-weight: bold;
    // border: ${(props) => props.border};
    border: none;
    margin: 0px 1vw;
    color: #646464;
    cursor: pointer;
    padding:  ${(props) => props.padding};
    text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
    
    ${media.tablet} {
        padding:  ${(props) => props.paddingMob};
        width: ${(props) => props.widthmob};
    height: ${(props) => props.heightmob};
    font-size: ${(props) => props.fontmob};
    }
    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `} 
`

export const BtnImg = styled.button`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 112px;
    height: 105px;
    margin: 0px 1vw 0px 1vw;
    background-color: white;
    border: none;
`



// ANIMACIONES
export const ShakeImg = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`
export const ZoomIn = keyframes`
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
`




// -------------- FIN LN2TODOS --------------


// -------------- LN3TODOS --------------

export const BtnSilabaLN3 = styled.button`
    width: 114rem;
    height: 90px;
    font-size: 3rem;
    font-family: nunito;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    background: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-weight: bold;
    // border: ${(props) => props.border};
    border: none;
    margin: 0px 1vw;
    color: #646464;
    cursor: pointer;
    padding:  ${(props) => props.padding};
    text-transform: uppercase;
    
    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `} 

    ${media.min}{
        width: 7rem;
        height: 90px;
        font-size: 3rem;
        }
    ${media.tablet}{
        width: 7rem;
        height: 120px;
        font-size: 3rem;
        
        }
    ${media.desktop}{
        width: 14rem;
        height: 130px;
        font-size: 6rem;
        }
    ${media.desktopLg}{
        width: 14rem;
        height: 130px;
        font-size: 6rem;
        }
`

export const BtnSonido = styled.button`
  border: none;
  width: 140px;
  height: 140px;
  background-color: transparent;


//  img {
//     width: 140px;
//     height: 140px;
//     cursor: pointer;
//     background-color: white;
//  }
`

// -------------- FIN LN3TODOS --------------


// -------------- LN5TODOS --------------

export const BtnSonidoLN5 = styled.button`
  border: none;
  width: 140px;
  height: 140px;
  background-color: transparent;
`

export const FormInputLN5 = styled.form `
margin: ${(props) => props.margin};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
border-radius: ${(props) => props.radius};
font-size: ${(props) => props.font};
text-transform:  ${(props) => props.uppercase};
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
border: none;

Input {
    border:none;
    width: ${(props) => props.widthmob}; 
    height: ${(props) => props.heightmob}; 
    margin: ${(props) => props.marginMob};
    background: #FAFAFA;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: ${(props) => props.radius};
    } 
`

export const InputTextLN5 = styled.input `
font-size:  2rem;
border-radius: 5px;
margin:  ${(props) => props.margin};
text-transform: uppercase;
font-family: nunito ;
width: 280px; 
height: ${(props) => props.height}; 
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
font-weight: ${(props) => props.weigth}; 
border: none;
border-radius: 5px;
text-align:center ;
color: ${(props) => props.color};
outline: none;

${media.min}{
    width: 280px;
    }
${media.tablet}{
    width: 300px;
    }
${media.desktop}{
    width: 400px;
    }
${media.desktopLg}{
    width: 500px;
    }
`


// -------------- FIN LN5TODOS --------------

// -------------- LN6TODOS --------------

export const LetraaLN6 = styled.p `
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: ${(props) => props.color};
    font-family: nunito;
    font-weight: bold;
    cursor: pointer;
    margin: 0px;
    font-size: 10vw;
    text-transform: ${props => props.transf};

    ${media.min}{
        font-size: 11vw;
        }
    ${media.tablet}{
        font-size: 10vw;
        
        }
    ${media.desktop}{
        font-size: 9vw;
        }
    ${media.desktopLg}{
        font-size: 8vw;
        }
`
// -------------- FIN LN6TODOS --------------

// -------------- LN7TODOS --------------

export const BtnTiempo = styled.button`
background-color: rgba(250, 250, 250, 1);
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: 4.5rem; 
height: 2rem; 
margin:  ${(props) => props.m}; 
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
flex-wrap: ${(props) => props.wrap};
border: none;
color: ${(props) => props.color};
border-radius: 10px;
font-size:  1rem;
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  ${(props) => props.weight};
cursor: pointer;
`

export const BtnSilabaLN7 = styled.button`
    width: 140px;
    height: 110px;
    font-size: 5rem;
    font-family: nunito;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    background: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-weight: bold;
    // border: ${(props) => props.border};
    border: none;
    margin: 0px 1vw;
    color: #646464;
    cursor: pointer;
    padding:  ${(props) => props.padding};
    text-transform: uppercase;
    
    ${media.min}{
        width: 140px;
        height: 110px;
        font-size: 5rem;
        }
    ${media.tablet}{
        width: 170px;
        height: 130px;
        }
    ${media.desktop}{
        width: 170px;
        height: 130px;
        }
    ${media.desktopLg}{
        width: 170px;
        height: 130px;
        }
`

export const LetraaLN7 = styled.p `
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: ${(props) => props.color};
    font-family: nunito;
    font-weight: bold;
    cursor: pointer;
    margin: 0px;
    font-size: 5rem;
    text-transform: ${props => props.transf};

    ${media.min}{
        font-size: 5rem;
        }
    ${media.tablet}{
        font-size: 6rem;
        }
    ${media.desktop}{
        font-size: 6rem;
        }
    ${media.desktopLg}{
        font-size: 6rem;
        }
`



// -------------- FIN LN7TODOS --------------


// ------------- LN8TODOS --------------

export const BtnMemo = styled.button `
width: 15rem;
height: 105px;
margin: 1rem;
background-color: transparent ;
border: none;
// border: ${(props) => props.border};
border-radius: 30px;

${media.min}{
    width: 15rem;
    height: 105px;
    margin: 1rem;
    }
${media.tablet}{
    width: 15rem;
    height: 110px;
    margin: 1rem;
    }
${media.desktop}{
    width: 15rem;
    height: 120px;
    margin: 1rem;
    }
${media.desktopLg}{
    width: 15rem;
    height: 120px;
    margin: 1rem;
    }

`

export const BtnMemoFront = styled.div `
width: 150px;
height: 105px;
margin: 0.5rem;
background-color: ${(props) => props.bg};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
display: flex ;
justify-content: center ;
align-items: center ;
border: ${(props) => props.border};

${media.min}{
    width: 150px;
    height: 105px;
    margin: 0.5rem;
    }
${media.tablet}{
    width: 150px;
    height: 110px;
    margin: 0.5rem;
    }
${media.desktop}{
    width: 160px;
    height: 120px;
    margin: 1rem;
    }
${media.desktopLg}{
    width: 160px;
    height: 120px;
    margin: 1rem;
    }
`

export const BtnMemoBack = styled.div `
display: flex ;
justify-content: center ;
// border: 1px solid rgba(69, 178, 182, 1);
border: ${(props) => props.border};
border-radius: 10px;
width: 15rem;
height: 105px;
margin: 0.5rem;


${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
    `}

    ${media.min}{
        width: 15rem;
        height: 105px;
        margin: 0.5rem;
        }
    ${media.tablet}{
        width: 15rem;
        height: 110px;
        margin: 0.5rem;
        }
    ${media.desktop}{
        width: 15rem;
        height: 120px;
        margin: 1rem;
        }
    ${media.desktopLg}{
        width: 15rem;
        height: 120px;
        margin: 1rem;
        }
`

export const MemoPalabra = styled.div`
  color: #646464;
  font-family: nunito ;
  font-weight: bold ;
  font-size: 2.5rem ;
  background-color: transparent ;
  border: none;
  text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
  
  
  ${media.min}{
    font-size: 2.5rem ;
    }
${media.tablet}{
    font-size: 2.5rem ;
    }
${media.desktop}{
    font-size: 3rem ;
    }
${media.desktopLg}{
    font-size: 3rem ;
    }
`

// IMÁGENES
export const ImgLN8 = styled.img`
    width: 15rem;
    height: 105px;
    margin: 0.5rem;
    background-color:  ${props => props.bg};
    border-radius:  ${props => props.br};
    box-shadow: ${(props) => props.boxs};

    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `} 

    ${media.min}{
        width: 15rem;
        height: 105px;
        margin: 0.5rem;
        }
    ${media.tablet}{
        width: 15rem;
        height: 110px;
        margin: 0.5rem;
        }
    ${media.desktop}{
        width: 15rem;
        height: 120px;
        margin: 1rem;
        }
    ${media.desktopLg}{
        width: 15rem;
        height: 120px;
        margin: 1rem;
        }

`

// -------------- FIN LN8TODOS --------------



// ------------ ESCRITURA -------------

// --- ESC1 ---
export const BtnLetra = styled.button`
background-color: #FAFAFA;
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: 4.2rem; 
height: 4rem; 
margin:  0 0.5rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
flex-wrap: ${(props) => props.wrap};
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: 10px;
font-size:  3rem;
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  600;
cursor: pointer;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    width: 4rem; 
    height: 4rem;
    }
${media.tablet}{
    width: 3.3rem; 
    height: 3.8rem;
    margin: 0.1rem 0.2rem;
    }
${media.desktop}{
    width: 5.3rem; 
    height: 4.5rem; 
    margin: 0.1rem 0.3rem;
    }
${media.desktopLg}{
    width: 5.5rem; 
    height: 4.5rem; 
    }
`

export const BtnColor = styled.button`
background-color: ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
box-shadow: ${(props) => props.shadow};
flex-wrap: ${(props) => props.wrap};
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: ${(props) => props.radius};
font-size:  ${(props) => props.font};
font-family: nunito ;
font-weight:  ${(props) => props.weight};
text-transform: ${(props) => props.uppercase};
cursor: pointer ;

${media.tablet} {
  width: ${(props) => props.widthmob}; 
}

`

export const Circulo = styled.div`
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    margin: 0 0.3rem;

    ${media.min}{
        width: 4rem; 
        height: 4rem;
        margin: 0 0.1rem;
        }
    ${media.tablet}{
        width: 2.8rem; 
        height: 2.8rem;
        margin: 0 0.1rem;
        }
`

// --- FIN ESC1 ---

// --- ESC2 ---

export const BtnAbecedarioGrande = styled.button`
background-color: #FAFAFA;
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: 2.4rem;
height: 2rem;
font-size: 1.2rem;
margin: 0 0.2rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
flex-wrap: ${(props) => props.wrap};
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: 10px;
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  600;
cursor: pointer;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    width: 3.6rem;
    height: 2.3rem;
    font-size: 1.2rem;
    margin: 0.1rem 0.2rem;
    }
${media.tablet}{
    width: 3.8rem;
    height: 2.5rem;
    font-size: 1.4rem;
    margin:  0 0.3rem;
    }
${media.desktop}{
    width: 3.8rem;
    height: 2.5rem;
    font-size: 1.4rem;
    margin:  0 0.3rem;
    }
${media.desktopLg}{
    width: 4rem;
    height: 2.5rem;
    font-size: 1.6rem;
    margin:  0 0.3rem;
    }
`

export const BtnBasurero = styled.button`
background-color:  ${(props) => props.bg};
border: ${(props) => props.border};
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
cursor: pointer;

img {
  width: 1.5rem;
  margin: 0 0 0 1rem ;
}

${media.tablet} {
  img {
  width: 1.2rem;
  margin: 0 0 0 0.5rem ;
}
}
`

export const BtnLetraDos = styled.button`
background-color: #FAFAFA;
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: 4.2rem; 
height: 4rem; 
margin:  0 0.5rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
flex-wrap: ${(props) => props.wrap};
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: 10px;
font-size:  2.5rem;
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  600;
cursor: pointer;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    width: 5.5rem; 
    height: 4.8rem;
    font-size: 2.2rem;
    }
${media.tablet}{
    width: 5.8rem; 
    height: 4.8rem;
    font-size: 2.3rem;
    margin: 0.1rem 0.2rem;
    }
${media.desktop}{
    width: 7.5rem; 
    height: 5.5rem; 
    font-size:  3rem;
    }
${media.desktopLg}{
    width: 7.5rem; 
    height: 5.5rem; 
    font-size:  3rem;
    }
`

// --- FIN ESC2 ---


// --- ESC3 ---

export const BtnLetraESC3 = styled.button`
background-color: #FAFAFA;
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: 4.2rem; 
height: 4rem; 
margin:  0.2rem 0.2rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
flex-wrap: ${(props) => props.wrap};
border: ${(props) => props.border};
color: ${(props) => props.color};
border-radius: 10px;
font-size:  3rem;
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  600;
cursor: pointer;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    width: 4rem; 
    height: 4rem;
    margin:  0.2rem 0.2rem;
    }
${media.tablet}{
    width: 4.8rem; 
    height: 4.8rem;
    margin:  0.3rem 0.5rem;
    }
${media.desktop}{
    width: 5.3rem; 
    height: 4.8rem; 
    margin:  0.3rem 0.5rem;
    }
${media.desktopLg}{
    width: 5.5rem; 
    height: 5rem; 
    margin:  0.3rem 0.5rem;
    }
`

// --- FIN ESC3 ---

// --- ESC4 ---

export const InputTextESC4 = styled.input `
font-size:  2rem;
border-radius: 5px;
margin:  0 0 0 2rem;
text-transform:  ${(props) => props.uppercase};
font-family: nunito ;
width: 16rem; 
height: ${(props) => props.height}; 
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
font-weight: ${(props) => props.weigth}; 
border: none;
text-align:center ;
color: ${(props) => props.color};
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
outline: none;

${media.min}{
    width: 16rem;
    }
${media.tablet}{
    width: 21rem;
    }
${media.desktop}{
    width: 25rem;
    }
${media.desktopLg}{
    width: 28rem;
    }
`


// --- FIN ESC4 ---

// --- ESC5 ---

export const InputTextESC5 = styled.input `
font-size:  2rem;
border-radius: ${(props) => props.bradius};
margin:  ${(props) => props.margin};
text-transform:  ${(props) => props.uppercase};
font-family: nunito ;
width: ${(props) => props.width};
height: ${(props) => props.height};
background-color: ${(props) => props.bg}; 
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25); 
font-weight: ${(props) => props.weigth}; 
border: none;
text-align:center ;
color: ${(props) => props.color};
text-transform: uppercase;
outline: none;

// ${media.min}{
//     width: 180px;
//     }
// ${media.tablet}{
//     width: 160px;
//     }
// ${media.desktop}{
//     width: 170px;
//     }
// ${media.desktopLg}{
//     width: 180px;
//     }
`

export const Pregunta = styled.div `
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: nunito ;
  color: rgba(100, 100, 100, 1);
  margin:  0 1rem;

${media.min}{
    font-size: 1.5rem;
    }
${media.tablet}{
    font-size: 1.3rem;
    }
${media.desktop}{
    font-size: 1.5rem;
    }
${media.desktopLg}{
    font-size: 1.5rem;
    }
`



// --- FIN ESC5 ---


// --- ESC6 ---

export const DivLetraESC6 = styled.div `
display: flex;
justify-content: center;
align-content: center;
align-items: center;
// border-bottom: 4px solid red;
// border: 2px solid red;
border-bottom: 4px solid grey;
width: 3rem;
height: 8rem; 
margin: 0 0.3rem;

${media.min}{
    width: 3rem;
    height: 8rem; 
    margin: 0 0.3rem;
    }
${media.tablet}{
    width: 4.3rem;
    height: 8rem; 
    margin: 0 0.5rem;
    }
${media.desktop}{
    width: 5rem;
    height: 9rem; 
    margin: 0 0.5rem;
    }
${media.desktopLg}{
    width: 5rem;
    height: 9rem; 
    margin: 0 0.5rem;
    }
`

export const LetraESC6 = styled.p `
font-family: nunito ;
// width: 100%; 
// height: 100%; 
margin: 0;
padding: 0px;
font-size:  4.3rem;
font-weight:  bold;
color: rgba(100, 100, 100, 1);
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    font-size:  5rem;
    }
${media.tablet}{
    font-size:  6rem;
    }
${media.desktop}{
    font-size:  6rem;
    }
${media.desktopLg}{
    font-size:  6rem;
    }
`

export const InputLetra = styled.input `
display: flex;
justify-content: center;
align-content: center;
align-items: center;
border-radius: 10px;
font-family: nunito ;
width: 3rem; 
height: 6rem;
margin:  0 0.2rem 0 0.2rem;
font-size: 4rem;
font-weight:  bold;
color: rgba(100, 100, 100, 1);
text-transform: uppercase;
text-align: center;
background: #FAFAFA;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
outline: none;
border:none;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
padding-inline-start: 0px;
padding-inline-end: 0px;
padding-block-start: 0px;
padding-block-end: 0px;

${media.min}{
    width: 3rem; 
    height: 6rem;
    margin: 0 0.2rem 0 0.2rem;
    font-size: 4rem;
    }
${media.tablet}{
    width: 4.5rem; 
    height: 6rem;
    margin:  0 0.5rem 0 0.5rem;
    font-size:  5.8rem;
    }
${media.desktop}{
    width: 4.5rem; 
    height: 7rem;
    margin:  0 0.5rem 0 0.5rem;
    font-size:  5.8rem;
    }
${media.desktopLg}{
    width: 4.5rem; 
    height: 7rem;
    margin:  0 0.5rem 0 0.5rem;
    font-size:  5.8rem;
    }
`

export const FormInputESC6 = styled.form `
display: flex;
justify-content: center;
align-content: center;
align-items: center;
width: 3rem;
height: 8rem; 
margin: 0 0.2rem 0 0.2rem;
border-bottom: 4px solid grey;

${media.min}{
    width: 3rem;
    height: 8rem; 
    margin: 0 0.2rem 0 0.2rem;
    }
${media.tablet}{
    width: 4.3rem;
    height: 8rem; 
    margin: 0 0.4rem 0 0.4rem;
    }
${media.desktop}{
    width: 5rem;
    height: 9rem; 
    margin: 0 0.5rem 0 0.5rem;
    }
${media.desktopLg}{
    width: 5rem;
    height: 9rem; 
    margin: 0 0.5rem 0 0.5rem;
    }
`

// --- FIN ESC6 ---


// --- LN13SD ---

export const BtnSilabaLN13 = styled.button`
// width: 85px;
// height: 90px;
font-size: 2.1rem;
font-family: nunito;
display: flex; 
justify-content: center; 
align-items: center; 
background: #FAFAFA;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
font-weight: bold;
// border: ${(props) => props.border};
border: none;
margin: 0px 1vw;
color: #646464;
cursor: pointer;
padding:  ${(props) => props.padding};
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    // width: 90px;
    // height: 90px;
    font-size: 2.1rem;
    }
${media.tablet}{
    // width: 120px;
    // height: 120px;
    font-size: 3.3rem;
    
    }
${media.desktop}{
    // width: 170px;
    // height: 130px;
    font-size: 4.5rem;
    }
${media.desktopLg}{
    // width: 170px;
    // height: 130px;
    font-size: 5rem;
    }

`

// --- FIN LN13SD ---

// --- LN18 ---

export const BtnLetraLN18 = styled.button`
background-color: rgba(249, 249, 249, 1);
display: flex;
direction: ${(props) => props.direction};
justify-content: center;
align-items: center; 
// width: 6vh; 
// height: 5.5vh; 
width: 2.5rem; 
height: 2.5rem;
margin: 0.1rem 0.6rem;
box-shadow: ${(props) => props.shadow};
flex-wrap: ${(props) => props.wrap};
border: none;
border: ${(props) => props.br};
color: ${(props) => props.color};
border-radius: 10px;
font-size: 2rem;
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  ${(props) => props.weight};
cursor: pointer;
text-align: center;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    width: 2.5rem; 
    height: 2.5rem;
    // font-size: 3rem;
    }
${media.tablet}{
    width: 2.5rem; 
    height: 2.5rem;
    // font-size: 3.8rem;
    }
${media.desktop}{
    width: 2.5rem; 
    height: 2.5rem;
    // font-size: 4.5rem;
    }
${media.desktopLg}{
    width: 2.5rem; 
    height: 2.5rem; 
    // font-size: 5rem;
    }

&:disabled {
  color: black;
  background-color: #23AAAF;
}
`

export const ContainerLN18= styled.div`
background-color: ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
border:  ${(props) => props.br};
flex-wrap: wrap ;

${media.tablet} {
  margin-top: ${(props) => props.marginMob};
}
${({isCorrect}) => isCorrect && 
  css`
  animation-name ${ZoomIn};
  animation-duration: 300ms;
`}
${({isIncorrect}) => isIncorrect && 
  css`
  animation-name ${ShakeImg};
  animation-duration: 300ms;
`}
`

export const ContInst = styled.div`
    position: absolute;
    bottom: 40px; 
    left: 25px; 
    text-transform: uppercase; 
    font-family: nunito;
    font-weight: 400;
    font-size: 16px; 
`

// --- FIN LN18 ---

// --- LN19 ---

export const BtnLetraLN19 = styled.button`
background-color: ${(props) => props.bgcolor};
display: flex;
direction: ${(props) => props.direction};
justify-content: center;
align-items: center; 
width: 12vw; 
height: 18vh; 
margin: 0.5rem;
padding: 1rem 4rem;
box-shadow: ${(props) => props.shadow};
flex-wrap: ${(props) => props.wrap};
border: none;
color: ${(props) => props.color ? props.color : "rgba(100, 100, 100, 1)"};
border-radius: 20px;
font-size: 4rem;
font-family: nunito ;
text-transform: uppercase;
font-weight:  ${(props) => props.weight};
cursor: pointer;
`

// --- FIN LN19 ---

// --- LN20 ---

export const BtnLetraLN20 = styled.button`
background-color: ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
width: 16.5vw; 
height: 14vh;   
font-size: 1rem;
margin: 0.5rem 1rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
flex-wrap: ${(props) => props.wrap};
border: none;
color: ${(props) => props.color};
border-radius: 20px;
font-family: nunito ;
text-transform: uppercase;
font-weight:  ${(props) => props.weight};
cursor: pointer;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};

${media.min}{
    width: 16.5vw; 
    height: 14vh;   
    font-size: 1rem;
    margin: 0.5rem 1rem;
    }
${media.tablet}{
    width: 14vw; 
    height: 14vh;   
    font-size: 1.2rem;
    margin: 0.5rem 1.5rem;
    }
${media.desktop}{
    width: 12vw; 
    height: 14vh;   
    font-size: 1.5rem;
    margin: 0.5rem 1.5rem;
    }
${media.desktopLg}{
    width: 12vw; 
    height: 14vh;   
    font-size: 1.5rem;
    margin: 0.5rem 1.5rem;
    }

    &:disabled {
        color: black;
        // background-color: #23AAAF;
        background-color: ${(props) => props.disabledColor};
      }

`

export const ContainerEjercicio = styled.div`
background-color: ${(props) => props.bg};
display: ${(props) => props.display};
direction: ${(props) => props.direction};
justify-content:  ${(props) => props.justify};
align-items:  ${(props) => props.align}; 
height: ${(props) => props.height}; 
margin:  ${(props) => props.margin};
flex-wrap: wrap ;

${media.tablet} {
  margin-top: ${(props) => props.marginMob};
}
${({isCorrect}) => isCorrect && 
  css`
  animation-name ${ZoomIn};
  animation-duration: 300ms;
`}
${({isIncorrect}) => isIncorrect && 
  css`
  animation-name ${ShakeImg};
  animation-duration: 300ms;
`}

`

// --- FIN LN20 ---

// --- LN30 ---

export const Palabra = styled.div `
background-color: rgba(253, 182, 44, 1);
display: flex;
direction: ${(props) => props.direction};
justify-content: center;
align-items: center;
width: 100%; 
height: 100%; 
margin:  ${(props) => props.margin};
font-size: 2.8rem;
font-family: nunito ;
border: none;
color: rgba(100, 100, 100, 1);
border-radius: 20px;
font-weight: 600;
// letter-spacing: 0.5rem;
border-bottom: ${(props) => props.borderBottom};
text-transform: ${(props) => props.uppercase};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const BtnLetraLN30 = styled.button`
background-color: rgba(253, 182, 44, 1);
// background-color: red;
display: flex;
direction: ${(props) => props.direction};
justify-content: center;
align-items: center; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin: 0;
padding: 0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
flex-wrap: ${(props) => props.wrap};
border: none;
color: ${(props) => props.color};
border-radius: 20px;
font-size:  ${(props) => props.font};
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  ${(props) => props.weight};
cursor: pointer;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
`

export const InputTextLN30 = styled.input `
font-size:  2rem;
font-family: nunito;
width: ${(props) => props.width}; 
height: 3rem; 
border-radius: 10px;
margin:  ${(props) => props.margin};
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
font-weight: ${(props) => props.weigth}; 
border: none;
text-align: center;
color: ${(props) => props.color};
text-transform: uppercase;
`

// --- FIN LN30 ---

// --- LN31 ---

export const PalabraLN31 = styled.div `
background-color: rgba(35, 170, 175, 1);
display: flex;
direction: ${(props) => props.direction};
justify-content: center;
align-items: center;
width: 100%; 
height: 100%; 
margin:  ${(props) => props.margin};
font-size: 2.8rem;
font-family: nunito ;
border: none;
color: rgba(100, 100, 100, 1);
border-radius: 20px;
font-weight: 600;
// letter-spacing: 0.5rem;
border-bottom: ${(props) => props.borderBottom};
text-transform: ${(props) => props.uppercase};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const BtnLetraLN31 = styled.button`
background-color: rgba(35, 170, 175, 1);
// background-color: red;
display: flex;
direction: ${(props) => props.direction};
justify-content: center;
align-items: center; 
width: ${(props) => props.width}; 
height: ${(props) => props.height}; 
margin: 0;
padding: 0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
flex-wrap: ${(props) => props.wrap};
border: none;
color: ${(props) => props.color};
border-radius: 20px;
font-size:  ${(props) => props.font};
font-family: nunito ;
text-transform: ${(props) => props.uppercase};
font-weight:  ${(props) => props.weight};
cursor: pointer;
text-transform: ${(props) => props.$minMay ? 'lowercase' : 'uppercase'};
`

export const FormInputLN31 = styled.form `
margin: ${(props) => props.margin};
display: flex;
direction: ${(props) => props.direction};
justify-content: center;
align-items: center; 
width: 100%; 
height: ${(props) => props.height}; 
border-radius: ${(props) => props.radius};
font-size: ${(props) => props.font};
text-transform:  ${(props) => props.uppercase};
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
// border: none;

Input {
    border:none;
    width: ${(props) => props.widthmob}; 
    height: ${(props) => props.heightmob}; 
    margin: ${(props) => props.marginMob};
    background: #FAFAFA;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: ${(props) => props.radius};
} 
`

export const InputTextLN31 = styled.input `
font-size: 2rem;
border-radius: 10px;
margin:  ${(props) => props.m};
text-transform:  ${(props) => props.uppercase};
font-family: nunito ;
width: 90px; 
height: 70px; 
background-color: ${(props) => props.bg}; 
box-shadow: ${(props) => props.shadow}; 
font-weight: ${(props) => props.weigth}; 
border: none;
text-align:center ;
color: ${(props) => props.color};
text-transform: uppercase;
outline: none;
`

// --- FIN LN31 ---


// ------------ ESCRITURA -------------