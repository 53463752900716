import React, {Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CualEsMayor from '../../../Compartidos/CualEsMayor';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const modelo = [
    {id:'1', numero_1: 1, numero_2: 3, numero_3: 4, numero_4: 4, cantidad_1: 5, cantidad_2: 7, operatoria:'-'},
    {id:'2', numero_1: 6, numero_2: 5, numero_3: 5, numero_4: 3, cantidad_1: 1, cantidad_2: 2, operatoria:'-'},
    {id:'3', numero_1: 4, numero_2: 1, numero_3: 2, numero_4: 1, cantidad_1: 2, cantidad_2: 0, operatoria:'-'},
    {id:'4', numero_1: 6, numero_2: 4, numero_3: 3, numero_4: 6, cantidad_1: 9, cantidad_2: 10, operatoria:'-'},
    {id:'5', numero_1: 3, numero_2: 4, numero_3: 1, numero_4: 1, cantidad_1: 2, cantidad_2: 3, operatoria:'-'}
]

const instrucciones = 'Indica qué número es mayor.';
const miniInstrucciones = 'Indica qué número es mayor.';

const M14 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(modelo));
        for(let i=0; i < 5; i++){
            const maxUno = 10;
            const minUno = 3;
            const numeroUno = Math.floor(Math.random() * (maxUno - minUno +1)) + minUno;
            const maxTres = numeroUno-1 ;
            const minTres = 1;
            const numeroTres = Math.floor(Math.random() * (maxTres - minTres +1)) + minTres;
            const numeroDos = (function () {
                let a = Math.floor(Math.random() * (maxUno - minUno +1)) + minUno;
                while(a === numeroUno){
                    a = Math.floor(Math.random() * (maxUno - minUno +1)) + minUno;
                }
                return a
            })();
            const maxCuatro = numeroDos-1 ;
            const minCuatro = 1;
            const numeroCuatro = Math.floor(Math.random() * (maxCuatro - minCuatro +1)) + minCuatro;
            const cantidad_1 = numeroUno - numeroTres;
            const cantidad_2 = numeroDos - numeroCuatro;
    
            newList[i].numero_1 = numeroUno;
            newList[i].numero_2 = numeroDos;
            newList[i].numero_3 = numeroTres;
            newList[i].numero_4 = numeroCuatro;
            newList[i].cantidad_1 = cantidad_1;
            newList[i].cantidad_2 = cantidad_2;
        }
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M14') );
        dispatch(setearUrlAnteriorAction('matematica/subitizacion/nivel-3/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <Fragment>
            <CualEsMayor bgcolor='#FFDC73' data={data} indexes={indexes} ejercicio="M14" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} minMay={false} estrellas={true} carrito={true} />
        </Fragment>
    );
}
 
export default M14;