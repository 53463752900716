import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// CSS
import {LogIn,  Formulario, Cuerpo, Logo, Input, Ilustracion, FlexColumn, Enlace, Form, Titulo, Button, ContenedorLogo,ContenedorForm, LabelRes, Img, ContenedorHeader, ContenedorLogin, ButtonTipo, FalsoDiv} from '../Css/StyledForm.js';
import {Error} from '../Css/StyledHeader.js';

// Actions de Redux
import { iniciarSesionAction } from '../../actions/loginActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';
import Alertas from '../Metodos/Compartidos/Alertas.js';

const Login = () => {

    // state del componente
    const [email, guardarEmail] = useState('');
    const [password, guardarPassword] = useState('');
    const [tipoUsuario, setTipoUsuario] = useState('________');
    const [visibilidadPassword, setVisibilidadPassword] = useState(false);

    // utilizar use dispatch y te crea una función
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Acceder el state del store
    const cargando = useSelector( (state) => state.estado.loading);
    const error = useSelector ( (state) => state.estado.error);
    const alerta = useSelector ( (state) => state.alertas.alerta);
    const autenticado = useSelector ( (state) => state.autenticado.autenticado);
    const rol = useSelector ( (state) => state.usuario.rol);

    // mandar llamar el action de login
    const login = (datos) => dispatch( iniciarSesionAction(datos) );

    const definirProfesional = () => {
        setTipoUsuario("Profesional");
    }
    const definirPaciente = () => {
        setTipoUsuario("Paciente");
    }
    const definirTutor = () => {
        setTipoUsuario("Tutor");
    }
    // cuando el usuario haga submit
    const submitLogin = e => {
        e.preventDefault();
        
        // validar formulario
        if(email.trim() === '') {
            const alerta = {
                msg: 'Falta completar el email'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        // validar formulario
        if(password.length <= 0) {
            const alerta = {
                msg: 'Debe escribir una contraseña'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        if(tipoUsuario === "________") {
            const alerta = {
                msg: 'Elegir tipo de usuario'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        // si no hay errores

        dispatch( ocultarAlertaAction());

        login({
            email,
            password,
            tipoUsuario
        });
    }

    const mostrarPassword = (e) => {
        e.preventDefault();
        if(visibilidadPassword) {
            setVisibilidadPassword(false);
        } else {
            setVisibilidadPassword(true);
        }
        
    }

    useEffect(() => {
        if(autenticado && rol === '249831'){
            navigate('/gestion');
        } else if(autenticado && tipoUsuario === "Profesional") {
            navigate('/escritorio/pacientes');
        } else if(autenticado && tipoUsuario === "Paciente" ) {
            navigate('/escritorio-paciente')
        } else if(autenticado && tipoUsuario === "Tutor"){
            navigate('escritorio-tutores')
        }
    }, [autenticado]);

    return (
        <ContenedorLogin>
            {alerta ? <Alertas /> : null}
            <ContenedorHeader>
                <ContenedorLogo>
                    <Link to={"/"}>
                        <Logo src={`/img/metodoYogenLogo.png`} alt="" width="20vh"/>
                    </Link>
                </ContenedorLogo>
                { tipoUsuario === "Paciente" || tipoUsuario === "Tutor"
                ?   null
                : <LogIn to={"/nueva-cuenta"}>Nueva Cuenta Profesional</LogIn>
                }
            </ContenedorHeader>
            <ContenedorForm>
                <Ilustracion src={tipoUsuario === "Paciente" ? `/img/login_entrenado.png` : `/img/ilustracionLogIn.png`} alt="" width="40vw" ancho="30vw"/>
                <Formulario  >
                
                    <Titulo>Bienvenido {tipoUsuario === "Paciente" ? "Entrenado" : tipoUsuario}, inicie sesión</Titulo>
                    <ButtonTipo onClick={definirProfesional}>Profesional</ButtonTipo>
                    <ButtonTipo onClick={definirPaciente}>Entrenado</ButtonTipo>
                    <ButtonTipo onClick={definirTutor}>Tutor</ButtonTipo>

                    <Form margin="0 4rem 0.5rem 0" margintablet="0 0.5rem 0.5rem 0"
                        onSubmit={submitLogin}
                    >
                        <LabelRes margin="0px 0px 20px 0px" padding="4px 0px 2px 7px" width="30vw">
                            <label htmlFor="email">Email</label>
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                // placeholder="Tu Email"
                                value={email}
                                onChange={e => guardarEmail(e.target.value)}
                            />
                        </LabelRes>
                        <LabelRes margin="0px 0px 20px 0px" padding="4px 0px 2px 7px" width="30vw">
                            <label htmlFor="password">Contraseña</label>
                            <Input
                                type={visibilidadPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                // placeholder="Tu Contraseña"
                                value={password}
                                onChange={e => guardarPassword(e.target.value)}
                            />
                            <Img onClick={e => mostrarPassword(e)} height="1.5rem" width="1.5rem" alt='' src={visibilidadPassword ? `/img/ojo_invisible.png` : `/img/ojo_visible.png`} />
                        </LabelRes>
                        <Button
                                type="submit"
                        >Iniciar Sesión
                        </Button>
                    </Form>
                    <FlexColumn>
                    <Enlace to={"/restablecer-password"}>¿Has olvidado tu contraseña?</Enlace>
                    </FlexColumn>    
                    {cargando ? <p>Cargando...</p> : null }
                    {error ? <Error> {error.msg} </Error>: null }
                </Formulario>
            </ContenedorForm>
        </ContenedorLogin>
    );
}
 
export default Login;