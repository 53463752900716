import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, ImgB10, InputM50, AvanzarImg, AvanzarBtn, TextM50 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Escribe los números y resuelve la suma.';
const miniInstrucciones = 'Escribe los números y resuelve la suma.';

const M52 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState('');
    const [numeroUno, setNumeroUno] = useState('');
    const [numeroDos, setNumeroDos] = useState('');
    const [bloqueUno, setBloqueUno] = useState([]);
    const [bloqueDos, setBloqueDos] = useState([]);
    const [bloque, setBloque] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if(numero.toString() !== (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() || numeroUno.toString() !== comparar[count-1].cantidad_1.toString() || numeroDos.toString() !== comparar[count-1].cantidad_2.toString() ) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numero.toString() !== (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString()) {
                    setNumero('');
                }
                if(numeroUno.toString() !== comparar[count-1].cantidad_1.toString()) {
                    setNumeroUno('');
                }
                if(numeroDos.toString() !== comparar[count-1].cantidad_2.toString()) {
                    setNumeroDos('');
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setNumeroUno('');
                    setNumeroDos('');
                    setBloqueUno([]);
                    setBloqueDos([]);
                    setBloque([]);
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setNumeroUno('');
                    setNumeroDos('');
                    setBloqueUno([]);
                    setBloqueDos([]);
                    setBloque([]);
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const min = 1;
        const max = 5;
        const listaUno = [];
        const listaDos = [];
        const randomLista = (min,max,lista) => {
            for(let i = 0; i < 5; i++){
                let randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                lista.push(randomNumero);
            }
            return lista
        }
        const randomListaUno = randomLista(min,max,listaUno);
        const randomListaDos = randomLista(min,max,listaDos);

        const modelo = [
            {id:'1', cantidad_1:randomListaUno[0], cantidad_2:randomListaDos[0]},
            {id:'2', cantidad_1:randomListaUno[1], cantidad_2:randomListaDos[1]},
            {id:'3', cantidad_1:randomListaUno[2], cantidad_2:randomListaDos[2]},
            {id:'4', cantidad_1:randomListaUno[3], cantidad_2:randomListaDos[3]},
            {id:'5', cantidad_1:randomListaUno[4], cantidad_2:randomListaDos[4]}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('unidad');
    }, [])

    useEffect(() => {
        if(comparar.length === 0) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        const colores = ['bloquerosa', 'bloqueazul', 'bloquenaranja', 'bloqueverde', 'bloqueamarillo'];

        const min = 0;
        const max = 4;
        const randomUno = Math.floor(Math.random() * (max - min + 1)) + min;
        const uno = colores.splice(randomUno,1);
        const randomDos = Math.floor(Math.random() * ((max-1) - min + 1)) + min;
        const dos = colores.splice(randomDos,1);

        const arrUno = Array(newList.cantidad_1).fill(uno);
        const arrDos = Array(newList.cantidad_2).fill(dos);
        
        setBloqueUno(arrUno);
        setBloqueDos(arrDos);
        
        const newList2 = JSON.parse(JSON.stringify(arrUno));
        const newList3 = JSON.parse(JSON.stringify(arrDos));
        const arr = newList2.concat(newList3);
        setBloque(arr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect])

    useEffect(() => {
        dispatch(setearEjercicioAction('M52') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/ejercitacion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M52",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);


    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex direction="column" margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        {/* IMG */}
                        <FlexFull justify='space-between' align='end' width='60vw' wt='55vw' wd='45vw' wlg='40vw'
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}
                        >
                            <div>
                                {bloqueUno.map((item,i) => 
                                <div key={i}>
                                    <ImgB10 src={`/img/${item}.png`}  w='8vw' wm='10vw' wt='5.5vw' wd='4.8vw' wlg='4vw' m='-21px 0px 0px 0px' mm='-25px 0px 0px 0px' mt='-21.5px 0px 0px 0px' md='-20px 0px 0px 0px' mlg='-22px 0px 0px 0px' z={-i} /> 
                                </div> 
                                )}
                            </div>
                            <div>
                                {bloqueDos.map((item,i) => 
                                <div key={i}>
                                    <ImgB10 src={`/img/${item}.png`}  w='8vw' wm='10vw' wt='5.5vw' wd='4.8vw' wlg='4vw' m='-21px 0px 0px 0px' mm='-25px 0px 0px 0px' mt='-21.5px 0px 0px 0px' md='-20px 0px 0px 0px' mlg='-22px 0px 0px 0px' z={-i} /> 
                                </div> 
                                )}
                            </div>
                            <div>
                                {bloque.map((item,i) => 
                                <div key={i}>
                                    <ImgB10 src={`/img/${item}.png`}  w='8vw' wm='10vw' wt='5.5vw' wd='4.8vw' wlg='4vw' m='-21px 0px 0px 0px' mm='-25px 0px 0px 0px' mt='-21.5px 0px 0px 0px' md='-20px 0px 0px 0px' mlg='-22px 0px 0px 0px' z={-i} /> 
                                </div> 
                                )}
                            </div>
                        </FlexFull>
                        <Flex margin="2rem 0 0 0">
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM50 type="numeric" min="1" max="10" value={numeroUno} onChange={(e) => setNumeroUno(e.target.value)} disabled={disable} />
                            </form>
                            <TextM50 color='#D33B52'>+</TextM50> 
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM50 type="numeric" min="1" max="10" value={numeroDos} onChange={(e) => setNumeroDos(e.target.value)} disabled={disable} />
                            </form>
                            <TextM50 color='#D33B52'>=</TextM50>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM50 type="numeric" min="1" max="10" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                            </form>
                        </Flex>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M52;