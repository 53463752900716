import React, { Fragment } from 'react';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, BtnLinkLecto } from '../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../Css/StyledHeader';

const Letras = () => {

    const isActive = false;

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} componente={"LETRAS"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Container justify="space-around" align="center" height="80vh">
                <BtnLinkLecto bgcolor="#D05F67" to={'/escritorio/metodos/lecto-escritura/letras/vocales'}>Vocales</BtnLinkLecto>
                <BtnLinkLecto bgcolor="#D05F67" to={'/escritorio/metodos/lecto-escritura/letras/consonantes'}>Consonantes</BtnLinkLecto>
            </Container>
        </Fragment>
    );
}
 
export default Letras;