import React, { Fragment } from 'react';

import HeaderUno from './Compartidos/HeaderUno';
import HeaderMetodos from '../AuxiliaresPaginas/HeaderMetodos';

// css
import {BackgroundLenguaje, BtnLink, BtnLecto, Container, BtnColores } from '../Css/MetodosCSS/StyledMethods'
import { HrMetodos } from '../Css/StyledHeader';

const Lenguaje = () => {
    return ( 
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} n1={"Métodos"} componente={"LENGUAJE"} paciente={true} fw='600' bgcolor="#FF9B2A" color="white" width='96vw' />
            <BackgroundLenguaje>
                <Container justify="space-around" >
                    <BtnLecto>
                        <BtnLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica'} color="black">Conciencia Fonológica</BtnLink>
                    </BtnLecto>
                    <BtnLecto>
                        <BtnLink to={'/escritorio/metodos/lenguaje/denominacion-rapida'} color="black">Denominación Rápida</BtnLink>
                    </BtnLecto>
                </Container>
            </BackgroundLenguaje>
        </Fragment>
    );
}
 
export default Lenguaje;