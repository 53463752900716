import {
    SOLICITO_INFORMACION_PERFIL_PROFESIONAL,
    SOLICITO_INFORMACION_PERFIL_PROFESIONAL_EXITO,
    SOLICITO_INFORMACION_PERFIL_PROFESIONAL_ERROR,
    SOLICITO_TOKEN_PERFIL_PROFESIONAL,
    SOLICITO_TOKEN_PERFIL_PROFESIONAL_ERROR,
    SOLICITO_TOKEN_PERFIL_PROFESIONAL_EXITO
} from '../types';
import clienteAxios from '../config/axios';

/* Solicito datos de la DB */
export function solicitoPerfilProfesionalAction(informacion) {
    return async (dispatch) => {
        dispatch (solicitoPerfilProfesional());
        try {
            const {config} = informacion;
            const respuesta = await clienteAxios.get('/usuarios/solicitoperfilprofesional', config);
            dispatch( solicitoPerfilProfesionalExito(respuesta.data.informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitoPerfilProfesionalError(error.response.data) );
            }
        }
    }
}

const solicitoPerfilProfesional = () => ({
    type: SOLICITO_INFORMACION_PERFIL_PROFESIONAL,
    payload: true
});

// si logro obtener la informacion de la BD
const solicitoPerfilProfesionalExito = (data) => ({
    type: SOLICITO_INFORMACION_PERFIL_PROFESIONAL_EXITO,
    payload: data
});

// si hubo un error
const solicitoPerfilProfesionalError = (msg) => ({
    type: SOLICITO_INFORMACION_PERFIL_PROFESIONAL_ERROR,
    payload: msg
});

export function solicitoTokenPerfilProfesionalAction(informacion) {
    return async (dispatch) => {
        dispatch( solicitoTokenPerfilProfesional() );
        try {
            const {config} = informacion;
            const respuesta = await clienteAxios.get('/usuarios/solicitotokenperfilprofesional', config);
            dispatch( solicitoTokenPerfilProfesionalExito(respuesta.data.informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitoTokenPerfilProfesionalError(error.response.data) );
            }
        }
    }
}

const solicitoTokenPerfilProfesional = () => ({
    type: SOLICITO_TOKEN_PERFIL_PROFESIONAL,
    payload: true
});

// si logro obtener la TOKEN de la BD
const solicitoTokenPerfilProfesionalExito = (data) => ({
    type: SOLICITO_TOKEN_PERFIL_PROFESIONAL_EXITO,
    payload: data
});

// si hubo un error
const solicitoTokenPerfilProfesionalError = (msg) => ({
    type: SOLICITO_TOKEN_PERFIL_PROFESIONAL_ERROR,
    payload: msg
});