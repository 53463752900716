import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, ImgB10, AvanzarImg, AvanzarBtn, InputM68, TextM50, NumerosM68, CirculoMasMenos } from '../../../../../Css/StyledMatematica' ;

const instrucciones = 'Resuelve la resta. Para ello deberás armar el número con helados. Luego tendras que hacer click sobre los helados que restas.';
const miniInstrucciones = 'Resuelve la resta.';

const M70 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]); 
    const [cuadrados, setCuadrados] = useState([]);
    const [numero, setNumero] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const agregar = (e) => {
        e.preventDefault();
        if(cuadrados.length === 10){
            return;
        }
        const newList = JSON.parse(JSON.stringify(cuadrados));
        newList.push({imagen: 'helado', tipo:'helado'});
        setCuadrados(newList);
    }
    const quitar = (e) => {
        e.preventDefault();
        if(cuadrados.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(cuadrados));
        newList.pop();
        setCuadrados(newList);
    }
    const tachar = (e, i, tp) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(cuadrados));
        if(tp === 'helado'){
            newList[i].imagen = 'heladoComido';
            newList[i].tipo = 'heladoComido';
        }
        if(tp === 'heladoComido'){
            newList[i].imagen = 'helado';
            newList[i].tipo = 'helado';
        }
        setCuadrados(newList);
    }

    const verificar = (e) => {
        e.preventDefault();
        if(numero.toString() !== (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() || cuadrados.filter(item => item.tipo === 'heladoComido').length.toString() !== comparar[count-1].cantidad_2.toString() || cuadrados.length.toString() !== comparar[count-1].cantidad_1.toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numero.toString() !== (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() || cuadrados.filter(item => item.tipo === 'heladoComido').length.toString() !== comparar[count-1].cantidad_2.toString() || cuadrados.length.toString() !== comparar[count-1].cantidad_1.toString()) {
                    setNumero('');
                    setCuadrados([]);
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setCuadrados([]);
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const listaUno = [1,2,3,4,5,6,7,8,9,10];
        const randomListaUno = shuffle(listaUno);
        const listaUnoFinal = [randomListaUno[0],randomListaUno[1],randomListaUno[2],randomListaUno[3],randomListaUno[4]];

        const listaDos = [];
        const randomLista = (lista, listaBase) => {
            for(let i = 0; i < 5; i++){
                let min = 1;
                let max = listaBase[i];
                let randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                lista.push(randomNumero);
            }
            return lista
        }
        const randomListaDos = randomLista(listaDos, listaUnoFinal);
        const modelo = [
            {id:'1', cantidad_1:listaUnoFinal[0], cantidad_2:randomListaDos[0]},
            {id:'2', cantidad_1:listaUnoFinal[1], cantidad_2:randomListaDos[1]},
            {id:'3', cantidad_1:listaUnoFinal[2], cantidad_2:randomListaDos[2]},
            {id:'4', cantidad_1:listaUnoFinal[3], cantidad_2:randomListaDos[3]},
            {id:'5', cantidad_1:listaUnoFinal[4], cantidad_2:randomListaDos[4]}
        ]
        setComparar(modelo);
        setIndexes(modelo.length);
        setTipoEjercicio('unidad');
    }, []);

    useEffect(() => {
        dispatch(setearEjercicioAction('M70') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/ejercitacion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M70",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex direction="column" margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <FlexFull height='10vh' justify='space-between' align='center'> {/*  border='2px solid violet' */}
                            <FlexFull direction='column'> {/*  border='2px solid red' */}
                                <CirculoMasMenos
                                    disabled={disable}
                                    onClick={(e) => quitar(e)}
                                >
                                    -
                                </CirculoMasMenos>
                                <CirculoMasMenos
                                    disabled={disable}
                                    onClick={(e) => agregar(e)}
                                >
                                    +
                                </CirculoMasMenos>
                            </FlexFull>
                            <Flex width='86.5vw' wm='75vw' wt='60vw' wd='55vw' wlg='53vw'>
                                {cuadrados.map((item, i) => 
                                    <div
                                        key={i}
                                        onClick={(e) => tachar(e,i, item.imagen)}
                                    >
                                        <ImgB10 src={`/img/${item.imagen}.png`} w='9vw' wm='7.8vw' wt='6vw' wd='5.5vw' wlg='5.2vw' />
                                    </div>
                                )}
                            </Flex>
                        </FlexFull>
                        <Flex margin="2rem 0 0 0">
                            <NumerosM68>
                                {comparar[count-1].cantidad_1}
                            </NumerosM68>
                            <NumerosM68>-</NumerosM68> 
                            <NumerosM68>
                                {comparar[count-1].cantidad_2}
                            </NumerosM68>
                            <TextM50 color='#FF9B2A'>=</TextM50>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputM68 type="numeric" min="1" max="10" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} color='#FF9B2A' bgcolor='#F1F0F0' w='15vw' bs='inset 0px 4px 4px rgba(0, 0, 0, 0.25)' bradius='20px' />
                            </form>
                        </Flex>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M70;