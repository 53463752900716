import {
    SOLICITO_ANAMNESIS,
    SOLICITO_ANAMNESIS_EXITO,
    SOLICITO_ANAMNESIS_ERROR
} from '../types';
import clienteAxios from '../config/axios';

/* Solicito datos de la DB */
export function solicitarAnamnesisAction(informacion) {
    return async (dispatch) => {
        dispatch (solicitarAnamnesis());
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/solicitaranamnesis',payload, config);
            const info = !data ? [] : data;
            dispatch( solicitarAnamnesisExito(info));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitarAnamnesisError(error.response.data) );
            }
        }
    }
}

const solicitarAnamnesis = () => ({
    type: SOLICITO_ANAMNESIS,
    payload: true
});

// si logro obtener la informacion de la BD
const solicitarAnamnesisExito = (data) => ({
    type: SOLICITO_ANAMNESIS_EXITO,
    payload: data
});

// si hubo un error
const solicitarAnamnesisError = (msg) => ({
    type: SOLICITO_ANAMNESIS_ERROR,
    payload: msg
});