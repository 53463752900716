import {
    ACTIVAR_METODO,
    ACTIVAR_METODO_EXITO,
    ACTIVAR_METODO_ERROR,
    DAR_DE_BAJA_METODO,
    DAR_DE_BAJA_METODO_EXITO,
    DAR_DE_BAJA_METODO_ERROR
} from '../types';
import clienteAxios from '../config/axios';

export function activarMetodoAction(informacion) {
    return async (dispatch) => {
        dispatch( activarMetodo() );
        try {
            const {config, payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/activarmetodo', payload,config);
            dispatch( activarMetodoExito(respuesta.data.msg) );
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( activarMetodoError(error.response.data) );
            }
        }
    }
}

const activarMetodo = () => ({
    type: ACTIVAR_METODO,
    payload: true
});
const activarMetodoExito = (msg) => ({
    type: ACTIVAR_METODO_EXITO,
    payload: msg
});
const activarMetodoError = (msg) => ({
    type: ACTIVAR_METODO_ERROR,
    payload: msg
});

export function darDeBajaMetodoAction(informacion) {
    return async (dispatch) => {
        dispatch( darDeBajaMetodo() );
        try {
            const {config, payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/dardebajametodo',payload, config);
            dispatch( darDeBajaMetodoExito(respuesta.data.msg) );
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( darDeBajaMetodoError(error.response.data) );
            }
        }
    }
}

const darDeBajaMetodo = () => ({
    type: DAR_DE_BAJA_METODO,
    payload: true
});
const darDeBajaMetodoExito = (msg) => ({
    type: DAR_DE_BAJA_METODO_EXITO,
    payload: msg
});
const darDeBajaMetodoError = (msg) => ({
    type: DAR_DE_BAJA_METODO_ERROR,
    payload: msg
});