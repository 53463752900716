import React, { Fragment } from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

//css
import { ContainerImg, ElegirLink } from '../../../Css/MetodosCSS/StyledInstruccionExplicita';
import { ContainerFlex } from '../../../Css/MetodosCSS/StyledLN2Todos';
import { HrMetodos } from '../../../Css/StyledHeader';

const TransicionDeLetras = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} l3={"escritura/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} n3={"ESCRITURA"} componente={"TRANSICIÓN DE LETRAS"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <ContainerFlex margintop="10rem" margintopmob="4rem" display="flex" justify="center" directionMob="column">
                <ContainerFlex display="flex" justify="center" height="25vh">
                    <ElegirLink marginright="2rem" to={`/escritorio/metodos/lecto-escritura/escritura/transicion-de-letras/ESC16`}>
                        <ContainerImg height="170px" src={`/img/portadas/escritura16.jpg`} alt="" />
                    </ElegirLink>
                    <ElegirLink marginright="2rem" to={`/escritorio/metodos/lecto-escritura/escritura/transicion-de-letras/ESC17`}>
                        <ContainerImg height="170px" src={`/img/portadas/escritura17.jpg`} alt="" />
                    </ElegirLink>
                </ContainerFlex>

                <ContainerFlex display="flex" justify="center" margintopmob="3rem">
                    <ElegirLink marginright="2rem" margintopmob="2rem" to={`/escritorio/metodos/lecto-escritura/escritura/transicion-de-letras/ESC18`}>
                        <ContainerImg height="170px"  src={`/img/portadas/escritura18.jpg`} alt="" />
                    </ElegirLink>
                </ContainerFlex>
            </ContainerFlex>
        </Fragment>
    );
}

export default TransicionDeLetras;