import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LN1Todos from '../../../Compartidos/LN1Todos';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'A continuación encontraras la unión silábica de la consonante seleccionada con las vocales. Prolonga los sonidos para que el niño/a pueda identificarlos correctamente.';
const miniInstrucciones = '';

const LN1IS = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN1IS') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/instruccion-sinfones/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[0]));

        if(newList.muestro_letras?.toString() === '5') {
            const nuevaLista = [
                {id:'1', silaba:newList.letra_1, imagen_letra:newList.imagen_url_1, sonido:newList.sonido_url_1, imagen_boca:newList.imagen_url_2, tipo:newList.tipo, seteo_imagen_letra:newList.seteo_imagen_letra},
                {id:'2', silaba:newList.silaba_1, primera_letra:newList.silaba_1, segunda_letra:newList.letra_2, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_3, myStyle:{color:'#23AAAF'}},
                {id:'3', silaba:newList.silaba_2, primera_letra:newList.silaba_2, segunda_letra:newList.letra_3, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_4, myStyle:{color:'#FDB62C'}},
                {id:'4', silaba:newList.silaba_3, primera_letra:newList.silaba_3, segunda_letra:newList.letra_4, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_5, myStyle:{color:'#FED67F'}},
                {id:'5', silaba:newList.silaba_4, primera_letra:newList.silaba_4, segunda_letra:newList.letra_5, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_6, myStyle:{color:'#655EE8'}},
                {id:'6', silaba:newList.silaba_5, primera_letra:newList.silaba_5, segunda_letra:newList.letra_6, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_7, myStyle:{color:'#D76970'}}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else if (newList.muestro_letras?.toString() === '3'){
            const nuevaLista = [
                {id:'1', silaba:newList.letra_1, imagen_letra:'', sonido:newList.sonido_url_1, imagen_boca:newList.imagen_url_2, tipo:newList.tipo, seteo_imagen_letra:newList.seteo_imagen_letra},
                {id:'2', silaba:newList.letra_1, primera_letra:newList.letra_1, segunda_letra:newList.letra_3, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_3, myStyle:{color:'#FDB62C'}},
                {id:'3', silaba:newList.letra_1, primera_letra:newList.letra_1, segunda_letra:newList.letra_4, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_4, myStyle:{color:'#FED67F'}}
                ]
                setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else {
            const nuevaLista = [
                {id:'1', silaba:newList.letra_1, imagen_letra:'', sonido:newList.sonido_url_1, imagen_boca:newList.imagen_url_2, tipo:newList.tipo, seteo_imagen_letra:newList.seteo_imagen_letra},
                {id:'2', silaba:newList.letra_1, primera_letra:newList.letra_1, segunda_letra:newList.letra_3, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_3, myStyle:{color:'#FDB62C'}},
                {id:'3', silaba:newList.letra_1, primera_letra:newList.letra_1, segunda_letra:newList.letra_4, imagen_1:newList.imagen_url_2, imagen_2:newList.imagen_url_4, myStyle:{color:'#FED67F'}}
                ]
                setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        }
    }, [informacion])

    return (
        <Fragment>
            <LN1Todos data={data} indexes={indexes} ejercicio="LN1IS" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default LN1IS;