import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// ccs
import {Btn, Ilustracion, Flex, ContInst, BtnCeleste} from '../../../../Css/MetodosCSS/StyledFonemica';

const instrucciones = 'Selecciona la palabra que dice el robot.';
const miniInstrucciones = 'Selecciona la palabra que dice el robot.';

const LEN10 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [randomSonido, setRandomSonido] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const play1 = () => new Audio(`/sounds/${comparar.sonido_url_1}.mp3`).play()

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, plbr) => {
        e.preventDefault();
        if(plbr !== comparar.palabra_1) {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setDisable(true);
            
            setTimeout(() => {
                setDisable(false);
            }, 1000);
        } else {
            console.log('bien');
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            setDisable(true);
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 1000);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    setDisable(false);
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN10') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-fonemica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN10",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        delete newList.id;
        delete newList.sonido_url_1;
        const shuffleList = Object.values(newList);
        
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(shuffleList);
        
        setRandomSonido(
            Object.values(newShuffleList)
        )
    }, [comparar]);

    return (
        <Fragment>
        <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
        {iniciar
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex direction="column" margin="auto">
                    <Btn
                        onClick={() => play1()}
                    >
                        <Ilustracion src={`/img/robot1.png`} width="20vw" 
                        isCorrect={isCorrect}
                        isIncorrect={isIncorrect}/>
                        
                    </Btn>
                    <audio src={`/sounds/${comparar.sonido_url_1}.mp3`} autoPlay />
                    <Flex>
                    <div>
                        <BtnCeleste
                            disabled={disable}
                            onClick={(e) => verificar(e, randomSonido[0])}
                        >
                            {randomSonido[0]}
                        </BtnCeleste>
                    </div>
                    <div>
                        <BtnCeleste
                            disabled={disable}
                            onClick={(e) => verificar(e, randomSonido[1])}
                        >   
                            {randomSonido[1]}
                        </BtnCeleste>
                    </div>
                    </Flex>
                </Flex>
                <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                    <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                </Flex>
            </Flex>
            :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default LEN10;