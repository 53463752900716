import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import BotoneraBottom from '../Compartidos/BotoneraBottom';
import useColorHelp from '../../../Hooks/useColorHelp';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { aumentarContadorConCorreccionAction } from '../../../actions/botoneraEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';
import { setearPrimerTiempoAction, setearSegundoTiempoAction } from '../../../actions/estadoEjercicioActions';

//css
import { BotoneraFixed, DivFlex, BtnSilaba, BtnSilabaLN7, ContainerFlexx, LetraaLN7, Img, BtnTiempo } from '../../Css/MetodosCSS/StyledLectoEscritura';


const LN7Todos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, instrucciones, miniInstrucciones } = props;

    const [comparar, setComparar] = useState([]);
    const [tiempoInicial, setTiempoInicial] = useState(0);
    const [tiempoInicialDos, setTiempoInicialDos] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [arrayColorUno, transformarColorUno] = useColorHelp('');
    const [arrayColorDos, transformarColorDos] = useColorHelp('');
    const [arrayColorTres, transformarColorTres] = useColorHelp('');
    const [arrayColorCuatro, transformarColorCuatro] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorConCorreccion = useSelector(state => state.ejercicio.contadorConCorreccion);
    const error = useSelector(state => state.ejercicio.error);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const letra = useSelector(state => state.ejercicio.letra);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ronda = useSelector((state) => state.ejercicio.ronda);
    const primerTiempo = useSelector((state) => state.ejercicio.primer_tiempo);
    const segundoTiempo = useSelector((state) => state.ejercicio.segundo_tiempo);

    useEffect(() => {
        if(data === undefined || contadorConCorreccion === indexes) {
            return;
        }
        if(ronda === 1){
            const newList = JSON.parse(JSON.stringify(data[contadorConCorreccion]));
            setComparar(newList)
        }
        if(ronda === 2){
            const newList = JSON.parse(JSON.stringify(data[contadorConCorreccion]));
            setComparar(newList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorConCorreccion, data, ronda]);

    useEffect(() => {
        if (ronda === 1) {
            const inicial = new Date();
            setTiempoInicial(inicial);
        }
        if (ronda === 2) {
            const inicialDos = new Date();
            setTiempoInicialDos(inicialDos);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar]);

    useEffect(() => {
        if (ronda === 1 && finalizar === true) {
            const final = new Date();
            const segundos = Math.round(date.subtract(final, tiempoInicial).toSeconds());
            const minutos = Math.floor(segundos / 60);
            const segundos2 = segundos - (Math.floor(segundos / 60) * 60)
            dispatch(setearPrimerTiempoAction(`${minutos}' ${segundos2}"`));
        }
        if (ronda === 2 && finalizar === true) {
            const final = new Date();
            const segundos = Math.round(date.subtract(final, tiempoInicialDos).toSeconds());
            const minutos = Math.floor(segundos / 60);
            const segundos2 = segundos - (Math.floor(segundos / 60) * 60)
            dispatch(setearSegundoTiempoAction(`${minutos}' ${segundos2}"`));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar.length === 0 ){
            return;
        }
        transformarColorUno(comparar.silaba_1);
        transformarColorDos(comparar.silaba_2);
        transformarColorTres(comparar.silaba_3);
        transformarColorCuatro(comparar.silaba_4);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, comparar, ayuda_lectura])

    useEffect(() => {
        if(iniciar === false && contadorConCorreccion === indexes ){
            return;
        }else if(iniciar === false && contadorConCorreccion === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorConCorreccion]);

    useEffect(() => {
        if(finalizar === true && contadorConCorreccion === indexes -1 ){
            dispatch(aumentarContadorConCorreccionAction());
        }
        if(finalizar && contadorConCorreccion === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            if(ronda === 1) {
                const informacion = {
                    config,
                    payload: {
                        'ejercicio': ejercicio,
                        'letra': letra,
                        'errores': error,
                        'tiempo': tiempoFinal,
                        'id_paciente': id_paciente,
                        'ronda': ronda,
                        sesion
                    }
                }
                dispatch( almacenarAction(informacion) ); 
                setContarTiempo([]);
                navigate("/escritorio/metodos/finalizar-ejercicio");
            } else {
                const informacion = {
                    config,
                    payload: {
                        'ejercicio': ejercicio,
                        'letra': letra,
                        'errores': error,
                        'tiempo': tiempoFinal,
                        'id_paciente': id_paciente,
                        'ronda': ronda,
                        sesion
                    }
                }
                dispatch( almacenarAction(informacion) ); 
                setContarTiempo([]);
                navigate("/escritorio/metodos/finalizar-ejercicio");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorConCorreccion]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <DivFlex height="85vh" justify='space-evenly' align='center'>
                    <ContainerFlexx direction='column' width='170px' wm='33vw' wt='30vw' wd='25vw' wlg='20vw'>
                        <Img w="210px" src={`/img/cronometro.png`} />
                        <DivFlex width='100%' justify='space-around' align='center'>
                            <BtnTiempo >
                                {primerTiempo === '' ? null : primerTiempo}
                            </BtnTiempo>
                            <BtnTiempo>
                                {segundoTiempo === '' ? null : segundoTiempo}
                            </BtnTiempo>
                        </DivFlex>
                    </ContainerFlexx>
                    <ContainerFlexx>
                        {comparar.silaba_1 === '' || arrayColorUno.length === 0 ? null :
                        <BtnSilabaLN7 $minMay={minuscula} style={{width: "auto"}} >
                            {arrayColorUno.map((item, i) =>
                                <span
                                    key={i}
                                >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                </span>
                            )}
                        </BtnSilabaLN7>
                        }
                        {comparar.silaba_2 === '' || arrayColorDos.length === 0 ? null :
                        <BtnSilabaLN7 $minMay={minuscula} style={{width: "auto"}} >
                            {arrayColorDos.map((item, i) =>
                                <span
                                    key={i}
                                >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                </span>
                            )}
                        </BtnSilabaLN7>
                        }
                        {comparar.silaba_3 === '' || arrayColorTres.length === 0 ? null :
                        <BtnSilabaLN7 $minMay={minuscula} style={{width: "auto"}} >
                            {arrayColorTres.map((item, i) =>
                                <span
                                    key={i}
                                >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                </span>
                            )}
                        </BtnSilabaLN7>
                        }
                        {comparar.silaba_4 === '' || arrayColorCuatro.length === 0 ? null :
                        <BtnSilabaLN7 $minMay={minuscula} style={{width: "auto"}} >
                            {arrayColorCuatro.map((item, i) =>
                                <span
                                    key={i}
                                >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                </span>
                            )}
                        </BtnSilabaLN7>
                        }
                    </ContainerFlexx>

                    <BotoneraFixed position='fixed' right='0' bottom='0'>
                        <BotoneraBottom aumentarConCorreccion={true} error={true} pausar={true} cantidad={indexes} />
                    </BotoneraFixed>
                </DivFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN7Todos;