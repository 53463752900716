import React, {Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import Instrucciones from '../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../Hooks/useConfigAuth';

import { aumentarContadorSecundarioAction } from '../../../../actions/botoneraEjercicioActions';
import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';
import { finalizarEjercicioAction } from '../../../../actions/estadoEjercicioActions';

import { Flex, NumeroM32B } from '../../../Css/StyledMatematica';

const data = [
    {id:'1', numero:1},
    {id:'2', numero:2},
    {id:'3', numero:3},
    {id:'4', numero:4},
    {id:'5', numero:5},
    {id:'6', numero:6},
    {id:'7', numero:7},
    {id:'8', numero:8},
    {id:'9', numero:9}
]

const PracticaMillones = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { ejercicio, instrucciones, miniInstrucciones } = props;

    const [randomNumeros, setRandomNumeros] = useState([]);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista = useSelector(state => state.ejercicio.lista);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const error = useSelector(state => state.ejercicio.error);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const indexes = Object.keys(data).length;

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(data));
        const shuffleList = newList.map(item => item.numero)
        
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const newShuffleList = shuffle(shuffleList);
        setRandomNumeros(newShuffleList)
        setIdOrden(newShuffleList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(indexes === 0){
            return;
        }
        if(contadorSecundario < indexes) {
            return;
        } else {
            dispatch( finalizarEjercicioAction() )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario]);

    useEffect(() => {
        if(contadorSecundario === 0){
            return;
        }
        if(contadorSecundario === 1){
            setErroresOrden([...erroresOrden, error]);
            return;
        }
        const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
        setErroresOrden([...erroresOrden, err]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorSecundario])

    useEffect(() => {
        if(iniciar === false && contadorSecundario === indexes ){
            return;
        }else if(iniciar === false && contadorSecundario === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorSecundario]);

    useEffect(() => {
        if(finalizar === true && contadorSecundario === indexes -1 ){
            dispatch(aumentarContadorSecundarioAction());
        }
        if(finalizar && contadorSecundario === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
            const erroresOrdenFinal = [...erroresOrden, err];

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrdenFinal,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorSecundario]);

    return (
        <Fragment>
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto" width="100%">
                        <NumeroM32B>
                            {randomNumeros[contadorSecundario]}.000.000
                        </NumeroM32B>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <Flex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </Flex>
                        <Flex>
                            <BotoneraBottom error={true} aumentarSecundario={true} />
                        </Flex>
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default PracticaMillones;