import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';

import useSelectDropwdown from '../../Hooks/useSelectDropwdown.js';
import { paisData, provinciaData, departamentoData, localidadData, nacionalidadData, nivelEstudiosData } from '../../Hooks/dropdowns.js';
import useConfigAuth from '../../Hooks/useConfigAuth';
import { editarPacienteAction } from '../../actions/editarPacienteActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';

//css
import { ContenedorFlexRow, ContenedorPrincipal, TituloDosTemp, TituloTemp, Division, ContendorPaciente, ContenedorSecundario, ContenedorGrid, LabelTemp, GridBox, DivisionVertical, BtnImg, InputTemp, FaltaDato, Selecciono } from '../Css/MetodosCSS/StyledFormPacTemp';



const DatosPaciente = () => {

    const dispatch = useDispatch();

    const [config] = useConfigAuth();

    const [incompletoEmailPaciente, setIncompletoEmailPaciente] = useState(false);
    const [incompletoNombrePaciente, setIncompletoNombrePaciente] = useState(false);
    const [incompletoApellidoPaciente, setIncompletoApellidoPaciente] = useState(false);
    const [incompletoSexoPaciente, setIncompletoSexoPaciente] = useState(false);
    const [incompletoDireccionPaciente, setIncompletoDireccionPaciente] = useState(false);
    const [incompletoNacionalidad, setIncompletoNacionalidad] = useState(false);
    const [incompletoPais, setIncompletoPais] = useState(false);
    const [incompletoProvincia, setIncompletoProvincia] = useState(false);
    const [incompletoDepartamento, setIncompletoDepartamento] = useState(false);
    const [incompletoLocalidad, setIncompletoLocalidad] = useState(false);
    const [incompletoFechaNacimientoPaciente, setIncompletoFechaNacimientoPaciente] = useState(false);
    const [incompletoColegioPaciente, setIncompletoColegioPaciente] = useState(false);
    const [incompletoCurso, setIncompletoCurso] = useState(false);
    const [incompletoRepitioAnio, setIncompletoRepitioAnio] = useState(false);
    const [incompletoDocumentoIdentidad, setIncompletoDocumentoIdentidad] = useState(false);
    const [incompletoCoberturaMedica, setIncompletoCoberturaMedica] = useState(false);
    const [incompletoPlanPaciente, setIncompletoPlanPaciente] = useState(false);
    const [incompletoCarnet, setIncompletoCarnet] = useState(false);
    const [incompletoMedicoPaciente, setIncompletoMedicoPaciente] = useState(false);
    const [incompletoMedicacionPaciente, setIncompletoMedicacionPaciente] = useState(false);
    const [incompletoDiagnosticoPrevio, setIncompletoDiagnosticoPrevio] = useState(false);
    const [incompletoTerapiaActual, setIncompletoTerapiaActual] = useState(false);
    const [incompletoMotivoConsulta, setIncompletoMotivoConsulta] = useState(false);
    const [incompletoHermanosPaciente, setIncompletoHermanosPaciente] = useState(false);
    const [incompletoNombreTutor1, setIncompletoNombreTutor1] = useState(false);
    const [incompletoApellidoTutor1, setIncompletoApellidoTutor1] = useState(false);
    const [incompletoFechaNacimientoTutor1, setIncompletoFechaNacimientoTutor1] = useState(false);
    const [incompletoEmailTutor1, setIncompletoEmailTutor1] = useState(false);
    const [incompletoCelTutor1, setIncompletoCelTutor1] = useState(false);
    const [incompletoDireccionTutor1, setIncompletoDireccionTutor1] = useState(false);
    const [incompletoProfesionTutor1, setIncompletoProfesionTutor1] = useState(false);
    const [incompletoOcupacionTutor1, setIncompletoOcupacionTutor1] = useState(false);
    const [incompletoEstadoCivilTutor1, setIncompletoEstadoCivilTutor1] = useState(false);
    const [incompletoNombreTutor2, setIncompletoNombreTutor2] = useState(false);
    const [incompletoApellidoTutor2, setIncompletoApellidoTutor2] = useState(false);
    const [incompletoFechaNacimientoTutor2, setIncompletoFechaNacimientoTutor2] = useState(false);
    const [incompletoEmailTutor2, setIncompletoEmailTutor2] = useState(false);
    const [incompletoCelTutor2, setIncompletoCelTutor2] = useState(false);
    const [incompletoDireccionTutor2, setIncompletoDireccionTutor2] = useState(false);
    const [incompletoProfesionTutor2, setIncompletoProfesionTutor2] = useState(false);
    const [incompletoOcupacionTutor2, setIncompletoOcupacionTutor2] = useState(false);
    const [incompletoEstadoCivilTutor2, setIncompletoEstadoCivilTutor2] = useState(false);
    const [incompletoPaisTutor1, setIncompletoPaisTutor1] = useState(false);
    const [incompletoProvinciaTutor1, setIncompletoProvinciaTutor1] = useState(false);
    const [incompletoDepartamentoTutor1, setIncompletoDepartamentoTutor1] = useState(false);
    const [incompletoLocalidadTutor1, setIncompletoLocalidadTutor1] = useState(false);
    const [incompletoNivelEstudiosTutor1, setIncompletoNivelEstudiosTutor1] = useState(false);
    const [incompletoPaisTutor2, setIncompletoPaisTutor2] = useState(false);
    const [incompletoProvinciaTutor2, setIncompletoProvinciaTutor2] = useState(false);
    const [incompletoDepartamentoTutor2, setIncompletoDepartamentoTutor2] = useState(false);
    const [incompletoLocalidadTutor2, setIncompletoLocalidadTutor2] = useState(false);
    const [incompletoNivelEstudiosTutor2, setIncompletoNivelEstudiosTutor2] = useState(false);
    const [incompletoMarketing, setIncompletoMarketing] = useState(false);
    const [editarEmailPaciente, setEditarEmailPaciente] = useState(false);
    const [editarNombrePaciente, setEditarNombrePaciente] = useState(false);
    const [editarApellidoPaciente, setEditarApellidoPaciente] = useState(false);
    const [editarSexoPaciente, setEditarSexoPaciente] = useState(false);
    const [editarDireccionPaciente, setEditarDireccionPaciente] = useState(false);
    const [editarFechaNacimientoPaciente, setEditarFechaNacimientoPaciente] = useState(false);
    const [editarCursoPaciente, setEditarCursoPaciente] = useState(false);
    const [editarColegioPaciente, setEditarColegioPaciente] = useState(false);
    const [editarRepitioAnio, setEditarRepitioAnio] = useState(false);
    const [editarDocumentoIdentidad, setEditarDocumentoIdentidad] = useState(false);
    const [editarCoberturaMedica, setEditarCoberturaMedica] = useState(false);
    const [editarPlanPaciente, setEditarPlanPaciente] = useState(false);
    const [editarCarnet, setEditarCarnet] = useState(false);
    const [editarMedicoPaciente, setEditarMedicoPaciente] = useState(false);
    const [editarMedicacionPaciente, setEditarMedicacionPaciente] = useState(false);
    const [editarDiagnosticoPrevio, setEditarDiagnosticoPrevio] = useState(false);
    const [editarMotivoConsulta, setEditarMotivoConsulta] = useState(false);
    const [editarTerapiaActual, setEditarTerapiaActual] = useState(false);
    const [editarHermanosPaciente, setEditarHermanosPaciente] = useState(false);
    const [editarNombreTutor1, setEditarNombreTutor1] = useState(false);
    const [editarApellidoTutor1, setEditarApellidoTutor1] = useState(false);
    const [editarFechaNacimientoTutor1, setEditarFechaNacimientoTutor1] = useState(false);
    const [editarEmailTutor1, setEditarEmailTutor1] = useState(false);
    const [editarCelTutor1, setEditarCelTutor1] = useState(false);
    const [editarDireccionTutor1, setEditarDireccionTutor1] = useState(false);
    const [editarProfesionTutor1, setEditarProfesionTutor1] = useState(false);
    const [editarOcupacionTutor1, setEditarOcupacionTutor1] = useState(false);
    const [editarEstadoCivilTutor1, setEditarEstadoCivilTutor1] = useState(false);
    const [editarNombreTutor2, setEditarNombreTutor2] = useState(false);
    const [editarApellidoTutor2, setEditarApellidoTutor2] = useState(false);
    const [editarFechaNacimientoTutor2, setEditarFechaNacimientoTutor2] = useState(false);
    const [editarEmailTutor2, setEditarEmailTutor2] = useState(false);
    const [editarCelTutor2, setEditarCelTutor2] = useState(false);
    const [editarDireccionTutor2, setEditarDireccionTutor2] = useState(false);
    const [editarProfesionTutor2, setEditarProfesionTutor2] = useState(false);
    const [editarOcupacionTutor2, setEditarOcupacionTutor2] = useState(false);
    const [editarEstadoCivilTutor2, setEditarEstadoCivilTutor2] = useState(false);
    const [editarMarketing, setEditarMarketing] = useState(false);
    const [editarNacionalidad, setEditarNacionalidad] = useState(false);
    const [editarPais, setEditarPais] = useState(false);
    const [editarProvincia, setEditarProvincia] = useState(false);
    const [editarDepartamento, setEditarDepartamento] = useState(false);
    const [editarLocalidad, setEditarLocalidad] = useState(false);
    const [editarPaisTutor1, setEditarPaisTutor1] = useState(false);
    const [editarProvinciaTutor1, setEditarProvinciaTutor1] = useState(false);
    const [editarDepartamentoTutor1, setEditarDepartamentoTutor1] = useState(false);
    const [editarLocalidadTutor1, setEditarLocalidadTutor1] = useState(false);
    const [editarNivelEstudiosTutor1, setEditarNivelEstudiosTutor1] = useState(false);
    const [editarPaisTutor2, setEditarPaisTutor2] = useState(false);
    const [editarProvinciaTutor2, setEditarProvinciaTutor2] = useState(false);
    const [editarDepartamentoTutor2, setEditarDepartamentoTutor2] = useState(false);
    const [editarLocalidadTutor2, setEditarLocalidadTutor2] = useState(false);
    const [editarNivelEstudiosTutor2, setEditarNivelEstudiosTutor2] = useState(false);

    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const pacientes = useSelector((state) => state.informacion.lista_pacientes);

    const {email, nombre, apellido, sexo, edad, nacionalidad, pais, provincia, departamento, localidad, direccion, fecha_nacimiento, colegio, curso, repitio_anio, dni, cobertura_medica, plan, numero_afiliado, medico, medicacion, diagnostico_previo, motivo_consulta, terapias_actuales, hermanos, nombre_1, apellido_1, fecha_nacimiento_1, edad_1, email_1, telefono_1, pais_1, provincia_1, departamento_1, localidad_1, direccion_1, nivel_estudios_1, profesion_1, ocupacion_1, estado_civil_1, nombre_2, apellido_2, fecha_nacimiento_2, edad_2, email_2, telefono_2, pais_2, provincia_2, departamento_2, localidad_2, direccion_2, nivel_estudios_2, profesion_2, ocupacion_2, estado_civil_2, conocieron, profesional_tratamiento, servicio } = pacientes.find(item => item.id_paciente === id_paciente);

    const emailPaciente = useRef();
    const nombrePaciente = useRef();
    const apellidoPaciente = useRef();
    const sexoPaciente = useRef();
    const direccionPaciente = useRef();
    const fechaNacimientoPaciente = useRef();
    const colegioPaciente = useRef();
    const cursoPaciente = useRef();
    const repitioAnio = useRef();
    const documentoIdentidad = useRef();
    const coberturaMedica = useRef();
    const planPaciente = useRef();
    const carnet = useRef();
    const medicoPaciente = useRef();
    const medicacionPaciente = useRef();
    const diagnosticoPrevio = useRef();
    const motivoConsulta = useRef();
    const terapiaActual = useRef();
    const hermanosPaciente = useRef();
    const nombreTutor1 = useRef();
    const apellidoTutor1 = useRef();
    const fechaNacimientoTutor1 = useRef();
    const emailTutor1 = useRef();
    const celTutor1 = useRef();
    const direccionTutor1 = useRef();
    const profesionTutor1 = useRef();
    const ocupacionTutor1 = useRef();
    const estadoCivilTutor1 = useRef();
    const nombreTutor2 = useRef();
    const apellidoTutor2 = useRef();
    const fechaNacimientoTutor2 = useRef();
    const emailTutor2 = useRef();
    const celTutor2 = useRef();
    const direccionTutor2 = useRef();
    const profesionTutor2 = useRef();
    const ocupacionTutor2 = useRef();
    const estadoCivilTutor2 = useRef();
    const marketing = useRef();

    const [paisPaciente, SelectDropdownPais] = useSelectDropwdown('Pais', paisData, pais, false);
    const [provinciaPaciente, SelectDropdownProvincia] = useSelectDropwdown('Provincia', provinciaData,provincia,true,paisPaciente);
    const [departamentoPaciente, SelectDropdownDepartamento] = useSelectDropwdown('Departamento', departamentoData,departamento,true,provinciaPaciente);
    const [localidadPaciente, SelectDropdownLocalidad] = useSelectDropwdown('Localidad', localidadData,localidad,true,departamentoPaciente);
    const [nacionalidadPaciente, SelectDropdownNacionalidad] = useSelectDropwdown('Nacionalidad', nacionalidadData, nacionalidad);
    const [paisTutor1, SelectDropdownPaisTutor1] = useSelectDropwdown('Pais', paisData,pais_1,false);
    const [provinciaTutor1, SelectDropdownProvinciaTutor1] = useSelectDropwdown('Provincia', provinciaData,provincia_1,true,paisTutor1);
    const [departamentoTutor1, SelectDropdownDepartamentoTutor1] = useSelectDropwdown('Departamento', departamentoData,departamento_1,true,provinciaTutor1);
    const [localidadTutor1, SelectDropdownLocalidadTutor1] = useSelectDropwdown('Localidad', localidadData,localidad_1,true,departamentoTutor1);
    const [paisTutor2, SelectDropdownPaisTutor2] = useSelectDropwdown('Pais', paisData, pais_2, false);
    const [provinciaTutor2, SelectDropdownProvinciaTutor2] = useSelectDropwdown('Provincia', provinciaData,provincia_2,true,paisTutor2);
    const [departamentoTutor2, SelectDropdownDepartamentoTutor2] = useSelectDropwdown('Departamento', departamentoData,departamento_2,true,provinciaTutor2);
    const [localidadTutor2, SelectDropdownLocalidadTutor2] = useSelectDropwdown('Localidad', localidadData,localidad_2,true,departamentoTutor2);
    const [nivelEstudiosTutor1, SelectDropdownNivelEstudiosTutor1] = useSelectDropwdown('Estudios', nivelEstudiosData, nivel_estudios_1);
    const [nivelEstudiosTutor2, SelectDropdownNivelEstudiosTutor2] = useSelectDropwdown('Estudios', nivelEstudiosData, nivel_estudios_2);

    const guardarNombrePaciente = (e) => {
        e.preventDefault();
        if(nombrePaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoNombrePaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'nombre': nombrePaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarNombrePaciente(false);
    }
    const guardarApellidoPaciente = (e) => {
        e.preventDefault();
        if(apellidoPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoApellidoPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'apellido': apellidoPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarApellidoPaciente(false);
    }
    const guardarSexoPaciente = (e) => {
        e.preventDefault();
        if(sexoPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoSexoPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'sexo': sexoPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarSexoPaciente(false);
    }
    const guardarEmailPaciente = (e) => {
        e.preventDefault();
        if(emailPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoEmailPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'email': emailPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarEmailPaciente(false);
    }
    const guardarDireccionPaciente = (e) => {
        e.preventDefault();
        if(direccionPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoDireccionPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'direccion': direccionPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarDireccionPaciente(false);
    }
    const guardarFechaNacimientoPaciente = (e) => {
        e.preventDefault();
        if(fechaNacimientoPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoFechaNacimientoPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'fecha_nacimiento': fechaNacimientoPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarFechaNacimientoPaciente(false);
    }
    const guardarColegioPaciente = (e) => {
        e.preventDefault();
        if(colegioPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoColegioPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'colegio': colegioPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarColegioPaciente(false);
    }    
    const guardarCursoPaciente = (e) => {
        e.preventDefault();
        if(cursoPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoCurso(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'fecha_nacimiento': cursoPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarCursoPaciente(false);
    }
    const guardarRepitioAnio = (e) => {
        e.preventDefault();
        if(repitioAnio.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoRepitioAnio(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'repitio_anio': repitioAnio.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarRepitioAnio(false);
    }
    const guardarDocumentoIdentidad = (e) => {
        e.preventDefault();
        if(documentoIdentidad.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoDocumentoIdentidad(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'dni': documentoIdentidad.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarDocumentoIdentidad(false);
    }
    const guardarCoberturaMedica = (e) => {
        e.preventDefault();
        if(coberturaMedica.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoCoberturaMedica(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'cobertura_medica': coberturaMedica.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarCoberturaMedica(false);
    }
    const guardarPlanPaciente = (e) => {
        e.preventDefault();
        if(planPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoPlanPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'plan': planPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarPlanPaciente(false);
    }
    const guardarCarnet = (e) => {
        e.preventDefault();
        if(carnet.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoCarnet(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'numero_afiliado': carnet.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarCarnet(false);
    }
    const guardarMedicoPaciente = (e) => {
        e.preventDefault();
        if(medicoPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoMedicoPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'medico': medicoPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarMedicoPaciente(false);
    }
    const guardarMedicacionPaciente = (e) => {
        e.preventDefault();
        if(medicacionPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoMedicacionPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'medicacion': medicacionPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarMedicacionPaciente(false);
    }
    const guardarDiagnosticoPrevio = (e) => {
        e.preventDefault();
        if(diagnosticoPrevio.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoDiagnosticoPrevio(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'diagnostico_previo': diagnosticoPrevio.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarDiagnosticoPrevio(false);
    }
    const guardarMotivoConsulta = (e) => {
        e.preventDefault();
        if(motivoConsulta.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoMotivoConsulta(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'motivo_consulta': motivoConsulta.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarMotivoConsulta(false);
    }
    const guardarTerapiaActual = (e) => {
        e.preventDefault();
        if(terapiaActual.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoTerapiaActual(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'terapias_actuales':terapiaActual.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarTerapiaActual(false);
    }
    const guardarHermanosPaciente = (e) => {
        e.preventDefault();
        if(hermanosPaciente.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoHermanosPaciente(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'hermanos':hermanosPaciente.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarHermanosPaciente(false);
    }
    const guardarEmailTutor1 = (e) => {
        e.preventDefault();
        if(emailTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoEmailTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'email_1':emailTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarEmailTutor1(false);
    }
    const guardarNombreTutor1 = (e) => {
        e.preventDefault();
        if(nombreTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoNombreTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'nombre_1':nombreTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarNombreTutor1(false);
    }
    const guardarApellidoTutor1 = (e) => {
        e.preventDefault();
        if(apellidoTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoApellidoTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'apellido_1':apellidoTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarApellidoTutor1(false);
    }
    const guardarFechaNacimientoTutor1 = (e) => {
        e.preventDefault();
        if(fechaNacimientoTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoFechaNacimientoTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'fecha_nacimiento_1':fechaNacimientoTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarFechaNacimientoTutor1(false);
    }
    const guardarCelTutor1 = (e) => {
        e.preventDefault();
        if(celTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoCelTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'telefono_1':celTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarCelTutor1(false);
    }
    const guardarDireccionTutor1 = (e) => {
        e.preventDefault();
        if(direccionTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoDireccionTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'direccion_1':direccionTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarDireccionTutor1(false);
    }
    const guardarProfesionTutor1 = (e) => {
        e.preventDefault();
        if(profesionTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoProfesionTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'profesion_1':profesionTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarProfesionTutor1(false);
    }
    const guardarOcupacionTutor1 = (e) => {
        e.preventDefault();
        if(ocupacionTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoOcupacionTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'ocupacion_1':ocupacionTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarOcupacionTutor1(false);
    }
    const guardarEstadoCivilTutor1 = (e) => {
        e.preventDefault();
        if(estadoCivilTutor1.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoEstadoCivilTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'estado_civil_1':estadoCivilTutor1.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarEstadoCivilTutor1(false);
    }
    const guardarEmailTutor2 = (e) => {
        e.preventDefault();
        if(emailTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoEmailTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'email_2':emailTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarEmailTutor2(false);
    }
    const guardarNombreTutor2 = (e) => {
        e.preventDefault();
        if(nombreTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoNombreTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'nombre_2':nombreTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarNombreTutor2(false);
    }
    const guardarApellidoTutor2 = (e) => {
        e.preventDefault();
        if(apellidoTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoApellidoTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'apellido_2':apellidoTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarApellidoTutor2(false);
    }
    const guardarFechaNacimientoTutor2 = (e) => {
        e.preventDefault();
        if(fechaNacimientoTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoFechaNacimientoTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'fecha_nacimiento_2':fechaNacimientoTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarFechaNacimientoTutor2(false);
    }
    const guardarCelTutor2 = (e) => {
        e.preventDefault();
        if(celTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoCelTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'telefono_2':celTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarCelTutor2(false);
    }
    const guardarDireccionTutor2 = (e) => {
        e.preventDefault();
        if(direccionTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoDireccionTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'direccion_2':direccionTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarDireccionTutor2(false);
    }
    const guardarProfesionTutor2 = (e) => {
        e.preventDefault();
        if(profesionTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoProfesionTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'profesion_2':profesionTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarProfesionTutor2(false);
    }
    const guardarOcupacionTutor2 = (e) => {
        e.preventDefault();
        if(ocupacionTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoOcupacionTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'ocupacion_2':ocupacionTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarOcupacionTutor2(false);
    }
    const guardarEstadoCivilTutor2 = (e) => {
        e.preventDefault();
        if(estadoCivilTutor2.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoEstadoCivilTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'estado_civil_2':estadoCivilTutor2.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarEstadoCivilTutor2(false);
    }
    const guardarMarketing = (e) => {
        e.preventDefault();
        if(marketing.current.value.trim() === ''){
            const alerta = {msg: 'Todos los campos son obligatorios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoMarketing(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'conocieron':marketing.current.value, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarMarketing(false);
    }
    const guardarNacionalidad = (e) => {
        e.preventDefault();
        if(nacionalidadPaciente === 'Nacionalidad'){
            const alerta = {msg: 'Elegir una Nacionalidad'}
            dispatch( setearAlerta (alerta) );
            setIncompletoNacionalidad(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'nacionalidad':nacionalidadPaciente, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarNacionalidad(false);
    }
    const guardarPais = (e) => {
        e.preventDefault();
        if(paisPaciente === 'Pais'){
            const alerta = {msg: 'Elegir un País'}
            dispatch( setearAlerta (alerta) );
            setIncompletoPais(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'pais':paisPaciente, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarPais(false);
    }
    const guardarProvincia = (e) => {
        e.preventDefault();
        if(provinciaPaciente === 'Provincia'){
            const alerta = {msg: 'Elegir un Provincia'}
            dispatch( setearAlerta (alerta) );
            setIncompletoProvincia(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'provincia':provinciaPaciente, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarProvincia(false);
    }
    const guardarDepartamento = (e) => {
        e.preventDefault();
        if(departamentoPaciente === 'Departamento'){
            const alerta = {msg: 'Elegir una Ciudad'}
            dispatch( setearAlerta (alerta) );
            setIncompletoDepartamento(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'ciudad':departamentoPaciente, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarDepartamento(false);
    }
    const guardarLocalidad = (e) => {
        e.preventDefault();
        if(localidadPaciente === 'Localidad'){
            const alerta = {msg: 'Elegir una Localidad'}
            dispatch( setearAlerta (alerta) );
            setIncompletoLocalidad(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'localidad':localidadPaciente, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarLocalidad(false);
    }
    const guardarPaisTutor1 = (e) => {
        e.preventDefault();
        if(paisTutor1 === 'País'){
            const alerta = {msg: 'Elegir una País'}
            dispatch( setearAlerta (alerta) );
            setIncompletoPaisTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'pais_1':paisTutor1, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarPaisTutor1(false);
    }
    const guardarProvinciaTutor1 = (e) => {
        e.preventDefault();
        if(provinciaTutor1 === 'Provincia'){
            const alerta = {msg: 'Elegir una Provincia'}
            dispatch( setearAlerta (alerta) );
            setIncompletoProvinciaTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'provincia_1':provinciaTutor1, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarProvinciaTutor1(false);
    }
    const guardarDepartamentoTutor1 = (e) => {
        e.preventDefault();
        if(departamentoTutor1 === 'Ciudad'){
            const alerta = {msg: 'Elegir una Ciudad'}
            dispatch( setearAlerta (alerta) );
            setIncompletoDepartamentoTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'ciudad_1':departamentoTutor1, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarDepartamentoTutor1(false);
    }
    const guardarLocalidadTutor1 = (e) => {
        e.preventDefault();
        if(localidadTutor1 === 'Localidad'){
            const alerta = {msg: 'Elegir una Localidad'}
            dispatch( setearAlerta (alerta) );
            setIncompletoLocalidadTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'localidad_1':localidadTutor1, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarLocalidadTutor1(false);
    }
    const guardarNivelEstudiosTutor1 = (e) => {
        e.preventDefault();
        if(nivelEstudiosTutor1 === ''){
            const alerta = {msg: 'Elegir el nivel de estudios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoNivelEstudiosTutor1(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'nivel_estudios_1':nivelEstudiosTutor1, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarNivelEstudiosTutor1(false);
    }
    const guardarPaisTutor2 = (e) => {
        e.preventDefault();
        if(paisTutor2 === 'País'){
            const alerta = {msg: 'Elegir una País'}
            dispatch( setearAlerta (alerta) );
            setIncompletoPaisTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'pais_2':paisTutor2, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarPaisTutor2(false);
    }
    const guardarProvinciaTutor2 = (e) => {
        e.preventDefault();
        if(provinciaTutor2 === 'Provincia'){
            const alerta = {msg: 'Elegir una Provincia'}
            dispatch( setearAlerta (alerta) );
            setIncompletoProvinciaTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'provincia_2':provinciaTutor2, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarProvinciaTutor2(false);
    }
    const guardarDepartamentoTutor2 = (e) => {
        e.preventDefault();
        if(departamentoTutor2 === 'Ciudad'){
            const alerta = {msg: 'Elegir una Ciudad'}
            dispatch( setearAlerta (alerta) );
            setIncompletoDepartamentoTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'ciudad_2':departamentoTutor2, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarDepartamentoTutor2(false);
    }
    const guardarLocalidadTutor2 = (e) => {
        e.preventDefault();
        if(localidadTutor2 === 'Localidad'){
            const alerta = {msg: 'Elegir una Localidad'}
            dispatch( setearAlerta (alerta) );
            setIncompletoLocalidadTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'localidad_2':localidadTutor2, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarLocalidadTutor2(false);
    }
    const guardarNivelEstudiosTutor2 = (e) => {
        e.preventDefault();
        if(nivelEstudiosTutor2 === ''){
            const alerta = {msg: 'Elegir el nivel de estudios'}
            dispatch( setearAlerta (alerta) );
            setIncompletoNivelEstudiosTutor2(true);
        }
        dispatch( ocultarAlertaAction());
        const informacion = {config, payload: {'nivel_estudios_2':nivelEstudiosTutor2, id_paciente}};
        dispatch( editarPacienteAction(informacion) );
        setEditarNivelEstudiosTutor2(false);
    }

    return ( 
        <ContenedorPrincipal>
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes/paciente`} componente={"Información paciente"} paciente={false} />
            <ContenedorFlexRow $justifycontent="space-evenly" $margin="0 0 2rem 0" >
                <TituloDosTemp $fontsize="1.1rem" >Profesional que lo trata: {profesional_tratamiento}</TituloDosTemp>
                <TituloDosTemp $fontsize="1.1rem" >Servicio: {servicio}</TituloDosTemp>
            </ContenedorFlexRow>
            
            <ContenedorSecundario>
                <ContendorPaciente>
                    <TituloTemp $color="#23AAAF">Datos del Paciente</TituloTemp>
                    <Division $border="1px solid black" />
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="22% 68% 10%" $alignitems="center" >
                            <LabelTemp htmlFor="nombre" >Nombre:</LabelTemp>
                            {!editarNombrePaciente  
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center" >
                                    {nombre}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarNombrePaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center" >
                                    <InputTemp 
                                        disabled={!editarNombrePaciente}
                                        type="text"
                                        id="nombrePaciente"
                                        name="nombrePaciente"
                                        ref={nombrePaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarNombrePaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarNombrePaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoNombrePaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="22% 68% 10%">
                            <LabelTemp htmlFor="apellido" >Apellido:</LabelTemp>
                            {!editarApellidoPaciente
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {apellido}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarApellidoPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarApellidoPaciente}
                                        type="text"
                                        id="apellidoPaciente"
                                        name="apellidoPaciente"
                                        ref={apellidoPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarApellidoPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarApellidoPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoApellidoPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="35% 2% 63%">
                        <ContenedorGrid $disposicion="22% 68% 10%" $alignitems="center" >
                            <LabelTemp htmlFor="sexoPaciente">Sexo:</LabelTemp>
                            {!editarSexoPaciente  
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center" >
                                    {sexo}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarSexoPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center" >
                                    <Selecciono ref={sexoPaciente}>
                                        <option></option>
                                        <option>Masculino</option>
                                        <option>Femenino</option>
                                    </Selecciono>
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarSexoPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarSexoPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoSexoPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="20% 35% 35% 10%" $alignitems="center">
                            <LabelTemp>Edad: {edad}</LabelTemp>
                            <LabelTemp htmlFor="fechaNacimientoPaciente">Fecha Nacimiento:</LabelTemp>
                            {!editarFechaNacimientoPaciente
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {fecha_nacimiento}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarFechaNacimientoPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarFechaNacimientoPaciente}
                                        type="date"
                                        id="fechaNacimientoPaciente"
                                        name="fechaNacimientoPaciente"
                                        ref={fechaNacimientoPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarFechaNacimientoPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarFechaNacimientoPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoFechaNacimientoPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="32% 2% 32% 2% 32%">
                        <ContenedorGrid  $alignitems="center" >
                            {!editarNacionalidad
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownNacionalidad disable={true} incompleto={incompletoNacionalidad} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarNacionalidad(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownNacionalidad incompleto={incompletoNacionalidad} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarNacionalidad(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarNacionalidad(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid  $alignitems="center" >
                            {!editarPais
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownPais disable={true} incompleto={incompletoPais} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarPais(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownPais incompleto={incompletoPais} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarPais(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarPais(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid  $alignitems="center" >
                            {!editarProvincia
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownProvincia disable={true} incompleto={incompletoProvincia} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarProvincia(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownProvincia incompleto={incompletoProvincia} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarProvincia(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarProvincia(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid  $alignitems="center" >
                            {!editarDepartamento
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownDepartamento disable={true} incompleto={incompletoDepartamento} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarDepartamento(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownDepartamento incompleto={incompletoDepartamento} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarDepartamento(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarDepartamento(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid  $alignitems="center" >
                            {!editarLocalidad
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownLocalidad disable={true} incompleto={incompletoLocalidad} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarLocalidad(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownLocalidad incompleto={incompletoLocalidad} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarLocalidad(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarLocalidad(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="100%">
                        <ContenedorGrid $disposicion="15% 75% 10%" $alignitems="center" >
                            <LabelTemp htmlFor="direccion">Direccion:</LabelTemp>
                            {!editarDireccionPaciente  
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center" >
                                    {direccion}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarDireccionPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="70% 15% 15%" $alignitems="center" >
                                    <InputTemp
                                        disabled={!editarDireccionPaciente}
                                        type="text"
                                        id="direccionPaciente"
                                        name="direccionPaciente"
                                        ref={direccionPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarDireccionPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarDireccionPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoDireccionPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="100%">
                        <ContenedorGrid $disposicion="15% 75% 10%" $alignitems="center" >
                            <LabelTemp htmlFor="emailPaciente">Email:</LabelTemp>
                            {!editarEmailPaciente  
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center" >
                                    {email}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarEmailPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="70% 15% 15%" $alignitems="center" >
                                    <InputTemp
                                        disabled={!editarEmailPaciente}
                                        type="email"
                                        id="emailPaciente"
                                        name="emailPaciente"
                                        ref={emailPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarEmailPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarEmailPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoEmailPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="20% 70% 10%" $alignitems="center" >
                            <LabelTemp htmlFor="colegio">Colegio:</LabelTemp>
                            {!editarColegioPaciente  
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center" >
                                    {colegio}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarColegioPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center" >
                                    <InputTemp 
                                        disabled={!editarColegioPaciente}
                                        type="text"
                                        id="colegioPaciente"
                                        name="colegioPaciente"
                                        ref={colegioPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarColegioPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarColegioPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoColegioPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="20% 70% 10%">
                            <LabelTemp htmlFor="curso">Curso:</LabelTemp>
                            {!editarCursoPaciente
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {apellido}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarCursoPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarCursoPaciente}
                                        type="text"
                                        id="cursoPaciente"
                                        name="cursoPaciente"
                                        ref={cursoPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarCursoPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarCursoPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoCurso ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="40% 50% 10%" $alignitems="center" >
                            <LabelTemp htmlFor="repitioAnio">Repitió Año?:</LabelTemp>
                            {!editarRepitioAnio  
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center" >
                                    {repitio_anio}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarRepitioAnio(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center" >
                                    <Selecciono ref={repitioAnio}>
                                        <option></option>
                                        <option>Si</option>
                                        <option>No</option>
                                    </Selecciono>
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarRepitioAnio(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarRepitioAnio(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoRepitioAnio ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="40% 50% 10%">
                            <LabelTemp htmlFor="documentoIdentidad">Documento de Identidad:</LabelTemp>
                            {!editarDocumentoIdentidad
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {dni}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarDocumentoIdentidad(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarDocumentoIdentidad}
                                        type="numeric"
                                        id="documentoIdentidad"
                                        name="documentoIdentidad"
                                        ref={documentoIdentidad}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarDocumentoIdentidad(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarDocumentoIdentidad(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoDocumentoIdentidad ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="35% 55% 10%">
                            <LabelTemp htmlFor="coberturaMedica">Obra Social:</LabelTemp>
                            {!editarCoberturaMedica
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {cobertura_medica}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarCoberturaMedica(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarCoberturaMedica}
                                        type="text"
                                        id="coberturaMedica"
                                        name="coberturaMedica"
                                        ref={coberturaMedica}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarCoberturaMedica(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarCoberturaMedica(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoCoberturaMedica ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="20% 70% 10%">
                            <LabelTemp htmlFor="plan">Plan:</LabelTemp>
                            {!editarPlanPaciente
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {plan}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarPlanPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarPlanPaciente}
                                        type="text"
                                        id="planPaciente"
                                        name="planPaciente"
                                        ref={planPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarPlanPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarPlanPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoPlanPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="22% 68% 10%">
                            <LabelTemp htmlFor="carnet">Carnet:</LabelTemp>
                            {!editarCarnet
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {numero_afiliado}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarCarnet(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarCarnet}
                                        type="text"
                                        id="carnet"
                                        name="carnet"
                                        ref={carnet}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarCarnet(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarCarnet(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoCarnet ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="20% 70% 10%">
                            <LabelTemp htmlFor="medico">Médico:</LabelTemp>
                            {!editarMedicoPaciente
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {medico}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarMedicoPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarMedicoPaciente}
                                        type="text"
                                        id="medicoPaciente"
                                        name="medicoPaciente"
                                        ref={medicoPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarMedicoPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarMedicoPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoMedicoPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="medicacion">Medicación:</LabelTemp>
                            {!editarMedicacionPaciente
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {medicacion}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarMedicacionPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarMedicacionPaciente}
                                        type="text"
                                        id="medicacionPaciente"
                                        name="medicacionPaciente"
                                        ref={medicacionPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarMedicacionPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarMedicacionPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoMedicacionPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="diagnosticoPrevio">Diagnóstico Previo:</LabelTemp>
                            {!editarDiagnosticoPrevio
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {diagnostico_previo}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarDiagnosticoPrevio(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarDiagnosticoPrevio}
                                        type="text"
                                        id="diagnosticoPrevio"
                                        name="diagnosticoPrevio"
                                        ref={diagnosticoPrevio}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarDiagnosticoPrevio(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarDiagnosticoPrevio(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoDiagnosticoPrevio ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="terapiaActual">Terapia Actual:</LabelTemp>
                            {!editarTerapiaActual
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {terapias_actuales}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarTerapiaActual(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarTerapiaActual}
                                        type="text"
                                        id="terapiaActual"
                                        name="terapiaActual"
                                        ref={terapiaActual}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarTerapiaActual(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarTerapiaActual(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoTerapiaActual ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="hermanos">Hermanos:</LabelTemp>
                            {!editarHermanosPaciente
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {hermanos}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarHermanosPaciente(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarHermanosPaciente}
                                        type="text"
                                        id="hermanosPaciente"
                                        name="hermanosPaciente"
                                        ref={hermanosPaciente}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarHermanosPaciente(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarHermanosPaciente(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoHermanosPaciente ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="100%">
                        <ContenedorGrid $disposicion="22% 68% 10%">
                            <LabelTemp htmlFor="motivoConsulta">Motivo Consulta:</LabelTemp>
                            {!editarMotivoConsulta
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {motivo_consulta}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarMotivoConsulta(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarMotivoConsulta}
                                        type="text"
                                        id="motivoConsulta"
                                        name="motivoConsulta"
                                        ref={motivoConsulta}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarMotivoConsulta(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarMotivoConsulta(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoMotivoConsulta ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <TituloTemp $margin="1.5rem 0 1.5rem 0" $color="#23AAAF" >Datos padres/tutores</TituloTemp>
                    <GridBox $disposicion="50% 50%" $textalign="center" >
                        <TituloDosTemp>Padre/Tutor 1</TituloDosTemp>
                        <TituloDosTemp>Madre/Tutor 2</TituloDosTemp>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="nombreTutor1">Nombre:</LabelTemp>
                            {!editarNombreTutor1
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {nombre_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarNombreTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarNombreTutor1}
                                        type="text"
                                        id="nombreTutor1"
                                        name="nombreTutor1"
                                        ref={nombreTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarNombreTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarNombreTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoNombreTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="nombreTutor2">Nombre:</LabelTemp>
                            {!editarNombreTutor2
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {nombre_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarNombreTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarNombreTutor2}
                                        type="text"
                                        id="nombreTutor2"
                                        name="nombreTutor2"
                                        ref={nombreTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarNombreTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarNombreTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoNombreTutor2 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="apellidoTutor1">Apellido:</LabelTemp>
                            {!editarApellidoTutor1
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {apellido_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarApellidoTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarApellidoTutor1}
                                        type="text"
                                        id="apellidoTutor1"
                                        name="apellidoTutor1"
                                        ref={apellidoTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarApellidoTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarApellidoTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoApellidoTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="apellidoTutor2">Apellido:</LabelTemp>
                            {!editarApellidoTutor2
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {apellido_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarApellidoTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarApellidoTutor2}
                                        type="text"
                                        id="apellidoTutor2"
                                        name="apellidoTutor2"
                                        ref={apellidoTutor2}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarApellidoTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarApellidoTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoApellidoTutor2 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="fechaNacimientoTutor1">Fecha Nacimiento:</LabelTemp>
                            {!editarFechaNacimientoTutor1
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {fecha_nacimiento_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarFechaNacimientoTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarFechaNacimientoTutor1}
                                        type="date"
                                        id="fechaNacimientoTutor1"
                                        name="fechaNacimientoTutor1"
                                        ref={fechaNacimientoTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarFechaNacimientoTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarFechaNacimientoTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoFechaNacimientoTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="fechaNacimientoTutor2">Fecha Nacimiento:</LabelTemp>
                            {!editarFechaNacimientoTutor2
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {fecha_nacimiento_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarFechaNacimientoTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarFechaNacimientoTutor2}
                                        type="date"
                                        id="fechaNacimientoTutor2"
                                        name="fechaNacimientoTutor2"
                                        ref={fechaNacimientoTutor2}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarFechaNacimientoTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarFechaNacimientoTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoFechaNacimientoTutor2 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <LabelTemp>Edad: {edad_1}</LabelTemp>
                        <DivisionVertical />
                        <LabelTemp>Edad: {edad_2}</LabelTemp>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="18% 72% 10%">
                            <LabelTemp htmlFor="emailTutor1">Email:</LabelTemp>
                            {!editarEmailTutor1
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {email_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarEmailTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarEmailTutor1}
                                        type="email"
                                        id="emailTutor1"
                                        name="emailTutor1"
                                        ref={emailTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarEmailTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarEmailTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoEmailTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="18% 72% 10%">
                            <LabelTemp htmlFor="emailTutor2">Email:</LabelTemp>
                            {!editarEmailTutor2
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {email_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarEmailTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarEmailTutor2}
                                        type="email"
                                        id="emailTutor2"
                                        name="emailTutor2"
                                        ref={emailTutor2}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarEmailTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarEmailTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoEmailTutor2 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="25% 65% 10%">
                            <LabelTemp htmlFor="celTutor1">Celular:</LabelTemp>
                            {!editarCelTutor1
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {telefono_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarCelTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarCelTutor1}
                                        type="number"
                                        id="celTutor1"
                                        name="celTutor1"
                                        ref={celTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarCelTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarCelTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoCelTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="25% 65% 10%">
                            <LabelTemp htmlFor="celTutor2">Celular:</LabelTemp>
                            {!editarCelTutor2
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {telefono_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarCelTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarCelTutor2}
                                        type="number"
                                        id="celTutor2"
                                        name="celTutor2"
                                        ref={celTutor2}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarCelTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarCelTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoCelTutor2 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="100%">
                            {!editarPaisTutor1
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownPaisTutor1 disable={true} incompleto={incompletoPaisTutor1} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarPaisTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownPaisTutor1 incompleto={incompletoPaisTutor1} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarPaisTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarPaisTutor1(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="100%">
                            {!editarPaisTutor2
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownPaisTutor2 disable={true} incompleto={incompletoPaisTutor2} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarPaisTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownPaisTutor2 incompleto={incompletoPaisTutor2} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarPaisTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarPaisTutor2(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="100%">
                            {!editarProvinciaTutor1
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownProvinciaTutor1 disable={true} incompleto={incompletoProvinciaTutor1} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarProvinciaTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownProvinciaTutor1 incompleto={incompletoProvinciaTutor1} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarProvinciaTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarProvinciaTutor1(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="100%">
                            {!editarProvinciaTutor2
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownProvinciaTutor2 disable={true} incompleto={incompletoProvinciaTutor2} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarProvinciaTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownProvinciaTutor2 incompleto={incompletoProvinciaTutor2} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarProvinciaTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarProvinciaTutor2(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="100%">
                            {!editarDepartamentoTutor1
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownDepartamentoTutor1 disable={true} incompleto={incompletoDepartamentoTutor1} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarDepartamentoTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownDepartamentoTutor1 incompleto={incompletoDepartamentoTutor1} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarDepartamentoTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarDepartamentoTutor1(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="100%">
                            {!editarDepartamentoTutor2
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownDepartamentoTutor2 disable={true} incompleto={incompletoDepartamentoTutor2} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarDepartamentoTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownDepartamentoTutor2 incompleto={incompletoDepartamentoTutor2} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarDepartamentoTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarDepartamentoTutor2(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="100%">
                            {!editarLocalidadTutor1
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownLocalidadTutor1 disable={true} incompleto={incompletoLocalidadTutor1} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarLocalidadTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownLocalidadTutor1 incompleto={incompletoLocalidadTutor1} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarLocalidadTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarLocalidadTutor1(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="100%">
                            {!editarLocalidadTutor2
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownLocalidadTutor2 disable={true} incompleto={incompletoLocalidadTutor2} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarLocalidadTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownLocalidadTutor2 incompleto={incompletoLocalidadTutor2} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarLocalidadTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarLocalidadTutor2(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="100%">
                        <ContenedorGrid $disposicion="15% 75% 10%" $alignitems="center" >
                            <LabelTemp htmlFor="direccionTutor1">Dirección Tutor1:</LabelTemp>
                            {!editarDireccionTutor1  
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center" >
                                    {direccion_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarDireccionTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="70% 15% 15%" $alignitems="center" >
                                    <InputTemp
                                        disabled={!editarDireccionTutor1}
                                        type="text"
                                        id="direccionTutor1"
                                        name="direccionTutor1"
                                        ref={direccionTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarDireccionTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarDireccionTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoDireccionTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="100%">
                        <ContenedorGrid $disposicion="15% 75% 10%" $alignitems="center" >
                            <LabelTemp htmlFor="direccionTutor2">Dirección Tutor2:</LabelTemp>
                            {!editarDireccionTutor2  
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center" >
                                    {direccion_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarDireccionTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="70% 15% 15%" $alignitems="center" >
                                    <InputTemp
                                        disabled={!editarDireccionTutor2}
                                        type="text"
                                        id="direccionTutor2"
                                        name="direccionTutor2"
                                        ref={direccionTutor2}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarDireccionTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarDireccionTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoDireccionTutor2 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="100%">
                            {!editarNivelEstudiosTutor1
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownNivelEstudiosTutor1 disable={true} incompleto={incompletoNivelEstudiosTutor1} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarNivelEstudiosTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownNivelEstudiosTutor1 incompleto={incompletoNivelEstudiosTutor1} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarNivelEstudiosTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarNivelEstudiosTutor1(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="100%">
                            {!editarNivelEstudiosTutor2
                            ?   <ContenedorGrid $disposicion="70% 20% 10%" $alignitems="center">
                                    <SelectDropdownNivelEstudiosTutor2 disable={true} incompleto={incompletoNivelEstudiosTutor2} />
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarNivelEstudiosTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 15% 15% 10%" $alignitems="center">
                                    <SelectDropdownNivelEstudiosTutor2 incompleto={incompletoNivelEstudiosTutor2} />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarNivelEstudiosTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarNivelEstudiosTutor2(e)} />
                                </ContenedorGrid>
                            }
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="profesionTutor1">Profesión:</LabelTemp>
                            {!editarProfesionTutor1
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {profesion_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarProfesionTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarProfesionTutor1}
                                        type="text"
                                        id="profesionTutor1"
                                        name="profesionTutor1"
                                        ref={profesionTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarProfesionTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarProfesionTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoProfesionTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="profesionTutor2">Profesión:</LabelTemp>
                            {!editarProfesionTutor2
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {profesion_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarProfesionTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarProfesionTutor2}
                                        type="text"
                                        id="profesionTutor2"
                                        name="profesionTutor2"
                                        ref={profesionTutor2}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarProfesionTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarProfesionTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoProfesionTutor2 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="ocupacionTutor1">Ocupación:</LabelTemp>
                            {!editarOcupacionTutor1
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {ocupacion_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarOcupacionTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarOcupacionTutor1}
                                        type="text"
                                        id="ocupacionTutor1"
                                        name="ocupacionTutor1"
                                        ref={ocupacionTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarOcupacionTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarOcupacionTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoOcupacionTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="ocupacionTutor2">Ocupación:</LabelTemp>
                            {!editarOcupacionTutor2
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {ocupacion_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarOcupacionTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarOcupacionTutor2}
                                        type="text"
                                        id="ocupacionTutor2"
                                        name="ocupacionTutor2"
                                        ref={ocupacionTutor2}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarOcupacionTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarOcupacionTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoOcupacionTutor2 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                    <Division/>
                    <GridBox $disposicion="49% 2% 49%">
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="estadoCivilTutor1">Estado Civil:</LabelTemp>
                            {!editarEstadoCivilTutor1
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {estado_civil_1}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarEstadoCivilTutor1(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarEstadoCivilTutor1}
                                        type="text"
                                        id="estadoCivilTutor1"
                                        name="estadoCivilTutor1"
                                        ref={estadoCivilTutor1}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarEstadoCivilTutor1(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarEstadoCivilTutor1(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoEstadoCivilTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                        <DivisionVertical />
                        <ContenedorGrid $disposicion="30% 60% 10%">
                            <LabelTemp htmlFor="estadoCivilTutor2">Estado Civil:</LabelTemp>
                            {!editarEstadoCivilTutor2
                            ?   <ContenedorGrid $disposicion="90% 10%" $alignitems="center">
                                    {estado_civil_2}
                                    {servicio !== "Solo Administrativo" && servicio !== "Finalizado" ? null 
                                    :
                                    <BtnImg src={`/img/editar.png`} alt="x cerrar" onClick={() => setEditarEstadoCivilTutor2(true)} />
                                    }
                                </ContenedorGrid>
                            :   <ContenedorGrid $disposicion="60% 20% 20%" $alignitems="center">
                                    <InputTemp 
                                        disabled={!editarEstadoCivilTutor2}
                                        type="text"
                                        id="estadoCivilTutor2"
                                        name="estadoCivilTutor2"
                                        ref={estadoCivilTutor2}
                                    />
                                    <BtnImg src={`/img/cancel.svg`} onClick={() => setEditarEstadoCivilTutor2(false)} />
                                    <BtnImg src={`/img/confirm.svg`} onClick={(e) => guardarEstadoCivilTutor2(e)} />
                                </ContenedorGrid>
                            }
                            {!incompletoEstadoCivilTutor1 ? null : <FaltaDato>X</FaltaDato>}
                        </ContenedorGrid>
                    </GridBox>
                </ContendorPaciente>
            </ContenedorSecundario>
        </ContenedorPrincipal>
    );
}
 
export default DatosPaciente;