import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';
import M83Bis from './M83Bis';
import M83Practica from './M83Practica';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgB10, PasosSubT, DivM83, PasosT, Pasos, NumRedondo, NumCuadrado, NumberDivR, NumberDivL, ImgDiv, NumCalculoM83 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M83 = () => {

    const dispatch = useDispatch();

    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);

    useEffect(() => {
        dispatch(setearEjercicioAction('M83') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-descomposicion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
                <Fragment>
                    {iniciar || fuerzoInicio
                        ?
                        <Fragment>
                            {contador=== 0 &&
                            <div>
                            {contadorSecundario >= 0 &&
                            <FlexFull justify='space-evenly' align='center'> {/*  border='2px solid blue' */} 
                                {/* CALCULO Y DESCOMPOSICIÓN */}
                                <FlexFull direction='column' justify='space-around' align='start' width='48vw' height='60vh' hlg='70vh'> {/*  border='2px solid red' */} 
                                    <FlexFull direction='column' justify='center' align='start' width='48vw'>
                                        {/* CALCULO */}
                                        <FlexFull justify='end' align='center' width='100%'> {/*  border='2px solid blue' */} 
                                            <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                                <NumCalculoM83 color='#D76970'>34</NumCalculoM83>
                                                <NumCalculoM83 color='#646464'>-</NumCalculoM83>                                     
                                                <NumCalculoM83 color='#23AAAF'>12</NumCalculoM83>
                                                <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                                {contadorSecundario >= 16 && <NumCalculoM83 color='#646464'>22</NumCalculoM83>}
                                            </FlexFull>
                                        </FlexFull>
                                        {/* FIN CALCULO */}
                                        {/* DESCOMPOSICIÓN */}
                                        <FlexFull justify='space-around' align='center' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='-18px 0vw 0vh 15px' > {/* width='35vw' border='3px solid blue' */}
                                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 2vw 0vh 0vw' mt='0px 1.5vw 0vh 0px' md='0px 1.2vw 0vh 0px' mlg='0px 2.3vw 0vh 0px'> { /* border='3px solid violet'     mt='0vh 1.5vw 0vh 0vw' mlg='0px' */}
                                            {contadorSecundario >= 2 &&
                                            <div>
                                                <ImgDiv>
                                                    <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                                </ImgDiv>
                                                <Flex>
                                                    <NumberDivL>
                                                        {contadorSecundario >= 3 &&
                                                        <NumRedondo>30</NumRedondo>
                                                        }
                                                    </NumberDivL>
                                                    <NumberDivR>
                                                        {contadorSecundario >= 4 &&
                                                        <NumCuadrado>4</NumCuadrado>
                                                        }
                                                    </NumberDivR>
                                                </Flex>
                                            </div>
                                            }
                                            </Flex>
                                            <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 0vw 0vh 2vw' mlg='0px'>  { /*   margin='0vh 0vw 0vh 4vw'   border='1px solid green' */}
                                            {contadorSecundario >= 6 &&
                                            <div>
                                                <ImgDiv>
                                                    <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                                </ImgDiv>
                                                <Flex>
                                                    <NumberDivL>
                                                        {contadorSecundario >= 7 &&
                                                        <NumRedondo>10</NumRedondo>
                                                        }
                                                    </NumberDivL>
                                                    <NumberDivR>
                                                        {contadorSecundario >= 8 &&
                                                        <NumCuadrado>2</NumCuadrado>
                                                        }
                                                    </NumberDivR>
                                                </Flex>
                                            </div>
                                            }
                                            </Flex>
                                        </FlexFull>
                                        {/* FIN DESCOMPOSICIÓN */}
                                    </FlexFull>
                                    {/* CALCULO ABAJO */}
                                    <FlexFull justify='center' align='center' width='100%'> {/*  border='2px solid blue' */} 
                                        <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                        <DivM83>
                                        {contadorSecundario >= 11 &&
                                            <div>
                                                {contadorSecundario >= 12 &&
                                                <NumRedondo>20</NumRedondo>
                                                }
                                            </div>
                                        }
                                        </DivM83>
                                        <DivM83>
                                        {contadorSecundario >= 13 &&
                                            <NumCalculoM83 color='#646464'>+</NumCalculoM83>
                                        }
                                        </DivM83>
                                        <DivM83>
                                        {contadorSecundario >= 9 &&
                                            <div>
                                                {contadorSecundario >= 10 &&
                                                <NumCuadrado>2</NumCuadrado>
                                                }
                                            </div>
                                        }
                                        </DivM83>
                                        <DivM83>
                                        {contadorSecundario >= 13 &&
                                            <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                        }
                                        </DivM83>
                                        <DivM83>
                                        {contadorSecundario >= 14 && <NumCuadrado>22</NumCuadrado>}
                                        </DivM83>
                                        </FlexFull>
                                    </FlexFull>
                                    {/* FIN CALCULO ABAJO */}
                                </FlexFull>
                                {/* FIN CALCULO Y DESCOMPOSICIÓN */}
                                {/* PASOS */}
                                <FlexFull direction='column' justify='start' align='center' width='25vw' height='80vh'>
                                    <PasosT>
                                        PASOS
                                    </PasosT>
                                    <PasosSubT>
                                        RESTA POR DESCOMPOSICIÓN
                                    </PasosSubT>
                                    {contadorSecundario >= 1 &&
                                    <Pasos bgcolor='#D76970'>
                                        1- DESCOMPONGO EL PRIMER NÚMERO
                                    </Pasos>
                                    }
                                    {contadorSecundario >= 5 &&
                                    <Pasos bgcolor='#23AAAF'>
                                        2- DESCOMPONGOO EL SEGUNDO NÚMERO
                                    </Pasos>
                                    }
                                    {contadorSecundario >= 9 &&
                                    <Pasos bgcolor='#FF9B2A'>
                                        3- RESTO LAS UNIDADES
                                    </Pasos>
                                    }
                                    {contadorSecundario >= 11 &&
                                    <Pasos bgcolor='#FFDC73'>
                                        4- RESTO LAS DECENAS
                                    </Pasos>
                                    }
                                    {contadorSecundario >= 13 &&
                                    <Pasos bgcolor='#655EE8'>
                                        5- HAGO LA SUMA
                                    </Pasos>
                                    }
                                    {contadorSecundario >= 15 &&
                                    <Pasos bgcolor='#D76970'>
                                        6- ¿CUÁL ES EL RESULTADO?
                                    </Pasos>
                                    }
                                </FlexFull>
                                {/* FIN PASOS */}
                            </FlexFull>
                            }

                        <FlexCuz  position='fixed' right='0' bottom='0'>
                            {contadorSecundario === 0 && <BotoneraBottom aumentarSecundario={true} />}
                            {contadorSecundario > 0 && contadorSecundario < 16 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />}
                            {contadorSecundario === 16 && <BotoneraBottom reducirSecundario={true} aumentar={true} />}
                        </FlexCuz>
                        </div>
                        }
                        {contador === 1 && <M83Bis mostrar={true} />}
                        {contador === 2 && <M83Practica mostrar={true} />}
                        </Fragment>
                        :
                        <Instrucciones instrucciones={instrucciones} />
                    }
                </Fragment>
        </Fragment>
    );
}
 
export default M83;