import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import AyudaCuadricula from '../../Suma/AyudaCuadricula';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, NumSumaM55, AvanzarImg, AvanzarBtn, InputM55 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta.';
const miniInstrucciones = 'Resuelve la resta.';

const M77 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numero.toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numero.toString()){
                    setNumero('');
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');          
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const listaUno = [];
        const listaDos = [];
        const funcionLista = (minimo, maximo, lista) => {
            for(let i = 0; i < 5; i++){
                let min = minimo;
                let max = maximo;
                let randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                lista.push(randomNumero);
            }
            return lista;
        }
        const randomListaUno = funcionLista(11,40,listaUno);
        const randomListaDos = funcionLista(1,9,listaDos);
        const modelo = [
            {id:'1', cantidad_1:randomListaUno[0], cantidad_2:randomListaDos[0]},
            {id:'2', cantidad_1:randomListaUno[1], cantidad_2:randomListaDos[1]},
            {id:'3', cantidad_1:randomListaUno[2], cantidad_2:randomListaDos[2]},
            {id:'4', cantidad_1:randomListaUno[3], cantidad_2:randomListaDos[3]},
            {id:'5', cantidad_1:randomListaUno[4], cantidad_2:randomListaDos[4]}
        ];
        setComparar(modelo);
        setIndexes(modelo.length);
        setTipoEjercicio('decena');
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M77') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M77",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    'ayuda_visual': ayuVisualSolicitada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <FlexCuz $alignself='start' $order='1'>
                            <AyudaCuadricula />
                        </FlexCuz>
                        {comparar.length === 0 ? null :
                        <Flex>
                            <NumSumaM55 color='#FF9B2A' w="12vw">{comparar[count-1].cantidad_1}</NumSumaM55> 
                            <NumSumaM55 color='#646464' w='8vw'>-</NumSumaM55> 
                            <NumSumaM55 color='#FF9B2A' w="12vw">{comparar[count-1].cantidad_2}</NumSumaM55> 
                            <NumSumaM55 color='#646464' w='8vw'>=</NumSumaM55> 
                        </Flex>
                        } 
                        <form onSubmit={(e) => onSubmit(e)}>
                            <InputM55 type="numeric" min="1" max="5000" value={numero} onChange={(e) => setNumero(e.target.value)} color="#FF9B2A" w="27vw" bs='inset 0px 4px 4px rgba(0, 0, 0, 0.25)' bradius='20px' />
                        </form>
                    </Flex>

                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M77;