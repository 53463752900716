import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, DivFlex, BtnAbecedario, BtnAbecedarioGrande, BtnLetra, BtnLetraDos, Img, FormInput, InputText, BtnBasurero } from '../../../../Css/MetodosCSS/StyledLectoEscritura';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const array = [
    { id: 1, letra: '', tipo: '', color: '', muestro: false },
    { id: 2, letra: '', tipo: '', color: '', muestro: false },
    { id: 3, letra: '', tipo: '', color: '', muestro: false },
    { id: 4, letra: '', tipo: '', color: '', muestro: false },
    { id: 5, letra: '', tipo: '', color: '', muestro: false },
    { id: 6, letra: '', tipo: '', color: '', muestro: false },
    { id: 7, letra: '', tipo: '', color: '', muestro: false },
    { id: 8, letra: '', tipo: '', color: '', muestro: false },
    { id: 9, letra: '', tipo: '', color: '', muestro: false },
    { id: 10, letra: '', tipo: '', color: '', muestro: false },
    { id: 11, letra: '', tipo: '', color: '', muestro: false },
    { id: 12, letra: '', tipo: '', color: '', muestro: false }
]

const array2 = [
    { id: 1, silaba: '', muestro: false},
    { id: 2, silaba: '', muestro: false},
    { id: 3, silaba: '', muestro: false},
    { id: 4, silaba: '', muestro: false},
    { id: 5, silaba: '', muestro: false},
    { id: 6, silaba: '', muestro: false},
    { id: 7, silaba: '', muestro: false},
    { id: 8, silaba: '', muestro: false}
]

const abc = [
    { id: '1', letra: 'a', estilo:false, bgcolor:'#23AAAF'}, { id: '2', letra: 'b', estilo:false, bgcolor:'#646464'} , { id: '3', letra: 'c', estilo:false, bgcolor:'#646464'} , { id: '4', letra: 'd', estilo:false, bgcolor:'#646464'} , { id: '5', letra: 'e', estilo:false, bgcolor:'#FF9B2A'} , { id: '6', letra: 'f', estilo:false, bgcolor:'#646464'} , { id: '7', letra: 'g', estilo:false, bgcolor:'#646464'} , { id: '8', letra: 'h', estilo:false, bgcolor:'#646464'} , { id: '9', letra: 'i', estilo:false, bgcolor:'#FFDC73'} , { id: '10', letra: 'j', estilo:false, bgcolor:'#646464'} , { id: '11', letra: 'k', estilo:false, bgcolor:'#646464'} , { id: '12', letra: 'l', estilo:false, bgcolor:'#646464'} , { id: '13', letra: 'm', estilo:false, bgcolor:'#646464'} , { id: '14', letra: 'n', estilo:false, bgcolor:'#646464'} , { id: '15', letra: 'ñ', estilo:false, bgcolor:'#646464'} , { id: '16', letra: 'o', estilo:false, bgcolor:'#655EE8'} , { id: '17', letra: 'p', estilo:false, bgcolor:'#646464'} , { id: '18', letra: 'q', estilo:false, bgcolor:'#646464'} , { id: '19', letra: 'r', estilo:false, bgcolor:'#646464'} , { id: '20', letra: 's', estilo:false, bgcolor:'#646464'} , { id: '21', letra: 't', estilo:false, bgcolor:'#646464'} , { id: '22', letra: 'u', estilo:false, bgcolor:'#D05F67'} , { id: '23', letra: 'v', estilo:false, bgcolor:'#646464'} , { id: '24', letra: 'w', estilo:false, bgcolor:'#646464'} , { id: '25', letra: 'x', estilo:false, bgcolor:'#646464'} , { id: '26', letra: 'y', estilo:false, bgcolor:'#646464'} , { id: '27', letra: 'z', estilo:false, bgcolor:'#646464'},
]

const instrucciones = 'A continuación se presenta un dibujo. En una primera instancia deberás indicar las sílabas. Luego seleccionaras las letras . Por último, escribirás la palabra con el teclado.';
const miniInstrucciones = 'A continuación se presenta un dibujo. En una primera instancia deberás indicar las sílabas. Luego seleccionaras las letras . Por último, escribirás la palabra con el teclado.';

const ESC2 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [disable, setDisable] = useState(false);
    const [silabas, setSilabas] = useState([])
    const [abecedario, setAbecedario] = useState(abc);
    const [palabra, setPalabra] = useState('');
    const [matrix, setMatrix] = useState([]);
    const [matrix2, setMatrix2] = useState([]);
    const [numeroSilabas, setNumeroSilabas] = useState(0);
    const [split, setSplit] = useState('');
    const [count, setCount] = useState(1);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        const newArray2 = matrix.filter(item => item.muestro === true).map(item => item.letra.toUpperCase());
        const comparoSplit = split.map((item, i) => item === newArray2[i]);
        if (matrix2[0].silaba.toUpperCase() !== comparar.silaba_1.toUpperCase() || matrix2[1].silaba.toUpperCase() !== comparar.silaba_2.toUpperCase() || matrix2[2].silaba.toUpperCase() !== comparar.silaba_3.toUpperCase() || matrix2[3].silaba.toUpperCase() !== comparar.silaba_4.toUpperCase() || matrix2[4].silaba.toUpperCase() !== comparar.silaba_5.toUpperCase() || comparoSplit.find(item => item === false) === false || palabra.toUpperCase() !== comparar.palabra_1.toUpperCase()) {
            console.log('mal');
            setDisable(true);
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                const newList6 = JSON.parse(JSON.stringify(array2));
                for(let i = 0; i < numeroSilabas; i++){
                    newList6[i].muestro = true;
                }
                setMatrix2(newList6);
                const newList = JSON.parse(JSON.stringify(matrix));
                newList.map(item => item.letra = '')
                setMatrix(newList);
                setPalabra('');
            }, 1000);
        } else {
            console.log('bien')
            setDisable(true);
            setIsCorrect(true);
            GoodWork();
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setMatrix(array)
                    setPalabra('');
                    setCount(count + 1);
                }, 1000);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1000);
            }
        }
    }

    const borrar = (e) => {
        e.preventDefault();
        const newList6 = JSON.parse(JSON.stringify(array2));
        for(let i = 0; i < numeroSilabas; i++){
            newList6[i].muestro = true;
        }
        setMatrix2(newList6);
    }
    const borrar2 = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(matrix));
        newList.map(item => item.letra = '')
        setMatrix(newList)
    }

    const asignarLetra = (e, letra) => {
        e.preventDefault();
        const colorLetra = (function () {
            let color;
            switch (letra.toUpperCase()) {
                case 'A':
                    color = '#23AAAF';
                    return color;
                case 'E':
                    color = '#FF9B2A';
                    return color;
                case 'I':
                    color = '#FFDC73';
                    return color;
                case 'O':
                    color = '#655EE8';
                    return color;
                case 'U':
                    color = '#D05F67';
                    return color;
                default:
                    color = '#646464';
                    return color;
            }
        })();
        if(matrix.filter(item => item.muestro === true).length.toString() === matrix.filter(item => item.letra !== "").length.toString()){
            return;
        }
        const newList = JSON.parse(JSON.stringify(matrix));
        const index = matrix.findIndex(item => item.letra === '');
        newList[index].letra = letra;
        newList[index].color = colorLetra;
        setMatrix(newList);
    }

    const asignarSilaba = (e, slb) => {
        e.preventDefault();
        if(matrix2.filter(item => item.muestro === true).length.toString() === matrix2.filter(item => item.silaba !== "").length.toString()){
            return;
        }
        const newList = JSON.parse(JSON.stringify(matrix2));
        const index = newList.findIndex(item => item.silaba === '');
        newList[index].silaba = slb;
        setMatrix2(newList);
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC2') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/silaba-directa/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC2",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[count-1]));
        delete newList.id;
        delete newList.palabra_1;
        delete newList.imagen_url_1;
        delete newList.ayuda_imagen_1;
        const shuffleList = Object.values(newList);
        const filtered = shuffleList.filter(item => { return item !== '' })

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(filtered);

        setSilabas(
            Object.values(newShuffleList))
        
        const newList4 = JSON.parse(JSON.stringify(informacion[count-1]));
        setComparar(newList4);
        let a = 0; let b = 0; let c = 0; let d = 0; let e = 0; let f = 0; let g = 0; let h = 0;
        if(newList4.silaba_1){ a = 1}
        if(newList4.silaba_2){ b = 1}
        if(newList4.silaba_3){ c = 1}
        if(newList4.silaba_4){ d = 1}
        if(newList4.silaba_5){ e = 1}
        if(newList4.silaba_6){ f = 1}
        if(newList4.silaba_7){ g = 1}
        if(newList4.silaba_8){ h = 1}

        const cantidadSilabas = a + b + c + d + e + f + g + h;
        setNumeroSilabas(cantidadSilabas);
        const newList6 = JSON.parse(JSON.stringify(array2));
        for(let i = 0; i < cantidadSilabas; i++){
            newList6[i].muestro = true;
        }
        setMatrix2(newList6);

        const newList5 = JSON.parse(JSON.stringify(informacion[count-1]));
        const numero = newList5.palabra_1.length;
        const newList2 = JSON.parse(JSON.stringify(array));
        newList2.filter(item => item.id <= numero).map(item => item.muestro = true);
        setMatrix(newList2);

        const newList3 = JSON.parse(JSON.stringify(informacion[count-1]));
        const newArray = newList3.palabra_1.toUpperCase().split("");
        setSplit(newArray);
        setIndexes(Object.keys(informacion).length);
    }, [informacion, count]);
    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} ayuImagen={true} />
            {iniciar
                ?
                <ContainerFlexx direction="column" height="100%" width="100%">
                    <ContainerFlexx direction="column" margin="auto" width="100%"
                        isCorrect={isCorrect} isIncorrect={isIncorrect}
                    >
                        <ContainerFlexx wrap='wrap'>
                            <ContainerFlexx margin='0 2rem 0 0' display="flex" direction="column" align="center" justify="center">
                                <Img width="250px" height="140px" hm='150px' ht='160px' hd='170px' hlg='180px' m='0px' src={`/img/${comparar.imagen_url_1}.png`} />
                                {ayuda_imagen ? 
                                    <div>{comparar.ayuda_imagen_1}</div>
                                : null}
                            </ContainerFlexx>
                            <ContainerFlexx direction="column">
                                <ContainerFlexx>
                                    {matrix2.length === 0 ? null :
                                    <ContainerFlexx bs="inset 0px 4px 4px rgba(0, 0, 0, 0.25)" padding="0.3rem" bradius="10px" >
                                        {matrix2.filter(item => item.muestro === true).map(item => 
                                            <BtnLetraDos border="none" $minMay={minuscula} color="#646464"
                                                key={item.id}
                                                disabled={disable}
                                            >
                                                {item.silaba}
                                            </BtnLetraDos>
                                        )}
                                    </ContainerFlexx>
                                    }
                                    <ContainerFlexx>
                                        <BtnBasurero bg="transparent" border="none"
                                            disabled={disable}
                                            onClick={(e) => borrar(e)}
                                        >
                                            <Img src={`/img/icono_basurero.png`} />
                                        </BtnBasurero>
                                    </ContainerFlexx>
                                </ContainerFlexx>
                                <ContainerFlexx margin='0.3rem 0 0.3rem 0' mm='0.3rem 0 0.3rem 0' mt='1rem 0 1rem 0' md='1rem 0 1rem 0' mlg='1rem 0 1rem 0'>
                                    <ContainerFlexx bs="inset 0px 4px 4px rgba(0, 0, 0, 0.25)" padding="0.3rem" bradius="10px">
                                        {matrix.filter(item => item.muestro === true).map(item =>
                                            <BtnLetra border="none" $minMay={minuscula} color={ayuda_lectura ? item.color : '#646464'}
                                                key={item.id}
                                                disabled={disable}
                                            >
                                                {item.letra}
                                            </BtnLetra>
                                        )}
                                    </ContainerFlexx>
                                    <ContainerFlexx>
                                        <BtnBasurero bg="transparent" border="none"
                                            onClick={(e) => borrar2(e)}
                                        >
                                            <Img src={`/img/icono_basurero.png`} />
                                        </BtnBasurero>
                                    </ContainerFlexx>
                                </ContainerFlexx>

                                <ContainerFlexx>
                                    <FormInput
                                        onSubmit={(e) => verificar(e)}
                                    >
                                        <InputText radius="10px" font="1.5rem" $minMay={minuscula} type="text" color="#646464" value={palabra} onChange={(e) => setPalabra(e.target.value)} disabled={disable} />
                                    </FormInput>
                                </ContainerFlexx>
                            </ContainerFlexx>
                        </ContainerFlexx>
                        <ContainerFlexx margin='0.8rem 0 0.2rem 0' mm='0.8rem 0 0.2rem 0' mt='2rem 0 0.3rem 0' md='2rem 0 0.5rem 0' mlg='2rem 0 0.5rem 0'>
                            {silabas.map((item, index) =>
                                <BtnAbecedarioGrande bg="#FAFAFA" $minMay={minuscula} shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" radius="10px" border="none" margin="0 0.5rem" font="1.2rem" weight="bold" color=" #646464"
                                    key={index}
                                    onClick={(e) => asignarSilaba(e, item)}
                                >
                                    {item}
                                </BtnAbecedarioGrande>
                            )}
                        </ContainerFlexx>
                        {/* ABECEDARIO */}
                        <DivFlex wrap='wrap' justify='center' align='center'>
                            {abecedario.map(item => (
                                    <BtnAbecedario $estilo={item.estilo} $background={"#69BEB8"} $minMay={minuscula} style={ayuda_lectura ? {color:`${item.bgcolor}`} : {color: '#646464'}}
                                        disabled={disable}
                                        key={item.id}
                                        onClick={(e) => asignarLetra(e, item.letra)}
                                    >
                                        {item.letra}
                                    </BtnAbecedario>
                                ))
                            }
                        </DivFlex>
                        {/* ABECEDARIO */}
                    
                    </ContainerFlexx>
                    <ContainerFlexx margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlexx>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC2;