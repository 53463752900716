import React, { useEffect } from 'react';

import Main from './components/PaginaPrincipal/Main';
import QuienesSomos from './components/PaginaPrincipal/QuienesSomos';
import Login from './components/Login/Login';
import NuevaCuenta from './components/NuevaCuenta/NuevaCuenta';
import RestablecerPassword from './components/Login/RestablecerPassword';
import ResetPassword from './components/Login/ResetPassword';
import ConfirmarCuenta from './components/NuevaCuenta/ConfirmarCuenta';
import InvitacionPaciente from './components/NuevaCuenta/InvitacionPaciente';
import Escritorio from './components/Escritorio/Escritorio';
import AdministrarPerfil from './components/AuxiliaresPaginas/AdministrarPerfil';
import MiSuscripcion from './components/Escritorio/MiSuscripcion';
import Pacientes from './components/Escritorio/Pacientes';
import Paciente from './components/Escritorio/Paciente';
import DatosPaciente from './components/Escritorio/DatosPaciente';
import Anamnesis from './components/Escritorio/Anamnesis';
import HistoriaClinica from './components/Escritorio/HistoriaClinica';
import InformesPrevios from './components/Escritorio/InformesPrevios';
import Estadisticas from './components/Escritorio/Estadisticas';
import NuevoPaciente from './components/Escritorio/NuevoPaciente';
import CalendarioPaciente from './components/Escritorio/CalendarioPaciente';
import Objetivos from './components/Escritorio/Objetivos';
import SeleccionarActividades from './components/Escritorio/SeleccionarActividades';
import GestionarMetodo from './components/Escritorio/GestionarMetodo';
import Resultados from './components/Escritorio/Resultados';
import Metodos from './components/Metodos/Metodos';
import FinalEjercicio from './components/Metodos/Compartidos/FinalEjercicio';
import LectoEscritura from './components/Metodos/LectoEscritura';
import Letras from './components/Metodos/LectoEscritura/Letras';
import Vocales from './components/Metodos/LectoEscritura/Letras/Vocales';
import Introduccion from './components/Metodos/LectoEscritura/Letras/Vocales/Introduccion';
import Repaso from './components/Metodos/LectoEscritura/Letras/Vocales/Repaso';
import FluidezVocales from './components/Metodos/LectoEscritura/Letras/Vocales/FluidezVocales';
import Consonantes from './components/Metodos/LectoEscritura/Letras/Consonantes';
import LN45 from './components/Metodos/LectoEscritura/Letras/Consonantes/LN45';
import ConcienciaFonologica from './components/Metodos/Lenguaje/ConcienciaFonologica';
import CCRimaLexica from './components/Metodos/Lenguaje/ConcienciaFonologica/CCRimaLexica';
import LEN25 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCRimaLexica/LEN25';
import LEN26 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCRimaLexica/LEN26';
import CCSilabica from './components/Metodos/Lenguaje/ConcienciaFonologica/CCSilabica';
import LEN20 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCSilabica/LEN20';
import LEN21 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCSilabica/LEN21';
import LEN22 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCSilabica/LEN22';
import LEN23 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCSilabica/LEN23';
import LEN24 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCSilabica/LEN24';
import CCLexica from './components/Metodos/Lenguaje/ConcienciaFonologica/CCLexica';
import LEN15 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCLexica/LEN15';
import LEN16 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCLexica/LEN16';
import LEN17 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCLexica/LEN17';
import LEN18 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCLexica/LEN18';
import LEN19 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCLexica/LEN19';
import LEN30 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCLexica/LEN30';
import CCIntrasilabica from './components/Metodos/Lenguaje/ConcienciaFonologica/CCIntrasilabica';
import LEN27 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCIntrasilabica/LEN27';
import LEN28 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCIntrasilabica/LEN28';
import LEN29 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCIntrasilabica/LEN29';
import CCFonemica from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica';
import LEN1 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN1';
import LEN2 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN2';
import LEN3 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN3';
import LEN4 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN4';
import LEN5 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN5';
import LEN6 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN6';
import LEN8 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN8';
import LEN9 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN9';
import LEN10 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN10';
import LEN31 from './components/Metodos/Lenguaje/ConcienciaFonologica/CCFonemica/LEN31';
import DenominacionRapida from './components/Metodos/Lenguaje/DenominacionRapida';
import LEN11 from './components/Metodos/Lenguaje/DenominacionRapida/LEN11';
import LEN12 from './components/Metodos/Lenguaje/DenominacionRapida/LEN12';
import LEN13 from './components/Metodos/Lenguaje/DenominacionRapida/LEN13';
import LEN14 from './components/Metodos/Lenguaje/DenominacionRapida/LEN13';
import Lectura from './components/Metodos/LectoEscritura/Lectura';
import InstruccionExplicita from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita';
import LN1 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN1';
import LN2 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN2';
import LN3 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN3';
import LN4 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN4';
import LN5 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN5';
import LN6 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN6';
import LN7 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN7';
import LN8 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN8';
import LN9 from './components/Metodos/LectoEscritura/Lectura/InstruccionExplicita/LN9';
import InstruccionSinfones from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones';
import LN1IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN1IS';
import LN2IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN2IS';
import LN3IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN3IS';
import LN4IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN4IS';
import LN5IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN5IS';
import LN6IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN6IS';
import LN7IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN7IS';
import LN8IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN8IS';
import LN9IS from './components/Metodos/LectoEscritura/Lectura/InstruccionSinfones/LN9IS';
import Silabas from './components/Metodos/LectoEscritura/Lectura/Silabas';
import LN12 from './components/Metodos/LectoEscritura/Lectura/Silabas/LN12';
import SilabaDirecta from './components/Metodos/LectoEscritura/Lectura/SilabaDirecta';
import LN13SD from './components/Metodos/LectoEscritura/Lectura/SilabaDirecta/LN13SD';
import SilabaInversa from './components/Metodos/LectoEscritura/Lectura/SilabaInversa';
import LN13SI from './components/Metodos/LectoEscritura/Lectura/SilabaInversa/LN13SI';
import SilabaTrabada from './components/Metodos/LectoEscritura/Lectura/SilabaTrabada';
import LN13ST from './components/Metodos/LectoEscritura/Lectura/SilabaTrabada/LN13ST';
import SilabaMixta from './components/Metodos/LectoEscritura/Lectura/SilabaMixta';
import LN13SM from './components/Metodos/LectoEscritura/Lectura/SilabaMixta/LN13SM';
import SilabaConectores from './components/Metodos/LectoEscritura/Lectura/SilabaConectores';
import LN13SC from './components/Metodos/LectoEscritura/Lectura/SilabaConectores/LN13SC';
import SilabaDiptongo from './components/Metodos/LectoEscritura/Lectura/SilabaDiptongo';
import LN13SDIP from './components/Metodos/LectoEscritura/Lectura/SilabaDiptongo/LN13SDIP';
import SilabaHiato from './components/Metodos/LectoEscritura/Lectura/SilabaHiato';
import LN13SH from './components/Metodos/LectoEscritura/Lectura/SilabaHiato/LN13SH';
import Pseudopalabras from './components/Metodos/LectoEscritura/Lectura/Pseudopalabras';
import LN44 from './components/Metodos/LectoEscritura/Lectura/Pseudopalabras/LN44';
import Ejercicios from './components/Metodos/LectoEscritura/Lectura/Ejercicios';
import LN14 from './components/Metodos/LectoEscritura/Lectura/Ejercicios/LN14';
import LN18 from './components/Metodos/LectoEscritura/Lectura/Ejercicios/LN18';
import LN19 from './components/Metodos/LectoEscritura/Lectura/Ejercicios/LN19';
import LN20 from './components/Metodos/LectoEscritura/Lectura/Ejercicios/LN20';
import LN21 from './components/Metodos/LectoEscritura/Lectura/Ejercicios/LN21';
import LN30 from './components/Metodos/LectoEscritura/Lectura/Ejercicios/LN30';
import LN31 from './components/Metodos/LectoEscritura/Lectura/Ejercicios/LN31';
import Escritura from './components/Metodos/LectoEscritura/Escritura';
import SilabaDirectaE from './components/Metodos/LectoEscritura/Escritura/SilabaDirectaE';
import ESC1 from './components/Metodos/LectoEscritura/Escritura/SilabaDirecta/ESC1';
import ESC2 from './components/Metodos/LectoEscritura/Escritura/SilabaDirecta/ESC2';
import ESC3 from './components/Metodos/LectoEscritura/Escritura/SilabaDirecta/ESC3';
import ESC4 from './components/Metodos/LectoEscritura/Escritura/SilabaDirecta/ESC4';
import ESC5 from './components/Metodos/LectoEscritura/Escritura/SilabaDirecta/ESC5';
import ESC6 from './components/Metodos/LectoEscritura/Escritura/SilabaDirecta/ESC6';
import Sinfones from './components/Metodos/LectoEscritura/Escritura/Sinfones';
import ESC7 from './components/Metodos/LectoEscritura/Escritura/Sinfones/ESC7';
import ESC8 from './components/Metodos/LectoEscritura/Escritura/Sinfones/ESC8';
import Ortografia from './components/Metodos/LectoEscritura/Escritura/Ortografia';
import ESC9 from './components/Metodos/LectoEscritura/Escritura/Ortografia/ESC9';
import ESC10 from './components/Metodos/LectoEscritura/Escritura/Ortografia/ESC10';
import Oraciones from './components/Metodos/LectoEscritura/Escritura/Oraciones';
import ESC11 from './components/Metodos/LectoEscritura/Escritura/Oraciones/ESC11';
import ESC12 from './components/Metodos/LectoEscritura/Escritura/Oraciones/ESC12';
import ESC13 from './components/Metodos/LectoEscritura/Escritura/Descripciones/ESC13';
import Cuentos from './components/Metodos/LectoEscritura/Escritura/Cuentos';
import ESC14 from './components/Metodos/LectoEscritura/Escritura/Cuentos/ESC14';
import ESC15 from './components/Metodos/LectoEscritura/Escritura/Cuentos/ESC15';
import TransicionDeLetras from './components/Metodos/LectoEscritura/Escritura/TransicionDeLetras';
import ESC16 from './components/Metodos/LectoEscritura/Escritura/TransicionDeLetras/ESC16';
import ESC17 from './components/Metodos/LectoEscritura/Escritura/TransicionDeLetras/ESC17';
import ESC18 from './components/Metodos/LectoEscritura/Escritura/TransicionDeLetras/ESC18';
import Matematica from './components/Metodos/Matematica';
import Subitizacion from './components/Metodos/Matematica/Subitizacion';
import Nivel1 from './components/Metodos/Matematica/Subitizacion/Nivel1';
import M1 from './components/Metodos/Matematica/Subitizacion/Nivel1/M1';
import M2 from './components/Metodos/Matematica/Subitizacion/Nivel1/M2';
import M3 from './components/Metodos/Matematica/Subitizacion/Nivel1/M3';
import M4 from './components/Metodos/Matematica/Subitizacion/Nivel1/M4';
import M5 from './components/Metodos/Matematica/Subitizacion/Nivel1/M5';
import Nivel2 from './components/Metodos/Matematica/Subitizacion/Nivel2';
import M6 from './components/Metodos/Matematica/Subitizacion/Nivel2/M6';
import M7 from './components/Metodos/Matematica/Subitizacion/Nivel2/M7';
import M8 from './components/Metodos/Matematica/Subitizacion/Nivel2/M8';
import M9 from './components/Metodos/Matematica/Subitizacion/Nivel2/M9';
import M10 from './components/Metodos/Matematica/Subitizacion/Nivel2/M10';
import Nivel3 from './components/Metodos/Matematica/Subitizacion/Nivel3';
import M11 from './components/Metodos/Matematica/Subitizacion/Nivel3/M11';
import M12 from './components/Metodos/Matematica/Subitizacion/Nivel3/M12';
import M13 from './components/Metodos/Matematica/Subitizacion/Nivel3/M13';
import M14 from './components/Metodos/Matematica/Subitizacion/Nivel3/M14';
import EstimacionYNocionDeCantidad from './components/Metodos/Matematica/EstimacionYNocionDeCantidad';
import EstimacionDeCantidad from './components/Metodos/Matematica/EstimacionYNocionDeCantidad/EstimacionDeCantidad';
import M15 from './components/Metodos/Matematica/EstimacionYNocionDeCantidad/EstimacionDeCantidad/M15';
import M16 from './components/Metodos/Matematica/EstimacionYNocionDeCantidad/EstimacionDeCantidad/M16';
import NocionDeCantidad from './components/Metodos/Matematica/EstimacionYNocionDeCantidad/NocionDeCantidad';
import M17 from './components/Metodos/Matematica/EstimacionYNocionDeCantidad/NocionDeCantidad/M17';
import M18 from './components/Metodos/Matematica/EstimacionYNocionDeCantidad/NocionDeCantidad/M18';
import Conteo from './components/Metodos/Matematica/Conteo';
import M19 from './components/Metodos/Matematica/Conteo/C1a9/M19';
import M19Bis from './components/Metodos/Matematica/Conteo/C1a9/M19Bis';
import M21 from './components/Metodos/Matematica/Conteo/C10a19/M21';
import M21Bis from './components/Metodos/Matematica/Conteo/C10a19/M21Bis';
import M22 from './components/Metodos/Matematica/Conteo/C20a29/M22';
import M22Bis from './components/Metodos/Matematica/Conteo/C20a29/M22Bis';
import M23 from './components/Metodos/Matematica/Conteo/C30a39/M23';
import M23Bis from './components/Metodos/Matematica/Conteo/C30a39/M23Bis';
import M24 from './components/Metodos/Matematica/Conteo/C40a49/M24';
import M24Bis from './components/Metodos/Matematica/Conteo/C40a49/M24Bis';
import M25 from './components/Metodos/Matematica/Conteo/C50a59/M25';
import M25Bis from './components/Metodos/Matematica/Conteo/C50a59/M25Bis';
import M26 from './components/Metodos/Matematica/Conteo/C60a69/M26';
import M26Bis from './components/Metodos/Matematica/Conteo/C60a69/M26Bis';
import M27 from './components/Metodos/Matematica/Conteo/C70a79/M27';
import M27Bis from './components/Metodos/Matematica/Conteo/C70a79/M27Bis';
import M28 from './components/Metodos/Matematica/Conteo/C80a89/M28';
import M28Bis from './components/Metodos/Matematica/Conteo/C80a89/M28Bis';
import M29 from './components/Metodos/Matematica/Conteo/C90a99/M29';
import M29Bis from './components/Metodos/Matematica/Conteo/C90a99/M29Bis';
import M86Bis from './components/Metodos/Matematica/Conteo/C10a99/M86Bis';
import M20 from './components/Metodos/Matematica/Conteo/Decena/M20';
import M20Bis from './components/Metodos/Matematica/Conteo/Decena/M20Bis';
import M30 from './components/Metodos/Matematica/Conteo/Centena/M30';
import M30Bis from './components/Metodos/Matematica/Conteo/Centena/M30Bis';
import M31 from './components/Metodos/Matematica/Conteo/UnidadDeMil/M31';
import M31Bis from './components/Metodos/Matematica/Conteo/UnidadDeMil/M31Bis';
import M32 from './components/Metodos/Matematica/Conteo/Millon/M32';
import M32Bis from './components/Metodos/Matematica/Conteo/Millon/M32Bis';
import Ejercitacion1Al10 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10';
import M33 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10/M33';
import M34 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10/M34';
import M35 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10/M35';
import M36 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10/M36';
import M37 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10/M37';
import M39 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10/M39';
import M40 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10/M40';
import M41 from './components/Metodos/Matematica/Conteo/Ejercitacion1Al10/M41';
import Base10 from './components/Metodos/Matematica/Base10';
import M43 from './components/Metodos/Matematica/Base10/M43';
import M44 from './components/Metodos/Matematica/Base10/M44';
import M45 from './components/Metodos/Matematica/Base10/M45';
import M46 from './components/Metodos/Matematica/Base10/M46';
import M47 from './components/Metodos/Matematica/Base10/M47';
import Calculo from './components/Metodos/Matematica/Calculo';
import Suma from './components/Metodos/Matematica/Calculo/Suma';
import M48 from './components/Metodos/Matematica/Calculo/Suma/M48';
import Ejercitacion from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion';
import M49 from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion/M49';
import M50 from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion/M50';
import M51 from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion/M51';
import M52 from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion/M52';
import M54 from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion/M54';
import M42 from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion/M42';
import M38 from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion/M38';
import M87 from './components/Metodos/Matematica/Calculo/Suma/Ejercitacion/M87';
import CalculoMentalSuma from './components/Metodos/Matematica/Calculo/Suma/CalculoMentalSuma';
import M55 from './components/Metodos/Matematica/Calculo/Suma/CalculoMental/M55';
import M55Bis from './components/Metodos/Matematica/Calculo/Suma/CalculoMental/M55Bis';
import M56 from './components/Metodos/Matematica/Calculo/Suma/CalculoMental/M56';
import M57 from './components/Metodos/Matematica/Calculo/Suma/CalculoMental/M57';
import M58 from './components/Metodos/Matematica/Calculo/Suma/CalculoMental/M58';
import M59 from './components/Metodos/Matematica/Calculo/Suma/CalculoMental/M59';
import M60 from './components/Metodos/Matematica/Calculo/Suma/CalculoMental/M60';
import SumaTradicional from './components/Metodos/Matematica/Calculo/Suma/SumaTradicional';
import M61 from './components/Metodos/Matematica/Calculo/Suma/SumaTradicional/M61';
import M61Bis from './components/Metodos/Matematica/Calculo/Suma/SumaTradicional/M61Bis';
import M61Practica from './components/Metodos/Matematica/Calculo/Suma/SumaTradicional/M61Practica';
import SumaDescomposicion from './components/Metodos/Matematica/Calculo/Suma/SumaDescomposicion';
import M62 from './components/Metodos/Matematica/Calculo/Suma/SumaDescomposicion/M62';
import M62Bis from './components/Metodos/Matematica/Calculo/Suma/SumaDescomposicion/M62Bis';
import M62Practica from './components/Metodos/Matematica/Calculo/Suma/SumaDescomposicion/M62Practica';
import M63 from './components/Metodos/Matematica/Calculo/Suma/SumaDescomposicion/M63';
import M63Bis from './components/Metodos/Matematica/Calculo/Suma/SumaDescomposicion/M63Bis';
import M63Practica from './components/Metodos/Matematica/Calculo/Suma/SumaDescomposicion/M63Practica';
import M64 from './components/Metodos/Matematica/Calculo/Suma/SumaDescomposicion/M64';
import Resta from './components/Metodos/Matematica/Calculo/Resta';
import M65 from './components/Metodos/Matematica/Calculo/Resta/M65';
import EjercitacionResta from './components/Metodos/Matematica/Calculo/Resta/EjercitacionResta';
import M66 from './components/Metodos/Matematica/Calculo/Resta/Ejercitacion/M66';
import M67 from './components/Metodos/Matematica/Calculo/Resta/Ejercitacion/M67';
import M68 from './components/Metodos/Matematica/Calculo/Resta/Ejercitacion/M68';
import M70 from './components/Metodos/Matematica/Calculo/Resta/Ejercitacion/M70';
import M71 from './components/Metodos/Matematica/Calculo/Resta/Ejercitacion/M71';
import CalculoMentalResta from './components/Metodos/Matematica/Calculo/Resta/CalculoMentalResta';
import M72 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M72';
import M73 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M73';
import M75 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M75';
import M76 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M76';
import M77 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M77';
import M78 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M78';
import M79 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M79';
import M80 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M80';
import M81 from './components/Metodos/Matematica/Calculo/Resta/CalculoMental/M81';
import RestaTradicional from './components/Metodos/Matematica/Calculo/Resta/RestaTradicional';
import M82 from './components/Metodos/Matematica/Calculo/Resta/RestaTradicional/M82';
import M82Bis from './components/Metodos/Matematica/Calculo/Resta/RestaTradicional/M82Bis';
import M82Practica from './components/Metodos/Matematica/Calculo/Resta/RestaTradicional/M82Practica';
import M89 from './components/Metodos/Matematica/Calculo/Resta/RestaTradicional/M89';
import M89Bis from './components/Metodos/Matematica/Calculo/Resta/RestaTradicional/M89Bis';
import M89Practica from './components/Metodos/Matematica/Calculo/Resta/RestaTradicional/M89Practica';
import RestaDescomposicion from './components/Metodos/Matematica/Calculo/Resta/RestaDescomposicion';
import M83 from './components/Metodos/Matematica/Calculo/Resta/RestaDescomposicion/M83';
import M83Bis from './components/Metodos/Matematica/Calculo/Resta/RestaDescomposicion/M83Bis';
import M83Practica from './components/Metodos/Matematica/Calculo/Resta/RestaDescomposicion/M83Practica';
import M84 from './components/Metodos/Matematica/Calculo/Resta/RestaDescomposicion/M84';
import M84Bis from './components/Metodos/Matematica/Calculo/Resta/RestaDescomposicion/M84Bis';
import M84Practica from './components/Metodos/Matematica/Calculo/Resta/RestaDescomposicion/M84Practica';
import M85 from './components/Metodos/Matematica/Calculo/Resta/RestaDescomposicion/M85';
import LecturaYEscrituraDeNumeros from './components/Metodos/Matematica/LecturaYEscrituraDeNumeros';
import EscrituraMatematica from './components/Metodos/Matematica/LecturaYEscrituraDeNumeros/EscrituraMatematica';
import M69 from './components/Metodos/Matematica/LecturaYEscrituraDeNumeros/EscrituraMatematica/M69';
import LecturaMatematica from './components/Metodos/Matematica/LecturaYEscrituraDeNumeros/LecturaMatematica';
import M74 from './components/Metodos/Matematica/LecturaYEscrituraDeNumeros/LecturaMatematica/M74';
import Cognicion from './components/Metodos/Cognicion';
import Lenguaje from './components/Metodos/Lenguaje';
import Emocion from './components/Metodos/Emocion';
import Contacto from './components/Varios/Contacto';
import Testeo from './components/OtrosUsuarios/Testeo';
import PacienteUsuario from './components/OtrosUsuarios/PacienteUsuario';
import Gestion from './components/OtrosUsuarios/Gestion';
import Tutores from './components/OtrosUsuarios/Tutores';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthRol from './components/rutas/AuthRol';

// Redux
import { Provider } from 'react-redux';
import store from './store';

function App() {

  useEffect(() => {   
    window.onpopstate = () => {
      window.location = "https://metodoyogen.com/login";
    }
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/quienes-somos" element={<QuienesSomos />} />
          <Route path="/login" element={<Login />} />
          <Route path="/nueva-cuenta" element={<NuevaCuenta />} />
          <Route path="/restablecer-password" element={<RestablecerPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/confirmar-cuenta/:token" element={<ConfirmarCuenta />} />
          <Route path="/invitacion-paciente/:token" element={<InvitacionPaciente />} />
          <Route path="/teste" element={<Testeo />} />
          <Route path="/pagoo" element={<Tutores />} />
          <Route element={<AuthRol rolPermitido={[249831]} />}>
            <Route path="/gestion" element={<Gestion />} />
          </Route>
          {/*A partir de acá <RutaPrivada />*/}
          
          <Route element={<AuthRol rolPermitido={[321684,489431,935483]} />}>
            <Route path="/escritorio" element={<Escritorio />} />
            <Route path="/escritorio/miperfil" element={<AdministrarPerfil />} />
            <Route path="/escritorio/misuscripcion" element={<MiSuscripcion />} />
            <Route path="/escritorio/pacientes" element={<Pacientes />} />
            <Route path="/escritorio/pacientes/nuevo-paciente" element={<NuevoPaciente />} />
            <Route path="/escritorio/pacientes/paciente" element={<Paciente />} />
            <Route path="/escritorio/pacientes/paciente/datos" element={<DatosPaciente />} />
            <Route path="/escritorio/pacientes/paciente/anamnesis" element={<Anamnesis />} />
            <Route path="/escritorio/pacientes/paciente/historiaclinica" element={<HistoriaClinica />} />
            <Route path="/escritorio/pacientes/paciente/resultados" element={<Resultados />} />
            <Route path="/escritorio/pacientes/paciente/informesprevios" element={<InformesPrevios />} />
            <Route path="/escritorio/pacientes/paciente/calendario" element={<CalendarioPaciente />} />
            <Route path="/escritorio/pacientes/paciente/objetivos" element={<Objetivos />} />
            <Route path="/escritorio/pacientes/paciente/gestionarmetodo" element={<GestionarMetodo />} />
            <Route path="/escritorio/contacto" element={<Contacto />} />
          </Route>
            <Route path="/escritorio/pacientes/paciente/estadisticas" element={<Estadisticas />} />
          <Route element={<AuthRol rolPermitido={[489431,935483]} />}>
            <Route path="/escritorio/pacientes/paciente/seleccionaractividades" element={<SeleccionarActividades />} />
          </Route>
          <Route element={<AuthRol rolPermitido={[489431,935483,894616]} />}>
            {/* <Route path="/escritorio/resultados" element={<Resultados />} /> */}
              <Route path="/escritorio/metodos" element={<Metodos />} />
              <Route path="/escritorio/metodos/finalizar-ejercicio" element={<FinalEjercicio />} />
              <Route path="/escritorio/metodos/lecto-escritura" element={<LectoEscritura />} />
              <Route path="/escritorio/metodos/lecto-escritura/letras" element={<Letras />} />
              <Route path="/escritorio/metodos/lecto-escritura/letras/vocales" element={<Vocales />} />
              <Route path="/escritorio/metodos/lecto-escritura/letras/vocales/introduccion" element={<Introduccion />} />
              <Route path="/escritorio/metodos/lecto-escritura/letras/vocales/repaso" element={<Repaso />} />
              <Route path="/escritorio/metodos/lecto-escritura/letras/vocales/fluidez-vocales" element={<FluidezVocales />} />
              <Route path="/escritorio/metodos/lecto-escritura/letras/consonantes" element={<Consonantes />} />
              <Route path="/escritorio/metodos/lecto-escritura/letras/consonantes/LN45" element={<LN45 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura" element={<Lectura />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita" element={<InstruccionExplicita />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN1" element={<LN1 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN2" element={<LN2 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN3" element={<LN3 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN4" element={<LN4 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN5" element={<LN5 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN6" element={<LN6 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN7" element={<LN7 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN8" element={<LN8 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-explicita/LN9" element={<LN9 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones" element={<InstruccionSinfones />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN1IS" element={<LN1IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN2IS" element={<LN2IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN3IS" element={<LN3IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN4IS" element={<LN4IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN5IS" element={<LN5IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN6IS" element={<LN6IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN7IS" element={<LN7IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN8IS" element={<LN8IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/instruccion-sinfones/LN9IS" element={<LN9IS />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silabas" element={<Silabas />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silabas/LN12" element={<LN12 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-directa" element={<SilabaDirecta />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-directa/LN13SD" element={<LN13SD />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-inversa" element={<SilabaInversa />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-inversa/LN13SI" element={<LN13SI />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-trabada" element={<SilabaTrabada />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-trabada/LN13ST" element={<LN13ST />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-mixta" element={<SilabaMixta />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-mixta/LN13SM" element={<LN13SM />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-conectores" element={<SilabaConectores />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-conectores/LN13SC" element={<LN13SC />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-diptongo" element={<SilabaDiptongo />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-diptongo/LN13SDIP" element={<LN13SDIP />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-hiato" element={<SilabaHiato />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/silaba-hiato/LN13SH" element={<LN13SH />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/pseudopalabras" element={<Pseudopalabras />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/pseudopalabras/LN44" element={<LN44 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/ejercicios" element={<Ejercicios />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN14" element={<LN14 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN18" element={<LN18 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN19" element={<LN19 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN20" element={<LN20 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN21" element={<LN21 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN30" element={<LN30 />} />
              <Route path="/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN31" element={<LN31 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura" element={<Escritura />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/silaba-directa" element={<SilabaDirectaE />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC1" element={<ESC1 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC2" element={<ESC2 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC3" element={<ESC3 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC4" element={<ESC4 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC5" element={<ESC5 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/silaba-directa/ESC6" element={<ESC6 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/sinfones" element={<Sinfones />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/sinfones/ESC7" element={<ESC7 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/sinfones/ESC8" element={<ESC8 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/ortografia" element={<Ortografia />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/ortografia/ESC9" element={<ESC9 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/ortografia/ESC10" element={<ESC10 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/oraciones" element={<Oraciones />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/oraciones/ESC11" element={<ESC11 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/oraciones/ESC12" element={<ESC12 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/descripciones/ESC13" element={<ESC13 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/cuentos" element={<Cuentos />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/cuentos/ESC14" element={<ESC14 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/cuentos/ESC15" element={<ESC15 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/transicion-de-letras" element={<TransicionDeLetras />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/transicion-de-letras/ESC16" element={<ESC16 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/transicion-de-letras/ESC17" element={<ESC17 />} />
              <Route path="/escritorio/metodos/lecto-escritura/escritura/transicion-de-letras/ESC18" element={<ESC18 />} />
              <Route path="/escritorio/metodos/matematica" element={<Matematica />} />
              <Route path="/escritorio/metodos/matematica/subitizacion" element={<Subitizacion />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-1" element={<Nivel1 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-1/M1" element={<M1 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-1/M2" element={<M2 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-1/M3" element={<M3 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-1/M4" element={<M4 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-1/M5" element={<M5 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-2" element={<Nivel2 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-2/M6" element={<M6 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-2/M7" element={<M7 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-2/M8" element={<M8 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-2/M9" element={<M9 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-2/M10" element={<M10 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-3" element={<Nivel3 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-3/M11" element={<M11 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-3/M12" element={<M12 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-3/M13" element={<M13 />} />
              <Route path="/escritorio/metodos/matematica/subitizacion/nivel-3/M14" element={<M14 />} />
              <Route path="/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad" element={<EstimacionYNocionDeCantidad />} />
              <Route path="/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/estimacion-de-cantidad" element={<EstimacionDeCantidad />} />
              <Route path="/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/estimacion-de-cantidad/M15" element={<M15 />} />
              <Route path="/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/estimacion-de-cantidad/M16" element={<M16 />} />
              <Route path="/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/nocion-de-cantidad" element={<NocionDeCantidad />} />
              <Route path="/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/nocion-de-cantidad/M17" element={<M17 />} />
              <Route path="/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/nocion-de-cantidad/M18" element={<M18 />} />
              <Route path="/escritorio/metodos/matematica/conteo" element={<Conteo />} />
              <Route path="/escritorio/metodos/matematica/conteo/1-9/M19" element={<M19 />} />
              <Route path="/escritorio/metodos/matematica/conteo/1-9/M19Bis" element={<M19Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/10-19/M21" element={<M21 />} />
              <Route path="/escritorio/metodos/matematica/conteo/10-19/M21Bis" element={<M21Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/20-29/M22" element={<M22 />} />
              <Route path="/escritorio/metodos/matematica/conteo/20-29/M22Bis" element={<M22Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/30-39/M23" element={<M23 />} />
              <Route path="/escritorio/metodos/matematica/conteo/30-39/M23Bis" element={<M23Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/40-49/M24" element={<M24 />} />
              <Route path="/escritorio/metodos/matematica/conteo/40-49/M24Bis" element={<M24Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/50-59/M25" element={<M25 />} />
              <Route path="/escritorio/metodos/matematica/conteo/50-59/M25Bis" element={<M25Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/60-69/M26" element={<M26 />} />
              <Route path="/escritorio/metodos/matematica/conteo/60-69/M26Bis" element={<M26Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/70-79/M27" element={<M27 />} />
              <Route path="/escritorio/metodos/matematica/conteo/70-79/M27Bis" element={<M27Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/80-89/M28" element={<M28 />} />
              <Route path="/escritorio/metodos/matematica/conteo/80-89/M28Bis" element={<M28Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/90-99/M29" element={<M29 />} />
              <Route path="/escritorio/metodos/matematica/conteo/90-99/M29Bis" element={<M29Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/10-99/M86Bis" element={<M86Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/decena/M20" element={<M20 />} />
              <Route path="/escritorio/metodos/matematica/conteo/decena/M20Bis" element={<M20Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/centena/M30" element={<M30 />} />
              <Route path="/escritorio/metodos/matematica/conteo/centena/M30Bis" element={<M30Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/unidad-de-mil/M31" element={<M31 />} />
              <Route path="/escritorio/metodos/matematica/conteo/unidad-de-mil/M31Bis" element={<M31Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/millon/M32" element={<M32 />} />
              <Route path="/escritorio/metodos/matematica/conteo/millon/M32Bis" element={<M32Bis />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10" element={<Ejercitacion1Al10 />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M33" element={<M33 />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M34" element={<M34 />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M35" element={<M35 />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M36" element={<M36 />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M37" element={<M37 />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M39" element={<M39 />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M40" element={<M40 />} />
              <Route path="/escritorio/metodos/matematica/conteo/ejercitacion-1-10/M41" element={<M41 />} />
              <Route path="/escritorio/metodos/matematica/base-10" element={<Base10 />} />
              <Route path="/escritorio/metodos/matematica/base-10/M43" element={<M43 />} />
              <Route path="/escritorio/metodos/matematica/base-10/M44" element={<M44 />} />
              <Route path="/escritorio/metodos/matematica/base-10/M45" element={<M45 />} />
              <Route path="/escritorio/metodos/matematica/base-10/M46" element={<M46 />} />
              <Route path="/escritorio/metodos/matematica/base-10/M47" element={<M47 />} />
              <Route path="/escritorio/metodos/matematica/calculo" element={<Calculo />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma" element={<Suma />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/m48" element={<M48 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion" element={<Ejercitacion />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion/M49" element={<M49 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion/M50" element={<M50 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion/M51" element={<M51 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion/M52" element={<M52 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion/M54" element={<M54 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion/M42" element={<M42 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion/M38" element={<M38 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/ejercitacion/M87" element={<M87 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/calculo-mental" element={<CalculoMentalSuma />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/calculo-mental/M55" element={<M55 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/calculo-mental/M55Bis" element={<M55Bis />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/calculo-mental/M56" element={<M56 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/calculo-mental/M57" element={<M57 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/calculo-mental/M58" element={<M58 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/calculo-mental/M59" element={<M59 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/calculo-mental/M60" element={<M60 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-tradicional" element={<SumaTradicional />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-tradicional/M61" element={<M61 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-tradicional/M61Bis" element={<M61Bis />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-tradicional/M61Practica" element={<M61Practica />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-descomposicion" element={<SumaDescomposicion />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-descomposicion/M62" element={<M62 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-descomposicion/M62Bis" element={<M62Bis />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-descomposicion/M62Practica" element={<M62Practica />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-descomposicion/M63" element={<M63 />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-descomposicion/M63Bis" element={<M63Bis />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-descomposicion/M63Practica" element={<M63Practica />} />
              <Route path="/escritorio/metodos/matematica/calculo/suma/suma-descomposicion/M64" element={<M64 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta" element={<Resta />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/m65" element={<M65 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/ejercitacion" element={<EjercitacionResta />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/ejercitacion/M66" element={<M66 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/ejercitacion/M67" element={<M67 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/ejercitacion/M68" element={<M68 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/ejercitacion/M70" element={<M70 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/ejercitacion/M71" element={<M71 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental" element={<CalculoMentalResta />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M72" element={<M72 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M73" element={<M73 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M75" element={<M75 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M76" element={<M76 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M77" element={<M77 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M78" element={<M78 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M79" element={<M79 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M80" element={<M80 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/calculo-mental/M81" element={<M81 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-tradicional" element={<RestaTradicional />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-tradicional/M82" element={<M82 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-tradicional/M82Bis" element={<M82Bis />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-tradicional/M82Practica" element={<M82Practica />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-tradicional/M89" element={<M89 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-tradicional/M89Bis" element={<M89Bis />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-tradicional/M89Practica" element={<M89Practica />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-descomposicion" element={<RestaDescomposicion />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M83" element={<M83 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M83Bis" element={<M83Bis />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M83Practica" element={<M83Practica />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M84" element={<M84 />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M84Bis" element={<M84Bis />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M84Practica" element={<M84Practica />} />
              <Route path="/escritorio/metodos/matematica/calculo/resta/resta-descomposicion/M85" element={<M85 />} />
              <Route path="/escritorio/metodos/matematica/lectura-y-escritura-de-numeros" element={<LecturaYEscrituraDeNumeros />} />
              <Route path="/escritorio/metodos/matematica/lectura-y-escritura-de-numeros/escritura" element={<EscrituraMatematica />} />
              <Route path="/escritorio/metodos/matematica/lectura-y-escritura-de-numeros/escritura/M69" element={<M69 />} />
              <Route path="/escritorio/metodos/matematica/lectura-y-escritura-de-numeros/lectura" element={<LecturaMatematica />} />
              <Route path="/escritorio/metodos/matematica/lectura-y-escritura-de-numeros/lectura/M74" element={<M74 />} />
              <Route path="/escritorio/metodos/cognicion" element={<Cognicion />} />
              <Route path="/escritorio/metodos/lenguaje" element={<Lenguaje />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica" element={<CCFonemica />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN1" element={<LEN1 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN2" element={<LEN2 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN3" element={<LEN3 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN4" element={<LEN4 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN5" element={<LEN5 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN6" element={<LEN6 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN8" element={<LEN8 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN9" element={<LEN9 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN10" element={<LEN10 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN31" element={<LEN31 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica" element={<ConcienciaFonologica />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-rima-lexica" element={<CCRimaLexica />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-rima-lexica/LEN25" element={<LEN25 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-rima-lexica/LEN26" element={<LEN26 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica" element={<CCSilabica />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN20" element={<LEN20 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN21" element={<LEN21 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN22" element={<LEN22 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN23" element={<LEN23 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica/LEN24" element={<LEN24 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica" element={<CCLexica />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN15" element={<LEN15 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN16" element={<LEN16 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN17" element={<LEN17 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN18" element={<LEN18 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN19" element={<LEN19 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica/LEN30" element={<LEN30 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-intrasilabica" element={<CCIntrasilabica />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-intrasilabica/LEN27" element={<LEN27 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-intrasilabica/LEN28" element={<LEN28 />} />
              <Route path="/escritorio/metodos/lenguaje/conciencia-fonologica/cc-intrasilabica/LEN29" element={<LEN29 />} />
              <Route path="/escritorio/metodos/lenguaje/denominacion-rapida" element={<DenominacionRapida />} />
              <Route path="/escritorio/metodos/lenguaje/denominacion-rapida/LEN11" element={<LEN11 />} />
              <Route path="/escritorio/metodos/lenguaje/denominacion-rapida/LEN12" element={<LEN12 />} />
              <Route path="/escritorio/metodos/lenguaje/denominacion-rapida/LEN13" element={<LEN13 />} />
              <Route path="/escritorio/metodos/lenguaje/denominacion-rapida/LEN14" element={<LEN14 />} />
              <Route path="/escritorio/metodos/emocion" element={<Emocion />} />
            </Route>
            <Route path="/escritorio-paciente" element={<PacienteUsuario />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
