import {
    MOSTRAR_ALERTA,
    OCULTAR_ALERTA,
    MOSTRAR_SUCCESSFUL,
    OCULTAR_SUCCESSFUL
} from '../types';

export function setearAlerta (alerta) {
    return (dispatch) => {
        dispatch (mostrarAlerta(alerta));
    }
}
const mostrarAlerta = (alerta) => ({
    type: MOSTRAR_ALERTA,
    payload: alerta
});

export function ocultarAlertaAction () {
    return (dispatch) => {
        dispatch (ocultarAlerta());
    }
}
const ocultarAlerta = () => ({
    type: OCULTAR_ALERTA
});

export function setearSuccessful (successful) {
    return (dispatch) => {
        dispatch (mostrarSuccessful(successful));
    }
}
const mostrarSuccessful = (successful) => ({
    type: MOSTRAR_SUCCESSFUL,
    payload: successful
});

export function ocultarSuccessfulAction () {
    return (dispatch) => {
        dispatch (ocultarSuccessful());
    }
}
const ocultarSuccessful = () => ({
    type: OCULTAR_SUCCESSFUL
});