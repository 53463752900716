import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
// css
import {Ilustracion, Flex, ContInst, YellowBtn} from '../../../../Css/MetodosCSS/StyledCCSilabica';

const instrucciones = 'Indica si los dibujos comienzan con la misma sílaba.';
const miniInstrucciones = 'Indica si los dibujos comienzan con la misma sílaba.';

const LEN20 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, resultado) => {
        e.preventDefault();
        if(comparar.misma_silaba === 1 && resultado === "si") {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes){
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 1500);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    dispatch( finalizarEjercicioAction() );                     
                }, 1500);
            }
        } else if(comparar.misma_silaba === 0 && resultado === "no") {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            
            GoodWork();
            if(count < indexes){
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 1500);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    dispatch( finalizarEjercicioAction() );       
                }, 1500);
            }
        } else {
            console.log('mal')
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setDisable(false);
            }, 1500);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            return
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN20') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-silabica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length)
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN20",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };

            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} ayuImagen={true} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto">
                        <Flex>
                            {ayuda_imagen ? 
                                <div>{comparar.ayuda_imagen_1}</div>
                            : null}
                            <Ilustracion src={`/img/${comparar.imagen_url_1}.png`} width="10vw" margin="40px"
                            isCorrect={isCorrect}
                            isIncorrect={isIncorrect}/>
                            <Ilustracion src={`/img/${comparar.imagen_url_2}.png`} width="10vw" margin="40px"
                            isCorrect={isCorrect}
                            isIncorrect={isIncorrect}/>
                            {ayuda_imagen ? 
                                <div>{comparar.ayuda_imagen_2}</div>
                            : null}
                        </Flex>
                        <Flex>
                            <YellowBtn width="10vw" height="50px" margin="50px 70px 0px 0px"
                                disabled={disable}
                                onClick={(e) => verificar(e, "si")}
                            >   
                                Si
                            </YellowBtn>
                            <YellowBtn width="10vw" height="50px"  margin="50px 0px 0px 0px"
                                disabled={disable}
                                onClick={(e) => verificar(e, "no")}
                            >   
                                No
                            </YellowBtn>
                        </Flex>
                    </Flex>
                    <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default LEN20;