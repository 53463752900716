import styled, {css, keyframes} from 'styled-components'

const media = {
    min: '@media (min-width:505px) and (max-width:699px)',
    tablet: '@media (min-width:700px) and (max-width:996px)',
    desktop: '@media(min-width:996px) and (max-width:1200px)',
    desktopLg: '@media (min-width:1200px)'
}

export const ContenedorHeaderPaciente = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 30px; 
`

export const LineaHorizontal = styled.div`
    border-top: medium solid #FF9B2A;
    height: 75px;
    margin: 0;
    height: 0;
`

export const PantallaPaciente = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-image: url("/img/pantallapaciente.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
`

export const ContendorPrincipal = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 60px;
`

export const LineaVertical = styled.div`
    border-left: medium solid #FF9B2A;
    height: 75px;
`
export const FlexTable = styled.table`
    background-color: #23AAAF;
    font-family: 'Baloo Bhai 2';
    font-size: 20px;
    position: relative;
    padding: 0px 15px 0px 45px;
    border-radius: 10px;
    margin-bottom: 20px;
    
`
export const HeadTable = styled.thead`
    
    
`
export const TableRow = styled.tr`
    display: grid;
    grid-template-columns: 4% 15% 1% 35% 25% 10% 10%;
    justify-items:center;
    align-items: center;
`

export const TituloColumna = styled.th`
`

export const TituloDos = styled.h2`
    font-family: 'Nunito';
    font-size: 22px;
`

export const TituloTres = styled.h3`
    display: flex;
    justify-content: flex-start;
    font-family: 'Baloo Bhai 2';
    font-size: 20px;
`

export const TituloCuatro = styled.h4`
    font-size: 18px;
`

export const ImagenAbsoluta = styled.img`
    width: 5%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    width: 70px;
    height: auto;
`

export const Imagen = styled.img`
    width: 35px;
    height: 35px;
    cursor: pointer;
`

export const ContHeaderEjPa = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 52% 43% 5%;
    margin: 20px 10px 20px 10px; 
`

export const ContPrinAvanceEj = styled.div`
    background: ${props => props.$completo ? `linear-gradient(to right, #5E57DF 0%, #5E57DF ${props.$completo}%, #F1F0F0 ${props.$completo}%, #F1F0F0 100%)` : "#F1F0F0"};
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-right: 0.5rem;
`

export const ContAvanceEj = styled.div`
    padding: 0.5rem 0.45rem 0.5rem 0.45rem;
`

export const Circulo = styled.div`
    height: 15px;
    width: 15px;
    background-color: #23AAAF;
    border-radius: 50%;
`

export const CircBoton = styled.button`
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin: 0 0 0 0.5rem;
`

export const Estrella = styled.div`
    background-image: url('/img/estrella_roja.png');
`

export const IconoHeaderPaciente = styled.div`
    display: flex;
    align-items: center;
    margin: 0px 10px;
    text-decoration: none;
    color: #646464;
`