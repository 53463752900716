import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clienteAxios from '../../config/axios';

import PerfilNavbar from '../AuxiliaresPaginas/PerfilNavbar';
import useConfigAuth from '../../Hooks/useConfigAuth';
import Alertas from '../Metodos/Compartidos/Alertas';
import Suscripcion from './Suscripcion';
import Suscriptor from './Suscriptor';

import { setearAlerta } from '../../actions/alertaActions';

import { BotonNaranja, Flex, BotonCeleste} from '../Css/StyledPatient';


const Testeo = () => {
    
    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const [cobros, setCobros] = useState(false);
    const [volver, setVolver] = useState(false);
    const [suscripcion, setSuscripcion] = useState(false);
    const [suscriptor, setSuscriptor] = useState(false);
    const [id, setID] = useState('');
    const [email, setEmail] = useState('');
    const [datosActivacion, setDatosActivacion] = useState([]);
    const [datosProfesional, setDatosProfesional] = useState();
    const [duracion, setDuracion] = useState('');
    const [tipoPago, setTipoPago] = useState('');
    const [idProfesionalActivo, setIDProfesionalActivo] = useState('');
    const [idProfesionalDesactivo, setIDProfesionalDesactivo] = useState('');
    const [confirmo, setConfirmo] = useState('');
    const [mensaje, setMensaje] = useState('');

    const alerta = useSelector ( (state) => state.alertas.alerta);

    const buscarActivados = async (e) => {
        e.preventDefault();
        const payload = {
            id,
            email
        }
        try {
            const {data} = await clienteAxios.post('/usuarios/buscoactivados', payload, config);
            setDatosActivacion(data.dataActivacion);
            setDatosProfesional(data.dataProfesional);
        } catch (error) {
            console.log(error.response)
        }
    }

    const cargarActivacion = async (e) => {
        e.preventDefault();
        if(!duracion || !tipoPago || !idProfesionalActivo || duracion === "Seleccione duración" || tipoPago === "Seleccione tipo de pago") {
            const alerta = {
                msg: 'Faltan completar datos'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        const payload = {
            idProfesionalActivo,
            duracion,
            tipoPago
        }
        try {
            const {data} = await clienteAxios.post('/usuarios/activometodo', payload, config);
            setDuracion('');
            setTipoPago('');
            setIDProfesionalActivo('');
            setMensaje(data);
        } catch (error) {
            console.log(error.response)
        }
    }

    const eliminarActivacion = async (e) => {
        e.preventDefault();
        if(!idProfesionalDesactivo || !confirmo || confirmo === "Esta seguro?") {
            const alerta = {
                msg: 'Faltan completar datos'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        const payload = {
            idProfesionalDesactivo
        }
        try {
            const {data} = await clienteAxios.post('/usuarios/desactivometodo', payload, config);
            setIDProfesionalDesactivo('');
            setConfirmo('');
            setMensaje(data);
        } catch (error) {
            console.log(error.response)
        }
    }

    useEffect(() => {
        if(mensaje.length === 0){
            return;
        }
        dispatch( setearAlerta (mensaje) );
    }, [mensaje])

    return (
        <Fragment>
            <PerfilNavbar />
            {alerta ? <Alertas /> : null}
            {volver ? <BotonCeleste onClick={() => (setCobros(false), setSuscripcion(false), setSuscriptor(false), setVolver(false))}>Volver</BotonCeleste> : null}
            {!cobros 
            ?
            <Flex margin="2rem 0 2rem 0" direccion="column">
                <BotonNaranja margin="0 0 1rem 0"
                    onClick={() => (setCobros(true), setVolver(true))}
                >
                    Cobros
                </BotonNaranja>
            </Flex>
            :
            null
            }
            {cobros
            ?
            <Flex margin="2rem 0 2rem 0">
                <BotonNaranja margin="0 1rem 0rem 0"
                    onClick={() => (setSuscripcion(true), setSuscriptor(false))}
                >
                    Suscripciones
                </BotonNaranja>
                <BotonNaranja margin="0rem 0 0rem 1rem"
                    onClick={() => (setSuscriptor(true), setSuscripcion(false))}
                >
                    Suscriptores
                </BotonNaranja>
            </Flex>
            :
            null
            }
            {suscripcion ? <Suscripcion /> : null}
            {suscriptor ? <Suscriptor /> : null}
            
        </Fragment>
    );
}

export default Testeo;