import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { resetearConfigPacienteAction } from '../../actions/resetStoreActions.js';

//css
import {Flex, Ubicacion, Enlace, ContenedorHeaderMetodos, InfoPaciente} from '../Css/StyledHeader.js';

const HeaderMetodos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const nombre = useSelector((state) => state.usuario.nombrePaciente);
    const apellido = useSelector((state) => state.usuario.apellidoPaciente);

    const deseleccionarPaciente = (e) => {
        e.preventDefault();
        navigate("/escritorio/pacientes");
        dispatch( resetearConfigPacienteAction() );
    }

    const volverPaciente = (e) => {
        e.preventDefault();
        navigate("/escritorio/pacientes/paciente");
    }

    return ( 
        <ContenedorHeaderMetodos width={props.width} bgcolor={props.bgcolor} color={props.color} fw={props.fw}>
            <Flex align="center">
                {props.l4
                    ? <Flex align="center">
                        <Link to={`/escritorio/${props.l1}`}> <img src={'/img/icono_logo_blanco.png'} /> </Link>
                        <Enlace to={`/escritorio/${props.l1}${props.l2}`}>{props.n2}</Enlace>
                        <Enlace to={`/escritorio/${props.l1}${props.l2}${props.l3}`}>{props.n3}</Enlace>
                        <Enlace to={`/escritorio/${props.l1}${props.l2}${props.l3}${props.l4}`}>{props.n4}</Enlace>
                        <Ubicacion>{props.componente}</Ubicacion>
                    </Flex>
                    : props.l3
                        ? <Flex align="center">
                            <Enlace to={`/escritorio/${props.l1}`}> <img src={'/img/icono_logo_blanco.png'} /> </Enlace>
                            <Enlace to={`/escritorio/${props.l1}${props.l2}`}>{props.n2}</Enlace>
                            <Enlace to={`/escritorio/${props.l1}${props.l2}${props.l3}`}>{props.n3}</Enlace>
                            <Ubicacion>{props.componente}</Ubicacion>
                        </Flex>
                        : props.l2
                            ? <Flex align="center">
                                <Enlace to={`/escritorio/${props.l1}`}> <img src={'/img/icono_logo_blanco.png'} /> </Enlace>
                                <Enlace to={`/escritorio/${props.l1}${props.l2}`}>{props.n2}</Enlace>
                                <Ubicacion>{props.componente}</Ubicacion>
                            </Flex>
                            : props.l1
                                ? <Flex align="center" $justify="flex-start">
                                    <Enlace to={`/escritorio/${props.l1}`}> <img src={'/img/icono_logo_blanco.png'} /> </Enlace>
                                    <Ubicacion>{props.componente}</Ubicacion>
                                </Flex>
                                : props.componente
                                    ? <Flex align="center" $justify="flex-start">
                                        <Ubicacion>{props.componente}</Ubicacion>
                                    </Flex>
                                    : null
                }
                {props.paciente === false
                    ? null
                    : nombre !== "" 
                        ? <Flex align="center" $justify="flex-end">
                            <InfoPaciente onClick={(e) => volverPaciente(e)}>Paciente: {apellido}, {nombre}</InfoPaciente>
                            <button
                            onClick={(e) => deseleccionarPaciente(e)}
                            >
                                X
                            </button>
                        </Flex>
                        : <Flex padding="0 0.5rem 0 0" align="center" $justify="flex-end"><InfoPaciente>Paciente: sin elegir</InfoPaciente></Flex>
                }
            </Flex>
            {/* <Linea display={props.display}/> */}
        </ContenedorHeaderMetodos>
    );
}
 
export default HeaderMetodos;