import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TipoDenoRapida from '../../../Compartidos/TipoDenoRapida';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Lee la siguiente lista de palabras inventadas.';
const miniInstrucciones = 'Lee la siguiente lista de palabras inventadas.';

const LN44 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN44') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/pseudopalabras/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])

    return (
        <Fragment>
            <TipoDenoRapida data={data} indexes={indexes} ejercicio="LN44" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} ayu_visual={false} />
        </Fragment>
    );
}

export default LN44;