import {
    SETEAR_ID_PACIENTE_EXITO,
    SETEAR_ID_PACIENTE_ERROR
} from '../types';

export function setearIdPacienteAction(informacion) {
    return async (dispatch) => {
        try {
            const {payload} = informacion;
            dispatch( setearIdPacienteExito(payload));
        } catch (error) {
            dispatch( setearIdPacienteError(error) );
        }
    }
}

const setearIdPacienteExito = (data) => ({
    type: SETEAR_ID_PACIENTE_EXITO,
    payload: data
});

const setearIdPacienteError = (msg) => ({
    type: SETEAR_ID_PACIENTE_ERROR,
    payload: msg
});