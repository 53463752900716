import React, {Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../../Metodos/Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { aumentarContadorAction, aumentarContadorTerciarioAction, zeroContadorTerciarioAction } from '../../../../../../actions/botoneraEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, InputM83Bis, ImgB10, ImgDivM63, Container, PasosSubT, DivM83, AvanzarBtn, AvanzarImg, PasosT, Pasos, InputCuadrado, InputRedondo, NumberDivR, NumberDivL, ImgDiv, NumCalculoM83 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la suma.';
const miniInstrucciones = 'Resuelve la suma.';

const M63Bis = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [disableUno, setDisableUno] = useState(false);
    const [disableDos, setDisableDos] = useState(false);
    const [disableTres, setDisableTres] = useState(false);
    const [disableCuatro, setDisableCuatro] = useState(false);
    const [disableCinco, setDisableCinco] = useState(false);
    const [disableSeis, setDisableSeis] = useState(false);
    const [disableSiete, setDisableSiete] = useState(false);
    const [disableOcho, setDisableOcho] = useState(false);
    const [disableNueve, setDisableNueve] = useState(false);
    const [disableDiez, setDisableDiez] = useState(false);
    const [disableOnce, setDisableOnce] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState(0);
    const [digitoD1, setDigitoD1] = useState(0);
    const [digitoU2, setDigitoU2] = useState(0);
    const [digitoD2, setDigitoD2] = useState(0);
    const [digitoIntermedioUno, setDigitoIntermedioUno] = useState(0);
    const [digitoIntermedioUnoA, setDigitoIntermedioUnoA] = useState(0);
    const [digitoIntermedioUnoB, setDigitoIntermedioUnoB] = useState(0);
    const [digitoIntermedioDos, setDigitoIntermedioDos] = useState(0);
    const [digitoIntermedioDosA, setDigitoIntermedioDosA] = useState(0);
    const [digitoFinalUno, setDigitoFinalUno] = useState(0);
    const [digitoFinalDos, setDigitoFinalDos] = useState(0);
    const [numeroUnidadUno, setNumeroUnidadUno] = useState(0);
    const [numeroUnidadDos, setNumeroUnidadDos] = useState(0);
    const [numeroDecenaUno, setNumeroDecenaUno] = useState(0);
    const [numeroDecenaDos, setNumeroDecenaDos] = useState(0);
    const [numeroIntermedioDos, setNumeroIntermedioDos] = useState(0);
    const [numeroIntermedioDosA, setNumeroIntermedioDosA] = useState(0);
    const [numeroIntermedioUno, setNumeroIntermedioUno] = useState(0);
    const [numeroIntermedioUnoA, setNumeroIntermedioUnoA] = useState(0);
    const [numeroIntermedioUnoB, setNumeroIntermedioUnoB] = useState(0);
    const [numeroFinalUno, setNumeroFinalUno] = useState(0);
    const [numeroFinalDos, setNumeroFinalDos] = useState(0);
    const [numeroFinal, setNumeroFinal] = useState(0);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');
    const [forzarAlmacenar, setForzarAlmacenar] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const contadorTerciario = useSelector(state => state.ejercicio.contadorTerciario);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const verificar = (e) => {
        e.preventDefault();
        if(contadorTerciario === 2){
            if( numeroDecenaDos.toString() !== digitoD2.toString()) {
                console.log('mal');
                setDisableUno(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableUno(false);
                    if(numeroDecenaDos.toString() !== digitoD2.toString()) {
                        setNumeroDecenaDos(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableUno(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 3){
            if(numeroUnidadDos.toString() !== digitoU2.toString()) {
                console.log('mal');
                setDisableDos(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableDos(false);
                    if(numeroUnidadDos.toString() !== digitoU2.toString()) {
                        setNumeroUnidadDos(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableDos(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 5){
            if( numeroDecenaUno.toString() !== digitoD1.toString()) {
                console.log('mal');
                setDisableTres(true);
                setTimeout(() => {
                    setDisableTres(false);
                    if(numeroDecenaUno.toString() !== digitoD1.toString()) {
                        setNumeroDecenaUno(0);
                    }
                }, 1500);
            } else {
                console.log("bien");
                setDisableTres(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 6){
            if(numeroUnidadUno.toString() !== digitoU1.toString()) {
                console.log('mal');
                setDisableCuatro(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableCuatro(false);
                    if(numeroUnidadUno.toString() !== digitoU1.toString()) {
                        setNumeroUnidadUno(0);
                    }
                }, 300);
            } else {
                console.log("bien");
                setDisableCuatro(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            }
        }
        if(contadorTerciario === 8){
            if(digitoIntermedioUno.toString() === numeroIntermedioUno.toString()) {
                console.log("bien");
                setDisableCinco(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableCinco(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableCinco(false);
                    setNumeroIntermedioUno(0);
                }, 300);
            }
        }
        if(contadorTerciario === 10){
            if(digitoIntermedioDos.toString() === numeroIntermedioDos.toString()) {
                console.log("bien");
                setDisableSeis(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableSeis(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableSeis(false);
                    setNumeroIntermedioDos(0);
                }, 300);
            }
        }
        if(contadorTerciario === 12){
            if(digitoIntermedioUnoA.toString() === numeroIntermedioUnoA.toString()) {
                console.log("bien");
                setDisableOcho(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableOcho(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableOcho(false);
                    setNumeroIntermedioUnoB(0);
                }, 300);
            }
        }
        if(contadorTerciario === 13){
            if(digitoIntermedioUnoB.toString() === numeroIntermedioUnoB.toString()) {
                console.log("bien");
                setDisableSiete(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableSiete(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableSiete(false);
                    setNumeroIntermedioUnoA(0);
                }, 300);
            }
        }
        if(contadorTerciario === 14){
            if(digitoIntermedioDosA.toString() === numeroIntermedioDosA.toString()) {
                console.log("bien");
                setDisableNueve(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableNueve(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableNueve(false);
                    setNumeroIntermedioDosA(0);
                }, 300);
            }
        }
        if(contadorTerciario === 16){
            if(digitoFinalUno.toString() === numeroFinalUno.toString()) {
                console.log("bien");
                setDisableDiez(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setDisableDiez(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisableDiez(false);
                    setNumeroFinalUno(0);
                }, 300);
            }
        }
        if(contadorTerciario === 18){
            if(digitoFinalDos.toString() === numeroFinalDos.toString()) {
                console.log("bien");
                setDisableOnce(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                dispatch(aumentarContadorTerciarioAction());
            } else {
                console.log('mal');
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setDisableOnce(true);
                setTimeout(() => {
                    setDisableOnce(false);
                    setNumeroFinalDos(0);
                }, 300);
            }
        }
        if(contadorTerciario === 20){
            if((comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() === numeroFinal.toString()) {
                console.log("bien");
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        setDisable(false);
                        setNumeroFinal(0);
                        setCount(count + 1);
                        dispatch( zeroContadorTerciarioAction() );
                    }, 300);
                } else {
                    setTimeout(() => {
                        if(props.mostrar){
                            setUltimoTiempo(true);
                            setForzarAlmacenar(true);
                            dispatch(aumentarContadorAction());
                            dispatch( zeroContadorTerciarioAction() );
                        } else {
                            setUltimoTiempo(true);
                            dispatch( finalizarEjercicioAction() );
                            dispatch( zeroContadorTerciarioAction() );
                        }
                    }, 1500);
                }
            } else {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                setTimeout(() => {
                    setDisable(false);
                    setNumeroFinal(0);
                }, 300);
            }
        }
    }

    useEffect(() => {
        const min = 11;
        const max = 79;
        const randomNumeroUno = Math.floor(Math.random() * (max - min +1)) + min;
        const randomNumeroDos = (function () {
            const u1 = Number(randomNumeroUno.toString().substring(1,2))
            const d1 = Number(randomNumeroUno.toString().substring(0,1));
            const minU = 9-u1;
            const maxU = 9;
            const u2 = Math.floor(Math.random() * (maxU - minU +1)) + minU;
            const minD = 1;
            const maxD = 8-d1;
            const d2 = Math.floor(Math.random() * (maxD - minD +1)) + minD;
            const numero = Number(d2.toString().concat(u2.toString()));
            return numero;
        })();
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('suma_descomposicion_compleja');
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        const pasoUnoU2 = newList.cantidad_1.toString().substring(1,2);
        const pasoUnoU1 = newList.cantidad_2.toString().substring(1,2);
        const pasoUnoD2 = (Number(newList.cantidad_1.toString().substring(0,1))*10).toString();
        const pasoUnoD1 = (Number(newList.cantidad_2.toString().substring(0,1))*10).toString();
        const pasoDosU1 = (Number(pasoUnoU1) + Number(pasoUnoU2)).toString();
        const pasoDosD1 = (Number(pasoUnoD1) + Number(pasoUnoD2)).toString();
        const pasoTresD1 = pasoDosD1;
        const pasoTresD2 = (Number(pasoDosU1.substring(0,1))*10).toString();
        const pasoTresU2 = pasoDosU1.substring(1,2);
        const pasoCuatroD1 = (Number(pasoTresD1) + Number(pasoTresD2)).toString();
        const pasoCuatroU1 = pasoTresU2;
        
        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(pasoUnoU1);
            setDigitoD1(pasoUnoD1);
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(pasoUnoU2);
            setDigitoD2(pasoUnoD2);
        }
        setDigitoIntermedioUno(pasoDosU1);
        setDigitoIntermedioUnoA(pasoTresD2);
        setDigitoIntermedioUnoB(pasoTresU2);
        setDigitoIntermedioDos(pasoDosD1);
        setDigitoIntermedioDosA(pasoTresD1);
        setDigitoFinalUno(pasoCuatroD1);
        setDigitoFinalDos(pasoCuatroU1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M63Bis') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/suma-descomposicion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar || forzarAlmacenar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M63Bis",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, forzarAlmacenar]);
    
    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment
                isCorrect={isCorrect}
                isIncorrect={isIncorrect}
                >
                    {contadorTerciario >= 0 &&
                    <FlexFull justify='space-evenly' align='center'> {/*  border='2px solid blue' */} 
                        <FlexFull direction='column' justify='space-around' align='start' width='48vw' height='60vh' hlg='70vh'> {/*  border='2px solid red' */} 
                            <FlexFull direction='column' justify='center' align='start' width='48vw'>
                                {/* CALCULO */}
                                <FlexFull justify='end' align='center' width='100%'> {/*  border='2px solid blue' */}
                                    {comparar.length === 0 ? null : 
                                        <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                            <NumCalculoM83 color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculoM83>
                                            <NumCalculoM83 color='#646464'>+</NumCalculoM83>                                     
                                            <NumCalculoM83 color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculoM83>
                                            <NumCalculoM83 color='#646464'>=</NumCalculoM83>  
                                            <NumCalculoM83 color='#646464'>       
                                            {contadorTerciario >= 11 && 
                                            <form onSubmit={(e) => onSubmit(e)}>
                                                <InputM83Bis type="text" value={numeroFinal} disabled={true} />
                                            </form>
                                            }
                                            </NumCalculoM83>  
                                        </FlexFull>
                                    }
                                </FlexFull>
                                {/* FIN CALCULO */}
                                {/* DESCOMPOSICIÓN */}
                                <FlexFull justify='space-around' align='center' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='-18px 0vw 0vh 15px'> {/* border='3px solid blue' */}
                                    <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 2vw 0vh 0vw' mt='0px 1.5vw 0vh 0px' md='0px 1.2vw 0vh 0px' mlg='0px 2.3vw 0vh 0px'> { /* border='3px solid violet'     mt='0vh 1.5vw 0vh 0vw' mlg='0px' */}
                                    {/* {contadorTerciario >= 1 && */}
                                    {contadorTerciario >= 2 &&
                                    <div>
                                        <ImgDiv>
                                            <ImgB10 src={`/img/lineasDescomposicion.png`}  w='55%' h='12vh' hm='12vh' hlg='15vh' />
                                        </ImgDiv>
                                        <Flex>
                                            <NumberDivL>
                                                {contadorTerciario >= 2 &&
                                                    <InputRedondo type="text" min="1" max="10000" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disableUno} />
                                                }
                                            </NumberDivL>
                                            <NumberDivR>
                                                {contadorTerciario >= 3 &&
                                                    <InputCuadrado type="text" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disableDos} />
                                                }
                                            </NumberDivR>
                                        </Flex>
                                    </div>
                                    }
                                    </Flex>
                                    <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 0vw 0vh 2vw' mlg='0px'>  { /*   margin='0vh 0vw 0vh 4vw'   border='1px solid green' */}
                                        {/* {contadorTerciario >= 4 && */}
                                        {contadorTerciario >= 5 &&
                                        <div>
                                            <ImgDiv>
                                                <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='12vh' hm='12vh' hlg='15vh' /> {/* h='14vh' hm='14vh' hlg='17vh' */}
                                            </ImgDiv>
                                            <Flex>
                                                <NumberDivL>
                                                    {contadorTerciario >= 5 &&
                                                    <InputRedondo type="text" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disableTres} />
                                                    }
                                                </NumberDivL>
                                                <NumberDivR>
                                                    {contadorTerciario >= 6 &&
                                                    <InputCuadrado type="text" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disableCuatro} />
                                                    }
                                                </NumberDivR>
                                            </Flex>
                                        </div>
                                        }
                                    </Flex>
                                </FlexFull>
                                {/* ------------------ MÁS NUEVO */}
                                <FlexFull justify='space-evenly' align='center' width='32.3vw' minw='32.3vw' margin='-27px 0vw 0vh 0px' mm='-25px 0vw 0vh 0px' mt='-23px 0vw 0vh -5px' md='-20px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px'> {/* border='2px solid blue' margin='-7.5vh 0vw 0vh 0px' mm='-43px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px' */}
                                    <Container minw='50%' dis='flex' justify='center' align='center'>
                                        {contadorTerciario >= 10 &&
                                            <InputRedondo type="text" value={numeroIntermedioDos} onChange={(e) => setNumeroIntermedioDos(e.target.value)} disabled={disableSeis} />
                                        }
                                        {/* INT2 */}
                                    </Container>
                                    {/* <Container minw='6vw'>
                                        {contadorTerciario >= 14 &&
                                            <InputCuadrado type="text" value={numeroIntermedioDosA} onChange={(e) => setNumeroIntermedioDosA(e.target.value)} disabled={disableNueve} /> 
                                        }INT2A
                                    </Container> */}
                                    <Container minw='50%' dis='flex' justify='center' align='center'>
                                        {/* {contadorTerciario >= 6 && */}
                                        {contadorTerciario >= 8 &&
                                            <InputCuadrado value={numeroIntermedioUno} onChange={(e) => setNumeroIntermedioUno(e.target.value)} disabled={disableCinco} m='0px 10px 20px 0px' mm='0px 0px 20px 0px' mt='0px 0px 20px 0px' md='0px 0px 15px 0px' mlg='0px 0px 0px 30px' /> 
                                        }
                                        {/* INT1   */}
                                        {/*  m='0px 10px 20px 0px' mm='0px 0px 20px 0px' mt='0px 0px 20px 0px' md='0px 0px 15px 0px' mlg='0px 0px 0px 0px'  */}
                                    </Container>
                                </FlexFull>
                                {/* ------------------ FIN MÁS NUEVO */}
                                {/* ---------------- NUEVO */}
                                {contadorTerciario >= 6 &&
                                <FlexFull justify='end' align='center' width='32.3vw' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' margin='53px 0vw 0vh 0px' mm='38px 0vw 0vh 0px' mt='23px 0vw 0vh -5px' md='3px 0vw 0vh -5px' mlg='-5px 0vw 5px 15px' >  { /* width='32.3vw' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' margin='0vh 0vw 0vh 0px' mm='0px 0vw 0vh 0px' mt='0px 0vw 0vh -5px' md='0px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px' border='2px solid green' */}
                                    {/* {contadorTerciario >= 10 && */}
                                    {contadorTerciario >= 15 &&
                                    // <FlexFull justify='center' align='flex-end' minw='50%' minh='100%' maxh='100%' border='2px solid red'>
                                    //     <InputRedondo type="text" value={numeroIntermedioDosA} onChange={(e) => setNumeroIntermedioDosA(e.target.value)} disabled={disableNueve} m='-20px 10px 0px 0px' mm='-20px 0px 0px 0px' mt='-20px 0px 0px 0px' md='-15px 0px 0px 0px' mlg='-80px 0px 80px 0px' /> {/* m='0px 10px 20px 0px' mm='0px 0px 20px 0px' mt='0px 0px 20px 0px' md='0px 0px 15px 0px' mlg='0px 0px 0px 0px' */}
                                    // </FlexFull>
                                    <FlexFull direction='column' minw='50%' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' justify='end' align='center' padding='0px' margin='0px 15px 40px 0px' mm='0px 0px 45px 0px' mt='0px 0px 45px 0px' md='0px 0px 35px 0px' mlg='0px 0px 0px 0px'>
                                        <FlexFull>
                                            <InputRedondo type="text" value={numeroIntermedioDosA} onChange={(e) => setNumeroIntermedioDosA(e.target.value)} disabled={disableNueve} />
                                            {/* INT2A ESTE ES EL QUE ANDA MAL */}
                                        </FlexFull>
                                    </FlexFull>
                                    }
                                    <FlexFull justify='end' align='end' minw='50%' minh='100%' maxh='100%'>
                                    {contadorTerciario >= 7 &&
                                    <div>
                                        {contadorTerciario >= 8 &&
                                        <div>
                                            {/* <div>FORM</div> */}
                                            <FlexFull direction='column' minw='50%' height='60px' hm='80px' ht='100px' hd='120px' hlg='140px' justify='end' align='center' padding='0px' margin='0px -20px 0px 5px' mm='0px -16px 0px 0px' mt='0px 0px 0px 5px' md='0px 0px 0px 5px' mlg='0px 0px 0px 5px'>
                                                {contadorTerciario >= 12 && 
                                                <ImgDivM63>
                                                    <ImgB10 src={`/img/lineasDescomposicion.png`} w='45%' h='12vh' margin='40px 0px 0px 0px' mm='50px 0px 0px -5px' mt='50px 0px 0px -3px' md='50px 0px 0px -12px' mlg='50px 0px 0px -5px'  />
                                                </ImgDivM63>
                                                }
                                                {contadorTerciario >= 11 && 
                                                <Flex minw='100%'> {/* margin='0px 0px 0px 15px' */}
                                                    <NumberDivL>
                                                        {contadorTerciario >= 12 && 
                                                            <InputRedondo type="text" value={numeroIntermedioUnoA} onChange={(e) => setNumeroIntermedioUnoA(e.target.value)} disabled={disableOcho} />
                                                        }
                                                    </NumberDivL>
                                                    <NumberDivR>
                                                        {contadorTerciario >= 13 &&
                                                            <InputCuadrado type="text" min="1" max="10000" value={numeroIntermedioUnoB} onChange={(e) => setNumeroIntermedioUnoB(e.target.value)} disabled={disableSiete} />
                                                        }
                                                    </NumberDivR>
                                                </Flex>
                                                }
                                            </FlexFull>
                                        </div>
                                    }
                                        </div>
                                    }
                                    </FlexFull>
                                </FlexFull>
                                }                                       
                                {/* ---------------- NUEVO */}
                                {/* FIN DESCOMPOSICIÓN */}
                            </FlexFull>
                            {/* CALCULO ABAJO */}
                            <FlexFull justify='center' align='center' width='100%'>
                                <FlexFull justify='space-around' align='center'> 
                                    <DivM83>
                                    {contadorTerciario >= 15 &&
                                        <div>
                                            {contadorTerciario >= 16 &&
                                            <InputRedondo type="text" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disableDiez} />
                                            }
                                        </div>
                                    }
                                    </DivM83>
                                    <DivM83>
                                    {contadorTerciario >= 19 &&
                                        <NumCalculoM83 color='#646464'>+</NumCalculoM83>
                                    }
                                    </DivM83>
                                    <DivM83>
                                    {contadorTerciario >= 17 &&
                                        <div>
                                            {contadorTerciario >= 18 &&
                                            <InputCuadrado type="text" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disableOnce} />
                                            }
                                        </div>
                                    }
                                    </DivM83>
                                    <DivM83>
                                    {contadorTerciario >= 19 &&
                                        <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                    }
                                    </DivM83>
                                    <DivM83>
                                    {contadorTerciario >= 20 && 
                                    <InputCuadrado type="text" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                                    }
                                    </DivM83>
                                </FlexFull>
                            </FlexFull>
                            {/* FIN CALCULO ABAJO */}
                        </FlexFull>
                        {/* PASOS */}
                        <FlexFull direction='column' justify='start' align='center' width='25vw' height='80vh'>
                            <PasosT>
                                PASOS
                            </PasosT>
                            <PasosSubT>
                                SUMA DESCOMPOSICIÓN
                            </PasosSubT>
                            {contadorTerciario >= 1 &&
                            <Pasos bgcolor='#D76970'>
                                1- DESCOMPONGO EL PRIMER NÚMERO
                            </Pasos>
                            }
                            {contadorTerciario >= 4 &&
                            <Pasos bgcolor='#23AAAF'>
                                2- DESCOMPONGOO EL SEGUNDO NÚMERO
                            </Pasos>
                            }
                            {contadorTerciario >= 7 &&
                            <Pasos bgcolor='#FF9B2A'>
                                3- SUMO LAS UNIDADES
                            </Pasos>
                            }
                            {contadorTerciario >= 9 &&
                            <Pasos bgcolor='#FFDC73'>
                                4- SUMO LAS DECENAS
                            </Pasos>
                            }
                            {contadorTerciario >= 11 &&
                            <Pasos bgcolor='#655EE8'>
                                5- VUELVO A DESCOMPONER LAS UNIDADES
                            </Pasos>
                            }
                            {/* {contadorTerciario >= 15 && */}
                            {contadorTerciario >= 14 &&
                            <Pasos bgcolor='#D76970'>
                                6- SUMO LAS DECENAS
                            </Pasos>
                            }
                            {contadorTerciario >= 17 &&
                            <Pasos bgcolor='#23AAAF'>
                                7- VUELVO A ESCRIBIR LAS UNIDADES
                            </Pasos>
                            }
                            {contadorTerciario >= 19 &&
                            <Pasos bgcolor='#FF9B2A'>
                                8- HAGO LA SUMA
                            </Pasos>
                            }
                            {contadorTerciario >= 20 &&
                            <Pasos bgcolor='#FFDC73'>
                                9- ¿CUÁL ES EL RESULTADO?
                            </Pasos>
                            }
                        </FlexFull>
                        {/* FIN PASOS */}
                    </FlexFull>
                    }
                    <FlexCuz  position='fixed' right='0' bottom='0'>
                    {contadorTerciario === 0 && <BotoneraBottom aumentarTerciario={true} />}
                    {contadorTerciario === 1 || contadorTerciario === 4 || contadorTerciario === 7 || contadorTerciario === 9 || contadorTerciario === 11  || contadorTerciario === 15 || contadorTerciario === 17 || contadorTerciario === 19 ? <BotoneraBottom aumentarTerciario={true} /> : null}
                    {contadorTerciario === 2 || contadorTerciario === 3 || contadorTerciario === 5 || contadorTerciario === 6 || contadorTerciario === 10 || contadorTerciario === 14 || contadorTerciario === 8 || contadorTerciario === 13 || contadorTerciario === 12  || contadorTerciario === 16  || contadorTerciario === 18  || contadorTerciario === 20 ?
                    
                    <FlexCuz position='fixed' right='0' bottom='0'>
                            <AvanzarBtn
                                disabled={disable}
                                onClick={(e) => verificar(e)}
                            >
                            <AvanzarImg src={`/img/play.png`} /> 
                        </AvanzarBtn>
                    </FlexCuz>
                    :null
                    }
                    </FlexCuz>
                    
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M63Bis;