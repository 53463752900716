import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, ContainerImg, FlexLink, Descripcion } from '../../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../../Css/StyledHeader';

const Consonantes = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} l3={"letras/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} n3={"LETRAS"} componente={"CONSONANTES"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Container justify="center" direction="column" width="100%" height="100%" margin="2rem 0 0 0">
                <Container justify="space-around" wrap="wrap" >
                    <FlexLink to={'/escritorio/metodos/lecto-escritura/letras/consonantes/LN45'}><ContainerImg src={`/img/portadas/LN45.jpg`} alt=""/> <Descripcion>SÍLABA INICIAL</Descripcion></FlexLink>
                </Container>
            </Container>
        </Fragment>
    );
}
 
export default Consonantes;