import { Link } from 'react-router-dom';

import Header2 from '../../AuxiliaresPaginas/Header2';

import {FlexHeaderDos, Icon, Linea} from '../../Css/StyledHeader.js';
import { Fragment } from 'react';

const HeaderDos = (props) => {
    const {link, componente, paciente, color, fw, bgcolor, width, n1, n2, n3, n4, l1, l2, l3, l4} = props;
    return (
        <Fragment>
            <Linea />
            <FlexHeaderDos>
                {link ? <Link to={link}><Icon src={`/img/volver.svg`} alt="" width="20px" margin="0 1rem 0 0" /></Link> : null }
                <Header2 componente={componente} paciente={paciente} color={color} fw={fw} bgcolor={bgcolor} width={width} n1={n1} n2={n2} n3={n3} n4={n4} l1={l1} l2={l2} l3={l3} l4={l4} />
            </FlexHeaderDos>
        </Fragment>
    );
}
 
export default HeaderDos;