import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';
import M84Bis from './M84Bis';
import M84Practica from './M84Practica';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, FlexFull, ImgB10, PasosSubT, DivM83, NumCuadradoTachado, NumRedondoTachado, PasosT, Pasos, NumRedondo, NumCuadrado, NumberDivR, NumberDivL, ImgDiv, NumCalculoM83 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta siguiendo los pasos.';
const miniInstrucciones = 'Resuelve la resta siguiendo los pasos.';

const M84 = () => {

    const dispatch = useDispatch();

    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);
    const contadorSecundario = useSelector(state => state.ejercicio.contadorSecundario);

    useEffect(() => {
        dispatch(setearEjercicioAction('M84') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/resta-descomposicion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
                <Fragment>
                    {iniciar || fuerzoInicio
                        ?
                        <Fragment>
                            {contador=== 0 &&
                            <div>
                                {contadorSecundario >= 0 &&
                                <div>
                                    <FlexFull justify='space-evenly' align='center'> {/*  border='2px solid blue' */} 
                                        <FlexFull direction='column' justify='space-around' align='start' width='48vw' height='60vh' hlg='70vh'> {/*  border='2px solid red' */} 
                                            <FlexFull direction='column' justify='center' align='start' width='48vw'>
                                            {/* CALCULO */}
                                            <FlexFull justify='end' align='center' width='100%'> {/*  border='2px solid blue' */} 
                                                <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                                    <NumCalculoM83 color='#D76970'>35</NumCalculoM83>
                                                    <NumCalculoM83 color='#646464'>-</NumCalculoM83>                                     
                                                    <NumCalculoM83 color='#23AAAF'>19</NumCalculoM83>
                                                    <NumCalculoM83 color='#646464'>=</NumCalculoM83>                                     
                                                    <NumCalculoM83 color='#646464'>
                                                    {contadorSecundario >= 18 && <div>16</div>}
                                                    </NumCalculoM83>
                                                </FlexFull>
                                            </FlexFull>
                                            {/* FIN CALCULO */}

                                            {/* DESCOMPOSICIÓN */}
                                            <FlexFull justify='space-around' align='center' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='-18px 0vw 0vh 15px'> {/* border='3px solid blue' */}
                                                <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 2vw 0vh 0vw' mt='0px 1.5vw 0vh 0px' md='0px 1.2vw 0vh 0px' mlg='0px 2.3vw 0vh 0px'> { /* border='3px solid violet'     mt='0vh 1.5vw 0vh 0vw' mlg='0px' */}
                                                {contadorSecundario >= 1 &&
                                                <div>
                                                    <ImgDiv>
                                                        <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                                    </ImgDiv>
                                                    <Flex>
                                                        <NumberDivL>
                                                            {contadorSecundario >= 2 &&
                                                            <NumRedondo>30</NumRedondo>
                                                            }
                                                        </NumberDivL>
                                                        <NumberDivR>
                                                            


                                                            {contadorSecundario >= 3 && contadorSecundario <= 12 ?
                                                                <NumCuadrado>5</NumCuadrado> :
                                                                contadorSecundario <= 2 ?
                                                                null :
                                                                <NumCuadradoTachado>5</NumCuadradoTachado>
                                                            }
                                                        </NumberDivR>
                                                    </Flex>
                                                </div>
                                                }
                                                </Flex>
                                                <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='0vh 0vw 0vh 2vw' mlg='0px'>  { /*   margin='0vh 0vw 0vh 4vw'   border='1px solid green' */}
                                                    {contadorSecundario >= 4 &&
                                                    <div>
                                                        <ImgDiv>
                                                            <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='14vh' hm='14vh' hlg='17vh' />
                                                        </ImgDiv>
                                                        <Flex>
                                                            <NumberDivL>
                                                                {contadorSecundario >= 5 &&
                                                                <NumRedondo>10</NumRedondo>
                                                                }
                                                            </NumberDivL>
                                                            <NumberDivR>
                                                                {contadorSecundario >= 6 &&
                                                                <NumCuadrado>9</NumCuadrado>
                                                                }
                                                            </NumberDivR>
                                                        </Flex>
                                                    </div>
                                                    }
                                                </Flex>
                                            </FlexFull>
                                            {/* ---------------- NUEVO */}
                                            <FlexFull justify='end' align='center' width='32.3vw' margin='-9vh 0vw 0vh 0px' mm='-50px 0vw 0vh 0px' mt='-40px 0vw 0vh -5px' md='-35px 0vw 0vh -5px' mlg='0px 0vw 0vh 15px'>  { /* border='1px solid green' */}
                                                <Flex direction='column' width='10vw' minw='15vw' height='30vh' margin='-23px -7.8vw 0vh 0vw' mm='-33px -7vw 0vh 0px' mt='-37px -5.2vw 0vh 0px' md='-43px -5vw 0vh 0px' mlg='-33px -45px 0vh 0px'>
                                                    {contadorSecundario >= 8 &&
                                                    <div>
                                                        <ImgDiv>
                                                            <ImgB10 src={`/img/lineasDescomposicion.png`} w='55%' h='11vh' hm='10vh' hlg='15vh' />
                                                        </ImgDiv>
                                                        <Flex>
                                                            <NumberDivL>
                                                                {contadorSecundario >= 5 && contadorSecundario <= 12 ?
                                                                    <NumRedondo>5</NumRedondo> :
                                                                    contadorSecundario <= 4 ?
                                                                    null :
                                                                    <NumRedondoTachado>5</NumRedondoTachado>
                                                                }
                                                            </NumberDivL>
                                                            <NumberDivR>
                                                                {contadorSecundario >= 10 &&
                                                                <NumCuadrado>4</NumCuadrado>
                                                                }
                                                            </NumberDivR>
                                                        </Flex>
                                                    </div>
                                                    }
                                                </Flex>
                                            </FlexFull>
                                            {/* ---------------- NUEVO */}
                                            {/* FIN DESCOMPOSICIÓN */}
                                            </FlexFull>

                                            {/* CALCULO ABAJO */}
                                            <FlexFull justify='center' align='center' width='100%'> {/*  border='2px solid blue' */} 
                                                    <FlexFull justify='space-around' align='center'> {/*  border='2px solid green' */} 
                                                    <DivM83>
                                                    {contadorSecundario >= 13 &&
                                                        <div>
                                                            {contadorSecundario >= 14 &&
                                                            <NumRedondo>20</NumRedondo>
                                                            }
                                                        </div>
                                                    }
                                                    </DivM83>
                                                    <DivM83>
                                                    {contadorSecundario >= 17 &&
                                                        <NumCalculoM83 color='#646464'>-</NumCalculoM83>
                                                    }
                                                    </DivM83>
                                                    <DivM83>
                                                    {contadorSecundario >= 15 &&
                                                        <div>
                                                            {contadorSecundario >= 16 &&
                                                            <NumCuadrado>4</NumCuadrado>
                                                            }
                                                        </div>
                                                    }
                                                    </DivM83>
                                                    <DivM83>
                                                    {contadorSecundario >= 17 &&
                                                        <NumCalculoM83 color='#646464'>=</NumCalculoM83>
                                                    }
                                                    </DivM83>
                                                    <DivM83>
                                                    {contadorSecundario >= 17 && <NumCuadrado>16</NumCuadrado>}
                                                    </DivM83>
                                                    </FlexFull>
                                            </FlexFull>
                                            {/* FIN CALCULO ABAJO */}
                                        </FlexFull>

                                        {/* PASOS */}
                                        <FlexFull direction='column' justify='start' align='center' width='25vw' height='80vh'>
                                            <PasosT>
                                                PASOS
                                            </PasosT>
                                            <PasosSubT>
                                                RESTA POR DESCOMPOSICIÓN
                                            </PasosSubT>
                                            {contadorSecundario >= 1 &&
                                            <Pasos bgcolor='#D76970'>
                                                1- DESCOMPONGO EL PRIMER NÚMERO
                                            </Pasos>
                                            }
                                            {contadorSecundario >= 4 &&
                                            <Pasos bgcolor='#23AAAF'>
                                                2- DESCOMPONGOO EL SEGUNDO NÚMERO
                                            </Pasos>
                                            }
                                            {contadorSecundario >= 7 &&
                                            <Pasos bgcolor='#FF9B2A'>
                                                3- ¿PUEDO RESTAR LAS UNIDADES?
                                            </Pasos>
                                            }
                                            {contadorSecundario >= 8 &&
                                            <Pasos bgcolor='#FFDC73'>
                                                4- DESCOMPONGO EL NÚMERO UTILIZANDO DE GUÍA LAS UNIDADES DEL NÚMERO ANTERIOR
                                            </Pasos>
                                            }
                                            {contadorSecundario >= 11 &&
                                            <Pasos bgcolor='#655EE8'>
                                                5- RESTO LAS UNIDADES
                                            </Pasos>
                                            }
                                            {contadorSecundario >= 13 &&
                                            <Pasos bgcolor='#D76970'>
                                                6- RESTO LAS DECENAS
                                            </Pasos>
                                            }
                                            {contadorSecundario >= 15 &&
                                            <Pasos bgcolor='#23AAAF'>
                                                7- ESCRIBO LAS UNIDADES
                                            </Pasos>
                                            }
                                            {contadorSecundario >= 17 &&
                                            <Pasos bgcolor='#FF9B2A'>
                                                8- HAGO LA RESTA
                                            </Pasos>
                                            }
                                            {contadorSecundario >= 18 &&
                                            <Pasos bgcolor='#FFDC73'>
                                                9- ¿CUÁL ES EL RESULTADO?
                                            </Pasos>
                                            }
                                        </FlexFull>
                                        {/* FIN PASOS */}

                                    </FlexFull>


                                    {/* ----------------------------- PASOS */}
                                </div>
                                }

                            <FlexCuz  position='fixed' right='0' bottom='0'>
                                {contadorSecundario === 0 && <BotoneraBottom aumentarSecundario={true} />}
                                {contadorSecundario > 0 && contadorSecundario < 18 && <BotoneraBottom reducirSecundario={true} aumentarSecundario={true} />}
                                {contadorSecundario === 18 && <BotoneraBottom reducirSecundario={true} aumentar={true} />}
                            </FlexCuz>
                            </div>
                            }
                            {contador === 1 && <M84Bis mostrar={true} />}
                            {contador === 2 && <M84Practica mostrar={true} />}
                        </Fragment>
                        :
                        <Instrucciones instrucciones={instrucciones}  />
                    }
                </Fragment>
        </Fragment>
    );
}
 
export default M84;