import React, {useEffect, useState} from "react";

const useCloseElement = (ref) => {
    const [state, setState] = useState(false);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if(!ref.current) {
                return;
            }
            if (!ref.current.contains(event.target)) {
                setState(true);
                setTimeout(() => {
                    setState(false);
                }, 0);
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
        };
    }, [ref])
    return [state];
}

export default useCloseElement;