import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import AyudaEstructuraOperaciones from '../AyudaEstructuraOperaciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexCuz, FlexFull, AvanzarBtn, AvanzarImg, InputCalcM82B, InputCompartido, NumCalculo, DivFlexM82P, Menos, ResultLeftP, ResultRightP, OutP, OutResult, DownLeftP, DownRightP, UpLeftP, UpRightP, InputM82BP, Flecha } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la suma.';
const miniInstrucciones = 'Resuelve la suma.';

const M61Practica = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [digitoU1, setDigitoU1] = useState('');
    const [digitoD1, setDigitoD1] = useState('');
    const [digitoU2, setDigitoU2] = useState('');
    const [digitoD2, setDigitoD2] = useState('');
    const [digitoMeLlevo, setDigitoMeLlevo] = useState('');
    const [digitoFinalUno, setDigitoFinalUno] = useState('');
    const [digitoFinalDos, setDigitoFinalDos] = useState('');
    const [digitoFinalTres, setDigitoFinalTres] = useState('');
    const [numeroUnidadUno, setNumeroUnidadUno] = useState('');
    const [numeroUnidadDos, setNumeroUnidadDos] = useState('');
    const [numeroMeLlevo, setNumeroMeLlevo] = useState('');
    const [numeroDecenaUno, setNumeroDecenaUno] = useState('');
    const [numeroDecenaDos, setNumeroDecenaDos] = useState('');
    const [numeroFinalUno, setNumeroFinalUno] = useState('');
    const [numeroFinalDos, setNumeroFinalDos] = useState('');
    const [numeroFinalTres, setNumeroFinalTres] = useState('');
    const [numeroFinal, setNumeroFinal] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const ayuVisualSolicitada = useSelector(state => state.configuracion.ayuda_visual);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const verificar = (e) => {
        e.preventDefault();
        if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() === !numeroFinalDos.toString() || (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numeroFinal.toString() || digitoMeLlevo.toString() !== numeroMeLlevo.toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numeroUnidadUno.toString() !== digitoU1.toString() || numeroUnidadDos.toString() !== digitoU2.toString() || numeroDecenaUno.toString() !== digitoD1.toString() || numeroDecenaDos.toString() !== digitoD2.toString() || digitoFinalUno.toString() !== numeroFinalUno.toString() || digitoFinalDos.toString() === numeroFinalDos.toString() || digitoFinalTres.toString() === numeroFinalTres.toString() || (comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() === numeroFinal.toString() || digitoMeLlevo.toString() !== numeroMeLlevo.toString()) {
                    setNumeroUnidadUno('');
                    setNumeroDecenaUno('');
                    setNumeroUnidadDos('');
                    setNumeroDecenaDos('');
                    setNumeroMeLlevo('');
                    setNumeroFinalUno('');
                    setNumeroFinalDos('');
                    setNumeroFinalTres('');
                    setNumeroFinal('');
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumeroUnidadUno('');
                    setNumeroDecenaUno('');
                    setNumeroUnidadDos('');
                    setNumeroDecenaDos('');
                    setNumeroMeLlevo('');
                    setNumeroFinalUno('');
                    setNumeroFinalDos('');
                    setNumeroFinalTres('');
                    setNumeroFinal('');
                    setDigitoU1('');
                    setDigitoD1('');
                    setDigitoU2('');
                    setDigitoD2('');
                    setDigitoMeLlevo('');
                    setDigitoFinalUno('');
                    setDigitoFinalDos('');
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                }, 1500);
            }
        }
    }

    useEffect(() => {
        const min = 10;
        const max = 99;
        const randomNumeroUno = Math.floor(Math.random() * (max - min +1)) + min;
        const randomNumeroDos = Math.floor(Math.random() * (max - min +1)) + min;
        const modelo = [
            {id:'1', cantidad_1:randomNumeroUno, cantidad_2:randomNumeroDos}
        ]
        setComparar(modelo);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('suma_tradicional');
    }, []);

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        const u1 = newList.cantidad_1.toString().substring(1,2);
        const u2 = newList.cantidad_2.toString().substring(1,2);
        const d1 = newList.cantidad_1.toString().substring(0,1);
        const d2 = newList.cantidad_2.toString().substring(0,1);

        if(newList.cantidad_1.toString().length === 2 ){
            setDigitoU1(u1);
            setDigitoD1(d1);
        }
        if(newList.cantidad_1.toString().length === 1 ){
            setDigitoU1(u1);
        }
        if(newList.cantidad_2.toString().length === 2 ){
            setDigitoU2(u2);
            setDigitoD2(d2);
        }
        if(newList.cantidad_2.toString().length === 1 ){
            setDigitoU2(d2);
        }
        if(Number(u1) + Number(u2) > 9){
            setDigitoMeLlevo((Number(u1) + Number(u2)).toString().substring(0,1));
        } else {
            setDigitoMeLlevo(0);
        }
        
        if(Number(d1) + Number(d2) > 9){
            const final = newList.cantidad_1 + newList.cantidad_2;
            setDigitoFinalTres((final).toString().substring(0,1));
            setDigitoFinalDos((final).toString().substring(1,2));
            setDigitoFinalUno((final).toString().substring(2,3));
        } else {
            setDigitoFinalUno((newList.cantidad_1 + newList.cantidad_2).toString().substring(1,2));
            setDigitoFinalDos((newList.cantidad_1 + newList.cantidad_2).toString().substring(0,1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M61Practica') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/suma-tradicional/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(lanzoEffect === false){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lanzoEffect, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M61Practica",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'ayuda_visual': ayuVisualSolicitada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            {props.mostrar ? 
            null
            :
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            }
            {iniciar
                ?
                <Fragment>
                    <FlexFull justify='space-evenly' height='80vh'>
                    {comparar.length === 0 ? null :
                        <FlexFull justify='start' width='20vw'> {/*  border='2px solid green' */}
                            <NumCalculo color='#D76970'>{comparar[count-1].cantidad_1}</NumCalculo>
                            <NumCalculo color='#646464'>+</NumCalculo>
                            <NumCalculo color='#23AAAF'>{comparar[count-1].cantidad_2}</NumCalculo>
                            <NumCalculo color='#646464'>=</NumCalculo>
                            <form onSubmit={(e) => onSubmit(e)}> {/* 6 */}
                                <InputCalcM82B type="numeric" min="0" max="10000" value={numeroFinal} onChange={(e) => setNumeroFinal(e.target.value)} disabled={disable} />
                            </form>
                        </FlexFull>
                    }
                    <Flex margin='0px 10vw 0px 0px'>
                                <DivFlexM82P>
                                    <Flex>
                                        <Flecha>
                                            <InputM82BP type="numeric" min="0" max="10000" value={numeroMeLlevo} onChange={(e) => setNumeroMeLlevo(e.target.value)} disabled={disable} />
                                        </Flecha>
                                        <Flecha>

                                        </Flecha>
                                    </Flex>
                                    <Flex>
                                        <UpRightP>
                                            <InputM82BP type="numeric" min="0" max="10000" value={numeroDecenaUno} onChange={(e) => setNumeroDecenaUno(e.target.value)} disabled={disable}  />
                                        </UpRightP>
                                        <UpLeftP>
                                            <InputM82BP type="numeric" min="0" max="10000" value={numeroUnidadUno} onChange={(e) => setNumeroUnidadUno(e.target.value)} disabled={disable} />
                                        </UpLeftP>
                                    </Flex>
                                    <Flex>
                                        <OutP>
                                            <Menos>-</Menos>
                                        </OutP>
                                        <DownLeftP>
                                            <InputM82BP type="numeric" min="0" max="10000" value={numeroDecenaDos} onChange={(e) => setNumeroDecenaDos(e.target.value)} disabled={disable} />
                                        </DownLeftP>
                                        <DownRightP>
                                            <InputM82BP type="numeric" min="0" max="10000" value={numeroUnidadDos} onChange={(e) => setNumeroUnidadDos(e.target.value)} disabled={disable} />
                                        </DownRightP>
                                    </Flex>
                                    <Flex>
                                        <OutResult>
                                            <InputM82BP type="numeric" min="0" max="10000" value={numeroFinalTres} onChange={(e) => setNumeroFinalTres(e.target.value)} disabled={disable} />
                                        </OutResult>
                                        <ResultLeftP>
                                            <Flex>
                                                <InputM82BP type="numeric" min="0" max="10000" value={numeroFinalDos} onChange={(e) => setNumeroFinalDos(e.target.value)} disabled={disable} /> 
                                            </Flex>
                                        </ResultLeftP>
                                        <ResultRightP>
                                            <InputM82BP type="numeric" min="0" max="10000" value={numeroFinalUno} onChange={(e) => setNumeroFinalUno(e.target.value)} disabled={disable} />
                                        </ResultRightP>
                                    </Flex>
                                </DivFlexM82P>
                        </Flex>
                        <Flex>
                            <AyudaEstructuraOperaciones tipo={tipoEjercicio} />
                        </Flex>
                    </FlexFull>
                    <FlexCuz direction='row-reverse' position='fixed' right='0' bottom='0'>
                            <AvanzarBtn
                                disabled={disable}
                                onClick={(e) => verificar(e)}
                            >
                            <AvanzarImg src={`/img/play.png`} /> 
                            </AvanzarBtn>
                        </FlexCuz>
                </Fragment>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M61Practica;