import React, {Fragment} from 'react';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, BtnColores } from '../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../Css/StyledHeader';

const ConcienciaFonologica = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lenguaje/"} n1={"Métodos"} n2={"LENGUAJE"} componente={"CONCIENCIA FONOLÓGICA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Container align="space-around" justify="center" height="100%" width="100%" direction="column">
                <Container justify="space-around" >
                    <BtnColores bgcolor="#D05F67" to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-rima-lexica'}>CC Rima Léxica</BtnColores>
                    <BtnColores bgcolor="#23AAAF" to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-silabica'}>CC Silábica</BtnColores>
                    <BtnColores bgcolor="#FF9B2A" to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-lexica'}>CC Léxica</BtnColores>
                </Container>
                <Container justify="space-around" margin="20px 0px 0px 0px">
                    <BtnColores font="22px" bgcolor="#FFDC73" to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-intrasilabica'}>CC Intrasilábica</BtnColores>
                    <BtnColores bgcolor="#655EE8" to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica'}>CC Fonémica</BtnColores>
                </Container>
                {/* <Link to={'/escritorio/metodos/lenguaje'}>Atras</Link> */}
            </Container>
        </Fragment>
    );
}
 
export default ConcienciaFonologica;