import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CualEsMayor from '../../../Compartidos/CualEsMayor';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Indica que grupo tiene más puntos de colores';
const miniInstrucciones = 'Indica que grupo tiene más puntos de colores';

const M12 = () => {
    
    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('M12') );
        dispatch(setearUrlAnteriorAction('matematica/subitizacion/nivel-3/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])

    return (
        <Fragment>
            <CualEsMayor bg='#F1F0F0' m='1vh 0px 0px 0px' w='25vw' wm='22vw' wt='18vw' wd='15vw' wlg='11vw' data={data} indexes={indexes} ejercicio="M12" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} minMay={false} estrellas={true} carrito={true} />
        </Fragment>
    );
}
 
export default M12;