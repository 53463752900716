import {
    ESTADO_ERROR_INITIAL,
    ESTADO_LOADING_INITIAL,
    ESTADO_CORRECTO_INITIAL,
    ESTADO_PACIENTE_INITIAL,
    ESTADO_USUARIO_PACIENTE_INITIAL
} from '../types';

export function estadoErrorInitialAction() {
    return async (dispatch) => {
        dispatch (estadoErrorInitial());
    }
}
const estadoErrorInitial = () => ({
    type: ESTADO_ERROR_INITIAL,
    payload: null
});

export function estadoLoadingInitialAction() {
    return async (dispatch) => {
        dispatch (estadoLoadingInitial());
    }
}
const estadoLoadingInitial = () => ({
    type: ESTADO_LOADING_INITIAL,
    payload: false
});

export function estadoCorrectoInitialAction() {
    return async (dispatch) => {
        dispatch (estadoCorrectoInitial());
    }
}
const estadoCorrectoInitial = () => ({
    type: ESTADO_CORRECTO_INITIAL,
    payload: null
});

export function resetearConfigPacienteAction() {
    return async (dispatch) => {
        dispatch (resetearConfigPaciente());
    }
}
const resetearConfigPaciente = () => ({
    type: ESTADO_PACIENTE_INITIAL,
    payload: null
});

export function resetearConfigUsuarioPacienteAction() {
    return async (dispatch) => {
        dispatch(restearConfigUsuarioPaciente());
    }
}

const restearConfigUsuarioPaciente = () => ({
    type: ESTADO_USUARIO_PACIENTE_INITIAL
});