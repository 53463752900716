import styled from 'styled-components'
import {
    Link
} from "react-router-dom";
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`
// QUERYS
const media = {
    min: '@media (min-width:505px) and (max-width:699px)',
    tablet: '@media (min-width:700px) and (max-width:996px)',
    desktop: '@media(min-width:996px) and (max-width:1200px)',
    desktopLg: '@media (min-width:1200px)'
}

export const FlexHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    height: 3rem;
    margin: 0.6rem 0 0.6rem 0;
`

export const FlexHeaderDos = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 0.75rem 0 0.75rem;
`

export const Cuerpo = styled.div`
    font-family: 'Montserrat', sans-serif;
    background-color:#FFFFFF;
    margin: 0px;
    padding:0;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    height:100%;
    width:100%;
`
export const CuerpoCompleto = styled.div`
    font-family: 'Montserrat', sans-serif;
    background-color:#FFFFFF;
    height: ${props => props.height};
    width: ${props => props.width};
    margin: ${props => props.margin};
`
export const NombreProfesional = styled.div`
    text-decoration:none;
    color: black;
    margin-right: 10px;
`
export const Linea = styled.hr`
    border: 1px solid #FF9B2A;
    width: 98%;
`
export const Linea2 = styled.hr`
    border: 1px solid #23AAAF;
    width: 98%;
    display: ${props => props.display};
    align-self: flex-end;
`

export const Ubicacion = styled.div`
    text-transform: uppercase;
`

export const InfoPaciente = styled.div`
    cursor: pointer;
`

// MENÚ
export const Listados = styled.li`
    list-style:none;
    position: relative;
`

export const Item = styled(Link)`
    text-decoration:none;
    color: black;
    margin: ${props => props.margin};
    font-size: 14px;
`
export const DropdownPerfil = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    width: 20vh;
    margin-top: 7px;
`

export const MenuProfesional = styled.div`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 0.6rem 0.6rem 0.6rem 0.6rem;
    background: #FFFFFF;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
`
export const Contacto = styled(Link)`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    padding: 0.6rem 0.6rem 0.6rem 0.6rem;
    background: #FFFFFF;
    text-decoration:none;
    color: black;
    font-size: 1rem;
`
export const LinkPaciente = styled(Link)`
    display: flex;
    align-items: center;
    margin: 0px 10px;
    text-decoration: none;
    color: #646464;
`

export const HrMetodos = styled.hr`
    background-color: #646464;
    height: 1px;
    margin: ${props => props.margin};
    display: ${props => props.display};
`

// SIDEBAR
export const NavBar = styled.div`
    cursor:pointer;
`
export const NavMenu = styled.nav`
    background-color: #23AAAF;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 300ms;
    z-index:10;
    border-top-right-radius:15px;
    border-bottom-right-radius:15px;
`

export const MenuText = styled.li`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    font-weight: 600;
    &:hover{
        background-color: #0ba1a3;
    }
`
export const NavMenuItems = styled.ul` 
    width: 100%;
    padding:0;
`
export const BtnCerrar = styled.img` 
    background-color: #23AAAF;
    width:20px;
    margin: 25px 15px;
    cursor:pointer;
`
// IMÁGENES
export const LogoHeader = styled.img`
    height: ${props => props.height};
`
export const Icon = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    cursor: pointer;
`
// FLEXBOX
export const Flex = styled.div`
    display:flex;
    height: 100%;
    width: 100%;
    justify-content: ${props => props.$justify ? props.$justify : "space-between"};
    padding: ${props => props.padding};
    align-items: ${props => props.align};
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};
`
export const FlexBlanco = styled(Flex)`
    background-color:white;
`
export const FlexBox = styled.div`
    display:flex;
    justify-content: ${props => props.justify};
    align-items:${props => props.align};
    margin: ${props => props.margin};
`

export const FlexColumn = styled.div`
    display:flex;  
    flex-direction: column; 
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};
`

// CONTENEDOR
export const Div = styled.div`
    background-color: ${props => props.bgcolor};
    color: ${props => props.color};
    box-shadow: ${props => props.shadow};
    padding: ${props => props.padding};
    flex-grow: 1;
`

export const Contenedor = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${props => props.margin};
`

export const LineaVertical = styled.div`
    border-left: 1px solid black;
    height: 2rem;
    margin: 0 0.4rem 0 0.8rem;
`

export const ContenedorHeaderMetodos = styled.div`
    width: ${props => props.width};
    height: 70px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: ${props => props.fw}; 
    color: ${props => props.color};
    background-color: ${props => props.bgcolor};

    ${media.min}{
        color: ${props => props.colorm};  
        background-color: ${props => props.bgcolorm};
        width: ${props => props.wm}; 
        height: ${props => props.hm}; 
        margin: ${props => props.marginm};
        padding: ${props => props.paddingm}; 
        }
    ${media.tablet}{
        color: ${props => props.colort}; 
        background-color: ${props => props.bgcolort};
        width: ${props => props.wt}; 
        height: ${props => props.ht}; 
        margin: ${props => props.margint};
        padding: ${props => props.paddingt}; 
        }
    ${media.desktop}{
        color: ${props => props.colord}; 
        background-color: ${props => props.bgcolord};
        width: ${props => props.wd}; 
        height: ${props => props.hd}; 
        margin: ${props => props.margind};
        padding: ${props => props.paddingd}; 
        }
    ${media.desktopLg}{
        color: ${props => props.colorlg}; 
        background-color: ${props => props.bgcolorlg};
        width: ${props => props.wlg}; 
        height: ${props => props.hlg}; 
        margin: ${props => props.marginlg};
        padding: ${props => props.paddinglg}; 
        }
`

export const FlexHeaderEjercicio = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const FlexMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justify};
    margin: ${props => props.margin};
`

export const Enlace = styled (Link)`
   text-decoration:none;
   color: white;
   margin: 0px 10px;
   border-right: solid 1px black; 
   padding: 15px 20px 15px 0px;

   font-weight: bold;
 `
// TEXTOS
export const Error = styled.p`
    background: #F9B875;
    border-radius: 10px;
    padding: 5px;
`

// LISTADOS
export const Ul = styled.ul`
    padding: 0;
`

// MODAL
export const StyledModal = styled.div`
    display: flex;
    justify-content: center; 
    align-items: center; 
    padding-top: 180px;
`
export const ImgClose = styled.img`
    width: 20px;
    position: absolute; 
    top: 30px;
    right: 30px;
`
export const BtnClose = styled.button`
    background: transparent;
    border: 0;
`
// BOTONES
export const Min = styled.button`
    background: transparent; 
    font-family: nunito; 
    border: solid 1px #646464;
    border-radius: 10px;
    padding: 10px;
`