import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import AsignarEnABCM from '../../../Compartidos/AsignarEnABCM';

const data = [
    {id:'1', objeto:1, seteado: false, imagen: false, random: false},
    {id:'2', objeto:2, seteado: false, imagen: false, random: false},
    {id:'3', objeto:3, seteado: false, imagen: false, random: false},
    {id:'4', objeto:4, seteado: false, imagen: false, random: false},
    {id:'5', objeto:5, seteado: false, imagen: false, random: false},
    {id:'6', objeto:6, seteado: false, imagen: false, random: false},
    {id:'7', objeto:7, seteado: false, imagen: false, random: false},
    {id:'8', objeto:8, seteado: false, imagen: false, random: false},
    {id:'9', objeto:9, seteado: false, imagen: false, random: false},
    {id:'10', objeto:10, seteado: false, imagen: false, random: false}
]

const instrucciones = 'Selecciona la cantidad de puntos con el número correspondiente.';
const miniInstrucciones = 'Selecciona la cantidad de puntos con el número correspondiente.';

const M34 = () => {

    const dispatch = useDispatch();

    const [data2, setData2] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('M34') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/ejercitacion-1-10/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        const nuevaLista = [
            {id:'1', objeto:newList[0].imagen_url_1, imagen: true, correcto:newList[0].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'2', objeto:newList[1].imagen_url_1, imagen: true, correcto:newList[1].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'3', objeto:newList[2].imagen_url_1, imagen: true, correcto:newList[2].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'4', objeto:newList[3].imagen_url_1, imagen: true, correcto:newList[3].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'5', objeto:newList[4].imagen_url_1, imagen: true, correcto:newList[4].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'6', objeto:newList[5].imagen_url_1, imagen: true, correcto:newList[5].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'7', objeto:newList[6].imagen_url_1, imagen: true, correcto:newList[6].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'8', objeto:newList[7].imagen_url_1, imagen: true, correcto:newList[7].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'9', objeto:newList[8].imagen_url_1, imagen: true, correcto:newList[8].cantidad_1, seteado:false, asignado:'', random: true},
            {id:'10', objeto:newList[9].imagen_url_1, imagen: true, correcto:newList[9].cantidad_1, seteado:false, asignado:'', random: true},
        ]
        setData2(nuevaLista);
    }, [informacion])

    return (
        <Fragment>
            <AsignarEnABCM data={data} data2={data2} ejercicio={"M34"} instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default M34;