import {
    MOSTRAR_ALERTA,
    OCULTAR_ALERTA,
    MOSTRAR_SUCCESSFUL,
    OCULTAR_SUCCESSFUL,
    CERRAR_SESION
} from '../types';


const initialState = {
    alerta: null,
    successful: null
};

export default function alertaReducer(state = initialState, action) {
    switch(action.type) {
        case CERRAR_SESION:
            return {
                alerta: null,
                successful: null
            }
        case MOSTRAR_ALERTA:
            return {
                ...state,
                alerta: action.payload
            }
        case OCULTAR_ALERTA:
            return {
                ...state,
                alerta: null
            }
        case MOSTRAR_SUCCESSFUL:
            return {
                ...state,
                successful: action.payload
            }
        case OCULTAR_SUCCESSFUL:
            return {
                ...state,
                successful: null
            }      
        default:
            return state;
    }
}