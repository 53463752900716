import React, {Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AsignarEnABC from '../../../Compartidos/AsignarEnABC';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Coloca cada letra donde le corresponde.';
const miniInstrucciones = 'Coloca cada letra donde le corresponde.';

const LN45 = () => {

    const dispatch = useDispatch();

    const data = [
        {id:'1', palabra_1:'a', seteado: false, imagen: false, random: true},
        {id:'2', palabra_1:'b', seteado: false, imagen: false, random: true},
        {id:'3', palabra_1:'c', seteado: false, imagen: false, random: true},
        {id:'4', palabra_1:'d', seteado: false, imagen: false, random: true},
        {id:'5', palabra_1:'e', seteado: false, imagen: false, random: true},
        {id:'6', palabra_1:'f', seteado: false, imagen: false, random: true},
        {id:'7', palabra_1:'g', seteado: false, imagen: false, random: true},
        {id:'8', palabra_1:'h', seteado: false, imagen: false, random: true},
        {id:'9', palabra_1:'i', seteado: false, imagen: false, random: true},
        {id:'10', palabra_1:'j', seteado: false, imagen: false, random: true},
        {id:'11', palabra_1:'k', seteado: false, imagen: false, random: true},
        {id:'12', palabra_1:'l', seteado: false, imagen: false, random: true},
        {id:'13', palabra_1:'m', seteado: false, imagen: false, random: true},
        {id:'14', palabra_1:'n', seteado: false, imagen: false, random: true},
        {id:'15', palabra_1:'ñ', seteado: false, imagen: false, random: true},
        {id:'16', palabra_1:'o', seteado: false, imagen: false, random: true},
        {id:'17', palabra_1:'p', seteado: false, imagen: false, random: true},
        {id:'18', palabra_1:'q', seteado: false, imagen: false, random: true},
        {id:'19', palabra_1:'r', seteado: false, imagen: false, random: true},
        {id:'20', palabra_1:'s', seteado: false, imagen: false, random: true},
        {id:'21', palabra_1:'t', seteado: false, imagen: false, random: true},
        {id:'22', palabra_1:'u', seteado: false, imagen: false, random: true},
        {id:'23', palabra_1:'v', seteado: false, imagen: false, random: true},
        {id:'24', palabra_1:'w', seteado: false, imagen: false, random: true},
        {id:'25', palabra_1:'x', seteado: false, imagen: false, random: true},
        {id:'26', palabra_1:'y', seteado: false, imagen: false, random: true},
        {id:'27', palabra_1:'z', seteado: false, imagen: false, random: true}
    ];

    const data2 = [
        { id: '1', objeto: 'a', correcto: 'a', estilo:false, bgcolor:'#23AAAF', seteado: false},
        { id: '2', objeto: 'b', correcto: 'b', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '3', objeto: 'c', correcto: 'c', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '4', objeto: 'd', correcto: 'd', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '5', objeto: 'e', correcto: 'e', estilo:false, bgcolor:'#FF9B2A', seteado: false},
        { id: '6', objeto: 'f', correcto: 'f', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '7', objeto: 'g', correcto: 'g', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '8', objeto: 'h', correcto: 'h', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '9', objeto: 'i', correcto: 'i', estilo:false, bgcolor:'#FFDC73', seteado: false},
        { id: '10', objeto: 'j', correcto: 'j', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '11', objeto: 'k', correcto: 'k', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '12', objeto: 'l', correcto: 'l', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '13', objeto: 'm', correcto: 'm', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '14', objeto: 'n', correcto: 'n', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '15', objeto: 'ñ', correcto: 'ñ', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '16', objeto: 'o', correcto: 'o', estilo:false, bgcolor:'#655EE8', seteado: false},
        { id: '17', objeto: 'p', correcto: 'p', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '18', objeto: 'q', correcto: 'q', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '19', objeto: 'r', correcto: 'r', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '20', objeto: 's', correcto: 's', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '21', objeto: 't', correcto: 't', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '22', objeto: 'u', correcto: 'u', estilo:false, bgcolor:'#D05F67', seteado: false},
        { id: '23', objeto: 'v', correcto: 'v', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '24', objeto: 'w', correcto: 'w', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '25', objeto: 'x', correcto: 'x', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '26', objeto: 'y', correcto: 'y', estilo:false, bgcolor:'#646464', seteado: false},
        { id: '27', objeto: 'z', correcto: 'z', estilo:false, bgcolor:'#646464', seteado: false}
    ];

    useEffect(() => {
        dispatch(setearEjercicioAction('LN45') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/letras/consonantes/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AsignarEnABC data={data} data2={data2} ejercicio="LN45" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
    );
}
 
export default LN45;