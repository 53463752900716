import styled, {css, keyframes} from 'styled-components';
import {createGlobalStyle} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`

// IMAGENES
export const Ilustracion = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin};
    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `}
`
export const Icons = styled.img`
    width: 50px; 
    margin: ${props => props.margin};

    ${media.tablet}{
        width:40px;
}
`
// ANIMACIONES
export const ShakeImg = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`
export const ZoomIn = keyframes`
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
`
// CONTENEDORES
export const Flex = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    align-self: ${props => props.alignself};
    margin: ${props => props.margin};
    height: ${props => props.height};
    width: ${props => props.width};
    ${media.tablet}{
        margin: ${props => props.marginTablet};
}
`
export const ContInst = styled.div`
    position: absolute;
    bottom: 40px; 
    left: 25px; 
    text-transform: uppercase; 
    font-family: nunito;
    font-weight: 400;
    font-size: 16px; 
`
export const YellowBorder = styled.button`
    border: solid 5px #FCF7BC;
    background: transparent;
    border-radius: 10px;
    height: 200px;
    margin: ${props => props.margin};
    ${({disabled}) => disabled ? `
        &:focus{
            border: solid 5px #23AAAF;
        }` : `
        &:focus{
            border: solid 5px #FCF7BC
        }`
    }
    ${media.tablet}{
        height: ${props => props.heightTablet};
}
    
`

export const ContImagenes = styled.button`
    background: #64C2C8;
    border:0;
    width: 18vw;
    height: 50vh;
    border-radius: 15px;
    margin: 0 10px 0px 10px;
    align-items:flex-start;
    justify-content:flex-start;
    display:flex;
    flex-wrap: wrap;
    padding: 20px;
    font-size:20px; 
    font-family:nunito; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
    `}
    ${media.tablet}{
        margin-top: 70px;
        height: 55vh;
}
`
export const ContenedorIcons = styled.div`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #FAFAFA;
    border-radius: 10px;
    width:70vw;
    padding: 20px 40px;
    display:flex;
    justify-content: space-around; 
    align-items: center;
    flex-wrap: wrap;
`

export const Silabas = styled.div`
    border-right:${props => props.border};
    padding: 1.5rem 0 1.5rem 0;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 50em;
`
// BOTONES
export const YellowBtn = styled.button`
    width: ${props => props.width};
    height: ${props => props.height};
    margin: ${props => props.margin};
    background:#FFDC73;
    color: white; 
    font-family: nunito;
    font-size: 30px;
    border-radius: 10px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 0;
`

export const Btn = styled.button`
    display:flex;
    flex-direction: column;
    border: 0;
    background: transparent; 
`
export const ContBtnSilaba = styled.button`
    display:flex;
    background:transparent;
    border: ${props => props.border ? `solid 5px #23AAAF` : `solid 5px #FCF7BC`};
    width: 30vw;
    justify-content: space-around;
    align-items:center;
    font-size: 30px;
    font-family:nunito;
    border-radius:10px;
`