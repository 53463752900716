import {
    SOLICITAR_CONFIRMAR,
    SOLICITAR_CONFIRMAR_EXITO,
    SOLICITAR_CONFIRMAR_ERROR
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// solicita el token nuevamente
export function solicitaNuevamenteAction(informacion) {
    return async (dispatch) => {
        dispatch( solicitaNuevamente() );
        try {
            const {payload} = informacion;
            const respuesta = await clienteAxios.post('/usuarios/solicitarnuevamente', payload);
            dispatch( solicitaNuevamenteExito(respuesta.data) );

            Swal.fire({
                icon: 'success',
                title:'La solicitud ha sido un éxito',
                text: `${respuesta.data.msg}`,
            }).then(function() {
                window.location = "https://metodoyogen.com/login";
            });


        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitaNuevamenteError(error.response.data) );
            }
        }
    }
}

const solicitaNuevamente = () => ({
    type: SOLICITAR_CONFIRMAR,
    payload: true
});

// si solicita con éxito la confirmación
const solicitaNuevamenteExito = (msg) => ({
    type: SOLICITAR_CONFIRMAR_EXITO,
    payload: msg
});

// si hubo un error
const solicitaNuevamenteError = (msg) => ({
    type: SOLICITAR_CONFIRMAR_ERROR,
    payload: msg
});