import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'

//css
import {FlexBox} from '../Css/StyledHeader'
import {Fondo, Div, BotonPequeño, Li, Ul, Flex, Img} from '../Css/StyledActivities';
import { actualizarActividadesAction, actualizarInfoActividadesAction } from '../../actions/actividadesActions';
import { CircBoton } from '../Css/StyledPaciente';

const media = {
    tablet: '@media (max-width:768px)'
}

const ContenedorCarpeta = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin:${props => props.margin};
    display: flex;
    flex-direction: row;
    ${media.tablet}{
       width: ${props => props.ancho};
    height: ${props => props.alto};
   }
`

const SeleccionarActividadesDisponibles = () => {

    const dispatch = useDispatch();

    const infoActividades = useSelector((state) => state.informacion.info_actividades);
    const {uno, dos, tres, cuatro, cinco, actividades} = infoActividades;
    const seleccionadas = useSelector((state) => state.actividades.seleccionadas);

    const [unoId, setUnoId] = useState(1);
    const [dosId, setDosId] = useState('');
    const [tresId, setTresId] = useState('');
    const [cuatroId, setCuatroId] = useState('');
    const [cincoId, setCincoId] = useState('');

    const seleccionoEjercicio = (e, id, columna, tengoColor) => {
        e.preventDefault();
        const actualizoInfoActividades = JSON.parse(JSON.stringify(infoActividades));
        const actividadSeleccionada = JSON.parse(JSON.stringify(seleccionadas));
        let iActividades = actualizoInfoActividades.actividades.findIndex(item => {
            return item.id === id;
        })
        if(actualizoInfoActividades.actividades[iActividades].seleccionado){
            actualizoInfoActividades.actividades[iActividades].seleccionado = false;
        } else {
            actualizoInfoActividades.actividades[iActividades].seleccionado = true;
            actualizoInfoActividades.actividades[iActividades].contador = 1;
        }
        let estadoColor = tengoColor ? false : true;
        if(tengoColor){
            let index = actividadSeleccionada.indexOf(id);
            actividadSeleccionada.splice(index,1);
        } else {
            actividadSeleccionada.push(id);
        }
        
        if(columna === 'uno') {
            let i = actualizoInfoActividades.uno.findIndex(item => {
                return item.id === id;
            })
            actualizoInfoActividades.uno[i].color = estadoColor;
            
        } else if(columna === 'dos') {
            let i = actualizoInfoActividades.dos.findIndex(item => {
                return item.id === id;
            })
            actualizoInfoActividades.dos[i].color = estadoColor;
        }
        else if(columna === 'tres') {
            let i = actualizoInfoActividades.tres.findIndex(item => {
                return item.id === id;
            })
            actualizoInfoActividades.tres[i].color = estadoColor;
        }
        else if(columna === 'cuatro') {
            let i = actualizoInfoActividades.cuatro.findIndex(item => {
                return item.id === id;
            })
            actualizoInfoActividades.cuatro[i].color = estadoColor;
        }
        else if(columna === 'cinco') {
            let i = actualizoInfoActividades.cinco.findIndex(item => {
                return item.id === id;
            })
            actualizoInfoActividades.cinco[i].color = estadoColor;
        }
        dispatch( actualizarActividadesAction(actividadSeleccionada) );
        dispatch( actualizarInfoActividadesAction(actualizoInfoActividades) );
    }
    
    return (
        <Fragment>
            <Div margin="15px 0px 10px 7px">
                <FlexBox justify="space-around">
                    {!uno ? null :
                        uno.map(item => 
                        <div
                        key={item.id_uno}
                        >
                        {item.ultimo === 'uno'
                        ? 
                            <div>
                                {item.nombre_mostrar}
                                <CircBoton style={{backgroundcolor: item.color ? "#CF223D" : "#646464"}}
                                    onClick={(e) => seleccionoEjercicio(e,item.id,"uno",item.color)}
                                ></CircBoton>
                            </div>
                        : 
                            <BotonPequeño backgroundcolor={unoId === item.id_uno ? true : false}
                                onClick={() => {setUnoId(item.id_uno); setDosId(''); setTresId(''); setCuatroId('')}}
                            >
                                {item.uno}
                            </BotonPequeño>
                        }
                        </div>
                    )}
                </FlexBox>
            </Div>
            <Fondo width="100%" height="100%" justify="center" align="center">
            <ContenedorCarpeta>
                <Ul padding="0.4rem">
                {!dos ? null :
                dos.filter(item => item.id_uno === unoId && item.id_dos !== '').map(item => (
                    <Li padding="0.3rem"
                    key={item.id_dos}>
                        {item.ultimo === 'dos' 
                        ? 
                            <Flex justify="space.between" width="100%">
                                <Img src={`/img/portadas/${actividades.find(ele => item.id === ele.id).portada}.jpg`} width='4.5rem' height="3rem" />
                                {item.nombre_mostrar}
                                <CircBoton style={{backgroundColor: item.color ? "#CF223D" : "#646464"}}
                                    onClick={(e) => seleccionoEjercicio(e,item.id,"dos",item.color)}
                                ></CircBoton>
                            </Flex>
                        :
                            <BotonPequeño backgroundcolor={dosId === item.id_dos ? true : false}
                                onClick={() => {setDosId(item.id_dos); setTresId(''); setCuatroId(''); setCincoId('')}}
                            >
                                {item.dos}
                            </BotonPequeño>
                        }
                    </Li>
                ))}
                </Ul>
                <Ul padding="0.4rem">
                    {!tres ? null :
                    tres.filter(item => item.id_dos === dosId && item.id_tres !== '').map(item => (
                    <Li padding="0.3rem"
                    key={item.id_tres}>
                        {item.ultimo === 'tres' 
                        ? 
                            <Flex justify="space.between" width="100%">
                                <Img src={`/img/portadas/${actividades.find(ele => item.id === ele.id).portada}.jpg`} width='4.5rem' height="3rem" />
                                {item.nombre_mostrar}
                                <CircBoton style={{backgroundColor: item.color ? "#CF223D" : "#646464"}}
                                    onClick={(e) => seleccionoEjercicio(e,item.id,"tres",item.color)}
                                ></CircBoton>
                            </Flex>
                        :
                            <BotonPequeño backgroundcolor={tresId === item.id_tres ? true : false}
                                onClick={() => {setTresId(item.id_tres); setCuatroId(''); setCincoId('')}}
                            >
                                {item.tres}
                            </BotonPequeño>
                        }
                    </Li>
                    ))}
                </Ul>
                <Ul padding="0.4rem">
                    {!cuatro ? null :
                    cuatro.filter(item => item.id_tres === tresId && item.id_cuatro !== '').map(item => (
                    <Li padding="0.3rem"
                    key={item.id_cuatro}>
                        
                        {item.ultimo === 'cuatro' 
                        ? 
                            <Flex justify="space.between" width="100%">
                                <Img src={`/img/portadas/${actividades.find(ele => item.id === ele.id).portada}.jpg`} width='4.5rem' height="3rem" />
                                {item.nombre_mostrar}
                                <CircBoton style={{backgroundColor: item.color ? "#CF223D" : "#646464"}}
                                    onClick={(e) => seleccionoEjercicio(e,item.id,"cuatro",item.color)}
                                ></CircBoton>
                            </Flex>
                        :
                            <BotonPequeño backgroundColor={cuatroId === item.id_cuatro ? true : false}
                                onClick={() => {setCuatroId(item.id_cuatro); setCincoId('')}}
                            >
                                {item.cuatro}
                            </BotonPequeño>
                        }
                    </Li>
                ))}
                </Ul>
                <Ul padding="0.4rem">
                    {!cinco ? null :
                    cinco.filter(item => item.id_cuatro === cuatroId && item.id_cinco !== '').map(item => (
                    <Li padding="0.3rem"
                    key={item.id_cinco}>
                        {item.ultimo === 'cinco' 
                        ? 
                            <Flex justify="space.between" width="100%">
                                <Img src={`/img/portadas/${actividades.find(ele => item.id === ele.id).portada}.jpg`} width='4.5rem' height="3rem" />
                                {item.nombre_mostrar}
                                <CircBoton style={{backgroundColor: item.color ? "#CF223D" : "#646464"}}
                                    onClick={(e) => seleccionoEjercicio(e,item.id,"cinco",item.color)}
                                ></CircBoton>
                            </Flex>
                        :
                            <BotonPequeño backgroundcolor={cincoId === item.id_cinco ? true : false}
                                onClick={() => setCincoId(item.id_cinco)}
                            >
                                {item.cinco}
                            </BotonPequeño>
                        }
                    </Li>
                ))}
                </Ul>
            </ContenedorCarpeta>
            </Fondo>
        </Fragment>
    );
}
 
export default SeleccionarActividadesDisponibles;