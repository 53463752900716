import {
    SOLICITO_SUSCRIPCION_PROFESIONAL,
    SOLICITO_SUSCRIPCION_PROFESIONAL_EXITO,
    SOLICITO_SUSCRIPCION_PROFESIONAL_ERROR,
    SOLICITO_CANTIDAD_SUSCRIPCION,
    SOLICITO_CANTIDAD_SUSCRIPCION_EXITO,
    SOLICITO_CANTIDAD_SUSCRIPCION_ERROR
} from '../types';
import clienteAxios from '../config/axios';

/* Solicito datos de la DB */
export function solicitoSuscripcionProfesionalAction(informacion) {
    return async (dispatch) => {
        dispatch (solicitoSuscripcionProfesional());
        try {
            const {config} = informacion;
            const respuesta = await clienteAxios.get('/usuarios/solicitosuscripcionprofesional', config);
            dispatch( solicitoSuscripcionProfesionalExito(respuesta.data.informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitoSuscripcionProfesionalError(error.response.data) );
            }
        }
    }
}

const solicitoSuscripcionProfesional = () => ({
    type: SOLICITO_SUSCRIPCION_PROFESIONAL,
    payload: true
});

// si logro obtener la informacion de la BD
const solicitoSuscripcionProfesionalExito = (data) => ({
    type: SOLICITO_SUSCRIPCION_PROFESIONAL_EXITO,
    payload: data
});

// si hubo un error
const solicitoSuscripcionProfesionalError = (msg) => ({
    type: SOLICITO_SUSCRIPCION_PROFESIONAL_ERROR,
    payload: msg
});

export function solicitoCantidadSuscripcionAction(informacion) {
    return async (dispatch) => {
        dispatch (solicitoCantidadSuscripcion());
        try {
            const {config} = informacion;
            const respuesta = await clienteAxios.get('/usuarios/solicitocantidadsuscripcion', config);
            dispatch( solicitoCantidadSuscripcionExito(respuesta.data.informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitoCantidadSuscripcionError(error.response.data) );
            }
        }
    }
}

const solicitoCantidadSuscripcion = () => ({
    type: SOLICITO_CANTIDAD_SUSCRIPCION,
    payload: true
});

// si logro obtener la informacion de la BD
const solicitoCantidadSuscripcionExito = (data) => ({
    type: SOLICITO_CANTIDAD_SUSCRIPCION_EXITO,
    payload: data
});

// si hubo un error
const solicitoCantidadSuscripcionError = (msg) => ({
    type: SOLICITO_CANTIDAD_SUSCRIPCION_ERROR,
    payload: msg
});