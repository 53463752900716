import React, { useState } from 'react';

import CargaSuscriptor from './CargaSuscriptor';
import Suscriptores from './Suscriptores';
import PagoRechazado from './PagoRechazado';

import {FlexBox, FlexColumn} from '../Css/StyledHeader';
import {BotonSeleccionado} from '../Css/StyledActivities';

const Suscriptor = () => {

    const [objetivosEtapa, setObjetivosEtapa] = useState('cargarSuscriptor');

    return (
        <FlexColumn align="center">
            <FlexBox justify="center">
                <BotonSeleccionado name="cargarSuscriptor" onClick={e => setObjetivosEtapa(e.target.name)} backgroundcolor={objetivosEtapa === "cargarSuscriptor" ? true : false} width="35%" padding="15px 0px" margin="20px 0px 0px 10px">
                    Activar Suscriptor
                </BotonSeleccionado>
                <BotonSeleccionado name="verSuscriptores" onClick={e => setObjetivosEtapa(e.target.name)} backgroundcolor={objetivosEtapa === "verSuscriptores" ? true : false} width="30%" padding="15px 0px" margin="20px 0px 0px 10px">
                    Suscriptores
                </BotonSeleccionado>
                <BotonSeleccionado name="pagoRechazado" onClick={e => setObjetivosEtapa(e.target.name)} backgroundcolor={objetivosEtapa === "pagoRechazado" ? true : false} width="35%" padding="15px 0px" margin="20px 0px 0px 10px">
                    Pagos Rechazados
                </BotonSeleccionado>
            </FlexBox>
            <div>
                {objetivosEtapa === "cargarSuscriptor" ? <CargaSuscriptor /> : null }
                {objetivosEtapa === "verSuscriptores" ?  <Suscriptores />  : null }
                {objetivosEtapa === "pagoRechazado" ?  <PagoRechazado />  : null }
            </div>
        </FlexColumn>
    );
}
 
export default Suscriptor;