import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';
//CAMI

import { Flex, LinksSubNivs, ImgSubNivs, DesSubNivs } from '../../../Css/StyledMatematica';
import { HrMetodos } from '../../../Css/StyledHeader';


const Nivel2 = () => {
    
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} l3={"subitizacion/"} n1={"Métodos"} n2={"MATEMATICA"} n3={"SUBITIZACION"} componente={"NIVEL 2"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Flex wrap='wrap' margin='12vh 0vw 0px 0vw' mt='8.5vh 0vw 0px 0vw' md='7vh 0vw 0px 0vw' mlg='6.5vh 0vw 0px 0vw'>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-2/M6`}><ImgSubNivs src={'/img/portadas/matematica6.jpg'} /><DesSubNivs margin="1rem 0 0 0">puntos negros</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-2/M7`}><ImgSubNivs src={'/img/portadas/matematica7.jpg'} /><DesSubNivs margin="1rem 0 0 0">puntos color</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-2/M8`}><ImgSubNivs src={'/img/portadas/matematica8.jpg'} /><DesSubNivs margin="1rem 0 0 0">¿Qué grupo tiene más?</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-2/M9`}><ImgSubNivs src={'/img/portadas/matematica9.jpg'} /><DesSubNivs margin="1rem 0 0 0">noción de cantidad</DesSubNivs></LinksSubNivs>
                <LinksSubNivs to={`/escritorio/metodos/matematica/subitizacion/nivel-2/M10`}><ImgSubNivs src={'/img/portadas/matematica10.jpg'} /><DesSubNivs margin="1rem 0 0 0">puntos y números</DesSubNivs></LinksSubNivs>
            </Flex>
        </Fragment>    
    );
}
 
export default Nivel2;