import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useColorHelp from '../../../../../Hooks/useColorHelp';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';
import { aumentarContadorConCorreccionAction } from '../../../../../actions/botoneraEjercicioActions';

//css
import { Btn, ImgBottom } from '../../../../Css/MetodosCSS/StyledSilabas';
import { ContainerFlex } from '../../../../Css/MetodosCSS/StyledLN2Todos';
import { BtnSilaba } from '../../../../Css/MetodosCSS/StyledLN3Todos';

const instrucciones = 'Lee las siguientes silábas lo más rápido que puedas.';
const miniInstrucciones = 'Lee las siguientes silábas lo más rápido que puedas.';

const LN12 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [comparar, setComparar] = useState([]);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [indexes, setIndexes] = useState();
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [arrayColor, transformarColor] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const lista = useSelector(state => state.ejercicio.lista);
    const contadorConCorreccion = useSelector(state => state.ejercicio.contadorConCorreccion);
    const error = useSelector(state => state.ejercicio.error);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
        }
    }

    useEffect(() => {
        if(comparar[contadorConCorreccion] === undefined || contadorConCorreccion === indexes){
            return;
        }
        transformarColor(comparar[contadorConCorreccion].silaba_1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, contadorConCorreccion, ayuda_lectura])

    useEffect(() => {
        dispatch(setearEjercicioAction('LN12') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/silabas/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        transformarColor(newList[contadorConCorreccion].silaba_1);
        setComparar(newList);
        setIndexes(Object.keys(informacion).length)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informacion])

    useEffect(() => {
        if(iniciar === false && contadorConCorreccion === indexes ){
            return;
        }else if(iniciar === false && contadorConCorreccion === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorConCorreccion]);

    useEffect(() => {
        if(finalizar === true && contadorConCorreccion === indexes -1 ){
            dispatch(aumentarContadorConCorreccionAction());
        }
        if(finalizar && contadorConCorreccion === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': 'LN12',
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda_visual': ayuVisualSolicitada,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorConCorreccion]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <ContainerFlex display="flex" direction="column" height="100%" width="100%">
                    <ContainerFlex display="flex" direction="column" margin="auto" width="100%">
                        <ContainerFlex alignself="end" margin="0 2rem 2rem 0">
                            <Btn bg="transparent" border="none"
                                onClick={(e) => setearAyuVisual(e)}
                            >
                                {ayuVisual ? 
                                <ImgBottom width="45px" src={`/img/ayuda_clickeado.png`} />
                                :
                                <ImgBottom width="45px" src={`/img/icono_ayuda.png`} />
                                }
                            </Btn>
                        </ContainerFlex>
                        <ContainerFlex display="flex" direction="column" justify="center" align="center">
                            <BtnSilaba padding="2rem 6rem" paddingMob="1.5rem 4rem" font="6rem" $minMay={minuscula}>
                                {arrayColor === '' ? null :
                                    arrayColor.map((item, i) =>
                                        <span
                                        key={i}
                                        >
                                        {item.map((itemInterior, i) =>
                                            <span style={{color: itemInterior.color}}
                                            key={i}
                                            >
                                                {itemInterior.letra}
                                            </span>
                                        )}
                                        </span>
                                    )
                                }
                            </BtnSilaba>
                            {ayuVisual && comparar[contadorConCorreccion].imagen_url_1 !== '' ? <ImgBottom height="4rem" src={`/img/${comparar[contadorConCorreccion].imagen_url_1}.png`} /> : null}
                            {ayuVisual && comparar[contadorConCorreccion].imagen_url_2 !== '' ? <ImgBottom height="4rem" src={`/img/${comparar[contadorConCorreccion].imagen_url_2}.png`} /> : null} 
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex display="flex" justify="space-between" justifyself="end">
                        <ContainerFlex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </ContainerFlex>
                        <ContainerFlex>
                            <BotoneraBottom aumentarConCorreccion={true} error={true} pausar={true} cantidad={indexes} />
                        </ContainerFlex>
                    </ContainerFlex>

                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN12;