import {
    CREAR_USUARIO,
    CREAR_USUARIO_EXITO,
    CREAR_USUARIO_ERROR
} from '../types';
import clienteAxios from '../config/axios';

import Swal from 'sweetalert2';

// Crear nuevos usuarios
export function crearNuevoUsuarioAction(usuario) {
    return async (dispatch) => {
        dispatch( crearUsuario() ); 
        try {
            const respuesta = await clienteAxios.post('/usuarios', usuario);
            dispatch( crearUsuarioExito(respuesta.data) );

            //alerta
            Swal.fire({
                icon: 'success',
                title:'Usuario creado con éxito',
                text: 'Se ha enviado un email de confirmación a su casilla de correo',
            }).then(function() {
                window.location = "https://metodoyogen.com/login";
            });

        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( crearUsuarioError(error.response.data) );
            }
        }
    }
}

const crearUsuario = () => ({
    type: CREAR_USUARIO,
    payload: true
});

// si el usuario se guarda en la base de datos
const crearUsuarioExito = (token) => ({
    type: CREAR_USUARIO_EXITO,
    payload: token
});

// si hubo un error
const crearUsuarioError = (msg) => ({
    type: CREAR_USUARIO_ERROR,
    payload: msg
});
