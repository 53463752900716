import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import BotoneraBottom from './BotoneraBottom';
import useColorHelp from '../../../Hooks/useColorHelp';
import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { almacenarAction } from '../../../actions/almacenarEjercicioActions';
import { aumentarContadorConCorreccionAction } from '../../../actions/botoneraEjercicioActions';

// css
import { Icons, ContenedorXL } from '../../Css/MetodosCSS/StyledLetras'
import { ContainerFlex } from '../../Css/MetodosCSS/StyledLN2Todos';
import { Img } from '../../Css/StyledForm';
import { BtnColor } from '../../Css/MetodosCSS/StyledEjercicios';
import { BtnSilaba } from '../../Css/MetodosCSS/StyledLN3Todos';

const TipoDenoRapida = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, ronda, ayu_visual, colorFinal, minMay, instrucciones, miniInstrucciones } = props;

    const [randomPalabra, setRandomPalabra] = useState([]);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [arrayColor, transformarColor] = useColorHelp('');
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista = useSelector(state => state.ejercicio.lista);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const contadorConCorreccion = useSelector(state => state.ejercicio.contadorConCorreccion);
    const error = useSelector(state => state.ejercicio.error);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
        }
    }

    useEffect(() => {
        if(contadorConCorreccion === 0){
            return;
        }
        if(contadorConCorreccion === 1){
            setErroresOrden([...erroresOrden, error]);
            return;
        }
        const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
        setErroresOrden([...erroresOrden, err]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorConCorreccion])
    
    useEffect(() => {
        if(data === undefined) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(data));

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList);
        if(randomPalabra[contadorConCorreccion] !== undefined && finalizar !== true){
            transformarColor(shuffleList[contadorConCorreccion].palabra_1);
        }
        setRandomPalabra(shuffleList);
        const newList2 = JSON.parse(JSON.stringify(shuffleList));
        const elOrdenDelId = newList2.map(item => item.id)
        setIdOrden(elOrdenDelId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar])

    useEffect(() => {
        if(randomPalabra[contadorConCorreccion] === undefined || contadorConCorreccion === indexes || randomPalabra[contadorConCorreccion].palabra_1 === undefined ){
            return;
        }
        transformarColor(randomPalabra[contadorConCorreccion].palabra_1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [randomPalabra, contadorConCorreccion, ayuda_lectura])

    useEffect(() => {
        if(iniciar === false && contadorConCorreccion === indexes ){
            return;
        }else if(iniciar === false && contadorConCorreccion === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorConCorreccion]);

    useEffect(() => {
        if(finalizar === true && contadorConCorreccion === indexes -1 ){
            dispatch(aumentarContadorConCorreccionAction());
        }
        if(finalizar && contadorConCorreccion === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
            const erroresOrdenFinal = [...erroresOrden, err];

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda_visual': ayuVisualSolicitada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrdenFinal,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorConCorreccion]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuVisual={ayu_visual} ayuLectura={true} />
            {iniciar
                ?
                <ContainerFlex display="flex" direction="column" height="100%" width="100%">
                    {ayuVisual ?
                    <ContainerFlex display="flex" justify="end" height="5vh">
                        <BtnColor bg="transparent" border="none"
                            onClick={(e) => setearAyuVisual(e)}
                        >
                            <Img width="45px" src={`/img/icono_ayuda.png`} />
                        </BtnColor>
                    </ContainerFlex>
                    : null
                    }

                    <ContainerFlex display="flex" align="center" justify="center" margin="auto">
                        {randomPalabra.length === 0 ? null :
                            randomPalabra[contadorConCorreccion]?.palabra_1 === '' || randomPalabra[contadorConCorreccion]?.palabra_1 === undefined
                            ?
                            <ContainerFlex >
                                <Img align="center" justify="center" src={`/img/${randomPalabra[contadorConCorreccion]?.imagen_url_1}.png`} width="15vw" widthmob="20vw" />
                            </ContainerFlex>
                            :
                            <ContenedorXL>
                                
                                <BtnSilaba padding="2rem 8rem" paddingMob="1.5rem 4rem" margin="6rem 0 0 0" font="6rem" $minMay={minuscula}>
                                {ayuVisual && randomPalabra[contadorConCorreccion]?.imagen_url_1 !== '' ? <Img src={`/img/${randomPalabra[contadorConCorreccion]?.imagen_url_1}.png`} width="130px" height="130px" widthmob="100px" heightmob="100px" /> : null}
                                    {arrayColor === '' ? null :
                                            arrayColor.map((item, i) =>
                                                <span
                                                key={i}
                                                >
                                                {item.map((itemInterior, i) =>
                                                    <span style={colorFinal ? {color: randomPalabra[contadorConCorreccion]?.color} : {color: itemInterior.color}}
                                                    key={i}
                                                    >
                                                        {itemInterior.letra}
                                                    </span>
                                                )}
                                                </span>
                                            )
                                        }
                                    {ayuVisual && randomPalabra[contadorConCorreccion]?.imagen_url_2 !== '' ? <Img src={`/img/${randomPalabra[contadorConCorreccion]?.imagen_url_2}.png`} width="130px" height="130px" widthmob="100px" heightmob="100px" /> : null}
                                </BtnSilaba>
                            </ContenedorXL>
                    }
                    </ContainerFlex>
                    <ContainerFlex display="flex" justify="space-between" justifyself="end">
                        <ContainerFlex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </ContainerFlex>
                        <ContainerFlex>
                            <BotoneraBottom aumentarConCorreccion={true} error={true} pausar={true} cantidad={indexes} ronda={ronda} />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default TipoDenoRapida;