import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, ImgB10, BtnR, NumSumaM56, InputM56, BtnImgM56, LateralM56, AvanzarImg, AvanzarBtn } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Coloca el número más grande en la cabeza del niño y el número más chico en la mano para poder resolver la suma.';
const miniInstrucciones = 'Coloca el número más grande en la cabeza y el número más chico en la mano para poder resolver la suma.';

const M56 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [asignar, setAsignar] = useState([]);
    const [numero, setNumero] = useState('');
    const [asignado, setAsignado] = useState([{imagen:'', tipo:''}]);
    const [asignadoUno, setAsignadoUno] = useState('');
    const [asignadoDos, setAsignadoDos] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const setear = (e, img, tipo) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(asignado));
        if(img === asignado[0].imagen){
            newList[0].imagen = '';
            newList[0].tipo = '';
            setAsignado(newList);
            return;
        }
        newList[0].imagen = img;
        newList[0].tipo = tipo;
        setAsignado(newList);
    }

    const asignameUno = (e) => {
        e.preventDefault();
        if(asignado[0].imagen === undefined || asignado[0].imagen === '') {
            return;
        }
        if(asignado[0].tipo === 'mayor' && comparar[count-1].cantidad_1 > comparar[count-1].cantidad_2) {
            setAsignadoUno(asignado[0].imagen);
        } else if(asignado[0].tipo === 'menor' && comparar[count-1].cantidad_1 < comparar[count-1].cantidad_2) {
            setAsignadoUno(asignado[0].imagen);
        } else {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList[0].imagen = '';
            newList[0].tipo = '';
            setAsignado(newList);
        }
    }
    const asignameDos = (e) => {
        e.preventDefault();
        if(asignado[0].imagen === undefined || asignado[0].imagen === '') {
            return;
        }
        if(asignado[0].tipo === 'mayor' && comparar[count-1].cantidad_1 < comparar[count-1].cantidad_2) {
            setAsignadoDos(asignado[0].imagen);
        } else if(asignado[0].tipo === 'menor' && comparar[count-1].cantidad_1 > comparar[count-1].cantidad_2) {
            setAsignadoDos(asignado[0].imagen);
        } else {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList[0].imagen = '';
            newList[0].tipo = '';
            setAsignado(newList);
        }
    }

    const verificar = (e) => {
        e.preventDefault();
        if((comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numero.toString() ) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if((comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numero.toString()){
                    setNumero('');
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setAsignadoDos('');
                    setAsignadoUno('');
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setAsignadoDos('');
                    setAsignadoUno('');                 
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const lista = [1,2,3,4,5]
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(lista);
        const modelo = [
            {id:'1', cantidad_1:shuffleList[0], cantidad_2:shuffleList[2]},
            {id:'2', cantidad_1:shuffleList[1], cantidad_2:shuffleList[4]},
            {id:'3', cantidad_1:shuffleList[2], cantidad_2:shuffleList[0]},
            {id:'4', cantidad_1:shuffleList[3], cantidad_2:shuffleList[1]},
            {id:'5', cantidad_1:shuffleList[4], cantidad_2:shuffleList[3]}
        ]
        const data1 = [
            {id:'1', imagen:'nenecabeza1', tipo:'mayor'},
            {id:'2', imagen:'nenacabeza1', tipo:'mayor'},
            {id:'3', imagen:'dedos_5', tipo:'menor'}
        ]
        setComparar(modelo);
        const newList2 = JSON.parse(JSON.stringify(data1));
        setAsignar(newList2);
        setIndexes(modelo.length);
        setTipoEjercicio('unidad');
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M56') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M56",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <LateralM56>
                            <FlexFull direction='column'>
                                {asignar.map(item =>
                                <BtnImgM56
                                    key={item.id}
                                    disabled={disable}
                                    onClick={(e) => setear(e, item.imagen, item.tipo)}
                                >
                                    <ImgB10 src={`/img/${item.imagen}.png`} w='75px' style={{zIndex: 0}} />
                                </BtnImgM56>    
                                )}
                            </FlexFull>
                        </LateralM56>
                        <FlexFull direction='column' height='480px' justify='space-evenly' align='start'>
                        {/* <FlexFull width='300px' wm='320px' wt='440px' wd='490px' wlg='520px' height='180px' justify='space-evenly' align='center' border='2px solid red'> */}
                            <FlexFull width='340px' wm='360px' wt='570px' wd='830px' wlg='900px' height='180px' justify='space-evenly' align='center'>
                                <NumSumaM56 color='#FF9B2A'>{comparar[count-1].cantidad_1}</NumSumaM56> 
                                <NumSumaM56 color='#646464'>+</NumSumaM56>
                                <NumSumaM56 color='#FF9B2A'>{comparar[count-1].cantidad_2}</NumSumaM56> 
                                <NumSumaM56 color='#646464'>=</NumSumaM56>
                                {/* <InputM56 type="text" value={numero} onChange={(e) => setNumero(e.target.value)} color='#21A2A7' disabled={disable} /> */}
                                {asignadoUno === '' || asignadoDos === '' ? null :
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <InputM56 type="numeric" min="1" max="20" value={numero} onChange={(e) => setNumero(e.target.value)} color='#21A2A7' disabled={disable} />
                                </form>
                                }
                            </FlexFull>
                            <FlexFull width='150px' wm='165px' wt='240px' wd='330px' wlg='350px' margin='-40px 0px 0px 10px' mm='-40px 0px 0px 10px' mt='-40px 0px 0px 40px' md='-40px 0px 0px 80px' mlg='-40px 0px 0px 95px' justify='space-between' align='center'>
                                <BtnR style={{display: "flex", justifyContent: "center", alignItems: "center"}} 
                                    disabled={disable}
                                    onClick={(e) => asignameUno(e)}
                                >
                                    {asignadoUno === '' ? null : <ImgB10 src={`/img/${asignadoUno}.png`} w='5vw' style={{zIndex: 0}} />} 
                                </BtnR>
                                <BtnR style={{display: "flex", justifyContent: "center", alignItems: "center"}} 
                                    disabled={disable}
                                    onClick={(e) => asignameDos(e)}
                                >
                                    {asignadoDos === '' ? null : <ImgB10 src={`/img/${asignadoDos}.png`} w='5vw' style={{zIndex: 0}} />} 
                                </BtnR>
                            </FlexFull>
                        </FlexFull>
                    </Flex>
                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M56;