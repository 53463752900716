import React, {Fragment} from 'react';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';
//CAMI

import { Flex, LinksVarios } from '../../Css/StyledMatematica';
import { HrMetodos } from '../../Css/StyledHeader';



const EstimacionYNocionDeCantidad = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} n1={"Métodos"} n2={"MATEMATICA"} componente={"ESTIMACIONYNOCIONDECANTIDAD"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Flex>
                <LinksVarios bgcolor='#23AAAF' color='#FFFFFF' fw='600'
                to={'/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/estimacion-de-cantidad'}>
                    Estimación de cantidad
                </LinksVarios>
                <LinksVarios bgcolor='#23AAAF' color='#FFFFFF' fw='600' to={'/escritorio/metodos/matematica/estimacion-y-nocion-de-cantidad/nocion-de-cantidad'}>
                    Noción de cantidad
                </LinksVarios>
            </Flex>
        </Fragment>
    );
}
 
export default EstimacionYNocionDeCantidad;