import { useSelector } from "react-redux";

const useConfigAuth = () => {
    const token = useSelector ( (state) => state.autenticado.token);
    const config = {
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
        }
    }
    return [config];
};

export default useConfigAuth;