import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
// css
import {Ilustracion, ContBtnSilaba, Silabas, Flex, ContInst} from '../../../../Css/MetodosCSS/StyledCCSilabica';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Selecciona los dos dibujos que terminan con la misma sílaba.';
const miniInstrucciones = 'Selecciona los dos dibujos que terminan con la misma sílaba.';

const LEN24 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [randomImagen, setRandomImagen] = useState([]);
    const [asignadoA, setAsignadoA] = useState('');
    const [asignadoB, setAsignadoB] = useState('');
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [indexes, setIndexes] = useState([]);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignar = (e,id, B, C, D) => {
        e.preventDefault();
        if(D !== undefined && D !== '') {
            if(id === asignadoA.id) {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = false;
                setRandomImagen(newList);
                setAsignadoA('');
                return;
            }
            if(id === asignadoB.id) {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = false;
                setRandomImagen(newList);
                setAsignadoB('');
                return;
            }
            if(asignadoA === '') {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = true;
                setRandomImagen(newList);
                setAsignadoA({id:id, silaba:D});
                return;
            }
            if(asignadoB === '') {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = true;
                setRandomImagen(newList);
                setAsignadoB({id:id, silaba:D});
                return;
            }
        } else if(C !== undefined && C !== '') {
            if(id === asignadoA.id) {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = false;
                setRandomImagen(newList);
                setAsignadoA('');
                return;
            }
            if(id === asignadoB.id) {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = false;
                setRandomImagen(newList);
                setAsignadoB('');
                return;
            }
            if(asignadoA === '') {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = true;
                setRandomImagen(newList);
                setAsignadoA({id:id, silaba:C});
                return;
            }
            if(asignadoB === '') {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = true;
                setRandomImagen(newList);
                setAsignadoB({id:id, silaba:C});
                return;
            }
        } else {
            if(id === asignadoA.id) {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = false;
                setRandomImagen(newList);
                setAsignadoA('');
                return;
            }
            if(id === asignadoB.id) {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = false;
                setRandomImagen(newList);
                setAsignadoB('');
                return;
            }
            if(asignadoA === '') {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = true;
                setRandomImagen(newList);
                setAsignadoA({id:id, silaba:B});
                return;
            }
            if(asignadoB === '') {
                const newList = JSON.parse(JSON.stringify(randomImagen));
                const index = newList.findIndex(item => item.id === id);
                newList[index].border = true;
                setRandomImagen(newList);
                setAsignadoB({id:id, silaba:B});
                return;
            }
        }
    }

    const verificar = (e) => {
        e.preventDefault();
        if(asignadoA === '' || asignadoB === '') {
            return
        }
        if(asignadoA.silaba !== asignadoB.silaba) {
            console.log("mal");
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setDisable(true);
            const newList = JSON.parse(JSON.stringify(randomImagen));
            const indexA = newList.findIndex(item => item.id === asignadoA.id);
            const indexB = newList.findIndex(item => item.id === asignadoB.id);
            newList[indexA].border = false;
            newList[indexB].border = false;
            setTimeout(() => {
                setDisable(false);
                setRandomImagen(newList);
                setAsignadoA('');
                setAsignadoB('');
            }, 1500);
            return;
        } else {
            console.log("bien")
            setIsCorrect(true);
            setTimeout(() => {
                 setIsCorrect(false);
            }, 300);
            GoodWork();
            setDisable(true);
            setAsignadoA('');
            setAsignadoB('');
            if(count < indexes){
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 1500);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    setDisable(false);
                    dispatch( finalizarEjercicioAction() )
                }, 1500);
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN24') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-silabica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length)
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': "LEN24",
                'errores': contarErrores,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                'lista_usada': lista_usada,
                sesion
                }
            }

            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        const newComparar = [
            {id:'1', imagen_url:newList.imagen_url_1, silaba_1:newList.palabra_1_silaba_1, silaba_2:newList.palabra_1_silaba_2, silaba_3:newList.palabra_1_silaba_3, silaba_4:newList.palabra_1_silaba_4, ayuda_imagen: newList.ayuda_imagen_1},
            {id:'2', imagen_url:newList.imagen_url_2, silaba_1:newList.palabra_2_silaba_1, silaba_2:newList.palabra_2_silaba_2, silaba_3:newList.palabra_2_silaba_3, silaba_4:newList.palabra_2_silaba_4, ayuda_imagen: newList.ayuda_imagen_2},
            {id:'3', imagen_url:newList.imagen_url_3, silaba_1:newList.palabra_3_silaba_1, silaba_2:newList.palabra_3_silaba_2, silaba_3:newList.palabra_3_silaba_3, silaba_4:newList.palabra_3_silaba_4, ayuda_imagen: newList.ayuda_imagen_3},
            {id:'4', imagen_url:newList.imagen_url_4, silaba_1:newList.palabra_4_silaba_1, silaba_2:newList.palabra_4_silaba_2, silaba_3:newList.palabra_4_silaba_3, silaba_4:newList.palabra_4_silaba_4, ayuda_imagen: newList.ayuda_imagen_4}
        ]
        
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(newComparar);
        
        setRandomImagen(
            newShuffleList
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comparar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto">
                        {randomImagen.map(item => 
                            <Flex margin="0.5rem 0 0 0"
                                key={item.id}
                            >
                                <Flex direction="column">
                                    <Ilustracion src={`/img/${item.imagen_url}.png`} width="5vw" margin="0px 50px"
                                    isCorrect={isCorrect}
                                    isIncorrect={isIncorrect}/>
                                </Flex>
                                <ContBtnSilaba border={item.border}
                                    disabled={disable}
                                    onClick={(e) => asignar(e, item.id, item.silaba_2, item.silaba_3, item.silaba_4)}
                                >
                                    <Silabas>
                                        {item.silaba_1 === undefined ? null : item.silaba_1}
                                    </Silabas>
                                    <Silabas>
                                        {item.silaba_2 === undefined ? null : item.silaba_2}
                                    </Silabas>
                                    <Silabas>
                                        {item.silaba_3 === undefined ? null : item.silaba_3}
                                    </Silabas>
                                    <Silabas>
                                        {item.silaba_4 === undefined ? null : item.silaba_4}
                                    </Silabas>
                                </ContBtnSilaba>
                            </Flex>
                        )}
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default LEN24;