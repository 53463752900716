import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import useConfigAuth from '../../Hooks/useConfigAuth';

import { solicitarAnamnesisAction } from '../../actions/solicitarAnamnesisActions';
import { objetivosSolicitarAction } from '../../actions/objetivosActions';
import { historiaClinicaSolicitarAction } from '../../actions/historiaClinicaActions';
import { actividadesSolicitarAction, actividadesAgendadasSolicitarAction } from '../../actions/actividadesActions';
import { solicitoCantidadSuscripcionAction } from '../../actions/cargarSuscripcionActions';
import { modificoPacienteLecturaAction, modificoPacienteMinusculaAction } from '../../actions/configuradorActions';

//css
import {Cuerpo, Icon, FlexColumn, FlexBox} from '../Css/StyledHeader.js';
import {Fondo, Pe, BotonPaciente, BotonMas, Div,BotonNaranjaLink, BotonPacienteDos, HiddenText } from '../Css/StyledPatient.js';

const Paciente = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [config] = useConfigAuth();

    const [estadoAnamnesis, setEstadoAnamnesis] = useState(false);
    const [estadoObjetivos, setEstadoObjetivos] = useState(false);
    const [estadoHistoriaClinica, setEstadoHistoriaClinica] = useState(false);
    const [estadoActividades, setEstadoActividades] = useState(false);
    const [estadoCantidadSuscripciones, setEstadoCantidadSuscripciones] = useState(false);
    const [hover, setHover] = useState('none');

    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const pacientes = useSelector((state) => state.informacion.lista_pacientes);
    const informacionAnamnesis = useSelector((state) => state.informacion.info_anamnesis);
    const informacionObjetivos = useSelector((state) => state.informacion.info_objetivos);
    const informacionHistoriaClinica = useSelector((state) => state.informacion.info_historia_clinica);
    const informacionActividades = useSelector((state) => state.informacion.info_actividades);
    const informacionActividadesAgendadas = useSelector((state) => state.informacion.info_actividades_agendadas);
    const informacionCantidadSuscripciones = useSelector((state) => state.usuario.cantidad_suscripcion);
    const rol = useSelector((state) => state.usuario.rol);

    const {nombre, apellido, edad, mes, fecha_nacimiento, curso, minuscula, ayuda_lectura, servicio} = pacientes.find(item => item.id_paciente === id_paciente);

    const isActive = false;

    const traerAnamnesis = (e) => {
        e.preventDefault();
        if(informacionAnamnesis.length === 0 ){
            const informacion = {
                config,
                payload: {
                    id_paciente
                }
            }
            dispatch( solicitarAnamnesisAction(informacion) );
            Swal.fire({
                title: 'Please Wait',
                showConfirmButton: false
                })
            Swal.showLoading();
            setEstadoAnamnesis(true);
        } else {
            navigate("/escritorio/pacientes/paciente/anamnesis");
        }
    }

    const traerObjetivos = (e) => {
        e.preventDefault();
        if(informacionObjetivos.length === 0 ){
        const informacion = {
            config,
            payload: {
                id_paciente
            }
        }
        dispatch( objetivosSolicitarAction(informacion) );
        Swal.fire({
            title: 'Please Wait',
            showConfirmButton: false
            })
        Swal.showLoading();
        setEstadoObjetivos(true);
        } else {
            navigate("/escritorio/pacientes/paciente/objetivos");
        }
    }

    const traerHistoriaClinica = (e) => {
        e.preventDefault();
        if(informacionHistoriaClinica.length === 0){
        const informacion = {
            config,
            payload: {
                id_paciente
            }
        }
        dispatch( historiaClinicaSolicitarAction(informacion) );
        Swal.fire({
            title: 'Please Wait',
            showConfirmButton: false
            })
        Swal.showLoading();
        setEstadoHistoriaClinica(true);
        } else {
            navigate("/escritorio/pacientes/paciente/historiaclinica");
        }
    }

    const traerActividades = (e) => {
        e.preventDefault();
        if(informacionActividades.length === 0){
        const informacion = {
            config,
            payload: {
                id_paciente
            }
        };
        dispatch( actividadesSolicitarAction(informacion) );
        dispatch( actividadesAgendadasSolicitarAction(informacion) );
        Swal.fire({
            title: 'Please Wait',
            showConfirmButton: false
            })
        Swal.showLoading();
        setEstadoActividades(true);
        } else if(informacionActividadesAgendadas.length === 0) {
            const informacion = {
                config,
                payload: {
                    id_paciente
                }
            };
            dispatch( actividadesAgendadasSolicitarAction(informacion) );
            Swal.fire({
                title: 'Please Wait',
                showConfirmButton: false
                })
            Swal.showLoading();
            setEstadoActividades(true);
            }
        else {
            navigate("/escritorio/pacientes/paciente/seleccionaractividades");
        }
    }

    const traerCantidadSuscripciones = (e) => {
        e.preventDefault();
        const informacion = {
            config
        }
        if(informacionCantidadSuscripciones.length === 0){
            dispatch( solicitoCantidadSuscripcionAction(informacion) );
            Swal.fire({
                title: 'Please Wait',
                showConfirmButton: false
                })
            Swal.showLoading();
            setEstadoCantidadSuscripciones(true);
        } else {
            navigate("/escritorio/pacientes/paciente/gestionarmetodo");
        }
    }

    const cambiarMinuscula = (e) => {
        e.preventDefault();
        let estado;
        if(minuscula) {
            estado = false;
        } else {
            estado = true;
        }
        const informacion = {
            config,
            payload: {
                id_paciente,
                estado
            },
            info_pacientes: {
                pacientes
            }
        };
        dispatch(modificoPacienteMinusculaAction(informacion));
    }

    const cambiarLectura = (e) => {
        e.preventDefault();
        let estado;
        if(ayuda_lectura) {
            estado = false;
        } else {
            estado = true;
        }
        const informacion = {
            config,
            payload: {
                id_paciente,
                estado
            },
            info_pacientes: {
                pacientes
            }
        };
        dispatch(modificoPacienteLecturaAction(informacion));
    }

    useEffect(() => {
        if(estadoAnamnesis) {
            Swal.close();
            navigate("/escritorio/pacientes/paciente/anamnesis");
            setEstadoAnamnesis(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informacionAnamnesis])

    useEffect(() => {
        if(estadoObjetivos) {
            Swal.close();
            navigate("/escritorio/pacientes/paciente/objetivos");
            setEstadoObjetivos(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informacionObjetivos])

    useEffect(() => {
        if(estadoHistoriaClinica) {
            Swal.close();
            navigate("/escritorio/pacientes/paciente/historiaclinica");
            setEstadoHistoriaClinica(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informacionHistoriaClinica])

    useEffect(() => {
        if(estadoActividades) {
            Swal.close();
            navigate("/escritorio/pacientes/paciente/seleccionaractividades");
            setEstadoActividades(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informacionActividades])

    useEffect(() => {
        if(!informacionActividades) {
            return;
        }
        if(estadoActividades) {
            Swal.close();
            navigate("/escritorio/pacientes/paciente/seleccionaractividades");
            setEstadoActividades(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informacionActividadesAgendadas])
    
    useEffect(() => {
        if(estadoCantidadSuscripciones) {
            Swal.close();
            navigate("/escritorio/pacientes/paciente/gestionarmetodo");
            setEstadoCantidadSuscripciones(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informacionCantidadSuscripciones])
       
    return ( 
        <Cuerpo>
            <HeaderUno />
            <HeaderDos link={"/escritorio/pacientes"} componente={"Información paciente"} paciente={true} />
            <FlexColumn align="center">
                <Fondo width="70vw" >
                {servicio === 'Usuario Prueba' || servicio === 'Finalizó Prueba' ?
                    <FlexBox justify="space-between">
                        <Fondo width="25vw">   
                        <Pe>{`Nombre: ${nombre}`} </Pe> 
                        <Pe>{`Apellido: ${apellido}`}</Pe> 
                        <FlexBox justify="space-between">
                            <Pe>{`Edad: ${edad}`}</Pe>
                            <Pe>{`Meses: ${mes}`}</Pe> 
                        </FlexBox>
                        <Pe>{`Fecha de nacimiento: ${fecha_nacimiento}`} </Pe>
                        <Pe>{`Curso: ${curso}`}</Pe> 
                        <FlexColumn align="center">
                            <BotonMas bgcolor="grey" ><Icon src={`/img/mas.svg`} alt="" width="20px" margin="10px"/></BotonMas>
                            <BotonNaranjaLink bgcolor="grey" margin="3vh 0px 0px 0px">Desactivar Método Yögen</BotonNaranjaLink>
                        </FlexColumn> 
                        </Fondo>
                        <Div margin="10vh 0vh 0vh 5vh">
                            <FlexBox>
                                <BotonPaciente bgcolor="grey" >
                                    Anamnesis
                                </BotonPaciente>
                                <BotonPaciente bgcolor="grey" >Resultados</BotonPaciente>
                            </FlexBox>
                            <FlexBox>
                                <BotonPaciente bgcolor="grey">
                                    Historia Clínica
                                </BotonPaciente>
                                <BotonPaciente bgcolor="grey" to={isActive ? `/escritorio/pacientes/paciente/calendario` : '#'} >Calendario</BotonPaciente>
                            </FlexBox>
                            <FlexBox>
                                <BotonPaciente bgcolor="grey" to={isActive ? `/escritorio/pacientes/paciente/informesprevios` : '#'} >Informes Previos</BotonPaciente>
                                <BotonPaciente bgcolor="grey">
                                    Objetivos
                                </BotonPaciente>
                            </FlexBox>
                            <FlexBox>
                                <BotonPaciente bgcolor="grey">
                                    Seleccionar Actividades
                                </BotonPaciente>
                                {servicio === "Finalizó Prueba" || rol === "321684"? 
                                    <BotonPaciente bgcolor="grey" >Método Yögen </BotonPaciente>
                                :
                                    <BotonPaciente to={`/escritorio/metodos`} >Método Yögen </BotonPaciente>
                                }
                            </FlexBox>
                        </Div>
                    </FlexBox>
                :
                    <FlexBox justify="space-between">
                        <Fondo width="25vw">   
                        <Pe>{`Nombre: ${nombre}`} </Pe> 
                        <Pe>{`Apellido: ${apellido}`}</Pe>
                        <FlexBox justify="space-between">
                            <Pe>{`Edad: ${edad}`}</Pe>
                            <Pe>{`Meses: ${mes}`}</Pe> 
                        </FlexBox>
                        <Pe>{`Fecha de nacimiento: ${fecha_nacimiento}`} </Pe>
                        <Pe>{`Curso: ${curso}`}</Pe> 
                        <FlexColumn align="center">
                            <BotonMas to={`/escritorio/pacientes/paciente/datos`}><Icon src={`/img/mas.svg`} alt="" width="20px" margin="10px"/></BotonMas>
                            {servicio === "Método Yögen" ? 
                            <BotonNaranjaLink to={`/escritorio/pacientes/paciente/gestionarmetodo`} margin="3vh 0px 0px 0px">Desactivar Método Yögen</BotonNaranjaLink>
                            :
                            <BotonNaranjaLink
                            onClick={(e) => traerCantidadSuscripciones(e)}
                            >
                                Activar Método Yögen
                            </BotonNaranjaLink>
                            }
                        </FlexColumn> 
                        </Fondo>
                        <Div margin="10vh 0vh 0vh 5vh">
                            <FlexBox>
                                <BotonPaciente
                                onClick={(e) => traerAnamnesis(e)}
                                >
                                    Anamnesis
                                </BotonPaciente>
                                <BotonPaciente to={`/escritorio/pacientes/paciente/resultados`}>Resultados</BotonPaciente>
                            </FlexBox>
                            <FlexBox>
                                <BotonPaciente 
                                onClick={(e) => traerHistoriaClinica(e)}
                                >
                                    Historia Clínica
                                </BotonPaciente>
                                <BotonPaciente bgcolor="grey" to={isActive ? `/escritorio/pacientes/paciente/calendario` : '#'} >Calendario</BotonPaciente>
                            </FlexBox>
                            <FlexBox>
                                <BotonPaciente bgcolor="grey" to={isActive ? `/escritorio/pacientes/paciente/informesprevios` : '#'} >Informes Previos</BotonPaciente>
                                <BotonPaciente
                                onClick={(e) => traerObjetivos(e)}
                                >
                                    Objetivos
                                </BotonPaciente>
                            </FlexBox>
                            <FlexBox>
                                <BotonPaciente bgcolor={servicio !== "Método Yögen" ? "grey" : "#FFF" } 
                                onClick={(e) => (servicio === "Método Yögen" ? traerActividades(e) : undefined)}
                                onMouseOver={() => setHover('block')}
                                onMouseOut={() => setHover('none')}
                                >
                                    Ejercitación Domicilio
                                </BotonPaciente>
                                {rol === '489431' ? 
                                    <BotonPaciente bgcolor={servicio !== "Método Yögen" ? "grey" : "#FFF" } to={servicio === "Método Yögen" ? `/escritorio/metodos` : '#'} 
                                        onMouseOver={() => setHover('block')}
                                        onMouseOut={() => setHover('none')}
                                    >
                                        Método Yögen
                                    </BotonPaciente>
                                    :
                                    <BotonPaciente>Profesional no suscripto</BotonPaciente>
                                }
                            </FlexBox>
                            {servicio !== "Método Yögen" ? <HiddenText display={hover}>Debe Activar el Método</HiddenText>: null}
                        </Div>
                    </FlexBox>
                }
                    <FlexBox justify="center" align="center" margin="2rem 0 0 0">
                        <FlexBox margin="0 1rem 0 0" justify="center" align="center">
                            <div>Tipo de Letra:</div>
                            <BotonPaciente
                                onClick={(e) => cambiarMinuscula(e)}
                            >
                                {minuscula ? <div>Mínuscula</div> : <div>Mayúscula</div>}
                            </BotonPaciente>
                        </FlexBox>
                        <FlexBox justify="center" align="center">
                            <div>Ayuda Color:</div>
                            <BotonPaciente
                                onClick={(e) => cambiarLectura(e)}
                            >
                                {ayuda_lectura ? <div>"Activo"</div> : <div>"Inactivo"</div> }
                            </BotonPaciente>
                        </FlexBox>
                    </FlexBox>
                </Fondo>
            </FlexColumn>
        </Cuerpo>
    );
}
 
export default Paciente;