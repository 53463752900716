import {
    INICIAR_EJERCICIO,
    FINALIZAR_EJERCICIO,
    RESET_EJERCICIO,
    SETEAR_EJERCICIO,
    SETEAR_LETRA,
    BORRAR_LETRA,
    SETEAR_LISTA,
    BORRAR_LISTA,
    SETEAR_URL,
    BORRAR_URL,
    SETEAR_URL_ANTERIOR,
    BORRAR_URL_ANTERIOR,
    SETEAR_SESION,
    SETEAR_SESION_TERMINADA,
    SETEAR_PRIMER_TIEMPO,
    BORRAR_PRIMER_TIEMPO,
    SETEAR_SEGUNDO_TIEMPO,
    BORRAR_SEGUNDO_TIEMPO,
    PROXIMO_EJERCICIO,
    PROXIMA_RONDA,
    SETEAR_GRUPO_EJERCICIO,
    SETEAR_ITEM_GRUPO,
    NUEVA_RONDA_EJERCICIO,
    BORRAR_INFO_DB
} from '../types';

// Inicio el ejercicio
export function iniciarEjercicioAction() {
    return (dispatch) => {
        dispatch( iniciarEjercicio() );
    }
}
//Finalizo ejericio
export function finalizarEjercicioAction() {
    return (dispatch) => {
        dispatch( finalizarEjercicio() );
    } 
}
//Reset ejercicio
export function resetearEjercicioAction() {
    return (dispatch) => {
        dispatch( resetearEjercicio() );
    } 
}

export function nuevaRondaAction() {
    return (dispatch) => {
        dispatch( nuevaRondaEjercicio() );
    } 
}

// Seteo el ejercicio que va a iniciar
export function setearEjercicioAction(ejercicio) {
    return (dispatch) => {
        dispatch( setearEjercicio(ejercicio) );
    } 
}

export function setearLetraAction(ltr) {
    return (dispatch) => {
        dispatch( setearLetra(ltr) );
    }
}

export function borrarLetraAction() {
    return (dispatch) => {
        dispatch( borrarLetra() );
    }
}

export function setearListaAction(ltr) {
    return (dispatch) => {
        dispatch( setearLista(ltr) );
    }
}

export function borrarListaAction() {
    return (dispatch) => {
        dispatch( borrarLista() );
    }
}

export function setearUrlAction(url) {
    return (dispatch) => {
        dispatch( setearUrl(url) );
    }
}

export function borrarUrlAction() {
    return (dispatch) => {
        dispatch( borrarUrl() );
    }
}

export function setearPrimerTiempoAction(primerTiempo) {
    return (dispatch) => {
        dispatch( setearPrimerTiempo(primerTiempo) );
    }
}

export function borrarPrimerTiempoAction() {
    return (dispatch) => {
        dispatch( borrarPrimerTiempo() );
    }
}

export function setearSegundoTiempoAction(segundoTiempo) {
    return (dispatch) => {
        dispatch( setearSegundoTiempo(segundoTiempo) );
    }
}

export function borrarSegundoTiempoAction() {
    return (dispatch) => {
        dispatch( borrarSegundoTiempo() );
    }
}

export function setearUrlAnteriorAction(urlAnterior) {
    return (dispatch) => {
        dispatch( setearUrlAnterior(urlAnterior) );
    }
}

export function borrarUrlAnteriorAction() {
    return (dispatch) => {
        dispatch( borrarUrlAnterior() );
    }
}

export function setearSesionAction(payload) {
    return (dispatch) => {
        const {sesion} = payload;
        const ejercicio = sesion.find(item => item.estado_ejercicio === 'pendiente');
        dispatch( setearSesion(sesion) );
        if(ejercicio){
            dispatch( setearUrl(ejercicio.url))
            dispatch( setearLetra(ejercicio.letra) );
            dispatch( setearLista(ejercicio.lista) );
        }
    }
}

export function setearSesionTerminadaAction(payload) {
    return (dispatch) => {
        const {sesionTerminada} = payload;
        const ejercicioTerminado = sesionTerminada.find(item => item.status === 'pendiente');
        dispatch( setearSesion(sesionTerminada) );
        if(ejercicioTerminado) {
            dispatch( setearUrl(ejercicioTerminado.url))
            dispatch( setearLetra(ejercicioTerminado.letra) );
            dispatch( setearLista(ejercicioTerminado.lista) );
        }
        dispatch( setearSesionTerminada(sesionTerminada));
    }
}

export function proximoEjercicioAction() {
    return (dispatch) => {
        dispatch( proximoEjercicio() );
    }
}

export function proximaRondaAction() {
    return (dispatch) => {
        dispatch( proximaRonda() );
    }
}

export function setearGrupoEjercicioAction(grp) {
    return (dispatch) => {
        dispatch( setearGrupoEjercicio(grp) );
    }
}

export function setearItemGrupoAction(idx) {
    return (dispatch) => {
        dispatch( setearItemGrupo(idx) );
    }
}

export function borrarInfoDBAction() {
    return (dispatch) => {
        dispatch( borrarInfoDB() );
    }
}

const iniciarEjercicio = () => ({
    type: INICIAR_EJERCICIO,
    payload: true
});

const finalizarEjercicio = () => ({
    type: FINALIZAR_EJERCICIO,
    payload: true
});

const resetearEjercicio = () => ({
    type: RESET_EJERCICIO
});

const nuevaRondaEjercicio = () => ({
    type: NUEVA_RONDA_EJERCICIO
});

const setearEjercicio = (ejercicio) => ({
    type: SETEAR_EJERCICIO,
    payload: ejercicio
});

const setearLetra = (ltr) => ({
    type: SETEAR_LETRA,
    payload: ltr
});

const borrarLetra = () => ({
    type: BORRAR_LETRA
});

const setearLista = (ltr) => ({
    type: SETEAR_LISTA,
    payload: ltr
});

const borrarLista = () => ({
    type: BORRAR_LISTA
});

const setearUrl = (url) => ({
    type: SETEAR_URL,
    payload: url
});

const borrarUrl = () => ({
    type: BORRAR_URL
});

const setearPrimerTiempo = (primerTiempo) => ({
    type: SETEAR_PRIMER_TIEMPO,
    payload: primerTiempo
});

const borrarPrimerTiempo = () => ({
    type: BORRAR_PRIMER_TIEMPO
});

const setearSegundoTiempo = (segundoTiempo) => ({
    type: SETEAR_SEGUNDO_TIEMPO,
    payload: segundoTiempo
});

const borrarSegundoTiempo = () => ({
    type: BORRAR_SEGUNDO_TIEMPO
});

const setearUrlAnterior = (urlAnterior) => ({
    type: SETEAR_URL_ANTERIOR,
    payload: urlAnterior
});

const borrarUrlAnterior = () => ({
    type: BORRAR_URL_ANTERIOR
});

const setearSesion = (sesion) => ({
    type: SETEAR_SESION,
    payload: sesion
});

const setearSesionTerminada = (sesionTerminada) => ({
    type: SETEAR_SESION_TERMINADA,
    payload: sesionTerminada
});

const proximoEjercicio = () => ({
    type: PROXIMO_EJERCICIO
});

const proximaRonda = () => ({
    type: PROXIMA_RONDA
});

const setearGrupoEjercicio = (grp) => ({
    type: SETEAR_GRUPO_EJERCICIO,
    payload: grp
});

const setearItemGrupo = (idx) => ({
    type: SETEAR_ITEM_GRUPO,
    payload: idx
});

const borrarInfoDB = () => ({
    type: BORRAR_INFO_DB
});
