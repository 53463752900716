import {
    ENVIAR_INVITACION_PACIENTE,
    ENVIAR_INVITACION_PACIENTE_EXITO,
    ENVIAR_INVITACION_PACIENTE_ERROR,
    CARGAR_DATOS_PACIENTE_NO_REGISTRADO,
    CARGAR_DATOS_PACIENTE_NO_REGISTRADO_EXITO,
    MOSTRAR_ALERTA
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function enviarInvitacionPacienteAction(informacion) {
    return async (dispatch) => {
        dispatch( enviarInvitacionPaciente() );
        try {
            const {config, payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/enviarinvitacionpaciente', payload,config);
            dispatch( enviarInvitacionPacienteExito(respuesta.data.msg) );
            if(respuesta.data.msg === 'Invitación enviada') {
                Swal.fire(
                    'Correcto',
                    'El email se ha enviado al paciente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Incorrecto',
                    'El email no se ha enviado al paciente',
                    'error'
                )
            }
            
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( enviarInvitacionPacienteError(error.response.data) );
            }
        }
    }
}

const enviarInvitacionPaciente = () => ({
    type: ENVIAR_INVITACION_PACIENTE,
    payload: true
});
const enviarInvitacionPacienteExito = (msg) => ({
    type: ENVIAR_INVITACION_PACIENTE_EXITO,
    payload: msg
});
const enviarInvitacionPacienteError = (msg) => ({
    type: ENVIAR_INVITACION_PACIENTE_ERROR,
    payload: msg
});

export function cargarDatosPacienteNoRegistradoAction(informacion) {
    return async (dispatch) => {
        dispatch( cargarDatosPacienteNoRegistrado() );
        try {
            const {payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/cargardatospacientenoregistrado', payload);
            dispatch( cargarDatosPacienteNoRegistradoExito(respuesta.data.msg) );

        } catch (error) {
            const errores = error.response.data;
            if (errores !== undefined) {
                dispatch (mostrarAlerta(errores));
            }
        }
    }
}

const cargarDatosPacienteNoRegistrado = () => ({
    type: CARGAR_DATOS_PACIENTE_NO_REGISTRADO,
    payload: true
});
const cargarDatosPacienteNoRegistradoExito = (msg) => ({
    type: CARGAR_DATOS_PACIENTE_NO_REGISTRADO_EXITO,
    payload: msg
});
// si hubo un error
const mostrarAlerta = (alerta) => ({
    type: MOSTRAR_ALERTA,
    payload: alerta
});