import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useColorHelp from '../../../Hooks/useColorHelp';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction } from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlex } from '../../Css/MetodosCSS/StyledLN2Todos';
import { BtnSilaba, ContainerFlexx, BtnImg, Img } from '../../Css/MetodosCSS/StyledLectoEscritura';

const LN6Todos = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, instrucciones, miniInstrucciones } = props;

    const [disable, setDisable] = useState(false);
    const [count, setCount] = useState(1);
    const [comparar, setComparar] = useState([]);
    const [randomImagen, setRandomImagen] = useState([]);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [arrayColorUno, transformarColorUno] = useColorHelp('');
    const [arrayColorDos, transformarColorDos] = useColorHelp('');
    const [arrayColorTres, transformarColorTres] = useColorHelp('');
    const [arrayColorCuatro, transformarColorCuatro] = useColorHelp('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const letra = useSelector(state => state.ejercicio.letra);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_lectura = useSelector(state => state.configuracion.ayuda_lectura);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, plb) => {
        e.preventDefault();
        if (plb.toUpperCase() !== comparar.palabra_1.toUpperCase()) {
            setDisable(true);
            console.log('mal');
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setDisable(false);
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            return;
        }
        console.log('bien');
        setDisable(true);
        setIsCorrect(true);
        setTimeout(() => {
            setIsCorrect(false);
        }, 300);
        GoodWork();
        if (count < indexes) {
            setCount(count + 1);
            setDisable(false);
        } else {
            setTimeout(() => {
                setDisable(false);
                setUltimoTiempo(true);
                dispatch(finalizarEjercicioAction());
            }, 1500);
        }
    }

    useEffect(() => {
        if(comparar.length === 0 ){
            return;
        }
        transformarColorUno(comparar.silaba_1);
        transformarColorDos(comparar.silaba_2);
        transformarColorTres(comparar.silaba_3);
        transformarColorCuatro(comparar.silaba_4);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, comparar, ayuda_lectura])

    useEffect(() => {
        if(data === undefined) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(data[count-1]));
        const nuevaLista = [
            {id:1, palabra: newList.palabra_1, imagen: newList.imagen_url_1, ayuda_imagen: newList.ayuda_imagen_1},
            {id:2, palabra: newList.palabra_2, imagen: newList.imagen_url_2, ayuda_imagen: newList.ayuda_imagen_2},
            {id:3, palabra: newList.palabra_3, imagen: newList.imagen_url_3, ayuda_imagen: newList.ayuda_imagen_3},
            {id:4, palabra: newList.palabra_4, imagen: newList.imagen_url_4, ayuda_imagen: newList.ayuda_imagen_4}
        ]

        function shuffle(array) {
            let currentIndex = array.length, randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex !== 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const newShuffleList = shuffle(nuevaLista);
        setRandomImagen(newShuffleList);
        setComparar(newList);
    }, [count, data]);

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                'ejercicio': ejercicio,
                'letra': letra,
                'errores': contarErrores,
                'tiempo': tiempoFinal,
                'id_paciente': id_paciente,
                sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} />
            {iniciar
                ?
                <ContainerFlex display="flex" direction="column" height="85vh">

                    <ContainerFlex display="flex" align="center" justify="center" height="50vh">
                        {comparar.silaba_1 === '' ? null :
                        <ContainerFlex>
                            {arrayColorUno.map((item, i) =>
                                <BtnSilaba $minMay={minuscula} style={{width: "auto"}}
                                    key={i}
                                >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                </BtnSilaba>
                            )}
                        </ContainerFlex>
                        }
                        {comparar.silaba_2 === '' ? null :
                        <ContainerFlexx>
                            {arrayColorDos.map((item, i) =>
                                <BtnSilaba $minMay={minuscula} style={{width: "auto"}}
                                    key={i}
                                >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                </BtnSilaba>
                            )}
                        </ContainerFlexx>
                        }
                        {comparar.silaba_3 === '' ? null :
                        <ContainerFlexx>
                            {arrayColorTres.map((item, i) =>
                                <BtnSilaba $minMay={minuscula} style={{width: "auto"}}
                                    key={i}
                                >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                </BtnSilaba>
                            )}
                        </ContainerFlexx>
                        }
                        {comparar.silaba_4 === '' ? null :
                        <ContainerFlexx>
                            {arrayColorCuatro.map((item, i) =>
                                <BtnSilaba $minMay={minuscula} style={{width: "auto"}}
                                    key={i}
                                >
                                    {item.map((itemInterior, i) =>
                                        <span style={{color: itemInterior.color}}
                                        key={i}
                                        >
                                            {itemInterior.letra}
                                        </span>
                                    )}
                                </BtnSilaba>
                            )}
                        </ContainerFlexx>
                        }
                    </ContainerFlex>

                    <ContainerFlexx height="25vh">
                        {randomImagen.map(item =>
                        <BtnImg
                            key={item.id}
                            disabled={disable}
                            onClick={(e) => verificar(e, item.palabra)}
                        >
                                <Img src={`/img/${item.imagen}.png`} w="100%" h="100%"
                                isCorrect={isCorrect} 
                                isIncorrect={isIncorrect}
                                />
                                {ayuda_imagen ? 
                                <div>{item.ayuda_imagen}</div>
                                : null}
                        </BtnImg>
                        )}
                    </ContainerFlexx>
                </ContainerFlex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default LN6Todos;