import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import BotoneraBottom from './BotoneraBottom';
import HeaderEjercicios from '../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../Escritorio/Instrucciones';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction } from '../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../actions/almacenarEjercicioActions';

import { Flex, BtnNumberM9, Img, DescriptionM18, BtnSiNo, FlexM20 } from '../../Css/StyledMatematica';

const CantIguales = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { data, indexes, ejercicio, w, wm, wt, wd, wlg, instrucciones, miniInstrucciones } = props;

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, resultado) => {
        e.preventDefault();
        if(comparar.cantidad_1 === comparar.cantidad_2 && resultado === "si") {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes){
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() )                        
                }, 1000);
            }
        } else if(comparar.cantidad_1 !== comparar.cantidad_2 && resultado === "no") {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes){
                setTimeout(() => {
                    setDisable(false);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    dispatch( finalizarEjercicioAction() )                        
                }, 1000);
            }
        } else {
            console.log('mal')
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
            }, 1000);
            return
        }
    }
    
    useEffect(() => {
        if(data === undefined) {
            return;
        }
        setComparar(data[count-1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar, count])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                    }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%"
                isCorrect={isCorrect} isIncorrect={isIncorrect}
                >
                    <Flex direction="column" height="100%" width="100%">
                        <DescriptionM18>
                            {comparar.frase} { /* Esto lo modifique yo, Cami */}
                        </DescriptionM18>
                        <div>
                            {comparar.numero_1
                            ?
                            <Flex>
                                <BtnNumberM9>
                                {comparar.numero_1}
                                </BtnNumberM9>
                                <BtnNumberM9>
                                <Img src={`/img/${comparar.imagen_url_1}.png`} width={w} wm={wm} wt={wt} wd={wd} wlg={wlg} />
                                </BtnNumberM9>
                            </Flex>
                            :
                            <Flex>
                                <Img src={`/img/${comparar.imagen_url_1}.png`} width={w} wm={wm} wt={wt} wd={wd} wlg={wlg} />
                                <Img src={`/img/${comparar.imagen_url_2}.png`} width={w} wm={wm} wt={wt} wd={wd} wlg={wlg} />
                                {/* {comparar.frase} */}
                            </Flex>}
                            
                        </div>
                        <Flex>
                            <BtnSiNo bgcolor='#D76970'
                                disabled={disable}
                                onClick={(e) => verificar(e, "si")}
                            >   
                                SI
                            </BtnSiNo>
                            <BtnSiNo bgcolor='#FF9B2A'
                                disabled={disable}
                                onClick={(e) => verificar(e, "no")}
                            >   
                                NO
                            </BtnSiNo>
                        </Flex>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="start">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones}/>
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default CantIguales;