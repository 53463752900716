import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import Instrucciones from '../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../Hooks/useConfigAuth';

import { almacenarAction } from '../../../../actions/almacenarEjercicioActions';
import { aumentarContadorConCorreccionAction } from '../../../../actions/botoneraEjercicioActions';
import { ayudaVisualAction } from '../../../../actions/configuradorActions';

import { FlexM20, Number, NumerosAyuda, ButtonAyuda, Flex } from '../../../Css/StyledMatematica';

const modelo = [
    {id:'1', numero:10},
    {id:'2', numero:20},
    {id:'3', numero:30},
    {id:'4', numero:40},
    {id:'5', numero:50},
    {id:'6', numero:60},
    {id:'7', numero:70},
    {id:'8', numero:80},
    {id:'9', numero:90}
]

const Practica20 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const { ejercicio, instrucciones, miniInstrucciones } = props;

    const [comparar, setComparar] = useState([]);
    const [randomNumeros, setRandomNumeros] = useState([]);
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [idOrden, setIdOrden] = useState([]);
    const [erroresOrden, setErroresOrden] = useState([]);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista = useSelector(state => state.ejercicio.lista);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const contadorConCorreccion = useSelector(state => state.ejercicio.contadorConCorreccion);
    const error = useSelector(state => state.ejercicio.error);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const indexes = Object.keys(modelo).length;

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
            dispatch( ayudaVisualAction(true) );
        }
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(modelo));
        const newList2 = newList.map(item => item.numero);

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleList = shuffle(newList2);
        setRandomNumeros(shuffleList)
        const newList3 = JSON.parse(JSON.stringify(shuffleList));
        const elOrdenDelId = newList3.map(item => item)
        setIdOrden(elOrdenDelId);
    }, [])

    useEffect(() => {
        setComparar(modelo);
    }, [])

    useEffect(() => {
        if(contadorConCorreccion === 0){
            return;
        }
        if(contadorConCorreccion === 1){
            setErroresOrden([...erroresOrden, error]);
            return;
        }
        const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
        setErroresOrden([...erroresOrden, err]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contadorConCorreccion])

    useEffect(() => {
        if(iniciar === false && contadorConCorreccion === indexes ){
            return;
        }else if(iniciar === false && contadorConCorreccion === 0){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,contadorConCorreccion]);

    useEffect(() => {
        if(finalizar === true && contadorConCorreccion === indexes -1 ){
            dispatch(aumentarContadorConCorreccionAction());
        }
        if(finalizar && contadorConCorreccion === indexes ){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const err = error - erroresOrden.reduce((partialSum, a) => partialSum + a, 0);
            const erroresOrdenFinal = [...erroresOrden, err];

            const informacion = {
                config,
                payload: {
                    'ejercicio': ejercicio,
                    'lista': lista,
                    'errores': error,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda_visual': ayuVisualSolicitada,
                    'idOrden': idOrden,
                    'erroresOrden': erroresOrdenFinal,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar, contadorConCorreccion]);

    return (
        <Fragment>
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto" width="100%">
                        <Number color='#FFDC73'>{randomNumeros[contadorConCorreccion]}</Number>
                            <FlexM20 margin='16vh 0px 0vh 0px'>
                                <ButtonAyuda
                                    onClick={(e) => setearAyuVisual(e)}
                                >
                                    ?
                                </ButtonAyuda>
                                <div>
                                {ayuVisual &&
                                <FlexM20 position='absolute' margin='-2.3vh 0vw 0vh -1vw'>
                                    {comparar.map(item => <NumerosAyuda>{item.numero}</NumerosAyuda>)}
                                </FlexM20>
                                }
                                </div>
                            </FlexM20>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <Flex>
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </Flex>
                        <Flex>
                            <BotoneraBottom error={true} aumentarConCorreccion={true} cantidad={indexes}/>
                        </Flex>
                    </Flex>
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default Practica20;