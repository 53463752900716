import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {Ilustracion, Flex, ContInst, BtnSelected, Texto} from '../../../../Css/MetodosCSS/StyledCCLexica';

const instrucciones = 'Encuentra los dos dibujos que se encuentran escondidos en la palabra.';
const miniInstrucciones = 'Encuentra los dos dibujos que se encuentran escondidos en la palabra.';

const LEN19 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [randomImagen, setRandomImagen] = useState('');
    const [imagenCorrecta, setImagenCorrecta] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e, imagenElegida, primerImagen, index) => {
        e.preventDefault();
        if(primerImagen === undefined || primerImagen === '') {
            if(imagenElegida !== comparar.palabra_parte_1) {
                console.log('mal');
                setDisable(true);
                setContarErrores(contarErrores + 1);
                setTimeout(() => {
                    setDisable(false);
                }, 1500);
                setIsIncorrect(true);
                setTimeout(() => {
                    setIsIncorrect(false);
                }, 300);
                TryAgain();
                return
            } else {
                setImagenCorrecta(imagenElegida);
                console.log('bien');
                const newList = JSON.parse(JSON.stringify(randomImagen));
                newList[index].border = true;
                setRandomImagen(newList);
                setDisable(true)
                setTimeout(() => {
                    setDisable(false);
                }, 300);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
            }
        } else {
            if(imagenElegida !== comparar.palabra_parte_2) {
                console.log('mal');
                setImagenCorrecta('');
                const newList = JSON.parse(JSON.stringify(randomImagen));
                newList[0].border = false;
                newList[1].border = false;
                newList[2].border = false;
                setRandomImagen(newList);
                setDisable(true)
                setContarErrores(contarErrores + 1);
                setTimeout(() => {
                    setDisable(false);
                }, 300);
                setIsIncorrect(true);
                setTimeout(() => {
                  setIsIncorrect(false);  
                }, 300);   
                TryAgain();
            } else {
                console.log('bien');
                const newList = JSON.parse(JSON.stringify(randomImagen));
                newList[index].border = true;
                setRandomImagen(newList);
                setDisable(true);
                setIsCorrect(true);
                setTimeout(() => {
                    setIsCorrect(false);
                }, 300);
                GoodWork();
                if(count < indexes) {
                    setTimeout(() => {
                        const newList = JSON.parse(JSON.stringify(randomImagen));
                        newList[0].border = false;
                        newList[1].border = false;
                        newList[2].border = false;
                        setRandomImagen(newList);
                        setDisable(false);
                        setCount(count + 1)
                        setImagenCorrecta('');
                    }, 1500);
                } else {
                    setUltimoTiempo(true);
                    setTimeout(() => {
                        setDisable(false);
                        setImagenCorrecta('');
                        dispatch( finalizarEjercicioAction() )                        
                    }, 1500);
                }
            }
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN19') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-lexica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN19",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        const newComparar = [
            {id:'1', imagen_url:newList.imagen_url_1, palabra_parte:newList.palabra_parte_1, ayuda_imagen: newList.ayuda_imagen_1},
            {id:'2', imagen_url:newList.imagen_url_2, palabra_parte:newList.palabra_parte_2, ayuda_imagen: newList.ayuda_imagen_2},
            {id:'3', imagen_url:newList.imagen_url_3, palabra_parte:newList.palabra_parte_3, ayuda_imagen: newList.ayuda_imagen_3}
        ]

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        const newShuffleList = shuffle(newComparar);
        
        setRandomImagen(
            newShuffleList
        )
    }, [comparar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} ayuImagen={true} />
                {iniciar
                    ?
                    <Flex direction="column" height="100%" width="100%">
                        <Flex margin="auto" direction="column">
                            <div>
                                <Texto width="35vw" background="#23AAAF">{comparar.palabra_1}</Texto>
                            </div>
                            <Flex justify="space-around" margin="3rem 0 0 0">
                                {randomImagen.map((item, index) => 
                                    <BtnSelected border={item.border}
                                        key={item.id}
                                        disabled={disable}
                                        onClick={(e) => verificar(e, item.palabra_parte, imagenCorrecta, index)}
                                    >   
                                        <Flex direction="column">
                                            <Ilustracion src={`/img/${item.imagen_url}.png`} width="8vw" margin="20px"
                                            isCorrect={isCorrect} 
                                            isIncorrect={isIncorrect}/>
                                            {ayuda_imagen ? 
                                                <div>{item.ayuda_imagen}</div>
                                            : null}
                                        </Flex>
                                    </BtnSelected>
                                )}
                            </Flex>
                        </Flex>
                        <Flex align="center" justify="flex-start" width="90%" margin="0 0 1rem 0">
                            <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        </Flex>
                    </Flex>
                    :
                        <Instrucciones instrucciones={instrucciones} />
                    }
        </Fragment>
    );
}
 
export default LEN19;