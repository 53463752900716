import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import PracticaDecena from '../PracticaDecena';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, NumFlexM21, NumbersM21, FlexFull } from '../../../../Css/StyledMatematica';

const data = [
    {id:'1', numero: 50, color: "#FFDC73"},
    {id:'2', numero: 51, color: "#23AAAF"},
    {id:'3', numero: 52, color: "#D76970"},
    {id:'4', numero: 53, color: "#FF9B2A"},
    {id:'5', numero: 54, color: "#655EE8"},
    {id:'6', numero: 55, color: "#FFDC73"},
    {id:'7', numero: 56, color: "#23AAAF"},
    {id:'8', numero: 57, color: "#D76970"},
    {id:'9', numero: 58, color: "#FF9B2A"},
    {id:'10', numero: 59, color: "#FF9B2A"}
]

const instrucciones = 'A continuación se presentan números diferenciados por su valor posicional. El niño deberá leer en voz alta dichos números. Si necesita ayuda se puede hacer click sobre el recuadro donde presenta dificultad. Asimismo se puede hacer click sobre el ícono de ayuda.';
const miniInstrucciones = 'Lee los números.';

const M25 = () => {

    const dispatch = useDispatch();

    const [comparar, setComparar] = useState([]);
    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);

    if(contador === 11){
        dispatch(setearEjercicioAction('M25Bis') );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        setComparar(data);
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('M25') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar || fuerzoInicio
            ?
            <Flex direction="column" height="100%" width="100%">
                {contador === 0 &&
                <Flex height='78vh'>
                        <NumbersM21 color='pink'>50</NumbersM21>
                        <NumFlexM21>
                            {comparar.filter(item => item.id !== '1').map(item => 
                            <NumbersM21 color={item.color} key={item.id}>
                                {item.numero}
                            </NumbersM21>
                            )}
                        </NumFlexM21> 
                </Flex> 
                }
                <FlexCuz position='fixed' right='0' bottom='0'>
                    {contador === 0 &&
                        <BotoneraBottom aumentar={true} /> 
                    }
                </FlexCuz>
                {contador > 0 && contador < 11 &&
                <div>
                    <FlexFull height='78vh' justify='center' align='center'>  
                        <NumbersM21 color='pink'>50</NumbersM21>
                        <NumFlexM21>
                            {comparar.filter(item => item.id !== '1' && item.id < contador+1).map(item => 
                            <NumbersM21 color={item.color} key={item.id}>
                                {item.numero}
                            </NumbersM21>
                            )}
                        </NumFlexM21> 
                    </FlexFull>
                    <FlexCuz position='fixed' right='0' bottom='0'>
                            <BotoneraBottom reducir={true} aumentar={true} />
                    </FlexCuz>
                </div>
                }
                {contador >= 11 &&
                <PracticaDecena data={data} ejercicio="M25Bis" />
                }
            </Flex>
            :
            <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M25;