import React, {Fragment} from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

// css
import {Container, ContainerImg, FlexLink, Descripcion } from '../../../Css/MetodosCSS/StyledMethods';
import { HrMetodos } from '../../../Css/StyledHeader';

const CCFonemica = () => {
    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lenguaje/"} n1={"Métodos"} n2={"LENGUAJE"} componente={"CCFONEMICA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>

            <Container justify="center" direction="column" width="100%" height="100%" margin="2rem 0 0 0">
                <Container justify="space-around" wrap="wrap" >
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN1'}><ContainerImg src={`/img/portadas/LEN1.jpg`} alt=""/> <Descripcion>Robot - palabra a sonidos</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN2'}><ContainerImg src={`/img/portadas/LEN2.jpg`} alt=""/> <Descripcion>Sonido inicial</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN3'}><ContainerImg src={`/img/portadas/LEN3.jpg`} alt=""/> <Descripcion>Sinfones</Descripcion></FlexLink>
                    
                </Container>
                <Container justify="space-around" margin="50px 0px 0px 0px">
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN9'}><ContainerImg src={`/img/portadas/LEN9.jpg`} alt=""/> <Descripcion>Vocal inicial</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN4'}><ContainerImg src={`/img/portadas/LEN4.jpg`} alt=""/> <Descripcion>Letra inicial abecedario</Descripcion></FlexLink>
                </Container>
                <Container justify="space-around" margin="50px 0px 0px 0px">
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN5'}><ContainerImg src={`/img/portadas/LEN5.jpg`} alt=""/> <Descripcion>Sonido inicial palabras</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN6'}><ContainerImg src={`/img/portadas/LEN6.jpg`} alt=""/> <Descripcion>Sonido inicial</Descripcion></FlexLink>
                    <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN8'}><ContainerImg src={`/img/portadas/LEN8.jpg`} alt=""/> <Descripcion>Cantidad de sonidos</Descripcion></FlexLink>
                </Container>
                <Container justify="space-around" margin="50px 0px 0px 0px">
                <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN10'}><ContainerImg src={`/img/portadas/LEN10.jpg`} alt=""/> <Descripcion>Robot - sonido a palabra</Descripcion></FlexLink>
                <FlexLink to={'/escritorio/metodos/lenguaje/conciencia-fonologica/cc-fonemica/LEN31'}><ContainerImg src={`/img/portadas/LEN31.jpg`} alt=""/> <Descripcion>Sonido dentro de palabra</Descripcion></FlexLink>
                </Container>
            </Container>
        </Fragment>    
    );
}
 
export default CCFonemica;