import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import PracticaMillones from '../PracticaMillones';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

import { Flex, FlexCuz, NumeroM32, RayitaM32, FlechaM32, NumberDivM32, NumbersM30, Btn } from '../../../../Css/StyledMatematica';

const instrucciones = 'A continuación se presentan números diferenciados por su valor posicional. El niño deberá leer en voz alta dichos números. Si necesita ayuda se puede hacer click sobre el recuadro donde presenta dificultad. Asimismo se puede hacer click sobre el ícono de ayuda.';
const miniInstrucciones = 'Lee los números.';

const M32 = () => {

    const dispatch = useDispatch();

    const [fuerzoInicio, setFuerzoInicio] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const contador = useSelector(state => state.ejercicio.contador);

    if(contador === 4){
        dispatch(setearEjercicioAction('M32Bis') );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('M32') );
        dispatch(setearUrlAnteriorAction('matematica/conteo/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === true){
            setFuerzoInicio(true);
        }
    }, [iniciar])

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar || fuerzoInicio
            ?
            <Flex direction="column" height="100%" width="100%">
                {/* ESTO LO AGREGO YO, CAMILA */}
                {contador === 0 &&
                <Flex direction='column' margin='15vh 0px 0px 0px'>
                    <NumeroM32>1.000.000</NumeroM32>
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom aumentar={true} />
                    </FlexCuz>
                </Flex>
                }
                {contador === 1 &&
                <Flex direction='column' margin='15vh 0px 0px 0px'>
                    <NumeroM32>1.000.000</NumeroM32>
                    <Flex>
                        <RayitaM32 m='-5vh 43px 0px 16vw' mm='-5vh 45px 0px 15vw' mt='-5vh 85px 0px 14vw' md='-5vh 85px 0px 14vw' mlg='-5vh 85px 0px 12vw'></RayitaM32>
                        <RayitaM32 m='-5vh 0px 0px 0vw'></RayitaM32>
                    </Flex>
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom reducir={true} aumentar={true} />
                    </FlexCuz>
                </Flex>
                }
                {contador === 2 &&
                <Flex direction='column' margin='15vh 0px 0px 0px'>
                    <NumeroM32>1.000.000</NumeroM32>
                    <Flex>
                        <RayitaM32 m='-5vh 43px 0px 16vw' mm='-5vh 45px 0px 15vw' mt='-5vh 85px 0px 14vw' md='-5vh 85px 0px 14vw' mlg='-5vh 85px 0px 12vw'></RayitaM32>
                        <RayitaM32 m='-5vh 0px 0px 0vw'></RayitaM32>
                    </Flex>
                    <FlechaM32 m='-3vh 0px 0px -65vw' mm='-2.5vh 0px 0px -61vw' mt='-3vh 0px 0px -54.5vw' md='-3vh 0px 0px -49vw' mlg='-2vh 0px 0px -42vw'></FlechaM32>
                    <NumberDivM32 m='0px 0px 0px -61vw' mm='-1.5vh 0px 0px -59vw' mt='-1.5vh 0px 0px -52vw' md='-1vh 0px 0px -49vw' mlg='-1vh 0px 0px -42vw'>MILLÓN</NumberDivM32>
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom reducir={true} aumentar={true} />
                    </FlexCuz>
                </Flex>
                }
                {contador === 3 &&
                <Flex direction='column' margin='15vh 0px 0px 0px'>
                    <NumeroM32>1.000.000</NumeroM32>
                    <Flex>
                        <RayitaM32 m='-5vh 43px 0px 16vw' mm='-5vh 45px 0px 15vw' mt='-5vh 85px 0px 14vw' md='-5vh 85px 0px 14vw' mlg='-5vh 85px 0px 12vw'></RayitaM32>
                        <RayitaM32 m='-5vh 0px 0px 0vw'></RayitaM32>
                    </Flex>
                    <Flex>
                        <FlechaM32 m='-3vh 40vw 0px -23.5vw' mm='-3vh 38.3vw 0px -23vw' mt='-5vh 34.4vw 0px -20vw' md='-5vh 30.7vw 0px -18vw' mlg='-5vh 26.8vw 0px -15.5vw'></FlechaM32>
                        <FlechaM32 m='-3vh 0px 0px 0vw' mt='-5vh 0vw 0px 0vw' md='-5vh 0vw 0px 0vw' mlg='-5vh 0vw 0px 0vw'></FlechaM32>
                    </Flex>
                    <Flex>
                        <NumberDivM32 m='0vh 17.5vw 0px -25vw' mm='-1vh 19vw 0px -23vw' mt='-1vh 23vw 0px -21vw' md='-1vh 23vw 0px -19vw' mlg='-1vh 19vw 0px -15vw'>MILLÓN</NumberDivM32>
                        <NumberDivM32 m='0vh 0px 0px 0vw'>MIL</NumberDivM32>
                    </Flex>
                    <FlexCuz position='fixed' right='0' bottom='0'>
                        <BotoneraBottom reducir={true} aumentar={true} />
                    </FlexCuz>
                </Flex>
                }
                {contador > 3 &&
                    <PracticaMillones ejercicio="M32Bis" />
                }
            </Flex>
            :
            <Instrucciones miniInstrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M32;