import {
    SOLICITO_NUEVO_PROFESIONAL,
    SOLICITO_NUEVO_PROFESIONAL_EXITO,
    SOLICITO_NUEVO_PROFESIONAL_ERROR
} from '../types';
import clienteAxios from '../config/axios';

/* Solicito datos de la DB */
export function solicitudNuevoProfesionalAction(informacion) {
    return async (dispatch) => {
        dispatch (solicitudNuevoProfesional());
        try {
            const {config, payload} = informacion;
            const {data} = await clienteAxios.post('/paciente/recibirsolicitudprofesional',payload, config);
            dispatch( solicitudNuevoProfesionalExito(data));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitudNuevoProfesionalError(error.response.data) );
            }
        }
    }
}

const solicitudNuevoProfesional = () => ({
    type: SOLICITO_NUEVO_PROFESIONAL,
    payload: true
});

// si logro obtener la informacion de la BD
const solicitudNuevoProfesionalExito = (data) => ({
    type: SOLICITO_NUEVO_PROFESIONAL_EXITO,
    payload: data
});

// si hubo un error
const solicitudNuevoProfesionalError = (msg) => ({
    type: SOLICITO_NUEVO_PROFESIONAL_ERROR,
    payload: msg
});