import {
    SOLICITO_INFORMACION_PACIENTE,
    SOLICITO_INFORMACION_PACIENTE_EXITO,
    SOLICITO_INFORMACION_PACIENTE_ERROR,
    ACTUALIZAR_INFORMACION_PACIENTE_EXITO,
    ACTUALIZAR_INFORMACION_PACIENTE_ERROR
} from '../types';
import clienteAxios from '../config/axios';

/* Solicito datos de la DB */
export function cargarPacientesAction(informacion) {
    return async (dispatch) => {
        dispatch (solicitoInformacion());
        try {
            const {config} = informacion;
            const respuesta = await clienteAxios.get('/paciente/buscarpacientes',config);
            dispatch( solicitoInformacionExito(respuesta.data.informacion));
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( solicitoInformacionError(error.response.data) );
            }
        }
    }
}

const solicitoInformacion = () => ({
    type: SOLICITO_INFORMACION_PACIENTE,
    payload: true
});

// si logro obtener la informacion de la BD
const solicitoInformacionExito = (data) => ({
    type: SOLICITO_INFORMACION_PACIENTE_EXITO,
    payload: data
});

// si hubo un error
const solicitoInformacionError = (msg) => ({
    type: SOLICITO_INFORMACION_PACIENTE_ERROR,
    payload: msg
});

export function actualizarPacientesAction(informacion) {
    return async (dispatch) => {
        try {
            dispatch( actualizoInformacionExito({lista_pacientes: informacion}));
        } catch (error) {
            dispatch( actualizoInformacionError('Hubo un error') );
        }
    }
}


const actualizoInformacionExito = (data) => ({
    type: ACTUALIZAR_INFORMACION_PACIENTE_EXITO,
    payload: data
});

// si hubo un error
const actualizoInformacionError = (msg) => ({
    type: ACTUALIZAR_INFORMACION_PACIENTE_ERROR,
    payload: msg
});