import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';
import useConfigAuth from '../../../Hooks/useConfigAuth';

import { borrarLetraAction } from '../../../actions/estadoEjercicioActions';
import { solicitoListaEjerciciosAction } from '../../../actions/solicitoInformacionEjercicioActions';

// css
import { Container, BtnGroup, BtnsLink } from '../../Css/MetodosCSS/StyledLectura';
import { ContainerFlex } from '../../Css/MetodosCSS/StyledLN2Todos';
import { HrMetodos } from '../../Css/StyledHeader';

const Escritura = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const resetLetra = () => {
        dispatch(borrarLetraAction());
    }

    useEffect(() => {
        const informacion = {
            config,
            payload: {
                
            }
        }
        dispatch(solicitoListaEjerciciosAction(informacion))
    }, [])

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} componente={"ESCRITURA"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Container width="100%" height="100%">
                <Container display="flex" justify="center" direction="column" align="center" margin="auto">
                    <ContainerFlex display="flex" align="center">
                        <BtnsLink margin="1rem" padding="40px 40px" font="1.3rem" to={'/escritorio/metodos/lecto-escritura/escritura/silaba-directa'} onClick={(e) => resetLetra(e)}>Sílaba Directa </BtnsLink>
                        <BtnsLink margin="1rem" padding="40px 40px" font="1.3rem" to={'/escritorio/metodos/lecto-escritura/escritura/sinfones'}>Sinfones</BtnsLink>
                        <BtnsLink margin="1rem" padding="40px 40px" font="1.3rem" to={'/escritorio/metodos/lecto-escritura/escritura/ortografia'}>Ortografía</BtnsLink>
                    </ContainerFlex>

                    <ContainerFlex display="flex" align="center">
                        <BtnsLink margin="1rem" padding="40px 40px" font="1.3rem" to={'/escritorio/metodos/lecto-escritura/escritura/oraciones'}>Oraciones</BtnsLink>
                        <BtnsLink margin="1rem" padding="40px 40px" font="1.3rem" to={'/escritorio/metodos/lecto-escritura/escritura/cuentos'}>Cuentos</BtnsLink>
                        <BtnsLink margin="1rem" padding="40px 40px" font="1.3rem" to={`/escritorio/metodos/lecto-escritura/escritura/descripciones/ESC13`}>Descripciones</BtnsLink>
                    </ContainerFlex>

                    <ContainerFlex display="flex" align="center">
                        <BtnsLink margin="1rem" padding="40px 40px" font="1.3rem" to={'/escritorio/metodos/lecto-escritura/escritura/transicion-de-letras'}>Transicion De Letras</BtnsLink>
                    </ContainerFlex>
                </Container>
            </Container>
        </Fragment>
    );
}

export default Escritura;