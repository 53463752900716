import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LN3Todos from '../../../Compartidos/LN3Todos';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Selecciona la sílaba que escuchas.';
const miniInstrucciones = 'Selecciona la sílaba que escuchas.';

const LN3 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN3') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/instruccion-explicita/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[0]));
        if(newList.muestro_letras?.toString() === '5') {
            const nuevaLista = [
                {id:'1', letra:newList.letra_1, silaba:newList.silaba_1, segunda_letra:newList.letra_6, sonido:newList.sonido_url_1, myStyle:{color:'#23AAAF'}},
                {id:'2', letra:newList.letra_2, silaba:newList.silaba_2, segunda_letra:newList.letra_7, sonido:newList.sonido_url_2, myStyle:{color:'#FDB62C'}},
                {id:'3', letra:newList.letra_3, silaba:newList.silaba_3, segunda_letra:newList.letra_8, sonido:newList.sonido_url_3, myStyle:{color:'#FED67F'}},
                {id:'4', letra:newList.letra_4, silaba:newList.silaba_4, segunda_letra:newList.letra_9, sonido:newList.sonido_url_4, myStyle:{color:'#655EE8'}},
                {id:'5', letra:newList.letra_5, silaba:newList.silaba_5, segunda_letra:newList.letra_10, sonido:newList.sonido_url_5, myStyle:{color:'#D76970'}}
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else if (newList.muestro_letras?.toString() === '4'){
            const nuevaLista = [
                {id:'1', letra:newList.letra_1, silaba:newList.silaba_1, segunda_letra:newList.letra_6, sonido:newList.sonido_url_1, myStyle:{color:'#FDB62C'}},
                {id:'2', letra:newList.letra_2, silaba:newList.silaba_2, segunda_letra:newList.letra_7, sonido:newList.sonido_url_2, myStyle:{color:'#FED67F'}},
                {id:'3', letra:newList.letra_3, silaba:newList.silaba_3, segunda_letra:newList.letra_8, sonido:newList.sonido_url_3, myStyle:{color:'#FDB62C'}},
                {id:'4', letra:newList.letra_4, silaba:newList.silaba_4, segunda_letra:newList.letra_9, sonido:newList.sonido_url_4, myStyle:{color:'#FED67F'}},
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else if (newList.muestro_letras?.toString() === '3'){
            const nuevaLista = [
                {id:'1', letra:newList.letra_1, silaba:newList.silaba_1, segunda_letra:newList.letra_6, sonido:newList.sonido_url_1, myStyle:{color:'#FDB62C'}},
                {id:'2', letra:newList.letra_2, silaba:newList.silaba_2, segunda_letra:newList.letra_7, sonido:newList.sonido_url_2, myStyle:{color:'#FED67F'}},
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        } else {
            const nuevaLista = [
                {id:'1', letra:newList.letra_1, silaba:newList.silaba_1, segunda_letra:newList.letra_6, sonido:newList.sonido_url_1, myStyle:{color:'#FDB62C'}},
                {id:'2', letra:newList.letra_2, silaba:newList.silaba_2, segunda_letra:newList.letra_7, sonido:newList.sonido_url_2, myStyle:{color:'#FED67F'}},
            ]
            setData(nuevaLista);
            setIndexes(Object.keys(nuevaLista).length);
        }
        
    }, [informacion])

    return (
        <Fragment>
            <LN3Todos data={data} indexes={indexes} ejercicio="LN3" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default LN3;