import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, DivFlex, DivNoFlex, ImgB10, InputM49, ButtonM49, AvanzarImg, AvanzarBtn, TextM49 } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Selecciona los cuadraditos para cada número utilizando el color correspondiente y resuelve la suma.';
const miniInstrucciones = 'Selecciona los cuadraditos para cada número utilizando el color correspondiente y resuelve la suma.';

const M49 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState('');
    const [cuadrados, setCuadrados] = useState([]);
    const [coloresCuadrados, setColoresCuadrados] = useState([]);
    const [red, setRed] = useState(0);
    const [blue, setBlue] = useState(0);
    const [orange, setOrange] = useState(0);
    const [green, setGreen] = useState(0);
    const [yellow, setYellow] = useState(0);
    const [compararRed1, setCompararRed1] = useState(0);
    const [compararBlue1, setCompararBlue1] = useState(0);
    const [compararOrange1, setCompararOrange1] = useState(0);
    const [compararGreen1, setCompararGreen1] = useState(0);
    const [compararYellow1, setCompararYellow1] = useState(0);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const setearCuadrado = (e, color) => {
        e.preventDefault();
        if(cuadrados.length === 10) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(cuadrados));
        newList.push(color);
        setCuadrados(newList);
    }

    const borrar = (e) => {
        e.preventDefault();
        if(cuadrados.length === 0) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(cuadrados));
        newList.pop();
        setCuadrados(newList);
    }

    const verificar = (e) => {
        e.preventDefault();
        if((comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numero.toString() || compararRed1 !== red || compararBlue1 !== blue || compararOrange1 !== orange || compararGreen1 !== green || compararYellow1 !== yellow ) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if((comparar[count-1].cantidad_1 + comparar[count-1].cantidad_2).toString() !== numero.toString()){
                    setNumero(0);
                }
                if(compararRed1 !== red){ setCuadrados([]); }
                if(compararBlue1 !== blue){ setCuadrados([]); }
                if(compararOrange1 !== orange){ setCuadrados([]); }
                if(compararGreen1 !== green){ setCuadrados([]); }
                if(compararYellow1 !== yellow){ setCuadrados([]); }

            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setCuadrados([]);
                    setRed(0);
                    setBlue(0);
                    setOrange(0);
                    setGreen(0);
                    setYellow(0);
                    setCompararRed1(0);
                    setCompararBlue1(0);
                    setCompararOrange1(0);
                    setCompararGreen1(0);
                    setCompararYellow1(0);
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setCuadrados([]);
                    setRed(0);
                    setBlue(0);
                    setOrange(0);
                    setGreen(0);
                    setYellow(0);
                    setCompararRed1(0);
                    setCompararBlue1(0);
                    setCompararOrange1(0);
                    setCompararGreen1(0);
                    setCompararYellow1(0);                  
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const min = 1;
        const max = 5;
        const listaUno = [];
        const listaDos = [];
        const randomLista = (min,max,lista) => {
            for(let i = 0; i < 5; i++){
                let randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                lista.push(randomNumero);
            }
            return lista
        }
        const randomListaUno = randomLista(min,max,listaUno);
        const randomListaDos = randomLista(min,max,listaDos);

        const array = ['rosa', 'azul', 'naranja', 'verde', 'amarillo'];
        const arrayEnglish = ['red', 'blue', 'orange', 'green', 'yellow'];

        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
            
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = 
                [array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const shuffleColor = shuffle(arrayEnglish);

        let arrayColorNumero = []
        for(let i = 0; i<5; i++){
            switch (shuffleColor[i]) {
                case 'red':
                    arrayColorNumero.push('#D33B52');
                    break;
                case 'blue':
                    arrayColorNumero.push('#1177AB');
                    break;
                case 'orange':
                    arrayColorNumero.push('#E7973C');
                    break;
                case 'green':
                    arrayColorNumero.push('#76C06A');
                    break;
                case 'yellow':
                    arrayColorNumero.push('#EFC340');
                    break;
                default:
                    break;
            }
        }

        const modelo = [
            {id:'1', cantidad_1:randomListaUno[0], cantidad_2:randomListaDos[0], color_1:shuffleColor[0], color_2:shuffleColor[3], color_numero_1:arrayColorNumero[0], color_numero_2:arrayColorNumero[3]},
            {id:'2', cantidad_1:randomListaUno[1], cantidad_2:randomListaDos[1], color_1:shuffleColor[1], color_2:shuffleColor[4], color_numero_1:arrayColorNumero[1], color_numero_2:arrayColorNumero[4]},
            {id:'3', cantidad_1:randomListaUno[2], cantidad_2:randomListaDos[2], color_1:shuffleColor[2], color_2:shuffleColor[0], color_numero_1:arrayColorNumero[2], color_numero_2:arrayColorNumero[0]},
            {id:'4', cantidad_1:randomListaUno[3], cantidad_2:randomListaDos[3], color_1:shuffleColor[3], color_2:shuffleColor[1], color_numero_1:arrayColorNumero[3], color_numero_2:arrayColorNumero[1]},
            {id:'5', cantidad_1:randomListaUno[4], cantidad_2:randomListaDos[4], color_1:shuffleColor[4], color_2:shuffleColor[2], color_numero_1:arrayColorNumero[4], color_numero_2:arrayColorNumero[2]}
        ]
        setComparar(modelo);
        setColoresCuadrados(array);
        setLanzoEffect(true);
        setIndexes(modelo.length);
        setTipoEjercicio('unidad');
    }, [])

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));

        switch (newList[count-1].color_1) {
            case 'red':
                setCompararRed1(newList[count-1].cantidad_1)
                break;
            case 'blue':
                setCompararBlue1(newList[count-1].cantidad_1)
                break;
            case 'orange':
                setCompararOrange1(newList[count-1].cantidad_1)
                break;
            case 'green':
                setCompararGreen1(newList[count-1].cantidad_1)
                break;
            case 'yellow':
                setCompararYellow1(newList[count-1].cantidad_1)
                break;
            default:
                break;
        }
        switch (newList[count-1].color_2) {
            case 'red':
                setCompararRed1(newList[count-1].cantidad_2)
                break;
            case 'blue':
                setCompararBlue1(newList[count-1].cantidad_2)
                break;
            case 'orange':
                setCompararOrange1(newList[count-1].cantidad_2)
                break;
            case 'green':
                setCompararGreen1(newList[count-1].cantidad_2)
                break;
            case 'yellow':
                setCompararYellow1(newList[count-1].cantidad_2)
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect])

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(cuadrados));
        const counts = {};
        newList.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        const cantRed = counts.rosa;
        const cantBlue = counts.azul;
        const cantOrange = counts.naranja;
        const cantGreen = counts.verde;
        const cantYellow = counts.amarillo;
        if(cantRed === undefined){ setRed(0);} else { setRed(cantRed); }
        if(cantBlue === undefined){ setBlue(0);} else { setBlue(cantBlue); }
        if(cantOrange === undefined){ setOrange(0);} else { setOrange(cantOrange); }
        if(cantGreen === undefined){ setGreen(0);} else { setGreen(cantGreen); }
        if(cantYellow === undefined){ setYellow(0);} else { setYellow(cantYellow); }

    }, [cuadrados])

    useEffect(() => {
        dispatch(setearEjercicioAction('M49') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/suma/ejercitacion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M49",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <FlexFull direction='column' justify='space-evenly' align='center' height='95%'>
                            <Flex>
                            {/* IMGS */}
                                <DivFlex color='#FCFBFB' flex='flex' wrap='wrap' justify='start' align='start' minw='53vw' maxw='53vw' minwm='52vw' maxwm='52vw' minwt='43vw' maxwt='43vw' minwd='40vw' maxwd='40vw' minwlg='34vw' maxwlg='34vw' minh='22vw' maxh='22vw' minht='19vw' maxht='19vw' minhd='16vw' maxhd='16vw' minhlg='14vw' maxhlg='14vw' padding='1.5vh 0px 0px 2vw' bradius='20px' bs='0px 4px 4px rgba(0, 0, 0, 0.25)'>
                                    {cuadrados.map((item, i) =>
                                    <div key={i}>
                                        {/* <ImgB10 key={i} src={`/img/BASE10centena.png`} w='9vw' wt='7.5vw' wd='6.5vw' wlg='5.6vw' m='0.3vw' /> */}
                                        <ImgB10 key={i} src={`/img/bloque${item}.png`} w='9vw' wt='7.5vw' wd='6.5vw' wlg='5.6vw' m='0.3vw' style={{zIndex: 0}} />
                                        {/* {item} */}
                                    </div>    
                                    )}
                                </DivFlex>
                            {/* FIN IMGS */}
                                <ButtonM49
                                    disabled={disable}
                                    onClick={(e) => borrar(e)}
                                >
                                    <ImgB10 src={`/img/icono_basurero.png`} w='4vw' m='0.3vw' /> 
                                </ButtonM49>
                            </Flex>
                            {/* SUMA */}
                            <Flex margin="1rem 0 0 0">
                                <TextM49 style={{color:`${comparar[count-1].color_numero_1}`}}>{comparar[count-1].cantidad_1}</TextM49><TextM49>+</TextM49><TextM49 style={{color:`${comparar[count-1].color_numero_2}`}}>{comparar[count-1].cantidad_2}</TextM49><TextM49>=</TextM49>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <InputM49 type="numeric" min="1" max="10" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                                </form>
                            </Flex>
                            {/* FIN SUMA */}
                        </FlexFull>
                        {/* SIDEBAR */}
                        <DivNoFlex flex='flex'  bs='0px 4px 4px rgba(0, 0, 0, 0.25)' bradius='12px'> 
                            <DivFlex flex='flex' direction='column' padding='1.5vh' width='fit-content' bradius='16px'>
                                {coloresCuadrados.map((item, i) => 
                                    <ButtonM49 m= '2px 0px -5px 0px' mlg='2px 0px -4vh 0px'
                                        key={i}
                                        onClick={(e) => setearCuadrado(e, item)}
                                    >
                                        <ImgB10 key={i} src={`/img/bloque${item}.png`} w='8vw' wm='7vw' wt='5.5vw' wd='4.8vw' wlg='3.5vw' m='0vh 2vw 0vh -2vw' mm='0vh 2vw 0vh -1.5vw' mt='0vh 1vw -0.8vh -0.4vw' md='0vh 1vw -0.8vh 0vw' mlg='0vh 1vw 2vh 0.5vw' style={{zIndex: 0}} />
                                    </ButtonM49> 
                                )}
                            </DivFlex>
                        </DivNoFlex>
                        {/* FIN SIDEBAR */}
                    </Flex>

                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>

                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M49;