import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import date from 'date-and-time';

import { actividadesAgendadasSolicitarAction, actividadesAgendarAction, actualizarActividadesAction, actualizarInfoActividadesAction } from '../../actions/actividadesActions';
import useConfigAuth from '../../Hooks/useConfigAuth';

//css
import {FlexBox,FlexColumn} from '../Css/StyledHeader'
import { Fondo, Titulos, Ul, Input, Li, P, BotonNaranja, Boton, Linea} from '../Css/StyledActivities'

const SeleccionarActividadesSeleccionadas = () => {
    
    const dispatch = useDispatch();

    const [config] = useConfigAuth();

    const infoActividades = useSelector((state) => state.informacion.info_actividades);
    const {actividades} = infoActividades;
    const seleccionadas = useSelector((state) => state.actividades.seleccionadas);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const correcto = useSelector((state) => state.estado.correcto);

    const [tiempoSesion, setTiempoSesion] = useState('0hs 0min');
    const [fechaSesion, setFechaSesion] = useState('');
    const [actividadesSeleccionadas, setActividadesSeleccionadas] = useState(seleccionadas);

    const actualizarContador = (e, id, tipo) => {
        e.preventDefault();
        const actualizoInfoActividades = JSON.parse(JSON.stringify(infoActividades));
        let iActividades = actualizoInfoActividades.actividades.findIndex(item => {
            return item.id === id;
        })
        if(tipo === "disminuir"){
            if(actualizoInfoActividades.actividades[iActividades].contador === 0){
                return;
            } else {
                actualizoInfoActividades.actividades[iActividades].contador--;
            }
        } else {
            actualizoInfoActividades.actividades[iActividades].contador++;
        }
        dispatch( actualizarInfoActividadesAction(actualizoInfoActividades) );
    }

    const eliminarActividad = (e, id) => {
        e.preventDefault();
        const actualizoInfoActividades = JSON.parse(JSON.stringify(infoActividades));
        const actividadSeleccionada = JSON.parse(JSON.stringify(seleccionadas));
        let iActividades = actualizoInfoActividades.actividades.findIndex(item => {
            return item.id === id;
        })
        actualizoInfoActividades.actividades[iActividades].seleccionado = false;
        actualizoInfoActividades.actividades[iActividades].contador = 1;

        let numero = actualizoInfoActividades.actividades[iActividades].ultimo;
        for(let obj of actualizoInfoActividades[numero]){
            if(obj.id === id){
                obj.color = false;
            }
        }

        let index = actividadSeleccionada.indexOf(id);
        actividadSeleccionada.splice(index,1);

        dispatch( actualizarActividadesAction(actividadSeleccionada) );
        dispatch( actualizarInfoActividadesAction(actualizoInfoActividades) );
        setActividadesSeleccionadas(actividadSeleccionada);
    }

    const modificoOrden = (e, i, movimiento) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(actividadesSeleccionadas));
        if(movimiento === 'disminuir') {
            let item = newList.splice(i, 1)[0];
            newList.splice(i-1, 0, item);
            setActividadesSeleccionadas(newList);
        } else {
            let item = newList.splice(i, 1)[0];
            newList.splice(i+1, 0, item);
            setActividadesSeleccionadas(newList);
        }
    }

    const actividadesAgendar = (e) => {
        e.preventDefault();
        const actualizoInfoActividades = JSON.parse(JSON.stringify(infoActividades));
        const agendar = JSON.parse(JSON.stringify(infoActividades)).actividades.filter(obj => obj.seleccionado === true && obj.contador > 0);
        const fechaHoy = new Date();
        const hora = fechaHoy.getHours();
        const minutos = fechaHoy.getMinutes() + 1;
        const formatFechaSesion = `${fechaSesion} ${hora}:${minutos}`
        const fechaElegida = new Date(formatFechaSesion);
        const fecha_agendada = date.format(fechaElegida, 'YYYY-MM-DD');


        if(agendar.length === 0 || fechaSesion === '' || fechaElegida < fechaHoy){
            return;
        }

        
        const payload = [];
        for(let i = 0; i < agendar.length; i++){
            let orden = actividadesSeleccionadas.indexOf(agendar[i].id)+1
            const obj = {
                'ejercicio_id': agendar[i].id,
                'nombre_mostrar': agendar[i].nombre_mostrar,
                'cantidad': agendar[i].contador,
                'fecha_agendada': fecha_agendada,
                'url':agendar[i].url,
                'lista': agendar[i].lista,
                'letra': agendar[i].letra,
                id_paciente,
                'orden': orden
            }
            payload.push(obj);
        }

        const informacion = {
            config,
            payload
        }

        for(let i = 0; i < seleccionadas.length; i++){
            let iActividades = actualizoInfoActividades.actividades.findIndex(item => {
                return item.id === seleccionadas[i];
            })
            actualizoInfoActividades.actividades[iActividades].seleccionado = false;
            actualizoInfoActividades.actividades[iActividades].contador = 1;

            let numero = actualizoInfoActividades.actividades[iActividades].ultimo;
            let iNumero = actualizoInfoActividades[numero].findIndex(item => {
                return item.id === seleccionadas[i];
            })
            actualizoInfoActividades[numero][iNumero].color = false;
        }
        let actividadSeleccionada = [];
        dispatch( actualizarActividadesAction(actividadSeleccionada) );
        dispatch( actualizarInfoActividadesAction(actualizoInfoActividades) );
        dispatch( actividadesAgendarAction(informacion));
        setActividadesSeleccionadas([]);
        setFechaSesion('');
    }

    useEffect(() => {
        const seleccionados = actividades.filter(obj => obj.seleccionado === true);
        let tiempo = 0;
        for(let i = 0; i < seleccionados.length; i++){
            tiempo+=(seleccionados[i].tiempo)*seleccionados[i].contador;
        }
        let minutos = tiempo/60;
        let minutofinal = Math.floor(minutos);
        let segundos = (minutos - minutofinal)*60;
        let segundosFinal = Math.round(segundos);

        setTiempoSesion(`${minutofinal} min ${segundosFinal} seg`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoActividades])

    useEffect(() => {
        if(correcto){
            const informacion = {
                config,
                payload: {
                    id_paciente
                }
            };
            dispatch( actividadesAgendadasSolicitarAction(informacion) );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correcto])
    
    

    return (
        <Fragment>
            <div>
                <Fondo width="100%" height="100%" margin="1.5rem 0 0 1rem">
                    <Titulos margin="1rem 0 1rem 1.5rem">Actividades Seleccionadas</Titulos>
                    <Linea/>
                    <Ul>
                        <FlexColumn justify="space-between">
                        {actividadesSeleccionadas.map((ejercicio, index) =>
                            <div key={index}>
                                {actividades.filter(actividad => actividad.id == ejercicio).map(actividad => (
                                    <Li key={actividad.id}>
                                        {actividad.ultimo === 'cinco' ? <p>{actividad.uno} - {actividad.dos} - {actividad.tres} - {actividad.cuatro} - {actividad.cinco}</p> : null}
                                        {actividad.ultimo === 'cuatro' ? <p>{actividad.uno} - {actividad.dos} - {actividad.tres} - {actividad.cuatro}</p> : null}
                                        {actividad.ultimo === 'tres' ? <p>{actividad.uno} - {actividad.dos} - {actividad.tres}</p> : null}
                                        {actividad.ultimo === 'dos' ? <p>{actividad.uno} - {actividad.dos}</p> : null}
                                        {actividad.ultimo === 'uno' ? <p>{actividad.uno}</p> : null}
                                        <button
                                            onClick={(e) => eliminarActividad(e, actividad.id)}
                                        >
                                            Eliminar
                                        </button>
                                        <FlexBox>
                                            <Boton
                                            onClick={(e) => actualizarContador(e, actividad.id, 'disminuir')}
                                            >-</Boton>
                                            <P border="1px solid" padding="0px 6px"  height="20px">{actividad.contador}</P>
                                            <Boton
                                            onClick={(e) => actualizarContador(e, actividad.id, 'aumentar')}
                                            >+</Boton>
                                        </FlexBox>
                                        <FlexBox>
                                            {index == actividadesSeleccionadas.length - 1 ? null :
                                            <Boton
                                            onClick={(e) => modificoOrden(e, index, 'aumentar')}
                                            >Subir posición</Boton>
                                            }
                                            {index == '0'? null :
                                            <Boton
                                            onClick={(e) => modificoOrden(e, index, 'disminuir')}
                                            >Bajar Posición</Boton>
                                            }
                                        </FlexBox>
                                    </Li>
                                ))}
                            </div>
                        )}
                        </FlexColumn>
                    </Ul>
                </Fondo>
                <Fondo width="100%" height="100%">
                    <FlexBox justify="space-between" align="center" margin="1rem">
                        <FlexBox>
                            <P>Agendar sesión para la fecha:</P>
                            <Input margin="0px 0px 0px 10px"
                                type="date"
                                name="fechaSesion"
                                value={fechaSesion}
                                onChange={e => setFechaSesion(e.target.value)}
                            />
                        </FlexBox>
                        <FlexBox>
                            <P margin="0px 1vw 0px 0px">Cantidad de ejercicios:</P>
                            <P margin="-12px 3vw 0px 0px" background="#F2F2F2" padding="10px 10px">{actividades.filter(actividad => actividad.seleccionado === true).length}</P>
                        </FlexBox> 
                        <P  margin="0px 1vw 0px 0px">Tiempo de sesión</P>
                        <P margin="-12px 3vw 0px 0px" background="#F2F2F2" padding="10px 10px">{tiempoSesion}</P>
                        <FlexBox justify="space-between">
                            <BotonNaranja padding="10px 15px"
                                onClick={(e) => actividadesAgendar(e)}
                            >
                                    Agendar
                            </BotonNaranja>
                        </FlexBox> 
                    </FlexBox>   
                </Fondo>
            </div>
        </Fragment>
    );
}
 
export default SeleccionarActividadesSeleccionadas;