import styled, {css, keyframes} from 'styled-components';
import {createGlobalStyle} from 'styled-components';

const media = {
    tablet: '@media (max-width:768px)'
}
export default createGlobalStyle`
*{@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

}
`

// PALABRAS
export const BtnCeleste = styled.div`
    background:#23AAAF;
    width: 20vw;
    height: 60px;
    border: 0;
    border-radius: 10px;
    color: white;
    font-size: 30px;
    font-family: nunito;
    margin: 40px 100px 40px 0px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: ${props => props.margin};
    display: flez; 
    justify-content: center;
    align-items: center;
    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `}
`
export const LetraXL = styled.p`
    color: rgba(231, 151, 60, 1);
    font-size: 13rem;
    font-family: nunito;
    font-weight: 800;
    margin: 0;
    display: flex; 
    justify-content: center;
    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `}
    &:focus{
        background: #FCF7BC;
    }
`
export const Pregunta = styled.div`
    justify-self: ${props => props.justifyself};
    text-transform: uppercase;
    font-family:nunito;
    font-weight: 300;
`
export const PalabraXL = styled.p`
    font-size: 35px;
    ${media.tablet}{
        font-size: 24px;
}
`
// CONTENEDORES
export const Flex = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center; 
    justify-content: ${props => props.justify};
    justify-self: ${props => props.justifyself};
    align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    height: ${props => props.height};
    width: ${props => props.width};
`
export const Grid = styled.div`
    display: grid;
    grid-template-columns: 20% 60% 20%;
    justify-content: center; 
    align-items: center; 
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    flex-direction: ${props => props.direction};
    margin: ${props => props.margin};
    height: ${props => props.height};
    width: ${props => props.width};
`

export const ContInst = styled.div`
    position: absolute;
    bottom: 40px; 
    left: 25px; 
    text-transform: uppercase; 
    font-family: nunito;
    font-weight: 400;
    font-size: 16px; 
`
export const Colores = styled.div`
    width: 5vw;
    height: 5vw;
    border-radius: 100px;
    background: ${props => props.bgcolor};
    color: rgba(0,0,0,0);
    &:hover{
        border: solid 3px rgb(181,211,230);
    }
`
export const YellowBorder = styled.div`
    border: solid 5px #FCF7BC;
    font-family: nunito;
    text-transform: uppercase;
    font-size: 18px;
    padding: 20px 40px ;
    margin: 0;
    padding: ${props => props.padding};
    border-radius: ${props => props.radius};
    
`

// BOTONES
export const Btn = styled.button`
    border: 0;
    background: transparent; 
    padding: ${props => props.padding};
    margin: ${props => props.margin};
`
export const YellowBtn = styled (Btn)`
    ${({isCorrect}) => isCorrect && 
    css`
    animation-name ${ZoomIn};
    animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
    css`
    animation-name ${ShakeImg};
    animation-duration: 300ms;
    `}
    background: ${props => props.background ? `#23AAAF` : `white`};
`
// IMAGENES
export const ImgBottom = styled.img`
    &:hover{
        transform: scale(1.1);
    }
`

export const Icon = styled.img`
    position: absolute;
    right: ${props => props.right};
    bottom: ${props => props.bottom};
    
`
export const Ilustracion = styled.img`
    width: ${props => props.width};
    margin: ${props => props.margin};
    cursor: ${props => props.cursor};
    ${({isCorrect}) => isCorrect && 
        css`
        animation-name ${ZoomIn};
        animation-duration: 300ms;
    `}
    ${({isIncorrect}) => isIncorrect && 
        css`
        animation-name ${ShakeImg};
        animation-duration: 300ms;
    `}
`
// ANIMACIONES
export const ShakeImg = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`
export const ZoomIn = keyframes`
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
`
// FORM
export const Input = styled.input`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    border-radius: 10px;
    width: 35vw;
    height: 60px;
    border:0;
    background: #F1F0F0;
    text-align:center;
    font-size:18px;
`