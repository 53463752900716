import {
    ALMACENAR_PROFESION    
} from '../types';

// Mando al store los datos de residencia
export function almacenarProfesionAction(informacion) {
    return async (dispatch) => {
        dispatch( almacenarProfesion(informacion) );
    }
}

const almacenarProfesion = (informacion) => ({
    type: ALMACENAR_PROFESION,
    payload: informacion
});
