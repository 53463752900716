import React, {Fragment} from 'react';

import HeaderUno from '../Compartidos/HeaderUno';
import HeaderMetodos from '../../AuxiliaresPaginas/HeaderMetodos';

import { LinksVarios, Flex } from '../../Css/StyledMatematica';
import { HrMetodos } from '../../Css/StyledHeader';

const LecturaYEscrituraDeNumeros = () => {
    

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"matematica/"} n1={"Métodos"} n2={"MATEMATICA"} componente={"LECTURA Y ESCRITURA DE NUMEROS"} paciente={true} bgcolor="#23AAAF" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <Flex>
                <LinksVarios bgcolor='#23AAAF' color='#FFFFFF' fw='600' to={'/escritorio/metodos/matematica/lectura-y-escritura-de-numeros/lectura'}>Lectura</LinksVarios>
                <LinksVarios bgcolor='#23AAAF' color='#FFFFFF' fw='600' to={'/escritorio/metodos/matematica/lectura-y-escritura-de-numeros/escritura'}>Escritura</LinksVarios>
            </Flex>
        </Fragment>
    );
}
 
export default LecturaYEscrituraDeNumeros;