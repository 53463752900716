import {
    SETEAR_MINUSCULA,
    SETEAR_AYUDA_VISUAL,
    SETEAR_AYUDA_LECTURA,
    SETEAR_AYUDA_IMAGEN,
    RESETEAR_AYUDA_LECTURA,
    RESETEAR_AYUDA_VISUAL,
    CERRAR_SESION
} from '../types';


const initialState = {
    minuscula: null,
    ayuda_visual: false,
    ayuda_lectura: false,
    ayuda_imagen: false,
    palabra: null,
    resetAyuda: false
}
export default function configuracionReducer (state = initialState, action) {
    switch(action.type) {
        case CERRAR_SESION:
            return {
                minuscula: null,
                ayuda_visual: false,
                ayuda_lectura: false,
                ayuda_imagen: false,
                palabra: null,
                resetAyuda: false
            }
        case SETEAR_MINUSCULA:
            return {
                ...state,
                minuscula: action.payload
            }
        case SETEAR_AYUDA_VISUAL:
            return {
                ...state,
                ayuda_visual: action.payload
            }
        case SETEAR_AYUDA_LECTURA:
            return {
                ...state,
                ayuda_lectura: action.payload.ayudaLectura,
                palabra: action.payload.palabra
            }
        case SETEAR_AYUDA_IMAGEN:
            return {
                ...state,
                ayuda_imagen: action.payload
            }
        case RESETEAR_AYUDA_VISUAL:
            return {
                ...state,
                ayuda_visual: action.payload,
                resetAyuda: true
            }
        case RESETEAR_AYUDA_LECTURA:
            return {
                ...state,
                ayuda_lectura: action.payload,
                palabra: null,
                resetAyuda: false
            }
        default:
            return state;
    }
}