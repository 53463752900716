import React, { Fragment } from 'react';

import HeaderUno from './Compartidos/HeaderUno';
import HeaderMetodos from '../AuxiliaresPaginas/HeaderMetodos';

// css
import {BackgroundLecto, BtnLink, BtnLecto, Container } from '../Css/MetodosCSS/StyledMethods'
import { HrMetodos } from '../Css/StyledHeader';


const LectoEscritura = () => {

    const isActive = false;

    return ( 
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} n1={"Métodos"} componente={"LECTURA Y ESCRITURA"} paciente={true} fw='600' bgcolor="#D05F67" color="white" width='96vw' />
            <BackgroundLecto>
                <Container justify="space-around">
                    <BtnLecto >
                        <BtnLink to={'/escritorio/metodos/lecto-escritura/letras'} color="black">Letras</BtnLink>
                    </BtnLecto>
                    <BtnLecto>
                        <BtnLink to={'/escritorio/metodos/lecto-escritura/lectura'} color="black">Lectura</BtnLink>
                    </BtnLecto>
                    <BtnLecto>
                        <BtnLink to={'/escritorio/metodos/lecto-escritura/escritura'} color="black">Escritura</BtnLink>
                    </BtnLecto>
                </Container>
                <Container justify="space-around" >
                    <BtnLecto style={{backgroundColor: "grey"}}>
                        <BtnLink to={isActive ? '/escritorio/metodos/lecto-escritura' : '#'} color="black">Comprensión</BtnLink>
                    </BtnLecto>
                </Container>
            </BackgroundLecto>
        </Fragment>
    );
}
 
export default LectoEscritura;