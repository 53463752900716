import React from 'react';
import { useSelector } from 'react-redux';

import PerfilNavbar from './PerfilNavbar';
// css
import {LogoHeader, Flex, Contacto, GlobalStyle, FlexBox} from '../Css/StyledHeader.js'

const Header = () => {

    const rol = useSelector((state) => state.usuario.rol);

    return ( 
        <Flex>
            <LogoHeader src={`/img/logogris.png`} alt="Logo" height="100%" />
            <FlexBox align="center" justify="center">
                {rol !== '894616'
                ? 
                    <Contacto to={"/escritorio/contacto"}>Contacto</Contacto>
                : null
                } 
                <PerfilNavbar />
            </FlexBox>
        </Flex>
    );
}
 
export default Header;