import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clienteAxios from '../../config/axios';

import useConfigAuth from '../../Hooks/useConfigAuth';

import { setearAlerta } from '../../actions/alertaActions';

import { Flex, Input, BotonNaranjaButton, Div} from '../Css/StyledPatient';

const CargaSuscriptor = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [duracion, setDuracion] = useState('');
    const [idProfesionalActivo, setIDProfesionalActivo] = useState('');
    const [cantidad, setCantidad] = useState('');

    const cargarActivacion = async (e) => {
        e.preventDefault();
        if(!duracion || !idProfesionalActivo || duracion === "Seleccione duración" || !cantidad ) {
            const alerta = {
                msg: 'Faltan completar datos'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        const payload = {
            idProfesionalActivo,
            duracion,
            cantidad
        }
        setDisable(true);
        try {
            const {data} = await clienteAxios.post('/usuarios/activometodo', payload, config);
            setDuracion('');
            setIDProfesionalActivo('');
            setCantidad('');
            const alerta = {
                msg: data.msg
            }
            dispatch( setearAlerta (alerta) );
            setDisable(false);
        } catch (error) {
            console.log(error.response)
            setDisable(false);
        }
    }

    return (
        <Flex direccion="column" margin="3rem 0 0rem 0">
            <form
                onSubmit={(e) => cargarActivacion(e)}
            >
                <label style={{width: "5rem"}}htmlFor="text">ID PROF</label>
                <Input width="6rem" radius="10px"
                    type="text"
                    value={idProfesionalActivo || ""}
                    onChange={e => setIDProfesionalActivo(e.target.value)}
                />
                <select value={duracion} onChange={e => setDuracion(e.target.value)}>
                    <option>Seleccione duración</option>
                    <option>Mes</option>
                    <option>Fin de año</option>
                    <option>Fin año que viene</option>
                </select>
                <label style={{width: "5rem"}}htmlFor="text">Cantidad</label>
                <select value={cantidad} onChange={e => setCantidad(e.target.value)}>
                    <option>Seleccione Cantidad</option>
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                </select>
                <Flex>
                    <BotonNaranjaButton disabled={disable} type="submit" margin="10px">Cargar</BotonNaranjaButton>
                </Flex>
            </form>
        </Flex>
    );
}
 
export default CargaSuscriptor;