import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, AvanzarImg, NumSumaM73, PRectaM67, InputM73, BtnRectaM67, AvanzarBtn, ImgB10, ButtonM44, BotonRetrocede } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta haciendo los saltos en la recta numérica.';
const miniInstrucciones = 'Resuelve la resta.';

const M67 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]); 
    const [numeroRegla, setNumeroRegla] = useState([]);
    const [compararFlechas, setCompararFlechas] = useState([]);
    const [flechas, setFlechas] = useState([]);
    const [contador, setContador] = useState(0);
    const [posicion, setPosicion] = useState('');
    const [numero, setNumero] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignarPosicion = (e, pos) => {
        e.preventDefault();
        if(pos === posicion){
            const newList = JSON.parse(JSON.stringify(numeroRegla));
            newList[pos].color = '';
            setNumeroRegla(newList);
            setPosicion('');
        } else if(!posicion){
            const newList = JSON.parse(JSON.stringify(numeroRegla));
            newList[pos].color = '#23AAAF';
            setNumeroRegla(newList);
            setPosicion(pos);
        } else {
            const newList = JSON.parse(JSON.stringify(numeroRegla));
            newList[posicion].color = ''
            newList[pos].color = '#23AAAF';
            setNumeroRegla(newList);
            setPosicion(pos);
        }
    }

    const borrarFlechas = (e) => {
        e.preventDefault();
        setFlechas(new Array(11));
        setContador(0);
    }

    const asignarCurva = (e) => {
        e.preventDefault();
        if(!posicion) {
            return
        }
        let posicionFinal = posicion - contador;
        const newList = JSON.parse(JSON.stringify(flechas));
        newList[posicionFinal] = 'retrocede';
        setFlechas(newList);
        setContador(contador + 1);
    }

    const verificar = (e) => {
        e.preventDefault();
        if(numero.toString() !== (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() || flechas[0] !== compararFlechas[0] || flechas[1] !== compararFlechas[1] || flechas[2] !== compararFlechas[2] || flechas[3] !== compararFlechas[3] || flechas[4] !== compararFlechas[4] || flechas[5] !== compararFlechas[5] || flechas[6] !== compararFlechas[6] || flechas[7] !== compararFlechas[7] || flechas[8] !== compararFlechas[8] || flechas[9] !== compararFlechas[9] || flechas[10] !== compararFlechas[10]) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setContador(0);
            const newList = JSON.parse(JSON.stringify(numeroRegla));
            newList[posicion].color = '';
            setNumeroRegla(newList);
            setPosicion('');
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                if(numero.toString() !== (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() || flechas[0] !== compararFlechas[0] || flechas[1] !== compararFlechas[1] || flechas[2] !== compararFlechas[2] || flechas[3] !== compararFlechas[3] || flechas[4] !== compararFlechas[4] || flechas[5] !== compararFlechas[5] || flechas[6] !== compararFlechas[6] || flechas[7] !== compararFlechas[7] || flechas[8] !== compararFlechas[8] || flechas[9] !== compararFlechas[9] || flechas[10] !== compararFlechas[10]) {
                    setNumero('');
                    setFlechas(new Array(11));
                }
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setContador(0);
                    const newList = JSON.parse(JSON.stringify(numeroRegla));
                    newList[posicion].color = '';
                    setNumeroRegla(newList);
                    setPosicion('');
                    setFlechas(new Array(11));
                    setCount(count + 1);
                    
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setContador(0);
                    const newList = JSON.parse(JSON.stringify(numeroRegla));
                    newList[posicion].color = '';
                    setNumeroRegla(newList);
                    setPosicion('');
                    setFlechas(new Array(11));
                }, 1500);
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        function shuffle (array) {
            let currentIndex = array.length, randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex !== 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
            return array;
        }
        const listaUno = [1,2,3,4,5,6,7,8,9,10];
        const randomListaUno = shuffle(listaUno);
        const listaUnoFinal = [randomListaUno[0],randomListaUno[1],randomListaUno[2],randomListaUno[3],randomListaUno[4]];

        const listaDos = [];
        const randomLista = (lista, listaBase) => {
            for(let i = 0; i < 5; i++){
                let min = 1;
                let max = listaBase[i];
                let randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                lista.push(randomNumero);
            }
            return lista
        }
        const randomListaDos = randomLista(listaDos, listaUnoFinal);
        const modelo = [
            {id:'1', cantidad_1:listaUnoFinal[0], cantidad_2:randomListaDos[0]},
            {id:'2', cantidad_1:listaUnoFinal[1], cantidad_2:randomListaDos[1]},
            {id:'3', cantidad_1:listaUnoFinal[2], cantidad_2:randomListaDos[2]},
            {id:'4', cantidad_1:listaUnoFinal[3], cantidad_2:randomListaDos[3]},
            {id:'5', cantidad_1:listaUnoFinal[4], cantidad_2:randomListaDos[4]}
        ]
        setComparar(modelo);
        setIndexes(modelo.length);
        setLanzoEffect(true);
        setTipoEjercicio('unidad');
    }, []);

    useEffect(() => {
        if(comparar.length === 0) {
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar[count-1]));
        const numeroUno = newList.cantidad_2;
        const numeroDos = newList.cantidad_1;

        const inicio = (numeroDos - numeroUno + 1);
        const intermedio = numeroUno;
        const final = (10 - numeroDos);

        const uno = Array(inicio).fill(null);
        const dos = Array(intermedio).fill('retrocede');
        const tres = Array(final).fill(null);

        const array2 = uno.concat(dos,tres);

        const array = [
            {id:'1', numero:0, color: ''},
            {id:'2', numero:1, color: ''},
            {id:'3', numero:2, color: ''},
            {id:'4', numero:3, color: ''},
            {id:'5', numero:4, color: ''},
            {id:'6', numero:5, color: ''},
            {id:'7', numero:6, color: ''},
            {id:'8', numero:7, color: ''},
            {id:'9', numero:8, color: ''},
            {id:'10', numero:9, color: ''},
            {id:'11', numero:10, color: ''}
        ];
        setNumeroRegla(array);
        setCompararFlechas(array2);
        setFlechas(new Array(11));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect])

    useEffect(() => {
        dispatch(setearEjercicioAction('M67') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/ejercitacion/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M67",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex direction="column" margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        {/* RECTA 3 */}
                        <FlexFull>
                            <FlexFull direction='column' justify='center' align='center'>
                                <FlexFull height='50px' width='260px' wm='270px' wt='400px' wd='478px' wlg='485px' bradius='10px' margin='0px -5px -65px 0px' mm='0px -3px -65px 0px' mt='0px 33px -76px 0px' md='0px -5px -87px 0px' mlg='0px -10px -90px 0px' justify='end' align='center' z='1'>
                                    {flechas.map((item,i) =>
                                    <div key={i}>
                                        {item === 'vacio' ? null 
                                        : 
                                        <Flex>
                                            <ImgB10 src={item ? `/img/${item}.png` : null} w='30px' wm='31px' wt='40px' wd='52px' wlg='53.3px' m='0px -2px -40px -2px' mm='0px -2px -48px -2px' mt='0px -2px -58px -2px' md='0px -2.3px -80px -2px' mlg='0px -2px -78px -3px' />
                                        </Flex>}
                                    </div>
                                    )}
                                </FlexFull>
                                <FlexFull direction='column' justify='center' align='center' margin='0.5vw' bradius='10px' color='#FFFFFF' >
                                    <ImgB10 src='/img/rectanumerica1.png' w='373px' wm='387px' wt='515px' wd='680px' wlg='695px' m='3vh 0px 0px 0px' style={{zIndex: 0}} />
                                    <Flex width='52vw' wm='47vw' wt='43vw' wd='41vw' wlg='40vw' margin='-60px -5px 0px 0px' mm='-63px -8px 0px 0px' mt='-80px -20px 0px 0px' md='-100px -14px 0px 0px' mlg='-100px -15px 0px 0px'>
                                        {numeroRegla.map(item =>
                                        <BtnRectaM67 m='0px 4.8px 0px 4.8px' mm='0px 5.3px 0px 5.3px' mt='0px 15px 0px 4.5px' md='0px 15px 0px 15.8px' mlg='0px 15px 0px 17px'
                                            key={item.id}
                                            onClick={(e) => asignarPosicion(e,item.numero)}
                                        >
                                            <PRectaM67 m='0px' color={item.color}>{item.numero}</PRectaM67>
                                        </BtnRectaM67>
                                        )}
                                    </Flex>
                                </FlexFull>
                            </FlexFull>
                            <FlexFull direction='column' margin='0px 0px 0px 20px'>
                                <BotonRetrocede
                                    disabled={disable}
                                    onClick={(e) => asignarCurva(e)}
                                >
                                    <ImgB10 src={`/img/retrocede.png`} w='6vw' wm='6vw' wt='5vw' wd='5vw' wlg='5vw' bs='0px 4px 4px rgba(0, 0, 0, 0.25)' p='0.1vw' bradius='10px' />
                                </BotonRetrocede>
                                <ButtonM44 margin='20px 0px 0px 0px'
                                    disabled={disable}
                                    onClick={(e) => borrarFlechas(e)}
                                > 
                                    <ImgB10 src={`/img/icono_basurero.png`} w='5vw'  /> {/* m='1vh 0px 0px 0px' */}
                                </ButtonM44>
                            </FlexFull>
                        </FlexFull>
                        {/* FIN RECTA 3 */}


                        {/* SUMA */}
                        <FlexFull  direction='column' justify='center' align='start' margin="2rem 0 0 0">
                            <Flex height='120px'>
                                {comparar.length === 0 ? null :
                                <Flex>
                                    <NumSumaM73 color='#FF9B2A'>{comparar[count-1].cantidad_1}</NumSumaM73> 
                                    <NumSumaM73 color='#FF9B2A'>-</NumSumaM73>
                                    <NumSumaM73 color='#FF9B2A'>{comparar[count-1].cantidad_2}</NumSumaM73> 
                                    <NumSumaM73 color='#FF9B2A'>=</NumSumaM73>
                                </Flex>
                                }
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <InputM73 type="numeric" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} color='#21A2A7' />
                                </form>
                            </Flex>
                        </FlexFull>
                        {/* FIN SUMA */}
                    </Flex>

                    {/* INSTRUCCIONES */}
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                    {/* FIN INSTRUCCIONES */}
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M67;