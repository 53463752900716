import {
    ACTUALIZAR_ACTIVIDADES_EXITO,
    CERRAR_SESION
} from '../types';


const initialState = {
    seleccionadas: [],
    agendadas: []
}
export default function actividadesReducer(state = initialState, action) {
    switch(action.type) {
        case CERRAR_SESION:
            return {
                seleccionadas: [],
                agendadas: []
            }
        case ACTUALIZAR_ACTIVIDADES_EXITO:
            return {
                ...state,
                seleccionadas: action.payload
            }
        default:
            return state;
    }
}