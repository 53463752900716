import {Fragment, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useConfigAuth from '../../Hooks/useConfigAuth';

import { actividadesAgendadasSolicitarAction } from '../../actions/actividadesActions';
import { setearSesionAction, setearSesionTerminadaAction } from '../../actions/estadoEjercicioActions';
import HeaderPaciente from '../AuxiliaresPaginas/HeaderPaciente';
import { ContendorPrincipal, FlexTable, HeadTable, Imagen, ImagenAbsoluta, LineaVertical, PantallaPaciente, TableRow, TituloColumna, TituloCuatro, TituloDos, TituloTres } from '../Css/StyledPaciente';

const PacienteUsuario = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();
    const navigate = useNavigate();

    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const nombrePaciente = useSelector((state) => state.usuario.nombrePaciente);
    const actividades = useSelector((state) => state.informacion.info_actividades_agendadas);
    const url = useSelector((state) => state.ejercicio.url);

    const [sesionesPend, setSesionesPend] = useState([]);
    const [sesionesTer, setSesionesTer] = useState([]);

    const iniciarSesion = (e, sesn) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(actividades));
        const sesion = newList.filter(item => item.sesion === sesn);
        const informacion = {
            sesion
        }
        dispatch(setearSesionAction(informacion));
    }

    const repetirSesion = (e, sesn) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(actividades));
        const sesion = newList.filter(item => item.sesion === sesn);
        const sesionTerminada = sesion.map(item => ({
            ...item,
            status:'pendiente',
            nuevaRepeticion: 0
        }));
        const informacionDos = {
            sesionTerminada
        }
        dispatch(setearSesionTerminadaAction(informacionDos));
    }

    useEffect(() => {
        if(url === ''){
            return
        }
        navigate(url);
    }, [url]);

    useEffect(() => {
        if(actividades.length !== 0) {
            return
        }
        const informacion = {
            config,
            payload: {
                id_paciente
            }
        }
        dispatch(actividadesAgendadasSolicitarAction(informacion));
    }, [])
    
    useEffect(() => {
        if(!actividades) {
            return;
        }
        let sesiones = [];
        let sesionesPendientes = [];
        let sesionesTerminadas = [];
        const newList = JSON.parse(JSON.stringify(actividades));
        let uniqueSesions = [...new Set(newList.map(item => item.sesion))];
        for(let i=0; i<uniqueSesions.length; i++) {
            let obj = {};
            let cantidad = actividades.filter(item => item.sesion === uniqueSesions[i]).map(item => item.cantidad).reduce((acumulador, currValue) => acumulador + currValue);
            let fecha_agendada = actividades.filter(item => item.sesion === uniqueSesions[i]).map(item => item.fecha_agendada)[0];
            let fecha_expiracion = actividades.filter(item => item.sesion === uniqueSesions[i]).map(item => item.fecha_expiracion)[0];
            let ejerciciosTerminados = actividades.filter(item => item.sesion === uniqueSesions[i]);
            let diffArray = [];
            for(let j=0; j<ejerciciosTerminados.length; j++){
                let diferencia = ejerciciosTerminados[j].cantidad - ejerciciosTerminados[j].repeticiones;
                if(diferencia >= 0){
                    diffArray.push(ejerciciosTerminados[j].repeticiones)
                } else {
                    diffArray.push(ejerciciosTerminados[j].cantidad)
                }
            }
            let sum = diffArray.reduce((partialSum, a) => partialSum + a, 0);
            obj.id = uniqueSesions[i];
            obj.cantidad = cantidad;
            obj.fecha_agendada = fecha_agendada;
            obj.fecha_expiracion = fecha_expiracion;
            obj.ejerciciosTerminados = sum;
            sesiones.push(obj);
        }

        for(let i=0; i<sesiones.length; i++){
            if(sesiones[i].ejerciciosTerminados === sesiones[i].cantidad){
                sesionesTerminadas.push(sesiones[i]);
            } else {
                sesionesPendientes.push(sesiones[i]);
            }
        }
        sesionesPendientes.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        sesionesTerminadas.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        setSesionesPend(sesionesPendientes);
        setSesionesTer(sesionesTerminadas);
    }, [actividades])
    

    return (
        <Fragment >
            <HeaderPaciente />
            <PantallaPaciente>
                <ContendorPrincipal>
                    <TituloDos>
                        HOLA {nombrePaciente}! ESTAS SON TUS ACTIVIDADES PROGRAMADAS
                    </TituloDos>
                    <TituloTres>
                        Agendadas
                    </TituloTres>
                    {sesionesPend.map(item => 
                        <FlexTable
                            key={item.id}
                        >
                            <HeadTable>
                                <TableRow>
                                    <TituloColumna><ImagenAbsoluta src="/img/cohete1.png" alt='cohete'/></TituloColumna>
                                    <TituloColumna><TituloCuatro>{item.id}</TituloCuatro></TituloColumna>
                                    <TituloColumna><LineaVertical></LineaVertical></TituloColumna>
                                    <TituloColumna><TituloCuatro>Agendado para: {item.fecha_agendada}</TituloCuatro></TituloColumna>
                                    <TituloColumna><TituloCuatro>Vence: {item.fecha_expiracion}</TituloCuatro></TituloColumna>
                                    <TituloColumna><TituloCuatro>{item.ejerciciosTerminados}/{item.cantidad}</TituloCuatro></TituloColumna>
                                    <TituloColumna><Imagen onClick={(e) => iniciarSesion(e, item.id)} src="/img/playusuariopaciente.png" alt='empezar'/></TituloColumna>
                                </TableRow>
                            </HeadTable>
                        </FlexTable>
                    )}
                    <TituloTres>
                        Finalizadas
                    </TituloTres>
                    {sesionesTer.map(item => 
                        <FlexTable
                            key={item.id}
                        >
                            <HeadTable>
                                <TableRow>
                                    <TituloColumna><ImagenAbsoluta src="/img/cohete1.png" alt='cohete'/></TituloColumna>
                                    <TituloColumna><TituloCuatro>{item.id}</TituloCuatro></TituloColumna>
                                    <TituloColumna><LineaVertical></LineaVertical></TituloColumna>
                                    <TituloColumna><TituloCuatro></TituloCuatro></TituloColumna>
                                    <TituloColumna><TituloCuatro>Vence: {item.fecha_expiracion}</TituloCuatro></TituloColumna>
                                    <TituloColumna><TituloCuatro></TituloCuatro></TituloColumna>
                                    <TituloColumna><Imagen onClick={(e) => repetirSesion(e, item.id)} src="/img/rehacerusuariopaciente.png" alt='rehacer'/></TituloColumna>
                                </TableRow>
                            </HeadTable>
                        </FlexTable>
                    )}
                </ContendorPrincipal>
            </PantallaPaciente>
        </Fragment>
    )
}
 
export default PacienteUsuario;