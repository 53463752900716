import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {Flex, Ilustracion, Input, Pregunta, Btn, Colores, ImgBottom, Grid} from '../../../../Css/MetodosCSS/StyledCCIntrasilabica';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Quita el sonido mencionado y descubre una nueva palabra.';
const miniInstrucciones = 'Quitale el sonido mencionado y descubre una nueva palabra.';

const LEN29 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState();
    const [palabra, setPalabra] = useState('');
    const [indexes, setIndexes] = useState();
    const [circulos, setCirculos] = useState([]);
    const [ayuVisual, setAyuVisual] = useState(false);
    const [ayuVisualSolicitada, setAyuVisualSolicitada] = useState(false);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const circulo = [
        {id:1, color:'red', mostrar: false},
        {id:2, color:'blue', mostrar: false},
        {id:3, color:'green', mostrar: false},
        {id:4, color:'yellow', mostrar: false},
        {id:5, color:'violet', mostrar: false},
        {id:6, color:'pink', mostrar: false},
        {id:7, color:'black', mostrar: false},
        {id:8, color:'brown', mostrar: false},
        {id:9, color:'grey', mostrar: false},
        {id:10, color:'orange', mostrar: false}
    ]
    
    const verificar = (e) => {
        e.preventDefault();
        if(palabra.toUpperCase() !== comparar.palabra_2.toUpperCase()) {
            console.log('mal');
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
                setPalabra('');
            }, 1000);
        } else {
            console.log('bien');
            setIsCorrect(true);
            setTimeout(() => {
               setIsCorrect(false);
            }, 300);
            GoodWork();
            setDisable(true);
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setPalabra('');
                    setCount(count + 1);
                }, 1000);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    setDisable(false);
                    setPalabra('');
                    dispatch( finalizarEjercicioAction() )
                }, 1000);
            }
        }
    }

    const setearAyuVisual = (e) => {
        e.preventDefault();
        if (ayuVisual) {
            setAyuVisual(false);
        } else {
            setAyuVisual(true);
        }
        if(ayuVisualSolicitada){
            return;
        } else {
            setAyuVisualSolicitada(true);
        }
    }

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN29') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-intrasilabica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN29",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    'ayuda_visual': ayuVisualSolicitada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    useEffect(() => {
        if(comparar === undefined){
            return
        }
        const largo = comparar.palabra_1.length;
        const newList = JSON.parse(JSON.stringify(circulo));
        switch (largo) {
            case 1:
                newList[0].mostrar = true
                break;
            case 2:
                newList[0].mostrar = true
                newList[1].mostrar = true
                break;
            case 3:
                newList[0].mostrar = true
                newList[1].mostrar = true
                newList[2].mostrar = true
                break;
            case 4:
                newList[0].mostrar = true
                newList[1].mostrar = true
                newList[2].mostrar = true
                newList[3].mostrar = true
                break;
            case 5:
                newList[0].mostrar = true
                newList[1].mostrar = true
                newList[2].mostrar = true
                newList[3].mostrar = true
                newList[4].mostrar = true
                break;
            case 6:
                newList[0].mostrar = true
                newList[1].mostrar = true
                newList[2].mostrar = true
                newList[3].mostrar = true
                newList[4].mostrar = true
                newList[5].mostrar = true
                break;
            case 7:
                newList[0].mostrar = true
                newList[1].mostrar = true
                newList[2].mostrar = true
                newList[3].mostrar = true
                newList[4].mostrar = true
                newList[5].mostrar = true
                newList[6].mostrar = true
                break;
            case 8:
                newList[0].mostrar = true
                newList[1].mostrar = true
                newList[2].mostrar = true
                newList[3].mostrar = true
                newList[4].mostrar = true
                newList[5].mostrar = true
                newList[6].mostrar = true
                newList[7].mostrar = true
                break;
            case 9:
                newList[0].mostrar = true
                newList[1].mostrar = true
                newList[2].mostrar = true
                newList[3].mostrar = true
                newList[4].mostrar = true
                newList[5].mostrar = true
                newList[6].mostrar = true
                newList[7].mostrar = true
                newList[8].mostrar = true
                break;
            case 10:
                newList[0].mostrar = true
                newList[1].mostrar = true
                newList[2].mostrar = true
                newList[3].mostrar = true
                newList[4].mostrar = true
                newList[5].mostrar = true
                newList[6].mostrar = true
                newList[7].mostrar = true
                newList[8].mostrar = true
                newList[9].mostrar = true
                break;
            default:
                break;
        }
        const filter = newList.filter(obj => {
            return obj.mostrar === true;
        })
        setCirculos(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comparar])

    return (
        <Fragment>
        <HeaderEjercicios estrellas={true} carrito={true} ayuImagen={true} />
        {iniciar
            ?
            <Flex direction="column" height="100%" width="100%">
                <Flex direction="column" margin="auto" width="100%">
                    <Grid margin="0 0 1.5rem 0" width="100%">
                        <div></div>
                        <Pregunta margin="0 3rem 0 0" justifyself="center">
                            <p>¿Qué palabra se forma si le quitas la letra "{comparar.letra.toUpperCase()}"</p>
                        </Pregunta>
                        <Flex>
                            <Btn
                                onClick={(e) => setearAyuVisual(e)}
                            >
                                <ImgBottom src={`/img/ayuda.png`} alt=""/>
                            </Btn>
                        </Flex>
                    </Grid>
                    <Flex justify="center">
                        <Flex direction="column" margin="0 1.5rem 0 0" >
                            <Ilustracion src={`/img/${comparar.imagen_url_1}.png`} width="5vw" 
                            isCorrect={isCorrect} isIncorrect={isIncorrect}/>
                            {ayuda_imagen ? 
                                <div>{comparar.ayuda_imagen_1}</div>
                            : null}
                        </Flex>
                        <Input disabled={disable} type="text" 
                        value={palabra}
                        onChange={(e) => setPalabra(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' ? verificar(e) : null} 
                        />
                    </Flex>
                    <Flex justify="space-around" margin="3rem 0 0 0">
                        {ayuVisual
                        ?   circulos.map(item => (
                                <div
                                    key={item.id}
                                >
                                    <Colores style={{background:`${item.color}`}}> {item.color}</Colores>
                                </div>
                            ))
                        : null
                        }
                    </Flex>
                </Flex>
                <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                    <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                    <AvanzarBtn
                        disabled={disable}
                        onClick={(e) => verificar(e)}
                    >
                        <AvanzarImg src={'/img/play.png'} /> 
                    </AvanzarBtn>
                </Flex>
            </Flex>
            :
                <Instrucciones instrucciones={instrucciones} />
        }
    </Fragment>
    );
}
 
export default LEN29;