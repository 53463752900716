import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

// css
import {Icono, Btn, ContBtn, Flex} from '../../../../Css/MetodosCSS/StyledCCLexica';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const instrucciones = 'Piensa una oración y selecciona los cuadrados que correspondan según el largo de la palabra.';
const miniInstrucciones = 'Piensa una oración y selecciona los cuadrados que correspondan según el largo de la palabra.';

const LEN17 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [asignar, setAsignar] = useState([]);
    const [asignado, setAsignado] = useState([]);
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const play1 = new Audio(`/sounds/${informacion[0]?.sonido_url_1}.mp3`);
    const play2 = new Audio(`/sounds/${informacion[1]?.sonido_url_1}.mp3`);
    const play3 = new Audio(`/sounds/${informacion[2]?.sonido_url_1}.mp3`);
    const play4 = new Audio(`/sounds/${informacion[3]?.sonido_url_1}.mp3`);
    const play5 = new Audio(`/sounds/${informacion[4]?.sonido_url_1}.mp3`);

    const play = (count) => {
        play1.pause();
        play2.pause();
        play3.pause();
        play4.pause();
        play5.pause();
        play1.currentTime = 0;
        play2.currentTime = 0;
        play3.currentTime = 0;
        play4.currentTime = 0;
        play5.currentTime = 0;
        switch(count){
            case 1:
                play1.play();
                break;
            case 2:
                play2.play();
                break;
            case 3:
                play3.play();
                break;
            case 4:
                play4.play();
                break;
            case 5:
                play5.play();
                break;
            default:
        }
    }


    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const setearTipo = (e, tipo) => {
        e.preventDefault();
        // Tengo que optimizar el siguiente código con una iteración
        if(asignado.comparar_1.tipo === '') {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList.comparar_1 = tipo;
            setAsignado(newList);
        } else if(asignado.comparar_2.tipo === '') {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList.comparar_2 = tipo;
            setAsignado(newList);
        } else if(asignado.comparar_3.tipo === '') {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList.comparar_3 = tipo;
            setAsignado(newList);
        } else if(asignado.comparar_4.tipo === '') {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList.comparar_4 = tipo;
            setAsignado(newList);
        } else if(asignado.comparar_5.tipo === '') {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList.comparar_5 = tipo;
            setAsignado(newList);
        } else if(asignado.comparar_6.tipo === '') {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList.comparar_6 = tipo;
            setAsignado(newList);
        } else if(asignado.comparar_7.tipo === '') {
            const newList = JSON.parse(JSON.stringify(asignado));
            newList.comparar_7 = tipo;
            setAsignado(newList);
            return;
        }
    }

    const borrar = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(asignado));
        newList.comparar_1 = {tipo: '', background: '', width: ''};
        newList.comparar_2 = {tipo: '', background: '', width: ''};
        newList.comparar_3 = {tipo: '', background: '', width: ''};
        newList.comparar_4 = {tipo: '', background: '', width: ''};
        newList.comparar_5 = {tipo: '', background: '', width: ''};
        newList.comparar_6 = {tipo: '', background: '', width: ''};
        newList.comparar_7 = {tipo: '', background: '', width: ''};
        setAsignado(newList);
    }

    const verificar = (e, frase, original) => {
        e.preventDefault();
        const {comparar_1, comparar_2, comparar_3, comparar_4, comparar_5, comparar_6, comparar_7} = frase;
        const {palabra_1, palabra_2, palabra_3, palabra_4, palabra_5, palabra_6, palabra_7} = original;

        if(comparar_1.tipo === palabra_1 && comparar_2.tipo === palabra_2 && comparar_3.tipo === palabra_3 && comparar_4.tipo === palabra_4 && comparar_5.tipo === palabra_5 && comparar_6.tipo === palabra_6 && comparar_7.tipo === palabra_7 ) {
            console.log("Bien")
            setDisable(true)
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setCount(count+1)
                    const newList = JSON.parse(JSON.stringify(asignado));
                    newList.comparar_1 = {tipo: '', background: '', width: ''};
                    newList.comparar_2 = {tipo: '', background: '', width: ''};
                    newList.comparar_3 = {tipo: '', background: '', width: ''};
                    newList.comparar_4 = {tipo: '', background: '', width: ''};
                    newList.comparar_5 = {tipo: '', background: '', width: ''};
                    newList.comparar_6 = {tipo: '', background: '', width: ''};
                    newList.comparar_7 = {tipo: '', background: '', width: ''};
                    setAsignado(newList);
                }, 500);
            } else {
                setUltimoTiempo(true);
                setTimeout(() => {
                    dispatch( finalizarEjercicioAction() );       
                }, 1500);
            }
        } else {
            console.log("mal")
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            const newList = JSON.parse(JSON.stringify(asignado));
            newList.comparar_1 = {tipo: '', background: '', width: ''};
            newList.comparar_2 = {tipo: '', background: '', width: ''};
            newList.comparar_3 = {tipo: '', background: '', width: ''};
            newList.comparar_4 = {tipo: '', background: '', width: ''};
            newList.comparar_5 = {tipo: '', background: '', width: ''};
            newList.comparar_6 = {tipo: '', background: '', width: ''};
            newList.comparar_7 = {tipo: '', background: '', width: ''};
            setAsignado(newList);
        }
    }

    useEffect(() => {
        const frase = [
            {id:'1', comparar_1:{tipo: ''}, comparar_2:{tipo: ''}, comparar_3:{tipo: ''}, comparar_4:{tipo: ''}, comparar_5:{tipo: ''}, comparar_6:{tipo: ''}, comparar_7:{tipo: ''}}
        ]
        const palabra = [
            {id:'1', tipo:'corta', background: '#FF9B2A', width:'55px', simbolo:'+'},
            {id:'2', tipo:'larga', background: '#23AAAF', width:'70px',simbolo:'+'}
        ]
        setAsignado(frase[0]);
        setAsignar(palabra);
    }, [])

    useEffect(() => {
        dispatch(setearEjercicioAction('LEN17') );
        dispatch(setearUrlAnteriorAction('lenguaje/conciencia-fonologica/cc-lexica/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "LEN17",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    sesion
                }
            };
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%">
                    <Flex direction="column" margin="auto">
                        <Flex margin="0 0rem 2.5rem 0">
                            <Btn onClick={() => play(count)} > 
                                <Icono src={`/img/altavoz.png`} alt="" margin="0px 0px 0px 0px"  
                                isCorrect={isCorrect} isIncorrect={isIncorrect}
                                /> 
                            </Btn>
                            <audio src={`/sounds/${comparar.sonido_url_1}.mp3`} autoPlay />
                        </Flex>
                        <Flex margin="0 0 2rem 0">
                            <Flex direction="column" margin="0 3rem 0 0">
                                {asignar.map(item => (
                                    <Btn
                                        key={item.id}
                                        onClick={(e) => setearTipo(e, item)}
                                    >
                                        <ContBtn style={{background:`${item.background}`, width:`${item.width}`}}>{item.simbolo}</ContBtn>
                                    </Btn>
                                ))}
                            </Flex>
                            <Btn align="center"
                                disabled={disable}
                                onClick={(e) => borrar(e)}
                            >
                                <img src={`/img/icono_basurero.png`} alt=''/>
                            </Btn>
                        </Flex>
                        <Flex wrap="wrap" align="center" justify="center">
                            {asignado.comparar_1 && <ContBtn style={{background:`${asignado.comparar_1.background}`, width:`${asignado.comparar_1.width}`}}> {asignado.comparar_1.tipo} </ContBtn>}
                            {asignado.comparar_2 && <ContBtn style={{background:`${asignado.comparar_2.background}`, width:`${asignado.comparar_2.width}`}}> {asignado.comparar_2.tipo} </ContBtn>}
                            {asignado.comparar_3 && <ContBtn style={{background:`${asignado.comparar_3.background}`, width:`${asignado.comparar_3.width}`}}> {asignado.comparar_3.tipo} </ContBtn>}
                            {asignado.comparar_4 && <ContBtn style={{background:`${asignado.comparar_4.background}`, width:`${asignado.comparar_4.width}`}}> {asignado.comparar_4.tipo} </ContBtn>}
                            {asignado.comparar_5 && <ContBtn style={{background:`${asignado.comparar_5.background}`, width:`${asignado.comparar_5.width}`}}> {asignado.comparar_5.tipo} </ContBtn>}
                            {asignado.comparar_6 && <ContBtn style={{background:`${asignado.comparar_6.background}`, width:`${asignado.comparar_6.width}`}}> {asignado.comparar_6.tipo} </ContBtn>}
                            {asignado.comparar_7 && <ContBtn style={{background:`${asignado.comparar_7.background}`, width:`${asignado.comparar_7.width}`}}> {asignado.comparar_7.tipo} </ContBtn>}
                        </Flex>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e, asignado, comparar)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                    <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default LEN17;