import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';


import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import TablaEvolucion from './ResultadosTablaResultados';
import useConfigAuth from '../../Hooks/useConfigAuth';

import { consultarActividadesAction, consultarEjerciciosTablaAction } from '../../actions/resultadosActions';

import {Card, Skeleton, TabButton, TabContainer } from '../Css/StyledResultados';
import { Cuerpo, FlexColumn } from '../Css/StyledHeader';

Modal.setAppElement('#root');

const Resultados = () => {

    const dispatch = useDispatch();

    const [config] = useConfigAuth();

    const [activeTabFirst, setActiveTabFirst] = useState(1);
    const [activeTabSecond, setActiveTabSecond] = useState(2);
    const [selectedButtons, setSelectedButtons] = useState({
        colButton1: { Id: null, Nombre: null },
        colButton2: { Id: null, Nombre: null },
        colButton3: { Id: null, Nombre: null },
        colButton4: { Id: null, Nombre: null },
    });

    const actividadFinal = useSelector((state) => state.informacion.info_resultadosDos);
    const ejercicios = useSelector((state) => state.informacion.info_resultados);
    const loading = false;
    const id_paciente = useSelector((state) => state.usuario.id_paciente);

    const actividades = actividadFinal?.actividad;

    useEffect(() => {
        if(actividadFinal?.length > 0){
            return;
        }
        const informacion = {
            config,
            payload: {
                activeTabFirst,
                id_paciente
            }
        }
        dispatch(consultarActividadesAction(informacion));
        dispatch(consultarEjerciciosTablaAction(informacion));
    }, []);

    const changeTabFirst = (tabIndex) => setActiveTabFirst(tabIndex);

    const changeTabSecond = (tabIndex) => setActiveTabSecond(tabIndex);

    const handleTabClick = (buttonKey, id, nombre) => {
        setSelectedButtons((prevSelectedButtons) => ({
        ...prevSelectedButtons,
        [buttonKey]: { Id: id, Nombre: nombre },
        }));
    };
    console.log(actividades)
    return (
        <Cuerpo>
        <HeaderUno />
        <HeaderDos link={`/escritorio/pacientes/paciente`} componente={"EVOLUCIÓN"} paciente={true} />
        <FlexColumn align="center">
            <Card width="80%">
            <TabContainer>
                {actividades?.length > 0 ? (
                actividades.map((actividad) => {
                    return (
                    <TabButton
                        active={activeTabFirst === actividad.id_uno ? activeTabFirst : ""}
                        backgroundColorEnabled="#3EBCC1"
                        backgroundColorDisabled="#EAEAEA"
                        onClick={() => changeTabFirst(actividad.id_uno)}
                        key={actividad.id_uno}
                    >
                        {actividad.uno}
                    </TabButton>
                    );
                })
                ) : (
                <>
                    <Skeleton width="100%" height="20px" />
                    <Skeleton width="100%" height="20px" />
                    <Skeleton width="100%" height="20px" />
                </>
                )}
            </TabContainer>
            <TabContainer style={{ marginLeft: "auto", width: "50%" }}>
                <TabButton
                active={activeTabSecond === 1 ? activeTabSecond : ""}
                backgroundColorEnabled="#3EBCC1"
                backgroundColorDisabled="#EAEAEA"
                onClick={() => changeTabSecond(1)}
                >
                GRÁFICO
                </TabButton>
                <TabButton
                active={activeTabSecond === 2 ? activeTabSecond : ""}
                backgroundColorEnabled="#3EBCC1"
                backgroundColorDisabled="#EAEAEA"
                onClick={() => changeTabSecond(2)}
                >
                TABLA
                </TabButton>
            </TabContainer>
            <TablaEvolucion
                ejercicios={ejercicios}
                loading={loading}
                selectedButtons={selectedButtons}
                onTabClick={handleTabClick}
                active={activeTabSecond}
                seccion={activeTabFirst}
            />
            </Card>
        </FlexColumn>
        </Cuerpo>
    );
};

export default Resultados;
