import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../../Escritorio/Instrucciones';
import useConfigAuth from '../../../../../../Hooks/useConfigAuth';
import BotoneraBottom from '../../../../Compartidos/BotoneraBottom';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../../actions/almacenarEjercicioActions';

import { Flex, FlexFull, ImgB10, BtnRectaM73, PRectaM73, LateralM73, CirculoMasMenos, NumSumaM73, InputM73, AvanzarImg, AvanzarBtn } from '../../../../../Css/StyledMatematica';

const instrucciones = 'Resuelve la resta utilizando la estrategia que corresponde.';
const miniInstrucciones = 'Resuelve la resta utilizando la estrategia que corresponde.';

const M76 = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [lanzoEffect, setLanzoEffect] = useState(false);
    const [count, setCount] = useState(1);
    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [numero, setNumero] = useState('');
    const [curva, setCurva] = useState([]);
    const [numeroRegla, setNumeroRegla] = useState([]);
    const [countCurva, setCountCurva] = useState(0);
    const [posicion, setPosicion] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);
    const [tipoEjercicio, setTipoEjercicio] = useState('');

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const sesion = useSelector((state) => state.ejercicio.sesion);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const asignarPosicion = (e, pos) => {
        e.preventDefault();
        if(pos === 0){
            return;
        }
        if(pos === posicion){
            const newList = JSON.parse(JSON.stringify(numeroRegla));
            newList[pos].color = '';
            setNumeroRegla(newList);
            setPosicion('');
        } else if(!posicion){
            const newList = JSON.parse(JSON.stringify(numeroRegla));
            newList[pos].color = '#23AAAF';
            setNumeroRegla(newList);
            setPosicion(pos);
        } else {
            const newList = JSON.parse(JSON.stringify(numeroRegla));
            newList[posicion].color = ''
            newList[pos].color = '#23AAAF';
            setNumeroRegla(newList);
            setPosicion(pos);
        }
    }

    const verificar = (e) => {
        e.preventDefault();
        if((comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() !== numero.toString() || curva.filter(item => item === 'retrocede_inversa').length.toString() !== (comparar[count-1].cantidad_1 - comparar[count-1].cantidad_2).toString() || comparar[count-1].cantidad_2.toString() !== numeroRegla[posicion].numero.toString()) {
            console.log('mal');
            setDisable(true);
            setContarErrores(contarErrores + 1);
            setIsIncorrect(true);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setNumero('');
                setCountCurva(0);
                if(posicion) {
                    const newList = JSON.parse(JSON.stringify(numeroRegla));
                    newList[posicion].color = '';
                    setNumeroRegla(newList);
                }
                setPosicion('');
                const arr2 = new Array(16);
                setCurva(arr2);
                setDisable(false);
            }, 300);
        } else {
            console.log("bien");
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if(count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setNumero('');
                    setCountCurva(0);
                    if(posicion) {
                        const newList = JSON.parse(JSON.stringify(numeroRegla));
                        newList[posicion].color = '';
                        setNumeroRegla(newList);
                    }
                    setPosicion('');
                    const arr2 = new Array(16);
                    setCurva(arr2);
                    setDisable(false);
                    setCount(count + 1);
                }, 300);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch( finalizarEjercicioAction() );
                    setNumero('');
                    setCountCurva(0);
                    setPosicion(0);
                    const arr2 = new Array(17);
                    setCurva(arr2);
                    setDisable(true);              
                }, 1500);
            }
        }
    }

    const sumarCurva = (e) => {

        e.preventDefault();
        if(!posicion){
            return;
        }
        setDisable(false);
        const newList = JSON.parse(JSON.stringify(curva));
        const start = posicion;
        if(posicion + countCurva > 16){
            return;
        }
        const positionAgregar = start + countCurva;
        newList[positionAgregar] = 'retrocede_inversa';
        setCountCurva(countCurva + 1);
        setCurva(newList);
    }
    const restarCurva = (e) => {
        e.preventDefault();
        if(!posicion){
            return;
        }
        const newList = JSON.parse(JSON.stringify(curva));
        const start = posicion;
        if(countCurva === 0){
            return;
        }
        const positionBorrar = start + countCurva - 1;
        newList[positionBorrar] = '';
        setCountCurva(countCurva - 1);
        setCurva(newList);
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const listaUno = [];
        const listaDos = [];
        const funcionListaUno = (minimo, maximo, lista) => {
            for(let i = 0; i < 5; i++){
                let min = minimo;
                let max = maximo;
                let randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                lista.push(randomNumero);
            }
            return lista;
        }
        const funcionListaDos = (lista, listaUsada) => {
            for(let i = 0; i < 5; i++){
                let min = listaUsada[i]-9;
                let max = listaUsada[i];
                let randomNumero = Math.floor(Math.random() * (max - min +1)) + min;
                lista.push(randomNumero);
            }
            return lista;
        }
        const randomListaUno = funcionListaUno(11,99,listaUno);
        const randomListaDos = funcionListaDos(listaDos, randomListaUno);
        const modelo = [
            {id:'1', cantidad_1:randomListaUno[0], cantidad_2:randomListaDos[0]},
            {id:'2', cantidad_1:randomListaUno[1], cantidad_2:randomListaDos[1]},
            {id:'3', cantidad_1:randomListaUno[2], cantidad_2:randomListaDos[2]},
            {id:'4', cantidad_1:randomListaUno[3], cantidad_2:randomListaDos[3]},
            {id:'5', cantidad_1:randomListaUno[4], cantidad_2:randomListaDos[4]}
        ];
        setComparar(modelo);
        setIndexes(modelo.length);
        setTipoEjercicio('decena');
        setLanzoEffect(true);
    }, [])

    useEffect(() => {
        if(comparar.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(comparar));
        const numeroUno = newList[count-1].cantidad_1
        let superior = Math.floor(Math.random() * (7 - 0 +1)) + 0;
        let inferior = 7 - superior;
        let cotaSuperior = numeroUno + superior;
        let cotaInferior = numeroUno - 9 - inferior;

        const arrayRange = (start, stop, step) =>
            Array.from(
            { length: (stop - start) / step + 1 },
            (value, index) => start + index * step
            );
        
        const arr = arrayRange(cotaInferior, cotaSuperior, 1);
        const array = [
            {id:'1', numero:arr[0], color: ''},
            {id:'2', numero:arr[1], color: ''},
            {id:'3', numero:arr[2], color: ''},
            {id:'4', numero:arr[3], color: ''},
            {id:'5', numero:arr[4], color: ''},
            {id:'6', numero:arr[5], color: ''},
            {id:'7', numero:arr[6], color: ''},
            {id:'8', numero:arr[7], color: ''},
            {id:'9', numero:arr[8], color: ''},
            {id:'10', numero:arr[9], color: ''},
            {id:'11', numero:arr[10], color: ''},
            {id:'12', numero:arr[11], color: ''},
            {id:'13', numero:arr[12], color: ''},
            {id:'14', numero:arr[13], color: ''},
            {id:'15', numero:arr[14], color: ''},
            {id:'16', numero:arr[15], color: ''},
            {id:'17', numero:arr[16], color: ''}
        ];
        setNumeroRegla(array);

        const arr2 = new Array(16);
        setCurva(arr2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, lanzoEffect]);

    useEffect(() => {
        dispatch(setearEjercicioAction('M73') );
        dispatch(setearUrlAnteriorAction('matematica/calculo/resta/calculo-mental/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "M73",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'tipo': tipoEjercicio,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={false} />
            {iniciar
                ?
                <Flex direction="column" height="100%" width="100%" align="center">
                    <Flex margin="auto" width="100%" align='center' justify="space-evenly"
                    isCorrect={isCorrect}
                    isIncorrect={isIncorrect}
                    >
                        <FlexFull direction='column' width='78%' wm='78%' wt='75%' wd='80%' wlg='85%' justify='space-between' align='center' height='64vh' margin='0px 1vw 0px 0px' mm='0px 1vw 0px 0px' mt='0px 3vw 0px 0px' md='0px 3vw 0px 0px' mlg='0px 5vw 0px 0px'>
                            <FlexFull width='100%' direction='column' justify='center' align='start'>
                                <Flex height='120px'>
                                    {comparar.length === 0 ? null :
                                    <Flex>
                                        <NumSumaM73 color='#FF9B2A'>{comparar[count-1].cantidad_1}</NumSumaM73> 
                                        <NumSumaM73 color='#FF9B2A'>-</NumSumaM73>
                                        <NumSumaM73 color='#FF9B2A'>{comparar[count-1].cantidad_2}</NumSumaM73> 
                                        <NumSumaM73 color='#FF9B2A'>=</NumSumaM73>
                                    </Flex>
                                    }
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <InputM73 type="numeric" min="1" max="1000" value={numero} disabled={disable} onChange={(e) => setNumero(e.target.value)} color='#21A2A7' />
                                    </form>
                                </Flex>
                            </FlexFull>
                            <FlexFull direction='column' justify='center' align='center'>
                                <FlexFull height='50px' width='260px' wm='270px' wt='400px' wd='478px' wlg='485px' bradius='10px' margin='0px 30px -65px 0px' mm='0px -218px -65px 0px' mt='0px -250px -76px 0px' md='0px -386px -90px 0px' mlg='0px -432px -90px 0px' justify='end' align='center' z='1'>
                                    {curva.map((item,i) =>
                                    <div key={i}>
                                        {/* {item} */}
                                        {item === 'vacio' ? null 
                                        : 
                                        <Flex margin='0px -3px 0px 0px' mm='0px -3px 0px 0px' mt='0px -3px 0px 0px' md='0px -1px 0px 0px' mlg='0px -2px 0px 0px'>
                                            <ImgB10 src={item ? `/img/${item}.png` : null} w='30px' wm='31px' wt='40px' wd='53px' wlg='55px' m='0px -2px 0px -2px' mm='-30px 0px 0px -1px' mt='-57px 0px 0px -1px' md='-90px -2px 0px -2px' mlg='-85px -2px 0px -2px' style={{zIndex:0}} />
                                        </Flex>}
                                    </div>
                                    )}
                                </FlexFull>
                                <FlexFull direction='column' justify='center' align='center' margin='0.5vw' bradius='10px' color='#FFFFFF'>
                                    <ImgB10 src={`/img/regla_resta.png`} w='373px' wm='508px' wt='678px' wd='899px' wlg='926px' mlg='3vh -120px 0px 0px' md='3vh -87px 0px 0px' mt='3vh -66px 0px 0px' mm='3vh -52px 0px 0px' style={{zIndex:0}} />
                                    <Flex width='52vw' wm='47vw' wt='43vw' wd='41vw' wlg='40vw' margin='-13px 0px 0px 0px' mm='-10px -58px 0px 0px' mt='-10px -72px 0px 0px' md='-10px -99px 0px 0px' mlg='-6px -130px 0px 0px'>
                                        {numeroRegla.map((item,i) =>
                                        <BtnRectaM73 m='0px 1px 0px 1px' mm='0px 1.5px 0px 1.5px' mt='0px 3.9px 0px 3.9px' md='0px 9.8px 0px 9.8px' mlg='0px 10.5px 0px 10.5px'
                                            key={item.id}
                                            onClick={(e) => asignarPosicion(e,i)}
                                            disabled={disable}
                                        >
                                            <PRectaM73 m='0px' color={item.color}>{item.numero}</PRectaM73>
                                        </BtnRectaM73>
                                        )}
                                    </Flex>
                                </FlexFull>
                            </FlexFull>
                        </FlexFull>
                        <Flex direction='column'>
                            <LateralM73>
                                <ImgB10 src={`/img/retrocede_inversa.png`} w='70px' style={{zIndex:0}} />
                            </LateralM73>
                            <Flex>
                                <CirculoMasMenos
                                    disabled={disable}
                                    onClick={(e) => restarCurva(e)}
                                >
                                    -
                                </CirculoMasMenos>
                                <CirculoMasMenos
                                    disabled={disable}
                                    onClick={(e) => sumarCurva(e)}
                                >
                                    +
                                </CirculoMasMenos>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </Flex>
                </Flex>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}
 
export default M76;