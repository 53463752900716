import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import HeaderEjercicios from '../../../../AuxiliaresPaginas/HeaderEjercicios';
import Instrucciones from '../../../../Escritorio/Instrucciones';
import BotoneraBottom from '../../../Compartidos/BotoneraBottom';
import useConfigAuth from '../../../../../Hooks/useConfigAuth';

import { finalizarEjercicioAction, setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';
import { almacenarAction } from '../../../../../actions/almacenarEjercicioActions';

//css
import { ContainerFlexx, Img, Pregunta, InputTextESC5, FormInput, BtnColor, Circulo, BtnBasurero } from '../../../../Css/MetodosCSS/StyledLectoEscritura';
import { AvanzarBtn, AvanzarImg } from '../../../../Css/StyledMatematica';

const array = [
    { id: 1, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 2, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 3, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 4, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 5, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 6, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 7, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 8, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 9, tipo: '', color: '', colorCirculo: '', muestro: false },
    { id: 10, tipo: '', color: '', colorCirculo: '', muestro: false }
]

const instrucciones = 'A continuación se presenta un dibujo. En una primera instancia deberás indicar la cantidad de sonidos que presenta la palabra. Luego seleccionaras el círculo gris para las consonantes y el rosa para las vocales.  Por último, escribe la palabra.';
const miniInstrucciones = '1- Indicar la cantidad de sonidos que presenta la palabra. 2- seleccionar el círculo gris para las consonantes y el rosa para las vocales. 3- escribir la palabra.';

const ESC5 = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [config] = useConfigAuth();

    const [disable, setDisable] = useState(false);
    const [comparar, setComparar] = useState([]);
    const [count, setCount] = useState(1);
    const [numero, setNumero] = useState('');
    const [tipo, setTipo] = useState('');
    const [matrix, setMatrix] = useState([]);
    const [palabra, setPalabra] = useState('');
    const [indexes, setIndexes] = useState();
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [contarErrores, setContarErrores] = useState(0);
    const [contarTiempo, setContarTiempo] = useState([]);
    const [ultimoTiempo, setUltimoTiempo] = useState(false);

    const iniciar = useSelector(state => state.ejercicio.iniciar);
    const finalizar = useSelector(state => state.ejercicio.finalizar);
    const id_paciente = useSelector((state) => state.usuario.id_paciente);
    const informacion = useSelector(state => state.informacion.info_DB);
    const lista_usada = useSelector(state => state.informacion.lista_usada);
    const sesion = useSelector((state) => state.ejercicio.sesion);
    const lista = useSelector((state) => state.ejercicio.lista);
    const minuscula = useSelector(state => state.configuracion.minuscula);
    const ayuda_imagen = useSelector((state) => state.configuracion.ayuda_imagen);

    const GoodWork = () => new Audio('/sounds/sonido_bien.mp3').play();
    const TryAgain = () => new Audio('/sounds/sonidoMal.mp3').play();

    const verificar = (e) => {
        e.preventDefault();
        const largo = matrix.filter(item => item.muestro === true).length;
        const newArray = matrix.filter(item => item.muestro === true).map(item => item.tipo);
        const comparoTipo = tipo.map((item, i) => item === newArray[i]);
        if (largo !== comparar.palabra_1.length || comparoTipo.find(item => item === false) === false || palabra.toUpperCase() !== comparar.palabra_1.toUpperCase()) {
            console.log('mal');
            setDisable(true)
            setIsIncorrect(true);
            setContarErrores(contarErrores + 1);
            setTimeout(() => {
                setIsIncorrect(false);
            }, 300);
            TryAgain();
            setTimeout(() => {
                setDisable(false);
                setMatrix(array)
                setNumero('');
                setPalabra('');
            }, 1000);
        } else {
            console.log('bien')
            setDisable(true);
            setIsCorrect(true);
            setTimeout(() => {
                setIsCorrect(false);
            }, 300);
            GoodWork();
            if (count < indexes) {
                setTimeout(() => {
                    setDisable(false);
                    setMatrix(array)
                    setNumero('');
                    setCount(count + 1);
                    setPalabra('');
                }, 1000);
            } else {
                setTimeout(() => {
                    setUltimoTiempo(true);
                    dispatch(finalizarEjercicioAction());
                }, 1000);
            }
        }

    }

    const setearTipo = (e, color, tipo) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(matrix));
        const index = matrix.findIndex(item => item.tipo === '' && item.muestro === true);
        if (index === -1) {
            return;
        } else {
            newList[index].colorCirculo = color;
            newList[index].tipo = tipo;
            setMatrix(newList);
        }
    }

    const borrar = (e) => {
        e.preventDefault();
        const newList = JSON.parse(JSON.stringify(matrix));
        newList.map(item => item.colorCirculo = '')
        newList.map(item => item.tipo = '')
        setMatrix(newList)
    }

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion[count-1]));
        const newArray = newList.palabra_1.toUpperCase().split("");
        const tipo = newArray.map(item => item.toUpperCase() === 'A' || item.toUpperCase() === 'Á' || item.toUpperCase() === 'E' || item.toUpperCase() === 'É' || item.toUpperCase() === 'I' || item.toUpperCase() === 'Í' || item.toUpperCase() === 'O' || item.toUpperCase() === 'Ó' || item.toUpperCase() === 'U' || item.toUpperCase() === 'Ú' ? item = 'vocal' : item = 'consonante');
        setTipo(tipo);
        setComparar(informacion[count-1]);
        setIndexes(Object.keys(informacion).length);
    }, [count, informacion])

    useEffect(() => {
        if (numero > 10) {
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(array));
            newList.filter(item => item.id <= numero).map(item => item.muestro = true);
            setMatrix(newList);
        }
    }, [numero]);

    useEffect(() => {
        dispatch(setearEjercicioAction('ESC5') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/escritura/silaba-directa/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(iniciar === false && count === indexes){
            return;
        }else if(iniciar === false && count === 1){
            return;
        } else {
            const newList = JSON.parse(JSON.stringify(contarTiempo));
            newList.push(new Date());
            setContarTiempo(newList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciar,count, ultimoTiempo]);

    useEffect(() => {
        if(finalizar){
            const tiempo = JSON.parse(JSON.stringify(contarTiempo));
            const conseguirTiempos = (a) => {
                const t = [];
                for (let i = 0; i < a.length - 1; i++){
                    const despues = new Date(a[i+1]);
                    const antes = new Date(a[i])
                    const segundos = Math.round((date.subtract(despues, antes).toSeconds())*10)/10;
                    t.push(segundos);
                }
                return t
            }
            const tiempoFinal = conseguirTiempos(tiempo);

            const informacion = {
                config,
                payload: {
                    'ejercicio': "ESC5",
                    'errores': contarErrores,
                    'tiempo': tiempoFinal,
                    'id_paciente': id_paciente,
                    'lista_usada': lista_usada,
                    lista,
                    sesion
                }
            }
            dispatch( almacenarAction(informacion) );
            navigate("/escritorio/metodos/finalizar-ejercicio");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalizar]);

    return (
        <Fragment>
            <HeaderEjercicios estrellas={true} carrito={true} minMay={true} ayuLectura={true} ayuImagen={true} />
            {iniciar
                ?
                <ContainerFlexx direction="column" height="100%" width="100%">
                    <ContainerFlexx display="flex" wrap='wrap' margin="auto">
                        <ContainerFlexx display="flex" direction="column" width="40vw" justify="end" align="center">
                                <Img w="230px" h="250px" src={`/img/${comparar.imagen_url_1}.png`} />
                                {ayuda_imagen ? 
                                    <div>{comparar.ayuda_imagen_1}</div>
                                : null
                                }
                        </ContainerFlexx>

                        <ContainerFlexx direction="column"
                            isCorrect={isCorrect} isIncorrect={isIncorrect}
                        >
                            {/* PREGUNTA */}
                            <ContainerFlexx>
                                <Pregunta>
                                    ¿Cuántas letra tiene?
                                </Pregunta>

                                <FormInput>
                                    <InputTextESC5 width="4rem" height="3.5rem" bradius="50%" font="2rem" type="numeric" min="1" max="6" value={numero} onChange={(e) => setNumero(e.target.value)} disabled={disable} />
                                </FormInput>
                            </ContainerFlexx>
                            {/* PREGUNTA */}

                            {numero === '' ? null
                                :
                                <ContainerFlexx direction='column'>
                                    <ContainerFlexx margin='1rem 0'>
                                        <ContainerFlexx direction="column">
                                            <BtnColor bg="rgba(208, 95, 103, 1)" border="none" width="2.5rem" height="2.5rem"  color="white" font="1.3rem" radius="50px" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" margin="0 0 1rem 0" weight="bold"
                                                onClick={(e) => setearTipo(e, '#D05F67', 'vocal')}
                                            >
                                                +
                                            </BtnColor>
                                            

                                            <BtnColor bg="rgba(100, 100, 100, 1)" border="none" width="2.5rem" height="2.5rem" color="white" font="1.3rem" radius="50px" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" weight="bold"
                                                onClick={(e) => setearTipo(e, 'grey', 'consonante')}
                                            >
                                                +
                                            </BtnColor>
                                        </ContainerFlexx>

                                        <ContainerFlexx>
                                            <ContainerFlexx>
                                                {matrix.filter(item => item.muestro === true).map(item =>
                                                    <ContainerFlexx display="flex"
                                                        key={item.id}
                                                    >
                                                        <Circulo style={{ backgroundColor: item.colorCirculo }} ></Circulo>
                                                    </ContainerFlexx>
                                                )}
                                            </ContainerFlexx>
                                            <BtnBasurero bg="transparent" border="none"
                                                onClick={(e) => borrar(e)}
                                            >
                                                <img alt='' src={`/img/icono_basurero.png`} />
                                            </BtnBasurero>
                                        </ContainerFlexx>
                                    </ContainerFlexx>
                                    {/* INPUT INFERIOR */}
                                    <ContainerFlexx>
                                        <FormInput 
                                            onSubmit={(e) => verificar(e)}
                                        >
                                            <InputTextESC5 width="16rem" height="2.5rem"  bradius="10px" font="2rem" type="text" $minMay={minuscula} value={palabra} onChange={(e) => setPalabra(e.target.value)} disabled={disable} />
                                        </FormInput>
                                    </ContainerFlexx>
                                    {/* INPUT INFERIOR */}
                                </ContainerFlexx>
                            }
                        </ContainerFlexx>

                    </ContainerFlexx>

                    <ContainerFlexx margin="0 0rem 0 0rem" width="90%" justify="space-between">
                        <BotoneraBottom miniInstrucciones={miniInstrucciones} />
                        <AvanzarBtn
                            disabled={disable}
                            onClick={(e) => verificar(e)}
                        >
                            <AvanzarImg src={'/img/play.png'} /> 
                        </AvanzarBtn>
                    </ContainerFlexx>
                </ContainerFlexx>
                :
                <Instrucciones instrucciones={instrucciones} />
            }
        </Fragment>
    );
}

export default ESC5;