import React, { Fragment } from 'react';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';

// css
import { ContainerImg, ElegirLink, ContenedorEjercicios, ContainerLink } from '../../../Css/MetodosCSS/StyledInstruccionExplicita';
import { HrMetodos } from '../../../Css/StyledHeader';


const Ejercicios = () => {

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} l3={"lectura/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} n3={"LECTURA"} componente={"EJERCICIOS"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <ContenedorEjercicios  margin="auto" display="flex" direction="column">
                <ContainerLink display="flex">
                    <ElegirLink to={`/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN14`}>
                        <ContainerImg height="130px" heightmob="124px" margin="0 1.5rem 1rem 0" src={`/img/portadas/LN14.jpg`} alt="" />
                    </ElegirLink>
                    <ElegirLink to={`/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN18`}>
                        <ContainerImg height="130px" heightmob="124px" margin="0 1.5rem 1rem 0" src={`/img/portadas/LN18.jpg`} alt="" />
                    </ElegirLink>
                    <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN19`}>
                        <ContainerImg height="130px" heightmob="124px" margin="0 1.5rem 1rem 0" src={`/img/portadas/LN19.jpg`} alt="" />
                    </ElegirLink>
                </ContainerLink>
                <ContainerLink display="flex">
                    <ElegirLink to={`/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN20`}>
                        <ContainerImg height="130px" heightmob="124px" margin="0 1.5rem 1rem 0" src={`/img/portadas/LN20.jpg`} alt="" />
                    </ElegirLink>
                    <ElegirLink to={`/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN21`}>
                        <ContainerImg height="130px" heightmob="124px" margin="0 1.5rem 1rem 0" src={`/img/portadas/LN21.jpg`} alt="" />
                    </ElegirLink>
                    <ElegirLink  to={`/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN30`}>
                        <ContainerImg height="130px" heightmob="124px" margin="0 1.5rem 1rem 0" src={`/img/portadas/LN30.jpg`} alt="" />
                    </ElegirLink>
                </ContainerLink>
                <ContainerLink display="flex">
                    <ElegirLink to={`/escritorio/metodos/lecto-escritura/lectura/ejercicios/LN31`}>
                        <ContainerImg height="130px" heightmob="124px" margin="0 1.5rem 1rem 0" src={`/img/portadas/LN31.jpg`} alt="" />
                    </ElegirLink>
                </ContainerLink>
            </ContenedorEjercicios>
        </Fragment>
    );
}

export default Ejercicios;