import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import FormularioPacienteTemporal from './FormularioPacienteTemporal';
import useConfigAuth from '../../Hooks/useConfigAuth';

// css
import {CuerpoCompleto, FlexColumn,  } from '../Css/StyledHeader.js'
import {Fondo, Label, Input, BotonNaranja,Div, Pe} from '../Css/StyledPatient.js';

import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';
import { buscoPacienteAction } from '../../actions/nuevoPacienteActions';
import { conseguirInformacionPacienteAction } from '../../actions/conseguirInformacionPacienteActions';
import Alertas from '../AuxiliaresPaginas/Alertas';
import { estadoCorrectoInitialAction } from '../../actions/resetStoreActions';

const NuevoPaciente = () => {

    const dispatch = useDispatch();

    const [config] = useConfigAuth();
    const [email, setEmail] = useState('');
    const [mostrarFormulario, setMostrarFormulario] = useState(true);

    const correcto = useSelector( (state) => state.estado.correcto);

    const buscarPaciente = (e) => {
        e.preventDefault();
        if(email.trim() === '') {
            const alerta = {msg:'Escriba un Email correcto'};
            dispatch( setearAlerta (alerta) );
            return;
        }
        dispatch( ocultarAlertaAction());
        
        const informacion = {
            config,
            payload: {
                email
            }
        };
        dispatch( buscoPacienteAction(informacion) );
    }
    const solicitoInfoPaciente = (e) => {
        e.preventDefault();
        const informacion = {
            config,
            payload: {
                email: email
            }
        }
        dispatch( conseguirInformacionPacienteAction(informacion) );
    }
    const resetearAction = (e) => {
        e.preventDefault();
        if(correcto !== null) {
            dispatch( estadoCorrectoInitialAction() );
        }
    }

    return (
        <CuerpoCompleto height="100vh">
            <HeaderUno />
            <HeaderDos link={`/escritorio/pacientes`} componente={"Nuevo Paciente"} paciente={false} />
            <FlexColumn align="center">
            <Alertas alertaOk={true} errorOk={true} correctoOk={true} />
                <div>
                { mostrarFormulario
                    ?   
                    <Fondo width="60vw" height="50vh" heightRes="60vh">
                        <div>
                            <Pe margin="15vh 0px 20px 0px">Deseo crear el paciente administrativo</Pe>
                            <BotonNaranja margin="10px 0px" onClick={() => setMostrarFormulario(false)}>
                                Crear usuario temporal
                            </BotonNaranja>
                        </div>
                        <form
                            onSubmit={buscarPaciente}
                        >
                            <Pe margin="0px 0px 20px 0px">El paciente tiene un usuario creado. Le solicito ser su profesional</Pe>
                            <Label>
                                <Input
                                    type="email"
                                    id="emailPaciente"
                                    name="email"
                                    placeholder="Email Paciente"
                                    value={email}
                                    onChange={e => {setEmail(e.target.value); resetearAction(e)}}
                                />
                            </Label>
                            <FlexColumn align="center">
                                <BotonNaranja margin="20px 0px" 
                                    type="submit"
                                >
                                    Buscar paciente     
                                </BotonNaranja>
                            </FlexColumn>
                        </form>
                    </Fondo>
                    
                    :
                    <div>
                        <FormularioPacienteTemporal />
                    </div>
                }
                {correcto === null ? null : correcto.tipo === 'plataforma' ?
                    <Div margin="-30vh 3vw" marginRes= "-40vh 3vw">
                        <Pe margin="10px 0px">El {email} es usuario método Yogen. </Pe>
                        <Pe margin="10px 0px">Puede solicitarle los datos a los tutores pidiendo la información del paciente.<br /></Pe> 
                        <Pe margin="10px 0px">Haz click en el siguiente botón para solicitar la información:</Pe>
                        <button
                            onClick={(e) => solicitoInfoPaciente(e)}
                        >
                            Solicitar Información Paciente
                        </button>
                    </Div>
                : null}
                {correcto === null ? null : correcto.tipo === 'administrativo' ?
                    <Div margin="-30vh 3vw" marginRes= "-40vh 3vw">
                        <Pe margin="10px 0px">El {email} está registrado dentro de tus pacientes administrativos. Podrás encontrarlo dentro del listado de tus pacientes </Pe>
                    </Div>
                : null}
                {correcto === null ? null : correcto.tipo === 'completo' ?
                    <Div margin="-30vh 3vw" marginRes= "-40vh 3vw">
                        <Pe margin="10px 0px">El {email} está registrado dentro de tus pacientes y es usuario de Método Yögen. Podrás encontrarlo dentro del listado de tus pacientes </Pe>
                    </Div>
                : null}
                </div>
            </FlexColumn>
        </CuerpoCompleto>
    );
}
 
export default NuevoPaciente;