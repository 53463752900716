import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LN13Todos from '../../../Compartidos/LN13Todos';

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Lee la siguiente lista de palabras.';
const miniInstrucciones = 'Lee la siguiente lista de palabras.';

const LN13ST = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('LN13ST') );
        dispatch(setearUrlAnteriorAction('lecto-escritura/lectura/silaba-trabada/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])

    return (
        <Fragment>
            <LN13Todos data={data} indexes={indexes} ejercicio="LN13ST" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} />
        </Fragment>
    );
}
 
export default LN13ST;