import {
    BUSCO_PACIENTE,
    BUSCO_PACIENTE_EXITO,
    BUSCO_PACIENTE_ERROR
} from '../types';
import clienteAxios from '../config/axios';

// Busco si existe el paciente antes de crearlo en el sistema
export function buscoPacienteAction(informacion) {
    return async (dispatch) => {
        dispatch( buscoPaciente() );
        try {
            const {config, payload} = informacion;
            const respuesta = await clienteAxios.post('/paciente/buscarpaciente', payload, config);
            dispatch( buscoPacienteExito(respuesta.data) );
        } catch (error) {
            const errores = error.response;
            if (errores !== undefined) {
                dispatch( buscoPacienteError(error.response.data) );
            }
        }
    }
}

const buscoPaciente = () => ({
    type: BUSCO_PACIENTE,
    payload: true
});

const buscoPacienteExito = (msg) => ({
    type: BUSCO_PACIENTE_EXITO,
    payload: msg
});

const buscoPacienteError = (msg) => ({
    type: BUSCO_PACIENTE_ERROR,
    payload: msg
});