import React, {Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ImgSubL1 from '../../../Compartidos/ImgSubL1';
//CAMI

import { setearEjercicioAction, setearUrlAnteriorAction } from '../../../../../actions/estadoEjercicioActions';

const instrucciones = 'Indica si es el mismo número.';
const miniInstrucciones = 'Indica si es el mismo número.';

const M11 = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState();
    const [indexes, setIndexes] = useState();

    const informacion = useSelector(state => state.informacion.info_DB);

    useEffect(() => {
        dispatch(setearEjercicioAction('M11') );
        dispatch(setearUrlAnteriorAction('matematica/subitizacion/nivel-3/'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(informacion.length === 0){
            return;
        }
        const newList = JSON.parse(JSON.stringify(informacion));
        setData(newList);
        setIndexes(Object.keys(newList).length);
    }, [informacion])
    
    return (
        <Fragment>
            <ImgSubL1 width="240px" wm='260px' wt='280px' wd='300px' wlg='320px' data={data} indexes={indexes} ejercicio="M11" instrucciones={instrucciones} miniInstrucciones={miniInstrucciones} estrellas={true} carrito={true} h="20rem" w="20rem" />
        </Fragment>
    );
}
 
export default M11;