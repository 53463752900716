
import HeaderUno from '../Metodos/Compartidos/HeaderUno';
import HeaderDos from '../Metodos/Compartidos/HeaderDos';
import Calendario from '../AuxiliaresPaginas/Calendario';

// css
import {Cuerpo, FlexBox} from '../Css/StyledHeader.js';

const Escritorio = () => {

   
    return ( 
        <Cuerpo>
            <HeaderUno />
            <HeaderDos componente="Escritorio" paciente={true} />
            <FlexBox justify="center">
                <Calendario />
            </FlexBox>

        </Cuerpo>
    );
}
 
export default Escritorio;