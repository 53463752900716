import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HeaderUno from '../../Compartidos/HeaderUno';
import HeaderMetodos from '../../../AuxiliaresPaginas/HeaderMetodos';
import useConfigAuth from '../../../../Hooks/useConfigAuth';

import { BtnLetra, ContenedorLetras, BtnElegir } from '../../../Css/MetodosCSS/StyledInstruccionExplicita';
import { BtnLetraInner, BtnLink } from '../../../Css/MetodosCSS/StyledSilabas';
import { HrMetodos } from '../../../Css/StyledHeader';

import { setearListaAction } from '../../../../actions/estadoEjercicioActions';
import { almacenarCheckEjerciciosAction } from '../../../../actions/almacenarEjercicioActions';

const Pseudopalabras = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const [checkeable, setCheckeable] = useState([]);
    const [clickeados, setClickeados] = useState([]);
    const [disable, setDisable] = useState(true);

    const listas_check = useSelector(state => state.ejercicio.listas_check);
    const lista_ejercicios_check = useSelector(state => state.informacion.lista_ejercicios_check);
    const id_paciente = useSelector(state => state.usuario.id_paciente);

    const setearLista = (e, lista) => {
        e.preventDefault();
        dispatch(setearListaAction(lista));
    }

    const checkear = (e, id) => {
        const newList = JSON.parse(JSON.stringify(checkeable));
        const newList2 = JSON.parse(JSON.stringify(clickeados));
        const encontrado = newList2.findIndex(item => item.id_lista === id);
        setDisable(false);
        const ids = newList2.map(item => {
            return item.id
        });
        const maxId = Math.max(...ids);
        if(encontrado !== -1) {
            newList2.splice(encontrado,1);
            setClickeados(newList2);
        } else {
            newList2.push({
                id: maxId +1,
                id_lista: id,
                id_paciente
            })
            setClickeados(newList2);
        }
        if (e.target.checked) {
            newList[id - 1].check = true;
            setCheckeable(newList);
        } else {
            newList[id - 1].check = false;
            setCheckeable(newList);
        }
    }

    const guardar = (e) => {
        e.preventDefault();
        setDisable(true);
        const informacion = {
            config,
            payload: {
                clickeados,
                id_paciente
            }
        }
        dispatch(almacenarCheckEjerciciosAction(informacion));
    }

    useEffect(() => {
        const newList = JSON.parse(JSON.stringify(listas_check));
        const newList2 = JSON.parse(JSON.stringify(lista_ejercicios_check));
        const newList3 = newList2.filter(item => item.id_paciente === id_paciente);
        setClickeados(newList3);
        newList.forEach((item, i) => {
            let idEncontrado = newList3.find(elemento => elemento.id_lista === item.id )
            if(idEncontrado) {
                newList[i].check = true;
            }
        })
        setCheckeable(newList)
    }, [])

    return (
        <Fragment>
            <HeaderUno />
            <HrMetodos margin='0 0.2rem 0.5rem 0.2rem' />
            <HeaderMetodos l1={"metodos/"} l2={"lecto-escritura/"} l3={"lectura/"} n1={"Métodos"} n2={"LECTURA Y ESCRITURA"} n3={"LECTURA"} componente={"PSEUDOPALABRAS"} paciente={true} bgcolor="#646464" color="white" width='96vw' shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" display="none"/>
            <ContenedorLetras height="100%" width="100%" justify="center" align="center">
                <ContenedorLetras margin="auto">
                    <ContenedorLetras margin="1rem" justify="center">
                        {checkeable.filter(item => item.tipo === 'pseudopalabras').map(item => (
                            <BtnLetra width="8rem" height="3rem" margin="0.5rem" display="flex" justify="space-between" align="center" shadow="0px 4px 4px rgba(0, 0, 0, 0.25)" key={item.id} >
                                <BtnLetraInner bg="#D05F67"
                                    onClick={(e) => setearLista(e, item.lista)}
                                >
                                    <BtnLink font="1.1rem" color="white" fontmob="0.9rem" to={`/escritorio/metodos/lecto-escritura/lectura/pseudopalabras/LN44`}>{item.nombre_lista}</BtnLink>

                                </BtnLetraInner>
                                <input
                                    type="checkbox"
                                    id={item.id}
                                    checked={item.check || ''}
                                    onChange={(e) => checkear(e, item.id)} />
                            </BtnLetra>
                        ))}
                    </ContenedorLetras>
                </ContenedorLetras>
                <ContenedorLetras width="95%" align="center" justify="flex-end" alignself="end">
                  <BtnElegir bg={disable ? 'grey' : "#23AAAF"}
                    disabled={disable}
                    onClick={(e) => guardar(e)}
                  >
                        Guardar
                  </BtnElegir>
                </ContenedorLetras>
            </ContenedorLetras>
        </Fragment>    
    );
}
 
export default Pseudopalabras;