import React from 'react';


import FullCalendar from '@fullcalendar/react' ;
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

// css
import {FlexBox, Icon, CuerpoCompleto} from '../Css/StyledHeader.js';
import {Agregar, ContenedorCalendario, Fondo} from '../Css/StyledCalendar.js'

const esLocale = {
    code: "es",
    week: {
      dow: 1, // Sunday is the first day of the week.
      doy: 6, // The week that contains Jan 1st is the first week of the year.
    },
    buttonText: {
      today: "Hoy",
      month: "Mes",
      week: "Semana",
      day: "Día",
    },
    weekText: "Sm",
    allDayText: "Todo el día",
    moreLinkText: "más",
    noEventsText: "No hay eventos para mostrar",
}

const eventos = [
    {title: 'Cumpleaños de Oli',
     start: '2022-10-14',
     allDay: true,
     
    },
    {title: 'Festejo de Oli',
     start: '2021-07-11T12:30:00',
     end: '2021-07-11T17:45:00',
     color: 'yellow'
    }
]

const Calendario = () => {

    const editarEvento = () => {
        
    }

    return (
        <CuerpoCompleto height="140vh" width="99vw">
            <FlexBox justify="center">
                <Fondo>
                    <ContenedorCalendario>
                        <FullCalendar
                            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                            initialView="timeGridWeek"
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            locale={esLocale}
                            slotMinTime={'08:00:00'}
                            slotMaxTime={'21:00:00'}
                            nowIndicator={true}
                            height="auto"
                            events={eventos}
                            dateClick={() => editarEvento()}
                        />
                    </ContenedorCalendario>
                    <Agregar><Icon src={`/img/mas.svg`} alt="Agregar" width="3vh"/></Agregar>
                </Fondo>
            </FlexBox>
        </CuerpoCompleto>
    );
}
 
export default Calendario;