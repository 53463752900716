import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clienteAxios from '../../config/axios';

import useConfigAuth from '../../Hooks/useConfigAuth';

import { setearAlerta } from '../../actions/alertaActions';
import { Table2, Th, Tr, Td1, Thead, BotonNaranjaButton, Input, Flex, Div } from '../Css/StyledPatient';

const Suscriptores = () => {

    const dispatch = useDispatch();
    const [config] = useConfigAuth();

    const [id, setID] = useState('');
    const [email, setEmail] = useState('');
    const [datosActivacion, setDatosActivacion] = useState([]);
    const [datosProfesional, setDatosProfesional] = useState();

    const [idProfesionalDesactivo, setIDProfesionalDesactivo] = useState('');
    const [confirmo, setConfirmo] = useState('');

    const buscarActivados = async (e) => {
        e.preventDefault();
        const payload = {
            id,
            email
        }
        try {
            const {data} = await clienteAxios.post('/usuarios/buscoactivados', payload, config);
            setDatosActivacion(data.dataActivacion);
            setDatosProfesional(data.dataProfesional);
        } catch (error) {
            console.log(error.response)
        }
    }

    const eliminarActivacion = async (e) => {
        e.preventDefault();
        if(!idProfesionalDesactivo || !confirmo || confirmo === "Esta seguro?") {
            const alerta = {
                msg: 'Faltan completar datos'
            }
            dispatch( setearAlerta (alerta) );
            return;
        }
        const payload = {
            idProfesionalDesactivo
        }
        try {
            const {data} = await clienteAxios.post('/usuarios/desactivometodo', payload, config);
            setIDProfesionalDesactivo('');
            setConfirmo('');
            const alerta = {
                msg: data.msg
            }
            dispatch( setearAlerta (alerta) );
        } catch (error) {
            console.log(error.response)
        }
    }

    return (
        <Flex direccion="column">
            <Flex margin="3rem 0 0rem 0">
                <form
                    onSubmit={(e) => buscarActivados(e)}
                >
                    <label style={{width: "5rem"}}htmlFor="text">ID</label>
                    <Input width="6rem" radius="10px"
                        type="text"
                        value={id || ""}
                        onChange={e => setID(e.target.value)}
                    />
                    <label style={{width: "5rem"}}htmlFor="text">Email</label>
                    <Input width="16rem" radius="10px"
                        type="email"
                        value={email || ""}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Flex>
                        <BotonNaranjaButton type="submit" margin="10px">Buscar</BotonNaranjaButton>
                    </Flex>
                </form>
            </Flex>
            <Flex direccion="column" margin="3rem 0 3rem 0">
                <Flex justify="space-between">
                    <Div margin="0 1rem 0 0">ID Profesional: {datosProfesional?.id}</Div>
                    <Div margin="0 1rem 0 1rem">Email: {datosProfesional?.email}</Div>
                    <Div margin="0 1rem 0 1rem">Apellido, Nombre: {datosProfesional?.apellido_nombre}</Div>
                    <Div margin="0 0 0 1rem">Activo?: {datosProfesional?.activo ? "Si" : "No" }</Div>
                </Flex>
                <Table2>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Proveedor</Th>
                            <Th>F. inicio</Th>
                            <Th>F. Fin</Th>
                            <Th>Activo?</Th>
                        </Tr>
                    </Thead>
                    <tbody>
                        {datosActivacion?.map( item => 
                        <Tr textalign="center"
                            key={item.id}
                        >
                            <Td1>{item.id}</Td1>
                            <Td1>{item.nombre_medio}</Td1>
                            <Td1>{item.fecha_inicio}</Td1>
                            <Td1>{item.fecha_fin}</Td1>
                            <Td1>{item.activo ? "Si" : "No"}</Td1>
                        </Tr>
                        )}
                    </tbody>
                </Table2>
            </Flex>
            <Flex direccion="column">
                <Div>ELIMINAR ACTIVACION</Div>
                <form
                    onSubmit={(e) => eliminarActivacion(e)}
                >
                    <label style={{width: "5rem"}}htmlFor="text">ID PROF</label>
                    <Input width="6rem" radius="10px"
                        type="text"
                        value={idProfesionalDesactivo || ""}
                        onChange={e => setIDProfesionalDesactivo(e.target.value)}
                    />
                    <select value={confirmo} onChange={e => setConfirmo(e.target.value)}>
                        <option>Esta seguro?</option>
                        <option>Si</option>
                    </select>
                    <Flex>
                        <BotonNaranjaButton type="submit" margin="10px">Eliminar</BotonNaranjaButton>
                    </Flex>
                </form>
            </Flex>
        </Flex>
    );
}
 
export default Suscriptores;